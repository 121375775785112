"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var angular_1 = require("@uirouter/angular");
var $ = require("jquery");
var applications_component_1 = require("@app/common/components/applications/applications.component");
var applicationsHost_directive_1 = require("@app/common/directives/applications/applicationsHost.directive");
var auth_service_1 = require("@app/common/services/auth.service");
var backend_version_service_1 = require("@app/common/services/backend-version.service");
var tooltip_system_metadata_component_1 = require("@app/common/components/tooltip-system-metadata/tooltip-system-metadata.component");
var MenuComponent = /** @class */ (function () {
    function MenuComponent(authService, cdr, componentFactoryResolver, elementRef, UIRouterGlobals, backendVersionService, frontendVersion, wikiUrl, APP_BRAND) {
        this.authService = authService;
        this.cdr = cdr;
        this.componentFactoryResolver = componentFactoryResolver;
        this.elementRef = elementRef;
        this.UIRouterGlobals = UIRouterGlobals;
        this.backendVersionService = backendVersionService;
        this.frontendVersion = frontendVersion;
        this.wikiUrl = wikiUrl;
        this.APP_BRAND = APP_BRAND;
        this.collapsed = false;
        this.menuConfig = [];
        this.copyrightDate = new Date();
        this.metadata = null;
        this.tooltipSystemMetadataComponent = tooltip_system_metadata_component_1.TooltipSystemMetadataComponent;
        this.user = this.authService.getUser();
        this.onDocumentClick = this.onDocumentClick.bind(this);
    }
    MenuComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b;
            var _this = this;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        this.applications = this.authService.getApplications().filter(function (a) { return _this.authService.aplicationHasProjects(a); });
                        _a = this;
                        _b = {};
                        return [4 /*yield*/, this.backendVersionService.getVersion()];
                    case 1:
                        _a.metadata = (_b.backendVersion = _c.sent(),
                            _b.frontendVersion = this.frontendVersion,
                            _b.wikiUrl = this.wikiUrl,
                            _b.cadastre = this.authService.getActualProject().cadastreDates,
                            _b);
                        return [2 /*return*/];
                }
            });
        });
    };
    MenuComponent.prototype.isActive = function (uiSref) {
        return !!this.UIRouterGlobals.$current.includes[uiSref];
    };
    MenuComponent.prototype.onDocumentClick = function (event) {
        var isClickInside = $.contains(this.componentRef.location.nativeElement, event.target);
        if (!isClickInside) {
            this.hideApplications();
        }
    };
    MenuComponent.prototype.hideApplications = function () {
        if (this.componentRef) {
            document.removeEventListener('click', this.onDocumentClick);
            this.componentRef.destroy();
            this.componentRef = undefined;
        }
    };
    MenuComponent.prototype.onApplicationSwitcherMouseEnter = function () {
        this.elementRef.nativeElement.querySelector('#app-switcher').classList.add('active');
    };
    MenuComponent.prototype.onApplicationSwitcherMouseLeave = function () {
        var switcherElm = this.elementRef.nativeElement.querySelector('#app-switcher.active');
        if (switcherElm) {
            switcherElm.classList.remove('active');
        }
    };
    MenuComponent.prototype.showApplications = function (event) {
        event.stopPropagation();
        if (this.componentRef) {
            this.hideApplications();
        }
        else {
            var box = this.elementRef.nativeElement.querySelector('#app-switcher').getBoundingClientRect();
            var top_1 = box.top - (this.collapsed ? 5 : 0);
            var left = box.width - 5;
            var factory = this.componentFactoryResolver.resolveComponentFactory(applications_component_1.ApplicationsComponent);
            this.componentRef = this.applicationsHostDirective.viewContainerRef.createComponent(factory);
            this.componentRef.instance.applications = this.applications;
            $(this.componentRef.location.nativeElement).css({
                cursor: 'default',
                visibility: 'hidden',
                top: '0px',
                left: '0px',
                position: 'fixed',
                zIndex: 400,
            });
            this.cdr.detectChanges(); // force change detection to get real height in the code below
            var applicationHeight = $(this.componentRef.location.nativeElement).outerHeight() - $(this.elementRef.nativeElement).find('#app-switcher')[0].getBoundingClientRect().height;
            $(this.componentRef.location.nativeElement).css({
                visibility: 'visible',
                transform: "translate3d(" + left + "px, " + (top_1 - applicationHeight) + "px, 0px)",
            });
            document.addEventListener('click', this.onDocumentClick);
        }
    };
    return MenuComponent;
}());
exports.MenuComponent = MenuComponent;
