"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var request_status_enum_1 = require("../enums/request-status.enum");
var i0 = require("@angular/core");
var i1 = require("../pk.module");
var StatusMessagePipe = /** @class */ (function () {
    function StatusMessagePipe() {
    }
    StatusMessagePipe.prototype.transform = function (value) {
        switch (value) {
            case request_status_enum_1.RequestStatusEnum.FAILED:
                return 'Chyba';
            case request_status_enum_1.RequestStatusEnum.FINISHED:
                return 'Hotovo';
            case request_status_enum_1.RequestStatusEnum.WAITING_FOR_PRICE_CONFIRMATION:
                return 'Odsouhlasit cenu';
            case request_status_enum_1.RequestStatusEnum.CUZK_DATA_RECEIVED:
            case request_status_enum_1.RequestStatusEnum.INTERSECTION:
                return 'Protíná se';
            default:
                return 'Vyřizuje se';
        }
    };
    StatusMessagePipe.ngInjectableDef = i0.defineInjectable({ factory: function StatusMessagePipe_Factory() { return new StatusMessagePipe(); }, token: StatusMessagePipe, providedIn: i1.PkAppModule });
    return StatusMessagePipe;
}());
exports.StatusMessagePipe = StatusMessagePipe;
