"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./vfze-problems.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../../directives/tooltip/tooltip.directive");
var i3 = require("@angular/common");
var i4 = require("../../directives/loading.directive");
var i5 = require("./vfze-problems.component");
var styles_VfzeProblemsComponent = [i0.styles];
var RenderType_VfzeProblemsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_VfzeProblemsComponent, data: {} });
exports.RenderType_VfzeProblemsComponent = RenderType_VfzeProblemsComponent;
function View_VfzeProblemsComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "fa fa-eye"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" (", ")"])), (_l()(), i1.ɵted(3, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.parent.parent.parent.context.$implicit.problems.length; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.parent.parent.parent.context.$implicit.problems[0].message; _ck(_v, 3, 0, currVal_1); }); }
function View_VfzeProblemsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 4, "div", [["class", "vfze-column"]], null, [[null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("mouseenter" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).mouseEnter() !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).closeTooltip() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 81920, null, 0, i2.TooltipDirective, [i1.ElementRef, "WindowDimensionService", i1.Renderer2, i1.ComponentFactoryResolver, i1.ViewContainerRef, i1.Injector], { content: [0, "content"], data: [1, "data"], activeContent: [2, "activeContent"] }, null), i1.ɵpod(2, { problems: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VfzeProblemsComponent_5)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tooltipProblemsComponent; var currVal_1 = _ck(_v, 2, 0, _v.parent.parent.context.$implicit.problems); var currVal_2 = true; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = ((_v.parent.parent.context.$implicit.problems == null) ? null : _v.parent.parent.context.$implicit.problems.length); _ck(_v, 4, 0, currVal_3); }, null); }
function View_VfzeProblemsComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "vfze-column"]], null, null, null, null, null))], null, null); }
function View_VfzeProblemsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "vfze-column"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "vfze-column"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "pre", [], null, null, null, null, null)), i1.ɵdid(5, 278528, null, 0, i3.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(6, { "error": 0 }), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VfzeProblemsComponent_4)), i1.ɵdid(9, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VfzeProblemsComponent_6)), i1.ɵdid(11, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_1 = _ck(_v, 6, 0, _v.parent.context.$implicit.problems.length); _ck(_v, 5, 0, currVal_1); var currVal_3 = ((_v.parent.context.$implicit.problems == null) ? null : _v.parent.context.$implicit.problems.length); _ck(_v, 9, 0, currVal_3); var currVal_4 = (!_v.parent.context.$implicit.problems || (_v.parent.context.$implicit.problems.length === 0)); _ck(_v, 11, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.line; _ck(_v, 2, 0, currVal_0); var currVal_2 = _v.parent.context.$implicit.content; _ck(_v, 7, 0, currVal_2); }); }
function View_VfzeProblemsComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "divider"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["..."]))], null, null); }
function View_VfzeProblemsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "vfze-row"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i3.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { error: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VfzeProblemsComponent_3)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["divider", 2]], null, 0, null, View_VfzeProblemsComponent_7))], function (_ck, _v) { var currVal_0 = "vfze-row"; var currVal_1 = _ck(_v, 2, 0, ((_v.context.$implicit.problems == null) ? null : _v.context.$implicit.problems.length)); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = (_v.context.$implicit.line && _v.context.$implicit.content); var currVal_3 = i1.ɵnov(_v, 5); _ck(_v, 4, 0, currVal_2, currVal_3); }, null); }
function View_VfzeProblemsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["class", "bordered"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "vfze-row head"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "vfze-column"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u0158\u00E1dek"])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "vfze-column"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["XML"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "vfze-column"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Probl\u00E9m"])), (_l()(), i1.ɵeld(8, 0, null, null, 7, "div", [["class", "view-area"]], null, [[null, "scroll"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("scroll" === en)) {
        var pd_0 = (_co.onScroll($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(9, 278528, null, 0, i3.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(10, { height: 0 }), (_l()(), i1.ɵeld(11, 0, null, null, 4, "div", [], null, null, null, null, null)), i1.ɵdid(12, 278528, null, 0, i3.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(13, { height: 0, marginTop: 1, marginBottom: 2 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VfzeProblemsComponent_2)), i1.ɵdid(15, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 10, 0, (_co.viewHeight + "px")); _ck(_v, 9, 0, currVal_0); var currVal_1 = _ck(_v, 13, 0, (_co.viewHeight + "px"), (_co.marginTop + "px"), (_co.marginBottom + "px")); _ck(_v, 12, 0, currVal_1); var currVal_2 = _co.rowsToShow; _ck(_v, 15, 0, currVal_2); }, null); }
function View_VfzeProblemsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], [[8, "className", 0]], null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i4.LoadingDirective, [i1.ElementRef], { loading: [0, "loading"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VfzeProblemsComponent_1)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.loading; _ck(_v, 1, 0, currVal_1); var currVal_2 = !_co.loading; _ck(_v, 3, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).elementClass; _ck(_v, 0, 0, currVal_0); }); }
exports.View_VfzeProblemsComponent_0 = View_VfzeProblemsComponent_0;
function View_VfzeProblemsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "vfze-problems", [], null, null, null, View_VfzeProblemsComponent_0, RenderType_VfzeProblemsComponent)), i1.ɵdid(1, 114688, null, 0, i5.VfzeProblemsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_VfzeProblemsComponent_Host_0 = View_VfzeProblemsComponent_Host_0;
var VfzeProblemsComponentNgFactory = i1.ɵccf("vfze-problems", i5.VfzeProblemsComponent, View_VfzeProblemsComponent_Host_0, { validationResponse: "validationResponse", vfzeSource: "vfzeSource" }, {}, []);
exports.VfzeProblemsComponentNgFactory = VfzeProblemsComponentNgFactory;
