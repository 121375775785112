"use strict";
var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var auth_service_1 = require("@app/common/services/auth.service");
var settings_service_1 = require("@app/ps/services/settings.service");
var moment = require("moment");
var _ = require("lodash");
var list_service_1 = require("@app/common/services/list.service");
var checklist_service_1 = require("@app/common/services/checklist.service");
var statistics_service_1 = require("@app/ps/services/statistics.service");
var table_service_1 = require("@app/ps/services/table.service");
var word_service_1 = require("@app/common/services/word.service");
var series_service_1 = require("@app/ps/services/series.service");
var modules_service_1 = require("@app/common/services/modules.service");
var project_subject_name_pipe_1 = require("@app/ps/pipes/project-subject-name.pipe");
var dialog_service_1 = require("@app/dialog/dialog.service");
var angular_1 = require("@uirouter/angular");
var obligation_settings_component_1 = require("@app/ps/project-settings/components/obligation-settings/obligation-settings.component");
var obligation_flow_settings_component_1 = require("@app/ps/project-settings/components/obligation-flow-settings/obligation-flow-settings.component");
var common_1 = require("@angular/common");
var case_status_service_1 = require("@app/ps/services/case-status.service");
var string_utils_1 = require("@app/common/utils/string.utils");
var organizational_unit_name_pipe_1 = require("@app/common/pipes/organizational-unit-name.pipe");
var ProjectUpdateComponent = /** @class */ (function () {
    function ProjectUpdateComponent(configurationRestangularService, restangularSymap, restangular, localStorageService, caseStatusService, APP_BRAND, projectSubjectNamePipe, organizationalUnitNamePipe, elementRef, tableService, wordService, statisticsService, listService, authService, settingsService, checklistService, seriesService, modulesService, dialogService, stateService, datePipe) {
        this.configurationRestangularService = configurationRestangularService;
        this.restangularSymap = restangularSymap;
        this.restangular = restangular;
        this.localStorageService = localStorageService;
        this.caseStatusService = caseStatusService;
        this.APP_BRAND = APP_BRAND;
        this.projectSubjectNamePipe = projectSubjectNamePipe;
        this.organizationalUnitNamePipe = organizationalUnitNamePipe;
        this.elementRef = elementRef;
        this.tableService = tableService;
        this.wordService = wordService;
        this.statisticsService = statisticsService;
        this.listService = listService;
        this.authService = authService;
        this.settingsService = settingsService;
        this.checklistService = checklistService;
        this.seriesService = seriesService;
        this.modulesService = modulesService;
        this.dialogService = dialogService;
        this.stateService = stateService;
        this.datePipe = datePipe;
        this.goToPrevState = false;
        this.loading = true;
        this.selectedTab = 'G';
        this.tabs = [];
        this.user = this.authService.getUser();
        this.projectId = this.authService.getActualProject().id;
        this.project = this.authService.getActualProject();
        this.mapSettingsOnSaveCallback = null;
        this.settings = null;
        this.settingsSaving = false;
        this.dataCaseStatuses = null;
        this.dataCaseStatusesLoading = false;
        this.dataCaseStatuseEdited = null;
        this.dataTemplateTypes = null;
        this.checklistTemplates = null;
        this.dataExportTypes = null;
        this.checklistExportTypes = null;
        this.statisticsData = null;
        this.statisticsCallbackRegister = null;
        this.statistics = null;
        this.columnsVisibilityData = null;
        this.columnsVisibility = null;
        this.columnsRestrictionsTable = null;
        this.nomenclatureData = null;
        this.nomenclature = null;
        this.nomenclatureEdited = null;
        this.emailData = null;
        this.email = null;
        this.seriesParsed = null;
        this.cadastreDeliveryDate = null;
        this.downloadDataDate = null;
        this.mapSettingsDefaults = null;
        this.dashboardSettingsOnSaveCallback = null;
        this.dashboardSettingsDefaults = null;
        this.cadastreDeliveryMonthPickerOptions = {
            minDate: moment().subtract(1, 'month').toDate(),
            maxDate: moment().add(1, 'month').toDate(),
            defaultDate: new Date()
        };
        this.downloadDataMonthPickerOptions = {
            minDate: moment().subtract(1, 'month').toDate(),
            maxDate: new Date(),
            defaultDate: new Date()
        };
        this.handleInvestorChange = this.handleInvestorChange.bind(this);
        this.getMapSettingsDefaults = this.getMapSettingsDefaults.bind(this);
        this.getDashboardSettingsDefaults = this.getDashboardSettingsDefaults.bind(this);
        this.displayEdit = this.displayEdit.bind(this);
        this.resetEdit = this.resetEdit.bind(this);
        this.columnIsVisible = this.columnIsVisible.bind(this);
        this.toggleHiddenColumn = this.toggleHiddenColumn.bind(this);
        this.selectTab = this.selectTab.bind(this);
        this.initFormData = this.initFormData.bind(this);
        this.onUpdateObligation = this.onUpdateObligation.bind(this);
        this.onUpdateObligationFlow = this.onUpdateObligationFlow.bind(this);
        this.update = this.update.bind(this);
        this.runCadastreDelivery = this.runCadastreDelivery.bind(this);
        this.runDownloadData = this.runDownloadData.bind(this);
        this.onProjectSubjectUpdateRegister = this.onProjectSubjectUpdateRegister.bind(this);
        this.onUpdateOrganizationalUnit = this.onUpdateOrganizationalUnit.bind(this);
        this.reloadOptionsOrganizationalUnitRegister = this.reloadOptionsOrganizationalUnitRegister.bind(this);
        this.onSaveNotificationConfigRegister = this.onSaveNotificationConfigRegister.bind(this);
    }
    ProjectUpdateComponent.prototype.ngOnInit = function () {
        this.tabs = this.getTabs();
        this.settingsService.clearSettings();
        this.modulesService.loadSyMAPProjectById(this.projectId).then(this.initFormData);
        this.organizationalUnitsNotCancelled = this.listService.createList('organizational-units', { filters: { cancelled: [false] } }, this.configurationRestangularService);
    };
    ProjectUpdateComponent.prototype.handleInvestorChange = function (investor) {
        if (!investor) {
            this.data.investor = null;
            return;
        }
        this.data.investor = _.cloneDeep(investor);
        this.data.investor.customerIdText = investor.idText;
        this.data.investor.customerOrganizationalUnit = null;
        this.data.investor.actingPersons.forEach(function (ap) {
            ap.remoteId = ap.id;
            ap.id = null;
        });
        ProjectUpdateComponent.getInvestorDefaults(this.data.investor);
        this.projectSubjectUpdate(this.data.investor.actingPersons);
        this.reloadUnits();
    };
    ProjectUpdateComponent.prototype.getMapSettingsDefaults = function (event) {
        this.mapSettingsDefaults = event.defaults;
        this.mapSettingsOnSaveCallback = event.onSave;
    };
    ProjectUpdateComponent.prototype.getDashboardSettingsDefaults = function (event) {
        this.dashboardSettingsDefaults = event.defaults;
        this.dashboardSettingsOnSaveCallback = event.onSave;
    };
    ProjectUpdateComponent.prototype.displayEdit = function (caseStatusIndex, event, type) {
        this.setEditItemIndexByType(type, caseStatusIndex);
        var inputs = event.currentTarget.getElementsByTagName('input');
        setTimeout(function () {
            var e_1, _a;
            try {
                for (var inputs_1 = __values(inputs), inputs_1_1 = inputs_1.next(); !inputs_1_1.done; inputs_1_1 = inputs_1.next()) {
                    var input = inputs_1_1.value;
                    input.focus();
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (inputs_1_1 && !inputs_1_1.done && (_a = inputs_1.return)) _a.call(inputs_1);
                }
                finally { if (e_1) throw e_1.error; }
            }
        });
    };
    ProjectUpdateComponent.prototype.onUpdateOrganizationalUnit = function (event) {
        this.data.investor.customerOrganizationalUnit = event.newValue;
    };
    ProjectUpdateComponent.prototype.reloadOptionsOrganizationalUnitRegister = function (reloadOptionsOrganizationalUnit) {
        this.reloadOptionsOrganizationalUnit = reloadOptionsOrganizationalUnit;
    };
    ProjectUpdateComponent.prototype.onUpdateObligation = function (obligation) {
        var dialog = this.dialogService.open(obligation_settings_component_1.ObligationSettingsComponent, { data: { obligationId: obligation.id } });
        var sub = dialog.afterClosed.subscribe(function (updatedObligation) {
            if (updatedObligation) {
                Object.assign(obligation, updatedObligation);
            }
            sub.unsubscribe();
        });
    };
    ProjectUpdateComponent.prototype.onUpdateObligationFlow = function (obligation) {
        var dialog = this.dialogService.open(obligation_flow_settings_component_1.ObligationFlowSettingsComponent, { data: { obligationId: obligation.id } });
        var sub = dialog.afterClosed.subscribe(function () {
            sub.unsubscribe();
        });
    };
    ProjectUpdateComponent.prototype.onProjectSubjectUpdateRegister = function (projectSubjectUpdate) {
        this.projectSubjectUpdate = projectSubjectUpdate;
    };
    ProjectUpdateComponent.prototype.resetEdit = function (caseStatus, type) {
        this.setEditItemIndexByType(type, null);
        setTimeout(function () {
            if (type === 'layer') {
                if (caseStatus.userlayer.name.trim() === '') {
                    caseStatus.userlayer.name = caseStatus.commonName;
                }
            }
            else if (caseStatus.name.trim() === '') {
                caseStatus.name = caseStatus.commonName;
            }
        });
    };
    ProjectUpdateComponent.prototype.columnIsVisible = function (tableID, columnID) {
        var table = this.getTableByID(tableID);
        var hiddenColumns = table && table.columns;
        if (hiddenColumns) {
            return !hiddenColumns.includes(columnID);
        }
        else {
            return true;
        }
    };
    ProjectUpdateComponent.prototype.toggleHiddenColumn = function (tableID, columnID) {
        var table = this.getTableByID(tableID);
        if (!table) {
            table = { name: tableID, columns: [] };
            this.columnsVisibility.hidden.push(table);
        }
        var columnIndex = table.columns.indexOf(columnID);
        if (columnIndex > -1) {
            table.columns.splice(columnIndex, 1);
        }
        else {
            table.columns.push(columnID);
        }
    };
    ProjectUpdateComponent.prototype.selectTab = function (tab) {
        this.selectedTab = tab.id;
        if (tab.onClick) {
            tab.onClick();
        }
    };
    ProjectUpdateComponent.prototype.reloadUnits = function () {
        if (this.data.investor.customerIdText) {
            this.organizationalUnitsNotCancelled.filter.filters.idText = [this.data.investor.customerIdText];
            if (this.reloadOptionsOrganizationalUnit) {
                this.reloadOptionsOrganizationalUnit();
            }
        }
    };
    ProjectUpdateComponent.prototype.initFormData = function (data) {
        this.data = data;
        data.investor = data.investor || {};
        data.investor = ProjectUpdateComponent.getInvestorDefaults(data.investor);
        if (this.projectSubjectUpdate) {
            this.projectSubjectUpdate(data.investor.actingPersons);
        }
        this.reloadUnits();
        data.customer = data.customer && data.customer.id ? data.customer : null;
        data.customerWsdpAccount = data.customerWsdpAccount && data.customerWsdpAccount.id ? data.customerWsdpAccount : null;
        data.exportTypes.forEach(function (exportType) {
            delete exportType.userParams;
        });
        // series tab
        this.seriesParsed = [];
        this.seriesService.parseSeries(data.series, this.seriesParsed);
        this.loading = false;
    };
    ProjectUpdateComponent.prototype.runCadastreDelivery = function () {
        return this.restangularSymap
            .all("projects/" + this.projectId + "/cadastre/start-delivery/" + this.cadastreDeliveryDate)
            .customPOST();
    };
    ProjectUpdateComponent.prototype.runDownloadData = function () {
        return this.restangularSymap
            .all("projects/" + this.projectId + "/cadastre/download-data")
            .customPOST({}, '', { validityDate: this.downloadDataDate });
    };
    ProjectUpdateComponent.prototype.onCadastreDeliveryDateChange = function (date) {
        this.cadastreDeliveryDate = this.datePipe.transform(date, 'yyyy-MM-dd');
    };
    ProjectUpdateComponent.prototype.onDownloadDataDateChange = function (date) {
        this.downloadDataDate = this.datePipe.transform(date, 'yyyy-MM-dd');
    };
    ProjectUpdateComponent.prototype.onSaveNotificationConfigRegister = function (saveNotificationConfig) {
        this.saveNotificationConfig = saveNotificationConfig;
    };
    ProjectUpdateComponent.prototype.isValid = function () {
        if (!this.data.state) {
            return true;
        }
        return this.data['state' + string_utils_1.StringUtils.capitalize(this.data.state) + 'Date']
            && (this.data.state !== 'STARTED' || (this.data.readinessPermanentOccupations !== undefined &&
                this.data.readinessTemporaryUnderOccupations !== undefined &&
                this.data.readinessTemporaryOverOccupations !== undefined &&
                this.data.readinessTemporaryEasements !== undefined));
    };
    ProjectUpdateComponent.prototype.update = function () {
        var _this = this;
        this.seriesService.sectionsToSeries(this.seriesParsed, this.data.series);
        this.settingsSaving = false;
        var tabIndex = -1;
        var responseData;
        var saveNext = function () {
            while (++tabIndex < _this.tabs.length) {
                if (_this.tabs[tabIndex].save) {
                    var promise = _this.tabs[tabIndex].save();
                    if (promise) {
                        return promise.then(function (data) {
                            if (tabIndex === 0) {
                                responseData = data;
                            }
                            return saveNext();
                        });
                    }
                }
            }
            return Promise.resolve();
        };
        // save all tabs
        return saveNext().then(function () {
            if (responseData) {
                _this.initFormData(responseData);
                var project_1 = {};
                [
                    'constructionObjectRealizationNotifyDaysBefore',
                    'easementDistributionSystemParts',
                    'easementPriceByShare',
                    'mapLat',
                    'mapLon',
                    'mapZoom',
                    'name',
                    'obligationTypes',
                    'series',
                    'isprofond',
                    'unit',
                    'name',
                    'infrastructure',
                    'region',
                    'phase',
                    'contractName',
                    'showExpertOpinionCoefficient',
                    'statisticsTypes',
                    'templateTypes',
                    'updateCadastre',
                    'investor',
                    'manager',
                    'designer',
                    'propertyActivityProcessor',
                    'landTakeDocumentationAuthor',
                    'projectStartDate',
                    'state',
                    'readinessPermanentOccupations',
                    'readinessTemporaryUnderOccupations',
                    'readinessTemporaryOverOccupations',
                    'readinessTemporaryEasements',
                ].forEach(function (attrName) {
                    project_1[attrName] = responseData[attrName];
                });
                Object.assign(_this.project, project_1);
                Object.assign(_.find(_this.authService.getActualProjects(), { id: _this.projectId }), project_1);
                _this.localStorageService.set('auth-projects', _this.authService.getActualProjects());
                _this.localStorageService.set('auth-project', _this.authService.getActualProject());
                if (_this.goToPrevState && _this.prevState) {
                    _this.stateService.go(_this.prevState);
                }
            }
        });
    };
    ProjectUpdateComponent.prototype.getTableByID = function (tableID) {
        return this.columnsVisibility
            && this.columnsVisibility.hidden
            && this.columnsVisibility.hidden.find(function (tableCfg) { return tableCfg.name === tableID; });
    };
    ProjectUpdateComponent.prototype.saveSettings = function () {
        if (!this.settingsSaving) {
            this.settingsSaving = true;
            return this.settings && this.settings.put();
        }
    };
    ProjectUpdateComponent.prototype.setEditItemIndexByType = function (type, val) {
        switch (type) {
            case 'nomenclature':
                this.nomenclatureEdited = val;
                break;
            case 'CaseStatuse':
                this.dataCaseStatuseEdited = val;
                break;
        }
    };
    ProjectUpdateComponent.prototype.getTabs = function () {
        var _this = this;
        var tabs = [
            {
                id: 'G',
                name: 'Základní',
                save: function () {
                    return _this.data.put(ProjectUpdateComponent.PROJECT_FILTER);
                }
            },
            {
                id: 'KN',
                name: 'KN data, LPIS'
            },
            {
                id: 'WSDP',
                name: 'KN data, WSDP'
            },
            {
                id: 'ZE',
                name: 'Data ZE'
            },
            {
                id: 'MAP',
                name: 'Mapa',
                save: function () {
                    if (typeof _this.mapSettingsOnSaveCallback === 'function') {
                        return _this.mapSettingsOnSaveCallback();
                    }
                },
            },
            {
                id: 'I',
                name: 'Organizace'
            },
            {
                id: 'N',
                name: 'Číselné řady'
            },
            {
                id: 'O',
                name: 'Případy'
            },
            {
                id: 'CS',
                name: 'Stavy případů',
                onClick: function () {
                    if (!_this.dataCaseStatuses) {
                        _this.dataCaseStatusesLoading = true;
                        _this.caseStatusService.loadStatuses().then(function (data) {
                            _this.dataCaseStatuses = data;
                            _this.dataCaseStatusesLoading = false;
                        });
                        _this.dataCaseStatuseEdited = null;
                    }
                },
                save: function () {
                    return _this.caseStatusService.saveStatuses(_this.dataCaseStatuses);
                }
            },
            {
                id: 'T',
                name: 'Šablony',
                onClick: function () {
                    if (!_this.dataTemplateTypes) {
                        _this.dataTemplateTypes = _this.listService.createList('templates/types-available', {});
                        _this.listService.fetchResult(_this.dataTemplateTypes);
                        _this.checklistTemplates = _this.checklistService.get(_this.data.templateTypes);
                        _this.checklistTemplates.identity = function (item) { return ({ key: item.key }); };
                    }
                }
            },
            {
                id: 'E',
                name: 'Exporty',
                onClick: function () {
                    if (!_this.dataExportTypes) {
                        _this.dataExportTypes = _this.listService.createList('exports/types-available', {});
                        _this.listService.fetchResult(_this.dataExportTypes).then(function () {
                            _this.dataExportTypes.list.forEach(function (exportType) {
                                delete exportType.userParams;
                            });
                        });
                        _this.checklistExportTypes = _this.checklistService.get(_this.data.exportTypes);
                        _this.checklistExportTypes.identity = function (item) { return ({ key: item.key }); };
                    }
                }
            },
            {
                id: 'S',
                name: 'Statistiky',
                onClick: function () {
                    if (!_this.statisticsData) {
                        _this.statisticsCallbackRegister = function ($event) {
                            _this.reloadStatistics = $event.reload;
                        };
                        _this.statisticsData = _this.listService.createList('statistics', { sortOrder: { sortBy: 'statisticsOrder' }, limit: null });
                        _this.listService.fetchResult(_this.statisticsData);
                        _this.statisticsData.promise.then(function () {
                            _this.statistics = _this.statisticsData.list;
                        });
                    }
                },
                save: function () {
                    if (_this.statistics) {
                        return _this.restangular.all('statistics').customPUT(_this.statistics).then(function (newData) {
                            _this.statistics = _this.restangular.stripRestangular(newData);
                            _this.reloadStatistics(_this.statistics);
                            _this.statisticsService.removeLoadedStatistics();
                        });
                    }
                }
            },
            {
                id: 'CL',
                name: 'Sloupce',
                onClick: function () {
                    if (!_this.columnsVisibilityData) {
                        _this.columnsVisibilityData = _this.settingsService.loadSettings(_this.projectId).then(function (settingsData) {
                            _this.settings = _this.settings || settingsData.clone();
                            _this.columnsVisibility = _this.settings.columnSettings;
                            if (!_this.columnsVisibility.hidden) {
                                _this.columnsVisibility.hidden = [];
                            }
                        });
                    }
                    _this.columnsRestrictionsTable = _this.tableService.getTables();
                },
                save: function () {
                    if (_this.columnsVisibility) {
                        return _this.saveSettings();
                    }
                }
            },
            {
                id: 'NL',
                name: 'Názvosloví',
                onClick: function () {
                    if (!_this.nomenclatureData) {
                        _this.nomenclatureData = _this.settingsService.loadSettings(_this.projectId).then(function (settingsData) {
                            var _a;
                            _this.settings = _this.settings || settingsData.clone();
                            // fallback if no nomenclature is filled
                            if (_this.settings.nomenclature.length === 0) {
                                _this.settings.nomenclature = Array
                                    .from(_this.wordService.getVocabulary())
                                    .filter(function (w) { return w.hasOwnProperty('editable') ? !w.editable === false : true; });
                            }
                            var missingWords = _this.wordService.getDifferenceWords(_this.settings.nomenclature, _this.wordService.getVocabulary());
                            (_a = _this.settings.nomenclature).push.apply(_a, __spread(missingWords.filter((function (mw) { return mw.editable !== false; }))));
                            _this.nomenclature = _this.settings.nomenclature;
                        });
                        _this.nomenclatureEdited = null;
                    }
                },
                save: function () {
                    if (_this.nomenclature) {
                        return _this.saveSettings();
                    }
                }
            },
            {
                id: 'EM',
                name: 'Email',
                onClick: function () {
                    if (!_this.emailData) {
                        _this.emailData = _this.settingsService.loadSettings(_this.projectId).then(function (settingsData) {
                            _this.settings = _this.settings || settingsData.clone();
                            if (!_this.settings.email) {
                                _this.settings.email = { welcomeEmail: {} };
                            }
                            _this.email = _this.settings.email;
                        });
                    }
                },
                save: function () {
                    if (_this.saveNotificationConfig) {
                        _this.saveNotificationConfig();
                    }
                    if (_this.email) {
                        return _this.saveSettings();
                    }
                }
            },
            {
                id: 'DASHBOARD',
                name: 'Dashboard',
                save: function () {
                    if (typeof _this.dashboardSettingsOnSaveCallback === 'function') {
                        return _this.dashboardSettingsOnSaveCallback();
                    }
                }
            },
        ];
        return tabs;
    };
    ProjectUpdateComponent.getInvestorDefaults = function (investor) {
        investor.useCaseNumberAsReferenceNumber = investor.useCaseNumberAsReferenceNumber || false;
        investor.useAccountNumberForProposal = investor.useAccountNumberForProposal || false;
        investor.deliveryUse = investor.deliveryUse || false;
        investor.discriminator = investor.discriminator || 'I';
        investor.subjectType = investor.type || investor.subjectType || 'OPO';
        investor.actingPersons = investor.actingPersons || [];
        investor.showPublicationNote = investor.showPublicationNote || false;
        investor.showNoPublicationNote = investor.showNoPublicationNote || false;
        investor.publicationNoteTemplate = investor.publicationNoteTemplate === undefined
            ? 'Tato smlouva byla uveřejněna v registru smluv pod id [ID]'
            : investor.publicationNoteTemplate;
        investor.noPublicationNoteTemplate = investor.noPublicationNoteTemplate === undefined
            ? 'Tato smlouva nebyla uveřejněna v registru smluv. Výjimka: [VYJIMKA]'
            : investor.noPublicationNoteTemplate;
        delete investor.abbreviation;
        delete investor.idText;
        delete investor.type;
        delete investor.cuzkWsdpUsername;
        delete investor.rootCustomer;
        delete investor.businessRegisterRegionalCourt;
        return investor;
    };
    ProjectUpdateComponent.prototype.compareCustomers = function (item1, item2) {
        return (item1.customerIdText || item1.idText) === (item2.customerIdText || item2.idText);
    };
    ProjectUpdateComponent.PROJECT_FILTER = { loadCollections: [
            'contract',
            'customerWsdpAccount',
            'customer', 'series',
            'occupationTypes',
            'obligationTypes',
            'statisticsTypes',
            'exportTypes',
            'templateTypes',
            'investor',
            'investor.actingPersons',
            'investor.cadastreAgent',
            'investor.cadastreAgent.actingPersons',
            'manager',
            'designer',
            'propertyActivityProcessor',
            'landTakeDocumentationAuthor',
        ] };
    return ProjectUpdateComponent;
}());
exports.ProjectUpdateComponent = ProjectUpdateComponent;
