"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var map_service_1 = require("@app/map/services/map.service");
var intersection_service_1 = require("@app/dimap/map/services/intersection.service");
var angular_1 = require("@uirouter/angular");
var L = require("leaflet");
var $ = require("jquery");
var MapModuleIntersectionComponent = /** @class */ (function () {
    function MapModuleIntersectionComponent(mapService, stateService, intersectionService) {
        this.mapService = mapService;
        this.stateService = stateService;
        this.intersectionService = intersectionService;
        this.intersectionOptions = [
            { id: 'polyline', value: 'Linie' },
            { id: 'polygon', value: 'Plochy' }
        ];
        this.intersectionOptionsSelections = 'polyline';
        this.onDrawCreated = this.onDrawCreated.bind(this);
        this.onPopupClose = this.onPopupClose.bind(this);
    }
    MapModuleIntersectionComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.mapService.getMap(this.mapId).then(function (map) {
            _this.map = map;
            _this.onChangedIntersectionType(_this.intersectionOptionsSelections);
        });
    };
    MapModuleIntersectionComponent.prototype.ngOnDestroy = function () {
        this.stopIntersection();
        this.map = null;
    };
    MapModuleIntersectionComponent.prototype.onChangedIntersectionType = function (newVal) {
        this.startDrawIntersection(newVal);
    };
    MapModuleIntersectionComponent.prototype.onPopupClose = function () {
        this.onChangedIntersectionType(this.intersectionOptionsSelections);
    };
    MapModuleIntersectionComponent.prototype.onDrawCreated = function (evt) {
        var _this = this;
        this.layer = evt.layer;
        var latLngs = this.layer.getLatLngs();
        var lastLatLngs;
        if (this.layer instanceof L.Polygon) {
            lastLatLngs = latLngs[0][latLngs.length - 1];
        }
        else {
            lastLatLngs = latLngs[latLngs.length - 1];
        }
        //show popup
        var popupContent = $('<p>');
        $('<a>').attr('class', 'button').on('click', function (evt) {
            //backend expecting featurecollection
            var geoJson = {
                "type": "FeatureCollection",
                "features": [
                    _this.layer.toGeoJSON()
                ]
            };
            //start loading
            var el = evt.target;
            el.classList.add('content-loading');
            _this.intersectionService.intersectGeometry(geoJson).then(function (requestID) {
                //stop loading
                el.classList.remove('content-loading');
                _this.stateService.go("dimap.project.propertyStatus", { requestid: requestID });
            }, function () {
                //stop loading
                el.classList.remove('content-loading');
                //TODO show error
            });
        }).html('ZOBRAZIT<div class="loading-spinner"></div>').appendTo(popupContent);
        $(popupContent).append(' V MAJETKOPRÁVNÍM PŘEHLEDU');
        this.intersectPopup = L.popup()
            .setLatLng(lastLatLngs)
            .setContent(popupContent[0])
            .openOn(this.map);
        this.map.once('popupclose', this.onPopupClose);
        this.map.addLayer(this.layer);
    };
    MapModuleIntersectionComponent.prototype.stopIntersection = function () {
        if (this.drawKN) {
            this.drawKN.disable();
            this.map.off(L.Draw.Event.CREATED);
            this.drawKN = null;
            if (this.layer) {
                this.map.removeLayer(this.layer);
            }
            //check if popup exist and is visible in map
            if (this.intersectPopup && this.intersectPopup._map) {
                this.map.off('popupclose', this.onPopupClose);
                this.map.closePopup(this.intersectPopup);
                this.intersectPopup = null;
            }
        }
        L.DomEvent.off(this.map._container, 'keyup', this._cancelDrawing, this);
    };
    ;
    MapModuleIntersectionComponent.prototype.startDrawIntersection = function (type) {
        if (this.drawKN) {
            this.stopIntersection();
        }
        switch (type) {
            case 'polygon':
                this.setDrawSelectPolygonLocalization();
                this.drawKN = new L.Draw.Polygon(this.map, { showLength: false });
                break;
            case 'polyline':
                this.setDrawSelectPolylineLocalization();
                this.drawKN = new L.Draw.Polyline(this.map, { showArea: false, showLength: false });
                break;
        }
        this.drawKN.options.icon = this.getVertexIcon();
        this.drawKN.enable();
        //open popup on end draw
        this.map.once(L.Draw.Event.CREATED, this.onDrawCreated);
        L.DomEvent.on(this.map._container, 'keyup', this._cancelDrawing, this);
    };
    MapModuleIntersectionComponent.prototype._cancelDrawing = function (e) {
        if (e.keyCode === 27) {
            this.onChangedIntersectionType(this.intersectionOptionsSelections);
        }
    };
    MapModuleIntersectionComponent.prototype.setDrawSelectPolylineLocalization = function () {
        L.drawLocal.draw.handlers.polyline.tooltip.start = "Vyberte zájmové území linií.";
        L.drawLocal.draw.handlers.polyline.tooltip.cont = "Klikněte pro pokračování.";
        L.drawLocal.draw.handlers.polyline.tooltip.end = "Kliknutím na poslední bod ukončete kreslení.";
    };
    MapModuleIntersectionComponent.prototype.setDrawSelectPolygonLocalization = function () {
        L.drawLocal.draw.handlers.polygon.tooltip.start = "Vyberte zájmové území plochou.";
        L.drawLocal.draw.handlers.polygon.tooltip.cont = "Klikněte pro pokračování.";
        L.drawLocal.draw.handlers.polygon.tooltip.end = "Kliknutím na první bod ukončete kreslení.";
    };
    MapModuleIntersectionComponent.prototype.getVertexIcon = function () {
        return new L.DivIcon({
            iconSize: new L.Point(8, 8),
            className: "leaflet-div-icon leaflet-editing-icon leaflet-touch-icon"
        });
    };
    return MapModuleIntersectionComponent;
}());
exports.MapModuleIntersectionComponent = MapModuleIntersectionComponent;
