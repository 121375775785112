"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var common_address_model_1 = require("@app/ps/models/common-address.model");
var ActingPersonModel = /** @class */ (function () {
    function ActingPersonModel() {
        this.subjectType = 'OFO';
        this.cancelled = false;
        this.roleCadastre = false;
        this.roleContract = false;
        // nested address
        this.address = new common_address_model_1.CommonAddressModel();
    }
    ActingPersonModel.fromFlatObject = function (actingPerson) {
        actingPerson.address = {
            street: actingPerson && actingPerson.street,
            houseNumber: actingPerson && actingPerson.houseNumber,
            commonNumber: actingPerson && actingPerson.streetNumber,
            neighborhood: actingPerson && actingPerson.neighbourhood,
            cityDistrict: actingPerson && actingPerson.district,
            quarter: actingPerson && actingPerson.quarter,
            city: actingPerson && actingPerson.municipality,
            county: actingPerson && actingPerson.county,
            postalCode: (actingPerson.postCode ? String(actingPerson.postCode) : ''),
            country: actingPerson && actingPerson.country,
            countryCode: actingPerson && actingPerson.countryCode,
            countryShortCode: actingPerson && actingPerson.countryCodeAl2
        };
        return actingPerson;
    };
    ActingPersonModel.toFlatObject = function (actingPerson) {
        actingPerson.street = actingPerson.address.street;
        actingPerson.houseNumber = actingPerson.address.houseNumber;
        actingPerson.streetNumber = actingPerson.address.commonNumber;
        actingPerson.neighbourhood = actingPerson.address.neighborhood;
        actingPerson.district = actingPerson.address.cityDistrict;
        actingPerson.quarter = actingPerson.address.quarter;
        actingPerson.municipality = actingPerson.address.city;
        actingPerson.county = actingPerson.address.county;
        actingPerson.postCode = Number(actingPerson.address.postalCode);
        actingPerson.country = actingPerson.address.country;
        actingPerson.countryCode = actingPerson.address.countryCode;
        actingPerson.countryCodeAl2 = actingPerson.address.countryShortCode;
        var ret = __assign({}, actingPerson);
        delete ret.address;
        return ret;
    };
    ActingPersonModel.hasAtLeastOneDefinedProperty = function (representative) {
        return Object.keys(representative)
            .filter(function (k) { return !['cancelled', 'roleCadastre', 'roleContract', 'address', 'subjectType'].includes(k); })
            .some(function (k) { return representative[k]; });
    };
    return ActingPersonModel;
}());
exports.ActingPersonModel = ActingPersonModel;
