"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createConstructionObject = function () {
    return {
        name: '',
        cislo: '',
        description: '',
        urad: '',
        administratorType: 'I',
        series: []
    };
};
