"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./cell-parcels.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/common");
var i3 = require("../../directives/tooltip/tooltip.directive");
var i4 = require("./cell-parcels.component");
var styles_CellParcelsComponent = [i0.styles];
var RenderType_CellParcelsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CellParcelsComponent, data: {} });
exports.RenderType_CellParcelsComponent = RenderType_CellParcelsComponent;
function View_CellParcelsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "fill-content flexbox-container flex-centered p-10"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "icon-count flexbox-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "fa fa-file-powerpoint-o"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "number ml-4"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.countParcels; _ck(_v, 4, 0, currVal_0); }); }
function View_CellParcelsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_CellParcelsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CellParcelsComponent_3)), i1.ɵdid(2, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.countParcels; _ck(_v, 2, 0, currVal_0); }, null); }
function View_CellParcelsComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_CellParcelsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 3, "div", [["class", "tooltip-hover-area fill-content"]], null, [[null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("mouseenter" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).mouseEnter() !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).closeTooltip() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 81920, null, 0, i3.TooltipDirective, [i1.ElementRef, "WindowDimensionService", i1.Renderer2, i1.ComponentFactoryResolver, i1.ViewContainerRef, i1.Injector], { content: [0, "content"], data: [1, "data"], activeContent: [2, "activeContent"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CellParcelsComponent_5)), i1.ɵdid(3, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tooltipComponent; var currVal_1 = _co.tooltipData; var currVal_2 = true; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = i1.ɵnov(_v.parent, 0); _ck(_v, 3, 0, currVal_3); }, null); }
function View_CellParcelsComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(0, [["parcelsCell", 2]], null, 0, null, View_CellParcelsComponent_1)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CellParcelsComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CellParcelsComponent_4)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.tooltipComponent || !_co.tooltipData); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.tooltipComponent && _co.tooltipData); _ck(_v, 4, 0, currVal_1); }, null); }
exports.View_CellParcelsComponent_0 = View_CellParcelsComponent_0;
function View_CellParcelsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "cell-parcels", [], null, null, null, View_CellParcelsComponent_0, RenderType_CellParcelsComponent)), i1.ɵdid(1, 49152, null, 0, i4.CellParcelsComponent, [], null, null)], null, null); }
exports.View_CellParcelsComponent_Host_0 = View_CellParcelsComponent_Host_0;
var CellParcelsComponentNgFactory = i1.ɵccf("cell-parcels", i4.CellParcelsComponent, View_CellParcelsComponent_Host_0, { tooltipData: "tooltipData", tooltipType: "tooltipType", countParcels: "countParcels" }, {}, []);
exports.CellParcelsComponentNgFactory = CellParcelsComponentNgFactory;
