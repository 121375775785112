"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var _ = require("lodash");
var ParcelUtils = /** @class */ (function () {
    function ParcelUtils() {
    }
    ParcelUtils.getParcelSortNumber = function (parcisKmen, parcisPod, parcisDil) {
        var podStrNumber = parcisPod ? parcisPod.toString().padStart(5, '0') : '00000';
        var dilStrNumber = parcisDil ? parcisDil.toString().padStart(5, '0') : '00000';
        var sortStrNumber = parcisKmen.toString() + podStrNumber + dilStrNumber;
        return parseInt(sortStrNumber);
    };
    ParcelUtils.getSortFunctionByParcelsNumbers = function (parcisKmenPath, parcisPodPath, parcisDilPath) {
        return function (item) {
            // convert string path into data
            var parcisKmen = parcisKmenPath.split('.').reduce(function (o, i) { return o[i]; }, item);
            var parcisPod = parcisPodPath.split('.').reduce(function (o, i) { return o[i]; }, item);
            var parcisDil = parcisDilPath.split('.').reduce(function (o, i) { return o[i]; }, item);
            return ParcelUtils.getParcelSortNumber(parcisKmen, parcisPod, parcisDil);
        };
    };
    ParcelUtils.sortDataByColumnStatic = function (data, dataPath, sortDir) {
        return _.orderBy(data, dataPath, sortDir);
    };
    return ParcelUtils;
}());
exports.ParcelUtils = ParcelUtils;
