"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var fileupload_component_1 = require("@app/common/components/fileupload/fileupload.component");
var FileUtils = /** @class */ (function () {
    function FileUtils() {
    }
    FileUtils.hasFilesSameTitles = function (files) {
        if (files.length === 0) {
            return true;
        }
        return files.every(function (f) { return f.title === files[0].title; });
    };
    FileUtils.fileToUpload = function (file, validator) {
        var returnFile = new fileupload_component_1.UploadFileExtended(file);
        returnFile.extension = FileUtils.getFileExtension(file);
        returnFile.title = FileUtils.getFileTitle(file);
        returnFile.status = '';
        returnFile.valid = validator(returnFile);
        return returnFile;
    };
    FileUtils.getFileExtension = function (file) {
        var lastDotIndex = FileUtils.getLastDotIndex(file);
        var extension = null;
        if (lastDotIndex !== -1) {
            extension = file.name.substr(lastDotIndex + 1);
        }
        return extension;
    };
    FileUtils.getFileTitle = function (file) {
        var lastDotIndex = FileUtils.getLastDotIndex(file);
        var title = file.name;
        if (lastDotIndex !== -1) {
            title = file.name.substr(0, lastDotIndex);
        }
        return title;
    };
    FileUtils.getLastDotIndex = function (file) {
        return file.name.lastIndexOf('.');
    };
    return FileUtils;
}());
exports.FileUtils = FileUtils;
