"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var LandingVfzeComponent = /** @class */ (function () {
    function LandingVfzeComponent() {
        this.tabs = [
            {
                name: 'Podmínky použití',
                id: 'terms-of-use',
                href: 'settings.modules.vfze',
                urlParams: { tab: 'terms-of-use' },
            },
        ];
        this.isActiveTab = this.isActiveTab.bind(this);
    }
    LandingVfzeComponent.prototype.ngOnInit = function () {
        this.isActiveTab = this.isActiveTab.bind(this);
    };
    LandingVfzeComponent.prototype.isActiveTab = function (tabId) {
        return tabId === this.activeTab;
    };
    return LandingVfzeComponent;
}());
exports.LandingVfzeComponent = LandingVfzeComponent;
