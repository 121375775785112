
var common = angular.module('common');

/**
 * Service extending ModalService
 */
common.service('AlertService', ['ModalService',function (ModalService) {
    return function (config) {
        if (config === undefined) {
            config = {};
        }

        var configModal = {
            callback: config.callback,
            className: 'adjusted-dialog',
            template: '<h1>' + (config.title || 'Upozornění') + '</h1>'
                    + '<p class="font-size-16 text-center">' + (config.message || 'Akci nebylo možné provést!') + '</p>'
                    + '<div class="actions"><input type="button" value="OK" ng-click="confirm();"/></div>',
            scope: config.scope,
            controller: ['$scope',function ($scope) {
                $scope.confirm = function() {
                    $scope.closeModal();
                };
            }]
        };
        return new ModalService(configModal);
    };
}]);
