"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var owner_list_component_1 = require("./components/owner-list/owner-list.component");
var owner_detail_component_1 = require("./components/owner-detail/owner-detail.component");
var problematic_note_component_1 = require("./components/problematic-note/problematic-note.component");
var dialog_service_1 = require("@app/dialog/dialog.service");
var callback_service_1 = require("@app/ps/services/callback.service");
var acting_person_model_1 = require("@app/ps/models/acting-person.model");
function resolveOwnerFunction($stateParams, Restangular) {
    var ownerId = $stateParams.ownerId;
    var collections = {
        actingPersons: {
            load: true
        },
    };
    return Restangular.all("subjects/" + ownerId).post(collections).then(function (data) {
        data.actingPersons = data.actingPersons && data.actingPersons.map(acting_person_model_1.ActingPersonModel.fromFlatObject);
        return data;
    });
}
exports.resolveOwnerFunction = resolveOwnerFunction;
function resolveCallbacksFunction(callbackService) { return callbackService.createCallback(); }
exports.resolveCallbacksFunction = resolveCallbacksFunction;
function resolveNoteFunction(Restangular, owner) {
    var filters = {
        attributes: {},
        filter: {
            offset: 0,
            limit: 1,
            sortOrder: [{
                    sortBy: 'timestamp',
                    direction: 'desc'
                }],
            filters: {
                subjectId: {
                    values: [owner.id]
                },
                systemType: false,
                noteTypeKey: 'SubjectProblematic',
            }
        }
    };
    return Restangular.all('notes').post(filters);
}
exports.resolveNoteFunction = resolveNoteFunction;
function resolvePreviousTabFunction($state) { return $state.params.tab; }
exports.resolvePreviousTabFunction = resolvePreviousTabFunction;
function problematicOwnerDialogFunction($transition$, $state, previousTab, owner, note, callbacks) {
    var dialogService = $transition$.injector().get(dialog_service_1.DialogService);
    var dialog = dialogService.open(problematic_note_component_1.ProblematicNoteComponent, {
        data: {
            owner: owner,
            note: note,
        },
        callbacks: callbacks,
    });
    var sub = dialog.afterClosed.subscribe(function (res) {
        $state.go('symap.project.owners.detail', { tab: previousTab });
        sub.unsubscribe();
    });
}
exports.problematicOwnerDialogFunction = problematicOwnerDialogFunction;
exports.ownerListState = {
    name: 'symap.project.owners',
    url: '/owners?page&sef',
    data: {
        title: 'Vlastníci',
    },
    views: {
        'content@symap.project': {
            component: owner_list_component_1.OwnerListComponent,
        },
    },
    params: {
        page: {
            dynamic: true,
            type: 'int',
            inherit: false,
        },
        sef: {
            type: 'string',
            inherit: false,
            array: true
        },
    },
};
exports.ownerDetailState = {
    name: 'symap.project.owners.detail',
    url: '/:ownerId/:tab',
    data: {
        title: 'Vlastník',
    },
    views: {
        'content@symap.project': {
            component: owner_detail_component_1.OwnerDetailComponent,
        }
    },
    resolve: {
        owner: ['$stateParams', 'Restangular', resolveOwnerFunction],
        callbacks: [callback_service_1.CallbackService, resolveCallbacksFunction],
    },
    params: {
        ownerId: {
            type: 'int',
            value: undefined
        },
        tab: {
            type: 'string',
            value: undefined,
            dynamic: true,
            inherit: false,
            squash: true,
        }
    },
};
exports.problematicOwnerNoteState = {
    name: 'symap.project.owners.detail.problematic',
    url: '/problematic',
    data: {
        title: 'Problematický vlastník',
    },
    resolve: {
        note: ['Restangular', 'owner', resolveNoteFunction],
        previousTab: ['$state', resolvePreviousTabFunction],
    },
    onEnter: [
        '$transition$',
        '$state',
        'previousTab',
        'owner',
        'note',
        'callbacks',
        problematicOwnerDialogFunction
    ],
};
