"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var tooltip_ownerships_component_1 = require("../tooltip-ownerships/tooltip-ownerships.component");
var cell_abstract_component_1 = require("../cell-abstract/cell-abstract.component");
var tooltip_dimap_ownerships_component_1 = require("@app/common/components/tooltip-dimap-ownerships/tooltip-dimap-ownerships.component");
var CellOwnershipComponent = /** @class */ (function (_super) {
    __extends(CellOwnershipComponent, _super);
    function CellOwnershipComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.limit = 3;
        // props
        _this.TOOLTIP_MAPPING = {
            'ownership-list': tooltip_ownerships_component_1.TooltipOwnershipsComponent,
            'ownership-dimap-list': tooltip_dimap_ownerships_component_1.TooltipDimapOwnershipsComponent,
        };
        return _this;
    }
    CellOwnershipComponent.prototype.ngOnInit = function () {
        if (!this.ownerships) {
            return;
        }
        this.filteredOwnerships = this.ownerships
            .map(CellOwnershipComponent.repairStructure)
            .filter(CellOwnershipComponent.isNotCzechRepublicDelegatedOwnership)
            .sort(CellOwnershipComponent.sortByCadastreOwnershipCode);
    };
    /**
     * Repair structure if ownership is nested... For example cases endpoint returns nested structure.
     * @param ownership
     */
    CellOwnershipComponent.repairStructure = function (ownership) {
        return (ownership.ownership ? ownership.ownership : ownership);
    };
    /**
     * Proper ownership codes are represented with smaller numbers
     * than delegated ownership codes.
     */
    CellOwnershipComponent.sortByCadastreOwnershipCode = function (a, b) {
        return b.typravKod - a.typravKod;
    };
    /**
     * The Czech Republic is not a subject to deal with
     * when its ownership is delegated to one of the state organizations.
     */
    CellOwnershipComponent.isNotCzechRepublicDelegatedOwnership = function (ownership) {
        var CADASTRE_OWNERSHIP_CODE = 30;
        var isOwner = ownership.typravKod === CADASTRE_OWNERSHIP_CODE;
        var isSingleSubject = ownership.subjects.length === 1;
        var isCzechRepublic = ownership.subjects[0].nazev === 'Česká republika';
        return !(isOwner && isSingleSubject && isCzechRepublic);
    };
    return CellOwnershipComponent;
}(cell_abstract_component_1.CellAbstractComponent));
exports.CellOwnershipComponent = CellOwnershipComponent;
