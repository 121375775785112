"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var auth_service_1 = require("@app/common/services/auth.service");
var multi_scene_cloud_service_1 = require("@app/dimap/map/services/multi-scene-cloud.service");
var MultiSceneCloudComponent = /** @class */ (function () {
    function MultiSceneCloudComponent(authService, multiSceneCloudService, ngZone, MULTI_SCENE_CLOUD) {
        this.authService = authService;
        this.multiSceneCloudService = multiSceneCloudService;
        this.ngZone = ngZone;
        this.MULTI_SCENE_CLOUD = MULTI_SCENE_CLOUD;
        this.message = 'Probíhá inicializace okna panoramatického snímku';
        this.onLicenseValid = this.onLicenseValid.bind(this);
        this.showPicture = this.showPicture.bind(this);
        this.onCameraChange = this.onCameraChange.bind(this);
    }
    MultiSceneCloudComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.containerId = 'multi-scene-cloud';
                this.multiSceneCloudKey = this.MULTI_SCENE_CLOUD.key;
                this.projectUrl = this.MULTI_SCENE_CLOUD.projects[this.authService.getActualProject().key];
                this.viewer = undefined;
                if (this.projectUrl) {
                    this.initialize();
                    this.message = null;
                }
                else {
                    this.message = 'Není nastavena URL adresa snímků daného projektu!';
                }
                if (this.pitch) {
                    this.multiSceneCloudService.pitch = this.pitch;
                }
                if (this.yaw) {
                    this.multiSceneCloudService.yaw = this.yaw;
                }
                return [2 /*return*/];
            });
        });
    };
    MultiSceneCloudComponent.prototype.initialize = function () {
        var _this = this;
        this.ngZone.runOutsideAngular(function () {
            _this.viewer = MultiSceneCore.Api.createViewer();
            MultiSceneCore.Api.initLicense(_this.viewer, _this.multiSceneCloudKey, _this.onLicenseValid);
            MultiSceneCore.Api.addEventListener(_this.viewer, 'onready', _this.showPicture);
            MultiSceneCore.Api.addEventListener(_this.viewer, 'camerachange', _this.onCameraChange);
        });
    };
    MultiSceneCloudComponent.prototype.onCameraChange = function (e) {
        this.multiSceneCloudService.pitch = e.cameraRotation.pitch;
        this.multiSceneCloudService.yaw = e.cameraRotation.yaw;
    };
    MultiSceneCloudComponent.prototype.onLicenseValid = function () {
        var _this = this;
        this.ngZone.runOutsideAngular(function () {
            MultiSceneCore.Api.initProject(_this.viewer, _this.containerId, _this.projectUrl, { camera: false });
        });
    };
    MultiSceneCloudComponent.prototype.onResize = function () {
        var _this = this;
        this.ngZone.runOutsideAngular(function () {
            MultiSceneCore.Api.updateViewerSize(_this.viewer);
        });
    };
    MultiSceneCloudComponent.prototype.showPicture = function () {
        var _this = this;
        if (!this.viewer) {
            return;
        }
        this.ngZone.runOutsideAngular(function () {
            MultiSceneCore.Api.showPositionOnTrajectory(_this.viewer, _this.trjName, _this.photoName, _this.x, _this.y, _this.z, _this.multiSceneCloudService.yaw, _this.multiSceneCloudService.pitch);
            if (_this.layers) {
                _this.layers.forEach(function (layerId) {
                    MultiSceneCore.Api.setDataLayerVisibility(_this.viewer, layerId, true);
                });
            }
        });
    };
    return MultiSceneCloudComponent;
}());
exports.MultiSceneCloudComponent = MultiSceneCloudComponent;
