"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var core_2 = require("@uirouter/core");
var CustomerDetailComponent = /** @class */ (function () {
    function CustomerDetailComponent(stateService) {
        this.stateService = stateService;
    }
    CustomerDetailComponent.prototype.ngOnInit = function () {
        this.showContractForm = this.showContractForm.bind(this);
        this.showCustomerForm = this.showCustomerForm.bind(this);
        this.pageTitle = this.stateService && this.stateService.current.data.title;
    };
    CustomerDetailComponent.prototype.showContractForm = function () {
        this.stateService.go('.contract.new');
    };
    CustomerDetailComponent.prototype.showCustomerForm = function () {
        this.stateService.go('.edit');
    };
    return CustomerDetailComponent;
}());
exports.CustomerDetailComponent = CustomerDetailComponent;
