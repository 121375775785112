"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var i0 = require("@angular/core");
/**
 * Display name of price type
 */
var PriceTypeNamePipe = /** @class */ (function () {
    function PriceTypeNamePipe() {
    }
    PriceTypeNamePipe.prototype.transform = function (priceType) {
        if (priceType === 'P') {
            return 'Předběžný posudek';
        }
        if (priceType === 'E') {
            return 'Znalecký posudek';
        }
        if (priceType === 'O') {
            return 'Jiné určení';
        }
        return '';
    };
    PriceTypeNamePipe.ngInjectableDef = i0.defineInjectable({ factory: function PriceTypeNamePipe_Factory() { return new PriceTypeNamePipe(); }, token: PriceTypeNamePipe, providedIn: "root" });
    return PriceTypeNamePipe;
}());
exports.PriceTypeNamePipe = PriceTypeNamePipe;
