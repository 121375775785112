"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
            t[p[i]] = s[p[i]];
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var angular_1 = require("@uirouter/angular");
var common_address_model_1 = require("@app/ps/models/common-address.model");
var customer_name_pipe_1 = require("@app/common/pipes/customer-name.pipe");
var dialog_service_1 = require("@app/dialog/dialog.service");
var ares_form_component_1 = require("@app/ps/components/ares-form/ares-form.component");
var list_service_1 = require("@app/common/services/list.service");
var _ = require("lodash");
var CustomerFormComponent = /** @class */ (function () {
    function CustomerFormComponent(dialogService, listService, stateService, restangular, customerNamePipe) {
        this.dialogService = dialogService;
        this.listService = listService;
        this.stateService = stateService;
        this.restangular = restangular;
        this.customerNamePipe = customerNamePipe;
        this.editable = true;
        this.submit = new core_1.EventEmitter();
        this.ownershipTypeOSSItems = [
            { id: 92, name: 'Příslušnost hospodařit s majetkem státu' },
            { id: 109, name: 'Právo hospodařit s majetkem státu' }
        ];
        this.ownershipTypePOItems = [
            { id: 160, name: 'Hospodaření se svěřeným majetkem kraje' },
            { id: 161, name: 'Hospodaření se svěřeným majetkem obce' }
        ];
        this.backButtonText = 'Seznam objednatelů';
        this.parentCustomerFilter = {
            filters: {
                excludeCustomerIds: {
                    values: [],
                },
                includeDepartments: {
                    values: ['true']
                },
            },
            sortOrder: [{
                    sortBy: 'companyName',
                    direction: 'asc',
                }],
        };
        this.customerTypes = [
            {
                id: 'OPO',
                value: 'Právnická osoba'
            },
            {
                id: 'OPO_OSS',
                value: 'Organizační složka státu'
            },
            {
                id: 'OPO_PO',
                value: 'Příspěvková organizace'
            },
        ];
        this.address = {};
        this.basicInfo = {};
        this.mailingAddress = {};
        this.onAresClick = this.onAresClick.bind(this);
        this.onToggleMailingAddress = this.onToggleMailingAddress.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onCustomerTypeSelect = this.onCustomerTypeSelect.bind(this);
    }
    CustomerFormComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.pageTitle = this.stateService.current.data.title;
        this.backButtonText = this.stateService.current.data.backButtonText || this.backButtonText;
        this.parentCustomerFilter.filters.excludeCustomerIds.values = this.customer.idText ? [this.customer.idText] : [];
        this.address = common_address_model_1.CommonAddressModel.fromCustomerAddress(this.customer);
        this.mailingAddress = common_address_model_1.CommonAddressModel.fromCustomerMailingAddress(this.customer);
        this.currentCustomerType = this.getCustomerType();
        this.basicInfo = this.getBasicInfo();
        this.onAddActing = this.onAddActing.bind(this);
        this.onCancelActing = this.onCancelActing.bind(this);
        this.onIdentificationNumberChange = this.onIdentificationNumberChange.bind(this);
        if (this.customer.type === 'OPO_OSS' && this.customer.ownershipType) {
            this.ownershipType = this.ownershipTypeOSSItems.find(function (type) { return type.id === _this.customer.ownershipType; });
        }
        else if (this.customer.type === 'OPO_PO' && this.customer.ownershipType) {
            this.ownershipType = this.ownershipTypePOItems.find(function (type) { return type.id === _this.customer.ownershipType; });
        }
    };
    CustomerFormComponent.prototype.getOptions = function (type) {
        var list = [];
        switch (type) {
            case 'section':
                list = CustomerFormComponent.SECTION_LIST;
                break;
        }
        return list.map(function (i) { return { id: i, name: i }; });
    };
    CustomerFormComponent.prototype.onAresClick = function () {
        var _this = this;
        var dialog = this.dialogService.open(ares_form_component_1.AresFormComponent, {
            data: {
                icoToSearch: this.customer.identificationNumber,
                nameToSearch: this.customer.companyName,
            },
        });
        var sub = dialog.afterClosed.subscribe(function (customer) { return __awaiter(_this, void 0, void 0, function () {
            var organizationalUnits, actingPersons, identificationNumber, postCode, subjectType, rest, courtName, courtDetail;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!customer) return [3 /*break*/, 2];
                        organizationalUnits = customer.organizationalUnits, actingPersons = customer.actingPersons, identificationNumber = customer.identificationNumber, postCode = customer.postCode, subjectType = customer.subjectType, rest = __rest(customer, ["organizationalUnits", "actingPersons", "identificationNumber", "postCode", "subjectType"]);
                        courtName = customer.businessRegisterName;
                        return [4 /*yield*/, this.findBusinessRegisterRegionalCourt(courtName)];
                    case 1:
                        courtDetail = _a.sent();
                        this.customer = __assign({}, this.customer, rest, { actingPersons: new Array(), organizationalUnits: new Array() });
                        this.customer.businessRegisterRegionalCourt = courtDetail || this.customer.businessRegisterRegionalCourt;
                        this.customer.identificationNumber = identificationNumber;
                        this.customer.postCode = postCode;
                        this.customer.type = subjectType;
                        this.currentCustomerType = this.getCustomerType();
                        this.address = __assign({}, common_address_model_1.CommonAddressModel.fromCustomerAddress(this.customer));
                        this.basicInfo = this.getBasicInfo();
                        _a.label = 2;
                    case 2:
                        sub.unsubscribe();
                        return [2 /*return*/];
                }
            });
        }); });
    };
    CustomerFormComponent.prototype.onSubmit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, opsubType, identificationNumber, vatId, basicInfo, mailingAddress, address, customer, result, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _a = this.basicInfo, opsubType = _a.opsubType, identificationNumber = _a.ico, vatId = _a.dic, basicInfo = __rest(_a, ["opsubType", "ico", "dic"]);
                        mailingAddress = common_address_model_1.CommonAddressModel.toCustomerMailingAddress(this.mailingAddress);
                        address = common_address_model_1.CommonAddressModel.toCustomerAddress(this.address);
                        customer = __assign({}, this.customer, address, mailingAddress, basicInfo, { type: this.currentCustomerType.id, businessRegisterName: basicInfo.businessRegisterRegionalCourt && basicInfo.businessRegisterRegionalCourt.name, vatId: vatId,
                            identificationNumber: identificationNumber });
                        customer.deliveryUse = customer.deliveryUse || false;
                        if (!this.customer.idText) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.restangular.one('v2/customers', this.customer.idText).customPUT(customer)];
                    case 1:
                        _b = _c.sent();
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.restangular.all('v2/customers/create').post(customer)];
                    case 3:
                        _b = _c.sent();
                        _c.label = 4;
                    case 4:
                        result = _b;
                        this.submit.emit(result);
                        return [2 /*return*/];
                }
            });
        });
    };
    CustomerFormComponent.prototype.onCustomerTypeSelect = function (customerType) {
        this.currentCustomerType = customerType;
        this.basicInfo = __assign({}, this.basicInfo, { opsubType: customerType.id });
        this.customer.ownershipType = null;
        this.ownershipType = null;
    };
    CustomerFormComponent.prototype.onIdentificationNumberChange = function (value) {
        this.basicInfo = __assign({}, this.basicInfo, { ico: value });
    };
    CustomerFormComponent.prototype.onToggleMailingAddress = function (event) {
        this.customer.deliveryUse = event;
    };
    CustomerFormComponent.prototype.getBasicInfo = function () {
        var _a = this.customer, businessRegisterEntry = _a.businessRegisterEntry, businessRegisterRegionalCourt = _a.businessRegisterRegionalCourt, businessRegisterSection = _a.businessRegisterSection, identificationNumber = _a.identificationNumber, opsubType = _a.opsubType, dic = _a.vatId, vatPayer = _a.vatPayer;
        return {
            businessRegisterRegionalCourt: businessRegisterRegionalCourt,
            businessRegisterEntry: businessRegisterEntry,
            businessRegisterSection: businessRegisterSection,
            dic: dic,
            ico: this.customer.identificationNumber && parseInt(this.customer.identificationNumber),
            identificationNumber: identificationNumber,
            opsubType: opsubType || this.currentCustomerType.id,
            vatPayer: vatPayer,
        };
    };
    CustomerFormComponent.prototype.getCustomerType = function () {
        var _this = this;
        return this.customer.type
            ? this.customerTypes.find(function (customerType) { return customerType.id === _this.customer.type; })
            : this.customerTypes[0];
    };
    CustomerFormComponent.prototype.findBusinessRegisterRegionalCourt = function (courtName) {
        return __awaiter(this, void 0, void 0, function () {
            var filter, courts;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!courtName) {
                            return [2 /*return*/];
                        }
                        filter = {
                            limit: 1,
                            filters: {
                                businessRegister: true,
                                searchText: courtName
                            }
                        };
                        courts = this.listService.createList('regional-courts', filter, this.restangular);
                        return [4 /*yield*/, this.listService.fetchResult(courts)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/, courts.list && courts.list[0]];
                }
            });
        });
    };
    CustomerFormComponent.prototype.onAddActing = function () {
        this.customer.actingPersons.push({ cancelled: false });
    };
    CustomerFormComponent.prototype.onCancelActing = function (acting) {
        if (acting.id) {
            acting.cancelled = true;
        }
        else {
            _.remove(this.customer.actingPersons, function (a) { return _.isEqual(a, acting); });
        }
    };
    CustomerFormComponent.prototype.onRestoreActing = function (acting) {
        acting.cancelled = false;
    };
    CustomerFormComponent.prototype.onAddUnit = function () {
        this.customer.organizationalUnits.push({ cancelled: false });
    };
    CustomerFormComponent.prototype.onCancelUnit = function (unit) {
        if (unit.id) {
            unit.cancelled = true;
        }
        else {
            _.remove(this.customer.organizationalUnits, function (u) { return _.isEqual(u, unit); });
        }
    };
    CustomerFormComponent.prototype.onRestoreUnit = function (unit) {
        unit.cancelled = false;
    };
    CustomerFormComponent.prototype.onSectionChanged = function (unit, section) {
        unit.section = section ? section.id : null;
    };
    CustomerFormComponent.SECTION_LIST = ['Úsek výstavby', 'Úsek provozní'];
    return CustomerFormComponent;
}());
exports.CustomerFormComponent = CustomerFormComponent;
