"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var SortByComponent = /** @class */ (function () {
    function SortByComponent() {
        this.sort = new core_1.EventEmitter();
    }
    SortByComponent.prototype.ngOnInit = function () { };
    SortByComponent.prototype.onSort = function () {
        this.sort.emit(this.sortDir);
    };
    return SortByComponent;
}());
exports.SortByComponent = SortByComponent;
