"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("../../../common/directives/loading.directive");
var i2 = require("@angular/common");
var i3 = require("./cases-generate-documents-confirm.component");
var i4 = require("../../../common/services/auth.service");
var i5 = require("../../services/case.service");
var styles_CasesGenerateDocumentsConfirmComponent = [];
var RenderType_CasesGenerateDocumentsConfirmComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CasesGenerateDocumentsConfirmComponent, data: {} });
exports.RenderType_CasesGenerateDocumentsConfirmComponent = RenderType_CasesGenerateDocumentsConfirmComponent;
function View_CasesGenerateDocumentsConfirmComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "div", [["class", "centered-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Na "])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""])), (_l()(), i0.ɵted(-1, null, [" p\u0159\u00EDpadech budou vygenerov\u00E1ny podle \u0161ablony s n\u00E1zvem "])), (_l()(), i0.ɵeld(5, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, ["", ""])), (_l()(), i0.ɵted(-1, null, [" dokumenty typu "])), (_l()(), i0.ɵeld(8, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i0.ɵted(9, null, ["", ""])), (_l()(), i0.ɵted(-1, null, ["."]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.details.group.cases.length; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.data.details.group.template.name; _ck(_v, 6, 0, currVal_1); var currVal_2 = _co.data.details.group.document.name; _ck(_v, 9, 0, currVal_2); }); }
function View_CasesGenerateDocumentsConfirmComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "centered-text"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", "/", ""])), (_l()(), i0.ɵeld(3, 0, null, null, 1, "div", [], [[8, "className", 0]], null, null, null, null)), i0.ɵdid(4, 16384, null, 0, i1.LoadingDirective, [i0.ElementRef], { loading: [0, "loading"] }, null)], function (_ck, _v) { var currVal_3 = true; _ck(_v, 4, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.processed; var currVal_1 = _co.data.details.group.cases.length; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = i0.ɵnov(_v, 4).elementClass; _ck(_v, 3, 0, currVal_2); }); }
function View_CasesGenerateDocumentsConfirmComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "jumbotron"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CasesGenerateDocumentsConfirmComponent_1)), i0.ɵdid(2, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CasesGenerateDocumentsConfirmComponent_2)), i0.ɵdid(4, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.saving; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.saving; _ck(_v, 4, 0, currVal_1); }, null); }
exports.View_CasesGenerateDocumentsConfirmComponent_0 = View_CasesGenerateDocumentsConfirmComponent_0;
function View_CasesGenerateDocumentsConfirmComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_CasesGenerateDocumentsConfirmComponent_0, RenderType_CasesGenerateDocumentsConfirmComponent)), i0.ɵdid(1, 180224, null, 0, i3.CasesGenerateDocumentsConfirmComponent, [i4.AuthService, "Restangular", i5.CaseService], null, null)], null, null); }
exports.View_CasesGenerateDocumentsConfirmComponent_Host_0 = View_CasesGenerateDocumentsConfirmComponent_Host_0;
var CasesGenerateDocumentsConfirmComponentNgFactory = i0.ɵccf("ng-component", i3.CasesGenerateDocumentsConfirmComponent, View_CasesGenerateDocumentsConfirmComponent_Host_0, { data: "data" }, {}, []);
exports.CasesGenerateDocumentsConfirmComponentNgFactory = CasesGenerateDocumentsConfirmComponentNgFactory;
