"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var _ = require("lodash");
var construction_object_name_pipe_1 = require("@app/common/pipes/construction-object-name.pipe");
var pageable_list_service_1 = require("@app/common/services/pageable.list.service");
var settings_service_1 = require("@app/ps/services/settings.service");
var table_service_1 = require("@app/ps/services/table.service");
var angular_1 = require("@uirouter/angular");
var auth_service_1 = require("@app/common/services/auth.service");
var array_utils_1 = require("@app/common/utils/array.utils");
var title_name_pipe_1 = require("@app/common/pipes/title-name.pipe");
var parcel_number_pipe_1 = require("@app/common/pipes/parcel-number.pipe");
var export_list_service_1 = require("@app/ps/services/export-list.service");
var OccupationListComponent = /** @class */ (function () {
    function OccupationListComponent(helpIds, APP_BRAND, constructionObjectNamePipe, titleNamePipe, parcelNumberPipe, pageableListService, settingsService, tableService, stateService, authService, exportListService) {
        this.helpIds = helpIds;
        this.APP_BRAND = APP_BRAND;
        this.constructionObjectNamePipe = constructionObjectNamePipe;
        this.titleNamePipe = titleNamePipe;
        this.parcelNumberPipe = parcelNumberPipe;
        this.pageableListService = pageableListService;
        this.settingsService = settingsService;
        this.tableService = tableService;
        this.stateService = stateService;
        this.authService = authService;
        this.exportListService = exportListService;
        this.inputValidities = [
            { id: 'valid_with_parcel', name: 'Platný' },
            { id: 'invalid', name: 'Neplatný' },
        ];
        this.isArray = Array.isArray;
        this.exportEnable = true;
        this.settingsLoaded = false;
        this.onExport = this.onExport.bind(this);
    }
    OccupationListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.settingsLoaded = this.settingsService.getSettingsLoader() === null;
        if (!this.settingsLoaded) {
            this.settingsService.getSettingsLoader().then(function () { return _this.settingsLoaded = true; });
        }
        var occupationTypes = this.authService.getActualProject().occupationTypes;
        this.occupationTypesPermanent = _.filter(occupationTypes, function (occupationType) {
            return [1, 2, 3, 4, 7, 8].includes(occupationType.number);
        });
        this.occupationTypesTemporary = _.filter(occupationTypes, function (occupationType) {
            return [5, 6, 9, 10].includes(occupationType.number);
        });
        this.removeCommonStringFromTableHeading(this.occupationTypesPermanent, 'trvalý zábor');
        this.removeCommonStringFromTableHeading(this.occupationTypesTemporary, 'dočasný zábor');
        this.rowspan = (this.shouldHaveSecondRow() ? 2 : 1);
        this.permanentOccupationRowspan = (this.shouldHaveSecondRow() && this.shouldDrawSecondRowOf(this.occupationTypesPermanent) ? 1 : 2);
        this.temporaryOccupationRowspan = (this.shouldHaveSecondRow() && this.shouldDrawSecondRowOf(this.occupationTypesTemporary) ? 1 : 2);
        this.initializeList();
    };
    OccupationListComponent.prototype.uiOnParamsChanged = function (changedParams, $transition$) {
        this.pageableList.uiOnParamsChanged(changedParams, $transition$);
    };
    OccupationListComponent.prototype.onExport = function () {
        var _this = this;
        this.exportEnable = false;
        var filter = __assign({}, this.list.filter);
        filter.filters.loadCollections.push('cases');
        return this.exportListService
            .exportList('occupations', 'occupations', filter)
            .then(function () {
            new Promise(function (resolve) { return setTimeout(function () { return resolve(); }, 5000); }).then(function () {
                _this.exportEnable = true;
            });
        });
    };
    OccupationListComponent.prototype.removeCommonStringFromTableHeading = function (heading, commonString) {
        var headingNames = _.concat(_.map(heading, 'name'), commonString);
        var replaceFn = function (str) { return str.toLowerCase().replace(array_utils_1.ArrayUtils.getLongestCommonSubstring(headingNames, true), '').trim(); };
        var wouldBeEmpty = heading.some(function (cur) { return replaceFn(cur.name) === ''; });
        if (wouldBeEmpty && heading.length > 1) { // don't prevent rows merging in projects with just one occupation type
            return;
        }
        _.forEach(heading, function (obj) {
            obj.name = replaceFn(obj.name);
        });
    };
    OccupationListComponent.prototype.shouldDrawSecondRowOf = function (what) {
        return !(what.length === 1 && what[0].name === '');
    };
    OccupationListComponent.prototype.shouldHaveSecondRow = function () {
        var temp = __spread(this.occupationTypesPermanent, this.occupationTypesTemporary);
        return temp.filter(function (item) { return item.name; }).length > 0;
    };
    OccupationListComponent.prototype.hideColumn = function (columnId) {
        return this.settingsService.shouldHideColumn('occupations', columnId);
    };
    OccupationListComponent.prototype.getDefaultFilter = function () {
        return {
            filters: {
                validOrWithCase: true,
                areaId: { values: [], negation: false },
                titleId: {},
                parcelId: {},
                occupationTypeId: {},
                constructionObjectStageId: {},
                constructionObjectId: { values: [], negation: false },
                validityParcelCase: {},
                searchText: { values: [{ id: '' }] },
                loadCollections: OccupationListComponent.COLLECTIONS
            },
            sortOrder: { sortBy: 'zablv', direction: 'asc' },
        };
    };
    OccupationListComponent.prototype.initializeList = function () {
        this.pageableList = this.pageableListService.get('occupations', this.getDefaultFilter(), 'occupationsFilter');
        this.list = this.pageableList.list;
        this.pageableList.load();
    };
    OccupationListComponent.COLLECTIONS = [
        'constructionObjects',
        'parcel.title.ownerships',
    ];
    return OccupationListComponent;
}());
exports.OccupationListComponent = OccupationListComponent;
