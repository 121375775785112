"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var LabeledBlockComponent = /** @class */ (function () {
    function LabeledBlockComponent() {
        this.collapsed = false;
        this.changed = new core_1.EventEmitter();
    }
    LabeledBlockComponent.prototype.onToggleCollapsed = function () {
        this.collapsed = !this.collapsed;
        this.changed.emit(this.collapsed);
    };
    return LabeledBlockComponent;
}());
exports.LabeledBlockComponent = LabeledBlockComponent;
