"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var dialog_config_1 = require("@app/dialog/dialog-config");
var dialog_ref_1 = require("@app/dialog/dialog-ref");
var SimpleTextModalComponent = /** @class */ (function () {
    function SimpleTextModalComponent(dialogConfig, dialogComponent) {
        this.dialogConfig = dialogConfig;
        this.dialogComponent = dialogComponent;
        this.onUpdate = this.onUpdate.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }
    SimpleTextModalComponent.prototype.ngOnInit = function () {
        this.title = this.dialogConfig.data.title;
        this.label = this.dialogConfig.data.label;
        this.required = this.dialogConfig.data.required;
        this.text = this.dialogConfig.data.text || '';
    };
    SimpleTextModalComponent.prototype.isValid = function () {
        return !!this.text || !this.required;
    };
    SimpleTextModalComponent.prototype.onUpdate = function () {
        this.dialogComponent.close(this.text);
    };
    SimpleTextModalComponent.prototype.onCancel = function () {
        this.dialogComponent.close(false);
    };
    return SimpleTextModalComponent;
}());
exports.SimpleTextModalComponent = SimpleTextModalComponent;
