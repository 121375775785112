"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./fileupload.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/common");
var i3 = require("./fileupload.component");
var styles_FileUploadComponent = [i0.styles];
var RenderType_FileUploadComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FileUploadComponent, data: {} });
exports.RenderType_FileUploadComponent = RenderType_FileUploadComponent;
function View_FileUploadComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "progressbar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [], null, null, null, null, null)), i1.ɵdid(2, 278528, null, 0, i2.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(3, { "width": 0, "margin": 1 })], function (_ck, _v) { var currVal_0 = _ck(_v, 3, 0, (_v.parent.context.$implicit.progress + "%"), "2px 0px"); _ck(_v, 2, 0, currVal_0); }, null); }
function View_FileUploadComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "error"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = (_v.parent.context.$implicit.errorMessage ? _v.parent.context.$implicit.errorMessage : "Chyba p\u0159i nahr\u00E1v\u00E1n\u00ED souboru"); _ck(_v, 1, 0, currVal_0); }); }
function View_FileUploadComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "li", [["class", "file-upload"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { invalid: 0 }), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [["class", "fa fa-file-o fa-lg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 10, "div", [["class", "ml-10"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "a", [["class", "ml-10"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        $event.stopPropagation();
        var pd_0 = (_co.removeFile(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, "i", [["class", "fa fa-times mr-10"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FileUploadComponent_2)), i1.ɵdid(13, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FileUploadComponent_3)), i1.ɵdid(15, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = "file-upload"; var currVal_1 = _ck(_v, 2, 0, !_v.context.$implicit.valid); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_3 = _v.context.$implicit.uploading; _ck(_v, 13, 0, currVal_3); var currVal_4 = _v.context.$implicit.error; _ck(_v, 15, 0, currVal_4); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.fileEntry.name; _ck(_v, 8, 0, currVal_2); }); }
function View_FileUploadComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "drop-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "drop-border-area"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 7, "div", [["class", "drop-area"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "drop-infobox"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [["class", "fa fa-3x fa-cloud-upload"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 2, "ul", [["class", "file-upload-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FileUploadComponent_1)), i1.ɵdid(9, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 0, "input", [["multiple", ""], ["style", "display: none;"], ["type", "file"]], [[8, "id", 0]], [[null, "change"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        _co.inputFilesAdded($event);
        var pd_0 = ($event.stopPropagation() !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = ($event.stopPropagation() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.files; _ck(_v, 9, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.message; _ck(_v, 6, 0, currVal_0); var currVal_2 = i1.ɵinlineInterpolate(1, "", _co.inputId, ""); _ck(_v, 10, 0, currVal_2); }); }
exports.View_FileUploadComponent_0 = View_FileUploadComponent_0;
function View_FileUploadComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fileupload", [], null, [[null, "dragover"], [null, "drop"], [null, "click"]], function (_v, en, $event) { var ad = true; if (("dragover" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).dragOver($event) !== false);
        ad = (pd_0 && ad);
    } if (("drop" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).dropFiles($event) !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1).openFileUpload() !== false);
        ad = (pd_2 && ad);
    } return ad; }, View_FileUploadComponent_0, RenderType_FileUploadComponent)), i1.ɵdid(1, 114688, null, 0, i3.FileUploadComponent, [i1.ElementRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_FileUploadComponent_Host_0 = View_FileUploadComponent_Host_0;
var FileUploadComponentNgFactory = i1.ɵccf("fileupload", i3.FileUploadComponent, View_FileUploadComponent_Host_0, { validation: "validation", message: "message", disabled: "disabled", inputId: "inputId", files: "files", draggable: "draggable" }, { onchange: "change" }, []);
exports.FileUploadComponentNgFactory = FileUploadComponentNgFactory;
