"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var InsertionDirective = /** @class */ (function () {
    function InsertionDirective(viewContainerRef) {
        this.viewContainerRef = viewContainerRef;
    }
    return InsertionDirective;
}());
exports.InsertionDirective = InsertionDirective;
