"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var common_1 = require("@angular/common");
var cs_1 = require("@angular/common/locales/cs");
var angular_hybrid_1 = require("@uirouter/angular-hybrid");
var main_component_1 = require("@app/landing/components/main/main.component");
var home_component_1 = require("@app/landing/components/home/home.component");
var project_list_component_1 = require("@app/landing/components/project-list/project-list.component");
var user_list_component_1 = require("@app/landing/components/user-list/user-list.component");
var user_detail_component_1 = require("@app/landing/components/user-detail/user-detail.component");
var state_utils_1 = require("@app/common/utils/state.utils");
var callback_service_1 = require("@app/ps/services/callback.service");
var manager_report_project_list_component_1 = require("@app/landing/components/manager-report-project-list/manager-report-project-list.component");
var manager_report_project_detail_component_1 = require("@app/landing/components/manager-report-project-detail/manager-report-project-detail.component");
var auth_service_1 = require("@app/common/services/auth.service");
common_1.registerLocaleData(cs_1.default, 'cs');
function authCallback(authService, applications, $transition$) {
    return authService.checkProjectStatus($transition$, applications.landing.name, null, null, 'error').then(function () { }, function (newState) { return newState; });
}
exports.authCallback = authCallback;
function resolveSymapPermissions(applications, restangular, authService) {
    return authService.getUser() ? restangular
        .one("permissions/application/" + applications.sy.name + "/user/", authService.getUser().id)
        .customGET('project-permissions')
        .then(function (data) {
        return data.plain();
    }) : {};
}
exports.resolveSymapPermissions = resolveSymapPermissions;
exports.landingState = {
    name: 'landing',
    url: '/landing',
    abstract: true,
    component: main_component_1.MainComponent,
    onEnter: ['AuthService', 'APPLICATIONS', '$transition$', authCallback],
    resolve: {
        symapPermissions: ['APPLICATIONS', 'GlobalRestangularSettingsProvider', auth_service_1.AuthService, resolveSymapPermissions],
    }
};
exports.homeState = {
    name: 'landing.home',
    url: '/home',
    data: {
        title: 'Úvodní stránka',
    },
    views: {
        'content@landing': {
            component: home_component_1.HomeComponent,
        }
    },
};
exports.projectsState = {
    name: 'landing.projects',
    url: '/projects',
    data: {
        title: 'ps.projectList',
    },
    views: {
        'content@landing': {
            component: project_list_component_1.ProjectListComponent,
        }
    },
};
exports.userListState = {
    name: 'landing.users',
    url: '/users?page',
    data: {
        title: 'Uživatelé',
    },
    views: {
        'content@landing': {
            component: user_list_component_1.UserListComponent,
        },
    },
    params: {
        page: {
            dynamic: true,
            type: 'int',
            inherit: false,
        },
    },
};
exports.userDetailState = {
    name: 'landing.users.detail',
    url: '/:id',
    data: {
        title: 'Detail uživatele',
    },
    views: {
        'content@landing': {
            component: user_detail_component_1.UserDetailComponent,
        }
    },
    resolve: {
        userId: ['$stateParams', state_utils_1.resolveId],
        callbacks: [callback_service_1.CallbackService, state_utils_1.resolveCallbacks],
    },
    params: {
        id: {
            type: 'int',
            value: undefined
        },
    },
};
exports.managerReportProjectsState = {
    name: 'landing.managerReportProjects',
    url: '/manager-report-projects',
    data: {
        title: 'Manažerský report - Přehled akcí',
    },
    views: {
        'content@landing': {
            component: manager_report_project_list_component_1.ManagerReportProjectListComponent,
        }
    },
};
function resolveProjectKey($stateParams) {
    return $stateParams.projectKey;
}
exports.resolveProjectKey = resolveProjectKey;
exports.managerReportProjectDetailState = {
    name: 'landing.managerReportProjects.detail',
    url: '/manager-report-projects/{projectKey:sy_[^/]*}',
    data: {
        title: 'Manažerský report akce',
    },
    params: {
        projectKey: {
            squash: true
        }
    },
    resolve: {
        projectKey: ['$stateParams', resolveProjectKey],
    },
    views: {
        'content@landing': {
            component: manager_report_project_detail_component_1.ManagerReportProjectDetailComponent,
        }
    },
};
var LandingAppModule = /** @class */ (function () {
    function LandingAppModule() {
    }
    return LandingAppModule;
}());
exports.LandingAppModule = LandingAppModule;
