"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var sample_status_enum_1 = require("@app/sv/enums/sample-status.enum");
var sample_service_1 = require("@app/sv/services/sample.service");
var auth_service_1 = require("@app/common/services/auth.service");
var angular_1 = require("@uirouter/angular");
var dialog_service_1 = require("@app/dialog/dialog.service");
var class_name_enum_1 = require("@app/dialog/class-name.enum");
var list_service_1 = require("@app/common/services/list.service");
var simple_text_modal_component_1 = require("@app/common/components/simple-text-modal/simple-text-modal.component");
var ticket_status_enum_1 = require("@app/sv/enums/ticket-status.enum");
var SampleDetailComponent = /** @class */ (function () {
    function SampleDetailComponent(helpIds, restangular, restangularSymap, errorHandlerService, sampleService, listService, authService, stateService, dialogService) {
        this.helpIds = helpIds;
        this.restangular = restangular;
        this.restangularSymap = restangularSymap;
        this.errorHandlerService = errorHandlerService;
        this.sampleService = sampleService;
        this.listService = listService;
        this.authService = authService;
        this.stateService = stateService;
        this.dialogService = dialogService;
        this.loading = true;
        this.sampleStatusEnum = sample_status_enum_1.SampleStatusEnum;
        this.templateFiles = [];
        this.templateFilesMsg = SampleDetailComponent.MSG_TEMPLATE_FILES_DEFAULT;
        this.restBaseUrl = this.authService.getActiveApplicationRestUrl();
        this.authToken = this.authService.getToken();
        this.tabs = [
            {
                name: 'Poznámky',
                id: 'notes',
                href: "sv.samples.detail",
                urlParams: { tab: 'notes' },
            },
            {
                name: 'Akce',
                id: 'projects',
                href: "sv.samples.detail",
                urlParams: { tab: 'projects' },
            },
            {
                name: 'Historie',
                id: 'history',
                href: "sv.samples.detail",
                urlParams: { tab: 'history' },
            },
            {
                name: 'Dokumenty',
                id: 'documents',
                href: "sv.samples.detail",
                urlParams: { tab: 'documents' },
            },
        ];
        this.tab = (this.stateService.params.tab ? this.stateService.params.tab : 'notes');
        this.isActiveTab = this.isActiveTab.bind(this);
        this.onUpdate = this.onUpdate.bind(this);
        this.onUpdateProjects = this.onUpdateProjects.bind(this);
        this.onDocumentsSort = this.onDocumentsSort.bind(this);
        this.toggleProjectActive = this.toggleProjectActive.bind(this);
        this.toggleProjectDisabled = this.toggleProjectDisabled.bind(this);
    }
    SampleDetailComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.loadSample()];
                    case 1:
                        _a.sent();
                        this.loadDocuments();
                        this.loadNotes();
                        this.loadHistory();
                        this.loadProjects();
                        this.loading = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    SampleDetailComponent.prototype.isProjectActive = function (project) {
        return this.sample.status === 'ACTIVE' || this.sample.projects.some(function (sampleProject) { return sampleProject.projectKey === project.key && sampleProject.exception; });
    };
    SampleDetailComponent.prototype.isProjectEnabled = function (project) {
        return (this.sample.status === 'ACTIVE' && !this.sample.projects.some(function (sampleProject) { return sampleProject.projectKey === project.key && sampleProject.disabled; })) ||
            (this.sample.projects.some(function (sampleProject) { return sampleProject.projectKey === project.key && sampleProject.exception && !sampleProject.disabled; }));
    };
    SampleDetailComponent.prototype.toggleProjectActive = function (project) {
        if (this.sample.status === 'ACTIVE') {
            return;
        }
        var sampleProject = this.sample.projects.find(function (sampleProject) { return sampleProject.projectKey === project.key; });
        if (!sampleProject) {
            sampleProject = { projectKey: project.key };
            this.sample.projects.push(sampleProject);
        }
        sampleProject.exception = !sampleProject.exception;
        sampleProject.disabled = false;
    };
    SampleDetailComponent.prototype.toggleProjectDisabled = function (project) {
        if (!this.isProjectActive(project)) {
            return;
        }
        var sampleProject = this.sample.projects.find(function (sampleProject) { return sampleProject.projectKey === project.key; });
        if (!sampleProject) {
            sampleProject = { projectKey: project.key, exception: false };
            this.sample.projects.push(sampleProject);
        }
        sampleProject.disabled = !sampleProject.disabled;
    };
    SampleDetailComponent.prototype.isActiveTab = function (id) {
        return id === this.tab;
    };
    SampleDetailComponent.prototype.uiOnParamsChanged = function (changedParams) {
        this.tab = changedParams.tab;
    };
    SampleDetailComponent.prototype.canEdit = function () {
        return true;
    };
    SampleDetailComponent.prototype.canBeActualized = function () {
        return this.sample.status !== sample_status_enum_1.SampleStatusEnum.INACTIVE
            && this.sample.actualizationTickets.every(function (t) { return t.status === ticket_status_enum_1.TicketStatusEnum.DELETED || t.status === ticket_status_enum_1.TicketStatusEnum.DISAPPROVED; });
    };
    SampleDetailComponent.prototype.canUserManage = function () {
        return this.authService.hasPermissionOnProject('template_manage', this.sample.organizationalUnitCode);
    };
    SampleDetailComponent.prototype.canEditNote = function () {
        return this.authService.hasPermissionOnProject('template_view', this.sample.organizationalUnitCode);
    };
    SampleDetailComponent.prototype.canViewTicket = function () {
        return this.authService.hasPermissionOnProject('template_aproval,template_manage') ||
            this.authService.hasPermissionOnProject('template_new', this.sample.organizationalUnitCode);
    };
    SampleDetailComponent.prototype.getActualizationTicket = function () {
        if (!this.sample.actualizationTickets) {
            return undefined;
        }
        return this.sample.actualizationTickets.find(function (t) { return t.status !== ticket_status_enum_1.TicketStatusEnum.DELETED && t.status !== ticket_status_enum_1.TicketStatusEnum.DISAPPROVED; });
    };
    SampleDetailComponent.prototype.onUpdateDocumentList = function () {
        var documentTab = this.tabs.find(function (tab) { return tab.id === 'documents'; });
        documentTab.name = "Dokumenty (" + this.documentList.list.filter(function (a) { return !a.cancelled; }).length + ")";
    };
    SampleDetailComponent.prototype.onDocumentsSort = function (sortValue, sortDir) {
        this.listService.sort(this.documentList, sortValue, sortDir);
    };
    SampleDetailComponent.prototype.onUpdateNoteList = function () {
        var noteTab = this.tabs.find(function (tab) { return tab.id === 'notes'; });
        noteTab.name = "Pozn\u00E1mky (" + this.noteList.list.filter(function (a) { return !a.cancelled; }).length + ")";
    };
    SampleDetailComponent.prototype.onUpdateHistoryList = function () {
        var historyTab = this.tabs.find(function (tab) { return tab.id === 'history'; });
        historyTab.name = "Historie (" + this.historyList.itemCount + ")";
    };
    SampleDetailComponent.prototype.onSampleInactivate = function () {
        var _this = this;
        var dialog = this.dialogService.open(simple_text_modal_component_1.SimpleTextModalComponent, {
            data: {
                title: 'Zneplatnění vzoru',
                label: 'Důvod',
                required: true
            },
            className: class_name_enum_1.ClassName.HIGHER_DIALOG,
        });
        var sub = dialog.afterClosed.subscribe(function (text) { return __awaiter(_this, void 0, void 0, function () {
            var note;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!text) return [3 /*break*/, 3];
                        text = 'Vzor zneplatněn. Důvod: ' + text;
                        note = { sampleId: { id: this.sampleId }, systemType: true, text: text };
                        this.sample.status = sample_status_enum_1.SampleStatusEnum.INACTIVE;
                        this.loading = true;
                        return [4 /*yield*/, this.sampleService.update(this.sample, note)];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.loadHistory()];
                    case 2:
                        _a.sent();
                        this.loading = false;
                        _a.label = 3;
                    case 3:
                        sub.unsubscribe();
                        return [2 /*return*/];
                }
            });
        }); });
    };
    SampleDetailComponent.prototype.onUpdate = function () {
        var _this = this;
        return this.sampleService.update(this.sample)
            .then(function (sample) {
            _this.sample = sample;
        });
    };
    SampleDetailComponent.prototype.onUpdateProjects = function () {
        return __awaiter(this, void 0, void 0, function () {
            var changes, dialog, sub;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        changes = this.getProjectChanges();
                        if (!(!changes.in.length && !changes.out.length)) return [3 /*break*/, 3];
                        this.loading = true;
                        return [4 /*yield*/, this.sampleService.updateProjects(this.sample)];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.loadSample()];
                    case 2:
                        _a.sent();
                        this.loading = false;
                        return [2 /*return*/];
                    case 3:
                        dialog = this.dialogService.open(simple_text_modal_component_1.SimpleTextModalComponent, {
                            data: {
                                title: 'Změna nastavení akcí vzoru',
                                label: 'Důvod',
                                required: true
                            },
                            className: class_name_enum_1.ClassName.HIGHER_DIALOG,
                        });
                        sub = dialog.afterClosed.subscribe(function (text) { return __awaiter(_this, void 0, void 0, function () {
                            var actionText, projects, projects, note;
                            var _this = this;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        if (!text) return [3 /*break*/, 4];
                                        actionText = '';
                                        if (changes.in.length) {
                                            projects = changes.in.map(function (sp) { return _this.projectList.list.find(function (p) { return p.key === sp.projectKey; }).name; }).join(', ');
                                            actionText += 'Vzor aktivován pro ' + (changes.in.length === 1 ? 'akci: ' : 'akce:') + projects + '. ';
                                        }
                                        if (changes.out.length) {
                                            projects = changes.out.map(function (sp) { return _this.projectList.list.find(function (p) { return p.key === sp.projectKey; }).name; }).join(', ');
                                            actionText += 'Vzor deaktivován pro akce ' + (changes.out.length === 1 ? 'akci: ' : 'akce:') + projects + '. ';
                                        }
                                        text = actionText + 'Důvod: ' + text;
                                        note = { sampleId: { id: this.sampleId }, systemType: true, text: text };
                                        // store changes
                                        this.loading = true;
                                        return [4 /*yield*/, this.sampleService.updateProjects(this.sample, note)];
                                    case 1:
                                        _a.sent();
                                        return [4 /*yield*/, this.loadSample()];
                                    case 2:
                                        _a.sent();
                                        return [4 /*yield*/, this.loadHistory()];
                                    case 3:
                                        _a.sent();
                                        this.loading = false;
                                        _a.label = 4;
                                    case 4:
                                        sub.unsubscribe();
                                        return [2 /*return*/];
                                }
                            });
                        }); });
                        return [2 /*return*/];
                }
            });
        });
    };
    SampleDetailComponent.prototype.onTemplateFilesChange = function (files) {
        var _this = this;
        this.templateFiles = files;
        var extensionValid = files.every(function (f) { return f.extension && f.extension.toLowerCase() === 'docx'; });
        if (this.templateFiles.length === 0) {
            this.templateFilesMsg = SampleDetailComponent.MSG_TEMPLATE_FILES_DEFAULT;
        }
        else if (!extensionValid) {
            this.templateFilesMsg = SampleDetailComponent.MSG_TEMPLATE_FILES_EXTENSION;
        }
        else if (this.templateFiles.length > 1) {
            this.templateFilesMsg = SampleDetailComponent.MSG_FILES_QUANTITY;
        }
        else {
            this.templateFilesMsg = '';
        }
        var valid = (extensionValid && this.templateFiles.length === 1);
        if (!valid) {
            return;
        }
        var dialog = this.dialogService.open(simple_text_modal_component_1.SimpleTextModalComponent, {
            data: {
                title: 'Technická oprava šablony',
                label: 'Důvod opravy šablony',
                required: true
            },
            className: class_name_enum_1.ClassName.HIGHER_DIALOG,
        });
        var sub = dialog.afterClosed.subscribe(function (text) { return __awaiter(_this, void 0, void 0, function () {
            var noteText, note, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!text) return [3 /*break*/, 7];
                        noteText = 'Oprava šablony vzoru. Důvod: ' + text;
                        note = { sampleId: { id: this.sampleId }, systemType: true, text: noteText };
                        this.loading = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.sampleService.updateTemplate(this.sample, this.templateFiles[0], note, text)];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _a.sent();
                        (new this.errorHandlerService())(e_1);
                        return [3 /*break*/, 4];
                    case 4: return [4 /*yield*/, this.loadDocuments()];
                    case 5:
                        _a.sent();
                        return [4 /*yield*/, this.loadHistory()];
                    case 6:
                        _a.sent();
                        this.loading = false;
                        this.templateFiles = [];
                        _a.label = 7;
                    case 7:
                        sub.unsubscribe();
                        return [2 /*return*/];
                }
            });
        }); });
    };
    SampleDetailComponent.prototype.loadDocuments = function () {
        var _this = this;
        var types = ['SAMPLE'];
        if (this.authService.hasPermissionOnProject('template_aproval,template_manage')) {
            types.push('TEMPLATE');
            types.push('INACTIVE_TEMPLATE');
        }
        this.documentList = this.listService.createList('attachments', {
            filters: { sampleId: this.sample.id, attachmentType: { values: types }, cancelled: false },
            sortOrder: { sortBy: 'timestamp', direction: 'desc' }
        }, undefined);
        this.listService.fetchResult(this.documentList).then(function () {
            _this.onUpdateDocumentList();
        });
    };
    SampleDetailComponent.prototype.loadHistory = function () {
        var _this = this;
        this.historyList = this.listService.createList('notes', {
            filters: { sampleId: this.sampleId, systemType: true, },
            sortOrder: { sortBy: 'timestamp', direction: 'desc' }
        });
        this.listService.fetchResult(this.historyList).then(function () {
            _this.onUpdateHistoryList();
        });
    };
    SampleDetailComponent.prototype.loadProjects = function () {
        this.projectList = this.listService.createList('projects/list', {
            filters: { investorOrganizationalUnitId: this.sample.organizationalUnitId },
            sortOrder: { sortBy: 'name', direction: 'asc' },
            limit: null
        }, this.restangularSymap);
        this.listService.fetchResult(this.projectList);
    };
    SampleDetailComponent.prototype.loadNotes = function () {
        var _this = this;
        this.noteList = this.listService.createList('notes', {
            filters: { sampleId: this.sampleId, systemType: false, cancelled: false, },
            sortOrder: { sortBy: 'timestamp', direction: 'desc' }
        });
        this.listService.fetchResult(this.noteList).then(function () {
            _this.onUpdateNoteList();
        });
    };
    SampleDetailComponent.prototype.loadSample = function () {
        var _this = this;
        return this.sampleService.getByIdWithAttributes(this.sampleId, {
            madeByTicket: {},
            actualizationTickets: {},
            parentSample: {},
            childSample: {},
            projects: {},
        }).then(function (sample) {
            _this.sample = sample;
            _this.currentProjects = [];
            _this.sample.projects.forEach(function (p) { return _this.currentProjects.push(__assign({}, p)); });
        });
    };
    SampleDetailComponent.prototype.getProjectChanges = function () {
        var _this = this;
        var res = { in: [], out: [] };
        this.sample.projects.forEach(function (sp) {
            var csp = _this.currentProjects.find(function (csp) { return csp.projectKey === sp.projectKey; });
            if (sp.exception === true && (!csp || csp.exception === false)) {
                res.in.push(sp);
            }
            if (sp.exception === false && csp && csp.exception === true) {
                res.out.push(sp);
            }
        });
        return res;
    };
    SampleDetailComponent.MSG_TEMPLATE_FILES_DEFAULT = 'Vložte opravu šablony. Je možné vložit jeden soubor docx.';
    SampleDetailComponent.MSG_TEMPLATE_FILES_EXTENSION = 'Soubor musí být ve formátu DOCX.';
    SampleDetailComponent.MSG_FILES_QUANTITY = 'Je možné vložit pouze jeden soubor.';
    return SampleDetailComponent;
}());
exports.SampleDetailComponent = SampleDetailComponent;
