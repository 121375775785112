"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var i0 = require("@angular/core");
var OrganizationalUnitNamePipe = /** @class */ (function () {
    function OrganizationalUnitNamePipe() {
    }
    OrganizationalUnitNamePipe.prototype.transform = function (organizationUnit) {
        if (!organizationUnit) {
            return '';
        }
        var res = [];
        // v mpv obsaženo v názvu
        /*if (organizationUnit.code) {
          res.push(organizationUnit.code);
        }*/
        if (organizationUnit.name) {
            res.push(organizationUnit.name);
        }
        if (!res.length) {
            return '';
        }
        return res.join(' ');
    };
    OrganizationalUnitNamePipe.ngInjectableDef = i0.defineInjectable({ factory: function OrganizationalUnitNamePipe_Factory() { return new OrganizationalUnitNamePipe(); }, token: OrganizationalUnitNamePipe, providedIn: "root" });
    return OrganizationalUnitNamePipe;
}());
exports.OrganizationalUnitNamePipe = OrganizationalUnitNamePipe;
