"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var GeometricPlanRequestStatusEnum;
(function (GeometricPlanRequestStatusEnum) {
    GeometricPlanRequestStatusEnum["CREATED"] = "CREATED";
    GeometricPlanRequestStatusEnum["CONCEPT_REALIZATION"] = "CONCEPT_REALIZATION";
    GeometricPlanRequestStatusEnum["CONCEPT_SENT_TO_CONFIRMATION"] = "CONCEPT_SENT_TO_CONFIRMATION";
    GeometricPlanRequestStatusEnum["CONCEPT_DISAPPROVED"] = "CONCEPT_DISAPPROVED";
    GeometricPlanRequestStatusEnum["CONCEPT_APPROVED"] = "CONCEPT_APPROVED";
    GeometricPlanRequestStatusEnum["SENT_TO_CONFIRMATION"] = "SENT_TO_CONFIRMATION";
    GeometricPlanRequestStatusEnum["DISAPPROVED"] = "DISAPPROVED";
    GeometricPlanRequestStatusEnum["APPROVED"] = "APPROVED";
    GeometricPlanRequestStatusEnum["DELETED"] = "DELETED";
    GeometricPlanRequestStatusEnum["REGISTER_REQUESTED"] = "REGISTER_REQUESTED";
    GeometricPlanRequestStatusEnum["REGISTERED"] = "REGISTERED";
    GeometricPlanRequestStatusEnum["IMPORT_REQUESTED"] = "IMPORT_REQUESTED";
    GeometricPlanRequestStatusEnum["IMPORTED"] = "IMPORTED";
})(GeometricPlanRequestStatusEnum = exports.GeometricPlanRequestStatusEnum || (exports.GeometricPlanRequestStatusEnum = {}));
exports.geometricPlanRequestStatusOptions = [
    {
        id: GeometricPlanRequestStatusEnum.CREATED,
        name: 'Založení záznamu o GP',
        dialog: false,
    },
    {
        id: GeometricPlanRequestStatusEnum.CONCEPT_REALIZATION,
        name: 'Zpracování GP',
        dialog: true,
    },
    {
        id: GeometricPlanRequestStatusEnum.CONCEPT_SENT_TO_CONFIRMATION,
        name: 'Koncept GP ke schválení',
        dialog: true,
    },
    {
        id: GeometricPlanRequestStatusEnum.CONCEPT_DISAPPROVED,
        name: 'Odmítnutý koncept GP',
        dialog: true,
    },
    {
        id: GeometricPlanRequestStatusEnum.CONCEPT_APPROVED,
        name: 'Schválený koncept GP',
        dialog: true,
    },
    {
        id: GeometricPlanRequestStatusEnum.SENT_TO_CONFIRMATION,
        name: 'Odesláno k potvrzení na KP',
        dialog: true,
    },
    {
        id: GeometricPlanRequestStatusEnum.DISAPPROVED,
        name: 'Odmítnutý GP (KP)',
        dialog: true,
    },
    {
        id: GeometricPlanRequestStatusEnum.APPROVED,
        name: 'Potvrzený GP (KP)',
        dialog: true,
    },
    {
        id: GeometricPlanRequestStatusEnum.DELETED,
        name: 'Zrušený GP',
        action: 'Zrušit GP',
        dialog: true,
    },
    {
        id: GeometricPlanRequestStatusEnum.REGISTER_REQUESTED,
        name: 'Vklad do KN na základě ÚR',
        dialog: true,
    },
    {
        id: GeometricPlanRequestStatusEnum.REGISTERED,
        name: 'GP zapsaný v KN (ÚR)',
        dialog: true,
    },
    {
        id: GeometricPlanRequestStatusEnum.IMPORT_REQUESTED,
        name: 'Požadavek na vložení nového stavu dle GP do aplikace',
        action: 'Vytvořit požadavek na vložení nového stavu dle GP do aplikace',
        dialog: true,
    },
    {
        id: GeometricPlanRequestStatusEnum.IMPORTED,
        name: 'GP zavedený do aplikace',
        action: 'Zavést GP do aplikace',
        dialog: true,
    }
];
