"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("../../common/services/symap-restangular.service");
var SettingsService = /** @class */ (function () {
    function SettingsService(restangularSymap) {
        this.restangularSymap = restangularSymap;
        this.settings = null;
        this.settingsLoader = null;
    }
    SettingsService.prototype.getSettingsLoader = function () {
        return this.settingsLoader;
    };
    SettingsService.prototype.clearSettings = function () {
        this.settings = null;
        if (this.canceller) {
            this.canceller();
        }
    };
    SettingsService.prototype.loadSettings = function (projectId) {
        var _this = this;
        if (!Number.isInteger(projectId)) {
            return Promise.reject("activeProjectId is not valid integer value " + projectId + ".");
        }
        if (this.settings) {
            return Promise.resolve(this.settings);
        }
        else if (this.settingsLoader) {
            return this.settingsLoader;
        }
        else {
            var cancelPromise = new Promise(function (resolve, reject) {
                _this.canceller = resolve;
            });
            this.settingsLoader = this.restangularSymap
                .one("projects/" + projectId + "/settings")
                .withHttpConfig({ timeout: cancelPromise })
                .get()
                .then(function (settings) {
                _this.settings = settings;
                _this.settingsLoader = null;
                _this.canceller = undefined;
                return _this.settings;
            }, function (reason) {
                _this.settingsLoader = null;
                _this.canceller = undefined;
                return Promise.reject(reason);
            });
            return this.settingsLoader;
        }
    };
    SettingsService.prototype.shouldHideColumn = function (tableId, columnId) {
        var settings = this.getSettings();
        if (settings) {
            var columnsVisibility = settings.columnSettings;
            if (columnsVisibility.hidden) {
                var searchColumnCfg = columnsVisibility.hidden.find(function (columnCfg) { return columnCfg.name === tableId; });
                return searchColumnCfg && searchColumnCfg.columns.includes(columnId);
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    };
    SettingsService.prototype.getSettings = function () {
        return this.settings;
    };
    SettingsService.ngInjectableDef = i0.defineInjectable({ factory: function SettingsService_Factory() { return new SettingsService(i0.inject(i1.RESTANGULAR_SYMAP)); }, token: SettingsService, providedIn: "root" });
    return SettingsService;
}());
exports.SettingsService = SettingsService;
