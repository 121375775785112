"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var auth_service_1 = require("@app/common/services/auth.service");
var AuthProjectDirective = /** @class */ (function () {
    function AuthProjectDirective(elementRef, authService) {
        this.elementRef = elementRef;
        this.authService = authService;
    }
    AuthProjectDirective.prototype.ngOnInit = function () {
        if (this.actions && !this.authService.hasPermissionOnProject(this.actions, this.project)) {
            this.elementRef.nativeElement.style.display = 'none';
        }
    };
    return AuthProjectDirective;
}());
exports.AuthProjectDirective = AuthProjectDirective;
