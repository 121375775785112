"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ApproveEnum;
(function (ApproveEnum) {
    ApproveEnum["NOT_ENSURE"] = "NOT_ENSURE";
    ApproveEnum["APPROVE"] = "APPROVE";
    ApproveEnum["NOT_APPROVE"] = "NOT_APPROVE";
})(ApproveEnum = exports.ApproveEnum || (exports.ApproveEnum = {}));
exports.approveOptions = [
    {
        id: ApproveEnum.NOT_ENSURE,
        name: 'Nezajištěno',
        value: 'Nezajištěno',
    },
    {
        id: ApproveEnum.APPROVE,
        name: 'Souhlas',
        value: 'Souhlas'
    },
    {
        id: ApproveEnum.NOT_APPROVE,
        name: 'Nesouhlas',
        value: 'Nesouhlas'
    },
];
