"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var FormCaseUndeliveredOwnerComponent = /** @class */ (function () {
    function FormCaseUndeliveredOwnerComponent() {
        this.callbackRegister = new core_1.EventEmitter();
        this.isValid = this.isValid.bind(this);
    }
    FormCaseUndeliveredOwnerComponent.prototype.ngOnInit = function () {
        this.data.undeliveredOwnerDate = this.data.sentOwnerDate || new Date();
        this.data.note = {};
        this.callbackRegister.emit({
            isValid: this.isValid,
        });
    };
    FormCaseUndeliveredOwnerComponent.prototype.isValid = function () {
        return !!this.data.undeliveredOwnerDate && !!this.data.note.text && !!this.data.note.noteType;
    };
    return FormCaseUndeliveredOwnerComponent;
}());
exports.FormCaseUndeliveredOwnerComponent = FormCaseUndeliveredOwnerComponent;
