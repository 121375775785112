"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var MainComponent = /** @class */ (function () {
    function MainComponent(APPLICATIONS, APP_BRAND) {
        this.APPLICATIONS = APPLICATIONS;
        this.APP_BRAND = APP_BRAND;
    }
    MainComponent.prototype.ngOnInit = function () {
    };
    return MainComponent;
}());
exports.MainComponent = MainComponent;
