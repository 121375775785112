"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var moment = require("moment");
var dialog_config_1 = require("@app/dialog/dialog-config");
var dialog_ref_1 = require("@app/dialog/dialog-ref");
var callbacks_enum_1 = require("@app/ps/enums/callbacks.enum");
var ProblematicNoteComponent = /** @class */ (function () {
    function ProblematicNoteComponent(config, dialog, restangular) {
        this.config = config;
        this.dialog = dialog;
        this.restangular = restangular;
        this.payload = {
            text: undefined,
            timestamp: moment(),
        };
        this.remove = this.remove.bind(this);
        this.submit = this.submit.bind(this);
        this.isValid = this.isValid.bind(this);
        this.owner = config.data.owner;
        this.API_ENDPOINT = "subjects/" + this.owner.id + "/problematic";
        if (config.data.note && config.data.note.length > 0 && this.owner.subjectDetail && this.owner.subjectDetail.problematic) {
            this.payload = config.data.note[0];
        }
    }
    ProblematicNoteComponent.prototype.ngOnInit = function () { };
    ProblematicNoteComponent.prototype.onSaved = function (owner) {
        if (this.config.callbacks) {
            this.config.callbacks.get(callbacks_enum_1.Callbacks.ProblematicNoteChanged)({ owner: owner });
        }
    };
    ProblematicNoteComponent.prototype.remove = function () {
        var _this = this;
        return this.restangular
            .one(this.API_ENDPOINT)
            .customPUT({})
            .then(function (res) {
            _this.dialog.close();
            _this.onSaved(res);
        });
    };
    ProblematicNoteComponent.prototype.submit = function () {
        var _this = this;
        this.payload['timestamp'] = moment(this.payload['timestamp']).unix();
        return this.restangular
            .one(this.API_ENDPOINT)
            .customPUT(this.payload)
            .then(function (res) {
            _this.dialog.close();
            _this.onSaved(res);
        });
    };
    ProblematicNoteComponent.prototype.isValid = function () {
        return this.payload['timestamp'] && this.payload['text'];
    };
    return ProblematicNoteComponent;
}());
exports.ProblematicNoteComponent = ProblematicNoteComponent;
