"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./cell-case-subjects.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/common");
var i3 = require("../../../common/pipes/subject-name.pipe");
var i4 = require("./cell-case-subjects.component");
var styles_CellCaseSubjectsComponent = [i0.styles];
var RenderType_CellCaseSubjectsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CellCaseSubjectsComponent, data: {} });
exports.RenderType_CellCaseSubjectsComponent = RenderType_CellCaseSubjectsComponent;
function View_CellCaseSubjectsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["(", ")"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.progress + "/") + _co.caseSubjects.length); _ck(_v, 1, 0, currVal_0); }); }
function View_CellCaseSubjectsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵppd(2, 1)], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent, 0), _v.context.$implicit)); _ck(_v, 1, 0, currVal_0); }); }
function View_CellCaseSubjectsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["class", "comma-list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CellCaseSubjectsComponent_3)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.subjectsToShow; _ck(_v, 2, 0, currVal_0); }, null); }
function View_CellCaseSubjectsComponent_0(_l) { return i1.ɵvid(2, [i1.ɵpid(0, i3.SubjectNamePipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CellCaseSubjectsComponent_1)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CellCaseSubjectsComponent_2)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.progress && (((_co.caseSubjects == null) ? null : _co.caseSubjects.length) > 1)); _ck(_v, 4, 0, currVal_1); var currVal_2 = ((_co.subjectsToShow == null) ? null : _co.subjectsToShow.length); _ck(_v, 7, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.caseStatus.name; _ck(_v, 2, 0, currVal_0); }); }
exports.View_CellCaseSubjectsComponent_0 = View_CellCaseSubjectsComponent_0;
function View_CellCaseSubjectsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "cell-case-subjects", [], null, null, null, View_CellCaseSubjectsComponent_0, RenderType_CellCaseSubjectsComponent)), i1.ɵdid(1, 114688, null, 0, i4.CellCaseSubjectsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_CellCaseSubjectsComponent_Host_0 = View_CellCaseSubjectsComponent_Host_0;
var CellCaseSubjectsComponentNgFactory = i1.ɵccf("cell-case-subjects", i4.CellCaseSubjectsComponent, View_CellCaseSubjectsComponent_Host_0, { caseSubjects: "caseSubjects", caseStatus: "caseStatus", lastCaseChangeDate: "lastCaseChangeDate" }, {}, []);
exports.CellCaseSubjectsComponentNgFactory = CellCaseSubjectsComponentNgFactory;
