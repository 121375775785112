"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var angular_1 = require("@uirouter/angular");
var title_model_1 = require("@app/common/models/title.model");
var subject_name_pipe_1 = require("@app/common/pipes/subject-name.pipe");
var core_2 = require("@uirouter/core");
var dialog_service_1 = require("@app/dialog/dialog.service");
var alert_component_1 = require("@app/dialog/alert/alert.component");
var _cloneDeep = require("lodash/cloneDeep");
var title_detail_create_case_select_parent_service_1 = require("@app/ps/services/title-detail-create-case-select-parent.service");
var title_detail_create_case_data_service_1 = require("@app/ps/services/title-detail-create-case-data.service");
var class_name_enum_1 = require("@app/dialog/class-name.enum");
var CreateCaseComponent = /** @class */ (function () {
    function CreateCaseComponent(transitionService, stateService, subjectNamePipe, transition, dialogService, HELP_IDS, confirmService, titleDetailCreateCaseDataService, titleDetailCreateCaseSelectParentService, restangular) {
        this.transitionService = transitionService;
        this.stateService = stateService;
        this.subjectNamePipe = subjectNamePipe;
        this.transition = transition;
        this.dialogService = dialogService;
        this.HELP_IDS = HELP_IDS;
        this.confirmService = confirmService;
        this.titleDetailCreateCaseDataService = titleDetailCreateCaseDataService;
        this.titleDetailCreateCaseSelectParentService = titleDetailCreateCaseSelectParentService;
        this.restangular = restangular;
        this.finished = false;
        this.displayConfirm = true;
        this.loading = true;
        this.menuSteps = [
            { name: 'Typ případu', ids: ['select-obligation'], enabled: false },
            { name: 'Předmět případu a vlastníci', ids: ['select-objects', 'select-parent-ownership'], enabled: false },
            { name: 'Další LV vlastníka', ids: ['select-titles', 'select-parent-ownership2'], enabled: false },
            { name: 'Detaily případu', ids: ['detail'], enabled: false },
            { name: 'Potvrzení', ids: ['confirm'], enabled: false },
            { name: 'Výsledek', ids: ['summary'], enabled: false },
        ];
        this.hasStep = this.hasStep.bind(this);
        this.onSelectParentOwnership = this.onSelectParentOwnership.bind(this);
    }
    // Prevent exit without users will
    CreateCaseComponent.prototype.beforeExit = function (transition) {
        var _this = this;
        if (transition && transition.to() === transition.from()) {
            return;
        }
        return new this.confirmService({
            title: 'Zrušit založení případu',
            message: 'Opravdu chcete odejít? Všechna zadaná data budou ztracena.'
        }).then(function (data) {
            if (data === true) {
                _this.deregisterLeaveListener();
            }
            else {
                return Promise.resolve(false);
            }
        });
    };
    CreateCaseComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.steps = [];
                        this.data = {};
                        if (!this.caseId) return [3 /*break*/, 2];
                        _a = this.data;
                        return [4 /*yield*/, this.restangular.one('cases', this.caseId).get({ loadCollections: [
                                    'caseOccupations',
                                    'caseEasements',
                                    'titles',
                                    'caseOwnerships',
                                    'caseBuildings',
                                ] })];
                    case 1:
                        _a.fromCase = _b.sent();
                        // výběr lv
                        this.titleId = this.data.fromCase.titles[0].id;
                        _b.label = 2;
                    case 2:
                        if (this.titleId) {
                            if (!this.caseId) {
                                this.stepChanged('select-obligation');
                            }
                            this.titleDataPromise = this.titleDetailCreateCaseDataService.loadTitleData(this.titleId);
                            this.generalDataPromise = this.titleDetailCreateCaseDataService.loadGeneralData(this.titleId, this.titleDataPromise);
                            this.generalDataPromise.then(function () { _this.loading = false; });
                        }
                        if (this.caseId) {
                            this.generalDataPromise.then(function (data) {
                                _this.data.obligation = data.dataObligationsFiltered.find(function (obligation) { return obligation.type === _this.obligationType; });
                                if (!_this.data.obligation) {
                                    var dialog = _this.dialogService.open(alert_component_1.AlertComponent, {
                                        data: {
                                            msg: 'Pro uvedený list vlastnictví není k dispozici typ případu!',
                                        },
                                        className: class_name_enum_1.ClassName.ADJUSTED_DIALOG,
                                    });
                                    var sub_1 = dialog.afterClosed.subscribe(function (result) {
                                        sub_1.unsubscribe();
                                        _this.deregisterLeaveListener();
                                        _this.stateService.go('^');
                                    });
                                    return;
                                }
                                _this.onSelectObligation();
                            });
                        }
                        // If any link is clicked
                        this.deregisterLeaveListener = this.transitionService.onBefore({}, this.beforeExit.bind(this));
                        return [2 /*return*/];
                }
            });
        });
    };
    CreateCaseComponent.prototype.onSelectObligation = function () {
        this.stepChanged('select-objects');
    };
    CreateCaseComponent.prototype.showSelectionError = function () {
        var _this = this;
        this.step = null;
        var dialog = this.dialogService.open(alert_component_1.AlertComponent, {
            data: {
                msg: 'V aktuálním stavu dat není možné založit případ!',
            },
            className: class_name_enum_1.ClassName.ADJUSTED_DIALOG,
        });
        var sub = dialog.afterClosed.subscribe(function (result) {
            sub.unsubscribe();
            _this.deregisterLeaveListener();
            _this.stateService.go('^');
        });
    };
    CreateCaseComponent.prototype.onSelectObjects = function () {
        if (!this.data.caseOwnerships) {
            this.showSelectionError();
            return;
        }
        this.stepChanged('select-parent-ownership');
    };
    CreateCaseComponent.prototype.onSelectParentOwnership = function (skipped) {
        if (skipped === void 0) { skipped = false; }
        if (skipped) {
            this.steps.pop();
        }
        // For second step pass go to detail otherwise to select-titles
        if (this.step.key === 'select-parent-ownership2') {
            if (this.data.fromCase && (this.data.fromCase.caseEasements.length !== this.data.easements.length ||
                this.data.fromCase.caseOccupations.length !== this.data.occupations.length ||
                this.data.fromCase.caseBuildings.length !== this.data.buildings.length ||
                this.data.fromCase.caseOwnerships.length !== this.data.caseOwnerships.length)) {
                this.showSelectionError();
                return;
            }
            this.stepChanged('detail');
        }
        else {
            return this.checkCantBeParent();
        }
    };
    CreateCaseComponent.prototype.onSelectTitles = function (skipped) {
        if (skipped === void 0) { skipped = false; }
        if (skipped) {
            this.steps.pop();
        }
        // Second step repetition
        this.stepChanged('select-parent-ownership2');
    };
    CreateCaseComponent.prototype.onDetail = function (error) {
        if (error === void 0) { error = false; }
        if (error) {
            this.steps.pop();
            this.onBack();
        }
        else {
            this.stepChanged('confirm');
        }
    };
    CreateCaseComponent.prototype.onConfirm = function () {
        this.finished = true;
        this.deregisterLeaveListener();
        this.stepChanged('summary');
    };
    CreateCaseComponent.prototype.onSummary = function () {
        this.stateService.go('^');
    };
    CreateCaseComponent.prototype.onBack = function () {
        var step = this.steps.pop();
        this.data = step.data;
        this.stepChanged(step.key);
        this.displayConfirm = false;
    };
    CreateCaseComponent.prototype.hasStep = function (stepKey) {
        return this.steps.some(function (step) { return step.key === stepKey; });
    };
    CreateCaseComponent.prototype.onClickStep = function (menuStep) {
        if (!menuStep.enabled) {
            return;
        }
        while (menuStep.ids[0] !== this.step.key && this.steps.length) {
            this.steps.pop();
            this.onBack();
        }
    };
    CreateCaseComponent.prototype.stepChanged = function (stepKey) {
        var _this = this;
        // identify forward step
        if (this.steps.length && !this.steps[this.steps.length - 1].data) {
            // create new instance to store state of data
            this.steps[this.steps.length - 1].data = _cloneDeep(this.data);
            this.displayConfirm = true;
        }
        this.step = {
            key: stepKey,
            data: null,
        };
        this.steps.push(this.step);
        switch (this.step.key) {
            case 'select-obligation':
                this.helpId = null;
                break;
            case 'select-objects':
                this.helpId = this.HELP_IDS.TITLE_DETAIL_CREATE_CASE_SELECT;
                break;
            case 'select-titles':
                this.helpId = this.HELP_IDS.TITLE_DETAIL_CREATE_CASE_SELECT_TITLES;
                break;
            case 'select-parent-ownership':
            case 'select-parent-ownership2':
                this.helpId = this.HELP_IDS.TITLE_DETAIL_CREATE_CASE_SELECT_PARENT_OWNERSHIP;
                break;
            case 'detail':
                this.helpId = null;
                break;
            case 'confirm':
                this.helpId = this.HELP_IDS.TITLE_DETAIL_CREATE_CASE_CONFIRM;
                break;
            case 'summary':
                this.helpId = this.HELP_IDS.TITLE_DETAIL_CREATE_CASE_SUMMARY;
                break;
            default:
                this.helpId = null;
        }
        if (this.onHelpIdChanged) {
            this.onHelpIdChanged(this.helpId);
        }
        this.menuSteps.forEach(function (step) {
            step.enabled = !_this.caseId && ((step.ids.includes('summary') && _this.step.key === 'summary') || (_this.step.key !== 'summary' && step.ids.some(_this.hasStep)));
        });
    };
    CreateCaseComponent.prototype.checkCantBeParent = function () {
        var _this = this;
        var ownershipsPossibleParents = this.titleDetailCreateCaseSelectParentService.ownershipsPossibleParents(this.data.caseOwnerships);
        var ownershipsWithoutParentPromise = ownershipsPossibleParents.length ? this.titleDetailCreateCaseSelectParentService.filterOwnershipsWithoutParent(this.data.caseOwnerships, this.data.obligation.type) : Promise.resolve([]);
        return ownershipsWithoutParentPromise.then(function (ownershipsWithoutParent) {
            if (ownershipsWithoutParent.length) {
                var notSelectedText = '<span class="comma-list">' + ownershipsWithoutParent.reduce(function (acc, titleOwnership) { return acc + '<span>' + titleOwnership.ownership.subjects.map(_this.subjectNamePipe.transform).join(', ') + '</span>'; }, '') + '</span>';
                var parentText = '<span class="comma-list">' + ownershipsPossibleParents.reduce(function (acc, titleOwnership) { return acc + '<span>' + titleOwnership.ownership.subjects.map(_this.subjectNamePipe.transform).join(', ') + '</span>'; }, '') + '</span>';
                return new _this.confirmService({
                    template: '<h1>Potvrzení výběru vlastníka</h1>'
                        + '<p class="font-size-16 mb-10">Na tomto LV se nachází organizace, která má jeden z typů práva či příslušnosti hospodaření s majetkem vlastníka. Jde o organizaci:</p>'
                        + ownershipsWithoutParent.reduce(function (acc, titleOwnership) { return acc + '<p class="font-size-16 text-center"><b>' + titleOwnership.ownership.subjects.map(_this.subjectNamePipe.transform).join(', ') + ' - ' + titleOwnership.ownership.typravNazev + '</b></p>'; }, '')
                        + '<p class="font-size-16 mt-10 mb-10">Případy je nutné zakládat vždy s organizací, se kterou budete dále jednat, což nutně nemusí být Vámi vybraný faktický vlastník - ' + parentText + '</p>'
                        + '<p class="font-size-16">Opravdu chcete založit případ s vybraným vlasníkem ' + parentText + ' a nikoliv s pověřenou organizací ' + notSelectedText + '?</p>'
                        + '<div class="actions"><input type="button" value="Ano" ng-click="confirm();"/>'
                        + '<input type="button" value="Ne" ng-click="decline();" class="red "/></div>',
                }).then(function (result) {
                    if (result === true) {
                        _this.stepChanged('select-titles');
                    }
                    else {
                        do {
                            _this.onBack();
                        } while (_this.step.key !== 'select-objects');
                    }
                });
            }
            _this.stepChanged('select-titles');
        });
    };
    CreateCaseComponent.prototype.onCallbackRegister = function ($event) {
        this.onHelpIdChanged = $event.onHelpIdChanged;
    };
    return CreateCaseComponent;
}());
exports.CreateCaseComponent = CreateCaseComponent;
