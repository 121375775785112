"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var angular_1 = require("@uirouter/angular");
var CreateCaseSummaryComponent = /** @class */ (function () {
    function CreateCaseSummaryComponent(stateService) {
        this.stateService = stateService;
        this.submitCallback = new core_1.EventEmitter();
        this.submit = this.submit.bind(this);
        this.showCreatedCase = this.showCreatedCase.bind(this);
    }
    CreateCaseSummaryComponent.prototype.ngOnInit = function () {
    };
    CreateCaseSummaryComponent.prototype.submit = function () {
        this.submitCallback.emit();
    };
    CreateCaseSummaryComponent.prototype.showCreatedCase = function () {
        this.stateService.go('symap.project.cases.detail', { id: this.data.id });
    };
    return CreateCaseSummaryComponent;
}());
exports.CreateCaseSummaryComponent = CreateCaseSummaryComponent;
