"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var _ = require("lodash");
var geometric_plan_request_status_enum_1 = require("@app/ps/enums/geometric-plan-request-status.enum");
var list_service_1 = require("@app/common/services/list.service");
var auth_service_1 = require("@app/common/services/auth.service");
var modules_service_1 = require("@app/common/services/modules.service");
var username_pipe_1 = require("@app/common/pipes/username.pipe");
var NotificationConfigComponent = /** @class */ (function () {
    function NotificationConfigComponent(restangularSettings, userNamePipe, listService, authService, moduleService) {
        this.restangularSettings = restangularSettings;
        this.userNamePipe = userNamePipe;
        this.listService = listService;
        this.authService = authService;
        this.moduleService = moduleService;
        this.loading = true;
        this.module = this.authService.getActiveApplication();
        this.project = this.authService.getActualProject();
        this.geometricPlanStatuses = [
            { key: geometric_plan_request_status_enum_1.GeometricPlanRequestStatusEnum.CREATED, name: 'Založení záznamu o GP' },
            { key: geometric_plan_request_status_enum_1.GeometricPlanRequestStatusEnum.CONCEPT_SENT_TO_CONFIRMATION, name: 'Koncept GP ke schválení' },
            { key: geometric_plan_request_status_enum_1.GeometricPlanRequestStatusEnum.CONCEPT_DISAPPROVED, name: 'Odmítnutý koncept GP' },
            { key: geometric_plan_request_status_enum_1.GeometricPlanRequestStatusEnum.CONCEPT_APPROVED, name: 'Schválený koncept GP' },
            { key: geometric_plan_request_status_enum_1.GeometricPlanRequestStatusEnum.IMPORT_REQUESTED, name: 'Požadavek na vložení nového stavu dle GP do aplikace' },
            { key: geometric_plan_request_status_enum_1.GeometricPlanRequestStatusEnum.IMPORTED, name: 'GP zavedený do aplikace' },
            { key: geometric_plan_request_status_enum_1.GeometricPlanRequestStatusEnum.REGISTERED, name: 'GP zapsaný v KN (ÚR)' },
        ];
        this.geometricPlanModuleEnabled = this.authService.getActualProject().geometricPlanModuleEnabled;
        this.reloadOptionsRegisterUser = this.reloadOptionsRegisterUser.bind(this);
        this.onUserSelected = this.onUserSelected.bind(this);
        this.onUserUnselected = this.onUserUnselected.bind(this);
        this.onSave = this.onSave.bind(this);
    }
    NotificationConfigComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b, _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.restangularSettings.all(this.module + "/roles").getList()];
                    case 1:
                        _a.roles = _d.sent();
                        _b = this;
                        return [4 /*yield*/, this.loadUsers()];
                    case 2:
                        _b.users = _d.sent();
                        _c = this;
                        return [4 /*yield*/, this.loadCaseStatuses()];
                    case 3:
                        _c.caseStatuses = _d.sent();
                        this.caseStatuses.forEach(function (s) {
                            if (['SentInvestor', 'ApprovedInvestor'].includes(s.key)) {
                                s.name += ' a krok zpět ze stavu';
                            }
                        });
                        return [4 /*yield*/, this.loadNotificationConfigs()];
                    case 4:
                        _d.sent();
                        this.loading = false;
                        if (this.saveNotificationConfigRegister) {
                            this.saveNotificationConfigRegister(this.onSave);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    NotificationConfigComponent.prototype.getStatusByConfig = function (config) {
        var caseStatus = _.find(this.caseStatuses, { key: config.statusKey });
        if (caseStatus) {
            return caseStatus;
        }
        return _.find(this.geometricPlanStatuses, { key: config.statusKey });
    };
    NotificationConfigComponent.prototype.isRoleEnabled = function (config, role) {
        return _.find(config.roles, function (r) { return r.id === role.id; });
    };
    NotificationConfigComponent.prototype.onUserSelected = function (config, user) {
        config.users.push(user);
        config.reloadOptionsUser();
        this.userSelected = undefined;
    };
    NotificationConfigComponent.prototype.onUserUnselected = function (config, user) {
        _.remove(config.users, function (u) { return u.id === user.id; });
        config.reloadOptionsUser();
    };
    NotificationConfigComponent.prototype.onToggleRole = function (config, role) {
        if (this.isRoleEnabled(config, role)) {
            _.remove(config.roles, function (r) { return r.id === role.id; });
        }
        else {
            config.roles.push(role);
        }
    };
    NotificationConfigComponent.prototype.onToggleCustomUsers = function (config) {
        config.customUsers = !config.customUsers;
        if (!config.customUsers) {
            _.remove(config.users);
        }
    };
    NotificationConfigComponent.prototype.reloadOptionsRegisterUser = function (config) {
        if (!config.reloadOptionsRegisterUser) {
            config.reloadOptionsRegisterUser = function (reloadOptions) {
                config.reloadOptionsUser = reloadOptions;
            };
            config.userFilter = function (user) {
                return !_.find(config.users, function (u) { return u.id === user.id; });
            };
        }
        return config.reloadOptionsRegisterUser;
    };
    NotificationConfigComponent.prototype.getUserRole = function (user) {
        var role = _.find(user.roles, { projectKey: this.project.key });
        if (!role) {
            return '';
        }
        return role.roleDescription;
    };
    NotificationConfigComponent.prototype.onSave = function () {
        var _this = this;
        var configsMapped = this.mapToResource(this.notificationConfigsMapped);
        return this.moduleService.uploadProjectNotificationConfigs(this.project.key, configsMapped)
            .then(function () {
            return _this.loadNotificationConfigs();
        });
    };
    NotificationConfigComponent.prototype.loadCaseStatuses = function () {
        var statusList = this.listService.createList('case-statuses', { filters: { key: ['SentInvestor', 'ApprovedInvestor', 'DeclinedInvestor'] }, limit: null });
        return this.listService.fetchResult(statusList).then(function () {
            return statusList.list;
        });
    };
    NotificationConfigComponent.prototype.loadNotificationConfigs = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.moduleService.getNotificationConfigs(this.module, this.project.key, undefined)];
                    case 1:
                        _a.notificationConfigs = _b.sent();
                        this.notificationConfigsMapped = this.mapFromResource(this.notificationConfigs);
                        return [2 /*return*/];
                }
            });
        });
    };
    NotificationConfigComponent.prototype.loadUsers = function () {
        var projectUsersFilter = {
            filters: {
                applications: {
                    values: [this.module]
                },
                projects: {
                    values: [this.project.key]
                },
            },
            limit: null,
        };
        var projectUserList = this.listService.createList('users/list', projectUsersFilter, this.restangularSettings);
        return this.listService.fetchResult(projectUserList).then(function () {
            return projectUserList.list;
        });
    };
    NotificationConfigComponent.prototype.mapFromResource = function (configs) {
        var result = [];
        var populate = function (user, role, statusKey, statusType) {
            var configMapped = _.find(result, { statusKey: statusKey });
            if (configMapped) {
                if (user) {
                    configMapped.users.push(user);
                }
                if (role) {
                    configMapped.roles.push(role);
                }
            }
            else {
                result.push({ statusKey: statusKey, statusType: statusType, users: (user ? [user] : []), roles: (role ? [role] : []) });
            }
        };
        configs.forEach(function (config) {
            config.emailNotification.caseStatuses.forEach(function (statusKey) { return populate(config.user, config.role, statusKey, 'caseStatuses'); });
            config.emailNotification.geometricPlanStatuses.forEach(function (statusKey) { return populate(config.user, config.role, statusKey, 'geometricPlanStatuses'); });
        });
        var fillEmpty = function (statusKey, statusType) {
            var exists = _.find(result, { statusKey: statusKey });
            if (!exists) {
                result.push({ statusKey: statusKey, statusType: statusType, users: [], roles: [] });
            }
        };
        this.caseStatuses.forEach(function (status) { return fillEmpty(status.key, 'caseStatuses'); });
        this.geometricPlanStatuses.forEach(function (status) { return fillEmpty(status.key, 'geometricPlanStatuses'); });
        result = result.map(function (c) {
            c.customUsers = c.users.length > 0;
            return c;
        });
        return result;
    };
    NotificationConfigComponent.prototype.mapToResource = function (configsMapped) {
        var _this = this;
        var result = [];
        var populate = function (type, entity, configMapped) {
            var matcher = (type === 'user'
                ? (function (c) { return c.user && c.user.id === entity.id; })
                : (function (c) { return c.role && c.role.id === entity.id; }));
            var config = _.find(result, matcher);
            if (config) {
                config.emailNotification[configMapped.statusType].push(configMapped.statusKey);
            }
            else {
                var newConfig = {
                    project: _this.project,
                    emailNotification: { caseStatuses: [], geometricPlanStatuses: [] }
                };
                newConfig[type] = entity;
                newConfig.emailNotification[configMapped.statusType].push(configMapped.statusKey);
                result.push(newConfig);
            }
        };
        configsMapped.forEach(function (configMapped) {
            configMapped.users.forEach(function (user) { return populate('user', user, configMapped); });
            configMapped.roles.forEach(function (role) { return populate('role', role, configMapped); });
        });
        result.forEach(function (config) {
            var existing;
            if (config.user) {
                existing = _.find(_this.notificationConfigs, function (c) { return c.user && c.user.id === config.user.id; });
            }
            if (config.role) {
                existing = _.find(_this.notificationConfigs, function (c) { return c.role && c.role.id === config.role.id; });
            }
            if (existing) {
                config.id = existing.id;
            }
            config.projectKey = _this.project.key;
            config.application = _this.module;
        });
        return result;
    };
    return NotificationConfigComponent;
}());
exports.NotificationConfigComponent = NotificationConfigComponent;
