"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var angular_1 = require("@uirouter/angular");
var CustomerEditComponent = /** @class */ (function () {
    function CustomerEditComponent(stateService) {
        this.stateService = stateService;
        this.pageTitle = this.stateService.current.data.title;
    }
    CustomerEditComponent.prototype.ngOnInit = function () {
        if (!this.customer) {
            this.customer = {
                actingPersons: new Array(),
                organizationalUnits: new Array(),
            };
        }
        this.onSubmit = this.onSubmit.bind(this);
    };
    CustomerEditComponent.prototype.onSubmit = function () {
        this.stateService.go('^', undefined, { reload: true, });
    };
    return CustomerEditComponent;
}());
exports.CustomerEditComponent = CustomerEditComponent;
