"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var auth_service_1 = require("@app/common/services/auth.service");
var project_subject_name_pipe_1 = require("@app/ps/pipes/project-subject-name.pipe");
var common_1 = require("@angular/common");
var username_pipe_1 = require("@app/common/pipes/username.pipe");
var brand_translate_pipe_1 = require("@app/common/pipes/brand-translate.pipe");
var organizational_unit_name_pipe_1 = require("@app/common/pipes/organizational-unit-name.pipe");
var project_state_pipe_1 = require("@app/ps/pipes/project-state.pipe");
var i0 = require("@angular/core");
var i1 = require("../../ps/pipes/project-subject-name.pipe");
var i2 = require("../../common/pipes/organizational-unit-name.pipe");
var i3 = require("../../common/pipes/username.pipe");
var i4 = require("../../common/services/auth.service");
var i5 = require("@angular/common");
var i6 = require("../../common/pipes/brand-translate.pipe");
var i7 = require("../../ps/pipes/project-state.pipe");
var ManagerReportProjectsService = /** @class */ (function () {
    function ManagerReportProjectsService(projectSubjectPipe, organizationalUnitNamePipe, userNamePipe, authService, numberPipe, datePipe, brandTranslatePipe, projectStatePipe) {
        this.projectSubjectPipe = projectSubjectPipe;
        this.organizationalUnitNamePipe = organizationalUnitNamePipe;
        this.userNamePipe = userNamePipe;
        this.authService = authService;
        this.numberPipe = numberPipe;
        this.datePipe = datePipe;
        this.brandTranslatePipe = brandTranslatePipe;
        this.projectStatePipe = projectStatePipe;
    }
    ManagerReportProjectsService.prototype.createFromApiBulk = function (projects) {
        var _this = this;
        return projects.map(function (p) { return _this.createFromApi(p); });
    };
    ManagerReportProjectsService.prototype.createFromApi = function (project) {
        return {
            hasDashboard: false,
            id: project.id,
            isprofond: project.isprofond,
            name: project.name,
            key: project.key,
            unit: project.unit,
            region: project.region,
            manager: this.userNamePipe.transform(project.manager, false),
            investor: this.organizationalUnitNamePipe.transform(project.investor ? project.investor.customerOrganizationalUnit : undefined),
            infrastructure: project.infrastructure,
            lastActivityDate: project.lastActivity ? project.lastActivity.timestamp : undefined,
            lastActivityAuthor: project.lastActivity ? this.userNamePipe.transform(project.lastActivity.user, false) : undefined,
            projectStartDate: project.projectStartDate,
            state: project.state,
            stateName: this.projectStatePipe.transform(project),
            phase: project.phase,
            propertyActivityProcessorCompany: project.propertyActivityProcessor ? project.propertyActivityProcessor.ssoCompanyName : undefined,
            propertyActivityProcessorName: this.userNamePipe.transform(project.propertyActivityProcessor, false),
            landTakeDocumentationAuthorCompany: project.landTakeDocumentationAuthor ? project.landTakeDocumentationAuthor.companyName : undefined,
            landTakeDocumentationAuthorName: this.projectSubjectPipe.transform(project.landTakeDocumentationAuthor, false, false),
            designerCompany: project.designer ? project.designer.companyName : undefined,
            designerName: this.projectSubjectPipe.transform(project.designer, false, false),
            readinessPermanentOccupations: project.readinessPermanentOccupations,
            readinessTemporaryUnderOccupations: project.readinessTemporaryUnderOccupations,
            readinessTemporaryOverOccupations: project.readinessTemporaryOverOccupations,
            readinessTemporaryEasements: project.readinessTemporaryEasements,
        };
    };
    ManagerReportProjectsService.mapDashboardDataOnProject = function (project, dashboard) {
        project.hasDashboard = true;
        project.activity = dashboard.activity;
        project.total = dashboard.total;
        project.exists = dashboard.total - dashboard.toCreate;
        project.done = dashboard.done;
        project.donePercentage = ManagerReportProjectsService.getPercentage(dashboard.done, dashboard.total);
        project.tzTotal = dashboard.tzTotal;
        project.tzDone = dashboard.tzDone;
        project.tzToCreate = dashboard.tzToCreate;
        project.dznrTotal = dashboard.dznrTotal;
        project.dznrDone = dashboard.dznrDone;
        project.dznrToCreate = dashboard.dznrToCreate;
        project.dzdrTotal = dashboard.dzdrTotal;
        project.dzdrDone = dashboard.dzdrDone;
        project.dzdrToCreate = dashboard.dzdrToCreate;
        project.vbTotal = dashboard.vbTotal;
        project.vbDone = dashboard.vbDone;
        project.vbToCreate = dashboard.vbToCreate;
        project.expropriationTotal = dashboard.expropriationTotal;
        project.parcelTotal = dashboard.parcelTotal;
        project.createdOpinions = dashboard.createdOpinions;
        project.createdCases = dashboard.createdCases;
        project.changedCases = dashboard.changedCases;
        project.changedCaseStatuses = dashboard.changedCaseStatuses;
        return project;
    };
    ManagerReportProjectsService.getPercentage = function (done, total) {
        if (total === undefined || done === undefined) {
            return undefined;
        }
        if (total === 0 || done === 0) {
            return 0;
        }
        return (done / total * 100);
    };
    ManagerReportProjectsService.ngInjectableDef = i0.defineInjectable({ factory: function ManagerReportProjectsService_Factory() { return new ManagerReportProjectsService(i0.inject(i1.ProjectSubjectNamePipe), i0.inject(i2.OrganizationalUnitNamePipe), i0.inject(i3.UserNamePipe), i0.inject(i4.AuthService), i0.inject(i5.DecimalPipe), i0.inject(i5.DatePipe), i0.inject(i6.BrandTranslatePipe), i0.inject(i7.ProjectStatePipe)); }, token: ManagerReportProjectsService, providedIn: "root" });
    return ManagerReportProjectsService;
}());
exports.ManagerReportProjectsService = ManagerReportProjectsService;
