"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("@angular/common");
var i2 = require("../../../../../common/components/button/button.component.ngfactory");
var i3 = require("../../../../../common/components/button/button.component");
var i4 = require("./bulk-price-result.component");
var styles_BulkPriceResultComponent = [];
var RenderType_BulkPriceResultComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_BulkPriceResultComponent, data: {} });
exports.RenderType_BulkPriceResultComponent = RenderType_BulkPriceResultComponent;
function View_BulkPriceResultComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" Z toho ", " \u00FAsp\u011B\u0161n\u011B. "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.success; _ck(_v, 1, 0, currVal_0); }); }
function View_BulkPriceResultComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 6, "div", [["class", "jumbotron text-center"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, [" Nahr\u00E1no ", " z "])), (_l()(), i0.ɵeld(3, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["", ""])), (_l()(), i0.ɵted(-1, null, [". "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_BulkPriceResultComponent_1)), i0.ɵdid(7, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(8, 0, null, null, 3, "div", [["class", "mt-10"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 2, "gmt-button", [], null, null, null, i2.View_ButtonComponent_0, i2.RenderType_ButtonComponent)), i0.ɵdid(10, 114688, null, 0, i3.ButtonComponent, ["ErrorHandlerService", i0.ChangeDetectorRef], { clickAction: [0, "clickAction"], gmtDisabled: [1, "gmtDisabled"] }, null), (_l()(), i0.ɵted(-1, 0, ["Hotovo"]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = ((_co.fail + _co.success) === _co.data.occupations.length); _ck(_v, 7, 0, currVal_2); var currVal_3 = _co.onSubmit; var currVal_4 = ((_co.fail + _co.success) !== _co.data.occupations.length); _ck(_v, 10, 0, currVal_3, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.fail + _co.success); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.data.occupations.length; _ck(_v, 4, 0, currVal_1); }); }
exports.View_BulkPriceResultComponent_0 = View_BulkPriceResultComponent_0;
function View_BulkPriceResultComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "bulk-price-result", [], null, null, null, View_BulkPriceResultComponent_0, RenderType_BulkPriceResultComponent)), i0.ɵdid(1, 114688, null, 0, i4.BulkPriceResultComponent, ["Restangular"], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_BulkPriceResultComponent_Host_0 = View_BulkPriceResultComponent_Host_0;
var BulkPriceResultComponentNgFactory = i0.ɵccf("bulk-price-result", i4.BulkPriceResultComponent, View_BulkPriceResultComponent_Host_0, { data: "data" }, { backCallback: "backCallback", submitCallback: "submitCallback" }, []);
exports.BulkPriceResultComponentNgFactory = BulkPriceResultComponentNgFactory;
