"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var _ = require("lodash");
var list_service_1 = require("@app/common/services/list.service");
var case_service_1 = require("@app/ps/services/case.service");
var parcel_service_1 = require("@app/ps/services/parcel.service");
var word_service_1 = require("@app/common/services/word.service");
var dialog_service_1 = require("@app/dialog/dialog.service");
var class_name_enum_1 = require("@app/dialog/class-name.enum");
var title_create_subject_component_1 = require("@app/ps/components/title-create-subject/title-create-subject.component");
var i0 = require("@angular/core");
var i1 = require("../../common/services/word.service");
var i2 = require("./case.service");
var i3 = require("../../common/services/list.service");
var i4 = require("./parcel.service");
var i5 = require("../../dialog/dialog.service");
var TitleDetailCreateCaseSelectionService = /** @class */ (function () {
    function TitleDetailCreateCaseSelectionService(restangular, alertService, wordService, caseService, listService, parcelService, dialogService) {
        var _this = this;
        this.restangular = restangular;
        this.alertService = alertService;
        this.wordService = wordService;
        this.caseService = caseService;
        this.listService = listService;
        this.parcelService = parcelService;
        this.dialogService = dialogService;
        this.checkedAllOwnerships = { value: false };
        this.mutuallyExclusiveObligationTypes = new Map([
            ['RentalContractShorterThan1YearLiability', ['RentalContractCommonLiability']],
            ['RentalContractLongerThan1YearLiability', ['RentalContractCommonLiability']],
            ['RentalContractCommonLiability', ['RentalContractShorterThan1YearLiability', 'RentalContractLongerThan1YearLiability']],
        ]);
        this.removeConstructionObjectsFiltered = function (constructionObject, listName, object) {
            var caseConstructionObject = _.find(_this.scope.dataConstructionObjectsFiltered, function (caseConstructionObject) {
                return caseConstructionObject.id === constructionObject.id;
            });
            _.remove(caseConstructionObject[listName], { id: object.id });
            caseConstructionObject[listName + 'Length']--;
            caseConstructionObject.selected = caseConstructionObject.selectedOriginal && !_this.constructionObjectMultipleTitles(constructionObject);
            if (caseConstructionObject.occupationsLength === 0 && caseConstructionObject.easementsLength === 0) {
                _.pull(_this.scope.dataConstructionObjectsFiltered, caseConstructionObject);
                if (caseConstructionObject.selected && _this.scope.checklistConstructionObject.isChecked(caseConstructionObject) && caseConstructionObject.occupations.length === 0 && caseConstructionObject.easements.length === 0) {
                    _this.scope.checklistConstructionObject.toggleSelection(caseConstructionObject);
                    _this.scope.checklistConstructionObjectLocal.toggleSelection(caseConstructionObject);
                }
            }
        };
        // this.disableByOpinions = this.disableByOpinions.bind(this);
        // this.constructionObjectToCase = this.constructionObjectToCase.bind(this);
        // this.addConstructionObjectsFiltered = this.addConstructionObjectsFiltered.bind(this);
        // this.removeConstructionObjectsFiltered = this.removeConstructionObjectsFiltered.bind(this);
        this.toggleSelectionGroup = this.toggleSelectionGroup.bind(this);
        this.toggleSelectionOwnership = this.toggleSelectionOwnership.bind(this);
        this.toggleSelectionCompensationPrice = this.toggleSelectionCompensationPrice.bind(this);
        this.toggleSelectionEasement = this.toggleSelectionEasement.bind(this);
        this.toggleSelectionConstructionObject = this.toggleSelectionConstructionObject.bind(this);
        this.toggleSelectionBuilding = this.toggleSelectionBuilding.bind(this);
        // this.checkMutuallyExclusiveObligationTypes = this.checkMutuallyExclusiveObligationTypes.bind(this);
        // this.ownershipCases = this.ownershipCases.bind(this);
        // this.getOwnershipDisabledMessage = this.getOwnershipDisabledMessage.bind(this);
        // this.isOwnershipEnabled = this.isOwnershipEnabled.bind(this);
        // this.getGroupDisabledMessage = this.getGroupDisabledMessage.bind(this);
        // this.isGroupEnabled = this.isGroupEnabled.bind(this);
        // this.getEasementDisabledMessage = this.getEasementDisabledMessage.bind(this);
        // this.isEasementEnabled = this.isEasementEnabled.bind(this);
        // this.constructionObjectMultipleTitles = this.constructionObjectMultipleTitles.bind(this);
        // this.getConstructionObjectDisabledMessage = this.getConstructionObjectDisabledMessage.bind(this);
        // this.isConstructionObjectEnabled = this.isConstructionObjectEnabled.bind(this);
        // this.getBuildingDisabledMessage = this.getBuildingDisabledMessage.bind(this);
        // this.isBuildingEnabled = this.isBuildingEnabled.bind(this);
        // this.subjectReloadRegister = this.subjectReloadRegister.bind(this);
    }
    TitleDetailCreateCaseSelectionService.prototype.disableByOpinions = function () {
        var _this = this;
        // enable only one other opinion on contract or any number of expert opinions
        if (this.scope.data.obligation.computePrice && this.scope.data.obligation.objectTypes.occupationGroupKeys && this.scope.data.obligation.objectTypes.occupationGroupKeys.length) {
            var occupationType_1 = _.some(this.scope.data.obligation.objectTypes.occupationGroupKeys, function (key) { return key === 'underOneYear' || key === 'overOneYear'; }) ? 'T' : 'P';
            var selectedOpinions_1 = _.union(this.scope.dataOpinions);
            var usedAnyParcelOpinion_1 = false;
            var filterOpinionsForGroup_1 = function (groupParcel) {
                // compensationPrice can be used only on one temporary occupation by parcel
                var compensationPriceAlreadyAttached = Object.keys(_this.scope.dataOccupationGroupsFiltered)
                    .find(function (key) {
                    var group = _this.scope.dataOccupationGroupsFiltered[key];
                    return key !== 'permanent' && group.find(function (groupItem) {
                        return (groupItem.occupations[0].compensationPrice
                            && groupItem.occupations[0].id !== groupParcel.occupations[0].id
                            && groupItem.occupations[0].parcel.id === groupParcel.parcel.id);
                    });
                });
                if (occupationType_1 === 'T' && (!groupParcel.occupations[0].compensationPrice || compensationPriceAlreadyAttached)) {
                    return [];
                }
                return _.filter(selectedOpinions_1, function (opinion) {
                    // opinion must contain parcel
                    // if opinion is not for certain ownership then none of other selected ownership have their specific opinion for current parcel
                    // otherwise only this is one ownership must be selected
                    return (opinion.otherOwnership ||
                        _.every(_this.scope.checklistOwnership.checkedItems, function (caseOwnership) {
                            return !_.some(_this.scope.dataParcelPrices, { parcel: { id: groupParcel.parcel.id }, opinion: { otherOwnership: { id: caseOwnership.ownership.id } } });
                        })) &&
                        (!opinion.otherOwnership ||
                            (_.every(_this.scope.checklistOwnership.checkedItems, function (caseOwnership) {
                                return opinion.otherOwnership.id === caseOwnership.ownership.id;
                            }) && _.some(opinion.parcelPrices, { parcel: { id: groupParcel.parcel.id } })));
                });
            };
            var filterOpinionsForBuilding_1 = function (building) {
                return _.filter(selectedOpinions_1, function (opinion) {
                    return (opinion.otherOwnership ||
                        _.every(_this.scope.checklistOwnership.checkedItems, function (caseOwnership) {
                            return !_.some(_this.scope.dataParcelPrices, { building: { id: building.id }, opinion: { otherOwnership: { id: caseOwnership.ownership.id } } });
                        })) &&
                        (!opinion.otherOwnership ||
                            (_.every(_this.scope.checklistOwnership.checkedItems, function (caseOwnership) {
                                return opinion.otherOwnership.id === caseOwnership.ownership.id;
                            }) && _.some(opinion.parcelPrices, { building: { id: building.id } })));
                });
            };
            var filterOpinionsForOwnership_1 = function (ownership) {
                return _.filter(selectedOpinions_1, function (opinion) {
                    return (opinion.otherOwnership && opinion.otherOwnership.id === ownership.id &&
                        _.every(_this.scope.checklistGroup.checkedItems, function (groupParcel) {
                            return _.some(opinion.parcelPrices, { parcel: { id: groupParcel.parcel.id } });
                        }) &&
                        _.every(_this.scope.checklistBuilding.checkedItems, function (building) {
                            return _.some(opinion.parcelPrices, { building: { id: building.id } });
                        })) ||
                        (!opinion.otherOwnership &&
                            _.every(_this.scope.checklistGroup.checkedItems, function (groupParcel) {
                                return !_.some(_this.scope.dataParcelPrices, { parcel: { id: groupParcel.parcel.id }, opinion: { otherOwnership: { id: ownership.id } } });
                            }) &&
                            _.every(_this.scope.checklistBuilding.checkedItems, function (building) {
                                return !_.some(_this.scope.dataParcelPrices, { building: { id: building.id }, opinion: { otherOwnership: { id: ownership.id } } });
                            }));
                });
            };
            this.scope.checklistGroup.checkedItems.forEach(function (groupParcel) {
                if (occupationType_1 === 'P' || groupParcel.occupations[0].compensationPrice) {
                    selectedOpinions_1 = _.intersection(selectedOpinions_1, filterOpinionsForGroup_1(groupParcel));
                    usedAnyParcelOpinion_1 = true;
                }
            });
            this.scope.checklistBuilding.checkedItems.forEach(function (building) {
                selectedOpinions_1 = _.intersection(selectedOpinions_1, filterOpinionsForBuilding_1(building));
            });
            this.scope.checklistOwnership.checkedItems.forEach(function (caseOwnership) {
                selectedOpinions_1 = _.intersection(selectedOpinions_1, filterOpinionsForOwnership_1(caseOwnership.ownership));
            });
            // walks through available parcels (grouped occupations) and check its existence in opinions. Additionally checks if there is not
            // on opinion that is specific for any selected ownership
            Object.keys(this.scope.dataOccupationGroupsFiltered).forEach(function (key) {
                var group = _this.scope.dataOccupationGroupsFiltered[key];
                group.forEach(function (groupParcel) {
                    groupParcel.disabled = false;
                    groupParcel.disabledCompensationPrice = false;
                    if ((_this.scope.checklistGroup.checkedItems.length || _this.scope.checklistOwnership.checkedItems.length) && !filterOpinionsForGroup_1(groupParcel).length && (occupationType_1 === 'P' || groupParcel.occupations[0].compensationPrice)) {
                        groupParcel.disabled = true;
                    }
                    if (!groupParcel.occupations[0].compensationPrice) {
                        groupParcel.occupations[0].compensationPrice = true;
                        if (occupationType_1 === 'T' && groupParcel.parcel.compensationPrice > 0 && !filterOpinionsForGroup_1(groupParcel).length) {
                            groupParcel.disabledCompensationPrice = true;
                        }
                        groupParcel.occupations[0].compensationPrice = false;
                    }
                });
            });
            _.forEach(this.scope.dataBuildingsFiltered, function (building) {
                building.disabled = false;
                if (!filterOpinionsForBuilding_1(building.building).length) {
                    building.disabled = true;
                }
            });
            // walks through available ownerships in same way as in previous code
            _.forEach(this.scope.dataOwnerships, function (caseOwnership) {
                caseOwnership.disabled = false;
                if ((_this.scope.checklistGroup.checkedItems.length || _this.scope.checklistOwnership.checkedItems.length) && !filterOpinionsForOwnership_1(caseOwnership.ownership).length && (occupationType_1 === 'P' || usedAnyParcelOpinion_1)) {
                    caseOwnership.disabled = true;
                }
            });
        }
        else {
            Object.keys(this.scope.dataOccupationGroupsFiltered).forEach(function (key) {
                var group = _this.scope.dataOccupationGroupsFiltered[key];
                group.forEach(function (groupParcel) {
                    groupParcel.disabled = false;
                    groupParcel.disabledCompensationPrice = false;
                });
            });
            _.forEach(this.scope.dataBuildingsFiltered, function (building) {
                building.disabled = false;
            });
            _.forEach(this.scope.dataOwnerships, function (caseOwnership) {
                caseOwnership.disabled = false;
            });
        }
    };
    TitleDetailCreateCaseSelectionService.prototype.constructionObjectToCase = function (constructionObject, selected) {
        if (selected === void 0) { selected = true; }
        var co = {
            id: constructionObject.id,
            constructionObject: constructionObject,
            occupations: this.scope.checklistGroupLocal ? _.filter(_.flatMap(this.scope.checklistGroupLocal.checkedItems, 'occupations'), function (occupation) { return _.some(occupation.constructionObjects, { id: constructionObject.id }); }) : [],
            easements: this.scope.checklistEasementLocal ? _.filter(this.scope.checklistEasementLocal.checkedItems, function (easement) { return _.some(easement.constructionObjects, { id: constructionObject.id }); }) : [],
            occupationsLength: 0,
            easementsLength: 0,
            selected: selected,
            selectedOriginal: selected,
        };
        return co;
    };
    TitleDetailCreateCaseSelectionService.prototype.addConstructionObjectsFiltered = function (constructionObject, listName, object) {
        var caseConstructionObject = _.find(this.scope.dataConstructionObjectsFiltered, { id: constructionObject.id });
        if (!caseConstructionObject) {
            var found = _.find(this.scope.checklistConstructionObject.checkedItems, { id: constructionObject.id });
            if (found) {
                caseConstructionObject = this.constructionObjectToCase(constructionObject, found.selectedOriginal && !this.constructionObjectMultipleTitles(constructionObject));
                found.occupations = caseConstructionObject.occupations;
                found.easements = caseConstructionObject.easements;
                this.scope.dataConstructionObjectsFiltered.push(caseConstructionObject);
            }
        }
        if (!caseConstructionObject) {
            caseConstructionObject = this.constructionObjectToCase(constructionObject);
            this.scope.dataConstructionObjectsFiltered.push(caseConstructionObject);
            this.toggleSelectionConstructionObject(caseConstructionObject);
            // this.scope.checkedAllConstructionObjects = false;
        }
        if (listName && object) {
            if (!_.some(caseConstructionObject[listName], { id: object.id })) {
                caseConstructionObject[listName].push(object);
                caseConstructionObject.selected = caseConstructionObject.selectedOriginal && !this.constructionObjectMultipleTitles(constructionObject);
            }
            // Count length specifically for current scope
            caseConstructionObject[listName + 'Length']++;
        }
    };
    TitleDetailCreateCaseSelectionService.prototype.toggleSelectionGroup = function (item) {
        var _this = this;
        if (!this.isGroupEnabled(item)) {
            return;
        }
        var checked = this.scope.checklistGroup.toggleSelection(item);
        this.scope.checklistGroupLocal.toggleSelection(item);
        this.disableByOpinions();
        // update selection of construction objects
        if (this.scope.data.obligation.selectConstructionObjectRequired) {
            item.occupations.forEach(function (occupation) {
                occupation.constructionObjects.forEach(function (constructionObject) {
                    if (!checked) {
                        // remove occupation from occupation list and remove selection if not any remaining
                        _this.removeConstructionObjectsFiltered(constructionObject, 'occupations', occupation);
                    }
                    else {
                        // add to occupation list or add new contruction object
                        _this.addConstructionObjectsFiltered(constructionObject, 'occupations', occupation);
                    }
                });
            });
        }
        if (!checked) {
            this.scope.checkedAllGroups[item.key] = false;
        }
    };
    TitleDetailCreateCaseSelectionService.prototype.toggleSelectionOwnership = function (item) {
        if (!this.isOwnershipEnabled(item)) {
            return;
        }
        var checked = this.scope.checklistOwnership.toggleSelection(item);
        this.disableByOpinions();
        if (!checked) {
            this.checkedAllOwnerships.value = false;
        }
    };
    TitleDetailCreateCaseSelectionService.prototype.toggleSelectionCompensationPrice = function (item) {
        if (item.disabledCompensationPrice || item.parcel.compensationOpinionsShare !== 100) {
            return;
        }
        item.occupations[0].compensationPrice = !item.occupations[0].compensationPrice;
        this.disableByOpinions();
    };
    TitleDetailCreateCaseSelectionService.prototype.toggleSelectionEasement = function (item) {
        var _this = this;
        if (!this.isEasementEnabled(item)) {
            return;
        }
        var checked = this.scope.checklistEasement.toggleSelection(item);
        this.scope.checklistEasementLocal.toggleSelection(item);
        // update selection of construction objects
        if (this.scope.data.obligation.selectConstructionObjectRequired) {
            item.constructionObjects.forEach(function (constructionObject) {
                if (!checked) {
                    // remove easement from easement list and remove selection if not any remaining
                    _this.removeConstructionObjectsFiltered(constructionObject, 'easements', item);
                }
                else {
                    // add to easement list or add new contruction object
                    _this.addConstructionObjectsFiltered(constructionObject, 'easements', item);
                }
            });
        }
        if (!checked) {
            this.scope.checkedAllEasements = false;
        }
        this.disableByOpinions();
        return checked;
    };
    TitleDetailCreateCaseSelectionService.prototype.toggleSelectionConstructionObject = function (item) {
        if (!this.isConstructionObjectEnabled(item)) {
            return;
        }
        var selected = this.scope.checklistConstructionObject.toggleSelection(item);
        this.scope.checklistConstructionObjectLocal.toggleSelection(item);
        if (!selected) {
            this.scope.checkedAllConstructionObjects = false;
        }
    };
    TitleDetailCreateCaseSelectionService.prototype.toggleSelectionBuilding = function (dataBuilding) {
        if (!this.isBuildingEnabled(dataBuilding)) {
            return;
        }
        else {
            delete dataBuilding.disabled;
        }
        this.scope.checklistBuilding.toggleSelection(dataBuilding.building);
        var selected = this.scope.checklistBuildingLocal.toggleSelection(dataBuilding.building);
        this.disableByOpinions();
        if (!selected) {
            this.scope.checkedAllBuildings = false;
        }
    };
    TitleDetailCreateCaseSelectionService.prototype.checkMutuallyExclusiveObligationTypes = function (obligationType1, obligationType2) {
        if (obligationType1 === obligationType2) {
            return true;
        }
        var hasRestriction = this.mutuallyExclusiveObligationTypes.get(obligationType1);
        return hasRestriction
            ? hasRestriction.includes(obligationType2)
            : false;
    };
    TitleDetailCreateCaseSelectionService.prototype.ownershipCases = function (dataOwnership) {
        var _this = this;
        if (this.scope.dataCases === undefined) {
            return [];
        }
        return this.scope.dataCases.filter(function (businessCase) {
            // of same or exclusive type. In case of transfer case also must not be observed against cadastre (expected not to be inserted)
            return _this.checkMutuallyExclusiveObligationTypes(_this.scope.data.obligation.type, businessCase.obligation.type) &&
                (_this.scope.data.obligation.type !== 'SalesContractLiability' || businessCase.checkCadastre) &&
                // and containing any of already selected occupations
                ((_this.scope.checklistGroup.checkedItems.length > 0 && _.some(businessCase.occupations, function (occupation) {
                    return _.some(_this.scope.checklistGroup.checkedItems, function (group) {
                        return _.some(group.occupations, function (groupOccupation) {
                            return occupation.zabidpar === groupOccupation.zabidpar && occupation.zabku === groupOccupation.zabku && occupation.zabst === groupOccupation.zabst && occupation.zabcis === groupOccupation.zabcis && occupation.zabtyp === groupOccupation.zabtyp;
                        });
                    });
                })) ||
                    // or containing any of already selected easements
                    (_this.scope.checklistEasement.checkedItems.length > 0 && _.some(businessCase.easements, function (caseEasement) {
                        return _.some(_this.scope.checklistEasement.checkedItems, function (dataEasement) {
                            return dataEasement.idpar === caseEasement.idpar && dataEasement.oznacVb === caseEasement.oznacVb && caseEasement.geometricPlan.id === dataEasement.geometricPlan.id;
                        });
                    }))
                    ||
                        // or containing any of already selected buildings
                        (_this.scope.checklistBuilding.checkedItems.length > 0 && _.some(businessCase.caseBuildings, function (caseBuilding) {
                            return _.some(_this.scope.checklistBuilding.checkedItems, function (dataBuilding) {
                                return dataBuilding.budId === caseBuilding.building.budId;
                            });
                        }))) &&
                // and containing this ownership
                _.some(businessCase.caseOwnerships, function (caseOwnership) {
                    return dataOwnership.ownership.opsubId === caseOwnership.ownership.opsubId;
                }) &&
                // containing any of already selected constructionObjects
                // when no construction object available to select then no need to check, but only if any group is selected
                (!_this.scope.data.obligation.selectConstructionObjectRequired || _.some(businessCase.constructionObjects, function (caseConstructionObject) {
                    return _.some(_this.scope.checklistConstructionObject.checkedItems, function (checkedItem) {
                        return caseConstructionObject.sousek === checkedItem.constructionObject.sousek && caseConstructionObject.socis === checkedItem.constructionObject.socis;
                    });
                }));
        });
    };
    TitleDetailCreateCaseSelectionService.prototype.getOwnershipDisabledMessage = function (dataOwnership) {
        var msgs = [];
        if (this.scope.checklistOwnership.isChecked(dataOwnership)) {
            return msgs;
        }
        if (dataOwnership.disabled) {
            msgs.push('Neplatná kombinace určení ceny');
        }
        // and does not exists any case
        var caseNumbers = this.ownershipCases(dataOwnership).map(function (businessCase) { return businessCase.mpp.number !== undefined ? businessCase.mpp.number : 'bez čísla'; });
        if (caseNumbers.length) {
            msgs.push('Založit duplicitní smlouvu nelze. Pro vlastníka existuje stávající smlouva na vybranou parcelu (' + _.uniq(caseNumbers).join(', ') + ').');
        }
        return msgs;
    };
    TitleDetailCreateCaseSelectionService.prototype.isOwnershipEnabled = function (dataOwnership) {
        return this.getOwnershipDisabledMessage(dataOwnership).length === 0;
    };
    /**
     * Checks if selection of group is disabled by missing price or ownership combination and returns messages array
     * @param {type} group
     * @returns {String[]}
     */
    TitleDetailCreateCaseSelectionService.prototype.getGroupDisabledMessage = function (group) {
        var _this = this;
        var msgs = [];
        if (this.scope.checklistGroup.isChecked(group)) {
            return msgs;
        }
        if (group.disabled !== undefined && group.disabled) {
            msgs.push('Neplatná kombinace určení ceny');
        }
        // parcel price for permanent occupation
        if (this.scope.data.obligation.computePrice && ((group.key === 'permanent' &&
            (group.parcel.landPrice === undefined ||
                group.parcel.buildingsPrice === undefined ||
                group.parcel.landPrice === undefined ||
                group.parcel.vegetationPrice === undefined ||
                group.priceSource === ''))
            ||
                // parcel price for non permanent occupation
                ((group.key === 'underOneYear' || group.key === 'overOneYear') &&
                    (group.occupations[0].totalRentPrice === undefined ||
                        (group.occupations[0].priceAssessmentLandPrice === undefined && group.occupations[0].rentalPriceType !== 'O' && group.occupations[0].rentalPriceUnit !== 'A') ||
                        (group.occupations[0].priceAssessmentYear === undefined && group.occupations[0].rentalPriceType === 'F') ||
                        (group.occupations[0].rentalExpertOpinionNumber === undefined && group.occupations[0].rentalPriceType === 'E') ||
                        (group.occupations[0].rentalExpertOpinionDate === undefined && group.occupations[0].rentalPriceType === 'E') ||
                        (group.occupations[0].rentalExpert === undefined && group.occupations[0].rentalExpert === 'E') ||
                        group.occupations[0].rentalLength === undefined)))) {
            msgs.push('Nezadána cena nebo neúplné zadání posudku');
        }
        if ( // allow only one checked item
        this.scope.data.obligation.type === 'FulfillmentOfDecisionExpropriationLiability' &&
            !this.scope.checklistEasement.isEmpty()) {
            msgs.push('Typ smlouvy nedovoluje kombinovat zábory a věcná břemena');
        }
        // and does not exists any case
        var caseNumbers = this.scope.dataCases.filter(function (businessCase) {
            // of same or exclusive type
            return _this.checkMutuallyExclusiveObligationTypes(_this.scope.data.obligation.type, businessCase.obligation.type) &&
                // and containing this occupations
                businessCase.occupations.some(function (occupation) {
                    return group.occupations.some(function (groupOccupation) {
                        return occupation.zabidpar === groupOccupation.zabidpar && occupation.zabku === groupOccupation.zabku && occupation.zabst === groupOccupation.zabst && occupation.zabcis === groupOccupation.zabcis && occupation.zabtyp === groupOccupation.zabtyp;
                    });
                }) &&
                // and containing any of already selected ownerships
                businessCase.caseOwnerships.some(function (caseOwnership) {
                    return _this.scope.checklistOwnership.checkedItems.some(function (checkedItem) {
                        return checkedItem.ownership.opsubId === caseOwnership.ownership.opsubId;
                    });
                }) &&
                // containing any of already selected constructionObjects
                // when no construction object available to select then no need to check
                (!_this.scope.data.obligation.selectConstructionObjectRequired || businessCase.constructionObjects.some(function (caseConstructionObject) {
                    return _this.scope.checklistConstructionObject.checkedItems.some(function (checkedItem) {
                        return caseConstructionObject.sousek === checkedItem.constructionObject.sousek && caseConstructionObject.socis === checkedItem.constructionObject.socis;
                    });
                }));
        }).map(function (businessCase) { return businessCase.mpp.number !== undefined ? businessCase.mpp.number : 'bez čísla'; });
        if (caseNumbers.length) {
            msgs.push('Založit duplicitní smlouvu nelze. Na parcelu existuje stávající smlouva pro zvoleného vlastníka (' + _.uniq(caseNumbers).join(', ') + ').');
        }
        return msgs;
    };
    /**
     * Checks if selection of ownerships does not prevent from group selection and price data are filled in
     * @param {type} group
     * @returns {Boolean}
     */
    TitleDetailCreateCaseSelectionService.prototype.isGroupEnabled = function (group) {
        return this.getGroupDisabledMessage(group).length === 0;
    };
    TitleDetailCreateCaseSelectionService.prototype.getEasementDisabledMessage = function (dataEasement) {
        var _this = this;
        var msgs = [];
        if (this.scope.checklistEasement.isChecked(dataEasement)) {
            return msgs;
        }
        if ( // allow only with price
        this.scope.data.obligation.computePrice &&
            dataEasement.easementPrice === undefined &&
            !this.caseService.easementPriceByShare(this.scope.data.obligation)) {
            if (new Set(dataEasement.constructionObjects.map(function (co) { return co.easementGlobalPriceType; })).size > 1 ||
                new Set(dataEasement.constructionObjects.map(function (co) { return co.easementGlobalOtherUnitType; })).size > 1 ||
                new Set(dataEasement.constructionObjects.map(function (co) { return co.easementGlobalOtherLengthPrice; })).size > 1 ||
                new Set(dataEasement.constructionObjects.map(function (co) { return co.easementGlobalOtherAreaPrice; })).size > 1 ||
                new Set(dataEasement.constructionObjects.map(function (co) { return co.easementGlobalOtherSinglePrice; })).size > 1) {
                msgs.push('Zadejte shodnou cenu VB všech souvisejích ' + this.wordService.getTranslation('CONSTRUCTION_OBJECT_PLURAL_GENITIV'));
            }
            else {
                msgs.push('Nezadána cena nebo neúplné zadání posudku');
            }
        }
        if (dataEasement.constructionObjects.some(function (co) { return !co.administrator; })) {
            msgs.push('Nezadán správce ' + this.wordService.getTranslation('CONSTRUCTION_OBJECT_GENITIV'));
        }
        if ( // allow only one checked item
        this.scope.data.obligation.type === 'FulfillmentOfDecisionExpropriationLiability' &&
            !this.scope.checklistGroup.isEmpty()) {
            msgs.push('Typ smlouvy nedovoluje kombinovat zábory a věcná břemena');
        }
        // and does not exists any case
        var caseNumbers = this.scope.dataCases.filter(function (businessCase) {
            // of same or exclusive type
            return _this.checkMutuallyExclusiveObligationTypes(_this.scope.data.obligation.type, businessCase.obligation.type) &&
                // and containing any of already selected easements
                businessCase.easements.some(function (caseEasement) {
                    return dataEasement.idpar === caseEasement.idpar && dataEasement.oznacVb === caseEasement.oznacVb && caseEasement.geometricPlan.id === dataEasement.geometricPlan.id;
                }) &&
                // and containing any of already selected ownerships
                (_this.scope.data.obligation.type === 'RealBurdenLiability' || _this.scope.data.obligation.type === 'RealBurdenLiabilityAccelerated' || // only one case allowed globally
                    businessCase.caseOwnerships.some(function (caseOwnership) {
                        return _this.scope.checklistOwnership.checkedItems.some(function (checkedItem) {
                            return checkedItem.ownership.opsubId === caseOwnership.ownership.opsubId;
                        });
                    })) &&
                // containing any of already selected constructionObjects
                // when no construction object available to select then no need to check
                (!_this.scope.data.obligation.selectConstructionObjectRequired || businessCase.constructionObjects.some(function (caseConstructionObject) {
                    return _this.scope.checklistConstructionObject.checkedItems.some(function (checkedItem) {
                        return caseConstructionObject.sousek === checkedItem.constructionObject.sousek && caseConstructionObject.socis === checkedItem.constructionObject.socis;
                    });
                }));
        }).map(function (businessCase) { return businessCase.mpp.number !== undefined ? businessCase.mpp.number : 'bez čísla'; });
        if (caseNumbers.length) {
            if (this.scope.data.obligation.type === 'RealBurdenLiability' || this.scope.data.obligation.type === 'RealBurdenLiabilityAccelerated') {
                msgs.push('Na parcelu existuje stávající smlouva (' + _.uniq(caseNumbers).join(', ') + ').');
            }
            else {
                msgs.push('Založit duplicitní smlouvu nelze. Na parcelu existuje stávající smlouva pro zvoleného vlastníka (' + _.uniq(caseNumbers).join(', ') + ').');
            }
        }
        if (dataEasement.geometricPlan.fiktivni && (this.scope.data.obligation.type === 'RealBurdenLiability' || this.scope.data.obligation.type === 'RealBurdenLiabilityAccelerated')) {
            msgs.push('Na VB není do aplikace vložen potvrzený geometrický plán, data pochází ze záborového elaborátu. Tento typ případu nelze založit.');
        }
        return msgs;
    };
    TitleDetailCreateCaseSelectionService.prototype.isEasementEnabled = function (dataEasement) {
        return this.getEasementDisabledMessage(dataEasement).length === 0;
    };
    TitleDetailCreateCaseSelectionService.prototype.constructionObjectMultipleTitles = function (constructionObject) {
        var occupations = _.filter(_.flatMap(this.scope.checklistGroupLocal.checkedItems, 'occupations'), function (occupation) { return _.some(occupation.constructionObjects, { id: constructionObject.id }); });
        var easements = _.filter(this.scope.checklistEasementLocal.checkedItems, function (easement) { return _.some(easement.constructionObjects, { id: constructionObject.id }); });
        return _.union(_.map(occupations, 'parcel.title.id'), _.map(easements, 'parcel.title.id')).length > 1;
    };
    TitleDetailCreateCaseSelectionService.prototype.getConstructionObjectDisabledMessage = function (dataConstructionObject) {
        var _this = this;
        var msgs = [];
        if (this.scope.checklistConstructionObject.isChecked(dataConstructionObject)) {
            return msgs;
        }
        // and does not exists any case
        var caseNumbers = this.scope.dataCases.filter(function (businessCase) {
            // of same or exclusive type
            return _this.checkMutuallyExclusiveObligationTypes(_this.scope.data.obligation.type, businessCase.obligation.type) &&
                // containing this constructionObject
                businessCase.constructionObjects.some(function (caseConstructionObject) {
                    return caseConstructionObject.sousek === dataConstructionObject.constructionObject.sousek && caseConstructionObject.socis === dataConstructionObject.constructionObject.socis;
                }) &&
                // and containing any of already selected occupations or easements
                (businessCase.occupations.some(function (occupation) {
                    return _this.scope.checklistGroup.checkedItems.some(function (group) {
                        return group.occupations.some(function (groupOccupation) {
                            return occupation.zabidpar === groupOccupation.zabidpar && occupation.zabku === groupOccupation.zabku && occupation.zabst === groupOccupation.zabst && occupation.zabcis === groupOccupation.zabcis && occupation.zabtyp === groupOccupation.zabtyp;
                        });
                    });
                }) || businessCase.easements.some(function (caseEasement) {
                    return _this.scope.checklistEasement.checkedItems.some(function (dataEasement) {
                        return dataEasement.idpar === caseEasement.idpar && dataEasement.oznacVb === caseEasement.oznacVb && caseEasement.geometricPlan.id === dataEasement.geometricPlan.id;
                    });
                })) &&
                // and containing any of already selected ownerships
                businessCase.caseOwnerships.some(function (caseOwnership) {
                    return _this.scope.checklistOwnership.checkedItems.some(function (checkedItem) {
                        return checkedItem.ownership.opsubId === caseOwnership.ownership.opsubId;
                    });
                });
        }).map(function (businessCase) { return businessCase.mpp.number !== undefined ? businessCase.mpp.number : 'bez čísla'; });
        if (caseNumbers.length) {
            msgs.push('Založit duplicitní smlouvu nelze. Existuje stávající smlouva pro zvoleného vlastníka (' + _.uniq(caseNumbers).join(', ') + ').');
        }
        return msgs;
    };
    TitleDetailCreateCaseSelectionService.prototype.isConstructionObjectEnabled = function (dataConstructionObject) {
        var checked = this.scope.checklistConstructionObject.isChecked(dataConstructionObject);
        // is already checked; If selection is caused by different title then disable deselection
        // allow deselection only if all easements and occupations are on same title (presumably current)
        return (checked && dataConstructionObject.selected) ||
            (!checked && this.getConstructionObjectDisabledMessage(dataConstructionObject).length === 0);
    };
    TitleDetailCreateCaseSelectionService.prototype.getBuildingDisabledMessage = function (dataBuilding) {
        var _this = this;
        var msgs = [];
        if (this.scope.checklistBuilding.isChecked(dataBuilding.building)) {
            return msgs;
        }
        if ( // allow only with price
        this.scope.data.obligation.computePrice &&
            dataBuilding.building.buildingsPrice === undefined) {
            msgs.push('Nezadána cena nebo neúplné zadání posudku');
        }
        if (dataBuilding.disabled) {
            msgs.push('Neplatná kombinace určení ceny');
        }
        // and does not exists any case
        var caseNumbers = this.scope.dataCases.filter(function (businessCase) {
            // of same or exclusive type
            return _this.checkMutuallyExclusiveObligationTypes(_this.scope.data.obligation.type, businessCase.obligation.type) &&
                // and containing any of already selected building
                businessCase.caseBuildings.some(function (caseBuilding) {
                    return dataBuilding.building.budId === caseBuilding.building.budId;
                }) &&
                // and containing any of already selected ownerships
                businessCase.caseOwnerships.some(function (caseOwnership) {
                    return _this.scope.checklistOwnership.checkedItems.some(function (checkedItem) {
                        return checkedItem.ownership.opsubId === caseOwnership.ownership.opsubId;
                    });
                });
        }).map(function (businessCase) { return businessCase.mpp.number !== undefined ? businessCase.mpp.number : 'bez čísla'; });
        if (caseNumbers.length) {
            msgs.push('Založit duplicitní smlouvu nelze. Na budovu existuje stávající smlouva pro zvoleného vlastníka (' + _.uniq(caseNumbers).join(', ') + ').');
        }
        return msgs;
    };
    TitleDetailCreateCaseSelectionService.prototype.isBuildingEnabled = function (dataBuilding) {
        return this.getBuildingDisabledMessage(dataBuilding).length === 0;
    };
    TitleDetailCreateCaseSelectionService.prototype.subjectReloadRegister = function (subjectReload) {
        this.scope.subjectReload = subjectReload;
    };
    TitleDetailCreateCaseSelectionService.prototype.subjectChanged = function (newVal) {
        // remove already added
        this.scope.checklistOwnership.empty();
        // add new
        if (!!newVal) {
            var ownership = { id: 0, cases: [],
                ownership: {
                    subjects: [newVal],
                    opsubId: newVal.opsubId
                }
            };
            if (this.isOwnershipEnabled(ownership)) {
                this.scope.checklistOwnership.toggleSelection(ownership);
            }
            else {
                new this.alertService({
                    message: 'Případ pro tuto osobu a vybranou parcelu již existuje.'
                }).then();
            }
        }
    };
    TitleDetailCreateCaseSelectionService.prototype.createSubject = function () {
        var _this = this;
        var dialog = this.dialogService.open(title_create_subject_component_1.TitleCreateSubjectComponent, {
            className: class_name_enum_1.ClassName.ADJUSTED_DIALOG,
            data: {},
        });
        var sub = dialog.afterClosed.subscribe(function (result) {
            if (result) {
                _this.subjectChanged(result);
                _this.scope.subjectReload();
            }
            sub.unsubscribe();
        });
    };
    TitleDetailCreateCaseSelectionService.prototype.refreshItems = function (obligation) {
        var _this = this;
        this.scope.dataOccupationGroupsFiltered = {};
        this.scope.dataConstructionObjectsFiltered = [];
        this.scope.dataBuildingsFiltered = [];
        if (obligation) {
            // determine which objects can be included on case of current type
            var objectTypes_1 = obligation.objectTypes;
            if (objectTypes_1) {
                if (objectTypes_1.occupations) {
                    this.scope.dataOccupationGroupsFiltered = this.scope.dataOccupationGroups;
                }
                else if (objectTypes_1.occupationGroupKeys) {
                    this.scope.dataOccupationGroupsFiltered = {};
                    Object.keys(this.scope.dataOccupationGroups).forEach(function (key) {
                        if (objectTypes_1.occupationGroupKeys.includes(key)) {
                            _this.scope.dataOccupationGroupsFiltered[key] = _this.scope.dataOccupationGroups[key];
                        }
                    });
                }
                if (objectTypes_1.buildings) {
                    this.scope.dataBuildingsFiltered = this.scope.dataBuildings;
                }
            }
            // update locally checked items
            Object.keys(this.scope.dataOccupationGroupsFiltered).forEach(function (key) {
                var group = _this.scope.dataOccupationGroupsFiltered[key];
                group.forEach(function (groupItem) {
                    if (_this.scope.checklistGroup.isChecked(groupItem)) {
                        _this.scope.checklistGroupLocal.toggleSelection(groupItem);
                    }
                });
            });
            // update locally checked items
            this.scope.dataEasements.forEach(function (easement) {
                if (_this.scope.checklistEasement.isChecked(easement)) {
                    _this.scope.checklistEasementLocal.checkedItems.push(easement);
                }
            });
            // update locally checked items
            this.scope.dataBuildingsFiltered.forEach(function (building) {
                if (_this.scope.checklistBuilding.isChecked(building)) {
                    _this.scope.checklistBuildingLocal.checkedItems.push(building);
                }
            });
            if (this.scope.data.obligation.selectConstructionObjectRequired) {
                this.scope.checklistGroupLocal.checkedItems.forEach(function (group) {
                    group.occupations.forEach(function (occupation) {
                        occupation.constructionObjects.forEach(function (constructionObject) {
                            _this.addConstructionObjectsFiltered(constructionObject, 'occupations', occupation);
                        });
                    });
                });
                this.scope.checklistEasementLocal.checkedItems.forEach(function (easement) {
                    easement.constructionObjects.forEach(function (constructionObject) {
                        _this.addConstructionObjectsFiltered(constructionObject, 'easements', easement);
                    });
                });
                // update locally checked items
                this.scope.dataConstructionObjectsFiltered.forEach(function (co) {
                    if (_this.scope.checklistConstructionObject.isChecked(co)) {
                        _this.scope.checklistConstructionObjectLocal.checkedItems.push(co);
                    }
                });
            }
            var toPriceSource_1 = function (filter) { return _.reduce(_.uniqBy(_.filter(_this.scope.dataParcelPrices, filter), function (parcelPrice) {
                return (parcelPrice.opinion.priceType === 'E' ? parcelPrice.opinion.expertOpinionNumber : parcelPrice.opinion.otherSource);
            }), function (result, parcelPrice) {
                var priceSource = (parcelPrice.opinion.priceType === 'E' ? parcelPrice.opinion.expertOpinionNumber : parcelPrice.opinion.otherSource);
                if (!priceSource) {
                    return result;
                }
                else {
                    return result += (result ? ', ' : '') + priceSource;
                }
            }, ''); };
            Object.keys(this.scope.dataOccupationGroupsFiltered).forEach(function (key) {
                var group = _this.scope.dataOccupationGroupsFiltered[key];
                group.forEach(function (groupItem) {
                    groupItem.disabled = false;
                    if (key === 'permanent' && obligation.computePrice) {
                        groupItem.priceSource = toPriceSource_1({ parcel: { id: groupItem.parcel.id } });
                    }
                });
            });
            this.scope.dataBuildingsFiltered.forEach(function (buildingData) {
                buildingData.disabled = false;
                if (obligation.computePrice) {
                    buildingData.priceSource = toPriceSource_1({ building: { id: buildingData.building.id } });
                }
            });
            this.disableByOpinions();
        }
    };
    // when obligation changes all selection are reset to default.
    TitleDetailCreateCaseSelectionService.prototype.obligationChanged = function (obligation) {
        this.scope.checklistOwnership.empty();
        this.scope.checklistGroup.empty();
        this.scope.checklistEasement.empty();
        this.scope.checklistConstructionObject.empty();
        this.scope.checklistBuilding.empty();
        this.scope.checklistGroupLocal.empty();
        this.scope.checklistEasementLocal.empty();
        this.scope.checklistBuildingLocal.empty();
        this.scope.checkedAllConstructionObjects = false;
        this.scope.checkedAllEasements = false;
        this.scope.checkedAllGroups = {};
        this.scope.checkedAllBuildings = false;
        this.refreshItems(obligation);
    };
    TitleDetailCreateCaseSelectionService.prototype.toggleAll = function (check, checklist, allItems, toggleSelectionFunction) {
        allItems.forEach(function (item) {
            if (checklist.isChecked(item) === !check) {
                toggleSelectionFunction(item);
            }
        });
    };
    TitleDetailCreateCaseSelectionService.ngInjectableDef = i0.defineInjectable({ factory: function TitleDetailCreateCaseSelectionService_Factory() { return new TitleDetailCreateCaseSelectionService(i0.inject("Restangular"), i0.inject("AlertService"), i0.inject(i1.WordService), i0.inject(i2.CaseService), i0.inject(i3.ListService), i0.inject(i4.ParcelService), i0.inject(i5.DialogService)); }, token: TitleDetailCreateCaseSelectionService, providedIn: "root" });
    return TitleDetailCreateCaseSelectionService;
}());
exports.TitleDetailCreateCaseSelectionService = TitleDetailCreateCaseSelectionService;
