"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".c-blue[_ngcontent-%COMP%] {\n  color: #0096dc; }\n\n.inline-block[_ngcontent-%COMP%] {\n  padding-right: 5px;\n  display: inline-block;\n  vertical-align: middle; }"];
exports.styles = styles;
