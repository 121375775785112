"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var parcel_model_1 = require("@app/common/models/parcel.model");
var parcel_price_model_1 = require("@app/ps/models/parcel-price.model");
var numeric_pipe_1 = require("@app/common/pipes/numeric.pipe");
var opinion_model_1 = require("@app/ps/models/opinion.model");
var PricingOtherSourceRowComponent = /** @class */ (function () {
    function PricingOtherSourceRowComponent(numericPipe) {
        this.numericPipe = numericPipe;
        this.changed = new core_1.EventEmitter();
        this.onChange = this.onChange.bind(this);
    }
    PricingOtherSourceRowComponent.prototype.ngOnInit = function () {
    };
    PricingOtherSourceRowComponent.prototype.computeParcelPrice = function () {
        var landPrice = this.parcelPrice.otherLandPrice && this.opinion.occupationType === 'P'
            ? this.numericPipe.transform(this.parcelPrice.otherLandPrice)
            : 0;
        var vegetationPrice = this.parcelPrice.otherVegetationPrice
            ? this.numericPipe.transform(this.parcelPrice.otherVegetationPrice)
            : 0;
        var compensationPrice = this.parcelPrice.otherCompensationPrice
            ? this.numericPipe.transform(this.parcelPrice.otherCompensationPrice)
            : 0;
        var buildingPrice = this.parcelPrice.otherBuildingsPrice && this.opinion.occupationType === 'P'
            ? this.numericPipe.transform(this.parcelPrice.otherBuildingsPrice)
            : 0;
        return landPrice + vegetationPrice + compensationPrice + buildingPrice;
    };
    PricingOtherSourceRowComponent.prototype.onChange = function () {
        this.changed.emit();
    };
    return PricingOtherSourceRowComponent;
}());
exports.PricingOtherSourceRowComponent = PricingOtherSourceRowComponent;
