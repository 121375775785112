"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var map_service_1 = require("@app/map/services/map.service");
var leaflet_map_service_1 = require("@app/map/services/leaflet-map.service");
var resize_observer_1 = require("@juggle/resize-observer");
var L = require("leaflet");
var LeafletMapDirective = /** @class */ (function () {
    function LeafletMapDirective(mapService, viewRef, leafletMapService) {
        this.mapService = mapService;
        this.viewRef = viewRef;
        this.leafletMapService = leafletMapService;
        this.map = null;
    }
    LeafletMapDirective.prototype.ngOnInit = function () {
        var _this = this;
        try {
            var options = this.options, config = this.config, mapElement = this.viewRef.element.nativeElement, mapId = this.options.id;
            mapElement.setAttribute('id', mapId);
            this.leafletMapService.setOptionsCrs(options);
            this.map = new L.Map(mapElement, options);
            this.map.whenReady(function () {
                _this.leafletMapService.enableLoadingWatch(mapId);
                _this.leafletMapService.initLayers(mapId, config);
                _this.leafletMapService.setBounds(mapId, options);
                _this.leafletMapService.setInitMapPos(_this.map);
                _this.leafletMapService.setFullscreen(_this.map, options);
                _this.leafletMapService.storeMap(_this.map, mapId);
                _this.leafletMapService.storeOptions(options, mapId);
                _this.mapService.setPositionListener(mapId, options);
            });
            // this.map.on('zoomend', function() {
            //     MapLayerVisibility.checkLayersVisibility(this.config, this.map);
            // });
        }
        catch (e) {
            console.error(e);
        }
        new resize_observer_1.default(this.doResizeCheck).observe(this.viewRef.element.nativeElement);
    };
    LeafletMapDirective.prototype.doResizeCheck = function () {
        if (this.map) {
            this.map.invalidateSize();
        }
    };
    LeafletMapDirective.prototype.ngOnDestroy = function () {
        if (this.map) {
            this.map.remove();
        }
        this.viewRef.element.nativeElement.remove();
        this.leafletMapService.resetMap(this.options.id);
        this.leafletMapService.resetOptions(this.options.id);
        this.leafletMapService.resetLayers(this.options.id);
    };
    return LeafletMapDirective;
}());
exports.LeafletMapDirective = LeafletMapDirective;
