"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var checklist_service_1 = require("@app/common/services/checklist.service");
var title_model_1 = require("@app/common/models/title.model");
var auth_service_1 = require("@app/common/services/auth.service");
var GenerateDocumentsFormComponent = /** @class */ (function () {
    function GenerateDocumentsFormComponent(restangular, authService, checklistService) {
        this.restangular = restangular;
        this.authService = authService;
        this.checklistService = checklistService;
        this.loading = true;
        this.otherDocumentTemplate = null;
        this.coveringLetterTemplate = null;
        this.templateTypes = [];
        this.data = null;
        this.editedTemplate = null;
        this.checklistTemplates = this.checklistService.get();
        this.coveringLetterProcessors = [];
        this.templateCoveringLetterProcessorMap = {};
        this.displayEdit = this.displayEdit.bind(this);
        this.resetEdit = this.resetEdit.bind(this);
        this.resetRecipientEdit = this.resetRecipientEdit.bind(this);
        this.getDocumentObject = this.getDocumentObject.bind(this);
        this.getParentVisible = this.getParentVisible.bind(this);
        this.onTemplateRecipientChecked = this.onTemplateRecipientChecked.bind(this);
        this.onTemplateChecked = this.onTemplateChecked.bind(this);
        this.getCheckedItems = this.getCheckedItems.bind(this);
        this.missingRequired = this.missingRequired.bind(this);
        this.generate = this.generate.bind(this);
    }
    GenerateDocumentsFormComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.loadCoveringLetterProcessors()];
                    case 1:
                        _a.sent();
                        this.checklistTemplates.identity = function (item) {
                            var filter = {
                                id: item.id,
                            };
                            if (item.recipientOpsubId) {
                                filter.recipientOpsubId = item.recipientOpsubId;
                            }
                            return filter;
                        };
                        this.templatesGetter().then(function (data) {
                            _this.loading = false;
                            _this.templateTypes = data;
                            _this.templateTypes.forEach(function (templateType) {
                                var e_1, _a;
                                if (templateType.key === 'SalesContractLiabilityCoveringLetter' && _this.coveringLetterDefaultProcessor) {
                                    try {
                                        for (var _b = __values(templateType.templates), _c = _b.next(); !_c.done; _c = _b.next()) {
                                            var template = _c.value;
                                            _this.templateCoveringLetterProcessorMap[template.id] = _this.coveringLetterDefaultProcessor;
                                        }
                                    }
                                    catch (e_1_1) { e_1 = { error: e_1_1 }; }
                                    finally {
                                        try {
                                            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                                        }
                                        finally { if (e_1) throw e_1.error; }
                                    }
                                }
                                var oneRecipient = templateType.recipients ? templateType.recipients.length === 1 : true;
                                if (templateType.templates.length === 1 && oneRecipient) {
                                    _this.switchTemplateWanted(templateType.templates[0]);
                                }
                            });
                        });
                        if (this.generateSetter) {
                            this.generateSetter({ generate: this.generate });
                        }
                        if (this.getCheckedItemsGetter) {
                            this.getCheckedItemsGetter({ checkedItemsGetter: this.getCheckedItems });
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    GenerateDocumentsFormComponent.prototype.onSelectCoveringLetterProcessor = function (processor, template) {
        delete this.templateCoveringLetterProcessorMap[template.id];
        if (processor) {
            this.templateCoveringLetterProcessorMap[template.id] = processor;
        }
    };
    GenerateDocumentsFormComponent.prototype.displayEdit = function (id, event) {
        this.editedTemplate = id;
        var el = event.target.closest('div');
        setTimeout(function () {
            var input = el.querySelector('input');
            input.focus();
        });
    };
    GenerateDocumentsFormComponent.prototype.resetEdit = function (template) {
        this.editedTemplate = null;
        setTimeout(function () {
            if (template.attachment.baseName === undefined || template.attachment.baseName.trim() === '') {
                template.attachment.baseName = template.commonName;
            }
        });
    };
    GenerateDocumentsFormComponent.prototype.resetRecipientEdit = function (recipient) {
        this.editedTemplate = null;
        setTimeout(function () {
            if (recipient.templateName.trim() === '') {
                recipient.templateName = recipient.commonTemplateName;
            }
        });
    };
    GenerateDocumentsFormComponent.prototype.getDocumentObject = function (template, recipient) {
        var document = {
            id: template.attachment.id,
            templateId: template.id
        };
        if (recipient && recipient.id) {
            document.recipientOpsubId = recipient.id;
            document.templateName = recipient.templateName;
        }
        return document;
    };
    GenerateDocumentsFormComponent.prototype.getParentVisible = function (template) {
        var _this = this;
        if (template.recipients && template.recipients.length > 0) {
            return template.recipients.some(function (r) { return _this.checklistTemplates.isChecked(_this.getDocumentObject(template, r)); });
        }
        else {
            return this.checklistTemplates.isChecked(this.getDocumentObject(template));
        }
    };
    GenerateDocumentsFormComponent.prototype.onTemplateRecipientChecked = function (templateType, template, event, recipient) {
        event.stopPropagation();
        this.switchTemplateRecipientWanted(template, recipient);
    };
    GenerateDocumentsFormComponent.prototype.onTemplateChecked = function (template, $event) {
        $event.stopPropagation();
        this.switchTemplateWanted(template);
    };
    GenerateDocumentsFormComponent.prototype.getCheckedItems = function () {
        return this.checklistTemplates ? this.checklistTemplates.checkedItems : [];
    };
    GenerateDocumentsFormComponent.prototype.missingRequired = function () {
        return !this.checklistTemplates || this.checklistTemplates.checkedItems.length === 0;
    };
    GenerateDocumentsFormComponent.prototype.generate = function () {
        var _this = this;
        if (this.missingRequired()) {
            return;
        }
        var documents = this.checklistTemplates.checkedItems;
        var generateDocuments = documents.map(function (d) {
            var doc = {
                name: _this.getDocumentName(d),
                id: d.templateId,
            };
            if (d.recipientOpsubId) {
                doc.recipientOpsubId = d.recipientOpsubId;
            }
            if (_this.templateCoveringLetterProcessorMap[d.templateId]) {
                doc.customProperties = _this.transformCoveringLetterProcessor(_this.templateCoveringLetterProcessorMap[d.templateId]);
            }
            return doc;
        });
        return this.restangular.one('cases', this.caseId)
            .all('generate')
            .customPOST(generateDocuments)
            .then(function (attachments) {
            return attachments;
        });
    };
    GenerateDocumentsFormComponent.prototype.getRecipientById = function (recipientId, templateId) {
        var recipient = null;
        this.templateTypes.find(function (templateType) {
            return templateType.templates.find(function (template) {
                return template.attachment.id === templateId && template.recipients ? (template.recipients.find(function (rep) {
                    if (rep.id === recipientId) {
                        recipient = rep;
                        return true;
                    }
                    return false;
                })) : false;
            });
        });
        return recipient;
    };
    GenerateDocumentsFormComponent.prototype.getAttachmentByID = function (attachmentId) {
        var attachment = null;
        this.templateTypes.find(function (templateType) {
            return templateType.templates.find(function (template) {
                if (template.attachment.id === attachmentId) {
                    attachment = template.attachment;
                    return true;
                }
                return false;
            });
        });
        return attachment;
    };
    GenerateDocumentsFormComponent.prototype.switchTemplateRecipientWanted = function (template, recipient) {
        var document = this.getDocumentObject(template, recipient);
        this.checklistTemplates.toggleSelection(document);
    };
    GenerateDocumentsFormComponent.prototype.switchTemplateWanted = function (template) {
        var e_2, _a;
        var document = this.getDocumentObject(template);
        var checked = this.checklistTemplates.isChecked(document);
        if (template.recipients && template.recipients.length !== 0) {
            try {
                for (var _b = __values(template.recipients), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var recipient = _c.value;
                    var document_1 = this.getDocumentObject(template, recipient);
                    var checkedRecipientDocument = this.checklistTemplates.isChecked(document_1);
                    if (checkedRecipientDocument === checked) {
                        this.switchTemplateRecipientWanted(template, recipient);
                    }
                }
            }
            catch (e_2_1) { e_2 = { error: e_2_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_2) throw e_2.error; }
            }
        }
        else {
            this.checklistTemplates.toggleSelection(document);
        }
    };
    GenerateDocumentsFormComponent.prototype.getDocumentName = function (document) {
        var attachment = this.getAttachmentByID(document.id);
        return document.recipientOpsubId ? this.getRecipientById(document.recipientOpsubId, document.id).templateName : attachment.baseName;
    };
    GenerateDocumentsFormComponent.prototype.loadCoveringLetterProcessors = function () {
        return __awaiter(this, void 0, void 0, function () {
            var e_3, _a, projectProperties, groupName, prefix, group, _loop_1, this_1, _b, _c, item, defaultProcessor;
            var _this = this;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0: return [4 /*yield*/, this.restangular.all('properties').customGET('')];
                    case 1:
                        projectProperties = _d.sent();
                        groupName = 'Údaje do průvodního dopisu';
                        prefix = 'podepisujici';
                        group = projectProperties.find(function (g) { return g.groupName === groupName; });
                        if (group) {
                            this.coveringLetterProcessors = [];
                            _loop_1 = function (item) {
                                if (!item.templateVariable || item.templateVariable.indexOf(prefix) !== 0) {
                                    return "continue";
                                }
                                var id = item.templateVariable.slice(0, prefix.length + 1);
                                var processor = this_1.coveringLetterProcessors.find(function (p) { return p.id === id; });
                                if (processor) {
                                    processor.items.push(item);
                                }
                                else {
                                    this_1.coveringLetterProcessors.push({
                                        id: id,
                                        items: [item],
                                    });
                                }
                            };
                            this_1 = this;
                            try {
                                for (_b = __values(group.items), _c = _b.next(); !_c.done; _c = _b.next()) {
                                    item = _c.value;
                                    _loop_1(item);
                                }
                            }
                            catch (e_3_1) { e_3 = { error: e_3_1 }; }
                            finally {
                                try {
                                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                                }
                                finally { if (e_3) throw e_3.error; }
                            }
                            this.coveringLetterProcessors = this.coveringLetterProcessors
                                .filter(function (p) {
                                var itemName = p.items.find(function (i) { return i.templateVariable === p.id + 'PrijmeniJmeno'; });
                                return itemName && !!itemName.valueText;
                            })
                                .map(function (p) {
                                var itemName = p.items.find(function (i) { return i.templateVariable === p.id + 'PrijmeniJmeno'; });
                                var itemOtherProcessor = p.items.find(function (i) { return i.templateVariable === p.id + 'JinaOsoba'; });
                                var itemOtherProcessorName = p.items.find(function (i) { return i.templateVariable === p.id + 'JinaOsobaPrijmeniJmeno'; });
                                p.name = itemName.valueText + (itemOtherProcessor.valueBoolean && itemOtherProcessorName.valueText ? ' (' + itemOtherProcessorName.valueText + ')' : '');
                                return p;
                            });
                            defaultProcessor = this.coveringLetterProcessors.find(function (p) { return p.items.find(function (i) { return i.type === 'USER_SELECT' && i.valueLong && _this.authService.getUser().id === i.valueLong; }); });
                            if (defaultProcessor) {
                                this.coveringLetterDefaultProcessor = defaultProcessor;
                            }
                            else if (this.coveringLetterProcessors.length === 1) {
                                this.coveringLetterDefaultProcessor = this.coveringLetterProcessors[0];
                            }
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    GenerateDocumentsFormComponent.prototype.transformCoveringLetterProcessor = function (processor) {
        var prefix = 'podepisujici';
        return processor.items.map(function (i) {
            i.templateVariable = prefix + i.templateVariable.slice(prefix.length + 1);
            return i;
        });
    };
    return GenerateDocumentsFormComponent;
}());
exports.GenerateDocumentsFormComponent = GenerateDocumentsFormComponent;
