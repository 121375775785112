"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var CreateCasePriceCaseOwnershipComponent = /** @class */ (function () {
    function CreateCasePriceCaseOwnershipComponent() {
        this.easementPriceByShareUpdated = new core_1.EventEmitter();
    }
    return CreateCasePriceCaseOwnershipComponent;
}());
exports.CreateCasePriceCaseOwnershipComponent = CreateCasePriceCaseOwnershipComponent;
