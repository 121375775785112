"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var BulkOperationStepHostDirective = /** @class */ (function () {
    function BulkOperationStepHostDirective(viewContainerRef) {
        this.viewContainerRef = viewContainerRef;
    }
    return BulkOperationStepHostDirective;
}());
exports.BulkOperationStepHostDirective = BulkOperationStepHostDirective;
