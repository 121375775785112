"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var dialog_config_1 = require("@app/dialog/dialog-config");
var dialog_ref_1 = require("@app/dialog/dialog-ref");
var geometric_plan_request_type_enum_1 = require("@app/ps/enums/geometric-plan-request-type.enum");
var approve_enum_1 = require("@app/ps/enums/approve.enum");
var geometric_plan_request_status_enum_1 = require("@app/ps/enums/geometric-plan-request-status.enum");
var note_type_key_enum_1 = require("@app/ps/enums/note-type-key.enum");
var geometric_plan_request_service_1 = require("@app/ps/services/geometric-plan-request.service");
var auth_service_1 = require("@app/common/services/auth.service");
var kn_gp_name_pipe_1 = require("@app/ps/pipes/kn-gp-name.pipe");
var GeometricPlanRequestEditComponent = /** @class */ (function () {
    function GeometricPlanRequestEditComponent(requestService, authService, dialogComponent, knGpNamePipe, dialogConfig) {
        this.requestService = requestService;
        this.authService = authService;
        this.dialogComponent = dialogComponent;
        this.knGpNamePipe = knGpNamePipe;
        this.dialogConfig = dialogConfig;
        this.typeEnum = geometric_plan_request_type_enum_1.GeometricPlanRequestTypeEnum;
        this.statusEnum = geometric_plan_request_status_enum_1.GeometricPlanRequestStatusEnum;
        this.approveOptions = approve_enum_1.approveOptions;
        this.gpFiles = [];
        this.gpVfkFiles = [];
        this.gpDocFiles = [];
        this.gpOtherFiles = [];
        this.gpFilesValid = false;
        this.gpVfkFilesValid = false;
        this.gpFilesMsg = GeometricPlanRequestEditComponent.MSG_GP_FILES_DEFAULT;
        this.gpVfkFilesMsg = GeometricPlanRequestEditComponent.MSG_GP_VFK_FILES_DEFAULT;
        this.gpDocFilesMsg = GeometricPlanRequestEditComponent.MSG_GP_DOC_FILES_DEFAULT;
        this.gpOtherFilesMsg = GeometricPlanRequestEditComponent.MSG_GP_OTHER_FILES_DEFAULT;
        this.onSave = this.onSave.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }
    GeometricPlanRequestEditComponent.prototype.ngOnInit = function () {
        var _this = this;
        var data = this.dialogConfig.data;
        this.newStatus = data.newStatus;
        this.editMode = data.editMode;
        this.geometricPlanRequest = data.geometricPlanRequest;
        this.noteTypes = data.noteTypes;
        this.parcelSplitPreApproveByBuildingAuthority = this.geometricPlanRequest.parcelSplitPreApproveByBuildingAuthority
            ? this.approveOptions.find(function (o) { return o.id === _this.geometricPlanRequest.parcelSplitPreApproveByBuildingAuthority; })
            : this.approveOptions[0];
    };
    GeometricPlanRequestEditComponent.prototype.isValid = function () {
        var _this = this;
        if (this.editMode === 'edit') {
            return true;
        }
        var statusConfig = this.getStatusConfig();
        if (!statusConfig) {
            return false;
        }
        var fieldsValidity = statusConfig.fields
            .filter(function (n) { return n !== 'realizationPrice' && n !== 'attachments' && n !== 'attachmentsOther' && n !== 'knGp'; })
            .map(function (n) { return !!_this.geometricPlanRequest[n]; })
            .every(function (b) { return b; });
        if (this.newStatus === geometric_plan_request_status_enum_1.GeometricPlanRequestStatusEnum.APPROVED) {
            return (fieldsValidity
                && this.gpFilesValid
                && this.gpVfkFilesValid
                && (this.geometricPlanRequest.type !== geometric_plan_request_type_enum_1.GeometricPlanRequestTypeEnum.EASEMENT_EXTENT || this.gpDocFiles.length > 0));
        }
        if (this.newStatus === geometric_plan_request_status_enum_1.GeometricPlanRequestStatusEnum.IMPORTED) {
            return (fieldsValidity && !!this.geometricPlanRequest.knGp);
        }
        return fieldsValidity;
    };
    GeometricPlanRequestEditComponent.prototype.isFieldVisible = function (field) {
        if (this.editMode === 'edit') {
            if (field.indexOf('Date') > 0 && this.geometricPlanRequest[field] === undefined) {
                return false;
            }
            else {
                return true;
            }
        }
        var statusConfig = this.getStatusConfig();
        if (!statusConfig) {
            return false;
        }
        return statusConfig.fields.includes(field);
    };
    GeometricPlanRequestEditComponent.prototype.onGpFilesChange = function (files) {
        this.gpFiles = files;
        var extensionValid = files.every(function (f) { return f.extension && f.extension.toLowerCase() === 'pdf'; });
        if (this.gpFiles.length === 0) {
            this.gpFilesMsg = GeometricPlanRequestEditComponent.MSG_GP_FILES_DEFAULT;
        }
        else if (!extensionValid) {
            this.gpFilesMsg = GeometricPlanRequestEditComponent.MSG_GP_FILES_EXTENSION;
        }
        else if (this.gpFiles.length > 1) {
            this.gpFilesMsg = GeometricPlanRequestEditComponent.MSG_FILES_QUANTITY;
        }
        else {
            this.gpFilesMsg = '';
        }
        this.gpFilesValid = (extensionValid && this.gpFiles.length === 1);
    };
    GeometricPlanRequestEditComponent.prototype.onGpVfkFilesChange = function (files) {
        this.gpVfkFiles = files;
        var extensionValid = files.every(function (f) { return f.extension && f.extension.toLowerCase() === 'vfk'; });
        if (this.gpVfkFiles.length === 0) {
            this.gpVfkFilesMsg = GeometricPlanRequestEditComponent.MSG_GP_VFK_FILES_DEFAULT;
        }
        else if (!extensionValid) {
            this.gpVfkFilesMsg = GeometricPlanRequestEditComponent.MSG_GP_VFK_FILES_EXTENSION;
        }
        else if (this.gpVfkFiles.length > 1) {
            this.gpVfkFilesMsg = GeometricPlanRequestEditComponent.MSG_FILES_QUANTITY;
        }
        else {
            this.gpVfkFilesMsg = '';
        }
        this.gpVfkFilesValid = (extensionValid && this.gpVfkFiles.length === 1);
    };
    GeometricPlanRequestEditComponent.prototype.onGpDocFilesChange = function (files) {
        this.gpDocFiles = files;
    };
    GeometricPlanRequestEditComponent.prototype.onGpOtherFilesChange = function (files) {
        this.gpOtherFiles = files;
    };
    GeometricPlanRequestEditComponent.prototype.onSave = function () {
        if (!this.isValid()) {
            return;
        }
        if (this.newStatus) {
            this.geometricPlanRequest.status = this.newStatus;
        }
        this.geometricPlanRequest.parcelSplitPreApproveByBuildingAuthority = this.parcelSplitPreApproveByBuildingAuthority.id;
        var notes = [];
        if (this.parcelSplitPreApproveByBuildingAuthorityNote) {
            notes.push({
                text: this.parcelSplitPreApproveByBuildingAuthorityNote,
                noteType: this.noteTypes.find(function (nt) { return nt.key === note_type_key_enum_1.NoteTypeKeyEnum.ParcelSplitPreApproveByBuildingAuthority; })
            });
        }
        if (this.conceptDisapprovedReasonNote) {
            notes.push({
                text: this.conceptDisapprovedReasonNote,
                noteType: this.noteTypes.find(function (nt) { return nt.key === note_type_key_enum_1.NoteTypeKeyEnum.ConceptDisapprovedReason; })
            });
        }
        this.dialogComponent.close({
            geometricPlanRequest: this.geometricPlanRequest,
            notes: notes,
            gpFiles: this.gpFiles,
            gpVfkFiles: this.gpVfkFiles,
            gpDocFiles: this.gpDocFiles,
            gpOtherFiles: this.gpOtherFiles,
        });
    };
    GeometricPlanRequestEditComponent.prototype.onCancel = function () {
        this.dialogComponent.close(false);
    };
    GeometricPlanRequestEditComponent.prototype.getStatusConfig = function () {
        var _this = this;
        return GeometricPlanRequestEditComponent.STATUSES_CONFIG.find(function (sc) { return sc.status === _this.newStatus; });
    };
    GeometricPlanRequestEditComponent.MSG_GP_FILES_DEFAULT = 'Vložte soubor přetažením nebo výběrem. Je možné vložit jeden soubor PDF.';
    GeometricPlanRequestEditComponent.MSG_GP_FILES_EXTENSION = 'Soubor musí být ve formátu PDF.';
    GeometricPlanRequestEditComponent.MSG_GP_VFK_FILES_DEFAULT = 'Vložte soubor přetažením nebo výběrem. Je možné vložit jeden soubor VFK.';
    GeometricPlanRequestEditComponent.MSG_GP_VFK_FILES_EXTENSION = 'Soubor musí být ve formátu VFK.';
    GeometricPlanRequestEditComponent.MSG_GP_DOC_FILES_DEFAULT = 'Vložte soubor přetažením nebo výběrem. Je možné vložit více souborů.';
    GeometricPlanRequestEditComponent.MSG_GP_OTHER_FILES_DEFAULT = 'Vložte soubor přetažením nebo výběrem. Je možné vložit více souborů.';
    GeometricPlanRequestEditComponent.MSG_FILES_QUANTITY = 'Je možné vložit pouze jeden soubor.';
    GeometricPlanRequestEditComponent.STATUSES_CONFIG = [
        {
            status: geometric_plan_request_status_enum_1.GeometricPlanRequestStatusEnum.CONCEPT_REALIZATION,
            fields: [
                'conceptRealizationInputDate',
                'supplier',
                'conceptRealizationOutputDate',
            ]
        },
        {
            status: geometric_plan_request_status_enum_1.GeometricPlanRequestStatusEnum.CONCEPT_SENT_TO_CONFIRMATION,
            fields: [
                'conceptToApproveDate',
                'attachmentsOther',
            ]
        },
        {
            status: geometric_plan_request_status_enum_1.GeometricPlanRequestStatusEnum.CONCEPT_DISAPPROVED,
            fields: [
                'conceptDisapprovedDate',
            ]
        },
        {
            status: geometric_plan_request_status_enum_1.GeometricPlanRequestStatusEnum.CONCEPT_APPROVED,
            fields: [
                'conceptApprovedDate',
            ]
        },
        {
            status: geometric_plan_request_status_enum_1.GeometricPlanRequestStatusEnum.SENT_TO_CONFIRMATION,
            fields: [
                'sentToConfirmationDate',
            ]
        },
        {
            status: geometric_plan_request_status_enum_1.GeometricPlanRequestStatusEnum.DISAPPROVED,
            fields: [
                'disapprovedDate',
            ]
        },
        {
            status: geometric_plan_request_status_enum_1.GeometricPlanRequestStatusEnum.APPROVED,
            fields: [
                'approvedDate',
                'realizationPrice',
                'pgpNumber',
                'attachments',
            ]
        },
        {
            status: geometric_plan_request_status_enum_1.GeometricPlanRequestStatusEnum.DELETED,
            fields: [
                'deletedDate',
            ]
        },
        {
            status: geometric_plan_request_status_enum_1.GeometricPlanRequestStatusEnum.REGISTER_REQUESTED,
            fields: [
                'registerRequestedDate',
            ]
        },
        {
            status: geometric_plan_request_status_enum_1.GeometricPlanRequestStatusEnum.REGISTERED,
            fields: [
                'registeredDate',
            ]
        },
        {
            status: geometric_plan_request_status_enum_1.GeometricPlanRequestStatusEnum.IMPORT_REQUESTED,
            fields: [
                'importRequestedDate',
            ]
        },
        {
            status: geometric_plan_request_status_enum_1.GeometricPlanRequestStatusEnum.IMPORTED,
            fields: [
                'importedDate',
                'knGp'
            ]
        },
    ];
    return GeometricPlanRequestEditComponent;
}());
exports.GeometricPlanRequestEditComponent = GeometricPlanRequestEditComponent;
