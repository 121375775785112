"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var FormCaseReceiveOwnerComponent = /** @class */ (function () {
    function FormCaseReceiveOwnerComponent() {
        this.callbackRegister = new core_1.EventEmitter();
        this.isValid = this.isValid.bind(this);
    }
    FormCaseReceiveOwnerComponent.prototype.ngOnInit = function () {
        var currentDate = new Date();
        var day = currentDate.getDate();
        var month = currentDate.getMonth() + 1;
        var year = currentDate.getFullYear();
        this.callbackRegister.emit({
            isValid: this.isValid,
        });
        this.onReceiveDateUpdate(year + "-" + ("00" + month).slice(-2) + "-" + ("00" + day).slice(-2));
    };
    FormCaseReceiveOwnerComponent.prototype.onReceiveDateUpdate = function (value) {
        var _this = this;
        this.data.receivedOwnerDate = value;
        this.cases.forEach(function (bCase, i) {
            if (value) {
                var date = new Date(value);
                _this.dataCases[i] = {};
                var newDate = void 0;
                if (bCase.obligation.bonusPeriod && bCase.bonusPossible && !bCase.bonusDate) {
                    newDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() + bCase.obligation.bonusPeriod);
                    _this.dataCases[i].bonusDate = newDate.getFullYear() + '-' + ('00' + (newDate.getMonth() + 1)).slice(-2) + '-' + ('00' + newDate.getDate()).slice(-2);
                }
                if (bCase.obligation.validityPeriod) {
                    newDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() + bCase.obligation.validityPeriod);
                    _this.dataCases[i].validityDate = newDate.getFullYear() + '-' + ('00' + (newDate.getMonth() + 1)).slice(-2) + '-' + ('00' + newDate.getDate()).slice(-2);
                }
            }
            else if (_this.dataCases[i]) {
                _this.dataCases[i].validityDate = null;
                if (!bCase.bonusDate) {
                    _this.dataCases[i].bonusDate = null;
                }
            }
        });
    };
    FormCaseReceiveOwnerComponent.prototype.isValid = function () {
        return !!this.data.receivedOwnerDate;
    };
    return FormCaseReceiveOwnerComponent;
}());
exports.FormCaseReceiveOwnerComponent = FormCaseReceiveOwnerComponent;
