"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
exports.RESTANGULAR_SV = new core_1.InjectionToken('RestangularSV');
function RestangularSVFactory(restangular, config) {
    return restangular.withConfig(function (restangularConfigurer) {
        restangularConfigurer.setBaseUrl(config.BACKEND_OPTIONS.restUrlSV);
    });
}
exports.RestangularSVFactory = RestangularSVFactory;
