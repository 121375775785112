"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var dialog_config_1 = require("@app/dialog/dialog-config");
var dialog_ref_1 = require("@app/dialog/dialog-ref");
var parcel_price_model_1 = require("@app/ps/models/parcel-price.model");
var parcel_model_1 = require("@app/common/models/parcel.model");
var opinion_model_1 = require("@app/ps/models/opinion.model");
var opinion_service_1 = require("@app/ps/services/opinion.service");
var ExternalParcelFormComponent = /** @class */ (function () {
    function ExternalParcelFormComponent(restangular, opinionService, dialogConfig, dialogComponent) {
        this.restangular = restangular;
        this.opinionService = opinionService;
        this.dialogConfig = dialogConfig;
        this.dialogComponent = dialogComponent;
        this.parcel = new parcel_model_1.ParcelModel();
        this.onSave = this.onSave.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onExpertSelected = this.onExpertSelected.bind(this);
    }
    ExternalParcelFormComponent.prototype.ngOnInit = function () {
        var enablePricing = this.dialogConfig.data.enablePricing;
        var areaSelect = this.dialogConfig.data.areaSelect;
        if (enablePricing !== undefined) {
            this.enablePricing = enablePricing;
        }
        if (areaSelect !== undefined) {
            this.areaSelect = areaSelect;
        }
    };
    ExternalParcelFormComponent.prototype.isParcelValid = function () {
        return !!(this.parcel.parcisKmen
            && this.parcel.katuzeNazev
            // && this.parcel.katuzeKod
            && this.parcel.vymera
            && this.parcel.lv);
    };
    ExternalParcelFormComponent.prototype.isPricingValid = function () {
        return !!this.opinion && !!((this.opinion.priceType === 'E' && this.opinion.expert && this.opinion.expertOpinionDate)
            ||
                (this.opinion.priceType === 'O' && this.opinion.otherSource));
    };
    ExternalParcelFormComponent.prototype.isValid = function () {
        return this.isParcelValid() && (!this.pricingType || this.isPricingValid());
    };
    ExternalParcelFormComponent.prototype.onPricingTypeSelect = function (pricingType) {
        var _this = this;
        return function () {
            if (!pricingType || _this.pricingType === pricingType) {
                _this.pricingType = undefined;
                _this.parcelPrice = undefined;
                _this.opinion = undefined;
                return;
            }
            _this.pricingType = pricingType;
            _this.parcelPrice = (pricingType === 'E'
                ? parcel_price_model_1.ParcelPriceModel.createDefaultExpertOpinionPermanent()
                : parcel_price_model_1.ParcelPriceModel.createDefaultOtherSourcePermanent());
            _this.opinion = (pricingType === 'E'
                ? opinion_model_1.OpinionModel.createDefaultExpertOpinionPermanent()
                : opinion_model_1.OpinionModel.createDefaultOtherSourcePermanent());
        };
    };
    ExternalParcelFormComponent.prototype.onExpertSelected = function (expert) {
        if (this.opinion) {
            this.opinion.expert = expert;
        }
    };
    ExternalParcelFormComponent.prototype.onSave = function () {
        var _this = this;
        var resultParcel;
        return this.restangular.all('parcels/create').customPOST(this.parcel)
            .then(function (parcel) {
            resultParcel = parcel.plain();
            if (_this.pricingType) {
                var opinionMsg = __assign({}, _this.opinion, { parcelPrices: [__assign({}, _this.parcelPrice, { parcel: resultParcel })] });
                return _this.opinionService.createOne(opinionMsg);
            }
            return true;
        })
            .then(function () {
            _this.dialogComponent.close(resultParcel);
        });
    };
    ExternalParcelFormComponent.prototype.onCancel = function () {
        this.dialogComponent.close(null);
    };
    return ExternalParcelFormComponent;
}());
exports.ExternalParcelFormComponent = ExternalParcelFormComponent;
