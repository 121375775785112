"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var case_service_1 = require("@app/ps/services/case.service");
var angular_1 = require("@uirouter/angular");
var list_service_1 = require("@app/common/services/list.service");
var auth_service_1 = require("@app/common/services/auth.service");
var case_status_service_1 = require("@app/ps/services/case-status.service");
var UpdateCaseOwnershipComponent = /** @class */ (function () {
    function UpdateCaseOwnershipComponent(caseService, stateService, listService, authService, restangular, caseStatusService) {
        this.caseService = caseService;
        this.stateService = stateService;
        this.listService = listService;
        this.authService = authService;
        this.restangular = restangular;
        this.caseStatusService = caseStatusService;
        this.loading = true;
        this.displayFields = {};
        this.caseOwnership = {};
        this.update = this.update.bind(this);
        this.updateCaseOwnership = this.updateCaseOwnership.bind(this);
        this.updateCaseSubjects = this.updateCaseSubjects.bind(this);
        this.toggleSinglePayment = this.toggleSinglePayment.bind(this);
    }
    UpdateCaseOwnershipComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var list;
            var _this = this;
            return __generator(this, function (_a) {
                list = this.listService.createList('case-subjects', {
                    limit: 2,
                    filters: {
                        caseOwnershipId: this.caseOwnershipId,
                        opsubType: 'OFO',
                    }
                });
                Promise.all([
                    this.restangular.one('case-ownerships', this.caseOwnershipId).get({ loadCollections: 'businessCase' }),
                    this.listService.fetchResult(list)
                ]).then(function (data) {
                    _this.caseOwnership = data[0];
                    if (_this.authService.getActualProject().easementPriceByShare) {
                        if (_this.caseOwnership.businessCase.obligation.type === 'RealBurdenLiability') {
                            _this.displayFields.easementFutureContractNumber = true;
                            _this.displayFields.easementFutureContractStatus = true;
                        }
                        if (_this.authService.hasPermission('admin,update_case_price')) {
                            _this.displayFields.easementPriceByShare = true;
                        }
                    }
                    _this.displayFieldsLength = Object.keys(_this.displayFields).length;
                    _this.caseSubjects = list.list;
                    _this.loading = false;
                });
                return [2 /*return*/];
            });
        });
    };
    UpdateCaseOwnershipComponent.prototype.update = function () {
        var _this = this;
        // save and return data to detail
        return this.restangular.one('cases', this.caseOwnership.businessCase.id).all('ownerships-subjects').customPUT({
            caseOwnerships: [this.restangular.stripRestangular(this.caseOwnership)],
            caseSubjects: this.caseSubjects,
        }).then(function (data) {
            _this.stateService.go('^');
        });
    };
    UpdateCaseOwnershipComponent.prototype.updateCaseOwnership = function () {
        return this.caseOwnership.put();
    };
    UpdateCaseOwnershipComponent.prototype.updateCaseSubjects = function () {
        return this.restangular.all('case-subjects').customPUT(this.caseSubjects);
    };
    UpdateCaseOwnershipComponent.prototype.toggleSinglePayment = function () {
        this.caseOwnership.singlePayment = !this.caseOwnership.singlePayment;
        if (this.caseOwnership.singlePayment) {
            this.caseSubjects.forEach(this.clearPayment);
        }
        else {
            this.clearPayment(this.caseOwnership);
        }
    };
    UpdateCaseOwnershipComponent.prototype.clearPayment = function (object) {
        object.paymentType = undefined;
        object.accountNumber = null;
        object.bankCode = null;
        object.variableSymbol = null;
        object.specificSymbol = null;
        object.constantSymbol = null;
    };
    return UpdateCaseOwnershipComponent;
}());
exports.UpdateCaseOwnershipComponent = UpdateCaseOwnershipComponent;
