"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var _ = require("lodash");
var dialog_config_1 = require("@app/dialog/dialog-config");
var dialog_ref_1 = require("@app/dialog/dialog-ref");
var modules_service_1 = require("@app/common/services/modules.service");
var role_pipe_1 = require("@app/common/pipes/role.pipe");
var UserPermissionsComponent = /** @class */ (function () {
    function UserPermissionsComponent(dialogConfig, dialogRef, modulesService, applications, rolePipe) {
        this.dialogConfig = dialogConfig;
        this.dialogRef = dialogRef;
        this.modulesService = modulesService;
        this.applications = applications;
        this.rolePipe = rolePipe;
    }
    UserPermissionsComponent.prototype.ngOnInit = function () {
        var data = this.dialogConfig.data;
        this.application = data.application;
        this.roleEnabled = this.application === this.applications.sy.name;
        this.projectKey = data.projectKey;
        this.userId = data.userId;
        this.roles = data.roles;
        this.permissionsWithRoles = data.permissionsWithRoles;
        this.currentRole = data.currentRole && this.roles.find(function (role) { return role.id === data.currentRole.role; });
        this.initialPermissions = this.getPermissions(this.permissionsWithRoles);
        this.currentPermissions = data.additionalPermissions || [];
        this.handleItemSelect = this.handleItemSelect.bind(this);
        this.handleRoleSelect = this.handleRoleSelect.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.setRoleAndPermissions();
    };
    UserPermissionsComponent.prototype.handleRoleSelect = function () {
        this.currentPermissions = [];
        this.setRoleAndPermissions();
    };
    UserPermissionsComponent.prototype.handleItemSelect = function (items) {
        this.currentPermissions = items;
    };
    UserPermissionsComponent.prototype.handleSubmit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var permissions, currentRoleId, payload;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        permissions = this.currentPermissions && this.currentPermissions.map(function (item) { return item.id; });
                        currentRoleId = (this.currentRole && this.currentRole.id);
                        payload = {
                            application: this.application,
                            projectKey: this.projectKey,
                            permissions: permissions || [],
                        };
                        if (currentRoleId) {
                            payload.projectRole = currentRoleId;
                        }
                        return [4 /*yield*/, this.modulesService.saveUserPermissions(this.application, this.projectKey, this.userId, payload)];
                    case 1:
                        _a.sent();
                        this.dialogRef.close(true);
                        return [2 /*return*/];
                }
            });
        });
    };
    UserPermissionsComponent.prototype.getPermissions = function (source) {
        return source.map(function (item) { return ({ id: item.name, value: item.description }); });
    };
    UserPermissionsComponent.prototype.getCurrentRolePermissions = function () {
        var _this = this;
        var rolePermissions = this.permissionsWithRoles.filter(function (pwr) { return pwr.roles.includes(_this.currentRole && _this.currentRole.id); }) || [];
        return rolePermissions.map(function (rp) { return ({ id: rp.name, value: rp.description }); });
    };
    UserPermissionsComponent.prototype.setRoleAndPermissions = function () {
        var currentPermissions = this.getCurrentRolePermissions().concat(this.currentPermissions);
        this.permissions = _.differenceBy(this.initialPermissions, currentPermissions, 'id');
        this.currentRolePermissions = _.sortBy(this.getCurrentRolePermissions(), 'value');
    };
    return UserPermissionsComponent;
}());
exports.UserPermissionsComponent = UserPermissionsComponent;
