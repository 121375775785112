"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var project_subject_simple_ofo_component_1 = require("@app/ps/project-settings/components/project-subject-simple-ofo/project-subject-simple-ofo.component");
var dialog_service_1 = require("@app/dialog/dialog.service");
var brand_translate_pipe_1 = require("@app/common/pipes/brand-translate.pipe");
var username_pipe_1 = require("@app/common/pipes/username.pipe");
var auth_service_1 = require("@app/common/services/auth.service");
var readiness_state_enum_1 = require("@app/ps/enums/readiness-state.enum");
var ProjectInfoSettingsComponent = /** @class */ (function () {
    function ProjectInfoSettingsComponent(APP_BRAND, restangularSettings, userNamePipe, authService, dialogService, brandTranslatePipe) {
        this.APP_BRAND = APP_BRAND;
        this.restangularSettings = restangularSettings;
        this.userNamePipe = userNamePipe;
        this.authService = authService;
        this.dialogService = dialogService;
        this.brandTranslatePipe = brandTranslatePipe;
        this.readinessStateOptions = readiness_state_enum_1.readinessStateOptions;
    }
    ProjectInfoSettingsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.state = this.getOptions('state').find(function (item) { return item.id === _this.project.state; });
        this.unit = this.getOptions('unit').find(function (item) { return item.id === _this.project.unit; });
        this.region = this.getOptions('region').find(function (item) { return item.id === _this.project.region; });
        this.infrastructure = this.getOptions('infrastructure').find(function (item) { return item.id === _this.project.infrastructure; });
        this.managerFilter = {
            filters: {},
            sortOrder: [
                { sortBy: 'surname' },
                { sortBy: 'name' },
            ],
        };
        this.propertyActivityProcessorFilter = {
            filters: {},
            sortOrder: [
                { sortBy: 'surname' },
                { sortBy: 'name' },
            ],
        };
        if (this.APP_BRAND.NAME === 'RSD') {
            this.managerFilter.filters.ssoCompanies = { values: ['ŘSD'] };
            this.propertyActivityProcessorFilter.filters.ssoCompanies = { values: ['ŘSD'], negation: true };
        }
    };
    ProjectInfoSettingsComponent.prototype.getOptions = function (type) {
        var _this = this;
        if (type === 'state') {
            return ProjectInfoSettingsComponent.STATE_LIST.map(function (i) {
                return {
                    id: i,
                    name: _this.brandTranslatePipe.transform('ps.projectState.' + i)
                };
            });
        }
        var list = [];
        switch (type) {
            case 'unit':
                list = ProjectInfoSettingsComponent.UNIT_LIST;
                break;
            case 'infrastructure':
                list = ProjectInfoSettingsComponent.INFRASTRUCTURE_LIST;
                break;
            case 'region':
                list = ProjectInfoSettingsComponent.REGION_LIST;
                break;
        }
        return list.map(function (i) { return { id: i, name: i }; });
    };
    ProjectInfoSettingsComponent.prototype.onStateChanged = function (value) {
        this.project.state = value ? value.id : undefined;
    };
    ProjectInfoSettingsComponent.prototype.onUnitChanged = function (value) {
        this.project.unit = value ? value.id : '';
    };
    ProjectInfoSettingsComponent.prototype.onInfrastructureChanged = function (value) {
        this.project.infrastructure = value ? value.id : '';
    };
    ProjectInfoSettingsComponent.prototype.onRegionChanged = function (value) {
        this.project.region = value ? value.id : '';
    };
    ProjectInfoSettingsComponent.prototype.onEditProjectSubject = function (projectSubject, type, discriminator) {
        var _this = this;
        var dialog = this.dialogService.open(project_subject_simple_ofo_component_1.ProjectSubjectSimpleOfoComponent, { data: {
                projectSubject: projectSubject,
                discriminator: discriminator
            } });
        var sub = dialog.afterClosed.subscribe(function (res) {
            if (res) {
                _this.project[type] = res;
            }
            sub.unsubscribe();
        });
    };
    ProjectInfoSettingsComponent.STATE_LIST = ['CREATED', 'STARTED', 'PAUSED', 'FINISHED'];
    ProjectInfoSettingsComponent.UNIT_LIST = ['Úsek výstavby', 'Úsek provozní'];
    ProjectInfoSettingsComponent.INFRASTRUCTURE_LIST = ['neurčeno', 'D0', 'D1', 'D10', 'D11', 'D2', 'D3', 'D35', 'D4', 'D46', 'D48', 'D5', 'D52', 'D55', 'D56', 'D6', 'D7', 'D8', 'I/10', 'I/11', 'I/12', 'I/13', 'I/14', 'I/15', 'I/16', 'I/17', 'I/18', 'I/19', 'I/2', 'I/20', 'I/21', 'I/22', 'I/23', 'I/24', 'I/25', 'I/26', 'I/27', 'I/28', 'I/29', 'I/3', 'I/30', 'I/31', 'I/32', 'I/33', 'I/34', 'I/35', 'I/36', 'I/37', 'I/38', 'I/39', 'I/4', 'I/40', 'I/41', 'I/42', 'I/43', 'I/44', 'I/45', 'I/46', 'I/47', 'I/48', 'I/49', 'I/5', 'I/50', 'I/51', 'I/52', 'I/53', 'I/54', 'I/55', 'I/56', 'I/57', 'I/58', 'I/59', 'I/6', 'I/60', 'I/61', 'I/62', 'I/63', 'I/64', 'I/65', 'I/66', 'I/67', 'I/68', 'I/69', 'I/7', 'I/70', 'I/71', 'I/8', 'I/9'];
    ProjectInfoSettingsComponent.REGION_LIST = ['Hlavní město Praha', 'Středočeský kraj', 'Jihočeský kraj', 'Plzeňský kraj', 'Karlovarský kraj', 'Ústecký kraj', 'Liberecký kraj', 'Královéhradecký kraj', 'Pardubický kraj', 'Vysočina', 'Jihomoravský kraj', 'Olomoucký kraj', 'Zlínský kraj', 'Moravskoslezský kraj'];
    return ProjectInfoSettingsComponent;
}());
exports.ProjectInfoSettingsComponent = ProjectInfoSettingsComponent;
