
var common = angular.module('common');

/**
 * Handle modals
 */
common.service('ChecklistService', function () {
    return function (checkedItems, toggleCallback) {
        if (!checkedItems) {
            checkedItems = [];
        }

        const svc = {
            identity: null,
            checkedItems: checkedItems,
            beforeCallback: toggleCallback,
            transformation: null,
            afterCallback: null,
            toggleSelection: function (item, event) {
                if (typeof this.beforeCallback === 'function') {
                    var beforeCallbackResult = this.beforeCallback(event, item);
                    if (beforeCallbackResult === false) {
                        return;
                    } else if (typeof beforeCallbackResult !== 'boolean') {
                        item = beforeCallbackResult;
                    }
                }
                if (typeof this.transformation === 'function') {
                    item = this.transformation(item);
                }
                var idx = this.indexOf(item);
                var result = null;
                if (idx > -1) {
                    this.checkedItems.splice(idx, 1);
                    result = false;
                }
                else {
                    this.checkedItems.push(item);
                    result = true;
                }

                if (typeof this.afterCallback === 'function') {
                    this.afterCallback(event, item, result);
                }

                return result;
            },
            isChecked: function (item) {
                if (typeof this.transformation === 'function') {
                    item = this.transformation(item);
                }
                return this.indexOf(item) > -1;
            },
            indexOf: function(item) {
                if (this.identity) {
                    return _.findIndex(this.checkedItems, this.identity(item));
                } else if (item.id !== undefined) {
                    return _.findIndex(this.checkedItems, {id: item.id});
                } else {
                    return _.indexOf(this.checkedItems, item);
                }
            },
            empty: function() {
                checkedItems.splice(0, checkedItems.length);
            },
            isEmpty: function() {
                return !checkedItems.length;
            },
        };
        svc.indexOf = svc.indexOf.bind(svc);
        svc.isChecked = svc.isChecked.bind(svc);
        svc.toggleSelection = svc.toggleSelection.bind(svc);
        return svc;
    };
});
