"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var _ = require("lodash");
var ArrayUtils = /** @class */ (function () {
    function ArrayUtils() {
    }
    ArrayUtils.generateIntegerArray = function (from, to) {
        if (from === void 0) { from = 0; }
        if (to === void 0) { to = 10; }
        var result = [];
        for (var i = from; i < to; i++) {
            result.push(i);
        }
        return result;
    };
    ArrayUtils.getLongestCommonSubstring = function (arr, caseSensitive) {
        if (caseSensitive === void 0) { caseSensitive = false; }
        if (!_.isArray(arr)) {
            return;
        }
        var sorted = arr.concat().sort();
        if (caseSensitive) {
            sorted = _.map(sorted, function (item) { return item.toLowerCase(); });
        }
        var shortest = sorted[0];
        var longest = sorted[sorted.length - 1];
        var shortestLength = shortest.length;
        var i = 0;
        while (i < shortestLength && shortest.charAt(i) === longest.charAt(i)) {
            i += 1;
        }
        return shortest.substring(0, i);
    };
    return ArrayUtils;
}());
exports.ArrayUtils = ArrayUtils;
