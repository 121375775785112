"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["[_nghost-%COMP%]   .item[_ngcontent-%COMP%] {\n  padding: 8px;\n  border-bottom: 1px solid #dddddd;\n  box-sizing: border-box; }\n  [_nghost-%COMP%]   .item[_ngcontent-%COMP%]:hover {\n    background-color: #fbfbdb;\n    cursor: pointer; }\n  [_nghost-%COMP%]   .item[_ngcontent-%COMP%]   .item-checkbox[_ngcontent-%COMP%] {\n    padding-right: 1rem; }\n\n[_nghost-%COMP%]   .column-50[_ngcontent-%COMP%]:nth-of-type(odd) {\n  border-right: 1px solid #dddddd; }\n\n[_nghost-%COMP%]   .column-50[_ngcontent-%COMP%]:nth-of-type(odd):last-of-type   .item[_ngcontent-%COMP%] {\n  border-bottom: none; }"];
exports.styles = styles;
