"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var TabbedBlockComponent = /** @class */ (function () {
    function TabbedBlockComponent() {
        this.updated = new core_1.EventEmitter();
        this.onUpdateSelection = this.onUpdateSelection.bind(this);
    }
    TabbedBlockComponent.prototype.onUpdateSelection = function (tab) {
        this.selected = tab.id;
        this.updated.emit(tab);
    };
    return TabbedBlockComponent;
}());
exports.TabbedBlockComponent = TabbedBlockComponent;
