"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var angular_1 = require("@uirouter/angular");
var VfzeImportComponent = /** @class */ (function () {
    function VfzeImportComponent(helpIds, confirmService, transitionService, stateService) {
        this.helpIds = helpIds;
        this.confirmService = confirmService;
        this.transitionService = transitionService;
        this.stateService = stateService;
        this.stepsHistory = [];
        this.data = {};
        this.steps = [
            { id: 'upload', name: 'Nahrání dat', enabled: false, help: '' },
            { id: 'validation', name: 'Validace VFZE', enabled: false, help: '' },
            { id: 'save', name: 'Uložení VFZE', enabled: false, help: '' },
        ];
    }
    VfzeImportComponent.prototype.ngOnInit = function () {
        this.onStep('upload', true);
        this.deregisterLeaveListener = this.transitionService.onBefore({}, this.beforeExit.bind(this));
    };
    VfzeImportComponent.prototype.beforeExit = function (transition) {
        var _this = this;
        if (transition && transition.to() === transition.from()) {
            return;
        }
        return new this.confirmService({
            title: 'Zrušit vložení VFZE',
            message: 'Opravdu chcete odejít? Všechna zadaná data budou ztracena.'
        }).then(function (data) {
            if (data === true) {
                _this.deregisterLeaveListener();
            }
            else {
                return Promise.resolve(false);
            }
        });
    };
    VfzeImportComponent.prototype.onStep = function (id, enable) {
        var _this = this;
        if (enable === void 0) { enable = false; }
        var step = this.steps.find(function (s) { return s.id === id; });
        if (step.enabled === false && enable === false) {
            return;
        }
        // step back
        if (this.stepsHistory.includes(id)) {
            this.activeStep = step;
            this.stepsHistory.splice(this.stepsHistory.indexOf(id) + 1);
            this.steps.forEach(function (v) { return v.enabled = _this.stepsHistory.includes(v.id); });
            // step forward
        }
        else {
            this.activeStep = step;
            this.stepsHistory.push(id);
            step.enabled = true;
            if (step.id === 'import') {
                this.steps.filter(function (step) { return step.id !== 'import'; }).forEach(function (step) {
                    step.enabled = false;
                });
            }
        }
    };
    VfzeImportComponent.prototype.onFinish = function () {
        this.deregisterLeaveListener();
        this.stateService.go('symap.project.vfze');
    };
    return VfzeImportComponent;
}());
exports.VfzeImportComponent = VfzeImportComponent;
