"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var angular_1 = require("@uirouter/angular");
var opinion_request_model_1 = require("@app/ps/models/opinion-request.model");
var opinion_request_service_1 = require("@app/ps/services/opinion-request.service");
var auth_service_1 = require("@app/common/services/auth.service");
var class_name_enum_1 = require("@app/dialog/class-name.enum");
var dialog_service_1 = require("@app/dialog/dialog.service");
var opinion_request_edit_component_1 = require("@app/ps/opinions/components/opinion-request-edit/opinion-request-edit.component");
var opinion_request_status_enum_1 = require("@app/ps/enums/opinion-request-status.enum");
var list_service_1 = require("@app/common/services/list.service");
var opinion_service_1 = require("@app/ps/services/opinion.service");
var alert_component_1 = require("@app/dialog/alert/alert.component");
var geometric_plan_request_service_1 = require("@app/ps/services/geometric-plan-request.service");
var OpinionRequestDetailComponent = /** @class */ (function () {
    function OpinionRequestDetailComponent(helpIds, restangular, requestService, authService, opinionService, dialogService, stateService, listService, geometricPlanRequestService) {
        this.helpIds = helpIds;
        this.restangular = restangular;
        this.requestService = requestService;
        this.authService = authService;
        this.opinionService = opinionService;
        this.dialogService = dialogService;
        this.stateService = stateService;
        this.listService = listService;
        this.geometricPlanRequestService = geometricPlanRequestService;
        this.statusEnum = opinion_request_status_enum_1.OpinionRequestStatusEnum;
        this.loading = false;
        this.PARCELS_COLUMNS = [
            { id: 'parcel', sortable: false },
            { id: 'title', sortable: false },
            { id: 'area_name', sortable: false },
            { id: 'area', sortable: false },
            { id: 'parcel_price', sortable: false },
        ];
        this.BUILDINGS_COLUMNS = [
            { id: 'parcel', sortable: false },
            { id: 'parcel_title', sortable: false },
            { id: 'building_title', sortable: false },
            { id: 'house_number', sortable: false },
            { id: 'price', sortable: false },
        ];
        this.STATUS_KEYS_REGEXP = new RegExp(opinion_request_status_enum_1.opinionRequestStatusOptions.map(function (opt) { return opt.id; }).join('|'));
        this.activeTab = this.stateService.params.tab;
    }
    OpinionRequestDetailComponent.prototype.ngOnInit = function () {
        this.tabs = this.getTabs();
        this.isActiveTab = this.isActiveTab.bind(this);
        this.loadDocuments();
        this.loadHistory();
    };
    OpinionRequestDetailComponent.prototype.isActiveTab = function (id) {
        return id === this.activeTab;
    };
    OpinionRequestDetailComponent.prototype.uiOnParamsChanged = function (changedParams) {
        this.activeTab = changedParams.tab;
    };
    OpinionRequestDetailComponent.prototype.onUpdateDocumentList = function () {
        var documentTab = this.tabs.find(function (tab) { return tab.id === 'documents'; });
        documentTab.name = "Dokumenty (" + this.documentList.list.filter(function (a) { return !a.cancelled; }).length + ")";
    };
    OpinionRequestDetailComponent.prototype.onUpdateHistoryList = function () {
        var _this = this;
        this.historyList.list.forEach(function (note) {
            note.text = note.text.replace(_this.STATUS_KEYS_REGEXP, function (status) { return _this.requestService.getStatusEnumLabel(status); });
        });
        var historyTab = this.tabs.find(function (tab) { return tab.id === 'history'; });
        historyTab.name = "Historie (" + this.historyList.itemCount + ")";
    };
    OpinionRequestDetailComponent.prototype.onEdit = function (status) {
        return __awaiter(this, void 0, void 0, function () {
            var parcelsOk, canFillPrices, dialog, sub;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(status === this.statusEnum.PRICES_FILLED)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.pairGpParcelsToOpinion()];
                    case 1:
                        parcelsOk = _a.sent();
                        if (!parcelsOk) {
                            this.dialogService.open(alert_component_1.AlertComponent, {
                                className: class_name_enum_1.ClassName.ADJUSTED_DIALOG,
                                data: { msg: 'Některá z ručně zadaných parcel nebyla nalezena v GP. Kontaktujte podporu.' },
                            });
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, this.canFillPrices()];
                    case 2:
                        canFillPrices = _a.sent();
                        if (!canFillPrices) {
                            this.dialogService.open(alert_component_1.AlertComponent, {
                                className: class_name_enum_1.ClassName.ADJUSTED_DIALOG,
                                data: { msg: 'Posudek na některou z parcel či budov je již vytvořen nebo nejsou k dispozici trvalé zábory.' },
                            });
                        }
                        else {
                            this.stateService.go('symap.project.opinionRequests.detail.fillPricesForm', {
                                id: this.opinionRequest.id, titleId: this.opinionRequest.title.id
                            });
                        }
                        return [2 /*return*/];
                    case 3:
                        if (status && !this.requestService.isStatusDialog(status)) {
                            this.opinionRequest.status = status;
                            this.uploadAndRefresh(this.opinionRequest, []);
                            return [2 /*return*/];
                        }
                        dialog = this.dialogService.open(opinion_request_edit_component_1.OpinionRequestEditComponent, {
                            data: {
                                opinionRequest: __assign({}, this.opinionRequest),
                                editMode: (status ? 'change-status' : 'edit'),
                                newStatus: status
                            },
                            className: class_name_enum_1.ClassName.HIGHER_DIALOG,
                        });
                        sub = dialog.afterClosed
                            .subscribe(function (result) {
                            sub.unsubscribe();
                            if (typeof result === 'object') {
                                _this.uploadAndRefresh(result.opinionRequest, result.files);
                            }
                            return;
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    OpinionRequestDetailComponent.prototype.canEdit = function () {
        return this.authService.hasPermission('manage_opinions');
    };
    OpinionRequestDetailComponent.prototype.isStatusButtonVisible = function (status) {
        if (status === opinion_request_status_enum_1.OpinionRequestStatusEnum.PRICES_FILLED) {
            return this.authService.hasPermission('assignable');
        }
        return true;
    };
    OpinionRequestDetailComponent.prototype.getTabs = function () {
        return [
            {
                name: "Parcely (" + this.opinionRequest.parcels.length + ")",
                id: 'parcels',
                href: 'symap.project.opinionRequests.detail',
                urlParams: {
                    id: this.opinionRequest.id,
                    tab: 'parcels',
                },
            },
            {
                name: "Budovy (" + this.opinionRequest.buildings.length + ")",
                id: 'buildings',
                href: 'symap.project.opinionRequests.detail',
                urlParams: {
                    id: this.opinionRequest.id,
                    tab: 'buildings',
                },
            },
            {
                name: 'Historie',
                id: 'history',
                href: "symap.project.opinionRequests.detail",
                urlParams: {
                    id: this.opinionRequest.id,
                    tab: 'history'
                }
            },
            {
                name: 'Dokumenty',
                id: 'documents',
                href: 'symap.project.opinionRequests.detail',
                urlParams: {
                    id: this.opinionRequest.id,
                    tab: 'documents',
                },
            },
        ];
    };
    OpinionRequestDetailComponent.prototype.loadDocuments = function () {
        var _this = this;
        this.documentList = this.listService.createList('attachments', {
            filters: { opinionRequestId: this.opinionRequest.id, cancelled: false },
            sortOrder: { sortBy: 'timestamp', direction: 'desc' }
        }, undefined);
        return this.listService.fetchResult(this.documentList).then(function () {
            _this.onUpdateDocumentList();
        });
    };
    OpinionRequestDetailComponent.prototype.loadHistory = function () {
        var _this = this;
        this.historyList = this.listService.createList('notes', {
            filters: { opinionRequestId: this.opinionRequest.id, systemType: true },
            sortOrder: { sortBy: 'timestamp', direction: 'desc' }
        }, undefined);
        return this.listService.fetchResult(this.historyList).then(function () {
            _this.onUpdateHistoryList();
        });
    };
    OpinionRequestDetailComponent.prototype.uploadAndRefresh = function (opinionRequest, files) {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.loading = true;
                        _a = this;
                        return [4 /*yield*/, this.requestService.update(opinionRequest, files)];
                    case 1:
                        _a.opinionRequest = _b.sent();
                        return [4 /*yield*/, this.loadDocuments()];
                    case 2:
                        _b.sent();
                        return [4 /*yield*/, this.loadHistory()];
                    case 3:
                        _b.sent();
                        this.loading = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    OpinionRequestDetailComponent.prototype.canFillPrices = function () {
        var _this = this;
        var parcelFilter = {
            filters: {
                opinionRequestId: [this.opinionRequest.id],
                validity: ['valid'],
                permanentOccupationExists: [true],
            },
        };
        return this.opinionService
            .getParcels(parcelFilter)
            .then(function (parcels) {
            return (parcels.length === _this.opinionRequest.parcels.length)
                && parcels.every(function (p) { return p.buyoutOpinionsShare < 100 || !p.buyoutOpinionsShare; });
        });
    };
    OpinionRequestDetailComponent.prototype.pairGpParcelsToOpinion = function () {
        return __awaiter(this, void 0, void 0, function () {
            var e_1, _a, temporaryParcels, geometricPlanRequest, gpParcels, newParcels, _loop_1, _b, _c, parcel, state_1;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        temporaryParcels = this.opinionRequest.parcels.filter(function (p) { return p.idpar < 0; });
                        if (temporaryParcels.length === 0 || !this.opinionRequest.geometricPlanRequest) {
                            return [2 /*return*/, Promise.resolve(true)];
                        }
                        return [4 /*yield*/, this.geometricPlanRequestService.get(this.opinionRequest.geometricPlanRequest.id, ['ngGp.knBudoucs', 'ngGp.knBudoucs.futureParcel'])];
                    case 1:
                        geometricPlanRequest = _d.sent();
                        if (!geometricPlanRequest || !geometricPlanRequest.knGp || !geometricPlanRequest.knGp.knBudoucs) {
                            return [2 /*return*/, Promise.resolve(false)];
                        }
                        gpParcels = geometricPlanRequest.knGp.knBudoucs
                            .filter(function (kb) { return kb.parcel; })
                            .map(function (kb) { return kb.parcel; });
                        newParcels = [];
                        _loop_1 = function (parcel) {
                            if (parcel.idpar > 0) {
                                newParcels.push(parcel);
                                return "continue";
                            }
                            var gpParcel = gpParcels.find(function (p) {
                                return (p.katuzeNazev === parcel.katuzeNazev
                                    && p.parcisKmen === parcel.parcisKmen
                                    && p.parcisPod === parcel.parcisPod);
                            });
                            if (!gpParcel) {
                                return { value: Promise.resolve(false) };
                            }
                            newParcels.push(gpParcel);
                        };
                        try {
                            for (_b = __values(this.opinionRequest.parcels), _c = _b.next(); !_c.done; _c = _b.next()) {
                                parcel = _c.value;
                                state_1 = _loop_1(parcel);
                                if (typeof state_1 === "object")
                                    return [2 /*return*/, state_1.value];
                            }
                        }
                        catch (e_1_1) { e_1 = { error: e_1_1 }; }
                        finally {
                            try {
                                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                            }
                            finally { if (e_1) throw e_1.error; }
                        }
                        // store paired parcels
                        this.opinionRequest.parcels = newParcels;
                        return [2 /*return*/, this.requestService.update(this.opinionRequest)];
                }
            });
        });
    };
    return OpinionRequestDetailComponent;
}());
exports.OpinionRequestDetailComponent = OpinionRequestDetailComponent;
