"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var send_user_email_component_1 = require("@app/ps/project-settings/components/send-user-email/send-user-email.component");
var state_utils_1 = require("@app/common/utils/state.utils");
var users_states_1 = require("@app/settings/users/users.states");
var administrator_list_component_1 = require("@app/ps/project-settings/components/administrator-list/administrator-list.component");
var administrator_detail_component_1 = require("@app/ps/project-settings/components/administrator-detail/administrator-detail.component");
var expert_list_component_1 = require("@app/ps/project-settings/components/expert-list/expert-list.component");
var expert_detail_component_1 = require("@app/ps/project-settings/components/expert-detail/expert-detail.component");
var template_list_component_1 = require("@app/ps/project-settings/components/template-list/template-list.component");
var sample_list_component_1 = require("@app/ps/project-settings/components/sample-list/sample-list.component");
var project_settings_main_component_1 = require("@app/ps/project-settings/components/project-settings-main/project-settings-main.component");
var template_history_component_1 = require("@app/ps/project-settings/components/template-history/template-history.component");
var user_list_component_1 = require("@app/common/components/user-list/user-list.component");
var user_detail_component_1 = require("@app/common/components/user-detail/user-detail.component");
var user_credentials_component_1 = require("@app/common/components/user-credentials/user-credentials.component");
var project_update_component_1 = require("@app/ps/project-settings/components/project-update/project-update.component");
var project_create_component_1 = require("@app/ps/project-settings/components/project-create/project-create.component");
var auth_service_1 = require("@app/common/services/auth.service");
var modules_service_1 = require("@app/common/services/modules.service");
var callback_service_1 = require("@app/ps/services/callback.service");
var angular_1 = require("@uirouter/angular");
var bulk_price_component_1 = require("@app/ps/project-settings/components/bulk-price/bulk-price/bulk-price.component");
exports.projectSettingsMainState = {
    name: 'symap.project.projectSettings',
    url: '/settings',
    data: { title: 'Nastavení' },
    views: {
        'content@symap.project': {
            component: project_settings_main_component_1.ProjectSettingsMainComponent
        }
    }
};
exports.projectCreateState = {
    name: 'symap.project.projectSettings.create',
    url: '/project-create',
    data: { title: 'Vytvoření projektu' },
    views: {
        'content@symap.project': {
            component: project_create_component_1.ProjectCreateComponent
        }
    }
};
function resolveGToPrevState($stateParams) {
    return $stateParams.goToPrevState;
}
exports.resolveGToPrevState = resolveGToPrevState;
function resolvePrevState($transition$) {
    return $transition$.$from().name;
}
exports.resolvePrevState = resolvePrevState;
exports.projectUpdateState = {
    name: 'symap.project.projectSettings.update',
    url: '/project-update',
    data: { title: 'Nastavení' },
    views: {
        'content@symap.project': {
            component: project_update_component_1.ProjectUpdateComponent
        }
    },
    resolve: {
        goToPrevState: ['$stateParams', resolveGToPrevState],
        prevState: ['$transition$', resolvePrevState]
    },
    params: {
        goToPrevState: {
            type: 'bool',
            value: false
        },
    }
};
exports.userCredentialsState = {
    name: 'symap.project.projectSettings.userCredentials',
    url: '/user-credentials',
    data: {
        title: 'Změna přihlašovacích údajů',
    },
    views: {
        'content@symap.project': {
            component: user_credentials_component_1.UserCredentialsComponent,
        }
    },
    params: {
        backSref: {
            type: 'string',
            value: 'symap.project.projectSettings'
        },
    }
};
exports.sendUserEmailState = {
    name: 'symap.project.projectSettings.users.detail.email',
    url: '/send-user-email',
    data: { title: 'Email uživateli' },
    views: {
        'content@symap.project': {
            component: send_user_email_component_1.SendUserEmailComponent
        }
    }
};
exports.administratorListState = {
    name: 'symap.project.projectSettings.administrators',
    url: '/administrators?page',
    data: {
        title: 'Správci $CONSTRUCTION_OBJECT_PLURAL_GENITIV',
    },
    views: {
        'content@symap.project': {
            component: administrator_list_component_1.AdministratorListComponent,
        },
    },
    params: {
        page: {
            dynamic: true,
            type: 'int',
            inherit: false,
        },
    },
};
exports.administratorDetailState = {
    name: 'symap.project.projectSettings.administrators.detail',
    url: '/:id',
    data: {
        title: 'Detail správce',
    },
    views: {
        'content@symap.project': {
            component: administrator_detail_component_1.AdministratorDetailComponent,
        }
    },
    resolve: {
        administratorId: ['$stateParams', state_utils_1.resolveId]
    },
    params: {
        id: {
            type: 'int',
            value: undefined
        },
    },
};
exports.expertListState = {
    name: 'symap.project.projectSettings.experts',
    url: '/experts?page',
    data: {
        title: 'Odhadci',
    },
    views: {
        'content@symap.project': {
            component: expert_list_component_1.ExpertListComponent,
        },
    },
    params: {
        page: {
            dynamic: true,
            type: 'int',
            inherit: false,
        },
    },
};
exports.expertDetailState = {
    name: 'symap.project.projectSettings.experts.detail',
    url: '/:id',
    data: {
        title: 'Detail odhadce',
    },
    views: {
        'content@symap.project': {
            component: expert_detail_component_1.ExpertDetailComponent,
        }
    },
    resolve: {
        expertId: ['$stateParams', state_utils_1.resolveId]
    },
    params: {
        id: {
            type: 'int',
            value: undefined
        },
    },
};
exports.templateListState = {
    name: 'symap.project.projectSettings.templates',
    url: '/templates?page',
    onEnter: handleTemplateListState,
    params: {
        page: {
            dynamic: true,
            type: 'int',
            inherit: false,
        },
    },
};
function handleTemplateListState($transition$) {
    return __awaiter(this, void 0, void 0, function () {
        var authService, stateService;
        return __generator(this, function (_a) {
            authService = $transition$.injector().get(auth_service_1.AuthService);
            stateService = $transition$.injector().get(angular_1.StateService);
            if ($transition$.$to().name !== exports.templateListState.name) {
                return [2 /*return*/];
            }
            if (authService.getActualProject().useTemplatesService === true) {
                return [2 /*return*/, stateService.go(exports.templateExternalListState.name, $transition$.params())];
            }
            else {
                return [2 /*return*/, stateService.go(exports.templateInternalListState.name, $transition$.params())];
            }
            return [2 /*return*/];
        });
    });
}
exports.handleTemplateListState = handleTemplateListState;
handleTemplateListState.$inject = ['$transition$'];
exports.templateInternalListState = {
    name: 'symap.project.projectSettings.templates.internal',
    data: {
        title: 'Šablony',
    },
    views: {
        'content@symap.project': {
            component: template_list_component_1.TemplateListComponent,
        },
    }
};
exports.templateExternalListState = {
    name: 'symap.project.projectSettings.templates.external',
    data: {
        title: 'Správa šablon',
    },
    views: {
        'content@symap.project': {
            component: sample_list_component_1.SampleListComponent,
        },
    }
};
function keyResolveFunc($stateParams) {
    return $stateParams.key;
}
exports.keyResolveFunc = keyResolveFunc;
exports.templateHistoryState = {
    name: 'symap.project.projectSettings.templates.history',
    url: '/history/:key?page',
    data: {
        title: 'Historie šablon',
    },
    views: {
        'content@symap.project': {
            component: template_history_component_1.TemplateHistoryComponent,
        },
    },
    resolve: {
        key: ['$stateParams', keyResolveFunc]
    },
    params: {
        key: {
            type: 'path',
            value: undefined
        },
        page: {
            dynamic: true,
            type: 'int',
            inherit: false,
        },
    },
};
exports.userListState = {
    name: 'symap.project.projectSettings.users',
    url: '/users?page',
    data: {
        title: 'Uživatelé',
    },
    views: {
        'content@symap.project': {
            component: user_list_component_1.UserListComponent,
        },
    },
    params: {
        page: {
            dynamic: true,
            type: 'int',
            inherit: false,
        },
    },
};
exports.userDetailState = {
    name: 'symap.project.projectSettings.users.detail',
    url: '/:id',
    data: {
        title: 'Detail uživatele',
    },
    views: {
        'content@symap.project': {
            component: user_detail_component_1.UserDetailComponent,
        }
    },
    resolve: {
        userId: ['$stateParams', state_utils_1.resolveId],
        callbacks: [callback_service_1.CallbackService, state_utils_1.resolveCallbacks],
    },
    params: {
        id: {
            type: 'int',
            value: undefined
        },
    },
};
function resolveApplication($stateParams, $transition$) {
    var authService = $transition$.injector().get(auth_service_1.AuthService);
    return authService.getActiveApplication();
}
exports.resolveApplication = resolveApplication;
function resolveUser($stateParams, $transition$) {
    return __awaiter(this, void 0, void 0, function () {
        var modulesService;
        return __generator(this, function (_a) {
            modulesService = $transition$.injector().get(modules_service_1.ModulesService);
            return [2 /*return*/, modulesService.loadUser($stateParams.id).then(function () { return modulesService.getUser($stateParams.id); })];
        });
    });
}
exports.resolveUser = resolveUser;
function resolvePermissionsCallback(callbacks) {
    return callbacks.get('userPermissionsChangedCallback');
}
exports.resolvePermissionsCallback = resolvePermissionsCallback;
exports.updateUserPermissionsState = {
    name: 'symap.project.projectSettings.users.detail.updateUserPermissions',
    url: '/user-permissions',
    data: {
        title: 'Editace oprávnění uživatele',
    },
    resolve: {
        application: ['$stateParams', '$transition$', resolveApplication],
        projectKey: ['$stateParams', users_states_1.resolveProjectKey],
        user: ['$stateParams', '$transition$', resolveUser],
        userPermissionsChangedCallback: ['callbacks', resolvePermissionsCallback],
    },
    onEnter: [
        '$transition$',
        '$state',
        'userPermissionsChangedCallback',
        'application',
        'projectKey',
        'user',
        users_states_1.handleUserPermissionsModal
    ],
};
exports.bulkPricesState = {
    name: 'symap.project.projectSettings.bulkPrices',
    url: '/bulk-prices',
    data: { title: 'Hromadné nastavení cen' },
    views: {
        'content@symap.project': {
            component: bulk_price_component_1.BulkPriceComponent
        }
    }
};
