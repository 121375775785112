"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var $ = require("jquery");
var pickadate_component_1 = require("../../components/pickadate/pickadate.component");
var PickADateDirective = /** @class */ (function () {
    function PickADateDirective(elementRef, windowDimensionService, renderer, componentFactoryResolver, viewContainerRef) {
        this.elementRef = elementRef;
        this.windowDimensionService = windowDimensionService;
        this.renderer = renderer;
        this.componentFactoryResolver = componentFactoryResolver;
        this.viewContainerRef = viewContainerRef;
        this.maxToday = false;
        this._ngModel = undefined;
        this.ngModelChange = new core_1.EventEmitter();
        this.blockEvent = false;
        this.checkPosition = this.checkPosition.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.onModelChange = this.onModelChange.bind(this);
    }
    Object.defineProperty(PickADateDirective.prototype, "ngModel", {
        get: function () { return this._ngModel; },
        set: function (ngModel) {
            this._ngModel = ngModel;
            if (this.pickAdateElement) {
                this.onModelChange(ngModel);
            }
        },
        enumerable: true,
        configurable: true
    });
    PickADateDirective.prototype.onModelChange = function (newValue) {
        if (this.blockEvent) {
            this.blockEvent = false;
            return;
        }
        if (this.getPickerDate() === newValue) {
            return;
        }
        if (!newValue) {
            this.blockEvent = true;
            this.pickAdateElement.pickadate('picker').clear();
        }
        else {
            if (typeof newValue === 'string') {
                this.blockEvent = true;
                this.pickAdateElement.pickadate('picker').set('select', new Date(new Date(newValue).getTime() + (new Date(newValue).getTimezoneOffset() * 60 * 1000)));
            }
            else {
                this.blockEvent = true;
                this.pickAdateElement.pickadate('picker').set('select', new Date(newValue));
            }
        }
        this.pickAdateElement.removeClass('invalid');
    };
    PickADateDirective.prototype.onInputChange = function (newValue) {
        var parsed = this.parseDate(newValue);
        if (parsed !== undefined) {
            this.blockEvent = true;
            this.picker.set('select', parsed);
            this.picker.set('highlight', parsed);
            this.ngModelChange.emit(this.getPickerDate());
            this.pickAdateElement.removeClass('invalid');
        }
        else {
            this.blockEvent = true;
            this.ngModelChange.emit(null);
            this.pickAdateElement.addClass('invalid');
        }
    };
    PickADateDirective.prototype.ngOnInit = function () {
        var _this = this;
        this.renderer.setStyle(this.elementRef.nativeElement, 'display', 'none');
        var factory = this.componentFactoryResolver.resolveComponentFactory(pickadate_component_1.PickADateComponent);
        this.componentRef = this.viewContainerRef.createComponent(factory);
        this.componentRef.instance.changed.subscribe(this.onInputChange);
        this.dateSelectorElement = $(this.componentRef.location.nativeElement.querySelector('.date-selector'));
        this.pickAdateElement = $(this.componentRef.location.nativeElement.querySelector('input'));
        this.pickAdateElement.pickadate({
            editable: true,
            onOpen: this.checkPosition,
            min: new Date(1900, 0, 1),
            max: (this.maxToday ? new Date() : new Date(2099, 11, 31))
        });
        this.picker = this.pickAdateElement.pickadate('picker');
        $(this.pickAdateElement).on('click', function (event) {
            if (_this.picker.get('open')) {
                _this.picker.close();
            }
            else {
                _this.picker.open();
            }
            event.stopPropagation();
        });
        this.picker.on({
            set: function (thingSet) {
                if (_this.blockEvent) {
                    _this.blockEvent = false;
                    return;
                }
                if (thingSet.select) {
                    _this.blockEvent = true;
                    var selected = _this.getPickerDate();
                    _this.ngModelChange.emit(selected);
                }
                else if (thingSet.clear === null) {
                    _this.blockEvent = true;
                    _this.ngModelChange.emit(null);
                }
            },
        });
        if (this.ngModel) {
            this.onModelChange(this.ngModel);
            this.componentRef.instance.dateInput = this.picker.get('select', 'd.m.yyyy');
        }
        if (this.placeholder) {
            this.componentRef.instance.placeholder = this.placeholder;
        }
    };
    PickADateDirective.prototype.getPickerDate = function () {
        return this.picker.get('select') ? this.picker.get('select', 'yyyy-mm-dd') : null;
    };
    PickADateDirective.prototype.checkPosition = function () {
        var pickerElement = $(this.dateSelectorElement.find('div.picker'));
        var dimensions = new this.windowDimensionService(this.elementRef.nativeElement, 5);
        pickerElement.css({
            marginTop: (dimensions.windowHeight + dimensions.windowOffsetTop < this.pickAdateElement.offset().top + this.pickAdateElement.outerHeight() + pickerElement.outerHeight()
                && this.pickAdateElement.offset().top - dimensions.windowOffsetTop > pickerElement.outerHeight()
                ? -(pickerElement.outerHeight() + this.pickAdateElement.outerHeight()) + 'px' : 0),
            marginLeft: this.pickAdateElement.offset().left + pickerElement.outerWidth() > dimensions.windowWidth + dimensions.windowOffsetLeft
                && pickerElement.outerWidth() > this.pickAdateElement.outerWidth()
                ? -(pickerElement.outerWidth() - this.pickAdateElement.outerWidth()) + 'px' : ''
        });
    };
    PickADateDirective.prototype.parseDate = function (input) {
        if (!input) {
            return null;
        }
        var parts = input.split('.').map(function (part) { return isFinite(parseInt(part)) ? Number(part) : null; });
        if (parts.length !== 3) {
            return undefined;
        }
        if (!isFinite(parts[2]) || parts[2] < 1000) {
            return undefined;
        }
        if (!isFinite(parts[1]) || parts[1] < 1 || parts[1] > 12) {
            return undefined;
        }
        if (!isFinite(parts[0]) || parts[0] < 1 || parts[0] > 31) {
            return undefined;
        }
        // sets date in local timezone
        var date = new Date(parts[2], parts[1] - 1, parts[0]);
        if (date.getDate() !== parts[0] ||
            date.getMonth() + 1 !== parts[1] ||
            date.getFullYear() !== parts[2]) {
            return undefined;
        }
        return date;
    };
    return PickADateDirective;
}());
exports.PickADateDirective = PickADateDirective;
