"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./notification-config.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/common");
var i3 = require("../../../../common/components/select/select.component.ngfactory");
var i4 = require("../../../../common/components/select/select.component");
var i5 = require("@uirouter/angular");
var i6 = require("../../../../common/components/checkbox/checkbox.component.ngfactory");
var i7 = require("../../../../common/components/checkbox/checkbox.component");
var i8 = require("../../../../common/components/button/button.component.ngfactory");
var i9 = require("../../../../common/components/button/button.component");
var i10 = require("../../../../common/pipes/role.pipe");
var i11 = require("../../../../common/pipes/username.pipe");
var i12 = require("../../../../common/directives/loading.directive");
var i13 = require("./notification-config.component");
var i14 = require("../../../../common/services/list.service");
var i15 = require("../../../../common/services/auth.service");
var i16 = require("../../../../common/services/modules.service");
var styles_NotificationConfigComponent = [i0.styles];
var RenderType_NotificationConfigComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NotificationConfigComponent, data: {} });
exports.RenderType_NotificationConfigComponent = RenderType_NotificationConfigComponent;
function View_NotificationConfigComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "span", [["class", "mr-5 mb-5 tag"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onToggleRole(_v.parent.parent.parent.context.$implicit, _v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "active": 0 }), (_l()(), i1.ɵted(3, null, [" ", " "])), i1.ɵppd(4, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "mr-5 mb-5 tag"; var currVal_1 = _ck(_v, 2, 0, _co.isRoleEnabled(_v.parent.parent.parent.context.$implicit, _v.context.$implicit)); _ck(_v, 1, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = i1.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent.parent.parent.parent.parent, 0), _v.context.$implicit.id)); _ck(_v, 3, 0, currVal_2); }); }
function View_NotificationConfigComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "gmt-select", [["field", "userSelected"], ["required", "true"], ["selectTitle", "p\u0159idat u\u017Eivatele"]], null, [[null, "changed"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("changed" === en)) {
        var pd_0 = (_co.onUserSelected(_v.parent.parent.parent.context.$implicit, $event.newValue) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_SelectComponent_0, i3.RenderType_SelectComponent)), i1.ɵdid(1, 114688, null, 0, i4.SelectComponent, ["ListLoadService", "WindowDimensionService", i1.ChangeDetectorRef, i1.ElementRef, "Restangular", i5.StateService], { data: [0, "data"], field: [1, "field"], filter: [2, "filter"], itemPipe: [3, "itemPipe"], optionItems: [4, "optionItems"], reloadOptionsRegister: [5, "reloadOptionsRegister"], required: [6, "required"], selectTitle: [7, "selectTitle"] }, { changed: "changed" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co; var currVal_1 = "userSelected"; var currVal_2 = _v.parent.parent.parent.context.$implicit.userFilter; var currVal_3 = _co.userNamePipe; var currVal_4 = _co.users; var currVal_5 = _co.reloadOptionsRegisterUser(_v.parent.parent.parent.context.$implicit); var currVal_6 = "true"; var currVal_7 = "p\u0159idat u\u017Eivatele"; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, null); }
function View_NotificationConfigComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["(", ")"]))], null, function (_ck, _v) { var currVal_0 = _v.context.ngIf; _ck(_v, 1, 0, currVal_0); }); }
function View_NotificationConfigComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "span", [["class", "tag tag--small active mr-5"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵppd(2, 2), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationConfigComponent_9)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onUserUnselected(_v.parent.parent.parent.parent.context.$implicit, _v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "i", [["class", "fa fa-remove"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.getUserRole(_v.context.$implicit); _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent.parent.parent.parent.parent, 1), _v.context.$implicit, false)); _ck(_v, 1, 0, currVal_0); }); }
function View_NotificationConfigComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "column-100 bordered p-5 mt-10"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationConfigComponent_8)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.parent.parent.context.$implicit.users; _ck(_v, 2, 0, currVal_0); }, null); }
function View_NotificationConfigComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 20, "fieldset", [["class", "bordered"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "legend", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 5, "div", [["class", "column-50"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "label"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Role"])), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationConfigComponent_5)), i1.ɵdid(8, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 11, "div", [["class", "column-50"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 8, "div", [["class", "column-100"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "div", [["class", "label"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Jednotliv\u00ED u\u017Eivatel\u00E9"])), (_l()(), i1.ɵeld(13, 0, null, null, 5, "div", [["class", "input"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 2, "span", [["class", "pr-10 pt-10"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onToggleCustomUsers(_v.parent.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 1, "gmt-checkbox", [], null, null, null, i6.View_CheckboxComponent_0, i6.RenderType_CheckboxComponent)), i1.ɵdid(16, 114688, null, 0, i7.CheckboxComponent, [], { gmtChecked: [0, "gmtChecked"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationConfigComponent_6)), i1.ɵdid(18, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationConfigComponent_7)), i1.ɵdid(20, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.roles; _ck(_v, 8, 0, currVal_1); var currVal_2 = _v.parent.parent.context.$implicit.customUsers; _ck(_v, 16, 0, currVal_2); var currVal_3 = _v.parent.parent.context.$implicit.customUsers; _ck(_v, 18, 0, currVal_3); var currVal_4 = (_v.parent.parent.context.$implicit.users.length > 0); _ck(_v, 20, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = _v.parent.context.ngIf.name; _ck(_v, 2, 0, currVal_0); }); }
function View_NotificationConfigComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationConfigComponent_4)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = (_v.parent.context.$implicit.statusType === _v.parent.parent.context.statusType); _ck(_v, 2, 0, currVal_0); }, null); }
function View_NotificationConfigComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationConfigComponent_3)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getStatusByConfig(_v.context.$implicit); _ck(_v, 2, 0, currVal_0); }, null); }
function View_NotificationConfigComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationConfigComponent_2)), i1.ɵdid(1, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.notificationConfigsMapped; _ck(_v, 1, 0, currVal_0); }, null); }
function View_NotificationConfigComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_NotificationConfigComponent_13(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_NotificationConfigComponent_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Notifikace pro zm\u011Bnu stavu GP"])), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_NotificationConfigComponent_13)), i1.ɵdid(4, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i1.ɵpod(5, { statusType: 0 }), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _ck(_v, 5, 0, "geometricPlanStatuses"); var currVal_1 = i1.ɵnov(_v.parent.parent, 2); _ck(_v, 4, 0, currVal_0, currVal_1); }, null); }
function View_NotificationConfigComponent_14(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "mt-30 text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "gmt-button", [], null, null, null, i8.View_ButtonComponent_0, i8.RenderType_ButtonComponent)), i1.ɵdid(2, 114688, null, 0, i9.ButtonComponent, ["ErrorHandlerService", i1.ChangeDetectorRef], { clickAction: [0, "clickAction"] }, null), (_l()(), i1.ɵted(-1, 0, ["Ulo\u017Eit"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.onSave; _ck(_v, 2, 0, currVal_0); }, null); }
function View_NotificationConfigComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Notifikace pro zm\u011Bnu stavu p\u0159\u00EDpadu"])), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_NotificationConfigComponent_11)), i1.ɵdid(4, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i1.ɵpod(5, { statusType: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationConfigComponent_12)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationConfigComponent_14)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 5, 0, "caseStatuses"); var currVal_1 = i1.ɵnov(_v.parent, 2); _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_2 = _co.geometricPlanModuleEnabled; _ck(_v, 7, 0, currVal_2); var currVal_3 = !_co.saveNotificationConfigRegister; _ck(_v, 9, 0, currVal_3); }, null); }
function View_NotificationConfigComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i10.RolePipe, []), i1.ɵpid(0, i11.UserNamePipe, []), (_l()(), i1.ɵand(0, [["notificationConfig", 2]], null, 0, null, View_NotificationConfigComponent_1)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [], [[8, "className", 0]], null, null, null, null)), i1.ɵdid(4, 16384, null, 0, i12.LoadingDirective, [i1.ElementRef], { loading: [0, "loading"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationConfigComponent_10)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.loading; _ck(_v, 4, 0, currVal_1); var currVal_2 = !_co.loading; _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 4).elementClass; _ck(_v, 3, 0, currVal_0); }); }
exports.View_NotificationConfigComponent_0 = View_NotificationConfigComponent_0;
function View_NotificationConfigComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "notification-config", [], null, null, null, View_NotificationConfigComponent_0, RenderType_NotificationConfigComponent)), i1.ɵdid(1, 114688, null, 0, i13.NotificationConfigComponent, ["GlobalRestangularSettingsProvider", i11.UserNamePipe, i14.ListService, i15.AuthService, i16.ModulesService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_NotificationConfigComponent_Host_0 = View_NotificationConfigComponent_Host_0;
var NotificationConfigComponentNgFactory = i1.ɵccf("notification-config", i13.NotificationConfigComponent, View_NotificationConfigComponent_Host_0, { saveNotificationConfigRegister: "saveNotificationConfigRegister" }, {}, []);
exports.NotificationConfigComponentNgFactory = NotificationConfigComponentNgFactory;
