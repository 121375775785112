"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var dialog_config_1 = require("../dialog-config");
var dialog_ref_1 = require("../dialog-ref");
var AlertComponent = /** @class */ (function () {
    function AlertComponent(config, dialog) {
        this.config = config;
        this.dialog = dialog;
        this.onClose = this.onClose.bind(this);
    }
    AlertComponent.prototype.onClose = function () {
        this.dialog.close(true);
    };
    return AlertComponent;
}());
exports.AlertComponent = AlertComponent;
