"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var geometric_plan_requests_states_1 = require("@app/ps/geometric-plans/geometric-plan-requests.states");
var GEOMETRIC_PLAN_REQUEST_STATES = [
    geometric_plan_requests_states_1.geometricPlanRequestCreateState,
    geometric_plan_requests_states_1.geometricPlanRequestDetailState,
    geometric_plan_requests_states_1.geometricPlanRequestListState,
    geometric_plan_requests_states_1.geometricPlanRequestCreateFromExistingState,
];
var GeometricPlanRequestsModule = /** @class */ (function () {
    function GeometricPlanRequestsModule() {
    }
    return GeometricPlanRequestsModule;
}());
exports.GeometricPlanRequestsModule = GeometricPlanRequestsModule;
