"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var username_pipe_1 = require("@app/common/pipes/username.pipe");
var construction_object_name_pipe_1 = require("../../../common/pipes/construction-object-name.pipe");
var series_name_pipe_1 = require("../../pipes/series-name.pipe");
var parent_case_ownership_name_pipe_1 = require("../../pipes/parent-case-ownership-name.pipe");
var auth_service_1 = require("@app/common/services/auth.service");
var list_service_1 = require("@app/common/services/list.service");
var listTransform_1 = require("../../../../common/services/pure/listTransform");
var create_case_select_parent_ownership_service_1 = require("@app/ps/services/create-case-select-parent-ownership.service");
var word_translate_pipe_1 = require("@app/common/pipes/word-translate.pipe");
var title_detail_create_case_data_service_1 = require("@app/ps/services/title-detail-create-case-data.service");
var TitlesCreateCasesComponent = /** @class */ (function () {
    function TitlesCreateCasesComponent(usernamePipe, listService, authService, constructionObjectNamePipe, seriesNamePipe, createCaseSelectParentOwnershipService, parentCaseOwnershipNamePipe, wordTranslatePipe, restangular, titleDetailCreateCaseDataService, checklistService) {
        var _this = this;
        this.usernamePipe = usernamePipe;
        this.listService = listService;
        this.authService = authService;
        this.constructionObjectNamePipe = constructionObjectNamePipe;
        this.seriesNamePipe = seriesNamePipe;
        this.createCaseSelectParentOwnershipService = createCaseSelectParentOwnershipService;
        this.parentCaseOwnershipNamePipe = parentCaseOwnershipNamePipe;
        this.wordTranslatePipe = wordTranslatePipe;
        this.restangular = restangular;
        this.titleDetailCreateCaseDataService = titleDetailCreateCaseDataService;
        this.checklistService = checklistService;
        this.callback = new core_1.EventEmitter();
        this.countResolved = 0;
        this.allowAssignment = false;
        this.interrupt = false;
        this.obligationType = [
            'GratuitousTransferContractLiability',
            'NonGratuitousTransferContractLiability',
            'RightToRealizeABuildingLiability',
            'AgreementOnAOtherFutureContractLiability',
            'AgreementOnAFutureTransferContractLiability',
            'AgreementOnAFutureNetworkConstructionContractLiability',
            'FutureRealBurdenLiabilityAccelerated',
            'AccessToLandLiability',
            'BuildingPlacementLiability',
            'BorrowingLiability',
            'OtherLiability',
            'OwnersStatementLiability',
            'RentalContractShorterThan1YearLiability',
            'RentalContractLongerThan1YearLiability',
            'RentalContractCommonLiability',
        ];
        this.obligationFilter = {
            filters: {
                obligationType: this.obligationType,
            }
        };
        this.inputData = {};
        this.createGroups = [
            { id: 'title', name: 'Po listech vlastnictví' },
            { id: 'constructionObject', name: 'Po stavebních objektech' },
            { id: 'owner', name: 'Po vlastnících' },
        ];
        this.objectTypes = [];
        this.selectParentShown = false;
        this.steps = [
            {
                key: 'selectObjects',
                skip: function () { return false; },
                initData: function () {
                    _this.objectTypes = [];
                    if ((_this.inputData.obligation.objectTypes.easementsGeometricPlan || _this.inputData.obligation.objectTypes.easementsLandTake) && _this.titlesWithEasementsSelected()) {
                        _this.objectTypes.push({
                            key: 'easement',
                            name: 'Věcná břemena',
                        });
                    }
                    if (_this.titlesWithOccupationGroupSelected('underOneYear') && (_this.inputData.obligation.objectTypes.occupations || (_this.inputData.obligation.objectTypes.occupationGroupKeys && _this.inputData.obligation.objectTypes.occupationGroupKeys.includes('underOneYear')))) {
                        _this.objectTypes.push({
                            key: 'underOneYear',
                            name: 'Dočasné zábory do jednoho roku',
                        });
                    }
                    if (_this.titlesWithOccupationGroupSelected('overOneYear') && (_this.inputData.obligation.objectTypes.occupations || (_this.inputData.obligation.objectTypes.occupationGroupKeys && _this.inputData.obligation.objectTypes.occupationGroupKeys.includes('overOneYear')))) {
                        _this.objectTypes.push({
                            key: 'overOneYear',
                            name: 'Dočasné zábory nad jeden rok',
                        });
                    }
                    if (_this.titlesWithOccupationGroupSelected('permanent') && (_this.inputData.obligation.objectTypes.occupations || (_this.inputData.obligation.objectTypes.occupationGroupKeys && _this.inputData.obligation.objectTypes.occupationGroupKeys.includes('permanent')))) {
                        _this.objectTypes.push({
                            key: 'permanent',
                            name: 'Trvalé zábory',
                        });
                    }
                },
                isValid: function () { return _this.inputData.objectTypes.length; }
            },
            {
                key: 'selectConstructionObjects',
                skip: function () { return !_this.inputData.obligation.selectConstructionObjectRequired; },
                initData: function () {
                    _this.constructionObjects = [];
                    _this.groupsByConstructionObject = [];
                    var addConstructionObject = function (constructionObject, titleData) {
                        var data = _this.groupsByConstructionObject.find(function (co) { return co.constructionObject.id === constructionObject.id; });
                        if (!data) {
                            data = {
                                constructionObject: constructionObject,
                                titles: [],
                            };
                            _this.groupsByConstructionObject.push(data);
                            _this.constructionObjects.push(constructionObject);
                        }
                        data.titles.push(titleData);
                    };
                    _this.inputData.objectTypes.forEach(function (objectType) {
                        _this.titles.forEach(function (titleData) {
                            switch (objectType.key) {
                                case 'easement':
                                    titleData.dataEasements.forEach(function (easement) {
                                        easement.constructionObjects.forEach(function (constructionObject) {
                                            addConstructionObject(constructionObject, titleData);
                                        });
                                    });
                                    break;
                                default:
                                    if (titleData.dataOccupationGroups[objectType.key]) {
                                        titleData.dataOccupationGroups[objectType.key].forEach(function (group) {
                                            group.occupations.forEach(function (occupation) {
                                                occupation.constructionObjects.forEach(function (constructionObject) {
                                                    addConstructionObject(constructionObject, titleData);
                                                });
                                            });
                                        });
                                    }
                            }
                        });
                    });
                },
                isValid: function () { return _this.inputData.constructionObjects === null || _this.inputData.constructionObjects.length; }
            },
            {
                key: 'selectTimeline',
                skip: function () { return false; },
                initData: function () { return __awaiter(_this, void 0, void 0, function () {
                    var constructionObjectIds;
                    var _this = this;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                constructionObjectIds = (this.inputData.constructionObjects === null ? this.constructionObjects : this.inputData.constructionObjects)
                                    .map(function (co) { return co.id; });
                                return [4 /*yield*/, this.restangular.all('series').customPOST({ filter: { filters: { constructionObjectId: constructionObjectIds } } }).then(function (seriesData) {
                                        _this.seriesData = _this.restangular.stripRestangular(seriesData);
                                    })];
                            case 1: return [2 /*return*/, _a.sent()];
                        }
                    });
                }); }
            },
            {
                key: 'selectParent',
                skip: function () { return !_this.selectParentShown; },
                initData: function () { return __awaiter(_this, void 0, void 0, function () {
                    var e_1, _a, titles, _b, _c, titleData, ownershipsWithParent, e_1_1;
                    var _this = this;
                    return __generator(this, function (_d) {
                        switch (_d.label) {
                            case 0:
                                titles = this.titles;
                                if (this.inputData.constructionObjects !== null) {
                                    titles = [];
                                    this.inputData.constructionObjects.forEach(function (co) {
                                        var gco = _this.groupsByConstructionObject.find(function (gco) { return gco.constructionObject === co; });
                                        gco.titles.forEach(function (title) {
                                            if (!titles.includes(title)) {
                                                titles.push(title);
                                            }
                                        });
                                    });
                                }
                                this.inputData.titles = [];
                                titles.forEach(function (titleData) {
                                    if ((!_this.inputData.objectTypes.some(function (objectType) { return objectType.key === 'easement'; }) || titleData.dataEasements.length === 0) &&
                                        (!_this.inputData.objectTypes.some(function (objectType) { return objectType.key === 'underOneYear'; }) || !titleData.dataOccupationGroups.underOneYear) &&
                                        (!_this.inputData.objectTypes.some(function (objectType) { return objectType.key === 'overOneYear'; }) || !titleData.dataOccupationGroups.overOneYear) &&
                                        (!_this.inputData.objectTypes.some(function (objectType) { return objectType.key === 'permanent'; }) || !titleData.dataOccupationGroups.permanent)) {
                                        return;
                                    }
                                    _this.inputData.titles.push(titleData);
                                });
                                this.selectParentShown = false;
                                this.titleParentSelections = [];
                                _d.label = 1;
                            case 1:
                                _d.trys.push([1, 6, 7, 8]);
                                _b = __values(this.inputData.titles), _c = _b.next();
                                _d.label = 2;
                            case 2:
                                if (!!_c.done) return [3 /*break*/, 5];
                                titleData = _c.value;
                                titleData.dataOwnerships.forEach(function (dataOwnership) {
                                    delete dataOwnership.isParent;
                                    delete dataOwnership.parentCaseOwnership;
                                });
                                return [4 /*yield*/, this.createCaseSelectParentOwnershipService.checkOwnershipsWithParent(titleData.dataOwnerships)];
                            case 3:
                                ownershipsWithParent = _d.sent();
                                this.titleParentSelections.push({
                                    ownershipsWithParent: ownershipsWithParent,
                                    caseOwnerships: titleData.dataOwnerships,
                                });
                                this.selectParentShown = this.selectParentShown || this.createCaseSelectParentOwnershipService.needsParentSelection(ownershipsWithParent);
                                _d.label = 4;
                            case 4:
                                _c = _b.next();
                                return [3 /*break*/, 2];
                            case 5: return [3 /*break*/, 8];
                            case 6:
                                e_1_1 = _d.sent();
                                e_1 = { error: e_1_1 };
                                return [3 /*break*/, 8];
                            case 7:
                                try {
                                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                                }
                                finally { if (e_1) throw e_1.error; }
                                return [7 /*endfinally*/];
                            case 8: return [2 /*return*/];
                        }
                    });
                }); },
                isValid: function () { return !_this.titleParentSelections.some(function (titleParentSelection) { return _this.createCaseSelectParentOwnershipService.needsParentSelection(titleParentSelection.ownershipsWithParent); }); }
            },
            {
                key: 'summary',
                skip: function () { return false; }
            },
        ];
        this.selectedConstructionObjects = [];
        this.arrangeTitles = this.arrangeTitles.bind(this);
        this.updatedObligation = this.updatedObligation.bind(this);
        this.previous = this.previous.bind(this);
        this.next = this.next.bind(this);
    }
    TitlesCreateCasesComponent.prototype.titlesWithEasementsSelected = function () {
        return this.titles.some(function (titleData) { return !!titleData.dataEasements.length; });
    };
    TitlesCreateCasesComponent.prototype.titlesWithOccupationGroupSelected = function (key) {
        return this.titles.some(function (titleData) { return titleData.dataOccupationGroups[key]; });
    };
    TitlesCreateCasesComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.allowAssignment = this.authService.hasPermission('assignment_edit') && this.authService.hasPermission('assignable');
        this.inputData = this.data.details || {};
        this.notifyParent(function () {
            return _this.inputData.obligation
                && (_this.inputData.obligation.type !== 'FutureRealBurdenLiabilityAccelerated' || _this.inputData.acceleratedPriceType)
                && _this.step === _this.steps[_this.steps.length - 1]
                && (_this.authService.hasPermission('admin') || _this.inputData.createGroups.some(function (group) { return group.id === 'title' || group.id === 'owner'; }));
        }, this.inputData);
        if (!this.inputData.createGroups) {
            this.inputData.createGroups = [];
        }
        else {
            this.updatedObligation();
        }
        this.checklistCreateGroups = new this.checklistService(this.inputData.createGroups);
        this.acceleratedPriceTypes = [
            { id: 'SINGLE', name: 'Jednotná cena za parcelu' },
            { id: 'CONSTRUCTION_OBJECT', name: 'Podle počtu ' + this.wordTranslatePipe.transform('CONSTRUCTION_OBJECT_PLURAL_GENITIV') + ' na případu' },
            { id: 'CONSTRUCTION_OBJECT_PARCEL', name: 'Podle počtu ' + this.wordTranslatePipe.transform('CONSTRUCTION_OBJECT_PLURAL_GENITIV') + ' na jednotlivých parcelách' },
            { id: 'ADMINISTRATOR', name: 'Podle počtu správců ' + this.wordTranslatePipe.transform('CONSTRUCTION_OBJECT_PLURAL_GENITIV') },
        ];
    };
    TitlesCreateCasesComponent.prototype.loadTitles = function () {
        return __awaiter(this, void 0, void 0, function () {
            var filter;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        filter = {
                            limit: null,
                            filters: Object.assign(listTransform_1.transformListToFilter({ filters: this.data.selectEntities.filters }).filters, {
                                entityId: {
                                    values: this.data.selectEntities.checkedItems.map(function (entity) { return entity.id; }),
                                    negation: this.data.selectEntities.inverseSelection,
                                },
                                obligationType: this.inputData.obligation.type,
                                loadCollections: [],
                            })
                        };
                        this.list = this.listService.createList('titles', filter);
                        return [4 /*yield*/, this.listService.fetchResult(this.list)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    TitlesCreateCasesComponent.prototype.ngOnDestroy = function () {
        this.interrupt = true;
    };
    TitlesCreateCasesComponent.prototype.updatedObligation = function () {
        return __awaiter(this, void 0, void 0, function () {
            var titles;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.inputData.objectTypes = [];
                        this.checklistObjectTypes = new this.checklistService(this.inputData.objectTypes);
                        this.inputData.constructionObjects = null;
                        this.inputData.series = null;
                        this.step = this.steps[0];
                        this.countResolved = 0;
                        return [4 /*yield*/, this.loadTitles()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.arrangeTitles()];
                    case 2:
                        titles = _a.sent();
                        if (titles) {
                            this.titles = titles;
                            this.step.initData();
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    TitlesCreateCasesComponent.prototype.arrangeTitles = function () {
        return __awaiter(this, void 0, void 0, function () {
            var e_2, _a, titlesData, list, list_1, list_1_1, item, titleDataPromise, data, e_2_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        titlesData = [];
                        list = this.list.list;
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 6, 7, 8]);
                        list_1 = __values(list), list_1_1 = list_1.next();
                        _b.label = 2;
                    case 2:
                        if (!!list_1_1.done) return [3 /*break*/, 5];
                        item = list_1_1.value;
                        if (this.interrupt) {
                            return [2 /*return*/, false];
                        }
                        titleDataPromise = this.titleDetailCreateCaseDataService.loadTitleData(item.id);
                        return [4 /*yield*/, this.titleDetailCreateCaseDataService.loadGeneralData(item.id, titleDataPromise)];
                    case 3:
                        data = _b.sent();
                        if (list !== this.list.list) {
                            return [2 /*return*/, false];
                        }
                        this.countResolved++;
                        titlesData.push(data);
                        _b.label = 4;
                    case 4:
                        list_1_1 = list_1.next();
                        return [3 /*break*/, 2];
                    case 5: return [3 /*break*/, 8];
                    case 6:
                        e_2_1 = _b.sent();
                        e_2 = { error: e_2_1 };
                        return [3 /*break*/, 8];
                    case 7:
                        try {
                            if (list_1_1 && !list_1_1.done && (_a = list_1.return)) _a.call(list_1);
                        }
                        finally { if (e_2) throw e_2.error; }
                        return [7 /*endfinally*/];
                    case 8: return [2 /*return*/, titlesData];
                }
            });
        });
    };
    TitlesCreateCasesComponent.prototype.previous = function () {
        var e_3, _a;
        var use = false;
        var tmpStep;
        try {
            for (var _b = __values(this.steps), _c = _b.next(); !_c.done; _c = _b.next()) {
                var step = _c.value;
                if (step.key === this.step.key) {
                    use = true;
                    break;
                }
                if (!step.skip || !step.skip()) {
                    tmpStep = step;
                }
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_3) throw e_3.error; }
        }
        if (use) {
            this.step = tmpStep;
        }
    };
    TitlesCreateCasesComponent.prototype.next = function () {
        return __awaiter(this, void 0, void 0, function () {
            var e_4, _a, use, _b, _c, step, e_4_1;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        use = false;
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 8, 9, 10]);
                        _b = __values(this.steps), _c = _b.next();
                        _d.label = 2;
                    case 2:
                        if (!!_c.done) return [3 /*break*/, 7];
                        step = _c.value;
                        if (!use) return [3 /*break*/, 5];
                        if (!step.initData) return [3 /*break*/, 4];
                        return [4 /*yield*/, step.initData()];
                    case 3:
                        _d.sent();
                        _d.label = 4;
                    case 4:
                        if (step.skip && step.skip()) {
                            return [3 /*break*/, 6];
                        }
                        this.step = step;
                        return [2 /*return*/, false];
                    case 5:
                        if (step.key === this.step.key) {
                            use = true;
                        }
                        _d.label = 6;
                    case 6:
                        _c = _b.next();
                        return [3 /*break*/, 2];
                    case 7: return [3 /*break*/, 10];
                    case 8:
                        e_4_1 = _d.sent();
                        e_4 = { error: e_4_1 };
                        return [3 /*break*/, 10];
                    case 9:
                        try {
                            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                        }
                        finally { if (e_4) throw e_4.error; }
                        return [7 /*endfinally*/];
                    case 10: return [2 /*return*/];
                }
            });
        });
    };
    TitlesCreateCasesComponent.prototype.toggleObjectType = function (objectType) {
        this.checklistObjectTypes.toggleSelection(objectType);
    };
    TitlesCreateCasesComponent.prototype.isCheckedObjectType = function (objectType) {
        return this.checklistObjectTypes.isChecked(objectType);
    };
    TitlesCreateCasesComponent.prototype.toggleCreateGroup = function (createGroup) {
        this.checklistCreateGroups.toggleSelection(createGroup);
    };
    TitlesCreateCasesComponent.prototype.isCheckeCreateGroup = function (createGroup) {
        return this.checklistCreateGroups.isChecked(createGroup);
    };
    TitlesCreateCasesComponent.prototype.checkAllConstructionObjects = function () {
        this.selectedConstructionObjects.splice(0, this.selectedConstructionObjects.length);
        this.inputData.constructionObjects = null;
    };
    TitlesCreateCasesComponent.prototype.checkSelectedConstructionObjects = function () {
        this.inputData.constructionObjects = this.selectedConstructionObjects;
    };
    TitlesCreateCasesComponent.prototype.changedSelectedConstructionObjects = function () {
        this.inputData.constructionObjects = this.selectedConstructionObjects;
    };
    TitlesCreateCasesComponent.prototype.toggleAcceleratedPriceType = function (acceleratedPriceType) {
        this.inputData.acceleratedPriceType = acceleratedPriceType.id;
    };
    TitlesCreateCasesComponent.prototype.isCheckedAcceleratedPriceType = function (acceleratedPriceType) {
        return this.inputData.acceleratedPriceType === acceleratedPriceType.id;
    };
    TitlesCreateCasesComponent.prototype.changedParent = function (data, ownershipWithParentExt, caseOwnerships, ownershipsWithParent) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.createCaseSelectParentOwnershipService.changedParent(data.newValue, data.oldValue, ownershipWithParentExt, caseOwnerships, ownershipsWithParent)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    return TitlesCreateCasesComponent;
}());
exports.TitlesCreateCasesComponent = TitlesCreateCasesComponent;
