"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var parcel_number_pipe_1 = require("./parcel-number.pipe");
var i0 = require("@angular/core");
var i1 = require("./parcel-number.pipe");
var ParcelNamePipe = /** @class */ (function () {
    function ParcelNamePipe(parcelNumberPipe) {
        this.parcelNumberPipe = parcelNumberPipe;
        this.transform = this.transform.bind(this);
    }
    ParcelNamePipe.prototype.transform = function (parcel) {
        var name = '-';
        var parcelNumber = this.parcelNumberPipe.transform(parcel);
        if (parcelNumber) {
            name = parcelNumber;
        }
        if ((parcel.title && parcel.title.lv) || (parcel.area && parcel.area.name) || parcel.katuzeNazev) {
            var detail = [];
            if (parcel.title && parcel.title.lv) {
                detail.push('LV' + parcel.title.lv);
            }
            if ((parcel.area && parcel.area.name) || parcel.katuzeNazev) {
                detail.push('KÚ ' + (parcel.katuzeNazev || parcel.area.name));
            }
            name += ' (' + detail.join(' - ') + ')';
        }
        return name;
    };
    ParcelNamePipe.ngInjectableDef = i0.defineInjectable({ factory: function ParcelNamePipe_Factory() { return new ParcelNamePipe(i0.inject(i1.ParcelNumberPipe)); }, token: ParcelNamePipe, providedIn: "root" });
    return ParcelNamePipe;
}());
exports.ParcelNamePipe = ParcelNamePipe;
