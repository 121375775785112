"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var document_type_model_1 = require("../../common/models/document-type.model");
var auth_service_1 = require("./../../common/services/auth.service");
var opinion_request_status_enum_1 = require("@app/ps/enums/opinion-request-status.enum");
var i0 = require("@angular/core");
var i1 = require("../../common/services/auth.service");
var OpinionRequestService = /** @class */ (function () {
    function OpinionRequestService(restangular, uploadService, authService) {
        this.restangular = restangular;
        this.uploadService = uploadService;
        this.authService = authService;
    }
    OpinionRequestService.prototype.create = function (opinionRequest) {
        return this.restangular
            .all('opinion-requests/create')
            .customPOST(opinionRequest, '', { loadCollections: ['parcels', 'buildings'] });
    };
    OpinionRequestService.prototype.createByOpinion = function (opinion) {
        return this.restangular
            .all('opinion-requests/create-by-opinion')
            .post(opinion);
    };
    OpinionRequestService.prototype.update = function (opinionRequest, files) {
        var _this = this;
        if (files === void 0) { files = []; }
        var updatedOpinionRequest;
        return this.restangular
            .one('opinion-requests', opinionRequest.id)
            .customPUT(opinionRequest, '', { loadCollections: ['parcels', 'buildings'] })
            .then(function (response) {
            updatedOpinionRequest = response.plain();
            return _this.createDocuments(updatedOpinionRequest, files);
        })
            .then(function () { return updatedOpinionRequest; });
    };
    OpinionRequestService.prototype.createDocuments = function (opinionRequest, files) {
        var _this = this;
        var restBaseUrl = this.authService.getActiveApplicationRestUrl();
        var attachmentType = document_type_model_1.DocumentTypeModel.createById('OPINION_SCAN').id;
        var promises = files.map(function (file) {
            var sendData = {
                file: file.fileEntry,
                filename: file.fileEntry.name,
                opinionRequestId: opinionRequest.id,
                attachmentType: attachmentType
            };
            return _this.uploadService.upload({
                url: restBaseUrl + '/attachments',
                data: sendData,
                headers: __assign({}, _this.restangular.defaultHeaders)
            });
        });
        return Promise.all(promises);
    };
    OpinionRequestService.prototype.getStatusEnumLabel = function (value) {
        return opinion_request_status_enum_1.opinionRequestStatusOptions.find(function (option) { return option.id === value; }).name;
    };
    OpinionRequestService.prototype.getStatusEnumAction = function (value) {
        var option = opinion_request_status_enum_1.opinionRequestStatusOptions.find(function (option) { return option.id === value; });
        return (option.action ? option.action : option.name);
    };
    OpinionRequestService.prototype.isStatusDialog = function (status) {
        return opinion_request_status_enum_1.opinionRequestStatusOptions.find(function (option) { return option.id === status; }).dialog;
    };
    OpinionRequestService.ngInjectableDef = i0.defineInjectable({ factory: function OpinionRequestService_Factory() { return new OpinionRequestService(i0.inject("Restangular"), i0.inject("Upload"), i0.inject(i1.AuthService)); }, token: OpinionRequestService, providedIn: "root" });
    return OpinionRequestService;
}());
exports.OpinionRequestService = OpinionRequestService;
