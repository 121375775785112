"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var i0 = require("@angular/core");
var DescriptionPipe = /** @class */ (function () {
    function DescriptionPipe() {
    }
    DescriptionPipe.prototype.transform = function (item) {
        return item.description;
    };
    DescriptionPipe.ngInjectableDef = i0.defineInjectable({ factory: function DescriptionPipe_Factory() { return new DescriptionPipe(); }, token: DescriptionPipe, providedIn: "root" });
    return DescriptionPipe;
}());
exports.DescriptionPipe = DescriptionPipe;
