"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var LandingPkComponent = /** @class */ (function () {
    function LandingPkComponent() {
        this.tabs = [
            {
                name: 'Podmínky použití',
                id: 'terms-of-use',
                href: 'settings.modules.pk',
                urlParams: { tab: 'terms-of-use' },
            },
            {
                name: 'Žádost o data KN',
                id: 'cadastre-data-request',
                href: 'settings.modules.pk',
                urlParams: { tab: 'cadastre-data-request' },
            },
            {
                name: 'Import dat KN',
                id: 'cadastre-data-import',
                href: 'settings.modules.pk',
                urlParams: { tab: 'cadastre-data-import' },
            },
            {
                name: 'Hlášení o šíření dat KN',
                id: 'cadastre-data-report',
                href: 'settings.modules.pk',
                urlParams: { tab: 'cadastre-data-report' },
            },
        ];
        this.isActiveTab = this.isActiveTab.bind(this);
    }
    LandingPkComponent.prototype.ngOnInit = function () {
        this.isActiveTab = this.isActiveTab.bind(this);
    };
    LandingPkComponent.prototype.isActiveTab = function (tabId) {
        return tabId === this.activeTab;
    };
    return LandingPkComponent;
}());
exports.LandingPkComponent = LandingPkComponent;
