"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var angular_1 = require("@uirouter/angular");
var user_edit_component_1 = require("@app/common/components/user-edit/user-edit.component");
var dialog_service_1 = require("@app/dialog/dialog.service");
var UserDetailComponent = /** @class */ (function () {
    function UserDetailComponent(helpIds, APP_BRAND, applications, modulesService, settingsRestangular, confirmService, stateService, dialogService) {
        this.helpIds = helpIds;
        this.APP_BRAND = APP_BRAND;
        this.applications = applications;
        this.modulesService = modulesService;
        this.settingsRestangular = settingsRestangular;
        this.confirmService = confirmService;
        this.stateService = stateService;
        this.dialogService = dialogService;
        this.loading = true;
        this.vfzeLoading = true;
        this.pkLoading = true;
        this.availableModules = [this.applications.sy.name, this.applications.di.name, this.applications.pk.name, this.applications.dashboard.name, this.applications.vfze.name];
        this.disabledTabs = [];
        this.syProjects = [];
        this.diProjects = [];
        this.tabs = [
            {
                name: 'Moduly',
                id: 'modules',
                href: 'settings.users.detail',
                urlParams: { tab: 'modules' },
            },
            {
                name: this.applications.sy.title + " (0)",
                id: this.applications.sy.name,
                href: 'settings.users.detail',
                urlParams: { tab: this.applications.sy.name }
            },
            {
                name: this.applications.dashboard.title,
                id: this.applications.dashboard.name,
                href: 'settings.users.detail',
                urlParams: { tab: this.applications.dashboard.name }
            },
        ];
        this.modules = [
            {
                id: 'SY',
                name: this.applications.sy.title,
            },
            {
                id: 'DASH',
                name: this.applications.dashboard.title,
            },
        ];
        this.tab = (this.stateService.params.tab ? this.stateService.params.tab : 'modules');
        this.isActiveTab = this.isActiveTab.bind(this);
        this.uiOnParamsChanged = this.uiOnParamsChanged.bind(this);
        this.onEdit = this.onEdit.bind(this);
        this.onDeactivate = this.onDeactivate.bind(this);
        this.onUserSync = this.onUserSync.bind(this);
        this.onModuleClick = this.onModuleClick.bind(this);
        this.moduleAvailable = this.moduleAvailable.bind(this);
        this.loadUserData = this.loadUserData.bind(this);
    }
    UserDetailComponent.prototype.ngOnInit = function () {
        if (this.APP_BRAND.NAME !== 'RSD') {
            this.tabs = this.tabs.concat([
                {
                    name: this.applications.di.title + " (0)",
                    id: this.applications.di.name,
                    href: 'settings.users.detail',
                    urlParams: { tab: this.applications.di.name }
                },
                {
                    name: this.applications.pk.title,
                    id: this.applications.pk.name,
                    href: 'settings.users.detail',
                    urlParams: { tab: this.applications.pk.name }
                },
                {
                    name: this.applications.vfze.title,
                    id: this.applications.vfze.name,
                    href: 'settings.users.detail',
                    urlParams: { tab: this.applications.vfze.name }
                },
            ]);
            this.modules = this.modules.concat([
                {
                    id: 'DI',
                    name: this.applications.di.title,
                },
                {
                    id: 'PRO',
                    name: this.applications.pk.title,
                },
                {
                    id: 'VFZE',
                    name: this.applications.vfze.title,
                },
            ]);
        }
        this.loadUserData();
        this.loadVfzeProjects();
        this.loadPkProjects();
        this.callbacks.add('userPermissionsChangedCallback', this.loadUserData);
    };
    UserDetailComponent.prototype.ngOnDestroy = function () {
        this.callbacks.remove('userPermissionsChangedCallback', this.loadUserData);
    };
    UserDetailComponent.prototype.isActiveTab = function (id) {
        return id === this.tab;
    };
    UserDetailComponent.prototype.uiOnParamsChanged = function (changedParams) {
        if (changedParams.tab) {
            this.tab = changedParams.tab;
        }
    };
    UserDetailComponent.prototype.onEdit = function () {
        var _this = this;
        var dialog = this.dialogService.open(user_edit_component_1.UserEditComponent, {
            data: { userId: this.userId }
        });
        var sub = dialog.afterClosed.subscribe(function (res) {
            if (res) {
                Object.assign(_this.data, res);
            }
            sub.unsubscribe();
        });
    };
    UserDetailComponent.prototype.onDeactivate = function () {
        var _this = this;
        new this.confirmService({
            title: 'potvrzení deaktivace uživatele',
            message: 'Potvrzením dojde k odebrání veškerých práv uživatele na moduly a přidružené projekty.'
        }).then(function (data) {
            if (data === true) {
                _this.modulesService.getUser(_this.userId).then(function (user) {
                    user.applications = [];
                    _this.modulesService.emptyUserPermissions(_this.userId);
                    _this.modulesService.saveUser(user)
                        .then(function () {
                        return Promise.all([ /* callbacks.get('userPermissionsChangedCallback')(), callbacks.get('userPermissionsDeactivateCallback')() */]);
                    }).then(function () {
                        _this.stateService.go('^', undefined, { location: 'replace', reload: true });
                    });
                });
            }
        });
    };
    UserDetailComponent.prototype.onUserSync = function () {
        return this.settingsRestangular.one("users/user/" + this.data.id + "/broadcast-user-update").post();
    };
    UserDetailComponent.prototype.onModuleClick = function (module) {
        var newModules = __spread(this.data.applications);
        var oldModules = __spread(this.data.applications);
        var moduleIndex = this.data.applications.indexOf(module.id);
        if (moduleIndex === -1) {
            newModules.push(module.id);
        }
        else {
            newModules.splice(moduleIndex, 1);
        }
        this.onModulesUpdated(newModules, oldModules);
    };
    UserDetailComponent.prototype.moduleAvailable = function (module) {
        return this.data.applications.indexOf(module.id) !== -1;
    };
    UserDetailComponent.prototype.onModulesUpdated = function (newModules, oldModules) {
        var _this = this;
        var saveChanges = function () {
            _this.setModules(newModules);
            return _this.saveChanges().then(function () {
                _this.modulesService.loadUser(_this.userId).then(function () {
                    _this.loadUserData();
                });
            });
        };
        // if removing authorisation for module
        if (oldModules.length > newModules.length) {
            var changedModule_1 = this.getChangedModule(newModules, oldModules);
            // if removing authorisation for module SY or DI
            if (changedModule_1 === this.applications.sy.name || changedModule_1 === this.applications.di.name) {
                var module_1 = Object.entries(this.applications).find(function (e) { return e[1].name === changedModule_1; })[1].title;
                return new this.confirmService({
                    title: 'Odstranit práva na modul?',
                    message: "Opravdu chcete odebrat u\u017Eivateli pr\u00E1va na modul " + module_1 + "? Pr\u00E1va ve st\u00E1vaj\u00EDc\u00EDch projektech budou odstran\u011Bna."
                }).then(function (data) {
                    if (data === true) {
                        // clear cached user data
                        _this.modulesService.emptyUserPermissions(_this.userId);
                        saveChanges();
                    }
                    else {
                        // reject changes
                        _this.setModules(oldModules);
                        return Promise.reject();
                    }
                });
            }
            else {
                saveChanges();
            }
        }
        else {
            saveChanges();
        }
    };
    UserDetailComponent.prototype.loadUserData = function () {
        var _this = this;
        this.tab = (this.stateService.params.tab ? this.stateService.params.tab : 'modules');
        return this.modulesService.loadUser(this.userId)
            .then(function () {
            return _this.modulesService.getUser(_this.userId);
        })
            .then(function (data) {
            _this.data = data;
            _this.loading = false;
            _this.syProjects = data.applicationProjects.SY ? data.applicationProjects.SY : [];
            _this.diProjects = data.applicationProjects.DI ? data.applicationProjects.DI : [];
            _this.setTabsProjectsCounts();
            _this.setModules(_this.data.applications);
        });
    };
    UserDetailComponent.prototype.getChangedModule = function (newModules, oldModules) {
        return oldModules.find(function (m) { return !newModules.includes(m); });
    };
    UserDetailComponent.prototype.setModules = function (modules) {
        this.setDisabledModules(modules);
        this.data.applications = modules;
    };
    UserDetailComponent.prototype.setDisabledModules = function (availableModules) {
        this.disabledTabs = this.availableModules.filter(function (m) { return !availableModules.includes(m); });
    };
    UserDetailComponent.prototype.saveChanges = function () {
        var _this = this;
        this.loading = true;
        return this.data.put().then(function () {
            _this.loading = false;
        });
    };
    UserDetailComponent.prototype.loadVfzeProjects = function () {
        var _this = this;
        this.modulesService.getProjects(this.applications.vfze.name).then(function (data) {
            _this.vfzeProject = data.items[0] || undefined;
            _this.vfzeLoading = false;
        });
    };
    UserDetailComponent.prototype.loadPkProjects = function () {
        var _this = this;
        this.modulesService.getProjects(this.applications.pk.name).then(function (data) {
            _this.pkProject = data.items[0] || undefined;
            _this.pkLoading = false;
        });
    };
    UserDetailComponent.prototype.setTabsProjectsCounts = function () {
        var _this = this;
        var symapTab = this.tabs.find(function (t) { return t.id === _this.applications.sy.name; });
        var dimapTab = this.tabs.find(function (t) { return t.id === _this.applications.di.name; });
        var bracketRegexp = new RegExp('\\(.+?\\)');
        symapTab.name = symapTab.name.replace(bracketRegexp, "(" + this.syProjects.length + ")");
        if (dimapTab) {
            dimapTab.name = dimapTab.name.replace(bracketRegexp, "(" + this.diProjects.length + ")");
        }
    };
    return UserDetailComponent;
}());
exports.UserDetailComponent = UserDetailComponent;
