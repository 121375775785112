"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var angular_1 = require("@uirouter/angular");
var WsdpCredentialsComponent = /** @class */ (function () {
    function WsdpCredentialsComponent(restangular, helpIds, stateService) {
        this.restangular = restangular;
        this.helpIds = helpIds;
        this.stateService = stateService;
        this.update = this.update.bind(this);
        this.isUpdateDisabled = this.isUpdateDisabled.bind(this);
    }
    WsdpCredentialsComponent.prototype.ngOnInit = function () {
        this.backSref = this.stateService.params.backSref;
    };
    WsdpCredentialsComponent.prototype.update = function () {
        return __awaiter(this, void 0, void 0, function () {
            var valid;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.validateWsdpAccount(this.cuzkWebserviceName, this.cuzkWebservicePassword)];
                    case 1:
                        valid = _a.sent();
                        if (!valid) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.updateWsdpAccount(this.cuzkWebserviceName, this.cuzkWebservicePassword)];
                    case 2:
                        _a.sent();
                        if (this.backSref) {
                            this.stateService.go(this.stateService.params.backSref);
                        }
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    WsdpCredentialsComponent.prototype.isUpdateDisabled = function () {
        return !!this.cuzkWebserviceName && !!this.cuzkWebservicePassword;
    };
    WsdpCredentialsComponent.prototype.updateWsdpAccount = function (name, password) {
        return this.restangular.one('cuzk/save-credentials').customPUT({
            userName: name,
            password: password
        });
    };
    WsdpCredentialsComponent.prototype.validateWsdpAccount = function (name, password) {
        var _this = this;
        this.cuzkAccountValidationMessage = '';
        return this.restangular.one('cuzk/verify-credentials').customPOST({
            userName: name,
            password: password
        }).then(function (data) {
            var valid = data.valid;
            _this.cuzkAccountValidationMessage = (valid ? '' : 'Zadali jste neplatné přihlašovací údaje k WSDP účtu.');
            return valid;
        });
    };
    return WsdpCredentialsComponent;
}());
exports.WsdpCredentialsComponent = WsdpCredentialsComponent;
