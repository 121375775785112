"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var ButtonComponent = /** @class */ (function () {
    function ButtonComponent(errorHandlerService, cdr) {
        this.errorHandlerService = errorHandlerService;
        this.cdr = cdr;
        this.callback = new core_1.EventEmitter();
        this.processing = false;
    }
    ButtonComponent.prototype.ngOnInit = function () {
        this.callback.emit({ doAction: this.doAction });
    };
    ButtonComponent.prototype.isDisabled = function () {
        return this.gmtDisabled || this.processing;
    };
    /**
     * Runs underlying action and checks its state.
     *
     * @param {object} event
     * @returns {void}
     */
    ButtonComponent.prototype.doAction = function (event) {
        var _this = this;
        if (typeof this.clickAction !== 'function') {
            return;
        }
        event.stopPropagation();
        if (this.isDisabled()) {
            return;
        }
        var actionPromise = this.clickAction();
        // is promise?
        if (actionPromise && !!actionPromise.finally && typeof actionPromise.finally === 'function') {
            this.processing = true;
            actionPromise.finally(function () {
                _this.processing = false;
                _this.cdr.markForCheck();
            }).catch(new this.errorHandlerService());
        }
    };
    return ButtonComponent;
}());
exports.ButtonComponent = ButtonComponent;
