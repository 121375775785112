"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var L = require("leaflet");
var _ = require("lodash");
var map_service_1 = require("@app/map/services/map.service");
var map_utils_crs_service_1 = require("@app/map/services/map-utils-crs.service");
var map_storage_service_1 = require("@app/map/services/map-storage.service");
var map_options_storage_service_1 = require("@app/map/services/map-options-storage.service");
var map_layers_storage_service_1 = require("@app/map/services/map-layers-storage.service");
var map_full_screen_service_1 = require("@app/map/services/map-full-screen.service");
var map_layer_service_1 = require("@app/map/services/map-layer.service");
var i0 = require("@angular/core");
var i1 = require("./map.service");
var i2 = require("./map-utils-crs.service");
var i3 = require("./map-storage.service");
var i4 = require("./map-options-storage.service");
var i5 = require("./map-layers-storage.service");
var i6 = require("./map-layer.service");
var i7 = require("./map-full-screen.service");
var LeafletMapService = /** @class */ (function () {
    function LeafletMapService(mapService, mapUtilsCrsService, mapStorageService, mapOptionsStorageService, mapLayersStorageService, mapLayerService, mapFullScreenService) {
        this.mapService = mapService;
        this.mapUtilsCrsService = mapUtilsCrsService;
        this.mapStorageService = mapStorageService;
        this.mapOptionsStorageService = mapOptionsStorageService;
        this.mapLayersStorageService = mapLayersStorageService;
        this.mapLayerService = mapLayerService;
        this.mapFullScreenService = mapFullScreenService;
        this.addSingleClickFeature();
    }
    LeafletMapService.prototype.setOptionsCrs = function (options) {
        if (options['srid']) {
            var crs = this.mapUtilsCrsService.getCrs(options['srid']);
            if (crs === undefined) {
                throw new Error('CRS with srid ' + options['srid'] + ' not defined.');
            }
            options['crs'] = crs;
        }
    };
    LeafletMapService.prototype.setFullscreen = function (map, opts) {
        if (opts.fullscreen) {
            map.fullscreenControl = this.mapFullScreenService.getFullscreenControl({});
        }
    };
    LeafletMapService.prototype.setBounds = function (mapId, opts) {
        if (opts.bounds && (opts.bounds instanceof L.LatLngBounds)) {
            this.mapService.fitBounds(mapId, opts.bounds);
        }
    };
    LeafletMapService.prototype.setInitMapPos = function (map) {
        map.options.initCenter = _.cloneDeep(map.getCenter());
        map.options.initZoom = _.cloneDeep(map.getZoom());
    };
    LeafletMapService.prototype.storeMap = function (map, mapId) {
        this.mapStorageService.setMap(map, mapId);
    };
    LeafletMapService.prototype.resetMap = function (mapId) {
        this.mapStorageService.resetMap(mapId);
    };
    LeafletMapService.prototype.storeOptions = function (opts, mapId) {
        this.mapOptionsStorageService.setOptions(opts, mapId);
    };
    LeafletMapService.prototype.resetOptions = function (mapId) {
        this.mapOptionsStorageService.resetOptions(mapId);
    };
    LeafletMapService.prototype.resetLayers = function (mapId) {
        this.mapLayersStorageService.resetLayers(mapId);
    };
    LeafletMapService.prototype.initLayers = function (mapId, config) {
        this.mapLayersStorageService.setLayers({}, mapId);
        this.mapLayerService.initLayersForMap(mapId, config);
    };
    LeafletMapService.prototype.enableLoadingWatch = function (mapId) {
        this.mapService.enableLoadingWatch(mapId);
    };
    LeafletMapService.prototype.addSingleClickFeature = function () {
        L.Evented.addInitHook(function () {
            this._singleClickTimeout = null;
            this.on('click', this._scheduleSingleClick, this);
            this.on('dblclick dragstart zoomstart', this._cancelSingleClick, this);
        });
        L.Evented.include({
            _cancelSingleClick: function () {
                // This timeout is key to workaround an issue where double-click events
                // are fired in this order on some touch browsers: ['click', 'dblclick', 'click']
                // instead of ['click', 'click', 'dblclick']
                setTimeout(this._clearSingleClickTimeout.bind(this), 0);
            },
            _scheduleSingleClick: function (e) {
                this._clearSingleClickTimeout();
                this._singleClickTimeout = setTimeout(this._fireSingleClick.bind(this, e), (this.options.singleClickTimeout || 250));
            },
            _fireSingleClick: function (e) {
                if (!e.originalEvent._stopped) {
                    this.fire('singleclick', L.Util.extend(e, { type: 'singleclick' }));
                }
            },
            _clearSingleClickTimeout: function () {
                if (this._singleClickTimeout) {
                    clearTimeout(this._singleClickTimeout);
                    this._singleClickTimeout = null;
                }
            }
        });
    };
    LeafletMapService.ngInjectableDef = i0.defineInjectable({ factory: function LeafletMapService_Factory() { return new LeafletMapService(i0.inject(i1.MapService), i0.inject(i2.MapUtilsCrsService), i0.inject(i3.MapStorageService), i0.inject(i4.MapOptionsStorageService), i0.inject(i5.MapLayersStorageService), i0.inject(i6.MapLayerService), i0.inject(i7.MapFullScreenService)); }, token: LeafletMapService, providedIn: "root" });
    return LeafletMapService;
}());
exports.LeafletMapService = LeafletMapService;
