"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var list_service_1 = require("@app/common/services/list.service");
var word_translate_pipe_1 = require("@app/common/pipes/word-translate.pipe");
var settings_service_1 = require("@app/ps/services/settings.service");
var CreateCaseSelectObligationComponent = /** @class */ (function () {
    function CreateCaseSelectObligationComponent(listService, wordTranslatePipe, settingsService, restangular, ConfirmService) {
        this.listService = listService;
        this.wordTranslatePipe = wordTranslatePipe;
        this.settingsService = settingsService;
        this.restangular = restangular;
        this.ConfirmService = ConfirmService;
        this.submitCallback = new core_1.EventEmitter();
        this.loading = true;
        this.submit = this.submit.bind(this);
    }
    CreateCaseSelectObligationComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.settingsService.getSettingsLoader()];
                    case 1:
                        _a.sent();
                        this.generalDataPromise.then(function (dataLoaded) {
                            _this.obligations = dataLoaded.dataObligationsFiltered;
                            _this.loading = false;
                        });
                        this.acceleratedPriceTypes = [
                            { id: 'SINGLE', name: 'Jednotná cena za parcelu' },
                            { id: 'CONSTRUCTION_OBJECT', name: 'Podle počtu ' + this.wordTranslatePipe.transform('CONSTRUCTION_OBJECT_PLURAL_GENITIV') + ' na případu' },
                            { id: 'CONSTRUCTION_OBJECT_PARCEL', name: 'Podle počtu ' + this.wordTranslatePipe.transform('CONSTRUCTION_OBJECT_PLURAL_GENITIV') + ' na jednotlivých parcelách' },
                            { id: 'ADMINISTRATOR', name: 'Podle počtu správců ' + this.wordTranslatePipe.transform('CONSTRUCTION_OBJECT_PLURAL_GENITIV') },
                        ];
                        return [2 /*return*/];
                }
            });
        });
    };
    CreateCaseSelectObligationComponent.prototype.toggleAcceleratedPriceType = function (acceleratedPriceType) {
        this.data.acceleratedPriceType = acceleratedPriceType.id;
    };
    CreateCaseSelectObligationComponent.prototype.isCheckedAcceleratedPriceType = function (acceleratedPriceType) {
        return this.data.acceleratedPriceType === acceleratedPriceType.id;
    };
    CreateCaseSelectObligationComponent.prototype.submit = function () {
        return this.submitCallback.emit();
    };
    CreateCaseSelectObligationComponent.prototype.onSelectObligation = function (obligation) {
        this.data.obligation = obligation;
        this.data.acceleratedPriceType = undefined;
    };
    return CreateCaseSelectObligationComponent;
}());
exports.CreateCaseSelectObligationComponent = CreateCaseSelectObligationComponent;
