"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var FormCaseSignedSendOwnersComponent = /** @class */ (function () {
    function FormCaseSignedSendOwnersComponent() {
        this.callbackRegister = new core_1.EventEmitter();
        this.isValid = this.isValid.bind(this);
    }
    FormCaseSignedSendOwnersComponent.prototype.ngOnInit = function () {
        this.data.signedSentOwnersDate = this.data.signedSentOwnersDate || new Date();
        this.callbackRegister.emit({
            isValid: this.isValid,
        });
    };
    FormCaseSignedSendOwnersComponent.prototype.isValid = function () {
        return !!this.data.signedSentOwnersDate;
    };
    return FormCaseSignedSendOwnersComponent;
}());
exports.FormCaseSignedSendOwnersComponent = FormCaseSignedSendOwnersComponent;
