"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./obligation-flow-settings.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../../../../common/components/button/button.component.ngfactory");
var i3 = require("../../../../common/components/button/button.component");
var i4 = require("@angular/common");
var i5 = require("../../../../common/components/select/select.component.ngfactory");
var i6 = require("../../../../common/components/select/select.component");
var i7 = require("@uirouter/angular");
var i8 = require("../../../../common/components/checkbox/checkbox.component.ngfactory");
var i9 = require("../../../../common/components/checkbox/checkbox.component");
var i10 = require("../../../../common/directives/loading.directive");
var i11 = require("./obligation-flow-settings.component");
var i12 = require("../../../../dialog/dialog.service");
var i13 = require("../../../../dialog/dialog-config");
var i14 = require("../../../../dialog/dialog-ref");
var styles_ObligationFlowSettingsComponent = [i0.styles];
var RenderType_ObligationFlowSettingsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ObligationFlowSettingsComponent, data: {} });
exports.RenderType_ObligationFlowSettingsComponent = RenderType_ObligationFlowSettingsComponent;
function View_ObligationFlowSettingsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "jumbotron mt-20 mb-20"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Tento typ m\u00E1 ji\u017E zalo\u017Een\u00E9 p\u0159\u00EDpady. Zm\u011Bna po\u0159ad\u00ED stav\u016F ve workflow nebo odebr\u00E1n\u00ED stav\u016F m\u016F\u017Ee zp\u016Fsobit zablokov\u00E1n\u00ED zm\u011Bnu stavu n\u011Bkter\u00FDch p\u0159\u00EDpad\u016F. "])), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "mt-10"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "gmt-button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.editable = true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ButtonComponent_0, i2.RenderType_ButtonComponent)), i1.ɵdid(4, 114688, null, 0, i3.ButtonComponent, ["ErrorHandlerService", i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵted(-1, 0, ["Povolit editaci"]))], function (_ck, _v) { _ck(_v, 4, 0); }, null); }
function View_ObligationFlowSettingsComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeFlowStatus(_v.parent.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fa fa-remove"]], null, null, null, null, null))], null, null); }
function View_ObligationFlowSettingsComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeEnterStatus(_v.parent.context.$implicit, _v.parent.parent.parent.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fa fa-remove"]], null, null, null, null, null))], null, null); }
function View_ObligationFlowSettingsComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToStatusAnchor(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵted(-1, null, ["\u00A0 "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ObligationFlowSettingsComponent_8)), i1.ɵdid(5, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.editable; _ck(_v, 5, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.flow[_v.context.$implicit].name; _ck(_v, 2, 0, currVal_0); }); }
function View_ObligationFlowSettingsComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "gmt-select", [["field", "selected"], ["required", "true"], ["selectTitle", "p\u0159idat stav"]], null, [[null, "changed"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("changed" === en)) {
        var pd_0 = (_co.selectedEnterStatus(_v.parent.parent.parent.context.$implicit, $event.newValue, _co.data) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_SelectComponent_0, i5.RenderType_SelectComponent)), i1.ɵdid(1, 114688, null, 0, i6.SelectComponent, ["ListLoadService", "WindowDimensionService", i1.ChangeDetectorRef, i1.ElementRef, "Restangular", i7.StateService], { data: [0, "data"], field: [1, "field"], filter: [2, "filter"], optionItems: [3, "optionItems"], reloadOptionsRegister: [4, "reloadOptionsRegister"], required: [5, "required"], selectTitle: [6, "selectTitle"] }, { changed: "changed" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data; var currVal_1 = "selected"; var currVal_2 = _v.parent.parent.context.ngIf.enterFilter; var currVal_3 = _co.caseStatusOptions; var currVal_4 = _co.reloadOptionsRegisterEnter(_v.parent.parent.context.ngIf); var currVal_5 = "true"; var currVal_6 = "p\u0159idat stav"; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
function View_ObligationFlowSettingsComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "enter-statuses"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fa fa-sign-in"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ObligationFlowSettingsComponent_7)), i1.ɵdid(3, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ObligationFlowSettingsComponent_9)), i1.ɵdid(5, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.parent.context.ngIf.flowStatus.enterStatuses; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.editable; _ck(_v, 5, 0, currVal_1); }, null); }
function View_ObligationFlowSettingsComponent_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeLeaveStatus(_v.parent.context.$implicit, _v.parent.parent.parent.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fa fa-remove"]], null, null, null, null, null))], null, null); }
function View_ObligationFlowSettingsComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToStatusAnchor(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵted(-1, null, ["\u00A0 "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ObligationFlowSettingsComponent_12)), i1.ɵdid(5, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.editable; _ck(_v, 5, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.flow[_v.context.$implicit].name; _ck(_v, 2, 0, currVal_0); }); }
function View_ObligationFlowSettingsComponent_13(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "gmt-select", [["field", "selected"], ["required", "true"], ["selectTitle", "p\u0159idat stav"]], null, [[null, "changed"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("changed" === en)) {
        var pd_0 = (_co.selectedLeaveStatus(_v.parent.parent.parent.context.$implicit, $event.newValue, _co.data) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_SelectComponent_0, i5.RenderType_SelectComponent)), i1.ɵdid(1, 114688, null, 0, i6.SelectComponent, ["ListLoadService", "WindowDimensionService", i1.ChangeDetectorRef, i1.ElementRef, "Restangular", i7.StateService], { data: [0, "data"], field: [1, "field"], filter: [2, "filter"], optionItems: [3, "optionItems"], reloadOptionsRegister: [4, "reloadOptionsRegister"], required: [5, "required"], selectTitle: [6, "selectTitle"] }, { changed: "changed" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data; var currVal_1 = "selected"; var currVal_2 = _v.parent.parent.context.ngIf.leaveFilter; var currVal_3 = _co.caseStatusOptions; var currVal_4 = _co.reloadOptionsRegisterLeave(_v.parent.parent.context.ngIf); var currVal_5 = "true"; var currVal_6 = "p\u0159idat stav"; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
function View_ObligationFlowSettingsComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "leave-statuses"], ["id", "prcat"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fa fa-sign-out"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ObligationFlowSettingsComponent_11)), i1.ɵdid(3, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ObligationFlowSettingsComponent_13)), i1.ɵdid(5, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.parent.context.ngIf.leaveStatuses; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.editable; _ck(_v, 5, 0, currVal_1); }, null); }
function View_ObligationFlowSettingsComponent_15(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeDocument(_v.parent.context.$implicit, _v.parent.parent.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "fa fa-remove"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00A0 "])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.editDocument($event, _v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "i", [["class", "fa fa-pencil"]], null, null, null, null, null))], null, null); }
function View_ObligationFlowSettingsComponent_14(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ObligationFlowSettingsComponent_15)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.documentTemplateNames[_v.context.$implicit.key]; _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.documentTemplateNames[_v.context.$implicit.key]; _ck(_v, 1, 0, currVal_0); }); }
function View_ObligationFlowSettingsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 23, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "flow-status"]], [[8, "id", 0]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "a", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "i", [["class", "fa fa-arrow-up"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.up(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00A0 "])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "a", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "i", [["class", "fa fa-arrow-down"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.down(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(7, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ObligationFlowSettingsComponent_5)), i1.ɵdid(9, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ObligationFlowSettingsComponent_6)), i1.ɵdid(11, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ObligationFlowSettingsComponent_10)), i1.ɵdid(13, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(14, 0, null, null, 5, "div", [["class", "documents"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 0, "i", [["class", "fa fa-file"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ObligationFlowSettingsComponent_14)), i1.ɵdid(17, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(18, 0, null, null, 1, "gmt-select", [["field", "selected"], ["required", "true"], ["selectTitle", "p\u0159idat dokument"]], null, [[null, "changed"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("changed" === en)) {
        var pd_0 = (_co.selectedDocument(_v.parent.context.$implicit, $event.newValue, _co.data) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_SelectComponent_0, i5.RenderType_SelectComponent)), i1.ɵdid(19, 114688, null, 0, i6.SelectComponent, ["ListLoadService", "WindowDimensionService", i1.ChangeDetectorRef, i1.ElementRef, "Restangular", i7.StateService], { data: [0, "data"], field: [1, "field"], filter: [2, "filter"], optionItems: [3, "optionItems"], reloadOptionsRegister: [4, "reloadOptionsRegister"], required: [5, "required"], selectTitle: [6, "selectTitle"] }, { changed: "changed" }), (_l()(), i1.ɵeld(20, 0, null, null, 3, "div", [["class", "documents"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleAllowCadastreEntryProposal(_v.context.ngIf) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 1, "gmt-checkbox", [], null, null, null, i8.View_CheckboxComponent_0, i8.RenderType_CheckboxComponent)), i1.ɵdid(22, 114688, null, 0, i9.CheckboxComponent, [], { gmtChecked: [0, "gmtChecked"] }, null), (_l()(), i1.ɵted(-1, null, [" Zobrazit \u00FAlohu \"Generovat n\u00E1vrh na vklad do KN\" "]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = ((_v.parent.context.$implicit !== "Created") && _co.editable); _ck(_v, 9, 0, currVal_2); var currVal_3 = (_co.editable || _v.context.ngIf.flowStatus.enterStatuses.length); _ck(_v, 11, 0, currVal_3); var currVal_4 = (_co.editable || _v.context.ngIf.leaveStatuses.length); _ck(_v, 13, 0, currVal_4); var currVal_5 = _v.context.ngIf.flowStatus.documents; _ck(_v, 17, 0, currVal_5); var currVal_6 = _co.data; var currVal_7 = "selected"; var currVal_8 = _v.context.ngIf.documentFilter; var currVal_9 = _co.documentTemplatesOptions; var currVal_10 = _co.reloadOptionsRegisterDocument(_v.context.ngIf); var currVal_11 = "true"; var currVal_12 = "p\u0159idat dokument"; _ck(_v, 19, 0, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12); var currVal_13 = !!_v.context.ngIf.flowStatus.allowCadastreEntryProposal; _ck(_v, 22, 0, currVal_13); }, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "statusAnchor", _v.context.ngIf.key, ""); _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.context.ngIf.name; _ck(_v, 7, 0, currVal_1); }); }
function View_ObligationFlowSettingsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "bordered mt-20 hover"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ObligationFlowSettingsComponent_4)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.flowData[_v.context.$implicit]; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ObligationFlowSettingsComponent_17(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "gmt-button", [["class", "red"]], null, null, null, i2.View_ButtonComponent_0, i2.RenderType_ButtonComponent)), i1.ɵdid(1, 114688, null, 0, i3.ButtonComponent, ["ErrorHandlerService", i1.ChangeDetectorRef], { clickAction: [0, "clickAction"] }, null), (_l()(), i1.ɵted(-1, 0, ["Obnovit v\u00FDchoz\u00ED"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.reset; _ck(_v, 1, 0, currVal_0); }, null); }
function View_ObligationFlowSettingsComponent_16(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "text-center actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "gmt-button", [], null, null, null, i2.View_ButtonComponent_0, i2.RenderType_ButtonComponent)), i1.ɵdid(2, 114688, null, 0, i3.ButtonComponent, ["ErrorHandlerService", i1.ChangeDetectorRef], { clickAction: [0, "clickAction"] }, null), (_l()(), i1.ɵted(-1, 0, ["Ulo\u017Eit"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ObligationFlowSettingsComponent_17)), i1.ɵdid(5, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.update; _ck(_v, 2, 0, currVal_0); var currVal_1 = (!_co.data.readOnly || _co.editable); _ck(_v, 5, 0, currVal_1); }, null); }
function View_ObligationFlowSettingsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ObligationFlowSettingsComponent_2)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "workflow"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ObligationFlowSettingsComponent_3)), i1.ɵdid(5, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ObligationFlowSettingsComponent_16)), i1.ɵdid(7, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.data.readOnly && !_co.editable); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.flowDataSort; _ck(_v, 5, 0, currVal_1); var currVal_2 = (_co.data.flow !== null); _ck(_v, 7, 0, currVal_2); }, null); }
function View_ObligationFlowSettingsComponent_18(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "empty-info"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Typ p\u0159\u00EDpadu nem\u00E1 definovan\u00E9 variabiln\u00ED workflow "]))], null, null); }
function View_ObligationFlowSettingsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Workflow typu p\u0159\u00EDpadu"])), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [], [[8, "className", 0]], null, null, null, null)), i1.ɵdid(3, 16384, null, 0, i10.LoadingDirective, [i1.ElementRef], { loading: [0, "loading"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ObligationFlowSettingsComponent_1)), i1.ɵdid(5, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ObligationFlowSettingsComponent_18)), i1.ɵdid(7, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.loading; _ck(_v, 3, 0, currVal_1); var currVal_2 = (!_co.loading && (_co.data.flow !== null)); _ck(_v, 5, 0, currVal_2); var currVal_3 = (!_co.loading && (((_co.data == null) ? null : _co.data.flow) === null)); _ck(_v, 7, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).elementClass; _ck(_v, 2, 0, currVal_0); }); }
exports.View_ObligationFlowSettingsComponent_0 = View_ObligationFlowSettingsComponent_0;
function View_ObligationFlowSettingsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "obligation-flow-settings", [], null, null, null, View_ObligationFlowSettingsComponent_0, RenderType_ObligationFlowSettingsComponent)), i1.ɵdid(1, 2211840, null, 0, i11.ObligationFlowSettingsComponent, ["HELP_IDS", "Restangular", "ConfirmService", i1.ElementRef, i12.DialogService, i13.DialogConfig, i14.DialogRef, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_ObligationFlowSettingsComponent_Host_0 = View_ObligationFlowSettingsComponent_Host_0;
var ObligationFlowSettingsComponentNgFactory = i1.ɵccf("obligation-flow-settings", i11.ObligationFlowSettingsComponent, View_ObligationFlowSettingsComponent_Host_0, {}, {}, []);
exports.ObligationFlowSettingsComponentNgFactory = ObligationFlowSettingsComponentNgFactory;
