"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var road_name_pipe_1 = require("./road-name.pipe");
var i0 = require("@angular/core");
var i1 = require("./road-name.pipe");
var RoadSectionNamePipe = /** @class */ (function () {
    function RoadSectionNamePipe(roadNamePipe) {
        this.roadNamePipe = roadNamePipe;
        this.transform = this.transform.bind(this);
    }
    RoadSectionNamePipe.prototype.transform = function (roadSection) {
        var roadName = this.roadNamePipe.transform(roadSection);
        return roadName ? roadName + ', Úsek č. ' + roadSection.poradiUs : null;
    };
    RoadSectionNamePipe.ngInjectableDef = i0.defineInjectable({ factory: function RoadSectionNamePipe_Factory() { return new RoadSectionNamePipe(i0.inject(i1.RoadNamePipe)); }, token: RoadSectionNamePipe, providedIn: "root" });
    return RoadSectionNamePipe;
}());
exports.RoadSectionNamePipe = RoadSectionNamePipe;
