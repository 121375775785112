"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var dialog_config_1 = require("@app/dialog/dialog-config");
var dialog_ref_1 = require("@app/dialog/dialog-ref");
var opinion_request_status_enum_1 = require("@app/ps/enums/opinion-request-status.enum");
var opinion_request_service_1 = require("@app/ps/services/opinion-request.service");
var auth_service_1 = require("@app/common/services/auth.service");
var OpinionRequestEditComponent = /** @class */ (function () {
    function OpinionRequestEditComponent(requestService, authService, dialogComponent, dialogConfig) {
        this.requestService = requestService;
        this.authService = authService;
        this.dialogComponent = dialogComponent;
        this.dialogConfig = dialogConfig;
        this.statusEnum = opinion_request_status_enum_1.OpinionRequestStatusEnum;
        this.filesMsg = OpinionRequestEditComponent.MSG_FILES_DEFAULT;
        this.filesValid = false;
        this.onSave = this.onSave.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onExpertSelected = this.onExpertSelected.bind(this);
    }
    OpinionRequestEditComponent.prototype.ngOnInit = function () {
        var data = this.dialogConfig.data;
        this.newStatus = data.newStatus;
        this.editMode = data.editMode;
        this.opinionRequest = data.opinionRequest;
    };
    OpinionRequestEditComponent.prototype.isValid = function () {
        var _this = this;
        if (this.editMode === 'edit') {
            return true;
        }
        var statusConfig = this.getStatusConfig();
        if (!statusConfig) {
            return false;
        }
        var fieldsValidity = statusConfig.fields
            .filter(function (n) { return n !== 'realizationPrice' && n !== 'attachments'; })
            .map(function (n) { return !!_this.opinionRequest[n]; })
            .every(function (b) { return b; });
        return (fieldsValidity && (this.newStatus !== opinion_request_status_enum_1.OpinionRequestStatusEnum.REALIZED || this.filesValid));
    };
    OpinionRequestEditComponent.prototype.isFieldVisible = function (field) {
        if (this.editMode === 'edit') {
            if (field.indexOf('Date') > 0 && this.opinionRequest[field] === undefined) {
                return false;
            }
            else if (['name', 'realizedDate'].includes(field) && opinion_request_status_enum_1.OpinionRequestStatusEnum.PRICES_FILLED === this.opinionRequest.status) {
                return false;
            }
            else {
                return true;
            }
        }
        var statusConfig = this.getStatusConfig();
        if (!statusConfig) {
            return false;
        }
        return statusConfig.fields.includes(field);
    };
    OpinionRequestEditComponent.prototype.onExpertSelected = function (expert) {
        if (this.opinionRequest) {
            this.opinionRequest.expert = expert;
        }
    };
    OpinionRequestEditComponent.prototype.onFileChange = function (files) {
        this.files = files;
        var extensionValid = files.every(function (f) { return f.extension && f.extension.toLowerCase() === 'pdf'; });
        if (this.files.length === 0) {
            this.filesMsg = OpinionRequestEditComponent.MSG_FILES_DEFAULT;
        }
        else if (!extensionValid) {
            this.filesMsg = OpinionRequestEditComponent.MSG_FILES_EXTENSION;
        }
        else if (this.files.length > 1) {
            this.filesMsg = OpinionRequestEditComponent.MSG_FILES_QUANTITY;
        }
        else {
            this.filesMsg = '';
        }
        this.filesValid = (extensionValid && this.files.length === 1);
    };
    OpinionRequestEditComponent.prototype.onSave = function () {
        if (!this.isValid()) {
            return;
        }
        if (this.newStatus) {
            this.opinionRequest.status = this.newStatus;
        }
        this.dialogComponent.close({ opinionRequest: this.opinionRequest, files: this.files });
    };
    OpinionRequestEditComponent.prototype.onCancel = function () {
        this.dialogComponent.close(false);
    };
    OpinionRequestEditComponent.prototype.getStatusConfig = function () {
        var _this = this;
        return OpinionRequestEditComponent.STATUSES_CONFIG.find(function (sc) { return sc.status === _this.newStatus; });
    };
    OpinionRequestEditComponent.MSG_FILES_DEFAULT = 'Vložte soubor přetažením nebo výběrem. Je možné vložit jeden soubor PDF.';
    OpinionRequestEditComponent.MSG_FILES_EXTENSION = 'Soubor musí být ve formátu PDF.';
    OpinionRequestEditComponent.MSG_FILES_QUANTITY = 'Je možné vložit pouze jeden soubor.';
    OpinionRequestEditComponent.STATUSES_CONFIG = [
        {
            status: opinion_request_status_enum_1.OpinionRequestStatusEnum.REALIZATION_STARTED,
            fields: [
                'realizationStartedDate',
                'expert',
                'realizationFinishExpectedDate',
            ]
        },
        {
            status: opinion_request_status_enum_1.OpinionRequestStatusEnum.REALIZED,
            fields: [
                'realizedDate',
                'realizationPrice',
                'attachments',
            ]
        },
        {
            status: opinion_request_status_enum_1.OpinionRequestStatusEnum.DELETED,
            fields: [
                'deletedDate',
            ]
        },
    ];
    return OpinionRequestEditComponent;
}());
exports.OpinionRequestEditComponent = OpinionRequestEditComponent;
