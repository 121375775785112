"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var FormCaseEntryStoppedComponent = /** @class */ (function () {
    function FormCaseEntryStoppedComponent() {
        this.callbackRegister = new core_1.EventEmitter();
        this.isValid = this.isValid.bind(this);
    }
    FormCaseEntryStoppedComponent.prototype.ngOnInit = function () {
        this.data.cadastreEntryStoppedDate = this.data.cadastreEntryStoppedDate || new Date();
        this.callbackRegister.emit({
            isValid: this.isValid,
        });
    };
    FormCaseEntryStoppedComponent.prototype.isValid = function () {
        return !!this.data.cadastreEntryStoppedDate;
    };
    return FormCaseEntryStoppedComponent;
}());
exports.FormCaseEntryStoppedComponent = FormCaseEntryStoppedComponent;
