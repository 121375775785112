"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var i0 = require("@angular/core");
var RoadNamePipe = /** @class */ (function () {
    function RoadNamePipe() {
    }
    RoadNamePipe.prototype.transform = function (roadSection) {
        return roadSection.kodTrKom ? (roadSection.kodTrKom === '1' ? '' : 'I' + (roadSection.kodTrKom === '2' ? '' : 'I' + (roadSection.kodTrKom === '3' ? '' : 'I')) + '/') + roadSection.indsil7 : null;
    };
    RoadNamePipe.ngInjectableDef = i0.defineInjectable({ factory: function RoadNamePipe_Factory() { return new RoadNamePipe(); }, token: RoadNamePipe, providedIn: "root" });
    return RoadNamePipe;
}());
exports.RoadNamePipe = RoadNamePipe;
