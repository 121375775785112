"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("../../../../common/components/checkbox/checkbox.component.ngfactory");
var i2 = require("../../../../common/components/checkbox/checkbox.component");
var i3 = require("@angular/common");
var i4 = require("../create-case-select-objects-items/create-case-select-objects-items.component.ngfactory");
var i5 = require("../create-case-select-objects-items/create-case-select-objects-items.component");
var i6 = require("../../../../common/services/list.service");
var i7 = require("../../../../common/services/checklist.service");
var i8 = require("../../../../common/pipes/subject-name.pipe");
var i9 = require("../../../services/parcel.service");
var i10 = require("../../../../common/pipes/parcel-number.pipe");
var i11 = require("../../../pipes/project-subject-name.pipe");
var i12 = require("../../../../common/pipes/construction-object-name.pipe");
var i13 = require("../../../services/case.service");
var i14 = require("../../../services/title-detail-create-case-selection.service");
var i15 = require("../../../services/title-detail-create-case-data.service");
var i16 = require("../../../../common/components/button/button.component.ngfactory");
var i17 = require("../../../../common/components/button/button.component");
var i18 = require("../../../../common/directives/loading.directive");
var i19 = require("./create-case-select-titles.component");
var i20 = require("../../../../common/services/auth.service");
var i21 = require("../../../services/title-detail-create-case-select-titles.service");
var styles_CreateCaseSelectTitlesComponent = [];
var RenderType_CreateCaseSelectTitlesComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CreateCaseSelectTitlesComponent, data: {} });
exports.RenderType_CreateCaseSelectTitlesComponent = RenderType_CreateCaseSelectTitlesComponent;
function View_CreateCaseSelectTitlesComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["V\u00FDb\u011Br dal\u0161\u00EDch LV subjektu ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getSubjectName(); _ck(_v, 1, 0, currVal_0); }); }
function View_CreateCaseSelectTitlesComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["V\u00FDb\u011Br dal\u0161\u00EDch parcel LV ", " (k.\u00FA. ", ")"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.displayTitleOwnership.ownership.title.lv; var currVal_1 = _co.displayTitleOwnership.ownership.title.area.name; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_CreateCaseSelectTitlesComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "column-100"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "info"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "gmt-checkbox", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleSelectionOwnershipTitle(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_CheckboxComponent_0, i1.RenderType_CheckboxComponent)), i0.ɵdid(3, 114688, null, 0, i2.CheckboxComponent, [], { gmtChecked: [0, "gmtChecked"], gmtDisabled: [1, "gmtDisabled"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 2, "div", [["class", "info ml-5"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleSelectionOwnershipTitle(_v.context.$implicit, false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(6, null, ["LV ", " (k.\u00FA. ", ") - pod\u00EDl ", "/", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.checklistOwnershipTitle.isChecked(_v.context.$implicit); var currVal_1 = !_co.selection.isOwnershipEnabled(_v.context.$implicit); _ck(_v, 3, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.ownership.title.lv; var currVal_3 = _v.context.$implicit.ownership.title.area.name; var currVal_4 = _v.context.$implicit.ownership.podilCitatel; var currVal_5 = _v.context.$implicit.ownership.podilJmenovatel; _ck(_v, 6, 0, currVal_2, currVal_3, currVal_4, currVal_5); }); }
function View_CreateCaseSelectTitlesComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "mt-10 clearfix"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CreateCaseSelectTitlesComponent_5)), i0.ɵdid(2, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dataTitles; _ck(_v, 2, 0, currVal_0); }, null); }
function View_CreateCaseSelectTitlesComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "title-detail-create-case-select-objects-items", [], null, [[null, "callbackRegister"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("callbackRegister" === en)) {
        var pd_0 = (_co.displayTitleOwnership.callbackRegister($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_CreateCaseSelectObjectsItemsComponent_0, i4.RenderType_CreateCaseSelectObjectsItemsComponent)), i0.ɵdid(1, 114688, null, 0, i5.CreateCaseSelectObjectsItemsComponent, [i6.ListService, i7.ChecklistService, i8.SubjectNamePipe, i9.ParcelService, i10.ParcelNumberPipe, i11.ProjectSubjectNamePipe, i12.ConstructionObjectNamePipe, i13.CaseService, i14.TitleDetailCreateCaseSelectionService, i15.TitleDetailCreateCaseDataService, "Restangular", "ConfirmService"], { data: [0, "data"], checklistGroup: [1, "checklistGroup"], checklistEasement: [2, "checklistEasement"], checklistConstructionObject: [3, "checklistConstructionObject"], checklistBuilding: [4, "checklistBuilding"], checklistOwnership: [5, "checklistOwnership"], titleId: [6, "titleId"] }, { callbackRegister: "callbackRegister" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data; var currVal_1 = _co.checklistGroup; var currVal_2 = _co.checklistEasement; var currVal_3 = _co.checklistConstructionObject; var currVal_4 = _co.checklistBuilding; var currVal_5 = _co.checklistOwnership; var currVal_6 = _co.displayTitleOwnership.ownership.title.id; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
function View_CreateCaseSelectTitlesComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "mt-20"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "gmt-button", [], null, null, null, i16.View_ButtonComponent_0, i16.RenderType_ButtonComponent)), i0.ɵdid(2, 114688, null, 0, i17.ButtonComponent, ["ErrorHandlerService", i0.ChangeDetectorRef], { clickAction: [0, "clickAction"] }, null), (_l()(), i0.ɵted(-1, 0, ["Dal\u0161\u00ED"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.submit; _ck(_v, 2, 0, currVal_0); }, null); }
function View_CreateCaseSelectTitlesComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "mt-20"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "gmt-button", [["class", "black mr-10"]], null, null, null, i16.View_ButtonComponent_0, i16.RenderType_ButtonComponent)), i0.ɵdid(2, 114688, null, 0, i17.ButtonComponent, ["ErrorHandlerService", i0.ChangeDetectorRef], { clickAction: [0, "clickAction"] }, null), (_l()(), i0.ɵted(-1, 0, ["Zru\u0161it v\u00FDb\u011Br LV"])), (_l()(), i0.ɵeld(4, 0, null, null, 2, "gmt-button", [], null, null, null, i16.View_ButtonComponent_0, i16.RenderType_ButtonComponent)), i0.ɵdid(5, 114688, null, 0, i17.ButtonComponent, ["ErrorHandlerService", i0.ChangeDetectorRef], { clickAction: [0, "clickAction"], gmtDisabled: [1, "gmtDisabled"] }, null), (_l()(), i0.ɵted(-1, 0, ["Potvrdit v\u00FDb\u011Br"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.closeSelectOwnershipTitleCancel; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.closeSelectOwnershipTitle; var currVal_2 = (!_co.displayTitleOwnership.isSelectionValidLocal || !_co.displayTitleOwnership.isSelectionValidLocal()); _ck(_v, 5, 0, currVal_1, currVal_2); }, null); }
function View_CreateCaseSelectTitlesComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 12, "div", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CreateCaseSelectTitlesComponent_2)), i0.ɵdid(2, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CreateCaseSelectTitlesComponent_3)), i0.ɵdid(4, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CreateCaseSelectTitlesComponent_4)), i0.ɵdid(6, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CreateCaseSelectTitlesComponent_6)), i0.ɵdid(8, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CreateCaseSelectTitlesComponent_7)), i0.ɵdid(10, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CreateCaseSelectTitlesComponent_8)), i0.ɵdid(12, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.displayTitleOwnership; _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.displayTitleOwnership && !_co.data.fromCase); _ck(_v, 4, 0, currVal_1); var currVal_2 = !_co.displayTitleOwnership; _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.displayTitleOwnership; _ck(_v, 8, 0, currVal_3); var currVal_4 = !_co.displayTitleOwnership; _ck(_v, 10, 0, currVal_4); var currVal_5 = (_co.displayTitleOwnership && !_co.data.fromCase); _ck(_v, 12, 0, currVal_5); }, null); }
function View_CreateCaseSelectTitlesComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [], [[8, "className", 0]], null, null, null, null)), i0.ɵdid(1, 16384, null, 0, i18.LoadingDirective, [i0.ElementRef], { loading: [0, "loading"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CreateCaseSelectTitlesComponent_1)), i0.ɵdid(3, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = !_co.dataTitles; _ck(_v, 1, 0, currVal_1); var currVal_2 = _co.dataTitles; _ck(_v, 3, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).elementClass; _ck(_v, 0, 0, currVal_0); }); }
exports.View_CreateCaseSelectTitlesComponent_0 = View_CreateCaseSelectTitlesComponent_0;
function View_CreateCaseSelectTitlesComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "title-detail-create-case-select-titles", [], null, null, null, View_CreateCaseSelectTitlesComponent_0, RenderType_CreateCaseSelectTitlesComponent)), i0.ɵdid(1, 114688, null, 0, i19.CreateCaseSelectTitlesComponent, [i20.AuthService, i7.ChecklistService, i8.SubjectNamePipe, i9.ParcelService, "ConfirmService", i21.TitleDetailCreateCaseSelectTitlesService, i14.TitleDetailCreateCaseSelectionService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_CreateCaseSelectTitlesComponent_Host_0 = View_CreateCaseSelectTitlesComponent_Host_0;
var CreateCaseSelectTitlesComponentNgFactory = i0.ɵccf("title-detail-create-case-select-titles", i19.CreateCaseSelectTitlesComponent, View_CreateCaseSelectTitlesComponent_Host_0, { data: "data", forwardStep: "forwardStep" }, { submitCallback: "submitCallback" }, []);
exports.CreateCaseSelectTitlesComponentNgFactory = CreateCaseSelectTitlesComponentNgFactory;
