"use strict";
var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var project_status_service_1 = require("@app/ps/services/project-status.service");
var construction_object_name_pipe_1 = require("@app/common/pipes/construction-object-name.pipe");
var username_pipe_1 = require("@app/common/pipes/username.pipe");
var export_list_service_1 = require("@app/ps/services/export-list.service");
var ExportFormComponent = /** @class */ (function () {
    function ExportFormComponent(restangular, exportListService, projectStatusService, constructionObjectNamePipe, userNamePipe) {
        this.restangular = restangular;
        this.exportListService = exportListService;
        this.projectStatusService = projectStatusService;
        this.constructionObjectNamePipe = constructionObjectNamePipe;
        this.userNamePipe = userNamePipe;
        this.displayChanged = new core_1.EventEmitter();
        this.loading = false;
        this.showNotification = false;
        this.onDisplayChange = this.onDisplayChange.bind(this);
        this.onCreateExport = this.onCreateExport.bind(this);
    }
    ExportFormComponent.prototype.ngOnInit = function () {
    };
    ExportFormComponent.prototype.onCreateExport = function () {
        var _this = this;
        var setting = ExportFormComponent.getSelectedSettings(this.exportCfg) || {};
        this.loading = true;
        var promise;
        if (this.exportCfg.key === 'STATISTICS' && setting.exportSettings.sections[0].selection !== 'statistics') {
            var tableId = setting.exportSettings.sections[0].selection;
            promise = this.exportListService.exportDefaultList(tableId);
        }
        else {
            promise = this.restangular.one('exports', this.exportCfg.key).customPOST(setting)
                .then(function (data) {
                _this.projectStatusService.refresh(data);
            });
        }
        promise
            .then(function () {
            _this.showNotification = true;
            setTimeout(function () {
                _this.showNotification = false;
            }, 5000);
            return setTimeout(function () {
                _this.loading = false;
            }, 3000);
        });
    };
    ExportFormComponent.prototype.onDisplayChange = function () {
        this.displayChanged.emit();
    };
    ExportFormComponent.prototype.getPipe = function (pipeName) {
        switch (pipeName) {
            case 'constructionObjectName':
                return this.constructionObjectNamePipe;
            case 'userName':
                return this.userNamePipe;
            default:
                return {
                    transform: function (value) {
                        return (value[pipeName] ? value[pipeName] : '');
                    }
                };
        }
    };
    ExportFormComponent.prototype.isVisible = function (param) {
        // special validation for statistics export
        if (this.exportCfg.key === 'STATISTICS' && (param.key === 'statisticsIds' || param.key === 'choices')) {
            var sectionsParam = this.exportCfg.userParams.find(function (p) { return p.key === 'sections'; });
            return sectionsParam.checklist.isChecked({ id: 'statistics' });
        }
        return true;
    };
    /**
     * Checks if required params for export type are set.
     * Only single selection combos are checked right now,
     * as no other fields are considered required.
     *
     * For future cases: requiredParams length should be equal
     * to the sum of distinct types of filled required params
     * to consider the export settings valid.
     *
     * @returns {boolean}
     */
    ExportFormComponent.prototype.isValid = function () {
        // special validation for statistics export
        if (this.exportCfg.key === 'STATISTICS') {
            var sectionsParam = this.exportCfg.userParams.find(function (p) { return p.key === 'sections'; });
            var choicesParam = this.exportCfg.userParams.find(function (p) { return p.key === 'choices'; });
            var statisticsIdsParam = this.exportCfg.userParams.find(function (p) { return p.key === 'statisticsIds'; });
            if (sectionsParam.checklist.isChecked({ id: 'statistics' })) {
                return (choicesParam.checklist.checkedItems.length && statisticsIdsParam.selectedObject && statisticsIdsParam.selectedObject.selected);
            }
            return sectionsParam.checklist.checkedItems.length;
        }
        var requiredParams = this.exportCfg.userParams && this.exportCfg.userParams.filter(function (p) { return p.required; });
        return !requiredParams || requiredParams
            .every(function (p) {
            return (p.type === 'combo' && !p.comboOptions.allowMultipleSelection && p.selectedObject && p.selectedObject.selected) ||
                (p.checklist.checkedItems.length);
        });
    };
    ExportFormComponent.getSelectedSettings = function (exportCfg) {
        var e_1, _a;
        // if export does not have any options, then return null
        if (!exportCfg.userParams || exportCfg.userParams.length === 0) {
            return undefined;
        }
        var setting = {};
        try {
            for (var _b = __values(exportCfg.userParams), _c = _b.next(); !_c.done; _c = _b.next()) {
                var param = _c.value;
                setting[param.key] = ExportFormComponent.createSettingValues(param);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return { exportSettings: setting };
    };
    ExportFormComponent.createSettingValues = function (param) {
        if (param.type === 'combo') {
            if (!param.selectedObject || !param.selectedObject.selected) {
                return [];
            }
            // only one item allowed in combo
            if (!(param.selectedObject.selected instanceof Array)) {
                return [{ objectId: param.selectedObject.selected.id }];
                // multiple selection in combo
            }
            else {
                return param.selectedObject.selected.map(function (item) { return ({ objectId: item.id }); });
            }
        }
        return param.selected.map(function (item) { return ({ selection: item.id }); });
    };
    return ExportFormComponent;
}());
exports.ExportFormComponent = ExportFormComponent;
