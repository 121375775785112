"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var project_name_pipe_1 = require("@app/common/pipes/project-name.pipe");
var ProjectSelectComponent = /** @class */ (function () {
    function ProjectSelectComponent(projectNamePipe) {
        this.projectNamePipe = projectNamePipe;
        this.selected = new core_1.EventEmitter();
    }
    ProjectSelectComponent.prototype.ngOnInit = function () {
    };
    ProjectSelectComponent.prototype.onProjectSelect = function (selectEvent) {
        this.selected.emit(selectEvent.newValue);
    };
    return ProjectSelectComponent;
}());
exports.ProjectSelectComponent = ProjectSelectComponent;
