"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("../layer-symbol/layer-symbol.component.ngfactory");
var i2 = require("../layer-symbol/layer-symbol.component");
var i3 = require("@angular/common");
var i4 = require("../../../common/components/checkbox/checkbox.component.ngfactory");
var i5 = require("../../../common/components/checkbox/checkbox.component");
var i6 = require("./map-layer-selector.component");
var i7 = require("../../services/map-layer-selector.service");
var i8 = require("../../services/map-layer.service");
var i9 = require("@uirouter/core");
var styles_MapLayerSelectorComponent = [];
var RenderType_MapLayerSelectorComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MapLayerSelectorComponent, data: {} });
exports.RenderType_MapLayerSelectorComponent = RenderType_MapLayerSelectorComponent;
function View_MapLayerSelectorComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["class", "icon"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.parent.parent.parent.parent.context.$implicit.icon; _ck(_v, 1, 0, currVal_0); }); }
function View_MapLayerSelectorComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [["class", "icon"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "layer-symbol", [], null, null, null, i1.View_LayerSymbolComponent_0, i1.RenderType_LayerSymbolComponent)), i0.ɵdid(2, 114688, null, 0, i2.LayerSymbolComponent, [], { interactive: [0, "interactive"], style: [1, "style"] }, null)], function (_ck, _v) { var currVal_0 = false; var currVal_1 = _v.parent.parent.parent.parent.context.$implicit.style; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_MapLayerSelectorComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "span", [["class", "selector"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MapLayerSelectorComponent_5)), i0.ɵdid(2, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MapLayerSelectorComponent_6)), i0.ɵdid(4, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(5, 0, null, null, 2, "span", [["class", "fa"]], null, null, null, null, null)), i0.ɵdid(6, 278528, null, 0, i3.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(7, { "fa-plus": 0 })], function (_ck, _v) { var currVal_0 = (_v.parent.parent.parent.context.$implicit.icon && _v.parent.parent.parent.context.$implicit.visible); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_v.parent.parent.parent.context.$implicit.style && _v.parent.parent.parent.context.$implicit.visible); _ck(_v, 4, 0, currVal_1); var currVal_2 = "fa"; var currVal_3 = _ck(_v, 7, 0, !_v.parent.parent.parent.context.$implicit.visible); _ck(_v, 6, 0, currVal_2, currVal_3); }, null); }
function View_MapLayerSelectorComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [["class", "selector"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.switchLayer(_v.parent.parent.parent.context.$implicit);
        var pd_0 = ($event.stopPropagation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "gmt-checkbox", [["gmtType", "tristate"]], null, null, null, i4.View_CheckboxComponent_0, i4.RenderType_CheckboxComponent)), i0.ɵdid(2, 114688, null, 0, i5.CheckboxComponent, [], { gmtChecked: [0, "gmtChecked"], gmtType: [1, "gmtType"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.parent.parent.context.$implicit.visible; var currVal_1 = "tristate"; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_MapLayerSelectorComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "div", [["class", "switch"]], null, [[null, "click"], [null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.switchLayer(_v.parent.parent.context.$implicit, (_v.parent.parent.context.$implicit.type === "node")) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (_co.layerMouseEnter($event, _v.parent.parent.context.$implicit) !== false);
        ad = (pd_1 && ad);
    } if (("mouseleave" === en)) {
        var pd_2 = (_co.layerMouseLeave($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 278528, null, 0, i3.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(2, { "layer-visible": 0 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MapLayerSelectorComponent_4)), i0.ɵdid(4, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MapLayerSelectorComponent_7)), i0.ɵdid(6, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(7, 0, null, null, 1, "span", [["class", "title"]], null, null, null, null, null)), (_l()(), i0.ɵted(8, null, [" ", " "]))], function (_ck, _v) { var currVal_0 = "switch"; var currVal_1 = _ck(_v, 2, 0, _v.parent.parent.context.$implicit.visible); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = (_v.parent.parent.context.$implicit.icon || _v.parent.parent.context.$implicit.style); _ck(_v, 4, 0, currVal_2); var currVal_3 = ((!_v.parent.parent.context.$implicit.icon && !_v.parent.parent.context.$implicit.style) && (_v.parent.parent.context.$implicit.visible !== undefined)); _ck(_v, 6, 0, currVal_3); }, function (_ck, _v) { var currVal_4 = _v.parent.parent.context.$implicit.title; _ck(_v, 8, 0, currVal_4); }); }
function View_MapLayerSelectorComponent_9(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(0, null, null, 0))], null, null); }
function View_MapLayerSelectorComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i3.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngClass: [0, "ngClass"] }, null), i0.ɵpod(2, { "pl-20": 0 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MapLayerSelectorComponent_9)), i0.ɵdid(4, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTemplate: [1, "ngForTemplate"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, !_co.getCollapsedState()); _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.parent.parent.context.$implicit.layers.slice().reverse(); var currVal_2 = i0.ɵnov(_v.parent.parent.parent, 1); _ck(_v, 4, 0, currVal_1, currVal_2); }, null); }
function View_MapLayerSelectorComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "span", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MapLayerSelectorComponent_3)), i0.ɵdid(2, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MapLayerSelectorComponent_8)), i0.ɵdid(4, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_v.parent.context.$implicit.type !== "node") || !_co.getCollapsedState()); _ck(_v, 2, 0, currVal_0); var currVal_1 = (!_v.parent.context.$implicit.collapsed && _v.parent.context.$implicit.layers); _ck(_v, 4, 0, currVal_1); }, null); }
function View_MapLayerSelectorComponent_13(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(0, null, null, 0))], null, null); }
function View_MapLayerSelectorComponent_12(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "li", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_MapLayerSelectorComponent_13)), i0.ɵdid(2, 540672, null, 0, i3.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i0.ɵpod(3, { $implicit: 0 })], function (_ck, _v) { var currVal_0 = _ck(_v, 3, 0, _v.context.$implicit); var currVal_1 = i0.ɵnov(_v.parent.parent.parent.parent, 1); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_MapLayerSelectorComponent_11(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "ul", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MapLayerSelectorComponent_12)), i0.ɵdid(2, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getSelectorLayers(_v.parent.parent.context.$implicit); _ck(_v, 2, 0, currVal_0); }, null); }
function View_MapLayerSelectorComponent_10(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MapLayerSelectorComponent_11)), i0.ɵdid(2, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.layers; _ck(_v, 2, 0, currVal_0); }, null); }
function View_MapLayerSelectorComponent_15(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["style", "cursor: help;"], ["title", "Po\u010Det zobrazen\u00FDch vrstev"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["(", "/", ")"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getDisplayedLayersCount(_v.parent.parent.context.$implicit); var currVal_1 = _co.getTotalLayersCount(_v.parent.parent.context.$implicit); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_MapLayerSelectorComponent_18(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(0, null, null, 0))], null, null); }
function View_MapLayerSelectorComponent_17(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "li", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_MapLayerSelectorComponent_18)), i0.ɵdid(2, 540672, null, 0, i3.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i0.ɵpod(3, { $implicit: 0 })], function (_ck, _v) { var currVal_0 = _ck(_v, 3, 0, _v.context.$implicit); var currVal_1 = i0.ɵnov(_v.parent.parent.parent.parent, 1); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_MapLayerSelectorComponent_16(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "ul", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MapLayerSelectorComponent_17)), i0.ɵdid(2, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit.layers.slice().reverse(); _ck(_v, 2, 0, currVal_0); }, null); }
function View_MapLayerSelectorComponent_14(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "span", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 8, "div", [["class", "group"]], null, null, null, null, null)), i0.ɵdid(2, 278528, null, 0, i3.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(3, { "group-collapsed": 0 }), (_l()(), i0.ɵeld(4, 0, null, null, 3, "div", [["class", "group-title"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.switchGroup(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(5, null, [" ", " "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MapLayerSelectorComponent_15)), i0.ɵdid(7, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MapLayerSelectorComponent_16)), i0.ɵdid(9, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "group"; var currVal_1 = _ck(_v, 3, 0, (_v.parent.context.$implicit.collapsed || !_v.parent.context.$implicit.hasVisibleItems)); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_3 = (_co.showCollapsedVisibleLayersCount() && _v.parent.context.$implicit.collapsed); _ck(_v, 7, 0, currVal_3); var currVal_4 = (_v.parent.context.$implicit.layers && !_v.parent.context.$implicit.collapsed); _ck(_v, 9, 0, currVal_4); }, function (_ck, _v) { var currVal_2 = _v.parent.context.$implicit.title; _ck(_v, 5, 0, currVal_2); }); }
function View_MapLayerSelectorComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_MapLayerSelectorComponent_2)), i0.ɵdid(1, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MapLayerSelectorComponent_10)), i0.ɵdid(3, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MapLayerSelectorComponent_14)), i0.ɵdid(5, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = ((_v.context.$implicit.selector === "switch") && (_v.context.$implicit.selected != false)); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_v.context.$implicit.selector === "hidden"); _ck(_v, 3, 0, currVal_1); var currVal_2 = ((_v.context.$implicit.selector === "group") && (_v.context.$implicit.selected != false)); _ck(_v, 5, 0, currVal_2); }, null); }
function View_MapLayerSelectorComponent_19(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(0, null, null, 0))], null, null); }
function View_MapLayerSelectorComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "cmLayerSelector"]], null, null, null, null, null)), (_l()(), i0.ɵand(0, [["layerTreeRef", 2]], null, 0, null, View_MapLayerSelectorComponent_1)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_MapLayerSelectorComponent_19)), i0.ɵdid(3, 540672, null, 0, i3.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i0.ɵpod(4, { $implicit: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 4, 0, _co.config); var currVal_1 = i0.ɵnov(_v, 1); _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
exports.View_MapLayerSelectorComponent_0 = View_MapLayerSelectorComponent_0;
function View_MapLayerSelectorComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "map-layer-selector", [], null, null, null, View_MapLayerSelectorComponent_0, RenderType_MapLayerSelectorComponent)), i0.ɵdid(1, 114688, null, 0, i6.MapLayerSelectorComponent, [i7.MapLayerSelectorService, "localStorageService", i8.MapLayerService, i9.Transition], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_MapLayerSelectorComponent_Host_0 = View_MapLayerSelectorComponent_Host_0;
var MapLayerSelectorComponentNgFactory = i0.ɵccf("map-layer-selector", i6.MapLayerSelectorComponent, View_MapLayerSelectorComponent_Host_0, { options: "options", config: "config", mapId: "mapId" }, {}, []);
exports.MapLayerSelectorComponentNgFactory = MapLayerSelectorComponentNgFactory;
