/**
 * Displays approve terms dialogue
 */

const component = {
    template: require('./approve-terms.html'),
    controller: Ctrl,
    bindings: {
        userId: '<',
        module: '<',
        closeModal: '<',
    }
};

Ctrl.$inject = ['$http', 'AuthService', 'ErrorHandlerService', 'ModulesService'];

function Ctrl($http, AuthService, ErrorHandlerService, ModulesService) {
  this.conditions = null;
  this.$onInit = () => {
      //get module conditions
      ModulesService.getApproveTermsConditions(this.module).then((conditions) => {
        this.conditions = conditions.termsAndConditions;
      });
  };

  this.termsApprove = () => {
      return ModulesService.approveTerms(this.userId, this.module).then(() => {
        if (this.closeModal) {
          this.closeModal();
        }
      });
  };
}

module.exports = component;
