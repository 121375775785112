"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var auth_service_1 = require("@app/common/services/auth.service");
var parent_case_ownership_name_pipe_1 = require("@app/ps/pipes/parent-case-ownership-name.pipe");
var list_service_1 = require("@app/common/services/list.service");
var _ = require("lodash");
var case_service_1 = require("@app/ps/services/case.service");
var CreateCaseSelectParentOwnershipComponent = /** @class */ (function () {
    function CreateCaseSelectParentOwnershipComponent(authService, parentCaseOwnershipNamePipe, listService, confirmService, alertService, caseService) {
        this.authService = authService;
        this.parentCaseOwnershipNamePipe = parentCaseOwnershipNamePipe;
        this.listService = listService;
        this.confirmService = confirmService;
        this.alertService = alertService;
        this.caseService = caseService;
        this.submitCallback = new core_1.EventEmitter();
        this.backCallback = new core_1.EventEmitter();
        this.loading = true;
        this.submit = this.submit.bind(this);
    }
    CreateCaseSelectParentOwnershipComponent.prototype.ngOnInit = function () {
        var project = this.authService.getActualProject();
        this.showEasementDistributionSystemParts = project.easementDistributionSystemParts;
        this.createCaseOwnerships();
    };
    CreateCaseSelectParentOwnershipComponent.prototype.createOwnershipWithParent = function (caseOwnership, businessCase) {
        caseOwnership.parentCaseOwnership = caseOwnership.parentCaseOwnership || null;
        var ownershipWithParent = {
            caseOwnership: caseOwnership,
            businessCase: businessCase,
            optionItems: _.filter(businessCase.caseOwnerships, function (caseOwnership2) {
                return caseOwnership !== caseOwnership2 && caseOwnership2.ownership.subjects[0].opsubType === 'OPO';
            })
        };
        if (!caseOwnership.parentCaseOwnership && ownershipWithParent.optionItems.length === 1) {
            caseOwnership.parentCaseOwnership = ownershipWithParent.optionItems[0];
        }
        if (caseOwnership.parentCaseOwnership) {
            this.changedParent(caseOwnership.parentCaseOwnership, null, ownershipWithParent);
        }
        return ownershipWithParent;
    };
    CreateCaseSelectParentOwnershipComponent.prototype.createCaseOwnerships = function () {
        return __awaiter(this, void 0, void 0, function () {
            var loadTitles, list;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        loadTitles = [];
                        this.loading = true;
                        this.data.cases = [];
                        this.data.easementsSelected.forEach(function (easement) {
                            var businessCase = _.find(_this.data.cases, function (value) { return value.titles[0].id === easement.parcel.title.id; });
                            if (businessCase === undefined) {
                                if (easement.parcel.title.ownerships.length === 0) {
                                    loadTitles.push(easement.parcel.title.id);
                                }
                                businessCase = {
                                    obligation: _this.data.obligation,
                                    titles: [easement.parcel.title],
                                    easements: [],
                                    mpp: {},
                                    distributionSystemParts: _this.showEasementDistributionSystemParts ? [] : null,
                                };
                                _this.data.cases.push(businessCase);
                            }
                            businessCase.easements.push(easement);
                        });
                        if (!(loadTitles.length > 0)) return [3 /*break*/, 2];
                        list = this.listService.createList('ownerships', {
                            limit: null,
                            filters: {
                                validity: ['valid'],
                                titleId: loadTitles
                            },
                        });
                        return [4 /*yield*/, this.listService.fetchResult(list).then(function (data) {
                                data.list.forEach(function (ownership) {
                                    var businessCase = _this.data.cases.find(function (value) { return value.titles[0].id === ownership.title.id; });
                                    businessCase.titles[0].ownerships.push(ownership);
                                });
                            })];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        this.filterOwnershipsWithParent();
                        this.loading = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    CreateCaseSelectParentOwnershipComponent.prototype.filterOwnershipsWithParent = function () {
        var _this = this;
        var oldOwnershipsWithParent = null;
        if (this.data.ownershipsWithParent) {
            oldOwnershipsWithParent = this.data.ownershipsWithParent;
        }
        this.data.ownershipsWithParent = [];
        this.data.cases.forEach(function (businessCase) {
            businessCase.caseOwnerships = [];
            businessCase.titles[0].ownerships.forEach(function (ownership) {
                var caseOwnership = {
                    easementPrice: null,
                    ownership: ownership,
                };
                businessCase.caseOwnerships.push(caseOwnership);
            });
            businessCase.caseOwnerships.forEach(function (caseOwnership) {
                if (caseOwnership.ownership.typeNeedsParent) {
                    if (oldOwnershipsWithParent) {
                        var foundOld = _.find(oldOwnershipsWithParent, { caseOwnership: { ownership: { id: caseOwnership.ownership.id } } });
                        if (foundOld) {
                            caseOwnership.parentCaseOwnership = _.find(businessCase.caseOwnerships, { ownership: { id: foundOld.caseOwnership.parentCaseOwnership.ownership.id } });
                        }
                    }
                    _this.data.ownershipsWithParent.push(_this.createOwnershipWithParent(caseOwnership, businessCase));
                }
            });
        });
        if (!oldOwnershipsWithParent && !this.needsParentSelection()) {
            this.submit(true);
        }
    };
    CreateCaseSelectParentOwnershipComponent.prototype.needsParentSelection = function () {
        return _.some(this.data.ownershipsWithParent, function (item) { return !item.caseOwnership.parentCaseOwnership; });
    };
    CreateCaseSelectParentOwnershipComponent.prototype.changedParent = function (newValue, oldValue, ownershipWithParent) {
        if (oldValue &&
            !_.includes(ownershipWithParent.businessCase.caseOwnerships, oldValue) &&
            !_.some(this.data.ownershipsWithParent, function (item) { return item.caseOwnership.parentCaseOwnership === oldValue; })) {
            ownershipWithParent.businessCase.caseOwnerships.push(oldValue);
            oldValue.isParent = false;
        }
        if (newValue) {
            newValue.isParent = true;
            if (this.caseService.checkParentCaseOwnershipCycle(newValue)) {
                oldValue = newValue.parentCaseOwnership;
                newValue.parentCaseOwnership = null;
                this.changedParent(null, oldValue, _.find(this.data.ownershipsWithParent, { caseOwnership: newValue }));
                new this.alertService({
                    message: 'Zjištěn cyklický vztah v zadání nadřazených subjektů!'
                }).then();
            }
            delete newValue.easementPrice;
            _.pull(ownershipWithParent.businessCase.caseOwnerships, newValue);
        }
    };
    CreateCaseSelectParentOwnershipComponent.prototype.submit = function (skip) {
        var _this = this;
        if (skip === void 0) { skip = false; }
        if (this.data.obligation.type === 'AgreementOnAFutureNetworkConstructionContractLiability' || this.data.obligation.type === 'FutureRealBurdenLiabilityAccelerated') {
            var findGroup_1 = function (casesGrouped, businessCase) {
                return casesGrouped.find(function (businessCaseGrouped) {
                    return (!_this.data.createGroups.some(function (group) { return group.id === 'owner'; }) ||
                        businessCaseGrouped.caseOwnerships[0].ownership.opsubId === businessCase.caseOwnerships[0].ownership.opsubId) &&
                        (!_this.data.createGroups.some(function (group) { return group.id === 'title'; }) ||
                            businessCaseGrouped.titles[0].id === businessCase.titles[0].id);
                });
            };
            this.data.cases = this.data.cases
                // Split for each caseOwnership
                .flatMap(function (businessCase) {
                if (_this.data.createGroups.some(function (group) { return group.id === 'owner'; })) {
                    return businessCase.caseOwnerships.map(function (co) { return Object.assign({}, businessCase, {
                        caseOwnerships: [co],
                    }); });
                }
                else {
                    return [businessCase];
                }
            })
                // do grouping
                .reduce(function (casesGrouped, businessCase) {
                var _a, _b, _c;
                var foundGroup = findGroup_1(casesGrouped, businessCase);
                if (!foundGroup) {
                    casesGrouped.push(businessCase);
                }
                else {
                    (_a = foundGroup.titles).push.apply(_a, __spread(businessCase.titles));
                    (_b = foundGroup.caseOwnerships).push.apply(_b, __spread(businessCase.caseOwnerships));
                    (_c = foundGroup.easements).push.apply(_c, __spread(businessCase.easements));
                }
                return casesGrouped;
            }, []);
        }
        return this.submitCallback.emit({ skipped: skip });
    };
    return CreateCaseSelectParentOwnershipComponent;
}());
exports.CreateCaseSelectParentOwnershipComponent = CreateCaseSelectParentOwnershipComponent;
