"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var bulk_operation_select_entities_component_1 = require("../components/bulk-operation-select-entities/bulk-operation-select-entities.component");
var bulk_operation_select_action_component_1 = require("../components/bulk-operation-select-action/bulk-operation-select-action.component");
var bulk_operation_details_component_1 = require("../components/bulk-operation-details/bulk-operation-details.component");
var bulk_operation_confirm_component_1 = require("../components/bulk-operation-confirm/bulk-operation-confirm.component");
var bulk_operation_finish_component_1 = require("../components/bulk-operation-finish/bulk-operation-finish.component");
var BulkOperationProcessDefinitionModel = /** @class */ (function () {
    function BulkOperationProcessDefinitionModel(operation, entityIds) {
        this.operation = operation;
        this.entityIds = entityIds;
    }
    BulkOperationProcessDefinitionModel.prototype.getTypeName = function () {
        return this.operation.config.title;
    };
    BulkOperationProcessDefinitionModel.prototype.getBackButtonText = function () {
        return this.operation.config.backButtonText;
    };
    BulkOperationProcessDefinitionModel.prototype.getSteps = function () {
        return [
            { name: this.operation.config.firstStepName, id: 'selectEntities', component: bulk_operation_select_entities_component_1.BulkOperationSelectEntitiesComponent },
            { name: 'Výběr operace', id: 'selectAction', component: bulk_operation_select_action_component_1.BulkOperationSelectActionComponent },
            { name: 'Detaily', id: 'details', component: bulk_operation_details_component_1.BulkOperationDetailsComponent },
            { name: 'Potvrzení', id: 'confirm', component: bulk_operation_confirm_component_1.BulkOperationConfirmComponent },
            { name: 'Výsledek', id: 'finish', component: bulk_operation_finish_component_1.BulkOperationFinishComponent },
        ];
    };
    BulkOperationProcessDefinitionModel.prototype.getBulkOperationId = function () {
        return this.operation.config.key;
    };
    BulkOperationProcessDefinitionModel.prototype.getDefaultFilters = function (settingsService) {
        if (typeof this.operation.config.getDefaultFilters === 'function') {
            var filters = this.operation.config.getDefaultFilters(settingsService);
            filters['entityId'] = this.getEntityIds() || [];
            return filters;
        }
    };
    BulkOperationProcessDefinitionModel.prototype.getFilterLocalStorageName = function () {
        return this.operation.config.filterKey;
    };
    BulkOperationProcessDefinitionModel.prototype.getTableComponent = function () {
        return this.operation.config.tableComponent;
    };
    BulkOperationProcessDefinitionModel.prototype.getActions = function (authService, settingsService) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.operation.config.getActions(authService, settingsService)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    BulkOperationProcessDefinitionModel.prototype.getEntityIds = function () {
        return this.entityIds;
    };
    BulkOperationProcessDefinitionModel.prototype.setEntityIds = function (ids) {
        this.entityIds = ids;
    };
    return BulkOperationProcessDefinitionModel;
}());
exports.BulkOperationProcessDefinitionModel = BulkOperationProcessDefinitionModel;
