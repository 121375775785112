"use strict";
var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var case_model_1 = require("@app/ps/models/case.model");
var SimpleCaseStatusFormComponent = /** @class */ (function () {
    function SimpleCaseStatusFormComponent() {
        this.callbackRegister = new core_1.EventEmitter();
        this.isValid = this.isValid.bind(this);
    }
    SimpleCaseStatusFormComponent.prototype.ngOnInit = function () {
        var e_1, _a;
        try {
            for (var _b = __values(this.config), _c = _b.next(); !_c.done; _c = _b.next()) {
                var formField = _c.value;
                if (formField.default && formField.default === 'currentDate') {
                    this.data[formField.field] = this.data[formField.field] || new Date();
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        this.callbackRegister.emit({
            isValid: this.isValid,
        });
    };
    SimpleCaseStatusFormComponent.prototype.isValid = function () {
        var e_2, _a;
        try {
            for (var _b = __values(this.config), _c = _b.next(); !_c.done; _c = _b.next()) {
                var formField = _c.value;
                if (formField.required && !!!this.data[formField.field]) {
                    return false;
                }
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_2) throw e_2.error; }
        }
        return true;
    };
    SimpleCaseStatusFormComponent.prototype.onChange = function ($event, formField) {
        this.data[formField.field] = $event;
        if ((typeof formField.onChange) === 'function') {
            formField.onChange(this.data);
        }
    };
    return SimpleCaseStatusFormComponent;
}());
exports.SimpleCaseStatusFormComponent = SimpleCaseStatusFormComponent;
