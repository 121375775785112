"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var case_model_1 = require("@app/ps/models/case.model");
var FormExpropriationAdministrativeAppealComponent = /** @class */ (function () {
    function FormExpropriationAdministrativeAppealComponent() {
        this.callbackRegister = new core_1.EventEmitter();
        this.isValid = this.isValid.bind(this);
    }
    FormExpropriationAdministrativeAppealComponent.prototype.ngOnInit = function () {
        this.data.expropriationAdministrativeAppealDate = new Date();
        this.callbackRegister.emit({
            isValid: this.isValid,
        });
    };
    FormExpropriationAdministrativeAppealComponent.prototype.isValid = function () {
        return !!this.data.expropriationAdministrativeAppealDate;
    };
    return FormExpropriationAdministrativeAppealComponent;
}());
exports.FormExpropriationAdministrativeAppealComponent = FormExpropriationAdministrativeAppealComponent;
