"use strict";
var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var angular_1 = require("@uirouter/angular");
var auth_service_1 = require("@app/common/services/auth.service");
var string_utils_1 = require("@app/common/utils/string.utils");
var file_validator_utils_1 = require("@app/pk/utils/file-validator.utils");
var CreateProjectFirstStepComponent = /** @class */ (function () {
    function CreateProjectFirstStepComponent(restangular, helpIds, authService, stateService) {
        this.restangular = restangular;
        this.helpIds = helpIds;
        this.authService = authService;
        this.stateService = stateService;
        // model
        this.fileValid = true;
        this.files = [];
        this.message = CreateProjectFirstStepComponent.MSG_EMPTY;
        this.analogLandMessage = CreateProjectFirstStepComponent.ANALOG_LAND_MESSAGES.MSG_EMPTY;
        this.analogLandFileValid = true;
        this.analogLandFileErrors = [];
        this.allAnalogLands = false;
        this.keepPreviousGeometry = true;
        this.onChange = this.onChange.bind(this);
        this.cancel = this.cancel.bind(this);
        this.continue = this.continue.bind(this);
        this.analogLandsFileValidator = this.analogLandsFileValidator.bind(this);
        this.onChangeAnalogLandsFile = this.onChangeAnalogLandsFile.bind(this);
        this.isFormValid = this.isFormValid.bind(this);
    }
    CreateProjectFirstStepComponent.prototype.ngOnInit = function () {
        this.requestId = this.stateService.params.requestId;
    };
    CreateProjectFirstStepComponent.prototype.onChange = function (files) {
        this.files = files;
        this.fileValid = file_validator_utils_1.FileValidatorUtils.checkValidity(files);
        if (this.fileValid) {
            this.message = file_validator_utils_1.FileValidatorUtils.getValidMessage(files);
        }
        else {
            this.message = file_validator_utils_1.FileValidatorUtils.getInvalidMessage(files);
        }
        if (files.length === 0) {
            this.onClearData();
        }
    };
    CreateProjectFirstStepComponent.prototype.onChangeAnalogLandsFile = function (files) {
        if (files.length === 0) {
            this.onClearAnalogLandsFile();
        }
        else if (files.length > 1) {
            this.analogLandFileErrors = [];
            this.analogLandFileValid = false;
            this.analogLandMessage = CreateProjectFirstStepComponent.ANALOG_LAND_MESSAGES.MSG_ONLY_ONE;
        }
        else {
            this.analogLandFile = files[0];
            this.validateAnalogLandsFile(files[0]);
        }
    };
    CreateProjectFirstStepComponent.prototype.validateAnalogLandsFile = function (file) {
        var _this = this;
        this.analogLandFileErrors = [];
        this.analogLandFileValid = false;
        if (!this.analogLandsFileValidator(file)) {
            this.analogLandMessage = CreateProjectFirstStepComponent.ANALOG_LAND_MESSAGES.MSG_ONLY_ONE;
        }
        else {
            this.analogLandMessage = CreateProjectFirstStepComponent.ANALOG_LAND_MESSAGES.MSG_VALIDATION;
            var formData = new FormData();
            formData.append('ANALOG_PARCELS_CSV', file.fileEntry);
            return this.restangular.all('validate').customPOST(formData, 'analog-parcels', undefined, { 'Content-Type': undefined }).then(function (data) {
                _this.analogLandFileValid = data.valid;
                if (!data.valid) {
                    _this.analogLandFileErrors = data.results
                        .filter(function (line) { return line.valid === false; })
                        .map(function (line) {
                        return {
                            line: line.lineNumber,
                            error: CreateProjectFirstStepComponent.ANALOG_LAND_ERROR_LIST[line.message]
                        };
                    });
                }
                _this.analogLandMessage = (_this.analogLandFileValid
                    ? CreateProjectFirstStepComponent.ANALOG_LAND_MESSAGES.MSG_OK
                    : CreateProjectFirstStepComponent.ANALOG_LAND_MESSAGES.MSG_FAIL);
            });
        }
    };
    CreateProjectFirstStepComponent.prototype.analogLandsFileValidator = function (file) {
        return file.extension.toLowerCase() === 'csv';
    };
    CreateProjectFirstStepComponent.prototype.isFormValid = function () {
        return ((this.requestId && this.keepPreviousGeometry)
            ||
                (this.files.length > 0 && this.fileValid && this.analogLandFileValid));
    };
    CreateProjectFirstStepComponent.prototype.continue = function () {
        var formData = new FormData();
        var filesPackage = CreateProjectFirstStepComponent.getUploadFilesPackage(this.files);
        for (var key in filesPackage) {
            formData.append(key, filesPackage[key], filesPackage[key].name);
        }
        if (this.analogLandFile) {
            formData.append('ANALOG_PARCELS_CSV', this.analogLandFile.fileEntry);
        }
        formData.append('ANALOG_ALL', string_utils_1.StringUtils.valueToString(this.allAnalogLands));
        var type = CreateProjectFirstStepComponent.getFilesTypes(this.files);
        this.stateService.go('pk.projects.create-project-second', {
            formData: formData,
            type: type,
            requestId: this.requestId
        });
    };
    CreateProjectFirstStepComponent.prototype.cancel = function () {
        this.stateService.go('pk.projects');
    };
    CreateProjectFirstStepComponent.prototype.onClearData = function () {
        this.fileValid = false;
        this.message = CreateProjectFirstStepComponent.MSG_EMPTY;
        this.files = [];
    };
    CreateProjectFirstStepComponent.prototype.onClearAnalogLandsFile = function () {
        this.analogLandFile = null;
        this.analogLandFileValid = true;
        this.analogLandFileErrors = [];
        this.analogLandMessage = CreateProjectFirstStepComponent.ANALOG_LAND_MESSAGES.MSG_EMPTY;
    };
    CreateProjectFirstStepComponent.getUploadFilesPackage = function (files) {
        var e_1, _a;
        var filesPackage = {};
        if (!files.length) {
            return filesPackage;
        }
        try {
            for (var files_1 = __values(files), files_1_1 = files_1.next(); !files_1_1.done; files_1_1 = files_1.next()) {
                var file = files_1_1.value;
                filesPackage[file.extension.toUpperCase()] = file.fileEntry;
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (files_1_1 && !files_1_1.done && (_a = files_1.return)) _a.call(files_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return filesPackage;
    };
    CreateProjectFirstStepComponent.MSG_EMPTY = "Je mo\u017En\u00E9 vlo\u017Eit pouze jeden soubor dxf, vfk nebo shapefile.";
    CreateProjectFirstStepComponent.ANALOG_LAND_ERROR_LIST = {
        TOO_FEW_COLUMNS: 'špatný počet sloupců',
        WRONG_KATUZE: 'špatné katastrální území',
        WRONG_CISL_KOD: 'špatný kód číslování',
        WRONG_PARCELNI_CISLO: 'špatné parcelní číslo',
        WRONG_DIL_PARCELY: 'špatný díl parcely',
        WRONG_PAR_TYPE: 'špatný typ parcely',
        WRONG_ZE_KOD: 'špatný kód ZE',
        WRONG_KATUZE_PUVODNI: 'špatné původní katastrální území',
        WRONG_PARCEL_ID: 'špatný identifikátor parcely',
        PARCEL_NOT_FOUND: 'parcela nebyla nalezena',
        WRONG_CSV_FORMAT: 'Nebyly nalezeny žádné parcely. Ujistěte se, že řádky nejsou uloženy s uvozovkami na začátku (tedy začíná číslem) a je ve formátu katuze_kod;cisl_kod;parcis;parcis_dil;par_type;ze_kod;katuze_kod_puv;id_par'
    };
    CreateProjectFirstStepComponent.ANALOG_LAND_MESSAGES = {
        MSG_EMPTY: 'Vložte soubor přetažením nebo výběrem. Je možné vložit jeden soubor CSV.',
        MSG_ONLY_ONE: 'Vložte jeden soubor CSV s analogovými parcelami.',
        MSG_VALIDATION: 'Probíhá validace...',
        MSG_OK: 'Soubor s analogovými parcelami je v pořádku.',
        MSG_FAIL: 'V souboru CSV se vyskytují chyby.'
    };
    CreateProjectFirstStepComponent.getFilesTypes = function (files) {
        if (!files.length) {
            return null;
        }
        if (file_validator_utils_1.FileValidatorUtils.checkSHPValidity(files)) {
            return 'SHP';
        }
        else {
            return files[0].extension.toUpperCase();
        }
    };
    return CreateProjectFirstStepComponent;
}());
exports.CreateProjectFirstStepComponent = CreateProjectFirstStepComponent;
