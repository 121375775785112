"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var DocumentTypeCategoryEnum;
(function (DocumentTypeCategoryEnum) {
    DocumentTypeCategoryEnum["CONTRACT"] = "CONTRACT";
    DocumentTypeCategoryEnum["COVERING_LETTER"] = "COVERING_LETTER";
    DocumentTypeCategoryEnum["PAYMENT_ORDER"] = "PAYMENT_ORDER";
    DocumentTypeCategoryEnum["OTHER"] = "OTHER";
    DocumentTypeCategoryEnum["FINANCIAL_CONTROL"] = "FINANCIAL_CONTROL";
    DocumentTypeCategoryEnum["PRELIMINARY_PAYMENT_ORDER"] = "PRELIMINARY_PAYMENT_ORDER";
})(DocumentTypeCategoryEnum = exports.DocumentTypeCategoryEnum || (exports.DocumentTypeCategoryEnum = {}));
;
exports.documentTypeCategoryOptions = [
    {
        id: DocumentTypeCategoryEnum.CONTRACT,
        name: 'Návrh smlouvy',
    },
    {
        id: DocumentTypeCategoryEnum.COVERING_LETTER,
        name: 'Průvodní dopis',
    },
    {
        id: DocumentTypeCategoryEnum.PAYMENT_ORDER,
        name: 'Platební příkaz',
    },
    {
        id: DocumentTypeCategoryEnum.OTHER,
        name: 'Ostatní',
    },
    {
        id: DocumentTypeCategoryEnum.FINANCIAL_CONTROL,
        name: 'Finanční kontrola před vznikem závazku',
    },
    {
        id: DocumentTypeCategoryEnum.PRELIMINARY_PAYMENT_ORDER,
        name: 'Proforma platebního příkazu',
    },
];
