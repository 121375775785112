"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var pageable_list_service_1 = require("@app/common/services/pageable.list.service");
var auth_service_1 = require("@app/common/services/auth.service");
var TemplateHistoryComponent = /** @class */ (function () {
    function TemplateHistoryComponent(helpIds, restangular, pageableListService, authService) {
        this.helpIds = helpIds;
        this.restangular = restangular;
        this.pageableListService = pageableListService;
        this.authService = authService;
        this.restBaseUrl = this.authService.getActiveApplicationRestUrl();
        this.authToken = this.authService.getToken();
        this.defaultFilter = {
            filters: {
                key: this.key,
            },
            sortOrder: {
                sortBy: 'startTimestamp',
                direction: 'desc',
            },
        };
    }
    TemplateHistoryComponent.prototype.ngOnInit = function () {
        this.initializeList();
    };
    TemplateHistoryComponent.prototype.initializeList = function () {
        this.pageableList = this.pageableListService.get('templates', this.defaultFilter);
        this.list = this.pageableList.list;
        this.pageableList.load();
    };
    TemplateHistoryComponent.prototype.uiOnParamsChanged = function (changedParams, $transition$) {
        this.pageableList.uiOnParamsChanged(changedParams, $transition$);
    };
    return TemplateHistoryComponent;
}());
exports.TemplateHistoryComponent = TemplateHistoryComponent;
