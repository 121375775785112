"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var project_subject_name_pipe_1 = require("@app/ps/pipes/project-subject-name.pipe");
var ConstructionObjectListComponent = /** @class */ (function () {
    function ConstructionObjectListComponent(projectSubjectNamePipe) {
        this.projectSubjectNamePipe = projectSubjectNamePipe;
        this.showAdministrator = false;
        this.showMore = true;
        // model
        this.show = false;
    }
    ConstructionObjectListComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.showAdministrator) {
            this.administrators = __spread(new Set(this.constructionObjects
                .map(function (co) { return co.administrator ? _this.projectSubjectNamePipe.transform(co.administrator) : 'nezvoleno'; })));
        }
        if (!this.showMore) {
            this.show = true;
        }
    };
    ConstructionObjectListComponent.prototype.onShowMore = function (event) {
        event.stopPropagation();
        event.preventDefault();
        this.show = true;
    };
    return ConstructionObjectListComponent;
}());
exports.ConstructionObjectListComponent = ConstructionObjectListComponent;
