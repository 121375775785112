"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var L = require("leaflet");
var leaflet_wms_common_service_1 = require("@app/map/services/leaflet-wms-common.service");
var map_utils_cql_filter_service_1 = require("@app/map/services/map-utils-cql-filter.service");
var map_utils_wfs_feature_service_1 = require("@app/map/services/map-utils-wfs-feature.service");
var i0 = require("@angular/core");
var i1 = require("./leaflet-wms-common.service");
var i2 = require("./map-utils-cql-filter.service");
var i3 = require("./map-utils-wfs-feature.service");
/**
* WMS layer extended with WFS-style filtering capabilities using CQL filter
*/
var LeafletTileLayerWmsCQLFiltered = /** @class */ (function () {
    function LeafletTileLayerWmsCQLFiltered(leafletWmsCommonService, mapUtilsCQLFilterService, mapUtilsWFSFeatureService) {
        this.WmsTileLayer = L.TileLayer.WMS.extend({
            initialize: function (url, options, filter) {
                if (!options || !options.id) {
                    throw new Error("Missing layer id option in initialization.");
                }
                this._id = options.id;
                this.wmsParams = L.extend(options.wms);
                var cqlFilter = mapUtilsCQLFilterService.getFilter(filter);
                // when filter is just empty string, geoserver returns error
                if (cqlFilter !== '') {
                    this.wmsParams['cql_filter'] = cqlFilter;
                }
                L.TileLayer.WMS.prototype.initialize.call(this, url, this.wmsParams);
            },
            loadFeatureData: function (options) {
                return leafletWmsCommonService.loadFeatureData({
                    latlng: options.latlng,
                    queryParams: options.queryParams,
                    url: this._url,
                    uppercase: this._uppercase,
                    httpService: this._httpService,
                    map: this._map,
                    crs: this._crs,
                    layers: this.wmsParams['layers'],
                    filter: null,
                    cqlFilter: this.wmsParams['cql_filter'],
                    featureCount: this.wmsParams['feature_count'],
                    version: options.version
                });
            },
            loadFeatureDataByID: function (featureID, queryParams) {
                var wfsBaseURL = this._url.substring(0, this._url.lastIndexOf("wms")) + 'wfs';
                return mapUtilsWFSFeatureService.getFeatureByID(wfsBaseURL, this.wmsParams.layers, featureID, queryParams);
            },
            removeWmsParam: function (param) {
                if (this.wmsParams.hasOwnProperty(param)) {
                    delete this.wmsParams[param];
                }
            }
        });
    }
    LeafletTileLayerWmsCQLFiltered.prototype.getLayer = function (url, options, filter) {
        return new this.WmsTileLayer(url, options, filter);
    };
    LeafletTileLayerWmsCQLFiltered.ngInjectableDef = i0.defineInjectable({ factory: function LeafletTileLayerWmsCQLFiltered_Factory() { return new LeafletTileLayerWmsCQLFiltered(i0.inject(i1.LeafletWmsCommonService), i0.inject(i2.MapUtilsCqlFilterService), i0.inject(i3.MapUtilsWfsFeatureService)); }, token: LeafletTileLayerWmsCQLFiltered, providedIn: "root" });
    return LeafletTileLayerWmsCQLFiltered;
}());
exports.LeafletTileLayerWmsCQLFiltered = LeafletTileLayerWmsCQLFiltered;
