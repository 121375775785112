"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var RequestStatusEnum;
(function (RequestStatusEnum) {
    RequestStatusEnum["RECEIVED"] = "RECEIVED";
    RequestStatusEnum["VALIDATED"] = "VALIDATED";
    RequestStatusEnum["IMPORTED"] = "IMPORTED";
    RequestStatusEnum["WAITING_FOR_PRICE_CONFIRMATION"] = "WAITING_FOR_PRICE_CONFIRMATION";
    RequestStatusEnum["PRICE_CONFIRMED"] = "PRICE_CONFIRMED";
    RequestStatusEnum["CUZK_DATA_WAITING"] = "CUZK_DATA_WAITING";
    RequestStatusEnum["CUZK_DATA_RECEIVED"] = "CUZK_DATA_RECEIVED";
    RequestStatusEnum["INTERSECTION"] = "INTERSECTION";
    RequestStatusEnum["FINISHED"] = "FINISHED";
    RequestStatusEnum["FINISHED_WITH_ANALOG"] = "FINISHED_WITH_ANALOG";
    RequestStatusEnum["FAILED"] = "FAILED";
    RequestStatusEnum["ARCHIVED"] = "ARCHIVED";
})(RequestStatusEnum = exports.RequestStatusEnum || (exports.RequestStatusEnum = {}));
exports.requestStatusOptions = [
    { id: RequestStatusEnum.RECEIVED, name: 'Přijato' },
    { id: RequestStatusEnum.VALIDATED, name: 'Validováno' },
    { id: RequestStatusEnum.IMPORTED, name: 'Importováno' },
    { id: RequestStatusEnum.WAITING_FOR_PRICE_CONFIRMATION, name: 'Čeká na potvrzení ceny' },
    { id: RequestStatusEnum.PRICE_CONFIRMED, name: 'Cena potvrzena' },
    { id: RequestStatusEnum.CUZK_DATA_WAITING, name: 'Čeká na data z KN' },
    { id: RequestStatusEnum.CUZK_DATA_RECEIVED, name: 'Obdržena data z KN' },
    { id: RequestStatusEnum.INTERSECTION, name: 'Protínání' },
    { id: RequestStatusEnum.FINISHED, name: 'Dokončeno' },
    { id: RequestStatusEnum.FINISHED_WITH_ANALOG, name: 'Dokončeno s analogovými parcelami' },
    { id: RequestStatusEnum.FAILED, name: 'Chyba' },
    { id: RequestStatusEnum.ARCHIVED, name: 'Archivováno' },
];
