"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var angular_1 = require("@uirouter/angular");
var i0 = require("@angular/core");
var i1 = require("@uirouter/angular");
var ProjectService = /** @class */ (function () {
    function ProjectService(stateService) {
        this.stateService = stateService;
    }
    ProjectService.prototype.changeProject = function (project) {
        this.stateService.go('symap.project.home', { projectKey: project.key }, { inherit: false });
    };
    ProjectService.ngInjectableDef = i0.defineInjectable({ factory: function ProjectService_Factory() { return new ProjectService(i0.inject(i1.StateService)); }, token: ProjectService, providedIn: "root" });
    return ProjectService;
}());
exports.ProjectService = ProjectService;
