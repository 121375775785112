"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var PickADateComponent = /** @class */ (function () {
    function PickADateComponent() {
        this.placeholder = 'd.m.rrrr';
        this.changed = new core_1.EventEmitter();
    }
    PickADateComponent.prototype.onChanged = function ($event) {
        this.dateInput = $event;
        this.changed.emit(this.dateInput);
    };
    return PickADateComponent;
}());
exports.PickADateComponent = PickADateComponent;
