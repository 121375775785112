"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var angular_1 = require("@uirouter/angular");
var angular_2 = require("@uirouter/angular");
var auth_service_1 = require("@app/common/services/auth.service");
var login_service_1 = require("@app/common/services/login.service");
var menuConfig_1 = require("@app/models/menuConfig");
var brand_translate_pipe_1 = require("@app/common/pipes/brand-translate.pipe");
var MainComponent = /** @class */ (function () {
    function MainComponent(authService, loginService, brandTranslatePipe, uiRouterGlobals, transitionService, stateService, ENV, APP_BRAND, applications, localStorageService, modulesService, approveTermsService, changePasswordService, googleAnalyticsService) {
        this.authService = authService;
        this.loginService = loginService;
        this.brandTranslatePipe = brandTranslatePipe;
        this.uiRouterGlobals = uiRouterGlobals;
        this.transitionService = transitionService;
        this.stateService = stateService;
        this.ENV = ENV;
        this.APP_BRAND = APP_BRAND;
        this.applications = applications;
        this.localStorageService = localStorageService;
        this.modulesService = modulesService;
        this.approveTermsService = approveTermsService;
        this.changePasswordService = changePasswordService;
        this.googleAnalyticsService = googleAnalyticsService;
        this.collapsed = false;
        this.menuConfig = [];
        this.checkPasswordChange = this.checkPasswordChange.bind(this);
        this.changeProject = this.changeProject.bind(this);
    }
    MainComponent.prototype.ngOnInit = function () {
        this.authService.setActiveApplication(this.applications.di.name);
        this.authService.updateBaseUrl();
        this.authService.updateAuthorizationHeader();
        this.googleAnalyticsService.onUserCome();
        this.collapsed = this.localStorageService.get('menuOpened');
        this.user = this.authService.getUser();
        var actualProject = this.authService.getActualProject();
        if (actualProject) {
            this.projectName = actualProject.name;
            this.selProject = actualProject;
        }
        var actualProjects = this.authService.getActualProjects();
        if (actualProjects) {
            this.projects = actualProjects.filter(function (p) { return p.key.match(/di_/); });
        }
        this.servicedeskUrl = this.authService.getServiceDeskUrl();
        this.menuConfig = this.getMenuConfig();
        this.checkApprovedTerms()
            .then(this.checkPasswordChange);
    };
    MainComponent.prototype.onCollapse = function () {
        this.collapsed = !this.collapsed;
        this.localStorageService.set('menuOpened', this.collapsed);
    };
    MainComponent.prototype.logout = function () {
        this.authService.logout();
    };
    MainComponent.prototype.changeProject = function (project) {
        this.stateService.go('dimap.project.propertyStatus', { projectKey: project.key });
    };
    MainComponent.prototype.checkApprovedTerms = function () {
        var _this = this;
        // check approvedTerms
        // get approvedTerms
        return this.modulesService.getUserApprovedTerms(this.user.id).then(function (approvedTerms) {
            if (!approvedTerms.includes(_this.applications.di.name)) {
                // open modal
                return _this.approveTermsService({
                    module: _this.applications.di.name,
                    userId: _this.user.id,
                });
            }
        });
    };
    MainComponent.prototype.checkPasswordChange = function () {
        // user is logging in for the first time - ask for password change
        // terms of conditions were changed
        if (this.authService.isFirstLogin() && !this.authService.isSsoLoginEnabled()) {
            return this.changePasswordService();
        }
    };
    MainComponent.prototype.getMenuConfig = function () {
        return [
            new menuConfig_1.MenuConfig('Majetkoprávní přehled', 'dimap.project.propertyStatus', 'fa-home', undefined, undefined, undefined, undefined, 'manage_property_status,property_status'),
            new menuConfig_1.MenuConfig('Mapa', 'dimap.project.map', 'fa-home', undefined, undefined, undefined, undefined, 'access'),
            new menuConfig_1.MenuConfig('LV', 'dimap.project.titles', 'fa-users', undefined, undefined, undefined, undefined, 'cadastre'),
            new menuConfig_1.MenuConfig('Parcely', 'dimap.project.parcels', 'fa-toggle-up', undefined, undefined, undefined, undefined, 'cadastre'),
            new menuConfig_1.MenuConfig('Nastavení', 'dimap.project.settings', 'fa-wrench', undefined, undefined, undefined, undefined, 'access')
        ];
    };
    return MainComponent;
}());
exports.MainComponent = MainComponent;
