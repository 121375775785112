"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var ProgressBarComponent = /** @class */ (function () {
    function ProgressBarComponent() {
        this.background = 'STARTED';
    }
    ProgressBarComponent.prototype.ngOnInit = function () {
        this.isNA = this.total === 0;
        this.percentage = (this.total === 0 ? 0 : (this.done / this.total * 100));
    };
    return ProgressBarComponent;
}());
exports.ProgressBarComponent = ProgressBarComponent;
