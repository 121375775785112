"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var i0 = require("@angular/core");
var SettlementCategoryPipe = /** @class */ (function () {
    function SettlementCategoryPipe() {
    }
    SettlementCategoryPipe.prototype.transform = function (settlementData, category) {
        return settlementData && settlementData.filter(function (sd) { return sd.occupationCategory === category; })[0];
    };
    SettlementCategoryPipe.ngInjectableDef = i0.defineInjectable({ factory: function SettlementCategoryPipe_Factory() { return new SettlementCategoryPipe(); }, token: SettlementCategoryPipe, providedIn: "root" });
    return SettlementCategoryPipe;
}());
exports.SettlementCategoryPipe = SettlementCategoryPipe;
