"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var settings_states_1 = require("@app/settings/settings.states");
var SETTINGS_STATES = [
    settings_states_1.settingsState,
    settings_states_1.customerListState,
    settings_states_1.customerContractDetailState,
    settings_states_1.customerContractEditState,
    settings_states_1.customerContractProjectState,
    settings_states_1.customerContractState,
    settings_states_1.customerContractAbstractState,
    settings_states_1.customerCreateState,
    settings_states_1.customerDetailState,
    settings_states_1.customerEditState,
];
var ɵ0 = function ($injector) { return $injector.get('GlobalRestangularConfigProvider'); }, ɵ1 = function ($injector) { return $injector.get('GlobalRestangularSYMAPProvider'); }, ɵ2 = function ($injector) { return $injector.get('ModulesService'); }, ɵ3 = function ($injector) { return $injector.get('APPLICATIONS'); }, ɵ4 = function ($injector) { return $injector.get('WindowDimensionService'); };
exports.ɵ0 = ɵ0;
exports.ɵ1 = ɵ1;
exports.ɵ2 = ɵ2;
exports.ɵ3 = ɵ3;
exports.ɵ4 = ɵ4;
var SettingsModule = /** @class */ (function () {
    function SettingsModule() {
    }
    return SettingsModule;
}());
exports.SettingsModule = SettingsModule;
