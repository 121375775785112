"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var auth_service_1 = require("@app/common/services/auth.service");
var _ = require("lodash");
var TemplateListComponent = /** @class */ (function () {
    function TemplateListComponent(helpIds, restangular, uploadService, authService) {
        this.helpIds = helpIds;
        this.restangular = restangular;
        this.uploadService = uploadService;
        this.authService = authService;
        this.loading = true;
        this.templateTypes = [];
        this.displayed = undefined;
        this.restBaseUrl = this.authService.getActiveApplicationRestUrl();
        this.authToken = this.authService.getToken();
        this.uploadingFiles = [];
        this.onSelectFiles = this.onSelectFiles.bind(this);
        this.onRemoveTemplate = this.onRemoveTemplate.bind(this);
    }
    TemplateListComponent.prototype.ngOnInit = function () {
        this.project = this.authService.getActualProject();
        this.loadAllowedTemplates();
    };
    TemplateListComponent.prototype.display = function (index) {
        var _this = this;
        this.displayed = index;
        if (this.templateTypes[index].loading === undefined) {
            this.templateTypes[index].loading = true;
            this.loadTemplatesByType(this.templateTypes[index]).then(function (data) {
                _this.templateTypes[index].loading = false;
                _this.templateTypes[index].templates = data;
                _this.templateTypes[index].modifiable = _this.isModifiable();
            });
        }
    };
    TemplateListComponent.prototype.onRemoveTemplate = function (template) {
        var templates = this.templateTypes[this.displayed].templates;
        if (!template.id) {
            _.pull(templates, template);
        }
        else {
            this.restangular
                .one('templates', template.id)
                .remove()
                .then(function () {
                _.remove(templates, { id: template.id });
            });
        }
    };
    TemplateListComponent.prototype.onSelectFiles = function (files) {
        var e_1, _a;
        try {
            for (var files_1 = __values(files), files_1_1 = files_1.next(); !files_1_1.done; files_1_1 = files_1.next()) {
                var file = files_1_1.value;
                this.uploadFile(file);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (files_1_1 && !files_1_1.done && (_a = files_1.return)) _a.call(files_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
    };
    TemplateListComponent.prototype.uploadFile = function (file) {
        var _this = this;
        if (this.uploadingFiles.indexOf(file) === -1) {
            this.uploadingFiles.push(file);
        }
        file.uploading = this.uploadService.upload({
            url: this.restBaseUrl + "/templates",
            data: {
                key: this.templateTypes[this.displayed].key,
                filename: file.fileEntry.name,
                file: file.fileEntry,
            },
            headers: __assign({}, this.restangular.defaultHeaders),
        }).progress(function (evt) {
            file.progress = (100.0 * evt.loaded / evt.total);
        }).success(function (data) {
            _this.templateTypes[_this.displayed].templates.push(data);
            _this.uploadingFiles.splice(_this.uploadingFiles.indexOf(file), 1);
        }).error(function (response, responseCode) {
            if (responseCode === 400 && response.message) {
                file.errorMessage = response.message;
            }
            file.error = true;
        });
    };
    TemplateListComponent.prototype.loadAllowedTemplates = function () {
        var _this = this;
        return this.restangular
            .all('templates')
            .all('types-allowed')
            .getList()
            .then(function (data) {
            _this.templateTypes = data.plain();
            _this.loading = false;
        });
    };
    TemplateListComponent.prototype.isModifiable = function () {
        return !this.project.useTemplatesService && (this.authService.hasPermission('manage_templates') || this.authService.hasPermission('admin'));
    };
    TemplateListComponent.prototype.loadTemplatesByType = function (templateType) {
        return this.restangular
            .all('templates')
            .customPOST({
            filter: {
                filters: {
                    key: [templateType.key],
                    validity: ['valid'],
                },
            },
        });
    };
    return TemplateListComponent;
}());
exports.TemplateListComponent = TemplateListComponent;
