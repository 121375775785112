"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ParcelPriceModel = /** @class */ (function () {
    function ParcelPriceModel() {
    }
    ParcelPriceModel.createDefaultExpertOpinionPermanent = function () {
        var parcelPrice = new ParcelPriceModel();
        parcelPrice.expertOpinionVegetationPriceDefault = 0;
        parcelPrice.expertOpinionCompensationPriceDefault = 0;
        parcelPrice.expertOpinionBuildingsPriceDefault = 0;
        parcelPrice.expertOpinionLandSquarePrice = 0;
        parcelPrice.expertOpinionParcelPriceDefault = 0;
        parcelPrice.expertOpinionPriceCoefficient = 1;
        return parcelPrice;
    };
    ParcelPriceModel.createDefaultOtherSourcePermanent = function () {
        var parcelPrice = new ParcelPriceModel();
        parcelPrice.otherLandPrice = 0;
        parcelPrice.otherVegetationPrice = 0;
        parcelPrice.otherBuildingsPrice = 0;
        return parcelPrice;
    };
    return ParcelPriceModel;
}());
exports.ParcelPriceModel = ParcelPriceModel;
