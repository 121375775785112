"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./titles-short-list.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@uirouter/angular/lib/directives/uiSref");
var i3 = require("@uirouter/core");
var i4 = require("@angular/common");
var i5 = require("../../../common/directives/loading.directive");
var i6 = require("./titles-short-list.component");
var styles_TitlesShortListComponent = [i0.styles];
var RenderType_TitlesShortListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TitlesShortListComponent, data: {} });
exports.RenderType_TitlesShortListComponent = RenderType_TitlesShortListComponent;
function View_TitlesShortListComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Pod\u00EDl"]))], null, null); }
function View_TitlesShortListComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "tag tag--danger ml-10 tag--small is-uppercase"], ["title", "Duplicitn\u00ED z\u00E1pis vlastnictv\u00ED"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Duplicitn\u00ED z\u00E1pis vlastnictv\u00ED"]))], null, null); }
function View_TitlesShortListComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "a", [["uiSref", "symap.project.owners.detail"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).go($event.button, $event.ctrlKey, $event.metaKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i2.AnchorUISref, [i1.ElementRef, i1.Renderer2], null, null), i1.ɵdid(2, 737280, null, 0, i2.UISref, [i3.UIRouter, [2, i2.AnchorUISref], "UIView.PARENT_INJECT"], { state: [0, "state"], params: [1, "params"] }, null), i1.ɵpod(3, { ownerId: 0, tab: 1 }), (_l()(), i1.ɵted(-1, null, ["SJM"]))], function (_ck, _v) { var currVal_0 = "symap.project.owners.detail"; var currVal_1 = _ck(_v, 3, 0, _v.parent.parent.context.$implicit.subject.id, "about"); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_TitlesShortListComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" ("])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TitlesShortListComponent_11)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵted(-1, null, [")"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.parent.context.$implicit.subject.id !== _co.ownerId); var currVal_1 = i1.ɵnov(_v.parent.parent.parent.parent.parent.parent.parent, 4); _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
function View_TitlesShortListComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TitlesShortListComponent_10)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(4, null, ["", " "]))], function (_ck, _v) { var currVal_1 = _v.context.$implicit.isBsm; _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = ((_v.context.$implicit.podilCitatel + "/") + _v.context.$implicit.podilJmenovatel); _ck(_v, 1, 0, currVal_0); var currVal_2 = (_v.context.last ? "" : ", "); _ck(_v, 4, 0, currVal_2); }); }
function View_TitlesShortListComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "td", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TitlesShortListComponent_9)), i1.ɵdid(2, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.ownerships; _ck(_v, 2, 0, currVal_0); }, null); }
function View_TitlesShortListComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 7, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 4, "a", [["uiSref", "symap.project.titles.detail"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).go($event.button, $event.ctrlKey, $event.metaKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 16384, null, 0, i2.AnchorUISref, [i1.ElementRef, i1.Renderer2], null, null), i1.ɵdid(6, 737280, null, 0, i2.UISref, [i3.UIRouter, [2, i2.AnchorUISref], "UIView.PARENT_INJECT"], { state: [0, "state"], params: [1, "params"] }, null), i1.ɵpod(7, { id: 0, tab: 1 }), (_l()(), i1.ɵted(8, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TitlesShortListComponent_7)), i1.ɵdid(10, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TitlesShortListComponent_8)), i1.ɵdid(12, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "symap.project.titles.detail"; var currVal_2 = _ck(_v, 7, 0, _v.context.$implicit.id, null); _ck(_v, 6, 0, currVal_1, currVal_2); var currVal_4 = _v.context.$implicit.duplicityOwnership; _ck(_v, 10, 0, currVal_4); var currVal_5 = _co.options.showOwnerships; _ck(_v, 12, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.area.name; _ck(_v, 2, 0, currVal_0); var currVal_3 = _v.context.$implicit.lv; _ck(_v, 8, 0, currVal_3); }); }
function View_TitlesShortListComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TitlesShortListComponent_6)), i1.ɵdid(2, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); }, null); }
function View_TitlesShortListComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "multiple-titles"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 11, "table", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 7, "thead", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 6, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Katastr\u00E1ln\u00ED \u00FAzem\u00ED"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Listy vlastnictv\u00ED"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TitlesShortListComponent_4)), i1.ɵdid(9, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 2, "tbody", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TitlesShortListComponent_5)), i1.ɵdid(12, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.options.showOwnerships; _ck(_v, 9, 0, currVal_0); var currVal_1 = _co.groupedTitleList; _ck(_v, 12, 0, currVal_1); }, null); }
function View_TitlesShortListComponent_13(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "tag tag--danger ml-10 tag--small is-uppercase"], ["title", "Duplicitn\u00ED z\u00E1pis vlastnictv\u00ED"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Duplicitn\u00ED z\u00E1pis vlastnictv\u00ED"]))], null, null); }
function View_TitlesShortListComponent_17(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "a", [["uiSref", "symap.project.owners.detail"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).go($event.button, $event.ctrlKey, $event.metaKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i2.AnchorUISref, [i1.ElementRef, i1.Renderer2], null, null), i1.ɵdid(2, 737280, null, 0, i2.UISref, [i3.UIRouter, [2, i2.AnchorUISref], "UIView.PARENT_INJECT"], { state: [0, "state"], params: [1, "params"] }, null), i1.ɵpod(3, { ownerId: 0, tab: 1 }), (_l()(), i1.ɵted(-1, null, ["SJM"]))], function (_ck, _v) { var currVal_0 = "symap.project.owners.detail"; var currVal_1 = _ck(_v, 3, 0, _v.parent.parent.context.$implicit.subject.id, "about"); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_TitlesShortListComponent_16(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" ("])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TitlesShortListComponent_17)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵted(-1, null, [")"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.parent.context.$implicit.subject.id !== _co.ownerId); var currVal_1 = i1.ɵnov(_v.parent.parent.parent.parent.parent, 4); _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
function View_TitlesShortListComponent_15(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TitlesShortListComponent_16)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(4, null, ["", " "]))], function (_ck, _v) { var currVal_1 = _v.context.$implicit.isBsm; _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = ((_v.context.$implicit.podilCitatel + "/") + _v.context.$implicit.podilJmenovatel); _ck(_v, 1, 0, currVal_0); var currVal_2 = (_v.context.last ? "" : ", "); _ck(_v, 4, 0, currVal_2); }); }
function View_TitlesShortListComponent_14(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Pod\u00EDl"])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "info"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TitlesShortListComponent_15)), i1.ɵdid(5, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.groupedTitleList[0][0].ownerships; _ck(_v, 5, 0, currVal_0); }, null); }
function View_TitlesShortListComponent_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "div", [["class", "column-100"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Katastr\u00E1ln\u00ED \u00FAzem\u00ED"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "info"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["List vlastnictv\u00ED"])), (_l()(), i1.ɵeld(7, 0, null, null, 7, "div", [["class", "info"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 4, "a", [["uiSref", "symap.project.titles.detail"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10).go($event.button, $event.ctrlKey, $event.metaKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(9, 16384, null, 0, i2.AnchorUISref, [i1.ElementRef, i1.Renderer2], null, null), i1.ɵdid(10, 737280, null, 0, i2.UISref, [i3.UIRouter, [2, i2.AnchorUISref], "UIView.PARENT_INJECT"], { state: [0, "state"], params: [1, "params"] }, null), i1.ɵpod(11, { id: 0, tab: 1 }), (_l()(), i1.ɵted(12, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TitlesShortListComponent_13)), i1.ɵdid(14, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TitlesShortListComponent_14)), i1.ɵdid(16, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "symap.project.titles.detail"; var currVal_2 = _ck(_v, 11, 0, _co.groupedTitleList[0][0].id, null); _ck(_v, 10, 0, currVal_1, currVal_2); var currVal_4 = _co.groupedTitleList[0][0].duplicityOwnership; _ck(_v, 14, 0, currVal_4); var currVal_5 = _co.options.showOwnerships; _ck(_v, 16, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.groupedTitleList[0][0].area.name; _ck(_v, 4, 0, currVal_0); var currVal_3 = _co.groupedTitleList[0][0].lv; _ck(_v, 12, 0, currVal_3); }); }
function View_TitlesShortListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TitlesShortListComponent_3)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["singleTitle", 2]], null, 0, null, View_TitlesShortListComponent_12))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.groupedTitleList.length > 1) || (_co.groupedTitleList[0].length > 1)); var currVal_1 = i1.ɵnov(_v, 3); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_TitlesShortListComponent_18(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, [" \u017D\u00E1dn\u00E9 dostupn\u00E9 LV "]))], null, null); }
function View_TitlesShortListComponent_19(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["SJM"]))], null, null); }
function View_TitlesShortListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TitlesShortListComponent_2)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["noTitles", 2]], null, 0, null, View_TitlesShortListComponent_18)), (_l()(), i1.ɵand(0, [["sjmNoLink", 2]], null, 0, null, View_TitlesShortListComponent_19))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.ownershipList.itemCount; var currVal_1 = i1.ɵnov(_v, 3); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_TitlesShortListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], [[8, "className", 0]], null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i5.LoadingDirective, [i1.ElementRef], { loading: [0, "loading"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TitlesShortListComponent_1)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.ownershipList.loading; _ck(_v, 1, 0, currVal_1); var currVal_2 = !_co.ownershipList.loading; _ck(_v, 3, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).elementClass; _ck(_v, 0, 0, currVal_0); }); }
exports.View_TitlesShortListComponent_0 = View_TitlesShortListComponent_0;
function View_TitlesShortListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "titles-short-list", [], null, null, null, View_TitlesShortListComponent_0, RenderType_TitlesShortListComponent)), i1.ɵdid(1, 114688, null, 0, i6.TitlesShortListComponent, [i3.Transition], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_TitlesShortListComponent_Host_0 = View_TitlesShortListComponent_Host_0;
var TitlesShortListComponentNgFactory = i1.ɵccf("titles-short-list", i6.TitlesShortListComponent, View_TitlesShortListComponent_Host_0, { ownershipList: "ownershipList", options: "options" }, {}, []);
exports.TitlesShortListComponentNgFactory = TitlesShortListComponentNgFactory;
