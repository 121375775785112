"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./login.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../../pipes/username.pipe");
var i3 = require("@angular/common");
var i4 = require("./login.component");
var styles_LoginComponent = [i0.styles];
var RenderType_LoginComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoginComponent, data: {} });
exports.RenderType_LoginComponent = RenderType_LoginComponent;
function View_LoginComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["class", "button"], ["target", "_blank"], ["title", "Podpora"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "span", [["class", "fa fa-question"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.servicedeskUrl, ""); _ck(_v, 0, 0, currVal_0); }); }
function View_LoginComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.UserNamePipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "user"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "fa fa-user mr-5"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", " "])), i1.ɵppd(4, 2), (_l()(), i1.ɵeld(5, 0, null, null, 1, "a", [["class", "button logout"], ["title", "Odhl\u00E1sit"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.logout($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "span", [["class", "fa fa-sign-out"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00A0 "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LoginComponent_1)), i1.ɵdid(9, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.servicedeskUrl; _ck(_v, 9, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i1.ɵnov(_v, 0), _co.user, _co.withCompany)); _ck(_v, 3, 0, currVal_0); }); }
exports.View_LoginComponent_0 = View_LoginComponent_0;
function View_LoginComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "login", [], null, null, null, View_LoginComponent_0, RenderType_LoginComponent)), i1.ɵdid(1, 114688, null, 0, i4.LoginComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_LoginComponent_Host_0 = View_LoginComponent_Host_0;
var LoginComponentNgFactory = i1.ɵccf("login", i4.LoginComponent, View_LoginComponent_Host_0, { user: "user", withCompany: "withCompany", servicedeskUrl: "servicedeskUrl" }, { logoutClick: "logoutClick" }, []);
exports.LoginComponentNgFactory = LoginComponentNgFactory;
