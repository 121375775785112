"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var angular_hybrid_1 = require("@uirouter/angular-hybrid");
var project_create_component_1 = require("@app/dimap/settings/components/project-create/project-create.component");
var project_update_component_1 = require("@app/dimap/settings/components/project-update/project-update.component");
var settings_component_1 = require("@app/dimap/settings/components/settings/settings.component");
var user_list_component_1 = require("@app/common/components/user-list/user-list.component");
var user_detail_component_1 = require("@app/common/components/user-detail/user-detail.component");
var state_utils_1 = require("@app/common/utils/state.utils");
var callback_service_1 = require("@app/ps/services/callback.service");
var user_credentials_component_1 = require("@app/common/components/user-credentials/user-credentials.component");
var users_states_1 = require("@app/settings/users/users.states");
var project_settings_states_1 = require("@app/ps/project-settings/project-settings.states");
exports.projectCrateState = {
    name: 'dimap.project.settings.createProject',
    data: {
        title: 'Založení projektu',
    },
    url: '/create-project',
    views: {
        'content@dimap.project': {
            component: project_create_component_1.ProjectCreateComponent,
        }
    }
};
exports.projectUpdateState = {
    name: 'dimap.project.settings.editProject',
    data: {
        title: 'Editace projektu',
    },
    url: '/edit-project',
    views: {
        'content@dimap.project': {
            component: project_update_component_1.ProjectUpdateComponent,
        }
    }
};
exports.settingsState = {
    name: 'dimap.project.settings',
    data: {
        title: 'Nastavení',
    },
    url: '/settings',
    views: {
        'content@dimap.project': {
            component: settings_component_1.SettingsComponent,
        }
    }
};
exports.userListState = {
    name: 'dimap.project.settings.users',
    url: '/users?page',
    data: {
        title: 'Uživatelé',
    },
    views: {
        'content@dimap.project': {
            component: user_list_component_1.UserListComponent,
        },
    },
    params: {
        page: {
            dynamic: true,
            type: 'int',
            inherit: false,
        },
    },
};
exports.userCredentialsState = {
    name: 'dimap.project.settings.changePassword',
    url: '/change-password',
    data: {
        title: 'Změna přihlašovacích údajů',
    },
    views: {
        'content@dimap.project': {
            component: user_credentials_component_1.UserCredentialsComponent,
        }
    },
    params: {
        backSref: {
            type: 'string',
            value: 'dimap.project.settings'
        },
    }
};
exports.userDetailState = {
    name: 'dimap.project.settings.users.detail',
    url: '/:id',
    data: {
        title: 'Detail uživatele',
    },
    views: {
        'content@dimap.project': {
            component: user_detail_component_1.UserDetailComponent,
        }
    },
    resolve: {
        userId: ['$stateParams', state_utils_1.resolveId],
        callbacks: [callback_service_1.CallbackService, state_utils_1.resolveCallbacks],
    },
    params: {
        id: {
            type: 'int',
            value: undefined
        },
    },
};
function resolvePermissionsCallback(callbacks) {
    return callbacks.get('userPermissionsChangedCallback');
}
exports.resolvePermissionsCallback = resolvePermissionsCallback;
exports.updateUserPermissionsState = {
    name: 'dimap.project.settings.users.detail.updateUserPermissions',
    url: '/user-permissions',
    data: {
        title: 'Editace oprávnění uživatele',
    },
    resolve: {
        application: ['$stateParams', '$transition$', project_settings_states_1.resolveApplication],
        projectKey: ['$stateParams', users_states_1.resolveProjectKey],
        user: ['$stateParams', '$transition$', project_settings_states_1.resolveUser],
        userPermissionsChangedCallback: ['callbacks', resolvePermissionsCallback],
    },
    onEnter: [
        '$transition$',
        '$state',
        'userPermissionsChangedCallback',
        'application',
        'projectKey',
        'user',
        users_states_1.handleUserPermissionsModal
    ],
};
var SettingsModule = /** @class */ (function () {
    function SettingsModule() {
    }
    return SettingsModule;
}());
exports.SettingsModule = SettingsModule;
