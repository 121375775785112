"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var TicketStatusEnum;
(function (TicketStatusEnum) {
    TicketStatusEnum["CREATED"] = "CREATED";
    TicketStatusEnum["SENT_TO_CONFIRMATION"] = "SENT_TO_CONFIRMATION";
    TicketStatusEnum["APPROVED"] = "APPROVED";
    TicketStatusEnum["DISAPPROVED"] = "DISAPPROVED";
    TicketStatusEnum["TEMPLATE_SENT_TO_PROCESSING"] = "TEMPLATE_SENT_TO_PROCESSING";
    TicketStatusEnum["IMPORTED"] = "IMPORTED";
    TicketStatusEnum["DELETED"] = "DELETED";
})(TicketStatusEnum = exports.TicketStatusEnum || (exports.TicketStatusEnum = {}));
exports.ticketStatusOptions = [
    {
        id: TicketStatusEnum.CREATED,
        name: 'Nový požadavek',
    },
    {
        id: TicketStatusEnum.SENT_TO_CONFIRMATION,
        name: 'Ke schválení',
        action: '',
    },
    {
        id: TicketStatusEnum.APPROVED,
        name: 'Schváleno',
    },
    {
        id: TicketStatusEnum.DISAPPROVED,
        name: 'Zamítnutí požadavku',
        action: '',
    },
    {
        id: TicketStatusEnum.TEMPLATE_SENT_TO_PROCESSING,
        name: 'Zpracování šablony',
    },
    {
        id: TicketStatusEnum.IMPORTED,
        name: 'Zavedeno do MPV',
    },
    {
        id: TicketStatusEnum.DELETED,
        name: 'Zrušený požadavek',
        action: 'Zrušit požadavek'
    },
];
