"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var EmailFormComponent = /** @class */ (function () {
    function EmailFormComponent(APP_BRAND) {
        this.APP_BRAND = APP_BRAND;
        this.type = 'common';
    }
    EmailFormComponent.prototype.ngOnInit = function () {
        if (this.type === 'welcome' && !this.email.subject && !this.email.body) {
            this.email.subject = this.APP_BRAND.PS.WELCOME_EMAIL.SUBJECT;
            this.email.body = this.APP_BRAND.PS.WELCOME_EMAIL.BODY;
        }
    };
    return EmailFormComponent;
}());
exports.EmailFormComponent = EmailFormComponent;
