"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var auth_service_1 = require("@app/common/services/auth.service");
var opinion_service_1 = require("@app/ps/services/opinion.service");
var angular_1 = require("@uirouter/angular");
var tooltip_parcel_prices_component_1 = require("@app/common/components/tooltip-parcel-prices/tooltip-parcel-prices.component");
var PricingOverviewComponent = /** @class */ (function () {
    function PricingOverviewComponent(helpIds, restangular, stateService, authService, opinionService) {
        this.helpIds = helpIds;
        this.restangular = restangular;
        this.stateService = stateService;
        this.authService = authService;
        this.opinionService = opinionService;
        this.loading = true;
        this.isAllowedToCreateOpinion = this.authService.hasPermission('assignable');
        this.hasExpertOpinionCoefficient = this.authService.getActualProject().showExpertOpinionCoefficient;
        this.tooltipComponent = tooltip_parcel_prices_component_1.TooltipParcelPricesComponent;
        this.permanentOpinions = [];
        this.temporaryOpinions = [];
        this.parcelsWithPermanentOccupation = [];
        this.parcelsWithTemporaryOccupation = [];
        this.buildings = [];
        this.hasAvailableParcelsWithTemporaryOccupation = false;
        this.hasAvailableParcelsWithPermanentOccupation = false;
        this.hasAvailableParcelsWithPermanentOccupationWithoutOpinion = false;
        this.hasAvailableBuildings = false;
    }
    PricingOverviewComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.loadTitle()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.loadOpinions()];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.loadParcelsWithPermanentOccupation()];
                    case 3:
                        _a.sent();
                        return [4 /*yield*/, this.loadParcelsWithTemporaryOccupation()];
                    case 4:
                        _a.sent();
                        return [4 /*yield*/, this.loadBuildings()];
                    case 5:
                        _a.sent();
                        this.hasAvailableParcelsWithTemporaryOccupation = this.parcelsWithTemporaryOccupation.length > 0; //some(p => p.compensationOpinionsShare < 100 || !p.compensationOpinionsShare);
                        this.hasAvailableParcelsWithPermanentOccupationWithoutOpinion = this.parcelsWithPermanentOccupation.some(function (p) { return p.buyoutOpinionsShare < 100 || !p.buyoutOpinionsShare; });
                        this.hasAvailableParcelsWithPermanentOccupation = this.parcelsWithPermanentOccupation.length > 0;
                        this.hasAvailableBuildings = this.buildings
                            .filter(function (b) { return !b.validBuyoutOpinionBuildings; })
                            .some(function (b) { return _this.parcelsWithPermanentOccupation.every(function (p) { return !p.building || p.building.budId !== b.budId; }); });
                        this.loading = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    PricingOverviewComponent.prototype.onUpdateOpinion = function (opinion) {
        var params = { opinionId: opinion.id, occupationType: opinion.occupationType, id: this.titleId };
        switch (opinion.priceType) {
            case 'E':
                this.stateService.go('symap.project.titles.detail.pricing.expertOpinion', params);
                break;
            case 'O':
                this.stateService.go('symap.project.titles.detail.pricing.otherSource', params);
                break;
            case 'P':
                this.stateService.go('symap.project.titles.detail.pricing.preliminary-opinion', params);
                break;
        }
    };
    PricingOverviewComponent.prototype.loadTitle = function () {
        var _this = this;
        return this.restangular
            .one('titles', this.titleId)
            .get({ loadCollections: ['ownerships', 'ownerships.subjects'] })
            .then(function (res) { _this.title = res.plain(); });
    };
    PricingOverviewComponent.prototype.loadOpinions = function () {
        var _this = this;
        return this.opinionService
            .getOpinionsForTitle(this.titleId)
            .then(function (res) {
            _this.permanentOpinions = res.filter(function (opinion) { return opinion.occupationType === 'P'; });
            _this.temporaryOpinions = res.filter(function (opinion) { return opinion.occupationType !== 'P'; });
        });
    };
    PricingOverviewComponent.prototype.loadParcelsWithPermanentOccupation = function () {
        var _this = this;
        return this.opinionService.getParcels({
            filters: {
                titleId: [this.titleId],
                validity: ['valid'],
                permanentOccupationExists: [true],
            },
        }).then(function (res) { return _this.parcelsWithPermanentOccupation = res; });
    };
    PricingOverviewComponent.prototype.loadParcelsWithTemporaryOccupation = function () {
        var _this = this;
        return this.opinionService.getParcels({
            filters: {
                titleId: [this.titleId],
                validity: ['valid'],
                temporaryOccupationExists: [true],
            },
        }).then(function (res) { return _this.parcelsWithTemporaryOccupation = res; });
    };
    PricingOverviewComponent.prototype.loadBuildings = function () {
        var _this = this;
        return this.opinionService.getBuildings({
            filters: {
                titleId: [this.titleId],
                validity: ['valid'],
                occupationOrEasementExists: [true],
                differentTitle: [true],
            },
        }).then(function (res) { return _this.buildings = res; });
    };
    return PricingOverviewComponent;
}());
exports.PricingOverviewComponent = PricingOverviewComponent;
