"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var occupation_model_1 = require("@app/ps/models/occupation.model");
var tooltip_disabled_occupation_component_1 = require("@app/common/components/tooltip-disabled-occupation/tooltip-disabled-occupation.component");
var CellOccupationComponent = /** @class */ (function () {
    function CellOccupationComponent() {
        this.showUpdate = false;
        this.type = 'occupation';
        this.tooltipComponent = tooltip_disabled_occupation_component_1.TooltipDisabledOccupationComponent;
    }
    return CellOccupationComponent;
}());
exports.CellOccupationComponent = CellOccupationComponent;
