"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var error_component_1 = require("@app/error/components/error/error.component");
function resolveError($transition$) {
    return $transition$.params().errorCode;
}
exports.resolveError = resolveError;
function resolveRedirect($transition$) {
    return $transition$.params().redirectUrl;
}
exports.resolveRedirect = resolveRedirect;
resolveError.$inject = ['$transition$'];
resolveRedirect.$inject = ['$transition$'];
exports.errorState = {
    name: 'error',
    url: '/error?errorCode&redirectUrl',
    component: error_component_1.ErrorComponent,
    data: {
        title: 'Error',
    },
    params: {
        errorCode: {
            type: 'int',
        },
        redirectUrl: {
            type: 'string',
        }
    },
    resolve: {
        errorCode: ['$transition$', resolveError],
        redirectUrl: ['$transition$', resolveRedirect]
    }
};
var ErrorModule = /** @class */ (function () {
    function ErrorModule() {
    }
    return ErrorModule;
}());
exports.ErrorModule = ErrorModule;
