"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var NotificationComponent = /** @class */ (function () {
    function NotificationComponent() {
        this.level = 'info';
    }
    NotificationComponent.prototype.ngOnInit = function () { };
    return NotificationComponent;
}());
exports.NotificationComponent = NotificationComponent;
