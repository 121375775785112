"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("../../../../common/components/button/button.component.ngfactory");
var i2 = require("../../../../common/components/button/button.component");
var i3 = require("../form-cadastre-entry-proposal/form-cadastre-entry-proposal.component.ngfactory");
var i4 = require("../form-cadastre-entry-proposal/form-cadastre-entry-proposal.component");
var i5 = require("../../../../common/services/auth.service");
var i6 = require("../../../pipes/acting-person-name.pipe");
var i7 = require("@angular/common");
var i8 = require("@uirouter/angular/lib/directives/uiSref");
var i9 = require("@uirouter/core");
var i10 = require("../../../../common/directives/loading.directive");
var i11 = require("./cadastre-entry-proposal.component");
var styles_CadastreEntryProposalComponent = [];
var RenderType_CadastreEntryProposalComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CadastreEntryProposalComponent, data: {} });
exports.RenderType_CadastreEntryProposalComponent = RenderType_CadastreEntryProposalComponent;
function View_CadastreEntryProposalComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "gmt-button", [], null, null, null, i1.View_ButtonComponent_0, i1.RenderType_ButtonComponent)), i0.ɵdid(2, 114688, null, 0, i2.ButtonComponent, ["ErrorHandlerService", i0.ChangeDetectorRef], { clickAction: [0, "clickAction"], gmtDisabled: [1, "gmtDisabled"] }, null), (_l()(), i0.ɵted(-1, 0, ["Odeslat"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.sendProposal; var currVal_1 = !_co.hasValidProposalCredentials(); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_CadastreEntryProposalComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "form-cadastre-entry-proposal", [], null, [[null, "callbackRegister"], [null, "load"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("callbackRegister" === en)) {
        var pd_0 = (_co.formCallbackRegister($event) !== false);
        ad = (pd_0 && ad);
    } if (("load" === en)) {
        var pd_1 = (_co.onLoad() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_FormCadastreEntryProposalComponent_0, i3.RenderType_FormCadastreEntryProposalComponent)), i0.ɵdid(2, 114688, null, 0, i4.FormCadastreEntryProposalComponent, [i5.AuthService, i6.ActingPersonNamePipe, "Restangular"], { data: [0, "data"] }, { callbackRegister: "callbackRegister", load: "load" }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CadastreEntryProposalComponent_2)), i0.ɵdid(4, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data; _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.loadingFinished && !_co.data.entryProposalLink); _ck(_v, 4, 0, currVal_1); }, null); }
function View_CadastreEntryProposalComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 12, "div", [["class", "detail"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 5, "div", [["class", "mb-10"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 4, "gmt-button", [["uiSref", "^"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 4).go($event.button, $event.ctrlKey, $event.metaKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_ButtonComponent_0, i1.RenderType_ButtonComponent)), i0.ɵdid(3, 114688, null, 0, i2.ButtonComponent, ["ErrorHandlerService", i0.ChangeDetectorRef], null, null), i0.ɵdid(4, 737280, null, 0, i8.UISref, [i9.UIRouter, [2, i8.AnchorUISref], "UIView.PARENT_INJECT"], { state: [0, "state"] }, null), (_l()(), i0.ɵeld(5, 0, null, 0, 0, "i", [["class", "fa fa-chevron-left mr-5"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, 0, [" Zp\u011Bt na p\u0159\u00EDpad"])), (_l()(), i0.ɵeld(7, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Odesl\u00E1n\u00ED n\u00E1vrhu na vklad do webov\u00E9 aplikace \u010CUZK"])), (_l()(), i0.ɵeld(9, 0, null, null, 3, "div", [], [[8, "className", 0]], null, null, null, null)), i0.ɵdid(10, 16384, null, 0, i10.LoadingDirective, [i0.ElementRef], { loading: [0, "loading"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CadastreEntryProposalComponent_1)), i0.ɵdid(12, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 3, 0); var currVal_0 = "^"; _ck(_v, 4, 0, currVal_0); var currVal_2 = !_co.loadingFinished; _ck(_v, 10, 0, currVal_2); var currVal_3 = !_co.loading; _ck(_v, 12, 0, currVal_3); }, function (_ck, _v) { var currVal_1 = i0.ɵnov(_v, 10).elementClass; _ck(_v, 9, 0, currVal_1); }); }
exports.View_CadastreEntryProposalComponent_0 = View_CadastreEntryProposalComponent_0;
function View_CadastreEntryProposalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "cadastre-entry-proposal", [], null, null, null, View_CadastreEntryProposalComponent_0, RenderType_CadastreEntryProposalComponent)), i0.ɵdid(1, 114688, null, 0, i11.CadastreEntryProposalComponent, ["Restangular"], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_CadastreEntryProposalComponent_Host_0 = View_CadastreEntryProposalComponent_Host_0;
var CadastreEntryProposalComponentNgFactory = i0.ɵccf("cadastre-entry-proposal", i11.CadastreEntryProposalComponent, View_CadastreEntryProposalComponent_Host_0, { caseId: "caseId" }, {}, []);
exports.CadastreEntryProposalComponentNgFactory = CadastreEntryProposalComponentNgFactory;
