"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var RoleEnum;
(function (RoleEnum) {
    RoleEnum["MANDATAR"] = "MANDATAR";
    RoleEnum["REFERENT"] = "REFERENT";
    RoleEnum["REFERENT_VEDOUCI"] = "REFERENT_VEDOUCI";
    RoleEnum["MANAZER"] = "MANAZER";
    RoleEnum["MANAZER_REPORT"] = "MANAZER_REPORT";
    RoleEnum["GENERALNI"] = "GENERALNI";
    RoleEnum["DASHBOARD"] = "DASHBOARD";
    RoleEnum["ADMIN"] = "ADMIN";
    RoleEnum["ADMIN_UV"] = "ADMIN_UV";
    RoleEnum["ADMIN_UP"] = "ADMIN_UP";
    RoleEnum["SUPER_ADMIN"] = "SUPER_ADMIN";
    RoleEnum["VZORY_ZADAVATEL"] = "VZORY_ZADAVATEL";
    RoleEnum["VZORY_SCHVALOVATEL"] = "VZORY_SCHVALOVATEL";
    RoleEnum["VZORY_ZPRACOVATEL"] = "VZORY_ZPRACOVATEL";
    RoleEnum["VZORY_NAHLED"] = "VZORY_NAHLED";
    RoleEnum["MANAZER_REPORT_UV"] = "MANAZER_REPORT_UV";
    RoleEnum["MANAZER_REPORT_UP"] = "MANAZER_REPORT_UP";
    RoleEnum["NAHLED"] = "NAHLED";
    RoleEnum["NAHLED_UV"] = "NAHLED_UV";
    RoleEnum["NAHLED_UP"] = "NAHLED_UP";
})(RoleEnum = exports.RoleEnum || (exports.RoleEnum = {}));
exports.roleOptions = [
    {
        id: RoleEnum.MANDATAR,
        name: 'Majetkář'
    },
    {
        id: RoleEnum.REFERENT,
        name: 'Referent'
    },
    {
        id: RoleEnum.REFERENT_VEDOUCI,
        name: 'Referent vedoucí'
    },
    {
        id: RoleEnum.MANAZER,
        name: 'Reporter'
    },
    {
        id: RoleEnum.MANAZER_REPORT,
        name: 'Manažerský report'
    },
    {
        id: RoleEnum.GENERALNI,
        name: 'Generální ředitel'
    },
    {
        id: RoleEnum.DASHBOARD,
        name: 'Přehled MPV old'
    },
    {
        id: RoleEnum.ADMIN,
        name: 'Admin'
    },
    {
        id: RoleEnum.ADMIN_UV,
        name: 'Administrátor Úseku výstavby'
    },
    {
        id: RoleEnum.ADMIN_UP,
        name: 'Administrátor Úseku provozního'
    },
    {
        id: RoleEnum.SUPER_ADMIN,
        name: 'Superadministrátor'
    },
    {
        id: RoleEnum.VZORY_ZADAVATEL,
        name: 'Zadavatel vzoru'
    },
    {
        id: RoleEnum.VZORY_SCHVALOVATEL,
        name: 'Schvalovatel vzoru'
    },
    {
        id: RoleEnum.VZORY_ZPRACOVATEL,
        name: 'Zpracovatel vzoru'
    },
    {
        id: RoleEnum.VZORY_NAHLED,
        name: 'Náhled do modulu vzory'
    },
    {
        id: RoleEnum.MANAZER_REPORT_UV,
        name: 'Manažerský report úseku výstavby'
    },
    {
        id: RoleEnum.MANAZER_REPORT_UP,
        name: 'Manažerský report úseku provozního'
    },
    {
        id: RoleEnum.NAHLED,
        name: 'Náhled'
    },
    {
        id: RoleEnum.NAHLED_UV,
        name: 'Náhled úseku výstavby'
    },
    {
        id: RoleEnum.NAHLED_UP,
        name: 'Náhled úseku provozního'
    },
];
