"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./notification.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/common");
var i3 = require("./notification.component");
var styles_NotificationComponent = [i0.styles];
var RenderType_NotificationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NotificationComponent, data: {} });
exports.RenderType_NotificationComponent = RenderType_NotificationComponent;
function View_NotificationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "fa fa-lg fa-exclamation-triangle"]], null, null, null, null, null))], null, null); }
function View_NotificationComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "fa fa-lg fa-info-circle"]], null, null, null, null, null))], null, null); }
function View_NotificationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "notification__icon"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationComponent_2)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "notification__content"]], null, null, null, null, null)), i1.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.level === "danger"); _ck(_v, 3, 0, currVal_1); var currVal_2 = (_co.level === "warning"); _ck(_v, 5, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "notification notification--", _co.level, ""); _ck(_v, 0, 0, currVal_0); }); }
exports.View_NotificationComponent_0 = View_NotificationComponent_0;
function View_NotificationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "notification", [], null, null, null, View_NotificationComponent_0, RenderType_NotificationComponent)), i1.ɵdid(1, 114688, null, 0, i3.NotificationComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_NotificationComponent_Host_0 = View_NotificationComponent_Host_0;
var NotificationComponentNgFactory = i1.ɵccf("notification", i3.NotificationComponent, View_NotificationComponent_Host_0, { level: "level" }, {}, ["*"]);
exports.NotificationComponentNgFactory = NotificationComponentNgFactory;
