"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var checklist_service_1 = require("@app/common/services/checklist.service");
var pageable_list_service_1 = require("@app/common/services/pageable.list.service");
var settings_service_1 = require("@app/ps/services/settings.service");
var angular_1 = require("@uirouter/angular");
var table_service_1 = require("@app/ps/services/table.service");
var export_list_service_1 = require("@app/ps/services/export-list.service");
var OwnerListComponent = /** @class */ (function () {
    function OwnerListComponent(helpIds, APP_BRAND, pageableListService, checklistService, tableService, settingsService, stateService, exportListService) {
        this.helpIds = helpIds;
        this.APP_BRAND = APP_BRAND;
        this.pageableListService = pageableListService;
        this.checklistService = checklistService;
        this.tableService = tableService;
        this.settingsService = settingsService;
        this.stateService = stateService;
        this.exportListService = exportListService;
        this.bsmOpsubType = { id: 'BSM' };
        this.INVALID_WITH_CASE_FLAG = 'invalidWithCase';
        this.WITH_OCCUPATION_OR_EASEMENT_FLAG = 'withOccupationOrEasement';
        this.WITH_OWNERSHIP_FLAG = 'withOwnership';
        this.VALID_FLAG = 'valid';
        this.settingsLoaded = false;
        this.exportEnable = true;
        this.registerUpdateStatisticsEntityFilterKey = this.registerUpdateStatisticsEntityFilterKey.bind(this);
        this.onExport = this.onExport.bind(this);
    }
    OwnerListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.settingsLoaded = this.settingsService.getSettingsLoader() === null;
        if (!this.settingsLoaded) {
            this.settingsService.getSettingsLoader().then(function () { return _this.settingsLoaded = true; });
        }
        var promise = new Promise(function (resolve, reject) {
            _this.deferredUpdateStatistics = {
                resolve: resolve,
                reject: reject,
            };
        });
        this.deferredUpdateStatistics.promise = promise;
        this.initializeList();
    };
    OwnerListComponent.prototype.uiOnParamsChanged = function (changedParams, $transition$) {
        this.pageableList.uiOnParamsChanged(changedParams, $transition$);
    };
    OwnerListComponent.prototype.registerUpdateStatisticsEntityFilterKey = function (registerCallback) {
        this.deferredUpdateStatistics.resolve(registerCallback.updateEntityFilterKey);
    };
    OwnerListComponent.prototype.onExport = function () {
        var _this = this;
        this.exportEnable = false;
        var filter = __assign({}, this.list.filter);
        filter.filters.loadCollections.push('cases');
        return this.exportListService
            .exportList('subjects', 'owners', filter)
            .then(function () {
            new Promise(function (resolve) { return setTimeout(function () { return resolve(); }, 5000); }).then(function () {
                _this.exportEnable = true;
            });
        });
    };
    OwnerListComponent.prototype.initStatisticsEntityFilter = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.stateService.params.sef) return [3 /*break*/, 2];
                        this.list.filter.offset = 0;
                        this.list.filter.filters = this.getDefaultFilter().filters;
                        this.list.filter.filters.validity.values = [this.VALID_FLAG, this.INVALID_WITH_CASE_FLAG];
                        this.opsubTypes = [];
                        return [4 /*yield*/, this.deferredUpdateStatistics.promise.then(function (updateEntityFilterKey) {
                                updateEntityFilterKey(_this.stateService.params.sef);
                            })];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        if (this.list.filter.filters.statisticsEntityFilterKey &&
                            this.list.filter.filters.statisticsEntityFilterKey.values &&
                            this.list.filter.filters.statisticsEntityFilterKey.values.length) {
                            this.list.filter.filters.validity.values = [this.VALID_FLAG, this.INVALID_WITH_CASE_FLAG];
                        }
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    OwnerListComponent.prototype.statisticsEntityFilterChanged = function (changed) {
        if (!changed.state) {
            this.list.filter.filters = Object.assign({}, this.getDefaultFilter().filters, this.list.filter.filters);
        }
    };
    OwnerListComponent.prototype.onOpsubTypeFilterChanged = function (data) {
        if (data.newValue.length === 0) {
            this.opsubTypeFilterChecklist.empty();
        }
        else {
            this.opsubTypeFilterChecklist.toggleSelection(data.oldValue);
        }
        if (data.newValue.length === 0 || (data.newValue.length === 1 && this.opsubTypeFilterChecklist.isChecked(this.bsmOpsubType))) {
            this.opsubTypeFilterChecklist.toggleSelection(this.bsmOpsubType);
            this.list.filter.filters.opsubType.negation = data.newValue.length === 0;
        }
    };
    OwnerListComponent.prototype.onShowFormerOwnersToggle = function () {
        if (!this.includesFormerOwners()) {
            this.pageableList.list.filter.filters.validity.values.push(this.INVALID_WITH_CASE_FLAG);
        }
        else {
            this.pageableList.list.filter.filters.validity.values.splice(this.getFormerOwnersIndex(), 1);
        }
    };
    OwnerListComponent.prototype.includesFormerOwners = function () {
        return this.getFormerOwnersIndex() > -1;
    };
    OwnerListComponent.prototype.includesNeighbourOwners = function () {
        return this.getNeighbourOwnersIndex() > -1;
    };
    OwnerListComponent.prototype.onShowNeighbourOwnersToggle = function () {
        if (!this.includesNeighbourOwners()) {
            this.pageableList.list.filter.filters.validity.values.push(this.WITH_OWNERSHIP_FLAG);
        }
        else {
            this.pageableList.list.filter.filters.validity.values.splice(this.getNeighbourOwnersIndex(), 1);
        }
    };
    OwnerListComponent.prototype.getDefaultFilter = function () {
        return {
            filters: {
                loadCollections: ['ownerships', 'occupationResolution'],
                searchText: { values: [{ id: '' }] },
                validity: { values: [this.WITH_OCCUPATION_OR_EASEMENT_FLAG, this.WITH_OWNERSHIP_FLAG] },
                caseStatusId: { values: [], negation: false },
                obligationId: { values: [], negation: false },
                areaId: { values: [], negation: false },
                opsubType: { values: [this.bsmOpsubType], negation: true },
                problematic: { values: [] },
            }
        };
    };
    OwnerListComponent.prototype.initializeList = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.pageableList = this.pageableListService.get('subjects', this.getDefaultFilter(), 'ownersFilter');
                        this.list = this.pageableList.list;
                        this.opsubTypes = __spread(this.list.filter.filters.opsubType.values.filter(function (opsubType) { return opsubType.id !== 'BSM'; }));
                        this.opsubTypeFilterChecklist = this.checklistService.get(this.list.filter.filters.opsubType.values);
                        return [4 /*yield*/, this.initStatisticsEntityFilter()];
                    case 1:
                        _a.sent();
                        this.pageableList.load();
                        return [2 /*return*/];
                }
            });
        });
    };
    OwnerListComponent.prototype.getFormerOwnersIndex = function () {
        var _this = this;
        return this.pageableList.list.filter.filters.validity.values.findIndex(function (item) { return (item.id || item) === _this.INVALID_WITH_CASE_FLAG; });
    };
    OwnerListComponent.prototype.getNeighbourOwnersIndex = function () {
        var _this = this;
        return this.pageableList.list.filter.filters.validity.values.findIndex(function (item) { return (item.id || item) === _this.WITH_OWNERSHIP_FLAG; });
    };
    return OwnerListComponent;
}());
exports.OwnerListComponent = OwnerListComponent;
