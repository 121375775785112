"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var CellOpinions = /** @class */ (function () {
    function CellOpinions() {
    }
    CellOpinions.prototype.shouldBeOpinionDisplayed = function (opinion) {
        // keep only opinions without opinions request relation
        if (this.opinionRequests && this.opinionRequests.length && opinion.opinionRequest) {
            return !this.opinionRequests.find(function (or) { return opinion.opinionRequest.id === or.id; });
        }
        return true;
    };
    return CellOpinions;
}());
exports.CellOpinions = CellOpinions;
