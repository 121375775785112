"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var checklist_service_1 = require("@app/common/services/checklist.service");
var dialog_service_1 = require("@app/dialog/dialog.service");
var external_parcel_form_component_1 = require("@app/ps/components/external-parcel-form/external-parcel-form.component");
var class_name_enum_1 = require("@app/dialog/class-name.enum");
var list_service_1 = require("@app/common/services/list.service");
var auth_service_1 = require("@app/common/services/auth.service");
var title_name_pipe_1 = require("@app/common/pipes/title-name.pipe");
var CreateCaseSelectExternalParcelsComponent = /** @class */ (function () {
    function CreateCaseSelectExternalParcelsComponent(restangular, titleNamePipe, checklistService, dialogService, listService, authService) {
        this.restangular = restangular;
        this.titleNamePipe = titleNamePipe;
        this.checklistService = checklistService;
        this.dialogService = dialogService;
        this.listService = listService;
        this.authService = authService;
        this.selected = new core_1.EventEmitter();
        this.PARCEL_COLS = [
            { id: 'parcel', sortable: true },
            { id: 'title', sortable: true },
            { id: 'area_name', sortable: true },
            { id: 'area', sortable: false },
        ];
        this.externalParcelList = this.listService.createList('parcels', {});
        this.externalParcelListAdded = { loading: false, list: [], itemCount: 0 };
        this.checkedAll = false;
        this.checkedAllAdded = false;
        this.titleFilter = {
            subjectId: [],
            areaId: [],
            validity: ['valid'],
        };
        this.parcelFilter = {
            filters: {
                subjectId: [],
                validity: ['valid'],
                areaId: [],
                titleId: [],
                searchText: { values: [{ id: '' }] },
            },
            sortOrder: [{ sortBy: 'katuzeNazev' }, { sortBy: 'lv' }],
            limit: 20,
            offset: 0
        };
        this.isChecked = this.isChecked.bind(this);
        this.isCheckedAdded = this.isCheckedAdded.bind(this);
        this.onAddParcel = this.onAddParcel.bind(this);
        this.onLoadNextParcels = this.onLoadNextParcels.bind(this);
        this.onCadastreChanged = this.onCadastreChanged.bind(this);
        this.onSubmitFilter = this.onSubmitFilter.bind(this);
    }
    CreateCaseSelectExternalParcelsComponent.prototype.ngOnInit = function () {
        this.loadSubject();
        this.checklistExternalParcels = this.checklistService.get();
        this.checklistExternalParcelsAdded = this.checklistService.get();
    };
    CreateCaseSelectExternalParcelsComponent.prototype.isChecked = function (parcel) {
        return this.checklistExternalParcels.isChecked(parcel);
    };
    CreateCaseSelectExternalParcelsComponent.prototype.isCheckedAdded = function (parcel) {
        return this.checklistExternalParcelsAdded.isChecked(parcel);
    };
    CreateCaseSelectExternalParcelsComponent.prototype.onToggleOne = function (parcel) {
        this.checklistExternalParcels.toggleSelection(parcel);
        this.checkedAll = (this.externalParcelList.list.length === this.checklistExternalParcels.checkedItems);
        this.notify();
    };
    CreateCaseSelectExternalParcelsComponent.prototype.onToggleOneAdded = function (parcel) {
        this.checklistExternalParcelsAdded.toggleSelection(parcel);
        this.checkedAllAdded = (this.externalParcelListAdded.list.length === this.checklistExternalParcelsAdded.checkedItems);
        this.notify();
    };
    CreateCaseSelectExternalParcelsComponent.prototype.onToggleAll = function () {
        var _this = this;
        this.checkedAll = !this.checkedAll;
        this.externalParcelList.list.forEach(function (parcel) {
            if (!(_this.checkedAll && _this.checklistExternalParcels.isChecked(parcel))) {
                _this.checklistExternalParcels.toggleSelection(parcel);
            }
        });
        this.notify();
    };
    CreateCaseSelectExternalParcelsComponent.prototype.onToggleAllAdded = function () {
        var _this = this;
        this.checkedAllAdded = !this.checkedAllAdded;
        this.externalParcelListAdded.list.forEach(function (parcel) {
            if (!(_this.checkedAllAdded && _this.checklistExternalParcelsAdded.isChecked(parcel))) {
                _this.checklistExternalParcelsAdded.toggleSelection(parcel);
            }
        });
        this.notify();
    };
    CreateCaseSelectExternalParcelsComponent.prototype.onCadastreChanged = function () {
        this.titleFilter.areaId = this.externalParcelList.filter.filters.areaId;
    };
    CreateCaseSelectExternalParcelsComponent.prototype.onSubmitFilter = function () {
        this.checklistExternalParcels.empty();
        this.externalParcelList.filter.offset = 0;
        return this.listService.fetchResult(this.externalParcelList);
    };
    CreateCaseSelectExternalParcelsComponent.prototype.onLoadNextParcels = function () {
        this.externalParcelList.filter.offset += this.externalParcelList.filter.limit;
        return this.listService.fetchResult(this.externalParcelList, true);
    };
    CreateCaseSelectExternalParcelsComponent.prototype.onAddParcel = function () {
        var _this = this;
        var dialog = this.dialogService.open(external_parcel_form_component_1.ExternalParcelFormComponent, { className: class_name_enum_1.ClassName.HIGHER_DIALOG });
        var subscriber = dialog.afterClosed.subscribe(function (parcel) {
            if (parcel) {
                _this.externalParcelListAdded.list = [parcel].concat(_this.externalParcelListAdded.list);
                _this.externalParcelListAdded.itemCount++;
                _this.checklistExternalParcelsAdded.toggleSelection(parcel);
                _this.notify();
            }
            subscriber.unsubscribe();
        });
    };
    CreateCaseSelectExternalParcelsComponent.prototype.loadSubject = function () {
        var _this = this;
        var project = this.authService.getActualProject();
        if (!project.investor || !project.investor.opsubId) {
            this.externalParcelList.list = [];
            this.externalParcelList.itemCount = 0;
            return;
        }
        this.loading = true;
        return this.restangular.all('subjects')
            .post({ filter: { filters: { opsubId: [project.investor.opsubId], validity: ['valid'] } } })
            .then(function (subjects) {
            var subjectId = subjects[0] ? subjects[0].id : 0;
            _this.parcelFilter.filters.subjectId = [subjectId];
            _this.titleFilter.subjectId = [subjectId];
            _this.externalParcelList.filter = _this.parcelFilter;
            _this.loading = false;
        });
    };
    CreateCaseSelectExternalParcelsComponent.prototype.notify = function () {
        var checked = this.checklistExternalParcels.checkedItems.concat(this.checklistExternalParcelsAdded.checkedItems);
        this.selected.emit(checked);
    };
    return CreateCaseSelectExternalParcelsComponent;
}());
exports.CreateCaseSelectExternalParcelsComponent = CreateCaseSelectExternalParcelsComponent;
