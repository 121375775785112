"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var i0 = require("@angular/core");
var ParcelProtectionPipe = /** @class */ (function () {
    function ParcelProtectionPipe() {
    }
    ParcelProtectionPipe.prototype.transform = function (parcel) {
        switch (parcel.parcelProtectionsText) {
            case 'zemědělský půdní fond':
                return 'ZPF';
            case 'pozemek určený k plnění funkcí lesa':
                return 'PUPFL';
            default:
                return parcel.parcelProtectionsText;
        }
    };
    ParcelProtectionPipe.ngInjectableDef = i0.defineInjectable({ factory: function ParcelProtectionPipe_Factory() { return new ParcelProtectionPipe(); }, token: ParcelProtectionPipe, providedIn: "root" });
    return ParcelProtectionPipe;
}());
exports.ParcelProtectionPipe = ParcelProtectionPipe;
