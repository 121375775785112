"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var auth_service_1 = require("@app/common/services/auth.service");
var HomeComponent = /** @class */ (function () {
    function HomeComponent(helpIds, APP_BRAND, authService) {
        this.helpIds = helpIds;
        this.APP_BRAND = APP_BRAND;
        this.authService = authService;
        this.application = this.authService.getActiveApplication();
        this.user = this.authService.getUser();
    }
    HomeComponent.prototype.ngOnInit = function () {
    };
    return HomeComponent;
}());
exports.HomeComponent = HomeComponent;
