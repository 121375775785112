"use strict";
/**
 * Main map configuration
 */
Object.defineProperty(exports, "__esModule", { value: true });
var _cloneDeep = require("lodash/cloneDeep");
var layers = [
    {
        id: 'main',
        type: 'node',
        title: 'Další mapy',
        visible: true,
        selector: 'group',
        layers: [
            'base_orto',
            'base_zm',
            'sy_base_lpis',
            'parcel_pupfl',
            'parcel_zpf',
            'geom_km',
            'base_additional_project_2',
            'base_additional_project',
            'base_project',
        ]
    },
    {
        id: 'km',
        type: 'node',
        title: 'Katastrální mapy',
        selector: 'group',
        visible: true,
        layers: [
            'base_pk',
            'base_kn',
            'kn_occupation',
            'geom_gp',
            'gp_parcels',
            'geom_ku',
            'kn_latest',
            'kn_zab',
        ]
    },
    {
        id: 'stav-reseni',
        type: 'node',
        title: 'Majetkoprávní stav',
        selector: 'group',
        visible: true,
        layers: [
            'easement_status2',
            'temporary_occupation_over_one_year_status',
            'temporary_occupation_under_one_year_status',
            'permanent_occupation_status',
            'parcel_investor',
            'occupation_problem_status',
            'problematic_owners',
            'occupation_expropriation',
        ]
    },
    {
        id: 'smlouvy-vecna-bremena',
        type: 'node',
        title: 'Případy věcných břemen',
        selector: 'group',
        visible: true,
        layers: [
            'easement_expropriation',
            'easement_status_payment',
            'easement_status_cadastre_entry',
            'easement_status_entry_proposal',
            'easement_status_signed_all_owners_declined_stopped',
            'easement_status_owner_communication',
            'easement_status_declined_owner',
            'easement_status_signed_investor',
            'easement_status_approved_investor',
            'easement_status_sent_investor',
            'easement_status_created_declined',
        ]
    },
    {
        id: 'vecna-bremena',
        type: 'node',
        title: 'Věcná břemena',
        selector: 'group',
        visible: true,
        layers: [
            'easement_geom_land_take',
            'easement_geom_geometric_plan',
            'geom_vbze'
        ]
    },
    {
        id: 'zabory',
        type: 'node',
        title: 'Zábory',
        selector: 'group',
        visible: true,
        layers: [
            'geom_ochrpas',
            'construction_object',
            'occupation_permanent_other',
            'occupation_permanent_investor',
            'occupation_type_10',
            'occupation_type_9',
            'occupation_type_8',
            'occupation_type_7',
            'occupation_type_6',
            'occupation_type_5',
            'occupation_type_4',
            'occupation_type_3',
            'occupation_type_2',
            'occupation_type_1',
        ]
    }
];
function getLayersConfig(project) {
    var config = {
        id: 'main-map',
        tools: ['info', 'measure'],
        layers: layers,
    };
    var layersConfig = _cloneDeep(config);
    return layersConfig;
}
exports.getLayersConfig = getLayersConfig;
