"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var angular_1 = require("@uirouter/angular");
var list_service_1 = require("@app/common/services/list.service");
var table_service_1 = require("@app/ps/services/table.service");
var checklist_service_1 = require("@app/common/services/checklist.service");
var UpdateOccupationParcelComponent = /** @class */ (function () {
    function UpdateOccupationParcelComponent(stateService, listService, tableService, checklistService, restangular) {
        this.stateService = stateService;
        this.listService = listService;
        this.tableService = tableService;
        this.checklistService = checklistService;
        this.restangular = restangular;
        this.goToCase = this.goToCase.bind(this);
        this.update = this.update.bind(this);
        this.isValid = this.isValid.bind(this);
        this.isCaseDisabled = this.isCaseDisabled.bind(this);
        this.caseChecklist = this.checklistService.get();
    }
    UpdateOccupationParcelComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.loadOccupation()];
                    case 1:
                        _a.sent();
                        this.parcelList = this.listService.createList('parcels', {
                            limit: undefined,
                            filters: {
                                geometry: this.occupationObject.geometry,
                                validity: 'valid',
                                loadCollections: [
                                    'title.ownerships',
                                ],
                            },
                            sortOrder: [{ sortBy: 'parcisKmen' }, { sortBy: 'parcisPod' }],
                        });
                        this.caseList = this.listService.createList('cases', {
                            limit: undefined,
                            filters: {
                                occupationId: this.occupationObject.id,
                                loadCollections: [
                                    'titles',
                                    'caseOwnerships',
                                    'caseStatusSubjects',
                                ],
                            },
                            sortOrder: [],
                        });
                        this.listService.fetchResult(this.parcelList);
                        this.listService.fetchResult(this.caseList);
                        return [2 /*return*/];
                }
            });
        });
    };
    UpdateOccupationParcelComponent.prototype.loadOccupation = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.restangular.one('occupations', this.occupationId).get({
                                loadCollections: ['constructionObjects', 'geometry'],
                            })];
                    case 1:
                        _a.occupationObject = _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    UpdateOccupationParcelComponent.prototype.goToCase = function () {
        this.stateService.go('^');
    };
    UpdateOccupationParcelComponent.prototype.onToggleCheckItem = function (parcel) {
        this.selected = parcel;
    };
    UpdateOccupationParcelComponent.prototype.onToggleCheckCaseItem = function (bCase) {
        this.caseChecklist.toggleSelection(bCase);
    };
    UpdateOccupationParcelComponent.prototype.isCaseChecked = function (bCase) {
        return this.caseChecklist.isChecked(bCase);
    };
    UpdateOccupationParcelComponent.prototype.getColumnName = function (tableId, columnId) {
        return this.tableService.getColumnById(tableId, columnId).name;
    };
    UpdateOccupationParcelComponent.prototype.isValid = function () {
        return !!this.selected;
    };
    UpdateOccupationParcelComponent.prototype.isCaseDisabled = function (bCase) {
        return bCase.obligation.computePrice &&
            ![
                'RentalContractShorterThan1YearLiability',
                'RentalContractLongerThan1YearLiability',
                'RentalContractCommonLiability'
            ].includes(bCase.obligation.type);
    };
    UpdateOccupationParcelComponent.prototype.update = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.restangular.one("occupations", this.occupationId).one('update-parcel', this.selected.id).customPUT({
                            caseIds: this.caseChecklist.checkedItems.map(function (bCase) { return bCase.id; }),
                        })];
                    case 1:
                        _a.sent();
                        this.stateService.go('^');
                        return [2 /*return*/];
                }
            });
        });
    };
    return UpdateOccupationParcelComponent;
}());
exports.UpdateOccupationParcelComponent = UpdateOccupationParcelComponent;
