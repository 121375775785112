"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var MapModuleMoveComponent = /** @class */ (function () {
    function MapModuleMoveComponent() {
        this.visibilityInfoboxCallback = new core_1.EventEmitter();
    }
    MapModuleMoveComponent.prototype.ngOnInit = function () {
        this.visibilityInfoboxCallback.emit({ infoboxVisibility: false });
    };
    return MapModuleMoveComponent;
}());
exports.MapModuleMoveComponent = MapModuleMoveComponent;
