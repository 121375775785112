"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var table_service_1 = require("@app/ps/services/table.service");
var settings_service_1 = require("@app/ps/services/settings.service");
var username_pipe_1 = require("@app/common/pipes/username.pipe");
var auth_service_1 = require("@app/common/services/auth.service");
var _ = require("lodash");
var TableParcelsComponent = /** @class */ (function () {
    function TableParcelsComponent(tableService, settingsService, authService, userNamePipe, restangular) {
        this.tableService = tableService;
        this.settingsService = settingsService;
        this.authService = authService;
        this.userNamePipe = userNamePipe;
        this.restangular = restangular;
        this.geometricPlanModuleEnabled = false;
        this.opinionModuleEnabled = false;
        this.showNavIcons = false;
        this.sort = new core_1.EventEmitter();
        this.TABLE_ID = 'parcels';
        this.isArray = Array.isArray;
    }
    TableParcelsComponent.prototype.ngOnInit = function () {
    };
    TableParcelsComponent.prototype.onSort = function (column, ascOrDesc) {
        this.sort.emit({
            column: column,
            ascOrDesc: ascOrDesc,
        });
    };
    TableParcelsComponent.prototype.hideColumn = function (columnId) {
        return this.settingsService.shouldHideColumn(this.TABLE_ID, columnId);
    };
    TableParcelsComponent.prototype.getColumnName = function (columnId) {
        return this.tableService.getColumnById(this.TABLE_ID, columnId).name;
    };
    TableParcelsComponent.prototype.getKnGpsFromParcel = function (parcel) {
        var gps = [];
        if (parcel.knBudouc) {
            gps.push(parcel.knBudouc.knGp);
        }
        if (parcel.knBudoucPepvs && parcel.knBudoucPepvs.length) {
            gps = gps.concat(parcel.knBudoucPepvs.map(function (knbp) { return knbp.knBudouc.knGp; }));
        }
        return _.uniqBy(gps, function (gp) {
            return gp.id;
        });
    };
    TableParcelsComponent.prototype.getOpinionsFromParcel = function (parcel) {
        return parcel.parcelPrices
            .filter(function (pp) { return pp.opinion && pp.opinion.valid === true; })
            .map(function (pp) { return pp.opinion; });
    };
    return TableParcelsComponent;
}());
exports.TableParcelsComponent = TableParcelsComponent;
