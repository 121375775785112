"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var callbacks_enum_1 = require("@app/ps/enums/callbacks.enum");
var callback_model_1 = require("@app/ps/models/callback.model");
var common_address_model_1 = require("@app/ps/models/common-address.model");
var subject_model_1 = require("@app/common/models/subject.model");
var subject_service_1 = require("@app/ps/services/subject.service");
var AboutComponent = /** @class */ (function () {
    function AboutComponent(restangular, callbacks, subjectService) {
        this.restangular = restangular;
        this.callbacks = callbacks;
        this.subjectService = subjectService;
        this.actualizeAll = false;
    }
    AboutComponent.prototype.ngOnInit = function () {
        this.address = this.normalizeAddressFromSubject();
        this.mailingAddress = this.normalizeMailingAddressFromSubject();
        this.contactPerson = this.normalizeContactPerson();
        this.basicInfo = this.normalizeBasicInfo();
        if (!this.owner.subjectDetail) {
            this.owner.subjectDetail = {};
        }
        this.onSubmit = this.onSubmit.bind(this);
    };
    AboutComponent.prototype.onToggleMailingAddress = function (addressUsed) {
        this.owner.subjectDetail.dorucovaciUse = addressUsed;
    };
    AboutComponent.prototype.onToggleAddress = function (addressUsed) {
        this.owner.subjectDetail.customAddressUse = addressUsed;
        if (!common_address_model_1.CommonAddressModel.isAddressFiled(this.address)) {
            this.address.street = this.owner.ulice;
            this.address.houseNumber = this.owner.cisloDomovni;
            this.address.commonNumber = this.owner.cisloOrientacni;
            this.address.neighborhood = this.owner.castObce;
            this.address.cityDistrict = this.owner.mestskyObvod;
            this.address.city = this.owner.obec;
            this.address.county = this.owner.okres;
            this.address.postalCode = this.owner.psc;
            this.address.quarter = this.owner.mestskaCast;
            this.address.country = this.owner.stat;
        }
    };
    AboutComponent.prototype.onSubmit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var updatedSubject, plainUpdatedSubject;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.assembleOwner();
                        return [4 /*yield*/, this.restangular.one("subjects/" + this.owner.id + "/detail").customPUT(this.owner.subjectDetail)];
                    case 1:
                        updatedSubject = _a.sent();
                        if (!this.actualizeAll) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.restangular.one("subjects/" + this.owner.id + "/update-cases-subjects-by-subject-detail").post()];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3:
                        plainUpdatedSubject = this.restangular.stripRestangular(updatedSubject);
                        this.callbacks.get(callbacks_enum_1.Callbacks.SubjectUpdated)(plainUpdatedSubject);
                        return [2 /*return*/];
                }
            });
        });
    };
    AboutComponent.prototype.normalizeBasicInfo = function () {
        return this.subjectService.getAdaptedBasicInfo(this.owner);
    };
    AboutComponent.prototype.denormalizeBasicInfo = function () {
        return this.subjectService.getSubjectCompatibleBasicInfo(this.basicInfo);
    };
    AboutComponent.prototype.normalizeContactPerson = function () {
        return this.subjectService.getAdaptedContactPerson(this.owner.subjectDetail);
    };
    AboutComponent.prototype.denormalizeContactPerson = function () {
        return this.subjectService.getSubjectCompatibleContactPerson(this.contactPerson);
    };
    AboutComponent.prototype.normalizeAddressFromSubject = function () {
        return this.subjectService.getAdaptedAddress(this.owner.subjectDetail);
    };
    AboutComponent.prototype.normalizeMailingAddressFromSubject = function () {
        return this.subjectService.getAdaptedMailingAddress(this.owner.subjectDetail);
    };
    AboutComponent.prototype.denormalizeMailingAddress = function () {
        return this.subjectService.getSubjectCompatibleMailingAddress(this.mailingAddress);
    };
    AboutComponent.prototype.denormalizeSubjectAddress = function () {
        return this.subjectService.getSubjectCompatibleAddress(this.address);
    };
    AboutComponent.prototype.assembleOwner = function () {
        Object.assign(this.owner.subjectDetail, this.denormalizeMailingAddress());
        Object.assign(this.owner.subjectDetail, this.denormalizeSubjectAddress());
        Object.assign(this.owner.subjectDetail, this.denormalizeContactPerson());
        Object.assign(this.owner.subjectDetail, this.denormalizeBasicInfo());
        if (!this.owner.subjectDetail.customAddressUse) {
            this.owner.subjectDetail.customAddressUse = false;
        }
        if (this.owner.subjectDetail.identificationNumber === '') {
            this.owner.subjectDetail.identificationNumber = undefined;
        }
    };
    return AboutComponent;
}());
exports.AboutComponent = AboutComponent;
