
var common = angular.module('common');

/**
 * Service that produces dimensions of scrollable div (or window object) that is ancestor to selected element
 */
common.service('WindowDimensionService', ['$window', function ($window) {
    return function (element, paddings) {
        if (typeof paddings !== 'object' && typeof paddings !== 'number') {
            paddings = 0;
        }
        paddings = {
            top: paddings === 'object' ? paddings.top : paddings,
            right: paddings === 'object' ? paddings.right : paddings,
            bottom: paddings === 'object' ? paddings.bottom : paddings,
            left: paddings === 'object' ? paddings.left : paddings
        };

        var scrollableContent = angular.element(element).closest('div.scrollable');
        //height
        var scrollableHeightContent = scrollableContent.length ? scrollableContent : angular.element(element).closest('#content-wrap');
        //width
        var scrollableWidthContent = scrollableContent.length ? scrollableContent : angular.element(element).closest('#content');

        var windowHeight = 0;
        var windowWidth = 0;
        var windowOffsetTop = 0;
        var windowOffsetLeft = 0;

        // offset is used for modal window to adjust to its position
        // scroll top is used to adjust position outside modal window
        if (scrollableHeightContent.length && scrollableWidthContent.length) {
          windowHeight = scrollableHeightContent.height() - paddings.top - paddings.bottom;
          windowWidth = scrollableWidthContent.width() - paddings.left - paddings.right;
          windowOffsetTop = scrollableHeightContent.offset().top + ((scrollableHeightContent.innerHeight() - scrollableHeightContent.height()) / 2) + paddings.top;
          windowOffsetLeft = scrollableWidthContent.offset().left + ((scrollableWidthContent.innerWidth() - scrollableWidthContent.width()) / 2) + paddings.left;
        } else {
          var window = angular.element($window);
          windowHeight = window.height() - paddings.top - paddings.bottom;
          windowWidth = window.width() - paddings.left - paddings.right;
          windowOffsetTop = window.scrollTop() + paddings.top;
          windowOffsetLeft = window.scrollLeft() + paddings.left;
        }

        var returnVal = {
            windowHeight: windowHeight,
            windowWidth: windowWidth,
            windowOffsetTop: windowOffsetTop,
            windowOffsetLeft: windowOffsetLeft
        };

        return returnVal;
    };
}]);
