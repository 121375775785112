"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ProjectModel = /** @class */ (function () {
    function ProjectModel() {
    }
    ProjectModel.createFromAPI = function (data) {
        var unit = (data.key ? 'případů' : 'parcel');
        return {
            name: data.name,
            management: data.management,
            isprofond: data.isprofond,
            activity: data.activity,
            key: data.key,
            permanentTrend: {
                done: data.tzDone,
                total: data.tzTotal,
                doneBefore: data.tzDoneBefore,
                totalBefore: data.tzTotalBefore,
                unit: unit
            },
            temporaryOverYearTrend: {
                done: data.dznrDone,
                total: data.dznrTotal,
                doneBefore: data.dznrDoneBefore,
                totalBefore: data.dznrTotalBefore,
                unit: unit
            },
            temporaryUnderYearTrend: {
                done: data.dzdrDone,
                total: data.dzdrTotal,
                doneBefore: data.dzdrDoneBefore,
                totalBefore: data.dzdrTotalBefore,
                unit: unit
            },
            easementTrend: {
                done: data.vbDone,
                total: data.vbTotal,
                doneBefore: data.vbDoneBefore,
                totalBefore: data.vbTotalBefore,
                unit: unit
            },
            totalTrend: {
                done: data.done,
                total: data.total,
                doneBefore: data.doneBefore,
                totalBefore: data.totalBefore,
                unit: unit
            },
        };
    };
    return ProjectModel;
}());
exports.ProjectModel = ProjectModel;
