"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var series_model_1 = require("@app/ps/models/series.model");
var _ = require("lodash");
var SeriesListComponent = /** @class */ (function () {
    function SeriesListComponent() {
        this.seriesList = [];
        this.withConstructionObjectSelection = true;
    }
    SeriesListComponent.prototype.ngOnInit = function () {
        this.onCancelSeries = this.onCancelSeries.bind(this);
        this.onRestoreSeries = this.onRestoreSeries.bind(this);
        this.onMoveSeries = this.onMoveSeries.bind(this);
        this.onChangeSeries = this.onChangeSeries.bind(this);
        this.onAddSeries = this.onAddSeries.bind(this);
    };
    SeriesListComponent.prototype.onCancelSeries = function (series) {
        if (series.series.id) {
            series.series.cancelled = true;
        }
        else {
            _.remove(this.seriesList, series);
        }
    };
    SeriesListComponent.prototype.onRestoreSeries = function (series) {
        series.series.cancelled = false;
    };
    SeriesListComponent.prototype.onMoveSeries = function (series, step) {
        var i = _.indexOf(this.seriesList, series);
        if (i + step < 0 || i + step >= this.seriesList.length) {
            return;
        }
        this.seriesList[i] = this.seriesList.splice(i + step, 1, this.seriesList[i])[0];
    };
    SeriesListComponent.prototype.onChangeSeries = function (series) {
        var i = _.indexOf(this.seriesList, series);
        this.seriesList[i] = series;
    };
    SeriesListComponent.prototype.onAddSeries = function () {
        this.seriesList.push(series_model_1.SeriesModel.createDefault());
    };
    return SeriesListComponent;
}());
exports.SeriesListComponent = SeriesListComponent;
