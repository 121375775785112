"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var pageable_list_service_1 = require("@app/common/services/pageable.list.service");
var auth_service_1 = require("@app/common/services/auth.service");
var ticket_status_enum_1 = require("@app/sv/enums/ticket-status.enum");
var dialog_service_1 = require("@app/dialog/dialog.service");
var alert_component_1 = require("@app/dialog/alert/alert.component");
var class_name_enum_1 = require("@app/dialog/class-name.enum");
var file_saver_1 = require("file-saver");
var SampleListComponent = /** @class */ (function () {
    function SampleListComponent(helpIds, restangularSV, applications, pageableListService, authService, dialogService) {
        this.helpIds = helpIds;
        this.restangularSV = restangularSV;
        this.applications = applications;
        this.pageableListService = pageableListService;
        this.authService = authService;
        this.dialogService = dialogService;
        this.hasUserAccessToSampleApp = this.authService.isAuthenticatedApplication(this.applications.sv.name);
    }
    SampleListComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var investor;
            return __generator(this, function (_a) {
                investor = this.authService.getActualProject().investor;
                if (!investor || !investor.customerOrganizationalUnit) {
                    this.isOrganizationalUnitSet = false;
                    return [2 /*return*/];
                }
                this.isOrganizationalUnitSet = true;
                this.defaultFilter = { filters: {
                        organizationalUnitId: investor.customerOrganizationalUnit.id,
                        exceptionOrProjectKey: this.authService.getActualProject().key,
                    } };
                this.initializeList();
                return [2 /*return*/];
            });
        });
    };
    SampleListComponent.prototype.uiOnParamsChanged = function (changedParams, $transition$) {
        this.pageableList.uiOnParamsChanged(changedParams, $transition$);
    };
    SampleListComponent.prototype.initializeList = function () {
        this.pageableList = this.pageableListService.get('samples', this.defaultFilter, undefined, this.restangularSV, {
            actualizationTickets: { load: true },
            documentType: {},
            disabled: { projectKey: this.authService.getActualProject().key },
        });
        this.list = this.pageableList.list;
        this.pageableList.load();
    };
    SampleListComponent.prototype.getActualizationTicket = function (sample) {
        if (!sample.actualizationTickets) {
            return undefined;
        }
        return sample.actualizationTickets.find(function (t) { return t.status !== ticket_status_enum_1.TicketStatusEnum.DELETED && t.status !== ticket_status_enum_1.TicketStatusEnum.DISAPPROVED; });
    };
    SampleListComponent.prototype.downloadSampleFile = function (sample) {
        return this.restangularSV
            .one("samples/" + sample.id + "/sample").withHttpConfig({
            responseType: 'blob',
            transformResponse: function (response, header) {
                return [response, header];
            }
        }).get().then(function (response) {
            var header = response[1]("content-disposition");
            var filename = sample.number + '.docx';
            if (header) {
                var matches = new RegExp("filename\\*?=(?:[^']*'')?([^;]*)").exec(header);
                if (matches.length) {
                    filename = matches[1];
                }
            }
            file_saver_1.saveAs(response[0], filename, true);
        });
    };
    SampleListComponent.prototype.onEnableToggle = function (sample) {
        var _this = this;
        if (sample.disabled === null) {
            return;
        }
        var result = !sample.disabled;
        sample.disabled = null;
        return this.restangularSV
            .one('samples', sample.id)
            .one('projects', this.authService.getActualProject().key)
            .all('disabled')
            .post({ disabled: result })
            .then(function () {
            sample.disabled = result;
            _this.dialogService.open(alert_component_1.AlertComponent, {
                className: class_name_enum_1.ClassName.ADJUSTED_DIALOG,
                data: {
                    msg: result ?
                        'Vzor nebude k dispozici pro generování dokumentů.' :
                        'Zvolený vzor je nyní k dispozici pro generování dokumentů.',
                },
            });
        });
    };
    return SampleListComponent;
}());
exports.SampleListComponent = SampleListComponent;
