"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var parcel_model_1 = require("@app/common/models/parcel.model");
var parcel_number_pipe_1 = require("@app/common/pipes/parcel-number.pipe");
var common_type_model_1 = require("@app/common/models/common-type.model");
var ParcelEditFormComponent = /** @class */ (function () {
    function ParcelEditFormComponent(parcelNumberPipe) {
        this.parcelNumberPipe = parcelNumberPipe;
        this.PARCEL_TYPES = common_type_model_1.parcelTypes;
        this.PARCEL_USAGE_TYPES = common_type_model_1.parcelUsageTypes;
        this.onParcelChange = this.onParcelChange.bind(this);
    }
    ParcelEditFormComponent.prototype.ngOnInit = function () {
        this.parcelNumber = this.parcelNumberPipe.transform(this.parcel, 'simple');
    };
    ParcelEditFormComponent.prototype.onParcelChange = function () {
        var parcelByNumber = this.parcelNumberPipe.parse(this.parcelNumber);
        if (parcelByNumber) {
            this.parcel.parcisKmen = parcelByNumber.parcisKmen;
            this.parcel.parcisPod = parcelByNumber.parcisPod;
            this.parcel.parcisDil = parcelByNumber.parcisDil;
        }
        else {
            this.parcel.parcisKmen = undefined;
            this.parcel.parcisPod = undefined;
            this.parcel.parcisDil = undefined;
        }
        if (this.parcelArea) {
            this.parcel.katuzeNazev = this.parcelArea.name;
            this.parcel.katuzeKod = this.parcelArea.id;
        }
        if (this.parcelType) {
            this.parcel.drupozKod = this.parcelType.id;
            this.parcel.drupozNazev = this.parcelType.name;
        }
        else {
            this.parcel.drupozKod = undefined;
            this.parcel.drupozNazev = undefined;
        }
        if (this.usageType) {
            this.parcel.zpusobVyuziti = this.usageType.name;
        }
        else {
            this.parcel.zpusobVyuziti = undefined;
        }
    };
    return ParcelEditFormComponent;
}());
exports.ParcelEditFormComponent = ParcelEditFormComponent;
