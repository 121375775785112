"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var auth_service_1 = require("@app/common/services/auth.service");
var list_service_1 = require("@app/common/services/list.service");
var _ = require("lodash");
var i0 = require("@angular/core");
var i1 = require("../../common/services/list.service");
var i2 = require("../../common/services/auth.service");
var CaseStatusService = /** @class */ (function () {
    function CaseStatusService(restangular, EVENTS, listService, authService) {
        this.restangular = restangular;
        this.EVENTS = EVENTS;
        this.listService = listService;
        this.authService = authService;
        this.loadStatuses = this.loadStatuses.bind(this);
        this.loadStatusName = this.loadStatusName.bind(this);
        this.saveStatuses = this.saveStatuses.bind(this);
        this.onAuthProjectUpdated = this.onAuthProjectUpdated.bind(this);
        this.authService.registerCallback(EVENTS.authProjectUpdated, this.onAuthProjectUpdated);
    }
    CaseStatusService.prototype.loadStatuses = function () {
        var _this = this;
        if (this.statuses) {
            return Promise.resolve(this.statuses);
        }
        if (this.list) {
            return this.listPromise.then(function () { return _this.statuses; });
        }
        this.list = this.listService.createList('case-statuses', { limit: null });
        this.listPromise = this.listService.fetchResult(this.list).then(function () {
            _this.statuses = _this.list.list;
            _this.listPromise = _this.list = undefined;
            return _this.statuses;
        });
        return this.listPromise;
    };
    CaseStatusService.prototype.loadStatusName = function (caseStatus) {
        var _this = this;
        return this.loadStatuses().then(function () {
            return _.find(_this.statuses, { key: caseStatus });
        });
    };
    CaseStatusService.prototype.saveStatuses = function (statuses) {
        if (statuses) {
            return this.restangular.all('case-statuses').customPUT(statuses);
        }
    };
    CaseStatusService.prototype.onAuthProjectUpdated = function () {
        if (this.list) {
            this.listService.cancelFetch(this.list);
            this.listPromise = this.list = undefined;
        }
        if (this.statuses) {
            this.statuses = undefined;
        }
    };
    CaseStatusService.ngInjectableDef = i0.defineInjectable({ factory: function CaseStatusService_Factory() { return new CaseStatusService(i0.inject("Restangular"), i0.inject("EVENTS"), i0.inject(i1.ListService), i0.inject(i2.AuthService)); }, token: CaseStatusService, providedIn: "root" });
    return CaseStatusService;
}());
exports.CaseStatusService = CaseStatusService;
