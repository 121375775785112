"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var dialog_config_1 = require("@app/dialog/dialog-config");
var dialog_ref_1 = require("@app/dialog/dialog-ref");
var expert_model_1 = require("@app/ps/models/expert.model");
var ExpertEditFormComponent = /** @class */ (function () {
    function ExpertEditFormComponent(restangular, dialogConfig, dialogComponent) {
        this.restangular = restangular;
        this.dialogConfig = dialogConfig;
        this.dialogComponent = dialogComponent;
        this.onSave = this.onSave.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }
    ExpertEditFormComponent.prototype.ngOnInit = function () {
        var incomingExpert = this.dialogConfig.data.expert;
        this.expert = incomingExpert
            ? incomingExpert
            : __assign({}, expert_model_1.expertModelDefault);
    };
    ExpertEditFormComponent.prototype.isValid = function () {
        return this.expert.prijmeni;
    };
    ExpertEditFormComponent.prototype.onSave = function () {
        var _this = this;
        if (this.expert.id) {
            this.restangular.one('experts', this.expert.id).customPUT(this.expert).then(function (expert) {
                _this.dialogComponent.close(expert.plain());
            });
        }
        else {
            this.restangular.all('experts/create').customPOST(this.expert).then(function (expert) {
                _this.dialogComponent.close(expert.plain());
            });
        }
    };
    ExpertEditFormComponent.prototype.onCancel = function () {
        this.dialogComponent.close(null);
    };
    return ExpertEditFormComponent;
}());
exports.ExpertEditFormComponent = ExpertEditFormComponent;
