"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".user[_ngcontent-%COMP%] {\n  float: right;\n  line-height: 40px;\n  margin-left: 20px; }\n  .user[_ngcontent-%COMP%]   a.logout[_ngcontent-%COMP%] {\n    margin-left: 15px; }"];
exports.styles = styles;
