"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var common_1 = require("@angular/common");
var cs_1 = require("@angular/common/locales/cs");
var angular_hybrid_1 = require("@uirouter/angular-hybrid");
var main_component_1 = require("./components/main/main.component");
var projects_component_1 = require("@app/dashboard/components/projects/projects.component");
common_1.registerLocaleData(cs_1.default, 'cs');
function authCallback(authService, applications, $transition$) {
    return authService.checkProjectStatus($transition$, applications.dashboard.name, null, null, 'error').then(function () {
    }, function (newState) {
        return newState;
    });
}
exports.authCallback = authCallback;
exports.dashboardState = {
    name: 'dashboard',
    url: '/dashboard',
    abstract: true,
    component: main_component_1.MainComponent,
    onEnter: ['AuthService', 'APPLICATIONS', '$transition$', authCallback],
};
exports.projectsState = {
    name: 'dashboard.projects',
    url: '/projects',
    data: {
        title: 'dashboard.name',
    },
    views: {
        'content@dashboard': {
            component: projects_component_1.ProjectsComponent,
        }
    },
};
var DashboardAppModule = /** @class */ (function () {
    function DashboardAppModule() {
    }
    return DashboardAppModule;
}());
exports.DashboardAppModule = DashboardAppModule;
