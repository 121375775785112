"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var cases_states_1 = require("./cases.states");
var CASES_STATES = [
    cases_states_1.casesState,
    cases_states_1.caseDetailState,
    cases_states_1.contractsRegisterState,
    cases_states_1.updateCaseState,
    cases_states_1.updateCaseOccupation,
    cases_states_1.updateCaseEasement,
    cases_states_1.cadastreEntryProposalState,
    cases_states_1.updateOccupationParcelState,
    cases_states_1.meetingRequestState,
    cases_states_1.acceptedGeometricalPlanPossibleState,
    cases_states_1.expropriationInterimDecisionRequestState,
    cases_states_1.expropriationInterimDecisionState,
    cases_states_1.expropriationAdministrativeAppealState,
    cases_states_1.expropriationAdministrativeAppealRejectedState,
    cases_states_1.expropriationAdministrativeAppealAcceptedState,
    cases_states_1.expropriationCompensationPaymentState,
    cases_states_1.expropriationEnforceableState,
    cases_states_1.expropriationAppealState,
    cases_states_1.expropriationHearingRepetitionState,
    cases_states_1.expropriationProceedingInteruptionExtendedState,
    cases_states_1.expropriationProceedingInteruptedState,
    cases_states_1.expropriationAssessmentInteruptedState,
    cases_states_1.expropriationCalledHearingState,
    cases_states_1.expropriationStopState,
    cases_states_1.expropriationProceedingStartedState,
    cases_states_1.handOverInvestorState,
    cases_states_1.paymentInstructionState,
    cases_states_1.paymentOrderState,
    cases_states_1.sendAdministratorState,
    cases_states_1.sendInvestorState,
    cases_states_1.expropriationProposalState,
    cases_states_1.signAdministratorState,
    cases_states_1.signInvestorState,
    cases_states_1.sendOwnerState,
    cases_states_1.signOwnerState,
    cases_states_1.receiveOwnerState,
    cases_states_1.confirmState,
    cases_states_1.cadastreEntryState,
    cases_states_1.expropriationResultState,
    cases_states_1.expropriationAppealResultState,
    cases_states_1.auctionResultState,
    cases_states_1.entryProposalState,
    cases_states_1.paymentCheckPreliminaryState,
    cases_states_1.paymentCheckState,
    cases_states_1.generateState,
    cases_states_1.generateFinancialControlState,
    cases_states_1.undeliveredOwnerState,
    cases_states_1.declinedOwnerState,
    cases_states_1.personalVisitState,
    cases_states_1.signedSentOwnersState,
    cases_states_1.updateCaseOwnershipState,
    cases_states_1.callBeforeExpropriationState,
    cases_states_1.callBeforeExpropriationReceivedState,
    cases_states_1.expropriationCaseInProgressState,
    cases_states_1.dunningLetterState,
];
var ɵ0 = function ($injector) { return $injector.get('CaseService'); };
exports.ɵ0 = ɵ0;
var CasesModule = /** @class */ (function () {
    function CasesModule() {
    }
    return CasesModule;
}());
exports.CasesModule = CasesModule;
