"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var home_component_1 = require("@app/ps/home/components/home/home.component");
exports.homeState = {
    name: 'symap.project.home',
    url: '/home?statisticsEntityTab&statisticsStatistics',
    data: {
        title: 'Přehled',
    },
    params: {
        statisticsEntityTab: { dynamic: true },
        statisticsStatistics: { dynamic: true, type: 'int' },
    },
    views: {
        'content@symap.project': {
            component: home_component_1.HomeComponent,
        },
    },
};
var HomeModule = /** @class */ (function () {
    function HomeModule() {
    }
    return HomeModule;
}());
exports.HomeModule = HomeModule;
