"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("./bulk-operation-confirm.component");
var styles_BulkOperationConfirmComponent = [];
var RenderType_BulkOperationConfirmComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_BulkOperationConfirmComponent, data: {} });
exports.RenderType_BulkOperationConfirmComponent = RenderType_BulkOperationConfirmComponent;
function View_BulkOperationConfirmComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(0, null, null, 0))], null, null); }
function View_BulkOperationConfirmComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { confirmViewContainerRef: 0 }), (_l()(), i0.ɵand(16777216, [[1, 3], ["operationConfirm", 2]], null, 0, null, View_BulkOperationConfirmComponent_1))], null, null); }
exports.View_BulkOperationConfirmComponent_0 = View_BulkOperationConfirmComponent_0;
function View_BulkOperationConfirmComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_BulkOperationConfirmComponent_0, RenderType_BulkOperationConfirmComponent)), i0.ɵdid(1, 114688, null, 0, i1.BulkOperationConfirmComponent, [i0.ComponentFactoryResolver], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_BulkOperationConfirmComponent_Host_0 = View_BulkOperationConfirmComponent_Host_0;
var BulkOperationConfirmComponentNgFactory = i0.ɵccf("ng-component", i1.BulkOperationConfirmComponent, View_BulkOperationConfirmComponent_Host_0, {}, {}, []);
exports.BulkOperationConfirmComponentNgFactory = BulkOperationConfirmComponentNgFactory;
