"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var auth_service_1 = require("../../../common/services/auth.service");
var callbacks_enum_1 = require("@app/ps/enums/callbacks.enum");
var case_service_1 = require("@app/ps/services/case.service");
var CasesGenerateDocumentsConfirmComponent = /** @class */ (function () {
    function CasesGenerateDocumentsConfirmComponent(authService, restangular, caseService) {
        this.authService = authService;
        this.restangular = restangular;
        this.caseService = caseService;
        this.processed = 0;
        this.saving = false;
        this.interrupt = false;
        this.proceed = this.proceed.bind(this);
    }
    CasesGenerateDocumentsConfirmComponent.prototype.ngOnDestroy = function () {
        this.interrupt = true;
    };
    CasesGenerateDocumentsConfirmComponent.prototype.proceed = function () {
        return __awaiter(this, void 0, void 0, function () {
            var e_1, _a, attachmentIds, cases, _b, _c, _d, i, item, e_1_1;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        this.saving = true;
                        attachmentIds = [];
                        cases = this.data.details.group.cases;
                        _e.label = 1;
                    case 1:
                        _e.trys.push([1, 6, 7, 8]);
                        _b = __values(cases.entries()), _c = _b.next();
                        _e.label = 2;
                    case 2:
                        if (!!_c.done) return [3 /*break*/, 5];
                        _d = __read(_c.value, 2), i = _d[0], item = _d[1];
                        if (this.interrupt) {
                            return [2 /*return*/, false];
                        }
                        return [4 /*yield*/, this.generate(item)];
                    case 3:
                        (_e.sent()).forEach(function (attachment) { return attachmentIds.push(attachment.id); });
                        this.processed++;
                        _e.label = 4;
                    case 4:
                        _c = _b.next();
                        return [3 /*break*/, 2];
                    case 5: return [3 /*break*/, 8];
                    case 6:
                        e_1_1 = _e.sent();
                        e_1 = { error: e_1_1 };
                        return [3 /*break*/, 8];
                    case 7:
                        try {
                            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                        }
                        finally { if (e_1) throw e_1.error; }
                        return [7 /*endfinally*/];
                    case 8:
                        ;
                        this.notifyParent(attachmentIds);
                        return [2 /*return*/];
                }
            });
        });
    };
    CasesGenerateDocumentsConfirmComponent.prototype.generate = function (caseData) {
        return __awaiter(this, void 0, void 0, function () {
            var generateDocuments;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.data.details.group.allowOwner) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.getCase(caseData.id)];
                    case 1:
                        caseData = _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        caseData.dataRecipients = [null];
                        _a.label = 3;
                    case 3:
                        generateDocuments = [];
                        caseData.dataRecipients.forEach(function (recipient) {
                            generateDocuments.push({
                                name: _this.getDocumentName(caseData, recipient),
                                recipientOpsubId: recipient ? recipient.id : null,
                                id: _this.data.details.group.template.id,
                            });
                        });
                        return [2 /*return*/, this.restangular
                                .one('cases', caseData.id)
                                .all('generate')
                                .customPOST(generateDocuments)];
                }
            });
        });
    };
    ;
    CasesGenerateDocumentsConfirmComponent.prototype.getCase = function (caseId) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.restangular
                            .one('cases', caseId).get({
                            loadCollections: [
                                'caseSubjects',
                                'caseOwnerships',
                                'titles',
                            ],
                        }).then(function (bCase) {
                            var dataCaseOwnerships = _this.caseService.getDataCaseOwnerships(bCase.caseOwnerships, bCase.caseSubjects);
                            bCase.dataRecipients = _this.caseService.getRecipients(dataCaseOwnerships, true);
                            return bCase;
                        })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    CasesGenerateDocumentsConfirmComponent.prototype.getDocumentName = function (caseData, recipient) {
        var e_2, _a;
        var name = '';
        try {
            for (var _b = __values(this.data.details.filename), _c = _b.next(); !_c.done; _c = _b.next()) {
                var section = _c.value;
                if (section.show) {
                    switch (section.type) {
                        case 'text':
                            name += section.text;
                            break;
                        case 'caseNumber':
                            name += caseData.mpp.number;
                            break;
                        case 'titleNumber':
                            name += caseData.titles.map(function (title) { return title.lv; }).join(',');
                            break;
                        case 'owner':
                            name += recipient.name;
                            break;
                    }
                }
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_2) throw e_2.error; }
        }
        return name;
    };
    CasesGenerateDocumentsConfirmComponent.prototype.notifyParent = function (attachmentIds) {
        this.callbacks.get(callbacks_enum_1.Callbacks.DataValidityChanged)({
            isValid: function () { return false; },
            data: {
                attachmentIds: attachmentIds,
            },
        });
    };
    return CasesGenerateDocumentsConfirmComponent;
}());
exports.CasesGenerateDocumentsConfirmComponent = CasesGenerateDocumentsConfirmComponent;
