"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./wanted-switch.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/common");
var i3 = require("../../../common/directives/auth.directive");
var i4 = require("../../../common/services/auth.service");
var i5 = require("../../../common/directives/auth-not.directive");
var i6 = require("./wanted-switch.component");
var i7 = require("../../../common/pipes/username.pipe");
var styles_WantedSwitchComponent = [i0.styles];
var RenderType_WantedSwitchComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_WantedSwitchComponent, data: {} });
exports.RenderType_WantedSwitchComponent = RenderType_WantedSwitchComponent;
function View_WantedSwitchComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "content-loading small-spinner right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "loader"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "span", [["class", "loading-spinner"]], null, null, null, null, null))], null, null); }
function View_WantedSwitchComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["V\u00FDchoz\u00ED"]))], null, null); }
function View_WantedSwitchComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.options.wanted; _ck(_v, 1, 0, currVal_0); }); }
function View_WantedSwitchComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.options.unwanted; _ck(_v, 1, 0, currVal_0); }); }
function View_WantedSwitchComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_WantedSwitchComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 19, "div", [["class", "switch"], ["gmtAuth", "manage_property_status"]], null, null, null, null, null)), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(4, { "hidden-switch": 0 }), i1.ɵdid(5, 81920, null, 0, i3.AuthDirective, [i1.ElementRef, i4.AuthService], { gmtAuth: [0, "gmtAuth"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 15, "ul", [["class", "options"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 4, "li", [], null, null, null, null, null)), i1.ɵdid(8, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(9, { active: 0 }), (_l()(), i1.ɵeld(10, 0, null, null, 1, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.updateSelection(null) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["V\u00FDchoz\u00ED"])), (_l()(), i1.ɵeld(12, 0, null, null, 4, "li", [], null, null, null, null, null)), i1.ɵdid(13, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(14, { active: 0 }), (_l()(), i1.ɵeld(15, 0, null, null, 1, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.updateSelection(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(16, null, ["", ""])), (_l()(), i1.ɵeld(17, 0, null, null, 4, "li", [], null, null, null, null, null)), i1.ɵdid(18, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(19, { active: 0 }), (_l()(), i1.ɵeld(20, 0, null, null, 1, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.updateSelection(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(21, null, ["", ""])), (_l()(), i1.ɵeld(22, 0, null, null, 7, "div", [["gmtAuthNot", "manage_property_status"]], null, null, null, null, null)), i1.ɵdid(23, 81920, null, 0, i5.AuthNotDirective, [i1.ElementRef, i4.AuthService], { gmtAuth: [0, "gmtAuth"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WantedSwitchComponent_2)), i1.ɵdid(25, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WantedSwitchComponent_3)), i1.ɵdid(27, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WantedSwitchComponent_4)), i1.ɵdid(29, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.areaLoading === true); _ck(_v, 1, 0, currVal_0); var currVal_1 = "switch"; var currVal_2 = _ck(_v, 4, 0, _co.areaLoading); _ck(_v, 3, 0, currVal_1, currVal_2); var currVal_3 = "manage_property_status"; _ck(_v, 5, 0, currVal_3); var currVal_4 = _ck(_v, 9, 0, (null === _co.selected)); _ck(_v, 8, 0, currVal_4); var currVal_5 = _ck(_v, 14, 0, (true === _co.selected)); _ck(_v, 13, 0, currVal_5); var currVal_7 = _ck(_v, 19, 0, (false === _co.selected)); _ck(_v, 18, 0, currVal_7); var currVal_9 = "manage_property_status"; _ck(_v, 23, 0, currVal_9); var currVal_10 = (null === _co.selected); _ck(_v, 25, 0, currVal_10); var currVal_11 = (true === _co.selected); _ck(_v, 27, 0, currVal_11); var currVal_12 = (false === _co.selected); _ck(_v, 29, 0, currVal_12); }, function (_ck, _v) { var _co = _v.component; var currVal_6 = _co.options.wanted; _ck(_v, 16, 0, currVal_6); var currVal_8 = _co.options.unwanted; _ck(_v, 21, 0, currVal_8); }); }
exports.View_WantedSwitchComponent_0 = View_WantedSwitchComponent_0;
function View_WantedSwitchComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "wanted-switch", [], null, null, null, View_WantedSwitchComponent_0, RenderType_WantedSwitchComponent)), i1.ɵdid(1, 114688, null, 0, i6.WantedSwitchComponent, [i4.AuthService, i7.UserNamePipe, "Restangular"], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_WantedSwitchComponent_Host_0 = View_WantedSwitchComponent_Host_0;
var WantedSwitchComponentNgFactory = i1.ɵccf("wanted-switch", i6.WantedSwitchComponent, View_WantedSwitchComponent_Host_0, { item: "item" }, { changed: "changed" }, []);
exports.WantedSwitchComponentNgFactory = WantedSwitchComponentNgFactory;
