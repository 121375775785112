"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./mirrored-list.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../checkbox/checkbox.component.ngfactory");
var i3 = require("../checkbox/checkbox.component");
var i4 = require("@angular/common");
var i5 = require("./mirrored-list.component");
var i6 = require("../../services/checklist.service");
var styles_MirroredListComponent = [i0.styles];
var RenderType_MirroredListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MirroredListComponent, data: {} });
exports.RenderType_MirroredListComponent = RenderType_MirroredListComponent;
function View_MirroredListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "empty-info"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u017D\u00E1dn\u00E9 polo\u017Eky"]))], null, null); }
function View_MirroredListComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "item flexbox-container"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (_v.parent.context.checklist.toggleSelection(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "gmt-checkbox", [["class", "item-checkbox"], ["gmtType", "multiselect"]], null, null, null, i2.View_CheckboxComponent_0, i2.RenderType_CheckboxComponent)), i1.ɵdid(2, 114688, null, 0, i3.CheckboxComponent, [], { gmtChecked: [0, "gmtChecked"], gmtType: [1, "gmtType"] }, null), (_l()(), i1.ɵted(3, null, [" ", " "]))], function (_ck, _v) { var currVal_0 = _v.parent.context.checklist.isChecked(_v.context.$implicit); var currVal_1 = "multiselect"; _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.value; _ck(_v, 3, 0, currVal_2); }); }
function View_MirroredListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "bordered"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MirroredListComponent_2)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MirroredListComponent_3)), i1.ɵdid(4, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = (_v.context.list.length === 0); _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.list; _ck(_v, 4, 0, currVal_1); }, null); }
function View_MirroredListComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_MirroredListComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_MirroredListComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(0, [["list", 2]], null, 0, null, View_MirroredListComponent_1)), (_l()(), i1.ɵeld(1, 0, null, null, 20, "fieldset", [["class", "mirrored-list"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "legend", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "flexbox-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "label-options"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["K dispozici"])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [["class", "label-selected"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Aktivn\u00ED"])), (_l()(), i1.ɵeld(9, 0, null, null, 12, "div", [["class", "flexbox-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 3, "div", [["class", "column-selected"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_MirroredListComponent_4)), i1.ɵdid(12, 540672, null, 0, i4.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i1.ɵpod(13, { list: 0, checklist: 1 }), (_l()(), i1.ɵeld(14, 0, null, null, 3, "div", [["class", "transfer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 2, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onToggle() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 0, "i", [["class", "fa fa-chevron-left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 0, "i", [["class", "fa fa-chevron-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 3, "div", [["class", "column-options"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_MirroredListComponent_5)), i1.ɵdid(20, 540672, null, 0, i4.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i1.ɵpod(21, { list: 0, checklist: 1 })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _ck(_v, 13, 0, _co.availableItems, _co.availableChecklist); var currVal_2 = i1.ɵnov(_v, 0); _ck(_v, 12, 0, currVal_1, currVal_2); var currVal_3 = _ck(_v, 21, 0, _co.selectedItems, _co.selectedChecklist); var currVal_4 = i1.ɵnov(_v, 0); _ck(_v, 20, 0, currVal_3, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.label; _ck(_v, 3, 0, currVal_0); }); }
exports.View_MirroredListComponent_0 = View_MirroredListComponent_0;
function View_MirroredListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mirrored-list", [], null, null, null, View_MirroredListComponent_0, RenderType_MirroredListComponent)), i1.ɵdid(1, 114688, null, 0, i5.MirroredListComponent, [i6.ChecklistService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_MirroredListComponent_Host_0 = View_MirroredListComponent_Host_0;
var MirroredListComponentNgFactory = i1.ɵccf("mirrored-list", i5.MirroredListComponent, View_MirroredListComponent_Host_0, { availableItems: "availableItems", selectedItems: "selectedItems", label: "label" }, { changed: "changed" }, []);
exports.MirroredListComponentNgFactory = MirroredListComponentNgFactory;
