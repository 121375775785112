"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var string_utils_1 = require("@app/common/utils/string.utils");
var i0 = require("@angular/core");
var PostCodePipe = /** @class */ (function () {
    function PostCodePipe() {
    }
    PostCodePipe.prototype.transform = function (postCode) {
        if (!postCode) {
            return '';
        }
        var spacePosition = 3;
        var stringVal = string_utils_1.StringUtils.valueToString(postCode);
        var withoutSpaces = string_utils_1.StringUtils.removeSpaces(stringVal);
        if (withoutSpaces.length === 5 && /^\d+$/.test(withoutSpaces)) {
            return withoutSpaces.substr(0, spacePosition) + " " + withoutSpaces.substr(spacePosition);
        }
        return stringVal;
    };
    PostCodePipe.ngInjectableDef = i0.defineInjectable({ factory: function PostCodePipe_Factory() { return new PostCodePipe(); }, token: PostCodePipe, providedIn: "root" });
    return PostCodePipe;
}());
exports.PostCodePipe = PostCodePipe;
