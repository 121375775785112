"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var construction_object_name_pipe_1 = require("@app/common/pipes/construction-object-name.pipe");
var parcel_number_pipe_1 = require("@app/common/pipes/parcel-number.pipe");
var geometric_plan_request_model_1 = require("@app/ps/models/geometric-plan-request.model");
var approve_enum_1 = require("@app/ps/enums/approve.enum");
var geometric_plan_request_type_enum_1 = require("@app/ps/enums/geometric-plan-request-type.enum");
var angular_1 = require("@uirouter/angular");
var geometric_plan_request_service_1 = require("@app/ps/services/geometric-plan-request.service");
var note_type_key_enum_1 = require("@app/ps/enums/note-type-key.enum");
var opinion_request_status_enum_1 = require("@app/ps/enums/opinion-request-status.enum");
var title_name_pipe_1 = require("@app/common/pipes/title-name.pipe");
var GeometricPlanRequestCreateComponent = /** @class */ (function () {
    function GeometricPlanRequestCreateComponent(requestService, constructionObjectNamePipe, parcelNumberPipe, stateService, titleNamePipe, restangular) {
        this.requestService = requestService;
        this.constructionObjectNamePipe = constructionObjectNamePipe;
        this.parcelNumberPipe = parcelNumberPipe;
        this.stateService = stateService;
        this.titleNamePipe = titleNamePipe;
        this.restangular = restangular;
        this.geometricPlanRequest = geometric_plan_request_model_1.GeometricPlanRequestModel.create();
        this.typeOptions = geometric_plan_request_type_enum_1.geometricPlanRequestTypeOptions;
        this.approveOptions = approve_enum_1.approveOptions;
        this.similarGeometricPlanRequestExists = false;
        this.type = this.typeOptions[0];
        this.typeEnum = geometric_plan_request_type_enum_1.GeometricPlanRequestTypeEnum;
        this.parcelSplitPreApproveByBuildingAuthority = this.approveOptions[0];
        this.parcelSplitPreApproveByOwner = this.approveOptions[0];
        this.titleFilter = {
            validity: { values: ['valid'] },
            areaId: { values: [] },
        };
        this.parcelFilter = {
            validity: { values: ['valid'] },
            areaId: { values: [] },
            titleId: { values: [] },
        };
        this.constructionObjectFilter = {
            validity: { values: ['valid'] },
            areaId: { values: [] },
        };
        this.onSubmit = this.onSubmit.bind(this);
    }
    GeometricPlanRequestCreateComponent.prototype.ngOnInit = function () {
    };
    GeometricPlanRequestCreateComponent.prototype.isValid = function () {
        return !!(this.type
            && this.geometricPlanRequest.name
            && this.area
            && this.geometricPlanRequest.parcels
            && this.geometricPlanRequest.parcels.length);
    };
    GeometricPlanRequestCreateComponent.prototype.onCadastreChanged = function () {
        this.titleFilter.areaId.values = [this.area.id];
        this.parcelFilter.areaId.values = [this.area.id];
        this.constructionObjectFilter.areaId.values = [this.area.id];
        this.titles = undefined;
        this.geometricPlanRequest.parcels = undefined;
    };
    GeometricPlanRequestCreateComponent.prototype.onTitleChanged = function () {
        this.parcelFilter.titleId.values = this.titles;
        this.geometricPlanRequest.parcels = undefined;
    };
    GeometricPlanRequestCreateComponent.prototype.onParcelsChanged = function () {
        this.checkExistingSimilarOpinionRequest();
    };
    GeometricPlanRequestCreateComponent.prototype.onSubmit = function () {
        var _this = this;
        this.geometricPlanRequest.type = this.type.id;
        this.geometricPlanRequest.areas = this.area ? [this.area] : [];
        this.geometricPlanRequest.parcelSplitPreApproveByBuildingAuthority = this.parcelSplitPreApproveByBuildingAuthority.id;
        this.geometricPlanRequest.parcelSplitPreApproveByOwner = this.parcelSplitPreApproveByOwner.id;
        var notes = [];
        if (this.geometricPlanRequest.type === geometric_plan_request_type_enum_1.GeometricPlanRequestTypeEnum.PARCEL_SPLIT && this.parcelSplitPreApproveByBuildingAuthorityNote) {
            notes.push({
                text: this.parcelSplitPreApproveByBuildingAuthorityNote,
                noteType: this.noteTypes.find(function (nt) { return nt.key === note_type_key_enum_1.NoteTypeKeyEnum.ParcelSplitPreApproveByBuildingAuthority; })
            });
        }
        if (this.geometricPlanRequest.type === geometric_plan_request_type_enum_1.GeometricPlanRequestTypeEnum.PARCEL_SPLIT && this.parcelSplitPreApproveByOwnerNote) {
            notes.push({
                text: this.parcelSplitPreApproveByOwnerNote,
                noteType: this.noteTypes.find(function (nt) { return nt.key === note_type_key_enum_1.NoteTypeKeyEnum.ParcelSplitPreApproveByOwner; })
            });
        }
        return this.requestService
            .create(this.geometricPlanRequest, notes)
            .then(function (geometricPlanRequest) {
            _this.stateService.go('symap.project.geometricPlanRequests.detail', { id: geometricPlanRequest.id, tab: 'parcels-budouc' });
        });
    };
    GeometricPlanRequestCreateComponent.prototype.checkExistingSimilarOpinionRequest = function () {
        var _this = this;
        if (!this.geometricPlanRequest.parcels || !this.geometricPlanRequest.parcels.length) {
            this.similarGeometricPlanRequestExists = false;
            return;
        }
        var parcelIds = this.geometricPlanRequest.parcels.map(function (p) { return p.id; });
        var filter = { filter: { filters: { parcelId: parcelIds } } };
        this.restangular
            .all('geometric-plan-requests').customPOST(filter)
            .then(function (data) {
            var requests = data.plain().filter(function (or) { return or.status !== opinion_request_status_enum_1.OpinionRequestStatusEnum.DELETED; });
            _this.similarGeometricPlanRequestExists = requests.length > 0;
        });
    };
    return GeometricPlanRequestCreateComponent;
}());
exports.GeometricPlanRequestCreateComponent = GeometricPlanRequestCreateComponent;
