"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var VfzeImportStepComponent = /** @class */ (function () {
    function VfzeImportStepComponent() {
        this.backCallback = new core_1.EventEmitter();
        this.submitCallback = new core_1.EventEmitter();
    }
    return VfzeImportStepComponent;
}());
exports.VfzeImportStepComponent = VfzeImportStepComponent;
