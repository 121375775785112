"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var pageable_list_service_1 = require("@app/common/services/pageable.list.service");
var dialog_service_1 = require("@app/dialog/dialog.service");
var expert_edit_form_component_1 = require("@app/ps/components/expert-edit-form/expert-edit-form.component");
var ExpertListComponent = /** @class */ (function () {
    function ExpertListComponent(helpIds, restangular, pageableListService, dialogService) {
        this.helpIds = helpIds;
        this.restangular = restangular;
        this.pageableListService = pageableListService;
        this.dialogService = dialogService;
        this.defaultFilter = {
            filters: {
                searchText: { values: [{ id: '' }] },
            }
        };
        this.onCreate = this.onCreate.bind(this);
    }
    ExpertListComponent.prototype.ngOnInit = function () {
        this.initializeList();
    };
    ExpertListComponent.prototype.uiOnParamsChanged = function (changedParams, $transition$) {
        this.pageableList.uiOnParamsChanged(changedParams, $transition$);
    };
    ExpertListComponent.prototype.initializeList = function () {
        this.pageableList = this.pageableListService.get('experts', this.defaultFilter, 'expertsFilter');
        this.list = this.pageableList.list;
        this.pageableList.load();
    };
    ExpertListComponent.prototype.onCreate = function () {
        var _this = this;
        var dialog = this.dialogService.open(expert_edit_form_component_1.ExpertEditFormComponent, { data: {} });
        var sub = dialog.afterClosed.subscribe(function (res) {
            if (res) {
                _this.pageableList.fetchResults();
            }
            sub.unsubscribe();
        });
    };
    return ExpertListComponent;
}());
exports.ExpertListComponent = ExpertListComponent;
