"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var angular_1 = require("@uirouter/angular");
var auth_service_1 = require("@app/common/services/auth.service");
var login_service_1 = require("@app/common/services/login.service");
var menuConfig_1 = require("@app/models/menuConfig");
var brand_translate_pipe_1 = require("@app/common/pipes/brand-translate.pipe");
var modules_service_1 = require("@app/common/services/modules.service");
var MainComponent = /** @class */ (function () {
    function MainComponent(authService, loginService, brandTranslatePipe, uiRouterGlobals, transitionService, modulesService, ENV, APP_BRAND, applications, localStorageService, approveTermsService, changePasswordService, googleAnalyticsService) {
        this.authService = authService;
        this.loginService = loginService;
        this.brandTranslatePipe = brandTranslatePipe;
        this.uiRouterGlobals = uiRouterGlobals;
        this.transitionService = transitionService;
        this.modulesService = modulesService;
        this.ENV = ENV;
        this.APP_BRAND = APP_BRAND;
        this.applications = applications;
        this.localStorageService = localStorageService;
        this.approveTermsService = approveTermsService;
        this.changePasswordService = changePasswordService;
        this.googleAnalyticsService = googleAnalyticsService;
        this.collapsed = false;
        this.projectsCollapsed = true;
        this.usersCollapsed = true;
        this.managerReportCollapsed = true;
        this.menuConfig = [];
    }
    MainComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var projectsCollapsed, usersCollapsed, managerReportCollapsed;
            var _this = this;
            return __generator(this, function (_a) {
                this.authService.setActiveApplication(this.applications.landing.name);
                this.authService.updateBaseUrl();
                this.authService.updateAuthorizationHeader();
                this.googleAnalyticsService.onUserCome();
                this.collapsed = this.localStorageService.get('menuCollapsed');
                projectsCollapsed = this.localStorageService.get('projectsMenuCollapsed');
                usersCollapsed = this.localStorageService.get('usersMenuCollapsed');
                managerReportCollapsed = this.localStorageService.get('managerReportMenuCollapsed');
                if (projectsCollapsed !== null) {
                    this.projectsCollapsed = projectsCollapsed;
                }
                if (usersCollapsed !== null) {
                    this.usersCollapsed = usersCollapsed;
                }
                if (managerReportCollapsed !== null) {
                    this.managerReportCollapsed = managerReportCollapsed;
                }
                this.user = this.authService.getUser();
                this.servicedeskUrl = this.authService.getServiceDeskUrl();
                this.menuConfig = this.getMenuConfig();
                this.transitionService.onStart({}, function (transition) {
                    if (transition && transition.to()) {
                        _this.menuConfig = _this.getMenuConfig(transition.to().name);
                    }
                });
                return [2 /*return*/];
            });
        });
    };
    MainComponent.prototype.getCollapseType = function () {
        if (this.isActive('landing.users')) {
            return 'usersMenuCollapsed';
        }
        if (this.isActive('landing.projects')) {
            return 'projectsMenuCollapsed';
        }
        if (this.isActive('landing.managerReportProjects')) {
            return 'managerReportMenuCollapsed';
        }
        return 'menuCollapsed';
    };
    MainComponent.prototype.isCollapsed = function () {
        var type = this.getCollapseType();
        if (type === 'menuCollapsed') {
            return this.collapsed;
        }
        else if (type === 'projectsMenuCollapsed') {
            return this.projectsCollapsed;
        }
        else if (type === 'usersMenuCollapsed') {
            return this.usersCollapsed;
        }
        else if (type === 'managerReportMenuCollapsed') {
            return this.managerReportCollapsed;
        }
    };
    MainComponent.prototype.onCollapse = function () {
        var type = this.getCollapseType();
        if (type === 'menuCollapsed') {
            this.collapsed = !this.collapsed;
            this.localStorageService.set('menuCollapsed', this.collapsed);
        }
        else if (type === 'projectsMenuCollapsed') {
            this.projectsCollapsed = !this.projectsCollapsed;
            this.localStorageService.set('projectsMenuCollapsed', this.projectsCollapsed);
        }
        else if (type === 'usersMenuCollapsed') {
            this.usersCollapsed = !this.usersCollapsed;
            this.localStorageService.set('usersMenuCollapsed', this.usersCollapsed);
        }
        else if (type === 'managerReportMenuCollapsed') {
            this.managerReportCollapsed = !this.managerReportCollapsed;
            this.localStorageService.set('managerReportMenuCollapsed', this.managerReportCollapsed);
        }
    };
    MainComponent.prototype.onLogout = function () {
        this.authService.logout();
    };
    MainComponent.prototype.isActive = function (uiSref, strict) {
        if (strict === void 0) { strict = false; }
        if (strict) {
            return this.uiRouterGlobals.$current.name === uiSref;
        }
        return !!this.uiRouterGlobals.$current.includes[uiSref];
    };
    MainComponent.prototype.getMenuConfig = function (route) {
        if (route === 'landing.projects' || (route === undefined && this.isActive('landing.projects'))) {
            return [
                new menuConfig_1.MenuConfig(this.brandTranslatePipe.transform('ps.projectList'), 'landing.projects', 'fa-list'),
            ];
        }
        if (route === 'landing.users' || route === 'landing.users.detail' || (route === undefined && this.isActive('landing.users'))) {
            return [
                new menuConfig_1.MenuConfig(this.brandTranslatePipe.transform('Uživatelé'), 'landing.users', 'fa-users'),
            ];
        }
        if (route === 'landing.managerReportProjects' || route === 'landing.managerReportProjects.detail' || (route === undefined && this.isActive('landing.managerReportProjects'))) {
            return [
                new menuConfig_1.MenuConfig(this.brandTranslatePipe.transform('Manažerský report'), 'landing.managerReportProjects', 'fa-users'),
            ];
        }
        var menu = [
            new menuConfig_1.MenuConfig('Úvodní stránka', 'landing.home', 'fa-home'),
        ];
        if (this.authService.hasPermissionOnProject('web', undefined, this.symapPermissions)) {
            menu.push(new menuConfig_1.MenuConfig(this.brandTranslatePipe.transform('ps.projectList'), 'landing.projects', 'fa-list'));
        }
        if (this.authService.isAuthenticatedApplication(this.applications.sv.name)) {
            menu.push(new menuConfig_1.MenuConfig('Správa vzorů', 'sv.samples', 'fa-folder-open'));
        }
        var hasDashboard = this.user.roles.filter(function (r) { return r.application === 'DASH'; } /*(r.application === 'SY' && (r.role === 'SUPER_ADMIN' || r.role === 'ADMIN')) || (r.application === 'DASH' && r.role === 'DASHBOARD')*/).length > 0;
        if (this.authService.isAuthenticatedApplication(this.applications.dashboard.name) && this.APP_BRAND.NAME !== 'SZ') {
            menu.push(new menuConfig_1.MenuConfig(this.brandTranslatePipe.transform('dashboard.name'), 'dashboard.projects', 'fa-list-ul'));
        }
        if (this.APP_BRAND.NAME === 'RSD' && this.authService.hasPermissionOnProject('manager_report', undefined, this.symapPermissions)) {
            menu.push(new menuConfig_1.MenuConfig('Manažerský report', 'landing.managerReportProjects', 'fa-bar-chart'));
        }
        var isAdmin = this.user.roles.filter(function (r) { return r.application === 'SY' && r.role === 'SUPER_ADMIN'; }).length > 0;
        if (isAdmin) {
            menu.push(new menuConfig_1.MenuConfig(this.brandTranslatePipe.transform('Uživatelé'), 'landing.users', 'fa-users'));
        }
        if (this.APP_BRAND.HELP) {
            menu.push(new menuConfig_1.MenuConfig('Nápověda', undefined, 'fa-question', undefined, undefined, this.APP_BRAND.HELP, '_blank'));
        }
        return menu;
    };
    return MainComponent;
}());
exports.MainComponent = MainComponent;
