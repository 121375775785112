"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var auth_service_1 = require("@app/common/services/auth.service");
var callbacks_enum_1 = require("@app/ps/enums/callbacks.enum");
var construction_object_name_pipe_1 = require("../../../common/pipes/construction-object-name.pipe");
var subject_name_pipe_1 = require("@app/common/pipes/subject-name.pipe");
var case_service_1 = require("@app/ps/services/case.service");
var ownership_name_pipe_1 = require("@app/common/pipes/ownership-name.pipe");
var GroupParams = /** @class */ (function () {
    function GroupParams() {
    }
    return GroupParams;
}());
var GroupPredicate = /** @class */ (function () {
    function GroupPredicate() {
        this.predicate = function () { return true; };
        this.mapping = function () { };
    }
    return GroupPredicate;
}());
var TitlesCreateCasesConfirmComponent = /** @class */ (function () {
    function TitlesCreateCasesConfirmComponent(authService, constructionObjectNamePipe, subjectNamePipe, caseService, ownershipNamePipe, restangular) {
        this.authService = authService;
        this.constructionObjectNamePipe = constructionObjectNamePipe;
        this.subjectNamePipe = subjectNamePipe;
        this.caseService = caseService;
        this.ownershipNamePipe = ownershipNamePipe;
        this.restangular = restangular;
        this.saving = false;
        this.caseIds = [];
        this.proceed = this.proceed.bind(this);
    }
    TitlesCreateCasesConfirmComponent.prototype.ngOnInit = function () {
        this.user = this.authService.getUser();
        this.initCases();
    };
    TitlesCreateCasesConfirmComponent.prototype.newCase = function () {
        var data = {};
        this.cases.push(data);
        data.constructionObjects = [];
        data.easements = [];
        data.occupations = [];
        data.titles = [];
        //data.buildings = [];
        data.obligation = this.data.details.obligation;
        data.caseOwnerships = [];
        data.series = this.data.details.series;
        data.acceleratedPriceType = this.data.details.acceleratedPriceType;
        return data;
    };
    /**
    * split data into case objects to send to backend
    */
    TitlesCreateCasesConfirmComponent.prototype.initCases = function () {
        var _this = this;
        this.cases = [];
        this.data.details.titles.forEach(function (titleData) {
            titleData.dataOwnerships = titleData.dataOwnerships.filter(function (caseOwnership) { return !caseOwnership.isParent; });
            var groupParams = [];
            groupParams.push(_this.groupByTitles(titleData));
            groupParams.push(_this.groupByConstructionObjects(titleData));
            groupParams.push(_this.groupByOwners(titleData));
            // Create combined predicate for case to be created
            groupParams
                .reduce(function (result, current) {
                return _this.combinePredicates(result, current);
            }, null)
                .forEach(function (predicate) {
                var caseData = _this.cases.find(predicate.predicate);
                if (!caseData) {
                    caseData = _this.newCase();
                }
                predicate.mapping(caseData);
            });
        });
        this.sortCases();
    };
    TitlesCreateCasesConfirmComponent.prototype.groupByTitles = function (titleData) {
        var params = new GroupParams();
        var predicates = [];
        params.group = this.data.details.createGroups.some(function (group) { return group.id === 'title'; });
        params.predicates = predicates;
        var gp = new GroupPredicate();
        gp.predicate = function (caseData) { return caseData.titles[0].id === titleData.dataTitle.id; };
        gp.mapping = function (caseData) {
            if (!caseData.titles.some(function (title) { return title.id === titleData.dataTitle.id; })) {
                caseData.titles.push(titleData.dataTitle);
            }
        };
        predicates.push(gp);
        return params;
    };
    TitlesCreateCasesConfirmComponent.prototype.groupByConstructionObjects = function (titleData) {
        var _this = this;
        var params = new GroupParams();
        var predicates = [];
        params.group = this.data.details.createGroups.some(function (group) { return group.id === 'constructionObject'; });
        params.predicates = predicates;
        // projít so a filtrovat podle jejich výběru
        if (this.data.details.objectTypes.some(function (objectType) { return objectType.key === 'easement'; })) {
            titleData.dataEasements.forEach(function (easement) {
                var constructionObjects = easement.constructionObjects;
                if (_this.data.details.constructionObjects !== null) {
                    constructionObjects = constructionObjects.filter(function (co1) { return _this.data.details.constructionObjects.some(function (co2) { return co2.id === co1.id; }); });
                }
                if (constructionObjects.length) {
                    constructionObjects.forEach(function (co) {
                        var gp = new GroupPredicate();
                        gp.predicate = function (caseData) { return caseData.constructionObjects[0].id === co.id; };
                        gp.mapping = function (caseData) {
                            if (!caseData.constructionObjects.some(function (co1) { return co1.id === co.id; })) {
                                caseData.constructionObjects.push(co);
                            }
                            if (!caseData.easements.some(function (easement1) { return easement1.id === easement.id; })) {
                                delete easement.cases;
                                caseData.easements.push(easement);
                            }
                        };
                        predicates.push(gp);
                    });
                }
            });
        }
        this.data.details.objectTypes.forEach(function (objectType) {
            if (!titleData.dataOccupationGroups[objectType.key]) {
                return;
            }
            titleData.dataOccupationGroups[objectType.key].forEach(function (group) {
                group.occupations.forEach(function (occupation) {
                    var constructionObjects = occupation.constructionObjects;
                    if (_this.data.details.constructionObjects !== null) {
                        constructionObjects = constructionObjects.filter(function (co1) { return _this.data.details.constructionObjects.some(function (co2) { return co2.id === co1.id; }); });
                    }
                    if (constructionObjects.length) {
                        constructionObjects.forEach(function (co) {
                            var gp = new GroupPredicate();
                            gp.predicate = function (caseData) { return caseData.constructionObjects[0].id === co.id; };
                            gp.mapping = function (caseData) {
                                if (!caseData.constructionObjects.some(function (co1) { return co1.id === co.id; })) {
                                    caseData.constructionObjects.push(co);
                                }
                                if (!caseData.occupations.some(function (occupation1) { return occupation1.id === occupation.id; })) {
                                    caseData.occupations.push(occupation);
                                }
                            };
                            predicates.push(gp);
                        });
                    }
                });
            });
        });
        return params;
    };
    TitlesCreateCasesConfirmComponent.prototype.groupByOwners = function (titleData) {
        var params = new GroupParams();
        var predicates = [];
        params.group = this.data.details.createGroups.some(function (group) { return group.id === 'owner'; });
        params.predicates = predicates;
        titleData.dataOwnerships.forEach(function (dataOwnership) {
            var gp = new GroupPredicate();
            gp.predicate = function (caseData) { return caseData.caseOwnerships[0].ownership.opsubId === dataOwnership.ownership.opsubId; };
            gp.mapping = function (caseData) {
                if (!caseData.caseOwnerships.some(function (caseOwnership) { return caseOwnership.ownership.id === dataOwnership.ownership.id; })) {
                    caseData.caseOwnerships.push({
                        ownership: dataOwnership.ownership,
                        parentCaseOwnership: dataOwnership.parentCaseOwnership,
                        isParent: dataOwnership.isParent,
                    });
                }
            };
            predicates.push(gp);
        });
        return params;
    };
    TitlesCreateCasesConfirmComponent.prototype.combinePredicates = function (predicates1, params) {
        if (!predicates1 || predicates1.length === 0) {
            predicates1 = [new GroupPredicate()];
        }
        var result = [];
        predicates1.forEach(function (p1) {
            params.predicates.forEach(function (p2) {
                var p = new GroupPredicate();
                if (params.group) {
                    p.predicate = function (caseData) { return p1.predicate(caseData) && p2.predicate(caseData); };
                }
                else {
                    p.predicate = p1.predicate;
                }
                p.mapping = function (caseData) { p1.mapping(caseData); p2.mapping(caseData); };
                result.push(p);
            });
        });
        return result;
    };
    TitlesCreateCasesConfirmComponent.prototype.sortCases = function () {
        var _this = this;
        if (this.data.details.createGroups.some(function (group) { return group.id === 'owner'; })) {
            this.cases.sort(function (a, b) {
                var aName = a.caseOwnerships[0] ? _this.ownershipNamePipe.transform(a.caseOwnerships[0].ownership) : '';
                var bName = b.caseOwnerships[0] ? _this.ownershipNamePipe.transform(b.caseOwnerships[0].ownership) : '';
                if (aName === bName) {
                    return 0;
                }
                else if (typeof aName.localeCompare === 'function') {
                    return aName.localeCompare(bName);
                }
                else {
                    return (aName || '') > (bName || '') ? 1 : -1;
                }
            });
        }
    };
    TitlesCreateCasesConfirmComponent.prototype.hasCreateGroup = function (groupKey) {
        return this.data.details.createGroups.some(function (group) { return group.id === groupKey; });
    };
    TitlesCreateCasesConfirmComponent.prototype.proceed = function () {
        return __awaiter(this, void 0, void 0, function () {
            var e_1, _a, _b, _c, caseData, series, caseCreated, error_1, caseText, msg, e_1_1;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        this.casesProcessed = 0;
                        this.saving = true;
                        this.messages = [];
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 11, 12, 13]);
                        _b = __values(this.cases), _c = _b.next();
                        _d.label = 2;
                    case 2:
                        if (!!_c.done) return [3 /*break*/, 10];
                        caseData = _c.value;
                        _d.label = 3;
                    case 3:
                        _d.trys.push([3, 7, , 8]);
                        if (!(caseData.obligation.computePrice && !this.caseService.easementPriceByShare(caseData.obligation))) return [3 /*break*/, 5];
                        series = caseData.series;
                        delete caseData.series;
                        return [4 /*yield*/, this.restangular
                                .one('titles')
                                .all('case')
                                .all('compute-price')
                                .post(caseData)];
                    case 4:
                        caseData = _d.sent();
                        caseData.series = series;
                        _d.label = 5;
                    case 5: return [4 /*yield*/, this.restangular.all('cases').all('create').post(caseData)];
                    case 6:
                        caseCreated = _d.sent();
                        this.caseIds.push(caseCreated.id);
                        return [3 /*break*/, 8];
                    case 7:
                        error_1 = _d.sent();
                        caseText = [];
                        if (this.hasCreateGroup('owner')) {
                            caseText.push('vlastníka ' + caseData.caseOwnerships[0].ownership.subjects.map(this.subjectNamePipe.transform).join(' a '));
                        }
                        if (this.hasCreateGroup('title')) {
                            caseText.push('list vlastnictví ' + caseData.titles[0].lv + ' v katastrálním území ' + caseData.titles[0].area.name);
                        }
                        if (this.hasCreateGroup('constructionObject')) {
                            caseText.push('stavební objekt ' + this.constructionObjectNamePipe.transform(caseData.constructionObjects[0]));
                        }
                        msg = (error_1.status === 400 && error_1.data && error_1.data.code === 0 ? error_1.data.message : 'Při zakládání případu se vyskytla chyba.');
                        if (caseText.length) {
                            this.messages.push('Pro ' + (caseText.length > 1 ? caseText.slice(0, -1).join(', ') + ' a ' : '') + caseText.slice(-1) + ': ' +
                                msg);
                        }
                        else {
                            this.messages.push(msg);
                        }
                        return [3 /*break*/, 8];
                    case 8:
                        this.casesProcessed++;
                        _d.label = 9;
                    case 9:
                        _c = _b.next();
                        return [3 /*break*/, 2];
                    case 10: return [3 /*break*/, 13];
                    case 11:
                        e_1_1 = _d.sent();
                        e_1 = { error: e_1_1 };
                        return [3 /*break*/, 13];
                    case 12:
                        try {
                            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                        }
                        finally { if (e_1) throw e_1.error; }
                        return [7 /*endfinally*/];
                    case 13:
                        this.saving = false;
                        this.notifyParent();
                        return [2 /*return*/];
                }
            });
        });
    };
    TitlesCreateCasesConfirmComponent.prototype.notifyParent = function () {
        this.callbacks.get(callbacks_enum_1.Callbacks.DataValidityChanged)({
            isValid: function () { return false; },
            data: {
                messages: this.messages,
                caseIds: this.caseIds,
            },
        });
    };
    return TitlesCreateCasesConfirmComponent;
}());
exports.TitlesCreateCasesConfirmComponent = TitlesCreateCasesConfirmComponent;
