"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var common_1 = require("@angular/common");
var cs_1 = require("@angular/common/locales/cs");
var angular_hybrid_1 = require("@uirouter/angular-hybrid");
var main_component_1 = require("./components/main/main.component");
var user_credentials_component_1 = require("./../common/components/user-credentials/user-credentials.component");
var validation_component_1 = require("./components/validation/validation.component");
var generate_component_1 = require("@app/vfze/components/generate/generate.component");
var import_component_1 = require("@app/vfze/components/import/import.component");
common_1.registerLocaleData(cs_1.default, 'cs');
function authCallback(authService, applications, $transition$) {
    return authService.checkProjectStatus($transition$, applications.vfze.name, null, authService.loadVfzeProjects, 'error')
        .then(function () { }, function (newState) { return newState; });
}
exports.authCallback = authCallback;
exports.vfzeState = {
    name: 'vfze',
    url: '/vfze',
    abstract: true,
    component: main_component_1.MainComponent,
    onEnter: ['AuthService', 'APPLICATIONS', '$transition$', authCallback],
};
exports.vfzeValidatorState = {
    name: 'vfze.validation',
    url: '/validation',
    data: { title: 'Validátor VFZE' },
    views: {
        'content@vfze': {
            component: validation_component_1.ValidationComponent
        }
    }
};
exports.vfzeGenerateState = {
    name: 'vfze.generate',
    url: '/generate',
    data: { title: 'Generování VFZE' },
    views: {
        'content@vfze': {
            component: generate_component_1.GenerateComponent
        }
    }
};
exports.importGenerateState = {
    name: 'vfze.import',
    url: '/import',
    data: { title: 'Import VFZE' },
    views: {
        'content@vfze': {
            component: import_component_1.ImportComponent
        }
    }
};
exports.vfzeSettingsState = {
    name: 'vfze.settings',
    url: '/settings',
    data: {
        title: 'Nastavení',
    },
    views: {
        'content@vfze': {
            component: user_credentials_component_1.UserCredentialsComponent,
        }
    },
    params: {
        backSref: {
            type: 'string',
            value: 'vfze.validation'
        },
    }
};
var ɵ0 = function ($injector) { return $injector.get('Upload'); };
exports.ɵ0 = ɵ0;
var VfzeAppModule = /** @class */ (function () {
    function VfzeAppModule() {
    }
    return VfzeAppModule;
}());
exports.VfzeAppModule = VfzeAppModule;
