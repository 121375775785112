"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var pageable_list_service_1 = require("@app/common/services/pageable.list.service");
var CustomerListComponent = /** @class */ (function () {
    function CustomerListComponent(configurationRestangular, helpIds, pageableListService) {
        this.configurationRestangular = configurationRestangular;
        this.helpIds = helpIds;
        this.pageableListService = pageableListService;
        this.uiOnParamsChanged = this.uiOnParamsChanged.bind(this);
        this.showCustomerDepartments = this.showCustomerDepartments.bind(this);
        this.onToggleCustomerDepartments = this.onToggleCustomerDepartments.bind(this);
    }
    CustomerListComponent.prototype.ngOnInit = function () {
        this.initializeList();
    };
    CustomerListComponent.prototype.getDefaultFilter = function () {
        return {
            filters: {
                searchText: {
                    values: [{ id: '' }],
                },
            },
        };
    };
    CustomerListComponent.prototype.uiOnParamsChanged = function (changedParams, $transition$) {
        this.pageableList.uiOnParamsChanged(changedParams, $transition$);
    };
    CustomerListComponent.prototype.showCustomerDepartments = function () {
        return this.list && this.list.filter.filters.includeDepartments && this.list.filter.filters.includeDepartments.values.length === 1;
    };
    CustomerListComponent.prototype.onToggleCustomerDepartments = function () {
        if (!this.list.filter.filters.includeDepartments || this.list.filter.filters.includeDepartments.values.length === 0) {
            this.list.filter.filters.includeDepartments = {
                values: ['true'],
            };
        }
        else {
            this.list.filter.filters.includeDepartments = {
                values: [],
            };
        }
    };
    CustomerListComponent.prototype.initializeList = function () {
        this.pageableList = this.pageableListService.get('v2/customers', this.getDefaultFilter(), 'settings.customers.filter', this.configurationRestangular);
        this.list = this.pageableList.list;
        this.pageableList.load();
    };
    return CustomerListComponent;
}());
exports.CustomerListComponent = CustomerListComponent;
