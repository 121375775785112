"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var i0 = require("@angular/core");
var FullStreetPipe = /** @class */ (function () {
    function FullStreetPipe() {
    }
    FullStreetPipe.prototype.transform = function (subject) {
        if (!subject) {
            return '';
        }
        var fullStreet = !subject.street && subject.neighbourhood && subject.neighbourhood !== subject.municipality ? subject.neighbourhood : '';
        fullStreet += !subject.street ? (fullStreet || !subject.houseNumber ? '' : 'č.p.') : subject.street;
        fullStreet += !subject.houseNumber ? '' : ' ' + subject.houseNumber;
        fullStreet += !subject.streetNumber ? '' : '/' + subject.streetNumber;
        return fullStreet;
    };
    FullStreetPipe.ngInjectableDef = i0.defineInjectable({ factory: function FullStreetPipe_Factory() { return new FullStreetPipe(); }, token: FullStreetPipe, providedIn: "root" });
    return FullStreetPipe;
}());
exports.FullStreetPipe = FullStreetPipe;
