"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".project-select[_ngcontent-%COMP%] {\n  float: right;\n  padding: 7px 0 0 20px;\n  height: 40px;\n  box-sizing: border-box; }\n\n.project-name[_ngcontent-%COMP%] {\n  float: right;\n  font-weight: bold;\n  line-height: 40px;\n  padding: 0 20px; }"];
exports.styles = styles;
