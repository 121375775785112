"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var _ = require("lodash");
var callback_service_1 = require("@app/ps/services/callback.service");
var map_callback_enum_1 = require("@app/map/enums/map-callback.enum");
var i0 = require("@angular/core");
var i1 = require("../../ps/services/callback.service");
var MapLayerSelectorService = /** @class */ (function () {
    function MapLayerSelectorService(callbackService) {
        this.collapsed = false;
        this.callbacks = callbackService.createCallback();
        this.getCollapsedState = this.getCollapsedState.bind(this);
        this.setCollapsedState = this.setCollapsedState.bind(this);
        this.fireCallback = this.fireCallback.bind(this);
        this.registerCallback = this.registerCallback.bind(this);
        this.unregisterCallback = this.unregisterCallback.bind(this);
    }
    MapLayerSelectorService.prototype.getObjectConfig = function (mainConfig, id) {
        var config = null;
        if (mainConfig.id === id) {
            config = mainConfig;
        }
        else {
            mainConfig.layers.forEach(function (group) {
                if (group.id === id) {
                    config = group;
                }
                else {
                    group.layers.forEach(function (layer) {
                        if (layer.id === id) {
                            config = layer;
                        }
                    });
                }
            });
        }
        return config;
    };
    /**
     * Returns id path from root to layer,
     * skipping root id element and layer id element.
     * Returned path could be used as argument for cmLeafletMapDirective.updateNode()
     * @param config map layer configuration
     * @param id id of searched layer
     * @param result used for recursion
     * @returns array|null
     */
    MapLayerSelectorService.prototype.getConfigPath = function (config, id, result) {
        if (config.id === id) {
            return result;
        }
        else if (_.isArray(config.layers)) {
            var r;
            if (!result) {
                r = [];
            }
            else {
                r = result.concat(config.id);
            }
            for (var t = 0; t < config.layers.length; t++) {
                var up = this.getConfigPath(config.layers[t], id, r);
                if (up) {
                    return up;
                }
            }
        }
        return null;
    };
    MapLayerSelectorService.prototype.fireCallback = function (eventName, event) {
        this.callbacks.get(eventName)(event);
    };
    MapLayerSelectorService.prototype.registerCallback = function (eventName, callback) {
        this.callbacks.add(eventName, callback);
    };
    MapLayerSelectorService.prototype.unregisterCallback = function (eventName, callback) {
        this.callbacks.remove(eventName, callback);
    };
    MapLayerSelectorService.prototype.setCollapsedState = function (newCollapsed) {
        this.collapsed = newCollapsed;
        this.fireCallback(map_callback_enum_1.MapCallbackEnum.collapsedStateChange, this.collapsed);
    };
    MapLayerSelectorService.prototype.getCollapsedState = function () {
        return this.collapsed;
    };
    MapLayerSelectorService.ngInjectableDef = i0.defineInjectable({ factory: function MapLayerSelectorService_Factory() { return new MapLayerSelectorService(i0.inject(i1.CallbackService)); }, token: MapLayerSelectorService, providedIn: "root" });
    return MapLayerSelectorService;
}());
exports.MapLayerSelectorService = MapLayerSelectorService;
