"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["[_nghost-%COMP%]   .attachment-item[_ngcontent-%COMP%]    > div[_ngcontent-%COMP%] {\n  display: flex; }\n  [_nghost-%COMP%]   .attachment-item[_ngcontent-%COMP%]    > div[_ngcontent-%COMP%]   div[_ngcontent-%COMP%]:first-child {\n    height: 40px;\n    display: table;\n    vertical-align: middle; }\n    [_nghost-%COMP%]   .attachment-item[_ngcontent-%COMP%]    > div[_ngcontent-%COMP%]   div[_ngcontent-%COMP%]:first-child   a[_ngcontent-%COMP%] {\n      vertical-align: middle;\n      display: table-cell; }\n  [_nghost-%COMP%]   .attachment-item[_ngcontent-%COMP%]    > div[_ngcontent-%COMP%]   div[_ngcontent-%COMP%]:nth-child(2) {\n    line-height: 20px; }\n    [_nghost-%COMP%]   .attachment-item[_ngcontent-%COMP%]    > div[_ngcontent-%COMP%]   div[_ngcontent-%COMP%]:nth-child(2)   p[_ngcontent-%COMP%] {\n      margin-bottom: 0; }\n\n[_nghost-%COMP%]   .remove-document[_ngcontent-%COMP%] {\n  margin-left: 8px;\n  margin-right: 16px;\n  color: #3d3d3d; }\n  [_nghost-%COMP%]   .remove-document[_ngcontent-%COMP%]:hover {\n    color: #000; }"];
exports.styles = styles;
