"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./radio.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("./radio.component");
var styles_RadioComponent = [i0.styles];
var RenderType_RadioComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RadioComponent, data: {} });
exports.RenderType_RadioComponent = RenderType_RadioComponent;
function View_RadioComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "input", [["type", "radio"]], [[8, "checked", 0], [8, "disabled", 0]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onChanged() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "span", [], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.gmtChecked; var currVal_1 = _co.gmtDisabled; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
exports.View_RadioComponent_0 = View_RadioComponent_0;
function View_RadioComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "gmt-radio", [], null, null, null, View_RadioComponent_0, RenderType_RadioComponent)), i1.ɵdid(1, 114688, null, 0, i2.RadioComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_RadioComponent_Host_0 = View_RadioComponent_Host_0;
var RadioComponentNgFactory = i1.ɵccf("gmt-radio", i2.RadioComponent, View_RadioComponent_Host_0, { gmtChecked: "gmtChecked", gmtDisabled: "gmtDisabled" }, { changed: "changed" }, []);
exports.RadioComponentNgFactory = RadioComponentNgFactory;
