"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var easement_create_step_component_1 = require("@app/ps/construction-objects/components/easement-create-step/easement-create-step.component");
var auth_service_1 = require("@app/common/services/auth.service");
var EasementCreateAddFilesComponent = /** @class */ (function (_super) {
    __extends(EasementCreateAddFilesComponent, _super);
    function EasementCreateAddFilesComponent(restangular, uploadService, authService) {
        var _this = _super.call(this) || this;
        _this.restangular = restangular;
        _this.uploadService = uploadService;
        _this.authService = authService;
        // model
        _this.vfkFiles = [];
        _this.csvFiles = [];
        _this.vfkMessage = EasementCreateAddFilesComponent.MSG_GP_VFK_FILES_DEFAULT;
        _this.csvMessage = EasementCreateAddFilesComponent.MSG_GP_CSV_FILES_DEFAULT;
        _this.csvErrors = [];
        _this.vfkErrors = [];
        _this.vfkValid = false;
        _this.csvValid = false;
        // aux
        _this.restBaseUrl = _this.authService.getActiveApplicationRestUrl();
        _this.onSubmit = _this.onSubmit.bind(_this);
        _this.onChangeFile = _this.onChangeFile.bind(_this);
        _this.uploadVfk = _this.uploadVfk.bind(_this);
        _this.uploadCsv = _this.uploadCsv.bind(_this);
        return _this;
    }
    EasementCreateAddFilesComponent.prototype.ngOnInit = function () {
    };
    EasementCreateAddFilesComponent.prototype.onChangeFile = function (files, type) {
        var _this = this;
        var extensionValid = files.every(function (f) { return f.extension && f.extension.toLowerCase() === type; });
        this[type + 'Message'] = EasementCreateAddFilesComponent.MSG_FILES_VALIDATION;
        this[type + 'Files'] = files;
        this[type + 'Valid'] = false;
        this[type + 'Errors'] = [];
        if (files.length === 0) {
            this[type + 'Message'] = type === 'vfk'
                ? EasementCreateAddFilesComponent.MSG_GP_VFK_FILES_DEFAULT
                : EasementCreateAddFilesComponent.MSG_GP_CSV_FILES_DEFAULT;
            return;
        }
        if (!extensionValid) {
            this[type + 'Message'] = type === 'vfk'
                ? EasementCreateAddFilesComponent.MSG_GP_VFK_FILES_EXTENSION
                : EasementCreateAddFilesComponent.MSG_GP_CSV_FILES_EXTENSION;
            return;
        }
        if (files.length > 1) {
            this[type + 'Message'] = EasementCreateAddFilesComponent.MSG_FILES_QUANTITY;
            return;
        }
        this.validate(this.vfkFiles[0], 'vfk')
            .then(function (data) {
            _this[type + 'Message'] = EasementCreateAddFilesComponent.MSG_FILES_VALID;
            _this[type + 'Valid'] = true;
        })
            .catch(function (data) {
            _this[type + 'Message'] = EasementCreateAddFilesComponent.MSG_FILES_NOT_VALID;
            _this[type + 'Errors'] = data.data.detailMsgs ? data.data.detailMsgs : [];
        });
    };
    EasementCreateAddFilesComponent.prototype.isFormValid = function () {
        return !!(this.vfkFiles.length === 1
            && this.vfkValid
            && (this.csvFiles.length === 0 || (this.csvFiles.length === 1 && this.csvValid)));
    };
    EasementCreateAddFilesComponent.prototype.onSubmit = function () {
        var _this = this;
        return this.uploadVfk()
            .then(function (data) {
            _this.data.vfkId = data.data.vfkId;
            return (_this.csvFiles.length !== 0
                ? _this.uploadCsv(data.data.vfkId)
                : true);
        })
            .then(function () {
            _this.submitCallback.emit();
        });
    };
    EasementCreateAddFilesComponent.prototype.validate = function (file, type) {
        var typeUrl = type === 'csv' ? '/csv/' : '/';
        return this.uploadService.upload({
            url: this.restBaseUrl + '/vfks' + typeUrl + 'validate',
            data: {
                filename: file.fileEntry.name,
                file: file.fileEntry
            },
            headers: __assign({}, this.restangular.defaultHeaders)
        }).progress(function (event) {
            file.progress = (100.0 * event.loaded / event.total);
        });
    };
    EasementCreateAddFilesComponent.prototype.uploadVfk = function () {
        var file = this.vfkFiles[0].fileEntry;
        return this.uploadService.upload({
            url: this.restBaseUrl + '/vfks/create',
            data: {
                filename: file.name,
                file: file
            },
            headers: __assign({}, this.restangular.defaultHeaders)
        });
    };
    EasementCreateAddFilesComponent.prototype.uploadCsv = function (vfkId) {
        var file = this.csvFiles[0].fileEntry;
        return this.uploadService.upload({
            url: this.restBaseUrl + '/vfks/csv/upload?vfkId=' + vfkId,
            data: {
                filename: file.name,
                file: file,
                vfkId: vfkId
            },
            headers: __assign({}, this.restangular.defaultHeaders)
        });
    };
    EasementCreateAddFilesComponent.MSG_FILES_QUANTITY = 'Je možné vložit pouze jeden soubor.';
    EasementCreateAddFilesComponent.MSG_FILES_VALIDATION = 'Vyčkejte, probíhá validace.';
    EasementCreateAddFilesComponent.MSG_FILES_VALID = 'Soubor je validní.';
    EasementCreateAddFilesComponent.MSG_FILES_NOT_VALID = 'Soubor není validní.';
    EasementCreateAddFilesComponent.MSG_GP_VFK_FILES_DEFAULT = 'Vložte soubor přetažením nebo výběrem. Je možné vložit jeden soubor VFK.';
    EasementCreateAddFilesComponent.MSG_GP_VFK_FILES_EXTENSION = 'Soubor musí být ve formátu VFK.';
    EasementCreateAddFilesComponent.MSG_GP_CSV_FILES_DEFAULT = 'Vložte soubor přetažením nebo výběrem. Je možné vložit jeden soubor CSV.';
    EasementCreateAddFilesComponent.MSG_GP_CSV_FILES_EXTENSION = 'Soubor musí být ve formátu CSV.';
    return EasementCreateAddFilesComponent;
}(easement_create_step_component_1.EasementCreateStepComponent));
exports.EasementCreateAddFilesComponent = EasementCreateAddFilesComponent;
