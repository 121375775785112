"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var map_component_1 = require("./components/map/map.component");
exports.mapState = {
    name: 'dimap.project.map',
    data: {
        title: 'Mapa',
    },
    url: '/map?parcelId&titleId&intersectionId&divisionParcelId',
    params: {
        parcelId: { type: 'int' },
        titleId: { type: 'int' },
        intersectionId: { type: 'int' },
        divisionParcelId: { type: 'int' },
    },
    views: {
        'content@dimap.project': {
            component: map_component_1.MapComponent,
        }
    }
};
