"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var parcel_service_1 = require("@app/ps/services/parcel.service");
var _ = require("lodash");
var i0 = require("@angular/core");
var i1 = require("./parcel.service");
var TitleDetailCreateCaseDataService = /** @class */ (function () {
    function TitleDetailCreateCaseDataService(restangular, parcelService) {
        this.restangular = restangular;
        this.parcelService = parcelService;
        this.filterObligations = this.filterObligations.bind(this);
        this.assignCasesObjects = this.assignCasesObjects.bind(this);
        this.assignCasesToOwnerships = this.assignCasesToOwnerships.bind(this);
        this.groupOccupationsByParcel = this.groupOccupationsByParcel.bind(this);
        this.checkLoadedTitleData = this.checkLoadedTitleData.bind(this);
        this.checkLoadedGeneralData = this.checkLoadedGeneralData.bind(this);
        this.loadTitleData = this.loadTitleData.bind(this);
        this.loadGeneralData = this.loadGeneralData.bind(this);
    }
    // filters obligations to be available depending on occupation types
    TitleDetailCreateCaseDataService.prototype.filterObligations = function () {
        var _this = this;
        this.dataObligationsFiltered = _.filter(this.dataObligations, function (obligation) {
            var objectTypes = obligation.objectTypes;
            return objectTypes &&
                (((objectTypes.easementsGeometricPlan || objectTypes.easementsLandTake) && !!_this.dataEasements.length) ||
                    (objectTypes.occupations && !!_this.dataOccupations.length) ||
                    (objectTypes.buildings && !!_this.dataBuildings.length) ||
                    (objectTypes.occupationTypes && _.some(_this.dataOccupations, function (occupation) { return objectTypes.occupationTypes.indexOf(occupation.zabtyp) !== -1; })));
        });
    };
    // assign cases to occupations/easements/ownerships, these data are later filtered by case.obligation to disable some entity selections
    TitleDetailCreateCaseDataService.prototype.assignCasesObjects = function () {
        var _this = this;
        var equals = function (occupation1, occupation2) {
            return occupation1.zabidpar === occupation2.zabidpar && occupation1.zabku === occupation2.zabku && occupation1.zabst === occupation2.zabst && occupation1.zabcis === occupation2.zabcis && occupation1.zabtyp === occupation2.zabtyp;
        };
        this.dataCases.forEach(function (businessCase) {
            // push case to grouped occupations
            businessCase.occupations.forEach(function (occupation) {
                Object.keys(_this.dataOccupationGroups).forEach(function (key) {
                    var group = _this.dataOccupationGroups[key];
                    group.forEach(function (groupItem) {
                        if (groupItem.occupations.some(function (occupation2) { return equals(occupation, occupation2); })) {
                            // make it unique for grouped permanent occupations
                            if (!groupItem.cases.includes(businessCase)) {
                                groupItem.cases.push(businessCase);
                            }
                        }
                    });
                });
            });
            // push case to easements
            businessCase.easements.forEach(function (easement) {
                var foundEasement = _.find(_this.dataEasements, function (dataEasement) {
                    return dataEasement.geometricPlan.id === easement.geometricPlan.id && dataEasement.oznacVb === easement.oznacVb && dataEasement.idpar === easement.idpar;
                });
                if (foundEasement !== undefined) {
                    if (foundEasement.cases === undefined) {
                        foundEasement.cases = [];
                    }
                    foundEasement.cases.push(businessCase);
                }
            });
            // push case to buildings
            businessCase.caseBuildings.forEach(function (caseBuilding) {
                var foundBuilding = _.find(_this.dataBuildings, function (dataBuilding) {
                    return dataBuilding.building.budId === caseBuilding.building.budId;
                });
                if (foundBuilding !== undefined) {
                    if (foundBuilding.cases === undefined) {
                        foundBuilding.cases = [];
                    }
                    foundBuilding.cases.push(businessCase);
                }
            });
        });
    };
    TitleDetailCreateCaseDataService.prototype.assignCasesToOwnerships = function () {
        var _this = this;
        this.dataCases.forEach(function (businessCase) {
            // push case to ownerships
            businessCase.caseOwnerships.forEach(function (caseOwnership) {
                var foundOwnerships = _.filter(_this.dataOwnerships, function (value) { return value.ownership.opsubId === caseOwnership.ownership.opsubId; });
                foundOwnerships.forEach(function (ownership) {
                    ownership.cases.push(businessCase);
                });
            });
        });
    };
    // group permanent occupations by parcel
    TitleDetailCreateCaseDataService.prototype.groupOccupationsByParcel = function () {
        var _this = this;
        this.dataOccupationGroups = this.parcelService.groupOccupationsByParcel(this.dataOccupations);
        Object.keys(this.dataOccupationGroups).forEach(function (key) {
            var group = _this.dataOccupationGroups[key];
            group.forEach(function (occupationGroup) {
                occupationGroup.cases = [];
                occupationGroup.compensationParcelPrices = _.filter(_this.dataParcelPrices, { idpar: occupationGroup.parcel.idpar, opinion: { occupationType: 'T' } });
            });
        });
    };
    TitleDetailCreateCaseDataService.prototype.extendBuildings = function (data) {
        return _.map(data, function (building) {
            return {
                building: building
            };
        });
    };
    // when all data id loaded prepares them for preview
    TitleDetailCreateCaseDataService.prototype.checkLoadedTitleData = function (datas) {
        var _this = this;
        this.dataParcelPrices = this.restangular.stripRestangular(datas[0]);
        this.dataOpinions = [];
        this.dataParcelPrices.forEach(function (parcelPrice) {
            var opinion = _.find(_this.dataOpinions, { id: parcelPrice.opinion.id });
            if (!opinion) {
                opinion = __assign({}, parcelPrice.opinion);
                _this.dataOpinions.push(opinion);
            }
            opinion.parcelPrices.push(parcelPrice);
        });
        this.dataOccupations = this.restangular.stripRestangular(datas[1]);
        this.dataOccupations.forEach(function (occupation) {
            occupation.constructionObjects = _.filter(occupation.constructionObjects, function (co) { return !co.endDate; });
        });
        this.groupOccupationsByParcel();
        this.dataEasements = this.restangular.stripRestangular(datas[2]);
        this.dataCases = this.restangular.stripRestangular(datas[3]);
        this.dataBuildings = this.extendBuildings(this.restangular.stripRestangular(datas[4]));
        this.dataOwnerships = [];
        this.assignCasesObjects();
        return this;
    };
    TitleDetailCreateCaseDataService.prototype.checkLoadedGeneralData = function (datas) {
        this.dataObligations = this.restangular.stripRestangular(datas[0]);
        this.dataTitle = this.restangular.stripRestangular(datas[1]);
        this.dataOwnerships = _.map(this.restangular.stripRestangular(datas[2]), function (value) {
            return {
                id: value.id,
                ownership: value,
                cases: []
            };
        });
        this.dataEasements = datas[3].dataEasements;
        this.dataOccupations = datas[3].dataOccupations;
        this.dataBuildings = datas[3].dataBuildings;
        this.dataCases = datas[3].dataCases;
        this.dataParcelPrices = datas[3].dataParcelPrices;
        this.dataOpinions = datas[3].dataOpinions;
        this.dataOccupationGroups = datas[3].dataOccupationGroups;
        this.filterObligations();
        this.assignCasesToOwnerships();
        return this;
    };
    TitleDetailCreateCaseDataService.prototype.loadTitleData = function (titleId) {
        return Promise.all([
            this.restangular.all('parcel-prices').customPOST({ filter: { filters: { titleId: [titleId], validity: ['valid'], opinionPriceType: ['E', 'O'] } } }),
            this.restangular.all('occupations').customPOST({ filter: { filters: { titleId: [titleId], validityParcelCase: ['valid_with_parcel'], loadCollections: ['constructionObjects', 'parcel.bpejList', 'parcel.parcelProtections', 'parcel.knBudouc.currentParcels'] } } }),
            this.restangular.all('easements').customPOST({
                filter: { filters: { titleId: [titleId], validity: ['valid'], parcelValidity: ['valid'] } },
                attributes: { constructionObjects: {
                        load: true,
                        attributes: {
                            administrator: { load: true },
                        }
                    } }
            }),
            this.restangular.all('cases').customPOST({ filter: { filters: { parcelTitleId: [titleId], cancelStatus: ['notCancelled'], loadCollections: ['caseBuildings', 'constructionObjects', 'occupations', 'easements', 'caseOwnerships'] } } }),
            this.restangular.all('buildings').customPOST({ filter: { filters: { occupationOrEasementExists: [true], titleId: [titleId], validity: ['valid'], differentTitle: [true], loadCollections: ['parcelsWithEasementOrOccupation', 'buildingProtections', 'parcels.parcelProtections', 'parcels.constructionObjects'] } } })
        ]).then(this.checkLoadedTitleData);
    };
    TitleDetailCreateCaseDataService.prototype.loadGeneralData = function (titleId, titleDataPromise) {
        return Promise.all([
            this.restangular.all('obligations').customPOST({ filter: { filters: {} } }),
            this.restangular.one('titles', titleId).get(),
            this.restangular.all('ownerships').customPOST({
                filter: { filters: { titleId: [titleId], validity: ['valid'] } },
                attributes: { parentOwnership: { load: true } }
            }),
            titleDataPromise
        ]).then(this.checkLoadedGeneralData);
    };
    TitleDetailCreateCaseDataService.ngInjectableDef = i0.defineInjectable({ factory: function TitleDetailCreateCaseDataService_Factory() { return new TitleDetailCreateCaseDataService(i0.inject("Restangular"), i0.inject(i1.ParcelService)); }, token: TitleDetailCreateCaseDataService, providedIn: "root" });
    return TitleDetailCreateCaseDataService;
}());
exports.TitleDetailCreateCaseDataService = TitleDetailCreateCaseDataService;
