"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("../../../../common/components/checkbox/checkbox.component.ngfactory");
var i2 = require("../../../../common/components/checkbox/checkbox.component");
var i3 = require("@angular/common");
var i4 = require("../../../../common/components/button/button.component.ngfactory");
var i5 = require("../../../../common/components/button/button.component");
var i6 = require("../../../../common/directives/loading.directive");
var i7 = require("./create-case-select-area.component");
var i8 = require("../../../../common/services/auth.service");
var i9 = require("../../../../common/services/list.service");
var styles_CreateCaseSelectAreaComponent = [];
var RenderType_CreateCaseSelectAreaComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CreateCaseSelectAreaComponent, data: {} });
exports.RenderType_CreateCaseSelectAreaComponent = RenderType_CreateCaseSelectAreaComponent;
function View_CreateCaseSelectAreaComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "checklist-item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleSelectionGeometricPlan(_co.data.geometricPlansSelected, _v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "gmt-checkbox", [["gmtType", "multiselect"]], null, null, null, i1.View_CheckboxComponent_0, i1.RenderType_CheckboxComponent)), i0.ɵdid(2, 114688, null, 0, i2.CheckboxComponent, [], { gmtChecked: [0, "gmtChecked"], gmtDisabled: [1, "gmtDisabled"], gmtType: [2, "gmtType"] }, null), (_l()(), i0.ɵted(3, null, [" ", "", ", obsahuje celkem ", " VB "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isChecked(_co.data.geometricPlansSelected, _v.context.$implicit); var currVal_1 = _co.isDisabled(_v.context.$implicit); var currVal_2 = "multiselect"; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_3 = (_v.context.$implicit.kutx ? (_v.context.$implicit.kutx + " - GP \u010D\u00EDslo: ") : ""); var currVal_4 = _v.context.$implicit.cis; var currVal_5 = _v.context.$implicit.countEasements; _ck(_v, 3, 0, currVal_3, currVal_4, currVal_5); }); }
function View_CreateCaseSelectAreaComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "checklist"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CreateCaseSelectAreaComponent_2)), i0.ɵdid(3, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 3, "div", [["class", "mt-10"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 2, "gmt-button", [], null, null, null, i4.View_ButtonComponent_0, i4.RenderType_ButtonComponent)), i0.ɵdid(6, 114688, null, 0, i5.ButtonComponent, ["ErrorHandlerService", i0.ChangeDetectorRef], { clickAction: [0, "clickAction"], gmtDisabled: [1, "gmtDisabled"] }, null), (_l()(), i0.ɵted(-1, 0, ["Dal\u0161\u00ED"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dataConstructionObject.geometricPlans; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.submit; var currVal_2 = !_co.data.geometricPlansSelected.length; _ck(_v, 6, 0, currVal_1, currVal_2); }, null); }
function View_CreateCaseSelectAreaComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [], [[8, "className", 0]], null, null, null, null)), i0.ɵdid(1, 16384, null, 0, i6.LoadingDirective, [i0.ElementRef], { loading: [0, "loading"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CreateCaseSelectAreaComponent_1)), i0.ɵdid(3, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.loading; _ck(_v, 1, 0, currVal_1); var currVal_2 = !_co.loading; _ck(_v, 3, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).elementClass; _ck(_v, 0, 0, currVal_0); }); }
exports.View_CreateCaseSelectAreaComponent_0 = View_CreateCaseSelectAreaComponent_0;
function View_CreateCaseSelectAreaComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "construction-object-detail-create-case-select-area", [], null, null, null, View_CreateCaseSelectAreaComponent_0, RenderType_CreateCaseSelectAreaComponent)), i0.ɵdid(1, 114688, null, 0, i7.CreateCaseSelectAreaComponent, [i8.AuthService, i9.ListService, "Restangular", "ConfirmService"], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_CreateCaseSelectAreaComponent_Host_0 = View_CreateCaseSelectAreaComponent_Host_0;
var CreateCaseSelectAreaComponentNgFactory = i0.ɵccf("construction-object-detail-create-case-select-area", i7.CreateCaseSelectAreaComponent, View_CreateCaseSelectAreaComponent_Host_0, { constructionObjectId: "constructionObjectId", data: "data", hasStep: "hasStep" }, { submitCallback: "submitCallback", backCallback: "backCallback" }, []);
exports.CreateCaseSelectAreaComponentNgFactory = CreateCaseSelectAreaComponentNgFactory;
