"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var pageable_list_service_1 = require("@app/common/services/pageable.list.service");
var ticket_status_enum_1 = require("@app/sv/enums/ticket-status.enum");
var username_pipe_1 = require("@app/common/pipes/username.pipe");
var auth_service_1 = require("@app/common/services/auth.service");
var organizational_unit_name_pipe_1 = require("@app/common/pipes/organizational-unit-name.pipe");
var document_type_fullname_pipe_1 = require("@app/sv/pipes/document-type-fullname.pipe");
var role_enum_1 = require("@app/common/enums/role.enum");
var TicketListComponent = /** @class */ (function () {
    function TicketListComponent(helpIds, APP_BRAND, restangular, configurationRestangular, settingsRestangular, restangularSymap, organizationalUnitNamePipe, authService, usernamePipe, documentTypeFullname, pageableListService) {
        this.helpIds = helpIds;
        this.APP_BRAND = APP_BRAND;
        this.restangular = restangular;
        this.configurationRestangular = configurationRestangular;
        this.settingsRestangular = settingsRestangular;
        this.restangularSymap = restangularSymap;
        this.organizationalUnitNamePipe = organizationalUnitNamePipe;
        this.authService = authService;
        this.usernamePipe = usernamePipe;
        this.documentTypeFullname = documentTypeFullname;
        this.pageableListService = pageableListService;
        this.statusOptions = ticket_status_enum_1.ticketStatusOptions;
        this.statusEnum = ticket_status_enum_1.TicketStatusEnum;
        this.documentTypeOptions = [];
        this.sectionOptions = [
            { id: 'Úsek výstavby', name: 'Úsek výstavby' },
            { id: 'Úsek provozní', name: 'Úsek provozní' },
        ];
        this.organizationalUnitOptions = [];
        this.obligationOptions = [];
        this.userFilter = {
            filters: {
                searchText: {
                    values: [],
                },
                applications: {
                    values: ['VZORY'],
                },
                permission: {
                    values: ['template_new']
                },
                roles: {
                    values: [role_enum_1.RoleEnum.VZORY_ZADAVATEL]
                }
            },
        };
        this.loading = true;
        this.organizationalUnitCode = [];
    }
    TicketListComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.loadOptions()];
                    case 1:
                        _a.sent();
                        this.loading = false;
                        this.initializeList();
                        return [2 /*return*/];
                }
            });
        });
    };
    TicketListComponent.prototype.uiOnParamsChanged = function (changedParams, $transition$) {
        this.pageableList.uiOnParamsChanged(changedParams, $transition$);
    };
    TicketListComponent.prototype.onToggleFinished = function () {
        var finished = this.list.filter.filters.finished;
        finished.values = (finished.values.length ? [] : [false]);
    };
    TicketListComponent.prototype.onUserChanged = function (event) {
        this.createdByUsers = event.newValue;
        this.list.filter.filters.userIdText = this.createdByUsers
            ? this.createdByUsers.map(function (u) { return u.idText; })
            : [];
    };
    TicketListComponent.prototype.getStatusDate = function (ticket) {
        switch (ticket.status) {
            case ticket_status_enum_1.TicketStatusEnum.CREATED:
                return ticket.createdDate;
            case ticket_status_enum_1.TicketStatusEnum.SENT_TO_CONFIRMATION:
                return ticket.sentToConfirmationDate;
            case ticket_status_enum_1.TicketStatusEnum.APPROVED:
                return ticket.approvedDate;
            case ticket_status_enum_1.TicketStatusEnum.DISAPPROVED:
                return ticket.disapprovedDate;
            case ticket_status_enum_1.TicketStatusEnum.TEMPLATE_SENT_TO_PROCESSING:
                return ticket.templateSentToProcessingDate;
            case ticket_status_enum_1.TicketStatusEnum.IMPORTED:
                return ticket.importedDate;
            case ticket_status_enum_1.TicketStatusEnum.DELETED:
                return ticket.deletedDate;
        }
    };
    TicketListComponent.prototype.initializeList = function () {
        this.pageableList = this.pageableListService.get('tickets', this.getDefaultFilter(), 'ticketsFilter', this.restangular, { actualizedSample: { load: true } });
        this.list = this.pageableList.list;
        this.pageableList.load();
    };
    TicketListComponent.prototype.loadOptions = function () {
        var _this = this;
        var p1 = this.restangular
            .all('document-types')
            .getList()
            .then(function (list) {
            _this.documentTypeOptions = list.plain();
        });
        var p2 = this.configurationRestangular
            .all('organizational-units').customPOST({
            filter: {
                limit: null,
                sortOrder: [{ sortBy: 'code', direction: 'asc' }],
                filters: {
                    cancelled: {
                        values: [false],
                    },
                }
            }
        })
            .then(function (data) {
            _this.organizationalUnitOptions = data.plain();
        });
        var p3 = this.restangularSymap
            .all('obligations')
            .getList()
            .then(function (list) {
            _this.obligationOptions = list.plain();
        });
        this.organizationalUnitCode = this.authService.hasPermissionOnProject('template_aproval,template_manage')
            ? []
            : this.authService.getProjectsByPermission();
        return Promise.all([p1, p2, p3]);
    };
    TicketListComponent.prototype.getDefaultFilter = function () {
        return {
            filters: {
                searchText: { values: [{ id: '' }] },
                documentTypeId: { values: [] },
                obligationTypes: { values: [] },
                status: { values: [] },
                organizationalUnitId: { values: [] },
                organizationalUnitCode: { values: this.organizationalUnitCode },
                section: { values: [] },
                finished: { values: [false] },
                userIdText: { values: [] },
            }
        };
    };
    return TicketListComponent;
}());
exports.TicketListComponent = TicketListComponent;
