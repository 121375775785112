"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var common_address_model_1 = require("@app/ps/models/common-address.model");
var AddressFormComponent = /** @class */ (function () {
    function AddressFormComponent() {
        this.editable = true;
        this.editableToggle = false;
        this.heading = 'Adresa';
        this.collapsed = true;
        this.requiredFields = [];
        this.toggle = new core_1.EventEmitter();
    }
    AddressFormComponent.prototype.ngOnInit = function () { };
    AddressFormComponent.prototype.toggleCollapsed = function () {
        if (!this.editable) {
            return;
        }
        this.collapsed = !this.collapsed;
        this.toggle.emit(this.collapsed);
    };
    AddressFormComponent.prototype.isRequired = function (fieldName) {
        return this.requiredFields.includes(fieldName);
    };
    return AddressFormComponent;
}());
exports.AddressFormComponent = AddressFormComponent;
