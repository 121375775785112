"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("../../../../common/directives/loading.directive");
var i2 = require("@angular/common");
var i3 = require("../../../../common/components/button/button.component.ngfactory");
var i4 = require("../../../../common/components/button/button.component");
var i5 = require("../../../../common/components/vfze-problems/vfze-problems.component.ngfactory");
var i6 = require("../../../../common/components/vfze-problems/vfze-problems.component");
var i7 = require("./vfze-import-validation.component");
var styles_VfzeImportValidationComponent = [];
var RenderType_VfzeImportValidationComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_VfzeImportValidationComponent, data: {} });
exports.RenderType_VfzeImportValidationComponent = RenderType_VfzeImportValidationComponent;
function View_VfzeImportValidationComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "centered-text jumbotron"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Prob\u00EDh\u00E1 validace VFZE "])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "div", [], [[8, "className", 0]], null, null, null, null)), i0.ɵdid(3, 16384, null, 0, i1.LoadingDirective, [i0.ElementRef], { loading: [0, "loading"] }, null)], function (_ck, _v) { var currVal_1 = true; _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 3).elementClass; _ck(_v, 2, 0, currVal_0); }); }
function View_VfzeImportValidationComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "p", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.validationResponse.validationMessage; _ck(_v, 1, 0, currVal_0); }); }
function View_VfzeImportValidationComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "p", [["class", "success-message text-center"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Validace \u00FAsp\u011B\u0161n\u011B dokon\u010Dena, bez chyb. "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_VfzeImportValidationComponent_4)), i0.ɵdid(4, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.validationResponse.validationMessage; _ck(_v, 4, 0, currVal_0); }, null); }
function View_VfzeImportValidationComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "p", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.validationResponse.validationMessage; _ck(_v, 1, 0, currVal_0); }); }
function View_VfzeImportValidationComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "p", [["class", "error-message text-center"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Validace skon\u010Dila ne\u00FAsp\u011B\u0161n\u011B. "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_VfzeImportValidationComponent_6)), i0.ɵdid(4, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.validationResponse.validationMessage; _ck(_v, 4, 0, currVal_0); }, null); }
function View_VfzeImportValidationComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "a", [["class", "button ml-10"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.xmlErrorsDisplayed = !_co.xmlErrorsDisplayed) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.xmlErrorsDisplayed ? "Skr\u00FDt chyby v XML" : "Zobrazit chyby v XML"); _ck(_v, 1, 0, currVal_0); }); }
function View_VfzeImportValidationComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "div", [["class", "jumbotron"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "div", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_VfzeImportValidationComponent_3)), i0.ɵdid(3, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_VfzeImportValidationComponent_5)), i0.ɵdid(5, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(6, 0, null, null, 5, "div", [["class", "text-center mt-20"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 2, "gmt-button", [], null, null, null, i3.View_ButtonComponent_0, i3.RenderType_ButtonComponent)), i0.ɵdid(8, 114688, null, 0, i4.ButtonComponent, ["ErrorHandlerService", i0.ChangeDetectorRef], { clickAction: [0, "clickAction"] }, null), (_l()(), i0.ɵted(-1, 0, ["St\u00E1hnout protokol validace"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_VfzeImportValidationComponent_7)), i0.ɵdid(11, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isResponseSuccessfully(); _ck(_v, 3, 0, currVal_0); var currVal_1 = !_co.isResponseSuccessfully(); _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.onSaveValidationResponse; _ck(_v, 8, 0, currVal_2); var currVal_3 = _co.containResponseErrors(); _ck(_v, 11, 0, currVal_3); }, null); }
function View_VfzeImportValidationComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "mt-20"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "vfze-problems", [], null, null, null, i5.View_VfzeProblemsComponent_0, i5.RenderType_VfzeProblemsComponent)), i0.ɵdid(2, 114688, null, 0, i6.VfzeProblemsComponent, [], { validationResponse: [0, "validationResponse"], vfzeSource: [1, "vfzeSource"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.validationResponse; var currVal_1 = _co.vfzeFileContent; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_VfzeImportValidationComponent_9(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "text-left"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "p", [["class", "error-message text-center"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" VFZE je nevalidn\u00ED. Import dat se nemus\u00ED poda\u0159it pokud je struktura XML po\u0161kozena. "]))], null, null); }
function View_VfzeImportValidationComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 12, "div", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_VfzeImportValidationComponent_1)), i0.ɵdid(2, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_VfzeImportValidationComponent_2)), i0.ɵdid(4, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_VfzeImportValidationComponent_8)), i0.ɵdid(6, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_VfzeImportValidationComponent_9)), i0.ɵdid(8, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(9, 0, null, null, 3, "div", [["class", "mt-10"]], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 2, "gmt-button", [], null, null, null, i3.View_ButtonComponent_0, i3.RenderType_ButtonComponent)), i0.ɵdid(11, 114688, null, 0, i4.ButtonComponent, ["ErrorHandlerService", i0.ChangeDetectorRef], { clickAction: [0, "clickAction"], gmtDisabled: [1, "gmtDisabled"] }, null), (_l()(), i0.ɵted(-1, 0, ["Importovat Data z VFZE"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.validationResponse; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.validationResponse; _ck(_v, 4, 0, currVal_1); var currVal_2 = ((_co.xmlErrorsDisplayed && _co.validationResponse) && _co.vfzeFileContent); _ck(_v, 6, 0, currVal_2); var currVal_3 = (_co.validationResponse && !_co.isResponseSuccessfully()); _ck(_v, 8, 0, currVal_3); var currVal_4 = _co.onSubmit; var currVal_5 = !_co.validationResponse; _ck(_v, 11, 0, currVal_4, currVal_5); }, null); }
exports.View_VfzeImportValidationComponent_0 = View_VfzeImportValidationComponent_0;
function View_VfzeImportValidationComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "vfze-import-validation", [], null, null, null, View_VfzeImportValidationComponent_0, RenderType_VfzeImportValidationComponent)), i0.ɵdid(1, 114688, null, 0, i7.VfzeImportValidationComponent, ["Restangular", "Upload", "ErrorHandlerService"], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_VfzeImportValidationComponent_Host_0 = View_VfzeImportValidationComponent_Host_0;
var VfzeImportValidationComponentNgFactory = i0.ɵccf("vfze-import-validation", i7.VfzeImportValidationComponent, View_VfzeImportValidationComponent_Host_0, { data: "data" }, { backCallback: "backCallback", submitCallback: "submitCallback" }, []);
exports.VfzeImportValidationComponentNgFactory = VfzeImportValidationComponentNgFactory;
