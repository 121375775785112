"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var _ = require("lodash");
var statistics_service_1 = require("@app/ps/services/statistics.service");
var common_1 = require("@angular/common");
var list_service_1 = require("@app/common/services/list.service");
var angular_1 = require("@uirouter/angular");
var construction_object_name_pipe_1 = require("@app/common/pipes/construction-object-name.pipe");
var auth_service_1 = require("@app/common/services/auth.service");
var dialog_service_1 = require("@app/dialog/dialog.service");
var MpvStatisticsComponent = /** @class */ (function () {
    function MpvStatisticsComponent(restangular, restangularSymap, APP_BRAND, localStorageService, restangularConfig, dialogService, authService, statisticsService, datePipe, listService, stateService, constructionObjectNamePipe) {
        this.restangular = restangular;
        this.restangularSymap = restangularSymap;
        this.APP_BRAND = APP_BRAND;
        this.localStorageService = localStorageService;
        this.restangularConfig = restangularConfig;
        this.dialogService = dialogService;
        this.authService = authService;
        this.statisticsService = statisticsService;
        this.datePipe = datePipe;
        this.listService = listService;
        this.stateService = stateService;
        this.constructionObjectNamePipe = constructionObjectNamePipe;
        this.project = this.authService.getActualProject();
        this.statisticsOptions = [];
        this.totalLoading = true;
        this.constructionObjectFilter = {
            validity: { values: ['valid'] },
            areaId: { values: [] },
        };
        this.totalCategoryTabs = [
            {
                name: 'Celkový počet všech případů'
            },
            {
                name: 'Nezaložené případy'
            },
            {
                name: 'Případy v řešení'
            },
            {
                name: 'Vypořádané případy'
            },
            {
                name: 'Vyvlastnění'
            },
        ];
        this.entityTabs = [
            {
                name: 'Případy',
                id: 'cases',
                sref: 'cases',
                totalCategoryName: 'Celkový počet případů',
                totalCategoryNameRsd: 'Počet případů',
            },
            {
                name: 'Listy vlastnictví',
                id: 'titles',
                sref: 'titles',
                params: { statisticsEntityTab: 'titles' },
                totalCategoryName: 'Celkový počet listů vlastnictví',
                totalCategoryNameRsd: 'Počet listů vlastnictví',
            },
            {
                name: 'Parcely',
                id: 'parcels',
                sref: 'parcels',
                totalCategoryName: 'Celkový počet parcel',
                totalCategoryNameRsd: 'Počet parcel',
            },
            {
                name: 'Vlastníci',
                id: 'subjects',
                sref: 'owners',
                totalCategoryName: 'Celkový počet vlastníků',
                totalCategoryNameRsd: 'Počet vlastníků',
            },
        ];
        this.stateParams = this.stateService.params;
        this.fetchStatusData = this.fetchStatusData.bind(this);
        this.prepareEntityTab = this.prepareEntityTab.bind(this);
        this.changeEntity = this.changeEntity.bind(this);
    }
    MpvStatisticsComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var totalSectionCollapsed, casesSectionCollapsed, otherSectionCollapsed, area, constructionObject;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        totalSectionCollapsed = this.localStorageService.get('totalSectionCollapsed');
                        casesSectionCollapsed = this.localStorageService.get('casesSectionCollapsed');
                        otherSectionCollapsed = this.localStorageService.get('otherSectionCollapsed');
                        area = this.statisticsService.getStorageKeyValue('area');
                        constructionObject = this.statisticsService.getStorageKeyValue('constructionObject');
                        this.totalSectionCollapsed = totalSectionCollapsed === undefined ? false : totalSectionCollapsed;
                        this.casesSectionCollapsed = casesSectionCollapsed === undefined ? false : casesSectionCollapsed;
                        this.otherSectionCollapsed = otherSectionCollapsed === undefined ? false : otherSectionCollapsed;
                        this.area = area ? JSON.parse(area) : undefined;
                        this.constructionObject = constructionObject ? JSON.parse(constructionObject) : undefined;
                        // load data
                        return [4 /*yield*/, this.loadStatistics()];
                    case 1:
                        // load data
                        _a.sent();
                        if (this.statisticsList.list.length === 0) {
                            return [2 /*return*/];
                        }
                        this.loadExpropriationObligationIds();
                        this.fetchTotalData();
                        this.setupParams();
                        this.fetchStatusData(this.statisticsId);
                        return [2 /*return*/];
                }
            });
        });
    };
    MpvStatisticsComponent.prototype.ngOnDestroy = function () {
        if (this.xhrCancelResolve) {
            this.xhrCancelResolve();
        }
        if (this.statusDataList) {
            this.listService.cancelFetch(this.statusDataList);
        }
    };
    MpvStatisticsComponent.prototype.fetchStatusData = function (statisticsId) {
        var _this = this;
        if (this.statusDataList) {
            this.listService.cancelFetch(this.statusDataList);
        }
        this.activeStatistics = _.find(this.statisticsList.list, { id: statisticsId });
        this.selectedStatisticsOption = _.find(this.statisticsOptions, { id: statisticsId });
        this.statisticsId = statisticsId;
        this.refreshState();
        this.currentData = undefined;
        this.expropriation = undefined;
        this.categoryTabs = [];
        this.activeStatistics.categories.forEach(function (tab, index) {
            _this.categoryTabs.push({
                name: tab.name,
                nameAlternative: tab.nameAlternative,
                splitTab: tab.split,
            });
            if (tab.split) {
                _this.categoryTabs.push({
                    name: tab.splitName,
                    nameAlternative: tab.splitNameAlternative,
                });
            }
        });
        this.categoryTabs.unshift({
            name: this.activeStatistics.defaultCategory,
            nameAlternative: this.activeStatistics.defaultCategoryAlternative,
        });
        this.categoryTabs.unshift({
            name: null,
        });
        var curDate = this.datePipe.transform(new Date(), 'y-MM-dd');
        this.statusDataList = this.listService.createList('statistics/generic', {
            filters: {
                dateTo: [curDate],
                statisticsId: [this.statisticsId],
                areaId: this.area ? [this.area.id] : [],
                constructionObjectId: this.constructionObject ? [this.constructionObject.id] : [],
            },
        });
        return this.listService.fetchResult(this.statusDataList).then(function (data) {
            _this.currentData = _.keyBy(_.map(_this.entityTabs, function (tab) {
                return _this.prepareEntityTab(data, data.list[0].plot[curDate], tab);
            }), function (tabData) { return tabData.id; });
            return _this.restangular.one("statistics/" + statisticsId + "/expropriation").customGET('', {
                areaId: _this.area ? _this.area.id : undefined,
                constructionObjectId: _this.constructionObject ? _this.constructionObject.id : undefined
            });
        }).then(function (expropriation) {
            _this.expropriation = expropriation;
        }).catch(function (e) { });
    };
    MpvStatisticsComponent.prototype.changeEntity = function (id) {
        this.entityTab = id;
        this.refreshState();
    };
    MpvStatisticsComponent.prototype.getTotalCategoryName = function (entityId) {
        var entity = _.find(this.entityTabs, { id: entityId });
        return this.APP_BRAND.NAME === 'RSD' ? entity.totalCategoryNameRsd : entity.totalCategoryName;
    };
    MpvStatisticsComponent.prototype.onSectionCollapse = function (name) {
        var collapsed = this[name];
        this[name] = !collapsed;
        this.localStorageService.set(name, this[name]);
    };
    MpvStatisticsComponent.prototype.onCadastreChanged = function () {
        this.constructionObjectFilter.areaId.values = this.area ? [this.area.id] : [];
        this.constructionObject = undefined;
        this.fetchStatusData(this.statisticsId);
    };
    MpvStatisticsComponent.prototype.loadStatistics = function () {
        var _this = this;
        this.statisticsList = this.statisticsService.getStatistics();
        return this.listService.fetchResult(this.statisticsList).then(function () {
            _this.statisticsList.list = _this.statisticsList.list.filter(function (s) { return s.available; });
            var options = [];
            _this.statisticsList.list.forEach(function (s, i) {
                options.push({ id: s.id, value: s.name });
                // resolve categories with same id
                var id = 1;
                s.categories.forEach(function (category) {
                    category.index = id++;
                });
            });
            _this.statisticsOptions = options;
        });
    };
    MpvStatisticsComponent.prototype.loadExpropriationObligationIds = function () {
        var _this = this;
        var obligations = this.listService.createList('obligations', { filters: { obligationType: ['FulfillmentOfDecisionExpropriationLiability', 'TemporaryExpropriationLiability'] } });
        return this.listService.fetchResult(obligations).then(function () {
            _this.expropriationObligationIds = obligations.list.map(function (o) { return o.id; });
        });
    };
    MpvStatisticsComponent.prototype.fetchTotalData = function () {
        return __awaiter(this, void 0, void 0, function () {
            var cancelPromise;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: 
                    // load dashboard settings
                    return [4 /*yield*/, this.restangularConfig.one("dashboard/" + this.project.key)
                            .get()
                            .then(function (data) { return data; }, function (result) {
                            if (result.status === 404) {
                                return null;
                            }
                            return Promise.reject(result);
                        })
                            .then(function (data) {
                            if (!data || !data.show) {
                                _this.totalPrecheck = false;
                                return;
                            }
                            data = data.plain();
                            _this.totalPrecheck = true;
                            _this.statisticsEntityFilterKeyFinished = [];
                            _this.statisticsEntityFilterKeyUnfinished = [];
                            var dashboardStats = ['statisticsPermanent', 'statisticsUnderOneYear', 'statisticsOverOneYear', 'statisticsEasement'];
                            dashboardStats.forEach(function (s) {
                                // resolve finished category
                                var statId = s + 'Id';
                                var catId = s + 'CategoryId';
                                var statistic = _.find(_this.statisticsList.list, { id: data[statId] });
                                if (statistic) {
                                    statistic.categories.forEach(function (c) {
                                        // final categories
                                        if (c.index === data[catId]) {
                                            _this.statisticsEntityFilterKeyFinished.push(data[statId] + '_' + (c.id + 1) + '____' + (c.split ? '1' : '0'));
                                            // other categories
                                        }
                                        else {
                                            _this.statisticsEntityFilterKeyUnfinished.push(data[statId] + '_' + (c.id + 1) + '____0');
                                        }
                                    });
                                }
                            });
                        })];
                    case 1:
                        // load dashboard settings
                        _a.sent();
                        // load dashboard data
                        if (!this.totalPrecheck) {
                            return [2 /*return*/];
                        }
                        cancelPromise = new Promise(function (resolve, reject) {
                            _this.xhrCancelResolve = resolve;
                        });
                        this.restangularSymap.one('dashboard', this.project.key)
                            .withHttpConfig({ timeout: cancelPromise })
                            .get()
                            .then(function (projectData) {
                            _this.totalLoading = false;
                            if (!projectData) {
                                _this.totalAvailable = false;
                                return;
                            }
                            _this.totalAvailable = true;
                            _this.totalCategoryTabs[0].value = projectData.total;
                            _this.totalCategoryTabs[1].value = projectData.toCreate;
                            _this.totalCategoryTabs[1].total = projectData.total;
                            _this.totalCategoryTabs[2].value = projectData.inProgress;
                            _this.totalCategoryTabs[2].total = projectData.total;
                            _this.totalCategoryTabs[3].value = projectData.done;
                            _this.totalCategoryTabs[3].total = projectData.total;
                            _this.totalCategoryTabs[4].value = projectData.expropriationTotal;
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    MpvStatisticsComponent.prototype.setupParams = function () {
        var statisticsId = this.stateParams.statisticsStatistics || this.statisticsService.getStorageKeyValue('statisticsId');
        var statistics = _.find(this.statisticsList.list, { id: statisticsId });
        var entityTab = this.stateParams.statisticsEntityTab || this.statisticsService.getStorageKeyValue('entityTab') || 'titles';
        this.statisticsId = statistics ? statisticsId : this.statisticsList.list[0].id;
        this.entityTab = entityTab === 'cases' ? 'titles' : entityTab;
        this.refreshState();
    };
    MpvStatisticsComponent.prototype.prepareEntityTab = function (data, currentStatuses, tab) {
        var _this = this;
        var dataR = {
            id: tab.id,
            data: [],
        };
        var index = 0;
        var totalStatus = currentStatuses ? _.find(currentStatuses, { status: 0 }) : null;
        data.list[0].categories.forEach(function (categorySetting, categoryIndex) {
            var category = _.find(currentStatuses, { status: categoryIndex + 1 });
            dataR.data.push({
                value: currentStatuses && category ? category[tab.id + 'InStatus'] : 0,
                url: tab.sref && (tab.id !== 'cases' || index !== 0)
                    ? _this.stateService.href('symap.project.' + tab.sref, { sef: category ? [category.entityFilterKey + (categorySetting.split ? '_0' : '')] : null })
                    : null,
                total: totalStatus ? totalStatus[tab.id] : 0,
            });
            index++;
            if (categorySetting.split) {
                dataR.data.push({
                    value: currentStatuses && category ? (tab.id === 'cases' ? category['casesInStatus'] : category[tab.id + 'OnlyInStatus']) : 0,
                    url: tab.sref && (tab.id !== 'cases' || index !== 0)
                        ? _this.stateService.href('symap.project.' + tab.sref, { sef: category ? [category.entityFilterKey + '_1'] : null, })
                        : null,
                    total: totalStatus ? totalStatus[tab.id] : 0,
                });
                dataR.data[dataR.data.length - 2].value = dataR.data[dataR.data.length - 2].value - dataR.data[dataR.data.length - 1].value;
                index++;
            }
        });
        dataR.data.unshift({
            value: totalStatus ? totalStatus[tab.id] : 0,
            url: totalStatus && tab.sref && tab.id !== 'cases'
                ? this.stateService.href('symap.project.' + tab.sref, { sef: [totalStatus.entityFilterKey] })
                : null,
        });
        return dataR;
    };
    MpvStatisticsComponent.prototype.refreshState = function () {
        this.statisticsService.updateStorageValue({
            statisticsId: this.statisticsId,
            entityTab: this.entityTab,
            area: this.area ? JSON.stringify(this.area) : undefined,
            constructionObject: this.constructionObject ? JSON.stringify(this.constructionObject) : undefined,
        });
        this.stateService.go('.', { statisticsStatistics: this.statisticsId, statisticsEntityTab: this.entityTab });
    };
    return MpvStatisticsComponent;
}());
exports.MpvStatisticsComponent = MpvStatisticsComponent;
