"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("../../../../common/components/switch/switch.component.ngfactory");
var i2 = require("../../../../common/components/switch/switch.component");
var i3 = require("./map-module-intersection.component");
var i4 = require("../../../../map/services/map.service");
var i5 = require("@uirouter/angular");
var i6 = require("../../services/intersection.service");
var styles_MapModuleIntersectionComponent = [];
var RenderType_MapModuleIntersectionComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MapModuleIntersectionComponent, data: {} });
exports.RenderType_MapModuleIntersectionComponent = RenderType_MapModuleIntersectionComponent;
function View_MapModuleIntersectionComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "content measure-module"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["V\u00FDb\u011Br parcel geometri\u00ED v map\u011B."])), (_l()(), i0.ɵeld(3, 0, null, null, 1, "gmt-switch", [], null, [[null, "selectedIdChange"], [null, "select"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectedIdChange" === en)) {
        var pd_0 = ((_co.intersectionOptionsSelections = $event) !== false);
        ad = (pd_0 && ad);
    } if (("select" === en)) {
        var pd_1 = (_co.onChangedIntersectionType($event.id) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_SwitchComponent_0, i1.RenderType_SwitchComponent)), i0.ɵdid(4, 114688, null, 0, i2.SwitchComponent, [], { options: [0, "options"], selectedId: [1, "selectedId"] }, { select: "select", selectedIdChange: "selectedIdChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.intersectionOptions; var currVal_1 = _co.intersectionOptionsSelections; _ck(_v, 4, 0, currVal_0, currVal_1); }, null); }
exports.View_MapModuleIntersectionComponent_0 = View_MapModuleIntersectionComponent_0;
function View_MapModuleIntersectionComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "map-module-intersection", [], null, null, null, View_MapModuleIntersectionComponent_0, RenderType_MapModuleIntersectionComponent)), i0.ɵdid(1, 245760, null, 0, i3.MapModuleIntersectionComponent, [i4.MapService, i5.StateService, i6.IntersectionService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_MapModuleIntersectionComponent_Host_0 = View_MapModuleIntersectionComponent_Host_0;
var MapModuleIntersectionComponentNgFactory = i0.ɵccf("map-module-intersection", i3.MapModuleIntersectionComponent, View_MapModuleIntersectionComponent_Host_0, { mapId: "mapId" }, {}, []);
exports.MapModuleIntersectionComponentNgFactory = MapModuleIntersectionComponentNgFactory;
