"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var i0 = require("@angular/core");
var SubjectNamePipe = /** @class */ (function () {
    function SubjectNamePipe() {
    }
    SubjectNamePipe.prototype.transform = function (subject) {
        if (!subject) {
            return '';
        }
        var words = [];
        if (subject.nazev) {
            words.push(subject.nazev);
        }
        if (subject.prijmeni) {
            words.push(subject.prijmeni);
        }
        if (subject.jmeno) {
            words.push(subject.jmeno);
        }
        var tmp = [];
        if (words.length !== 0) {
            tmp.push(words.join(" "));
        }
        words = [];
        if (subject.titulPredJmenem) {
            words.push(subject.titulPredJmenem);
        }
        if (subject.titulZaJmenem) {
            words.push(subject.titulZaJmenem);
        }
        if (words.length !== 0) {
            tmp.push(words.join(" "));
        }
        return tmp.join(", ");
    };
    SubjectNamePipe.ngInjectableDef = i0.defineInjectable({ factory: function SubjectNamePipe_Factory() { return new SubjectNamePipe(); }, token: SubjectNamePipe, providedIn: "root" });
    return SubjectNamePipe;
}());
exports.SubjectNamePipe = SubjectNamePipe;
