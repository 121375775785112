"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("@angular/common");
var i2 = require("../../../common/directives/tooltip/tooltip.directive");
var i3 = require("./cell-area.component");
var i4 = require("../../map/services/map-property-status.service");
var styles_CellAreaComponent = [];
var RenderType_CellAreaComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CellAreaComponent, data: {} });
exports.RenderType_CellAreaComponent = RenderType_CellAreaComponent;
function View_CellAreaComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""])), i0.ɵppd(2, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "fill-content flexbox-container flex-centered p-10 ", _co.propertyStatusClass, ""); _ck(_v, 0, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i0.ɵnov(_v.parent, 0), _co.item.area)); _ck(_v, 1, 0, currVal_1); }); }
function View_CellAreaComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_CellAreaComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CellAreaComponent_3)), i0.ɵdid(2, 540672, null, 0, i1.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = i0.ɵnov(_v.parent, 1); _ck(_v, 2, 0, currVal_0); }, null); }
function View_CellAreaComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_CellAreaComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 3, "div", [["class", "tooltip-hover-area fill-content"]], null, [[null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("mouseenter" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).mouseEnter() !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i0.ɵnov(_v, 1).closeTooltip() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 81920, null, 0, i2.TooltipDirective, [i0.ElementRef, "WindowDimensionService", i0.Renderer2, i0.ComponentFactoryResolver, i0.ViewContainerRef, i0.Injector], { content: [0, "content"], data: [1, "data"], activeContent: [2, "activeContent"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CellAreaComponent_5)), i0.ɵdid(3, 540672, null, 0, i1.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tooltipComponent; var currVal_1 = _co.tooltipData; var currVal_2 = true; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = i0.ɵnov(_v.parent, 1); _ck(_v, 3, 0, currVal_3); }, null); }
function View_CellAreaComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i1.DecimalPipe, [i0.LOCALE_ID]), (_l()(), i0.ɵand(0, [["parcelCell", 2]], null, 0, null, View_CellAreaComponent_1)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CellAreaComponent_2)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CellAreaComponent_4)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.tooltipComponent || !_co.tooltipData); _ck(_v, 3, 0, currVal_0); var currVal_1 = (_co.tooltipComponent && _co.tooltipData); _ck(_v, 5, 0, currVal_1); }, null); }
exports.View_CellAreaComponent_0 = View_CellAreaComponent_0;
function View_CellAreaComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "cell-area", [], null, null, null, View_CellAreaComponent_0, RenderType_CellAreaComponent)), i0.ɵdid(1, 114688, null, 0, i3.CellAreaComponent, [i4.MapPropertyStatusService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_CellAreaComponent_Host_0 = View_CellAreaComponent_Host_0;
var CellAreaComponentNgFactory = i0.ɵccf("cell-area", i3.CellAreaComponent, View_CellAreaComponent_Host_0, { tooltipData: "tooltipData", tooltipType: "tooltipType", item: "item" }, {}, []);
exports.CellAreaComponentNgFactory = CellAreaComponentNgFactory;
