class ButtonController {
  static get $inject() {
    return ['ErrorHandlerService'];
  }

  constructor(ErrorHandlerService) {
    this.errorHandlerService = ErrorHandlerService;
  }

  $onInit() {
    this.processing = false;
    this.callback({ doAction: this.doAction });
  }

  /**
   * Button is disabled while processing the api call
   * or when told so by cmDisabled attribute.
   *
   * @returns {boolean}
   */
  isDisabled() {
    return this.cmDisabled || this.processing;
  }

  /**
   * Runs underlying action and checks its state.
   *
   * @param {object} event
   * @returns {void}
   */
  doAction(event) {
    event.stopPropagation();
    if (this.isDisabled()) {
      return;
    }

    const actionPromise = this.clickAction();
    // is promise?
    if (actionPromise && !!actionPromise.finally && typeof actionPromise.finally === 'function') {
      this.processing = true;
      actionPromise.finally(() => {
        this.processing = false;
      }).catch(new this.errorHandlerService());
    }
  }
}

const component = {
  template: require('./cmButton.html'),
  transclude: true,
  bindings: {
    callback: '&',
    clickAction: '&',
    cmDisabled: '<',
  },
  controller: ButtonController,
};

export default component;

