"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var angular_1 = require("@uirouter/angular");
var list_service_1 = require("../../services/list.service");
var FilterComponent = /** @class */ (function () {
    function FilterComponent(listService, stateService) {
        var _this = this;
        this.listService = listService;
        this.stateService = stateService;
        this.onChanged = new core_1.EventEmitter();
        this.showLegend = false;
        this.filterButtonName = 'Filtrovat';
        this.getActualPage = function (params) { return parseInt(params.page, 10) || 1; };
        this.needsToResetPage = function () { return _this.getActualPage(_this.stateService.params) !== 1; };
        this.applyFilter = function () {
            if (_this.needsToResetPage()) {
                // we must reset page
                _this.stateService.go('.', {
                    page: 1
                });
                // TODO: to by se asi melo nastavit automaticky
                _this.list.filter.offset = 0;
            }
            // log every filtering with searchText
            if (_this.list.filter.filters.searchText && _this.list.filter.filters.searchText.values[0].id) {
                window.GoogleAnalytics('send', {
                    hitType: 'event',
                    eventCategory: 'filterFulltext',
                    eventAction: _this.list.path,
                    eventLabel: _this.stateService.current.name,
                });
            }
            var changingPage = _this.needsToResetPage();
            _this.onChanged.emit(changingPage);
        };
    }
    FilterComponent.prototype.ngOnInit = function () {
        this.showLegend = this.showLegend !== false;
    };
    return FilterComponent;
}());
exports.FilterComponent = FilterComponent;
