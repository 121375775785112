const common = angular.module('common');

/**
 * Service that returns backend version promise
 */
common.service('HelpService', ['$q', 'AuthService', 'ListLoadService', 'GlobalRestangularProvider', 'HELP_IDS', function ($q, AuthService, ListLoadService, GlobalRestangularProvider, HELP_IDS) {
  this.HELP = null;
  this.helpLoader = null;


  this.loadHelp = () => {
    const defered = $q((resolve, reject) => {
      if (this.HELP) {
        resolve(this.HELP);
      } else if (this.helpLoader) {
        this.helpLoader.then(() => {
          resolve(this.HELP);
        });
      } else {
        const list = ListLoadService.createList('help', {limit: null}, GlobalRestangularProvider);
        this.helpLoader = ListLoadService.fetchResult(list).then((data) => {
          this.HELP = data;
          this.HELP.list.forEach((h) => {
            h.strId = Object.keys(HELP_IDS).find(key => HELP_IDS[key] === h.id);
          });
          this.helpLoader = null;
          resolve(this.HELP);
        },
         ()=> {
            this.helpLoader = null;
            reject();
          });
      }
    });

    return defered;
  };

  this.getHelp = () => {
    return this.HELP;
  };

  this.saveChanges = (help) => {
    help.application = AuthService.getActiveApplication();
    const req = GlobalRestangularProvider.one('help').customPUT(help).then((data) => {
      const helpId = help.id;
      const oldHelp = this.getHelpById(helpId);
      if(oldHelp) {
        const helpIndex = this.HELP.list.indexOf(oldHelp);
        this.HELP.list.splice(helpIndex, 1, data);
      } else {
        this.HELP.list.push(data);
      }
    });
    return req;
  };

  this.getHelpById = (helpId) => {
    return this.loadHelp().then(()=>{
      return this.HELP.list.find((help) => {
        return help.id === helpId;
      });
    });
  };
}]);
