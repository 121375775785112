"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./perfectscrollbar.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/common");
var i3 = require("./perfectscrollbar.component");
var styles_PerfectScrollbarComponent = [i0.styles];
var RenderType_PerfectScrollbarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PerfectScrollbarComponent, data: {} });
exports.RenderType_PerfectScrollbarComponent = RenderType_PerfectScrollbarComponent;
function View_PerfectScrollbarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "scrollable"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { horizontal: 0 }), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "scrollable-inner"]], null, null, null, null, null)), i1.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "scrollable"; var currVal_1 = _ck(_v, 2, 0, _co.horizontal); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
exports.View_PerfectScrollbarComponent_0 = View_PerfectScrollbarComponent_0;
function View_PerfectScrollbarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "perfect-scrollbar", [], null, null, null, View_PerfectScrollbarComponent_0, RenderType_PerfectScrollbarComponent)), i1.ɵdid(1, 114688, null, 0, i3.PerfectScrollbarComponent, [i1.ElementRef, i1.KeyValueDiffers, i1.NgZone], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_PerfectScrollbarComponent_Host_0 = View_PerfectScrollbarComponent_Host_0;
var PerfectScrollbarComponentNgFactory = i1.ɵccf("perfect-scrollbar", i3.PerfectScrollbarComponent, View_PerfectScrollbarComponent_Host_0, { wheelSpeed: "wheelSpeed", wheelPropagation: "wheelPropagation", minScrollbarLength: "minScrollbarLength", horizontal: "horizontal" }, { callbackRegister: "callbackRegister" }, ["*"]);
exports.PerfectScrollbarComponentNgFactory = PerfectScrollbarComponentNgFactory;
