"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var FormCasePaymentInstructionComponent = /** @class */ (function () {
    function FormCasePaymentInstructionComponent() {
        this.callbackRegister = new core_1.EventEmitter();
        this.isValid = this.isValid.bind(this);
    }
    FormCasePaymentInstructionComponent.prototype.ngOnInit = function () {
        var currentDate = new Date();
        var day = currentDate.getDate();
        var month = currentDate.getMonth() + 1;
        var year = currentDate.getFullYear();
        this.data.paymentInstructionDate = this.data.paymentInstructionDate || new Date();
        this.callbackRegister.emit({
            isValid: this.isValid,
        });
    };
    FormCasePaymentInstructionComponent.prototype.isValid = function () {
        return !!this.data.paymentInstructionDate;
    };
    return FormCasePaymentInstructionComponent;
}());
exports.FormCasePaymentInstructionComponent = FormCasePaymentInstructionComponent;
