"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var map_module_service_1 = require("@app/map/services/map-module.service");
var $ = require("jquery");
var MapToolBoxComponent = /** @class */ (function () {
    function MapToolBoxComponent(windowDimensionService, elementRef, mapModuleService) {
        this.windowDimensionService = windowDimensionService;
        this.elementRef = elementRef;
        this.mapModuleService = mapModuleService;
        this.infoboxVisible = true;
        this.setContentHeight = this.setContentHeight.bind(this);
        this.onModuleClick = this.onModuleClick.bind(this);
        this.visibilitySetter = this.visibilitySetter.bind(this);
        this.getInfoboxWidth = this.getInfoboxWidth.bind(this);
    }
    MapToolBoxComponent.prototype.ngOnInit = function () {
        var _this = this;
        //Map has zero height on init
        setTimeout(this.setContentHeight, 100);
        this.tools = this.tools.map(function (t) { return _this.mapModuleService.getModuleById(t); });
        this.mapModuleService.setActiveModuleId(this.tools[0].id);
        this.visibilitySetter(this.getActiveTool().showInfobox !== false);
    };
    ;
    MapToolBoxComponent.prototype.visibilitySetter = function (infoboxVisibility) {
        this.infoboxVisible = infoboxVisibility;
    };
    MapToolBoxComponent.prototype.getActiveTool = function () {
        var activeModuleId = this.mapModuleService.getActiveModuleId();
        return this.mapModuleService.getModuleById(activeModuleId);
    };
    MapToolBoxComponent.prototype.onModuleClick = function (moduleId) {
        if (moduleId !== this.getActiveTool().id) {
            this.mapModuleService.setActiveModuleId(moduleId);
            this.visibilitySetter(this.getActiveTool().showInfobox !== false);
        }
    };
    MapToolBoxComponent.prototype.getInfoboxWidth = function () {
        return this.elementRef.nativeElement.querySelector('.cm-map-infobox').getBoundingClientRect().width;
    };
    // resizing window will change height of scrollable content
    MapToolBoxComponent.prototype.setContentHeight = function () {
        //set height for scrollbar
        var dimensions = new this.windowDimensionService(this.elementRef.nativeElement);
        $(this.elementRef.nativeElement.querySelector(".content .scrollable")).css('max-height', dimensions.windowHeight - 50 - 70 - 12);
    };
    return MapToolBoxComponent;
}());
exports.MapToolBoxComponent = MapToolBoxComponent;
