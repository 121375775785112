"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var dialog_config_1 = require("@app/dialog/dialog-config");
var dialog_ref_1 = require("@app/dialog/dialog-ref");
var changelog_model_1 = require("@app/common/models/changelog.model");
var email_utils_1 = require("@app/common/utils/email.utils");
var ChangelogEditComponent = /** @class */ (function () {
    function ChangelogEditComponent(helpIds, restangular, config, dialog) {
        this.helpIds = helpIds;
        this.restangular = restangular;
        this.config = config;
        this.dialog = dialog;
        this.loading = true;
        this.changelogId = config.data.changelogId;
        this.applicationName = config.data.applicationName;
        this.onUpdate = this.onUpdate.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }
    ChangelogEditComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.changelogId) {
            this.restangular
                .one('changelogs', this.changelogId)
                .get()
                .then(function (data) {
                _this.changelog = data.plain();
                _this.loading = false;
            });
        }
        else {
            this.changelog = changelog_model_1.createChangelog(this.applicationName);
            this.loading = false;
        }
    };
    ChangelogEditComponent.prototype.onUpdate = function () {
        var _this = this;
        return this.restangular
            .one('changelogs')
            .customPUT(this.changelog)
            .then(function (data) {
            _this.dialog.close(data.plain());
        });
    };
    ChangelogEditComponent.prototype.onCancel = function () {
        this.dialog.close();
    };
    ChangelogEditComponent.prototype.getPreview = function (description) {
        return email_utils_1.EmailUtils.textToHtml(description);
    };
    ChangelogEditComponent.prototype.isValid = function () {
        return !!this.changelog.name;
    };
    return ChangelogEditComponent;
}());
exports.ChangelogEditComponent = ChangelogEditComponent;
