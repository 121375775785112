"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var angular_1 = require("@uirouter/angular");
var ticket_service_1 = require("@app/sv/services/ticket.service");
var sample_service_1 = require("@app/sv/services/sample.service");
var sample_status_enum_1 = require("@app/sv/enums/sample-status.enum");
var auth_service_1 = require("@app/common/services/auth.service");
var document_type_fullname_pipe_1 = require("@app/sv/pipes/document-type-fullname.pipe");
var organizational_unit_name_pipe_1 = require("@app/common/pipes/organizational-unit-name.pipe");
var TicketCreateComponent = /** @class */ (function () {
    function TicketCreateComponent(restangular, configRestangular, ticketService, documentTypeFullnamePipe, organizationalUnitNamePipe, stateService, sampleService, authService) {
        this.restangular = restangular;
        this.configRestangular = configRestangular;
        this.ticketService = ticketService;
        this.documentTypeFullnamePipe = documentTypeFullnamePipe;
        this.organizationalUnitNamePipe = organizationalUnitNamePipe;
        this.stateService = stateService;
        this.sampleService = sampleService;
        this.authService = authService;
        this.loading = true;
        this.ticket = {};
        this.filesMsg = TicketCreateComponent.MSG_FILES_DEFAULT;
        this.filesValid = false;
        this.existingSample = false;
        this.onSubmit = this.onSubmit.bind(this);
        this.onOrganizationalUnitSelected = this.onOrganizationalUnitSelected.bind(this);
    }
    TicketCreateComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.loadSample()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.loadDocumentTypes()];
                    case 2:
                        _a.sent();
                        this.checkExistingSample();
                        this.organizationalUnitFilter = {
                            filters: {
                                cancelled: [false],
                                code: this.authService.getProjectsByPermission('template_new')
                            }
                        };
                        this.loading = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    TicketCreateComponent.prototype.onFilesChange = function (files) {
        this.files = files;
        var extensionValid = files.every(function (f) { return ['doc', 'docx'].includes(f.extension && f.extension.toLowerCase()); });
        if (this.files.length === 0) {
            this.filesMsg = TicketCreateComponent.MSG_FILES_DEFAULT;
        }
        else if (!extensionValid) {
            this.filesMsg = TicketCreateComponent.MSG_FILES_EXTENSION;
        }
        else if (this.files.length > 1) {
            this.filesMsg = TicketCreateComponent.MSG_FILES_QUANTITY;
        }
        else {
            this.filesMsg = '';
        }
        this.filesValid = (extensionValid && this.files.length === 1);
    };
    TicketCreateComponent.prototype.onOrganizationalUnitSelected = function (event) {
        this.ticket.section = event.newValue ? event.newValue.section : null;
        this.ticket.organizationalUnitName = event.newValue ? event.newValue.name : null;
        this.ticket.organizationalUnitId = event.newValue ? event.newValue.id : null;
        this.ticket.organizationalUnitCode = event.newValue ? event.newValue.code : null;
    };
    TicketCreateComponent.prototype.isValid = function () {
        return this.filesValid && !!this.ticket.description && (!!this.sample || (!!this.ticket.documentType && !!this.ticket.organizationalUnitId));
    };
    TicketCreateComponent.prototype.onSubmit = function () {
        var _this = this;
        if (this.sample) {
            return this.ticketService
                .createBySample(this.ticket, this.sample, this.files)
                .then(function (ticket) {
                _this.stateService.go('sv.tickets.detail', { id: ticket.id });
            });
        }
        return this.ticketService
            .create(this.ticket, this.files)
            .then(function (ticket) {
            _this.stateService.go('sv.tickets.detail', { id: ticket.id });
        });
    };
    TicketCreateComponent.prototype.checkExistingSample = function () {
        var _this = this;
        if (this.sample || !(this.ticket.documentType && this.ticket.organizationalUnitId)) {
            this.existingSample = false;
            return;
        }
        var filter = { filter: { filters: {
                    documentTypeId: this.ticket.documentType.id,
                    organizationalUnitId: this.ticket.organizationalUnitId,
                    status: [sample_status_enum_1.SampleStatusEnum.ACTIVE, sample_status_enum_1.SampleStatusEnum.EXCEPTION]
                } } };
        this.restangular
            .all('samples').customPOST(filter)
            .then(function (data) {
            _this.existingSample = data.length > 0;
        });
    };
    TicketCreateComponent.prototype.loadSample = function () {
        var _this = this;
        if (this.sampleId) {
            return this.sampleService.getById(this.sampleId).then(function (sample) {
                _this.sample = sample;
            });
        }
        return Promise.resolve();
    };
    TicketCreateComponent.prototype.loadDocumentTypes = function () {
        var _this = this;
        return this.restangular
            .all('document-types')
            .getList()
            .then(function (list) {
            _this.documentTypes = list.plain();
        });
    };
    TicketCreateComponent.MSG_FILES_DEFAULT = 'Vložte vzor přetažením, nebo jej vyberte z počítače. Povolen je jeden dokument typu doc, docx.';
    TicketCreateComponent.MSG_FILES_EXTENSION = 'Soubor musí být ve formátu doc, docx.';
    TicketCreateComponent.MSG_FILES_QUANTITY = 'Je možné vložit pouze jeden soubor.';
    return TicketCreateComponent;
}());
exports.TicketCreateComponent = TicketCreateComponent;
