"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var StatusService = /** @class */ (function () {
    function StatusService(restangular) {
        this.restangular = restangular;
    }
    StatusService.prototype.getStatus = function () {
        return this.restangular
            .one('import/status')
            .get();
    };
    StatusService.ngInjectableDef = i0.defineInjectable({ factory: function StatusService_Factory() { return new StatusService(i0.inject("Restangular")); }, token: StatusService, providedIn: "root" });
    return StatusService;
}());
exports.StatusService = StatusService;
