"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var case_subject_model_1 = require("@app/ps/models/case-subject.model");
var FormCaseSubjectDatesComponent = /** @class */ (function () {
    function FormCaseSubjectDatesComponent() {
    }
    FormCaseSubjectDatesComponent.prototype.ngOnInit = function () { };
    return FormCaseSubjectDatesComponent;
}());
exports.FormCaseSubjectDatesComponent = FormCaseSubjectDatesComponent;
