"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var i0 = require("@angular/core");
var PersonalNumberPipe = /** @class */ (function () {
    function PersonalNumberPipe() {
    }
    PersonalNumberPipe.prototype.transform = function (number) {
        if (!number) {
            return '';
        }
        if (number.length <= 6) {
            return number;
        }
        return number.substr(0, 6) + "/" + number.substr(6);
    };
    PersonalNumberPipe.ngInjectableDef = i0.defineInjectable({ factory: function PersonalNumberPipe_Factory() { return new PersonalNumberPipe(); }, token: PersonalNumberPipe, providedIn: "root" });
    return PersonalNumberPipe;
}());
exports.PersonalNumberPipe = PersonalNumberPipe;
