"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ɵ0 = function ($injector) { return $injector.get('MULTI_SCENE_CLOUD'); };
exports.ɵ0 = ɵ0;
var DimapCommonModule = /** @class */ (function () {
    function DimapCommonModule() {
    }
    return DimapCommonModule;
}());
exports.DimapCommonModule = DimapCommonModule;
