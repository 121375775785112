"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var map_service_1 = require("@app/map/services/map.service");
var map_reload_layers_service_1 = require("@app/map/services/map-reload-layers.service");
var map_highlight_service_1 = require("@app/map/services/map-highlight.service");
var list_service_1 = require("@app/common/services/list.service");
var L = require("leaflet");
var MapAffectedPropertyLayers_1 = require("@app/map/services/pure/MapAffectedPropertyLayers");
var getPropertyAreaColorClass_1 = require("@app/map/services/pure/getPropertyAreaColorClass");
var MapModuleCutParcelsComponent = /** @class */ (function () {
    function MapModuleCutParcelsComponent(mapService, mapReloadLayersService, mapHighlightService, listService) {
        var _this = this;
        this.mapService = mapService;
        this.mapReloadLayersService = mapReloadLayersService;
        this.mapHighlightService = mapHighlightService;
        this.listService = listService;
        this.map = null;
        this.intersectLayerExist = false;
        this.intersectionLayer = null;
        this.cutParcels = null;
        this.cutParcelsLayers = null;
        this.drawKN = null;
        this._cancelDrawing = function (e) {
            if (e.keyCode === 27 && !_this.intersectLayerExist) {
                _this.startDrawIntersection();
            }
        };
        this.startDrawIntersection = function () {
            if (_this.drawKN) {
                _this.stopIntersection();
            }
            _this.setDrawSelectPolylineLocalization();
            _this.drawKN = new L.Draw.Polyline(_this.map, { showArea: false, showLength: false });
            _this.drawKN.options.icon = _this.getVertexIcon();
            _this.drawKN.enable();
            _this.map.once(L.Draw.Event.CREATED, _this.onDrawCreated);
            L.DomEvent.on(_this.map._container, 'keyup', _this._cancelDrawing, _this);
        };
        this.onDrawCreated = this.onDrawCreated.bind(this);
        this.onNewCut = this.onNewCut.bind(this);
        this.onCutClick = this.onCutClick.bind(this);
        this.onFeatureMouseOut = this.onFeatureMouseOut.bind(this);
        this.onFeatureMouseOver = this.onFeatureMouseOver.bind(this);
    }
    MapModuleCutParcelsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.mapService.getMap(this.mapId).then(function (map) {
            _this.map = map;
        }).then(function () {
            _this.startDrawIntersection();
        });
    };
    MapModuleCutParcelsComponent.prototype.ngOnDestroy = function () {
        this.stopIntersection();
        this.clearParcels();
        this.removeHighlightParcel();
        this.map.off(L.Draw.Event.CREATED, this.onDrawCreated);
        this.map = null;
    };
    MapModuleCutParcelsComponent.prototype.getVertexIcon = function () {
        return new L.DivIcon({
            iconSize: new L.Point(8, 8),
            className: "leaflet-div-icon leaflet-editing-icon leaflet-touch-icon"
        });
    };
    MapModuleCutParcelsComponent.prototype.onNewCut = function () {
        this.clearParcels();
        this.startDrawIntersection();
    };
    MapModuleCutParcelsComponent.prototype.onWantedChanged = function (newValue, oldValue, ownerType) {
        var layersIDs = MapAffectedPropertyLayers_1.default(newValue, oldValue, ownerType);
        layersIDs.push('property_info_geometric_plan');
        this.mapReloadLayersService.reload(this.mapId, layersIDs);
    };
    MapModuleCutParcelsComponent.prototype.getAreaClass = function (owner_type, wanted, key) {
        return getPropertyAreaColorClass_1.default(owner_type, wanted, key);
    };
    MapModuleCutParcelsComponent.prototype.stopIntersection = function () {
        if (this.drawKN) {
            this.drawKN.disable();
            this.drawKN = null;
            if (this.intersectionLayer) {
                this.map.removeLayer(this.intersectionLayer);
            }
        }
        L.DomEvent.off(this.map._container, 'keyup', this._cancelDrawing, this);
    };
    MapModuleCutParcelsComponent.prototype.onDrawCreated = function (evt) {
        var _this = this;
        setTimeout(function () {
            _this.intersectLayerExist = true;
        });
        this.intersectionLayer = evt.layer;
        this.map.addLayer(this.intersectionLayer);
    };
    MapModuleCutParcelsComponent.prototype.clearParcels = function () {
        var _this = this;
        if (this.cutParcelsLayers) {
            this.cutParcelsLayers.forEach(function (l) {
                _this.map.removeLayer(l);
                l.off('mouseover', _this.onFeatureMouseOver);
                l.off('mouseout', _this.onFeatureMouseOut);
            });
        }
        this.cutParcels = null;
        this.cutParcelsLayers = null;
        this.intersectLayerExist = false;
    };
    MapModuleCutParcelsComponent.prototype.highlightParcel = function (parcel) {
        this.mapHighlightService.highlightFeature(this.mapId, JSON.stringify(parcel.geometry), false);
    };
    MapModuleCutParcelsComponent.prototype.removeHighlightParcel = function () {
        this.mapHighlightService.removeHighlight(this.mapId);
    };
    MapModuleCutParcelsComponent.prototype.getParcelByID = function (id) {
        return this.cutParcels.find(function (p) { return p.properties.id === id; });
    };
    MapModuleCutParcelsComponent.prototype.setHighlighted = function (layer, highlighted) {
        var id = layer.feature.properties.id;
        var parcel = this.getParcelByID(id);
        if (parcel) {
            setTimeout(function () { parcel.properties.highlighted = highlighted; });
        }
    };
    MapModuleCutParcelsComponent.prototype.onFeatureMouseOver = function (evt) {
        this.setHighlighted(evt.layer, true);
    };
    MapModuleCutParcelsComponent.prototype.onFeatureMouseOut = function (evt) {
        this.setHighlighted(evt.layer, false);
    };
    MapModuleCutParcelsComponent.prototype.onCutClick = function () {
        var _this = this;
        var filter = {
            filters: { 'lineString': JSON.stringify(this.intersectionLayer.toGeoJSON().geometry) }
        };
        var dataList = this.listService.createList('intersections/cut-by-line/4326', filter, undefined, {
            division: {
                mapping: {
                    attributes: {
                        divisionType: {},
                    }
                }
            },
            divisionParcel: {
                mapping: {
                    attributes: {
                        parcel: {
                            mapping: {
                                attributes: {
                                    title: {}
                                }
                            }
                        }
                    }
                }
            }
        });
        return this.listService.fetchResult(dataList, false).then(function (data) {
            _this.cutParcels = [];
            _this.cutParcelsLayers = [];
            _this.intersectLayerExist = false;
            _this.stopIntersection();
            var bounds = L.latLngBounds([]);
            data.list.forEach(function (f) {
                var properties = Object.keys(f)
                    .filter(function (key) { return key !== 'geometry'; })
                    .reduce(function (obj, key) {
                    obj[key] = f[key];
                    return obj;
                }, {});
                var feature = {
                    type: "Feature",
                    properties: {
                        id: properties.id,
                        wanted: properties.wanted,
                        owner_type: properties.divisionParcel.parcel.title.ownerType,
                        area: properties.area,
                        parcelNumber: properties.divisionParcel.parcel.parcelNumber,
                        key: properties.division && properties.division.divisionType ? properties.division.divisionType.key : null,
                    },
                    geometry: JSON.parse(f.geometry),
                    crs: {
                        type: 'name',
                        properties: {
                            'name': 'urn:ogc:def:crs:EPSG::5514'
                        }
                    }
                };
                var layerLeaflet = L.Proj.geoJson(feature, {
                    style: {
                        clickable: true,
                        fillColor: 'blue',
                        fillOpacity: 0.1,
                        color: 'blue',
                        weight: 2,
                        opacity: 1
                    }
                });
                layerLeaflet.on('mouseover', _this.onFeatureMouseOver);
                layerLeaflet.on('mouseout', _this.onFeatureMouseOut);
                _this.map.addLayer(layerLeaflet);
                _this.cutParcels.push(layerLeaflet.getLayers()[0].feature);
                _this.cutParcelsLayers.push(layerLeaflet);
                var layerBounds = layerLeaflet.getBounds();
                bounds.extend(layerBounds);
            });
            setTimeout(function () {
                _this.map.fitBounds(bounds, {
                    paddingTopLeft: [10, 10],
                    paddingBottomRight: [_this.infoboxWidthGetter(), 10]
                });
            });
        });
    };
    MapModuleCutParcelsComponent.prototype.setDrawSelectPolylineLocalization = function () {
        L.drawLocal.draw.handlers.polyline.tooltip.start = "Nakreslete novou hranici.";
        L.drawLocal.draw.handlers.polyline.tooltip.cont = "Klikněte pro pokračování.";
        L.drawLocal.draw.handlers.polyline.tooltip.end = "Kliknutím na poslední bod ukončete kreslení.";
    };
    return MapModuleCutParcelsComponent;
}());
exports.MapModuleCutParcelsComponent = MapModuleCutParcelsComponent;
