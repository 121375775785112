"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("./map-module-move.component");
var styles_MapModuleMoveComponent = [];
var RenderType_MapModuleMoveComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MapModuleMoveComponent, data: {} });
exports.RenderType_MapModuleMoveComponent = RenderType_MapModuleMoveComponent;
function View_MapModuleMoveComponent_0(_l) { return i0.ɵvid(0, [], null, null); }
exports.View_MapModuleMoveComponent_0 = View_MapModuleMoveComponent_0;
function View_MapModuleMoveComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "map-module-move", [], null, null, null, View_MapModuleMoveComponent_0, RenderType_MapModuleMoveComponent)), i0.ɵdid(1, 114688, null, 0, i1.MapModuleMoveComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_MapModuleMoveComponent_Host_0 = View_MapModuleMoveComponent_Host_0;
var MapModuleMoveComponentNgFactory = i0.ɵccf("map-module-move", i1.MapModuleMoveComponent, View_MapModuleMoveComponent_Host_0, {}, { visibilityInfoboxCallback: "visibilityInfoboxCallback" }, []);
exports.MapModuleMoveComponentNgFactory = MapModuleMoveComponentNgFactory;
