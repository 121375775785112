"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var case_model_1 = require("@app/ps/models/case.model");
var FormAcceptedGeometricalPlanPossibleComponent = /** @class */ (function () {
    function FormAcceptedGeometricalPlanPossibleComponent() {
        this.callbackRegister = new core_1.EventEmitter();
        this.enabledSubjects = {};
        this.alreadySigned = 0;
        this.isValid = this.isValid.bind(this);
    }
    FormAcceptedGeometricalPlanPossibleComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.data.responseReceivedDate = new Date();
        this.callbackRegister.emit({
            isValid: this.isValid,
        });
        this.data.caseSubjects = this.data.caseSubjects.filter(function (value) { return value.subject.opsubType !== "BSM" &&
            _this.data.caseOwnerships.some(function (value2) {
                var bsmSubject;
                if (value2.ownership.isBsm) {
                    bsmSubject = _this.data.caseSubjects.find(function (cs) { return cs.subject.opsubId === value2.ownership.opsubId; }).subject;
                }
                return !value2.isParent && ((!bsmSubject && value2.ownership.opsubId === value.subject.opsubId) || (bsmSubject && (bsmSubject.partner1OpsubId === value.subject.opsubId || bsmSubject.partner2OpsubId === value.subject.opsubId)));
            }); });
        this.data.caseSubjects.forEach(function (item) {
            _this.enabledSubjects[item.id] = !item.acceptedGeometricalPlanPossibleDate;
        });
        this.alreadySigned = this.data.caseSubjects.filter(function (subject) { return subject.acceptedGeometricalPlanPossibleDate; }).length;
    };
    FormAcceptedGeometricalPlanPossibleComponent.prototype.isValid = function () {
        return this.data.responseReceivedDate && this.data.caseSubjects.filter(function (subject) { return subject.acceptedGeometricalPlanPossibleDate; }).length > this.alreadySigned;
    };
    return FormAcceptedGeometricalPlanPossibleComponent;
}());
exports.FormAcceptedGeometricalPlanPossibleComponent = FormAcceptedGeometricalPlanPossibleComponent;
