"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("@uirouter/angular/lib/directives/uiSref");
var i2 = require("@uirouter/core");
var i3 = require("./tooltip-intersection-area.component");
var styles_TooltipIntersectionAreaComponent = [];
var RenderType_TooltipIntersectionAreaComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TooltipIntersectionAreaComponent, data: {} });
exports.RenderType_TooltipIntersectionAreaComponent = RenderType_TooltipIntersectionAreaComponent;
function View_TooltipIntersectionAreaComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "ul", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 5, "li", [["class", "map"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 4, "a", [["uiSref", "dimap.project.map"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 4).go($event.button, $event.ctrlKey, $event.metaKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(3, 16384, null, 0, i1.AnchorUISref, [i0.ElementRef, i0.Renderer2], null, null), i0.ɵdid(4, 737280, null, 0, i1.UISref, [i2.UIRouter, [2, i1.AnchorUISref], "UIView.PARENT_INJECT"], { state: [0, "state"], params: [1, "params"] }, null), i0.ɵpod(5, { intersectionId: 0 }), (_l()(), i0.ɵted(-1, null, ["Zobrazit v mapov\u00E9m okn\u011B"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "dimap.project.map"; var currVal_1 = _ck(_v, 5, 0, _co.data.item.id); _ck(_v, 4, 0, currVal_0, currVal_1); }, null); }
exports.View_TooltipIntersectionAreaComponent_0 = View_TooltipIntersectionAreaComponent_0;
function View_TooltipIntersectionAreaComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tooltip-intersection-area", [], null, null, null, View_TooltipIntersectionAreaComponent_0, RenderType_TooltipIntersectionAreaComponent)), i0.ɵdid(1, 49152, null, 0, i3.TooltipIntersectionAreaComponent, [], null, null)], null, null); }
exports.View_TooltipIntersectionAreaComponent_Host_0 = View_TooltipIntersectionAreaComponent_Host_0;
var TooltipIntersectionAreaComponentNgFactory = i0.ɵccf("tooltip-intersection-area", i3.TooltipIntersectionAreaComponent, View_TooltipIntersectionAreaComponent_Host_0, { data: "data" }, {}, []);
exports.TooltipIntersectionAreaComponentNgFactory = TooltipIntersectionAreaComponentNgFactory;
