"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var BrandService = /** @class */ (function () {
    function BrandService(APP_BRAND) {
        this.APP_BRAND = APP_BRAND;
    }
    BrandService.prototype.isVisible = function (id) {
        return this.APP_BRAND.VISIBILITY[id] && this.APP_BRAND.VISIBILITY[id] === true;
    };
    BrandService.ngInjectableDef = i0.defineInjectable({ factory: function BrandService_Factory() { return new BrandService(i0.inject("APP_BRAND")); }, token: BrandService, providedIn: "root" });
    return BrandService;
}());
exports.BrandService = BrandService;
