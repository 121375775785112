(function () {
    "use strict";
    angular
            .module('common')
            .factory('CallbackService', factory);
    /**
     * Factory providing service for passing callback functions through different Controllers
     */
    function factory() {
        return service;
    }

    /**
     * Service allows stacking multiple service and tries to search for callback in parent service
     * @returns {CallbackService_L1.service.CallbackServiceAnonym$0}
     */
    function service() {
        var callbacks = {};

        function unregister(name, callbackFunction) {
          _.pull(callbacks[name], callbackFunction);
          if(callbacks[name].length === 0) {
            delete callbacks[name];
          }
        }

        function register(name, callbackFunction) {
          if (name in callbacks) {
            callbacks[name].push(callbackFunction);
          } else {
            callbacks[name] = [callbackFunction];
          }
        }

        function get(name) {
          return function() {
            const callbackArguments = arguments;
            return callbacks[name] ? callbacks[name].forEach(cb => cb(...callbackArguments)) : null;
          };
        }

        return {
            register: register,
            unregister: unregister,
            get: get
        };
    }

    factory.$inject = [];
})();
