"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var CellGeometricPlans = /** @class */ (function () {
    function CellGeometricPlans() {
    }
    CellGeometricPlans.prototype.shouldBeKnGpDisplayed = function (knGp) {
        // keep only knGps without geometric plan request relation
        if (knGp.geometricPlanRequest && this.geometricPlanRequests && this.geometricPlanRequests.length) {
            return !this.geometricPlanRequests.find(function (gpr) { return gpr.id === knGp.geometricPlanRequest.id; });
        }
        return true;
    };
    return CellGeometricPlans;
}());
exports.CellGeometricPlans = CellGeometricPlans;
