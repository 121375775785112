"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".price-summary[_ngcontent-%COMP%] {\n  background-color: #f2f2f2;\n  border-radius: 0px;\n  float: right;\n  padding: 10px;\n  font-weight: bold; }"];
exports.styles = styles;
