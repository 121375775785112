"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./menu.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@uirouter/angular/lib/directives/uiSref");
var i3 = require("@uirouter/core");
var i4 = require("@angular/common");
var i5 = require("../../directives/tooltip/tooltip.directive");
var i6 = require("../../directives/auth.directive");
var i7 = require("../../services/auth.service");
var i8 = require("../../directives/applications/applicationsHost.directive");
var i9 = require("./menu.component");
var i10 = require("@uirouter/angular");
var i11 = require("../../services/backend-version.service");
var styles_MenuComponent = [i0.styles];
var RenderType_MenuComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MenuComponent, data: {} });
exports.RenderType_MenuComponent = RenderType_MenuComponent;
function View_MenuComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "section-label"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit.title; _ck(_v, 1, 0, currVal_0); }); }
function View_MenuComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).go($event.button, $event.ctrlKey, $event.metaKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i2.AnchorUISref, [i1.ElementRef, i1.Renderer2], null, null), i1.ɵdid(2, 737280, null, 0, i2.UISref, [i3.UIRouter, [2, i2.AnchorUISref], "UIView.PARENT_INJECT"], { state: [0, "state"], params: [1, "params"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "i", [["class", "fa"]], null, null, null, null, null)), i1.ɵdid(4, 278528, null, 0, i4.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpad(5, 1), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MenuComponent_3)), i1.ɵdid(7, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.parent.context.$implicit.uiSref; var currVal_1 = _v.parent.context.$implicit.uiParams; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = "fa"; var currVal_3 = _ck(_v, 5, 0, _v.parent.context.$implicit.icon); _ck(_v, 4, 0, currVal_2, currVal_3); var currVal_4 = !_co.collapsed; _ck(_v, 7, 0, currVal_4); }, null); }
function View_MenuComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "section-label"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit.title; _ck(_v, 1, 0, currVal_0); }); }
function View_MenuComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "a", [], [[8, "href", 4], [8, "target", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "i", [["class", "fa"]], null, null, null, null, null)), i1.ɵdid(2, 278528, null, 0, i4.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpad(3, 1), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MenuComponent_5)), i1.ɵdid(5, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = "fa"; var currVal_3 = _ck(_v, 3, 0, _v.parent.context.$implicit.icon); _ck(_v, 2, 0, currVal_2, currVal_3); var currVal_4 = !_co.collapsed; _ck(_v, 5, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.href; var currVal_1 = _v.parent.context.$implicit.target; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_MenuComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 9, "li", [["class", "menu-item tooltip-min"], ["position", "center"]], null, [[null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("mouseenter" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).mouseEnter() !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3).closeTooltip() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 278528, null, 0, i4.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { collapsed: 0, active: 1 }), i1.ɵdid(3, 81920, null, 0, i5.TooltipDirective, [i1.ElementRef, "WindowDimensionService", i1.Renderer2, i1.ComponentFactoryResolver, i1.ViewContainerRef, i1.Injector], { content: [0, "content"], data: [1, "data"], position: [2, "position"] }, null), i1.ɵpod(4, { visible: 0 }), i1.ɵdid(5, 81920, null, 0, i6.AuthDirective, [i1.ElementRef, i7.AuthService], { gmtAuth: [0, "gmtAuth"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MenuComponent_2)), i1.ɵdid(7, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MenuComponent_4)), i1.ɵdid(9, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "menu-item tooltip-min"; var currVal_1 = _ck(_v, 2, 0, _co.collapsed, _co.isActive(_v.context.$implicit.uiSref)); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = (_v.context.$implicit.tooltip ? _v.context.$implicit.tooltip : _v.context.$implicit.title); var currVal_3 = _ck(_v, 4, 0, _co.collapsed); var currVal_4 = "center"; _ck(_v, 3, 0, currVal_2, currVal_3, currVal_4); var currVal_5 = _v.context.$implicit.permissions; _ck(_v, 5, 0, currVal_5); var currVal_6 = (!_v.context.$implicit.children && _v.context.$implicit.uiSref); _ck(_v, 7, 0, currVal_6); var currVal_7 = (!_v.context.$implicit.children && _v.context.$implicit.href); _ck(_v, 9, 0, currVal_7); }, null); }
function View_MenuComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "section-label"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Aplikace "]))], null, null); }
function View_MenuComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_MenuComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "sections"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 8, "li", [["class", "menu-item"], ["id", "app-switcher"]], null, [[null, "mouseleave"], [null, "mouseenter"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseleave" === en)) {
        var pd_0 = (_co.onApplicationSwitcherMouseLeave($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (_co.onApplicationSwitcherMouseEnter($event) !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (_co.showApplications($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 278528, null, 0, i4.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(4, { collapsed: 0 }), (_l()(), i1.ɵeld(5, 0, null, null, 3, "a", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "i", [["class", "fa fa-th-large"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MenuComponent_7)), i1.ɵdid(8, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MenuComponent_8)), i1.ɵdid(10, 16384, [[1, 4]], 0, i8.ApplicationsHostDirective, [i1.ViewContainerRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "menu-item"; var currVal_1 = _ck(_v, 4, 0, _co.collapsed); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = (!_co.collapsed && (_co.APP_BRAND.NAME !== "RSD")); _ck(_v, 8, 0, currVal_2); }, null); }
function View_MenuComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 6, "div", [["class", "copy"]], null, [[null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("mouseenter" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).mouseEnter() !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).closeTooltip() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 81920, null, 0, i5.TooltipDirective, [i1.ElementRef, "WindowDimensionService", i1.Renderer2, i1.ComponentFactoryResolver, i1.ViewContainerRef, i1.Injector], { content: [0, "content"], data: [1, "data"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "text-center mb-10"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["verze: ", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", " \u00A9 GMtech, s.r.o."])), i1.ɵppd(6, 2), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((!_co.metadata || ((_co.APP_BRAND.NAME === "RSD") && !_co.metadata.cadastre)) ? undefined : _co.tooltipSystemMetadataComponent); var currVal_1 = _co.metadata; _ck(_v, 1, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = ((_co.metadata == null) ? null : _co.metadata.frontendVersion); _ck(_v, 3, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i1.ɵnov(_v.parent, 0), _co.copyrightDate, "yyyy")); _ck(_v, 5, 0, currVal_3); }); }
function View_MenuComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i4.DatePipe, [i1.LOCALE_ID]), i1.ɵqud(671088640, 1, { applicationsHostDirective: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 11, "div", [["style", "width:100%"]], null, null, null, null, null)), i1.ɵdid(3, 278528, null, 0, i4.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(4, { collapsed: 0 }), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "sections"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MenuComponent_1)), i1.ɵdid(8, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 4, "div", [["id", "bottom-bar"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MenuComponent_6)), i1.ɵdid(11, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MenuComponent_9)), i1.ɵdid(13, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 4, 0, _co.collapsed); _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.menuConfig; _ck(_v, 8, 0, currVal_1); var currVal_2 = ((_co.applications.length > 1) && ((_co.APP_BRAND.NAME !== "RSD") || ((_co.user == null) ? null : _co.user.superuser))); _ck(_v, 11, 0, currVal_2); var currVal_3 = !_co.collapsed; _ck(_v, 13, 0, currVal_3); }, null); }
exports.View_MenuComponent_0 = View_MenuComponent_0;
function View_MenuComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-menu", [], null, null, null, View_MenuComponent_0, RenderType_MenuComponent)), i1.ɵdid(1, 114688, null, 0, i9.MenuComponent, [i7.AuthService, i1.ChangeDetectorRef, i1.ComponentFactoryResolver, i1.ElementRef, i10.UIRouterGlobals, i11.BackendVersionService, "APP_VERSION", "APP_WIKI_URL", "APP_BRAND"], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_MenuComponent_Host_0 = View_MenuComponent_Host_0;
var MenuComponentNgFactory = i1.ɵccf("app-menu", i9.MenuComponent, View_MenuComponent_Host_0, { collapsed: "collapsed", menuConfig: "menuConfig" }, {}, []);
exports.MenuComponentNgFactory = MenuComponentNgFactory;
