"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var easement_create_step_component_1 = require("@app/ps/construction-objects/components/easement-create-step/easement-create-step.component");
var _ = require("lodash");
var parcel_utils_1 = require("@app/common/utils/parcel.utils");
var table_utils_1 = require("@app/common/utils/table.utils");
var EasementCreateSummaryComponent = /** @class */ (function (_super) {
    __extends(EasementCreateSummaryComponent, _super);
    function EasementCreateSummaryComponent(restangular, confirmService, alertService, APP_BRAND) {
        var _this = _super.call(this) || this;
        _this.restangular = restangular;
        _this.confirmService = confirmService;
        _this.alertService = alertService;
        _this.APP_BRAND = APP_BRAND;
        _this.sortOrder = {};
        _this.roundTo0DecimalPlaces = false;
        _this.uploadingData = false;
        _this.includeAllEasements = false;
        _this.loadingHeader = true;
        _this.header = {};
        _this.list = {
            list: undefined,
            itemCount: null,
            loading: true
        };
        _this.onSubmit = _this.onSubmit.bind(_this);
        _this.toggleAllEasements = _this.toggleAllEasements.bind(_this);
        return _this;
    }
    EasementCreateSummaryComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.data.vfkId) {
                            this.backCallback.emit();
                        }
                        return [4 /*yield*/, this.getHeader()];
                    case 1:
                        _a.sent();
                        this.getIntersectData();
                        return [2 /*return*/];
                }
            });
        });
    };
    EasementCreateSummaryComponent.prototype.showConfirmUpload = function (message) {
        return new this.confirmService({
            title: 'Potvrzení nahrání VB',
            message: message
        });
    };
    EasementCreateSummaryComponent.prototype.toggleAllEasements = function () {
        var e_1, _a, e_2, _b;
        this.includeAllEasements = !this.includeAllEasements;
        try {
            for (var _c = __values(this.list.list), _d = _c.next(); !_d.done; _d = _c.next()) {
                var item = _d.value;
                try {
                    for (var _e = __values(item.easements), _f = _e.next(); !_f.done; _f = _e.next()) {
                        var e = _f.value;
                        e.include = this.includeAllEasements;
                    }
                }
                catch (e_2_1) { e_2 = { error: e_2_1 }; }
                finally {
                    try {
                        if (_f && !_f.done && (_b = _e.return)) _b.call(_e);
                    }
                    finally { if (e_2) throw e_2.error; }
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
            }
            finally { if (e_1) throw e_1.error; }
        }
    };
    EasementCreateSummaryComponent.prototype.isValid = function () {
        var withWarnings = EasementCreateSummaryComponent.getInvalidData(this.list);
        var includedData = EasementCreateSummaryComponent.getIncludedEasements(this.list);
        return !this.uploadingData && withWarnings.length === 0 && includedData.length > 0;
    };
    EasementCreateSummaryComponent.prototype.onSubmit = function () {
        var _this = this;
        var deleteEasements = this.getDeleteEasements();
        var deleteData = deleteEasements.map(function (item) { return item.easement.importParcel.id; });
        var uploadEasements = EasementCreateSummaryComponent.getIncludedEasements(this.list);
        var uploadData = this.getUploadData().map(function (d) { delete d.warnings; return d; });
        var upload = {
            removedEasementIds: deleteData,
            symapParcelIntersectionVbs: uploadData,
            roundTo0DecimalPlaces: this.roundTo0DecimalPlaces
        };
        var deleteEasementsList = deleteEasements.map(EasementCreateSummaryComponent.mapEasementRow);
        var addEasementsList = uploadEasements.map(EasementCreateSummaryComponent.mapEasementRow);
        var easementsList = "\n        <h2>Potvrzen\u00EDm budou provedeny n\u00E1sleduj\u00EDc\u00ED \u00FApravy.</h2>\n        <h3>Nov\u00E1 VB:</h3>\n        <ul>" + addEasementsList.join('') + "</ul>\n        <h3>VB ke smaz\u00E1n\u00ED:</h3>\n        <ul>" + deleteEasementsList.join('') + "</ul>\n        <br/>" + (this.roundTo0DecimalPlaces ? "<h3>Hodnoty budou zaokrouhleny.</h3>" : "<h3>Hodnoty nebudou zaokrouhleny.</h3>") + "\n    ";
        return this.showConfirmUpload(easementsList).then(function (confirmed) {
            if (confirmed) {
                return _this.uploadParcels(upload);
            }
        });
    };
    EasementCreateSummaryComponent.prototype.dataContainsWarnings = function () {
        var withWarnings = EasementCreateSummaryComponent.getInvalidData(this.list);
        var includedData = EasementCreateSummaryComponent.getIncludedEasements(this.list);
        return withWarnings.length > 0 && includedData.length > 0;
    };
    EasementCreateSummaryComponent.prototype.getUploadData = function () {
        var includeData = EasementCreateSummaryComponent.getIncludedEasements(this.list);
        return includeData.map(function (parcel) {
            return {
                id: parcel.easement.importParcel.id,
                vymera: parcel.easement.importParcel.area,
                delka: parcel.easement.importParcel.length,
                warnings: parcel.easement.warnings
            };
        });
    };
    EasementCreateSummaryComponent.prototype.getDeleteEasements = function () {
        var deleteData = [];
        if (this.list && this.list.list && this.list.list.length > 0) {
            this.list.list.forEach(function (parcel) {
                parcel.easements.forEach(function (easement) {
                    if (easement.editable === false && easement.include === false) {
                        deleteData.push({
                            easement: easement,
                            parcel: parcel.parcel
                        });
                    }
                });
            });
        }
        return deleteData;
    };
    /* SORTING */
    EasementCreateSummaryComponent.prototype.setSortData = function (sortedBy) {
        this.sortOrder.direction = this.sortOrder.direction === 'asc' ? 'desc' : 'asc';
        // every first sort will be sorted 'desc'
        if (this.sortOrder.sortBy !== sortedBy) {
            this.sortOrder.direction = 'desc';
        }
        this.sortOrder.sortBy = sortedBy;
    };
    EasementCreateSummaryComponent.prototype.onSort = function (sortedBy, dataPath) {
        this.setSortData(sortedBy);
        this.list.list = table_utils_1.TableUtils.sortDataByColumnStatic(this.list.list, dataPath, this.sortOrder.direction);
    };
    EasementCreateSummaryComponent.prototype.onSortParcel = function (sortedBy) {
        this.setSortData(sortedBy);
        this.list.list = _.orderBy(this.list.list, parcel_utils_1.ParcelUtils.getSortFunctionByParcelsNumbers('parcel.parcelCis.parcisKmen', 'parcel.parcelCis.parcisPod', 'parcel.parcelCis.parcisDil'), this.sortDir);
    };
    /* DOWNLOADS & UPLOADS */
    EasementCreateSummaryComponent.prototype.getHeader = function () {
        var _this = this;
        return this.restangular.one("/vfks/intersect/" + this.data.vfkId, "header").get().then(function (data) {
            _this.loadingHeader = false;
            _this.constructionObject = data.constructionObject.id;
            _this.header.so = data.constructionObject.socis + " + " + data.constructionObject.sotx;
            _this.header.sousek = data.constructionObject.sousek;
            _this.header.cisloGp = data.cisloGp;
            _this.header.zhotovitel = data.zhotovitel;
            _this.header.datumOvereni = data.datumOvereni;
            _this.header.datumZhotoveni = data.datumZhotoveni;
            _this.header.ku = data.katastralniUzemi.name;
        });
    };
    EasementCreateSummaryComponent.prototype.getIntersectData = function () {
        var _this = this;
        return this.restangular.one("/vfks/intersect/", this.data.vfkId).get().then(function (data) {
            var list = _this.restangular.stripRestangular(data);
            var csvOnlyParcels = list.filter(function (parcel) { return parcel.result === 'CSV_ONLY'; });
            var csvOnlyParcelsNames = csvOnlyParcels.map(function (parcel) { return parcel.parcelName; });
            if (csvOnlyParcels.length > 0) {
                new _this.confirmService({
                    title: 'Upozornění',
                    message: "V CSV zji\u0161t\u011Bny neexistuj\u00EDc\u00ED parcely " + csvOnlyParcelsNames.join(', ') + ". Tyto parcely nejsou zpracovan\u00E9, chcete pokra\u010Dovat? "
                }).then(function (confirm) {
                    if (!confirm) {
                        _this.backCallback.emit();
                    }
                });
            }
            _this.list.list = list
                .filter(function (parcel) { return parcel.result !== 'CSV_ONLY'; })
                .map(function (parcel) { return EasementCreateSummaryComponent.getParcelData(parcel, _this.header.cisloGp); });
            _this.list.loading = false;
            _this.list.itemCount = list.length;
        });
    };
    EasementCreateSummaryComponent.prototype.uploadParcels = function (data) {
        var _this = this;
        this.uploadingData = true;
        return this.restangular.all("/vfks/create/" + this.data.vfkId).post(data).then(function () {
            _this.submitCallback.emit();
        });
    };
    /* HELPERS */
    EasementCreateSummaryComponent.getInvalidData = function (list) {
        var includedData = EasementCreateSummaryComponent.getIncludedEasements(list);
        return includedData.filter(function (data) {
            return data.easement.include && Object.keys(data.easement.warnings).length > 0;
        });
    };
    EasementCreateSummaryComponent.getIncludedEasements = function (list) {
        var data = [];
        if (list && list.list && list.list.length > 0) {
            list.list.forEach(function (parcel) {
                parcel.easements.forEach(function (easement) {
                    if (easement.editable && easement.include === true) {
                        data.push({
                            easement: easement,
                            parcel: parcel.parcel
                        });
                    }
                });
            });
        }
        return data;
    };
    EasementCreateSummaryComponent.getParcelData = function (data, cisloGp) {
        var _a;
        var parcel = {
            easements: [],
            parcel: {
                id: data.symapParcelIntersection ? data.symapParcelIntersection.id : null,
                lv: data.parcel ? data.parcel.lv : '-',
                future: data.parcel.future,
                drupozNazev: data.parcel ? data.parcel.drupozNazev : '-',
                parcelName: data.parcelName,
                parcelCis: {
                    parcisDil: data.parcel.parcisDil,
                    parcisKmen: data.parcel.parcisKmen,
                    parcisPod: data.parcel.parcisPod,
                },
                includedGPcis: []
            },
        };
        var importParcel = {
            area: '',
            length: '',
            id: data.symapParcelIntersection.id
        };
        var csvParcelIntersection = null;
        if (data.csvParcelIntersection) {
            csvParcelIntersection = {
                length: data.csvParcelIntersection.delka,
                area: data.csvParcelIntersection.vymera
            };
            // prefill import data from csv
            importParcel.area = data.csvParcelIntersection.vymera;
            importParcel.length = data.csvParcelIntersection.delka;
        }
        var symapParcelIntersection = null;
        if (data.symapParcelIntersection) {
            symapParcelIntersection = {
                length: data.symapParcelIntersection.delka,
                area: data.symapParcelIntersection.vymera,
                gpcis: cisloGp
            };
        }
        // add intersect (new) easement into stack
        var intersectionEasement = EasementCreateSummaryComponent.getEasementData(true, false, csvParcelIntersection, symapParcelIntersection, importParcel, null);
        parcel.easements.push(intersectionEasement);
        if (data.easements) {
            var easements = data.easements.map(function (easementData) {
                var importParcel = {
                    length: easementData.delka,
                    area: easementData.vymera,
                    id: easementData.id
                };
                var existingEasementInfo = {
                    constructionObjects: easementData.constructionObjects,
                    gpcis: easementData.geometricPlan.cis,
                };
                // add gpcis to the list of included gpcis for latest check
                parcel.parcel.includedGPcis.push(easementData.geometricPlan.cis);
                return EasementCreateSummaryComponent.getEasementData(false, true, {}, {}, importParcel, easementData.countCases, existingEasementInfo);
            });
            // add current easements into stack
            (_a = parcel.easements).push.apply(_a, __spread(easements));
        }
        return parcel;
    };
    EasementCreateSummaryComponent.getEasementData = function (editable, include, csvParcelIntersection, symapParcelIntersection, importParcel, countCases, existingEasementInfo) {
        if (editable === void 0) { editable = false; }
        if (include === void 0) { include = false; }
        if (csvParcelIntersection === void 0) { csvParcelIntersection = {}; }
        if (symapParcelIntersection === void 0) { symapParcelIntersection = {}; }
        if (importParcel === void 0) { importParcel = {}; }
        if (countCases === void 0) { countCases = null; }
        if (existingEasementInfo === void 0) { existingEasementInfo = null; }
        return {
            countCases: countCases,
            editable: editable,
            include: include,
            csvParcelIntersection: csvParcelIntersection,
            symapParcelIntersection: symapParcelIntersection,
            warnings: {},
            importParcel: importParcel,
            existingEasementInfo: existingEasementInfo
        };
    };
    EasementCreateSummaryComponent.mapEasementRow = function (easement) {
        var future = easement.parcel.future ? 'budoucí' : '';
        var row = [
            '<li>',
            "Parcela " + future + " " + easement.parcel.parcelName,
            "plocha " + easement.easement.importParcel.area + " [<span class=\"unit\">m<sup>2</sup></span>]",
            "d\u00E9lka " + easement.easement.importParcel.length + " [<span class=\"unit\">m</span>]",
            '</li>',
        ];
        return row.join(' ');
    };
    return EasementCreateSummaryComponent;
}(easement_create_step_component_1.EasementCreateStepComponent));
exports.EasementCreateSummaryComponent = EasementCreateSummaryComponent;
