"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var case_model_1 = require("@app/ps/models/case.model");
var FormMeetingRequestComponent = /** @class */ (function () {
    function FormMeetingRequestComponent() {
        this.callbackRegister = new core_1.EventEmitter();
        this.isValid = this.isValid.bind(this);
    }
    FormMeetingRequestComponent.prototype.ngOnInit = function () {
        this.data.meetingRequestDate = new Date();
        this.callbackRegister.emit({
            isValid: this.isValid,
        });
    };
    FormMeetingRequestComponent.prototype.isValid = function () {
        return !!this.data.meetingRequestDate;
    };
    return FormMeetingRequestComponent;
}());
exports.FormMeetingRequestComponent = FormMeetingRequestComponent;
