"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".notification[_ngcontent-%COMP%] {\n  background: #f2f2f2;\n  display: flex;\n  position: relative;\n  padding: 1rem; }\n\n.notification--info[_ngcontent-%COMP%] {\n  border: 1px solid #dddddd; }\n\n.notification--warning[_ngcontent-%COMP%] {\n  border: 1px solid #dddd60; }\n\n.notification--warning[_ngcontent-%COMP%]   .notification__icon[_ngcontent-%COMP%] {\n  color: #dddd60; }\n\n.notification--danger[_ngcontent-%COMP%] {\n  border: 1px solid #dc3545; }\n\n.notification--danger[_ngcontent-%COMP%]   .notification__icon[_ngcontent-%COMP%] {\n  color: #dc3545; }\n\n.notification__content[_ngcontent-%COMP%] {\n  flex-grow: 2;\n  text-align: center; }"];
exports.styles = styles;
