"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var dialog_service_1 = require("@app/dialog/dialog.service");
var expert_edit_form_component_1 = require("@app/ps/components/expert-edit-form/expert-edit-form.component");
var ExpertDetailComponent = /** @class */ (function () {
    function ExpertDetailComponent(restangular, dialogService) {
        this.restangular = restangular;
        this.dialogService = dialogService;
        this.loading = true;
        this.onEdit = this.onEdit.bind(this);
    }
    ExpertDetailComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.restangular
            .one('experts', this.expertId)
            .get()
            .then(function (data) {
            _this.expert = data.plain();
            _this.loading = false;
        });
    };
    ExpertDetailComponent.prototype.onEdit = function () {
        var _this = this;
        var dialog = this.dialogService.open(expert_edit_form_component_1.ExpertEditFormComponent, {
            data: { expert: this.expert }
        });
        var sub = dialog.afterClosed.subscribe(function (res) {
            if (res) {
                Object.assign(_this.expert, res);
            }
            sub.unsubscribe();
        });
    };
    return ExpertDetailComponent;
}());
exports.ExpertDetailComponent = ExpertDetailComponent;
