"use strict";
var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
Object.defineProperty(exports, "__esModule", { value: true });
var XLSX = require("sheetjs-style");
var i0 = require("@angular/core");
exports.DEFAULT_CELL_OPTIONS = { style: { alignment: { vertical: 'top' } } };
exports.DEFAULT_CELL_OPTIONS_WRAP = { style: { alignment: { vertical: 'top', wrapText: true } } };
exports.DEFAULT_CELL_OPTIONS_H_CENTER = { style: { alignment: { vertical: 'top', horizontal: 'center' } } };
var XlsxService = /** @class */ (function () {
    function XlsxService() {
    }
    XlsxService.prototype.createFile = function (data, name, columnOptions, bodyCellOptions) {
        if (columnOptions === void 0) { columnOptions = {}; }
        if (bodyCellOptions === void 0) { bodyCellOptions = {}; }
        var e_1, _a;
        // init worksheet
        var ws = XLSX.utils.aoa_to_sheet(data);
        var range = XLSX.utils.decode_range(ws['!ref']);
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        // columns styles
        var columnsMaxLength = {};
        var rowsMaxHeight = {};
        for (var r = range.s.r; r <= range.e.r; ++r) {
            for (var c = range.s.c; c <= range.e.c; ++c) {
                var cellAddress = { c: c, r: r };
                var cellRef = XLSX.utils.encode_cell(cellAddress);
                if (ws[cellRef] === undefined) {
                    continue;
                }
                if (r === 0) {
                    ws[cellRef].s = XlsxService.HEADER_CELL_OPTIONS.style;
                }
                else {
                    if (bodyCellOptions[c] && bodyCellOptions[c].style) {
                        ws[cellRef].s = bodyCellOptions[c].style;
                    }
                }
                var chunks = (typeof data[r][c] === 'string' ? data[r][c].split('\n') : [data[r][c]]);
                if (rowsMaxHeight[r] === undefined || (rowsMaxHeight[r] < chunks.length)) {
                    rowsMaxHeight[r] = chunks.length;
                }
                try {
                    for (var chunks_1 = __values(chunks), chunks_1_1 = chunks_1.next(); !chunks_1_1.done; chunks_1_1 = chunks_1.next()) {
                        var chunk = chunks_1_1.value;
                        if (columnsMaxLength[c] === undefined || (columnsMaxLength[c] < chunk.length)) {
                            columnsMaxLength[c] = chunk.length;
                        }
                    }
                }
                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                finally {
                    try {
                        if (chunks_1_1 && !chunks_1_1.done && (_a = chunks_1.return)) _a.call(chunks_1);
                    }
                    finally { if (e_1) throw e_1.error; }
                }
            }
        }
        // columns width
        var cols = [];
        for (var c = range.s.c; c <= range.e.c; ++c) {
            if (columnOptions && columnOptions[c] && columnOptions[c].width) {
                var width = columnOptions[c].width;
                switch (width.type) {
                    case 'auto':
                        cols.push({ wch: (columnsMaxLength[c] ? columnsMaxLength[c] : 10) });
                        break;
                    case 'wch':
                        cols.push({ wch: width.value });
                        break;
                    case 'wpx':
                        cols.push({ wpx: width.value });
                        break;
                }
            }
            else {
                cols.push({ wch: (columnsMaxLength[c] ? columnsMaxLength[c] : 10) });
            }
        }
        ws['!cols'] = cols;
        // rows height
        var rows = [];
        for (var r = range.s.r; r <= range.e.r; ++r) {
            rows.push(rowsMaxHeight[r] ? { hpt: rowsMaxHeight[r] * 15 } : undefined);
        }
        ws['!rows'] = rows;
        // export
        XLSX.writeFile(wb, name, { bookType: 'xlsx', type: 'buffer' });
    };
    XlsxService.HEADER_CELL_OPTIONS = {
        style: {
            fill: {
                patternType: 'solid',
                fgColor: { rgb: 'ffeeeeee' },
            },
            font: {
                bold: true,
            }
        }
    };
    XlsxService.ngInjectableDef = i0.defineInjectable({ factory: function XlsxService_Factory() { return new XlsxService(); }, token: XlsxService, providedIn: "root" });
    return XlsxService;
}());
exports.XlsxService = XlsxService;
