"use strict";
var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
Object.defineProperty(exports, "__esModule", { value: true });
var settings_service_1 = require("@app/ps/services/settings.service");
var table_service_1 = require("@app/ps/services/table.service");
var project_status_service_1 = require("@app/ps/services/project-status.service");
var i0 = require("@angular/core");
var i1 = require("./settings.service");
var i2 = require("./table.service");
var i3 = require("./project-status.service");
var ExportListService = /** @class */ (function () {
    function ExportListService(settingsService, tableService, projectStatusService, restangular) {
        this.settingsService = settingsService;
        this.tableService = tableService;
        this.projectStatusService = projectStatusService;
        this.restangular = restangular;
    }
    ExportListService.prototype.exportDefaultList = function (tableId) {
        var conf = ExportListService.DEFAULT_EXPORT_CONFIG[tableId];
        return this.exportList(conf.endpoint, tableId, conf.filter || { filter: {} });
    };
    ExportListService.prototype.exportList = function (endpoint, tableId, filter, attributes) {
        var _this = this;
        if (attributes === void 0) { attributes = {}; }
        var e_1, _a, e_2, _b;
        filter.limit = null;
        if (filter.sortOrder && !(filter.sortOrder instanceof Array)) {
            filter.sortOrder = [filter.sortOrder];
        }
        var table = this.tableService.getTables().find(function (t) { return t.id === tableId; });
        var columnDefinitions = [];
        try {
            for (var _c = __values(table.columns), _d = _c.next(); !_d.done; _d = _c.next()) {
                var c = _d.value;
                if (!this.settingsService.shouldHideColumn(tableId, c.id) && c.exportDefinitions && c.exportDefinitions.length) {
                    try {
                        for (var _e = __values(c.exportDefinitions), _f = _e.next(); !_f.done; _f = _e.next()) {
                            var d = _f.value;
                            columnDefinitions.push(d);
                        }
                    }
                    catch (e_2_1) { e_2 = { error: e_2_1 }; }
                    finally {
                        try {
                            if (_f && !_f.done && (_b = _e.return)) _b.call(_e);
                        }
                        finally { if (e_2) throw e_2.error; }
                    }
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return this.restangular
            .one(endpoint + "/export")
            .customPOST({
            attributes: attributes,
            filter: filter,
            columnDefinitions: columnDefinitions
        })
            .then(function (data) {
            _this.projectStatusService.refresh(data);
        });
    };
    ExportListService.DEFAULT_EXPORT_CONFIG = {
        cases: { endpoint: 'cases', filter: { filters: { loadCollections: ['titles', 'caseOwnerships', 'constructionObjects', 'caseSubjects',] } } },
        constructionObjects: { endpoint: 'construction-objects', filter: { filters: { validity: 'valid', loadCollections: ['occupationResolution', 'administrator'] } } },
        geometricPlanRequests: { endpoint: 'geometric-plan-requests', filter: { filters: { deleted: { values: [false] }, loadCollections: ['areas', 'constructionObjects', 'parcels'] } } },
        occupations: { endpoint: 'occupations', filter: { filters: { validOrWithCase: true, loadCollections: ['constructionObjects', 'parcel.title.ownerships', 'cases'] } } },
        opinionRequests: { endpoint: 'opinion-requests', filter: { filters: { deleted: { values: [false] }, loadCollections: ['parcels'] } } },
        owners: { endpoint: 'subjects', filter: { filters: { opsubType: { values: [{ id: 'BSM' }], negation: true }, validity: { values: ['withOccupationOrEasement', 'withOwnership'] }, loadCollections: ['ownerships', 'occupationResolution', 'cases'] } } },
        parcels: { endpoint: 'parcels', filter: { filters: { validity: 'valid', loadCollections: ['title.ownerships', 'geometricPlanRequests', 'opinionRequests', 'constructionObjects', 'knBudoucPepvs', 'parcelPrices', 'buildings', 'notCancelledCases'] } } },
        titles: { endpoint: 'titles', filter: { filters: { validity: 'valid', loadCollections: ['ownerships', 'occupationResolution', 'duplicityOwnership', 'cases'] } } },
    };
    ExportListService.ngInjectableDef = i0.defineInjectable({ factory: function ExportListService_Factory() { return new ExportListService(i0.inject(i1.SettingsService), i0.inject(i2.TableService), i0.inject(i3.ProjectStatusService), i0.inject("Restangular")); }, token: ExportListService, providedIn: "root" });
    return ExportListService;
}());
exports.ExportListService = ExportListService;
