"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var class_name_enum_1 = require("@app/dialog/class-name.enum");
var confirmation_component_1 = require("@app/dialog/confirmation/confirmation.component");
var dialog_config_1 = require("@app/dialog/dialog-config");
var contracts_register_legal_exceptions_1 = require("@app/ps/cases/components/form-contracts-register/contracts-register-legal-exceptions");
var dialog_ref_1 = require("@app/dialog/dialog-ref");
var dialog_service_1 = require("@app/dialog/dialog.service");
var FormContractsRegisterDialogComponent = /** @class */ (function () {
    function FormContractsRegisterDialogComponent(restangular, config, dialog, dialogService, cdr) {
        this.restangular = restangular;
        this.config = config;
        this.dialog = dialog;
        this.dialogService = dialogService;
        this.cdr = cdr;
        this.publishFormVisible = false;
        this.doNotPublishFormVisible = false;
    }
    FormContractsRegisterDialogComponent.prototype.ngOnInit = function () {
        this.caseObject = this.config.data.caseObject;
        this.initialCaseObject = __assign({}, this.caseObject);
        this.isValidForNotPublishing = this.isValidForNotPublishing.bind(this);
        this.isValidForPublishing = this.isValidForPublishing.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onExceptionSelected = this.onExceptionSelected.bind(this);
        this.onRenderOrSubmitDoNotPublishForm = this.onRenderOrSubmitDoNotPublishForm.bind(this);
        this.onRenderPublishForm = this.onRenderPublishForm.bind(this);
        this.onSend = this.onSend.bind(this);
    };
    FormContractsRegisterDialogComponent.prototype.onExceptionSelected = function (value) {
        this.caseObject.contractRegisterPublicationException = value;
    };
    FormContractsRegisterDialogComponent.prototype.onCancel = function () {
        this.publishFormVisible = false;
        this.doNotPublishFormVisible = false;
        this.caseObject = __assign({}, this.initialCaseObject);
    };
    FormContractsRegisterDialogComponent.prototype.onRenderPublishForm = function () {
        var _this = this;
        if (!this.caseObject.contractRegisterPublicationNecessary) {
            var dialog = this.createContractPublishmentConfirmationDialogWhenNotRequired();
            var sub_1 = dialog.afterClosed.subscribe(function (result) {
                sub_1.unsubscribe();
                if (result) {
                    _this.publishFormVisible = true;
                    _this.cdr.detectChanges();
                }
                else {
                    _this.onCancel();
                }
            });
        }
        else {
            this.publishFormVisible = true;
        }
    };
    FormContractsRegisterDialogComponent.prototype.createContractPublishmentConfirmationDialogWhenNotRequired = function () {
        var data = {
            msg: 'Skutečně chcete tuto smlouvu zveřejnit v registru smluv? Její hodnota nepřesahuje 50000,- Kč.',
        };
        var dialog = this.dialogService.open(confirmation_component_1.ConfirmationComponent, {
            data: data,
            className: class_name_enum_1.ClassName.ADJUSTED_DIALOG,
        });
        return dialog;
    };
    FormContractsRegisterDialogComponent.prototype.onRenderOrSubmitDoNotPublishForm = function () {
        if (this.caseObject.contractRegisterPublicationNecessary) {
            this.doNotPublishFormVisible = !this.doNotPublishFormVisible;
        }
        else {
            this.caseObject.contractRegisterPublicationException = contracts_register_legal_exceptions_1.defaultLegalException;
            this.onSend();
        }
    };
    FormContractsRegisterDialogComponent.prototype.isValidForPublishing = function () {
        return !!(this.caseObject.contractRegisterPublicationDate
            && this.caseObject.contractRegisterPublicationContractId
            && this.caseObject.contractRegisterPublicationVersionId);
    };
    FormContractsRegisterDialogComponent.prototype.isValidForNotPublishing = function () {
        return !!this.caseObject.contractRegisterPublicationException;
    };
    FormContractsRegisterDialogComponent.prototype.onSend = function () {
        return __awaiter(this, void 0, void 0, function () {
            var result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.caseObject.contractRegisterPublicationDate = this.caseObject.contractRegisterPublicationDate || new Date();
                        return [4 /*yield*/, this.restangular
                                .one('cases', this.caseObject.id)
                                .customPUT(this.caseObject, 'contract-register-publication')];
                    case 1:
                        result = _a.sent();
                        this.dialog.close(result);
                        return [2 /*return*/, result];
                }
            });
        });
    };
    return FormContractsRegisterDialogComponent;
}());
exports.FormContractsRegisterDialogComponent = FormContractsRegisterDialogComponent;
