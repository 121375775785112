"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var LabelPositions;
(function (LabelPositions) {
    LabelPositions["AFTER"] = "after";
    LabelPositions["BEFORE"] = "before";
})(LabelPositions || (LabelPositions = {}));
var CheckboxComponent = /** @class */ (function () {
    function CheckboxComponent() {
        this.changed = new core_1.EventEmitter();
    }
    CheckboxComponent.prototype.ngOnInit = function () {
        this.gmtType = this.gmtType || 'checkbox';
        this.throwOnBadLabelPosition();
    };
    CheckboxComponent.prototype.onChanged = function () {
        this.changed.emit(this.gmtChecked);
    };
    /**
     * Is there a better way to check the @Input value?
     */
    CheckboxComponent.prototype.throwOnBadLabelPosition = function () {
        if (this.gmtLabelPosition && !Object.values(LabelPositions).includes(this.gmtLabelPosition)) {
            throw new Error("Provided invalid label position. Choose one of " + Object.values(LabelPositions) + ".");
        }
    };
    return CheckboxComponent;
}());
exports.CheckboxComponent = CheckboxComponent;
