"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var username_pipe_1 = require("../../../common/pipes/username.pipe");
var auth_service_1 = require("@app/common/services/auth.service");
var TitleMandataryChangeComponent = /** @class */ (function () {
    function TitleMandataryChangeComponent(authService, usernamePipe, helpIds, restangular) {
        this.authService = authService;
        this.usernamePipe = usernamePipe;
        this.helpIds = helpIds;
        this.restangular = restangular;
        this.callback = new core_1.EventEmitter();
        this.inputData = {};
        this.filter = {};
    }
    TitleMandataryChangeComponent.prototype.ngOnInit = function () {
        var currentApplication = this.authService.getActiveApplication();
        var currentProject = this.authService.getActualProject().key;
        this.filter = {
            filters: {
                searchText: {
                    values: [],
                },
                applications: {
                    values: [currentApplication],
                },
                permission: {
                    values: ['assignable'],
                },
                projects: {
                    values: [currentProject],
                },
            },
        };
        this.inputData = this.data.details || {};
    };
    TitleMandataryChangeComponent.prototype.updated = function () {
        var _this = this;
        this.notifyParent(function () { return !!_this.inputData.user; }, this.inputData);
    };
    return TitleMandataryChangeComponent;
}());
exports.TitleMandataryChangeComponent = TitleMandataryChangeComponent;
