"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./cell-ownership.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/common");
var i3 = require("../../directives/tooltip/tooltip.directive");
var i4 = require("../../pipes/subject-name.pipe");
var i5 = require("./cell-ownership.component");
var styles_CellOwnershipComponent = [i0.styles];
var RenderType_CellOwnershipComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CellOwnershipComponent, data: {} });
exports.RenderType_CellOwnershipComponent = RenderType_CellOwnershipComponent;
function View_CellOwnershipComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", "", ""])), i1.ɵppd(2, 1)], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent.parent.parent, 0), _v.context.$implicit)); var currVal_1 = (_v.context.last ? "" : " a "); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_CellOwnershipComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "tag tag--danger tag--inline is-uppercase"], ["title", "Problematick\u00FD vlastn\u00EDk"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["PV"]))], null, null); }
function View_CellOwnershipComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "li", [["class", "cell--flex__container"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "mt-5": 0 }), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "cell--flex__container__main"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CellOwnershipComponent_4)), i1.ɵdid(5, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵted(6, null, [" (", "/", ") "])), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "cell--flex__container_side"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CellOwnershipComponent_5)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = "cell--flex__container"; var currVal_1 = _ck(_v, 2, 0, !_v.context.first); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _v.context.$implicit.subjects; _ck(_v, 5, 0, currVal_2); var currVal_5 = (((_v.context.$implicit.subjects[0].subjectDetail == null) ? null : _v.context.$implicit.subjects[0].subjectDetail.problematic) || ((_v.context.$implicit.subjects[1] == null) ? null : ((_v.context.$implicit.subjects[1].subjectDetail == null) ? null : _v.context.$implicit.subjects[1].subjectDetail.problematic))); _ck(_v, 9, 0, currVal_5); }, function (_ck, _v) { var currVal_3 = _v.context.$implicit.podilCitatel; var currVal_4 = _v.context.$implicit.podilJmenovatel; _ck(_v, 6, 0, currVal_3, currVal_4); }); }
function View_CellOwnershipComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "li", [["class", "mt-5"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["a dal\u0161\u00ED (", ")"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.ownerships.length - _co.limit) + 1); _ck(_v, 1, 0, currVal_0); }); }
function View_CellOwnershipComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "ul", [["class", "flex-rest"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_CellOwnershipComponent_3)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(0, i2.SlicePipe, []), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CellOwnershipComponent_6)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.filteredOwnerships, 0, ((_co.ownerships.length > _co.limit) ? (_co.limit - 1) : _co.limit))); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.ownerships.length > _co.limit); _ck(_v, 5, 0, currVal_1); }, null); }
function View_CellOwnershipComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "fill-content p-10 ownership-count-column"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "icon-count"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "i", [["class", "fa"]], null, null, null, null, null)), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(4, { "fa-user": 0, "fa-users": 1 }), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "number ml-4"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "fill-content flex-centered p-10 ownership-list-column"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CellOwnershipComponent_2)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "fa"; var currVal_1 = _ck(_v, 4, 0, (_co.count === 1), (_co.count > 1)); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_3 = _co.ownerships; _ck(_v, 9, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.count; _ck(_v, 6, 0, currVal_2); }); }
function View_CellOwnershipComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_CellOwnershipComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "fill-content"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CellOwnershipComponent_8)), i1.ɵdid(2, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var currVal_0 = i1.ɵnov(_v.parent, 1); _ck(_v, 2, 0, currVal_0); }, null); }
function View_CellOwnershipComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_CellOwnershipComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 3, "div", [["class", "tooltip-hover-area fill-content"]], null, [[null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("mouseenter" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).mouseEnter() !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).closeTooltip() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 81920, null, 0, i3.TooltipDirective, [i1.ElementRef, "WindowDimensionService", i1.Renderer2, i1.ComponentFactoryResolver, i1.ViewContainerRef, i1.Injector], { content: [0, "content"], data: [1, "data"], activeContent: [2, "activeContent"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CellOwnershipComponent_10)), i1.ɵdid(3, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tooltipComponent; var currVal_1 = _co.tooltipData; var currVal_2 = true; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = i1.ɵnov(_v.parent, 1); _ck(_v, 3, 0, currVal_3); }, null); }
function View_CellOwnershipComponent_0(_l) { return i1.ɵvid(2, [i1.ɵpid(0, i4.SubjectNamePipe, []), (_l()(), i1.ɵand(0, [["ownershipCell", 2]], null, 0, null, View_CellOwnershipComponent_1)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CellOwnershipComponent_7)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CellOwnershipComponent_9)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.tooltipComponent || !_co.tooltipData); _ck(_v, 3, 0, currVal_0); var currVal_1 = (_co.tooltipComponent && _co.tooltipData); _ck(_v, 5, 0, currVal_1); }, null); }
exports.View_CellOwnershipComponent_0 = View_CellOwnershipComponent_0;
function View_CellOwnershipComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "cell-ownership", [], null, null, null, View_CellOwnershipComponent_0, RenderType_CellOwnershipComponent)), i1.ɵdid(1, 114688, null, 0, i5.CellOwnershipComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_CellOwnershipComponent_Host_0 = View_CellOwnershipComponent_Host_0;
var CellOwnershipComponentNgFactory = i1.ɵccf("cell-ownership", i5.CellOwnershipComponent, View_CellOwnershipComponent_Host_0, { tooltipData: "tooltipData", tooltipType: "tooltipType", count: "count", ownerships: "ownerships", limit: "limit" }, {}, []);
exports.CellOwnershipComponentNgFactory = CellOwnershipComponentNgFactory;
