"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("../../../../common/components/select/select.component.ngfactory");
var i2 = require("../../../../common/components/select/select.component");
var i3 = require("@uirouter/angular");
var i4 = require("@angular/common");
var i5 = require("../../../../common/components/button/button.component.ngfactory");
var i6 = require("../../../../common/components/button/button.component");
var i7 = require("../../../../common/components/checkbox/checkbox.component.ngfactory");
var i8 = require("../../../../common/components/checkbox/checkbox.component");
var i9 = require("../../../../common/directives/loading.directive");
var i10 = require("./dashboard-settings.component");
var i11 = require("../../../../common/services/list.service");
var styles_DashboardSettingsComponent = [];
var RenderType_DashboardSettingsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DashboardSettingsComponent, data: {} });
exports.RenderType_DashboardSettingsComponent = RenderType_DashboardSettingsComponent;
function View_DashboardSettingsComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "column-100"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Fin\u00E1ln\u00ED kategorie"])), (_l()(), i0.ɵeld(3, 0, null, null, 2, "div", [["class", "input"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "gmt-select", [], null, null, null, i1.View_SelectComponent_0, i1.RenderType_SelectComponent)), i0.ɵdid(5, 114688, null, 0, i2.SelectComponent, ["ListLoadService", "WindowDimensionService", i0.ChangeDetectorRef, i0.ElementRef, "Restangular", i3.StateService], { data: [0, "data"], field: [1, "field"], optionItems: [2, "optionItems"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data; var currVal_1 = i0.ɵinlineInterpolate(1, "", _v.parent.context.$implicit.type, "Category"); var currVal_2 = _co.data[_v.parent.context.$implicit.type].categories; _ck(_v, 5, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_DashboardSettingsComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "div", [["class", "column-100"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 10, "fieldset", [["class", "bordered"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "legend", [], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""])), (_l()(), i0.ɵeld(4, 0, null, null, 5, "div", [["class", "column-100"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Statistika"])), (_l()(), i0.ɵeld(7, 0, null, null, 2, "div", [["class", "input"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 1, "gmt-select", [], null, [[null, "changed"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("changed" === en)) {
        var pd_0 = (_co.onStatisticsChanged(_v.context.$implicit.type, $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_SelectComponent_0, i1.RenderType_SelectComponent)), i0.ɵdid(9, 114688, null, 0, i2.SelectComponent, ["ListLoadService", "WindowDimensionService", i0.ChangeDetectorRef, i0.ElementRef, "Restangular", i3.StateService], { data: [0, "data"], field: [1, "field"], optionItems: [2, "optionItems"] }, { changed: "changed" }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DashboardSettingsComponent_3)), i0.ɵdid(11, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.data; var currVal_2 = i0.ɵinlineInterpolate(1, "", _v.context.$implicit.type, ""); var currVal_3 = _co.statistics; _ck(_v, 9, 0, currVal_1, currVal_2, currVal_3); var currVal_4 = _co.data[_v.context.$implicit.type]; _ck(_v, 11, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.name; _ck(_v, 3, 0, currVal_0); }); }
function View_DashboardSettingsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 13, "div", [["class", "flexbox-container flex-column"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "div", [["class", "column-100"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 3, "div", [["class", "input"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "gmt-button", [], null, null, null, i5.View_ButtonComponent_0, i5.RenderType_ButtonComponent)), i0.ɵdid(4, 114688, null, 0, i6.ButtonComponent, ["ErrorHandlerService", i0.ChangeDetectorRef], { clickAction: [0, "clickAction"] }, null), (_l()(), i0.ɵted(-1, 0, ["Smazat datovou cache pro Dashboard"])), (_l()(), i0.ɵeld(6, 0, null, null, 5, "div", [["class", "column-100"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 1, "label", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.data.show = !_co.data.show) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Zobrazit v Dashboardu"])), (_l()(), i0.ɵeld(9, 0, null, null, 2, "div", [["class", "info"]], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 1, "gmt-checkbox", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.data.show = !_co.data.show) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_CheckboxComponent_0, i7.RenderType_CheckboxComponent)), i0.ɵdid(11, 114688, null, 0, i8.CheckboxComponent, [], { gmtChecked: [0, "gmtChecked"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DashboardSettingsComponent_2)), i0.ɵdid(13, 278528, null, 0, i4.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.cleanCache; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.data.show; _ck(_v, 11, 0, currVal_1); var currVal_2 = _co.dashboardFields; _ck(_v, 13, 0, currVal_2); }, null); }
function View_DashboardSettingsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [], [[8, "className", 0]], null, null, null, null)), i0.ɵdid(1, 16384, null, 0, i9.LoadingDirective, [i0.ElementRef], { loading: [0, "loading"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DashboardSettingsComponent_1)), i0.ɵdid(3, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = !_co.data; _ck(_v, 1, 0, currVal_1); var currVal_2 = _co.data; _ck(_v, 3, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).elementClass; _ck(_v, 0, 0, currVal_0); }); }
exports.View_DashboardSettingsComponent_0 = View_DashboardSettingsComponent_0;
function View_DashboardSettingsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "dashboard-settings", [], null, null, null, View_DashboardSettingsComponent_0, RenderType_DashboardSettingsComponent)), i0.ɵdid(1, 114688, null, 0, i10.DashboardSettingsComponent, [i11.ListService, "APPLICATIONS", "ConfigRestangularService", "SymapRestangularService"], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_DashboardSettingsComponent_Host_0 = View_DashboardSettingsComponent_Host_0;
var DashboardSettingsComponentNgFactory = i0.ɵccf("dashboard-settings", i10.DashboardSettingsComponent, View_DashboardSettingsComponent_Host_0, { project: "project", dashboardDefaults: "dashboardDefaults" }, { loaded: "loaded" }, []);
exports.DashboardSettingsComponentNgFactory = DashboardSettingsComponentNgFactory;
