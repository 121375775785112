const component = {
    template: `
    <a ui-sref="{{$ctrl.sref}}" title="{{$ctrl.text}}" class="button mb-10">
      <i class="fa fa-chevron-left mr-5"></i>{{$ctrl.text}}
    </a>
    `,
    bindings: {
      text: '<',
      sref: '<',
    }
};

export default component;
