"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var BulkOperationFinishComponent = /** @class */ (function () {
    function BulkOperationFinishComponent(componentFactoryResolver) {
        this.componentFactoryResolver = componentFactoryResolver;
    }
    BulkOperationFinishComponent.prototype.ngOnInit = function () {
        if (this.data.selectAction && this.data.selectAction.finishComponent) {
            var factory = this.componentFactoryResolver
                .resolveComponentFactory(this.data.selectAction.finishComponent);
            this.finishComponent = factory
                .create(this.viewContainerRef.parentInjector);
            this.viewContainerRef.insert(this.finishComponent.hostView);
            this.finishComponent.instance.data = this.data;
            this.finishComponent.instance.newOperation = this.newOperation;
            this.finishComponent.instance.goToList = this.goToList;
        }
    };
    return BulkOperationFinishComponent;
}());
exports.BulkOperationFinishComponent = BulkOperationFinishComponent;
