"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var _ = require("lodash");
var i0 = require("@angular/core");
var i1 = require("@angular/common/http");
var MapUtilsWfsFeatureService = /** @class */ (function () {
    function MapUtilsWfsFeatureService(http) {
        this.http = http;
    }
    //////////////////////////////////////////
    MapUtilsWfsFeatureService.prototype.getFeatureByID = function (baseURL, typeName, featureID, queryParams) {
        var url = baseURL + '?request=GetFeature&service=WFS&version=1.0.0&outputFormat=application/json&typeName=' + encodeURIComponent(typeName) + '&CQL_FILTER=id=' + featureID;
        if (queryParams) {
            url += _.join(_.map(queryParams, function (value, key) { return '&' + key + '=' + value; }), '');
        }
        return this.http.get(url).toPromise();
    };
    MapUtilsWfsFeatureService.ngInjectableDef = i0.defineInjectable({ factory: function MapUtilsWfsFeatureService_Factory() { return new MapUtilsWfsFeatureService(i0.inject(i1.HttpClient)); }, token: MapUtilsWfsFeatureService, providedIn: "root" });
    return MapUtilsWfsFeatureService;
}());
exports.MapUtilsWfsFeatureService = MapUtilsWfsFeatureService;
