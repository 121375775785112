"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var file_utils_1 = require("@app/common/utils/file.utils");
var FileValidatorUtils = /** @class */ (function () {
    function FileValidatorUtils() {
    }
    FileValidatorUtils.checkValidity = function (files) {
        var valid = FileValidatorUtils.checkFiles(files);
        if (valid) {
            var firstExtension = files[0].extension.toLowerCase();
            if (FileValidatorUtils.SINGLE_FILES.indexOf(firstExtension) !== -1) {
                valid = files.length === 1;
            }
            else if (FileValidatorUtils.SHP_FILES.indexOf(firstExtension) !== -1) {
                valid = FileValidatorUtils.checkSHPValidity(files);
            }
        }
        return valid;
    };
    FileValidatorUtils.checkSHPValidity = function (files) {
        var valid = (files.length === FileValidatorUtils.SHP_FILES.length);
        if (valid) {
            var extensions_1 = files.map(function (f) { return f.extension.toLowerCase(); });
            var diff = FileValidatorUtils.SHP_FILES.filter(function (value) { return extensions_1.indexOf(value) === -1; });
            valid = (diff.length === 0 && file_utils_1.FileUtils.hasFilesSameTitles(files));
        }
        return valid;
    };
    FileValidatorUtils.getInvalidMessage = function (files) {
        var valid = FileValidatorUtils.checkFiles(files);
        var msg = '';
        if (valid) {
            var firstExtension = files[0].extension.toLowerCase();
            if (FileValidatorUtils.SINGLE_FILES.indexOf(firstExtension) !== -1) {
                msg = FileValidatorUtils.MSG_INVALID_COUNT;
            }
            else if (FileValidatorUtils.SHP_FILES.indexOf(firstExtension) !== -1 && file_utils_1.FileUtils.hasFilesSameTitles(files)) {
                msg = FileValidatorUtils.MSG_INVALID_UNCOMPLEATE_SHP;
            }
            else if (FileValidatorUtils.SHP_FILES.indexOf(firstExtension) !== -1 && !file_utils_1.FileUtils.hasFilesSameTitles(files)) {
                msg = FileValidatorUtils.MSG_INVALID_COUNT;
            }
        }
        else {
            msg = FileValidatorUtils.MSG_INVALID;
        }
        return msg;
    };
    FileValidatorUtils.getValidMessage = function (files) {
        if (files.every(function (f) { return f.extension.toLowerCase() === 'vfk'; })) {
            return FileValidatorUtils.MSG_VALID_VFK;
        }
        else if (files.every(function (f) { return f.extension.toLowerCase() === 'dxf'; })) {
            return FileValidatorUtils.MSG_VALID_DXF;
        }
        else if (FileValidatorUtils.checkSHPValidity(files)) {
            return FileValidatorUtils.MSG_VALID_SHP;
        }
        else {
            return FileValidatorUtils.MSG_VALID_ADD_NEXT;
        }
    };
    ;
    FileValidatorUtils.checkFiles = function (files) {
        if (files === void 0) { files = []; }
        return files.length !== 0 && files.every(function (file) { return FileValidatorUtils.SUPPORTED_FILES.indexOf(file.extension.toLowerCase()) !== -1; });
    };
    FileValidatorUtils.SHP_FILES = ['shp', 'shx', 'dbf', 'prj'];
    FileValidatorUtils.SINGLE_FILES = ['vfk', 'dxf'];
    FileValidatorUtils.SUPPORTED_FILES = __spread(FileValidatorUtils.SHP_FILES, FileValidatorUtils.SINGLE_FILES);
    FileValidatorUtils.MSG_VALID_VFK = "Vlo\u017Een soubor vfk, pokra\u010Dujte nahr\u00E1n\u00EDm.";
    FileValidatorUtils.MSG_VALID_DXF = "Vlo\u017Een soubor dxf, pokra\u010Dujte nahr\u00E1n\u00EDm.";
    FileValidatorUtils.MSG_VALID_SHP = "Vlo\u017Een soubor shapefile, pokra\u010Dujte nahr\u00E1n\u00EDm.";
    FileValidatorUtils.MSG_VALID_ADD_NEXT = "Vlo\u017Ete dal\u0161\u00ED soubory.";
    FileValidatorUtils.MSG_INVALID = "Vlo\u017Een\u00E9 soubory nejsou podporovan\u00E9. Vlo\u017Ete pouze soubory ve form\u00E1tu VFK, DXF nebo SHP.";
    FileValidatorUtils.MSG_INVALID_COUNT = "Je mo\u017En\u00E9 vlo\u017Eit pouze jeden soubor dxf, vfk nebo shapefile.";
    FileValidatorUtils.MSG_INVALID_UNCOMPLEATE_SHP = "Nejsou vlo\u017Eeny v\u0161echny pot\u0159ebn\u00E9 shapefile soubory [" + FileValidatorUtils.SHP_FILES.join(', ') + "].";
    return FileValidatorUtils;
}());
exports.FileValidatorUtils = FileValidatorUtils;
