"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var i0 = require("@angular/core");
var ApplicationsPipe = /** @class */ (function () {
    function ApplicationsPipe(APP_BRAND, APPLICATIONS) {
        this.APP_BRAND = APP_BRAND;
        this.APPLICATIONS = APPLICATIONS;
    }
    ApplicationsPipe.prototype.transform = function (applications) {
        var _this = this;
        if (applications === void 0) { applications = []; }
        return applications.map(function (name) {
            return name === _this.APPLICATIONS.sy.name ? _this.APP_BRAND.PS.TITLE : name;
        }).join(', ');
    };
    ApplicationsPipe.ngInjectableDef = i0.defineInjectable({ factory: function ApplicationsPipe_Factory() { return new ApplicationsPipe(i0.inject("APP_BRAND"), i0.inject("APPLICATIONS")); }, token: ApplicationsPipe, providedIn: "root" });
    return ApplicationsPipe;
}());
exports.ApplicationsPipe = ApplicationsPipe;
