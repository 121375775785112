"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var OpinionService = /** @class */ (function () {
    function OpinionService(restangular) {
        this.restangular = restangular;
    }
    OpinionService.prototype.getOne = function (opinionId) {
        return this.restangular
            .one('opinions', opinionId)
            .get()
            .then(function (response) { return response.plain(); });
    };
    OpinionService.prototype.createOne = function (opinion) {
        return this.restangular
            .all('opinions')
            .all('create')
            .post(opinion);
    };
    OpinionService.prototype.updateOne = function (opinionId, opinion) {
        return this.restangular
            .one('opinions', opinionId)
            .customPUT(opinion);
    };
    OpinionService.prototype.deleteOne = function (opinionId) {
        return this.restangular
            .one('opinions', opinionId)
            .remove();
    };
    OpinionService.prototype.getOpinionsForTitle = function (titleId) {
        return this.restangular
            .all('opinions')
            .customPOST({
            filter: {
                filters: {
                    titleId: titleId,
                    validity: ['valid'],
                },
                sortOrder: [
                    {
                        sortBy: 'id',
                    },
                ],
            },
        })
            .then(function (response) { return response.plain(); });
    };
    OpinionService.prototype.getParcels = function (filter) {
        return this.restangular
            .all('parcels')
            .customPOST({ filter: filter })
            .then(function (response) { return response.plain(); });
    };
    OpinionService.prototype.getBuildings = function (filter) {
        return this.restangular
            .all('buildings')
            .customPOST({ filter: filter })
            .then(function (response) { return response.plain(); });
    };
    OpinionService.prototype.getParcelPrices = function (opinionId) {
        return this.restangular
            .all('parcel-prices')
            .customPOST({
            filter: {
                filters: {
                    opinionId: [opinionId],
                    loadCollections: ['building.parcels'],
                },
            },
        })
            .then(function (response) { return response.plain(); });
    };
    OpinionService.prototype.getTitleParcelPrices = function (titleId) {
        return this.restangular
            .all('parcel-prices')
            .customPOST({
            filter: {
                filters: {
                    titleId: [titleId],
                    validity: ['valid'],
                },
            },
        })
            .then(function (response) { return response.plain(); });
    };
    OpinionService.ngInjectableDef = i0.defineInjectable({ factory: function OpinionService_Factory() { return new OpinionService(i0.inject("Restangular")); }, token: OpinionService, providedIn: "root" });
    return OpinionService;
}());
exports.OpinionService = OpinionService;
