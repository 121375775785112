"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var modules_service_1 = require("@app/common/services/modules.service");
var dialog_ref_1 = require("@app/dialog/dialog-ref");
var dialog_config_1 = require("@app/dialog/dialog-config");
var customer_name_pipe_1 = require("@app/common/pipes/customer-name.pipe");
var auth_service_1 = require("@app/common/services/auth.service");
var UserEditComponent = /** @class */ (function () {
    function UserEditComponent(helpIds, APP_BRAND, globalRestangular, customerNamePipe, modulesService, dialogRef, dialogConfig, authService) {
        this.helpIds = helpIds;
        this.APP_BRAND = APP_BRAND;
        this.globalRestangular = globalRestangular;
        this.customerNamePipe = customerNamePipe;
        this.modulesService = modulesService;
        this.dialogRef = dialogRef;
        this.dialogConfig = dialogConfig;
        this.authService = authService;
        this.loading = false;
        this.user = {};
        this.originalUser = {};
        this.editor = this.authService.getUser();
        this.userId = dialogConfig.data.userId;
        this.modules = dialogConfig.data.modules;
        this.create = this.userId === undefined;
        this.onSave = this.onSave.bind(this);
    }
    UserEditComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (!this.create) {
            this.loading = true;
            return this.modulesService.getUser(this.userId).then(function (user) {
                _this.user = user.plain();
                _this.username = _this.user.username;
                delete _this.user.username;
                _this.originalUser = __assign({}, _this.user);
                _this.loading = false;
            });
        }
    };
    UserEditComponent.prototype.onSave = function () {
        var _this = this;
        if (this.create) {
            if (this.modules) {
                this.user.applications = this.modules;
            }
            return this.modulesService.createUser(this.user).then(function (data) {
                _this.dialogRef.close(data.plain());
            });
        }
        else {
            return this.modulesService.saveUser(this.user).then(function (data) {
                _this.dialogRef.close(data.plain());
            });
        }
    };
    UserEditComponent.prototype.isValid = function () {
        if (this.APP_BRAND.NAME === 'RSD' || this.APP_BRAND.NAME === 'SZ') {
            return this.checkFilledFields();
        }
        else if (this.create) {
            return (this.checkPassword() && this.checkFilledFields() && this.checkUsername());
        }
        else {
            return (this.checkPassword() && this.checkChangedFields() && this.checkFilledFields() && this.checkUsername());
        }
    };
    UserEditComponent.prototype.checkChangedFields = function () {
        var e_1, _a;
        var checkFields = ['titleBefore', 'surname', 'email', 'phone', 'name', 'degreeAfter', 'company', 'username', 'superuser', 'password'];
        try {
            for (var checkFields_1 = __values(checkFields), checkFields_1_1 = checkFields_1.next(); !checkFields_1_1.done; checkFields_1_1 = checkFields_1.next()) {
                var field = checkFields_1_1.value;
                if (this.user[field] !== this.originalUser[field] && (!this.user[field] || !this.originalUser[field] || typeof this.user[field] !== 'object' || this.user[field].id !== this.originalUser[field].id)) {
                    return true;
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (checkFields_1_1 && !checkFields_1_1.done && (_a = checkFields_1.return)) _a.call(checkFields_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return false;
    };
    UserEditComponent.prototype.checkFilledFields = function () {
        var e_2, _a;
        var checkFields = ['surname', 'email', 'name', 'company'];
        try {
            for (var checkFields_2 = __values(checkFields), checkFields_2_1 = checkFields_2.next(); !checkFields_2_1.done; checkFields_2_1 = checkFields_2.next()) {
                var field = checkFields_2_1.value;
                if (!this.user[field]) {
                    return false;
                }
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (checkFields_2_1 && !checkFields_2_1.done && (_a = checkFields_2.return)) _a.call(checkFields_2);
            }
            finally { if (e_2) throw e_2.error; }
        }
        return true;
    };
    UserEditComponent.prototype.checkPassword = function () {
        if (this.user.password) {
            return this.user.password === this.user.passwordCheck;
        }
        else {
            return !this.create;
        }
    };
    UserEditComponent.prototype.checkUsername = function () {
        if (this.user.username) {
            return !!this.user.password;
        }
        if (this.create) {
            return !!(this.user.password && this.user.username);
        }
        else {
            return true;
        }
    };
    return UserEditComponent;
}());
exports.UserEditComponent = UserEditComponent;
