"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var case_model_1 = require("@app/ps/models/case.model");
var FormExpropriationCompensationPaymentComponent = /** @class */ (function () {
    function FormExpropriationCompensationPaymentComponent() {
        this.callbackRegister = new core_1.EventEmitter();
        this.isValid = this.isValid.bind(this);
    }
    FormExpropriationCompensationPaymentComponent.prototype.ngOnInit = function () {
        this.data.expropriationCompensationPaymentDate = new Date();
        this.callbackRegister.emit({
            isValid: this.isValid,
        });
    };
    FormExpropriationCompensationPaymentComponent.prototype.isValid = function () {
        return !!this.data.expropriationCompensationPaymentDate;
    };
    return FormExpropriationCompensationPaymentComponent;
}());
exports.FormExpropriationCompensationPaymentComponent = FormExpropriationCompensationPaymentComponent;
