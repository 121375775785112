"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var _ = require("lodash");
var i0 = require("@angular/core");
var NumericPipe = /** @class */ (function () {
    function NumericPipe() {
    }
    NumericPipe.prototype.transform = function (text) {
        var isString = typeof text === 'string';
        if (_.isNumber(text) || !isString || text.length === 0) {
            return text;
        }
        var res = parseFloat(text.replace(',', '.').replace(/[^\d\.]*/g, ''));
        if (isNaN(res)) {
            return;
        }
        return res;
    };
    NumericPipe.ngInjectableDef = i0.defineInjectable({ factory: function NumericPipe_Factory() { return new NumericPipe(); }, token: NumericPipe, providedIn: "root" });
    return NumericPipe;
}());
exports.NumericPipe = NumericPipe;
