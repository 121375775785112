"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var list_model_1 = require("@app/common/models/list.model");
var settings_service_1 = require("@app/ps/services/settings.service");
var table_service_1 = require("@app/ps/services/table.service");
var TableCasesComponent = /** @class */ (function () {
    function TableCasesComponent(tableService, settingsService) {
        this.tableService = tableService;
        this.settingsService = settingsService;
        this.caseStatusType = 'normal';
        this.showNavIcons = false;
        this.checkAll = new core_1.EventEmitter();
        this.sort = new core_1.EventEmitter();
        this.toggleCheckItem = new core_1.EventEmitter();
        this.TABLE_ID = 'cases';
        this.isArray = Array.isArray;
    }
    TableCasesComponent.prototype.onCheckAll = function () {
        this.checkAll.emit();
    };
    TableCasesComponent.prototype.onToggleCheckItem = function (title) {
        this.toggleCheckItem.emit(title);
    };
    TableCasesComponent.prototype.onSort = function (column, ascOrDesc) {
        this.sort.emit({
            column: column,
            ascOrDesc: ascOrDesc,
        });
    };
    TableCasesComponent.prototype.hideColumn = function (columnId) {
        if (this.customColumns) {
            return !this.customColumns.includes(columnId);
        }
        return this.settingsService.shouldHideColumn(this.TABLE_ID, columnId);
    };
    TableCasesComponent.prototype.getColumnName = function (columnId) {
        if (this.columnNames && this.columnNames[columnId]) {
            return this.columnNames[columnId];
        }
        return this.tableService.getColumnById(this.TABLE_ID, columnId).name;
    };
    return TableCasesComponent;
}());
exports.TableCasesComponent = TableCasesComponent;
