"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var table_parcels_abstract_component_1 = require("../table-parcels-abstract/table-parcels-abstract.component");
var auth_service_1 = require("@app/common/services/auth.service");
var TableOccupationsTemporaryComponent = /** @class */ (function (_super) {
    __extends(TableOccupationsTemporaryComponent, _super);
    function TableOccupationsTemporaryComponent(authService) {
        var _this = _super.call(this) || this;
        _this.authService = authService;
        _this.COMPONENT_COLUMNS = [
            'label_gp', 'occupation_area', 'occupation_title', 'occupation_parcel', 'occupation_parcel_title',
            'occupation_phase', 'occupation_occupation', 'occupation_cases', 'occupation_assessment_price',
            'occupation_price_land_price', 'occupation_price_rent_price', 'occupation_price_rent_price_year',
            'occupation_price_rent_length', 'occupation_concern_object', 'occupation_edit_price',
            'occupation_price_summary_cases', 'occupation_price_total_rental_price', 'current_title',
            'current_parcel', 'current_parcel_title', 'current_protection', 'current_concern_object',
            'occupation_update',
        ];
        _this.geometricPlanModuleEnabled = _this.authService.getActualProject().geometricPlanModuleEnabled;
        return _this;
    }
    TableOccupationsTemporaryComponent.prototype.canEdit = function (permissions) {
        return this.authService.hasPermission(permissions);
    };
    return TableOccupationsTemporaryComponent;
}(table_parcels_abstract_component_1.TableParcelsAbstractComponent));
exports.TableOccupationsTemporaryComponent = TableOccupationsTemporaryComponent;
