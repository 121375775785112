"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var MapLayerTypeEnum;
(function (MapLayerTypeEnum) {
    MapLayerTypeEnum["node"] = "node";
    MapLayerTypeEnum["wms"] = "wms";
    MapLayerTypeEnum["wmts"] = "wmts";
    MapLayerTypeEnum["wfs"] = "wfs";
})(MapLayerTypeEnum = exports.MapLayerTypeEnum || (exports.MapLayerTypeEnum = {}));
;
