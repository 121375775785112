"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var SwitchComponent = /** @class */ (function () {
    function SwitchComponent() {
        this.selectedChange = new core_1.EventEmitter();
        this.select = new core_1.EventEmitter();
        this.selectedIdChange = new core_1.EventEmitter();
        this.onSwitchMouseEnter = new core_1.EventEmitter();
        this.onSwitchMouseLeave = new core_1.EventEmitter();
    }
    SwitchComponent.prototype.ngOnInit = function () {
    };
    SwitchComponent.prototype.onSelect = function (option) {
        this.selected = option;
        this.selectedChange.emit(option);
        this.select.emit(option);
        this.selectedId = option.id;
        this.selectedIdChange.emit(this.selectedId);
    };
    return SwitchComponent;
}());
exports.SwitchComponent = SwitchComponent;
