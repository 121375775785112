"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var list_service_1 = require("@app/common/services/list.service");
var auth_service_1 = require("@app/common/services/auth.service");
var _ = require("lodash");
var CreateCaseSelectAreaComponent = /** @class */ (function () {
    function CreateCaseSelectAreaComponent(authService, listService, restangular, confirmService) {
        this.authService = authService;
        this.listService = listService;
        this.restangular = restangular;
        this.confirmService = confirmService;
        this.submitCallback = new core_1.EventEmitter();
        this.backCallback = new core_1.EventEmitter();
        this.loading = true;
        this.submit = this.submit.bind(this);
    }
    CreateCaseSelectAreaComponent.prototype.ngOnInit = function () {
        this.data.geometricPlansSelected = this.data.geometricPlansSelected || [];
        delete this.data.easementsSelected;
        this.data.geometricPlansSelected.forEach(function (geometricPlan) {
            delete geometricPlan.easementChecklist;
        });
        this.loadConstructionObject();
    };
    CreateCaseSelectAreaComponent.prototype.loadConstructionObject = function () {
        var _this = this;
        this.restangular.one('construction-objects', this.constructionObjectId).get({ loadCollections: ['geometricPlans'] })
            .then(function (data) {
            _this.dataConstructionObject = data;
            _this._sortGeometricPlans();
            _this.loading = false;
        });
    };
    CreateCaseSelectAreaComponent.prototype._sortGeometricPlans = function () {
        this.dataConstructionObject.geometricPlans.sort(function (a, b) {
            if (a.kutx === b.kutx) {
                return 0;
            }
            else if (a.kutx && b.kutx && typeof a.kutx.localeCompare === 'function') {
                return a.kutx.localeCompare(b.kutx);
            }
            else {
                return (a.kutx || '') > (b.kutx || '') ? 1 : -1;
            }
        });
    };
    CreateCaseSelectAreaComponent.prototype.toggleSelectionGeometricPlan = function (array, item) {
        var _this = this;
        if (this.isDisabled(item)) {
            return;
        }
        var check = !this.isChecked(array, item);
        var globalPromise = Promise.resolve();
        this.dataConstructionObject.geometricPlans.forEach(function (geometricPlan) {
            if (!_this.isDisabled(geometricPlan) && geometricPlan.ku === item.ku) {
                var promise = null;
                if (geometricPlan === item) {
                    promise = Promise.resolve(true);
                }
                else if (check === _this.isChecked(array, geometricPlan)) {
                    promise = Promise.resolve(false);
                }
                else {
                    promise = globalPromise = globalPromise.then(function () { return new _this.confirmService({
                        title: 'Další geometrický plán',
                        message: 'Ve stejném katastrálním území se nachází geometrický plán č. ' + geometricPlan.cis + '. Přejete si jej také ' + (check ? 'zvolit' : 'odvybrat') + '?',
                    }); });
                }
                promise.then(function (result) {
                    if (result) {
                        if (check) {
                            array.push(geometricPlan);
                        }
                        else {
                            _.remove(array, function (item) { return item.id === geometricPlan.id; });
                        }
                    }
                });
            }
        });
    };
    CreateCaseSelectAreaComponent.prototype.isChecked = function (array, item) {
        return _.some(array, { id: item.id });
    };
    CreateCaseSelectAreaComponent.prototype.isDisabled = function (item) {
        return item.fiktivni && (this.data.obligation.type === 'RealBurdenLiability' || this.data.obligation.type === 'RealBurdenLiabilityAccelerated');
    };
    CreateCaseSelectAreaComponent.prototype.submit = function () {
        if (!this.data.geometricPlansSelected.length) {
            return;
        }
        return this.submitCallback.emit();
    };
    return CreateCaseSelectAreaComponent;
}());
exports.CreateCaseSelectAreaComponent = CreateCaseSelectAreaComponent;
