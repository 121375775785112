"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var FormCaseSignAdministratorComponent = /** @class */ (function () {
    function FormCaseSignAdministratorComponent() {
        this.callbackRegister = new core_1.EventEmitter();
        this.isValid = this.isValid.bind(this);
    }
    FormCaseSignAdministratorComponent.prototype.ngOnInit = function () {
        this.data.signedAdministratorDate = this.data.signedAdministratorDate || new Date();
        this.callbackRegister.emit({
            isValid: this.isValid,
        });
    };
    FormCaseSignAdministratorComponent.prototype.isValid = function () {
        return !!this.data.signedAdministratorDate;
    };
    return FormCaseSignAdministratorComponent;
}());
exports.FormCaseSignAdministratorComponent = FormCaseSignAdministratorComponent;
