/**
 * Service extending ModalService
 */
const service = ['ModalService', 'HELP_IDS', function (ModalService, HELP_IDS) {
  return function (config = {}) {
    return new ModalService({
        template: require("./change-password.html"),
        allowClose: config.allowClose === true,
        controller: require('./ChangePasswordCtrl'),
        helpId: HELP_IDS.SETTINGS_CHANGE_PASSWORD,
    })
  }
}]

module.exports = service;
