"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var L = require("leaflet");
var _ = require("lodash");
var leaflet_wms_common_service_1 = require("@app/map/services/leaflet-wms-common.service");
var map_ogc_common_service_1 = require("@app/map/services/map-ogc-common.service");
var map_utils_wfs_feature_service_1 = require("@app/map/services/map-utils-wfs-feature.service");
var i0 = require("@angular/core");
var i1 = require("@angular/common/http");
var i2 = require("./leaflet-wms-common.service");
var i3 = require("./map-ogc-common.service");
var i4 = require("./map-utils-wfs-feature.service");
var LeafletTileLayerWfs = /** @class */ (function () {
    function LeafletTileLayerWfs(http, leafletWmsCommonService, mapOgcCommonService, mapUtilsWFSFeatureService) {
        this.WfsTileLayer = L.TileLayer.extend({
            defaultWfsParams: {
                service: 'WFS',
                request: 'GetFeature',
                version: '2.0.0',
                typeNames: '',
                outputFormat: 'application/json',
                startIndex: 0,
                count: 100,
                srsName: 'EPSG:5514',
            },
            initialize: function (url, options) {
                if (!options || !options.id) {
                    throw new Error("Missing layer id option in initialization.");
                }
                this._id = options.id;
                this._url = url;
                this._wfsParams = L.extend(this.defaultWfsParams, options.wfs);
                this._filter = options.filter;
                this._geoJson = options.geojson;
                this._wfsFormatParams = options.format;
                this._geometryColumn = options.geometryColumn || 'geom';
                this._idColumn = options.idColumn || 'id';
                this._limit = options.limit || 2000;
                L.setOptions(this, options);
                this._httpService = http;
                this._features = {};
            },
            onAdd: function (map) {
                this._crs = this.options.crs || map.options.crs;
                L.TileLayer.prototype.onAdd.call(this, map);
            },
            _getFormatString: function (obj) {
                var params = [];
                for (var i in obj) {
                    if (obj.hasOwnProperty(i)) {
                        params.push(i + ':' + obj[i]);
                    }
                }
                return params.join(';');
            },
            _getRequestUrl: function (_wfsParams, coords) {
                // we need to supply BBOX as part of filter, because we cannot specify both bbox and filter
                var nw = coords.getNorthWest();
                var ne = coords.getNorthEast();
                var se = coords.getSouthEast();
                var sw = coords.getSouthWest();
                var polygon = nw.lng + ',' + nw.lat + ' '
                    + ne.lng + ',' + ne.lat + ' '
                    + se.lng + ',' + se.lat + ' '
                    + sw.lng + ',' + sw.lat + ' '
                    + nw.lng + ',' + nw.lat;
                var bboxFilter = {
                    type: 'XML',
                    value: '<Intersects><PropertyName>' + this._geometryColumn + '</PropertyName>'
                        + '<gml:Polygon><gml:outerBoundaryIs><gml:LinearRing><gml:coordinates>'
                        + polygon
                        + '</gml:coordinates></gml:LinearRing></gml:outerBoundaryIs></gml:Polygon></Intersects>'
                };
                var requestFilter;
                if (this._filter) {
                    requestFilter = {
                        type: 'And',
                        arg1: bboxFilter,
                        arg2: this._filter
                    };
                }
                else {
                    requestFilter = bboxFilter;
                }
                _wfsParams['filter'] = mapOgcCommonService.getFilter(requestFilter, true);
                // prepare format params
                var fo = this._getFormatString(this._wfsFormatParams);
                return this._url +
                    L.Util.getParamString(_wfsParams, this._url, this._uppercase) +
                    (this._uppercase ? '&FORMAT_OPTIONS=' : '&format_options=') + fo;
            },
            getTileUrl: function (tilePoint) {
                var map = this._map;
                var tileSize = this.options.tileSize;
                var nwPoint = tilePoint.multiplyBy(tileSize);
                var sePoint = nwPoint.add([tileSize, tileSize]);
                var nw = this._crs.project(map.unproject(nwPoint, tilePoint.z));
                var se = this._crs.project(map.unproject(sePoint, tilePoint.z));
                var sw = L.latLng(nw.y, se.x);
                var ne = L.latLng(se.y, nw.x);
                var coords = L.latLngBounds(sw, ne);
                var _wfsParams = _.cloneDeep(this._wfsParams);
                var url = this._getRequestUrl(_wfsParams, coords);
                return url;
            },
            _loadTile: function (tile, tilePoint) {
                var url = this.getTileUrl(tilePoint);
                var _this = this;
                this._httpService.get(url).toPromise()
                    .then(function (data) {
                    //                    var featureLayer = L.Proj.geoJson(data, this._geoJson);
                    //                            tile.addLayer(featureLayer);
                    //                    tile.addData(data);
                    // TODO: refactor to separate function
                    for (var i = 0; i < data.features.length; i++) {
                        // TODO: replace id with idColumn
                        if (!(data.features[i].id in _this._features)) {
                            _this._features[data.features[i].id] = true;
                            // add crs to feature data
                            if ("crs" in data) {
                                data.features[i].crs = data.crs;
                            }
                            var featureLayer = L.Proj.geoJson(data.features[i], _this._geoJson);
                            tile.addLayer(featureLayer);
                        }
                    }
                    // TODO: record feature id in tile
                });
                this.fire('tileloadstart', {
                    tile: tile,
                    url: url
                });
            },
            _addTile: function (tilePoint) {
                // create new tile
                // skip _getTail and _createTile since we don't reuse tiles
                var tile = L.layerGroup(); //L.Proj.geoJson([], this._geoJson);
                this._tiles[tilePoint.x + ':' + tilePoint.y] = tile;
                this._loadTile(tile, tilePoint);
                this._map.addLayer(tile);
            },
            loadFeatureData: function (options) {
                var filter = null;
                if (this._filter) {
                    filter = mapOgcCommonService.getFilter(this._filter, true);
                }
                return leafletWmsCommonService.loadFeatureData({
                    latlng: options.latlng,
                    url: this._url,
                    uppercase: this._uppercase,
                    httpService: this._httpService,
                    map: this._map,
                    crs: this._crs,
                    layers: this._wfsParams['typeNames'],
                    filter: filter,
                    cqlFilter: null,
                    featureCount: this._wfsParams['feature_count']
                });
            },
            loadFeatureDataByID: function (featureID) {
                var wfsBaseURL = this._url;
                var queryParams = undefined; // TODO missing
                return mapUtilsWFSFeatureService.getFeatureByID(wfsBaseURL, this.wfsParams.layers, featureID, queryParams);
            }
        });
    }
    LeafletTileLayerWfs.prototype.getLayer = function (url, options) {
        return new this.WfsTileLayer(url, options);
    };
    LeafletTileLayerWfs.ngInjectableDef = i0.defineInjectable({ factory: function LeafletTileLayerWfs_Factory() { return new LeafletTileLayerWfs(i0.inject(i1.HttpClient), i0.inject(i2.LeafletWmsCommonService), i0.inject(i3.MapOgcCommonService), i0.inject(i4.MapUtilsWfsFeatureService)); }, token: LeafletTileLayerWfs, providedIn: "root" });
    return LeafletTileLayerWfs;
}());
exports.LeafletTileLayerWfs = LeafletTileLayerWfs;
