"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var acting_person_model_1 = require("@app/ps/models/acting-person.model");
var RepresentativeFormComponent = /** @class */ (function () {
    function RepresentativeFormComponent() {
        this.compact = false; // caused by different contexts (update-case-subject, owner-detail)
        this.heading = 'Zastupující';
        this.editable = true;
        this.editableToggle = false;
        this.toggleRoleCadastre = new core_1.EventEmitter();
    }
    RepresentativeFormComponent.prototype.ngOnInit = function () {
        this.requiredFields = this.allowRoleAssignment;
    };
    RepresentativeFormComponent.prototype.onToggleRoleCadastre = function (representative) {
        representative.roleCadastre = !representative.roleCadastre;
        this.toggleRoleCadastre.emit(representative);
    };
    return RepresentativeFormComponent;
}());
exports.RepresentativeFormComponent = RepresentativeFormComponent;
