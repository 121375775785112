"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var LoadingDirective = /** @class */ (function () {
    function LoadingDirective(elRef) {
        this.elRef = elRef;
        this.elRef.nativeElement.innerHTML += '<div class="loader"><span class="loading-spinner"></span></div>';
        this.defaultClass = this.elRef.nativeElement.className;
    }
    Object.defineProperty(LoadingDirective.prototype, "elementClass", {
        get: function () {
            return this.loading ? this.defaultClass + ' content-loading' : this.defaultClass;
        },
        enumerable: true,
        configurable: true
    });
    return LoadingDirective;
}());
exports.LoadingDirective = LoadingDirective;
