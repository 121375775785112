"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var create_case_component_1 = require("@app/ps/construction-objects/components/create-case/create-case.component");
var construction_object_list_component_1 = require("@app/ps/construction-objects/components/construction-object-list/construction-object-list.component");
var construction_object_detail_component_1 = require("@app/ps/construction-objects/components/construction-object-detail/construction-object-detail.component");
var construction_object_update_component_1 = require("@app/ps/construction-objects/components/construction-object-update/construction-object-update.component");
var dialog_service_1 = require("@app/dialog/dialog.service");
var easement_update_global_price_component_1 = require("@app/ps/construction-objects/components/easement-update-global-price/easement-update-global-price.component");
var construction_object_imported_list_component_1 = require("@app/ps/construction-objects/components/construction-object-imported-list/construction-object-imported-list.component");
var construction_object_create_component_1 = require("@app/ps/construction-objects/components/construction-object-create/construction-object-create.component");
var easement_create_component_1 = require("@app/ps/construction-objects/components/easement-create/easement-create.component");
var state_utils_1 = require("@app/common/utils/state.utils");
function updateGlobalEasementPriceDialogFunction($stateParams, $transition$, $state) {
    var dialogService = $transition$.injector().get(dialog_service_1.DialogService);
    var dialog = dialogService.open(easement_update_global_price_component_1.EasementUpdateGlobalPriceComponent, {
        data: { constructionObjectId: $stateParams.id },
    });
    var sub = dialog.afterClosed.subscribe(function () {
        $state.go('^', $state.params, { reload: true });
        sub.unsubscribe();
    });
}
exports.updateGlobalEasementPriceDialogFunction = updateGlobalEasementPriceDialogFunction;
exports.constructionObjectListState = {
    name: 'symap.project.constructionObjects',
    url: '/construction-objects?page',
    data: {
        title: '$CONSTRUCTION_OBJECT_PLURAL_NOMINATIV',
    },
    views: {
        'content@symap.project': {
            component: construction_object_list_component_1.ConstructionObjectListComponent,
        },
    },
    params: {
        page: {
            dynamic: true,
            type: 'int',
            inherit: false,
        },
    },
};
exports.constructionObjectImportedListState = {
    name: 'symap.project.constructionObjects.constructionObjectsImported',
    url: '/imported?page',
    data: {
        title: 'Připravované $CONSTRUCTION_OBJECT_PLURAL_NOMINATIV',
    },
    views: {
        'content@symap.project': {
            component: construction_object_imported_list_component_1.ConstructionObjectImportedListComponent,
        },
    },
    params: {
        page: {
            dynamic: true,
            type: 'int',
            inherit: false,
        },
    },
};
exports.constructionObjectDetailState = {
    name: 'symap.project.constructionObjects.detail',
    url: '/:id/:tab',
    data: {
        title: 'Detail $CONSTRUCTION_OBJECT_GENITIV',
    },
    views: {
        'content@symap.project': {
            component: construction_object_detail_component_1.ConstructionObjectDetailComponent,
        }
    },
    resolve: {
        constructionObjectId: ['$stateParams', state_utils_1.resolveId]
    },
    params: {
        id: {
            type: 'int',
            value: undefined
        },
        tab: {
            value: 'easements',
            dynamic: true,
            inherit: false,
            squash: true,
        }
    },
};
exports.constructionObjectDetailUpdateState = {
    name: 'symap.project.constructionObjects.update',
    url: '/update/:id',
    data: {
        title: 'Editace $CONSTRUCTION_OBJECT_GENITIV',
    },
    views: {
        'content@symap.project': {
            component: construction_object_update_component_1.ConstructionObjectUpdateComponent,
        }
    },
    resolve: {
        constructionObjectId: ['$stateParams', state_utils_1.resolveId]
    },
    params: {
        id: {
            type: 'int',
            value: undefined
        },
    },
};
exports.constructionObjectCreateState = {
    name: 'symap.project.constructionObjects.newConstructionObject',
    url: '/create',
    data: {
        title: '$NEW_CONSTRUCTION_OBJECT',
    },
    views: {
        'content@symap.project': {
            component: construction_object_create_component_1.ConstructionObjectCreateComponent,
        }
    }
};
exports.easementCreateState = {
    name: 'symap.project.constructionObjects.newEasement',
    url: '/create-easement',
    data: {
        title: 'Nové VB',
    },
    views: {
        'content@symap.project': {
            component: easement_create_component_1.EasementCreateComponent,
        }
    }
};
exports.createCaseState = {
    name: 'symap.project.constructionObjects.detail.createCase',
    url: '/create-case',
    data: {
        title: 'Založení případů VB',
    },
    resolve: {
        constructionObjectId: ['$stateParams', state_utils_1.resolveId],
    },
    views: {
        'content@symap.project': {
            component: create_case_component_1.CreateCaseComponent,
        }
    },
};
exports.updateGlobalEasementPrice = {
    name: 'symap.project.constructionObjects.detail.updateGlobalEasementPrice',
    url: '/update-global-easement-price',
    data: {
        title: 'Editace hromadného zadání ceny VB',
    },
    onEnter: ['$stateParams', '$transition$', '$state', updateGlobalEasementPriceDialogFunction],
};
