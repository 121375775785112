"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var map_states_1 = require("./map.states");
var STATES = [
    map_states_1.mapState,
];
var MapModule = /** @class */ (function () {
    function MapModule() {
    }
    return MapModule;
}());
exports.MapModule = MapModule;
