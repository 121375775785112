"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var angular_1 = require("@uirouter/angular");
var list_service_1 = require("@app/common/services/list.service");
var ParcelDetailComponent = /** @class */ (function () {
    function ParcelDetailComponent(helpIds, restangular, listService, stateService) {
        this.helpIds = helpIds;
        this.restangular = restangular;
        this.listService = listService;
        this.stateService = stateService;
        this.loading = true;
    }
    ParcelDetailComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.restangular.one('parcels', this.parcelId).customPOST({
            attributes: {
                title: {
                    mapping: {
                        attributes: {
                            area: {},
                            ownerships: {
                                mapping: {
                                    attributes: {
                                        subject: {
                                            mapping: {
                                                attributes: {
                                                    subjectSjm1: {},
                                                    subjectSjm2: {}
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }).then(function (data) {
            _this.data = data;
            _this.loading = false;
        });
        this.initList();
    };
    ParcelDetailComponent.prototype.getSubject = function (ownership) {
        return ownership.subject.opsubType === 'BSM' ? ownership.subject.subjectSjm1 : ownership.subject;
    };
    ParcelDetailComponent.prototype.initList = function () {
        this.list = this.listService.createList("parcels/" + this.parcelId + "/intersections", {});
        this.listService.attachFilterStorage(this.list, 'intersectionsFilter');
        this.listService.fetchResult(this.list);
    };
    ParcelDetailComponent.prototype.onSort = function (sortValue, sortDir) {
        this.listService.sort(this.list, sortValue, sortDir);
    };
    return ParcelDetailComponent;
}());
exports.ParcelDetailComponent = ParcelDetailComponent;
