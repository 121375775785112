"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var $ = require("jquery");
var L = require("leaflet");
var auth_service_1 = require("@app/common/services/auth.service");
var map_service_1 = require("@app/map/services/map.service");
var map_highlight_service_1 = require("@app/map/services/map-highlight.service");
var map_layer_selector_service_1 = require("@app/map/services/map-layer-selector.service");
var map_config_service_1 = require("@app/map/services/map-config.service");
var core_2 = require("@uirouter/core");
var title_name_pipe_1 = require("@app/common/pipes/title-name.pipe");
var parcel_name_pipe_1 = require("@app/common/pipes/parcel-name.pipe");
var road_section_name_pipe_1 = require("@app/dimap/pipes/road-section-name.pipe");
var layers_config_1 = require("../../config/layers-config");
var listLoadOrder_1 = require("../../../pure/listLoadOrder");
var MapComponent = /** @class */ (function () {
    function MapComponent(authService, transition, mapService, mapHighlightService, HELP_IDS, mapConfigService, localStorageService, restangular, mapLayerSelectorService, titleNamePipe, parcelNamePipe, roadSectionNamePipe) {
        this.authService = authService;
        this.transition = transition;
        this.mapService = mapService;
        this.mapHighlightService = mapHighlightService;
        this.HELP_IDS = HELP_IDS;
        this.mapConfigService = mapConfigService;
        this.localStorageService = localStorageService;
        this.restangular = restangular;
        this.mapLayerSelectorService = mapLayerSelectorService;
        this.titleNamePipe = titleNamePipe;
        this.parcelNamePipe = parcelNamePipe;
        this.roadSectionNamePipe = roadSectionNamePipe;
        this.searchResources = null;
        this.mapConfigLoading = true;
        this.defaultEnableCuzkInfo = false;
        this.selectorConfig = null;
        this.selectorOptions = null;
        this.mapOptions = null;
        this.toggleLayerSelector = this.toggleLayerSelector.bind(this);
        this.getCollapsed = this.getCollapsed.bind(this);
        this.fitHeight = this.fitHeight.bind(this);
    }
    MapComponent.prototype.ngOnInit = function () {
        var _this = this;
        var mapID = 'main-map';
        var project = this.authService.getActualProject();
        this.searchResources = [
            { id: 'areas/list', value: 'KÚ', filter: { filters: { requestArea: { values: [true] } }, sortOrder: listLoadOrder_1.getOrder('areas/list') }, formatItem: function (item) { return item.name; } },
            { id: 'titles/list', value: 'LV', filter: { filters: { requestArea: { values: [true] } }, sortOrder: listLoadOrder_1.getOrder('titles/list') }, attributes: { area: {} }, pipe: this.titleNamePipe },
            { id: 'parcels/list', value: 'Parcela', filter: { filters: { requestArea: { values: [true] } }, sortOrder: listLoadOrder_1.getOrder('parcels/list') }, attributes: { title: { mapping: { attributes: { area: {} } } } }, pipe: this.parcelNamePipe },
            { id: 'road-sections/list', value: 'Silnice', filter: { sortOrder: listLoadOrder_1.getOrder('road-sections/list') }, pipe: this.roadSectionNamePipe }
        ];
        var mapConfig = layers_config_1.getLayersConfig(this.authService);
        this.mapConfigService.setLayers(mapConfig, mapID)
            .then(function (mapConfigComplete) {
            _this.selectorConfig = _this.mapConfigService.filterMapConfig(mapConfigComplete, project.mapLayers);
            _this.mapConfigLoading = false;
            _this.selectorOptions = {
                saveLayersVisibility: true,
                saveGroupCollapseStatus: true,
                showCollapsedVisibleLayersCount: true,
                storagePrefix: project.key
            };
        });
        var savedPosition = this.localStorageService.get('map-' + project.key);
        var mapLat;
        var mapLon;
        var mapZoom;
        var defaultLat = project.mapLat ? project.mapLat : 49.75;
        var defaultLon = project.mapLon ? project.mapLon : 15.75;
        var defaultZoom = project.mapZoom ? project.mapZoom : 4;
        if (savedPosition) {
            mapLat = savedPosition.lat;
            mapLon = savedPosition.lon;
            mapZoom = savedPosition.zoom;
        }
        else {
            mapLat = defaultLat;
            mapLon = defaultLon;
            mapZoom = defaultZoom;
        }
        this.mapOptions = {
            id: mapID,
            srid: 5514,
            bounds: null,
            center: [mapLat, mapLon],
            zoom: mapZoom,
            minZoom: 4,
            maxZoom: 15
        };
        // resizing window will change height of map frame
        $('#map-layer-selector').height(0);
        this.fitHeight();
        this.mapService.getMap(this.mapOptions.id).then(function (map) {
            _this.fitHeight();
            L.control.fullscreen({
                position: 'topleft',
                title: 'Celoobrazovkový režim',
                titleCancel: 'Ukončit celoobrazovkový režim'
            }).addTo(map);
            L.control.graphicScale({ fill: 'hollow' }).addTo(map);
            var button = L.easyButton('fa-home map-home-icon', function (btn, map) {
                map.setView(new L.LatLng(defaultLat, defaultLon), defaultZoom);
                window.GoogleAnalytics('send', {
                    hitType: 'event',
                    eventCategory: 'mapModule',
                    eventAction: '',
                    eventLabel: 'home',
                });
            });
            button.addTo(map);
            var logMapUrlHighlight = function (type) {
                window.GoogleAnalytics('send', {
                    hitType: 'event',
                    eventCategory: 'mapModule',
                    eventAction: type,
                    eventLabel: 'map url highlight',
                });
            };
            if (_this.transition.params().titleId > 0) {
                var type = 'titles';
                logMapUrlHighlight(type);
                _this.restangular.one(type, _this.transition.params().titleId).customPOST({ attributes: { geometry: {} } }).then(function (data) {
                    _this.mapHighlightService.highlightFeature(_this.mapOptions.id, data.geometry);
                });
            }
            if (_this.transition.params().parcelId > 0) {
                var type = 'parcels';
                logMapUrlHighlight(type);
                _this.restangular.one(type, _this.transition.params().parcelId).customPOST({ attributes: { geometry: {} } }).then(function (data) {
                    _this.mapHighlightService.highlightFeature(_this.mapOptions.id, data.geometry);
                });
            }
            if (_this.transition.params().intersectionId) {
                var type = 'intersections';
                logMapUrlHighlight(type);
                _this.restangular.one(type, _this.transition.params().intersectionId).customPOST({ attributes: { geometry: {} } }).then(function (data) {
                    _this.mapHighlightService.highlightFeature(_this.mapOptions.id, data.geometry);
                });
            }
            if (_this.transition.params().divisionParcelId) {
                var type = 'division-parcels';
                logMapUrlHighlight(type);
                _this.restangular.one(type, _this.transition.params().divisionParcelId).customPOST({ attributes: { geometry: {} } }).then(function (data) {
                    _this.mapHighlightService.highlightFeature(_this.mapOptions.id, data.geometry);
                });
            }
            map.on('moveend', function () {
                var center = map.getCenter(), zoom = map.getZoom();
                var position = {
                    lat: center.lat,
                    lon: center.lng,
                    zoom: zoom
                };
                _this.localStorageService.set('map-' + project.key, position);
            });
        });
        this.localStorageCollapsedKey = project.key + ".layerSelector.collapsed";
        var collapsedStatus = this.localStorageService.get(this.localStorageCollapsedKey);
        this.mapLayerSelectorService.setCollapsedState((collapsedStatus === 1));
    };
    MapComponent.prototype.toggleLayerSelector = function () {
        var collapsed = !this.getCollapsed();
        this.localStorageService.set(this.localStorageCollapsedKey, collapsed ? 1 : 0);
        $('#map-layer-selector').height(0);
        this.mapLayerSelectorService.setCollapsedState(collapsed);
        // also forces recentering of map window
        setTimeout(this.fitHeight);
    };
    MapComponent.prototype.getCollapsed = function () {
        return this.mapLayerSelectorService.getCollapsedState();
    };
    MapComponent.prototype.fitHeight = function () {
        var topBarHeight = $('#top-bar').outerHeight();
        var windowHeight = $(window).height();
        $('#map-window').height(windowHeight - topBarHeight);
        $('#map-content').height(windowHeight - topBarHeight);
        var layerSelectorHeight = windowHeight - topBarHeight - Math.round($('#selector-heading').outerHeight());
        $('#map-layer-selector').height(layerSelectorHeight);
    };
    return MapComponent;
}());
exports.MapComponent = MapComponent;
