"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./change-case-status.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@uirouter/angular/lib/directives/uiSref");
var i3 = require("@uirouter/core");
var i4 = require("@angular/common");
var i5 = require("./change-case-status.component");
var i6 = require("../../../services/case.service");
var i7 = require("../../../services/case-status.service");
var i8 = require("@uirouter/angular");
var styles_ChangeCaseStatusComponent = [i0.styles];
var RenderType_ChangeCaseStatusComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ChangeCaseStatusComponent, data: {} });
exports.RenderType_ChangeCaseStatusComponent = RenderType_ChangeCaseStatusComponent;
function View_ChangeCaseStatusComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "a", [["class", "bordered-bottom p-10"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).go($event.button, $event.ctrlKey, $event.metaKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = ((_v.context.$implicit.click ? _v.context.$implicit.click() : undefined) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i2.AnchorUISref, [i1.ElementRef, i1.Renderer2], null, null), i1.ɵdid(2, 737280, null, 0, i2.UISref, [i3.UIRouter, [2, i2.AnchorUISref], "UIView.PARENT_INJECT"], { state: [0, "state"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 0, "i", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "]))], function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", (_v.context.$implicit.click ? "" : ("symap.project.cases.detail." + _v.context.$implicit.key)), ""); _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = i1.ɵinlineInterpolate(1, "fa ", _v.context.$implicit.icon, ""); _ck(_v, 3, 0, currVal_1); var currVal_2 = _v.context.$implicit.name; _ck(_v, 4, 0, currVal_2); }); }
function View_ChangeCaseStatusComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "text-center p-10"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Stav nelze zm\u011Bnit "]))], null, null); }
function View_ChangeCaseStatusComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "a", [["class", "p-10"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onBack() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "fa fa-step-backward"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" Vr\u00E1tit do stavu \"", "\""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.previousState; _ck(_v, 3, 0, currVal_0); }); }
function View_ChangeCaseStatusComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "content-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "bordered"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChangeCaseStatusComponent_2)), i1.ɵdid(3, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChangeCaseStatusComponent_3)), i1.ɵdid(5, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChangeCaseStatusComponent_4)), i1.ɵdid(7, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.actionButtons; _ck(_v, 3, 0, currVal_0); var currVal_1 = (!_co.actionButtons || (_co.actionButtons.length === 0)); _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.previousState; _ck(_v, 7, 0, currVal_2); }, null); }
function View_ChangeCaseStatusComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "state-label pr-10"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Stav:"])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "a", [["class", "button active"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.opened = !_co.opened) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(4, null, ["", "\u00A0\u00A0"])), (_l()(), i1.ɵeld(5, 0, null, null, 0, "i", [["class", "fa fa-chevron-circle-down"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChangeCaseStatusComponent_1)), i1.ɵdid(7, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.opened; _ck(_v, 7, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.case.caseStatus.name; _ck(_v, 4, 0, currVal_0); }); }
exports.View_ChangeCaseStatusComponent_0 = View_ChangeCaseStatusComponent_0;
function View_ChangeCaseStatusComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "change-case-status", [], null, [["document", "click"]], function (_v, en, $event) { var ad = true; if (("document:click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).clickout($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_ChangeCaseStatusComponent_0, RenderType_ChangeCaseStatusComponent)), i1.ɵdid(1, 114688, null, 0, i5.ChangeCaseStatusComponent, ["Restangular", i6.CaseService, i7.CaseStatusService, i8.StateService, i1.ElementRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_ChangeCaseStatusComponent_Host_0 = View_ChangeCaseStatusComponent_Host_0;
var ChangeCaseStatusComponentNgFactory = i1.ɵccf("change-case-status", i5.ChangeCaseStatusComponent, View_ChangeCaseStatusComponent_Host_0, { case: "case", actionButtons: "actionButtons", previousState: "previousState" }, { back: "back" }, []);
exports.ChangeCaseStatusComponentNgFactory = ChangeCaseStatusComponentNgFactory;
