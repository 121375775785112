"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var auth_service_1 = require("@app/common/services/auth.service");
var project_service_1 = require("@app/ps/services/project.service");
var checklist_service_1 = require("@app/common/services/checklist.service");
var ProjectCreateComponent = /** @class */ (function () {
    function ProjectCreateComponent(restangular, authService, projectService, checklistService) {
        this.restangular = restangular;
        this.authService = authService;
        this.projectService = projectService;
        this.checklistService = checklistService;
        this.data = { templateProjectSettings: undefined };
        this.projects = [];
        this.selectedTemplateSettingsBasic = 'a';
        this.templateSettingsBasic = [
            {
                id: 'a',
                value: 'Vše',
            },
            {
                id: 'c',
                value: 'Zvolit',
            }
        ];
        this.templateSettings = [
            { name: 'Základní', id: 'BASIC' },
            { name: 'Data katastru nemovitostí', id: 'CADASTRE_DATA' },
            { name: 'Investor', id: 'INVESTOR' },
            { name: 'Správci stavebních objektů', id: 'ADMINISTRATORS' },
            { name: 'Číselné řady', id: 'SERIES' },
            { name: 'Nastavení typů případů a jejich workflow', id: 'CASES' },
            { name: 'Stavy případů', id: 'CASE_STATUSES' },
            { name: 'Šablony a dokumenty šablon', id: 'TEMPLATES' },
            { name: 'Exporty', id: 'EXPORTS' },
            { name: 'Statistiky', id: 'STATISTICS' },
            { name: 'Sloupce', id: 'COLUMNS' },
            { name: 'Názvosloví', id: 'NOMENCLATURE' },
            { name: 'Mapové okno', id: 'MAP' },
        ];
        this.onChangeTemplateSettings = this.onChangeTemplateSettings.bind(this);
        this.onToggleSettings = this.onToggleSettings.bind(this);
        this.onTemplateProjectChanged = this.onTemplateProjectChanged.bind(this);
        this.removePrefix = this.removePrefix.bind(this);
        this.update = this.update.bind(this);
    }
    ProjectCreateComponent.prototype.ngOnInit = function () {
        var projects = this.authService.getActualProjects();
        if (projects) {
            this.projects = projects.filter(function (p) { return p.key.match(/sy_/); });
        }
        this.checklistSettings = this.checklistService.get();
    };
    ProjectCreateComponent.prototype.onChangeTemplateSettings = function (type) {
        this.data.templateProjectSettings = type === 'c'
            ? this.checklistSettings.checkedItems
            : undefined;
    };
    ProjectCreateComponent.prototype.onToggleSettings = function (settings) {
        this.checklistSettings.toggleSelection(settings);
    };
    ProjectCreateComponent.prototype.onTemplateProjectChanged = function (project) {
        this.data.templateProjectKey = (project ? project.key : undefined);
    };
    ProjectCreateComponent.prototype.isCheckedSettings = function (settings) {
        return this.checklistSettings.isChecked(settings);
    };
    ProjectCreateComponent.prototype.isDisabled = function () {
        return !(this.data.name && this.data.key);
    };
    ProjectCreateComponent.prototype.removePrefix = function () {
        if (this.data.key) {
            var idx = this.data.key.indexOf(ProjectCreateComponent.PREFIX);
            if (idx === -1 || idx !== 0) {
                return;
            }
            this.data.key = this.data.key.substring(idx + ProjectCreateComponent.PREFIX.length);
        }
    };
    ProjectCreateComponent.prototype.update = function () {
        var _this = this;
        return this.restangular.all('projects')
            .post(this.data)
            .then(function (data) {
            if (data) {
                return _this.authService.loadSymapProjects(_this.authService.getUser().id).then(function () {
                    return _this.projectService.changeProject(data);
                });
            }
        });
    };
    ProjectCreateComponent.PREFIX = 'sy_';
    return ProjectCreateComponent;
}());
exports.ProjectCreateComponent = ProjectCreateComponent;
