"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./user-permissions.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/common");
var i3 = require("../select/select.component.ngfactory");
var i4 = require("../select/select.component");
var i5 = require("@uirouter/angular");
var i6 = require("../mirrored-list/mirrored-list.component.ngfactory");
var i7 = require("../mirrored-list/mirrored-list.component");
var i8 = require("../../services/checklist.service");
var i9 = require("../button/button.component.ngfactory");
var i10 = require("../button/button.component");
var i11 = require("./user-permissions.component");
var i12 = require("../../../dialog/dialog-config");
var i13 = require("../../../dialog/dialog-ref");
var i14 = require("../../services/modules.service");
var i15 = require("../../pipes/role.pipe");
var styles_UserPermissionsComponent = [i0.styles];
var RenderType_UserPermissionsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UserPermissionsComponent, data: {} });
exports.RenderType_UserPermissionsComponent = RenderType_UserPermissionsComponent;
function View_UserPermissionsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "mt-5 mr-5 tag tag--inactive tag--not-clickable"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.value; _ck(_v, 1, 0, currVal_0); }); }
function View_UserPermissionsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "column-100"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserPermissionsComponent_3)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentRolePermissions; _ck(_v, 2, 0, currVal_0); }, null); }
function View_UserPermissionsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "fieldset", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "legend", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Role"])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "column-100"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "gmt-select", [["field", "currentRole"]], null, [[null, "changed"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("changed" === en)) {
        var pd_0 = (_co.handleRoleSelect() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_SelectComponent_0, i3.RenderType_SelectComponent)), i1.ɵdid(5, 114688, null, 0, i4.SelectComponent, ["ListLoadService", "WindowDimensionService", i1.ChangeDetectorRef, i1.ElementRef, "Restangular", i5.StateService], { data: [0, "data"], field: [1, "field"], itemPipe: [2, "itemPipe"], optionItems: [3, "optionItems"] }, { changed: "changed" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserPermissionsComponent_2)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co; var currVal_1 = "currentRole"; var currVal_2 = _co.rolePipe; var currVal_3 = _co.roles; _ck(_v, 5, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = (_co.currentRolePermissions && (_co.currentRolePermissions.length > 0)); _ck(_v, 7, 0, currVal_4); }, null); }
function View_UserPermissionsComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h1", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Editace opr\u00E1vn\u011Bn\u00ED u\u017Eivatele"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserPermissionsComponent_1)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "mirrored-list", [], null, [[null, "changed"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("changed" === en)) {
        var pd_0 = (_co.handleItemSelect($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_MirroredListComponent_0, i6.RenderType_MirroredListComponent)), i1.ɵdid(6, 114688, null, 0, i7.MirroredListComponent, [i8.ChecklistService], { availableItems: [0, "availableItems"], selectedItems: [1, "selectedItems"], label: [2, "label"] }, { changed: "changed" }), (_l()(), i1.ɵeld(7, 0, null, null, 3, "div", [["class", "actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "gmt-button", [], null, null, null, i9.View_ButtonComponent_0, i9.RenderType_ButtonComponent)), i1.ɵdid(9, 114688, null, 0, i10.ButtonComponent, ["ErrorHandlerService", i1.ChangeDetectorRef], { clickAction: [0, "clickAction"] }, null), (_l()(), i1.ɵted(-1, 0, ["Ulo\u017Eit"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.roleEnabled; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.permissions; var currVal_2 = _co.currentPermissions; var currVal_3 = (_co.roleEnabled ? "Opr\u00E1vn\u011Bn\u00ED nad r\u00E1mec role" : "Opr\u00E1vn\u011Bn\u00ED"); _ck(_v, 6, 0, currVal_1, currVal_2, currVal_3); var currVal_4 = _co.handleSubmit; _ck(_v, 9, 0, currVal_4); }, null); }
exports.View_UserPermissionsComponent_0 = View_UserPermissionsComponent_0;
function View_UserPermissionsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "user-permissions", [], null, null, null, View_UserPermissionsComponent_0, RenderType_UserPermissionsComponent)), i1.ɵdid(1, 114688, null, 0, i11.UserPermissionsComponent, [i12.DialogConfig, i13.DialogRef, i14.ModulesService, "APPLICATIONS", i15.RolePipe], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_UserPermissionsComponent_Host_0 = View_UserPermissionsComponent_Host_0;
var UserPermissionsComponentNgFactory = i1.ɵccf("user-permissions", i11.UserPermissionsComponent, View_UserPermissionsComponent_Host_0, {}, {}, []);
exports.UserPermissionsComponentNgFactory = UserPermissionsComponentNgFactory;
