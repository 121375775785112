"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var ticket_status_enum_1 = require("@app/sv/enums/ticket-status.enum");
var i0 = require("@angular/core");
var i1 = require("../sv.modules");
var TicketStatusNamePipe = /** @class */ (function () {
    function TicketStatusNamePipe() {
    }
    TicketStatusNamePipe.prototype.transform = function (value) {
        var option = ticket_status_enum_1.ticketStatusOptions.find(function (option) { return option.id === value; });
        return option.name;
    };
    TicketStatusNamePipe.ngInjectableDef = i0.defineInjectable({ factory: function TicketStatusNamePipe_Factory() { return new TicketStatusNamePipe(); }, token: TicketStatusNamePipe, providedIn: i1.SvAppModule });
    return TicketStatusNamePipe;
}());
exports.TicketStatusNamePipe = TicketStatusNamePipe;
