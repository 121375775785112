"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var angular_1 = require("@uirouter/angular");
var auth_service_1 = require("../../services/auth.service");
var UserCredentialsComponent = /** @class */ (function () {
    function UserCredentialsComponent(helpIds, globalRestangular, authService, stateService) {
        this.helpIds = helpIds;
        this.globalRestangular = globalRestangular;
        this.authService = authService;
        this.stateService = stateService;
        this.update = this.update.bind(this);
        this.isUpdateDisabled = this.isUpdateDisabled.bind(this);
    }
    UserCredentialsComponent.prototype.ngOnInit = function () {
        this.backSref = this.stateService.params.backSref;
    };
    UserCredentialsComponent.prototype.update = function () {
        var _this = this;
        if (!this.authService.isAuthenticated()) {
            return;
        }
        var data = {
            oldPassword: this.oldPassword,
            newPassword: this.newPassword,
            newPasswordCheck: this.newPasswordCheck,
            username: this.username
        };
        return this.globalRestangular.all("users/user/" + this.authService.getUser().id + "/password-change").customPUT(data).then(function () {
            _this.authService.passwordChanged();
            if (_this.backSref) {
                _this.stateService.go(_this.stateService.params.backSref);
            }
        });
    };
    UserCredentialsComponent.prototype.isUpdateDisabled = function () {
        return !!this.oldPassword && !!(this.username || (this.newPassword && this.newPasswordCheck));
    };
    return UserCredentialsComponent;
}());
exports.UserCredentialsComponent = UserCredentialsComponent;
