"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["[_nghost-%COMP%] {\n  display: inline-block; }\n  [_nghost-%COMP%]   .content-loading[_ngcontent-%COMP%] {\n    padding-top: 5px;\n    position: absolute; }\n  [_nghost-%COMP%]   .hidden-switch[_ngcontent-%COMP%] {\n    visibility: hidden; }"];
exports.styles = styles;
