"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("../../../common/pipes/username.pipe");
var i2 = require("./titles-mandatary-change-confirm.component");
var i3 = require("../../../common/services/list.service");
var styles_TitlesMandataryChangeConfirmComponent = [];
var RenderType_TitlesMandataryChangeConfirmComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TitlesMandataryChangeConfirmComponent, data: {} });
exports.RenderType_TitlesMandataryChangeConfirmComponent = RenderType_TitlesMandataryChangeConfirmComponent;
function View_TitlesMandataryChangeConfirmComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i1.UserNamePipe, []), (_l()(), i0.ɵeld(1, 0, null, null, 7, "div", [["class", "jumbotron centered-text"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""])), (_l()(), i0.ɵted(-1, null, [" list\u016F vlastnictv\u00ED bude p\u0159id\u011Bleno u\u017Eivateli "])), (_l()(), i0.ɵeld(5, 0, null, null, 2, "b", [], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, ["", ""])), i0.ɵppd(7, 1), (_l()(), i0.ɵted(-1, null, [".\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.selectEntities.count; _ck(_v, 3, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 6, 0, _ck(_v, 7, 0, i0.ɵnov(_v, 0), _co.data.details.user)); _ck(_v, 6, 0, currVal_1); }); }
exports.View_TitlesMandataryChangeConfirmComponent_0 = View_TitlesMandataryChangeConfirmComponent_0;
function View_TitlesMandataryChangeConfirmComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_TitlesMandataryChangeConfirmComponent_0, RenderType_TitlesMandataryChangeConfirmComponent)), i0.ɵdid(1, 49152, null, 0, i2.TitlesMandataryChangeConfirmComponent, [i3.ListService, "Restangular"], null, null)], null, null); }
exports.View_TitlesMandataryChangeConfirmComponent_Host_0 = View_TitlesMandataryChangeConfirmComponent_Host_0;
var TitlesMandataryChangeConfirmComponentNgFactory = i0.ɵccf("ng-component", i2.TitlesMandataryChangeConfirmComponent, View_TitlesMandataryChangeConfirmComponent_Host_0, { data: "data" }, {}, []);
exports.TitlesMandataryChangeConfirmComponentNgFactory = TitlesMandataryChangeConfirmComponentNgFactory;
