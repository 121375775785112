"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("../../../common/directives/tooltip/tooltipContent.directive");
var i2 = require("@angular/common");
var i3 = require("./map-layer-selector-tooltip.component");
var i4 = require("../../services/map-layer-selector.service");
var styles_MapLayerSelectorTooltipComponent = [];
var RenderType_MapLayerSelectorTooltipComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MapLayerSelectorTooltipComponent, data: {} });
exports.RenderType_MapLayerSelectorTooltipComponent = RenderType_MapLayerSelectorTooltipComponent;
function View_MapLayerSelectorTooltipComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "tooltip-min"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "div", [["class", "tooltip tooltip-show"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "div", [["class", "tooltip-content"], ["style", "margin-top: 0px;"]], null, null, null, null, null)), i0.ɵdid(3, 16384, null, 0, i1.TooltipContentDirective, [], null, null), (_l()(), i0.ɵted(4, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tooltipContent; _ck(_v, 4, 0, currVal_0); }); }
function View_MapLayerSelectorTooltipComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_MapLayerSelectorTooltipComponent_1)), i0.ɵdid(1, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showTooltip; _ck(_v, 1, 0, currVal_0); }, null); }
exports.View_MapLayerSelectorTooltipComponent_0 = View_MapLayerSelectorTooltipComponent_0;
function View_MapLayerSelectorTooltipComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "map-layer-selector-tooltip", [], null, null, null, View_MapLayerSelectorTooltipComponent_0, RenderType_MapLayerSelectorTooltipComponent)), i0.ɵdid(1, 114688, null, 0, i3.MapLayerSelectorTooltipComponent, [i4.MapLayerSelectorService, i0.ElementRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_MapLayerSelectorTooltipComponent_Host_0 = View_MapLayerSelectorTooltipComponent_Host_0;
var MapLayerSelectorTooltipComponentNgFactory = i0.ɵccf("map-layer-selector-tooltip", i3.MapLayerSelectorTooltipComponent, View_MapLayerSelectorTooltipComponent_Host_0, {}, {}, []);
exports.MapLayerSelectorTooltipComponentNgFactory = MapLayerSelectorTooltipComponentNgFactory;
