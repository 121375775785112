"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var _ = require("lodash");
var CadastreDataImportFormComponent = /** @class */ (function () {
    function CadastreDataImportFormComponent(restangularPromap) {
        this.restangularPromap = restangularPromap;
        this.importAdded = new core_1.EventEmitter();
        this.cadastreImport = {
            url: 'https://bd.cuzk.cz/owncloud/index.php/s/7QpYxcdNpXEP0Bp',
            password: '',
            folder: '',
            vfkVersion: '6.0',
            cadastreCodes: '',
        };
    }
    CadastreDataImportFormComponent.prototype.ngOnInit = function () {
        this.onSubmit = this.onSubmit.bind(this);
    };
    CadastreDataImportFormComponent.prototype.onSubmit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var data, newImport;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        data = __assign({}, this.cadastreImport);
                        data.kuIdsToDownload = data.cadastreCodes;
                        delete data.cadastreCodes;
                        if (typeof data.vfkVersion === 'string') {
                            data.vfkVersion = data.vfkVersion.replace('.', '');
                        }
                        return [4 /*yield*/, this.restangularPromap.all('cuzk/data-import').customPOST(data, 'new')];
                    case 1:
                        newImport = _a.sent();
                        this.importAdded.emit(newImport);
                        return [2 /*return*/, newImport];
                }
            });
        });
    };
    CadastreDataImportFormComponent.prototype.isValid = function () {
        var requiredFieldsValid = this.cadastreImport.url && this.cadastreImport.password && this.cadastreImport.folder && this.cadastreImport.vfkVersion;
        var cadastreCodesEmpty = !this.cadastreImport.cadastreCodes || this.cadastreImport.cadastreCodes.length === 0;
        var cadastreCodesValid = cadastreCodesEmpty || this.cadastreCodesToArray(this.cadastreImport.cadastreCodes).every(this.isCadastreCodeValid);
        return requiredFieldsValid && cadastreCodesValid;
    };
    CadastreDataImportFormComponent.prototype.cadastreCodesToArray = function (codes) {
        var SEPARATOR = ',';
        return codes.split(SEPARATOR);
    };
    CadastreDataImportFormComponent.prototype.isCadastreCodeValid = function (code) {
        var VALID_CADASTRE_CODE_LENGTH = 6;
        var isSixCharsLong = code.length === VALID_CADASTRE_CODE_LENGTH;
        var isFiniteNumber = _.isFinite(_.toNumber(code));
        return isFiniteNumber && isSixCharsLong;
    };
    return CadastreDataImportFormComponent;
}());
exports.CadastreDataImportFormComponent = CadastreDataImportFormComponent;
