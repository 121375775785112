"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var map_utils_crs_service_1 = require("@app/map/services/map-utils-crs.service");
var L = require("leaflet");
var MapModule = /** @class */ (function () {
    function MapModule(mapUtilsCrsService) {
        this.mapUtilsCrsService = mapUtilsCrsService;
        this.SRID_DEFS = {
            5514: "+proj=krovak +lat_0=49.5 +lon_0=24.83333333333333 +alpha=30.28813972222222 +k=0.9999 +x_0=0 +y_0=0 +ellps=bessel +towgs84=570.8,85.7,462.8,4.998,1.587,5.261,3.56 +units=m +no_defs",
        };
        this.getResolutions = function (width, maxZoom) {
            var size = width / 256;
            var levels = maxZoom;
            var resolutions = new Array(levels);
            var matrixIds = new Array(levels);
            for (var z = 0; z < levels; ++z) {
                resolutions[z] = size / Math.pow(2, z);
                matrixIds[z] = (z < 10) ? "0" + z : z.toString();
            }
            return resolutions;
        };
        this.LEAFLET_PROJ_DEFS = {
            5514: {
                resolutions: this.getResolutions(MapModule.JTSKBOUNDS[1][0] - MapModule.JTSKBOUNDS[0][0], 18),
                origin: MapModule.JTSKBOUNDS[0],
                bounds: L.bounds(L.point(MapModule.JTSKBOUNDS[0][0], MapModule.JTSKBOUNDS[1][1]), L.point(MapModule.JTSKBOUNDS[1][0], MapModule.JTSKBOUNDS[0][1]))
            }
        };
        this.registerProj4Srid();
        this.registerLeafletSrid();
    }
    MapModule.prototype.registerProj4Srid = function () {
        for (var sridId in this.SRID_DEFS) {
            this.mapUtilsCrsService.setProj4Srid(sridId, this.SRID_DEFS[sridId]);
        }
    };
    MapModule.prototype.registerLeafletSrid = function () {
        for (var sridId in this.LEAFLET_PROJ_DEFS) {
            this.mapUtilsCrsService.setLeafletSrid(sridId, new L.Proj.CRS('EPSG:' + sridId, this.SRID_DEFS[sridId], this.LEAFLET_PROJ_DEFS[sridId]));
        }
    };
    MapModule.JTSKBOUNDS = [[-925000, -920000], [-400646.464000000040, -1444353.535999999800]]; //WMTS
    return MapModule;
}());
exports.MapModule = MapModule;
