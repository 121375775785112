"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var _ = require("lodash");
var list_service_1 = require("@app/common/services/list.service");
var angular_1 = require("@uirouter/angular");
var parcel_service_1 = require("@app/ps/services/parcel.service");
var settings_service_1 = require("@app/ps/services/settings.service");
var auth_service_1 = require("@app/common/services/auth.service");
var table_utils_1 = require("@app/common/utils/table.utils");
var username_pipe_1 = require("@app/common/pipes/username.pipe");
var dialog_service_1 = require("@app/dialog/dialog.service");
var simple_text_modal_component_1 = require("@app/common/components/simple-text-modal/simple-text-modal.component");
var class_name_enum_1 = require("@app/dialog/class-name.enum");
var TitleDetailComponent = /** @class */ (function () {
    function TitleDetailComponent(helpIds, restangular, restangularSettings, userNamePipe, listService, stateService, parcelService, settingsService, authService, dialogService) {
        this.helpIds = helpIds;
        this.restangular = restangular;
        this.restangularSettings = restangularSettings;
        this.userNamePipe = userNamePipe;
        this.listService = listService;
        this.stateService = stateService;
        this.parcelService = parcelService;
        this.settingsService = settingsService;
        this.authService = authService;
        this.dialogService = dialogService;
        this.tabs = [
            {
                name: 'Vlastníci',
                id: 'owners',
                href: 'symap.project.titles.detail',
                urlParams: { tab: 'owners' },
            },
            {
                name: 'Parcely',
                id: 'parcels',
                href: 'symap.project.titles.detail',
                urlParams: { tab: 'parcels' },
            },
            {
                name: 'Ostatní parcely',
                id: 'otherParcels',
                href: 'symap.project.titles.detail',
                urlParams: { tab: 'otherParcels' },
            },
            {
                name: 'Budovy',
                id: 'buildings',
                href: 'symap.project.titles.detail',
                urlParams: { tab: 'buildings' },
            },
            {
                name: 'Omezení vlastnického práva',
                id: 'otherLegalRelations',
                href: 'symap.project.titles.detail',
                urlParams: { tab: 'otherLegalRelations' },
            },
            {
                name: 'Jiné zápisy',
                id: 'otherEntries',
                href: 'symap.project.titles.detail',
                urlParams: { tab: 'otherEntries' },
            },
            {
                name: 'Případy',
                id: 'cases',
                href: 'symap.project.titles.detail',
                urlParams: { tab: 'cases' },
            },
            {
                name: 'Poznámky',
                id: 'notes',
                href: 'symap.project.titles.detail',
                urlParams: { tab: 'notes' },
            },
            {
                name: 'Historie',
                id: 'history',
                href: 'symap.project.titles.detail',
                urlParams: { tab: 'history' },
            },
            {
                name: 'Upozornění',
                id: 'notifications',
                href: 'symap.project.titles.detail',
                urlParams: { tab: 'notifications' },
            },
            {
                name: 'Dokumenty',
                id: 'documents',
                href: 'symap.project.titles.detail',
                urlParams: { tab: 'documents' },
            },
        ];
        this.loading = true;
        this.parcelsLoading = true;
        this.easementPriceByShare = this.authService.getActualProject().easementPriceByShare;
        this.easementsLoading = true;
        this.tenantEdit = false;
        this.notificationCounts = { archived: undefined, unarchived: undefined };
        this.parcelsOtherLoading = true;
        this.dataOwnershipGroups = [];
        this.ownershipsLoading = true;
        this.buildingsLoading = true;
        this.buildingsWithDifferentTitleExists = false;
        this.otherEntriesLoading = true;
        this.restrictionsLoading = true;
        this.userSending = true;
        this.parcelsData = [];
        this.tab = (this.stateService.params.tab ? this.stateService.params.tab : 'owners');
        this.isActiveTab = this.isActiveTab.bind(this);
        this.uiOnParamsChanged = this.uiOnParamsChanged.bind(this);
        this.hideColumn = this.hideColumn.bind(this);
        this.canEdit = this.canEdit.bind(this);
        this.onUpdateNotificationList = this.onUpdateNotificationList.bind(this);
        this.addNote = this.addNote.bind(this);
        this.onUpdateDocumentList = this.onUpdateDocumentList.bind(this);
        this.onUpdateNoteList = this.onUpdateNoteList.bind(this);
        this.loadHistory = this.loadHistory.bind(this);
        this.onCaseTableSort = this.onCaseTableSort.bind(this);
        this.updateUser = this.updateUser.bind(this);
    }
    TitleDetailComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.loadTitle()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.settingsService.getSettingsLoader()];
                    case 2:
                        _a.sent();
                        this.loading = false;
                        this.prepareParcelColumns();
                        return [4 /*yield*/, this.loadOccupations()];
                    case 3:
                        _a.sent();
                        return [4 /*yield*/, this.loadEasements()];
                    case 4:
                        _a.sent();
                        this.loadNotifications();
                        this.loadParcels();
                        this.loadOwnerships();
                        this.loadBuildings();
                        this.loadOtherEntries();
                        this.loadHistory();
                        this.loadNotes();
                        this.loadDocuments();
                        this.loadCases();
                        this.loadUsers();
                        return [2 /*return*/];
                }
            });
        });
    };
    TitleDetailComponent.prototype.isActiveTab = function (id) {
        return id === this.tab;
    };
    TitleDetailComponent.prototype.uiOnParamsChanged = function (changedParams) {
        this.tab = changedParams.tab;
    };
    TitleDetailComponent.prototype.hideColumn = function (columnID, tableID) {
        if (tableID === void 0) { tableID = 'titleDetail'; }
        return this.settingsService.shouldHideColumn(tableID, columnID);
    };
    TitleDetailComponent.prototype.canEdit = function () {
        return this.authService.isAuthorized() || this.authService.hasPermission('assignable');
    };
    TitleDetailComponent.prototype.addNote = function () {
        var _this = this;
        var dialog = this.dialogService.open(simple_text_modal_component_1.SimpleTextModalComponent, {
            data: {
                title: this.data.blockedByUser ? 'Důvod odblokování LV' : 'Důvod blokace LV',
                label: 'Důvod',
                required: true
            },
            className: class_name_enum_1.ClassName.HIGHER_DIALOG,
        });
        var sub = dialog.afterClosed.subscribe(function (text) { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                if (text) {
                    this.restangular.one('titles', this.titleId).all('change-block-state')
                        .post({
                        text: text,
                        title: { id: this.titleId }
                    })
                        .then(function (data) {
                        _this.data.blockedByUser = data.noteType.key === 'ReasonTitleBlock';
                        _this.loadNotes();
                        _this.loadHistory();
                    });
                }
                sub.unsubscribe();
                return [2 /*return*/];
            });
        }); });
    };
    TitleDetailComponent.prototype.loadTitle = function () {
        var _this = this;
        return this.restangular.one('titles', this.titleId).get({ loadCollections: ['duplicityOwnership'] }).then(function (data) {
            _this.data = data;
            if (!data.endDate && data.platnostK && _this.authService.getActualProject().cadastreDataDate !== data.platnostK) {
                _this.cadastreDataDate = data.platnostK;
            }
        });
    };
    TitleDetailComponent.prototype.loadOccupations = function () {
        var _this = this;
        return this.restangular
            .all('occupations')
            .customPOST({ filter: { filters: { titleId: [this.titleId], validOrWithCase: [true], loadCollections: [
                        'parcel.knBudouc.currentParcels',
                        'parcel.knBudouc.currentParcels.bpejList',
                        'parcel.buildings',
                        'constructionObjects',
                        'rentalExpert',
                    ] }, sortOrder: [{ sortBy: 'parcel.parcisKmen' }, { sortBy: 'parcel.parcisPod' }] } })
            .then(function (data) {
            // group by occupation type into three groups permanent, underOneYear, overOneYear
            // permanent occupations on one parcel are merged
            _this.dataOccupations = data;
            _this.dataOccupations.forEach(function (item) {
                item.constructionObjects = item.constructionObjects.filter(function (co) { return !item.constructionObjects.some(function (co2) { return co2.id > co.id && co2.socis === co.socis && co2.sousek === co.sousek; }); });
            });
            _this.parcelsLoading = false;
            _this.onUpdateParcelsList();
            _this.dataOccupationGroups = _this.parcelService.groupOccupationsByParcel(_this.dataOccupations, _this.parcelsData);
            _this.occupationUnderYearTableName = _this.parcelService.getTemporaryOccupationName(_this.dataOccupationGroups.underOneYear);
            _this.occupationOverYearTableName = _this.parcelService.getTemporaryOccupationName(_this.dataOccupationGroups.overOneYear);
            var userIsAuthorized = _this.canEdit();
            if (_this.shouldAddEditPriceColumn(_this.dataOccupationGroups.overOneYear, userIsAuthorized)) {
                if (!_this.hideColumn('temporaryOverYearPriceColumns')) {
                    _this.occupationOverOneYearColumns.push({ id: 'occupation_edit_price', sortable: false });
                }
            }
            if (_this.shouldAddEditPriceColumn(_this.dataOccupationGroups.underOneYear, userIsAuthorized)) {
                if (!_this.hideColumn('temporaryUnderYearPriceColumns')) {
                    _this.occupationUnderOneYearColumns.push({ id: 'occupation_edit_price', sortable: false });
                }
            }
        });
    };
    TitleDetailComponent.prototype.loadEasements = function () {
        var _this = this;
        return this.restangular
            .all('easements')
            .customPOST({
            filter: {
                filters: { titleId: [this.titleId], validOrWithCase: [true], loadCollections: ['parcel.buildings'] },
                sortOrder: [{ sortBy: 'parcel.parcisKmen' }, { sortBy: 'parcel.parcisPod' }],
            },
            attributes: { constructionObjects: {
                    load: true,
                    attributes: {
                        administrator: { load: true },
                    }
                },
            }
        })
            .then(function (data) {
            _this.dataEasements = data;
            // if find some easement with property oznacVB, then set this.easementType to true, else false
            _this.easementType = data.some(function (easement) { return easement.hasOwnProperty('oznacVb') && easement.oznacVb; });
            if (!_this.easementPriceByShare && !_this.hideColumn('easementsPriceColumns')) {
                _this.easementColumns.push({ id: 'title_price', sortable: false });
            }
            _this.easementsLoading = false;
            _this.onUpdateParcelsList();
        });
    };
    TitleDetailComponent.prototype.onUpdateParcelsList = function () {
        var parcelTab = this.tabs.find(function (tab) { return tab.id === 'parcels'; });
        var occupationsParIDs = this.dataOccupations ? this.dataOccupations.map(function (occupation) { return occupation.parcel.idpar; }) : [];
        var easementsParIDs = this.dataEasements ? this.dataEasements.map(function (easement) { return easement.parcel.idpar; }) : [];
        var parcelsIDs = __spread(new Set(__spread(occupationsParIDs, easementsParIDs)));
        var parcelsCount = parcelsIDs.length;
        parcelTab.name = "Parcely (" + parcelsCount + ")";
    };
    TitleDetailComponent.prototype.loadBuildings = function () {
        var _this = this;
        return this.restangular
            .all('buildings')
            .customPOST({ filter: { filters: { occupationOrEasementExists: [true], titleRelatedId: [this.titleId], validity: ['valid'], loadCollections: ['parcelsWithEasementOrOccupation', 'buildingProtections', 'parcels.parcelProtections'] } } })
            .then(function (data) {
            data.forEach(function (building) {
                building.parcels.forEach(function (parcel) {
                    if (parcel.soucasti) {
                        building.buildingProtections = building.buildingProtections.concat(parcel.parcelProtections);
                    }
                    if ((!building.title || building.title.id === _this.titleId) && parcel.title.id !== _this.titleId) {
                        _this.buildingsWithDifferentTitleExists = true;
                    }
                });
            });
            _this.dataBuildings = data;
            _this.buildingsLoading = false;
            _this.onUpdateBuildingsList();
        });
    };
    TitleDetailComponent.prototype.onUpdateBuildingsList = function () {
        var buildingsTab = this.tabs.find(function (tab) { return tab.id === 'buildings'; });
        if (this.dataBuildings.length === 0) {
            this.tabs.splice(this.tabs.indexOf(buildingsTab), 1);
        }
        else {
            buildingsTab.name = "Budovy (" + this.dataBuildings.length + ")";
        }
    };
    TitleDetailComponent.prototype.loadOtherEntries = function () {
        var _this = this;
        this.restangular.all('other-legal-relations')
            .customPOST({ filter: { filters: { titleId: [this.titleId], validity: ['valid'], sections: ['C', 'D'], loadCollections: ['buildingTo.parcels', 'subjectsFor', 'subjectsTo'] } } })
            .then(function (data) {
            _this.dataRestrictions = _.filter(data, { sekce: 'C' });
            _this.restrictionsLoading = false;
            _this.onUpdateOtherLegalRelationsList();
            _this.dataOtherEntries = _.filter(data, { sekce: 'D' });
            _this.otherEntriesLoading = false;
            _this.onUpdateOtherEntriesList();
        });
    };
    TitleDetailComponent.prototype.onUpdateOtherEntriesList = function () {
        var otherEntriesTab = this.tabs.find(function (tab) { return tab.id === 'otherEntries'; });
        otherEntriesTab.name = "Jin\u00E9 z\u00E1pisy (" + this.dataOtherEntries.length + ")";
    };
    TitleDetailComponent.prototype.onUpdateOtherLegalRelationsList = function () {
        var otherLegalRelationsTab = this.tabs.find(function (tab) { return tab.id === 'otherLegalRelations'; });
        otherLegalRelationsTab.name = "Omezen\u00ED vlastnick\u00E9ho pr\u00E1va (" + this.dataRestrictions.length + ")";
    };
    TitleDetailComponent.prototype.loadNotifications = function () {
        var _this = this;
        this.notificationList = this.listService.createList('notifications', {
            filters: {
                titleId: this.titleId,
                loadCollections: ['knBudouc.futureParcel'],
                archive: false
            },
            sortOrder: { sortBy: 'createdTimestamp', direction: 'desc' }
        });
        this.listService.fetchResult(this.notificationList).then(function () {
            _this.onUpdateNotificationList();
        });
    };
    TitleDetailComponent.prototype.onUpdateNotificationList = function () {
        var _this = this;
        var filter = {
            limit: 1,
            filters: {
                titleId: this.titleId,
                archive: false
            }
        };
        var archivedFilter = __assign({}, filter, { filters: __assign({}, filter.filters, { archive: true }) });
        var notificationList = this.listService.createList('notifications', filter);
        var notificationListArchived = this.listService.createList('notifications', archivedFilter);
        this.listService.fetchResult(notificationList).then(function (data) {
            _this.notificationCounts.unarchived = data.itemCount;
            _this.updateNotificationTab();
        });
        this.listService.fetchResult(notificationListArchived).then(function (data) {
            _this.notificationCounts.archived = data.itemCount;
            _this.updateNotificationTab();
        });
    };
    TitleDetailComponent.prototype.updateNotificationTab = function () {
        var notificationTab = this.tabs.find(function (tab) { return tab.id === 'notifications'; });
        if (this.notificationCounts.unarchived !== undefined && this.notificationCounts.archived !== undefined) {
            notificationTab.name = "Upozorn\u011Bn\u00ED (" + this.notificationCounts.unarchived + "/" + this.notificationCounts.archived + ")";
        }
        else {
            notificationTab.name = 'Upozornění';
        }
    };
    TitleDetailComponent.prototype.loadParcels = function () {
        var _this = this;
        return this.restangular
            .all('parcels')
            .customPOST({ filter: { filters: { titleId: [this.titleId], validity: ['valid'], occupationOrEasementExists: [false], loadCollections: [] }, sortOrder: [{ sortBy: 'id' }] } })
            .then(function (data) {
            _this.parcelsOtherLoading = false;
            _this.dataParcelsOther = data;
            _this.onUpdateOtherParcels();
        });
    };
    TitleDetailComponent.prototype.onUpdateOtherParcels = function () {
        var otherParcelsTab = this.tabs.find(function (tab) { return tab.id === 'otherParcels'; });
        var otherParcelsCount = this.dataParcelsOther ? this.dataParcelsOther.length : 0;
        if (otherParcelsCount === 0) {
            this.tabs.splice(this.tabs.indexOf(otherParcelsTab), 1);
        }
        else {
            otherParcelsTab.name = "Ostatn\u00ED parcely (" + otherParcelsCount + ")";
        }
    };
    TitleDetailComponent.prototype.loadOwnerships = function () {
        var _this = this;
        return this.restangular
            .all('ownerships')
            .customPOST({ filter: { filters: { titleId: [this.titleId], validity: ['valid'] }, sortOrder: [{ sortBy: 'typravNazev' }] } })
            .then(function (data) {
            data.forEach(function (value) {
                var items = _this.dataOwnershipGroups.filter(function (e) { return e.groupName === value.typravNazev; });
                var group;
                if (items.length === 0) {
                    group = { groupName: value.typravNazev, items: [] };
                    _this.dataOwnershipGroups.push(group);
                }
                else {
                    group = items[0];
                }
                group.items.push(value);
            });
            _this.ownershipsLoading = false;
            _this.onUpdateOwnersList();
        });
    };
    TitleDetailComponent.prototype.onUpdateOwnersList = function () {
        var ownersTab = this.tabs.find(function (tab) { return tab.id === 'owners'; });
        var ownersCount = 0;
        this.dataOwnershipGroups.forEach(function (g) {
            ownersCount += g.items.length;
        });
        ownersTab.name = "Vlastn\u00EDci (" + ownersCount + ")";
    };
    TitleDetailComponent.prototype.loadDocuments = function () {
        var _this = this;
        this.documentList = this.listService.createList('attachments', {
            filters: { titleId: this.titleId, cancelled: false },
            sortOrder: { sortBy: 'timestamp', direction: 'desc' }
        }, undefined, { businessCase: {} });
        this.listService.fetchResult(this.documentList).then(function () {
            _this.onUpdateDocumentList();
        });
    };
    TitleDetailComponent.prototype.onUpdateDocumentList = function () {
        var attachmentsTab = this.tabs.find(function (tab) { return tab.id === 'documents'; });
        attachmentsTab.name = "Dokumenty (" + this.documentList.list.length + ")";
    };
    TitleDetailComponent.prototype.loadNotes = function () {
        var _this = this;
        this.noteList = this.listService.createList('notes', {
            filters: { titleId: this.titleId, systemType: false, cancelled: false, },
            sortOrder: { sortBy: 'timestamp', direction: 'desc' }
        });
        this.listService.fetchResult(this.noteList).then(function () {
            _this.onUpdateNoteList();
        });
    };
    TitleDetailComponent.prototype.onUpdateNoteList = function () {
        var noteTab = this.tabs.find(function (tab) { return tab.id === 'notes'; });
        noteTab.name = "Pozn\u00E1mky (" + this.noteList.list.filter(function (a) { return !a.cancelled; }).length + ")";
    };
    TitleDetailComponent.prototype.loadHistory = function () {
        var _this = this;
        this.historyList = this.listService.createList('notes', {
            filters: { titleId: this.titleId, systemType: true },
            sortOrder: { sortBy: 'timestamp', direction: 'desc' }
        });
        this.listService.fetchResult(this.historyList).then(function () {
            _this.onUpdateHistoryList();
        });
    };
    TitleDetailComponent.prototype.onUpdateHistoryList = function () {
        var historyTab = this.tabs.find(function (tab) { return tab.id === 'history'; });
        historyTab.name = "Historie (" + this.historyList.itemCount + ")";
    };
    TitleDetailComponent.prototype.loadCases = function () {
        var _this = this;
        this.caseList = this.listService.createList('cases', { filters: { parcelTitleId: this.titleId, cancelStatus: 'notCancelled', loadCollections: ['titles', 'caseOwnerships', 'constructionObjects'] }, limit: null });
        this.listService.fetchResult(this.caseList).then(function () {
            _this.onUpdateCaseList();
        });
    };
    TitleDetailComponent.prototype.onUpdateCaseList = function () {
        var caseTab = this.tabs.find(function (tab) { return tab.id === 'cases'; });
        caseTab.name = "P\u0159\u00EDpady (" + this.caseList.itemCount + ")";
    };
    TitleDetailComponent.prototype.onCaseTableSort = function (event) {
        this.listService.sort(this.caseList, event.column, event.ascOrDesc);
    };
    TitleDetailComponent.prototype.loadUsers = function () {
        var _this = this;
        var filter = {
            filters: {
                searchText: {
                    values: [],
                },
                applications: {
                    values: [this.authService.getActiveApplication()],
                },
                permission: {
                    values: ['assignable'],
                },
                projects: {
                    values: [this.authService.getActualProject().key],
                },
            },
            sortOrder: [
                { sortBy: 'surname' },
                { sortBy: 'name' },
            ],
        };
        this.restangularSettings.all('users/list').customPOST({ filter: filter }).then(function (data) {
            _this.users = data;
        });
        this.userSending = false;
    };
    TitleDetailComponent.prototype.updateUser = function () {
        var _this = this;
        this.userSending = true;
        this.restangular.one('titles', this.titleId).customPUT({ id: this.data.user.id }, 'user').then(function () {
            _this.userSending = false;
        });
    };
    TitleDetailComponent.prototype.prepareParcelColumns = function () {
        var occupationPermanentColumns = __spread(TitleDetailComponent.OCCUPATION_PERMANENT_COLUMNS);
        var occupationUnderOneYearColumns = __spread(TitleDetailComponent.OCCUPATION_UNDER_YEAR_COLUMNS);
        var occupationOverOneYearColumns = __spread(TitleDetailComponent.OCCUPATION_OVER_YEAR_COLUMNS);
        var easementColumns = __spread(TitleDetailComponent.EASEMENT_COLUMNS);
        if (!this.hideColumn('permanentOccupationPriceColumns')) {
            occupationPermanentColumns = occupationPermanentColumns.concat(TitleDetailComponent.OCCUPATION_PERMANENT_PRICE_COLUMNS);
        }
        if (!this.hideColumn('temporaryUnderYearPriceColumns')) {
            occupationUnderOneYearColumns = occupationUnderOneYearColumns.concat(TitleDetailComponent.OCCUPATION_UNDER_YEAR_PRICE_COLUMNS);
        }
        if (!this.hideColumn('temporaryOverYearPriceColumns')) {
            occupationOverOneYearColumns = occupationOverOneYearColumns.concat(TitleDetailComponent.OCCUPATION_OVER_YEAR_PRICE_COLUMNS);
        }
        this.occupationPermanentColumns = table_utils_1.TableUtils.repairColumnsStructure(occupationPermanentColumns);
        this.occupationUnderOneYearColumns = table_utils_1.TableUtils.repairColumnsStructure(occupationUnderOneYearColumns);
        this.occupationOverOneYearColumns = table_utils_1.TableUtils.repairColumnsStructure(occupationOverOneYearColumns);
        this.easementColumns = table_utils_1.TableUtils.repairColumnsStructure(easementColumns);
    };
    TitleDetailComponent.prototype.shouldAddEditPriceColumn = function (data, userIsAuthorized) {
        var countColumnIsEmpty = function () { return data && data.some(function (row) { return row.occupations.some(function (occupation) { return occupation.countCases - occupation.countCasesFuture === 0; }); }); };
        return countColumnIsEmpty() && userIsAuthorized;
    };
    /**
     * Finds objects added to the latest case
     * and increments the case count accordingly.
     *
     * @param {array} arr
     * @param {array} rowsToIncrement
     * @returns {void}
     */
    TitleDetailComponent.prototype.incrementCaseCount = function (arr, rowsToIncrement) {
        arr.forEach(function (item) {
            if (rowsToIncrement.includes(item.id)) {
                item.countCases += 1;
            }
        });
    };
    TitleDetailComponent.OCCUPATION_PERMANENT_COLUMNS = [
        { id: 'label_gp', sortable: false },
        { id: 'occupation_parcel_title', sortable: true },
        { id: 'occupation_concern_object', sortable: false },
        { id: 'occupation_phase', sortable: true },
        { id: 'occupation_occupation', sortable: true },
        { id: 'occupation_cases', sortable: true },
        { id: 'current_parcel_title', sortable: false },
        { id: 'current_protection', sortable: false },
        { id: 'current_concern_object', sortable: false },
    ];
    TitleDetailComponent.OCCUPATION_UNDER_YEAR_COLUMNS = [
        { id: 'label_gp', sortable: false },
        { id: 'occupation_parcel_title', sortable: true },
        { id: 'occupation_concern_object', sortable: false },
        { id: 'occupation_phase', sortable: true },
        { id: 'occupation_occupation', sortable: true },
        { id: 'occupation_cases', sortable: true },
        { id: 'current_parcel_title', sortable: false },
        { id: 'current_protection', sortable: false },
        { id: 'current_concern_object', sortable: false },
    ];
    TitleDetailComponent.OCCUPATION_OVER_YEAR_COLUMNS = TitleDetailComponent.OCCUPATION_UNDER_YEAR_COLUMNS;
    TitleDetailComponent.EASEMENT_COLUMNS = [
        { id: 'parcel', sortable: true },
        { id: 'construction_object', sortable: false },
        { id: 'title', sortable: true },
        { id: 'area', sortable: false },
        { id: 'geometric_plan', sortable: false },
        { id: 'length', sortable: false },
        { id: 'case', sortable: true },
    ];
    TitleDetailComponent.OCCUPATION_PERMANENT_PRICE_COLUMNS = [
        { id: 'occupation_price_parcel', sortable: false },
        { id: 'occupation_price_vegetation', sortable: false },
        { id: 'occupation_price_construction', sortable: false },
        { id: 'occupation_price_summary', sortable: false },
    ];
    TitleDetailComponent.OCCUPATION_UNDER_YEAR_PRICE_COLUMNS = [
        { id: 'occupation_assessment_price', sortable: false },
        { id: 'occupation_price_land_price', sortable: false },
        { id: 'occupation_price_rent_price_year', sortable: false },
        { id: 'occupation_price_rent_length', sortable: false },
        { id: 'occupation_price_total_rental_price', sortable: false },
    ];
    TitleDetailComponent.OCCUPATION_OVER_YEAR_PRICE_COLUMNS = TitleDetailComponent.OCCUPATION_UNDER_YEAR_PRICE_COLUMNS;
    return TitleDetailComponent;
}());
exports.TitleDetailComponent = TitleDetailComponent;
