"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var _ = require("lodash");
var list_service_1 = require("@app/common/services/list.service");
var angular_1 = require("@uirouter/angular");
var parcel_service_1 = require("@app/ps/services/parcel.service");
var settings_service_1 = require("@app/ps/services/settings.service");
var auth_service_1 = require("@app/common/services/auth.service");
var alert_component_1 = require("@app/dialog/alert/alert.component");
var class_name_enum_1 = require("@app/dialog/class-name.enum");
var dialog_service_1 = require("@app/dialog/dialog.service");
var word_translate_pipe_1 = require("@app/common/pipes/word-translate.pipe");
var table_utils_1 = require("@app/common/utils/table.utils");
var ConstructionObjectDetailComponent = /** @class */ (function () {
    function ConstructionObjectDetailComponent(helpIds, restangular, wordTranslatePipe, dialogService, listService, stateService, parcelService, settingsService, authService) {
        this.helpIds = helpIds;
        this.restangular = restangular;
        this.wordTranslatePipe = wordTranslatePipe;
        this.dialogService = dialogService;
        this.listService = listService;
        this.stateService = stateService;
        this.parcelService = parcelService;
        this.settingsService = settingsService;
        this.authService = authService;
        this.tabs = [
            {
                name: 'Poznámky',
                id: 'notes',
                href: "symap.project.constructionObjects.detail",
                urlParams: { tab: 'notes' }
            },
            {
                name: 'Historie',
                id: 'history',
                href: "symap.project.constructionObjects.detail",
                urlParams: { tab: 'history' }
            },
            {
                name: 'Upozornění',
                id: 'notifications',
                href: "symap.project.constructionObjects.detail",
                urlParams: { tab: 'notifications' }
            },
            {
                name: 'Dokumenty',
                id: 'documents',
                href: "symap.project.constructionObjects.detail",
                urlParams: { tab: 'documents' }
            },
        ];
        this.loading = true;
        this.isConstructionObjectGeometry = this.authService.getActualProject().isConstructionObjectGeometry;
        this.showPrice = !this.authService.getActualProject().easementPriceByShare;
        this.user = this.authService.getUser();
        this.updatingStatus = false;
        this.geometricPlans = [];
        this.geometricPlansLoading = true;
        this.allEasements = [];
        this.notificationCounts = { archived: undefined, unarchived: undefined };
        this.tab = (this.stateService.params.tab ? this.stateService.params.tab : 'occupations');
        this.isActiveTab = this.isActiveTab.bind(this);
        this.onCreateCase = this.onCreateCase.bind(this);
        this.onUpdateConstructionObjectStatus = this.onUpdateConstructionObjectStatus.bind(this);
    }
    ConstructionObjectDetailComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Promise.all([
                            this.settingsService.getSettingsLoader(),
                            this.loadConstructionObject(),
                            this.loadOccupations(),
                            this.loadGeometricPlans(),
                        ])];
                    case 1:
                        _a.sent();
                        this.onUpdateGeometricPlansList();
                        if (!this.hideColumnList('state')) {
                            this.loadStatuses();
                        }
                        this.loadNotes();
                        this.loadDocuments();
                        this.loadHistory();
                        this.loadNotifications();
                        this.resolveTabs();
                        this.prepareParcelColumns();
                        this.loading = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    ConstructionObjectDetailComponent.prototype.isActiveTab = function (id) {
        return id === this.tab;
    };
    ConstructionObjectDetailComponent.prototype.uiOnParamsChanged = function (changedParams) {
        this.tab = changedParams.tab;
    };
    ConstructionObjectDetailComponent.prototype.onCreateCase = function () {
        if (!this.constructionObject.administratorType) {
            var data = {
                msg: "\n          P\u0159ed zalo\u017Een\u00EDm p\u0159\u00EDpad\u016F pros\u00EDm zadejte spr\u00E1vce\n          " + this.wordTranslatePipe.transform('CONSTRUCTION_OBJECT_GENITIV') + ",\n          v z\u00E1kladn\u00EDch \u00FAdaj\u00EDch o " + this.wordTranslatePipe.transform('CONSTRUCTION_OBJECT_LOCAL') + ".\n        ",
            };
            this.dialogService.open(alert_component_1.AlertComponent, {
                data: data,
                className: class_name_enum_1.ClassName.ADJUSTED_DIALOG,
            });
        }
        else {
            this.stateService.go('symap.project.constructionObjects.detail.createCase');
        }
    };
    ConstructionObjectDetailComponent.prototype.onUpdateConstructionObjectStatus = function () {
        var _this = this;
        return this.restangular.one('construction-objects', this.constructionObjectId)
            .customPUT(this.constructionObject)
            .then(function (data) {
            delete data.actingPersons;
            delete data.administrator;
            _.assign(_this.constructionObject, data.plain());
            _this.updatingStatus = false;
        });
    };
    ConstructionObjectDetailComponent.prototype.onUpdateGeometricPlansList = function () {
        var tab = ConstructionObjectDetailComponent.GEOMETRIC_PLANS_TAB;
        var count = this.constructionObject.countEasements;
        tab.name = "Slu\u017Eebnosti (" + this.geometricPlansCount + "/" + this.constructionObject.countEasements + ")";
        var tabIndex = this.tabs.indexOf(tab);
        if (count === 0 && tabIndex > -1) {
            this.tabs.splice(this.tabs.indexOf(tab), 1);
        }
        else if (count > 0 && tabIndex === -1) {
            this.tabs.push(tab);
        }
    };
    ConstructionObjectDetailComponent.prototype.onUpdateParcelsList = function () {
        var tab = ConstructionObjectDetailComponent.PARCELS_TAB;
        var occupationsParIDs = this.occupationList && this.occupationList.list
            ? this.occupationList.list.map(function (occupation) { return occupation.parcel.idpar; })
            : [];
        var parcelsIDs = __spread(new Set(__spread(occupationsParIDs)));
        var parcelsCount = parcelsIDs.length;
        tab.name = "Parcely (" + parcelsCount + ")";
        var tabIndex = this.tabs.indexOf(tab);
        if (parcelsCount === 0 && tabIndex > -1) {
            this.tabs.splice(this.tabs.indexOf(tab), 1);
        }
        else if (parcelsCount > 0 && tabIndex === -1) {
            this.tabs.push(tab);
        }
    };
    ConstructionObjectDetailComponent.prototype.onUpdateNoteList = function () {
        var noteTab = this.tabs.find(function (tab) { return tab.id === 'notes'; });
        noteTab.name = "Pozn\u00E1mky (" + this.noteList.list.filter(function (a) { return !a.cancelled; }).length + ")";
    };
    ConstructionObjectDetailComponent.prototype.onUpdateHistoryList = function () {
        var historyTab = this.tabs.find(function (tab) { return tab.id === 'history'; });
        historyTab.name = "Historie (" + this.historyList.itemCount + ")";
    };
    ConstructionObjectDetailComponent.prototype.onUpdateDocumentList = function () {
        var attachmentsTab = this.tabs.find(function (tab) { return tab.id === 'documents'; });
        attachmentsTab.name = "Dokumenty (" + this.documentList.list.filter(function (a) { return !a.cancelled; }).length + ")";
    };
    ConstructionObjectDetailComponent.prototype.onUpdateNotificationList = function () {
        var _this = this;
        var filter = {
            limit: 1,
            filters: {
                constructionObjectId: this.constructionObject.id,
                archive: false
            }
        };
        var archivedFilter = __assign({}, filter, { filters: __assign({}, filter.filters, { archive: true }) });
        var notificationList = this.listService.createList('notifications', filter);
        var notificationListArchived = this.listService.createList('notifications', archivedFilter);
        this.listService.fetchResult(notificationList).then(function (data) {
            _this.notificationCounts.unarchived = data.itemCount;
            _this.updateNotificationTab();
        });
        this.listService.fetchResult(notificationListArchived).then(function (data) {
            _this.notificationCounts.archived = data.itemCount;
            _this.updateNotificationTab();
        });
    };
    ConstructionObjectDetailComponent.prototype.canEdit = function () {
        return this.authService.isAuthorized() || this.authService.hasPermission("assignable");
    };
    ConstructionObjectDetailComponent.prototype.loadConstructionObject = function () {
        var _this = this;
        return this.restangular.one('construction-objects', this.constructionObjectId)
            .get({ loadCollections: ['actingPersons', 'administrator'] })
            .then(function (data) {
            _this.constructionObject = data.plain();
        });
    };
    ConstructionObjectDetailComponent.prototype.loadGeometricPlans = function () {
        var _this = this;
        return this.restangular.all('geometric-plans')
            .customPOST({
            filter: {
                filters: { constructionObjectId: [this.constructionObjectId] },
                sortOrder: [{ sortBy: 'kutx' }]
            }
        })
            .then(function (data) {
            _this.geometricPlans = data.plain();
            _this.geometricPlansLoading = false;
            _this.geometricPlansCount = 0;
            _this.geometricPlans.forEach(function (geometricPlan) {
                // init
                geometricPlan.totalVymera = 0;
                geometricPlan.totalDelka = 0;
                geometricPlan.totalPrice = 0;
                _this.geometricPlansCount += 1;
                geometricPlan.listener = function (labelState) {
                    if (labelState === false && !geometricPlan.easementList) {
                        delete geometricPlan.listener; // run only once
                        // load GP data
                        geometricPlan.easementList = _this.listService.createList('easements', {
                            filters: { validOrWithCase: true, gpId: geometricPlan.id, constructionObjectId: _this.constructionObjectId, loadCollections: [
                                    'constructionObjects',
                                    'parcel.buildings',
                                ] },
                            limit: undefined
                        });
                        _this.listService.fetchResult(geometricPlan.easementList, false).then(function () {
                            geometricPlan.easementList.list.forEach(function (easement) {
                                ConstructionObjectDetailComponent.ammendGPInfo(easement, geometricPlan, ConstructionObjectDetailComponent.determinetGlobalPriceType(easement));
                            });
                            _this.allEasements = _this.allEasements.concat(geometricPlan.easementList.list);
                            geometricPlan.totalVymera = ConstructionObjectDetailComponent.getGeometricPlanTotalArea(geometricPlan.easementList.list);
                            geometricPlan.totalDelka = ConstructionObjectDetailComponent.getGeometricPlanTotalLength(geometricPlan.easementList.list);
                            geometricPlan.totalPrice = ConstructionObjectDetailComponent.getGeometricPlanTotalPrice(geometricPlan.easementList.list);
                        });
                    }
                };
            });
        });
    };
    ConstructionObjectDetailComponent.prototype.hideColumnList = function (columnID) {
        var tableID = 'constructionObjects';
        return this.settingsService.shouldHideColumn(tableID, columnID);
    };
    ConstructionObjectDetailComponent.prototype.hideColumn = function (columnID) {
        var tableID = 'constructionObjectDetail';
        return this.settingsService.shouldHideColumn(tableID, columnID);
    };
    ConstructionObjectDetailComponent.prototype.prepareParcelColumns = function () {
        var occupationPermanentColumns = __spread(ConstructionObjectDetailComponent.OCCUPATION_PERMANENT_COLUMNS);
        var occupationUnderOneYearColumns = __spread(ConstructionObjectDetailComponent.OCCUPATION_UNDER_YEAR_COLUMNS);
        var occupationOverOneYearColumns = __spread(ConstructionObjectDetailComponent.OCCUPATION_OVER_YEAR_COLUMNS);
        var geometricPlanColumns = __spread(ConstructionObjectDetailComponent.GEOMETRIC_PLAN_COLUMNS);
        if (this.showPrice) {
            if (!this.hideColumn('permanentOccupationPriceColumns')) {
                occupationPermanentColumns = occupationPermanentColumns.concat(ConstructionObjectDetailComponent.OCCUPATION_PERMANENT_PRICE_COLUMNS);
            }
            if (!this.hideColumn('temporaryUnderYearPriceColumns')) {
                occupationUnderOneYearColumns = occupationUnderOneYearColumns.concat(ConstructionObjectDetailComponent.OCCUPATION_UNDER_YEAR_PRICE_COLUMNS);
            }
            if (!this.hideColumn('temporaryOverYearPriceColumns')) {
                occupationOverOneYearColumns = occupationOverOneYearColumns.concat(ConstructionObjectDetailComponent.OCCUPATION_OVER_YEAR_PRICE_COLUMNS);
            }
            if (!this.hideColumn('constructionObjectsBySharedEasement')) {
                geometricPlanColumns = geometricPlanColumns.concat(ConstructionObjectDetailComponent.GEOMETRIC_PLAN_PRICE_COLUMNS);
            }
            if (this.constructionObject.easementGlobalPriceType === 'B' && this.constructionObject.easementGlobalOtherUnitType === 'S') {
                geometricPlanColumns.push({ id: 'price_edit_construction_object', sortable: true });
            }
            else if (this.constructionObject.easementGlobalPriceType !== 'I') {
                geometricPlanColumns.push({ id: 'price', sortable: true });
            }
            else if (this.constructionObject.easementGlobalPriceType === 'I') {
                geometricPlanColumns.push({ id: 'price_edit_construction_object', sortable: true });
            }
        }
        this.occupationPermanentColumns = table_utils_1.TableUtils.repairColumnsStructure(occupationPermanentColumns);
        this.occupationUnderOneYearColumns = table_utils_1.TableUtils.repairColumnsStructure(occupationUnderOneYearColumns);
        this.occupationOverOneYearColumns = table_utils_1.TableUtils.repairColumnsStructure(occupationOverOneYearColumns);
        this.geometricPlanColumns = table_utils_1.TableUtils.repairColumnsStructure(geometricPlanColumns);
    };
    ConstructionObjectDetailComponent.prototype.loadStatuses = function () {
        var _this = this;
        var statusesList = this.listService.createList('construction-object-statuses', { limit: null });
        this.listService.fetchResult(statusesList).then(function () { _this.statuses = statusesList.list; });
    };
    ConstructionObjectDetailComponent.prototype.loadNotes = function () {
        var _this = this;
        this.noteList = this.listService.createList('notes', {
            filters: { constructionObjectId: this.constructionObject.id, systemType: false, cancelled: false, },
            sortOrder: { sortBy: 'timestamp', direction: 'desc' }
        });
        this.listService.fetchResult(this.noteList).then(function () {
            _this.onUpdateNoteList();
        });
    };
    ConstructionObjectDetailComponent.prototype.loadHistory = function () {
        var _this = this;
        this.historyList = this.listService.createList('notes', {
            filters: { constructionObjectId: this.constructionObject.id, systemType: true },
            sortOrder: { sortBy: 'timestamp', direction: 'desc' }
        });
        this.listService.fetchResult(this.historyList).then(function () {
            _this.onUpdateHistoryList();
        });
    };
    ConstructionObjectDetailComponent.prototype.loadOccupations = function () {
        var _this = this;
        this.occupationList = this.listService.createList('occupations', {
            limit: undefined,
            filters: {
                constructionObjectId: this.constructionObjectId,
                validity: ['valid'],
                loadCollections: [
                    'parcel.parcelProtections',
                    'parcel.knBudouc.currentParcels',
                    'parcel.knBudouc.currentParcels.bpejList',
                    'parcel.knBudouc.currentParcels.parcelProtections',
                    'parcel.buildings',
                    'rentalExpert',
                ]
            },
            sortOrder: [{ sortBy: 'parcel.parcisKmen' }, { sortBy: 'parcel.parcisPod' }]
        });
        return this.listService.fetchResult(this.occupationList).then(function (data) {
            _this.occupationGroups = _this.parcelService.groupOccupationsByParcel(data.list, []);
            _this.occupationUnderYearTableName = _this.parcelService.getTemporaryOccupationName(_this.occupationGroups.underOneYear);
            _this.occupationOverYearTableName = _this.parcelService.getTemporaryOccupationName(_this.occupationGroups.overOneYear);
            _this.onUpdateParcelsList();
        });
    };
    ConstructionObjectDetailComponent.prototype.loadDocuments = function () {
        var _this = this;
        this.documentList = this.listService.createList('attachments', {
            filters: { constructionObjectId: this.constructionObject.id, cancelled: false },
            sortOrder: { sortBy: 'timestamp', direction: 'desc' }
        });
        this.listService.fetchResult(this.documentList).then(function () {
            _this.onUpdateDocumentList();
        });
    };
    ConstructionObjectDetailComponent.prototype.loadNotifications = function () {
        var _this = this;
        this.notificationList = this.listService.createList('notifications', {
            filters: {
                constructionObjectId: this.constructionObject.id,
                loadCollections: ['knBudouc.futureParcel'],
                archive: false,
            },
            sortOrder: { sortBy: 'createdTimestamp', direction: 'desc' }
        });
        this.listService.fetchResult(this.notificationList).then(function () {
            _this.onUpdateNotificationList();
        });
    };
    ConstructionObjectDetailComponent.prototype.updateNotificationTab = function () {
        var notificationTab = this.tabs.find(function (tab) { return tab.id === 'notifications'; });
        if (this.notificationCounts.unarchived !== undefined && this.notificationCounts.archived !== undefined) {
            notificationTab.name = "Upozorn\u011Bn\u00ED (" + this.notificationCounts.unarchived + "/" + this.notificationCounts.archived + ")";
        }
        else {
            notificationTab.name = 'Upozornění';
        }
    };
    ConstructionObjectDetailComponent.prototype.resolveTabs = function () {
        // if no occupations go to easements or notes
        if (this.occupationList.itemCount === 0 && this.tab === 'parcels') {
            if (this.constructionObject.countEasements > 0) {
                this.stateService.go('.', { tab: 'easements' }, { location: 'replace' });
            }
            else {
                this.stateService.go('.', { tab: 'notes' }, { location: 'replace' });
            }
        }
        // redirect if path is active
        if (this.constructionObject.countEasements === 0 && this.tab === 'easements') {
            this.stateService.go('.', { tab: 'notes' }, { location: 'replace' });
        }
        this.tabs.sort(function (a, b) {
            return ConstructionObjectDetailComponent.TABS_ORDER.indexOf(a.id) - ConstructionObjectDetailComponent.TABS_ORDER.indexOf(b.id);
        });
    };
    ConstructionObjectDetailComponent.getGeometricPlanTotalPrice = function (easements) {
        return easements.reduce(function (sum, e) {
            return e.easementPrice ? sum + e.easementPrice : sum;
        }, 0);
    };
    ConstructionObjectDetailComponent.getGeometricPlanTotalLength = function (easements) {
        return easements.reduce(function (sum, e) {
            return e.delka ? sum + e.delka : sum;
        }, 0);
    };
    ConstructionObjectDetailComponent.getGeometricPlanTotalArea = function (easements) {
        return easements.reduce(function (sum, e) {
            return e.vymera ? sum + e.vymera : sum;
        }, 0);
    };
    ConstructionObjectDetailComponent.ammendGPInfo = function (easement, geometricPlan, easementGlobalPriceType) {
        easement.geometricPlan = geometricPlan;
        easement.easementGlobalPriceType = easementGlobalPriceType;
    };
    ConstructionObjectDetailComponent.determinetGlobalPriceType = function (easement) {
        var easementGlobalPriceType = new Set(easement.constructionObjects.map(function (co) { return co.easementGlobalPriceType; })).size == 1 ?
            easement.constructionObjects[0].easementGlobalPriceType : null;
        var easementGlobalOtherUnitType = new Set(easement.constructionObjects.map(function (co) { return co.easementGlobalOtherUnitType; })).size == 1 ?
            easement.constructionObjects[0].easementGlobalOtherUnitType : null;
        return easementGlobalPriceType === 'I' ||
            (easementGlobalPriceType === 'B' &&
                easementGlobalOtherUnitType === 'S' &&
                easement.priceType &&
                (easement.existsCaseBlockingPrice || easement.acceleratedCaseStatus === 'DeclinedOwner')) ? 'I' : easementGlobalPriceType;
    };
    ConstructionObjectDetailComponent.OCCUPATION_PERMANENT_COLUMNS = [
        { id: 'label_gp', sortable: false },
        { id: 'occupation_title', sortable: true },
        { id: 'occupation_parcel', sortable: true },
        { id: 'occupation_area', sortable: true },
        { id: 'occupation_phase', sortable: true },
        { id: 'occupation_occupation', sortable: true },
        { id: 'occupation_cases_parcel', sortable: true },
        { id: 'current_title', sortable: false },
        { id: 'current_parcel', sortable: false },
        { id: 'current_protection', sortable: false },
    ];
    ConstructionObjectDetailComponent.OCCUPATION_UNDER_YEAR_COLUMNS = [
        { id: 'label_gp', sortable: false },
        { id: 'occupation_title', sortable: true },
        { id: 'occupation_parcel', sortable: true },
        { id: 'area', sortable: true },
        { id: 'occupation_phase', sortable: true },
        { id: 'occupation_occupation', sortable: true },
        { id: 'occupation_cases', sortable: true },
        { id: 'current_title', sortable: false },
        { id: 'current_parcel', sortable: false },
        { id: 'current_protection', sortable: false },
    ];
    ConstructionObjectDetailComponent.OCCUPATION_OVER_YEAR_COLUMNS = ConstructionObjectDetailComponent.OCCUPATION_UNDER_YEAR_COLUMNS;
    ConstructionObjectDetailComponent.GEOMETRIC_PLAN_COLUMNS = [
        { id: 'parcel', sortable: true },
        { id: 'title', sortable: true },
        { id: 'area', sortable: true },
        { id: 'length', sortable: true },
        { id: 'cases', sortable: true },
    ];
    ConstructionObjectDetailComponent.OCCUPATION_PERMANENT_PRICE_COLUMNS = [
        { id: 'occupation_price_parcel', sortable: false },
        { id: 'occupation_price_vegetation', sortable: false },
        { id: 'occupation_price_construction', sortable: false },
        { id: 'occupation_price_summary', sortable: false },
    ];
    ConstructionObjectDetailComponent.OCCUPATION_UNDER_YEAR_PRICE_COLUMNS = [
        { id: 'occupation_assessment_price', sortable: false },
        { id: 'occupation_price_land_price', sortable: false },
        { id: 'occupation_price_rent_price_year', sortable: false },
        { id: 'occupation_price_rent_length', sortable: false },
        { id: 'occupation_price_total_rental_price', sortable: false },
    ];
    ConstructionObjectDetailComponent.OCCUPATION_OVER_YEAR_PRICE_COLUMNS = ConstructionObjectDetailComponent.OCCUPATION_UNDER_YEAR_PRICE_COLUMNS;
    ConstructionObjectDetailComponent.GEOMETRIC_PLAN_PRICE_COLUMNS = [
        { id: 'construction_objects_by_shared_easement', sortable: true }
    ];
    ConstructionObjectDetailComponent.PARCELS_TAB = {
        name: 'Parcely',
        id: 'parcels',
        href: "symap.project.constructionObjects.detail",
        urlParams: { tab: 'parcels' }
    };
    ConstructionObjectDetailComponent.GEOMETRIC_PLANS_TAB = {
        name: 'Služebnosti',
        title: 'Počet GP/počet parcel',
        id: 'easements',
        href: "symap.project.constructionObjects.detail",
        urlParams: { tab: 'easements' }
    };
    ConstructionObjectDetailComponent.TABS_ORDER = ['easements', 'parcels', 'notes', 'history', 'notifications', 'documents'];
    return ConstructionObjectDetailComponent;
}());
exports.ConstructionObjectDetailComponent = ConstructionObjectDetailComponent;
