"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var angular_1 = require("@uirouter/angular");
var geometric_plan_request_model_1 = require("@app/ps/models/geometric-plan-request.model");
var geometric_plan_request_service_1 = require("@app/ps/services/geometric-plan-request.service");
var list_service_1 = require("@app/common/services/list.service");
var auth_service_1 = require("@app/common/services/auth.service");
var class_name_enum_1 = require("@app/dialog/class-name.enum");
var dialog_service_1 = require("@app/dialog/dialog.service");
var geometric_plan_request_edit_component_1 = require("@app/ps/geometric-plans/components/geometric-plan-request-edit/geometric-plan-request-edit.component");
var geometric_plan_request_status_enum_1 = require("@app/ps/enums/geometric-plan-request-status.enum");
var geometric_plan_request_type_enum_1 = require("@app/ps/enums/geometric-plan-request-type.enum");
var parcel_service_1 = require("@app/ps/services/parcel.service");
var table_utils_1 = require("@app/common/utils/table.utils");
var settings_service_1 = require("@app/ps/services/settings.service");
var GeometricPlanRequestDetailComponent = /** @class */ (function () {
    function GeometricPlanRequestDetailComponent(helpIds, requestService, authService, stateService, listService, dialogService, parcelService, settingsService) {
        this.helpIds = helpIds;
        this.requestService = requestService;
        this.authService = authService;
        this.stateService = stateService;
        this.listService = listService;
        this.dialogService = dialogService;
        this.parcelService = parcelService;
        this.settingsService = settingsService;
        this.typeEnum = geometric_plan_request_type_enum_1.GeometricPlanRequestTypeEnum;
        this.statusEnum = geometric_plan_request_status_enum_1.GeometricPlanRequestStatusEnum;
        this.loading = false;
        this.user = this.authService.getUser();
        this.PARCELS_COLUMNS = [
            { id: 'parcel_cell', sortable: false },
            { id: 'title', sortable: false },
            { id: 'area_name', sortable: false },
        ];
        this.STATUS_KEYS_REGEXP = new RegExp(geometric_plan_request_status_enum_1.geometricPlanRequestStatusOptions.map(function (opt) { return opt.id; }).join('|'));
        this.activeTab = this.stateService.params.tab;
        this.isActiveTab = this.isActiveTab.bind(this);
    }
    GeometricPlanRequestDetailComponent.prototype.ngOnInit = function () {
        this.tabs = this.getTabs();
        this.prepareParcelColumns();
        this.loadNotes();
        this.loadHistory();
        this.loadDocuments();
        if (this.geometricPlanRequest.knGp) {
            if (this.geometricPlanRequest.type === geometric_plan_request_type_enum_1.GeometricPlanRequestTypeEnum.EASEMENT_EXTENT) {
                this.loadEasements();
            }
            else {
                this.loadOccupations();
            }
        }
        var parcelsTab = this.tabs.find(function (tab) { return tab.id === 'parcels'; });
        parcelsTab.name = "P\u016Fvodn\u00ED parcely (" + this.geometricPlanRequest.parcels.length + ")";
    };
    GeometricPlanRequestDetailComponent.prototype.onEdit = function (status) {
        var _this = this;
        if (status && !this.requestService.isStatusDialog(status)) {
            this.geometricPlanRequest.status = status;
            return this.requestService.update(this.geometricPlanRequest);
        }
        var dialog = this.dialogService.open(geometric_plan_request_edit_component_1.GeometricPlanRequestEditComponent, {
            data: {
                geometricPlanRequest: __assign({}, this.geometricPlanRequest),
                noteTypes: this.noteTypes,
                editMode: (status ? 'change-status' : 'edit'),
                newStatus: status
            },
            className: class_name_enum_1.ClassName.HIGHER_DIALOG,
        });
        var sub = dialog.afterClosed.subscribe(function (result) {
            sub.unsubscribe();
            if (typeof result === 'object') {
                _this.uploadAndRefresh(status, result.geometricPlanRequest, result.notes, result.gpFiles, result.gpVfkFiles, result.gpDocFiles, result.gpOtherFiles);
            }
            return;
        });
    };
    GeometricPlanRequestDetailComponent.prototype.isActiveTab = function (id) {
        return id === this.activeTab;
    };
    GeometricPlanRequestDetailComponent.prototype.uiOnParamsChanged = function (changedParams) {
        this.activeTab = changedParams.tab;
    };
    GeometricPlanRequestDetailComponent.prototype.onUpdateNoteList = function () {
        var noteTab = this.tabs.find(function (tab) { return tab.id === 'notes'; });
        noteTab.name = "Pozn\u00E1mky (" + this.noteList.list.filter(function (a) { return !a.cancelled; }).length + ")";
    };
    GeometricPlanRequestDetailComponent.prototype.onUpdateHistoryList = function () {
        var _this = this;
        this.historyList.list.forEach(function (note) {
            note.text = note.text.replace(_this.STATUS_KEYS_REGEXP, function (status) { return _this.requestService.getEnumLabel('status', status); });
        });
        var historyTab = this.tabs.find(function (tab) { return tab.id === 'history'; });
        historyTab.name = "Historie (" + this.historyList.itemCount + ")";
    };
    GeometricPlanRequestDetailComponent.prototype.onUpdateDocumentList = function () {
        var documentTab = this.tabs.find(function (tab) { return tab.id === 'documents'; });
        documentTab.name = "Dokumenty (" + this.documentList.list.filter(function (a) { return !a.cancelled; }).length + ")";
    };
    GeometricPlanRequestDetailComponent.prototype.onUpdateParcelList = function () {
        var parcelTab = this.tabs.find(function (tab) { return tab.id === 'parcels-budouc'; });
        var occupationsParIDs = this.occupationList && this.occupationList.list
            ? this.occupationList.list.map(function (occupation) { return occupation.parcel.idpar; })
            : [];
        var easementsParIDs = this.easementList && this.easementList.list
            ? this.easementList.list.map(function (easement) { return easement.parcel.idpar; })
            : [];
        var parcelsIDs = __spread(new Set(__spread(occupationsParIDs, easementsParIDs)));
        parcelTab.name = "Majetkopr\u00E1vn\u011B \u0159e\u0161en\u00E9 parcely (" + parcelsIDs.length + ")";
    };
    GeometricPlanRequestDetailComponent.prototype.canEdit = function () {
        return this.authService.hasPermission('manage_geometric_plans');
    };
    GeometricPlanRequestDetailComponent.prototype.getTabs = function () {
        var tabs = [
            {
                name: 'Původní parcely',
                id: 'parcels',
                href: 'symap.project.geometricPlanRequests.detail',
                urlParams: {
                    id: this.geometricPlanRequest.id,
                    tab: 'parcels',
                },
            },
            {
                name: 'Majetkopávně řešené parcely',
                id: 'parcels-budouc',
                href: 'symap.project.geometricPlanRequests.detail',
                urlParams: {
                    id: this.geometricPlanRequest.id,
                    tab: 'parcels-budouc',
                },
            },
            {
                name: 'Poznámky',
                id: 'notes',
                href: 'symap.project.geometricPlanRequests.detail',
                urlParams: {
                    id: this.geometricPlanRequest.id,
                    tab: 'notes',
                },
            },
            {
                name: 'Historie',
                id: 'history',
                href: 'symap.project.geometricPlanRequests.detail',
                urlParams: {
                    id: this.geometricPlanRequest.id,
                    tab: 'history',
                },
            },
            {
                name: 'Dokumenty',
                id: 'documents',
                href: 'symap.project.geometricPlanRequests.detail',
                urlParams: {
                    id: this.geometricPlanRequest.id,
                    tab: 'documents',
                },
            },
        ];
        if (!this.geometricPlanRequest.knGp) {
            var parcelsBudoucTab = tabs.find(function (tab) { return tab.id === 'parcels-budouc'; });
            tabs.splice(tabs.indexOf(parcelsBudoucTab), 1);
            if (this.activeTab === 'parcels-budouc') {
                this.stateService.go('.', { tab: 'parcels' }, { location: 'replace' });
            }
        }
        return tabs;
    };
    GeometricPlanRequestDetailComponent.prototype.loadNotes = function () {
        var _this = this;
        this.noteList = this.listService.createList('notes', {
            filters: { geometricPlanRequestId: this.geometricPlanRequest.id, systemType: false, cancelled: false, },
            sortOrder: { sortBy: 'timestamp', direction: 'desc' }
        }, undefined);
        return this.listService.fetchResult(this.noteList).then(function () {
            _this.onUpdateNoteList();
        });
    };
    GeometricPlanRequestDetailComponent.prototype.showNextButton = function (nextStatus) {
        if (this.geometricPlanRequest.status === geometric_plan_request_status_enum_1.GeometricPlanRequestStatusEnum.CREATED && nextStatus === geometric_plan_request_status_enum_1.GeometricPlanRequestStatusEnum.IMPORTED) {
            return !!this.user.superuser;
        }
        else if (nextStatus === geometric_plan_request_status_enum_1.GeometricPlanRequestStatusEnum.IMPORTED) {
            return this.authService.hasPermission('admin');
        }
        else {
            return true;
        }
    };
    GeometricPlanRequestDetailComponent.prototype.loadHistory = function () {
        var _this = this;
        this.historyList = this.listService.createList('notes', {
            filters: { geometricPlanRequestId: this.geometricPlanRequest.id, systemType: true },
            sortOrder: { sortBy: 'timestamp', direction: 'desc' }
        }, undefined);
        return this.listService.fetchResult(this.historyList).then(function () {
            _this.onUpdateHistoryList();
        });
    };
    GeometricPlanRequestDetailComponent.prototype.loadDocuments = function () {
        var _this = this;
        this.documentList = this.listService.createList('attachments', {
            filters: { geometricPlanRequestId: this.geometricPlanRequest.id, cancelled: false },
            sortOrder: { sortBy: 'timestamp', direction: 'desc' }
        }, undefined);
        return this.listService.fetchResult(this.documentList).then(function () {
            _this.onUpdateDocumentList();
        });
    };
    GeometricPlanRequestDetailComponent.prototype.loadOccupations = function () {
        var _this = this;
        this.occupationList = this.listService.createList('occupations', {
            limit: undefined,
            filters: {
                knGpId: this.geometricPlanRequest.knGp.id,
                validity: ['valid'],
                loadCollections: [
                    'parcel.knBudouc.currentParcels',
                    'parcel.knBudouc.currentParcels.bpejList',
                    'constructionObjects',
                    'rentalExpert',
                ]
            },
            sortOrder: [{ sortBy: 'parcel.parcisKmen' }, { sortBy: 'parcel.parcisPod' }]
        });
        this.listService.fetchResult(this.occupationList).then(function (data) {
            _this.occupationGroups = _this.parcelService.groupOccupationsByParcel(data.list, []);
            _this.occupationUnderYearTableName = _this.parcelService.getTemporaryOccupationName(_this.occupationGroups.underOneYear);
            _this.occupationOverYearTableName = _this.parcelService.getTemporaryOccupationName(_this.occupationGroups.overOneYear);
            _this.onUpdateParcelList();
        });
    };
    GeometricPlanRequestDetailComponent.prototype.loadEasements = function () {
        var _this = this;
        this.easementList = this.listService.createList('easements', {
            limit: undefined,
            filters: {
                gpId: this.geometricPlanRequest.knGp.id,
                validity: ['valid'],
                loadCollections: ['parcel.bpejList']
            },
            sortOrder: [{ sortBy: 'parcel.parcisKmen' }, { sortBy: 'parcel.parcisPod' }]
        }, undefined, {
            constructionObjects: {
                load: true,
                attributes: {
                    administrator: { load: true },
                }
            }
        });
        this.listService.fetchResult(this.easementList).then(function () {
            _this.onUpdateParcelList();
        });
    };
    GeometricPlanRequestDetailComponent.prototype.uploadAndRefresh = function (status, geometricPlanRequest, notes, gpFiles, gpVfkFiles, gpDocFiles, gpOtherFiles) {
        return __awaiter(this, void 0, void 0, function () {
            var updatetdRequest;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loading = true;
                        return [4 /*yield*/, this.requestService.update(geometricPlanRequest, notes, gpFiles, gpVfkFiles, gpDocFiles, gpOtherFiles)];
                    case 1:
                        updatetdRequest = _a.sent();
                        if (status) {
                            updatetdRequest.lastStatusChangeDate = new Date().toISOString();
                        }
                        this.geometricPlanRequest = updatetdRequest;
                        return [4 /*yield*/, this.loadHistory()];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.loadNotes()];
                    case 3:
                        _a.sent();
                        return [4 /*yield*/, this.loadDocuments()];
                    case 4:
                        _a.sent();
                        this.loading = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    GeometricPlanRequestDetailComponent.prototype.prepareParcelColumns = function () {
        var occupationPermanentColumns = __spread(GeometricPlanRequestDetailComponent.OCCUPATION_PERMANENT_COLUMNS);
        var occupationUnderOneYearColumns = __spread(GeometricPlanRequestDetailComponent.OCCUPATION_UNDER_YEAR_COLUMNS);
        var occupationOverOneYearColumns = __spread(GeometricPlanRequestDetailComponent.OCCUPATION_OVER_YEAR_COLUMNS);
        var easementColumns = __spread(GeometricPlanRequestDetailComponent.EASEMENT_COLUMNS);
        if (!this.hideColumn('permanentOccupationPriceColumns')) {
            occupationPermanentColumns = occupationPermanentColumns.concat(GeometricPlanRequestDetailComponent.OCCUPATION_PERMANENT_PRICE_COLUMNS);
        }
        if (!this.hideColumn('temporaryUnderYearPriceColumns')) {
            occupationUnderOneYearColumns = occupationUnderOneYearColumns.concat(GeometricPlanRequestDetailComponent.OCCUPATION_UNDER_YEAR_PRICE_COLUMNS);
        }
        if (!this.hideColumn('temporaryOverYearPriceColumns')) {
            occupationOverOneYearColumns = occupationOverOneYearColumns.concat(GeometricPlanRequestDetailComponent.OCCUPATION_OVER_YEAR_PRICE_COLUMNS);
        }
        if (!this.hideColumn('easementsPriceColumns') && !this.authService.getActualProject().easementPriceByShare) {
            easementColumns = easementColumns.concat(GeometricPlanRequestDetailComponent.EASEMENT_PRICE_COLUMNS);
        }
        this.occupationPermanentColumns = table_utils_1.TableUtils.repairColumnsStructure(occupationPermanentColumns);
        this.occupationUnderOneYearColumns = table_utils_1.TableUtils.repairColumnsStructure(occupationUnderOneYearColumns);
        this.occupationOverOneYearColumns = table_utils_1.TableUtils.repairColumnsStructure(occupationOverOneYearColumns);
        this.easementColumns = table_utils_1.TableUtils.repairColumnsStructure(easementColumns);
    };
    GeometricPlanRequestDetailComponent.prototype.hideColumn = function (columnID) {
        var tableID = 'parcelDetail'; // @TODO - replace
        return this.settingsService.shouldHideColumn(tableID, columnID);
    };
    GeometricPlanRequestDetailComponent.OCCUPATION_PERMANENT_COLUMNS = [
        'label_gp',
        { id: 'occupation_title', sortable: true },
        { id: 'occupation_parcel_title', sortable: true },
        'occupation_concern_object',
        { id: 'occupation_phase', sortable: true },
        { id: 'occupation_occupation', sortable: true },
        { id: 'occupation_cases', sortable: true },
        'current_parcel_title',
        'current_protection',
        'current_concern_object'
    ];
    GeometricPlanRequestDetailComponent.OCCUPATION_UNDER_YEAR_COLUMNS = [
        'label_gp',
        { id: 'occupation_title', sortable: true },
        { id: 'occupation_parcel_title', sortable: true },
        'occupation_concern_object',
        { id: 'occupation_phase', sortable: true },
        { id: 'occupation_occupation', sortable: true },
        { id: 'occupation_cases', sortable: true },
        'current_parcel_title',
        'current_protection',
        'current_concern_object'
    ];
    GeometricPlanRequestDetailComponent.OCCUPATION_OVER_YEAR_COLUMNS = GeometricPlanRequestDetailComponent.OCCUPATION_UNDER_YEAR_COLUMNS;
    GeometricPlanRequestDetailComponent.EASEMENT_COLUMNS = [
        { id: 'parcel_title', sortable: true },
        { id: 'construction_object', sortable: false },
        { id: 'title', sortable: true },
        { id: 'area', sortable: false },
        { id: 'geometric_plan', sortable: false },
        { id: 'length', sortable: false },
        { id: 'case', sortable: true },
    ];
    GeometricPlanRequestDetailComponent.OCCUPATION_PERMANENT_PRICE_COLUMNS = [
        'occupation_price_parcel',
        'occupation_price_vegetation',
        'occupation_price_construction',
        'occupation_price_summary',
    ];
    GeometricPlanRequestDetailComponent.OCCUPATION_UNDER_YEAR_PRICE_COLUMNS = [
        'occupation_assessment_price',
        'occupation_price_land_price',
        'occupation_price_rent_price_year',
        'occupation_price_rent_length',
        'occupation_price_total_rental_price',
    ];
    GeometricPlanRequestDetailComponent.OCCUPATION_OVER_YEAR_PRICE_COLUMNS = GeometricPlanRequestDetailComponent.OCCUPATION_UNDER_YEAR_PRICE_COLUMNS;
    GeometricPlanRequestDetailComponent.EASEMENT_PRICE_COLUMNS = [
        { id: 'parcel_price', sortable: false },
    ];
    return GeometricPlanRequestDetailComponent;
}());
exports.GeometricPlanRequestDetailComponent = GeometricPlanRequestDetailComponent;
