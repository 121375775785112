"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var map_service_1 = require("@app/map/services/map.service");
var i0 = require("@angular/core");
var i1 = require("./map.service");
var MapReloadLayersService = /** @class */ (function () {
    function MapReloadLayersService(mapService) {
        this.mapService = mapService;
    }
    MapReloadLayersService.prototype.reload = function (mapId, layersIDs) {
        this.mapService.getMap(mapId).then(function (map) {
            map.eachLayer(function (l) {
                if (layersIDs.includes(l._id)) {
                    l.setParams({ random: new Date().getTime() }, false);
                }
            });
        });
    };
    MapReloadLayersService.ngInjectableDef = i0.defineInjectable({ factory: function MapReloadLayersService_Factory() { return new MapReloadLayersService(i0.inject(i1.MapService)); }, token: MapReloadLayersService, providedIn: "root" });
    return MapReloadLayersService;
}());
exports.MapReloadLayersService = MapReloadLayersService;
