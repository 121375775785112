"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var bulk_operation_process_definition_model_1 = require("../../models/bulk-operation-process-definition.model");
var list_service_1 = require("../../../common/services/list.service");
var listTransform_1 = require("../../../../common/services/pure/listTransform");
var _ = require("lodash");
var auth_service_1 = require("../../../common/services/auth.service");
var case_status_service_1 = require("@app/ps/services/case-status.service");
var case_service_1 = require("@app/ps/services/case.service");
var CasesChangeStatusComponent = /** @class */ (function () {
    function CasesChangeStatusComponent(listService, authService, componentFactoryResolver, processDefinition, caseStatusService, restangular, caseService) {
        this.listService = listService;
        this.authService = authService;
        this.componentFactoryResolver = componentFactoryResolver;
        this.processDefinition = processDefinition;
        this.caseStatusService = caseStatusService;
        this.restangular = restangular;
        this.caseService = caseService;
        this.inputData = {};
        this.countResolved = 0;
        this.allowAssignment = false;
        this.interrupt = false;
        this.arrangeCases = this.arrangeCases.bind(this);
        this.setActiveNextStatus = this.setActiveNextStatus.bind(this);
    }
    CasesChangeStatusComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.allowAssignment = this.authService.hasPermission('assignment_edit') && this.authService.hasPermission('assignable');
        this.inputData = this.data.details || {};
        if (!this.inputData.groups) {
            var entityIdFilter = this.data.selectEntities.filters.entityId;
            var filter = {
                limit: null,
                filters: Object.assign(listTransform_1.transformListToFilter({ filters: this.data.selectEntities.filters }).filters, {
                    entityId: {
                        values: entityIdFilter.length && this.data.selectEntities.inverseSelection ?
                            entityIdFilter.filter(function (entityId) { return !_this.data.selectEntities.checkedItems.some(function (entity) { return entity.id === entityId; }); }) :
                            (this.data.selectEntities.checkedItems.map(function (entity) { return entity.id; })),
                        negation: !entityIdFilter.length && this.data.selectEntities.inverseSelection,
                    },
                    loadCollections: ['titles'],
                })
            };
            this.list = this.listService.createList('cases', filter);
            this.listService.fetchResult(this.list);
        }
        else {
            this.countResolved = this.data.selectEntities.count;
            var activeGroup = this.inputData.groups.find(function (group) { return _this.inputData.group && _this.inputData.group.caseStatus.id === group.caseStatus.id; });
            if (activeGroup) {
                this.setActiveNextStatus(activeGroup);
            }
        }
    };
    CasesChangeStatusComponent.prototype.ngOnDestroy = function () {
        this.interrupt = true;
    };
    CasesChangeStatusComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        if (!this.inputData.groups) {
            this.list.promise.then(function (cases) { return __awaiter(_this, void 0, void 0, function () {
                var data;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.arrangeCases(cases)];
                        case 1:
                            data = _a.sent();
                            if (data) {
                                this.inputData.groups = data.groups;
                                this.inputData.noStatusGroup = data.noStatusGroup;
                                this.inputData.allCases = data.allCases;
                            }
                            return [2 /*return*/];
                    }
                });
            }); });
        }
    };
    CasesChangeStatusComponent.prototype.arrangeCases = function (cases) {
        return __awaiter(this, void 0, void 0, function () {
            var e_1, _a, e_2, _b, groups, noStatusGroup, allCases, _c, _d, item, nextCaseStauses, _loop_1, this_1, nextCaseStauses_1, nextCaseStauses_1_1, caseStatus, e_1_1;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        groups = [];
                        noStatusGroup = [];
                        allCases = [];
                        _e.label = 1;
                    case 1:
                        _e.trys.push([1, 6, 7, 8]);
                        _c = __values(cases.list), _d = _c.next();
                        _e.label = 2;
                    case 2:
                        if (!!_d.done) return [3 /*break*/, 5];
                        item = _d.value;
                        if (this.interrupt) {
                            return [2 /*return*/, false];
                        }
                        return [4 /*yield*/, this.loadSetting(item.id)];
                    case 3:
                        nextCaseStauses = _e.sent();
                        this.countResolved++;
                        allCases.push(item);
                        if (nextCaseStauses.length === 0) {
                            noStatusGroup.push(item);
                            return [3 /*break*/, 4];
                        }
                        _loop_1 = function (caseStatus) {
                            var found = groups.find(function (group) { return group.caseStatus.id === caseStatus.id; });
                            if (!found) {
                                var settings = this_1.caseService.getActionDialogues().find(function (button) { return button.caseStatus === caseStatus.key; });
                                found = {
                                    caseStatus: caseStatus,
                                    cases: [],
                                    formComponent: settings ? settings.formComponent || null : undefined,
                                    saveEndpoint: settings ? settings.saveEndpoint : undefined,
                                };
                                groups.push(found);
                            }
                            found.cases.push(item);
                        };
                        this_1 = this;
                        try {
                            for (nextCaseStauses_1 = __values(nextCaseStauses), nextCaseStauses_1_1 = nextCaseStauses_1.next(); !nextCaseStauses_1_1.done; nextCaseStauses_1_1 = nextCaseStauses_1.next()) {
                                caseStatus = nextCaseStauses_1_1.value;
                                _loop_1(caseStatus);
                            }
                        }
                        catch (e_2_1) { e_2 = { error: e_2_1 }; }
                        finally {
                            try {
                                if (nextCaseStauses_1_1 && !nextCaseStauses_1_1.done && (_b = nextCaseStauses_1.return)) _b.call(nextCaseStauses_1);
                            }
                            finally { if (e_2) throw e_2.error; }
                        }
                        _e.label = 4;
                    case 4:
                        _d = _c.next();
                        return [3 /*break*/, 2];
                    case 5: return [3 /*break*/, 8];
                    case 6:
                        e_1_1 = _e.sent();
                        e_1 = { error: e_1_1 };
                        return [3 /*break*/, 8];
                    case 7:
                        try {
                            if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
                        }
                        finally { if (e_1) throw e_1.error; }
                        return [7 /*endfinally*/];
                    case 8: return [2 /*return*/, {
                            groups: groups,
                            noStatusGroup: noStatusGroup,
                            allCases: allCases,
                        }];
                }
            });
        });
    };
    CasesChangeStatusComponent.prototype.loadSetting = function (caseId) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.restangular.one('cases', caseId).one("setting").get()
                            .then(function (data) { return __awaiter(_this, void 0, void 0, function () {
                            var nextStates;
                            var _this = this;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        nextStates = data.nextStates.items;
                                        return [4 /*yield*/, Promise.all(_.map(nextStates, function (stateKey) { return __awaiter(_this, void 0, void 0, function () {
                                                return __generator(this, function (_a) {
                                                    switch (_a.label) {
                                                        case 0: return [4 /*yield*/, this.caseStatusService.loadStatusName(stateKey)];
                                                        case 1: return [2 /*return*/, _a.sent()];
                                                    }
                                                });
                                            }); }))];
                                    case 1: return [2 /*return*/, _a.sent()];
                                }
                            });
                        }); })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    CasesChangeStatusComponent.prototype.setActiveNextStatus = function (group) {
        var _this = this;
        if (group.formComponent === undefined || (this.inputData.group === group && this.statusComponent)) {
            return;
        }
        if (this.inputData.group && this.inputData.group !== group) {
            this.inputData.case = {};
            this.inputData.cases = [];
        }
        this.inputData.case = this.inputData.case || {};
        this.inputData.cases = this.inputData.cases || [];
        this.inputData.group = group;
        this.statusViewContainerRef.clear();
        if (group.formComponent) {
            var factory = this.componentFactoryResolver
                .resolveComponentFactory(group.formComponent);
            this.statusComponent = factory
                .create(this.statusViewContainerRef.parentInjector);
            this.statusViewContainerRef.insert(this.statusComponent.hostView);
            this.statusComponent.instance.data = this.inputData.case;
            this.statusComponent.instance.cases = this.inputData.group.cases;
            this.statusComponent.instance.dataCases = this.inputData.cases;
            this.notifyParent(function () { return _this.statusComponent.instance.isValid() && _this.inputData.group.cases.length; }, this.inputData);
        }
        else {
            this.notifyParent(function () { return _this.inputData.group.cases.length; }, this.inputData);
        }
    };
    return CasesChangeStatusComponent;
}());
exports.CasesChangeStatusComponent = CasesChangeStatusComponent;
