var common = angular.module('common');

common.controller('HelpEditController', ['HelpService', '$scope', function(HelpService, $scope) {
  $scope.disabled = () => {
    return !($scope.help.title && $scope.help.description && $scope.help.url);
  };

  $scope.saveHelp = () => {
    if($scope.disabled()) {
      return false;
    } else {
      return HelpService.saveChanges($scope.help).then(()=>{
        $scope.closeModal();
      });
    }
  };
}]);
