"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var NavbarComponent = /** @class */ (function () {
    function NavbarComponent() {
    }
    NavbarComponent.prototype.ngOnInit = function () { };
    NavbarComponent.prototype.isActive = function (id) {
        return this.isActiveTab(id);
    };
    NavbarComponent.prototype.isDisabled = function (id) {
        return this.disabledTabs && this.disabledTabs.includes(id);
    };
    return NavbarComponent;
}());
exports.NavbarComponent = NavbarComponent;
