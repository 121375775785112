"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".mirrored-list[_ngcontent-%COMP%]   .transfer[_ngcontent-%COMP%] {\n  display: table-cell;\n  float: left;\n  width: 30px;\n  text-align: center;\n  padding-top: 6px;\n  font-size: 18px; }\n\n.mirrored-list[_ngcontent-%COMP%]   .label-selected[_ngcontent-%COMP%], .mirrored-list[_ngcontent-%COMP%]   .label-options[_ngcontent-%COMP%] {\n  color: #909090;\n  text-transform: uppercase;\n  padding-bottom: 10px;\n  padding-left: 5px; }\n\n.mirrored-list[_ngcontent-%COMP%]   .column-selected[_ngcontent-%COMP%], .mirrored-list[_ngcontent-%COMP%]   .column-options[_ngcontent-%COMP%], .mirrored-list[_ngcontent-%COMP%]   .label-selected[_ngcontent-%COMP%], .mirrored-list[_ngcontent-%COMP%]   .label-options[_ngcontent-%COMP%] {\n  width: 50%;\n  box-sizing: border-box; }\n\n.mirrored-list[_ngcontent-%COMP%]   .label-options[_ngcontent-%COMP%] {\n  margin-right: 30px; }\n\n.mirrored-list[_ngcontent-%COMP%]   .item[_ngcontent-%COMP%] {\n  padding: 8px; }\n  .mirrored-list[_ngcontent-%COMP%]   .item[_ngcontent-%COMP%]:not(:last-child) {\n    border-bottom: 1px solid #dddddd; }\n  .mirrored-list[_ngcontent-%COMP%]   .item[_ngcontent-%COMP%]:hover {\n    background-color: #fbfbdb;\n    cursor: pointer; }\n\n.item-checkbox[_ngcontent-%COMP%] {\n  padding-top: 1px;\n  padding-right: 1rem; }"];
exports.styles = styles;
