"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var GeometricPlanRequestTypeEnum;
(function (GeometricPlanRequestTypeEnum) {
    GeometricPlanRequestTypeEnum["PARCEL_SPLIT"] = "PARCEL_SPLIT";
    GeometricPlanRequestTypeEnum["PARCEL_BOUNDARY_SPECIFICATION"] = "PARCEL_BOUNDARY_SPECIFICATION";
    GeometricPlanRequestTypeEnum["EASEMENT_EXTENT"] = "EASEMENT_EXTENT";
    GeometricPlanRequestTypeEnum["PARCEL_BOUNDARY_CHANGE"] = "PARCEL_BOUNDARY_CHANGE";
})(GeometricPlanRequestTypeEnum = exports.GeometricPlanRequestTypeEnum || (exports.GeometricPlanRequestTypeEnum = {}));
exports.geometricPlanRequestTypeOptions = [
    {
        id: GeometricPlanRequestTypeEnum.PARCEL_SPLIT,
        name: 'Rozdělení pozemku',
    },
    {
        id: GeometricPlanRequestTypeEnum.PARCEL_BOUNDARY_SPECIFICATION,
        name: 'Průběh vytyčené nebo vlastníky zpřesněné hranice pozemků',
    },
    {
        id: GeometricPlanRequestTypeEnum.EASEMENT_EXTENT,
        name: 'Vymezení rozsahu věcného břemene k části pozemku',
    },
    {
        id: GeometricPlanRequestTypeEnum.PARCEL_BOUNDARY_CHANGE,
        name: 'Změna hranice pozemku',
    }
];
