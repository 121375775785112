"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var list_service_1 = require("../../services/list.service");
var angular_1 = require("@uirouter/angular");
var PaginationComponent = /** @class */ (function () {
    function PaginationComponent(listService, stateService) {
        var _this = this;
        this.listService = listService;
        this.stateService = stateService;
        this.shouldUpdateUrl = true;
        this.callbackRegister = new core_1.EventEmitter();
        this.pageChanged = new core_1.EventEmitter();
        this.currentPage = 0;
        this.totalPages = 0;
        this.cachedPages = {
            totalPages: 0, currentPage: 0, pages: {}
        };
        this.range = 5;
        this.pages = function () {
            if (_this.cachedPages.totalPages === _this.totalPages && _this.cachedPages.currentPage === _this.currentPage) {
                return _this.cachedPages.pages;
            }
            // if no ellipsis to be shown range is increased
            var currentpages = [];
            var tmpRange = _this.range;
            var start = _this.math.min(_this.currentPage - _this.math.floor(tmpRange / 2), _this.totalPages - _this.range + 1);
            if (start <= 2) {
                start = 2;
            }
            var end = _this.math.min(_this.totalPages - 1, start + tmpRange - 1);
            if (end === _this.totalPages - 1) {
                start = _this.totalPages > tmpRange ? _this.totalPages - tmpRange : start;
            }
            for (; start <= end; start++) {
                currentpages.push(start);
            }
            if (currentpages[0] === 1 && currentpages.length === _this.range) {
                currentpages.splice(0, 1);
            }
            _this.cachedPages.currentPage = _this.currentPage;
            _this.cachedPages.pages = currentpages;
            _this.cachedPages.totalPages = _this.totalPages;
            return _this.cachedPages.pages;
        };
        this.showPage = function (pageNumber) {
            _this.list.filter.offset = (pageNumber - 1) * _this.list.filter.limit;
            //this.listService.fetchResult(this.list);
            //this.onListChanged();
            _this.pageChanged.emit(pageNumber);
        };
        this.shouldUpdateUrl = this.shouldUpdateUrl === true;
        this.math = Math;
        this.onListChanged = this.onListChanged.bind(this);
    }
    PaginationComponent.prototype.ngOnInit = function () {
        this.callbackRegister.emit({
            onListChanged: this.onListChanged.bind(this),
        });
        this.onListChanged();
    };
    PaginationComponent.prototype.onListChanged = function () {
        this.currentPage = this.listService.getCurrentPage(this.list);
        this.totalPages = this.listService.getTotalPages(this.list);
        if (this.shouldUpdateUrl && ((this.totalPages > 0 && this.currentPage > this.totalPages) || parseInt(this.stateService.params.page, 10) !== this.currentPage)) {
            this.stateService.go('.', { page: 1 });
        }
    };
    return PaginationComponent;
}());
exports.PaginationComponent = PaginationComponent;
