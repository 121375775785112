"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var pageable_list_service_1 = require("@app/common/services/pageable.list.service");
var settings_service_1 = require("@app/ps/services/settings.service");
var angular_1 = require("@uirouter/angular");
var auth_service_1 = require("@app/common/services/auth.service");
var construction_object_name_pipe_1 = require("@app/common/pipes/construction-object-name.pipe");
var subject_name_pipe_1 = require("@app/common/pipes/subject-name.pipe");
var username_pipe_1 = require("@app/common/pipes/username.pipe");
var parcel_name_pipe_1 = require("@app/common/pipes/parcel-name.pipe");
var export_list_service_1 = require("@app/ps/services/export-list.service");
var TitleListComponent = /** @class */ (function () {
    function TitleListComponent(helpIds, APP_BRAND, restangularSettings, restangular, pageableListService, settingsService, stateService, authService, constructionObjectNamePipe, subjectNamePipe, userNamePipe, parcelNamePipe, exportListService) {
        this.helpIds = helpIds;
        this.APP_BRAND = APP_BRAND;
        this.restangularSettings = restangularSettings;
        this.restangular = restangular;
        this.pageableListService = pageableListService;
        this.settingsService = settingsService;
        this.stateService = stateService;
        this.authService = authService;
        this.constructionObjectNamePipe = constructionObjectNamePipe;
        this.subjectNamePipe = subjectNamePipe;
        this.userNamePipe = userNamePipe;
        this.parcelNamePipe = parcelNamePipe;
        this.exportListService = exportListService;
        this.statuses = [
            { id: 'resolved', name: 'Uzavřené' },
            { id: 'not_in_progress', name: 'Neřešené' },
            { id: 'in_progress', name: 'Řešené' },
            { id: 'all_signed', name: 'Podepsáno všemi na LV' }
        ];
        this.blockStatuses = [
            { id: true, name: 'Blokováno' },
            { id: false, name: 'Není blokováno' }
        ];
        this.settingsLoaded = false;
        this.exportEnable = true;
        this.registerUpdateStatisticsEntityFilterKey = this.registerUpdateStatisticsEntityFilterKey.bind(this);
        this.onExport = this.onExport.bind(this);
    }
    TitleListComponent.prototype.ngOnInit = function () {
        var _this = this;
        var activeApplication = this.authService.getActiveApplication();
        var activeProjectKey = this.authService.getActualProject().key;
        this.mandataryFilter = {
            filters: {
                searchText: {
                    values: [],
                },
                applications: {
                    values: [activeApplication],
                },
                permission: {
                    values: ['assignable'],
                },
                projects: {
                    values: [activeProjectKey],
                },
            },
            sortOrder: [
                { sortBy: 'surname' },
                { sortBy: 'name' },
            ],
        };
        this.settingsLoaded = this.settingsService.getSettingsLoader() === null;
        if (!this.settingsLoaded) {
            this.settingsService.getSettingsLoader().then(function () { return _this.settingsLoaded = true; });
        }
        var promise = new Promise(function (resolve, reject) {
            _this.deferredUpdateStatistics = {
                resolve: resolve,
                reject: reject,
            };
        });
        this.deferredUpdateStatistics.promise = promise;
        this.initializeList();
    };
    TitleListComponent.prototype.onExport = function () {
        var _this = this;
        this.exportEnable = false;
        var filter = __assign({}, this.list.filter);
        filter.filters.loadCollections.push('cases');
        return this.exportListService
            .exportList('titles', 'titles', filter, { caseMandatary: {} })
            .then(function () {
            new Promise(function (resolve) { return setTimeout(function () { return resolve(); }, 5000); }).then(function () {
                _this.exportEnable = true;
            });
        });
    };
    TitleListComponent.prototype.getDefaultFilter = function () {
        return {
            filters: {
                validity: 'valid',
                occupationOrEasementExists: {},
                searchText: { values: [{ id: '' }] },
                areaId: { values: [], negation: false },
                subjectId: {},
                userId: {},
                statusPermanentOccupation: {},
                statusTemporaryOccupation: {},
                statusEasement: {},
                parcelId: {},
                blockedByUser: {},
                constructionObjectId: { values: [], negation: false },
                caseMandataryId: {},
                titleDefinition: { values: [{ id: '' }] },
                loadCollections: ['ownerships', 'occupationResolution', 'duplicityOwnership'],
            }
        };
    };
    TitleListComponent.prototype.initializeList = function () {
        return __awaiter(this, void 0, void 0, function () {
            var attributes;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.settingsService.getSettingsLoader()];
                    case 1:
                        _a.sent();
                        attributes = undefined;
                        if (!this.hideColumn('mandatary')) {
                            attributes = { caseMandatary: {} };
                        }
                        this.pageableList = this.pageableListService.get('titles', this.getDefaultFilter(), 'titlesFilter', undefined, attributes);
                        if (this.hideColumn('mandatary')) {
                            this.pageableList.list.filter.filters.caseMandataryId = {};
                        }
                        if (this.hideColumn('user')) {
                            this.pageableList.list.filter.filters.userId = {};
                        }
                        this.list = this.pageableList.list;
                        return [4 /*yield*/, this.initStatisticsEntityFilter()];
                    case 2:
                        _a.sent();
                        this.pageableList.load();
                        return [2 /*return*/];
                }
            });
        });
    };
    TitleListComponent.prototype.uiOnParamsChanged = function (changedParams, $transition$) {
        this.pageableList.uiOnParamsChanged(changedParams, $transition$);
    };
    TitleListComponent.prototype.registerUpdateStatisticsEntityFilterKey = function (registerCallback) {
        this.deferredUpdateStatistics.resolve(registerCallback.updateEntityFilterKey);
    };
    TitleListComponent.prototype.initStatisticsEntityFilter = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.stateService.params.sef) return [3 /*break*/, 2];
                        this.list.filter.offset = 0;
                        this.list.filter.filters = this.getDefaultFilter().filters;
                        delete this.list.filter.filters.validity;
                        return [4 /*yield*/, this.deferredUpdateStatistics.promise.then(function (updateEntityFilterKey) {
                                updateEntityFilterKey(_this.stateService.params.sef);
                            })];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        if (this.list.filter.filters.statisticsEntityFilterKey &&
                            this.list.filter.filters.statisticsEntityFilterKey.values &&
                            this.list.filter.filters.statisticsEntityFilterKey.values.length) {
                            delete this.list.filter.filters.validity;
                        }
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    TitleListComponent.prototype.statisticsEntityFilterChanged = function (changed) {
        if (!changed.state) {
            this.list.filter.filters = Object.assign({}, this.getDefaultFilter().filters, this.list.filter.filters);
        }
    };
    TitleListComponent.prototype.checkNeighbors = function () {
        var exists = this.list.filter.filters.occupationOrEasementExists;
        return exists && (!exists.values || exists.values.length === 0);
    };
    TitleListComponent.prototype.onTitlesCheckboxClicked = function () {
        this.list.filter.filters.occupationOrEasementExists = { values: this.checkNeighbors() ? [{ id: true }] : [] };
    };
    TitleListComponent.prototype.hideColumn = function (columnId) {
        return this.settingsService.shouldHideColumn('titles', columnId);
    };
    return TitleListComponent;
}());
exports.TitleListComponent = TitleListComponent;
