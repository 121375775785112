"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var FailedReasonEnum;
(function (FailedReasonEnum) {
    FailedReasonEnum["INTERNAL_ERROR"] = "INTERNAL_ERROR";
    FailedReasonEnum["AREA_TOO_LARGE"] = "AREA_TOO_LARGE";
    FailedReasonEnum["INCOMPLETE_DATA"] = "INCOMPLETE_DATA";
    FailedReasonEnum["NOT_DIGITALIZED_MAP"] = "NOT_DIGITALIZED_MAP";
    FailedReasonEnum["INVALID_GEOMETRY"] = "INVALID_GEOMETRY";
    FailedReasonEnum["INVALID_GEOMETRY_MORE_THAN_ROW"] = "INVALID_GEOMETRY_MORE_THAN_ROW";
    FailedReasonEnum["INVALID_GEOMETRY_MORE_THAN_ONE_GEOMETRY"] = "INVALID_GEOMETRY_MORE_THAN_ONE_GEOMETRY";
    FailedReasonEnum["INVALID_GEOMETRY_HOLE_IN_GEOMETRY"] = "INVALID_GEOMETRY_HOLE_IN_GEOMETRY";
    FailedReasonEnum["INVALID_GEOMETRY_OR_NOT_POLYGON"] = "INVALID_GEOMETRY_OR_NOT_POLYGON";
    FailedReasonEnum["CTIOS_WRONG_CREDENTIALS"] = "CTIOS_WRONG_CREDENTIALS";
    FailedReasonEnum["CTIOS_EXPIRED_CREDENTIALS"] = "CTIOS_EXPIRED_CREDENTIALS";
    FailedReasonEnum["MISSING_SUBJECT"] = "MISSING_SUBJECT";
    FailedReasonEnum["CTIOS_UNAVAILABLE"] = "CTIOS_UNAVAILABLE";
    FailedReasonEnum["PRIDATNE_SPOLUVLASTNICTVI"] = "PRIDATNE_SPOLUVLASTNICTVI";
    FailedReasonEnum["INVALID_GEOMETRY_NARROW"] = "INVALID_GEOMETRY_NARROW";
})(FailedReasonEnum = exports.FailedReasonEnum || (exports.FailedReasonEnum = {}));
