"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var dialog_config_1 = require("@app/dialog/dialog-config");
var dialog_ref_1 = require("@app/dialog/dialog-ref");
var document_type_model_1 = require("../../models/document-type.model");
var DocumentEditFormComponent = /** @class */ (function () {
    function DocumentEditFormComponent(restangular, dialogConfig, dialogComponent) {
        this.restangular = restangular;
        this.dialogConfig = dialogConfig;
        this.dialogComponent = dialogComponent;
        this.onUpdate = this.onUpdate.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }
    DocumentEditFormComponent.prototype.ngOnInit = function () {
        var documentCategory = this.dialogConfig.data.documentCategory;
        this.allowType = this.dialogConfig.data.allowType;
        this.document = this.dialogConfig.data.document;
        this.activeDocumentType = document_type_model_1.DocumentTypeModel.createById(this.document.type);
        this.documentTypes = document_type_model_1.DocumentTypeModel.getByCategory(documentCategory);
    };
    DocumentEditFormComponent.prototype.onUpdateType = function (documentType) {
        this.document.type = documentType.id;
        this.activeDocumentType = documentType;
    };
    DocumentEditFormComponent.prototype.onUpdate = function () {
        var _this = this;
        this.loading = true;
        var restDocument = this.restangular.restangularizeElement(null, this.document, "attachments");
        return restDocument.put().then(function (data) {
            _this.loading = false;
            _this.dialogComponent.close(data.plain());
        });
    };
    DocumentEditFormComponent.prototype.onCancel = function () {
        this.dialogComponent.close(false);
    };
    DocumentEditFormComponent.prototype.isValid = function () {
        return this.document.baseName && this.document.type && (!this.activeDocumentType.extensions || this.activeDocumentType.extensions.includes(this.document.extension));
    };
    return DocumentEditFormComponent;
}());
exports.DocumentEditFormComponent = DocumentEditFormComponent;
