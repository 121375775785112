"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./trend.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/common");
var i3 = require("./trend.component");
var styles_TrendComponent = [i0.styles];
var RenderType_TrendComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TrendComponent, data: {} });
exports.RenderType_TrendComponent = RenderType_TrendComponent;
function View_TrendComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "percentage"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", " %"])), i1.ɵppd(2, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent, 0), _co.trend, "1.2-2")); _ck(_v, 1, 0, currVal_0); }); }
function View_TrendComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "percentage"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", " %"])), i1.ɵppd(2, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent, 0), _co.trend, "1.0-0")); _ck(_v, 1, 0, currVal_0); }); }
function View_TrendComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TrendComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TrendComponent_3)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.trend < 1) && (_co.trend > 0)); _ck(_v, 2, 0, currVal_0); var currVal_1 = ((_co.trend >= 1) || (_co.trend === 0)); _ck(_v, 4, 0, currVal_1); }, null); }
function View_TrendComponent_0(_l) { return i1.ɵvid(2, [i1.ɵpid(0, i2.DecimalPipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "flexbox-container flex-vertical-centered"]], null, null, null, null, null)), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "up-color": 0, "down-color": 1, "stay-color": 2 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TrendComponent_1)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "i", [["class", "fa"]], null, null, null, null, null)), i1.ɵdid(8, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(9, { "fa-arrow-up": 0, "fa-arrow-down": 1, "fa-arrow-right": 2 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "flexbox-container flex-vertical-centered"; var currVal_1 = _ck(_v, 3, 0, (_co.direction === "up"), (_co.direction === "down"), (_co.direction === "stay")); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = !_co.withoutPercentage; _ck(_v, 5, 0, currVal_2); var currVal_3 = "fa"; var currVal_4 = _ck(_v, 9, 0, (_co.direction === "up"), (_co.direction === "down"), (_co.direction === "stay")); _ck(_v, 8, 0, currVal_3, currVal_4); }, null); }
exports.View_TrendComponent_0 = View_TrendComponent_0;
function View_TrendComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "gmt-trend", [], null, null, null, View_TrendComponent_0, RenderType_TrendComponent)), i1.ɵdid(1, 114688, null, 0, i3.TrendComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_TrendComponent_Host_0 = View_TrendComponent_Host_0;
var TrendComponentNgFactory = i1.ɵccf("gmt-trend", i3.TrendComponent, View_TrendComponent_Host_0, { trendData: "trendData", withoutPercentage: "withoutPercentage" }, {}, []);
exports.TrendComponentNgFactory = TrendComponentNgFactory;
