"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var parcel_model_1 = require("@app/common/models/parcel.model");
var parcel_price_model_1 = require("@app/ps/models/parcel-price.model");
var numeric_pipe_1 = require("@app/common/pipes/numeric.pipe");
var opinion_model_1 = require("@app/ps/models/opinion.model");
var PricingExpertOpinionRowComponent = /** @class */ (function () {
    function PricingExpertOpinionRowComponent(numericPipe) {
        this.numericPipe = numericPipe;
        this.computeDefaultParcelPrice = this.computeDefaultParcelPrice.bind(this);
        this.computeCoefficientParcelPrice = this.computeCoefficientParcelPrice.bind(this);
        this.onToggleCompensationCoefficient = this.onToggleCompensationCoefficient.bind(this);
        this.onToggleVegetationCoefficient = this.onToggleVegetationCoefficient.bind(this);
        this.onToggleBuildingCoefficient = this.onToggleBuildingCoefficient.bind(this);
    }
    PricingExpertOpinionRowComponent.prototype.ngOnInit = function () {
    };
    PricingExpertOpinionRowComponent.prototype.isParcelPriceEnabled = function () {
        var hasLand = (this.parcel && !this.parcel.validBuyoutOpinionLand) || !this.opinion.expertOpinionLand;
        var hasVegetation = (this.parcel && !this.parcel.validBuyoutOpinionVegetation) || !this.opinion.expertOpinionVegetation;
        var hasBuilding = (this.parcel && !this.parcel.validBuyoutOpinionBuildings) || !this.opinion.expertOpinionBuildings;
        var isBuilding = this.building && (!this.building.validBuyoutOpinionBuildings || !this.opinion.expertOpinionBuildings);
        return (hasLand && hasVegetation && hasBuilding) || isBuilding;
    };
    PricingExpertOpinionRowComponent.prototype.onToggleBuildingCoefficient = function () {
        this.parcelPrice.expertOpinionBuildingsMultiply = !this.parcelPrice.expertOpinionBuildingsMultiply;
    };
    PricingExpertOpinionRowComponent.prototype.onToggleCompensationCoefficient = function () {
        this.parcelPrice.expertOpinionCompensationMultiply = !this.parcelPrice.expertOpinionCompensationMultiply;
    };
    PricingExpertOpinionRowComponent.prototype.onToggleVegetationCoefficient = function () {
        this.parcelPrice.expertOpinionVegetationMultiply = !this.parcelPrice.expertOpinionVegetationMultiply;
    };
    PricingExpertOpinionRowComponent.prototype.computeDefaultParcelPrice = function () {
        this.parcelPrice.expertOpinionParcelPriceDefault = 0;
        this.computeDefaultLandPrice();
        this.computeDefaultCompensationPrice();
        this.computeDefaultVegetationPrice();
        this.computeDefaultBuildingPrice();
        if (this.hasCoefficient) {
            this.computeCoefficientParcelPrice();
        }
    };
    PricingExpertOpinionRowComponent.prototype.computeCoefficientParcelPrice = function () {
        var coefficient = this.numericPipe.transform(this.parcelPrice.expertOpinionPriceCoefficient);
        var landPrice = this.numericPipe.transform(this.parcelPrice.expertOpinionLandSquarePrice);
        var compensationPrice = this.numericPipe.transform(this.parcelPrice.expertOpinionCompensationPriceDefault);
        var vegetationPrice = this.numericPipe.transform(this.parcelPrice.expertOpinionVegetationPriceDefault);
        var buildingsPrice = this.numericPipe.transform(this.parcelPrice.expertOpinionBuildingsPriceDefault);
        this.parcelPrice.expertOpinionLandPriceCoefficient = Math.round(landPrice * (this.parcel && this.parcel.vymera) * coefficient) || 0;
        this.parcelPrice.expertOpinionCompensationPrice = this.parcelPrice.expertOpinionCompensationMultiply
            ? Math.round(compensationPrice * coefficient) || 0
            : compensationPrice || 0;
        this.parcelPrice.expertOpinionVegetationPrice = this.parcelPrice.expertOpinionVegetationMultiply
            ? Math.round(vegetationPrice * coefficient) || 0
            : vegetationPrice || 0;
        this.parcelPrice.expertOpinionBuildingsPrice = this.parcelPrice.expertOpinionBuildingsMultiply
            ? Math.round(buildingsPrice * coefficient) || 0
            : buildingsPrice || 0;
    };
    PricingExpertOpinionRowComponent.prototype.computeDefaultLandPrice = function () {
        if (this.opinion.expertOpinionLand && this.parcel) {
            this.parcelPrice.expertOpinionParcelPriceDefault += Math.round(this.numericPipe.transform(this.parcelPrice.expertOpinionLandSquarePrice) * this.parcel.vymera);
        }
        else {
            this.parcelPrice.expertOpinionLandSquarePrice = 0;
        }
    };
    PricingExpertOpinionRowComponent.prototype.computeDefaultCompensationPrice = function () {
        if (this.opinion.expertOpinionCompensation) {
            this.parcelPrice.expertOpinionParcelPriceDefault += this.numericPipe.transform(this.parcelPrice.expertOpinionCompensationPriceDefault) || 0;
        }
        else {
            this.parcelPrice.expertOpinionCompensationPriceDefault = 0;
        }
    };
    PricingExpertOpinionRowComponent.prototype.computeDefaultVegetationPrice = function () {
        if (this.opinion.expertOpinionVegetation) {
            this.parcelPrice.expertOpinionParcelPriceDefault += this.numericPipe.transform(this.parcelPrice.expertOpinionVegetationPriceDefault) || 0;
        }
        else {
            this.parcelPrice.expertOpinionVegetationPriceDefault = 0;
        }
    };
    PricingExpertOpinionRowComponent.prototype.computeDefaultBuildingPrice = function () {
        if (this.opinion.expertOpinionBuildings) {
            this.parcelPrice.expertOpinionParcelPriceDefault += this.numericPipe.transform(this.parcelPrice.expertOpinionBuildingsPriceDefault) || 0;
        }
        else {
            this.parcelPrice.expertOpinionBuildingsPriceDefault = 0;
        }
    };
    return PricingExpertOpinionRowComponent;
}());
exports.PricingExpertOpinionRowComponent = PricingExpertOpinionRowComponent;
