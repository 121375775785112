"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var _ = require("lodash");
var auth_service_1 = require("@app/common/services/auth.service");
var class_name_enum_1 = require("@app/dialog/class-name.enum");
var confirmation_component_1 = require("@app/dialog/confirmation/confirmation.component");
var dialog_service_1 = require("@app/dialog/dialog.service");
var list_model_1 = require("@app/common/models/list.model");
var list_service_1 = require("@app/common/services/list.service");
var note_form_component_1 = require("@app/common/components/note-form/note-form.component");
var NotesComponent = /** @class */ (function () {
    function NotesComponent(restangular, authService, listService, dialogService) {
        this.restangular = restangular;
        this.authService = authService;
        this.listService = listService;
        this.dialogService = dialogService;
        // bindings
        this.type = 'notes';
        this.defaultData = {};
        this.updated = new core_1.EventEmitter();
        // fields
        this.scrollTop = function () { };
        this.onEditNote = this.onEditNote.bind(this);
        this.onCanScrollTop = this.onCanScrollTop.bind(this);
        this.onDeleteNote = this.onDeleteNote.bind(this);
        this.onLoadNextNotes = this.onLoadNextNotes.bind(this);
        this.onCancelledCheckboxClicked = this.onCancelledCheckboxClicked.bind(this);
        this.checkCancelled = this.checkCancelled.bind(this);
    }
    NotesComponent.prototype.ngOnInit = function () { };
    NotesComponent.prototype.onEditNote = function (note) {
        var _this = this;
        if (note === void 0) { note = null; }
        var dialog = this.dialogService.open(note_form_component_1.NoteFormComponent, {
            data: {
                allowType: this.allowType,
                note: (note ? __assign({}, note) : __assign({}, this.defaultData)),
                caseId: this.defaultData && this.defaultData.businessCase ? this.defaultData.businessCase.id : undefined,
            },
            className: class_name_enum_1.ClassName.HIGHER_DIALOG,
        });
        var sub = dialog.afterClosed.subscribe(function (result) {
            if (result) {
                Object.keys(_this.defaultData).forEach(function (key) {
                    delete result[key];
                });
                if (note) {
                    var index = _.findIndex(_this.noteList.list, note);
                    _this.noteList.list.splice(index, 1, result);
                }
                else {
                    _this.noteList.list.unshift(result);
                    _this.noteList.itemCount++;
                    _this.scrollTop();
                }
                _this.updated.emit(true);
            }
            sub.unsubscribe();
        });
    };
    NotesComponent.prototype.onDeleteNote = function (note) {
        var _this = this;
        var dialog = this.dialogService.open(confirmation_component_1.ConfirmationComponent, {
            data: {
                msg: 'Opravdu chcete poznámku smazat? Její obnovení nebude možné!',
            },
            className: class_name_enum_1.ClassName.ADJUSTED_DIALOG,
        });
        var sub = dialog.afterClosed.subscribe(function (result) {
            if (result) {
                _this.restangular.one('notes', note.id).remove().then(function () {
                    if (!_this.checkCancelled()) {
                        _this.noteList.list = _.without(_this.noteList.list, note);
                    }
                    else {
                        note.cancelled = true;
                    }
                    _this.noteList.itemCount--;
                    _this.updated.emit(true);
                });
            }
            sub.unsubscribe();
        });
    };
    NotesComponent.prototype.onLoadNextNotes = function () {
        this.noteList.filter.offset += this.noteList.filter.limit;
        this.listService.fetchResult(this.noteList, true);
    };
    NotesComponent.prototype.onCanScrollTop = function (scrollbarEvent) {
        this.scrollTop = scrollbarEvent.scrollTop;
    };
    NotesComponent.prototype.canModify = function (permissions, user) {
        return this.authService.hasPermission(permissions) && this.authService.isAuthorized(user);
    };
    NotesComponent.prototype.onFilterChanged = function () {
        return this.listService.fetchResult(this.noteList);
    };
    NotesComponent.prototype.checkCancelled = function () {
        return this.noteList.filter.filters.cancelled !== false;
    };
    NotesComponent.prototype.onCancelledCheckboxClicked = function () {
        if (this.checkCancelled()) {
            this.noteList.filter.filters.cancelled = false;
        }
        else {
            delete this.noteList.filter.filters.cancelled;
        }
        this.onFilterChanged();
    };
    return NotesComponent;
}());
exports.NotesComponent = NotesComponent;
