"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var MapUtilsCqlFilterService = /** @class */ (function () {
    function MapUtilsCqlFilterService() {
    }
    MapUtilsCqlFilterService.prototype.getFilter = function (filterDef) {
        var filter = '';
        if (filterDef && filterDef.type) {
            switch (filterDef.type) {
                case 'Or':
                    if (filterDef.args && Array.isArray(filterDef.args)) {
                        var orResult = [];
                        filterDef.args.forEach(function (value) {
                            orResult.push(this.getFilter(value));
                        });
                        filter += orResult.join(' OR ');
                    }
                    else {
                        filter += '(' + this.getFilter(filterDef.arg1) + ')';
                        filter += ' OR ';
                        filter += '(' + this.getFilter(filterDef.arg2) + ')';
                    }
                    break;
                case 'And':
                    if (filterDef.args && Array.isArray(filterDef.args)) {
                        var andResult = [];
                        filterDef.args.forEach(function (value) {
                            andResult.push(this.getFilter(value));
                        });
                        filter += andResult.join(' AND ');
                    }
                    else {
                        filter += '(' + this.getFilter(filterDef.arg1) + ')';
                        filter += ' AND ';
                        filter += '(' + this.getFilter(filterDef.arg2) + ')';
                    }
                    break;
                case 'Not':
                    filter += 'NOT (' + this.getFilter(filterDef.arg) + ')';
                    break;
                case 'PropertyIsEqualTo':
                    filter += filterDef.propertyName + '=\'' + filterDef.literal + '\'';
                    break;
                case 'PropertyIsGreaterThan':
                    filter += filterDef.propertyName + '>\'' + filterDef.literal + '\'';
                    break;
                case 'PropertyIsNull':
                    filter += filterDef.propertyName + ' IS NULL';
                    break;
                default:
                    console.log('Unsupported filter type: ' + filterDef.type);
            }
        }
        return filter;
    };
    MapUtilsCqlFilterService.ngInjectableDef = i0.defineInjectable({ factory: function MapUtilsCqlFilterService_Factory() { return new MapUtilsCqlFilterService(); }, token: MapUtilsCqlFilterService, providedIn: "root" });
    return MapUtilsCqlFilterService;
}());
exports.MapUtilsCqlFilterService = MapUtilsCqlFilterService;
