"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var _ = require("lodash");
var dialog_service_1 = require("@app/dialog/dialog.service");
var confirmation_component_1 = require("@app/dialog/confirmation/confirmation.component");
var class_name_enum_1 = require("@app/dialog/class-name.enum");
var list_model_1 = require("@app/common/models/list.model");
var list_service_1 = require("@app/common/services/list.service");
var auth_service_1 = require("@app/common/services/auth.service");
var document_edit_form_component_1 = require("../document-edit-form/document-edit-form.component");
var document_choose_type_form_component_1 = require("@app/common/components/document-choose-type-form/document-choose-type-form.component");
var file_utils_1 = require("@app/common/utils/file.utils");
var document_type_model_1 = require("@app/common/models/document-type.model");
var DocumentsComponent = /** @class */ (function () {
    function DocumentsComponent(restangular, uploadService, listService, authService, dialogService) {
        this.restangular = restangular;
        this.uploadService = uploadService;
        this.listService = listService;
        this.authService = authService;
        this.dialogService = dialogService;
        // bindings
        this.defaultData = {};
        this.draggable = true;
        this.documentCategory = 'case';
        this.updated = new core_1.EventEmitter();
        this.uploadingFiles = [];
        this.onSort = this.onSort.bind(this);
        this.onFileSelect = this.onFileSelect.bind(this);
        this.onFileSelectExternal = this.onFileSelectExternal.bind(this);
        this.onEditDocument = this.onEditDocument.bind(this);
        this.onDeleteDocument = this.onDeleteDocument.bind(this);
        this.onCancelledCheckboxClicked = this.onCancelledCheckboxClicked.bind(this);
        this.checkCancelled = this.checkCancelled.bind(this);
    }
    DocumentsComponent.prototype.ngOnInit = function () {
        if (this.filesSelectSetter) {
            this.filesSelectSetter(this.onFileSelectExternal);
        }
        this.restBaseUrl = this.authService.getActiveApplicationRestUrl();
        this.authToken = this.authService.getToken();
    };
    DocumentsComponent.prototype.onFileSelect = function (files) {
        return __awaiter(this, void 0, void 0, function () {
            var e_1, _a, dialog, sub_1, _b, _c, file, e_1_1;
            var _this = this;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        if (!(files.length === 1)) return [3 /*break*/, 1];
                        dialog = this.dialogService.open(document_choose_type_form_component_1.DocumentChooseTypeFormComponent, {
                            data: {
                                allowType: this.allowType,
                                documentCategory: this.documentCategory,
                                file: files[0],
                                defaultDocumentType: this.defaultDocumentType
                            },
                            className: class_name_enum_1.ClassName.HIGHER_DIALOG,
                        });
                        sub_1 = dialog.afterClosed.subscribe(function (result) {
                            if (typeof result === 'object') {
                                files
                                    .filter(function (file) { return !file.uploading; })
                                    .forEach(function (file) { return _this.startUpload(file, result.type, result.note, result.baseName); });
                            }
                            else {
                                files.splice(0, files.length);
                            }
                            sub_1.unsubscribe();
                        });
                        return [3 /*break*/, 8];
                    case 1:
                        _d.trys.push([1, 6, 7, 8]);
                        _b = __values(files.filter(function (file) { return !file.uploading; })), _c = _b.next();
                        _d.label = 2;
                    case 2:
                        if (!!_c.done) return [3 /*break*/, 5];
                        file = _c.value;
                        return [4 /*yield*/, this.startUpload(file)];
                    case 3:
                        _d.sent();
                        _d.label = 4;
                    case 4:
                        _c = _b.next();
                        return [3 /*break*/, 2];
                    case 5: return [3 /*break*/, 8];
                    case 6:
                        e_1_1 = _d.sent();
                        e_1 = { error: e_1_1 };
                        return [3 /*break*/, 8];
                    case 7:
                        try {
                            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                        }
                        finally { if (e_1) throw e_1.error; }
                        return [7 /*endfinally*/];
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    DocumentsComponent.prototype.onFileSelectExternal = function (files) {
        var extendedFiles = files.map(function (file) {
            return file_utils_1.FileUtils.fileToUpload(file, function () { return true; });
        });
        this.onFileSelect(extendedFiles);
    };
    DocumentsComponent.prototype.onFilterChanged = function () {
        return this.listService.fetchResult(this.documentList);
    };
    DocumentsComponent.prototype.checkCancelled = function () {
        return this.documentList.filter.filters.cancelled !== false;
    };
    DocumentsComponent.prototype.onCancelledCheckboxClicked = function () {
        if (this.checkCancelled()) {
            this.documentList.filter.filters.cancelled = false;
        }
        else {
            delete this.documentList.filter.filters.cancelled;
        }
        this.onFilterChanged();
    };
    DocumentsComponent.prototype.onSort = function (sortValue, sortDir) {
        this.listService.sort(this.documentList, sortValue, sortDir);
    };
    DocumentsComponent.prototype.onEditDocument = function (document) {
        var _this = this;
        var dialog = this.dialogService.open(document_edit_form_component_1.DocumentEditFormComponent, {
            data: {
                allowType: this.allowType,
                document: __assign({}, document),
                documentCategory: this.documentCategory
            },
            className: class_name_enum_1.ClassName.HIGHER_DIALOG,
        });
        var sub = dialog.afterClosed.subscribe(function (result) {
            if (result) {
                var index = _.findIndex(_this.documentList.list, document);
                _this.documentList.list.splice(index, 1, result);
                _this.updated.emit(true);
            }
            sub.unsubscribe();
        });
    };
    DocumentsComponent.prototype.onDeleteDocument = function (document) {
        var _this = this;
        var dialog = this.dialogService.open(confirmation_component_1.ConfirmationComponent, {
            data: {
                msg: 'Opravdu chcete soubor smazat? Jeho obnovení nebude možné!',
            },
            className: class_name_enum_1.ClassName.ADJUSTED_DIALOG,
        });
        var sub = dialog.afterClosed.subscribe(function (result) {
            if (result) {
                _this.restangular.one('attachments', document.id).remove().then(function () {
                    if (!_this.checkCancelled()) {
                        _this.documentList.list = _.without(_this.documentList.list, document);
                    }
                    else {
                        document.cancelled = true;
                    }
                    _this.documentList.itemCount--;
                    _this.updated.emit(true);
                });
            }
            sub.unsubscribe();
        });
    };
    DocumentsComponent.prototype.getDocumentUrl = function (document) {
        return "" + DocumentsComponent.VIEWER_URL + this.restBaseUrl + "/attachments/" + document.id + "/data-pdf%3Ft%3D" + this.authToken;
    };
    DocumentsComponent.prototype.canModify = function (permissions, user) {
        if (user === void 0) { user = undefined; }
        return ((this.authService.hasPermission(permissions) || this.authService.isAuthorized())
            &&
                (!user || this.authService.isAuthorized(user) || this.authorizedUser));
    };
    DocumentsComponent.prototype.startUpload = function (file, type, note, baseName) {
        var _this = this;
        if (type === void 0) { type = ''; }
        if (note === void 0) { note = ''; }
        if (baseName === void 0) { baseName = undefined; }
        // if uploaded from external source (not by file-upload)
        if (this.uploadingFiles.indexOf(file) === -1) {
            this.uploadingFiles.push(file);
        }
        file.progress = 0;
        var sendData = {
            file: file.fileEntry,
            filename: file.fileEntry.name,
            baseName: baseName,
        };
        if (this.defaultData) {
            sendData = __assign({}, sendData, this.defaultData);
        }
        if (type) {
            sendData.attachmentType = type;
        }
        if (note) {
            sendData.note = note;
        }
        file.uploading = this.uploadService.upload({
            url: this.restBaseUrl + '/attachments',
            data: sendData,
            headers: __assign({}, this.restangular.defaultHeaders)
        }).progress(function (event) {
            file.progress = (100.0 * event.loaded / event.total);
        }).success(function (data) {
            _this.uploadingFiles.splice(_this.uploadingFiles.indexOf(file), 1);
            _this.documentList.list.unshift(data);
            _this.documentList.itemCount++;
            _this.updated.emit(true);
        }).error(function () {
            file.error = true;
        });
        return file.uploading;
    };
    DocumentsComponent.VIEWER_URL = _.trimEnd(new URL(window.location.href).pathname, '/') + "/pdf-viewer/web/viewer.html?file=";
    return DocumentsComponent;
}());
exports.DocumentsComponent = DocumentsComponent;
