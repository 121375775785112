"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["[_nghost-%COMP%]   .notes__meta[_ngcontent-%COMP%] {\n  flex: 0 0 20%; }\n\n[_nghost-%COMP%]   .notes[_ngcontent-%COMP%]   ul[_ngcontent-%COMP%]   .notes__item[_ngcontent-%COMP%] {\n  display: flex;\n  align-items: flex-start;\n  flex-wrap: wrap;\n  overflow: auto; }\n  [_nghost-%COMP%]   .notes[_ngcontent-%COMP%]   ul[_ngcontent-%COMP%]   .notes__item[_ngcontent-%COMP%]:hover {\n    background-color: #fbfbdb; }\n    [_nghost-%COMP%]   .notes[_ngcontent-%COMP%]   ul[_ngcontent-%COMP%]   .notes__item[_ngcontent-%COMP%]:hover   .note-actions[_ngcontent-%COMP%] {\n      visibility: visible; }\n  [_nghost-%COMP%]   .notes[_ngcontent-%COMP%]   ul[_ngcontent-%COMP%]   .notes__item[_ngcontent-%COMP%]   .note-actions[_ngcontent-%COMP%] {\n    margin-left: auto;\n    visibility: hidden;\n    text-transform: uppercase; }\n    [_nghost-%COMP%]   .notes[_ngcontent-%COMP%]   ul[_ngcontent-%COMP%]   .notes__item[_ngcontent-%COMP%]   .note-actions[_ngcontent-%COMP%]   div[_ngcontent-%COMP%] {\n      display: inline-block;\n      padding: 3px 5px 3px 0; }"];
exports.styles = styles;
