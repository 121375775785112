"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var callbacks_enum_1 = require("@app/ps/enums/callbacks.enum");
var BulkOperationDetailsComponent = /** @class */ (function () {
    function BulkOperationDetailsComponent(componentFactoryResolver) {
        this.componentFactoryResolver = componentFactoryResolver;
        this.notifyParent = this.notifyParent.bind(this);
    }
    BulkOperationDetailsComponent.prototype.ngOnInit = function () {
        if (this.data.selectAction && this.data.selectAction.component) {
            var factory = this.componentFactoryResolver
                .resolveComponentFactory(this.data.selectAction.component);
            this.detailsComponent = factory
                .create(this.detailsViewContainerRef.parentInjector);
            this.detailsViewContainerRef.insert(this.detailsComponent.hostView);
            this.detailsComponent.instance.data = this.data;
            this.detailsComponent.instance.callbacks = this.callbacks;
            this.detailsComponent.instance.notifyParent = this.notifyParent;
        }
    };
    BulkOperationDetailsComponent.prototype.notifyParent = function (isValid, inputData) {
        this.callbacks.get(callbacks_enum_1.Callbacks.DataValidityChanged)({
            isValid: isValid,
            data: inputData,
        });
    };
    return BulkOperationDetailsComponent;
}());
exports.BulkOperationDetailsComponent = BulkOperationDetailsComponent;
