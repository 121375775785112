"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./customer-edit.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../../../../common/components/back-button/back-button.component.ngfactory");
var i3 = require("../../../../common/components/back-button/back-button.component");
var i4 = require("@angular/common");
var i5 = require("../customer-form/customer-form.component.ngfactory");
var i6 = require("../customer-form/customer-form.component");
var i7 = require("../../../../dialog/dialog.service");
var i8 = require("../../../../common/services/list.service");
var i9 = require("@uirouter/angular");
var i10 = require("../../../../common/pipes/customer-name.pipe");
var i11 = require("./customer-edit.component");
var styles_CustomerEditComponent = [i0.styles];
var RenderType_CustomerEditComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CustomerEditComponent, data: {} });
exports.RenderType_CustomerEditComponent = RenderType_CustomerEditComponent;
function View_CustomerEditComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.customer.companyName; _ck(_v, 1, 0, currVal_0); }); }
function View_CustomerEditComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "detail"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["style", "display:flex"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "line-height-l"], ["style", "min-width: 160px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "gmt-back-button", [["sref", "^"], ["text", "Detail objednatele"]], null, null, null, i2.View_BackButtonComponent_0, i2.RenderType_BackButtonComponent)), i1.ɵdid(5, 114688, null, 0, i3.BackButtonComponent, [], { text: [0, "text"], sref: [1, "sref"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 3, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomerEditComponent_1)), i1.ɵdid(9, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 1, "customer-form", [], null, [[null, "submit"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("submit" === en)) {
        var pd_0 = (_co.onSubmit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_CustomerFormComponent_0, i5.RenderType_CustomerFormComponent)), i1.ɵdid(11, 114688, null, 0, i6.CustomerFormComponent, [i7.DialogService, i8.ListService, i9.StateService, "ConfigRestangularService", i10.CustomerNamePipe], { editable: [0, "editable"], customer: [1, "customer"] }, { submit: "submit" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Detail objednatele"; var currVal_1 = "^"; _ck(_v, 5, 0, currVal_0, currVal_1); var currVal_3 = _co.customer.companyName; _ck(_v, 9, 0, currVal_3); var currVal_4 = true; var currVal_5 = _co.customer; _ck(_v, 11, 0, currVal_4, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.pageTitle; _ck(_v, 7, 0, currVal_2); }); }
exports.View_CustomerEditComponent_0 = View_CustomerEditComponent_0;
function View_CustomerEditComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "customer-edit", [], null, null, null, View_CustomerEditComponent_0, RenderType_CustomerEditComponent)), i1.ɵdid(1, 114688, null, 0, i11.CustomerEditComponent, [i9.StateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_CustomerEditComponent_Host_0 = View_CustomerEditComponent_Host_0;
var CustomerEditComponentNgFactory = i1.ɵccf("customer-edit", i11.CustomerEditComponent, View_CustomerEditComponent_Host_0, { customer: "customer" }, {}, []);
exports.CustomerEditComponentNgFactory = CustomerEditComponentNgFactory;
