"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var i0 = require("@angular/core");
var i1 = require("../sv.modules");
var DocumentTypeFullnamePipe = /** @class */ (function () {
    function DocumentTypeFullnamePipe() {
    }
    DocumentTypeFullnamePipe.prototype.transform = function (value) {
        return value.name + (value.description ? (' - ' + value.description) : '');
    };
    DocumentTypeFullnamePipe.ngInjectableDef = i0.defineInjectable({ factory: function DocumentTypeFullnamePipe_Factory() { return new DocumentTypeFullnamePipe(); }, token: DocumentTypeFullnamePipe, providedIn: i1.SvAppModule });
    return DocumentTypeFullnamePipe;
}());
exports.DocumentTypeFullnamePipe = DocumentTypeFullnamePipe;
