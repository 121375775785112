"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var readiness_state_enum_1 = require("@app/ps/enums/readiness-state.enum");
var i0 = require("@angular/core");
var ReadinessPipe = /** @class */ (function () {
    function ReadinessPipe() {
    }
    ReadinessPipe.prototype.transform = function (readiness, field) {
        if (field === void 0) { field = 'value'; }
        if (!readiness) {
            return '';
        }
        return readiness_state_enum_1.readinessStateOptions.find(function (r) { return r.id === readiness; })[field];
    };
    ReadinessPipe.ngInjectableDef = i0.defineInjectable({ factory: function ReadinessPipe_Factory() { return new ReadinessPipe(); }, token: ReadinessPipe, providedIn: "root" });
    return ReadinessPipe;
}());
exports.ReadinessPipe = ReadinessPipe;
