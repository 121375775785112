"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var i0 = require("@angular/core");
var TitleAreaNamesPipe = /** @class */ (function () {
    function TitleAreaNamesPipe() {
    }
    TitleAreaNamesPipe.prototype.transform = function (titles) {
        return titles.length > 1 ? 'Více K.Ú.' : titles[0].area.name;
    };
    TitleAreaNamesPipe.ngInjectableDef = i0.defineInjectable({ factory: function TitleAreaNamesPipe_Factory() { return new TitleAreaNamesPipe(); }, token: TitleAreaNamesPipe, providedIn: "root" });
    return TitleAreaNamesPipe;
}());
exports.TitleAreaNamesPipe = TitleAreaNamesPipe;
