"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var TooltipEasementPriceComponent = /** @class */ (function () {
    function TooltipEasementPriceComponent() {
    }
    TooltipEasementPriceComponent.prototype.ngOnInit = function () {
        var easementGlobalPriceType = new Set(this.data.easement.constructionObjects.map(function (co) { return co.easementGlobalPriceType; })).size === 1 ?
            this.data.easement.constructionObjects[0].easementGlobalPriceType : null;
        this.easementGlobalPriceType = easementGlobalPriceType === 'I'
            || (easementGlobalPriceType === 'B'
                && this.data.easement.priceType) ? 'I' : easementGlobalPriceType;
    };
    return TooltipEasementPriceComponent;
}());
exports.TooltipEasementPriceComponent = TooltipEasementPriceComponent;
