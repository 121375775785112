"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var post_code_pipe_1 = require("@app/common/pipes/post-code.pipe");
var InputPostCodeDirective = /** @class */ (function () {
    function InputPostCodeDirective(postCodePipe, el) {
        this.postCodePipe = postCodePipe;
        this.el = el;
        this.format = this.format.bind(this);
    }
    InputPostCodeDirective.prototype.ngOnInit = function () {
        var _this = this;
        setTimeout(function () {
            if (_this.el.nativeElement.value !== '') {
                _this.format();
            }
        });
    };
    InputPostCodeDirective.prototype.onBlur = function () {
        this.format();
    };
    InputPostCodeDirective.prototype.format = function () {
        this.el.nativeElement.value = this.postCodePipe.transform(this.el.nativeElement.value);
    };
    return InputPostCodeDirective;
}());
exports.InputPostCodeDirective = InputPostCodeDirective;
