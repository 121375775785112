"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var state_utils_1 = require("@app/common/utils/state.utils");
var title_detail_component_1 = require("@app/dimap/titles/title-detail/title-detail.component");
var title_list_component_1 = require("@app/dimap/titles/title-list/title-list.component");
exports.titlesState = {
    name: 'dimap.project.titles',
    data: {
        title: 'Listy vlastnictví',
    },
    url: '/titles?page',
    params: {
        page: {
            dynamic: true,
            type: 'int',
            inherit: false,
        }
    },
    views: {
        'content@dimap.project': {
            component: title_list_component_1.TitleListComponent,
        }
    }
};
exports.titleDetailState = {
    name: 'dimap.project.titles.detail',
    url: '/:id',
    data: {
        title: 'Detail listu vlastnictví',
    },
    views: {
        'content@dimap.project': {
            component: title_detail_component_1.TitleDetailComponent,
        }
    },
    resolve: {
        titleId: ['$stateParams', state_utils_1.resolveId]
    },
    params: {
        id: {
            type: 'int',
            value: undefined
        },
    },
};
var TitlesModule = /** @class */ (function () {
    function TitlesModule() {
    }
    return TitlesModule;
}());
exports.TitlesModule = TitlesModule;
