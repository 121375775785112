"use strict";
/**
 * Main map configuration
 */
Object.defineProperty(exports, "__esModule", { value: true });
var _cloneDeep = require("lodash/cloneDeep");
var layers = [
    {
        id: 'main',
        type: 'node',
        title: 'Hlavní mapy',
        selector: 'group',
        selectorOrder: 8,
        layers: [
            'base_orto', 'orto_dtm_pak', 'orto_dtm_khk', 'base_zm', 'base_lpis', 'base_history_pardubicky_kraj', 'base_regions', 'base_counties', 'base_municipalities', 'base_areas', 'base_kn', 'base_pk', 'base_hranice_parcel_barevne', 'base_podrobne_body_barevne', 'base_def_budovy'
        ]
    },
    {
        id: 'mm',
        type: 'node',
        title: 'Mobilní mapování',
        selector: 'group',
        layers: [
            'mm_roadway', 'mm_tree_line', 'mm_divisions_a', 'mm_divisions_p', 'mm_divisions_s'
        ]
    },
    {
        id: 'property_info',
        type: 'node',
        title: 'Majetkoprávní stav',
        selector: 'group',
        selectorOrder: 7,
        auth: 'manage_property_status,property_status',
        layers: [
            'mm_spherephoto_rsdpce',
            'management_areas', 'property_info_owned_inside', 'property_info_owned_outside', 'property_info_others_inside', 'property_info_others_outside', 'property_info_geometric_plan',
        ]
    },
    {
        id: 'repairs',
        type: 'node',
        title: 'Hodnocení vozovek',
        selector: 'group',
        selectorOrder: 2,
        auth: 'diagnostics',
        layers: [
            'di_repairs_5', 'di_repairs_4', 'di_repairs_3', 'di_repairs_2', 'di_repairs_1', 'di_repairs_0',
        ]
    },
    {
        id: 'faults',
        type: 'node',
        title: 'Poruchy',
        selector: 'group',
        selectorOrder: 1,
        auth: 'diagnostics',
        layers: [
            'di_faults_12', 'di_faults_11', 'di_faults_10', 'di_faults_9', 'di_faults_8', 'di_faults_7', 'di_faults_6', 'di_faults_5', 'di_faults_4', 'di_faults_3', 'di_faults_2', 'di_faults_1',
        ]
    },
    {
        id: 'km',
        type: 'node',
        title: 'Katastrální mapa',
        selector: 'group',
        selectorOrder: 6,
        auth: 'cadastre',
        layers: [
            'kn_parcels',
        ]
    },
    {
        id: 'inventory',
        type: 'node',
        title: 'Pasport',
        selector: 'group',
        selectorOrder: 5,
        layers: [
            'hfbiz_vertical_road_signs', 'hfbiz_horizontal_road_signs', 'hfbiz_trees', 'hfbiz_zbz'
        ]
    },
    {
        id: 'roads',
        type: 'node',
        title: 'Silnice',
        selector: 'group',
        selectorOrder: 4,
        layers: [
            'di_construction_object', 'di_occupation', 'roads_third_class', 'roads_second_class', 'roads_first_class', 'roads_highway', 'roads_stationing', 'bridges'
        ]
    },
    {
        id: 'diagnostics',
        type: 'node',
        title: 'Panoramatické snímky',
        selector: 'group',
        selectorOrder: 3,
        auth: 'diagnostics',
        layers: [
            'mm_spherephoto',
            'mm_spherephoto_d35_201911',
            'mm_spherephoto_d35_202103',
            'mm_spherephoto_d35_202109',
        ]
    }
];
function getLayersConfig(authService) {
    var tools = ['info' /*, 'cuzk'*/, 'measure'];
    if (authService.hasPermission('property_status,manage_property_status')) {
        tools.push('intersection');
    }
    if (authService.hasPermission('manage_property_status')) {
        tools.push('cutParcels');
    }
    var config = {
        id: 'main-map',
        type: 'node',
        selector: 'hidden',
        tools: tools,
        layers: layers,
        comparator: function (a, b) {
            if (!a.hasOwnProperty("selectorOrder")) {
                return -1;
            }
            if (!b.hasOwnProperty("selectorOrder")) {
                return 1;
            }
            if (a.selectorOrder > b.selectorOrder) {
                return 1;
            }
            else {
                return -1;
            }
        },
    };
    var layersConfig = _cloneDeep(config);
    return layersConfig;
}
exports.getLayersConfig = getLayersConfig;
