"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var CaseOwnerComponent = /** @class */ (function () {
    function CaseOwnerComponent() {
    }
    CaseOwnerComponent.prototype.ngOnInit = function () {
    };
    return CaseOwnerComponent;
}());
exports.CaseOwnerComponent = CaseOwnerComponent;
