"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var i0 = require("@angular/core");
var CustomerContractNamePipe = /** @class */ (function () {
    function CustomerContractNamePipe() {
    }
    CustomerContractNamePipe.prototype.transform = function (contract) {
        return (contract ? contract.subject : '');
    };
    CustomerContractNamePipe.ngInjectableDef = i0.defineInjectable({ factory: function CustomerContractNamePipe_Factory() { return new CustomerContractNamePipe(); }, token: CustomerContractNamePipe, providedIn: "root" });
    return CustomerContractNamePipe;
}());
exports.CustomerContractNamePipe = CustomerContractNamePipe;
