"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var rxjs_1 = require("rxjs");
var i0 = require("@angular/core");
var i1 = require("../../common/services/symap-restangular.service");
var i2 = require("@angular/common/http");
var DataService = /** @class */ (function () {
    // static readonly SOURCE_URL_LIST_RSD = [
    //   'https://mpvold.rsd.cz/public/data/export/maja_dashboard.json',
    // ];
    function DataService(APP_BRAND, restangularSymap, http) {
        this.APP_BRAND = APP_BRAND;
        this.restangularSymap = restangularSymap;
        this.http = http;
    }
    DataService.prototype.getStatisticsData = function () {
        // const urls = (this.APP_BRAND.NAME === 'RSD' ? DataService.SOURCE_URL_LIST_RSD : DataService.SOURCE_URL_LIST);
        var responses = [];
        // for (const url of urls) {
        //   responses.push(this.http.get(url));
        // }
        responses.push(this.restangularSymap.all('dashboard').post());
        return rxjs_1.forkJoin(responses);
    };
    DataService.SOURCE_URL_LIST = [];
    DataService.ngInjectableDef = i0.defineInjectable({ factory: function DataService_Factory() { return new DataService(i0.inject("APP_BRAND"), i0.inject(i1.RESTANGULAR_SYMAP), i0.inject(i2.HttpClient)); }, token: DataService, providedIn: "root" });
    return DataService;
}());
exports.DataService = DataService;
