"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./changelog.component.scss.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/common");
var i3 = require("./changelog.component");
var i4 = require("../../services/list.service");
var i5 = require("../../services/auth.service");
var styles_ChangelogComponent = [i0.styles];
var RenderType_ChangelogComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_ChangelogComponent, data: {} });
exports.RenderType_ChangelogComponent = RenderType_ChangelogComponent;
function View_ChangelogComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "changelog-description"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getPreview(_v.parent.context.$implicit.description); _ck(_v, 0, 0, currVal_0); }); }
function View_ChangelogComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "changelog-container"]], null, [[null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseenter" === en)) {
        var pd_0 = ((_co.collapsed = false) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = ((_co.collapsed = true) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "changelog-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "changelog-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "i", [["class", "fa fa-info-circle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "changelog-name"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", " - ", " "])), i1.ɵppd(6, 2), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "changelog-accept"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onAccept(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "a", [], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "i", [["class", "fa fa-times"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChangelogComponent_3)), i1.ɵdid(11, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = (!_co.collapsed && _v.context.$implicit.description); _ck(_v, 11, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i1.ɵnov(_v.parent.parent, 0), _v.context.$implicit.publishDate, "mediumDate")); var currVal_1 = _v.context.$implicit.name; _ck(_v, 5, 0, currVal_0, currVal_1); }); }
function View_ChangelogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChangelogComponent_2)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.changelogList.list; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ChangelogComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChangelogComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.changelogList.loading && _co.changelogList.itemCount); _ck(_v, 2, 0, currVal_0); }, null); }
exports.View_ChangelogComponent_0 = View_ChangelogComponent_0;
function View_ChangelogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "changelog", [], null, null, null, View_ChangelogComponent_0, RenderType_ChangelogComponent)), i1.ɵdid(1, 114688, null, 0, i3.ChangelogComponent, ["GlobalRestangularSettingsProvider", i4.ListService, i5.AuthService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_ChangelogComponent_Host_0 = View_ChangelogComponent_Host_0;
var ChangelogComponentNgFactory = i1.ɵccf("changelog", i3.ChangelogComponent, View_ChangelogComponent_Host_0, { showCount: "showCount" }, {}, []);
exports.ChangelogComponentNgFactory = ChangelogComponentNgFactory;
