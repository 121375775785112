"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var _ = require("lodash");
var core_2 = require("@uirouter/core");
var list_model_1 = require("../../../common/models/list.model");
var TitlesShortListComponent = /** @class */ (function () {
    function TitlesShortListComponent(transition) {
        this.options = { showOwnerships: false };
        this.groupedTitleList = [];
        this.ownerId = transition.params().ownerId;
    }
    TitlesShortListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.ownershipList.promise.then(function () {
            _this.groupedTitleList = _this.refreshTitleGroups();
        });
    };
    /**
     * Returns titles grouped by cadastral area
     */
    TitlesShortListComponent.prototype.refreshTitleGroups = function () {
        if (this.ownershipList.loading || !this.ownershipList.list) {
            return [];
        }
        var aux = __spread(this.ownershipList.list);
        var titleGroups = [];
        aux = _.sortBy(aux, function (ownership) { return ownership.title.area.name; });
        aux.forEach(function (ownership) {
            var groupIndex = _.findIndex(titleGroups, function (ta) { return ta[0].area.id === ownership.title.area.id; });
            if (groupIndex === -1) {
                titleGroups.push([ownership.title]);
                ownership.title.ownerships.push(ownership);
            }
            else {
                var titleIndex = _.findIndex(titleGroups[groupIndex], function (t) { return t.id === ownership.title.id; });
                if (titleIndex === -1) {
                    titleIndex = titleGroups[groupIndex].length;
                    titleGroups[groupIndex].push(ownership.title);
                }
                titleGroups[groupIndex][titleIndex].ownerships.push(ownership);
            }
        });
        return titleGroups;
    };
    return TitlesShortListComponent;
}());
exports.TitlesShortListComponent = TitlesShortListComponent;
