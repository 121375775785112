"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("@uirouter/angular/lib/directives/uiSref");
var i2 = require("@uirouter/core");
var i3 = require("@angular/common");
var i4 = require("./tooltip-dimap-parcel.component");
var styles_TooltipDimapParcelComponent = [];
var RenderType_TooltipDimapParcelComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TooltipDimapParcelComponent, data: {} });
exports.RenderType_TooltipDimapParcelComponent = RenderType_TooltipDimapParcelComponent;
function View_TooltipDimapParcelComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "li", [["class", "cuzk"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "a", [["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Nahl\u00ED\u017Een\u00ED do katastru"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "http://nahlizenidokn.cuzk.cz/ZobrazObjekt.aspx?&typ=parcela&id=", (_co.data.parcel.idpar || _co.data.parcel.id), ""); _ck(_v, 1, 0, currVal_0); }); }
function View_TooltipDimapParcelComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "li", [["class", "title"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "a", [["uiSref", "dimap.project.titles.detail"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 3).go($event.button, $event.ctrlKey, $event.metaKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 16384, null, 0, i1.AnchorUISref, [i0.ElementRef, i0.Renderer2], null, null), i0.ɵdid(3, 737280, null, 0, i1.UISref, [i2.UIRouter, [2, i1.AnchorUISref], "UIView.PARENT_INJECT"], { state: [0, "state"], params: [1, "params"] }, null), i0.ɵpod(4, { id: 0 }), (_l()(), i0.ɵted(-1, null, ["List vlastnictv\u00ED"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "dimap.project.titles.detail"; var currVal_1 = _ck(_v, 4, 0, (_co.data.parcel.titleid || _co.data.parcel.title.id)); _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
function View_TooltipDimapParcelComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 16, "ul", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 5, "li", [["class", "map"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 4, "a", [["uiSref", "dimap.project.map"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 4).go($event.button, $event.ctrlKey, $event.metaKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(3, 16384, null, 0, i1.AnchorUISref, [i0.ElementRef, i0.Renderer2], null, null), i0.ɵdid(4, 737280, null, 0, i1.UISref, [i2.UIRouter, [2, i1.AnchorUISref], "UIView.PARENT_INJECT"], { state: [0, "state"], params: [1, "params"] }, null), i0.ɵpod(5, { parcelId: 0 }), (_l()(), i0.ɵted(-1, null, ["Zobrazit v mapov\u00E9m okn\u011B"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TooltipDimapParcelComponent_2)), i0.ɵdid(8, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TooltipDimapParcelComponent_3)), i0.ɵdid(10, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(11, 0, null, null, 5, "li", [["class", "parcel"]], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 4, "a", [["uiSref", "dimap.project.parcels.detail"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 14).go($event.button, $event.ctrlKey, $event.metaKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(13, 16384, null, 0, i1.AnchorUISref, [i0.ElementRef, i0.Renderer2], null, null), i0.ɵdid(14, 737280, null, 0, i1.UISref, [i2.UIRouter, [2, i1.AnchorUISref], "UIView.PARENT_INJECT"], { state: [0, "state"], params: [1, "params"] }, null), i0.ɵpod(15, { id: 0 }), (_l()(), i0.ɵted(-1, null, ["Detail parcely"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "dimap.project.map"; var currVal_1 = _ck(_v, 5, 0, (_co.data.parcel.parcelid || _co.data.parcel.id)); _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_2 = (_co.data.parcel.idpar || _co.data.parcel.id); _ck(_v, 8, 0, currVal_2); var currVal_3 = (_co.data.parcel.titleid || _co.data.parcel.title.id); _ck(_v, 10, 0, currVal_3); var currVal_4 = "dimap.project.parcels.detail"; var currVal_5 = _ck(_v, 15, 0, _co.data.parcel.id); _ck(_v, 14, 0, currVal_4, currVal_5); }, null); }
function View_TooltipDimapParcelComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Parcela nenalezena v KN"]))], null, null); }
function View_TooltipDimapParcelComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_TooltipDimapParcelComponent_1)), i0.ɵdid(1, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TooltipDimapParcelComponent_4)), i0.ɵdid(3, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.data == null) ? null : _co.data.parcel); _ck(_v, 1, 0, currVal_0); var currVal_1 = (!_co.data || !_co.data.parcel); _ck(_v, 3, 0, currVal_1); }, null); }
exports.View_TooltipDimapParcelComponent_0 = View_TooltipDimapParcelComponent_0;
function View_TooltipDimapParcelComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tooltip-dimap-parcel", [], null, null, null, View_TooltipDimapParcelComponent_0, RenderType_TooltipDimapParcelComponent)), i0.ɵdid(1, 49152, null, 0, i4.TooltipDimapParcelComponent, [], null, null)], null, null); }
exports.View_TooltipDimapParcelComponent_Host_0 = View_TooltipDimapParcelComponent_Host_0;
var TooltipDimapParcelComponentNgFactory = i0.ɵccf("tooltip-dimap-parcel", i4.TooltipDimapParcelComponent, View_TooltipDimapParcelComponent_Host_0, { data: "data" }, {}, []);
exports.TooltipDimapParcelComponentNgFactory = TooltipDimapParcelComponentNgFactory;
