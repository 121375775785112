"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var auth_service_1 = require("@app/common/services/auth.service");
var angular_1 = require("@uirouter/angular");
var ticket_service_1 = require("@app/sv/services/ticket.service");
var ticket_status_enum_1 = require("@app/sv/enums/ticket-status.enum");
var class_name_enum_1 = require("@app/dialog/class-name.enum");
var dialog_service_1 = require("@app/dialog/dialog.service");
var ticket_edit_component_1 = require("@app/sv/components/ticket-edit/ticket-edit.component");
var sample_service_1 = require("@app/sv/services/sample.service");
var list_service_1 = require("@app/common/services/list.service");
var TicketDetailComponent = /** @class */ (function () {
    function TicketDetailComponent(helpIds, restangular, errorHandlerService, ticketService, listService, sampleService, authService, stateService, dialogService) {
        this.helpIds = helpIds;
        this.restangular = restangular;
        this.errorHandlerService = errorHandlerService;
        this.ticketService = ticketService;
        this.listService = listService;
        this.sampleService = sampleService;
        this.authService = authService;
        this.stateService = stateService;
        this.dialogService = dialogService;
        this.loading = true;
        this.ticketStatusEnum = ticket_status_enum_1.TicketStatusEnum;
        this.restBaseUrl = this.authService.getActiveApplicationRestUrl();
        this.authToken = this.authService.getToken();
        this.tabs = [
            {
                name: 'Poznámky',
                id: 'notes',
                href: "sv.tickets.detail",
                urlParams: { tab: 'notes' },
            },
            {
                name: 'Historie',
                id: 'history',
                href: "sv.tickets.detail",
                urlParams: { tab: 'history' },
            },
            {
                name: 'Dokumenty',
                id: 'documents',
                href: "sv.tickets.detail",
                urlParams: { tab: 'documents' },
            },
        ];
        this.tab = (this.stateService.params.tab ? this.stateService.params.tab : 'notes');
        this.isActiveTab = this.isActiveTab.bind(this);
        this.onDocumentsSort = this.onDocumentsSort.bind(this);
        this.isActionButtonVisible = this.isActionButtonVisible.bind(this);
    }
    TicketDetailComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.loadTicket()];
                    case 1:
                        _a.sent();
                        this.loadDocuments();
                        this.loadNotes();
                        this.loadHistory();
                        this.loading = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    TicketDetailComponent.prototype.onEdit = function (status) {
        var _this = this;
        var dialog = this.dialogService.open(ticket_edit_component_1.TicketEditComponent, {
            data: {
                ticket: __assign({}, this.ticket),
                newStatus: status
            },
            className: class_name_enum_1.ClassName.HIGHER_DIALOG,
        });
        var sub = dialog.afterClosed.subscribe(function (result) {
            sub.unsubscribe();
            if (typeof result === 'object') {
                _this.uploadAndRefresh(result.ticket, result.templateFiles, result.note);
            }
            return;
        });
    };
    TicketDetailComponent.prototype.isActiveTab = function (id) {
        return id === this.tab;
    };
    TicketDetailComponent.prototype.uiOnParamsChanged = function (changedParams) {
        this.tab = changedParams.tab;
    };
    TicketDetailComponent.prototype.isActionButtonVisible = function (nextStatus) {
        switch (nextStatus) {
            case ticket_status_enum_1.TicketStatusEnum.APPROVED:
            case ticket_status_enum_1.TicketStatusEnum.DISAPPROVED:
                return this.authService.hasPermissionOnProject('template_aproval', this.ticket.organizationalUnitCode);
            case ticket_status_enum_1.TicketStatusEnum.TEMPLATE_SENT_TO_PROCESSING:
            case ticket_status_enum_1.TicketStatusEnum.IMPORTED:
            case ticket_status_enum_1.TicketStatusEnum.DELETED:
                return this.authService.hasPermissionOnProject('template_manage', this.ticket.organizationalUnitCode);
            default:
                return false;
        }
    };
    TicketDetailComponent.prototype.onUpdateDocumentList = function () {
        var documentTab = this.tabs.find(function (tab) { return tab.id === 'documents'; });
        documentTab.name = "Dokumenty (" + this.documentList.list.filter(function (a) { return !a.cancelled; }).length + ")";
    };
    TicketDetailComponent.prototype.onUpdateNoteList = function () {
        var noteTab = this.tabs.find(function (tab) { return tab.id === 'notes'; });
        noteTab.name = "Pozn\u00E1mky (" + this.noteList.list.filter(function (a) { return !a.cancelled; }).length + ")";
    };
    TicketDetailComponent.prototype.onUpdateHistoryList = function () {
        var historyTab = this.tabs.find(function (tab) { return tab.id === 'history'; });
        historyTab.name = "Historie (" + this.historyList.itemCount + ")";
    };
    TicketDetailComponent.prototype.onDocumentsSort = function (sortValue, sortDir) {
        this.listService.sort(this.documentList, sortValue, sortDir);
    };
    TicketDetailComponent.prototype.canEditNote = function () {
        return this.authService.hasPermissionOnProject('template_view', this.ticket.organizationalUnitCode);
    };
    TicketDetailComponent.prototype.loadTicket = function () {
        var _this = this;
        return this.ticketService.getById(this.ticketId).then(function (ticket) {
            _this.ticket = ticket;
        });
    };
    TicketDetailComponent.prototype.loadDocuments = function () {
        var _this = this;
        this.documentList = this.listService.createList('attachments', {
            filters: { ticketId: this.ticket.id, attachmentType: { values: ['SAMPLE'] }, cancelled: false },
            sortOrder: { sortBy: 'timestamp', direction: 'desc' }
        });
        this.listService.fetchResult(this.documentList).then(function () {
            _this.onUpdateDocumentList();
        });
    };
    TicketDetailComponent.prototype.loadHistory = function () {
        var _this = this;
        this.historyList = this.listService.createList('notes', {
            filters: { ticketId: this.ticketId, systemType: true },
            sortOrder: { sortBy: 'timestamp', direction: 'desc' }
        });
        this.listService.fetchResult(this.historyList).then(function () {
            _this.onUpdateHistoryList();
        });
    };
    TicketDetailComponent.prototype.loadNotes = function () {
        var _this = this;
        this.noteList = this.listService.createList('notes', {
            filters: { ticketId: this.ticketId, systemType: false, cancelled: false, },
            sortOrder: { sortBy: 'timestamp', direction: 'desc' }
        });
        this.listService.fetchResult(this.noteList).then(function () {
            _this.onUpdateNoteList();
        });
    };
    TicketDetailComponent.prototype.uploadAndRefresh = function (ticket, templateFiles, note) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, e_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 7, 8, 9]);
                        this.loading = true;
                        _a = this;
                        return [4 /*yield*/, this.ticketService.update(ticket, templateFiles, note)];
                    case 1:
                        _a.ticket = _b.sent();
                        if (!(ticket.status === ticket_status_enum_1.TicketStatusEnum.IMPORTED)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.sampleService.createByTicket(this.ticket)];
                    case 2:
                        _b.sent();
                        _b.label = 3;
                    case 3: return [4 /*yield*/, this.loadDocuments()];
                    case 4:
                        _b.sent();
                        return [4 /*yield*/, this.loadNotes()];
                    case 5:
                        _b.sent();
                        return [4 /*yield*/, this.loadHistory()];
                    case 6:
                        _b.sent();
                        return [3 /*break*/, 9];
                    case 7:
                        e_1 = _b.sent();
                        (new this.errorHandlerService())(e_1);
                        return [3 /*break*/, 9];
                    case 8:
                        this.loading = false;
                        return [7 /*endfinally*/];
                    case 9: return [2 /*return*/];
                }
            });
        });
    };
    return TicketDetailComponent;
}());
exports.TicketDetailComponent = TicketDetailComponent;
