"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./settlement-progress-chart.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/common");
var i3 = require("../../../common/directives/tooltip/tooltip.directive");
var i4 = require("./settlement-progress-chart.component");
var i5 = require("../../pipes/settlement-category.pipe");
var styles_SettlementProgressChartComponent = [i0.styles];
var RenderType_SettlementProgressChartComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SettlementProgressChartComponent, data: {} });
exports.RenderType_SettlementProgressChartComponent = RenderType_SettlementProgressChartComponent;
function View_SettlementProgressChartComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["class", "chart chart--onhold"], ["title", "Parcely k \u0159e\u0161en\u00ED"]], [[4, "width", "%"]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "chart__number"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((((_co.data == null) ? null : _co.data.notStartedParcelIds.length) / ((((_co.data == null) ? null : _co.data.notStartedParcelIds.length) + ((_co.data == null) ? null : _co.data.inProgressParcelIds.length)) + ((_co.data == null) ? null : _co.data.doneParcelIds.length))) * 95); _ck(_v, 0, 0, currVal_0); var currVal_1 = ((_co.data == null) ? null : _co.data.notStartedParcelIds.length); _ck(_v, 2, 0, currVal_1); }); }
function View_SettlementProgressChartComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["class", "chart chart--progress"], ["title", "\u0158e\u0161en\u00E9 parcely"]], [[4, "width", "%"]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "chart__number"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((((_co.data == null) ? null : _co.data.inProgressParcelIds.length) / ((((_co.data == null) ? null : _co.data.notStartedParcelIds.length) + ((_co.data == null) ? null : _co.data.inProgressParcelIds.length)) + ((_co.data == null) ? null : _co.data.doneParcelIds.length))) * 95); _ck(_v, 0, 0, currVal_0); var currVal_1 = ((_co.data == null) ? null : _co.data.inProgressParcelIds.length); _ck(_v, 2, 0, currVal_1); }); }
function View_SettlementProgressChartComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["class", "chart chart--done"], ["title", "Vy\u0159e\u0161en\u00E9 parcely"]], [[4, "width", "%"]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "chart__number"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((((_co.data == null) ? null : _co.data.doneParcelIds.length) / ((((_co.data == null) ? null : _co.data.notStartedParcelIds.length) + ((_co.data == null) ? null : _co.data.inProgressParcelIds.length)) + ((_co.data == null) ? null : _co.data.doneParcelIds.length))) * 95); _ck(_v, 0, 0, currVal_0); var currVal_1 = ((_co.data == null) ? null : _co.data.doneParcelIds.length); _ck(_v, 2, 0, currVal_1); }); }
function View_SettlementProgressChartComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "chart--no-data"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["-"]))], null, null); }
function View_SettlementProgressChartComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "fill-content flexbox-container flex-centered p-10"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SettlementProgressChartComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SettlementProgressChartComponent_3)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SettlementProgressChartComponent_4)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SettlementProgressChartComponent_5)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_co.data == null) ? null : _co.data.notStartedParcelIds.length) > 0); _ck(_v, 2, 0, currVal_0); var currVal_1 = (((_co.data == null) ? null : _co.data.inProgressParcelIds.length) > 0); _ck(_v, 4, 0, currVal_1); var currVal_2 = (((_co.data == null) ? null : _co.data.doneParcelIds.length) > 0); _ck(_v, 6, 0, currVal_2); var currVal_3 = (!_co.data || (((((_co.data == null) ? null : _co.data.notStartedParcelIds.length) + ((_co.data == null) ? null : _co.data.inProgressParcelIds.length)) + ((_co.data == null) ? null : _co.data.doneParcelIds.length)) === 0)); _ck(_v, 8, 0, currVal_3); }, null); }
function View_SettlementProgressChartComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_SettlementProgressChartComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SettlementProgressChartComponent_7)), i1.ɵdid(2, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = i1.ɵnov(_v.parent, 0); _ck(_v, 2, 0, currVal_0); }, null); }
function View_SettlementProgressChartComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_SettlementProgressChartComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 3, "div", [["class", "tooltip-hover-area fill-content"]], null, [[null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("mouseenter" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).mouseEnter() !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).closeTooltip() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 81920, null, 0, i3.TooltipDirective, [i1.ElementRef, "WindowDimensionService", i1.Renderer2, i1.ComponentFactoryResolver, i1.ViewContainerRef, i1.Injector], { content: [0, "content"], data: [1, "data"], activeContent: [2, "activeContent"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SettlementProgressChartComponent_9)), i1.ɵdid(3, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tooltipComponent; var currVal_1 = _co.tooltipDataResult; var currVal_2 = true; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = i1.ɵnov(_v.parent, 0); _ck(_v, 3, 0, currVal_3); }, null); }
function View_SettlementProgressChartComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(0, [["progressBar", 2]], null, 0, null, View_SettlementProgressChartComponent_1)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SettlementProgressChartComponent_6)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SettlementProgressChartComponent_8)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((!_co.tooltipComponent || !_co.tooltipDataResult) || (((((_co.data == null) ? null : _co.data.notStartedParcelIds.length) === 0) && (((_co.data == null) ? null : _co.data.inProgressParcelIds.length) === 0)) && (((_co.data == null) ? null : _co.data.doneParcelIds.length) === 0))); _ck(_v, 2, 0, currVal_0); var currVal_1 = ((_co.tooltipComponent && _co.tooltipDataResult) && (((((_co.data == null) ? null : _co.data.notStartedParcelIds.length) > 0) || (((_co.data == null) ? null : _co.data.inProgressParcelIds.length) > 0)) || (((_co.data == null) ? null : _co.data.doneParcelIds.length) > 0))); _ck(_v, 4, 0, currVal_1); }, null); }
exports.View_SettlementProgressChartComponent_0 = View_SettlementProgressChartComponent_0;
function View_SettlementProgressChartComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "settlement-progress-chart", [], null, null, null, View_SettlementProgressChartComponent_0, RenderType_SettlementProgressChartComponent)), i1.ɵdid(1, 114688, null, 0, i4.SettlementProgressChartComponent, [i5.SettlementCategoryPipe], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_SettlementProgressChartComponent_Host_0 = View_SettlementProgressChartComponent_Host_0;
var SettlementProgressChartComponentNgFactory = i1.ɵccf("settlement-progress-chart", i4.SettlementProgressChartComponent, View_SettlementProgressChartComponent_Host_0, { tooltipData: "tooltipData", tooltipType: "tooltipType", data: "data" }, {}, []);
exports.SettlementProgressChartComponentNgFactory = SettlementProgressChartComponentNgFactory;
