"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var auth_service_1 = require("@app/common/services/auth.service");
var login_service_1 = require("@app/common/services/login.service");
var menuConfig_1 = require("@app/models/menuConfig");
var brand_translate_pipe_1 = require("@app/common/pipes/brand-translate.pipe");
var MainComponent = /** @class */ (function () {
    function MainComponent(authService, loginService, brandTranslatePipe, ENV, applications, localStorageService, modulesService, approveTermsService, changePasswordService, googleAnalyticsService) {
        this.authService = authService;
        this.loginService = loginService;
        this.brandTranslatePipe = brandTranslatePipe;
        this.ENV = ENV;
        this.applications = applications;
        this.localStorageService = localStorageService;
        this.modulesService = modulesService;
        this.approveTermsService = approveTermsService;
        this.changePasswordService = changePasswordService;
        this.googleAnalyticsService = googleAnalyticsService;
        this.collapsed = false;
        this.menuConfig = [];
    }
    MainComponent.prototype.ngOnInit = function () {
        this.authService.setActiveApplication(this.applications.dashboard.name);
        this.authService.updateBaseUrl();
        this.authService.updateAuthorizationHeader();
        this.googleAnalyticsService.onUserCome();
        this.collapsed = this.localStorageService.get('menuOpened');
        this.user = this.authService.getUser();
        this.servicedeskUrl = this.authService.getServiceDeskUrl();
        this.menuConfig = this.getMenuConfig();
    };
    MainComponent.prototype.onCollapse = function () {
        this.collapsed = !this.collapsed;
        this.localStorageService.set('menuOpened', this.collapsed);
    };
    MainComponent.prototype.onLogout = function () {
        this.authService.logout();
    };
    MainComponent.prototype.getMenuConfig = function () {
        return [
            new menuConfig_1.MenuConfig(this.brandTranslatePipe.transform('dashboard.name'), 'dashboard.projects', 'fa-folder-open'),
        ];
    };
    return MainComponent;
}());
exports.MainComponent = MainComponent;
