"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.expertModelDefault = {
    cisloDomovni: undefined,
    cisloOrientacni: undefined,
    id: undefined,
    jmeno: undefined,
    obec: undefined,
    prijmeni: undefined,
    psc: undefined,
    titulPredJmenem: undefined,
    titulZaJmenem: undefined,
    ulice: undefined,
};
