"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var _ = require("lodash");
var pricing_expert_opinion_step_component_1 = require("@app/ps/titles/components/pricing-expert-opinion-step/pricing-expert-opinion-step.component");
var auth_service_1 = require("@app/common/services/auth.service");
var checklist_service_1 = require("@app/common/services/checklist.service");
var numeric_pipe_1 = require("@app/common/pipes/numeric.pipe");
var parcel_utils_1 = require("@app/common/utils/parcel.utils");
var PricingExpertOpinionParcelsComponent = /** @class */ (function (_super) {
    __extends(PricingExpertOpinionParcelsComponent, _super);
    function PricingExpertOpinionParcelsComponent(restangular, numericPipe, checklistService, authService) {
        var _this = _super.call(this) || this;
        _this.restangular = restangular;
        _this.numericPipe = numericPipe;
        _this.checklistService = checklistService;
        _this.authService = authService;
        _this.COEFFICIENT_1_15 = 1.15;
        _this.COEFFICIENT_1_0 = 1;
        _this.COEFFICIENT_1_5 = 1.5;
        _this.COEFFICIENT_8 = 8;
        _this.hasCoefficient = _this.authService.getActualProject().showExpertOpinionCoefficient;
        _this.checkedAll = false;
        _this.sortOrder = {};
        _this.pricingMethodOptions = [
            { id: 'M', value: 'Cena za metr čtvereční pozemku' },
            { id: 'A', value: 'Cena za pozemek' },
        ];
        _this.onSubmit = _this.onSubmit.bind(_this);
        _this.onBack = _this.onBack.bind(_this);
        return _this;
    }
    PricingExpertOpinionParcelsComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.data.updated = false;
                        this.data.opinion.expertOpinionPricingMethod = this.data.opinion.expertOpinionPricingMethod || 'M';
                        if (!!this.data.parcelPrices) return [3 /*break*/, 5];
                        this.setupFilters();
                        if (!this.data.opinion.id) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.loadOpinionPricing()];
                    case 1:
                        _a = _b.sent();
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.loadParcelPrices()];
                    case 3:
                        _a = _b.sent();
                        _b.label = 4;
                    case 4:
                        _a;
                        _b.label = 5;
                    case 5:
                        this.data.parcelPrices.forEach(function (parcelPrice) {
                            parcelPrice.expertOpinionLandAreaPrice = Math.round(parcelPrice.parcel && parcelPrice.parcel.vymera
                                ? _this.numericPipe.transform(parcelPrice.expertOpinionLandSquarePrice) * parcelPrice.parcel.vymera
                                : 0);
                        });
                        this.loading = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    PricingExpertOpinionParcelsComponent.prototype.isFormValid = function () {
        return this.data.readonly || (this.data.opinion.parcelPrices.length && this.data.opinion.parcelPrices.every(this.isPriceRowValid, this));
    };
    PricingExpertOpinionParcelsComponent.prototype.onBack = function () {
        this.backCallback.emit();
    };
    PricingExpertOpinionParcelsComponent.prototype.onSubmit = function () {
        this.submitCallback.emit();
    };
    PricingExpertOpinionParcelsComponent.prototype.checkAll = function () {
        var _this = this;
        this.checkedAll = !this.checkedAll;
        this.data.opinion.parcelPrices.splice(0);
        if (!this.checkedAll) {
            return;
        }
        this.data.parcelPrices
            .filter(this.isParcelPriceEnabled, this)
            .forEach(function (item) {
            _this.data.opinion.parcelPrices.push(item);
        });
    };
    PricingExpertOpinionParcelsComponent.prototype.toggleParcelPrice = function (parcelPrice) {
        if (this.isParcelPriceEnabled(parcelPrice)) {
            this.data.checklistParcelPrices.toggleSelection(parcelPrice);
        }
        this.checkedAll = (this.data.checklistParcelPrices.checkedItems.length === this.data.parcelPrices.filter(this.isParcelPriceEnabled, this).length);
    };
    PricingExpertOpinionParcelsComponent.prototype.isParcelPriceEnabled = function (parcelPrice) {
        var hasLand = (parcelPrice.parcel && !parcelPrice.parcel.validBuyoutOpinionLand) || !this.data.opinion.expertOpinionLand;
        var hasVegetation = (parcelPrice.parcel && !parcelPrice.parcel.validBuyoutOpinionVegetation) || !this.data.opinion.expertOpinionVegetation;
        var hasBuilding = (parcelPrice.parcel && !parcelPrice.parcel.validBuyoutOpinionBuildings) || !this.data.opinion.expertOpinionBuildings;
        var isBuilding = parcelPrice.building && (!parcelPrice.building.validBuyoutOpinionBuildings || !this.data.opinion.expertOpinionBuildings);
        return (hasLand && hasVegetation && hasBuilding) || isBuilding;
    };
    PricingExpertOpinionParcelsComponent.prototype.isPriceRowValid = function (p) {
        var isLandPriceValid = ((p.expertOpinionLandSquarePrice || p.expertOpinionLandSquarePrice === 0) && this.data.opinion.expertOpinionLand) || !this.data.opinion.expertOpinionLand || !p.parcel;
        var isVegetationPriceValid = ((p.expertOpinionVegetationPriceDefault || p.expertOpinionVegetationPriceDefault === 0) && this.data.opinion.expertOpinionVegetation) || !this.data.opinion.expertOpinionVegetation || !p.parcel;
        var isBuildingPriceValid = ((p.expertOpinionBuildingsPriceDefault || p.expertOpinionBuildingsPriceDefault === 0) && this.data.opinion.expertOpinionBuildings) || !this.data.opinion.expertOpinionBuildings;
        var isCompensationPriceValid = ((p.expertOpinionCompensationPriceDefault || p.expertOpinionCompensationPriceDefault === 0) && this.data.opinion.expertOpinionCompensation) || !this.data.opinion.expertOpinionCompensation;
        var isCoefficientValid = (!this.hasCoefficient
            || p.isCoefficientSet
            || ((!this.data.opinion.expertOpinionLand || p.parcel === undefined)
                && (!p.expertOpinionBuildingsMultiply || !this.data.opinion.expertOpinionBuildings)
                && (!p.expertOpinionCompensationMultiply || !this.data.opinion.expertOpinionCompensation)
                && (!p.expertOpinionVegetationMultiply || !this.data.opinion.expertOpinionVegetation)));
        if (this.data.occupationType === 'P') {
            return isLandPriceValid && isVegetationPriceValid && isBuildingPriceValid && isCoefficientValid;
        }
        return isCompensationPriceValid && isCoefficientValid;
    };
    PricingExpertOpinionParcelsComponent.prototype.populateBaseLandPrice = function (parcelPrice) {
        if (this.data.opinion.expertOpinionPricingMethod === 'A') {
            parcelPrice.expertOpinionLandSquarePrice = parcelPrice.parcel && parcelPrice.parcel.vymera ? parcelPrice.expertOpinionLandAreaPrice / parcelPrice.parcel.vymera : 0;
        }
        else {
            parcelPrice.expertOpinionLandAreaPrice = Math.round(parcelPrice.parcel && parcelPrice.parcel.vymera
                ? this.numericPipe.transform(parcelPrice.expertOpinionLandSquarePrice) * parcelPrice.parcel.vymera
                : 0);
        }
    };
    PricingExpertOpinionParcelsComponent.prototype.setPriceCoefficient = function (parcelPrice, coefficient) {
        if (parcelPrice.isCoefficientSet && parcelPrice.expertOpinionPriceCoefficient === coefficient) {
            parcelPrice.expertOpinionPriceCoefficient = this.COEFFICIENT_1_0;
            parcelPrice.isCoefficientSet = false;
        }
        else {
            parcelPrice.expertOpinionPriceCoefficient = coefficient;
            parcelPrice.isCoefficientSet = true;
        }
    };
    PricingExpertOpinionParcelsComponent.prototype.computeDefaultParcelPrice = function (parcelPrice) {
        this.data.updated = true;
        parcelPrice.expertOpinionParcelPriceDefault = 0;
        this.computeDefaultLandPrice(parcelPrice);
        this.computeDefaultCompensationPrice(parcelPrice);
        this.computeDefaultVegetationPrice(parcelPrice);
        this.computeDefaultBuildingPrice(parcelPrice);
        if (this.hasCoefficient) {
            this.computeCoefficientParcelPrice(parcelPrice);
        }
    };
    PricingExpertOpinionParcelsComponent.prototype.computeCoefficientParcelPrice = function (parcelPrice) {
        this.data.updated = true;
        var coefficient = this.numericPipe.transform(parcelPrice.expertOpinionPriceCoefficient);
        var landPrice = this.numericPipe.transform(parcelPrice.expertOpinionLandSquarePrice);
        var compensationPrice = this.numericPipe.transform(parcelPrice.expertOpinionCompensationPriceDefault);
        var vegetationPrice = this.numericPipe.transform(parcelPrice.expertOpinionVegetationPriceDefault);
        var buildingsPrice = this.numericPipe.transform(parcelPrice.expertOpinionBuildingsPriceDefault);
        parcelPrice.expertOpinionLandPriceCoefficient = Math.round(landPrice * (parcelPrice.parcel && parcelPrice.parcel.vymera) * coefficient) || 0;
        parcelPrice.expertOpinionCompensationPrice = parcelPrice.expertOpinionCompensationMultiply
            ? Math.round(compensationPrice * coefficient) || 0
            : compensationPrice || 0;
        parcelPrice.expertOpinionVegetationPrice = parcelPrice.expertOpinionVegetationMultiply
            ? Math.round(vegetationPrice * coefficient) || 0
            : vegetationPrice || 0;
        parcelPrice.expertOpinionBuildingsPrice = parcelPrice.expertOpinionBuildingsMultiply
            ? Math.round(buildingsPrice * coefficient) || 0
            : buildingsPrice || 0;
    };
    PricingExpertOpinionParcelsComponent.prototype.setSortData = function (sortBy) {
        this.sortOrder.direction = this.sortOrder.direction === 'asc' ? 'desc' : 'asc';
        // every first sort will be sorted 'asc'
        if (this.sortOrder.sortBy !== sortBy) {
            this.sortOrder.direction = 'asc';
        }
        this.sortOrder.sortBy = sortBy;
    };
    PricingExpertOpinionParcelsComponent.prototype.onSortParcel = function () {
        var sortFn = function (item) {
            var parcel = item.parcel;
            if (item.building) {
                parcel = item.building.parcels[0];
            }
            return parcel_utils_1.ParcelUtils.getParcelSortNumber(parcel.parcisKmen, parcel.parcisPod, parcel.parcisDil);
        };
        this.setSortData('parcel');
        this.data.parcelPrices = _.orderBy(this.data.parcelPrices, sortFn, this.sortOrder.direction);
    };
    PricingExpertOpinionParcelsComponent.prototype.loadParcelPrices = function () {
        var _this = this;
        this.data.checklistParcelPrices = this.checklistService.get(this.data.opinion.parcelPrices);
        return Promise.all(this.prepareApiCalls())
            .then(function (res) {
            _this.data.parcelPrices = [];
            res[0].forEach(function (parcel) {
                var item = {
                    parcel: _this.restangular.stripRestangular(parcel),
                    expertOpinionCompensationMultiply: !PricingExpertOpinionParcelsComponent.isParcelWithBuilding(parcel),
                    expertOpinionVegetationMultiply: !PricingExpertOpinionParcelsComponent.isParcelWithBuilding(parcel),
                    expertOpinionBuildingsMultiply: PricingExpertOpinionParcelsComponent.isParcelWithBuilding(parcel),
                };
                _this.data.parcelPrices.push(Object.assign(item, PricingExpertOpinionParcelsComponent.DEFAULT_PRICE));
            });
            res[1].forEach(function (building) {
                var doesBuildingMatch = _this.data.parcelPrices.find(function (parcelPrice) { return PricingExpertOpinionParcelsComponent.buildingsMatch(building, parcelPrice); });
                var item = {
                    building: _this.restangular.stripRestangular(building),
                    expertOpinionVegetationMultiply: false,
                    expertOpinionBuildingsMultiply: true,
                };
                if (!doesBuildingMatch && _this.data.occupationType === 'P') {
                    _this.data.parcelPrices.push(Object.assign(item, PricingExpertOpinionParcelsComponent.DEFAULT_PRICE));
                }
            });
            // filter parcelPrices by parcels and buildings from opinion request
            if (_this.data.opinionRequest) {
                _this.data.parcelPrices = _this.data.parcelPrices.filter(function (item) {
                    return _this.data.opinionRequest.parcels.find(function (e) { return item.parcel.id === e.id; }) || _this.data.opinionRequest.buildings.find(function (e) { return item.building.id === e.id; });
                });
            }
            _this.checkAll();
        });
    };
    PricingExpertOpinionParcelsComponent.prototype.loadOpinionPricing = function () {
        var _this = this;
        return this.restangular
            .all('parcel-prices')
            .customPOST({ filter: this.parcelPricesFilter })
            .then(function (data) {
            _this.data.parcelPrices = [];
            data = _this.restangular.stripRestangular(data);
            _this.data.parcelPrices = _this.data.parcelPrices.concat(data);
            data.forEach(_this.loadParcelPrice, _this);
            _this.data.checklistParcelPrices = _this.checklistService.get(_this.data.opinion.parcelPrices);
        })
            .then(function () {
            if (_this.data.readonly) {
                return;
            }
            Promise.all(_this.prepareApiCalls()).then(function (res) {
                res[0].forEach(function (parcel) {
                    var doesParcelMatch = _this.data.parcelPrices.find(function (parcelPrice) { return PricingExpertOpinionParcelsComponent.parcelsMatch(parcel, parcelPrice); });
                    var item = {
                        parcel: _this.restangular.stripRestangular(parcel),
                        expertOpinionVegetationMultiply: !PricingExpertOpinionParcelsComponent.isParcelWithBuilding(parcel),
                        expertOpinionBuildingsMultiply: PricingExpertOpinionParcelsComponent.isParcelWithBuilding(parcel),
                    };
                    if (!doesParcelMatch) {
                        _this.data.parcelPrices.push(Object.assign(item, PricingExpertOpinionParcelsComponent.DEFAULT_PRICE));
                    }
                });
                res[1].forEach(function (building) {
                    var doesBuildingMatch = _this.data.parcelPrices.find(function (parcelPrice) { return PricingExpertOpinionParcelsComponent.buildingsMatch(building, parcelPrice); });
                    var item = {
                        building: _this.restangular.stripRestangular(building),
                        expertOpinionVegetationMultiply: false,
                        expertOpinionBuildingsMultiply: true,
                    };
                    if (!doesBuildingMatch) {
                        _this.data.parcelPrices.push(Object.assign(item, PricingExpertOpinionParcelsComponent.DEFAULT_PRICE));
                    }
                });
            });
        });
    };
    PricingExpertOpinionParcelsComponent.prototype.loadParcelPrice = function (parcelPrice) {
        var expertOpinionVegetationPriceDefault = (parcelPrice.expertOpinionVegetationPriceDefault && this.data.opinion.expertOpinionVegetation ? parcelPrice.expertOpinionVegetationPriceDefault : 0);
        var expertOpinionBuildingsPriceDefault = (parcelPrice.expertOpinionBuildingsPriceDefault && this.data.opinion.expertOpinionBuildings ? parcelPrice.expertOpinionBuildingsPriceDefault : 0);
        parcelPrice.expertOpinionParcelPriceDefault = Math.round((isFinite(parcelPrice.expertOpinionLandSquarePrice) && this.data.opinion.expertOpinionLand ? parcelPrice.expertOpinionLandSquarePrice : 0) * (parcelPrice.parcel ? parcelPrice.parcel.vymera : 0)) + expertOpinionVegetationPriceDefault + expertOpinionBuildingsPriceDefault;
        parcelPrice.expertOpinionParcelPriceDefaultRounded = parcelPrice.expertOpinionLandPriceDefault + expertOpinionVegetationPriceDefault + expertOpinionBuildingsPriceDefault;
        parcelPrice.isCoefficientSet = true;
        this.data.opinion.parcelPrices.push(parcelPrice);
        if (parcelPrice.parcel) {
            parcelPrice.parcel.validBuyoutOpinionLand = !this.data.opinion.expertOpinionLand && parcelPrice.parcel.validBuyoutOpinionLand;
            parcelPrice.parcel.validBuyoutOpinionVegetation = !this.data.opinion.expertOpinionVegetation && parcelPrice.parcel.validBuyoutOpinionVegetation;
            parcelPrice.parcel.validBuyoutOpinionBuildings = !this.data.opinion.expertOpinionBuildings && parcelPrice.parcel.validBuyoutOpinionBuildings;
        }
        else {
            parcelPrice.building.validBuyoutOpinionBuildings = !this.data.opinion.expertOpinionBuildings && parcelPrice.building.validBuyoutOpinionBuildings;
        }
    };
    PricingExpertOpinionParcelsComponent.prototype.prepareApiCalls = function () {
        var loadParcels = this.restangular.all('parcels').customPOST({ filter: this.parcelsFilter });
        var loadBuildings = this.restangular.all('buildings').customPOST({ filter: this.buildingsFilter });
        return [loadParcels, loadBuildings];
    };
    PricingExpertOpinionParcelsComponent.prototype.computeBaseLandPrice = function (parcelPrice) {
        if (this.data.opinion.expertOpinionPricingMethod === 'A') {
            return Math.round(this.numericPipe.transform(parcelPrice.expertOpinionLandAreaPrice));
        }
        else {
            return Math.round(this.numericPipe.transform(parcelPrice.expertOpinionLandSquarePrice) * (parcelPrice.parcel && parcelPrice.parcel.vymera));
        }
    };
    PricingExpertOpinionParcelsComponent.prototype.computeDefaultLandPrice = function (parcelPrice) {
        if (this.data.opinion.expertOpinionLand && parcelPrice.parcel) {
            parcelPrice.expertOpinionParcelPriceDefault += Math.round(this.numericPipe.transform(parcelPrice.expertOpinionLandSquarePrice) * parcelPrice.parcel.vymera);
        }
        else {
            parcelPrice.expertOpinionLandSquarePrice = null;
            parcelPrice.expertOpinionLandAreaPrice = null;
        }
    };
    PricingExpertOpinionParcelsComponent.prototype.computeDefaultCompensationPrice = function (parcelPrice) {
        if (this.data.opinion.expertOpinionCompensation) {
            parcelPrice.expertOpinionParcelPriceDefault += this.numericPipe.transform(parcelPrice.expertOpinionCompensationPriceDefault) || 0;
        }
        else {
            parcelPrice.expertOpinionCompensationPriceDefault = null;
        }
    };
    PricingExpertOpinionParcelsComponent.prototype.computeDefaultVegetationPrice = function (parcelPrice) {
        if (this.data.opinion.expertOpinionVegetation) {
            parcelPrice.expertOpinionParcelPriceDefault += this.numericPipe.transform(parcelPrice.expertOpinionVegetationPriceDefault) || 0;
        }
        else {
            parcelPrice.expertOpinionVegetationPriceDefault = null;
        }
    };
    PricingExpertOpinionParcelsComponent.prototype.computeDefaultBuildingPrice = function (parcelPrice) {
        if (this.data.opinion.expertOpinionBuildings) {
            parcelPrice.expertOpinionParcelPriceDefault += this.numericPipe.transform(parcelPrice.expertOpinionBuildingsPriceDefault) || 0;
        }
        else {
            parcelPrice.expertOpinionBuildingsPriceDefault = null;
        }
    };
    PricingExpertOpinionParcelsComponent.prototype.setupFilters = function () {
        this.parcelsFilter = {
            filters: {
                titleId: [this.data.title.id],
                validBuyoutOpinion: [false],
                validity: ['valid'],
            },
        };
        this.parcelsFilter.filters[(this.data.occupationType === 'P' ? 'permanent' : 'temporary') + 'OccupationExists'] = [true];
        this.buildingsFilter = {
            filters: {
                occupationOrEasementExists: [true],
                titleId: [this.data.title.id],
                validBuyoutOpinion: [false],
                validity: ['valid'],
                differentTitle: [true],
                loadCollections: [
                    'parcelsWithEasementOrOccupation',
                    'buildingProtections',
                    'parcels.parcelProtections',
                ],
            },
        };
        this.parcelPricesFilter = {
            filters: {
                opinionId: [this.data.opinion.id],
                validity: ['valid'],
                loadCollections: [
                    'building.parcelsWithEasementOrOccupation',
                    'building.buildingProtections',
                    'building.parcels.parcelProtections',
                ],
            },
        };
    };
    PricingExpertOpinionParcelsComponent.isParcelWithBuilding = function (parcel) {
        var isBuildingParcel = parcel.cislKod && parcel.cislKod === 1;
        var isLandParcelWithBuilding = parcel.cislKod && parcel.cislKod === 2 && parcel.drupozKod && parcel.drupozKod === 13;
        return isBuildingParcel || isLandParcelWithBuilding;
    };
    PricingExpertOpinionParcelsComponent.buildingsMatch = function (building, parcelPrice) {
        return parcelPrice.building && parcelPrice.building.budId === building.budId;
    };
    PricingExpertOpinionParcelsComponent.parcelsMatch = function (parcel, parcelPrice) {
        return parcelPrice.parcel && parcelPrice.parcel.idpar === parcel.idpar;
    };
    PricingExpertOpinionParcelsComponent.DEFAULT_PRICE = {
        expertOpinionLandSquarePrice: 0,
        expertOpinionLandAreaPrice: 0,
        expertOpinionParcelPriceDefault: 0,
        expertOpinionVegetationPriceDefault: 0,
        expertOpinionCompensationPriceDefault: 0,
        expertOpinionBuildingsPriceDefault: 0,
        expertOpinionPriceCoefficient: 1,
        isCoefficientSet: false
    };
    return PricingExpertOpinionParcelsComponent;
}(pricing_expert_opinion_step_component_1.PricingExpertOpinionStepComponent));
exports.PricingExpertOpinionParcelsComponent = PricingExpertOpinionParcelsComponent;
