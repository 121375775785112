"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var common_1 = require("@angular/common");
var _ = require("lodash");
var subject_name_pipe_1 = require("@app/common/pipes/subject-name.pipe");
var form_case_send_administrator_component_1 = require("@app/ps/cases/components/form-case-send-administrator/form-case-send-administrator.component");
var form_case_sign_administrator_component_1 = require("@app/ps/cases/components/form-case-sign-administrator/form-case-sign-administrator.component");
var form_case_send_investor_component_1 = require("@app/ps/cases/components/form-case-send-investor/form-case-send-investor.component");
var form_case_sign_investor_component_1 = require("@app/ps/cases/components/form-case-sign-investor/form-case-sign-investor.component");
var form_case_send_owner_component_1 = require("@app/ps/cases/components/form-case-send-owner/form-case-send-owner.component");
var form_case_undelivered_owner_component_1 = require("@app/ps/cases/components/form-case-undelivered-owner/form-case-undelivered-owner.component");
var form_case_receive_owner_component_1 = require("@app/ps/cases/components/form-case-receive-owner/form-case-receive-owner.component");
var form_case_decline_owner_component_1 = require("@app/ps/cases/components/form-case-decline-owner/form-case-decline-owner.component");
var form_case_sign_owner_component_1 = require("@app/ps/cases/components/form-case-sign-owner/form-case-sign-owner.component");
var form_case_entry_proposal_component_1 = require("@app/ps/cases/components/form-case-entry-proposal/form-case-entry-proposal.component");
var form_case_entry_declined_component_1 = require("@app/ps/cases/components/form-case-entry-declined/form-case-entry-declined.component");
var form_case_entry_stopped_component_1 = require("@app/ps/cases/components/form-case-entry-stopped/form-case-entry-stopped.component");
var form_case_hand_over_investor_component_1 = require("@app/ps/cases/components/form-case-hand-over-investor/form-case-hand-over-investor.component");
var form_case_payment_instruction_component_1 = require("@app/ps/cases/components/form-case-payment-instruction/form-case-payment-instruction.component");
var form_case_payment_order_component_1 = require("@app/ps/cases/components/form-case-payment-order/form-case-payment-order.component");
var form_case_signed_send_owners_component_1 = require("@app/ps/cases/components/form-case-signed-send-owners/form-case-signed-send-owners.component");
var auth_service_1 = require("@app/common/services/auth.service");
var i0 = require("@angular/core");
var i1 = require("@angular/common");
var i2 = require("../../common/pipes/subject-name.pipe");
var i3 = require("../../common/services/auth.service");
var CaseService = /** @class */ (function () {
    function CaseService(datePipe, subjectNamePipe, authService) {
        this.subjectNamePipe = subjectNamePipe;
        this.authService = authService;
        this.getRecipients = this.getRecipients.bind(this);
        this.hasEqualAddress = this.hasEqualAddress.bind(this);
        this.isFieldEqual = this.isFieldEqual.bind(this);
        this.easementPriceByShare = this.easementPriceByShare.bind(this);
        this.showEasementDistributionSystemParts = this.showEasementDistributionSystemParts.bind(this);
        this.simpleCaseStatusConfig = [
            {
                id: 'expropriationEnforceable',
                name: 'ExpropriationEnforceable',
                saveEndpoint: 'expropriation-enforceable',
                formFields: [
                    {
                        field: 'expropriationEnforceableDate',
                        name: 'Datum nabytí právní moci',
                        type: 'date',
                        required: true,
                    }
                ]
            }, {
                id: 'expropriationAppeal',
                name: 'ExpropriationAppeal',
                saveEndpoint: 'expropriation-appeal',
                formFields: [
                    {
                        field: 'expropriationAppealDate',
                        name: 'Datum odvolání',
                        type: 'date',
                        required: true,
                    }, {
                        field: 'expropriationAppealReason',
                        name: 'Důvod odvolání',
                        type: 'string',
                        required: true,
                    }, {
                        field: 'expropriationAppealSide',
                        name: 'Strana podávající odvolání',
                        type: 'switch',
                        options: [{ id: 'I', value: 'Investor' }, { id: 'O', value: 'Vlastník' }],
                        required: true,
                    }
                ]
            }, {
                id: 'expropriationHearingRepetition',
                name: 'ExpropriationHearingRepetition',
                saveEndpoint: 'expropriation-hearing-repetition',
                formFields: [
                    {
                        field: 'expropriationHearingRepetitionCalledDate',
                        name: 'Datum doručení rozhodnutí o svolání opakovaného ústního jednání',
                        type: 'date',
                        required: true,
                        default: 'currentDate',
                    }, {
                        field: 'expropriationHearingRepetitionReason',
                        name: 'Důvod opakování',
                        type: 'string',
                        required: true,
                    }, {
                        field: 'expropriationHearingRepetitionDate',
                        name: 'Datum konání opakovaného ústního jednání',
                        type: 'date',
                        required: true,
                        allowFutureDate: true
                    }
                ]
            }, {
                id: 'expropriationProceedingInteruptionExtended',
                name: 'ExpropriationProceedingInteruptionExtended',
                saveEndpoint: 'expropriation-proceeding-interuption-extended',
                formFields: [
                    {
                        field: 'expropriationProceedingInteruptionExtendedDate',
                        name: 'Datum prodloužení',
                        type: 'date',
                        required: true,
                        default: 'currentDate',
                    }, {
                        field: 'expropriationProceedingInteruptionExtendedReason',
                        name: 'Důvod',
                        type: 'string',
                        required: true,
                    }, {
                        field: 'expropriationProceedingInteruptionExtendedDeadlineDate',
                        name: 'Lhůta',
                        type: 'date',
                        required: true,
                        allowFutureDate: true
                    }
                ]
            }, {
                id: 'expropriationProceedingInterupted',
                name: 'ExpropriationProceedingInterupted',
                saveEndpoint: 'expropriation-proceeding-interupted',
                formFields: [
                    {
                        field: 'expropriationProceedingInteruptionDate',
                        name: 'Datum přerušení',
                        type: 'date',
                        required: true,
                        default: 'currentDate',
                    }, {
                        field: 'expropriationProceedingInteruptionReason',
                        name: 'Důvod',
                        type: 'string',
                        required: true,
                    }, {
                        field: 'expropriationProceedingInteruptionDeadlineDate',
                        name: 'Lhůta',
                        type: 'date',
                        required: true,
                        allowFutureDate: true
                    }
                ]
            }, {
                id: 'expropriationAssessmentInterupted',
                name: 'ExpropriationAssessmentInterupted',
                saveEndpoint: 'expropriation-assessment-interupted',
                formFields: [
                    {
                        field: 'expropriationAssessmentInteruptionDate',
                        name: 'Datum přerušení',
                        type: 'date',
                        required: true,
                        default: 'currentDate',
                    }, {
                        field: 'expropriationAssessmentInteruptionReason',
                        name: 'Důvod',
                        type: 'string',
                        required: true,
                    }, {
                        field: 'expropriationAssessmentInteruptionDeadlineDate',
                        name: 'Lhůta',
                        type: 'date',
                        required: true,
                        allowFutureDate: true
                    }
                ]
            }, {
                id: 'expropriationStop',
                name: 'ExpropriationStopped',
                saveEndpoint: 'expropriation-stopped',
                formFields: [
                    {
                        field: 'expropriationStoppedDate',
                        name: 'Datum zastavení řízení',
                        type: 'date',
                        required: true,
                        default: 'currentDate',
                    }, {
                        field: 'expropriationStoppedBySide',
                        name: 'Kdo řízení zastavil',
                        type: 'string',
                        required: true,
                    }, {
                        field: 'expropriationStoppedReason',
                        name: 'Důvod zastavení řízení',
                        type: 'string',
                        required: true,
                    }
                ]
            }, {
                id: 'expropriationCalledHearing',
                name: 'ExpropriationCalledHearing',
                saveEndpoint: 'expropriation-called-hearing',
                formFields: [
                    {
                        field: 'expropriationCalledHearingCalledDate',
                        name: 'Datum doručení rozhodnutí o svolání ústního jednání',
                        type: 'date',
                        required: true,
                    }, {
                        field: 'expropriationCalledHearingDate',
                        name: 'Datum konání ústního jednání',
                        type: 'date',
                        required: true,
                        allowFutureDate: true
                    }
                ]
            }, {
                id: 'expropriationProceedingStarted',
                name: 'ExpropriationProceedingStarted',
                saveEndpoint: 'expropriation-proceeding-started',
                formFields: [
                    {
                        field: 'expropriationProceedingStartedDate',
                        name: 'Datum zahájení řízení',
                        type: 'date',
                        required: true,
                    }
                ]
            }, {
                id: 'handOverInvestor',
                name: 'HandedOverInvestor',
                saveEndpoint: 'hand-over-investor',
                formFields: [
                    {
                        field: 'handedOverInvestorDate',
                        name: 'Datum předání',
                        type: 'date',
                        default: 'currentDate',
                        required: true,
                    }
                ]
            }, {
                id: 'paymentInstruction',
                name: 'PaymentInstruction',
                saveEndpoint: 'payment-instruction',
                formFields: [
                    {
                        field: 'paymentInstructionDate',
                        name: 'Datum',
                        type: 'date',
                        default: 'currentDate',
                        required: true,
                    }
                ]
            }, {
                id: 'paymentOrder',
                name: 'PaymentOrder',
                saveEndpoint: 'payment-order',
                formFields: [
                    {
                        field: 'paymentOrderDate',
                        name: 'Datum',
                        type: 'date',
                        required: true,
                    }
                ]
            }, {
                id: 'sendAdministrator',
                name: 'SentAdministrator',
                saveEndpoint: 'send-administrator',
                formFields: [
                    {
                        field: 'sentAdministratorDate',
                        name: 'Datum odeslání',
                        type: 'date',
                        required: true,
                    }
                ]
            }, {
                id: 'sendInvestor',
                name: 'SentInvestor',
                saveEndpoint: 'send-investor',
                formFields: [
                    {
                        field: 'sentInvestorDate',
                        name: 'Datum odeslání',
                        type: 'date',
                        default: 'currentDate',
                        required: true,
                    }
                ]
            }, {
                id: 'expropriationProposal',
                name: 'ExpropriationProposal',
                saveEndpoint: 'expropriation-proposal',
                formFields: [
                    {
                        field: 'expropriationProposalDate',
                        name: 'Datum žádosti',
                        type: 'date',
                        default: 'currentDate',
                        required: true,
                    }
                ]
            }, {
                id: 'signAdministrator',
                name: 'SignedAdministrator',
                saveEndpoint: 'sign-administrator',
                formFields: [
                    {
                        field: 'signedAdministratorDate',
                        name: 'Datum podpisu',
                        type: 'date',
                        required: true,
                    }
                ]
            }, {
                id: 'signedSentOwners',
                name: 'SignedSentOwners',
                saveEndpoint: 'signed-sent-owners',
                formFields: [
                    {
                        field: 'signedSentOwnersDate',
                        name: 'Datum',
                        type: 'date',
                        required: true,
                    }
                ]
            }, {
                id: 'callBeforeExpropriation',
                name: 'CallBeforeExpropriation',
                saveEndpoint: 'call-before-expropriation',
                recipient: true,
                statusDateFieldName: 'callBeforeExpropriationDate',
                formFields: [
                    {
                        field: 'callBeforeExpropriationDate',
                        name: 'Datum odeslání výzvy před vyvlastněním',
                        type: 'date',
                        required: true,
                    }
                ]
            }, {
                id: 'callBeforeExpropriationReceived',
                name: 'CallBeforeExpropriationReceived',
                saveEndpoint: 'call-before-expropriation-received',
                recipient: true,
                statusDateFieldName: 'callBeforeExpropriationReceivedDate',
                formFields: [
                    {
                        field: 'callBeforeExpropriationReceivedDate',
                        name: 'Datum doručení výzvy před vyvlastněním',
                        type: 'date',
                        required: true,
                        onChange: function (data) {
                            var date = new Date(data.callBeforeExpropriationReceivedDate);
                            var newDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() + data.obligation.callBeforeExpropriationReceivedPeriod);
                            data.callBeforeExpropriationReceivedDeadlineDate = datePipe.transform(newDate, 'yyyy-MM-dd');
                        },
                    },
                    {
                        field: 'callBeforeExpropriationReceivedDeadlineDate',
                        name: 'Datum lhůty výzvy k vyvlastnění',
                        type: 'date',
                        required: false,
                        allowFutureDate: true,
                    }
                ]
            }, {
                id: 'expropriationCaseInProgress',
                name: 'ExpropriationCaseInProgress',
                saveEndpoint: 'expropriation-case-in-progress',
                recipient: true,
                statusDateFieldName: 'expropriationCaseInProgressDate',
                formFields: [
                    {
                        field: 'expropriationCaseInProgressDate',
                        name: 'Datum zahájení vyvlastňovacího řízení',
                        type: 'date',
                        required: true,
                    }
                ]
            }, {
                id: 'dunningLetter',
                name: 'DunningLetter',
                saveEndpoint: 'dunning-letter',
                recipient: true,
                statusDateFieldName: 'dunningLetterDate',
                formFields: [
                    {
                        field: 'dunningLetterDate',
                        name: 'Datum odeslání urgence',
                        type: 'date',
                        required: true,
                        onChange: function (data) {
                            var date = new Date(data.dunningLetterDate);
                            var newDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() + data.obligation.dunningLetterPeriod);
                            data.dunningLetterDeadlineDate = datePipe.transform(newDate, 'yyyy-MM-dd');
                        },
                    },
                    {
                        field: 'dunningLetterDeadlineDate',
                        name: 'Datum lhůty urgence',
                        type: 'date',
                        required: false,
                        allowFutureDate: true,
                    }
                ]
            },
        ];
    }
    CaseService.prototype.getRecipients = function (caseOwnerships, allowDeliveryAddress) {
        var _this = this;
        allowDeliveryAddress = allowDeliveryAddress && true;
        var recipients = [];
        caseOwnerships.forEach(function (item) {
            // remove parents and avoid duplicates
            if (!item.isParent && !_.some(recipients, function (recipient) { return _.some(item.caseSubjects, function (caseSubject) { return caseSubject.subject.opsubId === recipient.id; }); })) {
                // fill recipients array, exactly the same algorithm as in backend in covering letter generating
                if (item.ownership.isBsm && item.caseSubjects && item.caseSubjects.length === 2) {
                    if (_this.hasEqualAddress(item.caseSubjects[0], item.caseSubjects[1], false) &&
                        (!allowDeliveryAddress || _this.hasEqualAddress(item.caseSubjects[0], item.caseSubjects[1], allowDeliveryAddress)) &&
                        item.caseSubjects[0].personalVisitDate === item.caseSubjects[1].personalVisitDate &&
                        item.caseSubjects[0].sentDate === item.caseSubjects[1].sentDate &&
                        item.caseSubjects[0].undeliveredDate === item.caseSubjects[1].undeliveredDate &&
                        item.caseSubjects[0].receivedDate === item.caseSubjects[1].receivedDate &&
                        item.caseSubjects[0].signedDate === item.caseSubjects[1].signedDate &&
                        item.caseSubjects[0].responseReceivedDate === item.caseSubjects[1].responseReceivedDate &&
                        item.caseSubjects[0].meetingRequestDate === item.caseSubjects[1].meetingRequestDate &&
                        item.caseSubjects[0].acceptedGeometricalPlanPossibleDate === item.caseSubjects[1].acceptedGeometricalPlanPossibleDate &&
                        item.caseSubjects[0].dunningLetterDate === item.caseSubjects[1].dunningLetterDate &&
                        item.caseSubjects[0].callBeforeExpropriationDate === item.caseSubjects[1].callBeforeExpropriationDate &&
                        item.caseSubjects[0].callBeforeExpropriationReceivedDate === item.caseSubjects[1].callBeforeExpropriationReceivedDate &&
                        item.caseSubjects[0].expropriationCaseInProgressDate === item.caseSubjects[1].expropriationCaseInProgressDate &&
                        item.caseSubjects[0].expropriationCaseStoppedDate === item.caseSubjects[1].expropriationCaseStoppedDate &&
                        item.caseSubjects[0].expropriationCaseFinishedDate === item.caseSubjects[1].expropriationCaseFinishedDate) {
                        recipients.push({
                            name: _this.subjectNamePipe.transform(item.caseSubjects[0].subject) + ', ' + _this.subjectNamePipe.transform(item.caseSubjects[1].subject),
                            id: item.ownership.opsubId,
                            opsubIds: [item.caseSubjects[0].subject.opsubId, item.caseSubjects[1].subject.opsubId],
                            personalVisitDate: item.caseSubjects[0].personalVisitDate,
                            sentDate: item.caseSubjects[0].sentDate,
                            undeliveredDate: item.caseSubjects[0].undeliveredDate,
                            receivedDate: item.caseSubjects[0].receivedDate,
                            declinedDate: item.caseSubjects[0].declinedDate,
                            signedDate: item.caseSubjects[0].signedDate,
                            responseReceivedDate: item.caseSubjects[0].responseReceivedDate,
                            meetingRequestDate: item.caseSubjects[0].meetingRequestDate,
                            acceptedGeometricalPlanPossibleDate: item.caseSubjects[0].acceptedGeometricalPlanPossibleDate,
                            dunningLetterDate: item.caseSubjects[0].dunningLetterDate,
                            callBeforeExpropriationDate: item.caseSubjects[0].callBeforeExpropriationDate,
                            callBeforeExpropriationReceivedDate: item.caseSubjects[0].callBeforeExpropriationReceivedDate,
                            expropriationCaseInProgressDate: item.caseSubjects[0].expropriationCaseInProgressDate,
                            expropriationCaseStoppedDate: item.caseSubjects[0].expropriationCaseStoppedDate,
                            expropriationCaseFinishedDate: item.caseSubjects[0].expropriationCaseFinishedDate,
                        });
                    }
                    else {
                        item.caseSubjects.forEach(function (caseSubject) {
                            recipients.push({
                                name: _this.subjectNamePipe.transform(caseSubject.subject),
                                id: caseSubject.subject.opsubId,
                                personalVisitDate: caseSubject.personalVisitDate,
                                sentDate: caseSubject.sentDate,
                                undeliveredDate: caseSubject.undeliveredDate,
                                receivedDate: caseSubject.receivedDate,
                                declinedDate: caseSubject.declinedDate,
                                signedDate: caseSubject.signedDate,
                                responseReceivedDate: caseSubject.responseReceivedDate,
                                meetingRequestDate: caseSubject.meetingRequestDate,
                                acceptedGeometricalPlanPossibleDate: caseSubject.acceptedGeometricalPlanPossibleDate,
                                dunningLetterDate: caseSubject.dunningLetterDate,
                                callBeforeExpropriationDate: caseSubject.callBeforeExpropriationDate,
                                callBeforeExpropriationReceivedDate: caseSubject.callBeforeExpropriationReceivedDate,
                                expropriationCaseInProgressDate: caseSubject.expropriationCaseInProgressDate,
                                expropriationCaseStoppedDate: caseSubject.expropriationCaseStoppedDate,
                                expropriationCaseFinishedDate: caseSubject.expropriationCaseFinishedDate,
                            });
                        });
                    }
                }
                else if (item.caseSubjects && item.caseSubjects.length === 1) {
                    if (item.caseSubjects[0].subject.opsubType !== 'OFO' ||
                        !_.some(caseOwnerships, function (caseOwnership) { return caseOwnership.ownership.isBsm &&
                            _.some(caseOwnership.caseSubjects, function (caseSubject) { return caseSubject.subject.opsubId === item.caseSubjects[0].subject.opsubId; }); })) {
                        recipients.push({
                            name: _this.subjectNamePipe.transform(item.caseSubjects[0].subject),
                            id: item.caseSubjects[0].subject.opsubId,
                            personalVisitDate: item.caseSubjects[0].personalVisitDate,
                            sentDate: item.caseSubjects[0].sentDate,
                            undeliveredDate: item.caseSubjects[0].undeliveredDate,
                            receivedDate: item.caseSubjects[0].receivedDate,
                            declinedDate: item.caseSubjects[0].declinedDate,
                            signedDate: item.caseSubjects[0].signedDate,
                            responseReceivedDate: item.caseSubjects[0].responseReceivedDate,
                            meetingRequestDate: item.caseSubjects[0].meetingRequestDate,
                            acceptedGeometricalPlanPossibleDate: item.caseSubjects[0].acceptedGeometricalPlanPossibleDate,
                            dunningLetterDate: item.caseSubjects[0].dunningLetterDate,
                            callBeforeExpropriationDate: item.caseSubjects[0].callBeforeExpropriationDate,
                            callBeforeExpropriationReceivedDate: item.caseSubjects[0].callBeforeExpropriationReceivedDate,
                            expropriationCaseInProgressDate: item.caseSubjects[0].expropriationCaseInProgressDate,
                            expropriationCaseStoppedDate: item.caseSubjects[0].expropriationCaseStoppedDate,
                            expropriationCaseFinishedDate: item.caseSubjects[0].expropriationCaseFinishedDate,
                        });
                    }
                }
            }
        });
        return recipients;
    };
    CaseService.prototype.hasEqualAddress = function (caseSubject1, caseSubject2, allowDeliveryAddress) {
        return this.isFieldEqual(caseSubject1, caseSubject2, 'obec', 'dorucovaciObec', allowDeliveryAddress) &&
            this.isFieldEqual(caseSubject1, caseSubject2, 'cisloDomovni', 'dorucovaciCisloDomovni', allowDeliveryAddress) &&
            this.isFieldEqual(caseSubject1, caseSubject2, 'cisloOrientacni', 'dorucovaciCisloOrientacni', allowDeliveryAddress) &&
            this.isFieldEqual(caseSubject1, caseSubject2, 'psc', 'dorucovaciPsc', allowDeliveryAddress) &&
            this.isFieldEqual(caseSubject1, caseSubject2, 'ulice', 'dorucovaciUlice', allowDeliveryAddress);
    };
    CaseService.prototype.getField = function (caseSubject, fieldName, deliveryFieldName, allowDeliveryAddress) {
        return caseSubject.dorucovaciUse && allowDeliveryAddress ? caseSubject[deliveryFieldName] : caseSubject[fieldName];
    };
    CaseService.prototype.isFieldEqual = function (caseSubject1, caseSubject2, fieldName, deliveryFieldName, allowDeliveryAddress, mapper) {
        if (mapper === void 0) { mapper = function (value) { return value; }; }
        return mapper(this.getField(caseSubject1, fieldName, deliveryFieldName, allowDeliveryAddress)) === mapper(this.getField(caseSubject2, fieldName, deliveryFieldName, allowDeliveryAddress));
    };
    CaseService.prototype.getDataCaseOwnerships = function (caseOwnerships, caseSubjects) {
        var dataCaseOwnerships = __spread(_.filter(caseOwnerships, function (item) {
            return !item.isParent;
        }));
        dataCaseOwnerships.forEach(function (item) {
            item.podil = item.ownership.podilCitatel + '/' + item.ownership.podilJmenovatel;
            if (item.ownership.isBsm) {
                var bsmSubject_1 = caseSubjects.find(function (value) { return value.subject.opsubId === item.ownership.opsubId; });
                if (bsmSubject_1) {
                    item.caseSubjects = caseSubjects.filter(function (value) { return value.subject.opsubId === bsmSubject_1.subject.partner1OpsubId || value.subject.opsubId === bsmSubject_1.subject.partner2OpsubId; });
                }
            }
            else {
                item.caseSubjects = caseSubjects.filter(function (value) { return value.subject.opsubId === item.ownership.opsubId; });
            }
        });
        return dataCaseOwnerships;
    };
    CaseService.prototype.checkParentCaseOwnershipCycle = function (newValue, ownershipsWithParent) {
        var cycleValue = newValue;
        do {
            if (ownershipsWithParent) {
                var ownershipWithParent = _.find(ownershipsWithParent, { caseOwnership: { ownership: { id: cycleValue.ownership.id } } });
                if (ownershipWithParent) {
                    cycleValue = ownershipWithParent.caseOwnership.parentCaseOwnership;
                }
                else {
                    cycleValue = null;
                }
            }
            else {
                cycleValue = cycleValue.parentCaseOwnership;
            }
            if (cycleValue && cycleValue.ownership.id === newValue.ownership.id) {
                return true;
            }
        } while (cycleValue);
        return false;
    };
    CaseService.prototype.isOwnerPresent = function (caseOwnership) {
        var cycleValue = caseOwnership;
        while (cycleValue.parentCaseOwnership) {
            cycleValue = cycleValue.parentCaseOwnership;
            if (cycleValue === caseOwnership) {
                return true;
            }
        }
        /*
         * "30";"Vlastnické právo"
         */
        return cycleValue.ownership.ownerType;
    };
    CaseService.prototype.easementPriceByShare = function (obligation) {
        return this.authService.getActualProject().easementPriceByShare &&
            obligation.easementPriceByShare;
    };
    CaseService.prototype.getActionButtons = function () {
        return [
            { key: 'sendAdministrator', caseStatus: 'SentAdministrator' },
            { key: 'signAdministrator', caseStatus: 'SignedAdministrator' },
            { key: 'sendInvestor', caseStatus: 'SentInvestor' },
            { key: 'confirm', caseStatus: 'ApprovedInvestor' },
            { key: 'signInvestor', caseStatus: 'SignedInvestor' },
            { key: 'auctionResult', caseStatus: 'AuctionWin' },
            { key: 'sendOwner', caseStatus: 'SentOwner' },
            { key: 'undeliveredOwner', caseStatus: 'UndeliveredOwner' },
            { key: 'receiveOwner', caseStatus: 'ReceivedOwner' },
            { key: 'declinedOwner', caseStatus: 'DeclinedOwner' },
            { key: 'signOwner', caseStatus: 'SignedOwner' },
            { key: 'personalVisit', caseStatus: 'PersonalVisit' },
            { key: 'meetingRequest', caseStatus: 'MeetingRequest' },
            { key: 'acceptedGeometricalPlanPossible', caseStatus: 'AcceptedGeometricalPlanPossible' },
            { key: 'expropriationProposal', caseStatus: 'ExpropriationProposal' },
            { key: 'expropriationAssessmentInterupted', caseStatus: 'ExpropriationAssessmentInterupted' },
            { key: 'expropriationProceedingStarted', caseStatus: 'ExpropriationProceedingStarted' },
            { key: 'expropriationProceedingInterupted', caseStatus: 'ExpropriationProceedingInterupted' },
            { key: 'expropriationProceedingInteruptionExtended', caseStatus: 'ExpropriationProceedingInteruptionExtended' },
            { key: 'expropriationStop', caseStatus: 'ExpropriationStopped' },
            { key: 'expropriationCalledHearing', caseStatus: 'ExpropriationCalledHearing' },
            { key: 'expropriationHearingRepetition', caseStatus: 'ExpropriationHearingRepetition' },
            { key: 'expropriationResult', caseStatus: 'ExpropriationAccepted', name: 'Rozhodnutí vyvlastňovacího úřadu' },
            { key: 'expropriationAppeal', caseStatus: 'ExpropriationAppeal' },
            { key: 'expropriationAppealResult', caseStatus: 'ExpropriationAppealAccepted', name: 'Rozhodnutí o odvolání' },
            { key: 'expropriationEnforceable', caseStatus: 'ExpropriationEnforceable' },
            { key: 'expropriationInterimDecisionRequest', caseStatus: 'ExpropriationInterimDecisionRequest' },
            { key: 'expropriationInterimDecision', caseStatus: 'ExpropriationInterimDecision' },
            { key: 'expropriationAdministrativeAppeal', caseStatus: 'ExpropriationAdministrativeAppeal' },
            { key: 'expropriationAdministrativeAppealRejected', caseStatus: 'ExpropriationAdministrativeAppealRejected' },
            { key: 'expropriationAdministrativeAppealAccepted', caseStatus: 'ExpropriationAdministrativeAppealAccepted' },
            { key: 'expropriationCompensationPayment', caseStatus: 'ExpropriationCompensationPayment' },
            { key: 'entryProposal', caseStatus: 'EntryProposal' },
            { key: 'cadastreEntry', caseStatus: 'CadastreEntry' },
            { key: 'handOverInvestor', caseStatus: 'HandedOverInvestor' },
            { key: 'paymentInstruction', caseStatus: 'PaymentInstruction' },
            { key: 'paymentOrder', caseStatus: 'PaymentOrder' },
            { key: 'signedSentOwners', caseStatus: 'SignedSentOwners' },
            { key: 'contractsRegister', caseStatus: 'ContractRegisterPublication' },
            { key: 'callBeforeExpropriation', caseStatus: 'CallBeforeExpropriation' },
            { key: 'callBeforeExpropriationReceived', caseStatus: 'CallBeforeExpropriationReceived' },
            { key: 'expropriationCaseInProgress', caseStatus: 'ExpropriationCaseInProgress' },
            { key: 'dunningLetter', caseStatus: 'DunningLetter' },
            { key: 'cancel', caseStatus: 'Cancelled', click: "cancel", icon: 'fa-remove' }
        ];
    };
    CaseService.prototype.getActionDialogues = function () {
        return [
            { caseStatus: 'SentAdministrator', formComponent: form_case_send_administrator_component_1.FormCaseSendAdministratorComponent, saveEndpoint: 'send-administrator' },
            { caseStatus: 'SignedAdministrator', formComponent: form_case_sign_administrator_component_1.FormCaseSignAdministratorComponent, saveEndpoint: 'sign-administrator' },
            { caseStatus: 'SentInvestor', formComponent: form_case_send_investor_component_1.FormCaseSendInvestorComponent, saveEndpoint: 'send-investor' },
            { caseStatus: 'ApprovedInvestor', saveEndpoint: 'approve-investor' },
            { caseStatus: 'DeclinedInvestor', saveEndpoint: 'decline-investor' },
            { caseStatus: 'SignedInvestor', formComponent: form_case_sign_investor_component_1.FormCaseSignInvestorComponent, saveEndpoint: 'sign-investor' },
            //{caseStatus: 'AuctionWin'},
            { caseStatus: 'SentOwner', formComponent: form_case_send_owner_component_1.FormCaseSendOwnerComponent, saveEndpoint: 'send-owner' },
            { caseStatus: 'UndeliveredOwner', formComponent: form_case_undelivered_owner_component_1.FormCaseUndeliveredOwnerComponent, saveEndpoint: 'undelivered-owner' },
            { caseStatus: 'ReceivedOwner', formComponent: form_case_receive_owner_component_1.FormCaseReceiveOwnerComponent, saveEndpoint: 'receive-owner' },
            { caseStatus: 'DeclinedOwner', formComponent: form_case_decline_owner_component_1.FormCaseDeclineOwnerComponent, saveEndpoint: 'decline-owner' },
            { caseStatus: 'SignedOwner', formComponent: form_case_sign_owner_component_1.FormCaseSignOwnerComponent, saveEndpoint: 'sign-owner' },
            /*{caseStatus: 'ExpropriationProposal'},
            {caseStatus: 'ExpropriationAssessmentInterupted'},
            {caseStatus: 'ExpropriationProceedingStarted'},
            {caseStatus: 'ExpropriationProceedingInterupted'},
            {caseStatus: 'ExpropriationProceedingInteruptionExtended'},
            {caseStatus: 'ExpropriationStopped'},
            {caseStatus: 'ExpropriationCalledHearing'},
            {caseStatus: 'ExpropriationHearingRepetition'},
            {caseStatus: 'ExpropriationAccepted'},
            {caseStatus: 'ExpropriationAppeal'},
            {caseStatus: 'ExpropriationAppealAccepted'},
            {caseStatus: 'ExpropriationEnforceable'},*/
            { caseStatus: 'EntryProposal', formComponent: form_case_entry_proposal_component_1.FormCaseEntryProposalComponent, saveEndpoint: 'entry-proposal' },
            // {caseStatus: 'CadastreEntry'},
            { caseStatus: 'EntryDeclined', formComponent: form_case_entry_declined_component_1.FormCaseEntryDeclinedComponent, saveEndpoint: 'decline-cadastre-entry' },
            { caseStatus: 'EntryStopped', formComponent: form_case_entry_stopped_component_1.FormCaseEntryStoppedComponent, saveEndpoint: 'stop-cadastre-entry' },
            { caseStatus: 'HandedOverInvestor', formComponent: form_case_hand_over_investor_component_1.FormCaseHandOverInvestorComponent, saveEndpoint: 'hand-over-investor' },
            { caseStatus: 'PaymentInstruction', formComponent: form_case_payment_instruction_component_1.FormCasePaymentInstructionComponent, saveEndpoint: 'payment-instruction' },
            { caseStatus: 'PaymentOrder', formComponent: form_case_payment_order_component_1.FormCasePaymentOrderComponent, saveEndpoint: 'payment-order' },
            { caseStatus: 'SignedSentOwners', formComponent: form_case_signed_send_owners_component_1.FormCaseSignedSendOwnersComponent, saveEndpoint: 'signed-sent-owners' },
            { caseStatus: 'ContractRegisterPublication', saveEndpoint: 'contract-register-publication' },
            { caseStatus: 'Cancelled', saveEndpoint: 'cancel' },
        ];
    };
    CaseService.prototype.getGenerateButtons = function (obligationType) {
        return [
            { key: 'generate', name: 'Generovat dokumenty', templates: [obligationType + 'Contract', obligationType + 'CoveringLetter', 'OtherDocument'] },
            { key: 'generateFinancialControl', name: 'Generovat dokument finanční kontroly', templates: ['FinancialControl'] },
            { key: 'paymentCheckPreliminary', name: 'Generovat proformu žádosti o úhradu', templates: [obligationType + 'PaymentInstructionPreliminary'] },
            { key: 'paymentCheck', name: 'Generovat žádost o úhradu', templates: [obligationType + 'PaymentInstruction'] }
        ];
    };
    CaseService.prototype.showEasementDistributionSystemParts = function (obligation) {
        return this.authService.getActualProject().easementDistributionSystemParts && obligation.easementDistributionSystemParts;
    };
    CaseService.prototype.getSimpleCaseStatusConfig = function (id) {
        return this.simpleCaseStatusConfig.find(function (c) { return c.id === id; });
    };
    CaseService.ngInjectableDef = i0.defineInjectable({ factory: function CaseService_Factory() { return new CaseService(i0.inject(i1.DatePipe), i0.inject(i2.SubjectNamePipe), i0.inject(i3.AuthService)); }, token: CaseService, providedIn: "root" });
    return CaseService;
}());
exports.CaseService = CaseService;
