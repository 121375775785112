"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var CellAbstractComponent = /** @class */ (function () {
    function CellAbstractComponent() {
    }
    Object.defineProperty(CellAbstractComponent.prototype, "tooltipType", {
        set: function (type) {
            this.tooltipComponent = CellAbstractComponent.getTooltipComponent(type, this.TOOLTIP_MAPPING);
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Get Tooltip component by string name
     * @param type
     * @param mappings
     */
    CellAbstractComponent.getTooltipComponent = function (type, mappings) {
        return type && mappings && mappings[type] ? mappings[type] : null;
    };
    return CellAbstractComponent;
}());
exports.CellAbstractComponent = CellAbstractComponent;
