"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("@uirouter/angular/lib/directives/uiSref");
var i2 = require("@uirouter/core");
var i3 = require("../../../../common/directives/auth.directive");
var i4 = require("../../../../common/services/auth.service");
var i5 = require("../../../../common/components/help/help.component.ngfactory");
var i6 = require("../../../../common/components/help/help.component");
var i7 = require("./settings.component");
var styles_SettingsComponent = [];
var RenderType_SettingsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SettingsComponent, data: {} });
exports.RenderType_SettingsComponent = RenderType_SettingsComponent;
function View_SettingsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 33, "div", [["class", "tasks"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 30, "fieldset", [["class", "mt-40 bordered"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "legend", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Nastaven\u00ED "])), (_l()(), i0.ɵeld(4, 0, null, null, 27, "div", [["class", "home-actions"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 5, "div", [["class", "column-25"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 4, "a", [["uiSref", "dimap.project.settings.changePassword"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 8).go($event.button, $event.ctrlKey, $event.metaKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(7, 16384, null, 0, i1.AnchorUISref, [i0.ElementRef, i0.Renderer2], null, null), i0.ɵdid(8, 737280, null, 0, i1.UISref, [i2.UIRouter, [2, i1.AnchorUISref], "UIView.PARENT_INJECT"], { state: [0, "state"] }, null), (_l()(), i0.ɵeld(9, 0, null, null, 0, "i", [["class", "fa fa-key"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Zm\u011Bna p\u0159ihla\u0161ovac\u00EDch \u00FAdaj\u016F "])), (_l()(), i0.ɵeld(11, 0, null, null, 6, "div", [["class", "column-25"], ["gmtAuth", "admin"]], null, null, null, null, null)), i0.ɵdid(12, 81920, null, 0, i3.AuthDirective, [i0.ElementRef, i4.AuthService], { gmtAuth: [0, "gmtAuth"] }, null), (_l()(), i0.ɵeld(13, 0, null, null, 4, "a", [["uiSref", "dimap.project.settings.users"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 15).go($event.button, $event.ctrlKey, $event.metaKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(14, 16384, null, 0, i1.AnchorUISref, [i0.ElementRef, i0.Renderer2], null, null), i0.ɵdid(15, 737280, null, 0, i1.UISref, [i2.UIRouter, [2, i1.AnchorUISref], "UIView.PARENT_INJECT"], { state: [0, "state"] }, null), (_l()(), i0.ɵeld(16, 0, null, null, 0, "i", [["class", "fa fa-users"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Spr\u00E1va u\u017Eivatel\u016F "])), (_l()(), i0.ɵeld(18, 0, null, null, 6, "div", [["class", "column-25"], ["gmtAuth", "admin"]], null, null, null, null, null)), i0.ɵdid(19, 81920, null, 0, i3.AuthDirective, [i0.ElementRef, i4.AuthService], { gmtAuth: [0, "gmtAuth"] }, null), (_l()(), i0.ɵeld(20, 0, null, null, 4, "a", [["uiSref", "dimap.project.settings.editProject"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 22).go($event.button, $event.ctrlKey, $event.metaKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(21, 16384, null, 0, i1.AnchorUISref, [i0.ElementRef, i0.Renderer2], null, null), i0.ɵdid(22, 737280, null, 0, i1.UISref, [i2.UIRouter, [2, i1.AnchorUISref], "UIView.PARENT_INJECT"], { state: [0, "state"] }, null), (_l()(), i0.ɵeld(23, 0, null, null, 0, "i", [["class", "fa fa-pencil"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Editace projektu "])), (_l()(), i0.ɵeld(25, 0, null, null, 6, "div", [["class", "column-25"], ["gmtAuth", "admin"]], null, null, null, null, null)), i0.ɵdid(26, 81920, null, 0, i3.AuthDirective, [i0.ElementRef, i4.AuthService], { gmtAuth: [0, "gmtAuth"] }, null), (_l()(), i0.ɵeld(27, 0, null, null, 4, "a", [["uiSref", "dimap.project.settings.createProject"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 29).go($event.button, $event.ctrlKey, $event.metaKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(28, 16384, null, 0, i1.AnchorUISref, [i0.ElementRef, i0.Renderer2], null, null), i0.ɵdid(29, 737280, null, 0, i1.UISref, [i2.UIRouter, [2, i1.AnchorUISref], "UIView.PARENT_INJECT"], { state: [0, "state"] }, null), (_l()(), i0.ɵeld(30, 0, null, null, 0, "i", [["class", "fa fa-plus"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Nov\u00FD projekt "])), (_l()(), i0.ɵeld(32, 0, null, null, 1, "help", [["class", "cm-help-page"]], null, null, null, i5.View_HelpComponent_0, i5.RenderType_HelpComponent)), i0.ɵdid(33, 114688, null, 0, i6.HelpComponent, [i4.AuthService, "HELP_IDS", "HelpService", "ModalService"], { helpId: [0, "helpId"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "dimap.project.settings.changePassword"; _ck(_v, 8, 0, currVal_0); var currVal_1 = "admin"; _ck(_v, 12, 0, currVal_1); var currVal_2 = "dimap.project.settings.users"; _ck(_v, 15, 0, currVal_2); var currVal_3 = "admin"; _ck(_v, 19, 0, currVal_3); var currVal_4 = "dimap.project.settings.editProject"; _ck(_v, 22, 0, currVal_4); var currVal_5 = "admin"; _ck(_v, 26, 0, currVal_5); var currVal_6 = "dimap.project.settings.createProject"; _ck(_v, 29, 0, currVal_6); var currVal_7 = _co.helpIds.VM_PANEL_SETTINGS; _ck(_v, 33, 0, currVal_7); }, null); }
exports.View_SettingsComponent_0 = View_SettingsComponent_0;
function View_SettingsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "settings", [], null, null, null, View_SettingsComponent_0, RenderType_SettingsComponent)), i0.ɵdid(1, 114688, null, 0, i7.SettingsComponent, ["HELP_IDS"], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_SettingsComponent_Host_0 = View_SettingsComponent_Host_0;
var SettingsComponentNgFactory = i0.ɵccf("settings", i7.SettingsComponent, View_SettingsComponent_Host_0, {}, {}, []);
exports.SettingsComponentNgFactory = SettingsComponentNgFactory;
