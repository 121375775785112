"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var EasementCreateStepComponent = /** @class */ (function () {
    function EasementCreateStepComponent() {
        this.backCallback = new core_1.EventEmitter();
        this.submitCallback = new core_1.EventEmitter();
    }
    return EasementCreateStepComponent;
}());
exports.EasementCreateStepComponent = EasementCreateStepComponent;
