"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var BulkPriceStepComponent = /** @class */ (function () {
    function BulkPriceStepComponent() {
        this.backCallback = new core_1.EventEmitter();
        this.submitCallback = new core_1.EventEmitter();
        this.priceType = [
            {
                value: 'Cenový výměr ministerstva financí',
                id: 'F',
            },
            {
                value: 'Znalecký posudek',
                id: 'E',
            },
            {
                value: 'Jiný způsob',
                id: 'O',
            },
        ];
        this.priceUnit = [
            {
                value: 'Roční cena za metr čtvereční',
                id: 'A',
            },
            {
                value: 'Jednorázová cena',
                id: 'T',
            },
        ];
    }
    return BulkPriceStepComponent;
}());
exports.BulkPriceStepComponent = BulkPriceStepComponent;
