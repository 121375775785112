"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var TitleDetailComponent = /** @class */ (function () {
    function TitleDetailComponent(helpIds, restangular) {
        this.helpIds = helpIds;
        this.restangular = restangular;
        this.loading = true;
    }
    TitleDetailComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.restangular.one('titles', this.titleId).customPOST({
            attributes: {
                area: {},
                ownerships: {
                    mapping: {
                        attributes: {
                            subject: {
                                mapping: {
                                    attributes: {
                                        subjectSjm1: {},
                                        subjectSjm2: {}
                                    }
                                }
                            }
                        }
                    }
                },
                parcels: {}
            }
        }).then(function (data) {
            _this.data = data;
            _this.loading = false;
        });
    };
    TitleDetailComponent.prototype.getSubject = function (ownership) {
        return ownership.subject.opsubType === 'BSM' ? ownership.subject.subjectSjm1 : ownership.subject;
    };
    return TitleDetailComponent;
}());
exports.TitleDetailComponent = TitleDetailComponent;
