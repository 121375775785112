"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var map_layer_type_enum_1 = require("@app/map/enums/map-layer-type.enum");
var map_storage_service_1 = require("@app/map/services/map-storage.service");
var map_layers_storage_service_1 = require("@app/map/services/map-layers-storage.service");
var map_layer_service_1 = require("@app/map/services/map-layer.service");
var i0 = require("@angular/core");
var i1 = require("./map-storage.service");
var i2 = require("./map-layers-storage.service");
var i3 = require("./map-layer.service");
var MapService = /** @class */ (function () {
    function MapService(mapStorageService, mapLayersStorage, mapLayerService) {
        this.mapStorageService = mapStorageService;
        this.mapLayersStorage = mapLayersStorage;
        this.mapLayerService = mapLayerService;
    }
    /**
     * Registers listener called with center positions and zoom every move end.
     * @param {String} mapId
     * @param {function} callback
     */
    MapService.prototype.addPositionListener = function (mapId, callback) {
        return this.mapStorageService.getMap(mapId).then(function (map) {
            if (typeof callback !== 'function') {
                throw new Error('No callback function provided.');
            }
            map.on('moveend', function (event) {
                if (event && event.target) {
                    var mapTarget = event.target, center = mapTarget.getCenter(), zoom = mapTarget.getZoom();
                    callback.call(this, center.lat, center.lng, zoom);
                }
            });
        });
    };
    MapService.prototype.getMap = function (mapId) {
        return this.mapStorageService.getMap(mapId);
    };
    MapService.prototype.fitBounds = function (mapId, bounds) {
        return this.mapStorageService.getMap(mapId).then(function (map) {
            map.fitBounds(bounds);
        });
    };
    /**
     * If positionListener option is setted, registers position listener.
     * @param {String} mapId
     * @param {object} opts
     */
    MapService.prototype.setPositionListener = function (mapId, opts) {
        if (opts.positionListener) {
            this.addPositionListener(mapId, opts.positionListener);
        }
    };
    MapService.prototype.toggleFullscreen = function (mapId) {
        return this.mapStorageService.getMap(mapId).then(function (map) {
            map.fullscreenControl.toggleFullScreen(map);
        });
    };
    MapService.prototype.enableLoadingWatch = function (mapId) {
        /* return CmMapStorage.getMap(mapId).then(function(map) {
    
            map.on('layeradd', function (e) {
    
                if (e.layer.loading) console.log('start');
    
                if (typeof e.layer.on != 'function') return;
    
                e.layer.on('data:loading', function () { console.log('start'); });
                e.layer.on('data:loaded',  function () { console.log('stop'); });
    
            });
    
    
        });*/
        var _this = this;
        return this.mapLayersStorage.getLayers(mapId).then(function (config) {
            var fnc = function (conf) {
                if (conf.type === map_layer_type_enum_1.MapLayerTypeEnum.wms) {
                    //var layer = config.layerObj;
                    // console.log(layer)
                }
            };
            _this.mapLayerService.forEachLayer(config, fnc);
        });
        // this.on('layerremove', function (e) {
        // if (e.layer.loading) this.spin(false);
        // if (typeof e.layer.on != 'function') return;
        // e.layer.off('data:loaded');
        // e.layer.off('data:loading');
        // }, this);
    };
    MapService.ngInjectableDef = i0.defineInjectable({ factory: function MapService_Factory() { return new MapService(i0.inject(i1.MapStorageService), i0.inject(i2.MapLayersStorageService), i0.inject(i3.MapLayerService)); }, token: MapService, providedIn: "root" });
    return MapService;
}());
exports.MapService = MapService;
