"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var dialog_config_1 = require("@app/dialog/dialog-config");
var dialog_ref_1 = require("@app/dialog/dialog-ref");
var TitleCreateSubjectComponent = /** @class */ (function () {
    function TitleCreateSubjectComponent(restangular, dialogConfig, dialogRef) {
        this.restangular = restangular;
        this.dialogConfig = dialogConfig;
        this.dialogRef = dialogRef;
        this.data = {};
        this.sending = false;
    }
    TitleCreateSubjectComponent.prototype.ngOnInit = function () {
    };
    TitleCreateSubjectComponent.prototype.create = function () {
        var _this = this;
        if (!this.sending) {
            this.sending = true;
            this.data.opsubType = (this.data.charosKod === 2) ? 'OFO' : 'OPO';
            return this.restangular.all('subjects/create')
                .post(this.data)
                .then(function (data) {
                _this.dialogRef.close(data.plain());
                _this.sending = false;
            });
        }
    };
    return TitleCreateSubjectComponent;
}());
exports.TitleCreateSubjectComponent = TitleCreateSubjectComponent;
