"use strict";
var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var _ = require("lodash");
var list_model_1 = require("../../../common/models/list.model");
var list_service_1 = require("../../../common/services/list.service");
var auth_service_1 = require("../../../common/services/auth.service");
var dialog_service_1 = require("../../../dialog/dialog.service");
var confirmation_component_1 = require("../../../dialog/confirmation/confirmation.component");
var class_name_enum_1 = require("../../../dialog/class-name.enum");
var NotificationsComponent = /** @class */ (function () {
    function NotificationsComponent(restangular, localStorageService, EVENTS, rootCallbackService, APP_BRAND, authService, listService, dialogService) {
        this.restangular = restangular;
        this.localStorageService = localStorageService;
        this.EVENTS = EVENTS;
        this.rootCallbackService = rootCallbackService;
        this.APP_BRAND = APP_BRAND;
        this.authService = authService;
        this.listService = listService;
        this.dialogService = dialogService;
        // bindings
        this.readonly = false;
        this.updated = new core_1.EventEmitter();
        this.readonlyState = true;
        // model
        this.notificationFilter = [];
        this.notificationFilterSelectionVisible = NotificationsComponent.NOTIFICATION_FILTER[0];
        this.selectedNotifications = [];
        this.checkedAllNotifications = false;
        this.scrollTop = function () { };
        this.onCanScrollTop = this.onCanScrollTop.bind(this);
        this.onNotificationFilterChanged = this.onNotificationFilterChanged.bind(this);
        this.onChangeNotificationsOrder = this.onChangeNotificationsOrder.bind(this);
        this.onLoadNextNotifications = this.onLoadNextNotifications.bind(this);
        this.onCheckAllNotifications = this.onCheckAllNotifications.bind(this);
        this.onDeleteNotification = this.onDeleteNotification.bind(this);
        this.onArchiveNotification = this.onArchiveNotification.bind(this);
        this.onToggleNotificationSelection = this.onToggleNotificationSelection.bind(this);
    }
    NotificationsComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (!this.readonly) {
            this.notificationFilter = NotificationsComponent.NOTIFICATION_FILTER;
        }
        if (!this.notificationList) {
            this.storageKey = this.authService.getActualProject().key + "." + 'homeNotificationsOrder';
            this.loadNotificationList();
            this.onNotificationFilterChanged();
        }
        else {
            this.notificationList.promise.then(function () {
                _this.readonlyState = _this.readonly || !_this.notificationList.list.some(function (notification) { return !!notification.user; });
            });
        }
    };
    NotificationsComponent.prototype.ngOnDestroy = function () {
        if (this.storageKey) {
            this.listService.cancelFetch(this.notificationList);
        }
    };
    NotificationsComponent.prototype.onCanScrollTop = function (scrollbarEvent) {
        this.scrollTop = scrollbarEvent.scrollTop;
    };
    ;
    NotificationsComponent.prototype.notifyChange = function () {
        this.readonlyState = this.readonly || !this.notificationList.list.some(function (notification) { return !!notification.user; });
        this.updated.emit({
            type: this.notificationList.filter.filters.archive ? 'archived' : 'unarchived',
            data: this.notificationList,
        });
    };
    NotificationsComponent.prototype.onNotificationFilterChanged = function (value) {
        var _this = this;
        if (value === void 0) { value = this.notificationFilterSelectionVisible; }
        this.scrollTop();
        this.notificationList.filter.filters.archive = (value.id === 'A');
        this.notificationList.filter.offset = 0;
        this.listService.fetchResult(this.notificationList).then(function () {
            _this.notificationFilterSelectionVisible = value;
            _this.uncheckAllNotifications();
            _this.notifyChange();
        });
    };
    ;
    NotificationsComponent.prototype.onChangeNotificationsOrder = function () {
        var _this = this;
        var order = this.loadNotificationsOrderSetting() === 'desc' ? 'asc' : 'desc';
        this.notificationList.filter.sortOrder.direction = order;
        if (this.storageKey) {
            this.localStorageService.set(this.storageKey, order);
        }
        this.notificationList.filter.offset = 0;
        this.listService.fetchResult(this.notificationList).then(function () { _this.uncheckAllNotifications(); });
    };
    ;
    NotificationsComponent.prototype.onLoadNextNotifications = function () {
        this.notificationList.filter.offset += this.notificationList.filter.limit;
        this.listService.fetchResult(this.notificationList, true);
        this.checkedAllNotifications = false;
    };
    ;
    NotificationsComponent.prototype.onCheckAllNotifications = function () {
        if (this.checkedAllNotifications) {
            this.selectedNotifications = [];
        }
        else {
            this.selectedNotifications = [].concat(this.notificationList.list.filter(function (notification) { return notification.user; }));
        }
        this.checkedAllNotifications = !this.checkedAllNotifications;
    };
    NotificationsComponent.prototype.onToggleNotificationSelection = function (notification, event) {
        if (event.target.nodeName === 'A' || !this.canModify(notification)) {
            return null;
        }
        var index = this.selectedNotifications.indexOf(notification);
        if (index !== -1) {
            this.selectedNotifications.splice(index, 1);
            this.checkedAllNotifications = false;
        }
        else {
            this.selectedNotifications.push(notification);
            if (this.selectedNotifications.length === this.notificationList.list.length) {
                this.checkedAllNotifications = true;
            }
        }
    };
    NotificationsComponent.prototype.onDeleteNotification = function (notifications) {
        var _this = this;
        var ids = _.map(notifications, 'id');
        var dialog = this.dialogService.open(confirmation_component_1.ConfirmationComponent, {
            data: {
                msg: 'Opravdu chcete upozornění smazat?',
            },
            className: class_name_enum_1.ClassName.ADJUSTED_DIALOG,
        });
        var sub = dialog.afterClosed.subscribe(function (result) {
            if (result) {
                _this.notificationList.loading = true;
                _this.restangular.all('notifications').customPUT(ids, 'hide').then(function () {
                    _this.selectedNotifications = _.difference(_this.selectedNotifications, notifications);
                    _this.notificationList.list = _.difference(_this.notificationList.list, notifications);
                    _this.notificationList.itemCount -= notifications.length;
                    _this.notificationList.filter.offset -= notifications.length;
                    var countWithWarning = notifications.reduce(function (total, item) {
                        return total + (item.withWarning && !item.archived ? 1 : 0);
                    }, 0);
                    if (countWithWarning > 0) {
                        _this.rootCallbackService.get(_this.EVENTS.notificationWithWarningHidden)(countWithWarning);
                    }
                    if (_this.notificationList.list.length === 0 && _this.notificationList.itemCount > 0) {
                        _this.onLoadNextNotifications();
                    }
                    _this.uncheckAllNotifications();
                    _this.notifyChange();
                    _this.notificationList.loading = false;
                });
            }
            sub.unsubscribe();
        });
    };
    NotificationsComponent.prototype.onArchiveNotification = function (notifications) {
        var _this = this;
        var ids = _.map(notifications, 'id');
        this.notificationList.loading = true;
        this.restangular.all('notifications').customPUT(ids, 'archive').then(function () {
            _this.selectedNotifications = _.difference(_this.selectedNotifications, notifications);
            _this.notificationList.list = _.difference(_this.notificationList.list, notifications);
            _this.notificationList.itemCount -= notifications.length;
            _this.notificationList.filter.offset -= notifications.length;
            _this.notificationList.loading = false;
            var countWithWarning = notifications.reduce(function (total, item) {
                return total + (item.withWarning && !item.archived ? 1 : 0);
            }, 0);
            if (countWithWarning > 0) {
                _this.rootCallbackService.get(_this.EVENTS.notificationWithWarningHidden)(countWithWarning);
            }
            if (_this.notificationList.list.length === 0 && _this.notificationList.itemCount > 0) {
                _this.onLoadNextNotifications();
            }
            _this.uncheckAllNotifications();
            _this.notifyChange();
        });
    };
    NotificationsComponent.prototype.isNotificationChecked = function (notification) {
        return _.findIndex(this.selectedNotifications, notification) !== -1;
    };
    NotificationsComponent.prototype.canModify = function (notification) {
        return this.authService.isAuthorized(notification.user);
    };
    NotificationsComponent.prototype.canModifyAllNotifications = function () {
        var e_1, _a;
        try {
            for (var _b = __values(this.notificationList.list), _c = _b.next(); !_c.done; _c = _b.next()) {
                var notification = _c.value;
                if (!this.canModify(notification)) {
                    return false;
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return true;
    };
    NotificationsComponent.prototype.loadNotificationList = function () {
        this.notificationList = this.listService.createList('notifications', {
            sortOrder: { sortBy: 'createdTimestamp', direction: this.loadNotificationsOrderSetting() },
            filters: {
                loadCollections: ['knBudouc.futureParcel'],
                hidden: [false],
                currentUser: [true],
            }
        });
    };
    NotificationsComponent.prototype.uncheckAllNotifications = function () {
        this.checkedAllNotifications = false;
        this.selectedNotifications = [];
    };
    NotificationsComponent.prototype.loadNotificationsOrderSetting = function () {
        var value = this.storageKey ? this.localStorageService.get(this.storageKey) : this.notificationList.filter.sortOrder.direction;
        return (value && value === 'desc') ? "desc" : "asc";
    };
    ;
    NotificationsComponent.NOTIFICATION_FILTER = [
        { id: 'U', name: 'Doručená' },
        { id: 'A', name: 'Archivovaná' }
    ];
    return NotificationsComponent;
}());
exports.NotificationsComponent = NotificationsComponent;
