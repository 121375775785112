"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var DocumentTypeService = /** @class */ (function () {
    function DocumentTypeService(restangular) {
        this.restangular = restangular;
    }
    DocumentTypeService.prototype.upload = function (documentType) {
        return this.restangular.one('document-types').customPUT(documentType)
            .then(function (data) {
            return data.plain();
        });
    };
    DocumentTypeService.ngInjectableDef = i0.defineInjectable({ factory: function DocumentTypeService_Factory() { return new DocumentTypeService(i0.inject("Restangular")); }, token: DocumentTypeService, providedIn: "root" });
    return DocumentTypeService;
}());
exports.DocumentTypeService = DocumentTypeService;
