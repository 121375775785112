"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var auth_service_1 = require("@app/common/services/auth.service");
var list_service_1 = require("@app/common/services/list.service");
var title_names_pipe_1 = require("@app/common/pipes/title-names.pipe");
var _ = require("lodash");
var _Fraction = require("fraction.js");
var Fraction = _Fraction;
var CreateCaseConfirmComponent = /** @class */ (function () {
    function CreateCaseConfirmComponent(authService, listService, titleNamesPipe, restangular, confirmService, alertService) {
        this.authService = authService;
        this.listService = listService;
        this.titleNamesPipe = titleNamesPipe;
        this.restangular = restangular;
        this.confirmService = confirmService;
        this.alertService = alertService;
        this.submitCallback = new core_1.EventEmitter();
        this.backCallback = new core_1.EventEmitter();
        this.sending = false;
        this.priceTotal = 0;
        this.parcelCount = 0;
        this.create = this.create.bind(this);
    }
    CreateCaseConfirmComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.data.createdCases = [];
        this.count = this.data.cases.length;
        this.data.cases.forEach(function (businessCase) {
            _this.priceTotal += businessCase.totalEasementPrice;
        });
        this.parcelCount = new Set(this.data.easementsSelected.map(function (easement) { return easement.parcel.id; })).size;
    };
    CreateCaseConfirmComponent.prototype.create = function () {
        var _this = this;
        this.sending = true;
        var createNext = function () {
            var promise = Promise.resolve();
            var businessCase = _this.data.cases[_this.data.cases.length - _this.count];
            if (businessCase.id) {
                _this.count--;
                if (_this.count === 0) {
                    _this.submitCallback.emit();
                }
                else {
                    return createNext();
                }
            }
            return promise.then(function () { return _this.restangular.all('cases/create').post(businessCase, { loadCollections: ['titles'] }).then(function (data) {
                _this.data.createdCases.push(data);
                _this.data.cases[_this.data.cases.length - _this.count] = _.assign(_this.restangular.stripRestangular(data), _this.data.cases[_this.data.cases.length - _this.count]);
                _this.count--;
                if (_this.count === 0) {
                    _this.submitCallback.emit();
                }
                else {
                    return createNext();
                }
            }, function (result) {
                _this.sending = false;
                return Promise.reject(result);
            }); });
        };
        return createNext();
    };
    return CreateCaseConfirmComponent;
}());
exports.CreateCaseConfirmComponent = CreateCaseConfirmComponent;
