"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var _ = require("lodash");
var DataZeComponent = /** @class */ (function () {
    function DataZeComponent(restangular, uiconstants) {
        this.restangular = restangular;
        this.uiconstants = uiconstants;
        this.precheckCompleted = false;
        this.sortOrder = { sortBy: 'roleName', direction: 'asc' };
        this.itemsPerPage = this.uiconstants.itemsPerPage10;
        this.dataPaging = { itemsPerPage: { id: 10, name: 10 } };
        this.precheck = [];
        this.onPrecheck = this.onPrecheck.bind(this);
        this.onUpdate = this.onUpdate.bind(this);
    }
    DataZeComponent.prototype.ngOnInit = function () {
    };
    DataZeComponent.prototype.onPrecheck = function () {
        var _this = this;
        this.updateResult = undefined;
        return this.restangular
            .one('construction-objects/precheck-symap-update')
            .post()
            .then(function (data) {
            var domain = window.location.protocol + '//' + window.location.hostname + (window.location.port ? (':' + window.location.port) : '');
            _this.precheck = data.plain().map(function (i) {
                i.url = i.url ? i.url.replace('https://app.gmtech.cz', domain) : null;
                return i;
            });
            _this.initList();
            _this.precheckCompleted = true;
        });
    };
    DataZeComponent.prototype.onUpdate = function () {
        var _this = this;
        this.updateResult = undefined;
        return this.restangular
            .one('construction-objects/update-occupation-symap-tables')
            .post()
            .then(function (r) {
            if (r && r.message) {
                _this.updateResult = r.message;
            }
            else {
                _this.updateResult = 'Update dokončen';
            }
        });
    };
    DataZeComponent.prototype.pagesCounterCallbackRegister = function (event) {
        this.onListChangedPagesCounter = event.onListChanged;
    };
    DataZeComponent.prototype.itemCounterCallbackRegister = function (event) {
        this.onListChangedItemCounter = event.onListChanged;
    };
    DataZeComponent.prototype.paginationCallbackRegister = function (event) {
        this.onListChangedPagination = event.onListChanged;
    };
    DataZeComponent.prototype.onSort = function (sortBy) {
        this.sortOrder.direction = sortBy === this.sortOrder.sortBy
            ? this.sortOrder.direction === 'asc' ? 'desc' : 'asc'
            : 'asc';
        this.sortOrder.sortBy = sortBy;
        this.reloadList();
    };
    DataZeComponent.prototype.onPageChanged = function (event) {
        this.precheckList.filter.offset = (event - 1) * this.dataPaging.itemsPerPage.id;
        this.reloadList();
    };
    DataZeComponent.prototype.onPageItemsChanged = function () {
        this.precheckList.filter.offset = 0;
        this.reloadList();
    };
    DataZeComponent.prototype.onListChanged = function () {
        if (this.onListChangedPagination) {
            this.onListChangedPagination();
        }
        if (this.onListChangedItemCounter) {
            this.onListChangedItemCounter();
        }
        if (this.onListChangedPagesCounter) {
            this.onListChangedPagesCounter();
        }
    };
    DataZeComponent.prototype.reloadList = function () {
        this.precheck = _.orderBy(this.precheck, this.sortOrder.sortBy, this.sortOrder.direction);
        this.precheckList.list = this.precheck.slice(this.precheckList.filter.offset, this.precheckList.filter.offset + this.dataPaging.itemsPerPage.id);
        this.precheckList.itemCount = this.precheck.length;
        this.precheckList.filter.limit = this.dataPaging.itemsPerPage.id;
        this.onListChanged();
    };
    DataZeComponent.prototype.initList = function () {
        this.precheckList = {
            loading: false,
            list: this.precheck.slice(0, this.dataPaging.itemsPerPage.id),
            itemCount: this.precheck.length,
            filter: { offset: 0, limit: this.dataPaging.itemsPerPage.id }
        };
    };
    return DataZeComponent;
}());
exports.DataZeComponent = DataZeComponent;
