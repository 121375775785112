"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./button.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/common");
var i3 = require("../../directives/loading.directive");
var i4 = require("./button.component");
var styles_ButtonComponent = [i0.styles];
var RenderType_ButtonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ButtonComponent, data: {} });
exports.RenderType_ButtonComponent = RenderType_ButtonComponent;
function View_ButtonComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "a", [["class", "button not-underline"]], [[8, "title", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.doAction($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { disabled: 0 }), (_l()(), i1.ɵeld(3, 0, null, null, 2, "span", [["class", "button-loading small-spinner"]], [[8, "className", 0]], null, null, null, null)), i1.ɵdid(4, 16384, null, 0, i3.LoadingDirective, [i1.ElementRef], { loading: [0, "loading"] }, null), i1.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "button not-underline"; var currVal_2 = _ck(_v, 2, 0, _co.isDisabled()); _ck(_v, 1, 0, currVal_1, currVal_2); var currVal_4 = _co.processing; _ck(_v, 4, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", (_co.isDisabled() ? "Vypl\u0148te pros\u00EDm povinn\u00E9 \u00FAdaje" : ""), ""); _ck(_v, 0, 0, currVal_0); var currVal_3 = i1.ɵnov(_v, 4).elementClass; _ck(_v, 3, 0, currVal_3); }); }
exports.View_ButtonComponent_0 = View_ButtonComponent_0;
function View_ButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "gmt-button", [], null, null, null, View_ButtonComponent_0, RenderType_ButtonComponent)), i1.ɵdid(1, 114688, null, 0, i4.ButtonComponent, ["ErrorHandlerService", i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_ButtonComponent_Host_0 = View_ButtonComponent_Host_0;
var ButtonComponentNgFactory = i1.ɵccf("gmt-button", i4.ButtonComponent, View_ButtonComponent_Host_0, { clickAction: "clickAction", gmtDisabled: "gmtDisabled" }, { callback: "callback" }, ["*"]);
exports.ButtonComponentNgFactory = ButtonComponentNgFactory;
