"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["[_nghost-%COMP%] {\n  width: 100%; }\n  [_nghost-%COMP%]   .percentage[_ngcontent-%COMP%] {\n    width: 60px; }\n    [_nghost-%COMP%]   .percentage.percentage-na[_ngcontent-%COMP%] {\n      color: #a3a3a3; }\n  [_nghost-%COMP%]   .progress[_ngcontent-%COMP%] {\n    width: 100%;\n    background: #eeeeee;\n    height: 5px; }\n    [_nghost-%COMP%]   .progress[_ngcontent-%COMP%]   .progress-bar[_ngcontent-%COMP%] {\n      height: 5px; }\n  [_nghost-%COMP%]   .bg-CREATED[_ngcontent-%COMP%] {\n    background-color: #ffa011; }\n  [_nghost-%COMP%]   .bg-STARTED[_ngcontent-%COMP%] {\n    background-color: #19bb27; }\n  [_nghost-%COMP%]   .bg-STARTED_COMPLETE[_ngcontent-%COMP%] {\n    background-color: #19bb27; }\n  [_nghost-%COMP%]   .bg-STARTED_PARTIAL[_ngcontent-%COMP%] {\n    background-color: #01fed8; }\n  [_nghost-%COMP%]   .bg-PAUSED[_ngcontent-%COMP%] {\n    background-color: #203864; }\n  [_nghost-%COMP%]   .bg-FINISHED[_ngcontent-%COMP%] {\n    background-color: #a8a8a8; }"];
exports.styles = styles;
