"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var ticket_status_enum_1 = require("@app/sv/enums/ticket-status.enum");
var auth_service_1 = require("@app/common/services/auth.service");
var document_type_model_1 = require("@app/common/models/document-type.model");
var i0 = require("@angular/core");
var i1 = require("../../common/services/auth.service");
var TicketService = /** @class */ (function () {
    function TicketService(restangular, uploadService, authService) {
        this.restangular = restangular;
        this.uploadService = uploadService;
        this.authService = authService;
    }
    TicketService.prototype.getById = function (ticketId) {
        return this.restangular.one('tickets', ticketId)
            .get()
            .then(function (ticket) {
            return ticket.plain();
        });
    };
    TicketService.prototype.create = function (ticket, samples) {
        var _this = this;
        var createdTicket;
        return this.restangular
            .all('tickets/create')
            .customPOST(ticket, '', { loadCollections: [] })
            .then(function (ticket) {
            createdTicket = ticket.plain();
        })
            .then(function () { return _this.createDocuments(createdTicket, samples, 'SAMPLE'); })
            .then(function () { return createdTicket; });
    };
    TicketService.prototype.createBySample = function (ticket, sample, samples) {
        var _this = this;
        ticket.documentType = sample.documentType;
        ticket.section = sample.section;
        ticket.organizationalUnitName = sample.organizationalUnitName;
        ticket.organizationalUnitId = sample.organizationalUnitId;
        ticket.organizationalUnitCode = sample.organizationalUnitCode;
        ticket.actualizedSample = sample;
        var createdTicket;
        return this.restangular
            .all('tickets/create')
            .customPOST(ticket, '', { loadCollections: [] })
            .then(function (ticket) {
            createdTicket = ticket.plain();
        })
            .then(function () { return _this.createDocuments(createdTicket, samples, 'SAMPLE'); })
            .then(function () { return createdTicket; });
    };
    TicketService.prototype.update = function (ticket, templates, note) {
        var _this = this;
        return this.createDocuments(ticket, templates, 'TEMPLATE')
            .then(function () { return _this.createNotes(ticket, (note ? [note] : [])); })
            .then(function () {
            return _this.restangular
                .one('tickets', ticket.id)
                .customPUT(ticket, '', { loadCollections: [] });
        })
            .then(function (ticket) {
            return ticket.plain();
        });
    };
    TicketService.prototype.getStatusEnumAction = function (value) {
        var option = ticket_status_enum_1.ticketStatusOptions.find(function (option) { return option.id === value; });
        return (option.action ? option.action : option.name);
    };
    TicketService.prototype.createNotes = function (ticket, notes) {
        var _this = this;
        if (notes === void 0) { notes = []; }
        var promises = notes.map(function (note) {
            return _this.restangular
                .all('notes/create')
                .customPOST(__assign({}, note, { ticket: ticket }));
        });
        return Promise.all(promises);
    };
    TicketService.prototype.createDocuments = function (ticket, files, type) {
        var _this = this;
        var restBaseUrl = this.authService.getActiveApplicationRestUrl();
        var attachmentType = document_type_model_1.DocumentTypeModel.createById(type).id;
        var promises = files.map(function (file) {
            var filename = file.fileEntry.name;
            if (ticket.sampleNumber) {
                filename = ticket.sampleNumber + (type === 'TEMPLATE' ? '_s' : '') + '.' + file.extension.toLowerCase();
            }
            var sendData = {
                file: file.fileEntry,
                filename: file.fileEntry.name,
                ticketId: ticket.id,
                attachmentType: attachmentType,
                baseName: filename
            };
            return _this.uploadService.upload({
                url: restBaseUrl + '/attachments',
                data: sendData,
                headers: __assign({}, _this.restangular.defaultHeaders)
            });
        });
        return Promise.all(promises);
    };
    TicketService.ngInjectableDef = i0.defineInjectable({ factory: function TicketService_Factory() { return new TicketService(i0.inject("Restangular"), i0.inject("Upload"), i0.inject(i1.AuthService)); }, token: TicketService, providedIn: "root" });
    return TicketService;
}());
exports.TicketService = TicketService;
