"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./main.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@uirouter/angular/lib/directives/uiSref");
var i3 = require("@uirouter/core");
var i4 = require("../../../../common/directives/brand-visibility.directive");
var i5 = require("../../../../common/services/brand.service");
var i6 = require("./main.component");
var styles_MainComponent = [i0.styles];
var RenderType_MainComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MainComponent, data: {} });
exports.RenderType_MainComponent = RenderType_MainComponent;
function View_MainComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 20, "div", [["class", "detail"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 19, "div", [["class", "flex-centered fill-content flexbox-container mt-200"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "a", [["class", "bordered"], ["uiSref", "settings.modules.ps"]], [[8, "title", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).go($event.button, $event.ctrlKey, $event.metaKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 16384, null, 0, i2.AnchorUISref, [i1.ElementRef, i1.Renderer2], null, null), i1.ɵdid(4, 737280, null, 0, i2.UISref, [i3.UIRouter, [2, i2.AnchorUISref], "UIView.PARENT_INJECT"], { state: [0, "state"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 0, "img", [["alt", "logo"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 4, "a", [["class", "bordered"], ["uiSref", "settings.modules.dimap"], ["visibleByBrand", "maja"]], [[8, "title", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9).go($event.button, $event.ctrlKey, $event.metaKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(7, 81920, null, 0, i4.BrandVisibilityDirective, [i1.ElementRef, i5.BrandService], { visibleByBrand: [0, "visibleByBrand"] }, null), i1.ɵdid(8, 16384, null, 0, i2.AnchorUISref, [i1.ElementRef, i1.Renderer2], null, null), i1.ɵdid(9, 737280, null, 0, i2.UISref, [i3.UIRouter, [2, i2.AnchorUISref], "UIView.PARENT_INJECT"], { state: [0, "state"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 0, "img", [["alt", "logo"], ["src", "img/logo/dimap_xxl.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 4, "a", [["class", "bordered"], ["uiSref", "settings.modules.pk"], ["visibleByBrand", "maja"]], [[8, "title", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 14).go($event.button, $event.ctrlKey, $event.metaKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(12, 81920, null, 0, i4.BrandVisibilityDirective, [i1.ElementRef, i5.BrandService], { visibleByBrand: [0, "visibleByBrand"] }, null), i1.ɵdid(13, 16384, null, 0, i2.AnchorUISref, [i1.ElementRef, i1.Renderer2], null, null), i1.ɵdid(14, 737280, null, 0, i2.UISref, [i3.UIRouter, [2, i2.AnchorUISref], "UIView.PARENT_INJECT"], { state: [0, "state"] }, null), (_l()(), i1.ɵeld(15, 0, null, null, 0, "img", [["alt", "logo"], ["src", "img/logo/promap_xxl.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 4, "a", [["class", "bordered"], ["uiSref", "settings.modules.vfze"], ["visibleByBrand", "maja"]], [[8, "title", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 19).go($event.button, $event.ctrlKey, $event.metaKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(17, 81920, null, 0, i4.BrandVisibilityDirective, [i1.ElementRef, i5.BrandService], { visibleByBrand: [0, "visibleByBrand"] }, null), i1.ɵdid(18, 16384, null, 0, i2.AnchorUISref, [i1.ElementRef, i1.Renderer2], null, null), i1.ɵdid(19, 737280, null, 0, i2.UISref, [i3.UIRouter, [2, i2.AnchorUISref], "UIView.PARENT_INJECT"], { state: [0, "state"] }, null), (_l()(), i1.ɵeld(20, 0, null, null, 0, "img", [["alt", "logo"], ["src", "img/logo/vfze_xxl.svg"]], null, null, null, null, null))], function (_ck, _v) { var currVal_1 = "settings.modules.ps"; _ck(_v, 4, 0, currVal_1); var currVal_4 = "maja"; _ck(_v, 7, 0, currVal_4); var currVal_5 = "settings.modules.dimap"; _ck(_v, 9, 0, currVal_5); var currVal_7 = "maja"; _ck(_v, 12, 0, currVal_7); var currVal_8 = "settings.modules.pk"; _ck(_v, 14, 0, currVal_8); var currVal_10 = "maja"; _ck(_v, 17, 0, currVal_10); var currVal_11 = "settings.modules.vfze"; _ck(_v, 19, 0, currVal_11); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.APPLICATIONS.sy.title, ""); _ck(_v, 2, 0, currVal_0); var currVal_2 = i1.ɵinlineInterpolate(1, "img/logo/", _co.APP_BRAND.PS.ICON_PATH, "_xxl.svg"); _ck(_v, 5, 0, currVal_2); var currVal_3 = i1.ɵinlineInterpolate(1, "", _co.APPLICATIONS.di.title, ""); _ck(_v, 6, 0, currVal_3); var currVal_6 = i1.ɵinlineInterpolate(1, "", _co.APPLICATIONS.pk.title, ""); _ck(_v, 11, 0, currVal_6); var currVal_9 = i1.ɵinlineInterpolate(1, "", _co.APPLICATIONS.vfze.title, ""); _ck(_v, 16, 0, currVal_9); }); }
exports.View_MainComponent_0 = View_MainComponent_0;
function View_MainComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "modules-main", [], null, null, null, View_MainComponent_0, RenderType_MainComponent)), i1.ɵdid(1, 114688, null, 0, i6.MainComponent, ["APPLICATIONS", "APP_BRAND"], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_MainComponent_Host_0 = View_MainComponent_Host_0;
var MainComponentNgFactory = i1.ɵccf("modules-main", i6.MainComponent, View_MainComponent_Host_0, {}, {}, []);
exports.MainComponentNgFactory = MainComponentNgFactory;
