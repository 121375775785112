"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var L = require("leaflet");
var leaflet_wms_common_service_1 = require("@app/map/services/leaflet-wms-common.service");
var map_ogc_common_service_1 = require("@app/map/services/map-ogc-common.service");
var map_utils_wfs_feature_service_1 = require("@app/map/services/map-utils-wfs-feature.service");
var i0 = require("@angular/core");
var i1 = require("@angular/common/http");
var i2 = require("./leaflet-wms-common.service");
var i3 = require("./map-ogc-common.service");
var i4 = require("./map-utils-wfs-feature.service");
/**
 * WMS layer extended with WFS-style filtering capabilities
 */
var LeafletTileLayerWmsFiltered = /** @class */ (function () {
    function LeafletTileLayerWmsFiltered(http, leafletWmsCommonService, mapOgcCommonService, mapUtilsWFSFeatureService) {
        this.WmsTileLayer = L.TileLayer.WMS.extend({
            initialize: function (url, options) {
                if (!options || !options.id) {
                    throw new Error("Missing layer id option in initialization.");
                }
                this._id = options.id;
                this.wmsParams = L.extend(options.wms);
                if (options.filter) {
                    this.wmsParams['filter'] = mapOgcCommonService.getFilter(options.filter, true);
                }
                this._httpService = http;
                L.TileLayer.WMS.prototype.initialize.call(this, url, this.wmsParams);
                if (options.hasOwnProperty('removeWmsParams')) {
                    for (var i = 0; i < options['removeWmsParams'].length; i++) {
                        this.removeWmsParam(options['removeWmsParams'][i]);
                    }
                }
            },
            loadFeatureData: function (options) {
                return leafletWmsCommonService.loadFeatureData({
                    latlng: options.latlng,
                    queryParams: options.queryParams,
                    url: this._url,
                    uppercase: this._uppercase,
                    httpService: this._httpService,
                    map: this._map,
                    crs: this._crs,
                    layers: this.wmsParams['layers'],
                    filter: this.wmsParams['filter'],
                    cqlFilter: null,
                    featureCount: this.wmsParams['feature_count'],
                    version: options.version
                });
            },
            loadFeatureDataByID: function (featureID, queryParams) {
                var wfsBaseURL = this._url.substring(0, this._url.lastIndexOf("wms")) + 'wfs';
                return mapUtilsWFSFeatureService.getFeatureByID(wfsBaseURL, this.wmsParams.layers, featureID, queryParams);
            },
            removeWmsParam: function (param) {
                if (this.wmsParams.hasOwnProperty(param)) {
                    delete this.wmsParams[param];
                }
            }
        });
    }
    LeafletTileLayerWmsFiltered.prototype.getLayer = function (url, options) {
        return new this.WmsTileLayer(url, options);
    };
    LeafletTileLayerWmsFiltered.ngInjectableDef = i0.defineInjectable({ factory: function LeafletTileLayerWmsFiltered_Factory() { return new LeafletTileLayerWmsFiltered(i0.inject(i1.HttpClient), i0.inject(i2.LeafletWmsCommonService), i0.inject(i3.MapOgcCommonService), i0.inject(i4.MapUtilsWfsFeatureService)); }, token: LeafletTileLayerWmsFiltered, providedIn: "root" });
    return LeafletTileLayerWmsFiltered;
}());
exports.LeafletTileLayerWmsFiltered = LeafletTileLayerWmsFiltered;
