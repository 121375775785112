"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var subject_model_1 = require("@app/common/models/subject.model");
var CommonAddressModel = /** @class */ (function () {
    function CommonAddressModel() {
    }
    CommonAddressModel.fromMailingAddress = function (subject) {
        return {
            street: subject && subject.dorucovaciUlice,
            houseNumber: subject && subject.dorucovaciCisloDomovni,
            commonNumber: subject && subject.dorucovaciCisloOrientacni,
            neighborhood: subject && subject.dorucovaciCastObce,
            cityDistrict: subject && subject.dorucovaciMestskyObvod,
            quarter: subject && subject.dorucovaciMestskaCast,
            city: subject && subject.dorucovaciObec,
            county: subject && subject.dorucovaciOkres,
            postalCode: subject && subject.dorucovaciPsc,
            country: subject && subject.dorucovaciStat,
            countryCode: subject && subject.dorucovaciKodStatu,
            countryShortCode: subject && subject.dorucovaciKodStatuAl2,
        };
    };
    CommonAddressModel.fromCustomerMailingAddress = function (customer) {
        return {
            street: customer && customer.deliveryStreet,
            houseNumber: customer && customer.deliveryHouseNumber,
            commonNumber: customer && customer.deliveryStreetNumber,
            neighborhood: customer && customer.deliveryNeighbourhood,
            cityDistrict: customer && customer.deliveryDistrict,
            quarter: customer && customer.deliveryQuarter,
            city: customer && customer.deliveryMunicipality,
            county: customer && customer.deliveryCounty,
            postalCode: customer && customer.deliveryPostCode,
            country: customer && customer.deliveryCountry,
        };
    };
    CommonAddressModel.fromCustomerAddress = function (customer) {
        return {
            street: customer && customer.street,
            houseNumber: customer && customer.houseNumber,
            commonNumber: customer && customer.streetNumber,
            neighborhood: customer && customer.neighbourhood,
            cityDistrict: customer && customer.district,
            quarter: customer && customer.quarter,
            city: customer && customer.municipality,
            county: customer && customer.county,
            postalCode: customer && customer.postCode,
            country: customer && customer.country,
        };
    };
    CommonAddressModel.fromSubjectAddress = function (subject) {
        var tmp = {
            street: subject && subject.ulice,
            houseNumber: subject && subject.cisloDomovni,
            commonNumber: subject && subject.cisloOrientacni,
            neighborhood: subject && subject.castObce,
            cityDistrict: subject && subject.mestskyObvod,
            quarter: subject && subject.mestskaCast,
            city: subject && subject.obec,
            county: subject && subject.okres,
            postalCode: subject && subject.psc,
            country: subject && subject.stat,
        };
        if (!(subject instanceof subject_model_1.SubjectModel)) {
            tmp.countryCode = subject && subject.kodStatu;
            tmp.countryShortCode = subject && subject.kodStatuAl2;
        }
        return tmp;
    };
    CommonAddressModel.toCustomerMailingAddress = function (address) {
        return {
            deliveryStreet: address.street,
            deliveryHouseNumber: address.houseNumber,
            deliveryStreetNumber: address.commonNumber,
            deliveryNeighbourhood: address.neighborhood,
            deliveryDistrict: address.cityDistrict,
            deliveryQuarter: address.quarter,
            deliveryMunicipality: address.city,
            deliveryCounty: address.county,
            deliveryPostCode: address.postalCode,
            deliveryCountry: address.country,
        };
    };
    CommonAddressModel.toMailingAddress = function (address) {
        return {
            dorucovaciUlice: address.street,
            dorucovaciCisloDomovni: address.houseNumber,
            dorucovaciCisloOrientacni: address.commonNumber,
            dorucovaciCastObce: address.neighborhood,
            dorucovaciMestskaCast: address.quarter,
            dorucovaciMestskyObvod: address.cityDistrict,
            dorucovaciObec: address.city,
            dorucovaciOkres: address.county,
            dorucovaciPsc: address.postalCode,
            dorucovaciStat: address.country,
            dorucovaciKodStatu: address.countryCode,
            dorucovaciKodStatuAl2: address.countryShortCode,
        };
    };
    CommonAddressModel.toAddress = function (address) {
        return {
            ulice: address.street,
            cisloDomovni: address.houseNumber,
            cisloOrientacni: address.commonNumber,
            castObce: address.neighborhood,
            mestskaCast: address.quarter,
            mestskyObvod: address.cityDistrict,
            obec: address.city,
            okres: address.county,
            psc: address.postalCode,
            stat: address.country,
            kodStatu: address.countryCode,
            kodStatuAl2: address.countryShortCode,
        };
    };
    CommonAddressModel.toCustomerAddress = function (address) {
        return {
            street: address.street,
            houseNumber: address.houseNumber,
            streetNumber: address.commonNumber,
            neighbourhood: address.neighborhood,
            district: address.cityDistrict,
            municipality: address.city,
            county: address.county,
            postCode: address.postalCode,
            quarter: address.quarter,
            country: address.country,
        };
    };
    CommonAddressModel.isAddressFiled = function (address) {
        return !!(address.street
            || address.houseNumber
            || address.commonNumber
            || address.neighborhood
            || address.cityDistrict
            || address.city
            || address.county
            || address.postalCode
            || address.quarter
            || address.country);
    };
    return CommonAddressModel;
}());
exports.CommonAddressModel = CommonAddressModel;
