"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var parcel_model_1 = require("@app/common/models/parcel.model");
var ParcelCostsFormComponent = /** @class */ (function () {
    function ParcelCostsFormComponent() {
    }
    ParcelCostsFormComponent.prototype.ngOnInit = function () { };
    return ParcelCostsFormComponent;
}());
exports.ParcelCostsFormComponent = ParcelCostsFormComponent;
