"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var MenuConfig = /** @class */ (function () {
    function MenuConfig(title, uiSref, icon, uiParams, tooltip, href, target, permissions) {
        this.title = title;
        this.uiSref = uiSref;
        this.icon = icon;
        this.uiParams = uiParams;
        this.tooltip = tooltip;
        this.href = href;
        this.target = target;
        this.permissions = permissions;
    }
    return MenuConfig;
}());
exports.MenuConfig = MenuConfig;
