"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var app_module_ajs_1 = require("./app.module.ajs");
var static_1 = require("@angular/upgrade/static");
var AppModule = /** @class */ (function () {
    function AppModule(upgrade) {
        this.upgrade = upgrade;
    }
    AppModule.prototype.ngDoBootstrap = function () {
        this.upgrade.bootstrap(document.body, [app_module_ajs_1.appJS.name], { strictDi: true });
    };
    return AppModule;
}());
exports.AppModule = AppModule;
