"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var OpinionModel = /** @class */ (function () {
    function OpinionModel() {
    }
    OpinionModel.createDefaultExpertOpinionPermanent = function () {
        var opinion = new OpinionModel();
        opinion.priceType = 'E';
        opinion.occupationType = 'P';
        opinion.expertOpinionPricingMethod = 'M';
        opinion.expertOpinionLand = true;
        opinion.expertOpinionVegetation = true;
        opinion.expertOpinionBuildings = true;
        opinion.expertOpinionCompensation = false;
        return opinion;
    };
    OpinionModel.createDefaultExpertOpinionTemporary = function () {
        var opinion = new OpinionModel();
        opinion.priceType = 'E';
        opinion.occupationType = 'T';
        opinion.expertOpinionPricingMethod = 'M';
        opinion.expertOpinionLand = false;
        opinion.expertOpinionVegetation = false;
        opinion.expertOpinionBuildings = false;
        opinion.expertOpinionCompensation = true;
        return opinion;
    };
    OpinionModel.createDefaultOtherSourcePermanent = function () {
        var opinion = new OpinionModel();
        opinion.priceType = 'O';
        opinion.occupationType = 'P';
        return opinion;
    };
    OpinionModel.createDefaultOtherSourceTemporary = function () {
        var opinion = new OpinionModel();
        opinion.priceType = 'O';
        opinion.occupationType = 'T';
        return opinion;
    };
    return OpinionModel;
}());
exports.OpinionModel = OpinionModel;
