"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("@angular/common");
var i2 = require("../../../../common/directives/auth.directive");
var i3 = require("../../../../common/services/auth.service");
var i4 = require("../../../../common/components/fileupload/fileupload.component.ngfactory");
var i5 = require("../../../../common/components/fileupload/fileupload.component");
var i6 = require("@uirouter/angular/lib/directives/uiSref");
var i7 = require("@uirouter/core");
var i8 = require("../../../../common/directives/loading.directive");
var i9 = require("../../../../common/components/help/help.component.ngfactory");
var i10 = require("../../../../common/components/help/help.component");
var i11 = require("./template-list.component");
var styles_TemplateListComponent = [];
var RenderType_TemplateListComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TemplateListComponent, data: {} });
exports.RenderType_TemplateListComponent = RenderType_TemplateListComponent;
function View_TemplateListComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "div", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.display(_v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngClass: [0, "ngClass"] }, null), i0.ɵpod(3, { active: 0 }), (_l()(), i0.ɵted(4, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, (_co.displayed === _v.context.index)); _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = _v.context.$implicit.name; _ck(_v, 4, 0, currVal_1); }); }
function View_TemplateListComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "content-menu"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TemplateListComponent_2)), i0.ɵdid(2, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.templateTypes; _ck(_v, 2, 0, currVal_0); }, null); }
function View_TemplateListComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "empty-info bordered p-20"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u0160ablona nen\u00ED zad\u00E1na."]))], null, null); }
function View_TemplateListComponent_9(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "a", [["class", "fa fa-times"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onRemoveTemplate(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, null); }
function View_TemplateListComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "mt-5 pb-5"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "a", [], [[8, "href", 4]], null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "i", [["class", "fa fa-file-o fa-lg"]], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", " "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TemplateListComponent_9)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_2 = _v.parent.parent.parent.context.$implicit.modifiable; _ck(_v, 5, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(3, "", _co.restBaseUrl, "/templates/", _v.context.$implicit.id, "/data?t=", _co.authToken, ""); _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.context.$implicit.attachment.name; _ck(_v, 3, 0, currVal_1); }); }
function View_TemplateListComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "bordered p-20"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TemplateListComponent_8)), i0.ɵdid(2, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit.templates; _ck(_v, 2, 0, currVal_0); }, null); }
function View_TemplateListComponent_10(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "div", [], null, null, null, null, null)), i0.ɵdid(1, 81920, null, 0, i2.AuthDirective, [i0.ElementRef, i3.AuthService], { gmtAuth: [0, "gmtAuth"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 2, "div", [["class", "mt-20"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "fileupload", [], null, [[null, "filesChange"], [null, "change"], [null, "dragover"], [null, "drop"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("dragover" === en)) {
        var pd_0 = (i0.ɵnov(_v, 4).dragOver($event) !== false);
        ad = (pd_0 && ad);
    } if (("drop" === en)) {
        var pd_1 = (i0.ɵnov(_v, 4).dropFiles($event) !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (i0.ɵnov(_v, 4).openFileUpload() !== false);
        ad = (pd_2 && ad);
    } if (("filesChange" === en)) {
        var pd_3 = ((_co.uploadingFiles = $event) !== false);
        ad = (pd_3 && ad);
    } if (("change" === en)) {
        var pd_4 = (_co.onSelectFiles($event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, i4.View_FileUploadComponent_0, i4.RenderType_FileUploadComponent)), i0.ɵdid(4, 114688, null, 0, i5.FileUploadComponent, [i0.ElementRef], { message: [0, "message"], disabled: [1, "disabled"], inputId: [2, "inputId"], files: [3, "files"] }, { onchange: "change" }), (_l()(), i0.ɵeld(5, 0, null, null, 4, "a", [["class", "button not-underline"], ["uiSref", "symap.project.projectSettings.templates.history"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 7).go($event.button, $event.ctrlKey, $event.metaKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(6, 16384, null, 0, i6.AnchorUISref, [i0.ElementRef, i0.Renderer2], null, null), i0.ɵdid(7, 737280, null, 0, i6.UISref, [i7.UIRouter, [2, i6.AnchorUISref], "UIView.PARENT_INJECT"], { state: [0, "state"], params: [1, "params"] }, null), i0.ɵpod(8, { key: 0 }), (_l()(), i0.ɵted(-1, null, ["Zobrazit historii zm\u011Bn"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "manage_templates"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "Vlo\u017Ete \u0161ablony p\u0159eta\u017Een\u00EDm, nebo je vyberte z po\u010D\u00EDta\u010De."; var currVal_2 = false; var currVal_3 = i0.ɵinlineInterpolate(1, "file-upload-", _v.parent.parent.context.index, ""); var currVal_4 = _co.uploadingFiles; _ck(_v, 4, 0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_5 = "symap.project.projectSettings.templates.history"; var currVal_6 = _ck(_v, 8, 0, _v.parent.parent.context.$implicit.key); _ck(_v, 7, 0, currVal_5, currVal_6); }, null); }
function View_TemplateListComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [], [[8, "className", 0]], null, null, null, null)), i0.ɵdid(1, 16384, null, 0, i8.LoadingDirective, [i0.ElementRef], { loading: [0, "loading"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TemplateListComponent_6)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TemplateListComponent_7)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TemplateListComponent_10)), i0.ɵdid(7, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _v.parent.context.$implicit.loading; _ck(_v, 1, 0, currVal_1); var currVal_2 = (!_v.parent.context.$implicit.loading && !_v.parent.context.$implicit.templates.length); _ck(_v, 3, 0, currVal_2); var currVal_3 = (!_v.parent.context.$implicit.loading && _v.parent.context.$implicit.templates.length); _ck(_v, 5, 0, currVal_3); var currVal_4 = (!_v.parent.context.$implicit.loading && !_co.project.useTemplatesService); _ck(_v, 7, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).elementClass; _ck(_v, 0, 0, currVal_0); }); }
function View_TemplateListComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TemplateListComponent_5)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.index === _co.displayed); _ck(_v, 2, 0, currVal_0); }, null); }
function View_TemplateListComponent_11(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u017D\u00E1dn\u00E9 \u0161ablony nejsou ve zvolen\u00E9m projektu pou\u017E\u00EDv\u00E1ny."]))], null, null); }
function View_TemplateListComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TemplateListComponent_4)), i0.ɵdid(2, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TemplateListComponent_11)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.templateTypes; _ck(_v, 2, 0, currVal_0); var currVal_1 = (!_co.loading && (_co.templateTypes.length === 0)); _ck(_v, 4, 0, currVal_1); }, null); }
function View_TemplateListComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 17, "div", [["class", "statistics templates"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 6, "div", [["style", "display:flex"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 5, "div", [["class", "line-height-l"], ["style", "min-width: 120px;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 4, "a", [["class", "button mb-10"], ["uiSref", "symap.project.projectSettings"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 5).go($event.button, $event.ctrlKey, $event.metaKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(4, 16384, null, 0, i6.AnchorUISref, [i0.ElementRef, i0.Renderer2], null, null), i0.ɵdid(5, 737280, null, 0, i6.UISref, [i7.UIRouter, [2, i6.AnchorUISref], "UIView.PARENT_INJECT"], { state: [0, "state"] }, null), (_l()(), i0.ɵeld(6, 0, null, null, 0, "i", [["class", "fa fa-chevron-left mr-5"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Nastaven\u00ED"])), (_l()(), i0.ɵeld(8, 0, null, null, 1, "h1", [["class", "mb-20"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u0160ablony dokument\u016F"])), (_l()(), i0.ɵeld(10, 0, null, null, 5, "div", [["class", "flexbox-container"]], [[8, "className", 0]], null, null, null, null)), i0.ɵdid(11, 16384, null, 0, i8.LoadingDirective, [i0.ElementRef], { loading: [0, "loading"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TemplateListComponent_1)), i0.ɵdid(13, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TemplateListComponent_3)), i0.ɵdid(15, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(16, 0, null, null, 1, "help", [["class", "cm-help-page"]], null, null, null, i9.View_HelpComponent_0, i9.RenderType_HelpComponent)), i0.ɵdid(17, 114688, null, 0, i10.HelpComponent, [i3.AuthService, "HELP_IDS", "HelpService", "ModalService"], { helpId: [0, "helpId"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "symap.project.projectSettings"; _ck(_v, 5, 0, currVal_0); var currVal_2 = _co.loading; _ck(_v, 11, 0, currVal_2); var currVal_3 = !_co.loading; _ck(_v, 13, 0, currVal_3); var currVal_4 = !_co.loading; _ck(_v, 15, 0, currVal_4); var currVal_5 = _co.helpIds.SETTINGS_TEMPLATES; _ck(_v, 17, 0, currVal_5); }, function (_ck, _v) { var currVal_1 = i0.ɵnov(_v, 11).elementClass; _ck(_v, 10, 0, currVal_1); }); }
exports.View_TemplateListComponent_0 = View_TemplateListComponent_0;
function View_TemplateListComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "template-list", [], null, null, null, View_TemplateListComponent_0, RenderType_TemplateListComponent)), i0.ɵdid(1, 114688, null, 0, i11.TemplateListComponent, ["HELP_IDS", "Restangular", "Upload", i3.AuthService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_TemplateListComponent_Host_0 = View_TemplateListComponent_Host_0;
var TemplateListComponentNgFactory = i0.ɵccf("template-list", i11.TemplateListComponent, View_TemplateListComponent_Host_0, {}, {}, []);
exports.TemplateListComponentNgFactory = TemplateListComponentNgFactory;
