"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var LandingDiComponent = /** @class */ (function () {
    function LandingDiComponent() {
        this.tabs = [{
                name: 'Podmínky použití',
                id: 'terms-of-use',
                href: 'settings.modules.dimap',
                urlParams: { tab: 'terms-of-use' },
            }];
        this.isActiveTab = this.isActiveTab.bind(this);
    }
    LandingDiComponent.prototype.ngOnInit = function () {
        this.isActiveTab = this.isActiveTab.bind(this);
    };
    LandingDiComponent.prototype.isActiveTab = function (tabId) {
        return tabId === this.activeTab;
    };
    return LandingDiComponent;
}());
exports.LandingDiComponent = LandingDiComponent;
