"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var angular_1 = require("@uirouter/angular");
var angular_2 = require("@uirouter/angular");
var auth_service_1 = require("@app/common/services/auth.service");
var login_service_1 = require("@app/common/services/login.service");
var menuConfig_1 = require("@app/models/menuConfig");
var brand_translate_pipe_1 = require("@app/common/pipes/brand-translate.pipe");
var word_service_1 = require("@app/common/services/word.service");
var project_status_service_1 = require("@app/ps/services/project-status.service");
var settings_service_1 = require("@app/ps/services/settings.service");
var common_1 = require("@angular/common");
var MainComponent = /** @class */ (function () {
    function MainComponent(projectStatusService, authService, loginService, brandTranslatePipe, uiRouterGlobals, transitionService, stateService, wordService, settingsService, titleCasePipe, transition, ENV, APP_BRAND, EVENTS, applications, localStorageService, modulesService, approveTermsService, changePasswordService, googleAnalyticsService, rootCallbackService, restangular) {
        this.projectStatusService = projectStatusService;
        this.authService = authService;
        this.loginService = loginService;
        this.brandTranslatePipe = brandTranslatePipe;
        this.uiRouterGlobals = uiRouterGlobals;
        this.transitionService = transitionService;
        this.stateService = stateService;
        this.wordService = wordService;
        this.settingsService = settingsService;
        this.titleCasePipe = titleCasePipe;
        this.transition = transition;
        this.ENV = ENV;
        this.APP_BRAND = APP_BRAND;
        this.EVENTS = EVENTS;
        this.applications = applications;
        this.localStorageService = localStorageService;
        this.modulesService = modulesService;
        this.approveTermsService = approveTermsService;
        this.changePasswordService = changePasswordService;
        this.googleAnalyticsService = googleAnalyticsService;
        this.rootCallbackService = rootCallbackService;
        this.restangular = restangular;
        this.collapsed = false;
        this.user = this.authService.getUser();
        this.servicedeskUrl = this.authService.getServiceDeskUrl();
        this.menuConfig = [];
        this.countWithWarningLoading = true;
        this.countWithWarning = 0;
        this.checkPasswordChange = this.checkPasswordChange.bind(this);
        this.changeProject = this.changeProject.bind(this);
        this.onAuthProjectUpdated = this.onAuthProjectUpdated.bind(this);
        this.onNotificationWithWarningHidden = this.onNotificationWithWarningHidden.bind(this);
    }
    MainComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.authService.setActiveApplication(this.applications.sy.name);
        this.authService.updateBaseUrl();
        this.authService.updateAuthorizationHeader();
        this.googleAnalyticsService.onUserCome();
        this.collapsed = this.localStorageService.get('menuOpened');
        this.checkApprovedTerms().then(this.checkPasswordChange);
        var actualProject = this.authService.getActualProject();
        if (actualProject) {
            this.projectName = actualProject.name;
            this.selProject = actualProject;
        }
        var actualProjects = this.authService.getActualProjects();
        if (actualProjects) {
            this.projects = actualProjects.filter(function (p) { return p.key.match(/sy_/); });
        }
        this.getMenuConfig().then(function (menu) {
            _this.menuConfig = menu;
        });
        this.restangular.all('notifications').customGET('count-with-warning').then(function (data) {
            _this.countWithWarning += data ? Number(data) : 0;
            _this.countWithWarningLoading = false;
        });
        this.authService.registerCallback(this.EVENTS.authProjectUpdated, this.onAuthProjectUpdated);
        this.rootCallbackService.register(this.EVENTS.notificationWithWarningHidden, this.onNotificationWithWarningHidden);
        this.projectStatusService.init();
        this.projectStatusService.registerListener(function (data) {
            _this.processes = data;
        });
        this.translateTitle(this.transition);
        this.transitionDeregister = this.transitionService.onFinish({}, function (transition) {
            _this.translateTitle(transition);
        });
    };
    MainComponent.prototype.ngOnDestroy = function () {
        if (this.transitionDeregister) {
            this.transitionDeregister();
        }
        this.projectStatusService.destroy();
        this.authService.unregisterCallback(this.EVENTS.authProjectUpdated, this.onAuthProjectUpdated);
        this.rootCallbackService.unregister(this.EVENTS.notificationWithWarningHidden);
    };
    MainComponent.prototype.onAuthProjectUpdated = function (project) {
        this.selProject = project;
        this.settingsService.clearSettings();
        this.wordService.clearVocabulary();
        if (project) {
            this.settingsService.loadSettings(project.id);
        }
    };
    MainComponent.prototype.onNotificationWithWarningHidden = function (countWithWarning) {
        this.countWithWarning -= countWithWarning;
    };
    MainComponent.prototype.onCollapse = function () {
        this.collapsed = !this.collapsed;
        this.localStorageService.set('menuOpened', this.collapsed);
    };
    MainComponent.prototype.logout = function () {
        this.authService.logout();
    };
    MainComponent.prototype.changeProject = function (project) {
        this.stateService.go('symap.project.home', { projectKey: project.key }, { inherit: false });
    };
    MainComponent.prototype.formatName = function (subject) {
        if (!subject) {
            return '';
        }
        var words = [];
        if (subject.name) {
            words.push(subject.name);
        }
        if (subject.surname) {
            words.push(subject.surname);
        }
        var tmp = [];
        if (words.length !== 0) {
            tmp.push(words.join(' '));
        }
        words = [];
        if (subject.titleBefore) {
            words.push(subject.titleBefore);
        }
        if (subject.degreeAfter) {
            words.push(subject.degreeAfter);
        }
        if (words.length !== 0) {
            tmp.push(words.join(' '));
        }
        return tmp.join(', ');
    };
    MainComponent.prototype.checkApprovedTerms = function () {
        var _this = this;
        // check approvedTerms
        // get approvedTerms
        return this.modulesService.getUserApprovedTerms(this.user.id).then(function (approvedTerms) {
            if (!approvedTerms.includes(_this.applications.sy.name)) {
                // open modal
                return _this.approveTermsService({
                    module: _this.applications.sy.name,
                    userId: _this.user.id,
                });
            }
        });
    };
    MainComponent.prototype.checkPasswordChange = function () {
        // user is logging in for the first time - ask for password change
        // terms of conditions were changed
        if (this.authService.isFirstLogin() && !this.authService.isSsoLoginEnabled()) {
            return this.changePasswordService();
        }
    };
    MainComponent.prototype.getMenuConfig = function () {
        var _this = this;
        return this.wordService.getWord('CONSTRUCTION_OBJECT_SHORTCUT', true)
            .then(function (CONSTRUCTION_OBJECT_SHORTCUT) {
            var menu = _this.getMenu(CONSTRUCTION_OBJECT_SHORTCUT);
            var project = _this.authService.getActualProject();
            if (!project.geometricPlanModuleEnabled) {
                var gpItem = menu.find(function (i) { return i.title === 'GP'; });
                menu.splice(menu.indexOf(gpItem), 1);
            }
            if (!project.opinionModuleEnabled) {
                var oItem = menu.find(function (i) { return i.uiSref === 'symap.project.opinionRequests'; });
                menu.splice(menu.indexOf(oItem), 1);
            }
            if (!project.projectInfoEnabled) {
                var oItem = menu.find(function (i) { return i.uiSref === 'symap.project.info'; });
                menu.splice(menu.indexOf(oItem), 1);
            }
            if (!project.vfzeModuleEnabled) {
                var oItem = menu.find(function (i) { return i.title === 'Import'; });
                menu.splice(menu.indexOf(oItem), 1);
            }
            return menu;
        });
    };
    MainComponent.prototype.getMenu = function (CONSTRUCTION_OBJECT_SHORTCUT) {
        return [
            new menuConfig_1.MenuConfig('Přehled', 'symap.project.home', 'fa-dashboard'),
            new menuConfig_1.MenuConfig((this.APP_BRAND.NAME === 'RSD' ? 'Karta akce' : 'Projekt'), 'symap.project.info', 'fa-info-circle'),
            new menuConfig_1.MenuConfig('Případy', 'symap.project.cases', 'fa-file', { page: 1 }),
            new menuConfig_1.MenuConfig('LV', 'symap.project.titles', 'fa-users', { page: 1 }),
            new menuConfig_1.MenuConfig('Vlastníci', 'symap.project.owners', 'fa-user', { page: 1 }),
            new menuConfig_1.MenuConfig('Zábory', 'symap.project.occupations', 'fa-map-pin'),
            new menuConfig_1.MenuConfig(CONSTRUCTION_OBJECT_SHORTCUT, 'symap.project.constructionObjects', 'fa-th', { page: 1 }),
            new menuConfig_1.MenuConfig('Parcely', 'symap.project.parcels', 'fa-toggle-up', { page: 1 }),
            new menuConfig_1.MenuConfig('GP', 'symap.project.geometricPlanRequests', 'fa-object-ungroup'),
            new menuConfig_1.MenuConfig((this.APP_BRAND.NAME === 'RSD' ? 'Posudky' : 'ZP'), 'symap.project.opinionRequests', 'fa-book'),
            new menuConfig_1.MenuConfig('Import', 'symap.project.vfze', 'fa-window-restore'),
            new menuConfig_1.MenuConfig('Mapa', 'symap.project.map', 'fa-map'),
            new menuConfig_1.MenuConfig('Exporty', 'symap.project.exports', 'fa-cloud-download'),
            new menuConfig_1.MenuConfig('Nastavení', 'symap.project.projectSettings', 'fa-wrench'),
        ];
    };
    MainComponent.prototype.translateTitle = function (transition) {
        var _this = this;
        var to = transition.to();
        if (to.data && to.data.title && to.data.title.includes('$')) {
            this.wordService.replaceVariablesInText(to.data.title).then(function (text) {
                document.title = _this.titleCasePipe.transform(text);
            });
        }
    };
    return MainComponent;
}());
exports.MainComponent = MainComponent;
