"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var opinion_requests_states_1 = require("@app/ps/opinions/opinion-requests.states");
var OPINION_REQUEST_STATES = [
    opinion_requests_states_1.opinionRequestCreateState,
    opinion_requests_states_1.opinionRequestDetailState,
    opinion_requests_states_1.opinionRequestListState,
    opinion_requests_states_1.opinionRequestFillPricesFormState,
];
var OpinionRequestsModule = /** @class */ (function () {
    function OpinionRequestsModule() {
    }
    return OpinionRequestsModule;
}());
exports.OpinionRequestsModule = OpinionRequestsModule;
