"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var DaysDiffPipe = /** @class */ (function () {
    function DaysDiffPipe() {
    }
    DaysDiffPipe.prototype.transform = function (date1, date2) {
        var text = '';
        if (!date1 || !date2) {
            return text;
        }
        var one = new Date(date1).getTime();
        var two = new Date(date2).getTime();
        var millisecondsPerDay = 1000 * 60 * 60 * 24;
        var millisBetween = one - two;
        var days = Math.ceil(millisBetween / millisecondsPerDay);
        switch (Math.abs(days)) {
            case 1:
                text = "den";
                break;
            case 2:
            case 3:
            case 4:
                text = "dny";
                break;
            default: text = "dní";
        }
        return (days < 0 ? 0 : days) + " " + text;
    };
    return DaysDiffPipe;
}());
exports.DaysDiffPipe = DaysDiffPipe;
