"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var bulk_price_step_component_1 = require("@app/ps/project-settings/components/bulk-price/bulk-price-step/bulk-price-step.component");
var BulkPriceConfirmComponent = /** @class */ (function (_super) {
    __extends(BulkPriceConfirmComponent, _super);
    function BulkPriceConfirmComponent(restangular) {
        var _this = _super.call(this) || this;
        _this.restangular = restangular;
        _this.loading = true;
        _this.rentalPriceUnitTxt = '';
        _this.rentalPriceTypeTxt = '';
        _this.onSubmit = _this.onSubmit.bind(_this);
        return _this;
    }
    BulkPriceConfirmComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.rentalPriceUnitTxt = this.priceUnit.find(function (i) { return i.id === _this.data.rentalPrice.rentalPriceUnit; }).value + '';
        this.rentalPriceTypeTxt = this.priceType.find(function (i) { return i.id === _this.data.rentalPrice.rentalPriceType; }).value + '';
    };
    BulkPriceConfirmComponent.prototype.isValid = function () {
        return true;
    };
    BulkPriceConfirmComponent.prototype.onSubmit = function () {
        this.submitCallback.emit();
    };
    return BulkPriceConfirmComponent;
}(bulk_price_step_component_1.BulkPriceStepComponent));
exports.BulkPriceConfirmComponent = BulkPriceConfirmComponent;
