"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var opinion_request_detail_component_1 = require("@app/ps/opinions/components/opinion-request-detail/opinion-request-detail.component");
var opinion_request_create_component_1 = require("@app/ps/opinions/components/opinion-request-create/opinion-request-create.component");
var opinion_request_list_component_1 = require("@app/ps/opinions/components/opinion-request-list/opinion-request-list.component");
var pricing_expert_opinion_component_1 = require("@app/ps/titles/components/pricing-expert-opinion/pricing-expert-opinion.component");
var state_utils_1 = require("@app/common/utils/state.utils");
function resolveOpinionRequest(restangular, $stateParams) {
    return restangular
        .one('opinion-requests', $stateParams.id)
        .get({ loadCollections: ['parcels', 'buildings'] });
}
exports.resolveOpinionRequest = resolveOpinionRequest;
exports.opinionRequestListState = {
    name: 'symap.project.opinionRequests',
    url: '/opinion-requests?page',
    data: {
        title: 'ZP',
    },
    params: {
        page: {
            dynamic: true,
            type: 'int',
            inherit: false,
        },
    },
    views: {
        'content@symap.project': {
            component: opinion_request_list_component_1.OpinionRequestListComponent,
        },
    },
};
exports.opinionRequestCreateState = {
    name: 'symap.project.opinionRequests.create',
    url: '/new',
    data: {
        title: 'Nový ZP',
    },
    views: {
        'content@symap.project': {
            component: opinion_request_create_component_1.OpinionRequestCreateComponent,
        },
    },
};
exports.opinionRequestDetailState = {
    name: 'symap.project.opinionRequests.detail',
    url: '/:id/:tab',
    data: {
        title: 'Detail ZP'
    },
    params: {
        id: {
            type: 'int',
        },
        tab: {
            dynamic: true,
            squash: true,
        },
    },
    views: {
        'content@symap.project': {
            component: opinion_request_detail_component_1.OpinionRequestDetailComponent,
        },
    },
    resolve: {
        opinionRequest: ['Restangular', '$stateParams', resolveOpinionRequest],
    },
};
function resolveTitleId($stateParams) {
    return $stateParams.titleId;
}
exports.resolveTitleId = resolveTitleId;
function resolveReturn() {
    return 'opinionRequest';
}
exports.resolveReturn = resolveReturn;
exports.opinionRequestFillPricesFormState = {
    name: 'symap.project.opinionRequests.detail.fillPricesForm',
    url: '/fill-prices-form?titleId',
    data: {
        title: 'Zadání cen ZP'
    },
    resolve: {
        titleId: ['$stateParams', resolveTitleId],
        opinionRequestId: ['$stateParams', state_utils_1.resolveId],
        returnDestination: [resolveReturn],
    },
    params: {
        titleId: {
            type: 'int',
        },
    },
    views: {
        'content@symap.project': {
            component: pricing_expert_opinion_component_1.PricingExpertOpinionComponent,
        },
    },
};
