"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["\n\n\n\n\ntable.article-table td.article-column {\n  padding: 0; }\n\n.article-table th:first-of-type {\n  width: 100px; }\n\n.article-table .article-preview h1 {\n  color: #000;\n  font-weight: normal;\n  font-size: 17px;\n  background-color: #f2f2f2;\n  text-transform: uppercase;\n  padding: 8px; }\n  .article-table .article-preview h1 a {\n    display: inline-block;\n    width: 20px; }\n\n.article-table .article-preview a.edit {\n  position: absolute;\n  right: 10px;\n  top: 5px;\n  display: none; }\n\n.article-table .article-preview a.remove {\n  position: absolute;\n  right: 50px;\n  top: 5px;\n  display: none; }\n\n.article-table .article-preview .article-preview-content {\n  padding: 5px; }\n  .article-table .article-preview .article-preview-content h2 {\n    color: #1a427b;\n    font-weight: normal;\n    font-size: 16px;\n    padding: 10px 0 0 0; }\n  .article-table .article-preview .article-preview-content h3 {\n    color: #1a427b;\n    font-weight: normal;\n    font-size: 14px;\n    padding: 10px 0 0 0; }\n  .article-table .article-preview .article-preview-content h4 {\n    font-weight: bold;\n    font-size: 13px;\n    padding: 10px 0 0 0; }\n  .article-table .article-preview .article-preview-content ul {\n    list-style-type: disc;\n    margin-left: 20px;\n    margin-bottom: 1rem; }\n  .article-table .article-preview .article-preview-content ol {\n    list-style-type: decimal;\n    margin-left: 20px;\n    margin-bottom: 1rem; }\n  .article-table .article-preview .article-preview-content u {\n    text-decoration: underline; }\n  .article-table .article-preview .article-preview-content s {\n    text-decoration: line-through; }\n\n.article-table tr:hover .article-preview a.edit, .article-table tr:hover .article-preview a.remove {\n  display: inline; }\n"];
exports.styles = styles;
