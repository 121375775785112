"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var _ = require("lodash");
var auth_service_1 = require("@app/common/services/auth.service");
var dialog_ref_1 = require("@app/dialog/dialog-ref");
var dialog_config_1 = require("@app/dialog/dialog-config");
var string_utils_1 = require("@app/common/utils/string.utils");
var UpdateEasementPriceComponent = /** @class */ (function () {
    function UpdateEasementPriceComponent(restangular, helpIds, authService, dialogRef, dialogConfig) {
        this.restangular = restangular;
        this.helpIds = helpIds;
        this.authService = authService;
        this.dialogRef = dialogRef;
        this.dialogConfig = dialogConfig;
        this.loading = true;
        this.showCoefficient = false;
        this.priceTypes = [
            {
                id: 'S',
                value: 'Jednorázová cena podle 416/2009 Sb.',
            },
            {
                id: 'E',
                value: 'Znalecký posudek',
            },
            {
                id: 'O',
                value: 'Jiný způsob',
            },
            {
                id: null,
                value: 'Reset',
            },
        ];
        this.unitTypes = [
            {
                id: 'A',
                value: 'Plocha',
            },
            {
                id: 'L',
                value: 'Délka',
            },
            {
                id: 'T',
                value: 'Jednorázová cena',
            },
        ];
        this.easementId = dialogConfig.data.easementId;
        this.refreshDefaultPrice = this.refreshDefaultPrice.bind(this);
        this.onUpdate = this.onUpdate.bind(this);
    }
    UpdateEasementPriceComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.restangular
            .one('easements', this.easementId)
            .customPOST({ constructionObjects: {} })
            .then(function (res) {
            _this.easement = UpdateEasementPriceComponent.normalizeData(res);
            _this.showCoefficient = _this.authService.getActualProject().showExpertOpinionCoefficient;
            if (!_this.easement.priceType) {
                _this.easement.priceType = 'S';
            }
            _this.refreshDefaultPrice();
            _this.loading = false;
        });
    };
    UpdateEasementPriceComponent.prototype.onExpertSelected = function (expert) {
        this.easement.expertOpinionExpert = expert;
    };
    UpdateEasementPriceComponent.prototype.isFormValid = function () {
        var isAreaPriceValid = this.easement.unitType === 'A' && this.easement.areaPrice !== undefined && string_utils_1.StringUtils.valueToString(this.easement.areaPrice) !== '';
        var isLengthPriceValid = this.easement.unitType === 'L' && this.easement.lengthPrice !== undefined && string_utils_1.StringUtils.valueToString(this.easement.lengthPrice) !== '';
        var isTotalPriceValid = this.easement.unitType === 'T' && this.easement.totalPrice !== undefined && string_utils_1.StringUtils.valueToString(this.easement.totalPrice) !== '';
        var isSinglePrice = this.easement.priceType === 'S' && this.easement.totalPrice !== undefined && string_utils_1.StringUtils.valueToString(this.easement.totalPrice) !== '';
        var reset = this.easement.priceType === null;
        return !!(isAreaPriceValid || isLengthPriceValid || isTotalPriceValid || isSinglePrice || reset);
    };
    UpdateEasementPriceComponent.prototype.refreshDefaultPrice = function (id) {
        if (id === void 0) { id = ''; }
        if (['L', 'A', 'T'].includes(id)) { // unitType changed
            this.easement.unitType = id;
        }
        if (['O', 'E', 'S', null].includes(id)) { // priceType changed
            this.easement.otherSource = undefined;
            this.easement.totalPrice = undefined;
            this.easement.priceType = id;
        }
        var price = this.calcDefaultPrice();
        this.easement.easementPriceDefault = isNaN(price) ? 0 : price;
        this.refreshPrice();
    };
    UpdateEasementPriceComponent.prototype.refreshPrice = function () {
        var price = this.easement.easementPriceDefault;
        if (this.easement.priceType !== 'S' && this.showCoefficient && this.easement.priceMultiply && this.easement.priceCoefficient) {
            price = this.easement.easementPriceDefault * this.easement.priceCoefficient;
        }
        this.calculatedPrice = _.isNumber(price) ? price : 0; // always displays a price
        if (!this.easement.useCustomRounding || this.easement.priceType === 'S') {
            this.easement.easementPrice = Math.round(this.calculatedPrice);
        }
    };
    UpdateEasementPriceComponent.prototype.onUpdate = function () {
        var _this = this;
        var easement = UpdateEasementPriceComponent.denormalizeData(this.easement);
        return this.restangular
            .one('easements', this.easement.id)
            .customPUT(easement, undefined, { loadCollections: ['constructionObjects'] })
            .then(function (res) {
            _this.dialogRef.close(res.plain());
        });
    };
    /**
     * Calculates default price by the unitType and given unit price.
     *
     * @returns {number}
     */
    UpdateEasementPriceComponent.prototype.calcDefaultPrice = function () {
        var finalPrice = null; // placeholder
        if (this.easement.priceType === 'S') {
            this.easement.otherSource = 'zákon 416/2009 Sb.';
            this.easement.totalPrice = this.easement.totalPrice || 10000;
            finalPrice = this.easement.totalPrice * this.easement.constructionObjects.length;
        }
        else {
            switch (this.easement.unitType) {
                case 'A': // Area
                    if (this.easement.areaPrice !== null && this.easement.vymera !== null) {
                        finalPrice = this.easement.areaPrice * this.easement.vymera;
                    }
                    break;
                case 'L': // Length
                    if (this.easement.lengthPrice !== null && this.easement.delka !== null) {
                        finalPrice = this.easement.lengthPrice * this.easement.delka;
                    }
                    break;
                case 'T': // Total
                    finalPrice = this.easement.totalPrice * 1;
                    break;
            }
        }
        return finalPrice;
    };
    /**
     * Adds general price properties to object to handle them easier.
     */
    UpdateEasementPriceComponent.normalizeData = function (data) {
        if (data.priceType === 'E') {
            data.unitType = data.expertOpinionUnitType || 'A';
        }
        else if (data.priceType === 'O') {
            data.unitType = data.otherUnitType || 'A';
        }
        data.areaPrice = data.expertOpinionAreaPrice || data.otherAreaPrice;
        data.lengthPrice = data.expertOpinionLengthPrice || data.otherLengthPrice;
        data.totalPrice = data.expertOpinionTotalPrice || data.otherTotalPrice;
        return data;
    };
    /**
     * Denormalizes the final data before passing them to the backend.
     * Using this approach, only values selected by user at the moment of saving
     * are actually saved.
     */
    UpdateEasementPriceComponent.denormalizeData = function (data2) {
        var data = __assign({}, data2);
        if (data.priceType === 'E') {
            switch (data.unitType) {
                case 'A':
                    data.expertOpinionAreaPrice = data.areaPrice;
                    data.expertOpinionLengthPrice = undefined;
                    data.expertOpinionTotalPrice = undefined;
                    break;
                case 'L':
                    data.expertOpinionLengthPrice = data.lengthPrice;
                    data.expertOpinionAreaPrice = undefined;
                    data.expertOpinionTotalPrice = undefined;
                    break;
                case 'T':
                    data.expertOpinionTotalPrice = data.totalPrice;
                    data.expertOpinionAreaPrice = undefined;
                    data.expertOpinionLengthPrice = undefined;
            }
            data.expertOpinionUnitType = data.unitType;
            data.otherAreaPrice = undefined;
            data.otherLengthPrice = undefined;
            data.otherTotalPrice = undefined;
        }
        else if (data.priceType === 'O') {
            switch (data.unitType) {
                case 'A':
                    data.otherAreaPrice = data.areaPrice;
                    data.otherLengthPrice = undefined;
                    data.otherTotalPrice = undefined;
                    break;
                case 'L':
                    data.otherLengthPrice = data.lengthPrice;
                    data.otherAreaPrice = undefined;
                    data.otherTotalPrice = undefined;
                    break;
                case 'T':
                    data.otherTotalPrice = data.totalPrice;
                    data.otherAreaPrice = undefined;
                    data.otherLengthPrice = undefined;
            }
            data.otherUnitType = data.unitType;
            data.expertOpinionAreaPrice = undefined;
            data.expertOpinionLengthPrice = undefined;
            data.expertOpinionTotalPrice = undefined;
        }
        else {
            data.otherTotalPrice = data.totalPrice;
            data.otherUnitType = undefined;
            data.expertOpinionAreaPrice = undefined;
            data.expertOpinionLengthPrice = undefined;
            data.expertOpinionTotalPrice = undefined;
        }
        delete data.areaPrice;
        delete data.lengthPrice;
        delete data.totalPrice;
        delete data.unitType;
        return data;
    };
    return UpdateEasementPriceComponent;
}());
exports.UpdateEasementPriceComponent = UpdateEasementPriceComponent;
