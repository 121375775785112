"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var auth_service_1 = require("@app/common/services/auth.service");
var project_service_1 = require("@app/ps/services/project.service");
var dialog_service_1 = require("@app/dialog/dialog.service");
var project_remove_component_1 = require("@app/ps/project-settings/components/project-remove/project-remove.component");
var ProjectSettingsMainComponent = /** @class */ (function () {
    function ProjectSettingsMainComponent(helpIds, APP_BRAND, projectService, authService, dialogService) {
        this.helpIds = helpIds;
        this.APP_BRAND = APP_BRAND;
        this.projectService = projectService;
        this.authService = authService;
        this.dialogService = dialogService;
        this.user = this.authService.getUser();
        this.useTemplatesService = this.authService.getActualProject().useTemplatesService;
        this.onRemoveProject = this.onRemoveProject.bind(this);
    }
    ProjectSettingsMainComponent.prototype.ngOnInit = function () {
    };
    ProjectSettingsMainComponent.prototype.onRemoveProject = function () {
        var project = this.authService.getActualProject();
        var dialog = this.dialogService.open(project_remove_component_1.ProjectRemoveComponent, { data: { project: project } });
        var sub = dialog.afterClosed.subscribe(function () {
            sub.unsubscribe();
        });
    };
    return ProjectSettingsMainComponent;
}());
exports.ProjectSettingsMainComponent = ProjectSettingsMainComponent;
