"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var auth_service_1 = require("@app/common/services/auth.service");
var username_pipe_1 = require("@app/common/pipes/username.pipe");
var WantedSwitchComponent = /** @class */ (function () {
    function WantedSwitchComponent(authService, userNamePipe, restangular) {
        this.authService = authService;
        this.userNamePipe = userNamePipe;
        this.restangular = restangular;
        this.changed = new core_1.EventEmitter();
    }
    WantedSwitchComponent.prototype.ngOnInit = function () {
        this.selected = this.item.wanted;
        this.areaLoading = false;
        this.options = this.getOptionsCaption(this.item.owner_type);
    };
    WantedSwitchComponent.prototype.updateSelection = function (v) {
        var _this = this;
        var oldValue = this.selected;
        this.selected = v;
        this.areaLoading = true;
        this.restangular.one('intersections', this.item.id).all('wanted')
            .customPOST({
            wanted: v
        })
            .then(function () {
            _this.item.user_name = _this.userNamePipe.transform(_this.authService.getUser());
            _this.item.revision_timestamp = new Date().getTime();
            _this.item.wanted = v;
            _this.areaLoading = false;
            _this.changed.emit({ newValue: _this.item.wanted, oldValue: oldValue, ownerType: _this.item.owner_type });
        });
    };
    WantedSwitchComponent.prototype.getOptionsCaption = function (ownerType) {
        switch (ownerType) {
            case 'O':
                return {
                    wanted: 'Ponechat',
                    unwanted: 'Prodat'
                };
            case 'F':
                return {
                    wanted: 'Koupit',
                    unwanted: 'Ponechat'
                };
            case 'S':
                return {
                    wanted: 'Koupit',
                    unwanted: 'Prodat'
                };
        }
    };
    return WantedSwitchComponent;
}());
exports.WantedSwitchComponent = WantedSwitchComponent;
