"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["[_nghost-%COMP%]    > div[_ngcontent-%COMP%] {\n  display: flex;\n  align-items: center;\n  min-width: 280px;\n  max-width: 335px; }\n\n[_nghost-%COMP%]   .ownership-count-column[_ngcontent-%COMP%] {\n  height: 100%;\n  width: 50px;\n  display: flex;\n  vertical-align: middle;\n  align-items: center; }\n\n[_nghost-%COMP%]   .ownership-list-column[_ngcontent-%COMP%] {\n  display: flex;\n  justify-content: flex-start;\n  vertical-align: middle; }"];
exports.styles = styles;
