"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var angular_1 = require("@uirouter/angular");
var callbacks_enum_1 = require("@app/ps/enums/callbacks.enum");
var dialog_service_1 = require("@app/dialog/dialog.service");
var form_case_update_component_1 = require("@app/ps/cases/components/form-case-update/form-case-update.component");
var form_contracts_register_dialog_component_1 = require("@app/ps/cases/components/form-contracts-register-dialog/form-contracts-register-dialog.component");
var update_occupation_component_1 = require("./components/update-occupation/update-occupation.component");
var update_easement_component_1 = require("./components/update-easement/update-easement.component");
var cadastre_entry_proposal_component_1 = require("./components/cadastre-entry-proposal/cadastre-entry-proposal.component");
var case_list_component_1 = require("./components/case-list/case-list.component");
var update_occupation_parcel_component_1 = require("@app/ps/occupations/components/update-occupation-parcel/update-occupation-parcel.component");
var state_utils_1 = require("@app/common/utils/state.utils");
var meeting_request_component_1 = require("./components/meeting-request/meeting-request.component");
var accepted_geometrical_plan_possible_component_1 = require("./components/accepted-geometrical-plan-possible/accepted-geometrical-plan-possible.component");
var expropriation_interim_decision_request_component_1 = require("./components/expropriation-interim-decision-request/expropriation-interim-decision-request.component");
var expropriation_interim_decision_component_1 = require("./components/expropriation-interim-decision/expropriation-interim-decision.component");
var expropriation_administrative_appeal_component_1 = require("./components/expropriation-administrative-appeal/expropriation-administrative-appeal.component");
var expropriation_administrative_appeal_rejected_component_1 = require("./components/expropriation-administrative-appeal-rejected/expropriation-administrative-appeal-rejected.component");
var expropriation_administrative_appeal_accepted_component_1 = require("./components/expropriation-administrative-appeal-accepted/expropriation-administrative-appeal-accepted.component");
var expropriation_compensation_payment_component_1 = require("./components/expropriation-compensation-payment/expropriation-compensation-payment.component");
var simple_case_status_component_1 = require("@app/ps/cases/components/simple-case-status/simple-case-status.component");
var case_service_1 = require("@app/ps/services/case.service");
var sign_investor_component_1 = require("@app/ps/cases/components/sign-investor/sign-investor.component");
var send_owner_component_1 = require("@app/ps/cases/components/send-owner/send-owner.component");
var sign_owner_component_1 = require("@app/ps/cases/components/sign-owner/sign-owner.component");
var receive_owner_component_1 = require("@app/ps/cases/components/receive-owner/receive-owner.component");
var confirm_component_1 = require("@app/ps/cases/components/confirm/confirm.component");
var cadastre_entry_component_1 = require("@app/ps/cases/components/cadastre-entry/cadastre-entry.component");
var expropriation_result_component_1 = require("@app/ps/cases/components/expropriation-result/expropriation-result.component");
var expropriation_appeal_result_component_1 = require("@app/ps/cases/components/expropriation-appeal-result/expropriation-appeal-result.component");
var auction_result_component_1 = require("@app/ps/cases/components/auction-result/auction-result.component");
var entry_proposal_component_1 = require("@app/ps/cases/components/entry-proposal/entry-proposal.component");
var payment_check_component_1 = require("@app/ps/cases/components/payment-check/payment-check.component");
var generate_documents_component_1 = require("@app/ps/cases/components/generate-documents/generate-documents.component");
var undelivered_owner_component_1 = require("@app/ps/cases/components/undelivered-owner/undelivered-owner.component");
var declined_owner_component_1 = require("@app/ps/cases/components/declined-owner/declined-owner.component");
var personal_visit_component_1 = require("@app/ps/cases/components/personal-visit/personal-visit.component");
var update_case_ownership_component_1 = require("@app/ps/cases/components/update-case-ownership/update-case-ownership.component");
var case_detail_component_1 = require("@app/ps/cases/components/case-detail/case-detail.component");
var callback_service_1 = require("@app/ps/services/callback.service");
var create_case_component_1 = require("../titles/components/create-case/create-case.component");
exports.casesState = {
    name: 'symap.project.cases',
    data: {
        title: 'Případy',
    },
    url: '/cases?page&sef&obligationId&cancelStatus&sefReset',
    params: {
        page: {
            dynamic: true,
            type: 'int',
            inherit: false,
        },
        sef: {
            type: 'string',
            inherit: false,
            array: true
        },
        sefReset: {
            type: 'string',
            inherit: false,
        },
        obligationId: {
            type: 'int',
            array: true,
            inherit: false,
        },
        cancelStatus: {
            type: 'string',
            inherit: false,
        },
    },
    views: {
        'content@symap.project': {
            component: case_list_component_1.CaseListComponent,
        }
    }
};
exports.updateCaseState = {
    name: 'symap.project.cases.detail.updateCase',
    url: '/update-case',
    data: {
        title: 'Editace obecných údajů případu',
    },
    resolve: {
        caseObject: ['$stateParams', 'Restangular', resolveCaseObjectFunction],
        previousTab: ['$state', resolvePreviousTabFunction],
    },
    onEnter: [
        '$transition$',
        '$state',
        'callbacks',
        'caseObject',
        'previousTab',
        updateCaseDialogFunction
    ],
};
exports.updateCaseOccupation = {
    name: 'symap.project.cases.detail.updateOccupation',
    url: '/update-occupation/{occupationId}',
    data: {
        title: 'Editace záboru případu',
    },
    params: {
        occupationId: {
            type: 'int',
        },
    },
    resolve: {
        caseId: ['$stateParams', state_utils_1.resolveId],
        occupationId: ['$stateParams', resolveOccupationIdFunction],
    },
    views: {
        'content@symap.project': {
            component: update_occupation_component_1.UpdateOccupationComponent,
        }
    },
};
exports.updateCaseEasement = {
    name: 'symap.project.cases.detail.updateEasement',
    url: '/update-easement/{easementId}',
    data: {
        title: 'Editace věcného břemene případu',
    },
    params: {
        easementId: {
            type: 'int',
        },
    },
    resolve: {
        caseId: ['$stateParams', state_utils_1.resolveId],
        easementId: ['$stateParams', resolveEasementIdFunction],
    },
    views: {
        'content@symap.project': {
            component: update_easement_component_1.UpdateEasementComponent,
        }
    },
};
exports.contractsRegisterState = {
    name: 'symap.project.cases.detail.contractsRegister',
    url: '/contracts-register',
    data: {
        title: 'Zveřejnění smlouvy v registru smluv',
    },
    resolve: {
        caseObject: ['$stateParams', 'Restangular', resolveCaseObjectFunction],
        previousTab: ['$state', resolvePreviousTabFunction],
    },
    onEnter: [
        '$transition$',
        '$state',
        'callbacks',
        'caseObject',
        'previousTab',
        contractsRegisterDialogFunction
    ],
};
exports.cadastreEntryProposalState = {
    name: 'symap.project.cases.detail.cadastreEntryProposal',
    data: {
        title: 'Webová služba pro návrh na vklad',
    },
    url: '/cadastre-entry-proposal',
    params: {},
    resolve: [
        {
            token: "caseId",
            deps: [angular_1.Transition],
            resolveFn: resolveCaseIdFunctionNg2,
        }
    ],
    views: {
        'content@symap.project': {
            component: cadastre_entry_proposal_component_1.CadastreEntryProposalComponent,
        },
    },
};
function contractsRegisterDialogFunction($transition$, $state, callbacks, caseObject, previousTab) {
    var dialogService = $transition$.injector().get(dialog_service_1.DialogService);
    var dialog = dialogService.open(form_contracts_register_dialog_component_1.FormContractsRegisterDialogComponent, {
        data: {
            caseObject: caseObject,
        },
    });
    var sub = dialog.afterClosed.subscribe(function (res) {
        if (res) {
            callbacks.get(callbacks_enum_1.Callbacks.CaseUpdated)(res);
        }
        $state.go('symap.project.cases.detail', { tab: previousTab });
        sub.unsubscribe();
    });
}
exports.contractsRegisterDialogFunction = contractsRegisterDialogFunction;
function resolveCaseIdFunctionNg2(transition) {
    return transition.params().id;
}
exports.resolveCaseIdFunctionNg2 = resolveCaseIdFunctionNg2;
function resolveSimpleStatusConfig($transition$, caseService) {
    var stateName = $transition$.$to().name;
    var chunks = stateName.split('.');
    return caseService.getSimpleCaseStatusConfig(chunks[chunks.length - 1]);
}
exports.resolveSimpleStatusConfig = resolveSimpleStatusConfig;
function resolveObligationType() { return 'FulfillmentOfDecisionExpropriationLiability'; }
exports.resolveObligationType = resolveObligationType;
function resolveOccupationIdFunction($stateParams) {
    return $stateParams.occupationId;
}
exports.resolveOccupationIdFunction = resolveOccupationIdFunction;
function resolveEasementIdFunction($stateParams) {
    return $stateParams.easementId;
}
exports.resolveEasementIdFunction = resolveEasementIdFunction;
function resolveCaseObjectFunction($stateParams, Restangular) {
    return Restangular.one('cases', $stateParams.id).get();
}
exports.resolveCaseObjectFunction = resolveCaseObjectFunction;
function resolvePreviousTabFunction($state) {
    return $state.params.tab;
}
exports.resolvePreviousTabFunction = resolvePreviousTabFunction;
function updateCaseDialogFunction($transition$, $state, callbacks, caseObject, previousTab) {
    return __awaiter(this, void 0, void 0, function () {
        var dialogService, dialog, sub;
        return __generator(this, function (_a) {
            dialogService = $transition$.injector().get(dialog_service_1.DialogService);
            dialog = dialogService.open(form_case_update_component_1.FormCaseUpdateComponent, {
                data: {
                    caseObject: caseObject,
                }
            });
            sub = dialog.afterClosed.subscribe(function (res) {
                if (res) {
                    callbacks.get(callbacks_enum_1.Callbacks.CaseUpdated)(res);
                }
                $state.go('symap.project.cases.detail', { tab: previousTab });
                sub.unsubscribe();
            });
            return [2 /*return*/];
        });
    });
}
exports.updateCaseDialogFunction = updateCaseDialogFunction;
exports.updateOccupationParcelState = {
    name: 'symap.project.cases.detail.updateOccupationParcel',
    url: '/occupation/{occupationId}/update-parcel',
    data: {
        title: 'Aktualizace parcely záboru',
    },
    params: {
        occupationId: {
            type: 'int',
        },
    },
    resolve: {
        occupationId: ['$stateParams', resolveOccupationIdFunction],
    },
    views: {
        'content@symap.project': {
            component: update_occupation_parcel_component_1.UpdateOccupationParcelComponent,
        },
    },
};
exports.meetingRequestState = {
    name: 'symap.project.cases.detail.meetingRequest',
    data: {
        title: 'Požadavek na jednání',
    },
    url: '/meeting-request',
    params: {},
    resolve: [
        {
            token: "caseId",
            deps: [angular_1.Transition],
            resolveFn: resolveCaseIdFunctionNg2,
        }
    ],
    views: {
        'content@symap.project': {
            component: meeting_request_component_1.MeetingRequestComponent,
        },
    },
};
exports.acceptedGeometricalPlanPossibleState = {
    name: 'symap.project.cases.detail.acceptedGeometricalPlanPossible',
    data: {
        title: 'Vlastník souhlasí',
    },
    url: '/accepted-geometrical-plan-possible',
    params: {},
    resolve: [
        {
            token: "caseId",
            deps: [angular_1.Transition],
            resolveFn: resolveCaseIdFunctionNg2,
        }
    ],
    views: {
        'content@symap.project': {
            component: accepted_geometrical_plan_possible_component_1.AcceptedGeometricalPlanPossibleComponent,
        },
    },
};
exports.expropriationInterimDecisionRequestState = {
    name: 'symap.project.cases.detail.expropriationInterimDecisionRequest',
    data: {
        title: 'Žádost o mezitimní rozhodnutí',
    },
    url: '/expropriation-interim-decision-request',
    params: {},
    resolve: [
        {
            token: "caseId",
            deps: [angular_1.Transition],
            resolveFn: resolveCaseIdFunctionNg2,
        }
    ],
    views: {
        'content@symap.project': {
            component: expropriation_interim_decision_request_component_1.ExpropriationInterimDecisionRequestComponent,
        },
    },
};
exports.expropriationInterimDecisionState = {
    name: 'symap.project.cases.detail.expropriationInterimDecision',
    data: {
        title: 'Vydání mezitimního rozhodnutí',
    },
    url: '/expropriation-interim-decision',
    params: {},
    resolve: [
        {
            token: "caseId",
            deps: [angular_1.Transition],
            resolveFn: resolveCaseIdFunctionNg2,
        }
    ],
    views: {
        'content@symap.project': {
            component: expropriation_interim_decision_component_1.ExpropriationInterimDecisionComponent,
        },
    },
};
exports.expropriationAdministrativeAppealState = {
    name: 'symap.project.cases.detail.expropriationAdministrativeAppeal',
    data: {
        title: 'Podána správní žaloba',
    },
    url: '/expropriation-administrative-appeal',
    params: {},
    resolve: [
        {
            token: "caseId",
            deps: [angular_1.Transition],
            resolveFn: resolveCaseIdFunctionNg2,
        }
    ],
    views: {
        'content@symap.project': {
            component: expropriation_administrative_appeal_component_1.ExpropriationAdministrativeAppealComponent,
        },
    },
};
exports.expropriationAdministrativeAppealRejectedState = {
    name: 'symap.project.cases.detail.expropriationAdministrativeAppealRejected',
    data: {
        title: 'Žaloba zamítnuta',
    },
    url: '/expropriation-administrative-appeal-rejected',
    params: {},
    resolve: [
        {
            token: "caseId",
            deps: [angular_1.Transition],
            resolveFn: resolveCaseIdFunctionNg2,
        }
    ],
    views: {
        'content@symap.project': {
            component: expropriation_administrative_appeal_rejected_component_1.ExpropriationAdministrativeAppealRejectedComponent,
        },
    },
};
exports.expropriationAdministrativeAppealAcceptedState = {
    name: 'symap.project.cases.detail.expropriationAdministrativeAppealAccepted',
    data: {
        title: 'Žaloba potvrzena',
    },
    url: '/expropriation-administrative-appeal-accepted',
    params: {},
    resolve: [
        {
            token: "caseId",
            deps: [angular_1.Transition],
            resolveFn: resolveCaseIdFunctionNg2,
        }
    ],
    views: {
        'content@symap.project': {
            component: expropriation_administrative_appeal_accepted_component_1.ExpropriationAdministrativeAppealAcceptedComponent,
        },
    },
};
exports.expropriationCompensationPaymentState = {
    name: 'symap.project.cases.detail.expropriationCompensationPayment',
    data: {
        title: 'Úhrada zálohy na náhradu',
    },
    url: '/expropriation-compensation-payment',
    params: {},
    resolve: [
        {
            token: "caseId",
            deps: [angular_1.Transition],
            resolveFn: resolveCaseIdFunctionNg2,
        }
    ],
    views: {
        'content@symap.project': {
            component: expropriation_compensation_payment_component_1.ExpropriationCompensationPaymentComponent,
        },
    },
};
exports.expropriationEnforceableState = {
    name: 'symap.project.cases.detail.expropriationEnforceable',
    data: {
        title: 'Nabytí právní moci rozhodnutí',
    },
    url: '/expropriation-enforceable',
    params: {},
    resolve: {
        caseId: ['$stateParams', state_utils_1.resolveId],
        config: ['$transition$', case_service_1.CaseService, resolveSimpleStatusConfig]
    },
    views: {
        'content@symap.project': {
            component: simple_case_status_component_1.SimpleCaseStatusComponent,
        },
    },
};
exports.expropriationAppealState = {
    name: 'symap.project.cases.detail.expropriationAppeal',
    data: {
        title: 'Odvolání účastníka řízení',
    },
    url: '/expropriation-appeal',
    params: {},
    resolve: {
        caseId: ['$stateParams', state_utils_1.resolveId],
        config: ['$transition$', case_service_1.CaseService, resolveSimpleStatusConfig]
    },
    views: {
        'content@symap.project': {
            component: simple_case_status_component_1.SimpleCaseStatusComponent,
        },
    },
};
exports.expropriationHearingRepetitionState = {
    name: 'symap.project.cases.detail.expropriationHearingRepetition',
    data: {
        title: 'Opakování ústního jednání',
    },
    url: '/expropriation-hearing-repetition',
    params: {},
    resolve: {
        caseId: ['$stateParams', state_utils_1.resolveId],
        config: ['$transition$', case_service_1.CaseService, resolveSimpleStatusConfig]
    },
    views: {
        'content@symap.project': {
            component: simple_case_status_component_1.SimpleCaseStatusComponent,
        },
    },
};
exports.expropriationProceedingInteruptionExtendedState = {
    name: 'symap.project.cases.detail.expropriationProceedingInteruptionExtended',
    data: {
        title: 'Prodloužení lhůty přerušení',
    },
    url: '/expropriation-proceeding-interuption-extended',
    params: {},
    resolve: {
        caseId: ['$stateParams', state_utils_1.resolveId],
        config: ['$transition$', case_service_1.CaseService, resolveSimpleStatusConfig]
    },
    views: {
        'content@symap.project': {
            component: simple_case_status_component_1.SimpleCaseStatusComponent,
        },
    },
};
exports.expropriationProceedingInteruptedState = {
    name: 'symap.project.cases.detail.expropriationProceedingInterupted',
    data: {
        title: 'Přerušení vyvlastňovacího řízení',
    },
    url: '/expropriation-proceeding-interupted',
    params: {},
    resolve: {
        caseId: ['$stateParams', state_utils_1.resolveId],
        config: ['$transition$', case_service_1.CaseService, resolveSimpleStatusConfig]
    },
    views: {
        'content@symap.project': {
            component: simple_case_status_component_1.SimpleCaseStatusComponent,
        },
    },
};
exports.expropriationAssessmentInteruptedState = {
    name: 'symap.project.cases.detail.expropriationAssessmentInterupted',
    data: {
        title: 'Přerušení posouzení žádosti',
    },
    url: '/expropriation-assessment-interupted',
    params: {},
    resolve: {
        caseId: ['$stateParams', state_utils_1.resolveId],
        config: ['$transition$', case_service_1.CaseService, resolveSimpleStatusConfig]
    },
    views: {
        'content@symap.project': {
            component: simple_case_status_component_1.SimpleCaseStatusComponent,
        },
    },
};
exports.expropriationStopState = {
    name: 'symap.project.cases.detail.expropriationStop',
    data: {
        title: 'Zastavení vyvlastňovacího řízení',
    },
    url: '/expropriation-stop',
    params: {},
    resolve: {
        caseId: ['$stateParams', state_utils_1.resolveId],
        config: ['$transition$', case_service_1.CaseService, resolveSimpleStatusConfig]
    },
    views: {
        'content@symap.project': {
            component: simple_case_status_component_1.SimpleCaseStatusComponent,
        },
    },
};
exports.expropriationCalledHearingState = {
    name: 'symap.project.cases.detail.expropriationCalledHearing',
    data: {
        title: 'Svolání ústního jednání',
    },
    url: '/expropriation-called-hearing',
    params: {},
    resolve: {
        caseId: ['$stateParams', state_utils_1.resolveId],
        config: ['$transition$', case_service_1.CaseService, resolveSimpleStatusConfig]
    },
    views: {
        'content@symap.project': {
            component: simple_case_status_component_1.SimpleCaseStatusComponent,
        },
    },
};
exports.expropriationProceedingStartedState = {
    name: 'symap.project.cases.detail.expropriationProceedingStarted',
    data: {
        title: 'Zahájení vyvlastňovacího řízení',
    },
    url: '/expropriation-proceeding-started',
    params: {},
    resolve: {
        caseId: ['$stateParams', state_utils_1.resolveId],
        config: ['$transition$', case_service_1.CaseService, resolveSimpleStatusConfig]
    },
    views: {
        'content@symap.project': {
            component: simple_case_status_component_1.SimpleCaseStatusComponent,
        },
    },
};
exports.handOverInvestorState = {
    name: 'symap.project.cases.detail.handOverInvestor',
    data: {
        title: 'Předání investorovi',
    },
    url: '/hand-over-investor',
    params: {},
    resolve: {
        caseId: ['$stateParams', state_utils_1.resolveId],
        config: ['$transition$', case_service_1.CaseService, resolveSimpleStatusConfig]
    },
    views: {
        'content@symap.project': {
            component: simple_case_status_component_1.SimpleCaseStatusComponent,
        },
    },
};
exports.paymentInstructionState = {
    name: 'symap.project.cases.detail.paymentInstruction',
    data: {
        title: 'Žádost o úhradu',
    },
    url: '/case-payment-instruction',
    params: {},
    resolve: {
        caseId: ['$stateParams', state_utils_1.resolveId],
        config: ['$transition$', case_service_1.CaseService, resolveSimpleStatusConfig]
    },
    views: {
        'content@symap.project': {
            component: simple_case_status_component_1.SimpleCaseStatusComponent,
        },
    },
};
exports.paymentOrderState = {
    name: 'symap.project.cases.detail.paymentOrder',
    data: {
        title: 'Platební příkaz',
    },
    url: '/case-payment-order',
    params: {},
    resolve: {
        caseId: ['$stateParams', state_utils_1.resolveId],
        config: ['$transition$', case_service_1.CaseService, resolveSimpleStatusConfig]
    },
    views: {
        'content@symap.project': {
            component: simple_case_status_component_1.SimpleCaseStatusComponent,
        },
    },
};
exports.sendAdministratorState = {
    name: 'symap.project.cases.detail.sendAdministrator',
    data: {
        title: 'Odeslání oprávněnému',
    },
    url: '/case-send-administrator',
    params: {},
    resolve: {
        caseId: ['$stateParams', state_utils_1.resolveId],
        config: ['$transition$', case_service_1.CaseService, resolveSimpleStatusConfig]
    },
    views: {
        'content@symap.project': {
            component: simple_case_status_component_1.SimpleCaseStatusComponent,
        },
    },
};
exports.sendInvestorState = {
    name: 'symap.project.cases.detail.sendInvestor',
    url: '/case-send-investor',
    data: {
        title: 'Odeslání investorovi',
    },
    resolve: {
        caseId: ['$stateParams', state_utils_1.resolveId],
        config: ['$transition$', case_service_1.CaseService, resolveSimpleStatusConfig]
    },
    views: {
        'content@symap.project': {
            component: simple_case_status_component_1.SimpleCaseStatusComponent,
        },
    },
};
exports.expropriationProposalState = {
    name: 'symap.project.cases.detail.expropriationProposal',
    data: {
        title: 'Podání žádosti na vyvlastňovací úřad',
    },
    url: '/expropriation-proposal',
    params: {},
    resolve: {
        caseId: ['$stateParams', state_utils_1.resolveId],
        config: ['$transition$', case_service_1.CaseService, resolveSimpleStatusConfig]
    },
    views: {
        'content@symap.project': {
            component: simple_case_status_component_1.SimpleCaseStatusComponent,
        },
    },
};
exports.signAdministratorState = {
    name: 'symap.project.cases.detail.signAdministrator',
    data: {
        title: 'Podpis oprávněným',
    },
    url: '/case-sign-administrator',
    params: {},
    resolve: {
        caseId: ['$stateParams', state_utils_1.resolveId],
        config: ['$transition$', case_service_1.CaseService, resolveSimpleStatusConfig]
    },
    views: {
        'content@symap.project': {
            component: simple_case_status_component_1.SimpleCaseStatusComponent,
        },
    },
};
exports.signInvestorState = {
    name: 'symap.project.cases.detail.signInvestor',
    data: {
        title: 'Podpis smlouvy investorem',
    },
    url: '/case-sign-investor',
    params: {},
    resolve: {
        caseId: ['$stateParams', state_utils_1.resolveId],
    },
    views: {
        'content@symap.project': {
            component: sign_investor_component_1.SignInvestorComponent,
        },
    },
};
exports.sendOwnerState = {
    name: 'symap.project.cases.detail.sendOwner',
    data: {
        title: 'Odeslání vlastníkovi',
    },
    url: '/case-send-owner',
    params: {},
    resolve: {
        caseId: ['$stateParams', state_utils_1.resolveId],
    },
    views: {
        'content@symap.project': {
            component: send_owner_component_1.SendOwnerComponent,
        },
    },
};
exports.signOwnerState = {
    name: 'symap.project.cases.detail.signOwner',
    data: {
        title: 'Podpis vlastníkem',
    },
    url: '/case-sign-owner',
    params: {},
    resolve: {
        caseId: ['$stateParams', state_utils_1.resolveId],
    },
    views: {
        'content@symap.project': {
            component: sign_owner_component_1.SignOwnerComponent,
        },
    },
};
exports.receiveOwnerState = {
    name: 'symap.project.cases.detail.receiveOwner',
    data: {
        title: 'Přijetí vlastníkem',
    },
    url: '/case-receive-owner',
    params: {},
    resolve: {
        caseId: ['$stateParams', state_utils_1.resolveId],
    },
    views: {
        'content@symap.project': {
            component: receive_owner_component_1.ReceiveOwnerComponent,
        },
    },
};
exports.confirmState = {
    name: 'symap.project.cases.detail.confirm',
    data: {
        title: 'Schválení případu',
    },
    url: '/case-confirm',
    params: {},
    resolve: {
        caseId: ['$stateParams', state_utils_1.resolveId],
    },
    views: {
        'content@symap.project': {
            component: confirm_component_1.ConfirmComponent,
        },
    },
};
exports.cadastreEntryState = {
    name: 'symap.project.cases.detail.cadastreEntry',
    data: {
        title: 'Vklad KN',
    },
    url: '/case-cadastre-entry',
    params: {},
    resolve: {
        caseId: ['$stateParams', state_utils_1.resolveId],
    },
    views: {
        'content@symap.project': {
            component: cadastre_entry_component_1.CadastreEntryComponent,
        },
    },
};
exports.expropriationResultState = {
    name: 'symap.project.cases.detail.expropriationResult',
    data: {
        title: 'Rozhodnutí vyvlastňovacího úřadu',
    },
    url: '/expropriation-result',
    params: {},
    resolve: {
        caseId: ['$stateParams', state_utils_1.resolveId],
    },
    views: {
        'content@symap.project': {
            component: expropriation_result_component_1.ExpropriationResultComponent,
        },
    },
};
exports.expropriationAppealResultState = {
    name: 'symap.project.cases.detail.expropriationAppealResult',
    data: {
        title: 'Výsledek odvolání',
    },
    url: '/expropriation-appeal-result',
    params: {},
    resolve: {
        caseId: ['$stateParams', state_utils_1.resolveId],
    },
    views: {
        'content@symap.project': {
            component: expropriation_appeal_result_component_1.ExpropriationAppealResultComponent,
        },
    },
};
exports.auctionResultState = {
    name: 'symap.project.cases.detail.auctionResult',
    data: {
        title: 'Výsledek dražby',
    },
    url: '/auction-result',
    params: {},
    resolve: {
        caseId: ['$stateParams', state_utils_1.resolveId],
    },
    views: {
        'content@symap.project': {
            component: auction_result_component_1.AuctionResultComponent,
        },
    },
};
exports.entryProposalState = {
    name: 'symap.project.cases.detail.entryProposal',
    data: {
        title: 'Návrh na vklad KN',
    },
    url: '/case-entry-proposal',
    params: {},
    resolve: {
        caseId: ['$stateParams', state_utils_1.resolveId],
    },
    views: {
        'content@symap.project': {
            component: entry_proposal_component_1.EntryProposalComponent,
        },
    },
};
exports.paymentCheckPreliminaryState = {
    name: 'symap.project.cases.detail.paymentCheckPreliminary',
    data: {
        title: 'Předběžná kontrola způsobu platby',
    },
    url: '/case-payment-check-preliminary',
    params: {},
    resolve: {
        caseId: ['$stateParams', state_utils_1.resolveId],
        preliminary: [state_utils_1.resolveTrue],
    },
    views: {
        'content@symap.project': {
            component: payment_check_component_1.PaymentCheckComponent,
        },
    },
};
exports.paymentCheckState = {
    name: 'symap.project.cases.detail.paymentCheck',
    data: {
        title: 'Závěrečná kontrola způsobu platby',
    },
    url: '/case-payment-check',
    params: {},
    resolve: {
        caseId: ['$stateParams', state_utils_1.resolveId],
    },
    views: {
        'content@symap.project': {
            component: payment_check_component_1.PaymentCheckComponent,
        },
    },
};
exports.undeliveredOwnerState = {
    name: 'symap.project.cases.detail.undeliveredOwner',
    data: {
        title: 'Vrácení smlouvy nedoručené vlastníkovi',
    },
    url: '/case-undelivered-owner',
    resolve: {
        caseId: ['$stateParams', state_utils_1.resolveId],
    },
    views: {
        'content@symap.project': {
            component: undelivered_owner_component_1.UndeliveredOwnerComponent,
        },
    },
};
exports.declinedOwnerState = {
    name: 'symap.project.cases.detail.declinedOwner',
    data: {
        title: 'Odmítnuto vlastníkem',
    },
    url: '/case-declined-owner',
    params: {},
    resolve: {
        caseId: ['$stateParams', state_utils_1.resolveId],
    },
    views: {
        'content@symap.project': {
            component: declined_owner_component_1.DeclinedOwnerComponent,
        },
    },
};
exports.personalVisitState = {
    name: 'symap.project.cases.detail.personalVisit',
    data: {
        title: 'Osobní návštěva',
    },
    url: '/personal-visit',
    params: {},
    resolve: {
        caseId: ['$stateParams', state_utils_1.resolveId],
    },
    views: {
        'content@symap.project': {
            component: personal_visit_component_1.PersonalVisitComponent,
        },
    },
};
exports.signedSentOwnersState = {
    name: 'symap.project.cases.detail.signedSentOwners',
    data: {
        title: 'Podepsané smlouvy odeslány vlastníkům',
    },
    url: '/signed-sent-owners',
    params: {},
    resolve: {
        caseId: ['$stateParams', state_utils_1.resolveId],
        config: ['$transition$', case_service_1.CaseService, resolveSimpleStatusConfig]
    },
    views: {
        'content@symap.project': {
            component: simple_case_status_component_1.SimpleCaseStatusComponent,
        },
    },
};
exports.generateState = {
    name: 'symap.project.cases.detail.generate',
    data: {
        title: 'Generování dokumentů',
    },
    url: '/generate',
    params: {},
    resolve: {
        caseId: ['$stateParams', state_utils_1.resolveId],
    },
    views: {
        'content@symap.project': {
            component: generate_documents_component_1.GenerateDocumentsComponent,
        },
    },
};
function resolveFinancialType() {
    return 'financial';
}
exports.resolveFinancialType = resolveFinancialType;
exports.generateFinancialControlState = {
    name: 'symap.project.cases.detail.generateFinancialControl',
    data: {
        title: 'Generování dokumentu finanční kontroly',
    },
    url: '/generateFinancialControl',
    params: {},
    resolve: {
        caseId: ['$stateParams', state_utils_1.resolveId],
        type: [resolveFinancialType]
    },
    views: {
        'content@symap.project': {
            component: generate_documents_component_1.GenerateDocumentsComponent,
        },
    },
};
function reolveCaseOwnershipId($stateParams) {
    return $stateParams.caseOwnershipId;
}
exports.reolveCaseOwnershipId = reolveCaseOwnershipId;
exports.updateCaseOwnershipState = {
    name: 'symap.project.cases.detail.updateCaseOwnership',
    data: {
        title: 'Editace vlastníka',
    },
    url: '/update-case-ownership/:caseOwnershipId',
    params: {
        caseOwnershipId: {
            type: 'int',
        },
    },
    resolve: {
        caseId: ['$stateParams', state_utils_1.resolveId],
        caseOwnershipId: ['$stateParams', reolveCaseOwnershipId],
    },
    views: {
        'content@symap.project': {
            component: update_case_ownership_component_1.UpdateCaseOwnershipComponent,
        },
    },
};
function resolveCallbacksFunction(callbackService) {
    return callbackService.createCallback();
}
exports.resolveCallbacksFunction = resolveCallbacksFunction;
exports.caseDetailState = {
    name: 'symap.project.cases.detail',
    url: '/:id/:tab',
    data: {
        title: 'Složka případu',
    },
    views: {
        'content@symap.project': {
            component: case_detail_component_1.CaseDetailComponent,
        }
    },
    resolve: {
        caseId: ['$stateParams', state_utils_1.resolveId],
        callbacks: [callback_service_1.CallbackService, resolveCallbacksFunction],
    },
    params: {
        id: {
            type: 'int',
            value: undefined,
        },
        tab: {
            value: 'owners',
            dynamic: true,
            inherit: false,
            squash: true,
        }
    },
};
exports.callBeforeExpropriationState = {
    name: 'symap.project.cases.detail.callBeforeExpropriation',
    data: {
        title: 'Výzva před vyvlastněním',
    },
    url: '/call-before-expropriation',
    params: {},
    resolve: {
        caseId: ['$stateParams', state_utils_1.resolveId],
        config: ['$transition$', case_service_1.CaseService, resolveSimpleStatusConfig]
    },
    views: {
        'content@symap.project': {
            component: simple_case_status_component_1.SimpleCaseStatusComponent,
        },
    },
};
exports.callBeforeExpropriationReceivedState = {
    name: 'symap.project.cases.detail.callBeforeExpropriationReceived',
    data: {
        title: 'Doručena výzva před vyvlastněním',
    },
    url: '/call-before-expropriation-received',
    params: {},
    resolve: {
        caseId: ['$stateParams', state_utils_1.resolveId],
        config: ['$transition$', case_service_1.CaseService, resolveSimpleStatusConfig]
    },
    views: {
        'content@symap.project': {
            component: simple_case_status_component_1.SimpleCaseStatusComponent,
        },
    },
};
exports.expropriationCaseInProgressState = {
    name: 'symap.project.cases.detail.expropriationCaseInProgress',
    data: {
        title: 'Zahájení vyvlastnění',
    },
    url: '/expropriation-case-in-progress',
    params: {},
    resolve: {
        caseId: ['$stateParams', state_utils_1.resolveId],
        obligationType: resolveObligationType,
        config: ['$transition$', case_service_1.CaseService, resolveSimpleStatusConfig]
    },
    views: {
        'content@symap.project': {
            component: create_case_component_1.CreateCaseComponent,
        },
    },
};
exports.dunningLetterState = {
    name: 'symap.project.cases.detail.dunningLetter',
    data: {
        title: 'Odeslána urgence',
    },
    url: '/dunning-letter',
    params: {},
    resolve: {
        caseId: ['$stateParams', state_utils_1.resolveId],
        config: ['$transition$', case_service_1.CaseService, resolveSimpleStatusConfig]
    },
    views: {
        'content@symap.project': {
            component: simple_case_status_component_1.SimpleCaseStatusComponent,
        },
    },
};
