"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var i0 = require("@angular/core");
var UserNamePipe = /** @class */ (function () {
    function UserNamePipe() {
    }
    UserNamePipe.prototype.transform = function (user, withCompany) {
        if (withCompany === void 0) { withCompany = true; }
        if (!user) {
            return '';
        }
        var words = [];
        if (user.titleBefore) {
            words.push(user.titleBefore);
        }
        if (user.name) {
            words.push(user.name);
        }
        if (user.surname) {
            words.push(user.surname);
        }
        var degree = user.degreeAfter;
        var name = words.join(' ') + (user.degreeAfter ? ', ' + user.degreeAfter : '');
        words = [name];
        if (withCompany && user.company && user.company.companyName) {
            words.push('(' + user.company.companyName + ')');
        }
        return words.join(' ');
    };
    UserNamePipe.ngInjectableDef = i0.defineInjectable({ factory: function UserNamePipe_Factory() { return new UserNamePipe(); }, token: UserNamePipe, providedIn: "root" });
    return UserNamePipe;
}());
exports.UserNamePipe = UserNamePipe;
