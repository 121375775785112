"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var angular_1 = require("@uirouter/angular");
var auth_service_1 = require("@app/common/services/auth.service");
var callback_model_1 = require("@app/ps/models/callback.model");
var callbacks_enum_1 = require("@app/ps/enums/callbacks.enum");
var list_service_1 = require("@app/common/services/list.service");
var parcel_service_1 = require("@app/ps/services/parcel.service");
var settings_service_1 = require("@app/ps/services/settings.service");
var subject_detail_model_1 = require("@app/ps/models/subject-detail.model");
var subject_model_1 = require("@app/common/models/subject.model");
var table_utils_1 = require("@app/common/utils/table.utils");
var word_service_1 = require("@app/common/services/word.service");
var OwnerDetailComponent = /** @class */ (function () {
    function OwnerDetailComponent(helpIds, listService, stateService, parcelService, settingsService, authService, wordService) {
        this.helpIds = helpIds;
        this.listService = listService;
        this.stateService = stateService;
        this.parcelService = parcelService;
        this.settingsService = settingsService;
        this.authService = authService;
        this.wordService = wordService;
        this.ownerContact = {};
        this.notificationCounts = { archived: undefined, unarchived: undefined };
        this.ownerId = this.stateService.params.ownerId;
        this.tab = (this.stateService.params.tab ? this.stateService.params.tab : 'about');
        this.isActiveTab = this.isActiveTab.bind(this);
        this.getTabs = this.getTabs.bind(this);
        this.onUpdateNoteList = this.onUpdateNoteList.bind(this);
        this.onUpdateDocumentList = this.onUpdateDocumentList.bind(this);
        this.onUpdateSubject = this.onUpdateSubject.bind(this);
        this.onUpdateRepresentatives = this.onUpdateRepresentatives.bind(this);
        this.onUpdateProblematicNote = this.onUpdateProblematicNote.bind(this);
    }
    OwnerDetailComponent.prototype.ngOnInit = function () {
        this.prepareParcelColumns();
        this.tabs = this.getTabs();
        this.loadNotes();
        this.loadDocuments();
        this.loadHistory();
        this.loadNotifications();
        this.loadOwnerships();
        this.loadCases();
        this.loadOccupations();
        this.loadEasements();
        this.registerCallbacks();
        this.ownerContact = this.getOwnerContact();
    };
    OwnerDetailComponent.prototype.isActiveTab = function (id) {
        return id === this.tab;
    };
    OwnerDetailComponent.prototype.uiOnParamsChanged = function (changedParams) {
        this.tab = changedParams.tab;
    };
    OwnerDetailComponent.prototype.onUpdateNoteList = function () {
        var noteTab = this.tabs.find(function (tab) { return tab.id === 'notes'; });
        noteTab.name = "Pozn\u00E1mky (" + this.noteList.list.filter(function (a) { return !a.cancelled; }).length + ")";
    };
    OwnerDetailComponent.prototype.onUpdateDocumentList = function () {
        var documentTab = this.tabs.find(function (tab) { return tab.id === 'documents'; });
        documentTab.name = "Dokumenty (" + this.documentList.list.filter(function (a) { return !a.cancelled; }).length + ")";
    };
    OwnerDetailComponent.prototype.onUpdateHistoryList = function () {
        var historyTab = this.tabs.find(function (tab) { return tab.id === 'history'; });
        historyTab.name = "Historie (" + this.historyList.itemCount + ")";
    };
    OwnerDetailComponent.prototype.onUpdateCaseList = function () {
        var caseTab = this.tabs.find(function (tab) { return tab.id === 'cases'; });
        caseTab.name = "P\u0159\u00EDpady (" + this.caseList.itemCount + ")";
    };
    OwnerDetailComponent.prototype.onUpdateNotificationList = function () {
        var _this = this;
        var filter = {
            limit: 1,
            filters: {
                subjectId: this.ownerId,
                archive: false
            }
        };
        var archivedFilter = __assign({}, filter, { filters: __assign({}, filter.filters, { archive: true }) });
        var notificationList = this.listService.createList('notifications', filter);
        var notificationListArchived = this.listService.createList('notifications', archivedFilter);
        this.listService.fetchResult(notificationList).then(function (data) {
            _this.notificationCounts.unarchived = data.itemCount;
            _this.updateNotificationTab();
        });
        this.listService.fetchResult(notificationListArchived).then(function (data) {
            _this.notificationCounts.archived = data.itemCount;
            _this.updateNotificationTab();
        });
    };
    OwnerDetailComponent.prototype.onUpdateParcelList = function () {
        var parcelTab = this.tabs.find(function (tab) { return tab.id === 'parcels'; });
        var occupationsParIDs = this.occupationList && this.occupationList.list
            ? this.occupationList.list.map(function (occupation) { return occupation.parcel.idpar; })
            : [];
        var easementsParIDs = this.easementList && this.easementList.list
            ? this.easementList.list.map(function (easement) { return easement.parcel.idpar; })
            : [];
        var parcelsIDs = __spread(new Set(__spread(occupationsParIDs, easementsParIDs)));
        parcelTab.name = "Parcely (" + parcelsIDs.length + ")";
    };
    OwnerDetailComponent.prototype.onCaseTableSort = function (event) {
        this.listService.sort(this.caseList, event.column, event.ascOrDesc);
    };
    OwnerDetailComponent.prototype.ngOnDestroy = function () {
        this.removeCallbacks();
    };
    /**
     * Keep frozen initial version of contact info
     * that is refreshed on form submit.
     */
    OwnerDetailComponent.prototype.getOwnerContact = function () {
        return {
            phoneNumber: this.owner.subjectDetail && this.owner.subjectDetail.phoneNumber,
            email: this.owner.subjectDetail && this.owner.subjectDetail.email,
            databoxId: this.owner.subjectDetail && this.owner.subjectDetail.databoxId,
        };
    };
    OwnerDetailComponent.prototype.updateNotificationTab = function () {
        var notificationTab = this.tabs.find(function (tab) { return tab.id === 'notifications'; });
        if (this.notificationCounts.unarchived !== undefined && this.notificationCounts.archived !== undefined) {
            notificationTab.name = "Upozorn\u011Bn\u00ED (" + this.notificationCounts.unarchived + "/" + this.notificationCounts.archived + ")";
        }
        else {
            notificationTab.name = 'Upozornění';
        }
    };
    OwnerDetailComponent.prototype.loadNotes = function () {
        var _this = this;
        this.noteList = this.listService.createList('notes', {
            filters: { opsubId: this.owner.opsubId, systemType: false, cancelled: false, },
            sortOrder: { sortBy: 'timestamp', direction: 'desc' }
        }, undefined, {
            title: {
                load: true
            },
            businessCase: {
                load: true
            },
        });
        this.listService.fetchResult(this.noteList).then(function () {
            _this.onUpdateNoteList();
        });
    };
    OwnerDetailComponent.prototype.loadDocuments = function () {
        var _this = this;
        this.documentList = this.listService.createList('attachments', {
            filters: { subjectId: this.ownerId, cancelled: false },
            sortOrder: { sortBy: 'timestamp', direction: 'desc' }
        }, undefined, { businessCase: {} });
        this.listService.fetchResult(this.documentList).then(function () {
            _this.onUpdateDocumentList();
        });
    };
    OwnerDetailComponent.prototype.loadHistory = function () {
        var _this = this;
        this.historyList = this.listService.createList('notes', {
            filters: { opsubId: this.owner.opsubId, systemType: true, },
            sortOrder: { sortBy: 'timestamp', direction: 'desc' }
        }, undefined, {
            subject: {
                load: true
            }
        });
        this.listService.fetchResult(this.historyList).then(function () {
            _this.onUpdateHistoryList();
        });
    };
    OwnerDetailComponent.prototype.canEdit = function () {
        return this.authService.isAuthorized() || this.authService.hasPermission("assignable");
    };
    OwnerDetailComponent.prototype.loadNotifications = function () {
        var _this = this;
        this.notificationList = this.listService.createList('notifications', {
            filters: {
                subjectId: this.ownerId,
                loadCollections: ['knBudouc.futureParcel'],
                archive: false
            },
            sortOrder: { sortBy: 'createdTimestamp', direction: 'desc' }
        });
        this.listService.fetchResult(this.notificationList).then(function () {
            _this.onUpdateNotificationList();
        });
    };
    OwnerDetailComponent.prototype.loadOwnerships = function () {
        this.ownershipList = this.listService.createList('ownerships', {
            filters: {
                subjectId: this.ownerId,
                validity: 'valid'
            },
        }, undefined, {
            subject: { load: true },
            duplicityOwnership: { load: true }
        });
        this.listService.fetchResult(this.ownershipList);
    };
    OwnerDetailComponent.prototype.loadCases = function () {
        var _this = this;
        this.caseList = this.listService.createList('cases', {
            limit: undefined,
            filters: {
                subjectId: this.ownerId,
                loadCollections: ['titles', 'caseOwnerships', 'constructionObjects']
            }
        });
        this.listService.fetchResult(this.caseList).then(function () {
            _this.onUpdateCaseList();
        });
    };
    OwnerDetailComponent.prototype.loadOccupations = function () {
        var _this = this;
        this.occupationList = this.listService.createList('occupations', {
            limit: undefined,
            filters: {
                subjectId: this.ownerId,
                validOrWithCase: true,
                loadCollections: [
                    'parcel.knBudouc.currentParcels',
                    'parcel.knBudouc.currentParcels.bpejList',
                    'constructionObjects'
                ]
            },
            sortOrder: [{ sortBy: 'parcel.parcisKmen' }, { sortBy: 'parcel.parcisPod' }]
        });
        this.listService.fetchResult(this.occupationList).then(function (data) {
            _this.occupationGroups = _this.parcelService.groupOccupationsByParcel(data.list, []);
            _this.occupationUnderYearTableName = _this.parcelService.getTemporaryOccupationName(_this.occupationGroups.underOneYear);
            _this.occupationOverYearTableName = _this.parcelService.getTemporaryOccupationName(_this.occupationGroups.overOneYear);
            _this.onUpdateParcelList();
        });
    };
    OwnerDetailComponent.prototype.loadEasements = function () {
        var _this = this;
        this.easementList = this.listService.createList('easements', {
            limit: undefined,
            filters: {
                subjectId: this.ownerId,
                validOrWithCase: true,
                loadCollections: []
            },
            sortOrder: [{ sortBy: 'parcel.parcisKmen' }, { sortBy: 'parcel.parcisPod' }]
        }, undefined, {
            constructionObjects: {
                load: true,
                attributes: {
                    administrator: { load: true },
                }
            }
        });
        this.listService.fetchResult(this.easementList).then(function () {
            _this.onUpdateParcelList();
        });
    };
    OwnerDetailComponent.prototype.getTabs = function () {
        var _this = this;
        return OwnerDetailComponent.TABS.map(function (tab) {
            return __assign({}, tab, { urlParams: { ownerId: _this.ownerId, tab: tab.id } });
        });
    };
    OwnerDetailComponent.prototype.hideColumn = function (columnID) {
        var tableID = 'titleDetail'; // @TODO - replace
        return this.settingsService.shouldHideColumn(tableID, columnID);
    };
    OwnerDetailComponent.prototype.prepareParcelColumns = function () {
        var occupationPermanentColumns = __spread(OwnerDetailComponent.OCCUPATION_PERMANENT_COLUMNS);
        var occupationUnderOneYearColumns = __spread(OwnerDetailComponent.OCCUPATION_UNDER_YEAR_COLUMNS);
        var occupationOverOneYearColumns = __spread(OwnerDetailComponent.OCCUPATION_OVER_YEAR_COLUMNS);
        var easementColumns = __spread(OwnerDetailComponent.EASEMENT_COLUMNS);
        if (!this.hideColumn('permanentOccupationPriceColumns')) {
            occupationPermanentColumns = occupationPermanentColumns.concat(OwnerDetailComponent.OCCUPATION_PERMANENT_PRICE_COLUMNS);
        }
        if (!this.hideColumn('temporaryUnderYearPriceColumns')) {
            occupationUnderOneYearColumns = occupationUnderOneYearColumns.concat(OwnerDetailComponent.OCCUPATION_UNDER_YEAR_PRICE_COLUMNS);
        }
        if (!this.hideColumn('temporaryOverYearPriceColumns')) {
            occupationOverOneYearColumns = occupationOverOneYearColumns.concat(OwnerDetailComponent.OCCUPATION_OVER_YEAR_PRICE_COLUMNS);
        }
        if (!this.hideColumn('easementsPriceColumns') && !this.authService.getActualProject().easementPriceByShare) {
            easementColumns = easementColumns.concat(OwnerDetailComponent.EASEMENT_PRICE_COLUMNS);
        }
        this.occupationPermanentColumns = table_utils_1.TableUtils.repairColumnsStructure(occupationPermanentColumns);
        this.occupationUnderOneYearColumns = table_utils_1.TableUtils.repairColumnsStructure(occupationUnderOneYearColumns);
        this.occupationOverOneYearColumns = table_utils_1.TableUtils.repairColumnsStructure(occupationOverOneYearColumns);
        this.easementColumns = table_utils_1.TableUtils.repairColumnsStructure(easementColumns);
    };
    OwnerDetailComponent.prototype.registerCallbacks = function () {
        this.callbacks.add(callbacks_enum_1.Callbacks.ProblematicNoteChanged, this.onUpdateProblematicNote);
        this.callbacks.add(callbacks_enum_1.Callbacks.SubjectUpdated, this.onUpdateSubject);
        this.callbacks.add(callbacks_enum_1.Callbacks.RepresentativesUpdated, this.onUpdateRepresentatives);
    };
    OwnerDetailComponent.prototype.removeCallbacks = function () {
        this.callbacks.remove(callbacks_enum_1.Callbacks.ProblematicNoteChanged, this.onUpdateProblematicNote);
        this.callbacks.remove(callbacks_enum_1.Callbacks.SubjectUpdated, this.onUpdateSubject);
        this.callbacks.remove(callbacks_enum_1.Callbacks.RepresentativesUpdated, this.onUpdateRepresentatives);
    };
    OwnerDetailComponent.prototype.onUpdateProblematicNote = function (event) {
        this.owner.subjectDetail = this.owner.subjectDetail || new subject_detail_model_1.SubjectDetailModel();
        this.owner.subjectDetail.problematic = event.owner.subjectDetail.problematic;
        this.loadNotes();
        this.loadHistory();
    };
    OwnerDetailComponent.prototype.onUpdateSubject = function (subjectDetail) {
        this.owner.subjectDetail = __assign({}, subjectDetail);
        this.ownerContact = this.getOwnerContact();
        this.loadHistory();
    };
    OwnerDetailComponent.prototype.onUpdateRepresentatives = function (representatives) {
        this.owner.actingPersons = representatives;
    };
    OwnerDetailComponent.TABS = [
        {
            name: 'Údaje o vlastníkovi',
            id: 'about',
            href: 'symap.project.owners.detail'
        },
        {
            name: 'Zastoupení vlastníka',
            id: 'representation',
            href: 'symap.project.owners.detail'
        },
        {
            name: 'Parcely',
            id: 'parcels',
            href: 'symap.project.owners.detail'
        },
        {
            name: 'Případy',
            id: 'cases',
            href: 'symap.project.owners.detail'
        },
        {
            name: 'Poznámky',
            id: 'notes',
            href: 'symap.project.owners.detail'
        },
        {
            name: 'Upozornění',
            id: 'notifications',
            href: 'symap.project.owners.detail'
        },
        {
            name: 'Historie',
            id: 'history',
            href: 'symap.project.owners.detail'
        },
        {
            name: 'Dokumenty',
            id: 'documents',
            href: 'symap.project.owners.detail'
        },
    ];
    OwnerDetailComponent.OCCUPATION_PERMANENT_COLUMNS = [
        'label_gp',
        { id: 'occupation_area', sortable: true },
        { id: 'occupation_title', sortable: true },
        { id: 'occupation_parcel_title', sortable: true },
        'occupation_concern_object',
        { id: 'occupation_phase', sortable: true },
        { id: 'occupation_occupation', sortable: true },
        { id: 'occupation_cases', sortable: true },
        'current_parcel_title',
        'current_protection',
        'current_concern_object'
    ];
    OwnerDetailComponent.OCCUPATION_UNDER_YEAR_COLUMNS = [
        'label_gp',
        { id: 'occupation_area', sortable: true },
        { id: 'occupation_title', sortable: true },
        { id: 'occupation_parcel_title', sortable: true },
        'occupation_concern_object',
        { id: 'occupation_phase', sortable: true },
        { id: 'occupation_occupation', sortable: true },
        { id: 'occupation_cases', sortable: true },
        'current_parcel_title',
        'current_protection',
        'current_concern_object'
    ];
    OwnerDetailComponent.OCCUPATION_OVER_YEAR_COLUMNS = OwnerDetailComponent.OCCUPATION_UNDER_YEAR_COLUMNS;
    OwnerDetailComponent.EASEMENT_COLUMNS = [
        { id: 'area_name', sortable: true },
        { id: 'title_name', sortable: true },
        { id: 'parcel_title', sortable: true },
        'geometric_plan',
        'construction_object',
        'title',
        'area',
        'length',
        { id: 'case', sortable: true }
    ];
    OwnerDetailComponent.OCCUPATION_PERMANENT_PRICE_COLUMNS = [
        'occupation_price_parcel',
        'occupation_price_vegetation',
        'occupation_price_construction',
        'occupation_price_summary',
    ];
    OwnerDetailComponent.OCCUPATION_UNDER_YEAR_PRICE_COLUMNS = [
        'occupation_assessment_price',
        'occupation_price_land_price',
        'occupation_price_rent_price_year',
        'occupation_price_rent_length',
        'occupation_price_total_rental_price',
    ];
    OwnerDetailComponent.OCCUPATION_OVER_YEAR_PRICE_COLUMNS = OwnerDetailComponent.OCCUPATION_UNDER_YEAR_PRICE_COLUMNS;
    OwnerDetailComponent.EASEMENT_PRICE_COLUMNS = [
        'subject_price'
    ];
    return OwnerDetailComponent;
}());
exports.OwnerDetailComponent = OwnerDetailComponent;
