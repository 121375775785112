"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var SampleStatusEnum;
(function (SampleStatusEnum) {
    SampleStatusEnum["ACTIVE"] = "ACTIVE";
    SampleStatusEnum["INACTIVE"] = "INACTIVE";
    SampleStatusEnum["EXCEPTION"] = "EXCEPTION";
})(SampleStatusEnum = exports.SampleStatusEnum || (exports.SampleStatusEnum = {}));
exports.sampleStatusOptions = [
    {
        id: SampleStatusEnum.ACTIVE,
        name: 'Platný',
    },
    {
        id: SampleStatusEnum.INACTIVE,
        name: 'Neplatný',
    },
    {
        id: SampleStatusEnum.EXCEPTION,
        name: 'Výjimka',
    },
];
