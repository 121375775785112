"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var list_service_1 = require("../../../common/services/list.service");
var listTransform_1 = require("../../../../common/services/pure/listTransform");
var _ = require("lodash");
var auth_service_1 = require("../../../common/services/auth.service");
var case_service_1 = require("@app/ps/services/case.service");
var CasesGenerateDocumentsComponent = /** @class */ (function () {
    function CasesGenerateDocumentsComponent(listService, authService, restangular, caseService) {
        this.listService = listService;
        this.authService = authService;
        this.restangular = restangular;
        this.caseService = caseService;
        this.inputData = {};
        this.countResolved = 0;
        this.allowAssignment = false;
        this.interrupt = false;
        this.loadingGroup = false;
        this.arrangeCases = this.arrangeCases.bind(this);
        this.setActiveDocument = this.setActiveDocument.bind(this);
    }
    CasesGenerateDocumentsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.allowAssignment = this.authService.hasPermission('assignment_edit') && this.authService.hasPermission('assignable');
        this.inputData = this.data.details || {};
        var entityIdFilter = this.data.selectEntities.filters.entityId;
        var filter = {
            limit: null,
            filters: Object.assign(listTransform_1.transformListToFilter({ filters: this.data.selectEntities.filters }).filters, {
                entityId: {
                    values: entityIdFilter.length && this.data.selectEntities.inverseSelection ?
                        entityIdFilter.filter(function (entityId) { return !_this.data.selectEntities.checkedItems.some(function (entity) { return entity.id === entityId; }); }) :
                        (this.data.selectEntities.checkedItems.map(function (entity) { return entity.id; })),
                    negation: !entityIdFilter.length && this.data.selectEntities.inverseSelection,
                },
                loadCollections: ['titles'],
            })
        };
        if (!this.inputData.groups) {
            this.list = this.listService.createList('cases', filter);
            this.listService.fetchResult(this.list);
        }
        else {
            this.countResolved = this.data.selectEntities.count;
            var activeGroup = this.inputData.groups.find(function (group) { return _this.inputData.group && _this.inputData.group.document.key === group.document.key; });
            if (activeGroup) {
                this.setActiveDocument(activeGroup);
            }
        }
    };
    CasesGenerateDocumentsComponent.prototype.ngOnDestroy = function () {
        this.interrupt = true;
    };
    CasesGenerateDocumentsComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        if (!this.inputData.groups) {
            this.list.promise.then(function (cases) { return __awaiter(_this, void 0, void 0, function () {
                var data;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.arrangeCases(cases)];
                        case 1:
                            data = _a.sent();
                            if (data) {
                                this.inputData.groups = data.groups;
                                this.inputData.noStatusGroup = data.noStatusGroup;
                            }
                            return [2 /*return*/];
                    }
                });
            }); });
        }
    };
    CasesGenerateDocumentsComponent.prototype.arrangeCases = function (cases) {
        return __awaiter(this, void 0, void 0, function () {
            var e_1, _a, e_2, _b, e_3, _c, groups, noStatusGroup, allowedTemplates, _d, _e, item, settings, documents, _loop_1, this_1, _f, _g, document_1, e_2_1, _loop_2, documents_1, documents_1_1, document_2, e_1_1;
            return __generator(this, function (_h) {
                switch (_h.label) {
                    case 0:
                        groups = [];
                        noStatusGroup = [];
                        return [4 /*yield*/, this.getAllowedTemplates()];
                    case 1:
                        allowedTemplates = _h.sent();
                        _h.label = 2;
                    case 2:
                        _h.trys.push([2, 15, 16, 17]);
                        _d = __values(cases.list), _e = _d.next();
                        _h.label = 3;
                    case 3:
                        if (!!_e.done) return [3 /*break*/, 14];
                        item = _e.value;
                        if (this.interrupt) {
                            return [2 /*return*/, false];
                        }
                        return [4 /*yield*/, this.loadObligationSetting(item.obligation.id, item.caseStatus.key)];
                    case 4:
                        settings = _h.sent();
                        documents = [];
                        _loop_1 = function (document_1) {
                            var template, templateFiles;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        if (document_1.permission && !this_1.authService.hasPermission(document_1.permission.name)) {
                                            return [2 /*return*/, "continue"];
                                        }
                                        template = allowedTemplates.find(function (allowedTemplate) { return allowedTemplate.key === document_1.key; });
                                        if (!template) {
                                            return [2 /*return*/, "continue"];
                                        }
                                        return [4 /*yield*/, this_1.getTemplates(document_1.key)];
                                    case 1:
                                        templateFiles = _a.sent();
                                        if (!templateFiles.length) {
                                            return [2 /*return*/, "continue"];
                                        }
                                        documents.push({
                                            key: document_1.key,
                                            name: template.name,
                                            permission: document_1.permission,
                                            mandatary: document_1.mandatary,
                                            templates: templateFiles,
                                        });
                                        return [2 /*return*/];
                                }
                            });
                        };
                        this_1 = this;
                        _h.label = 5;
                    case 5:
                        _h.trys.push([5, 10, 11, 12]);
                        _f = __values(settings.documents), _g = _f.next();
                        _h.label = 6;
                    case 6:
                        if (!!_g.done) return [3 /*break*/, 9];
                        document_1 = _g.value;
                        return [5 /*yield**/, _loop_1(document_1)];
                    case 7:
                        _h.sent();
                        _h.label = 8;
                    case 8:
                        _g = _f.next();
                        return [3 /*break*/, 6];
                    case 9: return [3 /*break*/, 12];
                    case 10:
                        e_2_1 = _h.sent();
                        e_2 = { error: e_2_1 };
                        return [3 /*break*/, 12];
                    case 11:
                        try {
                            if (_g && !_g.done && (_b = _f.return)) _b.call(_f);
                        }
                        finally { if (e_2) throw e_2.error; }
                        return [7 /*endfinally*/];
                    case 12:
                        this.countResolved++;
                        if (documents.length === 0) {
                            noStatusGroup.push(item);
                            return [3 /*break*/, 13];
                        }
                        _loop_2 = function (document_2) {
                            var found = groups.find(function (group) { return group.document.key === document_2.key; });
                            if (!found) {
                                found = {
                                    document: document_2,
                                    cases: [],
                                    allowOwner: document_2.key.indexOf('CoveringLetter') !== -1,
                                };
                                groups.push(found);
                            }
                            found.cases.push(item);
                        };
                        try {
                            for (documents_1 = __values(documents), documents_1_1 = documents_1.next(); !documents_1_1.done; documents_1_1 = documents_1.next()) {
                                document_2 = documents_1_1.value;
                                _loop_2(document_2);
                            }
                        }
                        catch (e_3_1) { e_3 = { error: e_3_1 }; }
                        finally {
                            try {
                                if (documents_1_1 && !documents_1_1.done && (_c = documents_1.return)) _c.call(documents_1);
                            }
                            finally { if (e_3) throw e_3.error; }
                        }
                        ;
                        _h.label = 13;
                    case 13:
                        _e = _d.next();
                        return [3 /*break*/, 3];
                    case 14: return [3 /*break*/, 17];
                    case 15:
                        e_1_1 = _h.sent();
                        e_1 = { error: e_1_1 };
                        return [3 /*break*/, 17];
                    case 16:
                        try {
                            if (_e && !_e.done && (_a = _d.return)) _a.call(_d);
                        }
                        finally { if (e_1) throw e_1.error; }
                        return [7 /*endfinally*/];
                    case 17: return [2 /*return*/, {
                            groups: groups,
                            noStatusGroup: noStatusGroup,
                        }];
                }
            });
        });
    };
    CasesGenerateDocumentsComponent.prototype.loadObligationSetting = function (obligationId, caseStatus) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.restangular.one('flow', obligationId).one("status", caseStatus).get()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    CasesGenerateDocumentsComponent.prototype.updateExampleCase = function (group) {
        var _this = this;
        var cases = group.cases;
        this.exampleCase = null;
        if (cases.length) {
            return this.getCase(cases[0].id).then(function (bCase) {
                _this.exampleCase = bCase;
            });
        }
        return Promise.resolve();
    };
    CasesGenerateDocumentsComponent.prototype.notifyValidity = function () {
        var _this = this;
        var cases = this.inputData.group.cases;
        this.notifyParent(function () { return (cases.length) && _this.inputData.group.template && _this.inputData.filename && _this.inputData.filename.some(function (item) { return item.show; }); }, this.inputData);
    };
    CasesGenerateDocumentsComponent.prototype.setActiveDocument = function (group) {
        var _this = this;
        var changed = this.inputData.group !== group;
        this.inputData.group = group;
        if (changed || (!this.loadingGroup && !this.exampleCase)) {
            this.loadingGroup = true;
            this.exampleCase = null;
            var casePromise = this.updateExampleCase(group);
            this.templates = group.document.templates;
            if (this.templates.length === 1) {
                group.template = this.templates[0];
            }
            Promise.all([casePromise]).then(function () {
                _this.loadingGroup = false;
            });
            this.notifyValidity();
        }
        if (!changed) {
            return;
        }
        this.inputData.filename = [];
        this.inputData.filename.push({
            type: 'text'
        });
        this.inputData.filename.push({
            type: 'caseNumber',
            show: true,
        });
        this.inputData.filename.push({
            type: 'text',
            text: '_LV',
            show: true,
        });
        this.inputData.filename.push({
            type: 'titleNumber',
            show: true,
        });
        if (group.allowOwner) {
            this.inputData.filename.push({
                type: 'text',
                text: '_',
                show: true,
            });
            this.inputData.filename.push({
                type: 'owner',
                show: true,
            });
        }
    };
    CasesGenerateDocumentsComponent.prototype.getAllowedTemplates = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.restangular
                            .all('templates')
                            .all('types-allowed')
                            .getList()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    CasesGenerateDocumentsComponent.prototype.getTemplates = function (key) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.restangular.all('templates').customPOST({ filter: { filters: { validity: ['valid'], key: [key] } } }).then(function (data) {
                            return _this.restangular.stripRestangular(data).map(function (template) {
                                template.name = template.attachment.name;
                                return template;
                            });
                        })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    CasesGenerateDocumentsComponent.prototype.getCase = function (caseId) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.restangular
                            .one('cases', caseId).get({
                            loadCollections: [
                                'caseSubjects',
                                'caseOwnerships',
                                'titles',
                            ],
                        }).then(function (bCase) {
                            var dataCaseOwnerships = _this.caseService.getDataCaseOwnerships(bCase.caseOwnerships, bCase.caseSubjects);
                            bCase.dataRecipients = _this.caseService.getRecipients(dataCaseOwnerships, true);
                            return bCase;
                        })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    CasesGenerateDocumentsComponent.prototype.moveSection = function (section, sections, step) {
        var x = _.indexOf(sections, section);
        if (x + step < 0 || x + step >= sections.length) {
            return;
        }
        sections[x] = sections.splice(x + step, 1, sections[x])[0];
    };
    ;
    CasesGenerateDocumentsComponent.prototype.generateFilename = function () {
        var e_4, _a;
        if (!this.exampleCase) {
            return '';
        }
        var name = '';
        try {
            for (var _b = __values(this.inputData.filename), _c = _b.next(); !_c.done; _c = _b.next()) {
                var section = _c.value;
                if (section.show) {
                    switch (section.type) {
                        case 'text':
                            name += section.text;
                            break;
                        case 'caseNumber':
                            name += this.exampleCase.mpp.number;
                            break;
                        case 'titleNumber':
                            name += this.exampleCase.titles.map(function (title) { return title.lv; }).join(',');
                            break;
                        case 'owner':
                            name += this.exampleCase.dataRecipients[0].name;
                            break;
                    }
                }
            }
        }
        catch (e_4_1) { e_4 = { error: e_4_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_4) throw e_4.error; }
        }
        return name;
    };
    CasesGenerateDocumentsComponent.prototype.toggleSection = function (section, sections) {
        if (section.type === 'text') {
            if (section.show) { // remove
                sections.splice(_.indexOf(sections, section), 1);
            }
            else { // add new empty line
                sections.splice(0, 0, {
                    type: 'text',
                    text: '',
                });
            }
        }
        section.show = !section.show;
    };
    return CasesGenerateDocumentsComponent;
}());
exports.CasesGenerateDocumentsComponent = CasesGenerateDocumentsComponent;
