"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var case_model_1 = require("@app/ps/models/case.model");
var contracts_register_legal_exceptions_1 = require("./contracts-register-legal-exceptions");
var FormContractsRegisterComponent = /** @class */ (function () {
    function FormContractsRegisterComponent() {
        this.exceptionSelected = new core_1.EventEmitter();
        this.contractsRegister = {
            publicationException: undefined,
        };
    }
    FormContractsRegisterComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.options = contracts_register_legal_exceptions_1.options;
        this.contractsRegister.publicationException = this.options.find(function (opt) { return opt.name === _this.data.contractRegisterPublicationException; });
    };
    FormContractsRegisterComponent.prototype.onPublicationExceptionSelect = function (evt) {
        this.exceptionSelected.emit(evt.newValue.name);
    };
    return FormContractsRegisterComponent;
}());
exports.FormContractsRegisterComponent = FormContractsRegisterComponent;
