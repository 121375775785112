"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var list_service_1 = require("../../services/list.service");
var ItemsCounterComponent = /** @class */ (function () {
    function ItemsCounterComponent(listService) {
        this.listService = listService;
        this.callbackRegister = new core_1.EventEmitter();
        this.itemsCount = 0;
        this.items = 'záznamů';
        this.onListChanged = this.onListChanged.bind(this);
    }
    ItemsCounterComponent.prototype.ngOnInit = function () {
        this.callbackRegister.emit({
            onListChanged: this.onListChanged,
        });
        this.onListChanged();
    };
    ItemsCounterComponent.prototype.onListChanged = function () {
        this.itemsCount = this.listService.getItemsCount(this.list);
        switch (true) {
            case (this.itemsCount === 1):
                this.items = 'záznam';
                break;
            case (this.itemsCount > 1 && this.itemsCount < 5):
                this.items = 'záznamy';
                break;
            default:
                this.items = 'záznamů';
                break;
        }
    };
    return ItemsCounterComponent;
}());
exports.ItemsCounterComponent = ItemsCounterComponent;
