"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var project_info_component_1 = require("./components/project-info/project-info.component");
exports.projectInfoState = {
    name: 'symap.project.info',
    data: {
        title: 'Karta',
    },
    url: '/info/:tab',
    params: {
        tab: {
            type: 'string',
            value: null,
            dynamic: true,
            inherit: false,
            squash: true,
        }
    },
    views: {
        'content@symap.project': {
            component: project_info_component_1.ProjectInfoComponent,
        }
    }
};
