"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require("leaflet");
require("proj4leaflet");
var d3 = require("d3");
var d3tip = require("d3-tip");
d3.tip = d3tip;
// leaflet.fullscreen po kompilaci schází ve verzi 2.0.0 nedefinovaná proměnná
var screenfull = require("leaflet.fullscreen");
window.screenfull = screenfull;
var static_1 = require("@angular/upgrade/static");
var angular = require("angular");
static_1.setAngularJSGlobal(angular);
require("zone.js");
var core_1 = require("@angular/core");
var core_2 = require("@uirouter/core");
var app_module_ajs_1 = require("./app.module.ajs");
var __NgCli_bootstrap_1 = require("./app.module.ngfactory");
var __NgCli_bootstrap_2 = require("@angular/platform-browser");
app_module_ajs_1.appJS.config(['$urlServiceProvider', function ($urlService) { return $urlService.deferIntercept(); }]);
__NgCli_bootstrap_2.platformBrowser()
    .bootstrapModuleFactory(__NgCli_bootstrap_1.AppModuleNgFactory)
    .then(function (platformRef) {
    var urlService = platformRef.injector.get(core_2.UIRouter).urlService;
    var startUIRouter = function () {
        urlService.listen();
        urlService.sync();
    };
    platformRef.injector.get(core_1.NgZone).run(startUIRouter);
});
