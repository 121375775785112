"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var sample_model_1 = require("@app/sv/models/sample.model");
var document_type_model_1 = require("@app/common/models/document-type.model");
var auth_service_1 = require("@app/common/services/auth.service");
var i0 = require("@angular/core");
var i1 = require("../../common/services/auth.service");
var SampleService = /** @class */ (function () {
    function SampleService(restangular, uploadService, authService) {
        this.restangular = restangular;
        this.uploadService = uploadService;
        this.authService = authService;
    }
    SampleService.prototype.getById = function (sampleId) {
        return this.restangular.one('samples', sampleId)
            .get()
            .then(function (sample) {
            return sample.plain();
        });
    };
    SampleService.prototype.getByIdWithAttributes = function (sampleId, attributes) {
        return this.restangular.one('samples', sampleId)
            .customPOST(attributes)
            .then(function (sample) {
            return sample.plain();
        });
    };
    SampleService.prototype.createByTicket = function (ticket) {
        return this.restangular
            .all('samples/create-by-ticket')
            .customPOST(ticket, '', { loadCollections: [] })
            .then(function (sample) {
            return sample.plain();
        });
    };
    SampleService.prototype.update = function (sample, note) {
        var _this = this;
        var updatedSample;
        return this.restangular
            .one('samples', sample.id)
            .customPUT(sample, '', { loadCollections: [] })
            .then(function (sample) {
            updatedSample = sample.plain();
        })
            .then(function () { return _this.createNotes(updatedSample, (note ? [note] : [])); })
            .then(function () { return updatedSample; });
    };
    SampleService.prototype.updateProjects = function (sample, note) {
        var _this = this;
        return this.restangular
            .one('samples', sample.id).all('projects')
            .customPUT(sample.projects)
            .then(function () { return _this.createNotes(sample, (note ? [note] : [])); });
    };
    SampleService.prototype.updateTemplate = function (sample, file, note, reason) {
        var _this = this;
        return this.createDocuments(sample, [file], 'TEMPLATE', reason)
            .then(function () { return _this.createNotes(sample, [note]); });
    };
    SampleService.prototype.createNotes = function (sample, notes) {
        var _this = this;
        if (notes === void 0) { notes = []; }
        var promises = notes.map(function (note) {
            return _this.restangular
                .all('notes/create')
                .customPOST(__assign({}, note, { sample: sample }));
        });
        return Promise.all(promises);
    };
    SampleService.prototype.createDocuments = function (sample, files, type, note) {
        var _this = this;
        var restBaseUrl = this.authService.getActiveApplicationRestUrl();
        var attachmentType = document_type_model_1.DocumentTypeModel.createById(type).id;
        var promises = files.map(function (file) {
            var filename = file.fileEntry.name;
            if (sample.number) {
                filename = sample.number + (type === 'TEMPLATE' ? '_s' : '') + '.' + file.extension.toLowerCase();
            }
            var sendData = {
                file: file.fileEntry,
                filename: file.fileEntry.name,
                sampleId: sample.id,
                attachmentType: attachmentType,
                baseName: filename
            };
            if (note) {
                sendData.note = note;
            }
            return _this.uploadService.upload({
                url: restBaseUrl + '/attachments',
                data: sendData,
                headers: __assign({}, _this.restangular.defaultHeaders)
            });
        });
        return Promise.all(promises);
    };
    SampleService.ngInjectableDef = i0.defineInjectable({ factory: function SampleService_Factory() { return new SampleService(i0.inject("Restangular"), i0.inject("Upload"), i0.inject(i1.AuthService)); }, token: SampleService, providedIn: "root" });
    return SampleService;
}());
exports.SampleService = SampleService;
