"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var pageable_list_service_1 = require("@app/common/services/pageable.list.service");
var settings_service_1 = require("@app/ps/services/settings.service");
var table_service_1 = require("@app/ps/services/table.service");
var dialog_service_1 = require("@app/dialog/dialog.service");
var confirmation_component_1 = require("@app/dialog/confirmation/confirmation.component");
var class_name_enum_1 = require("@app/dialog/class-name.enum");
var ConstructionObjectImportedListComponent = /** @class */ (function () {
    function ConstructionObjectImportedListComponent(helpIds, restangular, pageableListService, settingsService, tableService, dialogService) {
        this.helpIds = helpIds;
        this.restangular = restangular;
        this.pageableListService = pageableListService;
        this.settingsService = settingsService;
        this.tableService = tableService;
        this.dialogService = dialogService;
        this.defaultFilter = {
            filters: {},
            sortOrder: { sortBy: 'createdDate', direction: 'desc' },
        };
        this.onDeleteImportedConstructionObject = this.onDeleteImportedConstructionObject.bind(this);
    }
    ConstructionObjectImportedListComponent.prototype.ngOnInit = function () {
        this.initializeList();
    };
    ConstructionObjectImportedListComponent.prototype.initializeList = function () {
        this.pageableList = this.pageableListService.get('construction-objects/imports', this.defaultFilter, 'constructionObjectsImportedFilter');
        this.list = this.pageableList.list;
        this.pageableList.load();
    };
    ConstructionObjectImportedListComponent.prototype.uiOnParamsChanged = function (changedParams, $transition$) {
        this.pageableList.uiOnParamsChanged(changedParams, $transition$);
    };
    ConstructionObjectImportedListComponent.prototype.hideColumn = function (columnId) {
        return this.settingsService.shouldHideColumn('constructionObjectsImported', columnId);
    };
    ConstructionObjectImportedListComponent.prototype.getColumnName = function (columnId) {
        return this.tableService.getColumnById('constructionObjectsImported', columnId).name;
    };
    ConstructionObjectImportedListComponent.prototype.onDeleteImportedConstructionObject = function (constructionObjectId) {
        var _this = this;
        var dialog = this.dialogService.open(confirmation_component_1.ConfirmationComponent, {
            data: { msg: 'Opravdu chcete stavební objekt smazat?' },
            className: class_name_enum_1.ClassName.ADJUSTED_DIALOG,
        });
        var sub = dialog.afterClosed.subscribe(function (result) {
            if (result) {
                _this.restangular.all('construction-objects').all('create').all(constructionObjectId).customPOST('{}', 'rollback')
                    .then(function () {
                    _this.pageableList.fetchResults();
                });
            }
            sub.unsubscribe();
        });
    };
    return ConstructionObjectImportedListComponent;
}());
exports.ConstructionObjectImportedListComponent = ConstructionObjectImportedListComponent;
