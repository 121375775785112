"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var subject_name_pipe_1 = require("@app/common/pipes/subject-name.pipe");
var i0 = require("@angular/core");
var i1 = require("../../common/pipes/subject-name.pipe");
var ParentCaseOwnershipNamePipe = /** @class */ (function () {
    function ParentCaseOwnershipNamePipe(subjectNamePipe) {
        this.subjectNamePipe = subjectNamePipe;
    }
    ParentCaseOwnershipNamePipe.prototype.transform = function (caseOwnership) {
        return this.subjectNamePipe.transform(caseOwnership && caseOwnership.ownership && caseOwnership.ownership.subjects[0]);
    };
    ParentCaseOwnershipNamePipe.ngInjectableDef = i0.defineInjectable({ factory: function ParentCaseOwnershipNamePipe_Factory() { return new ParentCaseOwnershipNamePipe(i0.inject(i1.SubjectNamePipe)); }, token: ParentCaseOwnershipNamePipe, providedIn: "root" });
    return ParentCaseOwnershipNamePipe;
}());
exports.ParentCaseOwnershipNamePipe = ParentCaseOwnershipNamePipe;
