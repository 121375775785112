"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var modules_states_1 = require("@app/settings/modules/modules.states");
var STATES = [
    modules_states_1.modulesState, modules_states_1.landingDiState, modules_states_1.landingSyState, modules_states_1.promapLandingState, modules_states_1.vfzeLandingState,
];
var ModulesModule = /** @class */ (function () {
    function ModulesModule() {
    }
    return ModulesModule;
}());
exports.ModulesModule = ModulesModule;
