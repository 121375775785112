"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var word_service_1 = require("@app/common/services/word.service");
var BEFORESIGNSTATMENTSIDS = ['Created', 'SentInvestor', 'ApprovedInvestor', 'DeclinedInvestor', 'SignedInvestor', 'SentOwner', 'ReceivedOwner', 'SignedOwner', 'DeclinedOwner', 'Cancelled'];
var AFTERSIGNSTATMENTSIDS = ['SignedAllOwners', 'EntryProposal', 'CadastreEntry', 'EntryDeclined', 'PaymentOrder', 'PaymentInstruction', 'EntryStopped'];
var EasementCreateParcelRowComponent = /** @class */ (function () {
    function EasementCreateParcelRowComponent(restangular, confirmService, alertService, APP_BRAND, wordService) {
        this.restangular = restangular;
        this.confirmService = confirmService;
        this.alertService = alertService;
        this.APP_BRAND = APP_BRAND;
        this.wordService = wordService;
        this.existingEasementMsg = '';
        this.onToggleIncluded = this.onToggleIncluded.bind(this);
    }
    EasementCreateParcelRowComponent.prototype.ngOnInit = function () {
        if (this.easement.existingEasementInfo) {
            var so = this.wordService.getTranslation('CONSTRUCTION_OBJECT_AKUZATIV');
            this.existingEasementMsg = "Existuj\u00EDc\u00ED VB podle GP \u010D. " + this.easement.existingEasementInfo.gpcis + ", pro " + so + " " + this.easement.existingEasementInfo.constructionObjects[0].socis + " " + this.easement.existingEasementInfo.constructionObjects[0].sotx;
        }
        // if csvParcelIntersection && no warnings, then include
        var gpcis = this.easement.symapParcelIntersection.gpcis;
        var initWarnings = EasementCreateParcelRowComponent.getWarnings(this.easement.importParcel, this.parcel.includedGPcis, gpcis, this.APP_BRAND.PS.TITLE);
        var integrityWarnings = EasementCreateParcelRowComponent.getIntegrityWarnings(initWarnings);
        var csvFilled = this.easement.csvParcelIntersection && this.easement.csvParcelIntersection.length && this.easement.csvParcelIntersection.area;
        // try automatically include parcel if there are no warnings and CSV is filled
        if (csvFilled && integrityWarnings) {
            this.easement.include = true;
            var includedWarnings = EasementCreateParcelRowComponent.getWarnings(this.easement.importParcel, this.parcel.includedGPcis, gpcis, this.APP_BRAND.PS.TITLE);
            var includedIntegrityWarnings = EasementCreateParcelRowComponent.getIntegrityWarnings(includedWarnings);
            // there are cases when set include to "true" can broke integrity then we must reser include to false
            if (includedIntegrityWarnings) {
                this.easement.include = false;
            }
        }
        this.casesFilter = {
            filter: {
                filters: {
                    cancelStatus: { values: ['notCancelled'] },
                    easementId: { values: [this.easement.importParcel.id] },
                },
                limit: 20,
                offset: 0,
                sortOrder: [{ sortBy: 'id', direction: 'asc' }]
            }
        };
    };
    EasementCreateParcelRowComponent.prototype.onToggleIncluded = function () {
        var _this = this;
        // if editable means easement is not in symap
        if (!this.easement.editable && this.easement.include) {
            this.confirmChange().then(function (confirmed) {
                if (confirmed) {
                    _this.toggleIncluded();
                }
            });
        }
        else {
            this.toggleIncluded();
        }
    };
    EasementCreateParcelRowComponent.prototype.getEasementWarnings = function () {
        this.easement.warnings = (this.easement.include
            ? EasementCreateParcelRowComponent.getWarnings(this.easement.importParcel, this.parcel.includedGPcis, this.easement.symapParcelIntersection.gpcis, this.APP_BRAND.PS.TITLE)
            : {});
        return Object.keys(this.easement.warnings).length;
    };
    EasementCreateParcelRowComponent.prototype.confirmChange = function () {
        var _this = this;
        var parcelNumber = this.parcel.parcelName;
        if (!this.easement.countCases) {
            var gpcis = this.easement.existingEasementInfo.gpcis;
            var message = "Na parcele " + parcelNumber + " ji\u017E existuje VB podle GP " + gpcis + ". Chcete toto VB smazat a pou\u017E\u00EDt pouze nov\u00E9?";
            return this.showConfirmChangeModal(message);
        }
        return this.restangular.one('cases').customPOST(this.casesFilter).then(function (data) {
            // chceck status ID
            var easementStateID = data[0].caseStatus.key;
            if (BEFORESIGNSTATMENTSIDS.includes(easementStateID)) {
                var easementNumber = data[0].mpp.number ? "\u010D." + data[0].mpp.number : 'bez čísla';
                var easementState = data[0].caseStatus.commonName;
                var message = "Skute\u010Dn\u011B chcete smazat st\u00E1vaj\u00EDc\u00ED VB na parcele " + parcelNumber + " na kter\u00E9 je nav\u00E1z\u00E1n p\u0159\u00EDpad " + easementNumber + ", ve stavu \"" + easementState + "\"?";
                return _this.showConfirmChangeModal(message);
            }
            if (AFTERSIGNSTATMENTSIDS.includes(easementStateID)) {
                var soNom = _this.wordService.getTranslation('CONSTRUCTION_OBJECT_PLURAL_NOMINATIV');
                var message = "P\u016Fvodn\u00ED smlouva je podeps\u00E1na v\u0161emi stranami, nelze ji zru\u0161it. Na parcele bude v\u00EDce ploch VB v tomto " + soNom + ". Budou \u0159e\u0161en\u00E9 r\u016Fzn\u00FDmi p\u0159\u00EDpady.";
                return _this.showAlertChangeModal(message);
            }
        });
    };
    EasementCreateParcelRowComponent.prototype.showConfirmChangeModal = function (message) {
        return new this.confirmService({
            title: 'potvrzení zrušení VB',
            message: message
        });
    };
    EasementCreateParcelRowComponent.prototype.showAlertChangeModal = function (message) {
        return new this.alertService({
            title: 'potvrzení zrušení VB',
            message: message
        });
    };
    EasementCreateParcelRowComponent.prototype.toggleIncluded = function () {
        var gpcis = this.easement.existingEasementInfo && this.easement.existingEasementInfo.gpcis ?
            this.easement.existingEasementInfo.gpcis : this.easement.symapParcelIntersection.gpcis;
        if (this.easement.include) {
            this.easement.include = false;
            this.parcel.includedGPcis.splice(this.parcel.includedGPcis.indexOf(gpcis), 1);
        }
        else {
            this.easement.include = true;
            this.parcel.includedGPcis.push(gpcis);
        }
    };
    EasementCreateParcelRowComponent.getWarnings = function (parcelValues, includedGPcis, actualGPcis, appName) {
        var warnings = {};
        var NULLAREALENGTHMSG = "Tato parcela je obsahem VFK, pro jej\u00ED import do " + appName + " je pot\u0159eba doplnit \u00FAdaj v\u00FDm\u011Bry a d\u00E9lky VB.";
        if (!parcelValues.hasOwnProperty('length') || parcelValues.length === '') {
            warnings.nullAreaLength = NULLAREALENGTHMSG;
        }
        else {
            if ((parcelValues.length || parcelValues.length === '0') && isNaN(EasementCreateParcelRowComponent.getNumberFromString(parcelValues.length))) {
                warnings.nullLength = 'Hodnota délky není číslo.';
            }
        }
        // validate area user input
        if (parcelValues.area === '') {
            warnings.nullAreaLength = NULLAREALENGTHMSG;
        }
        else {
            if ((parcelValues.area || parcelValues.area === '0') && isNaN(EasementCreateParcelRowComponent.getNumberFromString(parcelValues.area))) {
                warnings.nullArea = 'Hodnota plochy není číslo.';
            }
        }
        // check if parcel contain more than one GP with equal name
        var sameGPCIS = includedGPcis.filter(function (gpcis) { return gpcis === actualGPcis; });
        if (sameGPCIS.length > 1) {
            warnings.moreGPcis = "Na t\u00E9to parcele ji\u017E existuje VB podle GP " + actualGPcis + ". Odstra\u0148te jedno z VB z tohoto GP.";
        }
        return warnings;
    };
    EasementCreateParcelRowComponent.getIntegrityWarnings = function (warnings) {
        return Object.keys(warnings).length === 0 || Object.keys(warnings).length === 1 && Object.keys(warnings)[0] === 'moreGPcis';
    };
    EasementCreateParcelRowComponent.getNumberFromString = function (strNumber) {
        return isNaN(strNumber) ? Number(strNumber.replace(',', '.')) : strNumber;
    };
    return EasementCreateParcelRowComponent;
}());
exports.EasementCreateParcelRowComponent = EasementCreateParcelRowComponent;
