"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var _ = require("lodash");
var dialog_service_1 = require("@app/dialog/dialog.service");
var project_subject_name_pipe_1 = require("@app/ps/pipes/project-subject-name.pipe");
var checklist_service_1 = require("@app/common/services/checklist.service");
var series_service_1 = require("@app/ps/services/series.service");
var administrator_edit_component_1 = require("@app/ps/components/administrator-edit/administrator-edit.component");
var settings_service_1 = require("@app/ps/services/settings.service");
var ConstructionObjectUpdateComponent = /** @class */ (function () {
    function ConstructionObjectUpdateComponent(helpIds, restangular, projectSubjectNamePipe, dialogService, seriesService, checklistService, settingsService) {
        this.helpIds = helpIds;
        this.restangular = restangular;
        this.projectSubjectNamePipe = projectSubjectNamePipe;
        this.dialogService = dialogService;
        this.seriesService = seriesService;
        this.checklistService = checklistService;
        this.settingsService = settingsService;
        this.loading = true;
        this.loadingActingPersons = false;
        this.administratorTypes = [
            {
                id: undefined,
                value: 'Neurčen',
            },
            {
                id: 'I',
                value: 'Investor je správcem',
            },
            {
                id: 'A',
                value: 'Jiný subjekt',
            },
        ];
        this.seriesParsed = [];
        this.reloadAdministratorRegister = this.reloadAdministratorRegister.bind(this);
        this.onChangeAdministratorType = this.onChangeAdministratorType.bind(this);
        this.onUpdate = this.onUpdate.bind(this);
        this.onChangeAdministrator = this.onChangeAdministrator.bind(this);
    }
    ConstructionObjectUpdateComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.settingsService.getSettingsLoader()];
                    case 1:
                        _a.sent();
                        this.restangular
                            .one('construction-objects', this.constructionObjectId)
                            .get({
                            loadCollections: [
                                'series',
                                'actingPersons',
                                'administrator',
                                'administrator.actingPersons',
                            ],
                        })
                            .then(function (data) {
                            _this.constructionObject = data.plain();
                            _this.actingPersons = _this.checklistService.get(_this.constructionObject.actingPersons);
                            if (_this.constructionObject.series) {
                                _this.series = _this.seriesService.parseSingleSeries(_this.constructionObject.series);
                            }
                            if (_this.constructionObject.administrator && _this.constructionObject.administratorType && _this.constructionObject.administratorType === 'A') {
                                _this.constructionObject.administrator.actingPersons = _this.uniqueMergeByKey(_this.constructionObject.actingPersons, _this.constructionObject.administrator.actingPersons, 'id');
                            }
                            if (!_this.constructionObject.series) {
                                _this.constructionObject.series = [];
                            }
                            _this.seriesService.parseSeries(_this.constructionObject.series, _this.seriesParsed);
                            _this.loading = false;
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    ConstructionObjectUpdateComponent.prototype.reloadAdministratorRegister = function (reloadFn) {
        this.reloadAdministrators = reloadFn;
    };
    ConstructionObjectUpdateComponent.prototype.onChangeAdministratorType = function () {
        this.constructionObject.administrator = undefined;
        this.actingPersons.empty();
    };
    ConstructionObjectUpdateComponent.prototype.onChangeAdministrator = function () {
        var _this = this;
        this.actingPersons.empty();
        if (this.constructionObject.administratorType && this.constructionObject.administratorType === 'A' && this.constructionObject.administrator) {
            this.constructionObject.administrator.actingPersons = undefined;
            this.loadingActingPersons = true;
            this.restangular
                .one('administrators', this.constructionObject.administrator.id)
                .all('acting-persons')
                .getList()
                .then(function (data) {
                _this.constructionObject.administrator.actingPersons = data.plain().filter(function (ap) { return !ap.cancelled && ap.roleContract; });
                _this.loadingActingPersons = false;
            });
        }
    };
    ConstructionObjectUpdateComponent.prototype.onUpdateAdministrator = function () {
        var _this = this;
        var dialog = this.dialogService.open(administrator_edit_component_1.AdministratorEditComponent, {
            data: { administratorId: this.constructionObject.administrator.id }
        });
        var sub = dialog.afterClosed.subscribe(function (res) {
            if (res) {
                res.actingPersons = _this.uniqueMergeByKey(_this.constructionObject.actingPersons, res.actingPersons, 'id');
                _.extend(_this.constructionObject.administrator, res);
            }
            sub.unsubscribe();
        });
    };
    ConstructionObjectUpdateComponent.prototype.onCreateAdministrator = function () {
        var _this = this;
        var dialog = this.dialogService.open(administrator_edit_component_1.AdministratorEditComponent, { data: {} });
        var sub = dialog.afterClosed.subscribe(function (res) {
            if (res) {
                _this.constructionObject.administrator = res;
                _this.onChangeAdministrator();
                _this.reloadAdministrators();
            }
            sub.unsubscribe();
        });
    };
    ConstructionObjectUpdateComponent.prototype.onUpdate = function () {
        var params = {
            loadCollections: [
                'actingPersons',
                'administrator',
            ],
            update: [
                'series',
                'administrator',
            ]
        };
        this.constructionObject.series = this.constructionObject.series.filter(function (item) { return item.id; });
        this.seriesService.sectionsToSeries(this.seriesParsed, this.constructionObject.series);
        return this.restangular.one('construction-objects', this.constructionObject.id)
            .customPUT(this.constructionObject, '', params);
    };
    /**
     * Merges unique items from two object arrays by key.
     *
     * @param {array} arr1 - array of objects
     * @param {array} arr2 - array of objects
     * @param {string} key - key to compare by
     * @returns {array}
     */
    ConstructionObjectUpdateComponent.prototype.uniqueMergeByKey = function (arr1, arr2, key) {
        var _this = this;
        var include = function (item) { return (!item.cancelled && item.roleContract) || _this.actingPersons.isChecked(item); };
        return _.filter(_.uniqBy(_.union(arr1, arr2), key), include);
    };
    ConstructionObjectUpdateComponent.prototype.hideColumnList = function (columnID) {
        var tableID = 'constructionObjects';
        return this.settingsService.shouldHideColumn(tableID, columnID);
    };
    return ConstructionObjectUpdateComponent;
}());
exports.ConstructionObjectUpdateComponent = ConstructionObjectUpdateComponent;
