"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var map_storage_helpers_service_1 = require("@app/map/services/map-storage-helpers.service");
var i0 = require("@angular/core");
var i1 = require("./map-storage-helpers.service");
var MapStorageService = /** @class */ (function () {
    function MapStorageService(mapStorageHelpersService) {
        this.mapStorageHelpersService = mapStorageHelpersService;
        this.map = {};
    }
    MapStorageService.prototype.setMap = function (m, id) {
        var defer = this.mapStorageHelpersService.getUnresolvedDefer(this.map, id);
        defer.resolve(m);
        this.mapStorageHelpersService.setResolvedDefer(this.map, id);
    };
    MapStorageService.prototype.getMap = function (id) {
        var defer = this.mapStorageHelpersService.getDefer(this.map, id);
        return defer.promise;
    };
    MapStorageService.prototype.resetMap = function (id) {
        this.map[id] = undefined;
    };
    MapStorageService.ngInjectableDef = i0.defineInjectable({ factory: function MapStorageService_Factory() { return new MapStorageService(i0.inject(i1.MapStorageHelpersService)); }, token: MapStorageService, providedIn: "root" });
    return MapStorageService;
}());
exports.MapStorageService = MapStorageService;
