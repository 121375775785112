"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var i0 = require("@angular/core");
var ProjectSubjectTypeNamePipe = /** @class */ (function () {
    function ProjectSubjectTypeNamePipe() {
    }
    ProjectSubjectTypeNamePipe.prototype.transform = function (type) {
        if (type === 'OPO') {
            return 'Právnická osoba';
        }
        if (type === 'OPO_OSS') {
            return 'Organizační složka státu';
        }
        if (type === 'OPO_PO') {
            return 'Příspěvková organizace';
        }
        if (type === 'OFO') {
            return 'Fyzická osoba';
        }
        return '';
    };
    ProjectSubjectTypeNamePipe.ngInjectableDef = i0.defineInjectable({ factory: function ProjectSubjectTypeNamePipe_Factory() { return new ProjectSubjectTypeNamePipe(); }, token: ProjectSubjectTypeNamePipe, providedIn: "root" });
    return ProjectSubjectTypeNamePipe;
}());
exports.ProjectSubjectTypeNamePipe = ProjectSubjectTypeNamePipe;
