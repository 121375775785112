"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var auth_service_1 = require("@app/common/services/auth.service");
var map_property_status_service_1 = require("@app/dimap/map/services/map-property-status.service");
var ParcelAreaComponent = /** @class */ (function () {
    function ParcelAreaComponent(authService, mapPropertyStatusService, restangular) {
        this.authService = authService;
        this.mapPropertyStatusService = mapPropertyStatusService;
        this.restangular = restangular;
        this.toKeep = 0;
        this.toBuy = 0;
        this.toSell = 0;
        this.loading = true;
        this.isDisplayed = true;
    }
    ParcelAreaComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.classToKeep = this.mapPropertyStatusService.propertyStatus.ownedInside;
        this.classToBuy = this.mapPropertyStatusService.propertyStatus.othersInside;
        this.classToSell = this.mapPropertyStatusService.propertyStatus.ownedOutside;
        if (this.authService.hasPermission('property_status,manage_property_status')) {
            this.restangular.one('parcels', this.parcelId).all('areas')
                .customPOST()
                .then(function (data) {
                if (data) {
                    _this.toBuy = data.propertyareabuy;
                    _this.toKeep = data.propertyareakeep;
                    _this.toSell = data.propertyareasell;
                }
                else {
                    _this.isDisplayed = false;
                }
                _this.loading = false;
            });
        }
        else {
            this.isDisplayed = false;
            this.loading = false;
        }
    };
    return ParcelAreaComponent;
}());
exports.ParcelAreaComponent = ParcelAreaComponent;
