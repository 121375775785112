"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var modules_service_1 = require("@app/common/services/modules.service");
var core_1 = require("@angular/core");
var auth_service_1 = require("@app/common/services/auth.service");
var menuConfig_1 = require("@app/models/menuConfig");
var MainComponent = /** @class */ (function () {
    function MainComponent(applications, localStorageService, approveTermsService, changePasswordService, googleAnalyticsService, ENV, authService, modulesService) {
        this.applications = applications;
        this.localStorageService = localStorageService;
        this.approveTermsService = approveTermsService;
        this.changePasswordService = changePasswordService;
        this.googleAnalyticsService = googleAnalyticsService;
        this.ENV = ENV;
        this.authService = authService;
        this.modulesService = modulesService;
        this.collapsed = false;
        this.servicedeskUrl = undefined;
        this.menuConfig = [];
        this.checkPasswordChange = this.checkPasswordChange.bind(this);
    }
    MainComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.authService.setActiveApplication(this.applications.vfze.name);
                this.authService.updateBaseUrl();
                this.authService.updateAuthorizationHeader();
                this.googleAnalyticsService.onUserCome();
                this.collapsed = this.localStorageService.get('menuOpened');
                this.user = this.authService.getUser();
                this.servicedeskUrl = this.authService.getServiceDeskUrl();
                this.menuConfig = this.getMenuConfig();
                this.checkApprovedTerms().then(this.checkPasswordChange);
                return [2 /*return*/];
            });
        });
    };
    MainComponent.prototype.checkApprovedTerms = function () {
        var _this = this;
        return this.modulesService.getUserApprovedTerms(this.user.id).then(function (approvedTerms) {
            if (!approvedTerms.includes(_this.applications.vfze.name)) {
                return _this.approveTermsService({
                    module: _this.applications.vfze.name,
                    userId: _this.user.id,
                });
            }
        });
    };
    MainComponent.prototype.checkPasswordChange = function () {
        if (this.authService.isFirstLogin()) {
            return this.changePasswordService();
        }
    };
    MainComponent.prototype.onCollapse = function () {
        this.collapsed = !this.collapsed;
        this.localStorageService.set('menuOpened', this.collapsed);
    };
    MainComponent.prototype.logout = function () {
        this.authService.logout();
    };
    MainComponent.prototype.getMenuConfig = function () {
        var menu = [
            new menuConfig_1.MenuConfig('Validátor', 'vfze.validation', 'fa-check'),
            new menuConfig_1.MenuConfig('Generování', 'vfze.generate', 'fa-cloud-download'),
            new menuConfig_1.MenuConfig('Import', 'vfze.import', 'fa fa-cloud-upload'),
            new menuConfig_1.MenuConfig('Nastavení', 'vfze.settings', 'fa fa-wrench'),
        ];
        if (!this.authService.hasPermission('generate_vfze')) {
            var gItem = menu.find(function (i) { return i.title === 'Generování'; });
            menu.splice(menu.indexOf(gItem), 1);
            var iItem = menu.find(function (i) { return i.title === 'Import'; });
            menu.splice(menu.indexOf(iItem), 1);
        }
        return menu;
    };
    return MainComponent;
}());
exports.MainComponent = MainComponent;
