"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var PricingExpertOpinionStepComponent = /** @class */ (function () {
    function PricingExpertOpinionStepComponent() {
        this.backCallback = new core_1.EventEmitter();
        this.submitCallback = new core_1.EventEmitter();
        this.loading = true;
    }
    PricingExpertOpinionStepComponent.DEFAULT_SUM = {
        priceDefault: 0,
        priceDefaultRounded: 0,
        priceCoefficient: 0,
        priceCoefficientRounded: 0,
        priceCoefficientWithLand: 0,
        priceCoefficientWithoutLand: 0,
    };
    return PricingExpertOpinionStepComponent;
}());
exports.PricingExpertOpinionStepComponent = PricingExpertOpinionStepComponent;
