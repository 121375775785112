"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var list_service_1 = require("../../services/list.service");
var PagesCounterComponent = /** @class */ (function () {
    function PagesCounterComponent(listService) {
        this.listService = listService;
        this.callbackRegister = new core_1.EventEmitter();
        this.totalPages = 0;
        this.currentPage = 0;
        this.onListChanged = this.onListChanged.bind(this);
    }
    PagesCounterComponent.prototype.ngOnInit = function () {
        this.callbackRegister.emit({
            onListChanged: this.onListChanged,
        });
        this.onListChanged();
    };
    PagesCounterComponent.prototype.onListChanged = function () {
        this.totalPages = this.listService.getTotalPages(this.list);
        this.currentPage = this.listService.getCurrentPage(this.list);
    };
    return PagesCounterComponent;
}());
exports.PagesCounterComponent = PagesCounterComponent;
