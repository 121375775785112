"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var easement_create_step_component_1 = require("@app/ps/construction-objects/components/easement-create-step/easement-create-step.component");
var construction_object_name_pipe_1 = require("@app/common/pipes/construction-object-name.pipe");
var auth_service_1 = require("@app/common/services/auth.service");
var EasementCreateInfoComponent = /** @class */ (function (_super) {
    __extends(EasementCreateInfoComponent, _super);
    function EasementCreateInfoComponent(restangular, uploadService, constructionObjectNamePipe, authService) {
        var _this = _super.call(this) || this;
        _this.restangular = restangular;
        _this.uploadService = uploadService;
        _this.constructionObjectNamePipe = constructionObjectNamePipe;
        _this.authService = authService;
        // aux
        _this.restBaseUrl = _this.authService.getActiveApplicationRestUrl();
        _this.onSubmit = _this.onSubmit.bind(_this);
        return _this;
    }
    EasementCreateInfoComponent.prototype.ngOnInit = function () {
        if (!this.data.vfkId) {
            this.backCallback.emit();
        }
    };
    EasementCreateInfoComponent.prototype.isFormValid = function () {
        return !!(this.dateOfConfirm
            && this.dateOfCheck
            && this.constructionObject
            && this.area
            && this.gpNumber
            && this.gpCreator
            && this.processNumber);
    };
    EasementCreateInfoComponent.prototype.onSubmit = function () {
        var _this = this;
        var data = {
            vfkId: this.data.vfkId,
            constructionObjectId: this.constructionObject.id,
            kuId: this.area.id,
            cisloGp: this.gpNumber,
            zhotovitelGp: this.gpCreator,
            datumOvereniGp: this.dateOfCheck,
            datumZhotoveniGp: this.dateOfConfirm,
            cisloJednaci: this.processNumber
        };
        return this.uploadService.upload({
            url: this.restBaseUrl + '/vfks/user/upload',
            data: data,
            headers: __assign({}, this.restangular.defaultHeaders)
        }).then(function () {
            _this.submitCallback.emit();
        });
    };
    return EasementCreateInfoComponent;
}(easement_create_step_component_1.EasementCreateStepComponent));
exports.EasementCreateInfoComponent = EasementCreateInfoComponent;
