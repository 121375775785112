"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./switch.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/common");
var i3 = require("./switch.component");
var styles_SwitchComponent = [i0.styles];
var RenderType_SwitchComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SwitchComponent, data: {} });
exports.RenderType_SwitchComponent = RenderType_SwitchComponent;
function View_SwitchComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.value; _ck(_v, 1, 0, currVal_0); }); }
function View_SwitchComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [], [[8, "className", 0]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "fa ", _v.parent.context.$implicit.icon, ""); _ck(_v, 0, 0, currVal_0); }); }
function View_SwitchComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "li", [["class", "gmt-switch__options__item is-uppercase pr-10 pl-10 pb-5 pt-5"]], null, [[null, "click"], [null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSelect(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (_co.onSwitchMouseEnter.emit({ event: $event, option: _v.context.$implicit }) !== false);
        ad = (pd_1 && ad);
    } if (("mouseleave" === en)) {
        var pd_2 = (_co.onSwitchMouseLeave.emit({ event: $event }) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "gmt-switch__options__item--active": 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SwitchComponent_2)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SwitchComponent_3)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "gmt-switch__options__item is-uppercase pr-10 pl-10 pb-5 pt-5"; var currVal_1 = _ck(_v, 2, 0, ((_v.context.$implicit === _co.selected) || (_v.context.$implicit.id === _co.selectedId))); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = !_v.context.$implicit.icon; _ck(_v, 4, 0, currVal_2); var currVal_3 = _v.context.$implicit.icon; _ck(_v, 6, 0, currVal_3); }, null); }
function View_SwitchComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "gmt-switch"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "ul", [["class", "gmt-switch__options"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SwitchComponent_1)), i1.ɵdid(3, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.options; _ck(_v, 3, 0, currVal_0); }, null); }
exports.View_SwitchComponent_0 = View_SwitchComponent_0;
function View_SwitchComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "gmt-switch", [], null, null, null, View_SwitchComponent_0, RenderType_SwitchComponent)), i1.ɵdid(1, 114688, null, 0, i3.SwitchComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_SwitchComponent_Host_0 = View_SwitchComponent_Host_0;
var SwitchComponentNgFactory = i1.ɵccf("gmt-switch", i3.SwitchComponent, View_SwitchComponent_Host_0, { options: "options", selected: "selected", selectedId: "selectedId" }, { selectedChange: "selectedChange", select: "select", selectedIdChange: "selectedIdChange", onSwitchMouseEnter: "onSwitchMouseEnter", onSwitchMouseLeave: "onSwitchMouseLeave" }, []);
exports.SwitchComponentNgFactory = SwitchComponentNgFactory;
