"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var user_1 = require("@app/models/user");
var LoginComponent = /** @class */ (function () {
    function LoginComponent() {
        this.withCompany = true;
        this.logoutClick = new core_1.EventEmitter();
    }
    LoginComponent.prototype.ngOnInit = function () {
    };
    LoginComponent.prototype.logout = function (event) {
        this.logoutClick.emit(null);
    };
    return LoginComponent;
}());
exports.LoginComponent = LoginComponent;
