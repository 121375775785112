"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var FormCaseEntryProposalComponent = /** @class */ (function () {
    function FormCaseEntryProposalComponent() {
        this.callbackRegister = new core_1.EventEmitter();
        this.isValid = this.isValid.bind(this);
    }
    FormCaseEntryProposalComponent.prototype.ngOnInit = function () {
        this.callbackRegister.emit({
            isValid: this.isValid,
        });
        this.data.entryProposalDate = new Date();
    };
    FormCaseEntryProposalComponent.prototype.isValid = function () {
        return !!this.data.entryProposalDate;
    };
    return FormCaseEntryProposalComponent;
}());
exports.FormCaseEntryProposalComponent = FormCaseEntryProposalComponent;
