"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var document_type_model_1 = require("@app/common/models/document-type.model");
var i0 = require("@angular/core");
var DocumentTypePipe = /** @class */ (function () {
    function DocumentTypePipe() {
    }
    DocumentTypePipe.prototype.transform = function (document, field) {
        var documentType = document_type_model_1.DocumentTypeModel.createById(document.type);
        switch (field) {
            case 'title':
                return documentType.title;
            case 'icon':
                return documentType.icon;
        }
    };
    DocumentTypePipe.ngInjectableDef = i0.defineInjectable({ factory: function DocumentTypePipe_Factory() { return new DocumentTypePipe(); }, token: DocumentTypePipe, providedIn: "root" });
    return DocumentTypePipe;
}());
exports.DocumentTypePipe = DocumentTypePipe;
