"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var angular_1 = require("@uirouter/angular");
var common_1 = require("@angular/common");
var moment = require("moment");
var auth_service_1 = require("@app/common/services/auth.service");
var string_utils_1 = require("@app/common/utils/string.utils");
var customer_name_pipe_1 = require("@app/common/pipes/customer-name.pipe");
var customer_contract_name_pipe_1 = require("@app/common/pipes/customer-contract-name.pipe");
var CreateProjectSecondStepComponent = /** @class */ (function () {
    function CreateProjectSecondStepComponent(restangular, configRestangular, helpIds, customerNamePipe, customerContractNamePipe, stateService, authService, datePipe) {
        this.restangular = restangular;
        this.configRestangular = configRestangular;
        this.helpIds = helpIds;
        this.customerNamePipe = customerNamePipe;
        this.customerContractNamePipe = customerContractNamePipe;
        this.stateService = stateService;
        this.authService = authService;
        this.datePipe = datePipe;
        this.projectName = '';
        this.prijemceIdentifikatorSmlouvy = '';
        this.prijemcePredmetSmlouvy = '';
        this.prijemceDatumUzavreniSmlouvy = '';
        this.cuzkWebserviceName = '';
        this.cuzkWebservicePassword = '';
        this.saveWsdpCredentials = true;
        this.oneTimeRequest = true;
        this.periodicalRequest = false;
        this.periodRequestEndDate = '';
        this.otherLegalRelationships = true;
        this.conditions = false;
        this.hasValidWsdpAccount = true;
        this.onValidDateChanged = this.onValidDateChanged.bind(this);
        this.onCustomerSelected = this.onCustomerSelected.bind(this);
        this.setReloadContractOptions = this.setReloadContractOptions.bind(this);
        this.cancel = this.cancel.bind(this);
        this.continue = this.continue.bind(this);
    }
    CreateProjectSecondStepComponent.prototype.ngOnInit = function () {
        if (!this.stateService.params.formData && !this.stateService.params.requestId) {
            this.stateService.go('pk.projects.create-project-first');
        }
        this.loading = true;
        this.formData = this.stateService.params.formData;
        this.type = this.stateService.params.type;
        this.requestId = this.stateService.params.requestId;
        this.user = this.authService.getUser();
        this.canSelectCustomer = (!this.requestId && this.user.company.companyName === 'GMtech, s.r.o.');
        if (this.requestId) {
            this.initRepeatRequest();
        }
        else {
            this.initNewRequest();
        }
    };
    CreateProjectSecondStepComponent.prototype.onValidDateChanged = function (date) {
        this.validityDate = date;
    };
    CreateProjectSecondStepComponent.prototype.isFormValid = function () {
        // Validation of repeating request
        if (this.requestId) {
            return this.validityDate;
        }
        // WSDP account validation - if user has no credential and didn't fill it...
        if (!this.hasValidWsdpAccount && !(this.cuzkWebserviceName && this.cuzkWebservicePassword)) {
            return false;
        }
        // Form validation
        return (this.projectName
            && (!this.canSelectCustomer || this.customer)
            && this.prijemceIdentifikatorSmlouvy
            && this.prijemcePredmetSmlouvy
            && this.prijemceDatumUzavreniSmlouvy
            && this.validityDate
            && (!this.periodicalRequest || (this.periodicalRequest && this.periodRequestEndDate))
            && this.conditions);
    };
    CreateProjectSecondStepComponent.prototype.continue = function () {
        var _this = this;
        // only when repeated request has unchanged geometry (from first step)
        if (this.requestId && !this.type) {
            return this.uploadRepeatRequest();
        }
        if (this.cuzkWebserviceName && this.cuzkWebservicePassword) {
            return this.validateWsdpAccount(this.cuzkWebserviceName, this.cuzkWebservicePassword)
                .then(function (ok) {
                if (ok) {
                    _this.uploadData();
                }
            });
        }
        return this.uploadData();
    };
    CreateProjectSecondStepComponent.prototype.cancel = function () {
        this.stateService.go('pk.projects');
    };
    CreateProjectSecondStepComponent.prototype.onCustomerSelected = function () {
        this.checkValidityOfWsdpAccount();
        this.contract = null;
        this.onContractSelected();
        if (this.reloadContractOptions) {
            this.reloadContractOptions();
        }
    };
    CreateProjectSecondStepComponent.prototype.onContractSelected = function () {
        this.prijemceIdentifikatorSmlouvy = this.contract && this.contract.contractNumber;
        this.prijemcePredmetSmlouvy = this.contract && this.contract.subject;
        this.prijemceDatumUzavreniSmlouvy = this.contract && this.contract.signDate;
    };
    CreateProjectSecondStepComponent.prototype.setReloadContractOptions = function (reloadFn) {
        this.reloadContractOptions = reloadFn;
    };
    CreateProjectSecondStepComponent.prototype.initNewRequest = function () {
        return __awaiter(this, void 0, void 0, function () {
            var lastReportDate;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getFirstPossibleDateByCuzkReport()];
                    case 1:
                        lastReportDate = _a.sent();
                        this.monthPickerOptions = {
                            minDate: (lastReportDate || moment().startOf('year').toDate()),
                            maxDate: moment().add(1, 'month').toDate(),
                            defaultDate: moment().add(1, 'month').toDate()
                        };
                        this.checkValidityOfWsdpAccount();
                        return [2 /*return*/];
                }
            });
        });
    };
    CreateProjectSecondStepComponent.prototype.initRepeatRequest = function () {
        return __awaiter(this, void 0, void 0, function () {
            var lastReportDate;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getFirstPossibleDateByCuzkReport()];
                    case 1:
                        lastReportDate = _a.sent();
                        this.restangular.one("projects/" + this.requestId).get()
                            .then(function (data) {
                            _this.projectName = data.name;
                            _this.prijemceIdentifikatorSmlouvy = data.identifikatorSmlouvy;
                            _this.prijemcePredmetSmlouvy = data.predmetSmlouvy;
                            _this.prijemceDatumUzavreniSmlouvy = data.datumUzavreniSmlouvy;
                            _this.otherLegalRelationships = data.jpv;
                            var minDateByVersion = moment(data.version).add(1, 'month').toDate();
                            _this.monthPickerOptions = {
                                minDate: (minDateByVersion > lastReportDate ? minDateByVersion : lastReportDate),
                                maxDate: moment().add(1, 'month').toDate(),
                                defaultDate: moment().add(1, 'month').toDate()
                            };
                            _this.customer = data.prijemce.company || data.company;
                            _this.loading = false;
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    CreateProjectSecondStepComponent.prototype.fillFormData = function () {
        var company = this.customer ? this.customer : this.user.company;
        // Form values
        this.formData.append('NAME', this.projectName);
        this.formData.append('PRIJEMCE_IDENTIFIKATOR_SMLOUVY', this.prijemceIdentifikatorSmlouvy);
        this.formData.append('PRIJEMCE_PREDMET_SMLOUVY', this.prijemcePredmetSmlouvy);
        this.formData.append('PRIJEMCE_DATUM_UZAVRENI_SMLOUVY', this.changeDateFormat(this.prijemceDatumUzavreniSmlouvy));
        this.formData.append('OPAKOVANA_MESICNI_ZADOST', string_utils_1.StringUtils.valueToString(this.periodicalRequest));
        this.formData.append('OPAKOVANA_MESICNI_ZADOST_DO', this.changeDateFormat(this.periodRequestEndDate));
        this.formData.append('DATUM_PLATNOSTI', this.changeDateFormat(this.validityDate));
        this.formData.append('JPV', string_utils_1.StringUtils.valueToString(this.otherLegalRelationships));
        // Company values
        this.formData.append('PRIJEMCE_COMPANY_ID_TEXT', company.idText);
        this.formData.append('PRIJEMCE_JMENO', this.user.name);
        this.formData.append('PRIJEMCE_PRIJMENI', this.user.surname);
        this.formData.append('PRIJEMCE_COMPANY_ID', string_utils_1.StringUtils.valueToString(company.id));
        this.formData.append('PRIJEMCE_NAZEV', string_utils_1.StringUtils.valueToString(company.companyName));
        this.formData.append('PRIJEMCE_ICO', string_utils_1.StringUtils.valueToString(company.identificationNumber));
        this.formData.append('PRIJEMCE_ULICE', string_utils_1.StringUtils.valueToString(company.street));
        this.formData.append('PRIJEMCE_CISLO_DOMOVNI', string_utils_1.StringUtils.valueToString(company.houseNumber));
        this.formData.append('PRIJEMCE_OBEC', string_utils_1.StringUtils.valueToString(company.municipality));
        this.formData.append('PRIJEMCE_PSC', string_utils_1.StringUtils.valueToString(company.postCode));
        // Default values
        this.formData.append('CENY', 'false');
        this.formData.append('UPLATNY_REZIM', 'true');
        this.formData.append('DESCRIPTION', '');
        this.formData.append('ANALOG_PARCELS', '');
        this.formData.append('PRIJEMCE_DATUM_NAROZENI', '');
        // If new WSDP account
        if (this.cuzkWebserviceName && this.cuzkWebservicePassword) {
            this.formData.append('CUZK_WEBSERVICE_NAME', this.cuzkWebserviceName);
            this.formData.append('CUZK_WEBSERVICE_PASSWORD', this.cuzkWebservicePassword);
            this.formData.append('CUZK_SAVE_CREDENTIALS', string_utils_1.StringUtils.valueToString(this.saveWsdpCredentials));
        }
    };
    CreateProjectSecondStepComponent.prototype.validateWsdpAccount = function (name, password) {
        var _this = this;
        this.cuzkAccountValidationMessage = '';
        return this.restangular.one('cuzk/verify-credentials').customPOST({
            userName: name,
            password: password
        }).then(function (data) {
            var valid = data.valid;
            _this.cuzkAccountValidationMessage = (valid ? '' : 'Zadali jste neplatné přihlašovací údaje k WSDP účtu.');
            return valid;
        });
    };
    CreateProjectSecondStepComponent.prototype.checkValidityOfWsdpAccount = function () {
        var _this = this;
        this.cuzkAccountExpirationMessage = '';
        var params = (this.customer && this.customer.idText)
            ? { companyIdText: this.customer.idText }
            : undefined;
        this.restangular.one('cuzk/verify-saved-credentials').customGET('', params).then(function (data) {
            var valid = data.valid, closeToExpire = data.closeToExpire, msg = data.msg;
            _this.hasValidWsdpAccount = valid;
            _this.cuzkAccountExpirationMessage = (valid && closeToExpire && msg) ? msg : '';
            _this.loading = false;
        });
    };
    CreateProjectSecondStepComponent.prototype.uploadData = function () {
        var _this = this;
        this.fillFormData();
        return this.restangular.all('request').customPOST(this.formData, '', { type: this.type }, { 'Content-Type': undefined }).then(function (data) {
            _this.stateService.go('pk.projects.create-project-third', { requestId: data.requestId });
        });
    };
    CreateProjectSecondStepComponent.prototype.uploadRepeatRequest = function () {
        var _this = this;
        return this.restangular.all("request/" + this.requestId + "/repeat").customPOST({}, null, { validityDate: this.changeDateFormat(this.validityDate) }).then(function (data) {
            _this.stateService.go('pk.projects.create-project-third', { requestId: data.requestId });
        });
    };
    CreateProjectSecondStepComponent.prototype.changeDateFormat = function (date) {
        var dateString = string_utils_1.StringUtils.valueToString(this.datePipe.transform(date));
        return string_utils_1.StringUtils.removeSpaces(dateString);
    };
    CreateProjectSecondStepComponent.prototype.getFirstPossibleDateByCuzkReport = function () {
        return __awaiter(this, void 0, void 0, function () {
            var e_1, _a, cuzkReportDates, _b, _c, report;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0: return [4 /*yield*/, this.restangular.one("cuzk/xml-report/report?lastMonths=10").get()];
                    case 1:
                        cuzkReportDates = _d.sent();
                        try {
                            for (_b = __values(cuzkReportDates.plain()), _c = _b.next(); !_c.done; _c = _b.next()) {
                                report = _c.value;
                                if (report.knHlaseniGenerated) {
                                    // month, when last cuzk report was sent (months from BE starts from 1!)
                                    return [2 /*return*/, moment().month(report.month).year(report.year).toDate()];
                                }
                            }
                        }
                        catch (e_1_1) { e_1 = { error: e_1_1 }; }
                        finally {
                            try {
                                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                            }
                            finally { if (e_1) throw e_1.error; }
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    return CreateProjectSecondStepComponent;
}());
exports.CreateProjectSecondStepComponent = CreateProjectSecondStepComponent;
