"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var pageable_list_service_1 = require("@app/common/services/pageable.list.service");
var auth_service_1 = require("@app/common/services/auth.service");
var xlsx_service_1 = require("@app/common/services/xlsx.service");
var common_1 = require("@angular/common");
var role_pipe_1 = require("@app/common/pipes/role.pipe");
var UserListComponent = /** @class */ (function () {
    function UserListComponent(helpIds, globalRestangular, APPLICATIONS, APP_BRAND, rolePipe, authService, pageableListService, xlsxService, datePipe) {
        this.helpIds = helpIds;
        this.globalRestangular = globalRestangular;
        this.APPLICATIONS = APPLICATIONS;
        this.APP_BRAND = APP_BRAND;
        this.rolePipe = rolePipe;
        this.authService = authService;
        this.pageableListService = pageableListService;
        this.xlsxService = xlsxService;
        this.datePipe = datePipe;
        this.module = this.authService.getActiveApplication();
        this.export = this.export.bind(this);
        this.exportExtended = this.exportExtended.bind(this);
    }
    UserListComponent.prototype.ngOnInit = function () {
        this.initializeList();
    };
    UserListComponent.prototype.uiOnParamsChanged = function (changedParams, $transition$) {
        this.pageableList.uiOnParamsChanged(changedParams, $transition$);
    };
    UserListComponent.prototype.export = function () {
        var _this = this;
        return this.globalRestangular.one('export/active-users').get().then(function (rest) {
            var e_1, _a, e_2, _b;
            var usersData = rest.plain();
            var header = _this.APP_BRAND.NAME === 'RSD' ? UserListComponent.RSD_COLUMN_NAMES : UserListComponent.COLUMN_NAMES;
            var columnIds = _this.APP_BRAND.NAME === 'RSD' ? UserListComponent.RSD_COLUMN_FIELDS : UserListComponent.COLUMN_FIELDS;
            var cellOpinions = _this.APP_BRAND.NAME === 'RSD' ? UserListComponent.RSD_COLUMN_OPTIONS : UserListComponent.COLUMN_OPTIONS;
            var body = [];
            try {
                for (var usersData_1 = __values(usersData), usersData_1_1 = usersData_1.next(); !usersData_1_1.done; usersData_1_1 = usersData_1.next()) {
                    var user = usersData_1_1.value;
                    var row = [];
                    try {
                        for (var columnIds_1 = __values(columnIds), columnIds_1_1 = columnIds_1.next(); !columnIds_1_1.done; columnIds_1_1 = columnIds_1.next()) {
                            var id = columnIds_1_1.value;
                            if (id === 'lastLogin') {
                                row.push(_this.datePipe.transform(user[id], 'mediumDate'));
                            }
                            else {
                                row.push(user[id]);
                            }
                        }
                    }
                    catch (e_2_1) { e_2 = { error: e_2_1 }; }
                    finally {
                        try {
                            if (columnIds_1_1 && !columnIds_1_1.done && (_b = columnIds_1.return)) _b.call(columnIds_1);
                        }
                        finally { if (e_2) throw e_2.error; }
                    }
                    body.push(row);
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (usersData_1_1 && !usersData_1_1.done && (_a = usersData_1.return)) _a.call(usersData_1);
                }
                finally { if (e_1) throw e_1.error; }
            }
            var data = [header].concat(body);
            _this.xlsxService.createFile(data, 'export.xlsx', undefined, cellOpinions);
        });
    };
    UserListComponent.prototype.exportExtended = function () {
        var _this = this;
        return this.globalRestangular.one('export/active-users-roles').get().then(function (rest) {
            var e_3, _a, e_4, _b, e_5, _c, e_6, _d;
            var usersData = rest.plain();
            // flat data
            var flattenData = [];
            try {
                for (var usersData_2 = __values(usersData), usersData_2_1 = usersData_2.next(); !usersData_2_1.done; usersData_2_1 = usersData_2.next()) {
                    var user = usersData_2_1.value;
                    var roles = _this.APP_BRAND.NAME === 'RSD' ? user.ssoRoles : user.roles;
                    delete user.roles;
                    delete user.ssoRoles;
                    if (roles && roles.length > 0) {
                        try {
                            for (var roles_1 = __values(roles), roles_1_1 = roles_1.next(); !roles_1_1.done; roles_1_1 = roles_1.next()) {
                                var role = roles_1_1.value;
                                var userRow = __assign({}, user);
                                userRow.roleName = _this.rolePipe.transform(role.role);
                                userRow.roleProjectIsprofond = role.isprofond;
                                userRow.roleProjectName = role.projectName;
                                flattenData.push(userRow);
                            }
                        }
                        catch (e_4_1) { e_4 = { error: e_4_1 }; }
                        finally {
                            try {
                                if (roles_1_1 && !roles_1_1.done && (_b = roles_1.return)) _b.call(roles_1);
                            }
                            finally { if (e_4) throw e_4.error; }
                        }
                    }
                    else {
                        flattenData.push(user);
                    }
                }
            }
            catch (e_3_1) { e_3 = { error: e_3_1 }; }
            finally {
                try {
                    if (usersData_2_1 && !usersData_2_1.done && (_a = usersData_2.return)) _a.call(usersData_2);
                }
                finally { if (e_3) throw e_3.error; }
            }
            var header = _this.APP_BRAND.NAME === 'RSD' ? UserListComponent.RSD_COLUMN_EXTENDED_NAMES : UserListComponent.COLUMN_EXTENDED_NAMES;
            var columnIds = _this.APP_BRAND.NAME === 'RSD' ? UserListComponent.RSD_COLUMN_EXTENDED_FIELDS : UserListComponent.COLUMN_EXTENDED_FIELDS;
            var cellOpinions = _this.APP_BRAND.NAME === 'RSD' ? UserListComponent.RSD_COLUMN_EXTENDED_OPTIONS : UserListComponent.COLUMN_EXTENDED_OPTIONS;
            var body = [];
            try {
                for (var flattenData_1 = __values(flattenData), flattenData_1_1 = flattenData_1.next(); !flattenData_1_1.done; flattenData_1_1 = flattenData_1.next()) {
                    var user = flattenData_1_1.value;
                    var row = [];
                    try {
                        for (var columnIds_2 = __values(columnIds), columnIds_2_1 = columnIds_2.next(); !columnIds_2_1.done; columnIds_2_1 = columnIds_2.next()) {
                            var id = columnIds_2_1.value;
                            if (id === 'lastLogin') {
                                row.push(_this.datePipe.transform(user[id], 'mediumDate'));
                            }
                            else {
                                row.push(user[id]);
                            }
                        }
                    }
                    catch (e_6_1) { e_6 = { error: e_6_1 }; }
                    finally {
                        try {
                            if (columnIds_2_1 && !columnIds_2_1.done && (_d = columnIds_2.return)) _d.call(columnIds_2);
                        }
                        finally { if (e_6) throw e_6.error; }
                    }
                    body.push(row);
                }
            }
            catch (e_5_1) { e_5 = { error: e_5_1 }; }
            finally {
                try {
                    if (flattenData_1_1 && !flattenData_1_1.done && (_c = flattenData_1.return)) _c.call(flattenData_1);
                }
                finally { if (e_5) throw e_5.error; }
            }
            var data = [header].concat(body);
            _this.xlsxService.createFile(data, 'export-extended.xlsx', undefined, cellOpinions);
        });
    };
    UserListComponent.prototype.initializeList = function () {
        this.pageableList = this.pageableListService.get('users/list', this.getDefaultFilter(), 'globalUsersFilter', this.globalRestangular);
        this.list = this.pageableList.list;
        this.pageableList.load();
    };
    UserListComponent.prototype.getDefaultFilter = function () {
        return {
            filters: {
                searchText: {
                    values: [{ id: '' }]
                },
                lastLoginNotEmpty: {
                    values: [true]
                },
                applications: {
                    values: [
                        this.APPLICATIONS.sy.name,
                        this.APPLICATIONS.sv.name,
                        this.APPLICATIONS.dashboard.name,
                    ]
                },
            },
        };
    };
    UserListComponent.COLUMN_NAMES = [
        'Příjmení uživatele',
        'Jméno uživatele',
        'Organizace',
        'Přihlašovací jméno',
        'E-mail',
        'Poslední přihlášení',
    ];
    UserListComponent.COLUMN_FIELDS = [
        'surname',
        'name',
        'companyName',
        'username',
        'email',
        'lastLogin'
    ];
    UserListComponent.COLUMN_OPTIONS = [
        xlsx_service_1.DEFAULT_CELL_OPTIONS,
        xlsx_service_1.DEFAULT_CELL_OPTIONS,
        xlsx_service_1.DEFAULT_CELL_OPTIONS,
        xlsx_service_1.DEFAULT_CELL_OPTIONS,
        xlsx_service_1.DEFAULT_CELL_OPTIONS,
        xlsx_service_1.DEFAULT_CELL_OPTIONS_H_CENTER,
    ];
    UserListComponent.COLUMN_EXTENDED_NAMES = [
        'Příjmení uživatele',
        'Jméno uživatele',
        'Organizace',
        'Přihlašovací jméno',
        'E-mail',
        'Poslední přihlášení',
        'Přiřazené role',
        'Přiřazená akce k roli - číslo',
        'Přiřazená akce k roli - název',
    ];
    UserListComponent.COLUMN_EXTENDED_FIELDS = [
        'surname',
        'name',
        'companyName',
        'username',
        'email',
        'lastLogin',
        'roleName',
        'roleProjectIsprofond',
        'roleProjectName',
    ];
    UserListComponent.COLUMN_EXTENDED_OPTIONS = [
        xlsx_service_1.DEFAULT_CELL_OPTIONS,
        xlsx_service_1.DEFAULT_CELL_OPTIONS,
        xlsx_service_1.DEFAULT_CELL_OPTIONS,
        xlsx_service_1.DEFAULT_CELL_OPTIONS,
        xlsx_service_1.DEFAULT_CELL_OPTIONS,
        xlsx_service_1.DEFAULT_CELL_OPTIONS_H_CENTER,
        xlsx_service_1.DEFAULT_CELL_OPTIONS,
        xlsx_service_1.DEFAULT_CELL_OPTIONS_H_CENTER,
        xlsx_service_1.DEFAULT_CELL_OPTIONS,
    ];
    UserListComponent.RSD_COLUMN_NAMES = [
        'Příjmení uživatele',
        'Jméno uživatele',
        'Číslo uživatele',
        'Organizace',
        'Název organizační jednotky',
        'Číslo organizační jednotky',
        'Přihlašovací jméno',
        'E-mail',
        'Poslední přihlášení',
    ];
    UserListComponent.RSD_COLUMN_FIELDS = [
        'surname',
        'name',
        'employeePersonalNumber',
        'ssoCompanyName',
        'departmentName',
        'departmentId',
        'username',
        'email',
        'lastLogin'
    ];
    UserListComponent.RSD_COLUMN_OPTIONS = [
        xlsx_service_1.DEFAULT_CELL_OPTIONS,
        xlsx_service_1.DEFAULT_CELL_OPTIONS,
        xlsx_service_1.DEFAULT_CELL_OPTIONS_H_CENTER,
        xlsx_service_1.DEFAULT_CELL_OPTIONS,
        xlsx_service_1.DEFAULT_CELL_OPTIONS,
        xlsx_service_1.DEFAULT_CELL_OPTIONS_H_CENTER,
        xlsx_service_1.DEFAULT_CELL_OPTIONS,
        xlsx_service_1.DEFAULT_CELL_OPTIONS,
        xlsx_service_1.DEFAULT_CELL_OPTIONS_H_CENTER,
    ];
    UserListComponent.RSD_COLUMN_EXTENDED_NAMES = [
        'Příjmení uživatele',
        'Jméno uživatele',
        'Číslo uživatele',
        'Organizace',
        'Název organizační jednotky',
        'Číslo organizační jednotky',
        'Přihlašovací jméno',
        'E-mail',
        'Poslední přihlášení',
        'Přiřazené role',
        'Přiřazená akce k roli - číslo',
        'Přiřazená akce k roli - název',
    ];
    UserListComponent.RSD_COLUMN_EXTENDED_FIELDS = [
        'surname',
        'name',
        'employeePersonalNumber',
        'ssoCompanyName',
        'departmentName',
        'departmentId',
        'username',
        'email',
        'lastLogin',
        'roleName',
        'roleProjectIsprofond',
        'roleProjectName',
    ];
    UserListComponent.RSD_COLUMN_EXTENDED_OPTIONS = [
        xlsx_service_1.DEFAULT_CELL_OPTIONS,
        xlsx_service_1.DEFAULT_CELL_OPTIONS,
        xlsx_service_1.DEFAULT_CELL_OPTIONS_H_CENTER,
        xlsx_service_1.DEFAULT_CELL_OPTIONS,
        xlsx_service_1.DEFAULT_CELL_OPTIONS,
        xlsx_service_1.DEFAULT_CELL_OPTIONS_H_CENTER,
        xlsx_service_1.DEFAULT_CELL_OPTIONS,
        xlsx_service_1.DEFAULT_CELL_OPTIONS,
        xlsx_service_1.DEFAULT_CELL_OPTIONS_H_CENTER,
        xlsx_service_1.DEFAULT_CELL_OPTIONS,
        xlsx_service_1.DEFAULT_CELL_OPTIONS_H_CENTER,
        xlsx_service_1.DEFAULT_CELL_OPTIONS,
    ];
    return UserListComponent;
}());
exports.UserListComponent = UserListComponent;
