"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./tooltip-parcels-base.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@uirouter/angular/lib/directives/uiSref");
var i3 = require("@uirouter/core");
var i4 = require("../../pipes/parcel-number.pipe");
var i5 = require("../../directives/loading.directive");
var i6 = require("@angular/common");
var i7 = require("./tooltip-parcels-base.component");
var i8 = require("../../services/list.service");
var styles_TooltipParcelsBaseComponent = [i0.styles];
var RenderType_TooltipParcelsBaseComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TooltipParcelsBaseComponent, data: {} });
exports.RenderType_TooltipParcelsBaseComponent = RenderType_TooltipParcelsBaseComponent;
function View_TooltipParcelsBaseComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "a", [["uiSref", "symap.project.parcels.detail"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).go($event.button, $event.ctrlKey, $event.metaKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 16384, null, 0, i2.AnchorUISref, [i1.ElementRef, i1.Renderer2], null, null), i1.ɵdid(3, 737280, null, 0, i2.UISref, [i3.UIRouter, [2, i2.AnchorUISref], "UIView.PARENT_INJECT"], { state: [0, "state"], params: [1, "params"] }, null), i1.ɵpod(4, { id: 0 }), (_l()(), i1.ɵted(5, null, ["", ""])), i1.ɵppd(6, 1)], function (_ck, _v) { var currVal_0 = "symap.project.parcels.detail"; var currVal_1 = _ck(_v, 4, 0, _v.context.$implicit.id); _ck(_v, 3, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = i1.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i1.ɵnov(_v.parent, 0), _v.context.$implicit)); _ck(_v, 5, 0, currVal_2); }); }
function View_TooltipParcelsBaseComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u017D\u00E1dn\u00E9 parcely"]))], null, null); }
function View_TooltipParcelsBaseComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i4.ParcelNumberPipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [], [[8, "className", 0]], null, null, null, null)), i1.ɵdid(2, 16384, null, 0, i5.LoadingDirective, [i1.ElementRef], { loading: [0, "loading"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TooltipParcelsBaseComponent_1)), i1.ɵdid(5, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TooltipParcelsBaseComponent_2)), i1.ɵdid(7, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.loading; _ck(_v, 2, 0, currVal_1); var currVal_2 = ((_co.data == null) ? null : ((_co.data.list == null) ? null : _co.data.list.list)); _ck(_v, 5, 0, currVal_2); var currVal_3 = (((_co.data == null) ? null : ((_co.data.list == null) ? null : _co.data.list.list)) && (_co.data.list.list.length === 0)); _ck(_v, 7, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).elementClass; _ck(_v, 1, 0, currVal_0); }); }
exports.View_TooltipParcelsBaseComponent_0 = View_TooltipParcelsBaseComponent_0;
function View_TooltipParcelsBaseComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "tooltip-parcels-base", [], null, null, null, View_TooltipParcelsBaseComponent_0, RenderType_TooltipParcelsBaseComponent)), i1.ɵdid(1, 114688, null, 0, i7.TooltipParcelsBaseComponent, [i1.ChangeDetectorRef, i8.ListService, "tooltipConfig"], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_TooltipParcelsBaseComponent_Host_0 = View_TooltipParcelsBaseComponent_Host_0;
var TooltipParcelsBaseComponentNgFactory = i1.ɵccf("tooltip-parcels-base", i7.TooltipParcelsBaseComponent, View_TooltipParcelsBaseComponent_Host_0, { data: "data" }, {}, []);
exports.TooltipParcelsBaseComponentNgFactory = TooltipParcelsBaseComponentNgFactory;
