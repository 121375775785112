"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var case_service_1 = require("@app/ps/services/case.service");
var angular_1 = require("@uirouter/angular");
var case_status_service_1 = require("@app/ps/services/case-status.service");
var PersonalVisitComponent = /** @class */ (function () {
    function PersonalVisitComponent(caseService, stateService, restangular, caseStatusService) {
        this.caseService = caseService;
        this.stateService = stateService;
        this.restangular = restangular;
        this.caseStatusService = caseStatusService;
        this.loading = true;
        this.dataSend = {
            personalVisitDate: new Date(),
            recipientOpsubId: [],
        };
        this.dataSelected = {
            recipient: null
        };
        this.onSubmit = this.onSubmit.bind(this);
        this.isValid = this.isValid.bind(this);
    }
    PersonalVisitComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.restangular.one('cases', this.caseId).get({ loadCollections: ['caseSubjects', 'caseOwnerships'] })];
                    case 1:
                        _a.data = _c.sent();
                        _b = this;
                        return [4 /*yield*/, this.caseStatusService.loadStatusName('PersonalVisit')];
                    case 2:
                        _b.name = (_c.sent()).name;
                        this.dataCaseOwnerships = this.caseService.getDataCaseOwnerships(this.data.caseOwnerships, this.data.caseSubjects);
                        this.recipients = this.caseService.getRecipients(this.dataCaseOwnerships).filter(function (value) {
                            return !value.sentDate && !value.signedDate;
                        });
                        if (this.recipients.length === 1) {
                            this.dataSelected.recipient = this.recipients[0];
                        }
                        this.loading = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    PersonalVisitComponent.prototype.isValid = function () {
        return !!this.dataSend.personalVisitDate;
    };
    PersonalVisitComponent.prototype.onSubmit = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.dataSend.recipientOpsubId = this.dataSelected.recipient.opsubIds || [this.dataSelected.recipient.id];
                        return [4 /*yield*/, this.restangular
                                .one('cases', this.caseId)
                                .customPUT(this.dataSend, 'personal-visit')];
                    case 1:
                        _a.sent();
                        this.stateService.go('^');
                        return [2 /*return*/];
                }
            });
        });
    };
    return PersonalVisitComponent;
}());
exports.PersonalVisitComponent = PersonalVisitComponent;
