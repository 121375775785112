"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./progress-bar.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/common");
var i3 = require("./progress-bar.component");
var styles_ProgressBarComponent = [i0.styles];
var RenderType_ProgressBarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProgressBarComponent, data: {} });
exports.RenderType_ProgressBarComponent = RenderType_ProgressBarComponent;
function View_ProgressBarComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "percentage percentage-na"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["N/A"]))], null, null); }
function View_ProgressBarComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "percentage"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", " %"])), i1.ɵppd(2, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent, 0), _co.percentage, "1.2-2")); _ck(_v, 1, 0, currVal_0); }); }
function View_ProgressBarComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "percentage"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", " %"])), i1.ɵppd(2, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent, 0), _co.percentage, "1.0-0")); _ck(_v, 1, 0, currVal_0); }); }
function View_ProgressBarComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "progress-bar"]], [[4, "width", null]], null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "progress-bar"; var currVal_2 = ("bg-" + _co.background); _ck(_v, 1, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.percentage + "%"); _ck(_v, 0, 0, currVal_0); }); }
function View_ProgressBarComponent_0(_l) { return i1.ɵvid(2, [i1.ɵpid(0, i2.DecimalPipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "flexbox-container flex-vertical-centered"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProgressBarComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProgressBarComponent_2)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProgressBarComponent_3)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "progress"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProgressBarComponent_4)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isNA; _ck(_v, 3, 0, currVal_0); var currVal_1 = ((!_co.isNA && (_co.percentage > 0)) && (_co.percentage < 1)); _ck(_v, 5, 0, currVal_1); var currVal_2 = (!_co.isNA && ((_co.percentage >= 1) || (_co.percentage === 0))); _ck(_v, 7, 0, currVal_2); var currVal_3 = !_co.isNA; _ck(_v, 10, 0, currVal_3); }, null); }
exports.View_ProgressBarComponent_0 = View_ProgressBarComponent_0;
function View_ProgressBarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "gmt-progress-bar", [], null, null, null, View_ProgressBarComponent_0, RenderType_ProgressBarComponent)), i1.ɵdid(1, 114688, null, 0, i3.ProgressBarComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_ProgressBarComponent_Host_0 = View_ProgressBarComponent_Host_0;
var ProgressBarComponentNgFactory = i1.ɵccf("gmt-progress-bar", i3.ProgressBarComponent, View_ProgressBarComponent_Host_0, { total: "total", done: "done", background: "background" }, {}, []);
exports.ProgressBarComponentNgFactory = ProgressBarComponentNgFactory;
