
var common = angular.module('common');

/**
 * Service handling error http response
 */
common.service('ErrorHandlerService', ['AlertService',function (AlertService) {
    return function (doAfter) {
        return function(result) {
            new AlertService({
                message: result.status === 400 && result.data.code === 0
                  ? result.data.message
                  : (result.status === 403 ? 'Akce není povolena' : 'Vyskytla se neočekávaná chyba')
            }).then(doAfter);
        };
    };
}]);
