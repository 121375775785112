"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".keep-input-size[_nghost-%COMP%]   .cm-select[_ngcontent-%COMP%]   .select-value[_ngcontent-%COMP%]:not(.empty) {\n  max-width: 142px;\n  overflow: hidden; }\n\n[_nghost-%COMP%]   perfect-scrollbar[_ngcontent-%COMP%] {\n  max-height: 300px; }\n\n[_nghost-%COMP%]   .select-all[_ngcontent-%COMP%] {\n  box-sizing: border-box; }"];
exports.styles = styles;
