"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var auth_service_1 = require("../../services/auth.service");
var HelpComponent = /** @class */ (function () {
    function HelpComponent(authService, helpIds, helpService, modalService) {
        var _this = this;
        this.authService = authService;
        this.helpIds = helpIds;
        this.helpService = helpService;
        this.modalService = modalService;
        this.callbackRegister = new core_1.EventEmitter();
        this.isSet = false;
        this.help = null;
        this.isAdmin = function () { return _this.authService.getUser() && _this.authService.getUser().superuser; };
        this.onEditClick = function () {
            new _this.modalService({
                templateUrl: "js/common/components/cm-help-edit.html",
                controller: 'HelpEditController',
                scope: {
                    help: _this.help,
                }
            }).then(function () {
                _this.helpService.getHelpById(_this.helpId).then(function (help) {
                    _this.isSet = !!help;
                });
            });
        };
        this.onEditClick = this.onEditClick.bind(this);
        this.onHelpIdChanged = this.onHelpIdChanged.bind(this);
    }
    HelpComponent.prototype.ngOnInit = function () {
        this.callbackRegister.emit({
            onHelpIdChanged: this.onHelpIdChanged,
        });
        this.onHelpIdChanged(this.helpId);
    };
    HelpComponent.prototype.onHelpIdChanged = function (helpId) {
        var _this = this;
        if (!helpId) {
            this.help = null;
            this.isSet = false;
            return;
        }
        this.helpService.getHelpById(helpId).then(function (help) {
            _this.isSet = !!help;
            _this.help = help || {
                id: helpId,
                url: null,
                title: null,
                description: null,
                strId: Object.keys(_this.helpIds).find(function (key) { return _this.helpIds[key] === helpId; })
            };
        });
    };
    return HelpComponent;
}());
exports.HelpComponent = HelpComponent;
