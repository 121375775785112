"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var CadastreEntryProposalComponent = /** @class */ (function () {
    function CadastreEntryProposalComponent(restangular) {
        this.restangular = restangular;
        this.loading = true;
        this.loadingFinished = false;
    }
    CadastreEntryProposalComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.restangular.one('cases', this.caseId).get().then(function (data) {
            _this.data = data;
            _this.loading = false;
        });
    };
    CadastreEntryProposalComponent.prototype.onLoad = function () {
        this.loadingFinished = true;
    };
    CadastreEntryProposalComponent.prototype.formCallbackRegister = function ($event) {
        this.sendProposal = $event.submit;
        this.hasValidProposalCredentials = $event.isValid;
    };
    return CadastreEntryProposalComponent;
}());
exports.CadastreEntryProposalComponent = CadastreEntryProposalComponent;
