"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".anchor-circle[_ngcontent-%COMP%] {\n  background: #57bdf1;\n  color: #fff;\n  text-decoration: none !important;\n  display: inline-block;\n  width: 25px;\n  height: 25px;\n  padding: 5px 8px;\n  border-radius: 20px; }\n\n.table[_ngcontent-%COMP%]   .row.inactive[_ngcontent-%COMP%]   .cell[_ngcontent-%COMP%]:not(:last-of-type) {\n  color: #909090; }"];
exports.styles = styles;
