"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./pie-chart.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("./pie-chart.component");
var styles_PieChartComponent = [i0.styles];
var RenderType_PieChartComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PieChartComponent, data: {} });
exports.RenderType_PieChartComponent = RenderType_PieChartComponent;
function View_PieChartComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { element: 0 }), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["containerPieChart", 1]], null, 0, "div", [], null, null, null, null, null))], null, null); }
exports.View_PieChartComponent_0 = View_PieChartComponent_0;
function View_PieChartComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "gmt-pie-chart", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onResize() !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_PieChartComponent_0, RenderType_PieChartComponent)), i1.ɵdid(1, 4767744, null, 0, i2.PieChartComponent, [], null, null)], null, null); }
exports.View_PieChartComponent_Host_0 = View_PieChartComponent_Host_0;
var PieChartComponentNgFactory = i1.ɵccf("gmt-pie-chart", i2.PieChartComponent, View_PieChartComponent_Host_0, { data: "data", title: "title", subTitle: "subTitle", subSubTitle: "subSubTitle", tooltip: "tooltip" }, {}, []);
exports.PieChartComponentNgFactory = PieChartComponentNgFactory;
