"use strict";
var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
Object.defineProperty(exports, "__esModule", { value: true });
var auth_service_1 = require("@app/common/services/auth.service");
var _ = require("lodash");
var i0 = require("@angular/core");
var i1 = require("../../common/services/auth.service");
var ProjectStatusService = /** @class */ (function () {
    function ProjectStatusService(restangular, authService) {
        this.restangular = restangular;
        this.authService = authService;
        this.listeners = [];
        this.directDownloadIds = [];
        this.makeCall = this.makeCall.bind(this);
    }
    ProjectStatusService.prototype.init = function () {
        this.destroy();
        this.makeCall();
    };
    ProjectStatusService.prototype.destroy = function () {
        if (this.timer) {
            clearTimeout(this.timer);
        }
        this.lastChange = undefined;
    };
    ProjectStatusService.prototype.registerListener = function (listener) {
        this.listeners.push(listener);
    };
    ProjectStatusService.prototype.download = function (processIds) {
        var e_1, _a;
        var _loop_1 = function (processId) {
            var url = this_1.authService.getActiveApplicationRestUrl() + '/processes/' + processId + '/data?t=' + this_1.authService.getToken();
            var link = document.createElement('a');
            link.setAttribute('download', null);
            link.setAttribute('href', url);
            link.style.display = 'none';
            document.body.appendChild(link);
            link.click();
            window.setTimeout(function () { return link.remove(); }, 5000);
        };
        var this_1 = this;
        try {
            for (var processIds_1 = __values(processIds), processIds_1_1 = processIds_1.next(); !processIds_1_1.done; processIds_1_1 = processIds_1.next()) {
                var processId = processIds_1_1.value;
                _loop_1(processId);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (processIds_1_1 && !processIds_1_1.done && (_a = processIds_1.return)) _a.call(processIds_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
    };
    ProjectStatusService.prototype.remove = function (process) {
        var _this = this;
        _.pull(this.directDownloadIds, process.id);
        return this.restangular
            .one('processes', process.id)
            .remove()
            .then(function () { return _this.refresh(); });
    };
    ProjectStatusService.prototype.refresh = function (process) {
        if (process) {
            this.directDownloadIds.push(process.id);
        }
        this.makeCall(false);
    };
    ProjectStatusService.prototype.makeCall = function (continues) {
        var _this = this;
        if (continues === void 0) { continues = true; }
        return this.restangular
            .all('processes')
            .customGET('status', { lastChange: this.lastChange })
            .then(function (data) {
            data = data.plain();
            if (_this.lastChange === undefined || data.lastChange !== _this.lastChange) {
                _this.listeners.forEach(function (l) { return l(data); });
                if (_this.directDownloadIds.length) {
                    var downloadIds = data.processes
                        .filter(function (process) { return process.status === 'F' && process.hasData && _.remove(_this.directDownloadIds, function (item) { return item === process.id; }).length; })
                        .map(function (process) { return process.id; });
                    _this.download(downloadIds);
                }
            }
            _this.lastChange = data.lastChange;
            if (continues) {
                _this.timer = window.setTimeout(_this.makeCall, 5000);
            }
        });
    };
    ProjectStatusService.ngInjectableDef = i0.defineInjectable({ factory: function ProjectStatusService_Factory() { return new ProjectStatusService(i0.inject("Restangular"), i0.inject(i1.AuthService)); }, token: ProjectStatusService, providedIn: "root" });
    return ProjectStatusService;
}());
exports.ProjectStatusService = ProjectStatusService;
