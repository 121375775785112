"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var _ = require("lodash");
var auth_service_1 = require("@app/common/services/auth.service");
var case_service_1 = require("@app/ps/services/case.service");
var dialog_config_1 = require("@app/dialog/dialog-config");
var dialog_ref_1 = require("@app/dialog/dialog-ref");
var form_fields_1 = require("./form-fields");
var list_service_1 = require("@app/common/services/list.service");
var username_pipe_1 = require("@app/common/pipes/username.pipe");
var settings_service_1 = require("@app/ps/services/settings.service");
var FormCaseUpdateComponent = /** @class */ (function () {
    function FormCaseUpdateComponent(helpIds, restangular, restangularSettings, userNamePipe, authService, caseService, cdr, config, dialog, listService, settingsService) {
        this.helpIds = helpIds;
        this.restangular = restangular;
        this.restangularSettings = restangularSettings;
        this.userNamePipe = userNamePipe;
        this.authService = authService;
        this.caseService = caseService;
        this.cdr = cdr;
        this.config = config;
        this.dialog = dialog;
        this.listService = listService;
        this.settingsService = settingsService;
        this.availableFields = form_fields_1.default;
        this.displayFields = {};
        this.expropriationAppealSides = [
            {
                id: 'I',
                name: 'Investor'
            },
            {
                id: 'O',
                name: 'Vlastník'
            }
        ];
        this.mandataryFilter = {
            filters: {
                searchText: {
                    values: [],
                },
                applications: {
                    values: [this.authService.getActiveApplication()],
                },
                permission: {
                    values: ['assignable'],
                },
                projects: {
                    values: [this.authService.getActualProject().key],
                },
            },
            sortOrder: [
                { sortBy: 'surname' },
                { sortBy: 'name' },
            ],
        };
    }
    FormCaseUpdateComponent.prototype.ngOnInit = function () {
        this.onAddTenant = this.onAddTenant.bind(this);
        this.onExpropriationAppealSideSelect = this.onExpropriationAppealSideSelect.bind(this);
        this.onRemoveTenant = this.onRemoveTenant.bind(this);
        this.onUpdate = this.onUpdate.bind(this);
        this.caseObject = this.config.data.caseObject;
        this.caseObject.tenantNames = this.caseObject.tenantNames || [];
        this.distributionSystemPartsEnabled = this.caseService.showEasementDistributionSystemParts(this.caseObject.obligation);
        if (this.distributionSystemPartsEnabled) {
            this.caseObject.distributionSystemParts = this.caseObject.distributionSystemParts || [];
            this.initializeDistributionSystemPartsOptions();
        }
        if (this.canUpdateCaseData()) {
            this.initializeDates();
        }
    };
    FormCaseUpdateComponent.prototype.onExpropriationAppealSideSelect = function (appealSide) {
        this.caseObject.expropriationAppealSide = appealSide;
    };
    FormCaseUpdateComponent.prototype.onPublicationExceptionSelect = function (value) {
        this.caseObject.contractRegisterPublicationException = value;
    };
    FormCaseUpdateComponent.prototype.onAddTenant = function () {
        this.caseObject.tenantNames.push('');
    };
    FormCaseUpdateComponent.prototype.onRemoveTenant = function (index) {
        if (index > -1) {
            this.caseObject.tenantNames.splice(index, 1);
        }
    };
    FormCaseUpdateComponent.prototype.onUpdate = function () {
        var _this = this;
        return this.restangular
            .one('cases', this.caseObject.id)
            .customPUT(this.caseObject)
            .then(function (data) {
            _this.dialog.close(data);
        });
    };
    FormCaseUpdateComponent.prototype.trackByFn = function (index) {
        return index;
    };
    FormCaseUpdateComponent.prototype.initializeDates = function () {
        if (!this.caseObject.obligation.bonusPeriod && !this.caseObject.bonusDate) {
            _.pull(this.availableFields, 'bonusPossible');
        }
        if (!this.caseObject.bonusDate) {
            _.pull(this.availableFields, 'bonusGranted');
        }
        this.displayFields = this.getDisplayedFields();
        this.displayFieldsEnabled = !!Object.keys(this.displayFields).length;
    };
    FormCaseUpdateComponent.prototype.canUpdateCaseData = function () {
        return this.authService.canUpdateCaseData();
    };
    FormCaseUpdateComponent.prototype.getDisplayedFields = function () {
        var _this = this;
        var activeFields = this.availableFields.filter(function (item) { return _this.caseObject[item] || _this.caseObject[item] === false; });
        var result = _.fromPairs(activeFields.map(function (field) { return [field, true]; }));
        result.dueDate = true;
        return result;
    };
    FormCaseUpdateComponent.prototype.initializeDistributionSystemPartsOptions = function () {
        var _this = this;
        var list = this.listService.createList('distribution-system-parts', { limit: undefined });
        this.listService
            .fetchResult(list)
            .then(function () {
            _this.distributionSystemPartsOptions = list.list;
            _this.cdr.markForCheck();
        });
    };
    FormCaseUpdateComponent.prototype.hideColumn = function (columnId) {
        return this.settingsService.shouldHideColumn('cases', columnId);
    };
    return FormCaseUpdateComponent;
}());
exports.FormCaseUpdateComponent = FormCaseUpdateComponent;
