"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("./map-module-cuzk.component");
var i2 = require("../../services/map.service");
var i3 = require("../../services/map-utils-crs.service");
var styles_MapModuleCuzkComponent = [];
var RenderType_MapModuleCuzkComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MapModuleCuzkComponent, data: {} });
exports.RenderType_MapModuleCuzkComponent = RenderType_MapModuleCuzkComponent;
function View_MapModuleCuzkComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Pro vyhled\u00E1n\u00ED parcely katastru nemovitost\u00ED podle pozice klikn\u011Bte do mapov\u00E9ho okna"]))], null, null); }
exports.View_MapModuleCuzkComponent_0 = View_MapModuleCuzkComponent_0;
function View_MapModuleCuzkComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "map-module-cuzk", [], null, null, null, View_MapModuleCuzkComponent_0, RenderType_MapModuleCuzkComponent)), i0.ɵdid(1, 245760, null, 0, i1.MapModuleCuzkComponent, [i2.MapService, i3.MapUtilsCrsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_MapModuleCuzkComponent_Host_0 = View_MapModuleCuzkComponent_Host_0;
var MapModuleCuzkComponentNgFactory = i0.ɵccf("map-module-cuzk", i1.MapModuleCuzkComponent, View_MapModuleCuzkComponent_Host_0, { mapId: "mapId" }, { visibilityInfoboxCallback: "visibilityInfoboxCallback" }, []);
exports.MapModuleCuzkComponentNgFactory = MapModuleCuzkComponentNgFactory;
