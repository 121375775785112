"use strict";
var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var angular_1 = require("@uirouter/angular");
var file_saver_1 = require("file-saver");
var listLoadOrder_1 = require("../../pure/listLoadOrder");
var title_name_pipe_1 = require("@app/common/pipes/title-name.pipe");
var subject_name_pipe_1 = require("@app/common/pipes/subject-name.pipe");
var parcel_name_pipe_1 = require("@app/common/pipes/parcel-name.pipe");
var _ = require("lodash");
var list_service_1 = require("@app/common/services/list.service");
var checklist_service_1 = require("@app/common/services/checklist.service");
var auth_service_1 = require("@app/common/services/auth.service");
var PropertyStatusComponent = /** @class */ (function () {
    function PropertyStatusComponent(helpIds, restangular, localStorageService, titleNamePipe, subjectNamePipe, parcelNamePipe, listLoadService, stateService, checklistService, authService, differs) {
        this.helpIds = helpIds;
        this.restangular = restangular;
        this.localStorageService = localStorageService;
        this.titleNamePipe = titleNamePipe;
        this.subjectNamePipe = subjectNamePipe;
        this.parcelNamePipe = parcelNamePipe;
        this.listLoadService = listLoadService;
        this.stateService = stateService;
        this.checklistService = checklistService;
        this.authService = authService;
        this.differs = differs;
        this.requestID = this.stateService.params.requestid;
        this.listLoaded = false;
        this.currentFilter = {};
        this.selectionSummary = {
            loading: true
        };
        this.exportLoading = false;
        this.exportLoadingXls = false;
        this.filterArea = { filters: { requestArea: { values: [true] } }, sortOrder: listLoadOrder_1.getOrder('areas/list') };
        this.filterSubject = { sortOrder: listLoadOrder_1.getOrder('subjects/list') };
        this.filterTitle = { sortOrder: listLoadOrder_1.getOrder('titles/list') };
        this.filterParcel = { sortOrder: listLoadOrder_1.getOrder('parcels/list') };
        this.filterRoadSection = { sortOrder: listLoadOrder_1.getOrder('road-sections/list') };
        this.filterOpsubGroup = { sortOrder: listLoadOrder_1.getOrder('opsub-group/list') };
        this.filterDivisionType = { sortOrder: listLoadOrder_1.getOrder('division-types/list') };
        this.globalFilter = {
            areaId: { values: [] },
            titleId: { values: [] },
            managementAreaId: { values: [] },
            subjectId: { values: [] },
            parcelId: { values: [] },
            roadId: { values: [] },
            roadSectionId: { values: [] },
            divisionTypeId: { values: [] },
            ownerType: { values: [] },
            opsubType: { values: [] },
            opsubGroup: { values: [] },
        };
        this.ownerTypes = [
            { name: 'Cizí', id: 'F' },
            { name: 'Vlastní', id: 'O' },
            { name: 'Ve spoluvlastnictví', id: 'S' }
        ];
        this.groups = [
            { value: 'Kraj', id: undefined },
            { value: 'Katastrální území', id: 'area' },
            { value: 'List vlastnictví', id: 'title' },
            { value: 'Parcely', id: 'parcel' },
            { value: 'Silnice', id: 'road' },
            { value: 'Úsek silnice', id: 'road_section' },
        ];
        this.opsubTypes = [
            { name: 'Fyzická osoba', id: 'OFO' },
            { name: 'Právnická osoba', id: 'OPO' },
            { name: 'Společné jmění manželů', id: 'BSM' }
        ];
        this.listLoadServiceSetter = this.listLoadServiceSetter.bind(this);
        this.onFilterChanged = this.onFilterChanged.bind(this);
        this.needsToResetPage = this.needsToResetPage.bind(this);
        this.getActualPage = this.getActualPage.bind(this);
        this.uiOnParamsChanged = this.uiOnParamsChanged.bind(this);
        this.onListChanged = this.onListChanged.bind(this);
        this.onListChangedSetterPagination = this.onListChangedSetterPagination.bind(this);
        this.fetchResults = this.fetchResults.bind(this);
        this.onSort = this.onSort.bind(this);
        this.onUpdateFilterCriteria = this.onUpdateFilterCriteria.bind(this);
        this.updateGroup = this.updateGroup.bind(this);
        this.updateSections = this.updateSections.bind(this);
        this.resetSelection = this.resetSelection.bind(this);
        this.exportXls = this.exportXls.bind(this);
        this.export = this.export.bind(this);
        this.showMap = this.showMap.bind(this);
    }
    PropertyStatusComponent.prototype.ngOnInit = function () {
        this.initializeList();
    };
    PropertyStatusComponent.prototype.ngDoCheck = function () {
        var _this = this;
        var e_1, _a;
        if (!this.differ) {
            return;
        }
        var changes = this.differ.diff(this.list);
        if (changes) {
            this.currentFilter = {
                parcelId: [],
                titleId: [],
                managementAreaId: [],
                areaId: [],
                subjectId: [],
                roadId: [],
                roadSectionId: [],
                divisionTypeId: [],
                ownerType: [],
                opsubType: [],
                opsubGroup: []
            };
            var _loop_1 = function (key) {
                this_1.list.filter.filters[key].values.forEach(function (item) {
                    _this.currentFilter[key].push(item.id);
                });
            };
            var this_1 = this;
            try {
                for (var _b = __values(Object.entries(this.currentFilter)), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var _d = __read(_c.value, 1), key = _d[0];
                    _loop_1(key);
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_1) throw e_1.error; }
            }
        }
    };
    PropertyStatusComponent.prototype.listLoadServiceSetter = function (setter) {
        if (typeof setter === 'function') {
            setter(this.listLoadService);
        }
    };
    PropertyStatusComponent.prototype.onFilterChanged = function (changingPage) {
        this.listLoadService.fetchResultCount(this.list).then(this.onListChanged);
        // if changing page, fetchResult will be called from transition handler
        if (!changingPage) {
            return this.fetchResults();
        }
        return Promise.reject();
    };
    PropertyStatusComponent.prototype.needsToResetPage = function () {
        return this.getActualPage(this.stateService.params) !== 1;
    };
    PropertyStatusComponent.prototype.getActualPage = function (params) {
        return parseInt(params.page, 10) || 1;
    };
    PropertyStatusComponent.prototype.uiOnParamsChanged = function (changedParams, $transition$) {
        if ($transition$.$from().name === $transition$.$to().name) {
            var page = this.getActualPage(changedParams);
            this.fetchResults(page);
        }
    };
    PropertyStatusComponent.prototype.onListChanged = function () {
        if (typeof this.onListChangedPagination === 'function') {
            this.onListChangedPagination();
        }
    };
    PropertyStatusComponent.prototype.onListChangedSetterPagination = function (setter) {
        this.onListChangedPagination = setter;
    };
    PropertyStatusComponent.prototype.fetchResults = function (page) {
        var _this = this;
        if (page === void 0) { page = this.getActualPage(this.stateService.params); }
        var overfloatFallback = function () { return _this.stateService.go('.', { page: 1 }); };
        return this.listLoadService.fetchListPerPage(this.list, page, this.onListChanged, overfloatFallback);
    };
    PropertyStatusComponent.prototype.onSort = function (sortValue, sortDir) {
        this.listLoadService.sort(this.list, sortValue, sortDir);
    };
    PropertyStatusComponent.prototype.initializeList = function () {
        var _this = this;
        this.getListService().then(function (list) {
            _this.list = list;
            _this.differ = _this.differs.find(_this.list).create();
            _this.listLoaded = true;
            var tmpPARCELIDS;
            if (_this.requestID) {
                tmpPARCELIDS = Object.assign({}, { values: _this.list.filter.filters.parcelId.values.map(function (val) { return ({ id: val }); }) });
            }
            _this.listLoadService.attachFilterStorage(_this.list, 'propertyStatusFilter');
            _.assign(_this.globalFilter, _.mapValues(_this.globalFilter, function (value, filterKey) { return _this.list.filter.filters[filterKey] || value; }));
            // todo watch
            if (tmpPARCELIDS) {
                _this.list.filter.filters.parcelId = tmpPARCELIDS;
            }
            _this.groupBy = _this.list.filter.filters.groupAtributes.values[0];
            if (!_this.groupBy || !_.find(_this.groups, _this.groupBy)) {
                _this.groupBy = { id: undefined };
                _this.list.filter.filters.groupAtributes.values = [];
            }
            _this.updateSections();
            _this.checklistSelection = _this.checklistService.get(_this.localStorageService.get(_this.authService.getActualProject().key + '.parcelSelection'));
            _this.checklistSelection.identity = function (identityItem) {
                return function (listItem) {
                    return _.isEqual(identityItem.parcelid ? identityItem.parcelid : _this.currentFilter.parcelId, listItem.parcelid) &&
                        _.isEqual(identityItem.titleid ? identityItem.titleid : _this.currentFilter.titleId, listItem.titleid) &&
                        _.isEqual(identityItem.areaid ? identityItem.areaid : _this.currentFilter.areaId, listItem.areaid) &&
                        _.isEqual(identityItem.roadid ? identityItem.roadid : _this.currentFilter.roadId, listItem.roadid) &&
                        _.isEqual(identityItem.roadsectionid ? identityItem.roadsectionid : _this.currentFilter.roadSectionId, listItem.roadsectionid) &&
                        _.isEqual(identityItem.subjectId, listItem.subjectId) &&
                        _.isEqual(identityItem.divisionTypeId, listItem.divisionTypeId) &&
                        _.isEqual(identityItem.ownerType, listItem.ownerType) &&
                        _.isEqual(identityItem.opsubType, listItem.opsubType) &&
                        _.isEqual(identityItem.opsubGroup, listItem.opsubGroup);
                };
            };
            _this.checklistSelection.transformation = function (item) {
                return {
                    parcelid: item.parcelid ? [item.parcelid] : _this.currentFilter.parcelId,
                    titleid: item.titleid ? [item.titleid] : _this.currentFilter.titleId,
                    areaid: item.areaid ? [item.areaid] : _this.currentFilter.areaId,
                    roadid: item.roadid !== undefined ? [item.roadid] : _this.currentFilter.roadId,
                    roadsectionid: item.roadsectionid !== undefined ? [item.roadsectionid] : _this.currentFilter.roadSectionId,
                    managementAreaId: _this.currentFilter.managementAreaId,
                    subjectId: _this.currentFilter.subjectId,
                    divisionTypeId: _this.currentFilter.divisionTypeId,
                    ownerType: _this.currentFilter.ownerType,
                    opsubType: _this.currentFilter.opsubType,
                    opsubGroup: _this.currentFilter.opsubGroup
                };
            };
            _this.checklistSelection.afterCallback = function () {
                _this.localStorageService.set(_this.authService.getActualProject().key + '.parcelSelection', _this.checklistSelection.checkedItems);
                _this.reloadSelectionSummary();
            };
            _this.onFilterChanged(false);
            _this.reloadSelectionSummary();
        });
    };
    PropertyStatusComponent.prototype.onUpdateFilterCriteria = function () {
        var changingPage = this.needsToResetPage();
        if (changingPage) {
            // we must reset page
            this.stateService.go('.', {
                page: 1
            });
            this.list.filter.offset = 0;
        }
        return this.onFilterChanged(changingPage);
    };
    PropertyStatusComponent.prototype.updateGroup = function (groupBy) {
        this.list.filter.filters.groupAtributes.values = groupBy ? [groupBy] : [];
        this.onUpdateFilterCriteria().then(this.updateSections);
    };
    PropertyStatusComponent.prototype.updateSections = function () {
        this.displaySections = {};
        this.displaySections[this.groupBy.id] = true;
    };
    PropertyStatusComponent.prototype.resetSelection = function () {
        this.checklistSelection.checkedItems.splice(0, this.checklistSelection.checkedItems.length);
        this.checklistSelection.afterCallback();
    };
    PropertyStatusComponent.prototype.exportXls = function () {
        var _this = this;
        this.exportLoadingXls = true;
        this.restangular.all('/property-status/export-xls')
            .withHttpConfig({ responseType: 'blob' }) // important to set response as a blob
            .customPOST(this.checklistSelection.checkedItems)
            .then(function (data) {
            var blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            file_saver_1.saveAs(blob, 'export.xls', true);
            _this.exportLoadingXls = false;
        }, function () {
            _this.exportLoadingXls = false;
        });
    };
    PropertyStatusComponent.prototype.export = function () {
        var _this = this;
        this.exportLoading = true;
        this.restangular.all('/property-status/export-list')
            .customPOST(this.checklistSelection.checkedItems)
            .then(function (data) {
            var blob = new Blob([data], { type: 'text/csv;charset=utf-8' });
            file_saver_1.saveAs(blob, 'export.csv');
            _this.exportLoading = false;
        }, function () {
            _this.exportLoading = false;
        });
    };
    PropertyStatusComponent.prototype.showMap = function () {
        this.stateService.go('dimap.project.map', { displaySelection: true });
    };
    PropertyStatusComponent.prototype.ceil = function (val) {
        return Math.ceil(val);
    };
    PropertyStatusComponent.prototype.reloadSelectionSummary = function () {
        var _this = this;
        if (this.selectionSummary.abortFetch) {
            this.selectionSummary.abortFetch();
        }
        this.selectionSummary.abortFetchPromise = new Promise(function (resolve, reject) {
            _this.selectionSummary.abortFetch = resolve;
        });
        this.selectionSummary.loading = true;
        this.restangular.all('/property-status/selection-summary').withHttpConfig({ timeout: this.selectionSummary.abortFetchPromise })
            .customPOST(this.checklistSelection.checkedItems)
            .then(function (data) {
            _this.selectionSummary.loading = false;
            _this.selectionSummary.data = data;
            _this.selectionSummary.abortFetch = undefined;
            _this.selectionSummary.abortFetchPromise = undefined;
        });
    };
    PropertyStatusComponent.prototype.getListService = function () {
        var _this = this;
        var composeList = function (parcelIDs) {
            if (parcelIDs === void 0) { parcelIDs = []; }
            _.merge(_this.globalFilter.parcelId.values, parcelIDs);
            return _this.listLoadService.createList('property-status/list', {
                filters: _.assign({
                    groupAtributes: { values: [{ id: 'area' }] },
                }, _this.globalFilter)
            }, undefined, {});
        };
        if (this.requestID) {
            return this.restangular.one("parcel/intersection/" + this.requestID + "/ids").get().then(function (parcelIDs) {
                return composeList(parcelIDs.plain());
            });
        }
        else {
            return Promise.resolve(composeList());
        }
    };
    return PropertyStatusComponent;
}());
exports.PropertyStatusComponent = PropertyStatusComponent;
