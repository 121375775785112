"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("../serie/series.component.ngfactory");
var i2 = require("../serie/series.component");
var i3 = require("../../../common/pipes/construction-object-name.pipe");
var i4 = require("../../services/series.service");
var i5 = require("@angular/common");
var i6 = require("./series-list.component");
var styles_SeriesListComponent = [];
var RenderType_SeriesListComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SeriesListComponent, data: {} });
exports.RenderType_SeriesListComponent = RenderType_SeriesListComponent;
function View_SeriesListComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["style", "display: flex;flex-direction: column;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "gmt-series", [], null, [[null, "canceled"], [null, "restored"], [null, "moved"], [null, "changed"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("canceled" === en)) {
        var pd_0 = (_co.onCancelSeries($event.series) !== false);
        ad = (pd_0 && ad);
    } if (("restored" === en)) {
        var pd_1 = (_co.onRestoreSeries($event.series) !== false);
        ad = (pd_1 && ad);
    } if (("moved" === en)) {
        var pd_2 = (_co.onMoveSeries($event.series, $event.step) !== false);
        ad = (pd_2 && ad);
    } if (("changed" === en)) {
        var pd_3 = (_co.onChangeSeries($event.series) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i1.View_SeriesComponent_0, i1.RenderType_SeriesComponent)), i0.ɵdid(2, 114688, null, 0, i2.SeriesComponent, [i3.ConstructionObjectNamePipe, i4.SeriesService], { series: [0, "series"], hideConstructionObject: [1, "hideConstructionObject"] }, { canceled: "canceled", restored: "restored", moved: "moved", changed: "changed" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = !_co.withConstructionObjectSelection; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_SeriesListComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "table mb-10"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SeriesListComponent_2)), i0.ɵdid(2, 278528, null, 0, i5.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.seriesList; _ck(_v, 2, 0, currVal_0); }, null); }
function View_SeriesListComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "pl-40"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SeriesListComponent_1)), i0.ɵdid(3, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 2, "div", [["class", "column-100"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "a", [["class", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onAddSeries() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 0, "i", [["class", "fa fa-plus"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.seriesList == null) ? null : _co.seriesList.length); _ck(_v, 3, 0, currVal_0); }, null); }
exports.View_SeriesListComponent_0 = View_SeriesListComponent_0;
function View_SeriesListComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "series-list", [], null, null, null, View_SeriesListComponent_0, RenderType_SeriesListComponent)), i0.ɵdid(1, 114688, null, 0, i6.SeriesListComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_SeriesListComponent_Host_0 = View_SeriesListComponent_Host_0;
var SeriesListComponentNgFactory = i0.ɵccf("series-list", i6.SeriesListComponent, View_SeriesListComponent_Host_0, { seriesList: "seriesList", withConstructionObjectSelection: "withConstructionObjectSelection" }, {}, []);
exports.SeriesListComponentNgFactory = SeriesListComponentNgFactory;
