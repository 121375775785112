"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var angular_hybrid_1 = require("@uirouter/angular-hybrid");
var main_component_1 = require("@app/dimap/components/main/main.component");
var multiSceneCloudLibs = [
    {
        src: 'https://api.multiscenecloud.com/client/v1.14/MultiSceneCore.js',
        element: undefined,
    },
    {
        src: 'https://api.multiscenecloud.com/client/v1.14/PointCloudCore.js',
        element: undefined,
    },
];
function loadMultiSceneCloudLibs($window) {
    multiSceneCloudLibs.forEach(function (lib, idx) {
        lib.element = document.createElement('script');
        lib.element.src = lib.src;
        lib.element.type = 'text/javascript';
        $window.document.getElementsByTagName('head')[0].appendChild(lib.element);
        if (idx === multiSceneCloudLibs.length - 1) {
            return Promise.resolve();
        }
    });
}
function removeMultiSceneCloudLibs($window) {
    multiSceneCloudLibs.forEach(function (lib) {
        if ($window.document.getElementsByTagName('head')[0].contains(lib.element)) {
            $window.document.getElementsByTagName('head')[0].removeChild(lib.element);
        }
    });
}
function dimapStateOnEnter($window) {
    return loadMultiSceneCloudLibs($window);
}
exports.dimapStateOnEnter = dimapStateOnEnter;
function dimapStateOnExit($window) {
    return removeMultiSceneCloudLibs($window);
}
exports.dimapStateOnExit = dimapStateOnExit;
exports.dimapState = {
    name: 'dimap',
    abstract: true,
    url: '/dimap',
    onEnter: ['$window', dimapStateOnEnter],
    onExit: ['$window', dimapStateOnExit]
};
function dimapProjectStateOnEnter(AuthService, APPLICATIONS, LoginService, $transition$) {
    return AuthService.checkProjectStatus($transition$, APPLICATIONS.di.name, 'di_', AuthService.loadDimapProjects, 'error').then(function () {
        AuthService.switchProjectByKey($transition$.params().projectKey, APPLICATIONS.di.name);
    }, function (newState) {
        return newState;
    });
}
exports.dimapProjectStateOnEnter = dimapProjectStateOnEnter;
exports.dimapProjectState = {
    name: 'dimap.project',
    abstract: true,
    url: '/project/{projectKey:di_[^/]*}',
    params: {
        projectKey: {
            squash: true,
        }
    },
    component: main_component_1.MainComponent,
    onEnter: ['AuthService', 'APPLICATIONS', 'LoginService', '$transition$', dimapProjectStateOnEnter],
};
var DimapAppModule = /** @class */ (function () {
    function DimapAppModule() {
    }
    return DimapAppModule;
}());
exports.DimapAppModule = DimapAppModule;
