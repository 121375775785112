"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var export_list_component_1 = require("@app/ps/exports/components/export-list/export-list.component");
exports.exportListState = {
    name: 'symap.project.exports',
    url: '/exports',
    data: {
        title: 'Exporty',
    },
    views: {
        'content@symap.project': {
            component: export_list_component_1.ExportListComponent,
        },
    },
};
var ExportsModule = /** @class */ (function () {
    function ExportsModule() {
    }
    return ExportsModule;
}());
exports.ExportsModule = ExportsModule;
