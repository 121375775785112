"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var project_settings_states_1 = require("@app/ps/project-settings/project-settings.states");
var PROJECT_SETTINGS_STATES = [
    project_settings_states_1.projectSettingsMainState,
    project_settings_states_1.projectCreateState,
    project_settings_states_1.projectUpdateState,
    project_settings_states_1.administratorListState,
    project_settings_states_1.administratorDetailState,
    project_settings_states_1.expertListState,
    project_settings_states_1.expertDetailState,
    project_settings_states_1.templateListState,
    project_settings_states_1.templateHistoryState,
    project_settings_states_1.sendUserEmailState,
    project_settings_states_1.userListState,
    project_settings_states_1.userDetailState,
    project_settings_states_1.userCredentialsState,
    project_settings_states_1.updateUserPermissionsState,
    project_settings_states_1.templateInternalListState,
    project_settings_states_1.templateExternalListState,
    project_settings_states_1.bulkPricesState
];
var ProjectSettingsModule = /** @class */ (function () {
    function ProjectSettingsModule() {
    }
    return ProjectSettingsModule;
}());
exports.ProjectSettingsModule = ProjectSettingsModule;
