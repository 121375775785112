"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".gmt-input-350[_nghost-%COMP%]   .gmt-input__input[_ngcontent-%COMP%] {\n  width: 350px; }\n\n.gmt-input-50[_nghost-%COMP%]   .gmt-input__input[_ngcontent-%COMP%] {\n  width: 50px; }\n\n.gmt-input-60[_nghost-%COMP%]   .gmt-input__input[_ngcontent-%COMP%] {\n  width: 60px; }\n\n.gmt-input[_ngcontent-%COMP%] {\n  display: flex; }\n\n.gmt-input__input-wrapper[_ngcontent-%COMP%] {\n  padding: 2px 0px;\n  flex-grow: 1; }\n  .gmt-input__input-wrapper.readonly[_ngcontent-%COMP%] {\n    padding: 7px 0; }\n\n.gmt-input__label[_ngcontent-%COMP%] {\n  clear: both;\n  color: #909090;\n  width: 200px;\n  padding: 7px 10px 7px 0px; }\n\n.gmt-input__input[_ngcontent-%COMP%] {\n  -webkit-box-sizing: border-box;\n  -moz-box-sizing: border-box;\n  box-sizing: border-box;\n  color: #3d3d3d;\n  padding: 4px 9px;\n  border: 1px solid #e7e7e7; }\n  .gmt-input__input[_ngcontent-%COMP%]:focus {\n    background: #fbfbdb; }\n  .gmt-input__input[_ngcontent-%COMP%]::-webkit-input-placeholder, .gmt-input__input[_ngcontent-%COMP%]::-moz-placeholder, .gmt-input__input[_ngcontent-%COMP%]:-moz-placeholder, .gmt-input__input[_ngcontent-%COMP%]:-ms-input-placeholder {\n    color: #8d8d8d; }\n  .gmt-input__input[_ngcontent-%COMP%]:-moz-placeholder, .gmt-input__input[_ngcontent-%COMP%]::-moz-placeholder {\n    opacity: 1; }\n  .gmt-input__input[_ngcontent-%COMP%]:disabled {\n    color: #909090; }"];
exports.styles = styles;
