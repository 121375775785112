"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var VectorLayerStyleModel = /** @class */ (function () {
    function VectorLayerStyleModel(fill, stroke) {
        this.fill = fill;
        this.stroke = stroke;
    }
    VectorLayerStyleModel.prototype.fillToRgbaString = function () {
        return this.toRgbaString(this.fill);
    };
    VectorLayerStyleModel.prototype.fillToRgbString = function () {
        return this.toRgbString(this.fill);
    };
    VectorLayerStyleModel.prototype.strokeToRgbaString = function () {
        return this.toRgbaString(this.stroke);
    };
    VectorLayerStyleModel.prototype.strokeToRgbString = function () {
        return this.toRgbString(this.stroke);
    };
    VectorLayerStyleModel.prototype.toRgbString = function (obj) {
        var r = obj.r, g = obj.g, b = obj.b;
        return "rgb(" + r + ", " + g + ", " + b + ")";
    };
    VectorLayerStyleModel.prototype.toRgbaString = function (obj) {
        var r = obj.r, g = obj.g, b = obj.b, a = obj.a;
        return "rgba(" + r + ", " + g + ", " + b + ", " + a + ")";
    };
    VectorLayerStyleModel.fromRgbStrings = function (fill, stroke) {
        var _a = __read(getRgbFromString(fill), 3), fillRed = _a[0], fillGreen = _a[1], fillBlue = _a[2];
        var _b = __read(getRgbFromString(stroke), 3), strokeRed = _b[0], strokeGreen = _b[1], strokeBlue = _b[2];
        var fillColor = {
            r: fillRed,
            g: fillGreen,
            b: fillBlue,
        };
        var strokeColor = {
            r: strokeRed,
            g: strokeGreen,
            b: strokeBlue,
        };
        return new this(fillColor, strokeColor);
    };
    return VectorLayerStyleModel;
}());
exports.VectorLayerStyleModel = VectorLayerStyleModel;
function getRgbFromString(rgb) {
    return rgb.split(',').map(function (rgbColor) { return rgbColor.replace(/\D/g, ''); }).map(function (color) { return parseInt(color); });
}
