"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var parcel_model_1 = require("../../models/parcel.model");
var occupation_model_1 = require("@app/ps/models/occupation.model");
var tooltip_parcel_component_1 = require("../tooltip-parcel/tooltip-parcel.component");
var cell_abstract_component_1 = require("../cell-abstract/cell-abstract.component");
var tooltip_dimap_parcel_component_1 = require("@app/common/components/tooltip-dimap-parcel/tooltip-dimap-parcel.component");
var CellParcelComponent = /** @class */ (function (_super) {
    __extends(CellParcelComponent, _super);
    function CellParcelComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.showTitle = false;
        _this.showProtection = false;
        // props
        _this.TOOLTIP_MAPPING = {
            'parcel': tooltip_parcel_component_1.TooltipParcelComponent,
            'dimap-parcel': tooltip_dimap_parcel_component_1.TooltipDimapParcelComponent,
        };
        return _this;
    }
    return CellParcelComponent;
}(cell_abstract_component_1.CellAbstractComponent));
exports.CellParcelComponent = CellParcelComponent;
