"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var subject_name_pipe_1 = require("./subject-name.pipe");
var i0 = require("@angular/core");
var i1 = require("./subject-name.pipe");
var OwnershipNamePipe = /** @class */ (function () {
    function OwnershipNamePipe(subjectNamePipe) {
        this.subjectNamePipe = subjectNamePipe;
    }
    OwnershipNamePipe.prototype.transform = function (ownership, type) {
        if (type === void 0) { type = 'name-with-fraction'; }
        var fraction = (ownership.podilCitatel + '/' + ownership.podilJmenovatel) + (ownership.isBsm ? ' (SJM)' : '');
        switch (type) {
            case 'name-with-fraction':
                var names = ownership.subjects.map(this.subjectNamePipe.transform).join(", ");
                return names + ' (' + fraction + ')';
            case 'only-fraction':
                return fraction;
        }
    };
    OwnershipNamePipe.ngInjectableDef = i0.defineInjectable({ factory: function OwnershipNamePipe_Factory() { return new OwnershipNamePipe(i0.inject(i1.SubjectNamePipe)); }, token: OwnershipNamePipe, providedIn: "root" });
    return OwnershipNamePipe;
}());
exports.OwnershipNamePipe = OwnershipNamePipe;
