"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var post_code_pipe_1 = require("@app/common/pipes/post-code.pipe");
var i0 = require("@angular/core");
var i1 = require("./post-code.pipe");
var STATUTORY_CITIES = ['Brno', 'Ostrava', 'Plzeň', 'Ústí nad Labem', 'Pardubice', 'Liberec', 'Opava'];
var CAPITAL_CITY = 'Praha';
var AddressPipe = /** @class */ (function () {
    function AddressPipe(postCodePipe) {
        this.postCodePipe = postCodePipe;
    }
    AddressPipe.prototype.transform = function (subject) {
        var address = '';
        var city = subject.obec;
        var postCode = subject.psc;
        var street = subject.ulice;
        var houseNumber = getHouseNumber(subject);
        var cityPart = subject.castObce || subject.mestskaCast;
        var postCodeCity = getPostCodeWithCity(subject, this.postCodePipe);
        /*
         * Dle Vyhlášky o základním registru územní identifikace, adres a nemovitostí 359/2011 Sb.
         */
        if (city === CAPITAL_CITY) {
            // 1. v Praze
            if (street) {
                address += street;
            }
            if (houseNumber) {
                address = addPartToAddress(address, houseNumber, ' ');
            }
            if (cityPart) {
                address = addPartToAddress(address, cityPart, ', ');
            }
            if (postCode) {
                address = addPartToAddress(address, postCode, ', ');
            }
            if (city || cityPart) {
                address = addPartToAddress(address, city || cityPart, ' ');
            }
        }
        else if (STATUTORY_CITIES.includes(city) || (street && city && cityPart && city !== cityPart)) {
            // 2. v územně členěném statutárním městě
            // 3. v místě, kde se užívá uliční systém a název obce není shodný s názvem městské části
            if (street) {
                address += street;
            }
            if (houseNumber) {
                address = addPartToAddress(address, houseNumber, ' ');
            }
            if (cityPart) {
                address = addPartToAddress(address, cityPart, ', ');
            }
            if (postCodeCity) {
                address = addPartToAddress(address, postCodeCity, ', ');
            }
        }
        else {
            if ((street && city === cityPart) || (!street && (city && cityPart && city !== cityPart))) {
                // 4. v místě, kde se užívá uliční systém a název obce je shodný s názvem části obce
                // 5. v místě, kde se neužívá uliční systém a název obce a její části nejsou shodné
                if (street || cityPart) {
                    address += street || cityPart;
                }
                if (houseNumber) {
                    address = addPartToAddress(address, houseNumber, ' ');
                }
                if (postCodeCity) {
                    address = addPartToAddress(address, postCodeCity, ', ');
                }
            }
            else if (!street && (city === cityPart || !cityPart)) {
                // 6. v místě, kde se neužívá uliční systém a název obce a její části jsou shodné
                if (houseNumber) {
                    address += "\u010D.p. " + houseNumber;
                }
                if (postCodeCity) {
                    address = addPartToAddress(address, postCodeCity, ', ');
                }
            }
            else {
                // fallback
                if (street) {
                    address += street;
                }
                if (houseNumber) {
                    address += address.length > 0 ? " " + houseNumber : "\u010D.p. " + houseNumber;
                }
                if (cityPart) {
                    address = addPartToAddress(address, cityPart, ', ');
                }
                if (postCodeCity) {
                    address = addPartToAddress(address, postCodeCity, ', ');
                }
            }
        }
        return address;
    };
    AddressPipe.ngInjectableDef = i0.defineInjectable({ factory: function AddressPipe_Factory() { return new AddressPipe(i0.inject(i1.PostCodePipe)); }, token: AddressPipe, providedIn: "root" });
    return AddressPipe;
}());
exports.AddressPipe = AddressPipe;
function addPartToAddress(address, part, sep) {
    return address += address.length > 0 ? "" + sep + part : "" + part;
}
function getHouseNumber(subject) {
    if (subject.cisloDomovni) {
        if (subject.cisloOrientacni) {
            return subject.cisloDomovni.toString() + "/" + subject.cisloOrientacni;
        }
        else {
            return "" + subject.cisloDomovni.toString();
        }
    }
    else {
        return null;
    }
}
function getPostCodeWithCity(subject, postCodePipe) {
    if (subject.psc || subject.obec) {
        return [postCodePipe.transform(subject.psc), subject.obec]
            .filter(function (elm) { return elm !== '' && elm !== null && typeof elm !== 'undefined'; })
            .join(' ');
    }
    else {
        return null;
    }
}
