"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./alert.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/common");
var i3 = require("../../common/components/button/button.component.ngfactory");
var i4 = require("../../common/components/button/button.component");
var i5 = require("./alert.component");
var i6 = require("../dialog-config");
var i7 = require("../dialog-ref");
var styles_AlertComponent = [i0.styles];
var RenderType_AlertComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AlertComponent, data: {} });
exports.RenderType_AlertComponent = RenderType_AlertComponent;
function View_AlertComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [["class", "text-center info pb-20 pt-20 pr-15"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.config.data.msg; _ck(_v, 1, 0, currVal_0); }); }
function View_AlertComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h1", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Upozorn\u011Bn\u00ED"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlertComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "gmt-button", [], null, null, null, i3.View_ButtonComponent_0, i3.RenderType_ButtonComponent)), i1.ɵdid(6, 114688, null, 0, i4.ButtonComponent, ["ErrorHandlerService", i1.ChangeDetectorRef], { clickAction: [0, "clickAction"] }, null), (_l()(), i1.ɵted(-1, 0, ["Rozum\u00EDm"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.config == null) ? null : ((_co.config.data == null) ? null : _co.config.data.msg)); _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.onClose; _ck(_v, 6, 0, currVal_1); }, null); }
exports.View_AlertComponent_0 = View_AlertComponent_0;
function View_AlertComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "alert", [], null, null, null, View_AlertComponent_0, RenderType_AlertComponent)), i1.ɵdid(1, 49152, null, 0, i5.AlertComponent, [i6.DialogConfig, i7.DialogRef], null, null)], null, null); }
exports.View_AlertComponent_Host_0 = View_AlertComponent_Host_0;
var AlertComponentNgFactory = i1.ɵccf("alert", i5.AlertComponent, View_AlertComponent_Host_0, {}, {}, []);
exports.AlertComponentNgFactory = AlertComponentNgFactory;
