"use strict";
var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var SeriesService = /** @class */ (function () {
    function SeriesService() {
    }
    SeriesService.prototype.updateSeries = function (seriesParsed) {
        var attributes = ['restart', 'short', 'from', 'to', 'show'];
        var position = 0;
        var lastTypeText = false;
        seriesParsed.series.format = this.generateFormat(seriesParsed.sections);
        seriesParsed.sections.forEach(function (section) {
            if (section.type === 'text') {
                if (section.show && section.text && !lastTypeText) {
                    lastTypeText = true;
                    position++;
                }
                return;
            }
            lastTypeText = false;
            position++;
            attributes.forEach(function (attrName) {
                if (section[attrName] !== undefined) {
                    seriesParsed.series[section.type + attrName.charAt(0).toUpperCase() + attrName.slice(1)] = section[attrName];
                }
            });
            seriesParsed.series[section.type + 'Position'] = position;
        });
    };
    SeriesService.prototype.parseSeries = function (seriesList, seriesParsed) {
        var _this = this;
        seriesList.forEach(function (series) {
            seriesParsed.push(_this.parseSingleSeries(series));
        });
    };
    SeriesService.prototype.parseSingleSeries = function (series) {
        var sections = [{
                type: 'text'
            }];
        var myRegexp = /([^%]+)?(?:%(?:0([0-9]+))?(\w))?/g;
        var match = myRegexp.exec(series.format);
        var position = 1;
        var groups = ['year', 'order', 'title'];
        var _loop_1 = function () {
            var e_1, _a;
            var section = void 0;
            var group = null;
            try {
                for (var groups_1 = __values(groups), groups_1_1 = groups_1.next(); !groups_1_1.done; groups_1_1 = groups_1.next()) {
                    var currentGroup = groups_1_1.value;
                    if (position === series[currentGroup + 'Position']) {
                        group = currentGroup;
                        break;
                    }
                    else if (!series[currentGroup + 'Position'] && !groups.some(function (currentGroup) { return series[currentGroup + 'Position']; })) {
                        group = currentGroup;
                        break;
                    }
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (groups_1_1 && !groups_1_1.done && (_a = groups_1.return)) _a.call(groups_1);
                }
                finally { if (e_1) throw e_1.error; }
            }
            position++;
            if (group) {
                groups = groups.filter(function (currentGroup) { return currentGroup !== group; });
                section = this_1.createSection(group, series);
                sections.push(section);
                if (!series[group + 'Show']) {
                    return "continue";
                }
            }
            if (match[1]) {
                section = this_1.createSection('text', series);
                section.text = match[1].replace(/%%/g, '%');
                section.show = true;
                sections.push(section);
                match[1] = null;
                return "continue";
            }
            if (match[3]) {
                section.padNumber = match[2];
                section.show = true;
            }
            match = !match[0] ? match : myRegexp.exec(series.format);
        };
        var this_1 = this;
        while (match[0] || groups.length > 0) {
            _loop_1();
        }
        return {
            series: series,
            sections: sections
        };
    };
    SeriesService.prototype.sectionsToSeries = function (seriesParsed, dataSeries) {
        var _this = this;
        var position = 1;
        seriesParsed.forEach(function (seriesParsed) {
            _this.updateSeries(seriesParsed);
            seriesParsed.series.seriesOrder = position++;
            if (!seriesParsed.series.id) {
                dataSeries.push(seriesParsed.series);
            }
        });
    };
    SeriesService.prototype.advanceSeries = function (seriesParsed) {
        seriesParsed.series.orderCurrent = (this.getNaturalNumber(seriesParsed.series.orderCurrent) || 0) + 1;
        seriesParsed.series.nextNumber = this.generateNumber(seriesParsed, false);
    };
    SeriesService.prototype.generateNumber = function (seriesParsed, advance) {
        var _this = this;
        if (advance === void 0) { advance = true; }
        var sectionPosition = 0;
        var titleNumber = '[LV]';
        var year = new Date().getFullYear();
        var order = (this.getNaturalNumber(seriesParsed.series.orderCurrent) || 0) + (advance ? 1 : 0);
        var orderRestart = false;
        seriesParsed.series.available = true;
        seriesParsed.sections.forEach(function (section) {
            if (section.type === 'text') {
                return;
            }
            else if (section.type === 'order') {
                orderRestart = section.restart;
            }
        });
        if (seriesParsed.series.yearCurrent && year > seriesParsed.series.yearCurrent && orderRestart) {
            if (seriesParsed.series.orderRestarted) {
                seriesParsed.series.available = false;
                year = seriesParsed.series.yearCurrent;
            }
            else {
                order = 0;
            }
        }
        return this.generateFormat(seriesParsed.sections).replace(/%%/g, '%').replace(/%(?:0([0-9]+))?(\w)/g, function ($0, $1) {
            while (seriesParsed.sections[sectionPosition].type === 'text' || !seriesParsed.sections[sectionPosition].show) {
                sectionPosition++;
            }
            var num;
            if (seriesParsed.sections[sectionPosition].type === 'year') {
                num = year;
            }
            else if (seriesParsed.sections[sectionPosition].type === 'order') {
                num = order;
            }
            else {
                sectionPosition++;
                return titleNumber;
            }
            var tmp = _this.getNaturalNumber(seriesParsed.sections[sectionPosition].from);
            if (tmp && num < tmp) {
                num = tmp;
            }
            tmp = _this.getNaturalNumber(seriesParsed.sections[sectionPosition].to);
            if (tmp && num > tmp) {
                seriesParsed.series.available = false;
            }
            if (seriesParsed.sections[sectionPosition].short) {
                num = num % 100;
            }
            num += '';
            if ($1 && num.length < $1) {
                var pad = new Array(Math.min(100, ++$1) - num.length).join('0');
                num = pad + num;
            }
            sectionPosition++;
            return num;
        });
    };
    SeriesService.prototype.getNaturalNumber = function (text) {
        return text && isFinite(text) && Math.round(Math.abs(text)) === text * 1 ? text * 1 : null;
    };
    SeriesService.prototype.generateFormat = function (sections) {
        var _this = this;
        var format = '';
        sections.forEach(function (item) {
            if (!item.show) {
                return;
            }
            if (item.type === 'text' && item.text) {
                format += item.text.replace(/%/g, '%%');
            }
            else if (item.type === 'title') {
                format += '%s';
            }
            else if (item.type === 'year') {
                format += '%' + (item.short ? '02' : '') + 'd';
            }
            else if (item.type === 'order') {
                var padNumber = _this.getNaturalNumber(item.padNumber);
                format += '%' + (padNumber ? '0' + padNumber : '') + 'd';
            }
        });
        return format;
    };
    SeriesService.prototype.createSection = function (type, series) {
        return {
            type: type,
            show: false,
            restart: series[type + 'Restart'],
            short: series[type + 'Short'],
            from: series[type + 'From'],
            to: series[type + 'To']
        };
    };
    SeriesService.ngInjectableDef = i0.defineInjectable({ factory: function SeriesService_Factory() { return new SeriesService(); }, token: SeriesService, providedIn: "root" });
    return SeriesService;
}());
exports.SeriesService = SeriesService;
