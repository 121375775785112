"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var list_service_1 = require("../../services/list.service");
var TooltipAbstractListComponent = /** @class */ (function () {
    function TooltipAbstractListComponent(cdr, listLoadService, tooltipConfig) {
        this.cdr = cdr;
        this.listLoadService = listLoadService;
        this.tooltipConfig = tooltipConfig;
    }
    TooltipAbstractListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.cdr.detach();
        if (!this.data.list) {
            this.loading = true;
            this.data.list = this.listLoadService.createList(this.data.resource || this.resource, this.data.filter ? this.data.filter : { limit: 0 }, undefined, this.data.attributes);
            this.cdr.detectChanges();
            this.listLoadService.fetchResult(this.data.list)
                .then(function () {
                _this.loading = false;
                _this.cdr.detectChanges();
                _this.tooltipConfig.checkPosition();
            });
        }
        else {
            this.cdr.detectChanges();
        }
    };
    return TooltipAbstractListComponent;
}());
exports.TooltipAbstractListComponent = TooltipAbstractListComponent;
