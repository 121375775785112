"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("../../../common/components/button/button.component.ngfactory");
var i2 = require("../../../common/components/button/button.component");
var i3 = require("../project-subject/project-subject.component.ngfactory");
var i4 = require("../project-subject/project-subject.component");
var i5 = require("../../pipes/acting-person-name.pipe");
var i6 = require("../../../dialog/dialog.service");
var i7 = require("@angular/common");
var i8 = require("../../../common/pipes/word-translate.pipe");
var i9 = require("../../../common/services/word.service");
var i10 = require("../../../common/services/auth.service");
var i11 = require("../../../common/directives/loading.directive");
var i12 = require("./administrator-edit.component");
var i13 = require("../../../dialog/dialog-config");
var i14 = require("../../../dialog/dialog-ref");
var styles_AdministratorEditComponent = [];
var RenderType_AdministratorEditComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AdministratorEditComponent, data: {} });
exports.RenderType_AdministratorEditComponent = RenderType_AdministratorEditComponent;
function View_AdministratorEditComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "gmt-button", [], null, null, null, i1.View_ButtonComponent_0, i1.RenderType_ButtonComponent)), i0.ɵdid(1, 114688, null, 0, i2.ButtonComponent, ["ErrorHandlerService", i0.ChangeDetectorRef], { clickAction: [0, "clickAction"], gmtDisabled: [1, "gmtDisabled"] }, null), (_l()(), i0.ɵted(-1, 0, ["Ulo\u017Eit"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.onCreate; var currVal_1 = !_co.isValid(); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_AdministratorEditComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "gmt-button", [], null, null, null, i1.View_ButtonComponent_0, i1.RenderType_ButtonComponent)), i0.ɵdid(1, 114688, null, 0, i2.ButtonComponent, ["ErrorHandlerService", i0.ChangeDetectorRef], { clickAction: [0, "clickAction"], gmtDisabled: [1, "gmtDisabled"] }, null), (_l()(), i0.ɵted(-1, 0, ["Ulo\u017Eit"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.onUpdate; var currVal_1 = !_co.isValid(); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_AdministratorEditComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "gmt-project-subject", [], null, null, null, i3.View_ProjectSubjectComponent_0, i3.RenderType_ProjectSubjectComponent)), i0.ɵdid(3, 114688, null, 0, i4.ProjectSubjectComponent, [i5.ActingPersonNamePipe, i6.DialogService], { subject: [0, "subject"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 4, "div", [["class", "actions"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AdministratorEditComponent_2)), i0.ɵdid(6, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AdministratorEditComponent_3)), i0.ɵdid(8, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.administrator; _ck(_v, 3, 0, currVal_0); var currVal_1 = (_co.administrator.id === undefined); _ck(_v, 6, 0, currVal_1); var currVal_2 = (_co.administrator.id !== undefined); _ck(_v, 8, 0, currVal_2); }, null); }
function View_AdministratorEditComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "h1", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", " ", ""])), i0.ɵpid(0, i8.WordTranslatePipe, ["EVENTS", i9.WordService, i10.AuthService]), (_l()(), i0.ɵeld(3, 0, null, null, 3, "div", [], [[8, "className", 0]], null, null, null, null)), i0.ɵdid(4, 16384, null, 0, i11.LoadingDirective, [i0.ElementRef], { loading: [0, "loading"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AdministratorEditComponent_1)), i0.ɵdid(6, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.loading; _ck(_v, 4, 0, currVal_3); var currVal_4 = !_co.loading; _ck(_v, 6, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.administratorId ? "Editace spr\u00E1vce" : "Zalo\u017Een\u00ED spr\u00E1vce"); var currVal_1 = i0.ɵunv(_v, 1, 1, i0.ɵnov(_v, 2).transform("CONSTRUCTION_OBJECT_GENITIV")); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = i0.ɵnov(_v, 4).elementClass; _ck(_v, 3, 0, currVal_2); }); }
exports.View_AdministratorEditComponent_0 = View_AdministratorEditComponent_0;
function View_AdministratorEditComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "administrator-edit", [], null, null, null, View_AdministratorEditComponent_0, RenderType_AdministratorEditComponent)), i0.ɵdid(1, 114688, null, 0, i12.AdministratorEditComponent, ["HELP_IDS", "Restangular", i6.DialogService, i13.DialogConfig, i14.DialogRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_AdministratorEditComponent_Host_0 = View_AdministratorEditComponent_Host_0;
var AdministratorEditComponentNgFactory = i0.ɵccf("administrator-edit", i12.AdministratorEditComponent, View_AdministratorEditComponent_Host_0, {}, {}, []);
exports.AdministratorEditComponentNgFactory = AdministratorEditComponentNgFactory;
