"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./tooltip-dimap-ownerships.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../../pipes/subject-name.pipe");
var i3 = require("../../directives/loading.directive");
var i4 = require("@angular/common");
var i5 = require("./tooltip-dimap-ownerships.component");
var i6 = require("../../services/list.service");
var styles_TooltipDimapOwnershipsComponent = [i0.styles];
var RenderType_TooltipDimapOwnershipsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TooltipDimapOwnershipsComponent, data: {} });
exports.RenderType_TooltipDimapOwnershipsComponent = RenderType_TooltipDimapOwnershipsComponent;
function View_TooltipDimapOwnershipsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "li", [["class", "ownership"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " / ", "", " "])), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵppd(4, 1)], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.podilCitatel; var currVal_1 = _v.context.$implicit.podilJmenovatel; var currVal_2 = (_v.context.$implicit.typravNazev ? ((" (" + _v.context.$implicit.typravNazev) + ")") : ""); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = i1.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent, 0), _v.context.$implicit.subject)); _ck(_v, 3, 0, currVal_3); }); }
function View_TooltipDimapOwnershipsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u017D\u00E1dn\u00ED vlastn\u00EDci"]))], null, null); }
function View_TooltipDimapOwnershipsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.SubjectNamePipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [], [[8, "className", 0]], null, null, null, null)), i1.ɵdid(2, 16384, null, 0, i3.LoadingDirective, [i1.ElementRef], { loading: [0, "loading"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TooltipDimapOwnershipsComponent_1)), i1.ɵdid(5, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TooltipDimapOwnershipsComponent_2)), i1.ɵdid(7, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.loading; _ck(_v, 2, 0, currVal_1); var currVal_2 = _co.data.list.list; _ck(_v, 5, 0, currVal_2); var currVal_3 = (((_co.data.list.list == null) ? null : _co.data.list.list.length) === 0); _ck(_v, 7, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).elementClass; _ck(_v, 1, 0, currVal_0); }); }
exports.View_TooltipDimapOwnershipsComponent_0 = View_TooltipDimapOwnershipsComponent_0;
function View_TooltipDimapOwnershipsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "tooltip-dimap-ownership", [], null, null, null, View_TooltipDimapOwnershipsComponent_0, RenderType_TooltipDimapOwnershipsComponent)), i1.ɵdid(1, 114688, null, 0, i5.TooltipDimapOwnershipsComponent, [i1.ChangeDetectorRef, i6.ListService, "tooltipConfig"], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_TooltipDimapOwnershipsComponent_Host_0 = View_TooltipDimapOwnershipsComponent_Host_0;
var TooltipDimapOwnershipsComponentNgFactory = i1.ɵccf("tooltip-dimap-ownership", i5.TooltipDimapOwnershipsComponent, View_TooltipDimapOwnershipsComponent_Host_0, { data: "data" }, {}, []);
exports.TooltipDimapOwnershipsComponentNgFactory = TooltipDimapOwnershipsComponentNgFactory;
