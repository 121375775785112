"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
exports.RESTANGULAR_PROMAP = new core_1.InjectionToken('RestangularPromap');
function RestangularPromapFactory(restangular, config) {
    return restangular.withConfig(function (restangularConfigurer) {
        restangularConfigurer.setBaseUrl(config.BACKEND_OPTIONS.restUrlPK);
    });
}
exports.RestangularPromapFactory = RestangularPromapFactory;
