"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var _ = require("lodash");
var table_parcels_abstract_component_1 = require("../table-parcels-abstract/table-parcels-abstract.component");
var auth_service_1 = require("@app/common/services/auth.service");
var dialog_service_1 = require("@app/dialog/dialog.service");
var update_easement_price_component_1 = require("@app/ps/components/update-easement-price/update-easement-price.component");
var TableGeometricPlansComponent = /** @class */ (function (_super) {
    __extends(TableGeometricPlansComponent, _super);
    function TableGeometricPlansComponent(dialogService, authService) {
        var _this = _super.call(this) || this;
        _this.dialogService = dialogService;
        _this.authService = authService;
        // props
        _this.COMPONENT_COLUMNS = [
            'parcel', 'title', 'area', 'length', 'price', 'price_edit_construction_object', 'cases',
            'construction_objects_by_shared_easement'
        ];
        _this.onPriceEdit = _this.onPriceEdit.bind(_this);
        return _this;
    }
    TableGeometricPlansComponent.prototype.ngOnInit = function () {
        this.showVbColumn = this.data.some(function (easement) { return easement.hasOwnProperty('oznacVb') && easement.oznacVb; });
    };
    TableGeometricPlansComponent.prototype.isEditablePrice = function (easement) {
        // Hodnoty na všech SO shodné
        var easementGlobalPriceType = new Set(easement.constructionObjects.map(function (co) { return co.easementGlobalPriceType; })).size == 1 ?
            easement.constructionObjects[0].easementGlobalPriceType : null;
        return this.visibleColumns.price_edit_construction_object
            && easement.parcel.title
            && (this.authService.isAuthorized() || this.authService.hasPermission("assignable"))
            && easementGlobalPriceType
            && !easement.existsCaseBlockingPrice;
    };
    TableGeometricPlansComponent.prototype.onPriceEdit = function (easement) {
        var _this = this;
        if (!this.isEditablePrice(easement)) {
            return;
        }
        var dialog = this.dialogService.open(update_easement_price_component_1.UpdateEasementPriceComponent, {
            data: { easementId: easement.id },
        });
        var sub = dialog.afterClosed.subscribe(function (updatedEasement) {
            if (updatedEasement) {
                var i = _.findIndex(_this.data, { id: updatedEasement.id });
                _this.data.splice(i, 1, updatedEasement);
            }
            sub.unsubscribe();
        });
    };
    return TableGeometricPlansComponent;
}(table_parcels_abstract_component_1.TableParcelsAbstractComponent));
exports.TableGeometricPlansComponent = TableGeometricPlansComponent;
