"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var dialog_service_1 = require("@app/dialog/dialog.service");
var dialog_config_1 = require("@app/dialog/dialog-config");
var dialog_ref_1 = require("@app/dialog/dialog-ref");
var project_subject_model_1 = require("@app/ps/models/project-subject.model");
var AdministratorEditComponent = /** @class */ (function () {
    function AdministratorEditComponent(helpIds, restangular, dialogService, config, dialog) {
        this.helpIds = helpIds;
        this.restangular = restangular;
        this.dialogService = dialogService;
        this.config = config;
        this.dialog = dialog;
        this.loading = true;
        this.administratorId = config.data.administratorId;
        this.onCreate = this.onCreate.bind(this);
        this.onUpdate = this.onUpdate.bind(this);
    }
    AdministratorEditComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.administratorId) {
            this.restangular
                .one('administrators', this.administratorId)
                .get({ loadCollections: ['actingPersons'] })
                .then(function (data) {
                _this.administrator = data.plain();
                _this.loading = false;
            });
        }
        else {
            this.administrator = project_subject_model_1.createAdministrator();
            this.loading = false;
        }
    };
    AdministratorEditComponent.prototype.onCreate = function () {
        var _this = this;
        return this.restangular
            .all('administrators')
            .all('create')
            .post(this.administrator)
            .then(function (data) {
            _this.dialog.close(data.plain());
        });
    };
    AdministratorEditComponent.prototype.onUpdate = function () {
        var _this = this;
        return this.restangular
            .one('administrators', this.administrator.id)
            .customPUT(this.administrator, '', { loadCollections: ['actingPersons'] })
            .then(function (data) {
            _this.dialog.close(data.plain());
        });
    };
    AdministratorEditComponent.prototype.isValid = function () {
        var companyTypes = ['OPO', 'OPO_OSS', 'OPO_PO'];
        var isCompany = this.administrator.companyName && companyTypes.includes(this.administrator.subjectType);
        var isPerson = this.administrator.surname && this.administrator.subjectType === 'OFO';
        return isCompany || isPerson;
    };
    return AdministratorEditComponent;
}());
exports.AdministratorEditComponent = AdministratorEditComponent;
