"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var auth_service_1 = require("@app/common/services/auth.service");
var series_name_pipe_1 = require("@app/ps/pipes/series-name.pipe");
var list_service_1 = require("@app/common/services/list.service");
var title_names_pipe_1 = require("@app/common/pipes/title-names.pipe");
var _ = require("lodash");
var _Fraction = require("fraction.js");
var other_legal_relations_service_1 = require("@app/common/services/other-legal-relations.service");
var case_service_1 = require("@app/ps/services/case.service");
var series_service_1 = require("@app/ps/services/series.service");
var Fraction = _Fraction;
var CreateCasePriceComponent = /** @class */ (function () {
    function CreateCasePriceComponent(authService, listService, seriesNamePipe, titleNamesPipe, otherLegalRelationsService, restangular, confirmService, alertService, seriesService, caseService, errorHandlerService) {
        this.authService = authService;
        this.listService = listService;
        this.seriesNamePipe = seriesNamePipe;
        this.titleNamesPipe = titleNamesPipe;
        this.otherLegalRelationsService = otherLegalRelationsService;
        this.restangular = restangular;
        this.confirmService = confirmService;
        this.alertService = alertService;
        this.seriesService = seriesService;
        this.caseService = caseService;
        this.errorHandlerService = errorHandlerService;
        this.submitCallback = new core_1.EventEmitter();
        this.backCallback = new core_1.EventEmitter();
        this.loading = true;
        this.distributionSystemPartsOptions = null;
        this.seriesData = [];
        this.seriesDataParsed = [];
        this.generatedSeriesNumbers = [];
        this.restrictions = [];
        this.otherEntries = [];
        this.businessCaseIndex = 0;
        this.flatCaseOwnerships = [];
        this.submit = this.submit.bind(this);
        this.previous = this.previous.bind(this);
        this.next = this.next.bind(this);
    }
    CreateCasePriceComponent.prototype.ngOnInit = function () {
        var project = this.authService.getActualProject();
        this.easementPriceByShare = project.easementPriceByShare;
        this.showEasementDistributionSystemParts = project.easementDistributionSystemParts;
        this.loadInitialData();
    };
    CreateCasePriceComponent.prototype.loadInitialData = function () {
        var _this = this;
        var promises = [this.computeShares(), this.loadSeries()];
        if (this.showEasementDistributionSystemParts) {
            promises.push(this.loadDistributionSystemParts());
        }
        Promise.all(promises).then(function () {
            _this.loading = false;
            _this.loadOtherLegalRelations();
        }).catch(function (r) {
            new _this.errorHandlerService()(r);
            _this.backCallback.emit();
        });
    };
    CreateCasePriceComponent.prototype.loadOtherLegalRelations = function () {
        var _this = this;
        this.restrictions;
        this.otherEntries;
        var titleIds = this.data.cases.flatMap(function (businessCase) { return businessCase.titles.map(function (title) { return title.id; }); });
        this.dataOtherLegalRelations = this.otherLegalRelationsService.loadOtherLegalRelations(titleIds);
        return this.dataOtherLegalRelations.promise.then(function () {
            _this.data.cases.forEach(function (businessCase, index) {
                var _a, _b, _c, _d;
                _this.restrictions[index] = [];
                _this.otherEntries[index] = [];
                var filteredGroups;
                filteredGroups = _this.otherLegalRelationsService.filterByEntityWithParcel(_this.dataOtherLegalRelations.list, businessCase.easements, businessCase.titles[0], businessCase.caseOwnerships);
                (_a = _this.restrictions[index]).push.apply(_a, __spread(filteredGroups.restrictions));
                (_b = _this.otherEntries[index]).push.apply(_b, __spread(filteredGroups.otherEntries));
                filteredGroups = _this.otherLegalRelationsService.filterByCaseOwnerships(_this.dataOtherLegalRelations.list, businessCase.caseOwnerships, businessCase.titles[0]);
                (_c = _this.restrictions[index]).push.apply(_c, __spread(filteredGroups.restrictions));
                (_d = _this.otherEntries[index]).push.apply(_d, __spread(filteredGroups.otherEntries));
            });
        });
    };
    CreateCasePriceComponent.prototype.loadSeries = function () {
        var _this = this;
        return this.restangular.all('series').customPOST({ filter: { filters: { constructionObjectId: [this.constructionObjectId] } } }).then(function (data) {
            _this.seriesData = _this.restangular.stripRestangular(data);
            _this.seriesService.parseSeries(_.cloneDeep(_this.seriesData), _this.seriesDataParsed);
            if (_this.seriesData[0]) {
                var seriesToChoose_1 = _this.seriesData[0];
                _this.data.cases.forEach(function (businessCase) {
                    businessCase.series = seriesToChoose_1;
                });
            }
            _this.refreshSeriesNumbers(-1);
        });
    };
    CreateCasePriceComponent.prototype.loadDistributionSystemParts = function () {
        var _this = this;
        this.distributionSystemPartOptionsReload = [];
        this.distributionSystemPartsOptions = null;
        var list = this.listService.createList('distribution-system-parts', { limit: null });
        this.listService.fetchResult(list).then(function () {
            _this.distributionSystemPartsOptions = list.list;
        });
        return list.promise;
    };
    CreateCasePriceComponent.prototype.distributionSystemPartAdded = function (businessCaseIndex) {
        this.distributionSystemPartOptionsReload.forEach(function (reload, index) {
            if (index !== businessCaseIndex) {
                reload();
            }
        });
    };
    CreateCasePriceComponent.prototype.distributionSystemPartReloadRegister = function (index, reload) {
        this.distributionSystemPartOptionsReload[index] = reload;
    };
    CreateCasePriceComponent.prototype.refreshSeriesNumbers = function (updateSeriesFrom) {
        var _this = this;
        this.seriesDataParsed.forEach(function (seriesParsed) {
            seriesParsed.series = _.cloneDeep(_.find(_this.seriesData, { id: seriesParsed.series.id }));
        });
        this.data.cases.forEach(function (businessCase, index) {
            if (index > updateSeriesFrom && (!businessCase.mpp.number || businessCase.series)) {
                businessCase.series = _this.seriesData[_.findIndex(_this.seriesDataParsed, { series: { available: true } })];
            }
            else if (businessCase.series && index === updateSeriesFrom) {
                businessCase.mpp.number = null;
            }
            if (businessCase.series) {
                var seriesParsed = _.find(_this.seriesDataParsed, { series: { id: businessCase.series.id } });
                _this.generatedSeriesNumbers[index] = seriesParsed.series.available ? seriesParsed.series.nextNumber : null;
                _this.seriesService.advanceSeries(seriesParsed);
            }
            else {
                _this.generatedSeriesNumbers[index] = null;
            }
        });
    };
    CreateCasePriceComponent.prototype.flattenParents = function (caseOwnership) {
        return _.flattenDeep(caseOwnership.parentCaseOwnership ? [caseOwnership.parentCaseOwnership, this.flattenParents(caseOwnership.parentCaseOwnership)] : []);
    };
    CreateCasePriceComponent.prototype.mapCaseOwnerships = function (caseOwnerships) {
        var _this = this;
        return _.map(caseOwnerships, function (caseOwnership) {
            return {
                caseOwnership: caseOwnership,
                parentCaseOwnerships: _this.flattenParents(caseOwnership)
            };
        });
    };
    CreateCasePriceComponent.prototype.computeShares = function () {
        var _this = this;
        var result = true;
        var promises = [];
        // compute shares of owners
        _.forEach(this.data.cases, function (businessCase, index) {
            businessCase.obligation = _this.data.obligation;
            businessCase.acceleratedPriceType = _this.data.acceleratedPriceType;
            var totalShare = new Fraction(0);
            businessCase.caseOwnerships.forEach(function (caseOwnership) {
                if (_this.caseService.isOwnerPresent(caseOwnership)) {
                    caseOwnership.easementPrice = 0;
                    caseOwnership.caseOwnershipParcels = [];
                    totalShare = totalShare.add(new Fraction(caseOwnership.ownership.podilCitatel, caseOwnership.ownership.podilJmenovatel));
                }
            });
            if (!totalShare.equals(1) && _this.data.createGroups.length === 1 && _this.data.createGroups.some(function (group) { return group.id === 'title'; })) {
                result = false;
                new _this.alertService({
                    message: 'Na LV ' + _this.titleNamesPipe.transform(businessCase.titles[0]) + ' byl zjištěn chybný celkový podíl ' + totalShare.toFraction() + '.'
                }).then();
                return false;
            }
            if (!_this.easementPriceByShare && _this.data.obligation.computePrice) {
                businessCase.totalEasementPrice = null;
                delete businessCase.series;
                var promise = _this.restangular.all('titles').all('case').all('compute-price')
                    .customPOST(businessCase)
                    .then(function (result) {
                    _.assign(businessCase, _this.restangular.stripRestangular(result));
                    _this.flatCaseOwnerships[index] = _this.mapCaseOwnerships(businessCase.caseOwnerships);
                })
                    .then(function () {
                    businessCase.totalEasementPrice = 0;
                    businessCase.caseOwnerships.forEach(function (caseOwnership) {
                        caseOwnership.easementPrice += _.reduce(caseOwnership.caseOwnershipParcels, function (acc, caseOwnershipParcel) {
                            return acc + caseOwnershipParcel.easementPrice;
                        }, 0);
                        businessCase.totalEasementPrice += caseOwnership.easementPrice;
                    });
                });
                promises.push(promise);
            }
            else {
                businessCase.totalEasementPrice = 0;
                _this.flatCaseOwnerships[index] = _this.mapCaseOwnerships(businessCase.caseOwnerships);
            }
        });
        return Promise.all(promises).then(function () { return result; });
    };
    CreateCasePriceComponent.prototype.easementPriceByShareUpdated = function (businessCase) {
        businessCase.caseOwnerships.forEach(function (caseOwnership) {
            businessCase.totalEasementPrice -= caseOwnership.easementPrice;
            caseOwnership.easementPrice = Number(caseOwnership.easementPriceByShare);
            caseOwnership.easementPrice = caseOwnership.easementPrice || 0;
            businessCase.totalEasementPrice += caseOwnership.easementPrice;
        });
    };
    CreateCasePriceComponent.prototype.submit = function () {
        this.submitCallback.emit();
    };
    CreateCasePriceComponent.prototype.previous = function () {
        this.businessCaseIndex--;
    };
    CreateCasePriceComponent.prototype.next = function () {
        this.businessCaseIndex++;
    };
    return CreateCasePriceComponent;
}());
exports.CreateCasePriceComponent = CreateCasePriceComponent;
