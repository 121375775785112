const helpIds = {
  CONSTRUCTIONOBJECT_DETAIL: 1,
  CASES_DETAIL: 2,
  CONSTRUCTIONOBJECT_CREATECASE_PRICE: 3,
  CONSTRUCTIONOBJECT_CREATECASE_SELECT_AREA: 4,
  CONSTRUCTIONOBJECT_CREATECASE_SELECT_EASEMENT: 5,
  CONSTRUCTIONOBJECT_CREATECASE_SELECT_PARENT_OWNERSHIP: 6,
  CONSTRUCTIONOBJECT_CREATECASE_SUMMARY: 7,
  PANEL_HOME: 8,
  PANEL_CASES: 9,
  PANEL_TITLES: 10,
  PANEL_OCCUPATIONS: 11,
  PANEL_CONSTRUCTION_OBJECTS: 12,
  PANEL_PARCELS: 13,
  PANEL_MAP: 14,
  PANEL_STATISTICS: 15,
  PANEL_EXPORTS: 16,
  PANEL_SETTINGS: 17,
  TITLE_DETAIL: 18,
  SETTINGS_CHANGE_PASSWORD: 19,
  SETTINGS_EXPERTS: 20,
  SETTINGS_ADMINISTRATORS: 21,
  SETTINGS_TEMPLATES: 22,
  SETTINGS_USERS: 23,
  PANEL_TITLES_RIGHTS: 24,
  CONSTRUCTION_OBJECT_NEW_SELECT: 25,
  CONSTRUCTION_OBJECT_NEW_SUMMARY: 26,
  CONSTRUCTION_OBJECT_NEW_EASEMENT_ADD_FILES: 27,
  CONSTRUCTION_OBJECT_NEW_EASEMENT_INFO: 28,
  CONSTRUCTION_OBJECT_NEW_EASEMENT_SUMMARY: 29,
  TITLE_DETAIL_BLOCK_TITLE: 30,
  TITLE_DETAIL_OPINIONS: 31,
  TITLE_DETAIL_OPINIONS_CREATE_OTHERS: 32,
  TITLE_DETAIL_OPINIONS_CREATE_EXPERTS_OPINION: 33,
  TITLE_DETAIL_OPINIONS_UPDATE_PRELIMINARY_OPINION: 34,
  TITLE_DETAIL_CREATE_CASE_SELECT: 35,
  TITLE_DETAIL_CREATE_CASE_SELECT_TITLES: 36,
  TITLE_DETAIL_CREATE_CASE_SELECT_PARENT_OWNERSHIP: 37,
  TITLE_DETAIL_CREATE_CASE_CONFIRM: 38,
  TITLE_DETAIL_CREATE_CASE_SUMMARY: 39,
  CONSTRUCTIONOBJECT_UPDATE_GLOBAL_EASEMENT_PRICE: 40,
  CONSTRUCTIONOBJECT_UPDATE_EASEMENT_PRICE: 41,
  PK_PROJECTS: 42,
  CASES_DETAIL_GENERATE_DOCUMENTS: 45,
  CASES_DETAIL_UPDATE_SUBJECT: 46,
  CASES_DETAIL_UPDATE: 47,
  VM_PANEL_PROPERTY_STATUS: 48,
  VM_PANEL_MAP: 49,
  VM_PANEL_TITLES: 50,
  VM_PANEL_PARCELS: 51,
  VM_TITLE_DETAIL: 52,
  VM_PARCEL_DETAIL: 53,
  PK_DOWNLOAD_DATA: 54,
  VM_PANEL_SETTINGS: 55,
  PK_CREATE_FIRST_STEP: 56,
  PK_CREATE_SECOND_STEP: 57,
  PK_CREATE_THIRD_STEP: 58,
  PK_SETTINGS: 59,
  PK_SETTINGS_WSDP: 60,
  PS_SUBJECT_DETAIL: 61,
  PS_SUBJECT_LIST: 62,
  DASHBOARD_PROJECTS: 63,
  GEOMETRIC_PLAN_REQUEST_LIST: 64,
  GEOMETRIC_PLAN_REQUEST_DETAIL: 65,
  OPINION_REQUEST_LIST: 66,
  OPINION_REQUEST_DETAIL: 67,
  PS_PROJECT_INFO: 68,
  TICKET_LIST: 69,
  SAMPLE_LIST: 70,
  HOME: 71,
  SY_PROJECTS: 72,
};

export default helpIds;
