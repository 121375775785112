"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var auth_service_1 = require("@app/common/services/auth.service");
var TooltipSystemMetadataComponent = /** @class */ (function () {
    function TooltipSystemMetadataComponent(APP_BRAND, APPLICATIONS, authService) {
        this.APP_BRAND = APP_BRAND;
        this.APPLICATIONS = APPLICATIONS;
        this.authService = authService;
    }
    TooltipSystemMetadataComponent.prototype.ngOnInit = function () {
        this.application = this.authService.getActiveApplication();
        this.applicationName = (this.application === this.APPLICATIONS.sy.name
            ? this.APP_BRAND.PS.TITLE
            : this.application);
    };
    return TooltipSystemMetadataComponent;
}());
exports.TooltipSystemMetadataComponent = TooltipSystemMetadataComponent;
