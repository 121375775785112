"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var dialog_ref_1 = require("@app/dialog/dialog-ref");
var dialog_config_1 = require("@app/dialog/dialog-config");
var EasementUpdateGlobalPriceComponent = /** @class */ (function () {
    function EasementUpdateGlobalPriceComponent(restangular, helpIds, dialogRef, dialogConfig) {
        this.restangular = restangular;
        this.helpIds = helpIds;
        this.dialogRef = dialogRef;
        this.dialogConfig = dialogConfig;
        this.loading = true;
        this.priceTypes = [
            {
                id: 'I',
                value: 'Individuálně',
            },
            {
                id: 'B',
                value: 'Hromadně',
            },
        ];
        this.unitTypes = [
            {
                id: 'S',
                value: 'Jednorázová cena podle 416/2009 Sb.',
            },
            {
                id: 'A',
                value: 'Plocha',
            },
            {
                id: 'L',
                value: 'Délka',
            },
        ];
        this.constructionObjectId = dialogConfig.data.constructionObjectId;
        this.onUpdate = this.onUpdate.bind(this);
    }
    EasementUpdateGlobalPriceComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.restangular
            .one('construction-objects', this.constructionObjectId)
            .get()
            .then(function (res) {
            _this.constructionObject = res.plain();
            if (!_this.constructionObject.easementGlobalPriceType) {
                _this.constructionObject.easementGlobalPriceType = 'B';
                _this.onPriceTypeUpdate(_this.constructionObject.easementGlobalPriceType);
            }
            _this.loading = false;
        });
    };
    EasementUpdateGlobalPriceComponent.prototype.onUnitTypeUpdate = function (value) {
        if (value === 'S') {
            this.constructionObject.easementGlobalOtherSource = 'zákon 416/2009 Sb.';
            this.constructionObject.easementGlobalOtherSinglePrice = 10000;
        }
        else {
            this.constructionObject.easementGlobalOtherSource = undefined;
            this.constructionObject.easementGlobalOtherSinglePrice = undefined;
        }
    };
    EasementUpdateGlobalPriceComponent.prototype.onPriceTypeUpdate = function (value) {
        if (value !== 'I' && !this.constructionObject.easementGlobalOtherUnitType) {
            this.constructionObject.easementGlobalOtherUnitType = 'S';
            this.onUnitTypeUpdate(this.constructionObject.easementGlobalOtherUnitType);
        }
        else if (value === 'I') {
            this.constructionObject.easementGlobalOtherUnitType = undefined;
            this.onUnitTypeUpdate(this.constructionObject.easementGlobalOtherUnitType);
        }
    };
    /**
     * Disables the save button when price is not properly set.
     * Price can be set either individually or globally.
     * If set globally, either price based on easement area or length has to be provided.
     *
     * @returns {boolean}
     */
    EasementUpdateGlobalPriceComponent.prototype.isValid = function () {
        var isIndividualPrice = this.constructionObject.easementGlobalPriceType === 'I';
        var priceByLength = this.constructionObject.easementGlobalOtherUnitType === 'L' && this.constructionObject.easementGlobalOtherLengthPrice;
        var priceByArea = this.constructionObject.easementGlobalOtherUnitType === 'A' && this.constructionObject.easementGlobalOtherAreaPrice;
        var singlePrice = this.constructionObject.easementGlobalOtherUnitType === 'S' && this.constructionObject.easementGlobalOtherSinglePrice;
        var isBulkPrice = this.constructionObject.easementGlobalPriceType === 'B';
        return isIndividualPrice || (isBulkPrice && (priceByLength || priceByArea || singlePrice));
    };
    EasementUpdateGlobalPriceComponent.prototype.onUpdate = function () {
        var _this = this;
        return this.restangular
            .one('construction-objects', this.constructionObjectId)
            .customPUT(this.constructionObject, undefined, { loadCollections: ['actingPersons', 'administrator'] })
            .then(function (res) {
            _this.dialogRef.close(res.plain());
        });
    };
    return EasementUpdateGlobalPriceComponent;
}());
exports.EasementUpdateGlobalPriceComponent = EasementUpdateGlobalPriceComponent;
