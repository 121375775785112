"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var angular_1 = require("@uirouter/angular");
var class_name_enum_1 = require("@app/dialog/class-name.enum");
var contract_detail_component_1 = require("./customers/components/contract-detail/contract-detail.component");
var contract_form_component_1 = require("./customers/components/contract-form/contract-form.component");
var contract_service_1 = require("../settings/customers/services/contract.service");
var customer_detail_component_1 = require("@app/settings/customers/components/customer-detail/customer-detail.component");
var customer_edit_component_1 = require("@app/settings/customers/components/customer-edit/customer-edit.component");
var dialog_service_1 = require("@app/dialog/dialog.service");
var project_form_component_1 = require("./customers/components/project-form/project-form.component");
var customer_list_component_1 = require("@app/settings/customers/components/customer-list/customer-list.component");
var main_component_1 = require("@app/settings/main/main.component");
function authCallback(authService, loginService, applications) {
    if (!authService.isAuthenticatedApplication(applications.settings.name)) {
        return loginService.redirectToLogin(true);
    }
}
exports.authCallback = authCallback;
exports.settingsState = {
    name: 'settings',
    url: '/settings',
    abstract: true,
    component: main_component_1.MainComponent,
    onEnter: ['AuthService', 'LoginService', 'APPLICATIONS', authCallback],
};
exports.customerListState = {
    name: 'settings.customers',
    url: '/customers?page',
    data: {
        title: 'Správa objednatelů',
    },
    params: {
        page: {
            dynamic: true,
            type: 'int',
            inherit: false,
            squash: true,
        },
    },
    views: {
        'content@settings': {
            component: customer_list_component_1.CustomerListComponent,
        },
    },
};
exports.customerCreateState = {
    name: 'settings.customers.create',
    url: '/create',
    data: {
        title: 'Nový objednatel',
    },
    views: {
        'content@settings': {
            component: customer_edit_component_1.CustomerEditComponent,
        }
    },
};
exports.customerDetailState = {
    name: 'settings.customers.detail',
    url: '/:customerId',
    data: {
        title: 'Detail objednatele',
    },
    resolve: {
        customer: ['$transition$', resolveCustomer],
    },
    views: {
        'content@settings': {
            component: customer_detail_component_1.CustomerDetailComponent,
        }
    }
};
exports.customerEditState = {
    name: 'settings.customers.detail.edit',
    data: {
        backButtonText: 'Detail objednatele',
        title: 'Editace objednatele',
    },
    url: '/edit',
    views: {
        'content@settings': {
            component: customer_edit_component_1.CustomerEditComponent,
        }
    },
};
exports.customerContractAbstractState = {
    abstract: true,
    name: 'settings.customers.detail.contract',
    url: '/contract',
};
exports.customerContractState = {
    name: 'settings.customers.detail.contract.new',
    url: '/new',
    data: {
        title: 'Nová smlouva',
    },
    resolve: {
        customerId: ['$stateParams', getCustomerId],
    },
    onEnter: newContractDialog,
};
exports.customerContractDetailState = {
    name: 'settings.customers.detail.contract.detail',
    url: '/:id',
    data: {
        title: 'Detail smlouvy',
    },
    resolve: {
        contract: ['$transition$', '$stateParams', getContract],
    },
    views: {
        'content@settings': {
            component: contract_detail_component_1.ContractDetailComponent,
        }
    },
};
exports.customerContractEditState = {
    name: 'settings.customers.detail.contract.detail.edit',
    url: '/edit',
    data: {
        title: 'Editace smlouvy',
    },
    onEnter: editContractDialog,
};
exports.customerContractProjectState = {
    name: 'settings.customers.detail.contract.detail.project',
    url: '/project',
    data: {
        title: 'Přidat projekt',
    },
    onEnter: newProjectDialog,
};
function resolveCustomer($transition$) {
    return __awaiter(this, void 0, void 0, function () {
        var restangular;
        return __generator(this, function (_a) {
            restangular = $transition$.injector().get('GlobalRestangularConfigProvider');
            return [2 /*return*/, restangular.one('v2/customers', $transition$.params().customerId).get()];
        });
    });
}
exports.resolveCustomer = resolveCustomer;
resolveCustomer.$inject = ['$transition$'];
function newProjectDialog($transition$, $state) {
    var stateService = $transition$.injector().get(angular_1.StateService);
    var dialogService = $transition$.injector().get(dialog_service_1.DialogService);
    var dialog = dialogService.open(project_form_component_1.ProjectFormComponent, {
        data: {
            customerId: $state.params.customerId,
            contractId: $state.params.id,
        },
        className: class_name_enum_1.ClassName.HIGHER_DIALOG,
    });
    var sub = dialog.afterClosed.subscribe(function (res) {
        stateService.go('^', {}, { reload: res === true });
        sub.unsubscribe();
    });
}
exports.newProjectDialog = newProjectDialog;
newProjectDialog.$inject = ['$transition$', '$state'];
function getCustomerId($stateParams) {
    return $stateParams.customerId;
}
exports.getCustomerId = getCustomerId;
function getContract($transition$, $stateParams) {
    var contractService = $transition$.injector().get(contract_service_1.ContractService);
    return contractService.getOne($stateParams.id);
}
exports.getContract = getContract;
function newContractDialog($transition$) {
    var dialogService = $transition$.injector().get(dialog_service_1.DialogService);
    var stateService = $transition$.injector().get(angular_1.StateService);
    var customerId = $transition$.injector().get('customerId');
    var dialog = dialogService.open(contract_form_component_1.ContractFormComponent, {
        data: {
            customerId: customerId,
        },
    });
    var sub = dialog.afterClosed.subscribe(function (res) {
        stateService.go('^.^', {}, { reload: res === true });
        sub.unsubscribe();
    });
}
exports.newContractDialog = newContractDialog;
newContractDialog.$inject = ['$transition$'];
function editContractDialog($transition$) {
    var dialogService = $transition$.injector().get(dialog_service_1.DialogService);
    var stateService = $transition$.injector().get(angular_1.StateService);
    var contract = $transition$.injector().get('contract');
    var dialog = dialogService.open(contract_form_component_1.ContractFormComponent, {
        data: {
            contract: contract,
        },
    });
    var sub = dialog.afterClosed.subscribe(function () {
        stateService.go('^');
        sub.unsubscribe();
    });
}
exports.editContractDialog = editContractDialog;
editContractDialog.$inject = ['$transition$'];
