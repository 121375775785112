"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var enum_utils_1 = require("@app/common/utils/enum.utils");
var payment_options_enum_1 = require("@app/ps/enums/payment-options.enum");
var payment_type_model_1 = require("@app/ps/models/payment-type.model");
var PaymentFormComponent = /** @class */ (function () {
    function PaymentFormComponent() {
        this.withPaymentDetails = true;
        this.nestedForm = false;
    }
    PaymentFormComponent.prototype.ngOnInit = function () {
        this.options = enum_utils_1.EnumUtils.toObject(payment_options_enum_1.PaymentOptionsEnum);
        if (!this.paymentOptions.paymentType) {
            this.paymentOptions.paymentType = 'X';
        }
    };
    PaymentFormComponent.prototype.isBankTransferPayment = function () {
        var bankTransferOption = this.options.find(function (o) { return o.value === payment_options_enum_1.PaymentOptionsEnum.B; });
        return this.paymentOptions.paymentType === bankTransferOption.id;
    };
    return PaymentFormComponent;
}());
exports.PaymentFormComponent = PaymentFormComponent;
