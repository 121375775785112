"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var tooltip_intersection_area_component_1 = require("@app/dimap/components/tooltip-intersection-area/tooltip-intersection-area.component");
var cell_abstract_component_1 = require("@app/common/components/cell-abstract/cell-abstract.component");
var map_property_status_service_1 = require("@app/dimap/map/services/map-property-status.service");
var CellAreaComponent = /** @class */ (function (_super) {
    __extends(CellAreaComponent, _super);
    function CellAreaComponent(propertyStatusService) {
        var _this = _super.call(this) || this;
        _this.propertyStatusService = propertyStatusService;
        // props
        _this.TOOLTIP_MAPPING = {
            'intersection-area': tooltip_intersection_area_component_1.TooltipIntersectionAreaComponent
        };
        return _this;
    }
    CellAreaComponent.prototype.ngOnInit = function () {
        this.propertyStatusClass = this.propertyStatusService.getPropertyStatusWanted(this.item.ownerType, this.item.divisionType, this.item.wanted);
    };
    return CellAreaComponent;
}(cell_abstract_component_1.CellAbstractComponent));
exports.CellAreaComponent = CellAreaComponent;
