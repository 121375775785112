"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var angular_1 = require("@uirouter/angular");
var TitlesCreateCasesFinishComponent = /** @class */ (function () {
    function TitlesCreateCasesFinishComponent(stateService) {
        this.stateService = stateService;
        this.goToBulkOperation = this.goToBulkOperation.bind(this);
    }
    TitlesCreateCasesFinishComponent.prototype.ngOnInit = function () {
    };
    TitlesCreateCasesFinishComponent.prototype.goToBulkOperation = function () {
        this.stateService.go('symap.project.cases.bulkOperation', { entityIds: this.data.confirm.caseIds });
    };
    return TitlesCreateCasesFinishComponent;
}());
exports.TitlesCreateCasesFinishComponent = TitlesCreateCasesFinishComponent;
