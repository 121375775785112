"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var full_municipality_pipe_1 = require("@app/common/pipes/full-municipality.pipe");
var full_street_pipe_1 = require("@app/common/pipes/full-street.pipe");
var i0 = require("@angular/core");
var i1 = require("./full-street.pipe");
var i2 = require("./full-municipality.pipe");
var FullAddressPipe = /** @class */ (function () {
    function FullAddressPipe(fullStreetPipe, fullMunicipalityPipe) {
        this.fullStreetPipe = fullStreetPipe;
        this.fullMunicipalityPipe = fullMunicipalityPipe;
    }
    FullAddressPipe.prototype.transform = function (subject) {
        if (!subject) {
            return '';
        }
        var fullAddress = this.fullStreetPipe.transform(subject);
        if (subject.quarter && subject.quarter !== subject.municipality && subject.street) {
            fullAddress += fullAddress !== '' ? ', ' : '';
            fullAddress += subject.quarter;
        }
        var municipality = this.fullMunicipalityPipe.transform(subject);
        fullAddress += fullAddress !== '' && municipality !== '' ? ', ' : '';
        return fullAddress + municipality;
    };
    FullAddressPipe.ngInjectableDef = i0.defineInjectable({ factory: function FullAddressPipe_Factory() { return new FullAddressPipe(i0.inject(i1.FullStreetPipe), i0.inject(i2.FullMunicipalityPipe)); }, token: FullAddressPipe, providedIn: "root" });
    return FullAddressPipe;
}());
exports.FullAddressPipe = FullAddressPipe;
