"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var angular_1 = require("@uirouter/angular");
var auth_service_1 = require("@app/common/services/auth.service");
var ErrorComponent = /** @class */ (function () {
    function ErrorComponent(applications, authService, stateService) {
        var _this = this;
        this.applications = applications;
        this.authService = authService;
        this.stateService = stateService;
        this.errors = {
            // user is autenticated and has permissions for requested module, but does not have permissions for requested project -> redirect to login
            1: 'Do požadovaného projektu nemáte přidělena oprávnění. Kontaktujte podporu, nebo přejděte do jiného projektu.',
            2: 'Do požadovaného modulu nemáte přidělena oprávnění.',
            3: 'Nejprve se přihlašte.',
            4: 'Nemáte přiřazen žádný projekt. Kontaktujte podporu.',
            5: 'Vyskytla se interní chyba při inicializaci modulu.',
        };
        this.logout = function () {
            return _this.authService.logout();
        };
    }
    ErrorComponent.prototype.getProjects = function () {
        var projects = this.authService.getActualProjects();
        if (!projects || projects.length === 0) {
            this.errorCode = 4;
        }
        return projects;
    };
    ErrorComponent.prototype.getModuleName = function (module) {
        return Object.entries(this.applications).find(function (a) { return a[1].name === module; })[1].title;
    };
    ErrorComponent.prototype.getModules = function () {
        return this.authService.getApplications();
    };
    ErrorComponent.prototype.getActiveModuleBaseState = function () {
        var application = this.authService.getActiveApplication();
        return this.authService.getApplicationBaseState(application);
    };
    ErrorComponent.prototype.getModuleBaseState = function (module) {
        var params = this.authService.getApplicationTransitionParams(module);
        var opts = {};
        if (params.projectKey) {
            opts.projectKey = params.projectKey;
        }
        return {
            module: params.module,
            opts: opts,
        };
    };
    ErrorComponent.prototype.goToProjectFunction = function (projectKey) {
        return this.stateService.go(this.getActiveModuleBaseState(), { projectKey: projectKey });
    };
    ErrorComponent.prototype.goToModuleFunction = function (module) {
        var _this = this;
        return function () {
            var destination = _this.getModuleBaseState(module);
            return _this.stateService.go(destination.module, destination.opts);
        };
    };
    ;
    ErrorComponent.prototype.goToLoginFunction = function (redirect) {
        var _this = this;
        return function () {
            if (redirect) {
                return _this.stateService.go('login', { redirectUrl: _this.redirectUrl });
            }
            else {
                return _this.stateService.go('login');
            }
        };
    };
    return ErrorComponent;
}());
exports.ErrorComponent = ErrorComponent;
