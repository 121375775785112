"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var angular_1 = require("@uirouter/angular");
var list_service_1 = require("./list.service");
var pageable_list_1 = require("@app/models/pageable.list");
var i0 = require("@angular/core");
var i1 = require("./list.service");
var i2 = require("@uirouter/angular");
var PageableListService = /** @class */ (function () {
    function PageableListService(listService, stateService, uiconstants) {
        this.listService = listService;
        this.stateService = stateService;
        this.uiconstants = uiconstants;
    }
    PageableListService.prototype.get = function (url, defaultFilter, localStorageKey, restangularService, attributes) {
        var list = this.listService.createList(url, defaultFilter, restangularService, attributes);
        if (localStorageKey) {
            this.listService.attachFilterStorage(list, localStorageKey);
        }
        return new pageable_list_1.PageableList(list, this.listService, this.stateService, this.uiconstants);
    };
    PageableListService.ngInjectableDef = i0.defineInjectable({ factory: function PageableListService_Factory() { return new PageableListService(i0.inject(i1.ListService), i0.inject(i2.StateService), i0.inject("UICONSTANTS")); }, token: PageableListService, providedIn: "root" });
    return PageableListService;
}());
exports.PageableListService = PageableListService;
