"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".pagination[_ngcontent-%COMP%] {\n  display: flex;\n  justify-content: center;\n  margin-top: 20px;\n  text-align: center; }\n  .pagination[_ngcontent-%COMP%]    > div[_ngcontent-%COMP%] {\n    line-height: 25px;\n    display: inline-flex; }\n  .pagination[_ngcontent-%COMP%]   ul[_ngcontent-%COMP%] {\n    display: inline-block;\n    margin: 0 24px;\n    height: 25px; }\n    .pagination[_ngcontent-%COMP%]   ul[_ngcontent-%COMP%]   li[_ngcontent-%COMP%] {\n      float: left;\n      margin: 0 3px;\n      text-align: center;\n      line-height: 25px;\n      min-width: 30px; }\n      .pagination[_ngcontent-%COMP%]   ul[_ngcontent-%COMP%]   li[_ngcontent-%COMP%]   a[_ngcontent-%COMP%] {\n        display: block;\n        height: 25px;\n        line-height: 25px;\n        cursor: pointer;\n        text-align: center; }\n      .pagination[_ngcontent-%COMP%]   ul[_ngcontent-%COMP%]   li[_ngcontent-%COMP%]   i[_ngcontent-%COMP%] {\n        visibility: hidden;\n        display: block;\n        text-align: center;\n        color: #0096dc;\n        line-height: 5px; }\n      .pagination[_ngcontent-%COMP%]   ul[_ngcontent-%COMP%]   li.active[_ngcontent-%COMP%]   a[_ngcontent-%COMP%] {\n        background-color: #0096dc;\n        border-color: #0096dc;\n        color: #fff;\n        margin-top: 0px; }\n      .pagination[_ngcontent-%COMP%]   ul[_ngcontent-%COMP%]   li.active[_ngcontent-%COMP%]   i[_ngcontent-%COMP%] {\n        visibility: visible; }\n      .pagination[_ngcontent-%COMP%]   ul[_ngcontent-%COMP%]   li.fa-ellipsis-h[_ngcontent-%COMP%] {\n        margin-top: 5px; }"];
exports.styles = styles;
