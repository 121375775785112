"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var checklist_service_1 = require("@app/common/services/checklist.service");
var subject_name_pipe_1 = require("@app/common/pipes/subject-name.pipe");
var _cloneDeep = require("lodash/cloneDeep");
var CreateCaseSelectObjectsComponent = /** @class */ (function () {
    function CreateCaseSelectObjectsComponent(subjectNamePipe, checklistService) {
        this.subjectNamePipe = subjectNamePipe;
        this.checklistService = checklistService;
        this.submitCallback = new core_1.EventEmitter();
        this.loading = false;
        this.setByBusinessCase = false;
        this.submit = this.submit.bind(this);
    }
    CreateCaseSelectObjectsComponent.prototype.ngOnInit = function () {
        this.dataSubject = {};
        this.checklistOwnership = this.checklistService.get();
        this.checklistGroup = this.checklistService.get();
        this.checklistEasement = this.checklistService.get();
        this.checklistBuilding = this.checklistService.get();
        this.checklistConstructionObject = this.checklistService.get();
        this.prepareData();
    };
    CreateCaseSelectObjectsComponent.prototype.prepareData = function () {
        var _this = this;
        if (this.data.fromCase && !this.data.titles) {
            this.setByBusinessCase = true;
        }
        this.data.titles = this.data.titles || [];
        this.loading = true;
        this.generalDataPromise.then(function (dataLoaded) {
            _this.dataOwnerships = dataLoaded.dataOwnerships;
            _this.dataEasements = dataLoaded.dataEasements;
            _this.dataOccupationGroups = dataLoaded.dataOccupationGroupsFiltered;
            _this.dataBuildings = dataLoaded.dataBuildings;
            if (!_this.data.titles.length) {
                _this.data.titles.push(dataLoaded.dataTitle);
            }
            _this.loading = false;
        });
    };
    CreateCaseSelectObjectsComponent.prototype.checkOwnershipsByData = function (data) {
        var _this = this;
        (data.caseOwnerships || []).forEach(function (caseOwnership) {
            // if ownership of another title
            var ownership = _this.dataOwnerships.find(function (item) { return item.id === caseOwnership.ownership.id; });
            if (ownership) {
                _this.selection.toggleSelectionOwnership(ownership);
            }
        });
    };
    CreateCaseSelectObjectsComponent.prototype.onSelectAllOwnerships = function () {
        if (this.selection) {
            this.selection.checkedAllOwnerships.value = !this.selection.checkedAllOwnerships.value;
            this.selection.toggleAll(this.selection.checkedAllOwnerships.value, this.checklistOwnership, this.dataOwnerships, this.selection.toggleSelectionOwnership);
        }
    };
    CreateCaseSelectObjectsComponent.prototype.callbackRegister = function (callbacks) {
        var _this = this;
        this.selection = callbacks.selection;
        this.refreshItems = callbacks.refreshItems;
        this.resetSelection = callbacks.resetSelection;
        this.isSelectionValidLocal = callbacks.isSelectionValidLocal;
        this.selectObjectsCheckObjectsByData = callbacks.checkObjectsByData;
        this.checkOwnershipsByData(this.data);
        if (!this.data.caseOwnerships && (this.data.obligation.type === 'RealBurdenLiability' || this.data.obligation.type === 'RealBurdenLiabilityAccelerated')) {
            this.onSelectAllOwnerships();
        }
        this.selectObjectsCheckObjectsByData(this.data);
        // výběr podle existující smlouvy
        if (this.setByBusinessCase) {
            callbacks.selectByCase();
            var dataTitle_1 = this.data.titles[this.data.titles.length - 1];
            this.data.fromCase.caseOwnerships.forEach(function (caseOwnership) {
                var dataOwnership = _this.dataOwnerships.find(function (dataOwnership) {
                    return dataOwnership.ownership.opsubId === caseOwnership.ownership.opsubId &&
                        caseOwnership.ownership.lv === dataTitle_1.lv &&
                        caseOwnership.ownership.katuzeKod === dataTitle_1.area.id;
                });
                if (dataOwnership) {
                    _this.selection.toggleSelectionOwnership(dataOwnership);
                }
            });
            this.submit();
        }
    };
    CreateCaseSelectObjectsComponent.prototype.isSelectionValid = function () {
        return this.checklistOwnership.checkedItems.length
            && this.isSelectionValidLocal && this.isSelectionValidLocal()
            && (this.data.obligation.type !== 'InnominateSalesContractLiability'
                || (!!this.data.innominationSubject && !!this.data.innominationPrice))
            && ((this.data.obligation.type !== 'RealBurdenLiability' && this.data.obligation.type !== 'RealBurdenLiabilityAccelerated')
                || this.checklistOwnership.checkedItems.length === this.dataOwnerships.length)
            && (this.data.obligation.type !== 'ExchangeContractLiability'
                || (this.parcelsExternal && this.parcelsExternal.length !== 0));
    };
    CreateCaseSelectObjectsComponent.prototype.submit = function () {
        if (this.isSelectionValid()) {
            var data_1 = {};
            data_1.occupations = [];
            data_1.easements = [];
            data_1.buildings = [];
            data_1.constructionObjects = [];
            data_1.caseOwnerships = [];
            data_1.parcelsExternal = this.parcelsExternal;
            this.checklistGroup.checkedItems.forEach(function (group) {
                group.occupations.forEach(function (occupation) {
                    data_1.occupations.push(occupation);
                });
            });
            this.checklistBuilding.checkedItems.forEach(function (building) {
                data_1.buildings.push(building);
            });
            this.checklistEasement.checkedItems.forEach(function (easement) {
                easement = _cloneDeep(easement);
                delete easement.cases;
                data_1.easements.push(easement);
            });
            this.checklistConstructionObject.checkedItems.forEach(function (caseConstructionObject) {
                data_1.constructionObjects.push(caseConstructionObject.constructionObject);
            });
            this.checklistOwnership.checkedItems.forEach(function (caseOwnership) {
                data_1.caseOwnerships.push({ ownership: caseOwnership.ownership });
            });
            Object.assign(this.data, data_1);
        }
        return this.submitCallback.emit();
    };
    CreateCaseSelectObjectsComponent.prototype.onParcelsExternalSelected = function (parcels) {
        this.parcelsExternal = parcels;
    };
    return CreateCaseSelectObjectsComponent;
}());
exports.CreateCaseSelectObjectsComponent = CreateCaseSelectObjectsComponent;
