"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var _ = require("lodash");
var series_service_1 = require("@app/ps/services/series.service");
var construction_object_name_pipe_1 = require("@app/common/pipes/construction-object-name.pipe");
var SeriesComponent = /** @class */ (function () {
    function SeriesComponent(constructionObjectNamePipe, seriesService) {
        this.constructionObjectNamePipe = constructionObjectNamePipe;
        this.seriesService = seriesService;
        this.canceled = new core_1.EventEmitter();
        this.restored = new core_1.EventEmitter();
        this.moved = new core_1.EventEmitter();
        this.changed = new core_1.EventEmitter();
        this.onChange = this.onChange.bind(this);
        this.onCancelSeries = this.onCancelSeries.bind(this);
        this.onRestoreSeries = this.onRestoreSeries.bind(this);
        this.onMoveSeries = this.onMoveSeries.bind(this);
        this.onMoveSection = this.onMoveSection.bind(this);
        this.onToggleSection = this.onToggleSection.bind(this);
    }
    SeriesComponent.prototype.ngOnInit = function () {
    };
    SeriesComponent.prototype.onChange = function () {
        this.changed.emit({ series: this.series });
    };
    SeriesComponent.prototype.onCancelSeries = function (series) {
        this.canceled.emit({ series: series });
    };
    SeriesComponent.prototype.onRestoreSeries = function (series) {
        this.restored.emit({ series: series });
    };
    SeriesComponent.prototype.onMoveSeries = function (series, step) {
        this.moved.emit({ series: series, step: step });
    };
    SeriesComponent.prototype.onMoveSection = function (section, sections, step) {
        var x = _.indexOf(sections, section);
        if (x + step < 0 || x + step >= sections.length) {
            return;
        }
        sections[x] = sections.splice(x + step, 1, sections[x])[0];
    };
    SeriesComponent.prototype.onToggleSection = function (section, sections) {
        if (section.type === 'text') {
            if (section.show) { // remove
                sections.splice(_.indexOf(sections, section), 1);
            }
            else { // add new empty line
                sections.splice(0, 0, {
                    type: 'text'
                });
            }
        }
        section.show = !section.show;
    };
    SeriesComponent.prototype.generateNumber = function () {
        return this.seriesService.generateNumber(this.series);
    };
    return SeriesComponent;
}());
exports.SeriesComponent = SeriesComponent;
