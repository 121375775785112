"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var subject_name_pipe_1 = require("@app/common/pipes/subject-name.pipe");
var checklist_service_1 = require("@app/common/services/checklist.service");
var parcel_service_1 = require("@app/ps/services/parcel.service");
var series_name_pipe_1 = require("@app/ps/pipes/series-name.pipe");
var _flattenDeep = require("lodash/flattenDeep");
var _cloneDeep = require("lodash/cloneDeep");
var _uniq = require("lodash/uniq");
var _remove = require("lodash/remove");
var other_legal_relations_service_1 = require("@app/common/services/other-legal-relations.service");
var case_service_1 = require("@app/ps/services/case.service");
var CreateCaseDetailComponent = /** @class */ (function () {
    function CreateCaseDetailComponent(checklistService, subjectNamePipe, seriesNamePipe, parcelService, otherLegalRelationsService, caseService, restangular, errorHandlerService) {
        this.checklistService = checklistService;
        this.subjectNamePipe = subjectNamePipe;
        this.seriesNamePipe = seriesNamePipe;
        this.parcelService = parcelService;
        this.otherLegalRelationsService = otherLegalRelationsService;
        this.caseService = caseService;
        this.restangular = restangular;
        this.errorHandlerService = errorHandlerService;
        this.submitCallback = new core_1.EventEmitter();
        this.forwardStep = true;
        this.loading = true;
        this.seriesData = [];
        this.easementPriceByShare = this.caseService.easementPriceByShare;
        this.acceptedRestrictions = false;
        this.dataOccupationGroups = [];
        this.dataEasements = [];
        this.dataBuildings = [];
        this.dataConstructionObjects = [];
        this.flatCaseOwnerships = [];
        this.PARCEL_COLS = [
            { id: 'parcel', sortable: false },
            { id: 'title', sortable: false },
            { id: 'area_name', sortable: false },
            { id: 'area', sortable: false },
        ];
        this.confirm = this.confirm.bind(this);
    }
    CreateCaseDetailComponent.prototype.ngOnInit = function () {
        this.data.mpp = this.data.mpp || {};
        this.data.createdDate = this.data.createdDate || new Date();
        this.computePrice();
    };
    CreateCaseDetailComponent.prototype.loadPrice = function () {
        var _this = this;
        if (this.forwardStep && this.data.obligation.computePrice && !this.caseService.easementPriceByShare(this.data.obligation)) {
            var data = _cloneDeep(this.data);
            return this.restangular
                .one('titles')
                .all('case')
                .all('compute-price')
                .customPOST(data)
                .then(function (result) { return Object.assign(_this.data, _this.restangular.stripRestangular(result)); })
                .catch(function (result) {
                _this.submitCallback.emit({
                    error: true
                });
                return Promise.reject(result);
            });
        }
        return Promise.resolve(this.data);
    };
    CreateCaseDetailComponent.prototype.groupOccupationsByParcel = function () {
        var _this = this;
        this.dataOccupationGroups = this.parcelService.groupOccupationsByParcelSingle(this.data.occupations);
        this.dataEasements = this.data.easements.map(function (easement) {
            return {
                easement: easement,
                price: _this.caseService.easementPriceByShare(_this.data.obligation) ? easement.easementPrice : null,
            };
        });
        this.dataBuildings = this.data.buildings.map(function (building) {
            return {
                building: building,
                price: null,
            };
        });
    };
    CreateCaseDetailComponent.prototype.assignOccupationsToConstructionObjects = function () {
        var _this = this;
        this.dataConstructionObjects = this.data.constructionObjects.map(function (constructionObject) {
            return {
                constructionObject: constructionObject,
                easements: _this.data.easements.filter(function (easement) { return easement.constructionObjects.some(function (item) { return item.id === constructionObject.id; }); }),
                occupations: _this.data.occupations.filter(function (occupation) { return occupation.constructionObjects.some(function (item) { return item.id === constructionObject.id; }); }),
            };
        });
    };
    CreateCaseDetailComponent.prototype.filterRestrictionsAndOtherEntries = function () {
        var _this = this;
        this.restrictions = undefined;
        this.otherEntries = undefined;
        if (!this.dataEasements.length && !this.dataOccupationGroups.length && !this.data.buildings.length) {
            return;
        }
        if (!this.dataOtherLegalRelations) {
            var titleIds = this.data.titles.map(function (item) { return item.id; });
            this.dataOtherLegalRelations = this.otherLegalRelationsService.loadOtherLegalRelations(titleIds);
        }
        this.dataOtherLegalRelations.promise.then(function () {
            var _a, _b, _c, _d, _e, _f, _g, _h;
            _this.restrictions = [];
            _this.otherEntries = [];
            var filteredGroups;
            filteredGroups = _this.otherLegalRelationsService.filterByEntityWithParcel(_this.dataOtherLegalRelations.list, _this.dataOccupationGroups, _this.data.titles[0], _this.data.caseOwnerships);
            (_a = _this.restrictions).push.apply(_a, __spread(filteredGroups.restrictions));
            (_b = _this.otherEntries).push.apply(_b, __spread(filteredGroups.otherEntries));
            filteredGroups = _this.otherLegalRelationsService.filterByEntityWithEasement(_this.dataOtherLegalRelations.list, _this.dataEasements, _this.data.titles[0], _this.data.caseOwnerships);
            (_c = _this.restrictions).push.apply(_c, __spread(filteredGroups.restrictions));
            (_d = _this.otherEntries).push.apply(_d, __spread(filteredGroups.otherEntries));
            filteredGroups = _this.otherLegalRelationsService.filterByEntityWithParcels(_this.dataOtherLegalRelations.list, _this.data.buildings, _this.data.titles[0], _this.data.caseOwnerships);
            (_e = _this.restrictions).push.apply(_e, __spread(filteredGroups.restrictions));
            (_f = _this.otherEntries).push.apply(_f, __spread(filteredGroups.otherEntries));
            filteredGroups = _this.otherLegalRelationsService.filterByCaseOwnerships(_this.dataOtherLegalRelations.list, _this.data.caseOwnerships, _this.data.titles[0]);
            (_g = _this.restrictions).push.apply(_g, __spread(filteredGroups.restrictions));
            (_h = _this.otherEntries).push.apply(_h, __spread(filteredGroups.otherEntries));
        });
    };
    CreateCaseDetailComponent.prototype.requiresAcceptance = function () {
        return (this.dataOccupationGroups.length && this.dataOccupationGroups[0].key === 'permanent') || this.data.buildings.length > 0;
    };
    CreateCaseDetailComponent.prototype.createEnabled = function () {
        return !this.loadingSeries && !!this.data.createdDate && (!this.requiresAcceptance() || this.acceptedRestrictions || !this.dataOtherLegalRelations || (!this.dataOtherLegalRelations.loading && this.restrictions.length === 0 && this.otherEntries.length === 0));
    };
    CreateCaseDetailComponent.prototype.easementPriceByShareUpdated = function () {
        var _this = this;
        this.data.caseOwnerships.forEach(function (caseOwnership) {
            _this.priceSummary -= caseOwnership.easementPrice || 0;
            caseOwnership.easementPrice = Number(caseOwnership.easementPriceByShare) || 0;
            _this.priceSummary += caseOwnership.easementPrice;
        });
    };
    CreateCaseDetailComponent.prototype.confirm = function () {
        if (!this.createEnabled()) {
            return;
        }
        return this.submitCallback.emit();
    };
    CreateCaseDetailComponent.prototype.loadSeries = function () {
        var _this = this;
        this.loadingSeries = true;
        var constructionObjectIds = [];
        if (this.data.constructionObjects.length) {
            this.data.constructionObjects.forEach(function (constructionObject) {
                constructionObjectIds.push(constructionObject.id);
            });
        }
        else {
            this.data.easements.forEach(function (easement) {
                easement.constructionObjects.forEach(function (constructionObject) {
                    constructionObjectIds.push(constructionObject.id);
                });
            });
            this.data.occupations.forEach(function (occupation) {
                occupation.constructionObjects.forEach(function (constructionObject) {
                    constructionObjectIds.push(constructionObject.id);
                });
            });
            this.data.buildings.forEach(function (building) {
                building.parcels.forEach(function (parcel) {
                    parcel.constructionObjects.forEach(function (constructionObject) {
                        constructionObjectIds.push(constructionObject.id);
                    });
                });
            });
        }
        this.restangular.all('series').customPOST({ filter: { filters: { constructionObjectId: _uniq(constructionObjectIds) } } }).then(function (seriesData) {
            _this.seriesData = _this.restangular.stripRestangular(seriesData);
            _this.data.series = null;
            if (seriesData[0] && (seriesData.length === 1 || seriesData[0].constructionObject === null)) {
                _this.data.series = _this.seriesData[0];
            }
            _this.loadingSeries = false;
        });
    };
    CreateCaseDetailComponent.prototype.showPrice = function () {
        return this.priceSummary !== null;
    };
    CreateCaseDetailComponent.prototype.acceptRestrictions = function () {
        this.acceptedRestrictions = !this.acceptedRestrictions;
    };
    CreateCaseDetailComponent.prototype.flattenParents = function (caseOwnership) {
        return _flattenDeep(caseOwnership.parentCaseOwnership ? [caseOwnership.parentCaseOwnership, this.flattenParents(caseOwnership.parentCaseOwnership)] : []);
    };
    CreateCaseDetailComponent.prototype.mapCaseOwnerships = function (caseOwnerships) {
        var _this = this;
        return caseOwnerships.map(function (caseOwnership) {
            return {
                caseOwnership: caseOwnership,
                parentCaseOwnerships: _this.flattenParents(caseOwnership)
            };
        });
    };
    CreateCaseDetailComponent.prototype.computePrice = function () {
        var _this = this;
        // remove parents
        _remove(this.data.caseOwnerships, { isParent: true });
        this.loadPrice().then(function () {
            _this.flatCaseOwnerships = _this.mapCaseOwnerships(_this.data.caseOwnerships);
            _this.loading = false;
            _this.loadSeries();
            _this.groupOccupationsByParcel();
            _this.assignOccupationsToConstructionObjects();
            _this.filterRestrictionsAndOtherEntries();
            var prices = ['vegetation', 'land', 'buildings', 'easement', 'compensation'];
            if (_this.caseService.easementPriceByShare(_this.data.obligation)) {
                _this.priceSummary = 0;
                return;
            }
            else {
                _this.priceSummary = null;
            }
            if (!_this.data.obligation.computePrice) {
                return;
            }
            // summarize prices
            _this.data.caseOwnerships.forEach(function (caseOwnership) {
                caseOwnership.price = null;
                caseOwnership.caseOwnershipParcels.forEach(function (caseOwnershipParcel) {
                    var occupationGroup, building, easement;
                    if (caseOwnershipParcel.parcel) {
                        occupationGroup = _this.dataOccupationGroups.find(function (item) {
                            return item.parcel.id === caseOwnershipParcel.parcel.id &&
                                (!caseOwnershipParcel.occupation || item.occupations.find(function (occ) { return occ.id === caseOwnershipParcel.occupation.id; }));
                        });
                        easement = _this.dataEasements.find(function (item) { return item.easement.parcel.id === caseOwnershipParcel.parcel.id &&
                            item.easement.oznacVb === caseOwnershipParcel.easement.oznacVb &&
                            item.easement.geometricPlan.id === caseOwnershipParcel.easement.geometricPlan.id; });
                    }
                    if (caseOwnershipParcel.building) {
                        building = _this.dataBuildings.find(function (item) { return item.building.id === caseOwnershipParcel.building.id; });
                    }
                    prices.forEach(function (price) {
                        if (caseOwnershipParcel[price + 'Price'] !== undefined) {
                            caseOwnership.price = (caseOwnership.price || 0) + caseOwnershipParcel[price + 'Price'];
                            if (occupationGroup) {
                                if (price !== 'compensation') {
                                    occupationGroup.price = (occupationGroup.price || 0) + caseOwnershipParcel[price + 'Price'];
                                }
                                if (caseOwnershipParcel[price + 'ParcelPrice']) {
                                    occupationGroup.parcelPrices = occupationGroup.parcelPrices || [];
                                    if (!occupationGroup.parcelPrices.some(function (item) { return item.id === caseOwnershipParcel[price + 'ParcelPrice'].id; })) {
                                        occupationGroup.parcelPrices.push(caseOwnershipParcel[price + 'ParcelPrice']);
                                    }
                                }
                            }
                            if (easement) {
                                easement.price = (easement.price || 0) + caseOwnershipParcel[price + 'Price'];
                            }
                            if (building) {
                                building.price = (building.price || 0) + caseOwnershipParcel[price + 'Price'];
                                if (caseOwnershipParcel[price + 'ParcelPrice']) {
                                    building.parcelPrices = building.parcelPrices || [];
                                    if (!building.parcelPrices.some(function (item) { return item.id === caseOwnershipParcel[price + 'ParcelPrice'].id; })) {
                                        building.parcelPrices.push(caseOwnershipParcel[price + 'ParcelPrice']);
                                    }
                                }
                            }
                        }
                    });
                });
                if (caseOwnership.price !== null) {
                    _this.priceSummary = (_this.priceSummary || 0) + caseOwnership.price;
                }
            });
            var parcelPricesToString = function (parcelPrices) { return parcelPrices.reduce(function (result, parcelPrice) {
                var priceSource = (parcelPrice.opinion.priceType === 'E' ? parcelPrice.opinion.expertOpinionNumber : parcelPrice.opinion.otherSource);
                if (!priceSource) {
                    return result;
                }
                else {
                    return result += (result ? ', ' : '') + priceSource;
                }
            }, ''); };
            // assign expert opinions
            _this.dataOccupationGroups.forEach(function (occupationGroup) {
                if (occupationGroup.parcelPrices) {
                    occupationGroup.compensationParcelPrices = occupationGroup.parcelPrices.filter(function (item) { return item.opinion.occupationType === 'T'; });
                    occupationGroup.priceSource = parcelPricesToString(occupationGroup.parcelPrices);
                }
            });
            _this.dataBuildings.forEach(function (building) {
                if (building.parcelPrices) {
                    building.priceSource = parcelPricesToString(building.parcelPrices);
                }
            });
        }, new this.errorHandlerService());
    };
    return CreateCaseDetailComponent;
}());
exports.CreateCaseDetailComponent = CreateCaseDetailComponent;
