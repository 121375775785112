"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var _ = require("lodash");
var map_storage_helpers_service_1 = require("@app/map/services/map-storage-helpers.service");
var L = require("leaflet");
var i0 = require("@angular/core");
var i1 = require("./map-storage-helpers.service");
var MapLayersStorageService = /** @class */ (function () {
    function MapLayersStorageService(mapStorageHelpersService) {
        this.mapStorageHelpersService = mapStorageHelpersService;
        this.layers = [];
    }
    /**
     * Adds Leaflet layer to given layers variable. Returns layers variable.
     * @memberof CmLayersStorage
     * @param {type} layers
     * @param {type} leafletLayer
     * @returns {Array} layers
     */
    MapLayersStorageService.prototype.addLayerToLayers = function (layers, leafletLayer) {
        if (!_.isArray(layers)) {
            layers = [];
        }
        layers.push(leafletLayer);
        return layers;
    };
    /**
     * Returns Leaflet layer object with given id.
     * @memberof CmLayersStorage
     * @param {Array} layers
     * @param {type} id
     * @returns {mixed} layer
     */
    MapLayersStorageService.prototype.getLayerById = function (layers, id) {
        var layer;
        if (_.isArray(layers) && id) {
            _.forEach(layers, function (value) {
                if (value._id === id) {
                    layer = value;
                }
                if (value instanceof L.LayerGroup) {
                    value.eachLayer(function (l) {
                        if (l._id === id) {
                            layer = l;
                        }
                    });
                }
            });
        }
        return layer;
    };
    /**
     * Returns deep copy of stored layers.
     * @memberof CmLayersStorage
     * @param {type} mapId
     * @returns {unresolved} promise
     */
    MapLayersStorageService.prototype.getLayers = function (mapId) {
        var defer = this.mapStorageHelpersService.getDefer(this.layers, mapId);
        return defer.promise;
    };
    /**
     * Sets layers to get layers promise.
     * @memberof CmLayersStorage
     * @param {type} c
     * @param {type} id
     */
    MapLayersStorageService.prototype.setLayers = function (c, id) {
        var defer = this.mapStorageHelpersService.getUnresolvedDefer(this.layers, id);
        defer.resolve(c);
        this.mapStorageHelpersService.setResolvedDefer(this.layers, id);
    };
    /**
     * Removes Leaflet layer from given layers variable and returns it.
     * @memberof CmLayersStorage
     * @param {type} layers
     * @param {type} layerId
     * @returns {unresolved} layers
     */
    MapLayersStorageService.prototype.removeLayerFromLayers = function (layers, layerId) {
        if (_.isArray(layers) && layerId) {
            _.forEach(layers, function (value) {
                if (value instanceof L.LayerGroup) {
                    _.remove(value.getLayers(), function (v) {
                        return (v._id && v._id === layerId);
                    });
                }
            });
            _.remove(layers, function (v) {
                return (v._id && v._id === layerId);
            });
        }
        return layers;
    };
    /**
     * Resets layer storage for given map id.
     * @memberof CmLayersStorage
     * @param {type} mapId
     */
    MapLayersStorageService.prototype.resetLayers = function (mapId) {
        this.layers[mapId] = [];
    };
    MapLayersStorageService.ngInjectableDef = i0.defineInjectable({ factory: function MapLayersStorageService_Factory() { return new MapLayersStorageService(i0.inject(i1.MapStorageHelpersService)); }, token: MapLayersStorageService, providedIn: "root" });
    return MapLayersStorageService;
}());
exports.MapLayersStorageService = MapLayersStorageService;
