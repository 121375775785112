"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// @TODO - move to ../common/models...
var Price = /** @class */ (function () {
    function Price() {
    }
    Price.createFromApi = function (data) {
        var price = new Price();
        price.price = (data.price && data.price !== -1 ? data.price : 0);
        price.landCount = (data.parcelCount ? data.parcelCount : 0);
        price.lvCount = (data.lvcount ? data.lvcount : 0);
        return price;
    };
    return Price;
}());
exports.Price = Price;
