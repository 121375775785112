"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var modules_service_1 = require("@app/common/services/modules.service");
var auth_service_1 = require("@app/common/services/auth.service");
var settings_service_1 = require("@app/ps/services/settings.service");
var email_utils_1 = require("@app/common/utils/email.utils");
var i0 = require("@angular/core");
var i1 = require("../../common/services/auth.service");
var i2 = require("./settings.service");
var i3 = require("../../common/services/modules.service");
var EmailService = /** @class */ (function () {
    function EmailService(authService, settingsService, modulesService) {
        this.authService = authService;
        this.settingsService = settingsService;
        this.modulesService = modulesService;
    }
    EmailService.prototype.loadProjectEmails = function () {
        var projectId = this.authService.getActualProject().id;
        return this.settingsService.loadSettings(projectId).then(function (settings) {
            return (settings.email) || null;
        });
    };
    EmailService.prototype.sendUserEmail = function (user, email, convertToHtml) {
        if (convertToHtml === void 0) { convertToHtml = true; }
        if (convertToHtml) {
            email = __assign({}, email, { body: email_utils_1.EmailUtils.textToHtml(email.body, user) });
        }
        return this.modulesService.sendUserEmail(user.id, email);
    };
    EmailService.ngInjectableDef = i0.defineInjectable({ factory: function EmailService_Factory() { return new EmailService(i0.inject(i1.AuthService), i0.inject(i2.SettingsService), i0.inject(i3.ModulesService)); }, token: EmailService, providedIn: "root" });
    return EmailService;
}());
exports.EmailService = EmailService;
