"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var checklist_service_1 = require("@app/common/services/checklist.service");
var series_service_1 = require("@app/ps/services/series.service");
var moment = require("moment");
var administrator_edit_component_1 = require("@app/ps/components/administrator-edit/administrator-edit.component");
var dialog_service_1 = require("@app/dialog/dialog.service");
var word_service_1 = require("@app/common/services/word.service");
var common_1 = require("@angular/common");
var construction_object_imported_model_1 = require("@app/ps/models/construction-object-imported.model");
var project_subject_name_pipe_1 = require("@app/ps/pipes/project-subject-name.pipe");
var angular_1 = require("@uirouter/angular");
var auth_service_1 = require("@app/common/services/auth.service");
var ConstructionObjectCreateComponent = /** @class */ (function () {
    function ConstructionObjectCreateComponent(helpIds, restangular, uploadService, confirmService, projectSubjectNamePipe, authService, stateService, checklistService, seriesService, dialogService, wordService, datePipe) {
        this.helpIds = helpIds;
        this.restangular = restangular;
        this.uploadService = uploadService;
        this.confirmService = confirmService;
        this.projectSubjectNamePipe = projectSubjectNamePipe;
        this.authService = authService;
        this.stateService = stateService;
        this.checklistService = checklistService;
        this.seriesService = seriesService;
        this.dialogService = dialogService;
        this.wordService = wordService;
        this.datePipe = datePipe;
        this.constructionObject = construction_object_imported_model_1.createConstructionObject();
        this.restBaseUrl = this.authService.getActiveApplicationRestUrl();
        this.token = this.authService.getToken();
        this.seriesParsed = [];
        this.filesValid = true;
        this.message = ConstructionObjectCreateComponent.MSG_EMPTY;
        this.cadastreDeliveryMonthPickerOptions = {
            minDate: new Date(),
            maxDate: moment().add(1, 'month').toDate(),
            defaultDate: new Date()
        };
        this.administratorTypes = [
            { id: null, value: 'Neurčen' },
            { id: 'I', value: 'Investor je správcem' },
            { id: 'A', value: 'Jiný subjekt' },
        ];
        this.onUpload = this.onUpload.bind(this);
    }
    ConstructionObjectCreateComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.actingPersons = this.checklistService.get();
        this.restangular.one('construction-objects', 'nextid').get().then(function (data) {
            if (!_this.constructionObject.usekEtapa) {
                _this.constructionObject.usekEtapa = data.nextId;
            }
        });
    };
    ConstructionObjectCreateComponent.prototype.onFilesChange = function (files) {
        this.files = files;
        var extensionValid = this.files.every(function (f) { return f.extension && f.extension.toLowerCase() === 'vfk'; });
        if (this.files.length === 0) {
            this.message = ConstructionObjectCreateComponent.MSG_EMPTY;
        }
        else if (!extensionValid) {
            this.message = ConstructionObjectCreateComponent.MSG_VFK_FILES_EXTENSION;
        }
        else {
            this.message = '';
        }
        this.filesValid = (extensionValid && this.files.length > 0);
    };
    ConstructionObjectCreateComponent.prototype.reloadAdministratorRegister = function (reloadFn) {
        this.reloadAdministrators = reloadFn;
    };
    ConstructionObjectCreateComponent.prototype.onChangeAdministrator = function () {
        this.constructionObject.administratorId = this.administrator ? this.administrator.id : undefined;
    };
    ConstructionObjectCreateComponent.prototype.onChangeAdministratorType = function (type) {
        this.constructionObject.administratorType = type.id;
        this.administrator = undefined;
        this.actingPersons.empty();
    };
    ConstructionObjectCreateComponent.prototype.formValid = function () {
        return this.filesValid
            && this.constructionObject.name
            && this.constructionObject.cislo
            && this.constructionObject.usekEtapa
            && (this.constructionObject.administratorType !== 'A' || (this.constructionObject.administratorType === 'A' && this.constructionObject.administratorId));
    };
    ConstructionObjectCreateComponent.prototype.onCreateAdministrator = function () {
        var _this = this;
        var dialog = this.dialogService.open(administrator_edit_component_1.AdministratorEditComponent, { data: {} });
        var sub = dialog.afterClosed.subscribe(function (res) {
            if (res) {
                _this.administrator = res;
                _this.reloadAdministrators();
            }
            sub.unsubscribe();
        });
    };
    ConstructionObjectCreateComponent.prototype.onUpdateAdministrator = function () {
        var _this = this;
        var dialog = this.dialogService.open(administrator_edit_component_1.AdministratorEditComponent, {
            data: { administratorId: this.administrator.id }
        });
        var sub = dialog.afterClosed.subscribe(function (res) {
            if (res) {
                _this.administrator = res;
            }
            sub.unsubscribe();
        });
    };
    ConstructionObjectCreateComponent.prototype.onCadastreDeliveryDateChange = function (date) {
        this.cadastreDeliveryDate = date;
    };
    ConstructionObjectCreateComponent.prototype.onUpload = function () {
        var _this = this;
        return this.restangular.all('construction-objects').customPOST({ filter: { filters: { number: [this.constructionObject.cislo] } } })
            .then(function (list) {
            if (!list.length) {
                return _this.uploadFormData();
            }
            var w1 = _this.wordService.getTranslation('CONSTRUCTION_OBJECT');
            var w2 = _this.wordService.getTranslation('CONSTRUCTION_OBJECT_OTHER');
            return (new _this.confirmService({
                title: 'Potvrzení',
                message: "Skute\u010Dn\u011B chcete zalo\u017Eit " + w1 + " s \u010D\u00EDslem " + _this.constructionObject.cislo + ", \u010D\u00EDslo je ji\u017E pou\u017Eit\u00E9 u " + w2 + ": " + list[0].sotx
            })).then(function (confirmed) {
                if (confirmed) {
                    return _this.uploadFormData();
                }
            });
        });
    };
    ConstructionObjectCreateComponent.prototype.uploadFormData = function () {
        var _this = this;
        this.constructionObject.validityDate = this.datePipe.transform(this.cadastreDeliveryDate, 'yyyy-MM-dd');
        if (this.seriesParsed) {
            this.seriesService.sectionsToSeries(this.seriesParsed, this.constructionObject.series);
        }
        var formData = {
            CONSTRUCTIONOBJECT: this.uploadService.jsonBlob(this.constructionObject),
            VFK: this.files.map(function (f) { return f.fileEntry; })
        };
        return this.uploadService.upload({
            url: this.restBaseUrl + "/construction-objects/create?type=VFK",
            data: formData,
            arrayKey: '',
            headers: { Authorization: this.token },
        }).then(function (data) {
            if (data.data && data.data.requestId) {
                _this.stateService.go('symap.project.constructionObjects.constructionObjectsImported');
            }
            else {
                return Promise.reject();
            }
        });
    };
    ConstructionObjectCreateComponent.MSG_EMPTY = "Vlo\u017Ete soubory p\u0159eta\u017Een\u00EDm nebo v\u00FDb\u011Brem. Je mo\u017En\u00E9 vlo\u017Eit v\u00EDce soubor\u016F VFK";
    ConstructionObjectCreateComponent.MSG_VFK_FILES_EXTENSION = 'Soubory musí být ve formátu VFK.';
    return ConstructionObjectCreateComponent;
}());
exports.ConstructionObjectCreateComponent = ConstructionObjectCreateComponent;
