"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./representation.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../../../../../common/components/button/button.component.ngfactory");
var i3 = require("../../../../../common/components/button/button.component");
var i4 = require("../../forms/representative-form/representative-form.component.ngfactory");
var i5 = require("@angular/common");
var i6 = require("../../forms/representative-form/representative-form.component");
var i7 = require("./representation.component");
var i8 = require("@uirouter/angular");
var styles_RepresentationComponent = [i0.styles];
var RenderType_RepresentationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RepresentationComponent, data: {} });
exports.RenderType_RepresentationComponent = RenderType_RepresentationComponent;
function View_RepresentationComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "gmt-button", [["class", "red mb-10"], ["slot", "deleteButton"]], null, null, null, i2.View_ButtonComponent_0, i2.RenderType_ButtonComponent)), i1.ɵdid(1, 114688, null, 0, i3.ButtonComponent, ["ErrorHandlerService", i1.ChangeDetectorRef], { clickAction: [0, "clickAction"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 0, "i", [["class", "fa fa-minus"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.removeRepresentative(_v.parent.context.$implicit); _ck(_v, 1, 0, currVal_0); }, null); }
function View_RepresentationComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "gmt-button", [["class", "ml-20"], ["slot", "addButton"]], null, null, null, i2.View_ButtonComponent_0, i2.RenderType_ButtonComponent)), i1.ɵdid(1, 114688, null, 0, i3.ButtonComponent, ["ErrorHandlerService", i1.ChangeDetectorRef], { clickAction: [0, "clickAction"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 0, "i", [["class", "fa fa-plus"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.createAndAddRepresentative; _ck(_v, 1, 0, currVal_0); }, null); }
function View_RepresentationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "representative-form", [], null, [[null, "toggleRoleCadastre"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("toggleRoleCadastre" === en)) {
        var pd_0 = (_co.removeRoleCadastreForAllExcept($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_RepresentativeFormComponent_0, i4.RenderType_RepresentativeFormComponent)), i1.ɵdid(1, 278528, null, 0, i5.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(2, { "mb-60": 0 }), i1.ɵdid(3, 114688, null, 0, i6.RepresentativeFormComponent, [], { allowRoleAssignment: [0, "allowRoleAssignment"], heading: [1, "heading"], representative: [2, "representative"] }, { toggleRoleCadastre: "toggleRoleCadastre" }), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_RepresentationComponent_2)), i1.ɵdid(5, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 2, 1, null, View_RepresentationComponent_3)), i1.ɵdid(7, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, !_v.context.last); _ck(_v, 1, 0, currVal_0); var currVal_1 = true; var currVal_2 = _co.label; var currVal_3 = _v.context.$implicit; _ck(_v, 3, 0, currVal_1, currVal_2, currVal_3); var currVal_4 = _co.shouldShowDeleteButton(); _ck(_v, 5, 0, currVal_4); var currVal_5 = _v.context.last; _ck(_v, 7, 0, currVal_5); }, null); }
function View_RepresentationComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_RepresentationComponent_1)), i1.ɵdid(1, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "centered-text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "gmt-button", [["class", "button-submit"]], null, null, null, i2.View_ButtonComponent_0, i2.RenderType_ButtonComponent)), i1.ɵdid(4, 114688, null, 0, i3.ButtonComponent, ["ErrorHandlerService", i1.ChangeDetectorRef], { clickAction: [0, "clickAction"], gmtDisabled: [1, "gmtDisabled"] }, null), (_l()(), i1.ɵted(-1, 0, ["Ulo\u017Eit"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.representatives; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.onSubmit; var currVal_2 = !_co.hasValidForm(); _ck(_v, 4, 0, currVal_1, currVal_2); }, null); }
exports.View_RepresentationComponent_0 = View_RepresentationComponent_0;
function View_RepresentationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "tab-owner-representation", [], null, null, null, View_RepresentationComponent_0, RenderType_RepresentationComponent)), i1.ɵdid(1, 114688, null, 0, i7.RepresentationComponent, [i8.StateService, "callbacks", "Restangular"], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_RepresentationComponent_Host_0 = View_RepresentationComponent_Host_0;
var RepresentationComponentNgFactory = i1.ɵccf("tab-owner-representation", i7.RepresentationComponent, View_RepresentationComponent_Host_0, { representatives: "representatives", label: "label" }, {}, []);
exports.RepresentationComponentNgFactory = RepresentationComponentNgFactory;
