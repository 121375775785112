"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var MapPropertyStatusService = /** @class */ (function () {
    function MapPropertyStatusService() {
        // owned
        this.statusOwned = 'O';
        // foreign ownership
        this.statusForeign = 'F';
        // joint ownership
        this.statusShared = 'S';
        /**
         * List of all possible property statuses
         * @type {{ownedInside: string, ownedOutside: string, othersInside: string, othersOutside: string}}
         */
        this.propertyStatus = {
            ownedInside: 'property-owned-inside',
            ownedOutside: 'property-owned-outside',
            othersInside: 'property-others-inside',
            othersOutside: 'property-others-outside'
        };
        this.getPropertyStatus = this.getPropertyStatus.bind(this);
        this.getPropertyStatusWanted = this.getPropertyStatusWanted.bind(this);
    }
    /**
     * Returns property status based on ownership type and division type
     * @param ownerType
     * @param divisionType
     * @returns {string}
     */
    MapPropertyStatusService.prototype.getPropertyStatus = function (ownerType, divisionType) {
        if (ownerType === this.statusOwned && divisionType) {
            return this.propertyStatus.ownedInside;
        }
        else if ((ownerType === this.statusOwned || ownerType === this.statusShared)
            && !divisionType) {
            return this.propertyStatus.ownedOutside;
        }
        else if ((ownerType === this.statusShared || ownerType === this.statusForeign)
            && divisionType) {
            return this.propertyStatus.othersInside;
        }
        else if ((ownerType === this.statusShared || ownerType === this.statusForeign)
            && !divisionType) {
            return this.propertyStatus.othersOutside;
        }
        return this.propertyStatus.othersOutside;
    };
    /**
     * Change property status according to resolution whether they want the area or not
     */
    MapPropertyStatusService.prototype.getPropertyStatusWanted = function (ownerType, divisionType, wanted) {
        // cases when property status changes because of wanted flag change
        if (ownerType === this.statusOwned) {
            if (divisionType && wanted === false) {
                return this.propertyStatus.ownedOutside;
            }
            else if (!divisionType && wanted === true) {
                return this.propertyStatus.ownedInside;
            }
        }
        else if (ownerType === this.statusForeign) {
            if (divisionType && wanted === false) {
                return this.propertyStatus.othersOutside;
            }
            else if (!divisionType && wanted === true) {
                return this.propertyStatus.othersInside;
            }
        }
        else if (ownerType === this.statusShared) {
            if (divisionType && wanted === false) {
                return this.propertyStatus.ownedOutside;
            }
            else if (!divisionType && wanted === true) {
                return this.propertyStatus.othersInside;
            }
        }
        // otherwise no change (this applies also in case wanted is not yet set = null)
        return this.getPropertyStatus(ownerType, divisionType);
    };
    MapPropertyStatusService.ngInjectableDef = i0.defineInjectable({ factory: function MapPropertyStatusService_Factory() { return new MapPropertyStatusService(); }, token: MapPropertyStatusService, providedIn: "root" });
    return MapPropertyStatusService;
}());
exports.MapPropertyStatusService = MapPropertyStatusService;
