"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var tooltip_abstract_list_component_1 = require("../tooltip-abstract-list/tooltip-abstract-list.component");
var list_service_1 = require("@app/common/services/list.service");
var TooltipDimapParcelsComponent = /** @class */ (function (_super) {
    __extends(TooltipDimapParcelsComponent, _super);
    function TooltipDimapParcelsComponent(cdr, listLoadService, tooltipConfig) {
        var _this = _super.call(this, cdr, listLoadService, tooltipConfig) || this;
        _this.resource = 'parcels/list';
        _this.loadNext = _this.loadNext.bind(_this);
        return _this;
    }
    TooltipDimapParcelsComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
    };
    TooltipDimapParcelsComponent.prototype.loadNext = function () {
        var _this = this;
        this.listLoadService.fetchResult(this.data.list, true).then(function () {
            _this.cdr.detectChanges();
            _this.tooltipConfig.checkPosition();
        });
    };
    return TooltipDimapParcelsComponent;
}(tooltip_abstract_list_component_1.TooltipAbstractListComponent));
exports.TooltipDimapParcelsComponent = TooltipDimapParcelsComponent;
