"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var brand_service_1 = require("@app/common/services/brand.service");
var BrandVisibilityDirective = /** @class */ (function () {
    function BrandVisibilityDirective(elementRef, brandService) {
        this.elementRef = elementRef;
        this.brandService = brandService;
    }
    BrandVisibilityDirective.prototype.ngOnInit = function () {
        if (this.visibleByBrand && !this.brandService.isVisible(this.visibleByBrand)) {
            this.elementRef.nativeElement.style.display = 'none';
        }
    };
    return BrandVisibilityDirective;
}());
exports.BrandVisibilityDirective = BrandVisibilityDirective;
