/**
 * Service extending ModalService
 */
const service = ['ModalService',function (ModalService) {
  return function (config = {}) {
    const configModal = {
      template: '<approve-terms module="module" user-id="userId" close-modal="closeModal"></approve-terms>',
      allowClose: false,
      scope: config,
    };
    return new ModalService(configModal);
  };
}];

module.exports = service;
