"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var document_type_category_enum_1 = require("@app/sv/enums/document-type-category.enum");
var i0 = require("@angular/core");
var i1 = require("../sv.modules");
var DocumentTypeCategoryNamePipe = /** @class */ (function () {
    function DocumentTypeCategoryNamePipe() {
    }
    DocumentTypeCategoryNamePipe.prototype.transform = function (value) {
        var option = document_type_category_enum_1.documentTypeCategoryOptions.find(function (option) { return option.id === value; });
        return option.name;
    };
    DocumentTypeCategoryNamePipe.ngInjectableDef = i0.defineInjectable({ factory: function DocumentTypeCategoryNamePipe_Factory() { return new DocumentTypeCategoryNamePipe(); }, token: DocumentTypeCategoryNamePipe, providedIn: i1.SvAppModule });
    return DocumentTypeCategoryNamePipe;
}());
exports.DocumentTypeCategoryNamePipe = DocumentTypeCategoryNamePipe;
