"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var map_callback_enum_1 = require("@app/map/enums/map-callback.enum");
var map_highlight_service_1 = require("@app/map/services/map-highlight.service");
var map_layer_selector_service_1 = require("@app/map/services/map-layer-selector.service");
var list_service_1 = require("@app/common/services/list.service");
var MapSearchComponent = /** @class */ (function () {
    function MapSearchComponent(mapLayerSelectorService, mapHighlightService, listService) {
        this.mapLayerSelectorService = mapLayerSelectorService;
        this.mapHighlightService = mapHighlightService;
        this.listService = listService;
        this.search = {
            text: '',
            resource: null,
            list: null,
            templateUrl: null,
            formatItem: null,
            itemFilter: null,
        };
    }
    MapSearchComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                // Remove on initialization if set from past
                this.mapHighlightService.removeHighlight(this.mapId);
                this.searchOld = Object.assign({}, this.search);
                return [2 /*return*/];
            });
        });
    };
    MapSearchComponent.prototype.searchChange = function (resource) {
        var _this = this;
        if (resource === void 0) { resource = undefined; }
        if (resource !== undefined) {
            this.search.resource = resource;
        }
        if (this.search.resource && this.search.text !== '') {
            var found = void 0, attributes = void 0, filter = void 0;
            if (this.search.resource !== this.searchOld.resource || !this.search.list) {
                found = this.getActiveSearchConfig();
                attributes = { geometry: {} };
                filter = found.filter || {};
                if (found.attributes) {
                    Object.assign(attributes, found.attributes);
                }
                this.search.formatItem = found.formatItem ||
                    (found.pipe ?
                        found.pipe.transform :
                        function (item) {
                            return item.name;
                        });
                this.search.list = this.listService.createList(this.search.resource, filter, undefined, attributes);
                this.search.itemFilter = found.itemFilter;
            }
            this.search.list.filter.offset = 0;
            this.search.list.filter.filters.searchText = {
                values: [{ id: this.search.text }]
            };
            this.listService.fetchResult(this.search.list, false).then(function () {
                window.GoogleAnalytics('send', {
                    hitType: 'event',
                    eventCategory: 'mapModule',
                    eventAction: "item searched " + _this.getActiveSearchConfig().name,
                    eventLabel: 'search',
                });
            });
        }
        else if (this.search.list) {
            this.listService.cancelFetch(this.search.list);
            this.search.list = null;
        }
        this.searchOld = Object.assign({}, this.search);
    };
    MapSearchComponent.prototype.getActiveSearchConfig = function () {
        var _this = this;
        return this.searchResources.find(function (r) { return r.id === _this.search.resource; });
    };
    MapSearchComponent.prototype.loadNext = function () {
        if (!this.search.list.loading) {
            this.search.list.filter.offset += this.search.list.filter.limit;
            this.listService.fetchResult(this.search.list, true);
        }
    };
    MapSearchComponent.prototype.highlightExists = function () {
        return this.mapHighlightService.highlightExists(this.mapId);
    };
    MapSearchComponent.prototype.removeHighlight = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.mapHighlightService.removeHighlight(this.mapId);
                return [2 /*return*/];
            });
        });
    };
    MapSearchComponent.prototype.highlightFeature = function (geoJsonString) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.mapHighlightService.highlightFeature(this.mapId, geoJsonString);
                return [2 /*return*/];
            });
        });
    };
    MapSearchComponent.prototype.onMouseEnterOption = function (event, option) {
        if (!option.icon) {
            return;
        }
        event.option = option;
        this.mapLayerSelectorService.fireCallback(map_callback_enum_1.MapCallbackEnum.searchMouseEnter, event);
    };
    MapSearchComponent.prototype.onMouseLeaveOption = function (event) {
        this.mapLayerSelectorService.fireCallback(map_callback_enum_1.MapCallbackEnum.searchMouseLeave, event);
    };
    return MapSearchComponent;
}());
exports.MapSearchComponent = MapSearchComponent;
