"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./ticket-create.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../../../common/components/select/select.component.ngfactory");
var i3 = require("../../../common/components/select/select.component");
var i4 = require("@uirouter/angular");
var i5 = require("@angular/common");
var i6 = require("@angular/forms");
var i7 = require("../../../common/components/fileupload/fileupload.component.ngfactory");
var i8 = require("../../../common/components/fileupload/fileupload.component");
var i9 = require("../../../common/components/button/button.component.ngfactory");
var i10 = require("../../../common/components/button/button.component");
var i11 = require("@uirouter/angular/lib/directives/uiSref");
var i12 = require("@uirouter/core");
var i13 = require("../../../common/directives/loading.directive");
var i14 = require("./ticket-create.component");
var i15 = require("../../services/ticket.service");
var i16 = require("../../pipes/document-type-fullname.pipe");
var i17 = require("../../../common/pipes/organizational-unit-name.pipe");
var i18 = require("../../services/sample.service");
var i19 = require("../../../common/services/auth.service");
var styles_TicketCreateComponent = [i0.styles];
var RenderType_TicketCreateComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TicketCreateComponent, data: {} });
exports.RenderType_TicketCreateComponent = RenderType_TicketCreateComponent;
function View_TicketCreateComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [["class", "jumbotron"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Po\u017Eadavek na aktualizaci st\u00E1vaj\u00EDc\u00EDho vzoru zadejte p\u0159es ikonu Aktualizovat v p\u0159ehledu vzor\u016F, nebo ve slo\u017Ece vzoru. Touto \u00FAlohou se zad\u00E1v\u00E1 po\u017Eadavek na zaveden\u00ED vzoru, kter\u00FD v MPV nen\u00ED. "]))], null, null); }
function View_TicketCreateComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "fieldset", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "column-100"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "label", [["class", "required"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Typ dokumentu"])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "gmt-select", [["field", "documentType"], ["required", "true"]], null, [[null, "changed"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("changed" === en)) {
        var pd_0 = (_co.checkExistingSample() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_SelectComponent_0, i2.RenderType_SelectComponent)), i1.ɵdid(5, 114688, null, 0, i3.SelectComponent, ["ListLoadService", "WindowDimensionService", i1.ChangeDetectorRef, i1.ElementRef, "Restangular", i4.StateService], { data: [0, "data"], field: [1, "field"], itemPipe: [2, "itemPipe"], optionItems: [3, "optionItems"], required: [4, "required"] }, { changed: "changed" }), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["class", "column-100"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "label", [["class", "required"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Organiza\u010Dn\u00ED jednotka"])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "gmt-select", [["required", "true"], ["resource", "organizational-units"]], null, [[null, "changed"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("changed" === en)) {
        _co.onOrganizationalUnitSelected($event);
        var pd_0 = (_co.checkExistingSample() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_SelectComponent_0, i2.RenderType_SelectComponent)), i1.ɵdid(10, 114688, null, 0, i3.SelectComponent, ["ListLoadService", "WindowDimensionService", i1.ChangeDetectorRef, i1.ElementRef, "Restangular", i4.StateService], { filter: [0, "filter"], itemPipe: [1, "itemPipe"], required: [2, "required"], resource: [3, "resource"], restangularService: [4, "restangularService"] }, { changed: "changed" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.ticket; var currVal_1 = "documentType"; var currVal_2 = _co.documentTypeFullnamePipe; var currVal_3 = _co.documentTypes; var currVal_4 = "true"; _ck(_v, 5, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_5 = _co.organizationalUnitFilter; var currVal_6 = _co.organizationalUnitNamePipe; var currVal_7 = "true"; var currVal_8 = "organizational-units"; var currVal_9 = _co.configRestangular; _ck(_v, 10, 0, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); }, null); }
function View_TicketCreateComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "mt-20 text-center bordered error-message p-20"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Ji\u017E existuje vzor, kter\u00FD je shodn\u00E9ho typu. Ov\u011B\u0159te, zda je opravdu nutn\u00E9 zakl\u00E1dat nov\u00FD vzor a nen\u00ED vhodn\u011Bj\u0161\u00ED aktualizovat existuj\u00EDc\u00ED vzor. Pokud je nutn\u00FD nov\u00FD vzor, pokra\u010Dujte d\u00E1le v zalo\u017Een\u00ED po\u017Eadavku. "]))], null, null); }
function View_TicketCreateComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 29, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 26, "div", [["class", "row mt-30"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 25, "div", [["class", "col-12 offset-lg-3 col-lg-6"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TicketCreateComponent_2)), i1.ɵdid(6, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TicketCreateComponent_3)), i1.ɵdid(8, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TicketCreateComponent_4)), i1.ɵdid(10, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 9, "fieldset", [], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "legend", [["class", "required"]], null, null, null, null, null)), (_l()(), i1.ɵted(13, null, ["Pozn\u00E1mka / d\u016Fvody ", ""])), (_l()(), i1.ɵeld(14, 0, null, null, 6, "div", [["class", "column-100 mt-10"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 5, "textarea", [], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 16)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 16).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 16)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 16)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.ticket.description = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(16, 16384, null, 0, i6.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i6.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i6.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i6.DefaultValueAccessor]), i1.ɵdid(18, 671744, null, 0, i6.NgModel, [[8, null], [8, null], [8, null], [6, i6.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i6.NgControl, null, [i6.NgModel]), i1.ɵdid(20, 16384, null, 0, i6.NgControlStatus, [[4, i6.NgControl]], null, null), (_l()(), i1.ɵeld(21, 0, null, null, 4, "fieldset", [], null, null, null, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 1, "legend", [["class", "required"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Vzor"])), (_l()(), i1.ɵeld(24, 0, null, null, 1, "fileupload", [["class", "blue"], ["inputId", "fileHidden"]], null, [[null, "change"], [null, "dragover"], [null, "drop"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("dragover" === en)) {
        var pd_0 = (i1.ɵnov(_v, 25).dragOver($event) !== false);
        ad = (pd_0 && ad);
    } if (("drop" === en)) {
        var pd_1 = (i1.ɵnov(_v, 25).dropFiles($event) !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (i1.ɵnov(_v, 25).openFileUpload() !== false);
        ad = (pd_2 && ad);
    } if (("change" === en)) {
        var pd_3 = (_co.onFilesChange($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i7.View_FileUploadComponent_0, i7.RenderType_FileUploadComponent)), i1.ɵdid(25, 114688, null, 0, i8.FileUploadComponent, [i1.ElementRef], { message: [0, "message"], inputId: [1, "inputId"] }, { onchange: "change" }), (_l()(), i1.ɵeld(26, 0, null, null, 3, "div", [["class", "mt-20 text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(27, 0, null, null, 2, "gmt-button", [], null, null, null, i9.View_ButtonComponent_0, i9.RenderType_ButtonComponent)), i1.ɵdid(28, 114688, null, 0, i10.ButtonComponent, ["ErrorHandlerService", i1.ChangeDetectorRef], { clickAction: [0, "clickAction"], gmtDisabled: [1, "gmtDisabled"] }, null), (_l()(), i1.ɵted(-1, 0, ["Zalo\u017Eit po\u017Eadavek"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = !_co.sample; _ck(_v, 6, 0, currVal_1); var currVal_2 = !_co.sample; _ck(_v, 8, 0, currVal_2); var currVal_3 = _co.existingSample; _ck(_v, 10, 0, currVal_3); var currVal_12 = _co.ticket.description; _ck(_v, 18, 0, currVal_12); var currVal_13 = _co.filesMsg; var currVal_14 = "fileHidden"; _ck(_v, 25, 0, currVal_13, currVal_14); var currVal_15 = _co.onSubmit; var currVal_16 = !_co.isValid(); _ck(_v, 28, 0, currVal_15, currVal_16); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.sample ? "Po\u017Eadavek na aktualizaci vzoru" : "Po\u017Eadavek na zaveden\u00ED nov\u00E9ho vzoru do MPV"); _ck(_v, 2, 0, currVal_0); var currVal_4 = (_co.sample ? "aktualizace" : "zaveden\u00ED nov\u00E9ho vzoru"); _ck(_v, 13, 0, currVal_4); var currVal_5 = i1.ɵnov(_v, 20).ngClassUntouched; var currVal_6 = i1.ɵnov(_v, 20).ngClassTouched; var currVal_7 = i1.ɵnov(_v, 20).ngClassPristine; var currVal_8 = i1.ɵnov(_v, 20).ngClassDirty; var currVal_9 = i1.ɵnov(_v, 20).ngClassValid; var currVal_10 = i1.ɵnov(_v, 20).ngClassInvalid; var currVal_11 = i1.ɵnov(_v, 20).ngClassPending; _ck(_v, 15, 0, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11); }); }
function View_TicketCreateComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "detail"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "a", [["class", "button mb-10"], ["uiSref", "sv.samples"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).go($event.button, $event.ctrlKey, $event.metaKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 16384, null, 0, i11.AnchorUISref, [i1.ElementRef, i1.Renderer2], null, null), i1.ɵdid(4, 737280, null, 0, i11.UISref, [i12.UIRouter, [2, i11.AnchorUISref], "UIView.PARENT_INJECT"], { state: [0, "state"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 0, "i", [["class", "fa fa-chevron-left mr-5"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["P\u0159ehled vzor\u016F"])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [], [[8, "className", 0]], null, null, null, null)), i1.ɵdid(8, 16384, null, 0, i13.LoadingDirective, [i1.ElementRef], { loading: [0, "loading"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TicketCreateComponent_1)), i1.ɵdid(10, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "sv.samples"; _ck(_v, 4, 0, currVal_0); var currVal_2 = _co.loading; _ck(_v, 8, 0, currVal_2); var currVal_3 = !_co.loading; _ck(_v, 10, 0, currVal_3); }, function (_ck, _v) { var currVal_1 = i1.ɵnov(_v, 8).elementClass; _ck(_v, 7, 0, currVal_1); }); }
exports.View_TicketCreateComponent_0 = View_TicketCreateComponent_0;
function View_TicketCreateComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ticket-create", [], null, null, null, View_TicketCreateComponent_0, RenderType_TicketCreateComponent)), i1.ɵdid(1, 114688, null, 0, i14.TicketCreateComponent, ["Restangular", "GlobalRestangularConfigProvider", i15.TicketService, i16.DocumentTypeFullnamePipe, i17.OrganizationalUnitNamePipe, i4.StateService, i18.SampleService, i19.AuthService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_TicketCreateComponent_Host_0 = View_TicketCreateComponent_Host_0;
var TicketCreateComponentNgFactory = i1.ɵccf("ticket-create", i14.TicketCreateComponent, View_TicketCreateComponent_Host_0, { sampleId: "sampleId" }, {}, []);
exports.TicketCreateComponentNgFactory = TicketCreateComponentNgFactory;
