export default (owner_type, wanted, key) => {
  switch(owner_type) {
    case 'O':
      return wanted || (wanted !== false && key) ? 'property-owned-inside' : 'property-owned-outside';
    case 'F':
      return wanted || (wanted !== false && key) ? 'property-others-inside' : 'property-others-outside';
    case 'S':
      return wanted || (wanted !== false && key) ? 'property-others-inside' : 'property-owned-outside';
  }
}
