"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var vfze_import_step_component_1 = require("@app/ps/vfze/components/vfze-import-step/vfze-import-step.component");
var vfze_utils_1 = require("@app/common/utils/vfze.utils");
var file_saver_1 = require("file-saver");
var VfzeImportValidationComponent = /** @class */ (function (_super) {
    __extends(VfzeImportValidationComponent, _super);
    function VfzeImportValidationComponent(restangular, uploadService, errorHandlerService) {
        var _this = _super.call(this) || this;
        _this.restangular = restangular;
        _this.uploadService = uploadService;
        _this.errorHandlerService = errorHandlerService;
        _this.files = [];
        _this.isFileValidToUpload = false;
        _this.authToken = '';
        _this.xmlErrorsDisplayed = false;
        _this.vfzeFileContent = '';
        _this.onSaveValidationResponse = _this.onSaveValidationResponse.bind(_this);
        _this.onSubmit = _this.onSubmit.bind(_this);
        return _this;
    }
    VfzeImportValidationComponent.prototype.ngOnInit = function () {
        this.onUpload();
    };
    VfzeImportValidationComponent.prototype.onUpload = function () {
        var _this = this;
        this.validationResponse = undefined;
        this.xmlErrorsDisplayed = false;
        return this.uploadService.upload({
            url: this.restangular.configuration.baseUrl + '/vfze/validate',
            data: { vfze: this.data.files[0].fileEntry },
            headers: __assign({}, this.restangular.defaultHeaders)
        }).then(function (response) {
            _this.validationResponse = response.data.validationResult;
            _this.isFileValidToUpload = false;
            _this.data.importVfzeId = response.data.importVfzeId;
            if (_this.data.files[0] && _this.containResponseErrors()) {
                var reader_1 = new FileReader();
                reader_1.addEventListener('load', function (event) {
                    _this.vfzeFileContent = reader_1.result;
                });
                reader_1.readAsText(_this.data.files[0].fileEntry);
            }
        }, function (r) {
            new _this.errorHandlerService()(r);
            _this.backCallback.emit();
        });
    };
    VfzeImportValidationComponent.prototype.onSaveValidationResponse = function () {
        var blob = new Blob([vfze_utils_1.VfzeUtils.validationResponseToText(this.validationResponse)], { type: 'text/plain;charset=utf-8' });
        file_saver_1.saveAs(blob, 'validace-vfze.txt');
    };
    VfzeImportValidationComponent.prototype.isResponseSuccessfully = function () {
        if (!this.validationResponse) {
            return false;
        }
        var _a = this.validationResponse, xsdValidationPassed = _a.xsdValidationPassed, modelDeserializationPassed = _a.modelDeserializationPassed, errors = _a.errors, fatal = _a.fatal;
        return !!(errors.length === 0
            && fatal.length === 0);
    };
    VfzeImportValidationComponent.prototype.containResponseErrors = function () {
        if (!this.validationResponse) {
            return false;
        }
        var _a = this.validationResponse, errors = _a.errors, fatal = _a.fatal;
        return (!!errors.filter(function (p) { return p.xmlStructureError; }).length || !!fatal.filter(function (p) { return p.xmlStructureError; }).length);
    };
    VfzeImportValidationComponent.prototype.onSubmit = function () {
        this.submitCallback.emit();
    };
    return VfzeImportValidationComponent;
}(vfze_import_step_component_1.VfzeImportStepComponent));
exports.VfzeImportValidationComponent = VfzeImportValidationComponent;
