"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var title_name_pipe_1 = require("@app/common/pipes/title-name.pipe");
var username_pipe_1 = require("@app/common/pipes/username.pipe");
var auth_service_1 = require("@app/common/services/auth.service");
var pageable_list_service_1 = require("@app/common/services/pageable.list.service");
var class_name_enum_1 = require("@app/dialog/class-name.enum");
var dialog_service_1 = require("@app/dialog/dialog.service");
var kn_wsdp_status_modal_component_1 = require("@app/ps/project-settings/components/kn-wsdp-status-modal/kn-wsdp-status-modal.component");
var KnWsdpUpdateComponent = /** @class */ (function () {
    function KnWsdpUpdateComponent(restangular, restangularSettings, restangularPromapProxy, uiconstants, pageableListService, titleNamePipe, userNamePipe, authService, dialogService) {
        this.restangular = restangular;
        this.restangularSettings = restangularSettings;
        this.restangularPromapProxy = restangularPromapProxy;
        this.uiconstants = uiconstants;
        this.pageableListService = pageableListService;
        this.titleNamePipe = titleNamePipe;
        this.userNamePipe = userNamePipe;
        this.authService = authService;
        this.dialogService = dialogService;
        this.project = this.authService.getActualProject();
        this.titles = [];
        this.gpTitles = [];
        this.newTitles = '';
        this.ticketNumber = 'INC-';
        this.requestType = 'LV';
        this.gpTitleOptions = [];
        this.requestTypes = [
            {
                id: 'LV',
                value: 'LV',
            },
            {
                id: 'GP',
                value: 'GP',
            },
        ];
        this.titleFilter = {
            validity: { values: ['valid'] },
            areaId: { values: [] },
        };
        this.geometricPlanRequestFilter = {
            areaId: { values: [] },
            loadCollections: { values: ['parcels'] }
        };
        this.onCadastreChanged = this.onCadastreChanged.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }
    KnWsdpUpdateComponent.prototype.ngOnInit = function () {
        this.initializeList();
    };
    KnWsdpUpdateComponent.prototype.onSwitchType = function () {
        this.clearContent();
    };
    KnWsdpUpdateComponent.prototype.onCadastreChanged = function () {
        this.titleFilter.areaId.values = [this.area.id];
        this.geometricPlanRequestFilter.areaId.values = [this.area.id];
        this.clearContent();
    };
    KnWsdpUpdateComponent.prototype.onGpChange = function (gp) {
        this.gpTitleOptions = gp.parcels.map(function (p) { return { id: p.lv, name: p.lv }; });
        this.gpTitles = gp.parcels.map(function (p) { return { id: p.lv, name: p.lv }; });
    };
    KnWsdpUpdateComponent.prototype.isLvValid = function () {
        var newTitlesValid = this.newTitles && this.newTitles.split(',').every(function (t) {
            var candidate = t.trim();
            return parseInt(candidate) + '' === candidate;
        });
        var ticketNumberValid = this.ticketNumber && this.ticketNumber.replace('INC-', '').length > 0;
        return this.area && (newTitlesValid || this.titles.length || this.gpTitles.length) && ticketNumberValid && this.user;
    };
    KnWsdpUpdateComponent.prototype.onSubmit = function () {
        var _this = this;
        var titles = [];
        if (this.titles) {
            titles = titles.concat(this.titles.map(function (t) { return t.lv; }));
        }
        if (this.newTitles) {
            titles = titles.concat(this.newTitles.split(',')
                .filter(function (t) {
                var candidate = t.trim();
                return parseInt(candidate) + '' === candidate;
            })
                .map(function (t) { return parseInt(t.trim()); }));
        }
        if (this.gpTitles) {
            titles = titles.concat(this.gpTitles.map(function (p) { return p.id; }));
        }
        var request = {
            type: this.requestType,
            projectKey: this.project.key,
            isprofond: this.project.isprofond,
            ticketNumber: this.ticketNumber,
            areaCode: this.area.id,
            areaName: this.area.name,
            lvs: titles.map(function (lv) { return { lv: lv, areaCode: _this.area.id }; }),
            userIdText: this.user.idText,
            userFullName: this.user.fullName,
        };
        return this.restangularPromapProxy.all('wsdp/requests/create').customPOST(request)
            .then(function () {
            _this.area = undefined;
            _this.user = undefined;
            _this.ticketNumber = 'INC-';
            _this.clearContent();
            _this.pageableList.load();
        });
    };
    KnWsdpUpdateComponent.prototype.pageChanged = function (pageNumber) {
        this.pageableList.fetchResults(pageNumber);
        this.pageableList.onListChanged();
    };
    KnWsdpUpdateComponent.prototype.formatTitles = function (lvs) {
        return lvs.map(function (lv) { return lv.lv; }).join(', ');
    };
    KnWsdpUpdateComponent.prototype.translateStatus = function (status) {
        var t = {
            CREATED: 'Vytvořený požadavek',
            LOADING_WSDP: 'Získávání dat WSDP',
            VALIDATION: 'Validace',
            IMPORT: 'Probíhá import dat do MPV',
            DONE: 'Požadavek byl zpracován',
            FAILED: 'Požadavek skončil s chybou',
        };
        return t[status] ? t[status] : status;
    };
    KnWsdpUpdateComponent.prototype.showStatusDescription = function (request) {
        var dialog = this.dialogService.open(kn_wsdp_status_modal_component_1.KnWsdpStatusModalComponent, {
            data: {
                request: request,
            },
            className: class_name_enum_1.ClassName.HIGHER_DIALOG,
        });
        //
        // const sub = dialog.afterClosed.subscribe(async (text: string) => {
        //
        //   sub.unsubscribe();
        // });
    };
    KnWsdpUpdateComponent.prototype.initializeList = function () {
        var _this = this;
        this.pageableList = this.pageableListService.get('wsdp/requests', {
            filters: {
                projectKey: { values: [this.project.key] }
            }
        }, undefined, this.restangularPromapProxy);
        this.list = this.pageableList.list;
        setInterval(function () {
            _this.pageableList.fetchResults();
        }, 20000);
        return this.pageableList.load();
    };
    KnWsdpUpdateComponent.prototype.clearContent = function () {
        this.titles = [];
        this.newTitles = '';
        this.geometricPlanRequest = undefined;
        this.gpTitles = [];
        this.gpTitleOptions = [];
    };
    return KnWsdpUpdateComponent;
}());
exports.KnWsdpUpdateComponent = KnWsdpUpdateComponent;
