"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./bulk-operation-select-action.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../../../common/components/radio/radio.component.ngfactory");
var i3 = require("../../../common/components/radio/radio.component");
var i4 = require("@angular/common");
var i5 = require("./bulk-operation-select-action.component");
var i6 = require("../../../common/services/auth.service");
var i7 = require("../../services/settings.service");
var styles_BulkOperationSelectActionComponent = [i0.styles];
var RenderType_BulkOperationSelectActionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BulkOperationSelectActionComponent, data: {} });
exports.RenderType_BulkOperationSelectActionComponent = RenderType_BulkOperationSelectActionComponent;
function View_BulkOperationSelectActionComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "checklist-item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setSelectedAction(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "gmt-radio", [], null, null, null, i2.View_RadioComponent_0, i2.RenderType_RadioComponent)), i1.ɵdid(2, 114688, null, 0, i3.RadioComponent, [], { gmtChecked: [0, "gmtChecked"] }, null), (_l()(), i1.ɵted(3, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isChecked(_v.context.$implicit); _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = _v.context.$implicit.name; _ck(_v, 3, 0, currVal_1); }); }
function View_BulkOperationSelectActionComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "fieldset", [["class", "no-legend"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "column-100 checklist"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BulkOperationSelectActionComponent_1)), i1.ɵdid(3, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.actions; _ck(_v, 3, 0, currVal_0); }, null); }
exports.View_BulkOperationSelectActionComponent_0 = View_BulkOperationSelectActionComponent_0;
function View_BulkOperationSelectActionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_BulkOperationSelectActionComponent_0, RenderType_BulkOperationSelectActionComponent)), i1.ɵdid(1, 114688, null, 0, i5.BulkOperationSelectActionComponent, [i6.AuthService, i7.SettingsService, "processDefinition"], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_BulkOperationSelectActionComponent_Host_0 = View_BulkOperationSelectActionComponent_Host_0;
var BulkOperationSelectActionComponentNgFactory = i1.ɵccf("ng-component", i5.BulkOperationSelectActionComponent, View_BulkOperationSelectActionComponent_Host_0, {}, {}, []);
exports.BulkOperationSelectActionComponentNgFactory = BulkOperationSelectActionComponentNgFactory;
