"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var ListService = /** @class */ (function () {
    function ListService(listLoadService, restangular) {
        this.listLoadService = listLoadService;
        this.restangular = restangular;
    }
    ListService.prototype.createList = function (path, defaultFilter, restangularService, attributes) {
        if (restangularService === void 0) { restangularService = this.restangular; }
        if (attributes === void 0) { attributes = {}; }
        return this.listLoadService.createList(path, defaultFilter, restangularService, attributes);
    };
    ListService.prototype.attachFilterStorage = function (list, filterLocalStorageName, executeAfter) {
        if (executeAfter === void 0) { executeAfter = null; }
        return this.listLoadService.attachFilterStorage(list, filterLocalStorageName, executeAfter);
    };
    ListService.prototype.fetchResult = function (list, aditive) {
        if (aditive === void 0) { aditive = false; }
        return this.listLoadService.fetchResult(list, aditive);
    };
    ListService.prototype.cancelFetch = function (list) {
        return this.listLoadService.cancelFetch(list);
    };
    ListService.prototype.getCurrentPage = function (list) {
        return this.listLoadService.getCurrentPage(list);
    };
    ListService.prototype.getTotalPages = function (list) {
        return this.listLoadService.getTotalPages(list);
    };
    ListService.prototype.getItemsCount = function (list) {
        return this.listLoadService.getItemsCount(list);
    };
    ListService.prototype.fetchListPerPage = function (list, page, onListChanged, onOverfloatPages) {
        return this.listLoadService.fetchListPerPage(list, page, onListChanged, onOverfloatPages);
    };
    ListService.prototype.sort = function (list, sortValues, sortDir) {
        return this.listLoadService.sort(list, sortValues, sortDir);
    };
    ListService.prototype.toFilterRequestMessage = function (filterRequest) {
        return this.listLoadService.toFilterRequestMessage(filterRequest);
    };
    ListService.prototype.fetchResultCount = function (list) {
        return this.listLoadService.fetchResultCount(list);
    };
    ListService.ngInjectableDef = i0.defineInjectable({ factory: function ListService_Factory() { return new ListService(i0.inject("ListLoadService"), i0.inject("Restangular")); }, token: ListService, providedIn: "root" });
    return ListService;
}());
exports.ListService = ListService;
