"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var settlement_category_pipe_1 = require("@app/ps/pipes/settlement-category.pipe");
var tooltip_parcels_component_1 = require("@app/common/components/tooltip-parcels/tooltip-parcels.component");
var cell_abstract_component_1 = require("@app/common/components/cell-abstract/cell-abstract.component");
var SettlementProgressChartComponent = /** @class */ (function (_super) {
    __extends(SettlementProgressChartComponent, _super);
    function SettlementProgressChartComponent(settlementCategoryPipe) {
        var _this = _super.call(this) || this;
        _this.settlementCategoryPipe = settlementCategoryPipe;
        _this.TOOLTIP_MAPPING = {
            parcels: tooltip_parcels_component_1.TooltipParcelsComponent
        };
        _this.defaultTooltipFilter = {
            customData: {},
            filter: {
                limit: null,
                filters: {},
            }
        };
        return _this;
    }
    SettlementProgressChartComponent.prototype.ngOnInit = function () {
        this.tooltipDataResult = __assign({}, this.getTooltipFilter());
    };
    /**
     * Filtering could be done in owner-list/titles component as well,
     * but it caused infinite digest cycle in angularjs titles component.
     */
    SettlementProgressChartComponent.prototype.getTooltipFilter = function () {
        var filter = this.defaultTooltipFilter;
        var occupationResolutionForCategory = this.settlementCategoryPipe.transform(this.tooltipData.occupationResolution, this.tooltipData.occupationCategory);
        if (!occupationResolutionForCategory) {
            filter.filter.entityIds = [];
            filter.customData.doneParcelIds = [];
            filter.customData.notStartedParcelIds = [];
            filter.customData.inProgressParcelIds = [];
        }
        else {
            filter.filter.entityIds = __spread(occupationResolutionForCategory.doneParcelIds, occupationResolutionForCategory.notStartedParcelIds, occupationResolutionForCategory.inProgressParcelIds);
            filter.customData.doneParcelIds = occupationResolutionForCategory.doneParcelIds;
            filter.customData.notStartedParcelIds = occupationResolutionForCategory.notStartedParcelIds;
            filter.customData.inProgressParcelIds = occupationResolutionForCategory.inProgressParcelIds;
        }
        return filter;
    };
    return SettlementProgressChartComponent;
}(cell_abstract_component_1.CellAbstractComponent));
exports.SettlementProgressChartComponent = SettlementProgressChartComponent;
