"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var case_service_1 = require("@app/ps/services/case.service");
var angular_1 = require("@uirouter/angular");
var _ = require("lodash");
var case_status_service_1 = require("@app/ps/services/case-status.service");
var ReceiveOwnerComponent = /** @class */ (function () {
    function ReceiveOwnerComponent(restangular, caseStatusService, caseService, stateService) {
        this.restangular = restangular;
        this.caseStatusService = caseStatusService;
        this.caseService = caseService;
        this.stateService = stateService;
        this.loading = true;
        this.recipient = null;
        this.sendData = {
            receivedOwnerDate: new Date(),
            bonusDate: null,
            validityDate: null,
        };
        this.onSubmit = this.onSubmit.bind(this);
        this.isValid = this.isValid.bind(this);
        this.onReceivedOwnerDateChanged = this.onReceivedOwnerDateChanged.bind(this);
    }
    ReceiveOwnerComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b;
            var _this = this;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.restangular.one('cases', this.caseId).get({ loadCollections: ['caseSubjects', 'caseOwnerships'] })];
                    case 1:
                        _a.data = _c.sent();
                        _b = this;
                        return [4 /*yield*/, this.caseStatusService.loadStatusName('ReceivedOwner')];
                    case 2:
                        _b.name = (_c.sent()).name;
                        this.sendData.bonusDate = this.data.bonusDate;
                        this.dataCaseOwnerships = __spread(this.data.caseOwnerships);
                        this.dataCaseOwnerships.forEach(function (item) {
                            var bsmSubject;
                            if (item.ownership.isBsm) {
                                bsmSubject = _this.data.caseSubjects.find(function (value) { return value.subject.opsubId === item.ownership.opsubId; }).subject;
                            }
                            item.caseSubjects = _this.data.caseSubjects.filter(function (value) { return (!bsmSubject && value.subject.opsubId === item.ownership.opsubId) || (bsmSubject && (bsmSubject.partner1OpsubId === value.subject.opsubId || bsmSubject.partner2OpsubId === value.subject.opsubId)); });
                        });
                        this.recipient = _.find(this.caseService.getRecipients(this.dataCaseOwnerships), function (value) {
                            return !value.receivedDate && (value.sentDate || value.personalVisitDate);
                        });
                        this.loading = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    ReceiveOwnerComponent.prototype.onReceivedOwnerDateChanged = function (value) {
        if (value) {
            var date = new Date(value);
            var newDate = void 0;
            if (this.data.obligation.bonusPeriod && this.data.bonusPossible && !this.data.bonusDate) {
                newDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() + this.data.obligation.bonusPeriod);
                this.sendData.bonusDate = newDate.getFullYear() + '-' + ('00' + (newDate.getMonth() + 1)).slice(-2) + '-' + ('00' + newDate.getDate()).slice(-2);
            }
            if (this.data.obligation.validityPeriod) {
                newDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() + this.data.obligation.validityPeriod);
                this.sendData.validityDate = newDate.getFullYear() + '-' + ('00' + (newDate.getMonth() + 1)).slice(-2) + '-' + ('00' + newDate.getDate()).slice(-2);
            }
        }
        else {
            this.sendData.validityDate = null;
            if (!this.data.bonusDate) {
                this.sendData.bonusDate = null;
            }
        }
    };
    ReceiveOwnerComponent.prototype.isValid = function () {
        return !!this.sendData.receivedOwnerDate;
    };
    ReceiveOwnerComponent.prototype.onSubmit = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.restangular
                            .one('cases', this.caseId)
                            .customPUT(this.sendData, 'receive-owner')];
                    case 1:
                        _a.sent();
                        this.stateService.go('^');
                        return [2 /*return*/];
                }
            });
        });
    };
    return ReceiveOwnerComponent;
}());
exports.ReceiveOwnerComponent = ReceiveOwnerComponent;
