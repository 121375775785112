"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["[_ngcontent-%COMP%]:not(.modal-no-padding)    > .modal-content[_ngcontent-%COMP%] {\n  padding-bottom: 20px !important;\n  padding-left: 15px !important;\n  padding-right: 15px !important;\n  padding-top: 15px !important; }\n\n.close[_ngcontent-%COMP%] {\n  margin-right: 15px !important; }"];
exports.styles = styles;
