"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var auth_service_1 = require("@app/common/services/auth.service");
var dialog_ref_1 = require("@app/dialog/dialog-ref");
var dialog_config_1 = require("@app/dialog/dialog-config");
var ticket_service_1 = require("@app/sv/services/ticket.service");
var ticket_status_enum_1 = require("@app/sv/enums/ticket-status.enum");
var sample_status_enum_1 = require("@app/sv/enums/sample-status.enum");
var TicketEditComponent = /** @class */ (function () {
    function TicketEditComponent(restangular, ticketService, authService, dialogComponent, dialogConfig) {
        this.restangular = restangular;
        this.ticketService = ticketService;
        this.authService = authService;
        this.dialogComponent = dialogComponent;
        this.dialogConfig = dialogConfig;
        this.templateFiles = [];
        this.templateFilesMsg = TicketEditComponent.MSG_TEMPLATE_FILES_DEFAULT;
        this.templateFilesValid = false;
        this.loading = true;
        this.onSave = this.onSave.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }
    TicketEditComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var data, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        data = this.dialogConfig.data;
                        this.ticket = data.ticket;
                        this.newStatus = data.newStatus;
                        if (!(this.newStatus === ticket_status_enum_1.TicketStatusEnum.IMPORTED)) return [3 /*break*/, 2];
                        _a = this;
                        return [4 /*yield*/, this.loadDocumentTypeOrder()];
                    case 1:
                        _a.documentTypeOrder = _b.sent();
                        this.ticket.sampleNumber = this.getSampleNumber();
                        _b.label = 2;
                    case 2:
                        this.loading = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    TicketEditComponent.prototype.isValid = function () {
        var _this = this;
        var statusConfig = this.getStatusConfig();
        if (!statusConfig) {
            return false;
        }
        var fieldsValidity = statusConfig.fields
            .filter(function (n) { return n !== 'attachments' && n !== 'documentTypeOrder' && n !== 'note' && n !== 'fullDocumentName' && n !== 'documentNameSpecification'; })
            .map(function (n) { return !!_this.ticket[n]; })
            .every(function (b) { return b; });
        if (this.newStatus === ticket_status_enum_1.TicketStatusEnum.IMPORTED) {
            return fieldsValidity && this.templateFilesValid;
        }
        if (this.newStatus === ticket_status_enum_1.TicketStatusEnum.DISAPPROVED) {
            return fieldsValidity && !!this.note;
        }
        return fieldsValidity;
    };
    TicketEditComponent.prototype.isFieldVisible = function (field) {
        var statusConfig = this.getStatusConfig();
        if (!statusConfig) {
            return false;
        }
        return statusConfig.fields.includes(field);
    };
    TicketEditComponent.prototype.onSave = function () {
        if (!this.isValid()) {
            return;
        }
        if (this.newStatus) {
            this.ticket.status = this.newStatus;
        }
        this.dialogComponent.close({
            ticket: this.ticket,
            templateFiles: this.templateFiles,
            note: this.note ? { ticketId: { id: this.ticket.id }, systemType: true, text: ('Důvod změny stavu: ' + this.note) } : undefined,
        });
    };
    TicketEditComponent.prototype.onTemplateFilesChange = function (files) {
        this.templateFiles = files;
        var extensionValid = files.every(function (f) { return f.extension && f.extension.toLowerCase() === 'docx'; });
        if (this.templateFiles.length === 0) {
            this.templateFilesMsg = TicketEditComponent.MSG_TEMPLATE_FILES_DEFAULT;
        }
        else if (!extensionValid) {
            this.templateFilesMsg = TicketEditComponent.MSG_TEMPLATE_FILES_EXTENSION;
        }
        else if (this.templateFiles.length > 1) {
            this.templateFilesMsg = TicketEditComponent.MSG_FILES_QUANTITY;
        }
        else {
            this.templateFilesMsg = '';
        }
        this.templateFilesValid = (extensionValid && this.templateFiles.length === 1);
    };
    TicketEditComponent.prototype.onCancel = function () {
        this.dialogComponent.close(false);
    };
    TicketEditComponent.prototype.getStatusConfig = function () {
        var _this = this;
        return TicketEditComponent.STATUSES_CONFIG.find(function (sc) { return sc.status === _this.newStatus; });
    };
    TicketEditComponent.prototype.getSampleNumber = function () {
        if (this.ticket.actualizedSample) {
            var prevVersion = parseInt(this.ticket.actualizedSample.number.substr(-2));
            prevVersion++;
            return this.ticket.actualizedSample.number.slice(0, -2) + String(prevVersion).padStart(2, '0');
        }
        var sectionCode = 'Úsek provozní' === this.ticket.section ? 'P' : 'V';
        var documentTypeOrder = String(this.documentTypeOrder).padStart(2, '0');
        return this.ticket.organizationalUnitCode
            + '-'
            + sectionCode
            + '-'
            + this.ticket.documentType.name
            + '.'
            + documentTypeOrder
            + '-'
            + 'v01';
    };
    TicketEditComponent.prototype.loadDocumentTypeOrder = function () {
        return this.restangular
            .all('samples')
            .customPOST({
            filter: {
                limit: null,
                filters: {
                    organizationalUnitId: {
                        values: [this.ticket.organizationalUnitId],
                    },
                    documentTypeId: {
                        values: [this.ticket.documentType.id],
                    },
                    section: {
                        values: [this.ticket.section],
                    },
                    status: {
                        values: [sample_status_enum_1.SampleStatusEnum.ACTIVE, sample_status_enum_1.SampleStatusEnum.EXCEPTION]
                    }
                }
            }
        })
            .then(function (samples) {
            var e_1, _a;
            var max = 0;
            try {
                for (var samples_1 = __values(samples), samples_1_1 = samples_1.next(); !samples_1_1.done; samples_1_1 = samples_1.next()) {
                    var sample = samples_1_1.value;
                    var matches = sample.number.match(/\.[0-9]{2}/g);
                    if (!matches) {
                        continue;
                    }
                    var version = parseInt(matches[0].replace(/\./g, ''));
                    if (!isNaN(version) && version > max) {
                        max = version;
                    }
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (samples_1_1 && !samples_1_1.done && (_a = samples_1.return)) _a.call(samples_1);
                }
                finally { if (e_1) throw e_1.error; }
            }
            return max + 1;
        });
    };
    TicketEditComponent.STATUSES_CONFIG = [
        {
            status: ticket_status_enum_1.TicketStatusEnum.SENT_TO_CONFIRMATION,
            fields: [
                'sentToConfirmationDate',
            ]
        },
        {
            status: ticket_status_enum_1.TicketStatusEnum.APPROVED,
            fields: [
                'approvedDate',
            ]
        },
        {
            status: ticket_status_enum_1.TicketStatusEnum.DISAPPROVED,
            fields: [
                'disapprovedDate',
                'note',
            ]
        },
        {
            status: ticket_status_enum_1.TicketStatusEnum.TEMPLATE_SENT_TO_PROCESSING,
            fields: [
                'templateSentToProcessingDate',
            ]
        },
        {
            status: ticket_status_enum_1.TicketStatusEnum.IMPORTED,
            fields: [
                'documentTypeOrder',
                'importedDate',
                'sampleNumber',
                'sampleDescription',
                'attachments',
                'fullDocumentName',
                'documentNameSpecification',
            ]
        },
        {
            status: ticket_status_enum_1.TicketStatusEnum.DELETED,
            fields: [
                'deletedDate',
            ]
        },
    ];
    TicketEditComponent.MSG_TEMPLATE_FILES_DEFAULT = 'Vložte soubor přetažením nebo výběrem. Je možné vložit jeden soubor docx.';
    TicketEditComponent.MSG_TEMPLATE_FILES_EXTENSION = 'Soubor musí být ve formátu DOCX.';
    TicketEditComponent.MSG_FILES_QUANTITY = 'Je možné vložit pouze jeden soubor.';
    return TicketEditComponent;
}());
exports.TicketEditComponent = TicketEditComponent;
