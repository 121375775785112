"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./update-case-ownership.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@uirouter/angular/lib/directives/uiSref");
var i3 = require("@uirouter/core");
var i4 = require("../../../owners/components/forms/payment-form/payment-form.component.ngfactory");
var i5 = require("../../../owners/components/forms/payment-form/payment-form.component");
var i6 = require("@angular/common");
var i7 = require("@angular/forms");
var i8 = require("../../../../common/components/checkbox/checkbox.component.ngfactory");
var i9 = require("../../../../common/components/checkbox/checkbox.component");
var i10 = require("../../../../common/components/button/button.component.ngfactory");
var i11 = require("../../../../common/components/button/button.component");
var i12 = require("../../../../common/pipes/subject-name.pipe");
var i13 = require("../../../../common/directives/loading.directive");
var i14 = require("./update-case-ownership.component");
var i15 = require("../../../services/case.service");
var i16 = require("@uirouter/angular");
var i17 = require("../../../../common/services/list.service");
var i18 = require("../../../../common/services/auth.service");
var i19 = require("../../../services/case-status.service");
var styles_UpdateCaseOwnershipComponent = [i0.styles];
var RenderType_UpdateCaseOwnershipComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UpdateCaseOwnershipComponent, data: {} });
exports.RenderType_UpdateCaseOwnershipComponent = RenderType_UpdateCaseOwnershipComponent;
function View_UpdateCaseOwnershipComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 19, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 16, "div", [["class", "column-100"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Man\u017Eel\u00E9"])), (_l()(), i1.ɵeld(4, 0, null, null, 13, "div", [["class", "info"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 5, "a", [["uiSref", "symap.project.owners.detail"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).go($event.button, $event.ctrlKey, $event.metaKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 16384, null, 0, i2.AnchorUISref, [i1.ElementRef, i1.Renderer2], null, null), i1.ɵdid(7, 737280, null, 0, i2.UISref, [i3.UIRouter, [2, i2.AnchorUISref], "UIView.PARENT_INJECT"], { state: [0, "state"], params: [1, "params"] }, null), i1.ɵpod(8, { ownerId: 0, tab: 1 }), (_l()(), i1.ɵted(9, null, ["", ""])), i1.ɵppd(10, 1), (_l()(), i1.ɵted(-1, null, [" a "])), (_l()(), i1.ɵeld(12, 0, null, null, 5, "a", [["uiSref", "symap.project.owners.detail"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 14).go($event.button, $event.ctrlKey, $event.metaKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(13, 16384, null, 0, i2.AnchorUISref, [i1.ElementRef, i1.Renderer2], null, null), i1.ɵdid(14, 737280, null, 0, i2.UISref, [i3.UIRouter, [2, i2.AnchorUISref], "UIView.PARENT_INJECT"], { state: [0, "state"], params: [1, "params"] }, null), i1.ɵpod(15, { ownerId: 0, tab: 1 }), (_l()(), i1.ɵted(16, null, ["", ""])), i1.ɵppd(17, 1), (_l()(), i1.ɵeld(18, 0, null, null, 1, "payment-form", [], null, null, null, i4.View_PaymentFormComponent_0, i4.RenderType_PaymentFormComponent)), i1.ɵdid(19, 114688, null, 0, i5.PaymentFormComponent, [], { paymentOptions: [0, "paymentOptions"], nestedForm: [1, "nestedForm"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "symap.project.owners.detail"; var currVal_1 = _ck(_v, 8, 0, _co.caseSubjects[0].subject.id, "about"); _ck(_v, 7, 0, currVal_0, currVal_1); var currVal_3 = "symap.project.owners.detail"; var currVal_4 = _ck(_v, 15, 0, _co.caseSubjects[1].subject.id, "about"); _ck(_v, 14, 0, currVal_3, currVal_4); var currVal_6 = _co.caseOwnership; var currVal_7 = true; _ck(_v, 19, 0, currVal_6, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = i1.ɵunv(_v, 9, 0, _ck(_v, 10, 0, i1.ɵnov(_v.parent.parent, 0), _co.caseSubjects[0].subject)); _ck(_v, 9, 0, currVal_2); var currVal_5 = i1.ɵunv(_v, 16, 0, _ck(_v, 17, 0, i1.ɵnov(_v.parent.parent, 0), _co.caseSubjects[1].subject)); _ck(_v, 16, 0, currVal_5); }); }
function View_UpdateCaseOwnershipComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "column-100 bordered-top mt-10 pt-10"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Jm\u00E9no"])), (_l()(), i1.ɵeld(4, 0, null, null, 6, "div", [["class", "info"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 5, "a", [["uiSref", "symap.project.owners.detail"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).go($event.button, $event.ctrlKey, $event.metaKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 16384, null, 0, i2.AnchorUISref, [i1.ElementRef, i1.Renderer2], null, null), i1.ɵdid(7, 737280, null, 0, i2.UISref, [i3.UIRouter, [2, i2.AnchorUISref], "UIView.PARENT_INJECT"], { state: [0, "state"], params: [1, "params"] }, null), i1.ɵpod(8, { ownerId: 0, tab: 1 }), (_l()(), i1.ɵted(9, null, ["", ""])), i1.ɵppd(10, 1), (_l()(), i1.ɵeld(11, 0, null, null, 1, "payment-form", [], null, null, null, i4.View_PaymentFormComponent_0, i4.RenderType_PaymentFormComponent)), i1.ɵdid(12, 114688, null, 0, i5.PaymentFormComponent, [], { paymentOptions: [0, "paymentOptions"], nestedForm: [1, "nestedForm"] }, null)], function (_ck, _v) { var currVal_0 = "symap.project.owners.detail"; var currVal_1 = _ck(_v, 8, 0, _v.context.$implicit.subject.id, "about"); _ck(_v, 7, 0, currVal_0, currVal_1); var currVal_3 = _v.context.$implicit; var currVal_4 = true; _ck(_v, 12, 0, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_2 = i1.ɵunv(_v, 9, 0, _ck(_v, 10, 0, i1.ɵnov(_v.parent.parent.parent, 0), _v.context.$implicit.subject)); _ck(_v, 9, 0, currVal_2); }); }
function View_UpdateCaseOwnershipComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UpdateCaseOwnershipComponent_4)), i1.ɵdid(2, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.caseSubjects; _ck(_v, 2, 0, currVal_0); }, null); }
function View_UpdateCaseOwnershipComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "column-50"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Cena VB za pod\u00EDl"])), (_l()(), i1.ɵeld(3, 0, null, null, 6, "div", [["class", "input"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 5, "input", [["cm-input-number", ""], ["type", "text"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 5).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 5)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 5)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.caseOwnership.easementPriceByShare = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 16384, null, 0, i7.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i7.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i7.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i7.DefaultValueAccessor]), i1.ɵdid(7, 671744, null, 0, i7.NgModel, [[8, null], [8, null], [8, null], [6, i7.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i7.NgControl, null, [i7.NgModel]), i1.ɵdid(9, 16384, null, 0, i7.NgControlStatus, [[4, i7.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.caseOwnership.easementPriceByShare; _ck(_v, 7, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 9).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 9).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 9).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 9).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 9).ngClassValid; var currVal_5 = i1.ɵnov(_v, 9).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 9).ngClassPending; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
function View_UpdateCaseOwnershipComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "column-50"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u010C\u00EDslo SOSB"])), (_l()(), i1.ɵeld(3, 0, null, null, 6, "div", [["class", "input"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 5, "input", [["type", "text"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 5).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 5)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 5)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.caseOwnership.easementFutureContractNumber = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 16384, null, 0, i7.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i7.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i7.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i7.DefaultValueAccessor]), i1.ɵdid(7, 671744, null, 0, i7.NgModel, [[8, null], [8, null], [8, null], [6, i7.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i7.NgControl, null, [i7.NgModel]), i1.ɵdid(9, 16384, null, 0, i7.NgControlStatus, [[4, i7.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.caseOwnership.easementFutureContractNumber; _ck(_v, 7, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 9).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 9).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 9).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 9).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 9).ngClassValid; var currVal_5 = i1.ɵnov(_v, 9).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 9).ngClassPending; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
function View_UpdateCaseOwnershipComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "column-50"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Stav SOSB"])), (_l()(), i1.ɵeld(3, 0, null, null, 6, "div", [["class", "input"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 5, "input", [["type", "text"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 5).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 5)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 5)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.caseOwnership.easementFutureContractStatus = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 16384, null, 0, i7.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i7.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i7.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i7.DefaultValueAccessor]), i1.ɵdid(7, 671744, null, 0, i7.NgModel, [[8, null], [8, null], [8, null], [6, i7.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i7.NgControl, null, [i7.NgModel]), i1.ɵdid(9, 16384, null, 0, i7.NgControlStatus, [[4, i7.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.caseOwnership.easementFutureContractStatus; _ck(_v, 7, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 9).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 9).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 9).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 9).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 9).ngClassValid; var currVal_5 = i1.ɵnov(_v, 9).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 9).ngClassPending; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
function View_UpdateCaseOwnershipComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "fieldset", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UpdateCaseOwnershipComponent_6)), i1.ɵdid(2, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UpdateCaseOwnershipComponent_7)), i1.ɵdid(4, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UpdateCaseOwnershipComponent_8)), i1.ɵdid(6, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.displayFields.easementPriceByShare; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.displayFields.easementFutureContractNumber; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.displayFields.easementFutureContractStatus; _ck(_v, 6, 0, currVal_2); }, null); }
function View_UpdateCaseOwnershipComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 19, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 12, "fieldset", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "legend", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Platebn\u00ED \u00FAdaje"])), (_l()(), i1.ɵeld(4, 0, null, null, 5, "div", [["class", "column-100"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "label", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleSinglePayment() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Jedna platba za pod\u00EDl SJM"])), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "info"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "gmt-checkbox", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleSinglePayment() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_CheckboxComponent_0, i8.RenderType_CheckboxComponent)), i1.ɵdid(9, 114688, null, 0, i9.CheckboxComponent, [], { gmtChecked: [0, "gmtChecked"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UpdateCaseOwnershipComponent_2)), i1.ɵdid(11, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UpdateCaseOwnershipComponent_3)), i1.ɵdid(13, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UpdateCaseOwnershipComponent_5)), i1.ɵdid(15, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(16, 0, null, null, 3, "div", [["class", "actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 2, "gmt-button", [], null, null, null, i10.View_ButtonComponent_0, i10.RenderType_ButtonComponent)), i1.ɵdid(18, 114688, null, 0, i11.ButtonComponent, ["ErrorHandlerService", i1.ChangeDetectorRef], { clickAction: [0, "clickAction"] }, null), (_l()(), i1.ɵted(-1, 0, ["Ulo\u017Eit vlastn\u00EDka"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.caseOwnership.singlePayment; _ck(_v, 9, 0, currVal_0); var currVal_1 = _co.caseOwnership.singlePayment; _ck(_v, 11, 0, currVal_1); var currVal_2 = !_co.caseOwnership.singlePayment; _ck(_v, 13, 0, currVal_2); var currVal_3 = _co.displayFieldsLength; _ck(_v, 15, 0, currVal_3); var currVal_4 = _co.update; _ck(_v, 18, 0, currVal_4); }, null); }
function View_UpdateCaseOwnershipComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i12.SubjectNamePipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 12, "div", [["class", "detail"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "mb-10"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "gmt-button", [["uiSref", "^"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).go($event.button, $event.ctrlKey, $event.metaKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_ButtonComponent_0, i10.RenderType_ButtonComponent)), i1.ɵdid(4, 114688, null, 0, i11.ButtonComponent, ["ErrorHandlerService", i1.ChangeDetectorRef], null, null), i1.ɵdid(5, 737280, null, 0, i2.UISref, [i3.UIRouter, [2, i2.AnchorUISref], "UIView.PARENT_INJECT"], { state: [0, "state"] }, null), (_l()(), i1.ɵeld(6, 0, null, 0, 0, "i", [["class", "fa fa-chevron-left mr-5"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, 0, [" Zp\u011Bt na p\u0159\u00EDpad"])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Editace vlastn\u00EDka"])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "div", [], [[8, "className", 0]], null, null, null, null)), i1.ɵdid(11, 16384, null, 0, i13.LoadingDirective, [i1.ElementRef], { loading: [0, "loading"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UpdateCaseOwnershipComponent_1)), i1.ɵdid(13, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 4, 0); var currVal_0 = "^"; _ck(_v, 5, 0, currVal_0); var currVal_2 = _co.loading; _ck(_v, 11, 0, currVal_2); var currVal_3 = !_co.loading; _ck(_v, 13, 0, currVal_3); }, function (_ck, _v) { var currVal_1 = i1.ɵnov(_v, 11).elementClass; _ck(_v, 10, 0, currVal_1); }); }
exports.View_UpdateCaseOwnershipComponent_0 = View_UpdateCaseOwnershipComponent_0;
function View_UpdateCaseOwnershipComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "update-case-ownership", [], null, null, null, View_UpdateCaseOwnershipComponent_0, RenderType_UpdateCaseOwnershipComponent)), i1.ɵdid(1, 114688, null, 0, i14.UpdateCaseOwnershipComponent, [i15.CaseService, i16.StateService, i17.ListService, i18.AuthService, "Restangular", i19.CaseStatusService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_UpdateCaseOwnershipComponent_Host_0 = View_UpdateCaseOwnershipComponent_Host_0;
var UpdateCaseOwnershipComponentNgFactory = i1.ɵccf("update-case-ownership", i14.UpdateCaseOwnershipComponent, View_UpdateCaseOwnershipComponent_Host_0, { caseOwnershipId: "caseOwnershipId", caseId: "caseId" }, {}, []);
exports.UpdateCaseOwnershipComponentNgFactory = UpdateCaseOwnershipComponentNgFactory;
