"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./tabbed-block.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/common");
var i3 = require("./tabbed-block.component");
var styles_TabbedBlockComponent = [i0.styles];
var RenderType_TabbedBlockComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TabbedBlockComponent, data: {} });
exports.RenderType_TabbedBlockComponent = RenderType_TabbedBlockComponent;
function View_TabbedBlockComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "li", [], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(2, { active: 0 }), (_l()(), i1.ɵeld(3, 0, null, null, 1, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onUpdateSelection(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(4, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, (_v.context.$implicit.id === _co.selected)); _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = _v.context.$implicit.name; _ck(_v, 4, 0, currVal_1); }); }
function View_TabbedBlockComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "tabbed-block"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "ul", [["class", "tabs"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TabbedBlockComponent_1)), i1.ɵdid(3, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tabs; _ck(_v, 3, 0, currVal_0); }, null); }
exports.View_TabbedBlockComponent_0 = View_TabbedBlockComponent_0;
function View_TabbedBlockComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "gmt-tabbed-block", [], null, null, null, View_TabbedBlockComponent_0, RenderType_TabbedBlockComponent)), i1.ɵdid(1, 49152, null, 0, i3.TabbedBlockComponent, [], null, null)], null, null); }
exports.View_TabbedBlockComponent_Host_0 = View_TabbedBlockComponent_Host_0;
var TabbedBlockComponentNgFactory = i1.ɵccf("gmt-tabbed-block", i3.TabbedBlockComponent, View_TabbedBlockComponent_Host_0, { tabs: "tabs", selected: "selected" }, { updated: "updated" }, ["*"]);
exports.TabbedBlockComponentNgFactory = TabbedBlockComponentNgFactory;
