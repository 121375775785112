"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var _ = require("lodash");
var list_service_1 = require("@app/common/services/list.service");
var i0 = require("@angular/core");
var i1 = require("../../common/services/list.service");
var TitleDetailCreateCaseSelectTitlesService = /** @class */ (function () {
    function TitleDetailCreateCaseSelectTitlesService(listService) {
        this.listService = listService;
        this.loadTitlesBySubject = this.loadTitlesBySubject.bind(this);
    }
    TitleDetailCreateCaseSelectTitlesService.prototype.loadTitlesBySubject = function (subjectId, originalTitleId, obligationType, setByBusinessCase) {
        var _this = this;
        var filters = { validity: 'valid', subjectId: [subjectId], obligationType: [obligationType] };
        var list = this.listService.createList('ownerships', {
            limit: null,
            sortOrder: [{ sortBy: 'title.area.name', direction: 'asc' }, { sortBy: 'title.lv', direction: 'asc' }],
            filters: filters
        }, undefined, { parentOwnership: { load: true } });
        var itemsSelectedPromiseResolve;
        var itemsSelectedPromise = new Promise(function (resolutionFunc, rejectionFunc) {
            itemsSelectedPromiseResolve = resolutionFunc;
        });
        this.listService.fetchResult(list).then(function () {
            list.itemCount -= _.remove(list.list, { title: { id: originalTitleId } }).length;
            list.list = _.map(list.list, function (ownership) {
                var newItem = {
                    id: ownership.id,
                    ownership: ownership,
                    callbackRegister: function (callbacks) {
                        newItem.unselect = callbacks.unselect;
                        newItem.selectByCase = callbacks.selectByCase;
                        newItem.isSelectionValidLocal = callbacks.isSelectionValidLocal;
                        newItem.checkObjectsByData = callbacks.checkObjectsByData;
                        if (setByBusinessCase) {
                            callbacks.selectByCase();
                            itemsSelectedPromiseResolve();
                        }
                    },
                    getOwnerships: function () {
                        if (!newItem.ownershipsData) {
                            var ownerships = _this.listService.createList('ownerships', {
                                limit: null,
                                filters: { validity: 'valid', titleId: [ownership.title.id] }
                            });
                            newItem.ownershipsData = _this.listService.fetchResult(ownerships);
                        }
                        return newItem.ownershipsData;
                    },
                    getCaseOwnerships: function () {
                        return newItem.getOwnerships().then(function (data) {
                            if (!newItem.caseOwnerships) {
                                newItem.caseOwnerships = _.map(data.list, function (ownership) {
                                    return {
                                        id: ownership.id,
                                        ownership: ownership
                                    };
                                });
                            }
                            return newItem.caseOwnerships;
                        });
                    },
                };
                return newItem;
            });
        });
        return list;
    };
    TitleDetailCreateCaseSelectTitlesService.ngInjectableDef = i0.defineInjectable({ factory: function TitleDetailCreateCaseSelectTitlesService_Factory() { return new TitleDetailCreateCaseSelectTitlesService(i0.inject(i1.ListService)); }, token: TitleDetailCreateCaseSelectTitlesService, providedIn: "root" });
    return TitleDetailCreateCaseSelectTitlesService;
}());
exports.TitleDetailCreateCaseSelectTitlesService = TitleDetailCreateCaseSelectTitlesService;
