"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./tooltip-construction-objects.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../construction-object-list/construction-object-list.component.ngfactory");
var i3 = require("../construction-object-list/construction-object-list.component");
var i4 = require("../../../ps/pipes/project-subject-name.pipe");
var i5 = require("./tooltip-construction-objects.component");
var styles_TooltipConstructionObjectsComponent = [i0.styles];
var RenderType_TooltipConstructionObjectsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TooltipConstructionObjectsComponent, data: {} });
exports.RenderType_TooltipConstructionObjectsComponent = RenderType_TooltipConstructionObjectsComponent;
function View_TooltipConstructionObjectsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "gmt-construction-object-list", [], null, null, null, i2.View_ConstructionObjectListComponent_0, i2.RenderType_ConstructionObjectListComponent)), i1.ɵdid(1, 114688, null, 0, i3.ConstructionObjectListComponent, [i4.ProjectSubjectNamePipe], { constructionObjects: [0, "constructionObjects"], showMore: [1, "showMore"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.constructionObjects; var currVal_1 = false; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
exports.View_TooltipConstructionObjectsComponent_0 = View_TooltipConstructionObjectsComponent_0;
function View_TooltipConstructionObjectsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "tooltip-construction-objects", [], null, null, null, View_TooltipConstructionObjectsComponent_0, RenderType_TooltipConstructionObjectsComponent)), i1.ɵdid(1, 49152, null, 0, i5.TooltipConstructionObjectsComponent, [], null, null)], null, null); }
exports.View_TooltipConstructionObjectsComponent_Host_0 = View_TooltipConstructionObjectsComponent_Host_0;
var TooltipConstructionObjectsComponentNgFactory = i1.ɵccf("tooltip-construction-objects", i5.TooltipConstructionObjectsComponent, View_TooltipConstructionObjectsComponent_Host_0, { data: "data" }, {}, []);
exports.TooltipConstructionObjectsComponentNgFactory = TooltipConstructionObjectsComponentNgFactory;
