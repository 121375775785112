"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var _ = require("lodash");
var checklist_service_1 = require("@app/common/services/checklist.service");
var MirroredListComponent = /** @class */ (function () {
    function MirroredListComponent(checklistService) {
        this.checklistService = checklistService;
        this.changed = new core_1.EventEmitter();
        this.availableChecklist = this.checklistService.get(this.availableItems);
        this.selectedChecklist = this.checklistService.get(this.selectedItems);
    }
    MirroredListComponent.prototype.ngOnInit = function () {
        this.availableItems = this.sortByValue(this.availableItems);
        this.selectedItems = this.sortByValue(this.selectedItems);
        this.onToggle = this.onToggle.bind(this);
    };
    MirroredListComponent.prototype.onToggle = function () {
        var _this = this;
        this.availableChecklist.checkedItems.forEach(function (item) {
            _this.selectedItems.push(item);
            _this.availableItems.splice(_this.availableItems.indexOf(item), 1);
        });
        this.selectedChecklist.checkedItems.forEach(function (item) {
            _this.availableItems.push(item);
            _this.selectedItems.splice(_this.selectedItems.indexOf(item), 1);
        });
        this.availableItems = this.sortByValue(this.availableItems);
        this.selectedItems = this.sortByValue(this.selectedItems);
        this.availableChecklist.checkedItems = [];
        this.selectedChecklist.checkedItems = [];
        this.changed.emit(this.selectedItems);
    };
    MirroredListComponent.prototype.sortByValue = function (items) {
        return _.sortBy(items, ['value']);
    };
    return MirroredListComponent;
}());
exports.MirroredListComponent = MirroredListComponent;
