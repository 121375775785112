"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var owners_states_1 = require("./owners.states");
var OWNERS_STATES = [owners_states_1.ownerListState, owners_states_1.ownerDetailState, owners_states_1.problematicOwnerNoteState];
var OwnersModule = /** @class */ (function () {
    function OwnersModule() {
    }
    return OwnersModule;
}());
exports.OwnersModule = OwnersModule;
