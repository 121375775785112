"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function resolveId($stateParams) {
    return $stateParams.id;
}
exports.resolveId = resolveId;
function resolveCallbacks(callbackService) {
    return callbackService.createCallback();
}
exports.resolveCallbacks = resolveCallbacks;
function resolveTrue() {
    return true;
}
exports.resolveTrue = resolveTrue;
