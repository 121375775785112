
var common = angular.module('common');

/**
 * Service extending ModalService
 */
common.service('ConfirmService', ['ModalService',function (ModalService) {
    return function (config) {
        if (config === undefined) {
            config = {};
        }

        var configModal = {
            className: 'adjusted-dialog',
            template: config.template || ('<h1>' + (config.title || 'Potvrzení') + '</h1>'
                    + '<p class="font-size-16 text-center">' + (config.message || 'Opravdu chcete akci provést?') + '</p>'
                    + '<div class="actions"><input type="button" value="Ano" ng-click="confirm();"/>'
                    + '<input type="button" value="Ne" ng-click="decline();" class="red "/></div>'),
            scope: config.scope,
            controller: ['$scope',function ($scope) {
                $scope.confirm = function() {
                    $scope.closeModal(true);
                };

                $scope.decline = function() {
                    $scope.closeModal(false);
                };
            }]
        };
        return new ModalService(configModal);
    };
}]);
