"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("@angular/common");
var i2 = require("@uirouter/angular/lib/directives/uiSref");
var i3 = require("@uirouter/core");
var i4 = require("./navbar.component");
var styles_NavbarComponent = [];
var RenderType_NavbarComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NavbarComponent, data: {} });
exports.RenderType_NavbarComponent = RenderType_NavbarComponent;
function View_NavbarComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "li", [["class", "nav-item"]], [[8, "title", 0]], null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(2, { active: 0, disabled: 1 }), (_l()(), i0.ɵeld(3, 0, null, null, 6, "a", [["class", "nav-link"]], [[1, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 7).go($event.button, $event.ctrlKey, $event.metaKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(4, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(5, { active: 0 }), i0.ɵdid(6, 16384, null, 0, i2.AnchorUISref, [i0.ElementRef, i0.Renderer2], null, null), i0.ɵdid(7, 737280, null, 0, i2.UISref, [i3.UIRouter, [2, i2.AnchorUISref], "UIView.PARENT_INJECT"], { state: [0, "state"], params: [1, "params"], options: [2, "options"] }, null), i0.ɵpod(8, { replace: 0, location: 1 }), (_l()(), i0.ɵted(9, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = "nav-item"; var currVal_2 = _ck(_v, 2, 0, _co.isActive(_v.context.$implicit.id), _co.isDisabled(_v.context.$implicit.id)); _ck(_v, 1, 0, currVal_1, currVal_2); var currVal_4 = "nav-link"; var currVal_5 = _ck(_v, 5, 0, _co.isActive(_v.context.$implicit.id)); _ck(_v, 4, 0, currVal_4, currVal_5); var currVal_6 = i0.ɵinlineInterpolate(1, "", (_co.isDisabled(_v.context.$implicit.id) ? "" : _v.context.$implicit.href), ""); var currVal_7 = (_v.context.$implicit.urlParams ? _v.context.$implicit.urlParams : i0.ɵEMPTY_MAP); var currVal_8 = _ck(_v, 8, 0, true, "replace"); _ck(_v, 7, 0, currVal_6, currVal_7, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "", (_v.context.$implicit.title || _v.context.$implicit.name), ""); _ck(_v, 0, 0, currVal_0); var currVal_3 = _co.isDisabled(_v.context.$implicit.id); _ck(_v, 3, 0, currVal_3); var currVal_9 = _v.context.$implicit.name; _ck(_v, 9, 0, currVal_9); }); }
function View_NavbarComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "ul", [["class", "nav nav-tabs"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NavbarComponent_2)), i0.ɵdid(2, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tabs; _ck(_v, 2, 0, currVal_0); }, null); }
function View_NavbarComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "tabbable-line tabs-below"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NavbarComponent_1)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 1, "div", [["class", "tab-content"]], null, null, null, null, null)), i0.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tabs; _ck(_v, 2, 0, currVal_0); }, null); }
exports.View_NavbarComponent_0 = View_NavbarComponent_0;
function View_NavbarComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "gmt-navbar", [], null, null, null, View_NavbarComponent_0, RenderType_NavbarComponent)), i0.ɵdid(1, 114688, null, 0, i4.NavbarComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_NavbarComponent_Host_0 = View_NavbarComponent_Host_0;
var NavbarComponentNgFactory = i0.ɵccf("gmt-navbar", i4.NavbarComponent, View_NavbarComponent_Host_0, { tabs: "tabs", disabledTabs: "disabledTabs", isActiveTab: "isActiveTab" }, {}, ["*"]);
exports.NavbarComponentNgFactory = NavbarComponentNgFactory;
