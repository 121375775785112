"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var angular_hybrid_1 = require("@uirouter/angular-hybrid");
var bulk_operation_component_1 = require("./components/bulk-operation/bulk-operation.component");
var bulk_operation_type_model_1 = require("@app/ps/models/bulk-operation-type.model");
var bulk_operation_process_definition_model_1 = require("./models/bulk-operation-process-definition.model");
var callback_service_1 = require("@app/ps/services/callback.service");
var main_component_1 = require("@app/ps/components/main/main.component");
var angular_1 = require("@uirouter/angular");
function resolveSymapUrl(APP_BRAND) {
    return APP_BRAND.PS.URL_BASE_PATH;
}
exports.resolveSymapUrl = resolveSymapUrl;
// export function resolveSymapOnEnter(transition: Transition) {
//   const wordService = transition.injector().get(WordService);
//
//   console.log(transition);
//
//   transition.onFinish({}, (transition) => {
//     const to = transition.to();
//
//     console.log('ps module');
//
//     wordService.replaceVariablesInText(to.data && to.data.title ? to.data.title : '').then((text) => {
//       console.log(text);
//       // Does not work for window title, while it is outside of scope
//       document.title = text || 'Načítání';
//     });
//   });
// }
// resolveSymapOnEnter.$inject = ['$transition$'];
exports.symapState = {
    name: 'symap',
    abstract: true,
    url: '/{brandPath: maja|mpv}',
    params: {
        brandPath: {
            value: ['APP_BRAND', resolveSymapUrl]
        }
    },
};
function symapProjectStateOnEnter($transition$, AuthService, APPLICATIONS, LoginService) {
    return AuthService.checkProjectStatus($transition$, APPLICATIONS.sy.name, 'sy_', AuthService.loadSymapProjects, 'error').then(function () {
        AuthService.switchProjectByKey($transition$.params().projectKey, APPLICATIONS.sy.name);
        AuthService.onAuthProjectUpdated(AuthService.getActualProject());
    }, function (newState) {
        return newState;
    });
}
exports.symapProjectStateOnEnter = symapProjectStateOnEnter;
function symapProjectStateOnExit(AuthService) {
    AuthService.onAuthProjectUpdated(null);
}
exports.symapProjectStateOnExit = symapProjectStateOnExit;
exports.symapProjectState = {
    name: 'symap.project',
    abstract: true,
    url: '/project/{projectKey:sy_[^/]*}',
    params: {
        projectKey: {
            squash: true,
        }
    },
    component: main_component_1.MainComponent,
    onEnter: ['$transition$', 'AuthService', 'APPLICATIONS', 'LoginService', symapProjectStateOnEnter],
    onExit: ['AuthService', symapProjectStateOnExit]
};
function titlesProcessDefinitionResolveFunction($stateParams) { return new bulk_operation_process_definition_model_1.BulkOperationProcessDefinitionModel(bulk_operation_type_model_1.BulkOperationType.TITLES, $stateParams.entityIds); }
exports.titlesProcessDefinitionResolveFunction = titlesProcessDefinitionResolveFunction;
function casesProcessDefinitionResolveFunction($stateParams) { return new bulk_operation_process_definition_model_1.BulkOperationProcessDefinitionModel(bulk_operation_type_model_1.BulkOperationType.CASES, $stateParams.entityIds); }
exports.casesProcessDefinitionResolveFunction = casesProcessDefinitionResolveFunction;
function entityIdsResolveFunction($stateParams) { return $stateParams.entityIds; }
exports.entityIdsResolveFunction = entityIdsResolveFunction;
function callbacksResolveFunction(callbackService) { return callbackService.createCallback(); }
exports.callbacksResolveFunction = callbacksResolveFunction;
exports.bulkOperationTitlesState = {
    name: 'symap.project.titles.bulkOperation',
    url: '/bulk-operation',
    data: {
        title: 'Hromadná operace',
    },
    views: {
        'content@symap.project': {
            component: bulk_operation_component_1.BulkOperationComponent,
        }
    },
    params: {
        entityIds: null,
    },
    resolve: [
        {
            token: 'processDefinition',
            deps: ['$stateParams'],
            resolveFn: titlesProcessDefinitionResolveFunction,
        },
        {
            token: 'entityIds',
            deps: ['$stateParams'],
            resolveFn: entityIdsResolveFunction,
        },
        {
            token: 'callbacks',
            deps: [callback_service_1.CallbackService],
            resolveFn: callbacksResolveFunction,
        },
    ]
};
exports.bulkOperationCasesState = {
    name: 'symap.project.cases.bulkOperation',
    url: '/bulk-operation',
    data: {
        title: 'Hromadná operace',
    },
    views: {
        'content@symap.project': {
            component: bulk_operation_component_1.BulkOperationComponent,
        }
    },
    params: {
        entityIds: null,
    },
    resolve: [
        {
            token: 'processDefinition',
            deps: ['$stateParams'],
            resolveFn: casesProcessDefinitionResolveFunction,
        },
        {
            token: 'entityIds',
            deps: ['$stateParams'],
            resolveFn: entityIdsResolveFunction,
        },
        {
            token: 'callbacks',
            deps: [callback_service_1.CallbackService],
            resolveFn: callbacksResolveFunction,
        },
    ]
};
var ɵ0 = function ($injector) { return $injector.get('CallbackService'); }, ɵ1 = function ($injector) { return $injector.get('EVENTS'); }, ɵ2 = function ($injector) { return $injector.get('APP_BRAND'); }, ɵ3 = function ($injector) { return $injector.get('RootCallbackService'); }, ɵ4 = function ($injector) { return $injector.get('OpinionService'); }, ɵ5 = function ($injector) { return $injector.get('GlobalRestangularConfigProvider'); };
exports.ɵ0 = ɵ0;
exports.ɵ1 = ɵ1;
exports.ɵ2 = ɵ2;
exports.ɵ3 = ɵ3;
exports.ɵ4 = ɵ4;
exports.ɵ5 = ɵ5;
var PsAppModule = /** @class */ (function () {
    function PsAppModule() {
    }
    return PsAppModule;
}());
exports.PsAppModule = PsAppModule;
