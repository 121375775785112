"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var auth_service_1 = require("@app/common/services/auth.service");
var core_1 = require("@angular/core");
var file_saver_1 = require("file-saver");
var vfze_utils_1 = require("@app/common/utils/vfze.utils");
var ValidationComponent = /** @class */ (function () {
    function ValidationComponent(restangular, uploadService, authService) {
        this.restangular = restangular;
        this.uploadService = uploadService;
        this.authService = authService;
        this.filesMsg = ValidationComponent.MSG_FILES_DEFAULT;
        this.files = [];
        this.isFileValidToUpload = false;
        this.xmlErrorsDisplayed = false;
        this.vfzeFileContent = '';
        this.partialMessageLog = [];
        this.keepWaitForValidationStatus = true;
        this.onUpload = this.onUpload.bind(this);
        this.onSaveValidationResponse = this.onSaveValidationResponse.bind(this);
    }
    ValidationComponent.prototype.ngOnInit = function () {
    };
    ValidationComponent.prototype.ngOnDestroy = function () {
        this.keepWaitForValidationStatus = false;
    };
    ValidationComponent.prototype.onFilesChange = function (files) {
        var _this = this;
        this.files = files;
        this.validationResponse = undefined;
        this.xmlErrorsDisplayed = false;
        if (this.files.length > 1) {
            this.filesMsg = ValidationComponent.MSG_FILES_QUANTITY;
        }
        else if (this.files.length === 0) {
            this.filesMsg = ValidationComponent.MSG_FILES_DEFAULT;
        }
        else {
            this.filesMsg = '';
        }
        if (this.files[0]) {
            var reader_1 = new FileReader();
            reader_1.addEventListener('load', function (event) {
                _this.vfzeFileContent = reader_1.result;
            });
            reader_1.readAsText(this.files[0].fileEntry);
        }
        this.isFileValidToUpload = this.files.length === 1;
    };
    ValidationComponent.prototype.onUpload = function () {
        var _this = this;
        this.validationResponse = undefined;
        this.xmlErrorsDisplayed = false;
        this.partialMessageLog = [];
        var restBaseUrl = this.authService.getActiveApplicationRestUrl();
        var headers = __assign({}, this.restangular.defaultHeaders);
        return this.uploadService.upload({
            url: restBaseUrl + '/async/validate',
            data: { vfze: this.files[0].fileEntry },
            headers: headers
        }).then(function (response) {
            _this.keepWaitForValidationStatus = true;
            var getValidationStatus = function (resolve, reject) {
                _this.restangular.one("/async/status/" + response.data.validationId).get()
                    .then(function (response) {
                    if (!_this.keepWaitForValidationStatus) {
                        reject();
                    }
                    else if (response.status === 'FINISHED' || response.status === 'FAILED') {
                        _this.validationResponse = response.validationResult;
                        _this.files = [];
                        _this.filesMsg = ValidationComponent.MSG_FILES_DEFAULT;
                        _this.isFileValidToUpload = false;
                        _this.partialMessageLog = [];
                        resolve();
                        _this.keepWaitForValidationStatus = false;
                    }
                    else {
                        _this.partialMessageLog = response.message;
                        setTimeout(function () { return getValidationStatus(resolve, reject); }, 1000);
                    }
                });
            };
            return new Promise(getValidationStatus);
        });
    };
    ValidationComponent.prototype.onSaveValidationResponse = function () {
        var blob = new Blob([vfze_utils_1.VfzeUtils.validationResponseToText(this.validationResponse)], { type: 'text/plain;charset=utf-8' });
        file_saver_1.saveAs(blob, 'validace-vfze.txt');
    };
    ValidationComponent.prototype.isResponseSuccessfully = function () {
        if (!this.validationResponse) {
            return false;
        }
        var _a = this.validationResponse, xsdValidationPassed = _a.xsdValidationPassed, modelDeserializationPassed = _a.modelDeserializationPassed, errors = _a.errors, fatal = _a.fatal;
        return !!(xsdValidationPassed
            && modelDeserializationPassed
            && errors.length === 0
            && fatal.length === 0);
    };
    ValidationComponent.prototype.containResponseErrors = function () {
        if (!this.validationResponse) {
            return false;
        }
        var _a = this.validationResponse, errors = _a.errors, fatal = _a.fatal;
        return (!!errors.filter(function (p) { return p.xmlStructureError; }).length || !!fatal.filter(function (p) { return p.xmlStructureError; }).length);
    };
    ValidationComponent.MSG_FILES_DEFAULT = 'Vložte soubor typu VFZE přetažením, nebo jej vyberte z počítače.';
    ValidationComponent.MSG_FILES_QUANTITY = 'Je možné vložit jenom jeden soubor.';
    return ValidationComponent;
}());
exports.ValidationComponent = ValidationComponent;
