"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./dialog.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../common/components/perfectscrollbar/perfectscrollbar.component.ngfactory");
var i3 = require("../common/components/perfectscrollbar/perfectscrollbar.component");
var i4 = require("./insertion.directive");
var i5 = require("./dialog.component");
var i6 = require("./dialog-ref");
var i7 = require("./dialog-config");
var styles_DialogComponent = [i0.styles];
var RenderType_DialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DialogComponent, data: {} });
exports.RenderType_DialogComponent = RenderType_DialogComponent;
function View_DialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_DialogComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { insertionPoint: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "flex-centered modal-container"]], null, [[null, "keydown"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("keydown" === en)) {
        var pd_0 = (_co.onEscape($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.onOverlayClicked($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["tabindex", "0"]], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "a", [["class", "close fa fa-times"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClosed($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "perfect-scrollbar", [], null, [[null, "callbackRegister"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("callbackRegister" === en)) {
        var pd_0 = (_co.onScrollbarRegister($event.updateScrollbar) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_PerfectScrollbarComponent_0, i2.RenderType_PerfectScrollbarComponent)), i1.ɵdid(6, 114688, null, 0, i3.PerfectScrollbarComponent, [i1.ElementRef, i1.KeyValueDiffers, i1.NgZone], null, { callbackRegister: "callbackRegister" }), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_DialogComponent_1)), i1.ɵdid(8, 16384, [[1, 4]], 0, i4.InsertionDirective, [i1.ViewContainerRef], null, null)], function (_ck, _v) { _ck(_v, 6, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "modal ", _co.className, ""); _ck(_v, 2, 0, currVal_0); }); }
exports.View_DialogComponent_0 = View_DialogComponent_0;
function View_DialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dialog", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onWindowResize() !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_DialogComponent_0, RenderType_DialogComponent)), i1.ɵdid(1, 4374528, null, 0, i5.DialogComponent, [i1.ComponentFactoryResolver, i1.ChangeDetectorRef, i6.DialogRef, i1.ElementRef, i7.DialogConfig], null, null)], null, null); }
exports.View_DialogComponent_Host_0 = View_DialogComponent_Host_0;
var DialogComponentNgFactory = i1.ɵccf("app-dialog", i5.DialogComponent, View_DialogComponent_Host_0, {}, {}, []);
exports.DialogComponentNgFactory = DialogComponentNgFactory;
