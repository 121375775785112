"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var pricing_expert_opinion_step_component_1 = require("@app/ps/titles/components/pricing-expert-opinion-step/pricing-expert-opinion-step.component");
var numeric_pipe_1 = require("@app/common/pipes/numeric.pipe");
var checklist_service_1 = require("@app/common/services/checklist.service");
var auth_service_1 = require("@app/common/services/auth.service");
var opinion_service_1 = require("@app/ps/services/opinion.service");
var opinion_request_service_1 = require("@app/ps/services/opinion-request.service");
var opinion_request_status_enum_1 = require("@app/ps/enums/opinion-request-status.enum");
var PricingExpertOpinionSummaryComponent = /** @class */ (function (_super) {
    __extends(PricingExpertOpinionSummaryComponent, _super);
    function PricingExpertOpinionSummaryComponent(restangular, opinionService, opinionRequestService, numericPipe, checklistService, authService) {
        var _this = _super.call(this) || this;
        _this.restangular = restangular;
        _this.opinionService = opinionService;
        _this.opinionRequestService = opinionRequestService;
        _this.numericPipe = numericPipe;
        _this.checklistService = checklistService;
        _this.authService = authService;
        _this.hasCoefficient = _this.authService.getActualProject().showExpertOpinionCoefficient;
        _this.allParcelsWithoutCoefficient = false;
        _this.onSave = _this.onSave.bind(_this);
        _this.onBack = _this.onBack.bind(_this);
        return _this;
    }
    PricingExpertOpinionSummaryComponent.prototype.ngOnInit = function () {
        if (!this.data.updated) {
            this.computeFromExisting();
        }
        else {
            this.computeFromInitial();
        }
        this.allParcelsWithoutCoefficient = this.data.opinion.parcelPrices.every(function (pp) { return pp.expertOpinionPriceCoefficient === 1; });
        this.loading = false;
    };
    PricingExpertOpinionSummaryComponent.prototype.onBack = function () {
        this.backCallback.emit();
    };
    PricingExpertOpinionSummaryComponent.prototype.onSave = function () {
        var _this = this;
        if (this.data.opinion.id) {
            return this.opinionService
                .updateOne(this.data.opinion.id, this.data.opinion)
                .then(function () { return _this.submitCallback.emit(); });
        }
        // create opinion and create opinion request or update existing one
        return this.opinionService
            .createOne(this.data.opinion)
            .then(function (opinion) {
            if (_this.data.opinionRequest) {
                _this.data.opinionRequest.opinion = opinion.plain();
                _this.data.opinionRequest.status = opinion_request_status_enum_1.OpinionRequestStatusEnum.PRICES_FILLED;
                return _this.opinionRequestService.update(_this.data.opinionRequest);
            }
            else {
                _this.data.opinion.id = opinion.id;
                return _this.opinionRequestService.createByOpinion(_this.data.opinion);
            }
        })
            .then(function (opinionRequest) {
            return _this.opinionRequestService.createDocuments(opinionRequest, _this.data.files ? _this.data.files : []);
        })
            .then(function () { return _this.submitCallback.emit(); });
    };
    PricingExpertOpinionSummaryComponent.prototype.isFormValid = function () {
        var defaultPriceValid = this.data.sum && isFinite(this.data.sum.priceDefaultRounded) && this.data.sum.priceDefaultRounded;
        var coefficientPriceValid = this.data.sum && isFinite(this.data.sum.priceCoefficientRounded);
        return defaultPriceValid && coefficientPriceValid;
    };
    PricingExpertOpinionSummaryComponent.prototype.computeDefaultPrice = function (init) {
        var _this = this;
        if (init === void 0) { init = false; }
        this.data.sum.priceDefault = 0;
        this.data.sum.priceDefaultWithLand = 0;
        this.data.sum.priceDefaultWithoutLand = 0;
        this.data.sum.countWithLand = 0;
        this.data.opinion.parcelPrices.forEach(function (p) {
            var hasLand = _this.data.opinion.expertOpinionLand && p.expertOpinionParcelPriceDefault > 0;
            if (_this.data.occupationType === 'P') {
                _this.data.sum.priceDefault += _this.numericPipe.transform(p.expertOpinionParcelPriceDefault) || 0;
            }
            else {
                _this.data.sum.priceDefault += _this.numericPipe.transform(p.expertOpinionCompensationPriceDefault) || 0;
            }
            if (hasLand) {
                _this.data.sum.priceDefaultWithLand += p.expertOpinionParcelPriceDefault;
                _this.data.sum.countWithLand += 1;
            }
            else {
                _this.data.sum.priceDefaultWithoutLand += p.expertOpinionParcelPriceDefault;
            }
        });
        if (init) {
            this.data.sum.priceDefaultRounded = this.data.sum.priceDefault;
        }
    };
    PricingExpertOpinionSummaryComponent.prototype.computeCoefficientPrice = function (init) {
        var _this = this;
        if (init === void 0) { init = false; }
        this.data.sum.priceCoefficient = 0;
        this.data.sum.priceCoefficientWithLand = 0;
        this.data.sum.priceCoefficientWithoutLand = 0;
        var parcelCount = this.data.sum.countWithLand;
        var priceDefaultRoundedRemain = this.data.sum.priceDefaultRounded - this.data.sum.priceDefaultWithoutLand;
        // Compute default price proportionally to input summary amount
        // Then computed price with coefficient applied
        this.data.opinion.parcelPrices.forEach(function (p) {
            // Rounding is applied only to land price
            if (_this.data.opinion.expertOpinionLand && priceDefaultRoundedRemain && _this.data.sum.priceDefaultRounded && p.expertOpinionLandSquarePrice > 0) {
                if (!--parcelCount) {
                    p.expertOpinionLandPriceDefault = Math.round(priceDefaultRoundedRemain);
                }
                else {
                    p.expertOpinionLandPriceDefault = Math.round((_this.data.sum.priceDefaultRounded - _this.data.sum.priceDefaultWithoutLand) * p.expertOpinionParcelPriceDefault / _this.data.sum.priceDefaultWithLand);
                }
                p.expertOpinionLandPriceDefault -= (p.expertOpinionVegetationPriceDefault || 0);
                p.expertOpinionLandPriceDefault -= (p.expertOpinionBuildingsPriceDefault || 0);
                priceDefaultRoundedRemain -= p.expertOpinionLandPriceDefault + (p.expertOpinionVegetationPriceDefault || 0) + (p.expertOpinionBuildingsPriceDefault || 0);
            }
            else {
                p.expertOpinionLandPriceDefault = 0;
            }
            p.expertOpinionParcelPriceDefaultRounded = (p.expertOpinionLandPriceDefault || 0) + (p.expertOpinionVegetationPriceDefault || 0) + (p.expertOpinionBuildingsPriceDefault || 0);
            if (!_this.hasCoefficient) {
                p.expertOpinionPriceCoefficient = 1;
            }
            p.expertOpinionLandPriceCoefficient = Math.round(p.expertOpinionLandPriceDefault
                * (p.expertOpinionPriceCoefficient ? p.expertOpinionPriceCoefficient : 0));
            p.expertOpinionCompensationPrice = Math.round(p.expertOpinionCompensationPriceDefault
                * (p.expertOpinionCompensationMultiply ?
                    (p.expertOpinionPriceCoefficient ? p.expertOpinionPriceCoefficient : 0) : 1));
            p.expertOpinionVegetationPrice = Math.round(p.expertOpinionVegetationPriceDefault
                * (p.expertOpinionVegetationMultiply ?
                    (p.expertOpinionPriceCoefficient ? p.expertOpinionPriceCoefficient : 0) : 1));
            p.expertOpinionBuildingsPrice = Math.round(p.expertOpinionBuildingsPriceDefault
                * (p.expertOpinionBuildingsMultiply ?
                    (p.expertOpinionPriceCoefficient ? p.expertOpinionPriceCoefficient : 0) : 1));
            var sum = Math.round((p.expertOpinionLandPriceCoefficient || 0)
                + (p.expertOpinionCompensationPrice || 0)
                + (p.expertOpinionVegetationPrice || 0)
                + (p.expertOpinionBuildingsPrice || 0));
            _this.data.sum.priceCoefficient += sum;
            if (p.expertOpinionLandPriceCoefficient > 0) {
                _this.data.sum.priceCoefficientWithLand += sum;
            }
            else {
                _this.data.sum.priceCoefficientWithoutLand += sum;
            }
            if (init) {
                _this.data.sum.priceCoefficientRounded = _this.data.sum.priceCoefficient;
            }
        });
    };
    PricingExpertOpinionSummaryComponent.prototype.computeRoundedPrice = function () {
        var _this = this;
        var parcelCount = this.data.sum.countWithLand;
        var priceCoefficientRoundedRemain = this.data.sum.priceCoefficientRounded - this.data.sum.priceCoefficientWithoutLand;
        this.data.sum.price = 0;
        // Compute final price proportionally to input summary amount
        this.data.opinion.parcelPrices.forEach(function (p) {
            if (_this.hasCoefficient) {
                if (_this.data.opinion.expertOpinionLand && priceCoefficientRoundedRemain && _this.data.sum.priceCoefficientRounded && p.expertOpinionLandSquarePrice > 0) {
                    if (!--parcelCount) {
                        p.expertOpinionLandPrice = Math.round(priceCoefficientRoundedRemain);
                    }
                    else {
                        p.expertOpinionLandPrice = Math.round((_this.data.sum.priceCoefficientRounded - _this.data.sum.priceCoefficientWithoutLand) *
                            ((p.expertOpinionLandPriceCoefficient || 0) + (p.expertOpinionVegetationPrice || 0) + (p.expertOpinionBuildingsPrice || 0))
                            / _this.data.sum.priceCoefficientWithLand);
                    }
                    p.expertOpinionLandPrice -= (p.expertOpinionVegetationPrice || 0);
                    p.expertOpinionLandPrice -= (p.expertOpinionBuildingsPrice || 0);
                    priceCoefficientRoundedRemain -= (p.expertOpinionLandPrice || 0) + (p.expertOpinionVegetationPrice || 0) + (p.expertOpinionBuildingsPrice || 0);
                }
                else {
                    p.expertOpinionLandPrice = null;
                }
            }
            else {
                p.expertOpinionLandPrice = p.expertOpinionLandPriceCoefficient;
            }
        });
    };
    PricingExpertOpinionSummaryComponent.prototype.computeFromInitial = function () {
        this.data.sum = __assign({}, PricingExpertOpinionSummaryComponent.DEFAULT_SUM);
        this.computeDefaultPrice(true);
        this.computeCoefficientPrice(true);
        this.computeRoundedPrice();
    };
    PricingExpertOpinionSummaryComponent.prototype.computeFromExisting = function () {
        var _this = this;
        this.data.sum = __assign({}, PricingExpertOpinionSummaryComponent.DEFAULT_SUM);
        this.data.opinion.parcelPrices.forEach(function (parcelPrice) {
            var expertOpinionVegetationPriceDefault = (parcelPrice.expertOpinionVegetationPriceDefault && _this.data.opinion.expertOpinionVegetation ? parcelPrice.expertOpinionVegetationPriceDefault : 0);
            var expertOpinionBuildingsPriceDefault = (parcelPrice.expertOpinionBuildingsPriceDefault && _this.data.opinion.expertOpinionBuildings ? parcelPrice.expertOpinionBuildingsPriceDefault : 0);
            parcelPrice.expertOpinionParcelPriceDefault = Math.round((isFinite(parcelPrice.expertOpinionLandSquarePrice) && _this.data.opinion.expertOpinionLand ? parcelPrice.expertOpinionLandSquarePrice : 0) * (parcelPrice.parcel ? parcelPrice.parcel.vymera : 0)) + expertOpinionVegetationPriceDefault + expertOpinionBuildingsPriceDefault;
            parcelPrice.expertOpinionParcelPriceDefaultRounded = parcelPrice.expertOpinionLandPriceDefault + expertOpinionVegetationPriceDefault + expertOpinionBuildingsPriceDefault;
            _this.data.sum.priceDefault += parcelPrice.expertOpinionParcelPriceDefault;
            _this.data.sum.priceDefaultRounded += parcelPrice.expertOpinionParcelPriceDefaultRounded;
            _this.data.sum.priceCoefficient += Math.round((parcelPrice.expertOpinionLandPriceCoefficient || 0)
                + (parcelPrice.expertOpinionVegetationPrice || 0)
                + (parcelPrice.expertOpinionBuildingsPrice || 0));
            _this.data.sum.priceCoefficientRounded +=
                (parcelPrice.expertOpinionLandPrice || 0)
                    + (parcelPrice.expertOpinionVegetationPrice || 0)
                    + (parcelPrice.expertOpinionBuildingsPrice || 0);
        });
    };
    return PricingExpertOpinionSummaryComponent;
}(pricing_expert_opinion_step_component_1.PricingExpertOpinionStepComponent));
exports.PricingExpertOpinionSummaryComponent = PricingExpertOpinionSummaryComponent;
