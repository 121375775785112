"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var _pull = require("lodash/pull");
var _remove = require("lodash/remove");
var dialog_config_1 = require("@app/dialog/dialog-config");
var dialog_ref_1 = require("@app/dialog/dialog-ref");
var StatisticsSettingsStatusEditComponent = /** @class */ (function () {
    function StatisticsSettingsStatusEditComponent(dialogConfig, dialogComponent) {
        this.dialogConfig = dialogConfig;
        this.dialogComponent = dialogComponent;
        this.requiredStatuses = [];
        this.onUpdate = this.onUpdate.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.reloadRegister = this.reloadRegister.bind(this);
    }
    StatisticsSettingsStatusEditComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.caseStatus = this.dialogConfig.data.caseStatus;
        this.caseStatus.requiredStatuses = this.caseStatus.requiredStatuses || [];
        this.statuses = this.dialogConfig.data.statuses.filter(function (status) { return status.key != _this.caseStatus.status; });
        this.caseStatus.requiredStatuses.forEach(function (requiredStatus) {
            var status = _this.statuses.find(function (status) { return status.key === requiredStatus.status; });
            if (status) {
                _this.requiredStatuses.push({
                    key: status.key,
                    name: status.name,
                    negation: requiredStatus.negation,
                });
            }
        });
    };
    StatisticsSettingsStatusEditComponent.prototype.selectedStatus = function (statusSelected, data) {
        data.selected = null;
        this.requiredStatuses.push({
            name: statusSelected.name,
            key: statusSelected.key,
            negation: false,
        });
        _remove(this.statuses, function (status) { return status.key === statusSelected.key; });
        this.reloadFn();
    };
    StatisticsSettingsStatusEditComponent.prototype.remove = function (requiredStatus) {
        _pull(this.requiredStatuses, requiredStatus);
        this.statuses.push({
            name: requiredStatus.name,
            key: requiredStatus.key,
            commonName: null,
            id: null,
        });
        this.reloadFn();
    };
    StatisticsSettingsStatusEditComponent.prototype.reloadRegister = function (reloadFn) {
        this.reloadFn = reloadFn;
    };
    StatisticsSettingsStatusEditComponent.prototype.isValid = function () {
        return true;
    };
    StatisticsSettingsStatusEditComponent.prototype.onUpdate = function () {
        this.caseStatus.requiredStatuses = this.requiredStatuses.map(function (status) {
            return {
                status: status.key,
                negation: status.negation,
            };
        });
        this.dialogComponent.close({ caseStatus: this.caseStatus });
    };
    StatisticsSettingsStatusEditComponent.prototype.onCancel = function () {
        this.dialogComponent.close();
    };
    return StatisticsSettingsStatusEditComponent;
}());
exports.StatisticsSettingsStatusEditComponent = StatisticsSettingsStatusEditComponent;
