"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./cadastre-update.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../../../../common/components/button/button.component.ngfactory");
var i3 = require("../../../../common/components/button/button.component");
var i4 = require("./cadastre-update.component");
var i5 = require("../../../../common/services/symap-restangular.service");
var i6 = require("../../../../common/services/auth.service");
var styles_CadastreUpdateComponent = [i0.styles];
var RenderType_CadastreUpdateComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CadastreUpdateComponent, data: {} });
exports.RenderType_CadastreUpdateComponent = RenderType_CadastreUpdateComponent;
function View_CadastreUpdateComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "column-100"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Odeslat po\u017Eadavky na \u0159\u00E1dnou m\u011Bs\u00ED\u010Dn\u00ED aktualizaci dat KN z aktivn\u00EDch projekt\u016F do PROMAPu (negeneruje XML \u017E\u00E1dost, pouze zalo\u017E\u00ED po\u017Eadavky na data v PROMAPu)."])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "gmt-button", [], null, null, null, i2.View_ButtonComponent_0, i2.RenderType_ButtonComponent)), i1.ɵdid(5, 114688, null, 0, i3.ButtonComponent, ["ErrorHandlerService", i1.ChangeDetectorRef], { clickAction: [0, "clickAction"], gmtDisabled: [1, "gmtDisabled"] }, null), (_l()(), i1.ɵted(-1, 0, ["Prov\u00E9st"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.onSend; var currVal_1 = !_co.isValid(); _ck(_v, 5, 0, currVal_0, currVal_1); }, null); }
exports.View_CadastreUpdateComponent_0 = View_CadastreUpdateComponent_0;
function View_CadastreUpdateComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "cadastre-update", [], null, null, null, View_CadastreUpdateComponent_0, RenderType_CadastreUpdateComponent)), i1.ɵdid(1, 114688, null, 0, i4.CadastreUpdateComponent, [i5.RESTANGULAR_SYMAP, i6.AuthService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_CadastreUpdateComponent_Host_0 = View_CadastreUpdateComponent_Host_0;
var CadastreUpdateComponentNgFactory = i1.ɵccf("cadastre-update", i4.CadastreUpdateComponent, View_CadastreUpdateComponent_Host_0, {}, {}, []);
exports.CadastreUpdateComponentNgFactory = CadastreUpdateComponentNgFactory;
