"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./project-list.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/common");
var i3 = require("./project-list.component");
var i4 = require("../../services/customer.service");
var i5 = require("../../../../dialog/dialog.service");
var styles_ProjectListComponent = [i0.styles];
var RenderType_ProjectListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProjectListComponent, data: {} });
exports.RenderType_ProjectListComponent = RenderType_ProjectListComponent;
function View_ProjectListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "tr", [["class", "row-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "td", [["class", "cell"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeProject(_v.context.$implicit.id) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "i", [["class", "fa fa-trash pr-15"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "td", [["class", "cell"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = (_v.context.$implicit.applicationName || _v.context.$implicit.application); _ck(_v, 4, 0, currVal_0); var currVal_1 = (_v.context.$implicit.projectName || _v.context.$implicit.projectKey); _ck(_v, 6, 0, currVal_1); }); }
function View_ProjectListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "table", [["class", "bordered"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "thead", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "th", [["class", "cell"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Aplikace"])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "th", [["class", "cell"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Projekt"])), (_l()(), i1.ɵeld(6, 0, null, null, 2, "tbody", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProjectListComponent_2)), i1.ɵdid(8, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.projects; _ck(_v, 8, 0, currVal_0); }, null); }
function View_ProjectListComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "empty-info pb-20 pt-20 bordered centered-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Ke smlouv\u011B dosud nebyl p\u0159i\u0159azen \u017E\u00E1dn\u00FD projekt."]))], null, null); }
function View_ProjectListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "mt-10"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProjectListComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProjectListComponent_3)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hasProjects(); _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.hasProjects(); _ck(_v, 4, 0, currVal_1); }, null); }
exports.View_ProjectListComponent_0 = View_ProjectListComponent_0;
function View_ProjectListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "project-list", [], null, null, null, View_ProjectListComponent_0, RenderType_ProjectListComponent)), i1.ɵdid(1, 114688, null, 0, i3.ProjectListComponent, ["APPLICATIONS", i4.CustomerService, i5.DialogService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_ProjectListComponent_Host_0 = View_ProjectListComponent_Host_0;
var ProjectListComponentNgFactory = i1.ɵccf("project-list", i3.ProjectListComponent, View_ProjectListComponent_Host_0, { projects: "projects", customerId: "customerId" }, { projectRemoval: "projectRemoval" }, []);
exports.ProjectListComponentNgFactory = ProjectListComponentNgFactory;
