"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var vfze_import_step_component_1 = require("@app/ps/vfze/components/vfze-import-step/vfze-import-step.component");
var VfzeImportRawDataComponent = /** @class */ (function (_super) {
    __extends(VfzeImportRawDataComponent, _super);
    function VfzeImportRawDataComponent(restangular, uploadService, errorHandlerService) {
        var _this = _super.call(this) || this;
        _this.restangular = restangular;
        _this.uploadService = uploadService;
        _this.errorHandlerService = errorHandlerService;
        _this.imported = false;
        _this.onSubmit = _this.onSubmit.bind(_this);
        return _this;
    }
    VfzeImportRawDataComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.uploadService.upload({
            url: this.restangular.configuration.baseUrl + '/vfze/save',
            data: { vfze: this.data.files[0].fileEntry },
            headers: __assign({}, this.restangular.defaultHeaders)
        }).then(function (data) {
            _this.imported = true;
        }, function (r) {
            new _this.errorHandlerService()(r);
            _this.backCallback.emit();
        });
    };
    VfzeImportRawDataComponent.prototype.onSubmit = function () {
        this.submitCallback.emit();
    };
    return VfzeImportRawDataComponent;
}(vfze_import_step_component_1.VfzeImportStepComponent));
exports.VfzeImportRawDataComponent = VfzeImportRawDataComponent;
