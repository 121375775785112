"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vfze_list_component_1 = require("@app/ps/vfze/components/vfze-list/vfze-list.component");
var vfze_detail_component_1 = require("@app/ps/vfze/components/vfze-detail/vfze-detail.component");
var state_utils_1 = require("@app/common/utils/state.utils");
var vfze_import_component_1 = require("@app/ps/vfze/components/vfze-import/vfze-import.component");
exports.vfzeListState = {
    name: 'symap.project.vfze',
    url: '/vfze?page',
    data: {
        title: 'Přehled importů VFZE',
    },
    views: {
        'content@symap.project': {
            component: vfze_list_component_1.VfzeListComponent,
        },
    },
    params: {
        page: {
            dynamic: true,
            type: 'int',
            inherit: false,
        },
    },
};
exports.vfzeDetailState = {
    name: 'symap.project.vfze.detail',
    url: '/:id',
    data: {
        title: 'Detail VFZE',
    },
    views: {
        'content@symap.project': {
            component: vfze_detail_component_1.VfzeDetailComponent,
        }
    },
    resolve: {
        vfzeId: ['$stateParams', state_utils_1.resolveId]
    },
    params: {
        id: {
            type: 'int',
            value: undefined
        },
    },
};
exports.vfzeImportState = {
    name: 'symap.project.vfze.import',
    url: '/import',
    data: {
        title: 'Import VFZE',
    },
    views: {
        'content@symap.project': {
            component: vfze_import_component_1.VfzeImportComponent,
        }
    }
};
