"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["[_nghost-%COMP%] {\n  position: absolute;\n  height: 100%;\n  width: 100%;\n  z-index: 11;\n  pointer-events: none; }\n\n[_nghost-%COMP%]   *[_ngcontent-%COMP%] {\n  pointer-events: auto; }"];
exports.styles = styles;
