"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var auth_service_1 = require("../../services/auth.service");
var ApplicationsComponent = /** @class */ (function () {
    function ApplicationsComponent(authService, APP_BRAND) {
        this.authService = authService;
        this.APP_BRAND = APP_BRAND;
    }
    ApplicationsComponent.prototype.hasApplication = function (applicationId) {
        return this.applications.includes(applicationId);
    };
    return ApplicationsComponent;
}());
exports.ApplicationsComponent = ApplicationsComponent;
