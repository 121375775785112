"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./cell-opinions.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@uirouter/angular/lib/directives/uiSref");
var i3 = require("@uirouter/core");
var i4 = require("@angular/common");
var i5 = require("./cell-opinions.component");
var styles_CellOpinions = [i0.styles];
var RenderType_CellOpinions = i1.ɵcrt({ encapsulation: 0, styles: styles_CellOpinions, data: {} });
exports.RenderType_CellOpinions = RenderType_CellOpinions;
function View_CellOpinions_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = ($event.stopPropagation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "a", [["uiSref", "symap.project.opinionRequests.detail"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).go($event.button, $event.ctrlKey, $event.metaKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 16384, null, 0, i2.AnchorUISref, [i1.ElementRef, i1.Renderer2], null, null), i1.ɵdid(4, 737280, null, 0, i2.UISref, [i3.UIRouter, [2, i2.AnchorUISref], "UIView.PARENT_INJECT"], { state: [0, "state"], params: [1, "params"] }, null), i1.ɵpod(5, { id: 0, tab: 1 }), (_l()(), i1.ɵted(6, null, [" ", " "]))], function (_ck, _v) { var currVal_0 = "symap.project.opinionRequests.detail"; var currVal_1 = _ck(_v, 5, 0, _v.context.$implicit.id, "parcels"); _ck(_v, 4, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.name; _ck(_v, 6, 0, currVal_2); }); }
function View_CellOpinions_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = (_v.parent.context.$implicit.expertOpinionNumber || _v.parent.context.$implicit.preliminaryOpinionNumber); _ck(_v, 1, 0, currVal_0); }); }
function View_CellOpinions_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CellOpinions_3)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.shouldBeOpinionDisplayed(_v.context.$implicit); _ck(_v, 2, 0, currVal_0); }, null); }
function View_CellOpinions_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_CellOpinions_1)), i1.ɵdid(1, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CellOpinions_2)), i1.ɵdid(3, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.opinionRequests; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.opinions; _ck(_v, 3, 0, currVal_1); }, null); }
exports.View_CellOpinions_0 = View_CellOpinions_0;
function View_CellOpinions_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "cell-opinions", [], null, null, null, View_CellOpinions_0, RenderType_CellOpinions)), i1.ɵdid(1, 49152, null, 0, i5.CellOpinions, [], null, null)], null, null); }
exports.View_CellOpinions_Host_0 = View_CellOpinions_Host_0;
var CellOpinionsNgFactory = i1.ɵccf("cell-opinions", i5.CellOpinions, View_CellOpinions_Host_0, { opinionRequests: "opinionRequests", opinions: "opinions" }, {}, []);
exports.CellOpinionsNgFactory = CellOpinionsNgFactory;
