"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var parcel_model_1 = require("../models/parcel.model");
var string_utils_1 = require("@app/common/utils/string.utils");
var i0 = require("@angular/core");
var ParcelNumberPipe = /** @class */ (function () {
    function ParcelNumberPipe() {
        this.simpleParcelNumberRegExp = new RegExp('^(\\d+)(\\/(\\d+))?(\\((\\d+)\\))?$', 'i');
    }
    ParcelNumberPipe.prototype.transform = function (parcel, type) {
        if (type === void 0) { type = 'complex'; }
        return (type === 'complex'
            ? this.transformComplex(parcel)
            : this.transformSimple(parcel));
    };
    ParcelNumberPipe.prototype.parse = function (parcelNumber) {
        if (!this.isParcelNumberValid(parcelNumber)) {
            return null;
        }
        var parcel = new parcel_model_1.ParcelModel();
        var matches = this.simpleParcelNumberRegExp.exec(string_utils_1.StringUtils.removeSpaces(parcelNumber));
        if (matches[1]) {
            parcel.parcisKmen = parseInt(matches[1]);
        }
        if (matches[3]) {
            parcel.parcisPod = parseInt(matches[3]);
        }
        if (matches[5]) {
            parcel.parcisDil = parseInt(matches[5]);
        }
        return parcel;
    };
    ParcelNumberPipe.prototype.transformComplex = function (parcel) {
        var result = '';
        if (!parcel) {
            return result;
        }
        if (parcel.future) {
            result += 'budoucí';
        }
        else if (parcel.zeKod && parcel.zeKod === 3) {
            result += 'EN';
        }
        else if (parcel.zeKod && parcel.zeKod === 4) {
            result += 'PK';
        }
        else if (parcel.zeKod) {
            result += 'GP';
        }
        else {
            result += 'KN';
        }
        result += ' ';
        if ((parcel.knsk12 && parcel.knsk12 === 1) ||
            (parcel.knsk12 && parcel.knsk12 === 2 && parcel.kndruhp && parcel.kndruhp === 'zastavěná plocha a nádvoří') ||
            (parcel.cislKod && parcel.cislKod === 1) ||
            (parcel.cislKod && parcel.cislKod === 2 && parcel.drupozKod && parcel.drupozKod === 13)) {
            result += ' st. ';
        }
        result += parcel.parcisKmen;
        result += (parcel.parcisPod ? '/' + parcel.parcisPod : '');
        result += (parcel.parcisDil ? ' (' + parcel.parcisDil + ')' : '');
        return result;
    };
    ParcelNumberPipe.prototype.transformSimple = function (parcel) {
        var ret = '';
        if (!parcel.parcisKmen) {
            return ret;
        }
        ret += parcel.parcisKmen;
        if (!!parcel.parcisPod) {
            ret += '/' + parcel.parcisPod;
        }
        if (!!parcel.parcisDil) {
            ret += '(' + parcel.parcisDil + ')';
        }
        return ret;
    };
    ParcelNumberPipe.prototype.isParcelNumberValid = function (parcelNumber) {
        return this.simpleParcelNumberRegExp.test(string_utils_1.StringUtils.removeSpaces(parcelNumber));
    };
    ParcelNumberPipe.ngInjectableDef = i0.defineInjectable({ factory: function ParcelNumberPipe_Factory() { return new ParcelNumberPipe(); }, token: ParcelNumberPipe, providedIn: "root" });
    return ParcelNumberPipe;
}());
exports.ParcelNumberPipe = ParcelNumberPipe;
