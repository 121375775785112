
var common = angular.module('common');

/**
 * Displays loading indicator
 */
common.directive('loading', function() {
        return {
            restrict: 'A',
            scope: {
                loadState: '='
            },
            link: function(scope, element) {
                scope.$watch('loadState', function(val) {
                    var loading = $(element).children('.loader');
                    if (val) {
                        if (loading.length === 0) {
                            $(element).prepend('<div class="loader"><span class="loading-spinner"></span></div>');
                        }

                        $(element).addClass('content-loading');
                    } else {
                        $(element).removeClass('content-loading');
                    }
                });
            }
        };
    });
