"use strict";
var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var brand_translate_pipe_1 = require("@app/common/pipes/brand-translate.pipe");
var readiness_state_enum_1 = require("@app/ps/enums/readiness-state.enum");
var i0 = require("@angular/core");
var i1 = require("../../common/pipes/brand-translate.pipe");
var ProjectStatePipe = /** @class */ (function () {
    function ProjectStatePipe(brandTranslatePipe) {
        this.brandTranslatePipe = brandTranslatePipe;
    }
    ProjectStatePipe.prototype.transform = function (project) {
        var state = this.getEnum(project);
        if (!state) {
            return '';
        }
        return this.brandTranslatePipe.transform('ps.projectState.' + state);
    };
    ProjectStatePipe.prototype.getEnum = function (project) {
        if (!project.state) {
            return undefined;
        }
        if (project.state !== 'STARTED' || (!project.readinessPermanentOccupations &&
            !project.readinessTemporaryUnderOccupations &&
            !project.readinessTemporaryOverOccupations &&
            !project.readinessTemporaryEasements)) {
            return project.state;
        }
        return this.isPartial(project)
            ? project.state + '_PARTIAL'
            : project.state + '_COMPLETE';
    };
    ProjectStatePipe.prototype.isPartial = function (project) {
        var e_1, _a;
        var states = [readiness_state_enum_1.ReadinessStateEnum.PARTIAL_DATA, readiness_state_enum_1.ReadinessStateEnum.NOT_DELIVERED_DATA];
        var fields = ['readinessPermanentOccupations', 'readinessTemporaryUnderOccupations', 'readinessTemporaryOverOccupations', 'readinessTemporaryEasements'];
        try {
            for (var fields_1 = __values(fields), fields_1_1 = fields_1.next(); !fields_1_1.done; fields_1_1 = fields_1.next()) {
                var field = fields_1_1.value;
                if (project[field] && states.includes(project[field])) {
                    return true;
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (fields_1_1 && !fields_1_1.done && (_a = fields_1.return)) _a.call(fields_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return false;
    };
    ProjectStatePipe.ngInjectableDef = i0.defineInjectable({ factory: function ProjectStatePipe_Factory() { return new ProjectStatePipe(i0.inject(i1.BrandTranslatePipe)); }, token: ProjectStatePipe, providedIn: "root" });
    return ProjectStatePipe;
}());
exports.ProjectStatePipe = ProjectStatePipe;
