"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var vfze_import_step_component_1 = require("@app/ps/vfze/components/vfze-import-step/vfze-import-step.component");
var VfzeImportUploadComponent = /** @class */ (function (_super) {
    __extends(VfzeImportUploadComponent, _super);
    function VfzeImportUploadComponent(restangular, uploadService) {
        var _this = _super.call(this) || this;
        _this.restangular = restangular;
        _this.uploadService = uploadService;
        _this.filesMsg = VfzeImportUploadComponent.MSG_FILES_DEFAULT;
        _this.files = [];
        _this.isFileValidToUpload = false;
        _this.onSubmit = _this.onSubmit.bind(_this);
        return _this;
    }
    VfzeImportUploadComponent.prototype.ngOnInit = function () {
        this.files = this.data.files || [];
        this.onFilesChange(this.files);
    };
    VfzeImportUploadComponent.prototype.onFilesChange = function (files) {
        this.files = files;
        if (this.files.length > 1) {
            this.filesMsg = VfzeImportUploadComponent.MSG_FILES_QUANTITY;
        }
        else if (this.files.length === 0) {
            this.filesMsg = VfzeImportUploadComponent.MSG_FILES_DEFAULT;
        }
        else {
            this.filesMsg = '';
        }
        this.isFileValidToUpload = this.files.length === 1;
    };
    VfzeImportUploadComponent.prototype.onSubmit = function () {
        this.data.files = this.files;
        this.submitCallback.emit();
    };
    VfzeImportUploadComponent.MSG_FILES_DEFAULT = 'Vložte soubor typu VFZE přetažením, nebo jej vyberte z počítače.';
    VfzeImportUploadComponent.MSG_FILES_QUANTITY = 'Je možné vložit jenom jeden soubor.';
    return VfzeImportUploadComponent;
}(vfze_import_step_component_1.VfzeImportStepComponent));
exports.VfzeImportUploadComponent = VfzeImportUploadComponent;
