"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var i0 = require("@angular/core");
var PricePipe = /** @class */ (function () {
    function PricePipe() {
        this.nbsp = String.fromCharCode(160);
    }
    PricePipe.prototype.transform = function (amount) {
        if (!amount) {
            return amount;
        }
        return "" + amount + this.nbsp + "K\u010D";
    };
    PricePipe.ngInjectableDef = i0.defineInjectable({ factory: function PricePipe_Factory() { return new PricePipe(); }, token: PricePipe, providedIn: "root" });
    return PricePipe;
}());
exports.PricePipe = PricePipe;
