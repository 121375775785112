"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var UrlHighlightPipe = /** @class */ (function () {
    function UrlHighlightPipe() {
    }
    UrlHighlightPipe.prototype.transform = function (text) {
        if (text.match(UrlHighlightPipe.URL_REGEXP)) {
            text = text.replace(UrlHighlightPipe.URL_REGEXP, UrlHighlightPipe.urlReplace);
        }
        if (text.match(UrlHighlightPipe.EMAIL_REGEXP)) {
            text = text.replace(UrlHighlightPipe.EMAIL_REGEXP, '<a href="mailto:$&" class="underlined">$&</a>');
        }
        return text;
    };
    UrlHighlightPipe.urlReplace = function (text) {
        var visibleText = text;
        if (text.indexOf('www') === 0) {
            text = "https://" + text;
        }
        return "<a href=\"" + text + "\" class=\"underlined\" target=\"_blank\">" + visibleText + "</a>";
    };
    UrlHighlightPipe.URL_REGEXP = /(((http|ftp|https):\/\/)|(www\.))([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:/~+#!-]*[\w@?^=%&/~+#-])?/g;
    UrlHighlightPipe.EMAIL_REGEXP = /(\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,6})/gim;
    return UrlHighlightPipe;
}());
exports.UrlHighlightPipe = UrlHighlightPipe;
