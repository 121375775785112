"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var list_service_1 = require("@app/common/services/list.service");
var statistics_service_1 = require("../../services/statistics.service");
var construction_object_name_pipe_1 = require("../../../common/pipes/construction-object-name.pipe");
var settings_service_1 = require("@app/ps/services/settings.service");
var StatisticsEntityFilterComponent = /** @class */ (function () {
    function StatisticsEntityFilterComponent(statisticsService, listLoadService, constructionObjectNamePipe, settingsService) {
        this.statisticsService = statisticsService;
        this.listLoadService = listLoadService;
        this.constructionObjectNamePipe = constructionObjectNamePipe;
        this.settingsService = settingsService;
        this.registerCallback = new core_1.EventEmitter();
        this.changed = new core_1.EventEmitter();
        this.defaultPipe = new (/** @class */ (function () {
            function DefaultPipe() {
            }
            DefaultPipe.prototype.transform = function (item) {
                return item.name;
            };
            return DefaultPipe;
        }()))();
        this.updateEntityFilterKey = this.updateEntityFilterKey.bind(this);
    }
    StatisticsEntityFilterComponent.prototype.ngOnInit = function () {
        this.registerCallback.emit({
            updateEntityFilterKey: this.updateEntityFilterKey,
        });
        this.loadFilterStatisticsName();
    };
    StatisticsEntityFilterComponent.prototype.checkFilterStatistics = function () {
        var exists = this.filter.filters.statisticsEntityFilterKey;
        return exists && exists.values && exists.values.length > 0 && exists.values.every(function (k) {
            return [5, 6].includes(k.id.split('_').length);
        });
    };
    StatisticsEntityFilterComponent.prototype.updateEntityFilterKey = function (value) {
        this.filter.filters.statisticsEntityFilterKey = {
            values: (value && value.length ? value.map(function (v) { return { id: v }; }) : [])
        };
        this.loadFilterStatisticsName();
        this.changed.emit({
            state: this.checkFilterStatistics(),
        });
    };
    StatisticsEntityFilterComponent.prototype.getFilterStatisticsParam = function (index, paramIndex) {
        if (!this.checkFilterStatistics()) {
            return null;
        }
        var value = this.filter.filters.statisticsEntityFilterKey.values[index].id;
        return Number(value.split('_')[paramIndex]);
    };
    StatisticsEntityFilterComponent.prototype.isSplitCategory = function (index) {
        var groups = this.filter.filters.statisticsEntityFilterKey.values[index].id.split('_');
        return 6 === groups.length && groups[5] === '1';
    };
    StatisticsEntityFilterComponent.prototype.resolveCategoryName = function (index) {
        var _this = this;
        return this.statisticsService.getStatisticsFilters().promise.then(function (data) {
            var foundStatistics = data.list.find(function (item) { return item.id === _this.getFilterStatisticsParam(index, 0); });
            var names = {};
            if (foundStatistics) {
                names.name = foundStatistics.name;
                var categoryId = _this.getFilterStatisticsParam(index, 1);
                if (categoryId === 0) {
                    names.categoryName = 'Celkem';
                }
                else if (categoryId === 1) {
                    names.categoryName = foundStatistics.defaultCategory;
                }
                else {
                    var foundCategory = foundStatistics.categories[categoryId - 2];
                    if (foundCategory) {
                        names.categoryName = _this.isSplitCategory(index) ? foundCategory.splitName : foundCategory.name;
                    }
                }
            }
            return names;
        });
    };
    StatisticsEntityFilterComponent.prototype.loadFilterStatisticsName = function () {
        var _this = this;
        if (!this.checkFilterStatistics()) {
            return;
        }
        this.filterNames = [];
        this.filter.filters.statisticsEntityFilterKey.values.forEach(function (v, i) {
            var promises = [];
            var entities = [
                {
                    entityId: _this.getFilterStatisticsParam(i, 2),
                    path: 'areas',
                    nameAttr: 'areaName',
                    filter: _this.defaultPipe,
                },
                {
                    entityId: _this.getFilterStatisticsParam(i, 3),
                    path: 'construction-objects',
                    nameAttr: 'constructionObjectName',
                    filter: _this.constructionObjectNamePipe,
                },
                {
                    entityId: _this.getFilterStatisticsParam(i, 4),
                    path: 'construction-object-stages',
                    nameAttr: 'constructionObjectStageName',
                    filter: _this.defaultPipe,
                }
            ];
            entities.forEach(function (entity) {
                promises.push(entity.entityId ? _this.listLoadService.fetchResult(_this.listLoadService.createList(entity.path, {
                    entityIds: [entity.entityId],
                    limit: 1,
                })).then(function (data) { return __awaiter(_this, void 0, void 0, function () {
                    var names;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                names = {};
                                if (!data.list.length) return [3 /*break*/, 2];
                                return [4 /*yield*/, this.settingsService.getSettingsLoader()];
                            case 1:
                                _a.sent();
                                names[entity.nameAttr] = entity.filter.transform(data.list[0]);
                                _a.label = 2;
                            case 2: return [2 /*return*/, names];
                        }
                    });
                }); }) : Promise.resolve({}));
            });
            promises.push(_this.resolveCategoryName(i));
            Promise.all(promises).then(function (data) {
                var namesObj = {};
                data.forEach(function (names) {
                    Object.assign(namesObj, names);
                });
                _this.filterNames.push(namesObj);
            });
        });
    };
    return StatisticsEntityFilterComponent;
}());
exports.StatisticsEntityFilterComponent = StatisticsEntityFilterComponent;
