"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var auth_service_1 = require("@app/common/services/auth.service");
var string_utils_1 = require("@app/common/utils/string.utils");
var ImportComponent = /** @class */ (function () {
    function ImportComponent(restangular, uploadService, authService) {
        this.restangular = restangular;
        this.uploadService = uploadService;
        this.authService = authService;
        this.projectName = '';
        this.files = [];
        this.filesMsg = ImportComponent.MSG_FILES_DEFAULT;
        this.isFileValidToUpload = false;
        this.importResponse = '';
        this.onImport = this.onImport.bind(this);
        this.onChangeProjectName = this.onChangeProjectName.bind(this);
    }
    ImportComponent.prototype.ngOnInit = function () {
    };
    ImportComponent.prototype.onImport = function () {
        var _this = this;
        this.importResponse = '';
        var restBaseUrl = this.authService.getActiveApplicationRestUrl();
        var headers = __assign({}, this.restangular.defaultHeaders);
        return this.uploadService.upload({
            url: restBaseUrl + '/convert',
            data: {
                vfze: this.files[0].fileEntry,
                project: this.projectName,
            },
            headers: headers
        }).then(function (response) {
            _this.importResponse = "Soubor '" + _this.files[0].fileEntry.name + "' pro projekt '" + _this.projectName + "' byl importov\u00E1n";
            _this.files = [];
            _this.filesMsg = ImportComponent.MSG_FILES_DEFAULT;
            _this.projectName = '';
            _this.isFileValidToUpload = false;
        });
    };
    ImportComponent.prototype.onChangeProjectName = function (projectName) {
        this.projectName = string_utils_1.StringUtils.removeSpaces(projectName);
    };
    ImportComponent.prototype.isValidToImport = function () {
        return !!(this.isFileValidToUpload && this.projectName);
    };
    ImportComponent.prototype.onFilesChange = function (files) {
        this.files = files;
        if (this.files.length > 1) {
            this.filesMsg = ImportComponent.MSG_FILES_QUANTITY;
        }
        else if (this.files.length === 0) {
            this.filesMsg = ImportComponent.MSG_FILES_DEFAULT;
        }
        else {
            this.filesMsg = '';
        }
        this.isFileValidToUpload = this.files.length === 1;
    };
    ImportComponent.MSG_FILES_DEFAULT = 'Vložte soubor typu VFZE přetažením, nebo jej vyberte z počítače.';
    ImportComponent.MSG_FILES_QUANTITY = 'Je možné vložit jenom jeden soubor.';
    return ImportComponent;
}());
exports.ImportComponent = ImportComponent;
