"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var _ = require("lodash");
var file_saver_1 = require("file-saver");
var manager_report_projects_service_1 = require("@app/landing/services/manager-report-projects.service");
var auth_service_1 = require("@app/common/services/auth.service");
var modules_service_1 = require("@app/common/services/modules.service");
var pageable_list_service_1 = require("@app/common/services/pageable.list.service");
var settings_service_1 = require("@app/ps/services/settings.service");
var word_service_1 = require("@app/common/services/word.service");
var statistics_service_1 = require("@app/ps/services/statistics.service");
var list_service_1 = require("@app/common/services/list.service");
var common_1 = require("@angular/common");
var percentage_pipe_1 = require("@app/common/pipes/percentage.pipe");
var angular_1 = require("@uirouter/angular");
var readiness_state_enum_1 = require("@app/ps/enums/readiness-state.enum");
var ManagerReportProjectDetailComponent = /** @class */ (function () {
    function ManagerReportProjectDetailComponent(APPLICATIONS, helpIds, restangular, restangularConfig, config, restangularSymap, percentagePipe, datePipe, listService, statisticsService, wordService, settingsService, pageableListService, authService, projectsService, modulesService, transitionService) {
        this.APPLICATIONS = APPLICATIONS;
        this.helpIds = helpIds;
        this.restangular = restangular;
        this.restangularConfig = restangularConfig;
        this.config = config;
        this.restangularSymap = restangularSymap;
        this.percentagePipe = percentagePipe;
        this.datePipe = datePipe;
        this.listService = listService;
        this.statisticsService = statisticsService;
        this.wordService = wordService;
        this.settingsService = settingsService;
        this.pageableListService = pageableListService;
        this.authService = authService;
        this.projectsService = projectsService;
        this.modulesService = modulesService;
        this.transitionService = transitionService;
        this.loading = true;
        this.tabsLoading = false;
        this.showPercentage = true;
        this.canVisitDetail = false;
        this.totalCategoryTabs = [
            {
                name: 'Celkový počet všech případů'
            },
            {
                name: 'Nezaložené případy'
            },
            {
                name: 'Případy v řešení'
            },
            {
                name: 'Vypořádané případy'
            },
            {
                name: 'Vyvlastnění'
            },
        ];
        this.casesCategoryTabs = [
            {
                name: 'Trvalý zábor',
                prefix: 'tz',
                mapLayerPrefix: 'permanent_occupation_status_',
                readinesName: 'readinessPermanentOccupations',
            },
            {
                name: 'Dočasný zábor do 1 roku',
                prefix: 'dzdr',
                mapLayerPrefix: 'temporary_occupation_under_one_year_status_',
                readinesName: 'readinessTemporaryUnderOccupations',
            },
            {
                name: 'Dočasný zábor nad 1 rok',
                prefix: 'dznr',
                mapLayerPrefix: 'temporary_occupation_over_one_year_status_',
                readinesName: 'readinessTemporaryOverOccupations',
            },
            {
                name: 'Věcné břemeno',
                prefix: 'vb',
                mapLayerPrefix: 'easement_status2_',
                readinesName: 'readinessTemporaryEasements',
            },
        ];
        this.entityTabs = [
            {
                value: 'Případy',
                id: 'cases',
                endpoint: 'cases',
                columns: ['caseNumber', 'areaName', 'lv', 'owner', 'caseType', 'caseStatus', 'respectiveDate', 'constructionObjects', 'price'],
                defaultFilter: {
                    filters: {
                        loadCollections: [
                            'titles',
                            'caseOwnerships',
                            'constructionObjects',
                            'caseSubjects',
                        ],
                        cancelStatus: { values: [{ id: 'notCancelled' }] },
                        searchText: { values: [{ id: '' }] },
                    }
                },
            },
            {
                value: 'Vlastníci',
                id: 'subjects',
                endpoint: 'subjects',
                defaultFilter: {
                    filters: {
                        loadCollections: ['ownerships', 'occupationResolution'],
                        searchText: { values: [{ id: '' }] },
                        validity: { values: ['withOccupationOrEasement', 'invalidWithCase'] },
                        opsubType: { values: [{ id: 'BSM' }], negation: true },
                    }
                },
            },
            {
                value: 'Parcely',
                id: 'parcels',
                endpoint: 'parcels',
                defaultFilter: {
                    filters: {
                        validity: 'valid',
                        searchText: { values: [{ id: '' }] },
                        loadCollections: [
                            'title.ownerships',
                            'geometricPlanRequests',
                            'opinionRequests',
                            'constructionObjects',
                            'knBudoucPepvs',
                            'parcelPrices'
                        ]
                    }
                },
            },
            {
                value: 'Listy vlastnictví',
                id: 'titles',
                endpoint: 'titles',
                columns: ['lv', 'areaName', 'owner', 'case', 'pernamentOccupationState', 'overOneYearOccupationState', 'underOneYearOccupationState', 'easementCaseState', 'blocked'],
                defaultFilter: {
                    filters: {
                        validity: 'valid',
                        searchText: { values: [{ id: '' }] },
                        loadCollections: [
                            'ownerships',
                            'occupationResolution',
                            'duplicityOwnership'
                        ],
                    }
                },
            },
            {
                value: 'Vyvlastnění',
                id: 'expropriation',
                endpoint: 'cases',
                columns: ['caseNumber', 'areaName', 'lv', 'owner', 'caseType', 'caseStatus', 'respectiveDate', 'constructionObjects', 'price'],
                defaultFilter: {
                    filters: {
                        loadCollections: [
                            'titles',
                            'caseOwnerships',
                            'constructionObjects',
                            'caseSubjects',
                        ],
                        cancelStatus: { values: [{ id: 'notCancelled' }] },
                        searchText: { values: [{ id: '' }] },
                        obligationId: { values: [] }
                    }
                },
            },
        ];
        this.onExportXlsx = this.onExportXlsx.bind(this);
        this.onExportPdf = this.onExportPdf.bind(this);
        this.onPageChanged = this.onPageChanged.bind(this);
    }
    ManagerReportProjectDetailComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.deregisterTransition = this.transitionService.onBefore({}, (function (transition) {
                            var params = transition.targetState().params();
                            var name = transition.targetState().name();
                            if (!name.includes('symap.project') || params.projectKey) {
                                return;
                            }
                            params.projectKey = _this.projectKey;
                            return transition.router.stateService.target(transition.to(), params);
                        }));
                        this.canVisitDetail = this.authService.hasPermissionOnProject('web', this.projectKey, this.symapPermissions);
                        this.restangular.setBaseUrl(this.config.BACKEND_OPTIONS.restUrlSY + '/' + this.projectKey);
                        return [4 /*yield*/, this.loadProject()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.loadDashboard()];
                    case 2:
                        _a.sent();
                        this.loading = false;
                        return [4 /*yield*/, this.settingsService.loadSettings(this.project.id)];
                    case 3:
                        _a.sent();
                        return [4 /*yield*/, this.wordService.setVocabulary(this.project.id)];
                    case 4:
                        _a.sent();
                        return [4 /*yield*/, this.loadExpropriationObligationIds()];
                    case 5:
                        _a.sent();
                        this.onChangeEntity('cases');
                        return [2 /*return*/];
                }
            });
        });
    };
    ManagerReportProjectDetailComponent.prototype.ngOnDestroy = function () {
        if (this.xhrCancelResolve) {
            this.xhrCancelResolve();
        }
        this.deregisterTransition();
    };
    ManagerReportProjectDetailComponent.prototype.onChangeEntity = function (entityId) {
        var selectedEntity = _.find(this.entityTabs, { id: entityId });
        if (!selectedEntity.list) {
            selectedEntity.pageableList = this.pageableListService.get(selectedEntity.endpoint, selectedEntity.defaultFilter, undefined, this.restangular);
            selectedEntity.list = selectedEntity.pageableList.list;
            this.selectedEntity = selectedEntity;
        }
        else {
            this.selectedEntity = selectedEntity;
        }
    };
    ManagerReportProjectDetailComponent.prototype.onExportXlsx = function () {
        return this.restangularSymap.one("manager-report/" + this.projectKey + "?type=xlsx")
            .withHttpConfig({
            responseType: 'blob',
            transformResponse: function (response, header) {
                return [response, header];
            }
        })
            .get()
            .then(function (response) {
            file_saver_1.saveAs(response[0], 'manager-report-detail.xlsx', true);
        });
    };
    ManagerReportProjectDetailComponent.prototype.onExportPdf = function () {
        return this.restangularSymap.one("manager-report/" + this.projectKey + "?type=pdf")
            .withHttpConfig({
            responseType: 'blob',
            transformResponse: function (response, header) {
                return [response, header];
            }
        })
            .get()
            .then(function (response) {
            file_saver_1.saveAs(response[0], 'manager-report-detail.pdf', true);
        });
    };
    ManagerReportProjectDetailComponent.prototype.onPageChanged = function (event) {
        this.selectedEntity.pageableList.fetchResults(event);
    };
    ManagerReportProjectDetailComponent.prototype.loadProject = function () {
        var _this = this;
        return this.modulesService.loadSyMAPProjectById(this.projectKey).then(function (data) {
            _this.project = _this.projectsService.createFromApi(data);
        });
    };
    ManagerReportProjectDetailComponent.prototype.loadDashboard = function () {
        var _this = this;
        var cancelPromise = new Promise(function (resolve, reject) {
            _this.xhrCancelResolve = resolve;
        });
        return this.restangularSymap.all('dashboard')
            .withHttpConfig({ timeout: cancelPromise })
            .post()
            .then(function (data) {
            var dashboard = data.data.find(function (dashboard) { return dashboard.key === _this.projectKey; });
            if (!dashboard) {
                _this.totalAvailable = false;
                return;
            }
            _this.totalAvailable = true;
            manager_report_projects_service_1.ManagerReportProjectsService.mapDashboardDataOnProject(_this.project, dashboard);
            _this.totalCategoryTabs[0].value = dashboard.total;
            _this.totalCategoryTabs[0].total = dashboard.total;
            _this.totalCategoryTabs[1].value = dashboard.toCreate;
            _this.totalCategoryTabs[1].total = dashboard.total;
            _this.totalCategoryTabs[2].value = dashboard.inProgress;
            _this.totalCategoryTabs[2].total = dashboard.total;
            _this.totalCategoryTabs[3].value = dashboard.done;
            _this.totalCategoryTabs[3].total = dashboard.total;
            _this.totalCategoryTabs[4].value = dashboard.expropriationTotal;
            _this.casesCategoryTabs.forEach(function (tab) {
                tab.total = (dashboard[tab.prefix + 'Total'] ? dashboard[tab.prefix + 'Total'] : 0);
                tab.done = (dashboard[tab.prefix + 'Done'] ? dashboard[tab.prefix + 'Done'] : 0);
                tab.toCreate = (dashboard[tab.prefix + 'ToCreate'] ? dashboard[tab.prefix + 'ToCreate'] : 0);
                tab.progress = tab.total - tab.done - tab.toCreate;
                tab.donePercentage = _this.percentagePipe.transform(tab.done, tab.total);
                tab.toCreatePercentage = _this.percentagePipe.transform(tab.toCreate, tab.total);
                tab.progressPercentage = _this.percentagePipe.transform(tab.progress, tab.total);
                tab.available = tab.total || tab.done || tab.toCreate || tab.progress;
                tab.partialData = _this.project[tab.readinesName] === readiness_state_enum_1.ReadinessStateEnum.PARTIAL_DATA;
                tab.readiness = _this.project[tab.readinesName];
                tab.chartData = [
                    { value: tab.toCreate, color: '#ffc90e', tooltip: 'Nezaloženo ' + (_this.showPercentage ? tab.toCreatePercentage : tab.toCreate) },
                    { value: tab.progress, color: '#30a2e8', tooltip: 'V řešení ' + (_this.showPercentage ? tab.progressPercentage : tab.progress) },
                    { value: tab.done, color: '#22b14c', tooltip: 'Vypořádáno ' + (_this.showPercentage ? tab.donePercentage : tab.done) },
                ];
            });
        });
    };
    ManagerReportProjectDetailComponent.prototype.loadExpropriationObligationIds = function () {
        var _this = this;
        var obligations = this.listService.createList('obligations', { filters: { obligationType: ['FulfillmentOfDecisionExpropriationLiability', 'TemporaryExpropriationLiability'] } }, this.restangular);
        return this.listService.fetchResult(obligations).then(function () {
            _this.entityTabs[4].defaultFilter.filters.obligationId.values = obligations.list.map(function (o) { return o.id; });
        });
    };
    return ManagerReportProjectDetailComponent;
}());
exports.ManagerReportProjectDetailComponent = ManagerReportProjectDetailComponent;
