"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var list_service_1 = require("@app/common/services/list.service");
var angular_1 = require("@uirouter/angular");
var parcel_service_1 = require("@app/ps/services/parcel.service");
var settings_service_1 = require("@app/ps/services/settings.service");
var auth_service_1 = require("@app/common/services/auth.service");
var table_utils_1 = require("@app/common/utils/table.utils");
var ParcelDetailComponent = /** @class */ (function () {
    function ParcelDetailComponent(helpIds, restangular, listService, stateService, parcelService, settingsService, authService) {
        this.helpIds = helpIds;
        this.restangular = restangular;
        this.listService = listService;
        this.stateService = stateService;
        this.parcelService = parcelService;
        this.settingsService = settingsService;
        this.authService = authService;
        this.tabs = [
            {
                name: 'Nájemce',
                id: 'tenant',
                href: "symap.project.parcels.detail",
                urlParams: { tab: 'tenant' },
            },
            {
                name: 'Případy',
                id: 'cases',
                href: 'symap.project.parcels.detail',
                urlParams: { tab: 'cases' },
            },
            {
                name: 'Poznámky',
                id: 'notes',
                href: 'symap.project.parcels.detail',
                urlParams: { tab: 'notes' },
            },
            {
                name: 'Historie',
                id: 'history',
                href: 'symap.project.parcels.detail',
                urlParams: { tab: 'history' },
            },
            {
                name: 'Dokumenty',
                id: 'documents',
                href: 'symap.project.parcels.detail',
                urlParams: { tab: 'documents' },
            },
        ];
        this.loading = true;
        this.tenantEdit = false;
        this.tab = (this.stateService.params.tab ? this.stateService.params.tab : 'occupations');
        this.isActiveTab = this.isActiveTab.bind(this);
    }
    ParcelDetailComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.geometricPlanModuleEnabled = this.authService.getActualProject().geometricPlanModuleEnabled;
                        return [4 /*yield*/, this.loadParcel()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.loadOccupations()];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.loadEasements()];
                    case 3:
                        _a.sent();
                        this.resolveTabs();
                        this.prepareParcelColumns();
                        this.loadCases();
                        this.loadNotes();
                        this.loadHistory();
                        this.loadDocuments();
                        this.loading = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    ParcelDetailComponent.prototype.isActiveTab = function (id) {
        return id === this.tab;
    };
    ParcelDetailComponent.prototype.uiOnParamsChanged = function (changedParams) {
        this.tab = changedParams.tab;
    };
    ParcelDetailComponent.prototype.onUpdateCaseList = function () {
        var caseTab = this.tabs.find(function (tab) { return tab.id === 'cases'; });
        caseTab.name = "P\u0159\u00EDpady (" + this.caseList.itemCount + ")";
    };
    ParcelDetailComponent.prototype.onCaseTableSort = function (event) {
        this.listService.sort(this.caseList, event.column, event.ascOrDesc);
    };
    ParcelDetailComponent.prototype.onUpdateNoteList = function () {
        var noteTab = this.tabs.find(function (tab) { return tab.id === 'notes'; });
        noteTab.name = "Pozn\u00E1mky (" + this.noteList.list.filter(function (a) { return !a.cancelled; }).length + ")";
    };
    ParcelDetailComponent.prototype.onUpdateHistoryList = function () {
        var historyTab = this.tabs.find(function (tab) { return tab.id === 'history'; });
        historyTab.name = "Historie (" + this.historyList.itemCount + ")";
    };
    ParcelDetailComponent.prototype.onUpdateOccupationsList = function () {
        var tab = ParcelDetailComponent.OCCUPATIONS_TAB;
        var count = this.occupationList.itemCount;
        tab.name = "Z\u00E1bory (" + count + ")";
        var tabIndex = this.tabs.indexOf(tab);
        if (count === 0 && tabIndex > -1) {
            this.tabs.splice(this.tabs.indexOf(tab), 1);
        }
        else if (count > 0 && tabIndex === -1) {
            this.tabs.push(tab);
        }
    };
    ParcelDetailComponent.prototype.onUpdateEasementsList = function () {
        var tab = ParcelDetailComponent.EASEMENTS_TAB;
        var count = this.easementList.itemCount;
        tab.name = "Slu\u017Eebnosti (" + count + ")";
        var tabIndex = this.tabs.indexOf(tab);
        if (count === 0 && tabIndex > -1) {
            this.tabs.splice(this.tabs.indexOf(tab), 1);
        }
        else if (count > 0 && tabIndex === -1) {
            this.tabs.push(tab);
        }
    };
    ParcelDetailComponent.prototype.onSubmitCosts = function () {
        var _this = this;
        return this.restangular.one("parcels/" + this.parcelId).customPUT(this.parcel).then(function () {
            _this.loadHistory();
        });
    };
    ParcelDetailComponent.prototype.onUpdateDocumentList = function () {
        var documentTab = this.tabs.find(function (tab) { return tab.id === 'documents'; });
        documentTab.name = "Dokumenty (" + this.documentList.list.filter(function (a) { return !a.cancelled; }).length + ")";
    };
    ParcelDetailComponent.prototype.loadHistory = function () {
        var _this = this;
        this.historyList = this.listService.createList('notes', {
            filters: { idpar: this.parcel.idpar, systemType: true },
            sortOrder: { sortBy: 'timestamp', direction: 'desc' }
        }, undefined, { subject: { load: true } });
        this.listService.fetchResult(this.historyList).then(function () {
            _this.onUpdateHistoryList();
        });
    };
    ParcelDetailComponent.prototype.loadParcel = function () {
        var _this = this;
        return this.restangular
            .one('parcels', this.parcelId)
            .customGET('', { loadCollections: ['bpejList'] })
            .then(function (data) {
            _this.parcel = data.plain();
            if (!data.endDate && data.platnostK && _this.authService.getActualProject().cadastreDataDate != data.platnostK) {
                _this.cadastreDataDate = data.platnostK;
            }
        });
    };
    ParcelDetailComponent.prototype.loadDocuments = function () {
        var _this = this;
        this.documentList = this.listService.createList('attachments', {
            filters: { parcelId: this.parcelId, cancelled: false },
            sortOrder: { sortBy: 'timestamp', direction: 'desc' }
        }, undefined, { businessCase: {} });
        this.listService.fetchResult(this.documentList).then(function () {
            _this.onUpdateDocumentList();
        });
    };
    ParcelDetailComponent.prototype.hideColumn = function (columnID) {
        var tableID = 'parcelDetail';
        return this.settingsService.shouldHideColumn(tableID, columnID);
    };
    ParcelDetailComponent.prototype.prepareParcelColumns = function () {
        var occupationPermanentColumns = __spread(ParcelDetailComponent.OCCUPATION_PERMANENT_COLUMNS);
        var occupationUnderOneYearColumns = __spread(ParcelDetailComponent.OCCUPATION_UNDER_YEAR_COLUMNS);
        var occupationOverOneYearColumns = __spread(ParcelDetailComponent.OCCUPATION_OVER_YEAR_COLUMNS);
        var easementColumns = __spread(ParcelDetailComponent.EASEMENT_COLUMNS);
        if (!this.hideColumn('permanentOccupationPriceColumns')) {
            occupationPermanentColumns = occupationPermanentColumns.concat(ParcelDetailComponent.OCCUPATION_PERMANENT_PRICE_COLUMNS);
        }
        if (!this.hideColumn('temporaryUnderYearPriceColumns')) {
            occupationUnderOneYearColumns = occupationUnderOneYearColumns.concat(ParcelDetailComponent.OCCUPATION_UNDER_YEAR_PRICE_COLUMNS);
        }
        if (!this.hideColumn('temporaryOverYearPriceColumns')) {
            occupationOverOneYearColumns = occupationOverOneYearColumns.concat(ParcelDetailComponent.OCCUPATION_OVER_YEAR_PRICE_COLUMNS);
        }
        if (!this.hideColumn('easementsPriceColumns') && !this.authService.getActualProject().easementPriceByShare) {
            easementColumns = easementColumns.concat(ParcelDetailComponent.EASEMENT_PRICE_COLUMNS);
        }
        this.occupationPermanentColumns = table_utils_1.TableUtils.repairColumnsStructure(occupationPermanentColumns);
        this.occupationUnderOneYearColumns = table_utils_1.TableUtils.repairColumnsStructure(occupationUnderOneYearColumns);
        this.occupationOverOneYearColumns = table_utils_1.TableUtils.repairColumnsStructure(occupationOverOneYearColumns);
        this.easementColumns = table_utils_1.TableUtils.repairColumnsStructure(easementColumns);
    };
    ParcelDetailComponent.prototype.loadCases = function () {
        var _this = this;
        this.caseList = this.listService.createList('cases', {
            limit: undefined,
            filters: {
                parcelId: this.parcelId,
                loadCollections: ['titles', 'caseOwnerships', 'constructionObjects']
            }
        });
        this.listService.fetchResult(this.caseList).then(function () {
            _this.onUpdateCaseList();
        });
    };
    ParcelDetailComponent.prototype.loadNotes = function () {
        var _this = this;
        this.noteList = this.listService.createList('notes', {
            filters: { idpar: this.parcel.idpar, systemType: false, cancelled: false, },
            sortOrder: { sortBy: 'timestamp', direction: 'desc' }
        }, undefined, { title: { load: true },
            businessCase: { load: true },
        });
        this.listService.fetchResult(this.noteList).then(function () {
            _this.onUpdateNoteList();
        });
    };
    ParcelDetailComponent.prototype.loadOccupations = function () {
        var _this = this;
        this.occupationList = this.listService.createList('occupations', {
            limit: undefined,
            filters: {
                parcelId: this.parcelId,
                validOrWithCase: true,
                loadCollections: [
                    'parcel.knBudouc.currentParcels',
                    'parcel.knBudouc.currentParcels.bpejList',
                    'constructionObjects',
                    'rentalExpert',
                ]
            },
            sortOrder: [{ sortBy: 'parcel.parcisKmen' }, { sortBy: 'parcel.parcisPod' }]
        });
        return this.listService.fetchResult(this.occupationList).then(function (data) {
            _this.occupationGroups = _this.parcelService.groupOccupationsByParcel(data.list, []);
            _this.occupationUnderYearTableName = _this.parcelService.getTemporaryOccupationName(_this.occupationGroups.underOneYear);
            _this.occupationOverYearTableName = _this.parcelService.getTemporaryOccupationName(_this.occupationGroups.overOneYear);
            _this.onUpdateOccupationsList();
        });
    };
    ParcelDetailComponent.prototype.loadEasements = function () {
        var _this = this;
        this.easementList = this.listService.createList('easements', {
            limit: undefined,
            filters: {
                parcelId: this.parcelId,
                validOrWithCase: true,
                loadCollections: [],
            },
            sortOrder: [{ sortBy: 'parcel.parcisKmen' }, { sortBy: 'parcel.parcisPod' }]
        }, undefined, {
            constructionObjects: {
                load: true,
                attributes: {
                    administrator: { load: true },
                }
            }
        });
        return this.listService.fetchResult(this.easementList).then(function () {
            _this.onUpdateEasementsList();
        });
    };
    ParcelDetailComponent.prototype.resolveTabs = function () {
        // if no occupations go to easements
        var easementsCount = this.easementList.itemCount;
        var occupationsCount = this.occupationList.itemCount;
        if (occupationsCount === 0 && this.tab === 'occupations') {
            if (easementsCount === 0) {
                // redirect if path is active
                this.stateService.go('.', { tab: 'tenant' }, { location: 'replace' });
            }
            else {
                this.stateService.go('.', { tab: 'easements' }, { location: 'replace' });
            }
        }
        if (easementsCount === 0 && this.tab === 'easements') {
            // redirect if path is active
            this.stateService.go('.', { tab: 'tenant' }, { location: 'replace' });
        }
        // check if show parcels costs tab
        if (this.authService.hasPermission('assignable') && this.occupationGroups.permanent) {
            this.tabs.push(ParcelDetailComponent.COSTS_TAB);
        }
        else if (this.tab === 'costs') {
            this.stateService.go('.', { tab: 'easements' }, { location: 'replace' });
        }
        this.tabs.sort(function (a, b) {
            return ParcelDetailComponent.TABS_ORDER.indexOf(a.id) - ParcelDetailComponent.TABS_ORDER.indexOf(b.id);
        });
    };
    ParcelDetailComponent.prototype.canEdit = function () {
        return this.authService.isAuthorized() || this.authService.hasPermission('assignable');
    };
    ParcelDetailComponent.OCCUPATIONS_TAB = {
        name: 'Zábory',
        id: 'occupations',
        href: 'symap.project.parcels.detail',
        urlParams: { tab: 'occupations' },
    };
    ParcelDetailComponent.EASEMENTS_TAB = {
        name: 'Služebnosti',
        id: 'easements',
        href: 'symap.project.parcels.detail',
        urlParams: { tab: 'easements' },
    };
    ParcelDetailComponent.COSTS_TAB = {
        name: 'Náklady na pořízení',
        id: 'costs',
        href: 'symap.project.parcels.detail',
        urlParams: { tab: 'costs' },
    };
    ParcelDetailComponent.OCCUPATION_PERMANENT_COLUMNS = [
        { id: 'occupation_concern_object', sortable: false },
        { id: 'occupation_phase', sortable: true },
        { id: 'occupation_occupation', sortable: true },
        { id: 'occupation_cases', sortable: true },
        { id: 'current_parcel', sortable: false },
        { id: 'current_protection', sortable: false },
        { id: 'current_concern_object', sortable: false },
    ];
    ParcelDetailComponent.OCCUPATION_UNDER_YEAR_COLUMNS = [
        { id: 'label_gp', sortable: false },
        { id: 'occupation_concern_object', sortable: false },
        { id: 'occupation_phase', sortable: true },
        { id: 'occupation_occupation', sortable: true },
        { id: 'occupation_cases', sortable: true },
        { id: 'current_parcel', sortable: false },
        { id: 'current_protection', sortable: false },
        { id: 'current_concern_object', sortable: false },
    ];
    ParcelDetailComponent.OCCUPATION_OVER_YEAR_COLUMNS = ParcelDetailComponent.OCCUPATION_UNDER_YEAR_COLUMNS;
    ParcelDetailComponent.EASEMENT_COLUMNS = [
        { id: 'construction_object', sortable: false },
        { id: 'title', sortable: true },
        { id: 'area', sortable: false },
        { id: 'geometric_plan', sortable: false },
        { id: 'length', sortable: false },
        { id: 'case', sortable: true },
    ];
    ParcelDetailComponent.OCCUPATION_PERMANENT_PRICE_COLUMNS = [
        { id: 'occupation_price_parcel', sortable: false },
        { id: 'occupation_price_vegetation', sortable: false },
        { id: 'occupation_price_construction', sortable: false },
        { id: 'occupation_price_summary', sortable: false },
    ];
    ParcelDetailComponent.OCCUPATION_UNDER_YEAR_PRICE_COLUMNS = [
        { id: 'occupation_assessment_price', sortable: false },
        { id: 'occupation_price_land_price', sortable: false },
        { id: 'occupation_price_rent_price_year', sortable: false },
        { id: 'occupation_price_rent_length', sortable: false },
        { id: 'occupation_price_total_rental_price', sortable: false },
    ];
    ParcelDetailComponent.OCCUPATION_OVER_YEAR_PRICE_COLUMNS = ParcelDetailComponent.OCCUPATION_UNDER_YEAR_PRICE_COLUMNS;
    ParcelDetailComponent.EASEMENT_PRICE_COLUMNS = [
        { id: 'parcel_price', sortable: false },
    ];
    ParcelDetailComponent.TABS_ORDER = ['occupations', 'easements', 'tenant', 'cases', 'notes', 'costs', 'history', 'documents'];
    return ParcelDetailComponent;
}());
exports.ParcelDetailComponent = ParcelDetailComponent;
