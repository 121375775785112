"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var angular_hybrid_1 = require("@uirouter/angular-hybrid");
var main_component_1 = require("./components/main/main.component");
var requests_component_1 = require("./components/requests/requests.component");
var projects_component_1 = require("@app/pk/components/projects/projects.component");
var create_project_first_step_component_1 = require("./components/create-project/create-project-first-step/create-project-first-step.component");
var create_project_second_step_component_1 = require("./components/create-project/create-project-second-step/create-project-second-step.component");
var create_project_third_step_component_1 = require("./components/create-project/create-project-third-step/create-project-third-step.component");
var user_credentials_component_1 = require("../common/components/user-credentials/user-credentials.component");
var settings_component_1 = require("./components/settings/settings.component");
var wsdp_credentials_component_1 = require("./components/wsdp-credentials/wsdp-credentials.component");
function authCallback(authService, applications, $transition$) {
    return authService.checkProjectStatus($transition$, applications.pk.name, null, authService.loadPromapProjects, 'error').then(function () { }, function (newState) { return newState; });
}
exports.authCallback = authCallback;
exports.pkState = {
    name: 'pk',
    url: '/promap',
    abstract: true,
    component: main_component_1.MainComponent,
    onEnter: ['AuthService', 'APPLICATIONS', '$transition$', authCallback],
};
exports.projectsState = {
    name: 'pk.projects',
    url: '/projects?page',
    data: {
        title: 'Projekty',
    },
    views: {
        'content@pk': {
            component: requests_component_1.RequestsComponent,
        }
    },
    params: {
        page: {
            dynamic: true,
            value: 1,
            type: 'int',
            squash: true,
            inherit: false,
        }
    },
};
exports.createProjectFirstStep = {
    name: 'pk.projects.create-project-first',
    url: '/create-first',
    data: { title: 'Nová žádost - Vložení zájmového území' },
    views: {
        'content@pk': {
            component: create_project_first_step_component_1.CreateProjectFirstStepComponent
        }
    },
    params: {
        requestId: null
    }
};
exports.createProjectSecondStep = {
    name: 'pk.projects.create-project-second',
    url: '/create-second',
    data: { title: 'Nová žádost - Identifikace žádosti' },
    views: {
        'content@pk': {
            component: create_project_second_step_component_1.CreateProjectSecondStepComponent
        }
    },
    params: {
        formData: null,
        type: null,
        requestId: null
    }
};
exports.createProjectThirdStep = {
    name: 'pk.projects.create-project-third',
    url: '/create-third',
    data: { title: 'Nová žádost - Odeslání žádosti' },
    views: {
        'content@pk': {
            component: create_project_third_step_component_1.CreateProjectThirdStepComponent
        }
    },
    params: {
        requestId: {
            type: 'string',
            value: null
        },
        title: {
            type: 'string',
            value: 'Nová žádost'
        },
        subtitle: {
            type: 'string',
            value: 'Odeslání žádosti'
        },
    }
};
exports.settingsState = {
    name: 'pk.settings',
    url: '/settings',
    data: {
        title: 'Nastavení',
    },
    views: {
        'content@pk': {
            component: settings_component_1.SettingsComponent,
        }
    }
};
exports.userCredentials = {
    name: 'pk.settings.user-credentials',
    url: '/user-credentials',
    data: {
        title: 'Změna přihlašovacích údajů',
    },
    views: {
        'content@pk': {
            component: user_credentials_component_1.UserCredentialsComponent,
        }
    },
    params: {
        backSref: {
            type: 'string',
            value: 'pk.settings'
        },
    }
};
exports.wsdpCredentials = {
    name: 'pk.settings.wsdp-credentials',
    url: '/wsdp-credentials',
    data: {
        title: 'Změna WSDP',
    },
    views: {
        'content@pk': {
            component: wsdp_credentials_component_1.WsdpCredentialsComponent,
        }
    },
    params: {
        backSref: {
            type: 'string',
            value: 'pk.settings'
        },
    }
};
exports.projectsGlobalState = {
    name: 'pk.projectsGlobal',
    url: '/projects-global?page',
    data: {
        title: 'Projekty a žádosti',
    },
    views: {
        'content@pk': {
            component: projects_component_1.ProjectsComponent,
        }
    },
    params: {
        page: {
            dynamic: true,
            value: 1,
            type: 'int',
            squash: true,
            inherit: false,
        }
    },
};
var PkAppModule = /** @class */ (function () {
    function PkAppModule() {
    }
    return PkAppModule;
}());
exports.PkAppModule = PkAppModule;
