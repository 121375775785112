"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var AuthService = /** @class */ (function () {
    function AuthService(APP_BRAND, APPLICATIONS, authService) {
        this.APP_BRAND = APP_BRAND;
        this.APPLICATIONS = APPLICATIONS;
        this.authService = null;
        this.authService = authService;
    }
    AuthService.prototype.getActualProjects = function () {
        return this.authService.getActualProjects();
    };
    AuthService.prototype.getActualProject = function () {
        return this.authService.getActualProject();
    };
    AuthService.prototype.getLastVisitedProjects = function (application) {
        return this.authService.getLastVisitedProjects(application);
    };
    AuthService.prototype.getActiveApplication = function () {
        return this.authService.getActiveApplication();
    };
    AuthService.prototype.getApplicationBaseState = function (application) {
        return this.authService.getApplicationBaseState(application);
    };
    AuthService.prototype.getApplicationTransitionParams = function (module) {
        return this.authService.getApplicationTransitionParams(module);
    };
    AuthService.prototype.checkProjectStatus = function ($transition$, applicationName, projectLoadingShortcut, projectLoader, errorPage) {
        return this.authService.checkProjectStatus($transition$, applicationName, projectLoadingShortcut, projectLoader, errorPage);
    };
    AuthService.prototype.getServiceDeskUrl = function () {
        return this.authService.getServicedeskUrl();
    };
    AuthService.prototype.getUser = function () {
        return this.authService.user;
    };
    AuthService.prototype.login = function (username, password, ssoToken, ssoAuthorizationCode, ssoCodeVerifier, ssoRedirectURI, ssoLogoutToken) {
        if (ssoAuthorizationCode === void 0) { ssoAuthorizationCode = undefined; }
        if (ssoCodeVerifier === void 0) { ssoCodeVerifier = undefined; }
        if (ssoRedirectURI === void 0) { ssoRedirectURI = undefined; }
        if (ssoLogoutToken === void 0) { ssoLogoutToken = undefined; }
        return this.authService.login(username, password, ssoToken, ssoAuthorizationCode, ssoCodeVerifier, ssoRedirectURI, ssoLogoutToken);
    };
    AuthService.prototype.logout = function () {
        this.authService.logout();
    };
    AuthService.prototype.onAuthProjectUpdated = function (project) {
        return this.authService.onAuthProjectUpdated(project);
    };
    AuthService.prototype.setActiveApplication = function (name) {
        this.authService.setActiveApplication(name);
    };
    AuthService.prototype.updateBaseUrl = function () {
        this.authService.updateBaseUrl();
    };
    AuthService.prototype.updateAuthorizationHeader = function () {
        this.authService.updateAuthorizationHeader();
    };
    AuthService.prototype.setFavicon = function (favicon) {
        this.authService.setFavicon(favicon);
    };
    AuthService.prototype.isAuthenticated = function () {
        return this.authService.isAuthenticated();
    };
    AuthService.prototype.isAuthenticatedApplication = function (application) {
        return this.authService.isAuthenticatedApplication(application);
    };
    AuthService.prototype.isAuthorized = function (user) {
        if (user === void 0) { user = null; }
        return this.authService.isAuthorized(user);
    };
    AuthService.prototype.getApplications = function () {
        return this.authService.getApplications();
    };
    AuthService.prototype.aplicationHasProjects = function (project) {
        return this.authService.aplicationHasProjects(project);
    };
    AuthService.prototype.getActiveApplicationRestUrl = function () {
        return this.authService.getActiveApplicationRestUrl();
    };
    AuthService.prototype.getToken = function () {
        return this.authService.token;
    };
    AuthService.prototype.hasPermission = function (permission) {
        return this.authService.hasPermission(permission);
    };
    AuthService.prototype.isFirstLogin = function () {
        return this.authService.isFirstLogin();
    };
    AuthService.prototype.passwordChanged = function () {
        return this.authService.passwordChanged;
    };
    AuthService.prototype.registerCallback = function (name, callback) {
        return this.authService.registerCallback(name, callback);
    };
    AuthService.prototype.unregisterCallback = function (name, callback) {
        return this.authService.unregisterCallback(name, callback);
    };
    AuthService.prototype.goToActiveAplication = function () {
        return this.authService.goToActiveAplication();
    };
    AuthService.prototype.canUpdateCaseData = function () {
        return this.authService.hasPermission('admin,update_case_data');
    };
    AuthService.prototype.canUpdateCasePrice = function () {
        return this.authService.hasPermission('admin,update_case_price');
    };
    AuthService.prototype.loadProject = function (projectKey) {
        return this.authService.loadProject(projectKey);
    };
    AuthService.prototype.loadSymapProjects = function (userId, params) {
        if (params === void 0) { params = {}; }
        return this.authService.loadSymapProjects(userId, params);
    };
    AuthService.prototype.loadDimapProjects = function (userId, params) {
        if (params === void 0) { params = {}; }
        return this.authService.dimapSymapProjects(userId, params);
    };
    AuthService.prototype.loadPromapProjects = function (userId) {
        return this.authService.loadPromapProjects(userId);
    };
    AuthService.prototype.loadVfzeProjects = function (userId) {
        return this.authService.loadVfzeProjects(userId);
    };
    AuthService.prototype.loadSvProjects = function (userId) {
        return this.authService.loadSvProjects(userId);
    };
    AuthService.prototype.isSsoLoginEnabled = function (secret) {
        if (secret === void 0) { secret = undefined; }
        return this.authService.isSsoLoginEnabled(secret);
    };
    AuthService.prototype.ssoLogin = function () {
        this.authService.ssoLogin();
    };
    AuthService.prototype.getAplicationGlobalRestangularProvider = function () {
        return this.authService.getAplicationGlobalRestangularProvider();
    };
    AuthService.prototype.getSymapProjects = function () {
        return this.authService.symapProjects;
    };
    AuthService.prototype.getSYdefaultProjectKey = function () {
        return this.authService.SYdefaultProjectKey;
    };
    AuthService.prototype.getDIdefaultProjectKey = function () {
        return this.authService.DIdefaultProjectKey;
    };
    AuthService.prototype.getProjectsByPermission = function (actions, permissions) {
        var actualPermissions = permissions ? permissions : this.authService.getActualPermissions();
        var projects = Object.keys(actualPermissions);
        return actions
            ? projects.filter(function (p) { return actions.split(',').some(function (a) { return actualPermissions[p].includes(a); }); })
            : projects.filter(function (p) { return actualPermissions[p].length > 0; });
    };
    AuthService.prototype.hasPermissionOnProject = function (actions, project, permissions) {
        var actualPermissions = permissions ? permissions : this.authService.getActualPermissions();
        // if project set, search action on the project
        if (project) {
            return actions.split(',').some(function (a) { return actualPermissions[project] && actualPermissions[project].includes(a); });
        }
        var permissionsArrays = Object.values(actualPermissions);
        // if project not set, search action anywhere
        return actions.split(',').some(function (a) { return !!permissionsArrays.find(function (pa) { return pa.includes(a); }); });
    };
    AuthService.prototype.switchProjectByKey = function (projectKey, applicationKey) {
        this.authService.switchProjectByKey(projectKey, applicationKey);
    };
    AuthService.ngInjectableDef = i0.defineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.inject("APP_BRAND"), i0.inject("APPLICATIONS"), i0.inject("AuthService")); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
exports.AuthService = AuthService;
