"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./simple-case-status-form.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/forms");
var i3 = require("../../../../common/directives/pickadate/pickadate.directive");
var i4 = require("../../../../common/components/switch/switch.component.ngfactory");
var i5 = require("../../../../common/components/switch/switch.component");
var i6 = require("@angular/common");
var i7 = require("../../../../common/components/input/input.component.ngfactory");
var i8 = require("../../../../common/components/input/input.component");
var i9 = require("./simple-case-status-form.component");
var styles_SimpleCaseStatusFormComponent = [i0.styles];
var RenderType_SimpleCaseStatusFormComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SimpleCaseStatusFormComponent, data: {} });
exports.RenderType_SimpleCaseStatusFormComponent = RenderType_SimpleCaseStatusFormComponent;
function View_SimpleCaseStatusFormComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.value; _ck(_v, 1, 0, currVal_0); }); }
function View_SimpleCaseStatusFormComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 6, "input", [["pick-a-date", ""], ["type", "text"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 1)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = (_co.onChange($event, _v.parent.parent.context.$implicit) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i2.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i2.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.DefaultValueAccessor]), i1.ɵdid(3, 671744, null, 0, i2.NgModel, [[8, null], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i1.ɵdid(5, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), i1.ɵdid(6, 81920, null, 0, i3.PickADateDirective, [i1.ElementRef, "WindowDimensionService", i1.Renderer2, i1.ComponentFactoryResolver, i1.ViewContainerRef], { maxToday: [0, "maxToday"], ngModel: [1, "ngModel"] }, { ngModelChange: "ngModelChange" }), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.data[_v.parent.parent.context.$implicit.field]; _ck(_v, 3, 0, currVal_7); var currVal_8 = !_v.parent.parent.context.$implicit.allowFutureDate; var currVal_9 = _co.data[_v.parent.parent.context.$implicit.field]; _ck(_v, 6, 0, currVal_8, currVal_9); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 5).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 5).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 5).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 5).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 5).ngClassValid; var currVal_5 = i1.ɵnov(_v, 5).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 5).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
function View_SimpleCaseStatusFormComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "input", [["type", "text"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 1)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = (_co.onChange($event, _v.parent.parent.context.$implicit) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i2.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i2.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.DefaultValueAccessor]), i1.ɵdid(3, 671744, null, 0, i2.NgModel, [[8, null], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i1.ɵdid(5, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.data[_v.parent.parent.context.$implicit.field]; _ck(_v, 3, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 5).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 5).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 5).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 5).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 5).ngClassValid; var currVal_5 = i1.ɵnov(_v, 5).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 5).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
function View_SimpleCaseStatusFormComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "gmt-switch", [], null, [[null, "selectedIdChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectedIdChange" === en)) {
        var pd_0 = (_co.onChange($event, _v.parent.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_SwitchComponent_0, i4.RenderType_SwitchComponent)), i1.ɵdid(1, 114688, null, 0, i5.SwitchComponent, [], { options: [0, "options"], selectedId: [1, "selectedId"] }, { selectedIdChange: "selectedIdChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.parent.parent.context.$implicit.options; var currVal_1 = _co.data[_v.parent.parent.context.$implicit.field]; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_SimpleCaseStatusFormComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SimpleCaseStatusFormComponent_4)), i1.ɵdid(2, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SimpleCaseStatusFormComponent_5)), i1.ɵdid(4, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SimpleCaseStatusFormComponent_6)), i1.ɵdid(6, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = (_v.parent.context.$implicit.type === "date"); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_v.parent.context.$implicit.type === "string"); _ck(_v, 4, 0, currVal_1); var currVal_2 = (_v.parent.context.$implicit.type === "switch"); _ck(_v, 6, 0, currVal_2); }, null); }
function View_SimpleCaseStatusFormComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "column-100"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "gmt-input", [], null, null, null, i7.View_InputComponent_0, i7.RenderType_InputComponent)), i1.ɵdid(2, 114688, null, 0, i8.InputComponent, [], { label: [0, "label"], required: [1, "required"], readonly: [2, "readonly"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_SimpleCaseStatusFormComponent_2)), i1.ɵdid(4, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_SimpleCaseStatusFormComponent_3)), i1.ɵdid(6, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit.name; var currVal_1 = _v.context.$implicit.required; var currVal_2 = !!_v.context.$implicit.value; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _v.context.$implicit.value; _ck(_v, 4, 0, currVal_3); var currVal_4 = !_v.context.$implicit.value; _ck(_v, 6, 0, currVal_4); }, null); }
function View_SimpleCaseStatusFormComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "fieldset", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SimpleCaseStatusFormComponent_1)), i1.ɵdid(2, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.config; _ck(_v, 2, 0, currVal_0); }, null); }
exports.View_SimpleCaseStatusFormComponent_0 = View_SimpleCaseStatusFormComponent_0;
function View_SimpleCaseStatusFormComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "simple-case-status-form", [], null, null, null, View_SimpleCaseStatusFormComponent_0, RenderType_SimpleCaseStatusFormComponent)), i1.ɵdid(1, 114688, null, 0, i9.SimpleCaseStatusFormComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_SimpleCaseStatusFormComponent_Host_0 = View_SimpleCaseStatusFormComponent_Host_0;
var SimpleCaseStatusFormComponentNgFactory = i1.ɵccf("simple-case-status-form", i9.SimpleCaseStatusFormComponent, View_SimpleCaseStatusFormComponent_Host_0, { data: "data", config: "config" }, { callbackRegister: "callbackRegister" }, []);
exports.SimpleCaseStatusFormComponentNgFactory = SimpleCaseStatusFormComponentNgFactory;
