"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var case_status_service_1 = require("@app/ps/services/case-status.service");
var case_service_1 = require("@app/ps/services/case.service");
var FormCaseSignInvestorComponent = /** @class */ (function () {
    function FormCaseSignInvestorComponent(restangular, caseService, caseStatusService) {
        this.restangular = restangular;
        this.caseService = caseService;
        this.caseStatusService = caseStatusService;
        this.callbackRegister = new core_1.EventEmitter();
        this.caseIndex = -1;
        this.isValid = this.isValid.bind(this);
        this.previous = this.previous.bind(this);
        this.next = this.next.bind(this);
    }
    FormCaseSignInvestorComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.data.signedInvestorDate = this.data.signedInvestorDate || new Date();
                this.callbackRegister.emit({
                    isValid: this.isValid,
                });
                this.next();
                return [2 /*return*/];
            });
        });
    };
    FormCaseSignInvestorComponent.prototype.previous = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (this.caseIndex > this.firstCaseId) {
                    this.caseIndex--;
                    if (this.cases[this.caseIndex].obligation.type !== 'FulfillmentOfDecisionAuctionSaleExecutionLiability') {
                        this.previous();
                    }
                }
                return [2 /*return*/];
            });
        });
    };
    FormCaseSignInvestorComponent.prototype.next = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (this.caseIndex >= 0) {
                    this.dataCases[this.caseIndex] = {
                        financialControlDate: this.cases[this.caseIndex].financialControlDate,
                        maxAuctionAmount: this.cases[this.caseIndex].maxAuctionAmount,
                    };
                }
                if (this.caseIndex + 1 < this.cases.length) {
                    this.caseIndex++;
                    if (this.cases[this.caseIndex].obligation.type !== 'FulfillmentOfDecisionAuctionSaleExecutionLiability') {
                        this.next();
                    }
                    else if (this.firstCaseId === undefined) {
                        this.executionCaseName = this.cases[this.caseIndex].obligation.name;
                        this.firstCaseId = this.caseIndex;
                    }
                }
                else {
                    this.caseIndex++;
                }
                return [2 /*return*/];
            });
        });
    };
    FormCaseSignInvestorComponent.prototype.isValid = function () {
        return !!this.data.signedInvestorDate && this.caseIndex === this.cases.length;
    };
    return FormCaseSignInvestorComponent;
}());
exports.FormCaseSignInvestorComponent = FormCaseSignInvestorComponent;
