"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var pageable_list_service_1 = require("@app/common/services/pageable.list.service");
var dialog_service_1 = require("@app/dialog/dialog.service");
var changelog_edit_component_1 = require("@app/settings/modules/components/changelog-edit/changelog-edit.component");
var ChangelogListComponent = /** @class */ (function () {
    function ChangelogListComponent(restangular, pageableListService, dialogService) {
        this.restangular = restangular;
        this.pageableListService = pageableListService;
        this.dialogService = dialogService;
        this.defaultFilter = {
            filters: {
                application: { values: [] },
            }
        };
        this.onEdit = this.onEdit.bind(this);
        this.onDelete = this.onDelete.bind(this);
    }
    ChangelogListComponent.prototype.ngOnInit = function () {
        this.initializeList();
        this.defaultFilter.filters.application.values = [this.applicationName];
    };
    ChangelogListComponent.prototype.onEdit = function (changelog) {
        var _this = this;
        var dialog = this.dialogService.open(changelog_edit_component_1.ChangelogEditComponent, {
            data: {
                changelogId: changelog ? changelog.id : undefined,
                applicationName: this.applicationName
            }
        });
        var sub = dialog.afterClosed.subscribe(function (updated) {
            if (updated) {
                _this.pageableList.load();
            }
            sub.unsubscribe();
        });
    };
    ChangelogListComponent.prototype.onDelete = function (changelog) {
        var _this = this;
        this.restangular
            .one("changelogs/" + changelog.id)
            .remove()
            .then(function () { return _this.pageableList.load(); });
    };
    ChangelogListComponent.prototype.initializeList = function () {
        this.pageableList = this.pageableListService.get('changelogs', this.defaultFilter);
        this.list = this.pageableList.list;
        this.pageableList.load();
    };
    return ChangelogListComponent;
}());
exports.ChangelogListComponent = ChangelogListComponent;
