"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var _ = require("lodash");
var dialog_config_1 = require("@app/dialog/dialog-config");
var dialog_ref_1 = require("@app/dialog/dialog-ref");
var ProjectColumnsEditComponent = /** @class */ (function () {
    function ProjectColumnsEditComponent(dialogConfig, dialogComponent) {
        this.dialogConfig = dialogConfig;
        this.dialogComponent = dialogComponent;
        this.onUpdate = this.onUpdate.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }
    ProjectColumnsEditComponent.prototype.ngOnInit = function () {
        this.columns = this.dialogConfig.data.columns;
        this.selectedColumns = this.dialogConfig.data.selectedColumns;
    };
    ProjectColumnsEditComponent.prototype.onToggleColumn = function (column) {
        this.selectedColumns.find(function (c) { return c === column.id; })
            ? _.remove(this.selectedColumns, function (c) { return c === column.id; })
            : this.selectedColumns.push(column.id);
    };
    ProjectColumnsEditComponent.prototype.onMoveColumn = function (column, step) {
        var index = _.indexOf(this.columns, column);
        if (index + step < this.columns.length && index + step >= 2) {
            _.pull(this.columns, column);
            this.columns.splice(index + step, 0, column);
        }
    };
    ProjectColumnsEditComponent.prototype.isColumnEnabled = function (column) {
        return !!this.selectedColumns.find(function (c) { return c === column.id; });
    };
    ProjectColumnsEditComponent.prototype.isValid = function () {
        return !!this.selectedColumns.length;
    };
    ProjectColumnsEditComponent.prototype.onUpdate = function () {
        this.dialogComponent.close({ selectedColumns: this.selectedColumns, sortedColumns: this.columns.map(function (c) { return c.id; }) });
    };
    ProjectColumnsEditComponent.prototype.onCancel = function () {
        this.dialogComponent.close();
    };
    return ProjectColumnsEditComponent;
}());
exports.ProjectColumnsEditComponent = ProjectColumnsEditComponent;
