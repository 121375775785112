"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var case_service_1 = require("@app/ps/services/case.service");
var angular_1 = require("@uirouter/angular");
var auth_service_1 = require("@app/common/services/auth.service");
var _ = require("lodash");
var PaymentCheckComponent = /** @class */ (function () {
    function PaymentCheckComponent(caseService, stateService, authService, restangular, APP_BRAND) {
        this.caseService = caseService;
        this.stateService = stateService;
        this.authService = authService;
        this.restangular = restangular;
        this.APP_BRAND = APP_BRAND;
        this.preliminary = false;
        this.loading = true;
        this.dataCaseOwnerships = [];
        this.templatesGetter = this.templatesGetter.bind(this);
        this.generateSetter = this.generateSetter.bind(this);
        this.getCheckedItemsGetter = this.getCheckedItemsGetter.bind(this);
        this.updatePayment = this.updatePayment.bind(this);
        this.toggleSinglePayment = this.toggleSinglePayment.bind(this);
    }
    PaymentCheckComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.restangular.one('cases', this.caseId).get({ loadCollections: ['caseSubjects', 'caseOwnerships'] })];
                    case 1:
                        _a.data = _b.sent();
                        this.data.caseOwnerships = _.filter(this.data.caseOwnerships, ['isParent', false]);
                        this.data.caseOwnerships.forEach(function (item) {
                            _this.dataCaseOwnerships.push({
                                caseOwnership: item,
                                caseSubjects: _this.data.caseSubjects.filter(function (value) {
                                    var bsmSubject;
                                    if (item.ownership.isBsm) {
                                        bsmSubject = _this.data.caseSubjects.find(function (value) { return value.subject.opsubId === item.ownership.opsubId; }).subject;
                                    }
                                    return (!bsmSubject && value.subject.opsubId === item.ownership.opsubId) || (bsmSubject && (bsmSubject.partner1OpsubId === value.subject.opsubId || bsmSubject.partner2OpsubId === value.subject.opsubId));
                                })
                            });
                        });
                        this.templateTypeKey = this.data.obligation.type + 'PaymentInstruction' + (this.preliminary ? 'Preliminary' : '');
                        this.loading = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    PaymentCheckComponent.prototype.disabled = function () {
        return !(this.getCheckedItems().length > 0);
    };
    PaymentCheckComponent.prototype.templatesGetter = function () {
        var _this = this;
        var templateTypes = [{
                key: this.templateTypeKey,
                required: true,
                templates: []
            }];
        var keys = templateTypes.map(function (templateTypes) { return templateTypes.key; });
        return this.restangular.all('templates').customPOST({ filter: { filters: { validity: ['valid'], key: keys } } }).then(function (data) {
            templateTypes.forEach(function (templateType) {
                templateType.templates = _.filter(data, { key: templateType.key });
                templateType.templates.forEach(function (template) {
                    if (_this.APP_BRAND.NAME === 'RSD' && _this.authService.getActualProject().useTemplatesService) {
                        template.commonName = template.attachment.baseName;
                        template.attachment.baseName = _this.data.mpp.number + (template.name ? '_' + template.name : '') + (template.documentNameSpecification ? '_' + template.documentNameSpecification : '');
                    }
                    else {
                        template.commonName = template.attachment.baseName;
                    }
                });
            });
            return templateTypes;
        });
    };
    PaymentCheckComponent.prototype.generateSetter = function (generateDocuments) {
        this.generatePayment = generateDocuments.generate;
    };
    PaymentCheckComponent.prototype.getCheckedItemsGetter = function (checkedItemsGetter) {
        this.getCheckedItems = checkedItemsGetter.checkedItemsGetter;
    };
    PaymentCheckComponent.prototype.updatePayment = function () {
        var _this = this;
        return this.data.put({ loadCollections: ['caseSubjects', 'caseOwnerships'] })
            .then(function (caseData) {
            return _this.generatePayment().then(function (attachment) {
                _this.stateService.go('^');
                // TODO - cases - puvodne se zde volal nejaky callback pro update priloh atp v detailu smlouvy
            });
        });
    };
    PaymentCheckComponent.prototype.toggleSinglePayment = function (caseOwnership) {
        caseOwnership.caseOwnership.singlePayment = !caseOwnership.caseOwnership.singlePayment;
        if (caseOwnership.caseOwnership.singlePayment) {
            caseOwnership.caseSubjects.forEach(this.clearPayment);
        }
        else {
            this.clearPayment(caseOwnership.caseOwnership);
        }
    };
    PaymentCheckComponent.prototype.clearPayment = function (object) {
        object.paymentType = undefined;
        object.accountNumber = null;
        object.bankCode = null;
        object.variableSymbol = null;
        object.specificSymbol = null;
        object.constantSymbol = null;
    };
    return PaymentCheckComponent;
}());
exports.PaymentCheckComponent = PaymentCheckComponent;
