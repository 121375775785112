"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var map_service_1 = require("@app/map/services/map.service");
var map_utils_crs_service_1 = require("@app/map/services/map-utils-crs.service");
var L = require("leaflet");
var MapModuleCuzkComponent = /** @class */ (function () {
    function MapModuleCuzkComponent(mapService, mapUtilsCrsService) {
        this.mapService = mapService;
        this.mapUtilsCrsService = mapUtilsCrsService;
        this.visibilityInfoboxCallback = new core_1.EventEmitter();
        this.marker = null;
    }
    MapModuleCuzkComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.visibilityInfoboxCallback.emit({ infoboxVisibility: true });
        this.mapService.getMap(this.mapId).then(function (map) {
            map.on('singleclick', _this.onMapClickHandler);
        });
    };
    MapModuleCuzkComponent.prototype.ngOnDestroy = function () {
        var _this = this;
        this.mapService.getMap(this.mapId).then(function (map) {
            map.off('singleclick', _this.onMapClickHandler);
        });
        //remove marker
        if (this.marker) {
            this.marker.remove();
        }
    };
    MapModuleCuzkComponent.prototype.onMapClickHandler = function (clickEvt) {
        var _this = this;
        //add marker
        if (this.marker) {
            this.marker.remove();
        }
        this.mapService.getMap(this.mapId).then(function (map) {
            var icon = L.icon({
                iconUrl: require('img/map/marker-icon.png'),
                iconSize: [25, 41],
                iconAnchor: [13, 41]
            });
            _this.marker = L.marker(clickEvt.latlng, { icon: icon }).addTo(map);
        });
        var point = this.mapUtilsCrsService.getCrs('5514').project(clickEvt.latlng);
        var url = 'http://nahlizenidokn.cuzk.cz/MapaIdentifikace.aspx?l=KN&x=' + Math.round(point.x) + '&y=' + Math.round(point.y);
        window.open(url, '_blank');
    };
    return MapModuleCuzkComponent;
}());
exports.MapModuleCuzkComponent = MapModuleCuzkComponent;
