"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("@angular/common");
var i2 = require("../../../components/wanted-switch/wanted-switch.component.ngfactory");
var i3 = require("../../../components/wanted-switch/wanted-switch.component");
var i4 = require("../../../../common/services/auth.service");
var i5 = require("../../../../common/pipes/username.pipe");
var i6 = require("../../../../common/components/button/button.component.ngfactory");
var i7 = require("../../../../common/components/button/button.component");
var i8 = require("./map-module-cut-parcels.component");
var i9 = require("../../../../map/services/map.service");
var i10 = require("../../../../map/services/map-reload-layers.service");
var i11 = require("../../../../map/services/map-highlight.service");
var i12 = require("../../../../common/services/list.service");
var styles_MapModuleCutParcelsComponent = [];
var RenderType_MapModuleCutParcelsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MapModuleCutParcelsComponent, data: {} });
exports.RenderType_MapModuleCutParcelsComponent = RenderType_MapModuleCutParcelsComponent;
function View_MapModuleCutParcelsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "p", [["class", "mt-10 mb-10"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Nakreslete linii a potvr\u010Ft\u011B d\u011Blen\u00ED plochy"]))], null, null); }
function View_MapModuleCutParcelsComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 12, "div", [], null, [[null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseenter" === en)) {
        var pd_0 = (_co.highlightParcel(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (_co.removeHighlightParcel() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngClass: [0, "ngClass"] }, null), i0.ɵpod(2, { "row-group": 0, "hover": 1, "hovered": 2 }), (_l()(), i0.ɵeld(3, 0, null, null, 1, "span", [["class", "cell"]], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, [" ", " "])), (_l()(), i0.ɵeld(5, 0, null, null, 3, "span", [], null, null, null, null, null)), i0.ɵdid(6, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(7, { "cell": 0 }), (_l()(), i0.ɵted(8, null, [" ", " "])), (_l()(), i0.ɵeld(9, 0, null, null, 3, "span", [["class", "cell"]], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 2, "td", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 1, "wanted-switch", [], null, [[null, "changed"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("changed" === en)) {
        var pd_0 = (_co.onWantedChanged($event.newValue, $event.oldValue, $event.ownerType) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_WantedSwitchComponent_0, i2.RenderType_WantedSwitchComponent)), i0.ɵdid(12, 114688, null, 0, i3.WantedSwitchComponent, [i4.AuthService, i5.UserNamePipe, "Restangular"], { item: [0, "item"] }, { changed: "changed" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, true, true, _v.context.$implicit.properties.highlighted); _ck(_v, 1, 0, currVal_0); var currVal_2 = i0.ɵinlineInterpolate(1, "", _co.getAreaClass(_v.context.$implicit.properties.owner_type, _v.context.$implicit.properties.wanted, _v.context.$implicit.properties.key), ""); var currVal_3 = _ck(_v, 7, 0, true); _ck(_v, 6, 0, currVal_2, currVal_3); var currVal_6 = _v.context.$implicit.properties; _ck(_v, 12, 0, currVal_6); }, function (_ck, _v) { var currVal_1 = _v.context.$implicit.properties.parcelNumber; _ck(_v, 4, 0, currVal_1); var currVal_4 = _v.context.$implicit.properties.area; _ck(_v, 8, 0, currVal_4); var currVal_5 = i0.ɵinlineInterpolate(1, "intersection item-", _v.context.$implicit.properties.id, ""); _ck(_v, 10, 0, currVal_5); }); }
function View_MapModuleCutParcelsComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 15, "div", [["class", "table mb-20 bordered"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 12, "div", [["class", "head-group"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 11, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "span", [["class", "cell"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Plocha"])), (_l()(), i0.ɵeld(5, 0, null, null, 6, "span", [["class", "cell"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["V\u00FDm\u011Bra ["])), (_l()(), i0.ɵeld(7, 0, null, null, 3, "span", [["class", "unit"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["m"])), (_l()(), i0.ɵeld(9, 0, null, null, 1, "sup", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["2"])), (_l()(), i0.ɵted(-1, null, ["]"])), (_l()(), i0.ɵeld(12, 0, null, null, 1, "span", [["class", "cell"], ["style", "min-width: 260px;"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Vlastnictv\u00ED"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MapModuleCutParcelsComponent_3)), i0.ɵdid(15, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.cutParcels; _ck(_v, 15, 0, currVal_0); }, null); }
function View_MapModuleCutParcelsComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "p", [["class", "mt-10 mb-10"], ["style", "max-width: 200px;"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Rozd\u011Blen\u00ED ploch je nevratn\u00E9. Ov\u011B\u0159te spr\u00E1vnost veden\u00ED linie."]))], null, null); }
function View_MapModuleCutParcelsComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "gmt-button", [], null, null, null, i6.View_ButtonComponent_0, i6.RenderType_ButtonComponent)), i0.ɵdid(1, 114688, null, 0, i7.ButtonComponent, ["ErrorHandlerService", i0.ChangeDetectorRef], { clickAction: [0, "clickAction"] }, null), (_l()(), i0.ɵted(-1, 0, ["Rozd\u011Blit"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.onCutClick; _ck(_v, 1, 0, currVal_0); }, null); }
function View_MapModuleCutParcelsComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "gmt-button", [], null, null, null, i6.View_ButtonComponent_0, i6.RenderType_ButtonComponent)), i0.ɵdid(1, 114688, null, 0, i7.ButtonComponent, ["ErrorHandlerService", i0.ChangeDetectorRef], { clickAction: [0, "clickAction"] }, null), (_l()(), i0.ɵted(-1, 0, ["Nov\u00E9 d\u011Blen\u00ED"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.onNewCut; _ck(_v, 1, 0, currVal_0); }, null); }
function View_MapModuleCutParcelsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MapModuleCutParcelsComponent_1)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MapModuleCutParcelsComponent_2)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MapModuleCutParcelsComponent_4)), i0.ɵdid(6, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MapModuleCutParcelsComponent_5)), i0.ɵdid(8, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MapModuleCutParcelsComponent_6)), i0.ɵdid(10, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.intersectLayerExist && !_co.cutParcels); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.cutParcels; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.intersectLayerExist; _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.intersectLayerExist; _ck(_v, 8, 0, currVal_3); var currVal_4 = (_co.intersectLayerExist || _co.cutParcels); _ck(_v, 10, 0, currVal_4); }, null); }
exports.View_MapModuleCutParcelsComponent_0 = View_MapModuleCutParcelsComponent_0;
function View_MapModuleCutParcelsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "map-module-cut-parcels", [], null, null, null, View_MapModuleCutParcelsComponent_0, RenderType_MapModuleCutParcelsComponent)), i0.ɵdid(1, 245760, null, 0, i8.MapModuleCutParcelsComponent, [i9.MapService, i10.MapReloadLayersService, i11.MapHighlightService, i12.ListService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_MapModuleCutParcelsComponent_Host_0 = View_MapModuleCutParcelsComponent_Host_0;
var MapModuleCutParcelsComponentNgFactory = i0.ɵccf("map-module-cut-parcels", i8.MapModuleCutParcelsComponent, View_MapModuleCutParcelsComponent_Host_0, { mapId: "mapId", infoboxWidthGetter: "infoboxWidthGetter" }, {}, []);
exports.MapModuleCutParcelsComponentNgFactory = MapModuleCutParcelsComponentNgFactory;
