"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./tooltip-parcel.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@uirouter/angular/lib/directives/uiSref");
var i3 = require("@uirouter/core");
var i4 = require("@angular/common");
var i5 = require("./tooltip-parcel.component");
var styles_TooltipParcelComponent = [i0.styles];
var RenderType_TooltipParcelComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TooltipParcelComponent, data: {} });
exports.RenderType_TooltipParcelComponent = RenderType_TooltipParcelComponent;
function View_TooltipParcelComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "li", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "a", [["uiSref", "symap.project.titles.detail"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).go($event.button, $event.ctrlKey, $event.metaKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 16384, null, 0, i2.AnchorUISref, [i1.ElementRef, i1.Renderer2], null, null), i1.ɵdid(3, 737280, null, 0, i2.UISref, [i3.UIRouter, [2, i2.AnchorUISref], "UIView.PARENT_INJECT"], { state: [0, "state"], params: [1, "params"] }, null), i1.ɵpod(4, { id: 0 }), (_l()(), i1.ɵted(5, null, [" List vlastnictv\u00ED ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "symap.project.titles.detail"; var currVal_1 = _ck(_v, 4, 0, _co.data.parcel.title.id); _ck(_v, 3, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.data.parcel.title.lv; _ck(_v, 5, 0, currVal_2); }); }
function View_TooltipParcelComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "li", [["class", "cuzk"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "a", [["class", "underlined"], ["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Nahl\u00ED\u017Een\u00ED do katastru"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.data.parcel.cuzk, ""); _ck(_v, 1, 0, currVal_0); }); }
function View_TooltipParcelComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TooltipParcelComponent_2)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 5, "li", [["class", "map"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 4, "a", [["uiSref", "symap.project.map"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).go($event.button, $event.ctrlKey, $event.metaKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 16384, null, 0, i2.AnchorUISref, [i1.ElementRef, i1.Renderer2], null, null), i1.ɵdid(6, 737280, null, 0, i2.UISref, [i3.UIRouter, [2, i2.AnchorUISref], "UIView.PARENT_INJECT"], { state: [0, "state"], params: [1, "params"] }, null), i1.ɵpod(7, { parcel: 0 }), (_l()(), i1.ɵted(-1, null, ["Mapov\u00E9 okno"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TooltipParcelComponent_3)), i1.ɵdid(10, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 5, "li", [["class", "parcel"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 4, "a", [["uiSref", "symap.project.parcels.detail"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 14).go($event.button, $event.ctrlKey, $event.metaKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(13, 16384, null, 0, i2.AnchorUISref, [i1.ElementRef, i1.Renderer2], null, null), i1.ɵdid(14, 737280, null, 0, i2.UISref, [i3.UIRouter, [2, i2.AnchorUISref], "UIView.PARENT_INJECT"], { state: [0, "state"], params: [1, "params"] }, null), i1.ɵpod(15, { id: 0 }), (_l()(), i1.ɵted(-1, null, ["Detail parcely"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.data.withoutTitle && ((_co.data.parcel.title == null) ? null : _co.data.parcel.title.id)); _ck(_v, 2, 0, currVal_0); var currVal_1 = "symap.project.map"; var currVal_2 = _ck(_v, 7, 0, _co.data.parcel.id); _ck(_v, 6, 0, currVal_1, currVal_2); var currVal_3 = _co.data.parcel.cuzk; _ck(_v, 10, 0, currVal_3); var currVal_4 = "symap.project.parcels.detail"; var currVal_5 = _ck(_v, 15, 0, _co.data.parcel.id); _ck(_v, 14, 0, currVal_4, currVal_5); }, null); }
function View_TooltipParcelComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Parcela nenalezena v KN"]))], null, null); }
function View_TooltipParcelComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_TooltipParcelComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TooltipParcelComponent_4)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.data == null) ? null : _co.data.parcel); _ck(_v, 1, 0, currVal_0); var currVal_1 = (!_co.data || !_co.data.parcel); _ck(_v, 3, 0, currVal_1); }, null); }
exports.View_TooltipParcelComponent_0 = View_TooltipParcelComponent_0;
function View_TooltipParcelComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "tooltip-parcel", [], null, null, null, View_TooltipParcelComponent_0, RenderType_TooltipParcelComponent)), i1.ɵdid(1, 49152, null, 0, i5.TooltipParcelComponent, [], null, null)], null, null); }
exports.View_TooltipParcelComponent_Host_0 = View_TooltipParcelComponent_Host_0;
var TooltipParcelComponentNgFactory = i1.ɵccf("tooltip-parcel", i5.TooltipParcelComponent, View_TooltipParcelComponent_Host_0, { data: "data" }, {}, []);
exports.TooltipParcelComponentNgFactory = TooltipParcelComponentNgFactory;
