"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var L = require("leaflet");
var i0 = require("@angular/core");
/**
 * Extension of LeafletGroup, added id attribute.
 */
var LeafletLayerGroupService = /** @class */ (function () {
    function LeafletLayerGroupService() {
        this.LayerGroup = L.LayerGroup.extend({
            initialize: function (layers, id) {
                if (!id) {
                    throw new Error("Missing layer group id option in initialization.");
                }
                this._id = id;
                L.LayerGroup.prototype.initialize.call(this, layers);
            }
        });
    }
    LeafletLayerGroupService.prototype.getLayerGroup = function (layers, id) {
        return new this.LayerGroup(layers, id);
    };
    LeafletLayerGroupService.ngInjectableDef = i0.defineInjectable({ factory: function LeafletLayerGroupService_Factory() { return new LeafletLayerGroupService(); }, token: LeafletLayerGroupService, providedIn: "root" });
    return LeafletLayerGroupService;
}());
exports.LeafletLayerGroupService = LeafletLayerGroupService;
