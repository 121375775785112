"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["[_nghost-%COMP%] {\n  display: inline-block;\n  position: relative; }\n  [_nghost-%COMP%]   .state-label[_ngcontent-%COMP%] {\n    color: #1a427b;\n    font-size: 1.3em; }\n  [_nghost-%COMP%]   .button.active[_ngcontent-%COMP%] {\n    position: relative;\n    top: -2px; }\n  [_nghost-%COMP%]   .content-container[_ngcontent-%COMP%] {\n    position: absolute;\n    min-width: 400px;\n    padding-left: 46px; }\n    [_nghost-%COMP%]   .content-container[_ngcontent-%COMP%]   a[_ngcontent-%COMP%] {\n      display: block; }\n      [_nghost-%COMP%]   .content-container[_ngcontent-%COMP%]   a[_ngcontent-%COMP%]:hover {\n        background: #fbfbdb; }"];
exports.styles = styles;
