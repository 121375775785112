"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var ContactFormComponent = /** @class */ (function () {
    function ContactFormComponent() {
        this.editable = true;
        this.editableToggle = false;
    }
    ContactFormComponent.prototype.ngOnInit = function () { };
    return ContactFormComponent;
}());
exports.ContactFormComponent = ContactFormComponent;
