"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var _ = require("lodash");
var L = require("leaflet");
var leaflet_wms_common_service_1 = require("@app/map/services/leaflet-wms-common.service");
var map_ogc_common_service_1 = require("@app/map/services/map-ogc-common.service");
var map_utils_wfs_feature_service_1 = require("@app/map/services/map-utils-wfs-feature.service");
var i0 = require("@angular/core");
var i1 = require("@angular/common/http");
var i2 = require("./leaflet-wms-common.service");
var i3 = require("./map-ogc-common.service");
var i4 = require("./map-utils-wfs-feature.service");
/**
 * GeoJSON WFS layer
 *
 * Based on code from CAN by Martin Tesar
 * Modified by Petr Sobeslavsky to work with L.Proj.GeoJSON - GeoJSON layer with custom CRS support
 */
var LeafletProjGeoJsonWfs = /** @class */ (function () {
    function LeafletProjGeoJsonWfs(http, leafletWmsCommonService, mapOgcCommonService, mapUtilsWFSFeatureService) {
        this.ProjGeoJsonWfs = L.Proj.GeoJSON.extend({
            defaultWfsParams: {
                service: 'WFS',
                request: 'GetFeature',
                version: '2.0.0',
                typeNames: '',
                outputFormat: 'application/json',
                count: 100,
                srsName: 'EPSG:5514',
            },
            initialize: function (url, options) {
                if (!options || !options.id) {
                    throw new Error("Missing layer id option in initialization.");
                }
                this.state = {
                    dataBounds: null,
                    newBounds: null,
                    zoom: null,
                    newZoom: null,
                    featuresFetched: 0,
                    limitReached: false,
                    currentDownloadChain: 0
                };
                this._id = options.id;
                this._url = url;
                this._wfsParams = __assign({}, L.extend(this.defaultWfsParams, options.wfs));
                this._filter = options.filter;
                this._wfsFormatParams = this.defaultWfsFormatParams;
                this._geometryColumn = options.geometryColumn || 'geom';
                this._limit = options.limit || 2000;
                this._httpService = http;
                this._chunked = false;
                this._unbounded = false;
                if (options.unbounded) {
                    this._unbounded = true;
                }
                if (options.chunked) {
                    // only set startIndex if we are downloading data in chunks
                    // it requires layers to provide natural ordering, so we cannot set it by default
                    // for all layers
                    this._wfsParams.startIndex = 0;
                    this._chunked = true;
                }
                if (options.dataCallback) {
                    this._dataCallback = options.dataCallback;
                }
                L.GeoJSON.prototype.initialize.call(this, null, options.geojson);
            },
            loadFeatureData: function (options) {
                var filter = null;
                if (this._filter) {
                    filter = mapOgcCommonService.getFilter(this._filter, true);
                }
                return leafletWmsCommonService.loadFeatureData({
                    latlng: options.latlng,
                    queryParams: options.queryParams,
                    url: this._url,
                    uppercase: this._uppercase,
                    httpService: this._httpService,
                    map: this._map,
                    crs: this._crs,
                    layers: this._wfsParams['typeNames'],
                    filter: filter,
                    cqlFilter: null,
                    featureCount: this._wfsParams['feature_count']
                });
            },
            loadFeatureDataByID: function (featureID, queryParams) {
                var wfsBaseURL = this._url;
                return mapUtilsWFSFeatureService.getFeatureByID(wfsBaseURL, this._wfsParams['typeNames'], featureID, queryParams);
            },
            onAdd: function (map) {
                L.GeoJSON.prototype.onAdd.call(this, map);
                this._crs = this.options.crs || map.options.crs;
                this._uppercase = this.options.uppercase || false;
                this._map = map;
                map.on({
                    moveend: this._updateDisplayedMap
                }, this);
                this._updateDisplayedMap();
            },
            _updateDisplayedMap: function () {
                if (!this._map) {
                    return;
                }
                var bounds = this._map.getBounds();
                var zoom = this._map.getZoom();
                this.update(bounds, zoom);
            },
            getExtendedBounds: function (bounds) {
                // extend bounds by 10 % in all directions
                return bounds.pad(0.05);
            },
            update: function (viewBounds, newZoom) {
                if (!this._map) {
                    return;
                }
                if (this._needsUpdate(viewBounds, newZoom)) {
                    this.state.newBounds = viewBounds;
                    this.state.newZoom = newZoom;
                    this._getFeatures(viewBounds);
                }
            },
            refresh: function () {
                this._getFeatures(this.state.newBounds);
            },
            _getFormatString: function (obj) {
                var params = [];
                for (var i in obj) {
                    if (obj.hasOwnProperty(i)) {
                        params.push(i + ':' + obj[i]);
                    }
                }
                return params.join(';');
            },
            _getRequestUrl: function (_wfsParams, bounds) {
                var extendedBounds = this.getExtendedBounds(bounds);
                var sw = this._crs.project(extendedBounds.getSouthWest());
                var ne = this._crs.project(extendedBounds.getNorthEast());
                var bbox = sw.x + ',' + sw.y + ' ' + ne.x + ',' + ne.y;
                // we need to supply BBOX as part of filter, because we cannot specify both bbox and filter
                var requestFilter;
                if (!this._unbounded) {
                    var bboxFilter = {
                        type: 'BBOX',
                        propertyName: this._geometryColumn,
                        coordinates: bbox
                    };
                    if (this._filter) {
                        requestFilter = {
                            type: 'And',
                            arg1: bboxFilter,
                            arg2: this._filter
                        };
                    }
                    else {
                        requestFilter = bboxFilter;
                    }
                }
                else {
                    requestFilter = this._filter;
                }
                _wfsParams['filter'] = mapOgcCommonService.getFilter(requestFilter, true);
                // prepare format params
                var fo = this._getFormatString(this._wfsFormatParams);
                return this._url +
                    L.Util.getParamString(_wfsParams, this._url, this._uppercase) +
                    (this._uppercase ? '&FORMAT_OPTIONS=' : '&format_options=') + fo;
            },
            _getFeatures: function (bounds) {
                var _wfsParams = _.cloneDeep(this._wfsParams);
                this.state.currentDownloadChain += 1;
                var downloadChain = this.state.currentDownloadChain;
                this.state.featuresFetched = 0;
                this.state.limitReached = false;
                this._fetchFeatures(_wfsParams, bounds, downloadChain);
            },
            _fetchFeatures: function (_wfsParams, bounds, downloadChain) {
                var url = this._getRequestUrl(_wfsParams, bounds);
                var _this = this;
                this._httpService.get(url).toPromise()
                    .then(function (data) {
                    _this._FeaturesFetchedCallback(data, _wfsParams, bounds, downloadChain);
                }, function () {
                    _this._updateDisplayedMapFailCallback();
                });
            },
            _FeaturesFetchedCallback: function (data, _wfsParams, bounds, downloadChain) {
                // if we received data from an old call, discard them
                if (downloadChain < this.state.currentDownloadChain) {
                    return;
                }
                if (this.state.featuresFetched === 0) {
                    // delete old data upon reception of the first batch of new data
                    this.clearLayers();
                }
                this.state.featuresFetched += data.features.length;
                this.addData(data);
                if (this._dataCallback) {
                    this._dataCallback(data);
                }
                if (this.state.featuresFetched >= data.totalFeatures) {
                    this._updateDisplayedMapDoneFinish(data);
                    return;
                }
                if (this.state.featuresFetched >= this._limit) {
                    this.state.limitReached = true;
                    this._updateDisplayedMapDoneFinish(data);
                    return;
                }
                if (this._chunked) {
                    _wfsParams.startIndex += _wfsParams.count;
                    this._fetchFeatures(_wfsParams, bounds, downloadChain);
                }
            },
            _updateDisplayedMapFailCallback: function () {
            },
            _updateDisplayedMapDoneFinish: function () {
                this.state.dataBounds = this.state.newBounds;
                this.state.zoom = this.state.newZoom;
            },
            _needsUpdate: function (viewBounds, newZoom) {
                var oldBounds = this.state.dataBounds;
                var oldZoom = this.state.zoom;
                var needs = false;
                if ((newZoom > oldZoom) && this.state.limitReached) {
                    needs = true;
                }
                if (!(oldBounds && oldBounds.contains(viewBounds))) {
                    needs = true;
                }
                return needs;
            }
        });
    }
    LeafletProjGeoJsonWfs.prototype.getLayer = function (url, options) {
        return new this.ProjGeoJsonWfs(url, options);
    };
    LeafletProjGeoJsonWfs.ngInjectableDef = i0.defineInjectable({ factory: function LeafletProjGeoJsonWfs_Factory() { return new LeafletProjGeoJsonWfs(i0.inject(i1.HttpClient), i0.inject(i2.LeafletWmsCommonService), i0.inject(i3.MapOgcCommonService), i0.inject(i4.MapUtilsWfsFeatureService)); }, token: LeafletProjGeoJsonWfs, providedIn: "root" });
    return LeafletProjGeoJsonWfs;
}());
exports.LeafletProjGeoJsonWfs = LeafletProjGeoJsonWfs;
