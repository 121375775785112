"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./tooltip.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../../directives/tooltip/tooltipContent.directive");
var i3 = require("../perfectscrollbar/perfectscrollbar.component.ngfactory");
var i4 = require("../perfectscrollbar/perfectscrollbar.component");
var i5 = require("../../directives/tooltip/tooltipHost.directive");
var i6 = require("./tooltip.component");
var styles_TooltipComponent = [i0.styles];
var RenderType_TooltipComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TooltipComponent, data: {} });
exports.RenderType_TooltipComponent = RenderType_TooltipComponent;
function View_TooltipComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_TooltipComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { tooltipContentContainer: 0 }), i1.ɵqud(402653184, 2, { tooltipHostDirective: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "tooltip"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, [[1, 0]], null, 5, "div", [["class", "tooltip-content"]], null, null, null, null, null)), i1.ɵdid(4, 16384, null, 0, i2.TooltipContentDirective, [], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 3, "perfect-scrollbar", [], null, null, null, i3.View_PerfectScrollbarComponent_0, i3.RenderType_PerfectScrollbarComponent)), i1.ɵdid(6, 114688, null, 0, i4.PerfectScrollbarComponent, [i1.ElementRef, i1.KeyValueDiffers, i1.NgZone], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_TooltipComponent_1)), i1.ɵdid(8, 16384, [[2, 4]], 0, i5.TooltipHostDirective, [i1.ViewContainerRef], null, null)], function (_ck, _v) { _ck(_v, 6, 0); }, null); }
exports.View_TooltipComponent_0 = View_TooltipComponent_0;
function View_TooltipComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_TooltipComponent_0, RenderType_TooltipComponent)), i1.ɵdid(1, 114688, null, 0, i6.TooltipComponent, ["tooltipConfig", i1.ComponentFactoryResolver, i1.Injector, i1.Renderer2], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_TooltipComponent_Host_0 = View_TooltipComponent_Host_0;
var TooltipComponentNgFactory = i1.ɵccf("ng-component", i6.TooltipComponent, View_TooltipComponent_Host_0, { data: "data" }, {}, []);
exports.TooltipComponentNgFactory = TooltipComponentNgFactory;
