"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var username_pipe_1 = require("./username.pipe");
var i0 = require("@angular/core");
var i1 = require("./username.pipe");
var TitleUserUniqueNamesPipe = /** @class */ (function () {
    function TitleUserUniqueNamesPipe(userNamePipe) {
        this.userNamePipe = userNamePipe;
    }
    TitleUserUniqueNamesPipe.prototype.transform = function (titles) {
        var _this = this;
        var uniqueUsers = [];
        titles.forEach(function (title) {
            if (!title.user && !uniqueUsers.includes(null)) {
                uniqueUsers.push(null);
            }
            else if (title.user && !uniqueUsers.find(function (u) { return u.id === title.user.id; })) {
                uniqueUsers.push(title.user);
            }
        });
        return uniqueUsers.length > 0
            ? uniqueUsers.map(function (user) { return user ? _this.userNamePipe.transform(user) : 'nezvoleno'; }).join(', ')
            : '';
    };
    TitleUserUniqueNamesPipe.ngInjectableDef = i0.defineInjectable({ factory: function TitleUserUniqueNamesPipe_Factory() { return new TitleUserUniqueNamesPipe(i0.inject(i1.UserNamePipe)); }, token: TitleUserUniqueNamesPipe, providedIn: "root" });
    return TitleUserUniqueNamesPipe;
}());
exports.TitleUserUniqueNamesPipe = TitleUserUniqueNamesPipe;
