"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var _ = require("lodash");
var map_service_1 = require("@app/map/services/map.service");
var map_layers_storage_service_1 = require("@app/map/services/map-layers-storage.service");
var map_config_service_1 = require("@app/map/services/map-config.service");
var layers_config_service_1 = require("@app/map/services/layers-config.service");
var auth_service_1 = require("@app/common/services/auth.service");
var map_utils_crs_service_1 = require("@app/map/services/map-utils-crs.service");
var map_layer_type_enum_1 = require("@app/map/enums/map-layer-type.enum");
var i0 = require("@angular/core");
var i1 = require("./map.service");
var i2 = require("./map-layers-storage.service");
var i3 = require("./map-config.service");
var i4 = require("./map-utils-crs.service");
var i5 = require("../../common/services/auth.service");
var MapInfoboxService = /** @class */ (function () {
    function MapInfoboxService(mapService, mapLayersStorageService, mapConfigService, mapUtilsCrsService, authService) {
        this.mapService = mapService;
        this.mapLayersStorageService = mapLayersStorageService;
        this.mapConfigService = mapConfigService;
        this.mapUtilsCrsService = mapUtilsCrsService;
        this.authService = authService;
        this.register = this.register.bind(this);
    }
    MapInfoboxService.prototype.getMapClickListener = function (map, mapId, selectorConfig, clickHandler, onFeatureDataLoaded) {
        var _this = this;
        return function (event) { return __awaiter(_this, void 0, void 0, function () {
            var popupOptions, loaders, leafletLayers, index;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        popupOptions = {};
                        popupOptions.featureDataLoaded = [];
                        popupOptions.featureData = [];
                        loaders = [];
                        return [4 /*yield*/, this.mapLayersStorageService.getLayers(mapId)];
                    case 1:
                        leafletLayers = _a.sent();
                        index = 0;
                        if (selectorConfig.enableCuzkInfo === true) {
                            loaders.push(Promise.resolve((layers_config_service_1.baseKnTemplate.bind({ mapUtilsCrsService: this.mapUtilsCrsService }))(event).map(function (item, key) {
                                item.layerID = '_cuzkInfo';
                                item.featureID = key;
                                item.highlightFeature = null;
                                return item;
                            })));
                        }
                        this.mapConfigService.forEachLayer(selectorConfig, function (layer) {
                            if (layer.visible && (layer.type === map_layer_type_enum_1.MapLayerTypeEnum.wms || layer.type === map_layer_type_enum_1.MapLayerTypeEnum.wfs)) {
                                var layerObj = _this.mapLayersStorageService.getLayerById(leafletLayers, layer.id);
                                if (layer.onLayerClick) {
                                    layerObj.loadFeatureData({
                                        latlng: event.latlng,
                                        version: _this.getLayerVersion(layerObj),
                                        queryParams: { t: _this.authService.getToken(), p: _this.authService.getActualProject().key }
                                    }).then(function (data) {
                                        var _data = [];
                                        if (data.features) {
                                            _data = data.features;
                                        }
                                        else if (Array.isArray(data) && data.length > 0) { // hfbiz multiscenecore layers are served this way
                                            _data = _.flatten(data
                                                .filter(function (item) { return item.features && item.features.length > 0; })
                                                .map(function (item) { return item.features; }));
                                        }
                                        if (_data.length > 0) {
                                            layer.onLayerClick(event, _data, layer);
                                        }
                                    });
                                }
                                if (layer.featureSummaryTemplate) {
                                    var layerIndex_1 = index++;
                                    popupOptions.featureData = [];
                                    popupOptions.featureDataLoaded[layerIndex_1] = false;
                                    var dataLoad = void 0;
                                    if (typeof layer.featureSummaryTemplate === 'function') {
                                        dataLoad = Promise.resolve(layer.featureSummaryTemplate(event)).then(function (data) {
                                            return data.map(function (item, key) {
                                                item.layerID = layer.id;
                                                item.featureID = item.featureID || key;
                                                item.highlightFeature = item.highlightFeature || null;
                                                item.templateProperties = item;
                                                return item;
                                            });
                                        });
                                    }
                                    else {
                                        dataLoad = layerObj.loadFeatureData({
                                            latlng: event.latlng,
                                            version: _this.getLayerVersion(layerObj),
                                            queryParams: {
                                                t: _this.authService.getToken(),
                                                p: _this.authService.getActualProject().key,
                                            }
                                        }).then(function (data) {
                                            if (data.features && data.features.length > 0) {
                                                return data.features.map(function (f) {
                                                    return {
                                                        properties: f.properties,
                                                        layerID: layer.id,
                                                        featureID: f.properties.id,
                                                        templateProperties: layer.featureSummaryTemplate,
                                                    };
                                                });
                                            }
                                        });
                                    }
                                    dataLoad = dataLoad.then(function (featureData) {
                                        var _a;
                                        popupOptions.featureDataLoaded[layerIndex_1] = true;
                                        if (featureData) {
                                            (_a = popupOptions.featureData).push.apply(_a, __spread(featureData));
                                            onFeatureDataLoaded(featureData);
                                        }
                                        else {
                                            onFeatureDataLoaded([]);
                                        }
                                    });
                                    loaders.push(dataLoad);
                                }
                            }
                        });
                        loaders.forEach(function (featureDataloader) { return featureDataloader.then(function (featureData) {
                            var _a;
                            if (featureData) {
                                (_a = popupOptions.featureData).push.apply(_a, __spread(featureData));
                                onFeatureDataLoaded(featureData);
                            }
                        }); });
                        if (typeof clickHandler === 'function') {
                            clickHandler(popupOptions, event);
                        }
                        onFeatureDataLoaded([]);
                        return [2 /*return*/];
                }
            });
        }); };
    };
    MapInfoboxService.prototype.getLayerVersion = function (layer) {
        return (layer.options && layer.options.version) || (layer.wmsParams && layer.wmsParams.version);
    };
    // temporary disable popups
    MapInfoboxService.prototype.disablePopup = function (evt) {
        if (this.onMapClickHandler) {
            evt.target.off('singleclick', this.onMapClickHandler);
        }
    };
    // reenable popups
    MapInfoboxService.prototype.enablePopup = function (evt) {
        if (this.onMapClickHandler) {
            evt.target.on('singleclick', this.onMapClickHandler);
        }
    };
    MapInfoboxService.prototype.register = function (mapId, selectorConfig, clickHandler, onFeatureDataLoaded) {
        var _this = this;
        this.mapService.getMap(mapId).then(function (map) {
            _this.onMapClickHandler = _this.getMapClickListener(map, mapId, selectorConfig, clickHandler, onFeatureDataLoaded);
            map.on('singleclick', _this.onMapClickHandler);
            // map.on('draw:drawstart', disablePopup);
            // map.on('draw:drawstop', enablePopup);
        });
    };
    MapInfoboxService.prototype.deregister = function (mapId) {
        var _this = this;
        if (this.onMapClickHandler) {
            this.mapService.getMap(mapId).then(function (map) {
                map.off('singleclick', _this.onMapClickHandler);
            });
        }
    };
    MapInfoboxService.ngInjectableDef = i0.defineInjectable({ factory: function MapInfoboxService_Factory() { return new MapInfoboxService(i0.inject(i1.MapService), i0.inject(i2.MapLayersStorageService), i0.inject(i3.MapConfigService), i0.inject(i4.MapUtilsCrsService), i0.inject(i5.AuthService)); }, token: MapInfoboxService, providedIn: "root" });
    return MapInfoboxService;
}());
exports.MapInfoboxService = MapInfoboxService;
