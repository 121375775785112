"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var dialog_config_1 = require("../dialog-config");
var dialog_ref_1 = require("../dialog-ref");
var ConfirmationComponent = /** @class */ (function () {
    function ConfirmationComponent(config, dialog) {
        this.config = config;
        this.dialog = dialog;
        this.onCancel = this.onCancel.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }
    ConfirmationComponent.prototype.onCancel = function () {
        this.dialog.close(false);
    };
    ConfirmationComponent.prototype.onSubmit = function () {
        this.dialog.close(true);
    };
    return ConfirmationComponent;
}());
exports.ConfirmationComponent = ConfirmationComponent;
