var common = angular.module('common');

/**
 * Handle modals
 */
common.service('ModalService', ['$compile', '$controller', '$q', '$http', '$templateCache', '$rootScope', '$timeout', '$window', '$document', '$transitions',
  function ($compile, $controller, $q, $http, $templateCache, $rootScope, $timeout, $window, $document, $transitions) {
    var zIndex = 2000;

    return function (config) {
        zIndex++;
        var html;
        if (config.templateUrl) {
            html = $http.get(config.templateUrl, {
                cache: $templateCache
            }).then(function (response) {
                return response.data;
            });
        } else if (config.template) {
            html = $q.defer();
            html.resolve(config.template);
            html = html.promise;
        }
        var deffered = $q.defer();
        var container = angular.element(document.body);
        var controller = config.controller || angular.noop;
        var eventName = config.eventName;
        var callback = config.callback;
        var className = config.className;
        var scope = $rootScope.$new();
        var allowClose = config.allowClose !== false;
        if (config.scope !== undefined) {
            for (var key in config.scope) {
                scope[key] = config.scope[key];
            }
        }

        var element = angular.element(
            '<div class="flex-centered modal-container" ng-click="onOverlayClick($event)" ng-mousedown="checkBlockEvent($event)" ng-keydown="onEscape($event)">'+
            '<div class="modal' + (className ? ' ' + className : '') + '" tabindex="0" ng-mouseenter="blockOverlayEvent = true">' +
            (allowClose ? '<a class="close fa fa-times" ng-click="closeModal()"></a>': '') +
            '<div class="modal-content">' +
            '<perfect-scrollbar (callback-register)="scrollTopRegister($event.scrollTop, $event.updateScrollbar)"></perfect-scrollbar>' +
            '</div>' +
            (config.helpId ? `<cm-help help-id="${config.helpId}" class="cm-help-modal"></cm-help>` : '') +
            '</div>'+
            '</div>');
        container.prepend(element);
        element.css('z-index', zIndex);
        element.focus();

        var w = angular.element($window);
        var b = angular.element($document[0].body);
        var resizeHandler = null;

        var unregChangeStart = $transitions.onStart({}, (trans) => {
            if (trans.to() !== trans.from() && allowClose) {
                $timeout(restoreState);
                unregChangeStart();
            }
        });

        function restoreState() {
            zIndex--;
            element.remove();
            scope.$destroy();
            element = null;
            if (zIndex === 2000) {
                b.css('overflow', 'hidden');
            }
            unregChangeStart();
            if (resizeHandler) {
                w.unbind("resize", resizeHandler);
            }
        }

        // For handling situations when button is pressed elsewhere and released over overlay
        scope.blockOverlayEvent = null;

        scope.checkBlockEvent = (event) => {
          if (event.target === element[0]) {
            scope.blockOverlayEvent = false;
          } else {
            scope.blockOverlayEvent = true;
          }
        };

        scope.onEscape = function(evt) {
            evt = evt || window.event;
            if (allowClose && evt.keyCode === 27) {    // Escape key
                scope.closeModal();
            }
        };

        scope.onOverlayClick = function() {
            if(allowClose && !scope.blockOverlayEvent) {
                scope.closeModal();
            }
        };

        scope.closeModalOriginal = function (data) {
            if (element) {
                restoreState();

                if (eventName) {
                    $rootScope.$broadcast(eventName, data);
                }

                if (callback) {
                    callback(data);
                }

                deffered.resolve(data);
            }
        };

        scope.closeModal = scope.closeModalOriginal;

        scope.setCloseModal = function (closeModal) {
          if (closeModal) {
            scope.closeModal = closeModal;
          } else {
            scope.closeModal = scope.closeModalOriginal;
          }
        };

        scope.scrollTop = angular.noop;

        scope.updateHelpId = function (helpId) {
          const helpElm = container.find('cm-help')[0];
          if (helpElm) {
            helpElm.parentNode.removeChild(helpElm);
          }

          if (helpId) {
            const helpElm = $compile(angular.element(`<cm-help help-id="${helpId}" class="cm-help-modal"></cm-help>`))(scope);
            container.find(".modal").append(helpElm);
          }
        };

        b.css('overflow', 'hidden');

        // wait for HTML to be ready
        html.then(function (html) {
            // append HTML to element
            element.find('perfect-scrollbar').append('<div class="modal-inner-content">' + html + '</div>');

            scope.updateScrollbar = function () {
                var scroll = $($(element[0]).find('.modal-content > * > .scrollable')[0]);
                scroll.css('max-height', (w.height() - 80) + 'px');
                if (scope.updatePerfectScrollbar) {
                  scope.updatePerfectScrollbar();
                }
            };

            scope.scrollTopRegister = function(scrollTop, update) {
                scope.scrollTop = scrollTop;
                scope.updatePerfectScrollbar = update;
            };

            $controller(controller, {$scope: scope});

            $compile(element)(scope);

            resizeHandler = function () {
                scope.updateScrollbar();
            };
            w.resize(resizeHandler);
            $timeout(function () {
                scope.updateScrollbar();
            });
        });

        return deffered.promise;
    };
}]);
