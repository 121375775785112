"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("@angular/common");
var i2 = require("./cadastre-data-date.component");
var i3 = require("../../../common/services/auth.service");
var styles_CadastreDataDateComponent = [];
var RenderType_CadastreDataDateComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CadastreDataDateComponent, data: {} });
exports.RenderType_CadastreDataDateComponent = RenderType_CadastreDataDateComponent;
function View_CadastreDataDateComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i1.DatePipe, [i0.LOCALE_ID]), (_l()(), i0.ɵeld(1, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, [" M\u011Bs\u00ED\u010Dn\u00ED data KN aktu\u00E1ln\u00ED k ", "\n"])), i0.ɵppd(3, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i0.ɵnov(_v, 0), _co.cadastreDataDate, "d. M. yyyy")); _ck(_v, 2, 0, currVal_0); }); }
exports.View_CadastreDataDateComponent_0 = View_CadastreDataDateComponent_0;
function View_CadastreDataDateComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "cadastre-data-date", [], null, null, null, View_CadastreDataDateComponent_0, RenderType_CadastreDataDateComponent)), i0.ɵdid(1, 114688, null, 0, i2.CadastreDataDateComponent, [i3.AuthService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_CadastreDataDateComponent_Host_0 = View_CadastreDataDateComponent_Host_0;
var CadastreDataDateComponentNgFactory = i0.ɵccf("cadastre-data-date", i2.CadastreDataDateComponent, View_CadastreDataDateComponent_Host_0, {}, {}, []);
exports.CadastreDataDateComponentNgFactory = CadastreDataDateComponentNgFactory;
