"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var projects_states_1 = require("./projects.states");
var STATES = [
    projects_states_1.projectInfoState,
];
var ProjectsModule = /** @class */ (function () {
    function ProjectsModule() {
    }
    return ProjectsModule;
}());
exports.ProjectsModule = ProjectsModule;
