"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("./vfze-detail.component");
var styles_VfzeDetailComponent = [];
var RenderType_VfzeDetailComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_VfzeDetailComponent, data: {} });
exports.RenderType_VfzeDetailComponent = RenderType_VfzeDetailComponent;
function View_VfzeDetailComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["Detail\n"]))], null, null); }
exports.View_VfzeDetailComponent_0 = View_VfzeDetailComponent_0;
function View_VfzeDetailComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "vfze-detail", [], null, null, null, View_VfzeDetailComponent_0, RenderType_VfzeDetailComponent)), i0.ɵdid(1, 114688, null, 0, i1.VfzeDetailComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_VfzeDetailComponent_Host_0 = View_VfzeDetailComponent_Host_0;
var VfzeDetailComponentNgFactory = i0.ɵccf("vfze-detail", i1.VfzeDetailComponent, View_VfzeDetailComponent_Host_0, { vfzeId: "vfzeId" }, {}, []);
exports.VfzeDetailComponentNgFactory = VfzeDetailComponentNgFactory;
