"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var map_layer_type_enum_1 = require("@app/map/enums/map-layer-type.enum");
var leaflet_proj_geo_json_wfs_service_1 = require("@app/map/services/leaflet-proj-geo-json-wfs.service");
var leaflet_tile_layer_wfs_service_1 = require("@app/map/services/leaflet-tile-layer-wfs.service");
var leaflet_tile_layer_wms_filtered_service_1 = require("@app/map/services/leaflet-tile-layer-wms-filtered.service");
var leaflet_tile_layer_wms_cql_filtered_service_1 = require("@app/map/services/leaflet-tile-layer-wms-cql-filtered.service");
var leaflet_layer_group_service_1 = require("@app/map/services/leaflet-layer-group.service");
var leaflet_tile_layer_wmts_service_1 = require("@app/map/services/leaflet-tile-layer-wmts.service");
var i0 = require("@angular/core");
var i1 = require("./leaflet-proj-geo-json-wfs.service");
var i2 = require("./leaflet-tile-layer-wfs.service");
var i3 = require("./leaflet-tile-layer-wms-filtered.service");
var i4 = require("./leaflet-tile-layer-wms-cql-filtered.service");
var i5 = require("./leaflet-layer-group.service");
var i6 = require("./leaflet-tile-layer-wmts.service");
var MapLayerFactoryService = /** @class */ (function () {
    function MapLayerFactoryService(leafletProjGeoJsonWfs, leafletTileLayerWfs, leafletTileLayerWmsFiltered, leafletTileLayerWmsCQLFiltered, leafletLayerGroup, leafletTileLayerWmts) {
        this.leafletProjGeoJsonWfs = leafletProjGeoJsonWfs;
        this.leafletTileLayerWfs = leafletTileLayerWfs;
        this.leafletTileLayerWmsFiltered = leafletTileLayerWmsFiltered;
        this.leafletTileLayerWmsCQLFiltered = leafletTileLayerWmsCQLFiltered;
        this.leafletLayerGroup = leafletLayerGroup;
        this.leafletTileLayerWmts = leafletTileLayerWmts;
    }
    ///////////////////////////////////////////////////////////////////////////
    // create layer from configuration
    MapLayerFactoryService.prototype.createLayer = function (config, id) {
        switch (config['type']) {
            case map_layer_type_enum_1.MapLayerTypeEnum.wms:
                config.id = id;
                return this.createWmsLayer(config);
            case map_layer_type_enum_1.MapLayerTypeEnum.wmts:
                config.id = id;
                return this.createWmtsLayer(config);
            case map_layer_type_enum_1.MapLayerTypeEnum.wfs:
                config.id = id;
                return this.createGeoJsonWfsLayer(config);
            case 'wfs-tiled':
                config.id = id;
                return this.createTileWfsLayer(config);
            default:
                throw new Error('Unknown layer type ' + config.type);
        }
    };
    /**
     * TODO check id param !!!
     */
    MapLayerFactoryService.prototype.createLayerGroup = function (layers, id) {
        return this.leafletLayerGroup.getLayerGroup(layers, id);
    };
    ///////////////////////////////////////////////////////////////////////////
    // create GeoJSON layer
    MapLayerFactoryService.prototype.createGeoJsonWfsLayer = function (layerConfig) {
        return this.leafletProjGeoJsonWfs.getLayer(layerConfig['url'], layerConfig);
    };
    // create tileds WFS layer
    MapLayerFactoryService.prototype.createTileWfsLayer = function (layerConfig) {
        return this.leafletTileLayerWfs.getLayer(layerConfig['url'], layerConfig);
    };
    // create WMS layer
    MapLayerFactoryService.prototype.createWmsLayer = function (layerConfig) {
        if (layerConfig['cqlFilter']) {
            return this.leafletTileLayerWmsCQLFiltered.getLayer(layerConfig['url'], layerConfig, layerConfig['cqlFilter']);
        }
        else {
            return this.leafletTileLayerWmsFiltered.getLayer(layerConfig['url'], layerConfig);
        }
    };
    MapLayerFactoryService.prototype.createWmtsLayer = function (layerConfig) {
        return this.leafletTileLayerWmts.getLayer(layerConfig['url'], layerConfig);
    };
    MapLayerFactoryService.ngInjectableDef = i0.defineInjectable({ factory: function MapLayerFactoryService_Factory() { return new MapLayerFactoryService(i0.inject(i1.LeafletProjGeoJsonWfs), i0.inject(i2.LeafletTileLayerWfs), i0.inject(i3.LeafletTileLayerWmsFiltered), i0.inject(i4.LeafletTileLayerWmsCQLFiltered), i0.inject(i5.LeafletLayerGroupService), i0.inject(i6.LeafletTileLayerWmts)); }, token: MapLayerFactoryService, providedIn: "root" });
    return MapLayerFactoryService;
}());
exports.MapLayerFactoryService = MapLayerFactoryService;
