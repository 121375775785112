"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var _ = require("lodash");
var angular_1 = require("@uirouter/angular");
var string_utils_1 = require("@app/common/utils/string.utils");
var SelectComponent = /** @class */ (function () {
    function SelectComponent(listLoadService, windowDimensionService, cdr, elementRef, restangular, stateService) {
        this.listLoadService = listLoadService;
        this.windowDimensionService = windowDimensionService;
        this.cdr = cdr;
        this.elementRef = elementRef;
        this.restangular = restangular;
        this.stateService = stateService;
        this.searchText = '';
        this.selectAll = false;
        this.enableSelectAll = false;
        this.changed = new core_1.EventEmitter(); // expression done on change
        this.searchTextChange = new core_1.EventEmitter(); // expression done on change of searchText value
        this.selectAllChange = new core_1.EventEmitter();
        this.initialized = false;
        this.visible = false;
        this.scrollTop = null;
        this._searchTimeout = null;
        this.onDocumentClick = this.onDocumentClick.bind(this);
        this.toggleSelectAll = this.toggleSelectAll.bind(this);
    }
    SelectComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.logBehaviour = this.logGa === '';
        this.multiselect = this.multiselect !== undefined ? true : false;
        // prevent blicking on first render after reposition
        if (!this.itemPipe) {
            this.itemPipe = new (/** @class */ (function () {
                function DefaultPipe() {
                }
                DefaultPipe.prototype.transform = function (item) {
                    return item.name === null ? '-' : item.name;
                };
                return DefaultPipe;
            }()))();
        }
        // if there is no direct need to set selected value to object directly, value is stored in current scope's data field named 'selected'.
        // it this case listener is an example for handling value change
        if (!this.data) {
            this.data = {};
        }
        if (!this.field) {
            this.field = 'selected';
        }
        if (this.multiselect && !this.data[this.field]) {
            this.data[this.field] = [];
        }
        this.displayInputOnly = ('displayInput' in this);
        // init static
        if (this.optionItems && !this.optionItems.path) {
            // static items + static filtering
            if (this.reloadOptionsRegister) {
                this.reloadOptionsRegister(function () {
                    if (!_this.initialized) {
                        return;
                    }
                    var optionItems = _this.reloadOptionsStatic();
                    return optionItems;
                });
            }
            var selectedItems_1 = this.data[this.field];
            if (selectedItems_1 && Array.isArray(selectedItems_1) && selectedItems_1.length > 0) {
                delete this.data[this.field];
                this.data[this.field] = this.optionItems.filter(function (v) {
                    return selectedItems_1.find(function (i) {
                        return i.id === v.id;
                    });
                });
            }
        }
        else {
            // dynamic items + dynamic filtering
            this.initialized = true;
            // list preloaded in controller
            if (this.optionItems && this.optionItems.path) {
                this.options = this.optionItems;
                if (this.optionItems.promise) {
                    this.optionItems.promise.then(function () {
                        _this.checkPosition(_this.visible);
                    });
                }
            }
            else {
                this.options = this.listLoadService.createList(this.resource, this.filter ? this.filter : {}, this.restangularService, this.attributes);
            }
            if (this.enableSelectAll) {
                var filter = this.filter ? __assign({}, this.filter, { limit: 1 }) : { limit: 1 };
                var totalList = this.listLoadService.createList(this.resource, filter, this.restangularService);
                this.listLoadService.fetchResult(totalList).then(function (data) {
                    _this.totalCount = data.itemCount;
                });
            }
            this.loadNext = function () {
                _this.options.filter.offset += _this.options.filter.limit;
                _this.fetchResults(true);
            };
            if (this.reloadOptionsRegister) {
                // Reloads dynamic items
                this.reloadOptionsRegister(function () {
                    if (_this.getItemsType() === 'dynamic') {
                        if (_this._searchTimeout) {
                            clearTimeout(_this._searchTimeout);
                        }
                        _this.options.list = null;
                        _this.listLoadService.cancelFetch(_this.options);
                        return _this.reloadOptionsDynamic();
                    }
                });
            }
            // if some objects are selected
            var selectedItems = this.data[this.field];
            if (selectedItems && Array.isArray(selectedItems) && selectedItems.length > 0) {
                var selectedItemsIDs = selectedItems.map(function (i) { return i.id; });
                var filter = {
                    entityIds: selectedItemsIDs,
                    limit: null,
                };
                var idsLoadList_1 = this.listLoadService.createList(this.resource, filter, this.restangularService, this.attributes);
                this.listLoadService.fetchResult(idsLoadList_1).then(function () {
                    _this.data[_this.field] = idsLoadList_1.list;
                });
            }
        }
    };
    SelectComponent.prototype.getTitle = function () {
        return (this.multiselect || !this.data[this.field] ? this.selectTitle || (this.multiselect && this.itemPipe && this.selectTitle === undefined ? 'Výběr' : null) : null) || this.formatItem(this.data[this.field]);
    };
    SelectComponent.prototype.scrollbarCallbackRegister = function (event) {
        this.scrollTop = event.scrollTop;
    };
    SelectComponent.prototype.onDocumentClick = function (event) {
        var _this = this;
        // check if click inside cmSelect
        var isClickInside = this.elementRef.nativeElement.contains(event.target);
        if (!isClickInside) {
            // close pane
            setTimeout(function () {
                _this.toggle(false);
                _this.cdr.markForCheck();
            });
        }
    };
    SelectComponent.prototype.getItemsType = function () {
        if (this.optionItems && !this.optionItems.path) {
            return 'static';
        }
        else {
            return 'dynamic';
        }
    };
    SelectComponent.prototype._onVisibilityChanged = function () {
        var _this = this;
        if (this.getItemsType() === 'static') {
            if (this.visible && !this.options) {
                this.reloadOptionsStatic();
                this.initialized = true;
            }
        }
        else if (this.getItemsType() === 'dynamic') {
            if (this.visible && this.options.list === null && !this.options.loading) {
                this.reloadOptionsDynamic();
            }
            else if (this.visible && this.globalFilterConfig) {
                // if globalFilterConfig is set, then reload items on opening only if any of global filter has changed
                var key = _.findKey(this.globalFilterConfig, function (filter, key) { return !_.isEqual(filter, _this.options.filter.filters[key]); });
                if (key) {
                    this.reloadOptionsDynamic();
                }
            }
        }
    };
    SelectComponent.prototype._onSearchTextChanged = function (searchText) {
        var _this = this;
        if (searchText === void 0) { searchText = ''; }
        if (this.searchText === searchText) {
            return;
        }
        this.searchText = searchText;
        if (this.getItemsType() === 'static') {
            this.reloadOptionsStatic();
        }
        else if (this.getItemsType() === 'dynamic') {
            if (this._searchTimeout) {
                clearTimeout(this._searchTimeout);
            }
            if (this.globalFilterConfig) {
                _.assign(this.options.filter.filters, 
                // clone all except current select
                _.mapValues(this.globalFilterConfig, function (filter) { return filter.values === _this.data[_this.field] ? _.assign(_.cloneDeep(filter), { values: filter.values }) : _.cloneDeep(filter); }));
            }
            if (this.searchText) {
                this.options.filter.filters.searchText = { values: [this.searchText] };
            }
            else {
                this.options.filter.filters.searchText = { values: [''] };
            }
            this._searchTimeout = setTimeout(this.reloadOptionsDynamic.bind(this), 250); // delay 250 ms
        }
        this.searchTextChange.emit(this.searchText);
    };
    SelectComponent.prototype.setVisibility = function (visible) {
        this.visible = visible;
        this._onVisibilityChanged();
    };
    SelectComponent.prototype.logEvent = function (value) {
        window.GoogleAnalytics('send', {
            hitType: 'event',
            eventCategory: 'cmSelect',
            eventAction: this.resource,
            eventLabel: this.stateService.current.name,
        });
    };
    SelectComponent.prototype.toggle = function (visible) {
        var _this = this;
        if (visible) {
            if (this.logBehaviour) {
                this.logEvent('open');
            }
            if (this.scrollTop) {
                this.scrollTop();
            }
            // must setTimeout and then set listener, otherwise click even buble down
            // to document and dispatch again
            setTimeout(function () { return document.addEventListener('click', _this.onDocumentClick); });
            // if search text, then include it in filter
            // clear searchtext from previous search
            if (this.searchText && this.options && this.options.filter && this.options.filter.filters) {
                this.options.filter.filters.searchText = { values: [this.searchText] };
            }
            else if (this.options && this.options.filter && this.options.filter.filters) {
                this.options.filter.filters.searchText = { values: [''] };
            }
        }
        else {
            document.removeEventListener('click', this.onDocumentClick);
            // delete searchText from criteria when dropdown is closed in order this would not affect other filters.
            if (this.options && this.options.filter && this.options.filter.filters) {
                this.options.filter.filters.searchText = { values: [''] };
            }
        }
        // hide options pane
        this.setVisibility(visible);
        this.checkPosition(visible);
        setTimeout(function () { return _this.checkPosition(visible); });
    };
    SelectComponent.prototype.checkPosition = function (visible) {
        if (!this.initialized) {
            return;
        }
        var element = this.elementRef.nativeElement;
        var optionsPane = element.querySelector('div.options-pane');
        optionsPane.style.marginLeft = 0;
        optionsPane.style.display = 'block';
        optionsPane.style.visibility = 'hidden';
        if (!visible) {
            optionsPane.style.visibility = 'visible';
            optionsPane.style.display = 'none';
            return;
        }
        else {
            var minPaneHeight = 150;
            var optionsPaneResult = optionsPane.querySelector('div.result');
            var selectValueElement = element.querySelector('div.cm-select-inputwrap');
            var searchTextElement = element.querySelector('.search-text');
            var dimensions = new this.windowDimensionService(this.elementRef.nativeElement, 5);
            var searchHeight = optionsPane.clientHeight - optionsPaneResult.offsetHeight;
            var newHeight = Math.min(300 + searchHeight, dimensions.windowHeight - (selectValueElement.getBoundingClientRect().top - dimensions.windowOffsetTop + selectValueElement.getBoundingClientRect().height));
            var flip = false;
            var shouldFlipVerticaly = optionsPane.getBoundingClientRect().width && optionsPane.getBoundingClientRect().left - dimensions.windowOffsetLeft + optionsPane.getBoundingClientRect().width > dimensions.windowWidth && optionsPane.getBoundingClientRect().width > selectValueElement.getBoundingClientRect().width;
            if (shouldFlipVerticaly) {
                optionsPane.style.marginLeft = -(optionsPane.getBoundingClientRect().width - selectValueElement.getBoundingClientRect().width) + 'px';
            }
            if (newHeight < optionsPane.clientHeight && newHeight < minPaneHeight) {
                newHeight = Math.min(300 + searchHeight, selectValueElement.getBoundingClientRect().top - dimensions.windowOffsetTop);
                if (newHeight > optionsPane.clientHeight || newHeight > minPaneHeight) {
                    flip = true;
                }
                else {
                    newHeight = minPaneHeight;
                }
            }
            else {
                newHeight = Math.max(newHeight, minPaneHeight);
            }
            optionsPaneResult.style.maxHeight = newHeight ? (newHeight - searchHeight) + 'px' : '';
            // IE11 overflow workaround
            var hostPerfectScrollbar = optionsPaneResult.querySelector('perfect-scrollbar');
            if (hostPerfectScrollbar) {
                hostPerfectScrollbar.style.maxHeight = optionsPaneResult.style.maxHeight;
            }
            optionsPane.style.marginTop = (flip ? -(optionsPane.getBoundingClientRect().height + selectValueElement.getBoundingClientRect().height) : 0) + 'px';
            optionsPane.style.visibility = 'visible';
            optionsPane.style.display = 'block';
            searchTextElement.focus();
        }
    };
    SelectComponent.prototype.fetchResults = function (additive) {
        var _this = this;
        if (additive === void 0) { additive = false; }
        this.checkPosition(this.visible);
        if (this.globalFilterConfig) {
            _.assign(this.options.filter.filters, _.mapValues(this.globalFilterConfig, function (filter) { return filter.values === _this.data[_this.field] ? _.assign(_.cloneDeep(filter), { values: filter.values }) : _.cloneDeep(filter); }));
        }
        if (this.searchText) {
            this.options.filter.filters.searchText = { values: [this.searchText] };
        }
        else {
            this.options.filter.filters.searchText = { values: [''] };
        }
        return this.listLoadService.fetchResult(this.options, additive).then(function () {
            setTimeout(function () {
                _this.checkPosition(_this.visible);
                // scrolltop is not avaliable on first open
                if (!additive && _this.visible && _this.scrollTop) {
                    _this.scrollTop();
                }
            });
            _this.cdr.markForCheck();
            return _this.options;
        });
    };
    SelectComponent.prototype.reloadOptionsStatic = function () {
        var _this = this;
        var optionItems;
        if (this.filter) {
            optionItems = _.filter(this.optionItems, this.filter);
        }
        else {
            optionItems = this.optionItems;
        }
        this.options = {};
        this.options.list = this.filterStaticItems(this.searchText, optionItems, this.itemPipe);
        this.options.itemCount = this.options.list.length;
        this.totalCount = this.options.list.length;
        setTimeout(function () {
            _this.checkPosition(_this.visible);
        });
        return optionItems;
    };
    SelectComponent.prototype.reloadOptionsDynamic = function () {
        this.options.filter.offset = 0;
        return this.fetchResults();
    };
    SelectComponent.prototype.formatItem = function (item) {
        if (item && this.itemPipe) {
            var title = this.itemPipe.transform(item);
            return title === null ? '-' : title;
        }
        else {
            return item;
        }
    };
    SelectComponent.prototype.compareItems = function (item1, item2) {
        if (this.compareFunction !== undefined) {
            return this.compareFunction(item1, item2);
        }
        else {
            return item1 === item2 || ('id' in item1 && item1.id === item2.id);
        }
    };
    SelectComponent.prototype.toggleSelection = function (item) {
        var _this = this;
        var oldValue;
        var checked = this.isChecked(item);
        if (this.multiselect) {
            if (!this.data[this.field]) {
                this.data[this.field] = [];
            }
            // is currently selected
            if (checked) {
                _.remove(this.data[this.field], function (selectedItem) { return _this.compareItems(selectedItem, item); });
            }
            // is newly selected
            else {
                this.data[this.field].push(item);
            }
            oldValue = item;
        }
        else {
            oldValue = this.data[this.field];
            if (checked) {
                return;
            }
            this.data[this.field] = item;
            this.toggle(false);
        }
        this.changed.emit({
            newValue: this.data[this.field],
            oldValue: oldValue,
            data: this.data
        });
    };
    SelectComponent.prototype.unselectAll = function () {
        var oldValue = this.data[this.field];
        this.data[this.field] = this.multiselect ? [] : null;
        this.changed.emit({
            newValue: this.data[this.field],
            oldValue: oldValue,
            data: this.data
        });
    };
    SelectComponent.prototype.isChecked = function (item) {
        var _this = this;
        return this.data && this.data[this.field]
            ? (this.multiselect
                ? _.some(this.data[this.field], function (selectedItem) { return _this.compareItems(selectedItem, item); })
                : this.compareItems(this.data[this.field], item))
            : false;
    };
    SelectComponent.prototype.resetSearchText = function () {
        var _this = this;
        this._onSearchTextChanged('');
        // avoid blinking on resetting
        setTimeout(function () {
            _this.elementRef.nativeElement.querySelector('.search-text').focus();
        });
    };
    SelectComponent.prototype.getSelectedItemsNames = function () {
        var _this = this;
        var selectedItems = this.data[this.field];
        if (!this.selectAll && selectedItems && selectedItems instanceof Array) {
            var selectedItemsNames = selectedItems.map(function (i) { return _this.formatItem(i); });
            return 'Zrušit výběr:\n' + selectedItemsNames.join('\n');
        }
        else {
            return 'Zrušit výběr';
        }
    };
    SelectComponent.prototype.filterStaticItems = function (searchText, optionItems, itemPipe) {
        if (searchText.length) {
            return optionItems.filter(function (value) {
                var transformSearchText = itemPipe ? itemPipe.transform(value) : value.name;
                return !searchText || string_utils_1.StringUtils.searchStringWithWildcard(searchText, transformSearchText);
            });
        }
        else {
            return optionItems;
        }
    };
    SelectComponent.prototype.toggleSelectAll = function () {
        this.selectAll = !this.selectAll;
        this.unselectAll();
        this.selectAllChange.emit(this.selectAll);
    };
    return SelectComponent;
}());
exports.SelectComponent = SelectComponent;
