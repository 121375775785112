"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var TooltipHostDirective = /** @class */ (function () {
    function TooltipHostDirective(viewContainerRef) {
        this.viewContainerRef = viewContainerRef;
    }
    return TooltipHostDirective;
}());
exports.TooltipHostDirective = TooltipHostDirective;
