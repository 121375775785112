"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var pageable_list_service_1 = require("@app/common/services/pageable.list.service");
var administrator_edit_component_1 = require("@app/ps/components/administrator-edit/administrator-edit.component");
var dialog_service_1 = require("@app/dialog/dialog.service");
var AdministratorListComponent = /** @class */ (function () {
    function AdministratorListComponent(helpIds, restangular, pageableListService, dialogService) {
        this.helpIds = helpIds;
        this.restangular = restangular;
        this.pageableListService = pageableListService;
        this.dialogService = dialogService;
        this.defaultFilter = {
            filters: {
                searchText: { values: [{ id: '' }] },
            }
        };
        this.onCreate = this.onCreate.bind(this);
    }
    AdministratorListComponent.prototype.ngOnInit = function () {
        this.initializeList();
    };
    AdministratorListComponent.prototype.initializeList = function () {
        this.pageableList = this.pageableListService.get('administrators', this.defaultFilter, 'administratorsFilter');
        this.list = this.pageableList.list;
        this.pageableList.load();
    };
    AdministratorListComponent.prototype.uiOnParamsChanged = function (changedParams, $transition$) {
        this.pageableList.uiOnParamsChanged(changedParams, $transition$);
    };
    AdministratorListComponent.prototype.onCreate = function () {
        var _this = this;
        var dialog = this.dialogService.open(administrator_edit_component_1.AdministratorEditComponent, { data: {} });
        var sub = dialog.afterClosed.subscribe(function (res) {
            if (res) {
                _this.pageableList.fetchResults();
            }
            sub.unsubscribe();
        });
    };
    return AdministratorListComponent;
}());
exports.AdministratorListComponent = AdministratorListComponent;
