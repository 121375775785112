"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var StringUtils = /** @class */ (function () {
    function StringUtils() {
    }
    StringUtils.removeSpaces = function (from) {
        return from.replace(/\s/g, '');
    };
    StringUtils.removeMultipleSpaces = function (from) {
        return from.replace(/\s\s+/g, ' ').trim();
    };
    StringUtils.removeSpecialChars = function (from) {
        return from.replace(/[^a-zA-Z0-9\-_]/g, '-');
    };
    StringUtils.valueToString = function (value) {
        switch (typeof value) {
            case 'string': return value;
            case 'object':
            case 'undefined': return '';
            case 'boolean':
            case 'number': return String(value);
            default: return '';
        }
    };
    StringUtils.capitalize = function (text) {
        return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
    };
    /**
     * Search string in other string.
     * Is possible to use ? and * wildcard chars for searching.
     *
     * @param {string} search
     * @param {string} where
     */
    StringUtils.searchStringWithWildcard = function (search, where) {
        if (search.length === 0) {
            return false;
        }
        var whereSanitized = StringUtils.removeMultipleSpaces(where);
        // create search RegExp - escape RegExp reserved chars except ? and *
        var searchRegExp = StringUtils.removeMultipleSpaces(search)
            .replace(/([.+^=!:${}()|\[\]\/\\])/g, '\\$1')
            .replace(/\?/g, '.')
            .replace(/\*/g, '.*?');
        var searchChunks = searchRegExp.split(' ');
        return searchChunks.every(function (chunk) {
            return new RegExp('([\\s]|^)' + chunk, 'i').test(whereSanitized);
        });
    };
    return StringUtils;
}());
exports.StringUtils = StringUtils;
