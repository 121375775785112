"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var _ = require("lodash");
var construction_object_name_pipe_1 = require("@app/common/pipes/construction-object-name.pipe");
var pageable_list_service_1 = require("@app/common/services/pageable.list.service");
var geometric_plan_request_type_enum_1 = require("@app/ps/enums/geometric-plan-request-type.enum");
var geometric_plan_request_status_enum_1 = require("@app/ps/enums/geometric-plan-request-status.enum");
var geometric_plan_request_service_1 = require("@app/ps/services/geometric-plan-request.service");
var parcel_number_pipe_1 = require("@app/common/pipes/parcel-number.pipe");
var export_list_service_1 = require("@app/ps/services/export-list.service");
var settings_service_1 = require("@app/ps/services/settings.service");
var GeometricPlanRequestListComponent = /** @class */ (function () {
    function GeometricPlanRequestListComponent(helpIds, constructionObjectNamePipe, parcelNumberPipe, requestService, pageableListService, exportListService, settingsService) {
        this.helpIds = helpIds;
        this.constructionObjectNamePipe = constructionObjectNamePipe;
        this.parcelNumberPipe = parcelNumberPipe;
        this.requestService = requestService;
        this.pageableListService = pageableListService;
        this.exportListService = exportListService;
        this.settingsService = settingsService;
        this.isArray = Array.isArray;
        this.typeOptions = geometric_plan_request_type_enum_1.geometricPlanRequestTypeOptions;
        this.statusOptions = geometric_plan_request_status_enum_1.geometricPlanRequestStatusOptions;
        this.exportEnable = true;
        this.settingsLoaded = false;
        this.onToggleDeleted = this.onToggleDeleted.bind(this);
        this.onExport = this.onExport.bind(this);
    }
    GeometricPlanRequestListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.settingsLoaded = this.settingsService.getSettingsLoader() === null;
        if (!this.settingsLoaded) {
            this.settingsService.getSettingsLoader().then(function () { return _this.settingsLoaded = true; });
        }
        this.initializeList();
    };
    GeometricPlanRequestListComponent.prototype.uiOnParamsChanged = function (changedParams, $transition$) {
        this.pageableList.uiOnParamsChanged(changedParams, $transition$);
    };
    GeometricPlanRequestListComponent.prototype.onToggleDeleted = function () {
        var deleted = this.list.filter.filters.deleted;
        deleted.values = (deleted.values.length ? [] : [false]);
    };
    GeometricPlanRequestListComponent.prototype.getTitles = function (geometricPlanRequest) {
        var titles = geometricPlanRequest.parcels
            .filter(function (p) { return p.title; })
            .map(function (p) { return p.title; });
        return _.uniqBy(titles, 'id');
    };
    GeometricPlanRequestListComponent.prototype.onExport = function () {
        var _this = this;
        this.exportEnable = false;
        var filter = __assign({}, this.list.filter);
        return this.exportListService
            .exportList('geometric-plan-requests', 'geometricPlanRequests', filter)
            .then(function () {
            new Promise(function (resolve) { return setTimeout(function () { return resolve(); }, 5000); }).then(function () {
                _this.exportEnable = true;
            });
        });
    };
    GeometricPlanRequestListComponent.prototype.hideColumn = function (columnId) {
        return this.settingsService.shouldHideColumn('geometricPlanRequests', columnId);
    };
    GeometricPlanRequestListComponent.prototype.getDefaultFilter = function () {
        return {
            filters: {
                loadCollections: ['areas', 'constructionObjects', 'parcels'],
                searchText: { values: [{ id: '' }] },
                areaId: { values: [] },
                parcelId: { values: [] },
                constructionObjectId: { values: [] },
                type: { values: [] },
                status: { values: [] },
                deleted: { values: [false] }
            }
        };
    };
    GeometricPlanRequestListComponent.prototype.initializeList = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.pageableList = this.pageableListService.get('geometric-plan-requests', this.getDefaultFilter(), 'geometricPlanRequestsFilter');
                this.list = this.pageableList.list;
                this.pageableList.load();
                return [2 /*return*/];
            });
        });
    };
    return GeometricPlanRequestListComponent;
}());
exports.GeometricPlanRequestListComponent = GeometricPlanRequestListComponent;
