"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var customer_service_1 = require("@app/settings/customers/services/customer.service");
var dialog_service_1 = require("@app/dialog/dialog.service");
var confirmation_component_1 = require("@app/dialog/confirmation/confirmation.component");
var class_name_enum_1 = require("@app/dialog/class-name.enum");
var ProjectListComponent = /** @class */ (function () {
    function ProjectListComponent(applications, customerService, dialogService) {
        this.applications = applications;
        this.customerService = customerService;
        this.dialogService = dialogService;
        this.projectRemoval = new core_1.EventEmitter();
        this.setApplicationName = this.setApplicationName.bind(this);
        this.setProjectName = this.setProjectName.bind(this);
    }
    ProjectListComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var args, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        args = {
                            filter: {},
                        };
                        _a = this;
                        return [4 /*yield*/, this.customerService.getProjects(this.customerId, args)];
                    case 1:
                        _a.availableProjects = _b.sent();
                        this.projects = this.projects
                            .map(this.setApplicationName)
                            .map(this.setProjectName);
                        return [2 /*return*/];
                }
            });
        });
    };
    ProjectListComponent.prototype.hasProjects = function () {
        return this.projects.length > 0;
    };
    ProjectListComponent.prototype.removeProject = function (projectId) {
        var _this = this;
        var dialog = this.dialogService.open(confirmation_component_1.ConfirmationComponent, {
            data: {
                msg: 'Vybraný projekt bude odebrán ze smlouvy.',
            },
            className: class_name_enum_1.ClassName.ADJUSTED_DIALOG,
        });
        var sub = dialog.afterClosed.subscribe(function (result) {
            if (result) {
                _this.projectRemoval.emit(projectId);
            }
            sub.unsubscribe();
        });
    };
    ProjectListComponent.prototype.setApplicationName = function (project) {
        project['applicationName'] = Object.values(this.applications)
            .find(function (app) { return app.name === project['application']; })['title'];
        return project;
    };
    ProjectListComponent.prototype.setProjectName = function (project) {
        var matchingProject = this.availableProjects.find(function (p) { return project.projectKey === p['projectKey']; });
        if (matchingProject) {
            project['projectName'] = matchingProject['projectName'];
        }
        return project;
    };
    return ProjectListComponent;
}());
exports.ProjectListComponent = ProjectListComponent;
