"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var auth_service_1 = require("../services/auth.service");
var word_service_1 = require("../services/word.service");
var i0 = require("@angular/core");
var i1 = require("../services/word.service");
var i2 = require("../services/auth.service");
var WordTranslatePipe = /** @class */ (function () {
    function WordTranslatePipe(EVENTS, wordService, authService) {
        var _this = this;
        this.EVENTS = EVENTS;
        this.wordService = wordService;
        this.authService = authService;
        this.translations = {};
        this.authService.registerCallback(EVENTS.authProjectUpdated, function () {
            _this.translations = {};
        });
    }
    WordTranslatePipe.prototype.transform = function (id, firstLetterUpper, toUpper) {
        if (firstLetterUpper === void 0) { firstLetterUpper = false; }
        if (toUpper === void 0) { toUpper = false; }
        if (!id /*|| !this.authService.getActualProject()*/) {
            return '';
        }
        if (!this.translations[id]) {
            if (this.wordService.hasVocabulary()) {
                this.translations[id] = this.wordService.getTranslation(id, firstLetterUpper, toUpper);
            }
            else {
                this.translations[id] = '';
                this.wordService.setVocabulary();
            }
        }
        return this.translations[id];
    };
    WordTranslatePipe.ngInjectableDef = i0.defineInjectable({ factory: function WordTranslatePipe_Factory() { return new WordTranslatePipe(i0.inject("EVENTS"), i0.inject(i1.WordService), i0.inject(i2.AuthService)); }, token: WordTranslatePipe, providedIn: "root" });
    return WordTranslatePipe;
}());
exports.WordTranslatePipe = WordTranslatePipe;
