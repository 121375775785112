"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("../../../../common/components/checkbox/checkbox.component.ngfactory");
var i2 = require("../../../../common/components/checkbox/checkbox.component");
var i3 = require("@angular/common");
var i4 = require("../../../../common/directives/loading.directive");
var i5 = require("./projects.component");
var i6 = require("@uirouter/angular");
var styles_ProjectsComponent = [];
var RenderType_ProjectsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ProjectsComponent, data: {} });
exports.RenderType_ProjectsComponent = RenderType_ProjectsComponent;
function View_ProjectsComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "checklist-item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "div", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onPermissionsChange(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "gmt-checkbox", [["class", "clickable"], ["gmtType", "multiselect"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.onPermissionsChange(_v.context.$implicit, true);
        $event.preventDefault();
        var pd_0 = ($event.stopPropagation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_CheckboxComponent_0, i1.RenderType_CheckboxComponent)), i0.ɵdid(3, 114688, null, 0, i2.CheckboxComponent, [], { gmtChecked: [0, "gmtChecked"], gmtType: [1, "gmtType"] }, null), (_l()(), i0.ɵted(4, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.checklistSelection.isChecked(_v.context.$implicit.key); var currVal_1 = "multiselect"; _ck(_v, 3, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.name; _ck(_v, 4, 0, currVal_2); }); }
function View_ProjectsComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "column-100 checklist"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ProjectsComponent_3)), i0.ɵdid(3, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.projects; _ck(_v, 3, 0, currVal_0); }, null); }
function View_ProjectsComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "empty-info bordered"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Nenalezena \u017E\u00E1dn\u00E1 data "]))], null, null); }
function View_ProjectsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ProjectsComponent_2)), i0.ɵdid(2, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ProjectsComponent_4)), i0.ɵdid(4, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.projects.length > 0); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.projects.length === 0); _ck(_v, 4, 0, currVal_1); }, null); }
function View_ProjectsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [], [[8, "className", 0]], null, null, null, null)), i0.ɵdid(1, 16384, null, 0, i4.LoadingDirective, [i0.ElementRef], { loading: [0, "loading"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ProjectsComponent_1)), i0.ɵdid(3, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.loading; _ck(_v, 1, 0, currVal_1); var currVal_2 = !_co.loading; _ck(_v, 3, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).elementClass; _ck(_v, 0, 0, currVal_0); }); }
exports.View_ProjectsComponent_0 = View_ProjectsComponent_0;
function View_ProjectsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "sydi-projects", [], null, null, null, View_ProjectsComponent_0, RenderType_ProjectsComponent)), i0.ɵdid(1, 114688, null, 0, i5.ProjectsComponent, ["HELP_IDS", "ModulesService", "ConfirmService", "ChecklistService", i6.StateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_ProjectsComponent_Host_0 = View_ProjectsComponent_Host_0;
var ProjectsComponentNgFactory = i0.ɵccf("sydi-projects", i5.ProjectsComponent, View_ProjectsComponent_Host_0, { module: "module", userId: "userId", callbacks: "callbacks", userProjects: "userProjects" }, {}, []);
exports.ProjectsComponentNgFactory = ProjectsComponentNgFactory;
