"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["[_nghost-%COMP%]   .tag[_ngcontent-%COMP%] {\n  display: inline-block; }\n  [_nghost-%COMP%]   .tag.tag--small[_ngcontent-%COMP%] {\n    cursor: auto;\n    display: inline-block; }\n    [_nghost-%COMP%]   .tag.tag--small[_ngcontent-%COMP%]   a[_ngcontent-%COMP%], [_nghost-%COMP%]   .tag.tag--small[_ngcontent-%COMP%]   a[_ngcontent-%COMP%]:hover, [_nghost-%COMP%]   .tag.tag--small[_ngcontent-%COMP%]   a[_ngcontent-%COMP%]:visited {\n      color: white !important; }"];
exports.styles = styles;
