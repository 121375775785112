"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var angular_1 = require("@uirouter/angular");
var property_status_component_1 = require("@app/dimap/property-status/property-status/property-status.component");
var auth_service_1 = require("@app/common/services/auth.service");
function protectState(authService, stateService) {
    if (!authService.hasPermission('property_status')) {
        return stateService.go('dimap.project.map', { projectKey: authService.getActualProject().key });
    }
}
exports.protectState = protectState;
exports.propertyStatusState = {
    name: 'dimap.project.propertyStatus',
    data: {
        title: 'Majetkoprávní přehled',
    },
    url: '/property-status?page&requestid',
    params: {
        page: {
            dynamic: true,
            type: 'int',
            inherit: false,
        },
        requestid: {
            type: 'int',
            inherit: false,
        }
    },
    views: {
        'content@dimap.project': {
            component: property_status_component_1.PropertyStatusComponent,
        }
    },
    resolve: {
        aux: [auth_service_1.AuthService, angular_1.StateService, protectState]
    }
};
var PropertyStatusModule = /** @class */ (function () {
    function PropertyStatusModule() {
    }
    return PropertyStatusModule;
}());
exports.PropertyStatusModule = PropertyStatusModule;
