"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var angular_1 = require("@uirouter/angular");
var UpdateRentalParcelPriceComponent = /** @class */ (function () {
    function UpdateRentalParcelPriceComponent(stateService, restangular) {
        this.stateService = stateService;
        this.restangular = restangular;
        this.update = this.update.bind(this);
    }
    UpdateRentalParcelPriceComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.loading = true;
        this.sending = false;
        this.occupationTypeName = null; // placeholder;
        this.titleId = this.stateService.params.titleId;
        this.priceType = [
            {
                value: 'Cenový výměr ministerstva financí',
                id: 'F',
            },
            {
                value: 'Znalecký posudek',
                id: 'E',
            },
            {
                value: 'Jiný způsob',
                id: 'O',
            },
        ];
        this.priceUnit = [
            {
                value: 'Roční cena za metr čtvereční',
                id: 'A',
            },
            {
                value: 'Jednorázová cena',
                id: 'T',
            },
        ];
        this.restangular
            .one('occupations', this.stateService.params.occupationId)
            .get({ loadCollections: ['rentalExpert'] })
            .then(function (res) {
            _this.data = res;
            _this.data.rentalPriceType = _this.data.rentalPriceType || 'F';
            _this.data.rentalPriceUnit = _this.data.rentalPriceUnit || 'A';
            _this.loading = false;
            _this.occupationTypeName = _this._occupationLongerThanOneYear() ? 'nad 1 rok' : 'do 1 roku';
            if (!_this._occupationLongerThanOneYear()) {
                _this.data.rentalLength = _this.data.rentalLength || 12;
            }
        });
    };
    UpdateRentalParcelPriceComponent.prototype.isValid = function () {
        return this.data.rentalPriceType && this.data.rentalLength && ((this.data.rentalPriceType === 'F' && this.data.priceAssessmentYear && this.data.priceAssessmentLandPrice) ||
            (this.data.rentalPriceType === 'E' && this.data.rentalExpertOpinionNumber && this.data.rentalExpertOpinionDate && this.data.rentalExpert && this.data.priceAssessmentLandPrice) ||
            (this.data.rentalPriceType === 'O' && this.data.rentalOtherPriceSource && ((this.data.rentalPriceUnit === 'T' && this.data.totalRentPrice) || (this.data.rentalPriceUnit === 'A' && this.data.priceAssessmentLandPrice))));
    };
    UpdateRentalParcelPriceComponent.prototype.update = function () {
        var _this = this;
        if (!this.loading) {
            this.sending = true;
            return this.data
                .put()
                .then(function (res) {
                _this.sending = false;
                _this.stateService.go('symap.project.titles.detail', { titleId: _this.stateService.params.titleId, tab: 'parcels' });
            });
        }
    };
    UpdateRentalParcelPriceComponent.prototype.onChangeExpert = function (expert) {
        this.data.rentalExpert = expert;
    };
    UpdateRentalParcelPriceComponent.prototype._occupationLongerThanOneYear = function () {
        return this.data.zabtyp === 6 || this.data.zabtyp === 10;
    };
    return UpdateRentalParcelPriceComponent;
}());
exports.UpdateRentalParcelPriceComponent = UpdateRentalParcelPriceComponent;
