"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var dialog_config_1 = require("@app/dialog/dialog-config");
var dialog_ref_1 = require("@app/dialog/dialog-ref");
var project_subject_model_1 = require("@app/ps/models/project-subject.model");
var ProjectSubjectSimpleOfoComponent = /** @class */ (function () {
    function ProjectSubjectSimpleOfoComponent(dialogConfig, dialogRef) {
        this.dialogConfig = dialogConfig;
        this.dialogRef = dialogRef;
        this.onCancel = this.onCancel.bind(this);
        this.onSave = this.onSave.bind(this);
    }
    ProjectSubjectSimpleOfoComponent.prototype.ngOnInit = function () {
        var projectSubject = this.dialogConfig.data.projectSubject;
        var discriminator = this.dialogConfig.data.discriminator;
        this.projectSubject = projectSubject ? projectSubject : project_subject_model_1.createSubject(discriminator);
    };
    ProjectSubjectSimpleOfoComponent.prototype.onCancel = function () {
        this.dialogRef.close();
    };
    ProjectSubjectSimpleOfoComponent.prototype.onSave = function () {
        this.dialogRef.close(this.projectSubject);
    };
    return ProjectSubjectSimpleOfoComponent;
}());
exports.ProjectSubjectSimpleOfoComponent = ProjectSubjectSimpleOfoComponent;
