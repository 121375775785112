"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var case_service_1 = require("@app/ps/services/case.service");
var angular_1 = require("@uirouter/angular");
var auth_service_1 = require("@app/common/services/auth.service");
var _ = require("lodash");
var GenerateDocumentsComponent = /** @class */ (function () {
    function GenerateDocumentsComponent(caseService, stateService, authService, restangular, helpIds, APP_BRAND) {
        this.caseService = caseService;
        this.stateService = stateService;
        this.authService = authService;
        this.restangular = restangular;
        this.helpIds = helpIds;
        this.APP_BRAND = APP_BRAND;
        this.type = 'normal';
        this.normalTemplateGetter = this.normalTemplateGetter.bind(this);
        this.financialTemplateGetter = this.financialTemplateGetter.bind(this);
        this.generateSetter = this.generateSetter.bind(this);
        this.submit = this.submit.bind(this);
    }
    GenerateDocumentsComponent.prototype.ngOnInit = function () {
    };
    GenerateDocumentsComponent.prototype.generateSetter = function (generateDocuments) {
        this.generate = generateDocuments.generate;
    };
    GenerateDocumentsComponent.prototype.normalTemplateGetter = function () {
        var _this = this;
        var templateTypes = [];
        var loadData = function () {
            return Promise.all([
                _this.restangular.one('cases', _this.caseId).get({ loadCollections: ['caseSubjects', 'caseOwnerships'] }),
                _this.restangular.one('cases', _this.caseId).all('documents').getList()
            ]);
        };
        return loadData().then(function (data) {
            var caseData = data[0];
            var templateExists = data[1].includes(caseData.obligation.type + 'Contract');
            var coveringLetterTemplateExists = data[1].includes(caseData.obligation.type + 'CoveringLetter');
            var otherDocumentTemplateExists = data[1].includes('OtherDocument');
            if (templateExists) {
                templateTypes.push({
                    key: caseData.obligation.type + 'Contract',
                    name: 'Generování smlouvy',
                    templates: []
                });
            }
            if (coveringLetterTemplateExists) {
                var coveringLetterTemplate = {
                    key: caseData.obligation.type + 'CoveringLetter',
                    name: 'Generování průvodního dopisu',
                    templates: [],
                    recipients: []
                };
                var caseOwnerships = _this.caseService.getDataCaseOwnerships(caseData.caseOwnerships, caseData.caseSubjects);
                coveringLetterTemplate.recipients = _this.caseService.getRecipients(caseOwnerships, true);
                templateTypes.push(coveringLetterTemplate);
            }
            if (otherDocumentTemplateExists) {
                var otherDocumentTemplate = {
                    key: 'OtherDocument',
                    name: 'Generování ostatních dokumentů',
                    templates: []
                };
                templateTypes.push(otherDocumentTemplate);
            }
            var keys = templateTypes.map(function (templateTypes) { return templateTypes.key; });
            return _this.restangular.all('templates').customPOST({ filter: { filters: { validity: ['valid'], key: keys } } }).then(function (data) {
                templateTypes.forEach(function (templateType) {
                    templateType.templates = data.filter(function (i) { return i.key === templateType.key; });
                    templateType.templates.forEach(function (template) {
                        template.commonName = template.attachment.baseName;
                        if (_this.APP_BRAND.NAME === 'RSD' && _this.authService.getActualProject().useTemplatesService) {
                            template.attachment.baseName = caseData.mpp.number + (template.name ? '_' + template.name : '') + (template.documentNameSpecification ? '_' + template.documentNameSpecification : '');
                        }
                        else if (templateType.key === caseData.obligation.type + 'Contract') {
                            template.attachment.baseName = caseData.mpp.number;
                        }
                        if (templateType.recipients) {
                            template.recipients = templateType.recipients.map(function (recipient) {
                                var r = __assign({}, recipient);
                                if (_this.APP_BRAND.NAME === 'RSD' && _this.authService.getActualProject().useTemplatesService) {
                                    r.commonTemplateName = caseData.mpp.number + (template.name ? "_" + template.name : '') + (template.documentNameSpecification ? '_' + template.documentNameSpecification : '') + ("_" + recipient.name);
                                    r.templateName = r.commonTemplateName;
                                }
                                else {
                                    r.commonTemplateName = template.attachment.baseName + "_" + recipient.name;
                                    r.templateName = template.attachment.baseName + "_" + recipient.name;
                                }
                                return r;
                            });
                        }
                    });
                });
                return templateTypes;
            });
        });
    };
    GenerateDocumentsComponent.prototype.financialTemplateGetter = function () {
        var _this = this;
        var templateTypes = [{
                key: 'FinancialControl',
                queryParam: 'templateId',
                required: true,
                templates: []
            }];
        var keys = templateTypes.map(function (templateTypes) { return templateTypes.key; });
        return Promise.all([
            this.restangular.one('cases', this.caseId).get(),
            this.restangular.all('templates').customPOST({ filter: { filters: { validity: ['valid'], key: keys } } })
        ])
            .then(function (data) {
            templateTypes.forEach(function (templateType) {
                templateType.templates = _.filter(data[1], { key: templateType.key });
                templateType.templates.forEach(function (template) {
                    if (_this.APP_BRAND.NAME === 'RSD' && _this.authService.getActualProject().useTemplatesService) {
                        template.commonName = template.attachment.baseName;
                        template.attachment.baseName = data[0].mpp.number + (template.name ? '_' + template.name : '') + (template.documentNameSpecification ? '_' + template.documentNameSpecification : '');
                    }
                    else {
                        template.commonName = template.attachment.baseName;
                    }
                });
            });
            return templateTypes;
        });
    };
    GenerateDocumentsComponent.prototype.submit = function () {
        var _this = this;
        return this.generate().then(function (attachment) {
            _this.stateService.go('^');
        });
    };
    return GenerateDocumentsComponent;
}());
exports.GenerateDocumentsComponent = GenerateDocumentsComponent;
