"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var common_1 = require("@angular/common");
var angular_1 = require("@uirouter/angular");
var _ = require("lodash");
var customerContract_1 = require("@app/models/customerContract");
var contract_service_1 = require("@app/settings/customers/services/contract.service");
var dialog_config_1 = require("@app/dialog/dialog-config");
var dialog_ref_1 = require("@app/dialog/dialog-ref");
var ContractFormComponent = /** @class */ (function () {
    function ContractFormComponent(contractService, config, dialog, stateService) {
        this.contractService = contractService;
        this.config = config;
        this.dialog = dialog;
        this.stateService = stateService;
        this.fileUploadMessage = 'Vložte smlouvu ve formátu PDF.';
        if (config.data && config.data.contract) {
            this.contract = _.clone(config.data.contract);
        }
        else {
            this.contract = new customerContract_1.CustomerContract();
        }
        this.save = this.save.bind(this);
        this.isComplete = this.isComplete.bind(this);
    }
    ContractFormComponent.prototype.ngOnInit = function () { };
    /**
     * Save new contract.
     *
     * @returns {Promise}
     */
    ContractFormComponent.prototype.save = function () {
        return __awaiter(this, void 0, void 0, function () {
            var data;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        data = new FormData();
                        data.append('subject', this.contract.subject);
                        data.append('signDate', common_1.formatDate(this.contract.signDate, 'yyyy-MM-dd', 'cs'));
                        data.append('contractNumber', this.contract.contractNumber);
                        if (this.contract.description) {
                            data.append('description', this.contract.description);
                        }
                        if (this.contract.file) {
                            data.append('file', this.contract.file['fileEntry']);
                        }
                        if (!this.contract.idText) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.contractService
                                .update(this.contract.idText, data)
                                .then(function (res) {
                                _this.dialog.close();
                                _this.stateService.go('^', { id: res.idText });
                            })];
                    case 1: return [2 /*return*/, _a.sent()];
                    case 2: return [4 /*yield*/, this.contractService
                            .create(this.config.data.customerId, data)
                            .then(function () {
                            _this.dialog.close(true);
                        })];
                    case 3: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Check the contract is complete.
     *
     * @returns {boolean}
     */
    ContractFormComponent.prototype.isComplete = function () {
        var values = [
            this.contract.contractNumber,
            this.contract.signDate,
            this.contract.subject,
        ];
        // check the file is present on new contract
        if (!this.contract.idText) {
            values.push(this.contract.file);
        }
        return values.some(function (x) { return (x === null || x === '' || typeof x === 'undefined'); });
    };
    /**
     * Check the uploaded file matches validity criteria.
     *
     * @returns {boolean}
     */
    ContractFormComponent.prototype.isFileUploadValid = function (file) {
        return ContractFormComponent.FILE_EXTENSION_WHITELIST.includes(file.extension.toLowerCase());
    };
    /**
     * Reset contract file when new file is uploaded.
     *
     * @returns {void}
     */
    ContractFormComponent.prototype.onFileAdded = function (files) {
        this.contract.file = files.filter(function (f) { return f.valid; })[0];
    };
    ContractFormComponent.FILE_EXTENSION_WHITELIST = ['pdf'];
    return ContractFormComponent;
}());
exports.ContractFormComponent = ContractFormComponent;
