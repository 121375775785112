"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var i0 = require("@angular/core");
var KnGpNamePipe = /** @class */ (function () {
    function KnGpNamePipe() {
    }
    KnGpNamePipe.prototype.transform = function (knGp) {
        return knGp.cis + " [id: " + knGp.id + "]";
    };
    KnGpNamePipe.ngInjectableDef = i0.defineInjectable({ factory: function KnGpNamePipe_Factory() { return new KnGpNamePipe(); }, token: KnGpNamePipe, providedIn: "root" });
    return KnGpNamePipe;
}());
exports.KnGpNamePipe = KnGpNamePipe;
