"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var opinion_model_1 = require("@app/ps/models/opinion.model");
var auth_service_1 = require("@app/common/services/auth.service");
var opinion_service_1 = require("@app/ps/services/opinion.service");
var angular_1 = require("@uirouter/angular");
var checklist_service_1 = require("@app/common/services/checklist.service");
var ownership_name_pipe_1 = require("@app/common/pipes/ownership-name.pipe");
var numeric_pipe_1 = require("@app/common/pipes/numeric.pipe");
var _ = require("lodash");
var pricing_expert_opinion_parcels_component_1 = require("@app/ps/titles/components/pricing-expert-opinion-parcels/pricing-expert-opinion-parcels.component");
var PricingOtherSourceComponent = /** @class */ (function () {
    function PricingOtherSourceComponent(restangular, helpIds, confirmService, ownershipNamePipe, checklistService, authService, opinionService, transitionService, stateService, numericPipe) {
        this.restangular = restangular;
        this.helpIds = helpIds;
        this.confirmService = confirmService;
        this.ownershipNamePipe = ownershipNamePipe;
        this.checklistService = checklistService;
        this.authService = authService;
        this.opinionService = opinionService;
        this.transitionService = transitionService;
        this.stateService = stateService;
        this.numericPipe = numericPipe;
        this.loading = true;
        this.readonly = !this.authService.hasPermission('assignable');
        this.parcelPrices = [];
        this.checkedAll = false;
        this.totalPrice = 0;
        this.switchTypes = [
            {
                value: 'Pro celé LV',
                id: 'T',
            },
            {
                value: 'Pro vlastnický podíl',
                id: 'O',
            },
        ];
        this.dataOwnerships = [];
        this.dataParcelPrices = [];
        this.onDelete = this.onDelete.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onClose = this.onClose.bind(this);
    }
    PricingOtherSourceComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.loadTitle()];
                    case 1:
                        _a.sent();
                        if (!this.opinionId) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.loadOpinion()];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.loadOpinionPrices()];
                    case 3:
                        _a.sent();
                        return [3 /*break*/, 6];
                    case 4:
                        this.opinion = this.occupationType === 'P'
                            ? opinion_model_1.OpinionModel.createDefaultOtherSourcePermanent()
                            : opinion_model_1.OpinionModel.createDefaultOtherSourceTemporary();
                        this.opinion.title = { id: this.titleId };
                        this.opinion.parcelPrices = [];
                        return [4 /*yield*/, this.loadParcelPrices()];
                    case 5:
                        _a.sent();
                        _a.label = 6;
                    case 6:
                        this.canDelete = !!(this.authService.hasPermission('assignable') && this.opinionId);
                        this.type = this.opinion.otherOwnership ? 'O' : 'T';
                        this.checklistParcelPrices = this.checklistService.get(this.opinion.parcelPrices);
                        this.deregisterLeaveListener = this.transitionService.onBefore({}, this.beforeExit.bind(this));
                        this.loading = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    PricingOtherSourceComponent.prototype.onDelete = function () {
        var _this = this;
        return new this.confirmService({
            title: 'Opravdu chcete znalecký posudek smazat?',
            message: 'Všechna zadaná data budou ztracena.'
        }).then(function (data) {
            if (data === true) {
                _this.deregisterLeaveListener();
                return _this.opinionService.deleteOne(_this.opinion.id).then(function () { return true; });
            }
            else {
                return false;
            }
        }).then(function (deleted) {
            if (deleted) {
                _this.onClose();
            }
        });
    };
    PricingOtherSourceComponent.prototype.onClose = function () {
        this.stateService.go('symap.project.titles.detail.pricing.overview', { titleId: this.titleId });
    };
    PricingOtherSourceComponent.prototype.onSave = function () {
        var _this = this;
        this.deregisterLeaveListener();
        if (this.opinion.id) {
            return this.opinionService
                .updateOne(this.opinion.id, this.opinion)
                .then(function () { return _this.onClose(); });
        }
        return this.opinionService
            .createOne(this.opinion)
            .then(function () { return _this.onClose(); });
    };
    PricingOtherSourceComponent.prototype.isFormValid = function () {
        var parcelsSelected = this.opinion.parcelPrices.length;
        var priceTypeValid = (this.opinion.otherOwnership && this.type === 'O') || this.type === 'T';
        var parcelsValid = this.opinion.parcelPrices.every(this.isParcelPriceValid, this);
        return !!(parcelsSelected && priceTypeValid && parcelsValid && this.opinion.otherSource);
    };
    PricingOtherSourceComponent.prototype.computeTotalPrice = function () {
        var _this = this;
        this.totalPrice = this.opinion.parcelPrices.reduce(function (acc, currVal) { return _this.computeParcelPrice(currVal) + acc; }, 0);
    };
    PricingOtherSourceComponent.prototype.computeParcelPrice = function (parcelPrice) {
        var landPrice = parcelPrice.otherLandPrice && this.occupationType === 'P' ? this.numericPipe.transform(parcelPrice.otherLandPrice) : 0;
        var vegetationPrice = parcelPrice.otherVegetationPrice ? this.numericPipe.transform(parcelPrice.otherVegetationPrice) : 0;
        var compensationPrice = parcelPrice.otherCompensationPrice ? this.numericPipe.transform(parcelPrice.otherCompensationPrice) : 0;
        var buildingPrice = parcelPrice.otherBuildingsPrice && this.occupationType === 'P' ? this.numericPipe.transform(parcelPrice.otherBuildingsPrice) : 0;
        return landPrice + vegetationPrice + compensationPrice + buildingPrice;
    };
    PricingOtherSourceComponent.prototype.isDisabled = function (parcelPrice) {
        var _this = this;
        var isDisabled;
        var parcelPriceWithoutPermanentOpinion = function (item) { return item.opinion.priceType === 'O' && item.parcel && parcelPrice.parcel.id === item.parcel.id; };
        var ownershipMatches = function (item) { return item.opinion.otherOwnership && item.opinion.otherOwnership.id === _this.opinion.otherOwnership.id; };
        var soloOwner = this.opinion.otherOwnership && this.opinion.otherOwnership.podilCitatel === this.opinion.otherOwnership.podilJmenovatel;
        if (this.opinion.otherOwnership) {
            isDisabled = this.dataParcelPrices.some(function (item) { return parcelPriceWithoutPermanentOpinion(item) && (ownershipMatches(item) || soloOwner); });
        }
        else {
            isDisabled = this.dataParcelPrices.some(function (item) { return parcelPriceWithoutPermanentOpinion(item) && !item.opinion.otherOwnership; });
        }
        return isDisabled;
    };
    PricingOtherSourceComponent.prototype.checkAll = function () {
        var _this = this;
        this.checkedAll = !this.checkedAll;
        this.opinion.parcelPrices.splice(0);
        if (this.checkedAll) {
            this.parcelPrices
                .filter(function (item) { return !_this.isDisabled(item); }, this)
                .forEach(function (item) { return _this.opinion.parcelPrices.push(item); }, this);
        }
        this.updateOwnerships();
    };
    PricingOtherSourceComponent.prototype.toggleSelection = function (parcelPrice) {
        if (!this.isDisabled(parcelPrice)) {
            this.checklistParcelPrices.toggleSelection(parcelPrice);
        }
        this.updateOwnerships();
        this.computeTotalPrice();
    };
    PricingOtherSourceComponent.prototype.togglePriceType = function (type) {
        if (type === 'T') {
            this.opinion.otherOwnership = null;
            this.opinion.parcelPrices
                .filter(this.isDisabled, this)
                .forEach(this.checklistParcelPrices.toggleSelection);
        }
    };
    PricingOtherSourceComponent.prototype.getCurrentPriceType = function () {
        var _this = this;
        return this.switchTypes.filter(function (obj) { return obj.id === _this.type; })[0].value;
    };
    PricingOtherSourceComponent.prototype.loadTitle = function () {
        var _this = this;
        return this.restangular
            .one('titles', this.titleId)
            .get({ loadCollections: ['ownerships', 'ownerships.subjects'] })
            .then(function (res) {
            _this.title = res.plain();
            _this.dataOwnerships = _this.title.ownerships;
        });
    };
    PricingOtherSourceComponent.prototype.loadOpinion = function () {
        var _this = this;
        return this.opinionService
            .getOne(this.opinionId)
            .then(function (res) { return _this.opinion = res; });
    };
    PricingOtherSourceComponent.prototype.loadOpinionPrices = function () {
        var _this = this;
        return Promise.all(this.prepareApiCalls()).then(function (res) {
            // loadOpinionParcelPrices
            res[0].forEach(function (parcelPrice) {
                _this.opinion.parcelPrices.push(parcelPrice);
                _this.parcelPrices.push(parcelPrice);
                _this.readonly = _this.readonly || parcelPrice.caseOwnershipExists;
                _this.canDelete = _this.canDelete && !parcelPrice.caseOwnershipExistsAndNotCancelled;
            });
            // loadTitleParcelPrices
            _this.dataParcelPrices = [];
            res[1].filter(function (parcelPrice) { return parcelPrice.opinion.id !== _this.opinionId; })
                .forEach(function (parcelPrice) {
                _this.dataParcelPrices.push(parcelPrice);
            });
            // loadFilteredParcels
            if (!_this.readonly) {
                res[2].filter(function (item) { return _this.parcelPrices
                    .map(function (pp) { return pp.idpar; })
                    .indexOf(item.idpar) === -1; })
                    .forEach(function (parcel) { return _this.parcelPrices.push({
                    parcel: parcel,
                    otherLandPrice: 0,
                    otherVegetationPrice: 0,
                    otherBuildingsPrice: 0,
                }); });
                res[3].filter(function (item) { return _this.parcelPrices
                    .filter(function (pp) { return pp.building; })
                    .map(function (pp) { return pp.building.budId; })
                    .indexOf(item.budId) === -1; })
                    .forEach(function (building) {
                    var doesBuildingMatch = _this.parcelPrices.find(function (parcelPrice) { return pricing_expert_opinion_parcels_component_1.PricingExpertOpinionParcelsComponent.buildingsMatch(building, parcelPrice); });
                    var item = {
                        building: building,
                        otherLandPrice: 0,
                        otherVegetationPrice: 0,
                        otherBuildingsPrice: 0,
                        otherCompensationPrice: 0,
                    };
                    if (!doesBuildingMatch && _this.occupationType === 'P') {
                        _this.parcelPrices.push(item);
                    }
                });
            }
            _this.updateOwnerships();
            _this.computeTotalPrice();
        });
    };
    PricingOtherSourceComponent.prototype.loadParcelPrices = function () {
        var _this = this;
        return Promise.all(this.prepareApiCalls()).then(function (res) {
            _this.dataParcelPrices = res[0];
            res[1].forEach(function (parcel) {
                _this.parcelPrices.push({
                    parcel: parcel,
                    otherLandPrice: 0,
                    otherVegetationPrice: 0,
                    otherBuildingsPrice: 0,
                    otherCompensationPrice: 0,
                });
            });
            res[2].forEach(function (building) {
                var doesBuildingMatch = _this.parcelPrices.find(function (parcelPrice) { return pricing_expert_opinion_parcels_component_1.PricingExpertOpinionParcelsComponent.buildingsMatch(building, parcelPrice); });
                var item = {
                    building: building,
                    otherLandPrice: 0,
                    otherVegetationPrice: 0,
                    otherBuildingsPrice: 0,
                    otherCompensationPrice: 0,
                };
                if (!doesBuildingMatch && _this.occupationType === 'P') {
                    _this.parcelPrices.push(item);
                }
            });
            _this.checkAll();
        });
    };
    PricingOtherSourceComponent.prototype.prepareApiCalls = function () {
        var parcelsFilter = {
            filters: {
                titleId: [this.titleId],
                // validBuyoutOpinion: [ false ],
                validity: ['valid'],
            },
        };
        parcelsFilter.filters[(this.opinion.occupationType === 'P' ? 'permanent' : 'temporary') + 'OccupationExists'] = [true];
        var buildingsFilter = {
            filters: {
                occupationOrEasementExists: [true],
                titleId: [this.titleId],
                // validBuyoutOpinion: [ false ],
                validity: ['valid'],
                differentTitle: [true],
                loadCollections: [
                    'parcelsWithEasementOrOccupation',
                    'buildingProtections',
                    'parcels.parcelProtections',
                ],
            },
        };
        var calls = [];
        if (this.opinionId) {
            calls.push(this.opinionService.getParcelPrices(this.opinionId));
        }
        calls.push(this.opinionService.getTitleParcelPrices(this.titleId));
        calls.push(this.opinionService.getParcels(parcelsFilter));
        calls.push(this.opinionService.getBuildings(buildingsFilter));
        return calls;
    };
    PricingOtherSourceComponent.prototype.isParcelPriceValid = function (parcelPrice) {
        var isValidLandPrice = isFinite(parcelPrice.otherLandPrice) && parcelPrice.otherLandPrice !== '' && this.occupationType === 'P';
        var isValidVegetationPrice = isFinite(parcelPrice.otherVegetationPrice) && parcelPrice.otherVegetationPrice !== '' && this.occupationType === 'P';
        var isValidCompensationPrice = isFinite(parcelPrice.otherCompensationPrice) && parcelPrice.otherCompensationPrice !== '' && this.occupationType === 'T';
        var isValidBuildingPrice = isFinite(parcelPrice.otherBuildingsPrice) && parcelPrice.otherBuildingsPrice !== '' && this.occupationType === 'P';
        return (isValidLandPrice && isValidVegetationPrice && isValidBuildingPrice) || isValidCompensationPrice;
    };
    PricingOtherSourceComponent.prototype.updateOwnerships = function () {
        var _this = this;
        this.selectOwnerships = this.dataOwnerships.filter(function (ownership) {
            var result = true;
            _.forEach(_this.opinion.parcelPrices, function (newParcelPrice) {
                _.forEach(_this.dataParcelPrices, function (existingParcelPrice) {
                    if (existingParcelPrice.parcel && newParcelPrice.parcel.id === existingParcelPrice.parcel.id && existingParcelPrice.opinion.priceType === 'O' &&
                        ((existingParcelPrice.opinion.otherOwnership && ownership.id === existingParcelPrice.opinion.otherOwnership.id) || ownership.podilCitatel === ownership.podilJmenovatel)) {
                        result = false;
                        return false;
                    }
                });
                return result;
            });
            return result;
        });
    };
    PricingOtherSourceComponent.prototype.beforeExit = function () {
        var _this = this;
        if (this.readonly) {
            this.deregisterLeaveListener();
            return Promise.resolve(true);
        }
        return new this.confirmService({
            title: 'Opravdu chcete odejít?',
            message: 'Všechny úpravy budou ztraceny.'
        }).then(function (data) {
            if (data === true) {
                _this.deregisterLeaveListener();
                return Promise.resolve(true);
            }
            return Promise.resolve(false);
        });
    };
    return PricingOtherSourceComponent;
}());
exports.PricingOtherSourceComponent = PricingOtherSourceComponent;
