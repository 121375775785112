"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var subject_name_pipe_1 = require("@app/common/pipes/subject-name.pipe");
var checklist_service_1 = require("@app/common/services/checklist.service");
var parcel_service_1 = require("@app/ps/services/parcel.service");
var auth_service_1 = require("@app/common/services/auth.service");
var _pull = require("lodash/pull");
var _pullAll = require("lodash/pullAll");
var _remove = require("lodash/remove");
var title_detail_create_case_select_titles_service_1 = require("@app/ps/services/title-detail-create-case-select-titles.service");
var title_detail_create_case_selection_service_1 = require("@app/ps/services/title-detail-create-case-selection.service");
var CreateCaseSelectTitlesComponent = /** @class */ (function () {
    function CreateCaseSelectTitlesComponent(authService, checklistService, subjectNamePipe, parcelService, confirmService, titleDetailCreateCaseSelectTitlesService, selection) {
        this.authService = authService;
        this.checklistService = checklistService;
        this.subjectNamePipe = subjectNamePipe;
        this.parcelService = parcelService;
        this.confirmService = confirmService;
        this.titleDetailCreateCaseSelectTitlesService = titleDetailCreateCaseSelectTitlesService;
        this.selection = selection;
        this.forwardStep = true;
        this.submitCallback = new core_1.EventEmitter();
        this.loading = false;
        this.submit = this.submit.bind(this);
        this.closeSelectOwnershipTitleCancel = this.closeSelectOwnershipTitleCancel.bind(this);
        this.closeSelectOwnershipTitle = this.closeSelectOwnershipTitle.bind(this);
    }
    CreateCaseSelectTitlesComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.selection.scope = this;
        this.checklistOwnership = this.checklistService.get();
        this.checklistGroup = this.checklistService.get();
        this.checklistEasement = this.checklistService.get();
        this.checklistBuilding = this.checklistService.get();
        this.checklistConstructionObject = this.checklistService.get(this.data.constructionObjects.map(function (co) { return _this.selection.constructionObjectToCase(co, true); }));
        for (var i = 0; i < this.data.easements.length; i++) {
            var easement = this.data.easements[i];
            if (easement.parcel.title.id !== this.data.titles[0].id) {
                _pull(this.data.easements, easement);
                i--;
                this.checklistEasement.checkedItems.push(easement);
            }
            else {
                easement.constructionObjects.forEach(function (co) {
                    var found = _this.checklistConstructionObject.checkedItems.find(function (item) { return item.id === co.id; });
                    if (found) {
                        found.selected = found.selectedOriginal = false;
                    }
                });
            }
        }
        for (var i = 0; i < this.data.buildings.length; i++) {
            var building = this.data.buildings[i];
            if (building.title.id !== this.data.titles[0].id) {
                _pull(this.data.buildings, building);
                i--;
                this.checklistBuilding.checkedItems.push(building);
            }
        }
        Object.entries(this.parcelService.groupOccupationsByParcelSingle(this.data.occupations)).forEach(function (_a) {
            var _b = __read(_a, 2), key = _b[0], group = _b[1];
            if (group.parcel.title.id !== _this.data.titles[0].id) {
                group.occupations.forEach(function (occupation) {
                    _pull(_this.data.occupations, occupation);
                });
                _this.checklistGroup.checkedItems.push(group);
            }
            else {
                // if construction object contains any easements or occupations of principal title do not allow deselection
                group.occupations.forEach(function (occupation) {
                    occupation.constructionObjects.forEach(function (co) {
                        var found = _this.checklistConstructionObject.checkedItems.find(function (item) { return item.id === co.id; });
                        if (found) {
                            found.selected = found.selectedOriginal = false;
                        }
                    });
                });
            }
        });
        _pullAll(this.data.constructionObjects, this.checklistConstructionObject.checkedItems.filter(function (item) { return item.selected === true; }).map(function (item) { return item.constructionObject; }));
        this.checklistOwnershipTitle = this.checklistService.get();
        this.loadTitles();
    };
    CreateCaseSelectTitlesComponent.prototype.submit = function () {
        var _this = this;
        this.checklistGroup.checkedItems.forEach(function (group) {
            group.occupations.forEach(function (occupation) {
                _this.data.occupations.push(occupation);
            });
        });
        this.checklistBuilding.checkedItems.forEach(function (building) {
            _this.data.buildings.push(building);
        });
        this.checklistEasement.checkedItems.forEach(function (easement) {
            delete easement.cases;
            _this.data.easements.push(easement);
        });
        this.checklistConstructionObject.checkedItems.forEach(function (caseConstructionObject) {
            if (!_this.data.constructionObjects.some(function (constructionObject) { return constructionObject.id === caseConstructionObject.id; })) {
                _this.data.constructionObjects.push(caseConstructionObject.constructionObject);
            }
        });
        this.checklistOwnershipTitle.checkedItems.forEach(function (ownershipTitle) {
            _this.data.caseOwnerships.push({ ownership: ownershipTitle.ownership });
            _this.data.titles.push(ownershipTitle.ownership.title);
        });
        return this.submitCallback.emit();
    };
    CreateCaseSelectTitlesComponent.prototype.getSubjectName = function () {
        return this.dataTitles[0].ownership.subjects.map(this.subjectNamePipe.transform).join(', ');
    };
    CreateCaseSelectTitlesComponent.prototype.loadTitles = function () {
        return __awaiter(this, void 0, void 0, function () {
            var notParentOwnerships;
            var _this = this;
            return __generator(this, function (_a) {
                notParentOwnerships = this.data.caseOwnerships.filter(function (caseOwnership) { return caseOwnership.isParent !== true; });
                if (this.data.titles.length > 1 || notParentOwnerships.length === 1 &&
                    this.data.obligation.type !== 'InnominateSalesContractLiability' &&
                    (this.data.obligation.type !== 'RealBurdenLiability' || !this.data.caseOwnerships.some(function (dataOwnership) { return dataOwnership.ownership.podilCitatel !== dataOwnership.ownership.podilJmenovatel; }))) {
                    return [2 /*return*/, this.titleDetailCreateCaseSelectTitlesService.loadTitlesBySubject(notParentOwnerships[0].ownership.subjects[0].id, this.data.titles[0].id, this.data.obligation.type, !!this.data.fromCase).promise.then(function (dataTitles) { return __awaiter(_this, void 0, void 0, function () {
                            var e_1, _a, _loop_1, this_1, _b, _c, title, e_1_1;
                            var _this = this;
                            return __generator(this, function (_d) {
                                switch (_d.label) {
                                    case 0:
                                        if (!(this.data.titles.length > 1)) return [3 /*break*/, 1];
                                        this.dataTitles = dataTitles.list;
                                        // remove caseOwnerships
                                        this.data.titles = [this.data.titles[0]];
                                        _remove(this.data.caseOwnerships, function (caseOwnership) { return caseOwnership.ownership.title.id !== _this.data.titles[0].id; })
                                            .forEach(function (caseOwnership) {
                                            var titleOwnership = _this.dataTitles.find(function (dataTitles) { return dataTitles.ownership.id === caseOwnership.ownership.id; });
                                            _this.checklistOwnershipTitle.toggleSelection(titleOwnership);
                                            _this.checklistOwnership.toggleSelection(titleOwnership);
                                        });
                                        return [3 /*break*/, 12];
                                    case 1:
                                        if (!(dataTitles.list.length && !this.data.fromCase)) return [3 /*break*/, 2];
                                        this.dataTitles = dataTitles.list;
                                        if (this.forwardStep) {
                                            new this.confirmService({
                                                template: '<h1>Založení případu pro více listů vlastnictví</h1>'
                                                    + '<p class="font-size-16 mb-10">' + this.getSubjectName() + ' je uvedený(á) v dalších LV, které vyhovují vybranému typu případu - ' + this.data.obligation.name + '.</p>'
                                                    + '<p class="font-size-16">Přejete si do zakládaného případu zahrnout další listy vlastnictví?</p>'
                                                    + '<div class="actions"><input type="button" value="Ano" ng-click="confirm();"/>'
                                                    + '<input type="button" value="Ne" ng-click="decline();" class="red "/></div>',
                                            }).then(function (result) {
                                                if (result === false) {
                                                    _this.submitCallback.emit();
                                                }
                                            });
                                        }
                                        return [3 /*break*/, 12];
                                    case 2:
                                        if (!(this.data.fromCase && this.data.fromCase.titles.length > 1)) return [3 /*break*/, 11];
                                        this.dataTitles = dataTitles.list;
                                        _loop_1 = function (title) {
                                            var dataTitle;
                                            return __generator(this, function (_a) {
                                                switch (_a.label) {
                                                    case 0:
                                                        dataTitle = dataTitles.list.find(function (dataTitle) { return dataTitle.ownership.lv === title.lv && dataTitle.ownership.katuzeKod === title.area.id; });
                                                        if (!dataTitle) return [3 /*break*/, 2];
                                                        this_1.toggleSelectionOwnershipTitle(dataTitle);
                                                        return [4 /*yield*/, dataTitle.itemsSelected];
                                                    case 1:
                                                        _a.sent();
                                                        _a.label = 2;
                                                    case 2: return [2 /*return*/];
                                                }
                                            });
                                        };
                                        this_1 = this;
                                        _d.label = 3;
                                    case 3:
                                        _d.trys.push([3, 8, 9, 10]);
                                        _b = __values(this.data.fromCase.titles.slice(1)), _c = _b.next();
                                        _d.label = 4;
                                    case 4:
                                        if (!!_c.done) return [3 /*break*/, 7];
                                        title = _c.value;
                                        return [5 /*yield**/, _loop_1(title)];
                                    case 5:
                                        _d.sent();
                                        _d.label = 6;
                                    case 6:
                                        _c = _b.next();
                                        return [3 /*break*/, 4];
                                    case 7: return [3 /*break*/, 10];
                                    case 8:
                                        e_1_1 = _d.sent();
                                        e_1 = { error: e_1_1 };
                                        return [3 /*break*/, 10];
                                    case 9:
                                        try {
                                            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                                        }
                                        finally { if (e_1) throw e_1.error; }
                                        return [7 /*endfinally*/];
                                    case 10:
                                        this.submit();
                                        return [3 /*break*/, 12];
                                    case 11:
                                        this.submitCallback.emit({ skipped: true });
                                        _d.label = 12;
                                    case 12: return [2 /*return*/];
                                }
                            });
                        }); })];
                }
                else {
                    this.submitCallback.emit({ skipped: true });
                }
                return [2 /*return*/];
            });
        });
    };
    CreateCaseSelectTitlesComponent.prototype.toggleSelectionOwnershipTitle = function (titleOwnership, checkboxClick) {
        if (checkboxClick === void 0) { checkboxClick = true; }
        if (!this.selection.isOwnershipEnabled(titleOwnership)) {
            return;
        }
        var checked = true;
        if (checkboxClick || !this.checklistOwnershipTitle.isChecked(titleOwnership)) {
            checked = this.checklistOwnershipTitle.toggleSelection(titleOwnership);
            this.checklistOwnership.toggleSelection(titleOwnership);
        }
        if (!checked && titleOwnership.unselect) {
            titleOwnership.unselect();
        }
        else if (checked) {
            this.checklistConstructionObject.checkedItems.forEach(function (dataConstructionObject) {
                dataConstructionObject.easements = [];
                dataConstructionObject.occupations = [];
            });
            this.displayTitleOwnership = titleOwnership;
        }
    };
    CreateCaseSelectTitlesComponent.prototype.closeSelectOwnershipTitleCancel = function () {
        this.toggleSelectionOwnershipTitle(this.displayTitleOwnership);
        delete this.displayTitleOwnership;
    };
    CreateCaseSelectTitlesComponent.prototype.closeSelectOwnershipTitle = function () {
        if (this.displayTitleOwnership.isSelectionValidLocal()) {
            delete this.displayTitleOwnership;
        }
    };
    return CreateCaseSelectTitlesComponent;
}());
exports.CreateCaseSelectTitlesComponent = CreateCaseSelectTitlesComponent;
