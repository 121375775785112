"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./help.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/common");
var i3 = require("./help.component");
var i4 = require("../../services/auth.service");
var styles_HelpComponent = [i0.styles];
var RenderType_HelpComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HelpComponent, data: {} });
exports.RenderType_HelpComponent = RenderType_HelpComponent;
function View_HelpComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "clickable"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onEditClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-pencil"]], null, null, null, null, null))], null, null); }
function View_HelpComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["class", "button"], ["target", "_blank"]], [[8, "href", 4], [8, "title", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-question fa-2x"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.help.url, ""); var currVal_1 = i1.ɵinlineInterpolate(1, "", _co.help.title, ""); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_HelpComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-question fa-2x"]], null, null, null, null, null))], null, null); }
function View_HelpComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "cm-help"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "cm-help-admin": 0, "cm-help-empty": 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HelpComponent_2)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HelpComponent_3)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HelpComponent_4)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "cm-help"; var currVal_1 = _ck(_v, 2, 0, _co.isAdmin(), !_co.isSet); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _co.isAdmin(); _ck(_v, 4, 0, currVal_2); var currVal_3 = _co.isSet; _ck(_v, 6, 0, currVal_3); var currVal_4 = (!_co.isSet && _co.isAdmin()); _ck(_v, 8, 0, currVal_4); }, null); }
function View_HelpComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_HelpComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.helpId; _ck(_v, 1, 0, currVal_0); }, null); }
exports.View_HelpComponent_0 = View_HelpComponent_0;
function View_HelpComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "help", [], null, null, null, View_HelpComponent_0, RenderType_HelpComponent)), i1.ɵdid(1, 114688, null, 0, i3.HelpComponent, [i4.AuthService, "HELP_IDS", "HelpService", "ModalService"], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_HelpComponent_Host_0 = View_HelpComponent_Host_0;
var HelpComponentNgFactory = i1.ɵccf("help", i3.HelpComponent, View_HelpComponent_Host_0, { helpId: "helpId" }, { callbackRegister: "callbackRegister" }, []);
exports.HelpComponentNgFactory = HelpComponentNgFactory;
