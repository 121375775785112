"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var FormCaseHandOverInvestorComponent = /** @class */ (function () {
    function FormCaseHandOverInvestorComponent() {
        this.callbackRegister = new core_1.EventEmitter();
        this.isValid = this.isValid.bind(this);
    }
    FormCaseHandOverInvestorComponent.prototype.ngOnInit = function () {
        this.data.handedOverInvestorDate = this.data.handedOverInvestorDate || new Date();
        this.callbackRegister.emit({
            isValid: this.isValid,
        });
    };
    FormCaseHandOverInvestorComponent.prototype.isValid = function () {
        return !!this.data.handedOverInvestorDate;
    };
    return FormCaseHandOverInvestorComponent;
}());
exports.FormCaseHandOverInvestorComponent = FormCaseHandOverInvestorComponent;
