"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var geometric_plan_request_type_enum_1 = require("@app/ps/enums/geometric-plan-request-type.enum");
var approve_enum_1 = require("@app/ps/enums/approve.enum");
var GeometricPlanRequestModel = /** @class */ (function () {
    function GeometricPlanRequestModel() {
    }
    GeometricPlanRequestModel.create = function () {
        var instance = new GeometricPlanRequestModel();
        instance.type = geometric_plan_request_type_enum_1.GeometricPlanRequestTypeEnum.PARCEL_SPLIT;
        instance.parcelSplitPreApproveByBuildingAuthority = approve_enum_1.ApproveEnum.NOT_ENSURE;
        instance.parcelSplitPreApproveByOwner = approve_enum_1.ApproveEnum.NOT_ENSURE;
        return instance;
    };
    return GeometricPlanRequestModel;
}());
exports.GeometricPlanRequestModel = GeometricPlanRequestModel;
