"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var _ = require("lodash");
var list_service_1 = require("@app/common/services/list.service");
var auth_service_1 = require("@app/common/services/auth.service");
var statistics_object_type_model_1 = require("@app/ps/models/statistics-object-type.model");
var dialog_service_1 = require("@app/dialog/dialog.service");
var statistics_settings_status_edit_component_1 = require("@app/ps/project-settings/components/statistics-settings-status-edit/statistics-settings-status-edit.component");
var FormStatisticsObligationModel = /** @class */ (function () {
    function FormStatisticsObligationModel() {
    }
    return FormStatisticsObligationModel;
}());
var FormStatisticsCategoryModel = /** @class */ (function () {
    function FormStatisticsCategoryModel() {
    }
    return FormStatisticsCategoryModel;
}());
var FormStatisticsSettingsModel = /** @class */ (function () {
    function FormStatisticsSettingsModel() {
    }
    return FormStatisticsSettingsModel;
}());
var FormStatisticsObjectTypeModel = /** @class */ (function () {
    function FormStatisticsObjectTypeModel() {
    }
    return FormStatisticsObjectTypeModel;
}());
var StatisticsSettingsComponent = /** @class */ (function () {
    function StatisticsSettingsComponent(checklistService, listService, authService, dialogService) {
        var _this = this;
        this.checklistService = checklistService;
        this.listService = listService;
        this.authService = authService;
        this.dialogService = dialogService;
        this.callbackRegister = new core_1.EventEmitter();
        this.graphTypes = [
            { id: 'INCREASING', value: 'Rostoucí' },
            { id: 'DECREASING', value: 'Klesající' }
        ];
        this.removeStatistics = function (statistics) {
            _.pull(_this.data, statistics.statistics);
            _.pull(_this.formData, statistics);
        };
        this.newCategoryChanged = this.newCategoryChanged.bind(this);
        this.addStatistics = this.addStatistics.bind(this);
        this.reload = this.reload.bind(this);
    }
    StatisticsSettingsComponent.prototype.ngOnInit = function () {
        var _this = this;
        var obligationsData = this.listService.createList('obligations', { limit: null, filters: { loadCollections: 'statuses' } });
        this.listService.fetchResult(obligationsData).then(function () {
            _this.obligations = obligationsData.list;
        });
        var statusData = this.listService.createList('case-statuses', { limit: null });
        this.listService.fetchResult(statusData).then(function () {
            _this.statuses = statusData.list;
        });
        Promise.all([obligationsData.promise, statusData.promise]).then(function () {
            _this.reload(_this.data);
        });
        this.callbackRegister.emit({
            reload: this.reload
        });
    };
    StatisticsSettingsComponent.prototype.reload = function (data) {
        var _this = this;
        this.data = data;
        this.formData = [];
        if (this.obligations && this.statuses) {
            this.data.forEach(function (statistic) {
                _this.initStatistics(statistic);
            });
        }
    };
    // General update button action
    StatisticsSettingsComponent.prototype.edit = function (object) {
        if (this.isEdited(object)) {
            this.editedObject = undefined;
        }
        else {
            this.editedObject = object;
        }
    };
    StatisticsSettingsComponent.prototype.isEdited = function (object) {
        return object === this.editedObject;
    };
    // Statistics
    StatisticsSettingsComponent.prototype.addStatistics = function () {
        var statistic = {
            name: '',
            categories: [],
            objectTypes: [],
            objectTypesCombination: false,
            intervals: [],
            available: false,
            statisticsOrder: this.data.length + 1,
        };
        this.data.push(statistic);
        this.initStatistics(statistic);
    };
    StatisticsSettingsComponent.prototype.moveStatistics = function (statistics, direction) {
        StatisticsSettingsComponent.move(this.data, statistics.statistics, direction);
        StatisticsSettingsComponent.move(this.formData, statistics, direction);
        this.data.forEach(function (field, index) { field.statisticsOrder = index + 1; });
    };
    StatisticsSettingsComponent.prototype.toggleStatistics = function (statistics) {
        statistics.statistics.available = !statistics.statistics.available;
    };
    // Category
    StatisticsSettingsComponent.prototype.newCategoryChanged = function (category, statistic) {
        var _this = this;
        if (category.name !== null && category === statistic.categories[statistic.categories.length - 1]) {
            var cat = {
                name: null,
                id: null,
                obligations: [],
            };
            statistic.categories.push(cat);
            category.id = ++statistic.categoryId;
            statistic.statistics.categories.push(category);
            statistic.obligations.forEach(function (obligationForm) {
                var obligation = {
                    type: obligationForm.obligationData.type,
                    statuses: [],
                };
                category.obligations.push(obligation);
                _this.addCategory(category, obligationForm, obligation);
            });
        }
    };
    StatisticsSettingsComponent.prototype.removeCategory = function (category, statistic) {
        _.pull(statistic.categories, category);
        _.pull(statistic.statistics.categories, category);
        statistic.obligations.forEach(function (obligation) {
            _.remove(obligation.categories, { category: category });
        });
    };
    StatisticsSettingsComponent.prototype.upCategory = function (category, statistic) {
        StatisticsSettingsComponent.move(statistic.categories, category, -1);
        StatisticsSettingsComponent.move(statistic.statistics.categories, category, -1);
        statistic.obligations.forEach(function (obligation) {
            StatisticsSettingsComponent.move(obligation.categories, _.find(obligation.categories, { category: category }), -1);
        });
    };
    StatisticsSettingsComponent.prototype.downCategory = function (category, statistic) {
        StatisticsSettingsComponent.move(statistic.categories, category, +1, false);
        StatisticsSettingsComponent.move(statistic.statistics.categories, category, +1);
        statistic.obligations.forEach(function (obligation) {
            StatisticsSettingsComponent.move(obligation.categories, _.find(obligation.categories, { category: category }), +1);
        });
    };
    StatisticsSettingsComponent.prototype.onSplit = function (category) {
        category.split = !category.split;
        if (category.split) {
            category.splitName = category.name;
            category.splitNameAlternative = category.nameAlternative;
            category.splitGraphType = category.graphType;
            category.splitYAxisLabel = category.yAxisLabel;
            category.splitValueLabel = category.valueLabel;
            category.splitRemainLabel = category.remainLabel;
            category.name = null;
            category.nameAlternative = null;
            category.graphType = null;
            category.yAxisLabel = null;
            category.valueLabel = null;
            category.remainLabel = null;
        }
        else {
            category.name = category.splitName;
            category.nameAlternative = category.splitNameAlternative;
            category.graphType = category.splitGraphType;
            category.yAxisLabel = category.splitYAxisLabel;
            category.valueLabel = category.splitValueLabel;
            category.remainLabel = category.splitRemainLabel;
            category.splitName = null;
            category.splitNameAlternative = null;
            category.splitGraphType = null;
            category.splitYAxisLabel = null;
            category.splitValueLabel = null;
            category.splitRemainLabel = null;
        }
    };
    StatisticsSettingsComponent.prototype.addCategory = function (category, obligationForm, obligation) {
        if (!_.some(obligationForm.categories, { category: category })) {
            var cat_1 = {
                category: category,
                obligation: obligation,
                statusesData: [],
                reloadOptionsRegisterStatus: function (reloadOptions) {
                    cat_1.reloadOptionsStatus = reloadOptions;
                },
                statusesFilter: function (caseStatus) {
                    return caseStatus.key !== 'Cancelled' &&
                        _.includes(obligationForm.obligationData.statuses, caseStatus.key) &&
                        _.every(obligationForm.categories, function (formCategory) {
                            return (category !== formCategory.category && obligationForm.repeat) ||
                                _.every(formCategory.obligation.statuses, function (status) { return status.status !== caseStatus.key; });
                        });
                },
            };
            obligationForm.categories.push(cat_1);
            return cat_1;
        }
    };
    // Obligation
    StatisticsSettingsComponent.prototype.selectedObligation = function (formStatistics, obligationData, data) {
        var _this = this;
        data.selected = null;
        formStatistics.categories.forEach(function (category) {
            if (category.name === null) {
                return;
            }
            var obligation = {
                type: obligationData.type,
                statuses: [],
            };
            category.obligations.push(obligation);
            if (category !== formStatistics.categories[formStatistics.categories.length - 1]) {
                _this.addObligation(formStatistics, obligation, category);
            }
        });
        this.addObligation(formStatistics, {
            type: obligationData.type,
            statuses: [],
        });
        formStatistics.reloadOptionsObligation();
    };
    StatisticsSettingsComponent.prototype.removeObligation = function (obligation, formStatistics) {
        _.pull(formStatistics.obligations, obligation);
        formStatistics.categories.forEach(function (category) {
            _.remove(category.obligations, { type: obligation.obligationData.type });
        });
        formStatistics.reloadOptionsObligation();
    };
    StatisticsSettingsComponent.prototype.selectedStatus = function (obligation, category, status, data) {
        data.selected = null;
        this.addStatus(category, {
            status: status.key,
        });
        obligation.reloadOptionsStatus();
    };
    StatisticsSettingsComponent.prototype.removeStatus = function (obligation, category, statusData) {
        _.remove(category.obligation.statuses, function (caseStatus) { return caseStatus.status === statusData.key; });
        _.pull(category.statusesData, statusData);
        obligation.reloadOptionsStatus();
    };
    StatisticsSettingsComponent.prototype.editStatus = function (category, statusData) {
        var caseStatus = category.obligation.statuses.find(function (caseStatus) { return caseStatus.status === statusData.key; });
        var dialog = this.dialogService.open(statistics_settings_status_edit_component_1.StatisticsSettingsStatusEditComponent, {
            data: {
                statuses: __spread(this.statuses),
                caseStatus: Object.assign({}, caseStatus),
            },
        });
        var sub = dialog.afterClosed.subscribe(function (result) {
            if (result) {
                Object.assign(caseStatus, result.caseStatus);
            }
            sub.unsubscribe();
        });
    };
    StatisticsSettingsComponent.prototype.toggleRepeat = function (obligation) {
        obligation.repeat = !obligation.repeat;
        obligation.reloadOptionsStatus();
    };
    StatisticsSettingsComponent.prototype.initStatistics = function (statistics) {
        var _this = this;
        var formStatistics = {};
        formStatistics.statistics = statistics;
        formStatistics.obligations = [];
        formStatistics.categories = _.clone(statistics.categories);
        formStatistics.intervals = _.clone(statistics.intervals);
        formStatistics.categoryId = 0;
        formStatistics.objectTypes = [
            { type: statistics_object_type_model_1.TypeEnum.EASEMENT_GEOMETRIC_PLAN, name: 'Služebnosti podle dat GP' },
            { type: statistics_object_type_model_1.TypeEnum.EASEMENT_LAND_TAKE, name: 'Služebnosti podle dat ZE' },
            { type: statistics_object_type_model_1.TypeEnum.UNDER_ONE_YEAR, name: 'Dočasné zábory do jednoho roku' },
            { type: statistics_object_type_model_1.TypeEnum.OVER_ONE_YEAR, name: 'Dočasné zábory nad jeden rok' },
            { type: statistics_object_type_model_1.TypeEnum.PERMANENT, name: 'Trvalé zábory' }
        ];
        formStatistics.objectTypes.forEach(function (obectType) {
            obectType.item = _.find(statistics.objectTypes, { type: obectType.type });
            if (obectType.item) {
                obectType.countTypeChecklist = new _this.checklistService(obectType.item.countType);
                obectType.item.occupationTypes = obectType.item.occupationTypes || [];
                obectType.occupationTypes = _this.filterOccupationTypes(obectType.type);
            }
        });
        statistics.categories.forEach(function (category) {
            formStatistics.categoryId = formStatistics.categoryId < category.id ? category.id : formStatistics.categoryId;
            category.obligations.forEach(function (obligation) {
                _this.addObligation(formStatistics, obligation, category);
            });
        });
        formStatistics.intervals.push({
            name: null,
        });
        formStatistics.categories.push({
            name: null,
            id: null,
            obligations: [],
        });
        formStatistics.obligationsFilter = this.getObligationsFilter(formStatistics);
        formStatistics.reloadOptionsRegisterObligation = function (reloadOptions) {
            formStatistics.reloadOptionsObligation = reloadOptions;
        };
        this.formData.push(formStatistics);
    };
    StatisticsSettingsComponent.prototype.addObligation = function (formStatistics, obligation, category) {
        var _this = this;
        if (category === void 0) { category = null; }
        var obligationData = _.find(this.obligations, { type: obligation.type });
        if (!obligationData) {
            return;
        }
        var obligationForm = _.find(formStatistics.obligations, { obligationData: obligationData });
        if (!obligationForm) {
            obligationForm = {
                obligationData: obligationData,
                categories: [],
                repeat: false,
                reloadOptionsStatus: function () {
                    obligationForm.categories.forEach(function (category) { return category.reloadOptionsStatus ? category.reloadOptionsStatus() : null; });
                },
            };
            formStatistics.obligations.push(obligationForm);
        }
        if (category) {
            var cat_2 = this.addCategory(category, obligationForm, obligation);
            obligation.statuses.forEach(function (caseStatus) { return _this.addStatus(cat_2, caseStatus); });
        }
    };
    StatisticsSettingsComponent.prototype.addStatus = function (category, caseStatus) {
        var statusData = _.find(this.statuses, { key: caseStatus.status });
        category.statusesData.push(statusData);
        if (!_.includes(category.obligation.statuses, caseStatus)) {
            category.obligation.statuses.push(caseStatus);
        }
    };
    StatisticsSettingsComponent.prototype.getObligationsFilter = function (formStatistics) {
        return function (obligationData) { return !_.some(formStatistics.obligations, { obligationData: obligationData }); };
    };
    // Object type
    StatisticsSettingsComponent.prototype.isObjectTypeAvailable = function (objectTypeForm, statistic) {
        return _.some(statistic.obligations, function (obligation) {
            return (obligation.obligationData.objectTypes.easementsGeometricPlan && objectTypeForm.type === 'EASEMENT_GEOMETRIC_PLAN') ||
                (obligation.obligationData.objectTypes.easementsLandTake && objectTypeForm.type === 'EASEMENT_LAND_TAKE') ||
                (obligation.obligationData.objectTypes.occupations && (objectTypeForm.type === 'OVER_ONE_YEAR' || objectTypeForm.type === 'UNDER_ONE_YEAR' || objectTypeForm.type === 'PERMANENT')) ||
                (objectTypeForm.type === 'PERMANENT' && _.includes(obligation.obligationData.objectTypes.occupationGroupKeys, 'permanent')) ||
                (objectTypeForm.type === 'UNDER_ONE_YEAR' && _.includes(obligation.obligationData.objectTypes.occupationGroupKeys, 'underOneYear')) ||
                (objectTypeForm.type === 'OVER_ONE_YEAR' && _.includes(obligation.obligationData.objectTypes.occupationGroupKeys, 'overOneYear'));
        });
    };
    StatisticsSettingsComponent.prototype.toggleObjectType = function (objectTypeForm, statistic) {
        if (objectTypeForm.item) {
            delete objectTypeForm.countTypeChecklist;
            _.pull(statistic.statistics.objectTypes, objectTypeForm.item);
            delete objectTypeForm.item;
            delete objectTypeForm.occupationTypes;
        }
        else if (this.isObjectTypeAvailable(objectTypeForm, statistic)) {
            objectTypeForm.item = { type: objectTypeForm.type, countType: [], occupationTypes: [] };
            statistic.statistics.objectTypes.push(objectTypeForm.item);
            objectTypeForm.countTypeChecklist = new this.checklistService(objectTypeForm.item.countType);
            objectTypeForm.occupationTypes = this.filterOccupationTypes(objectTypeForm.type);
        }
    };
    StatisticsSettingsComponent.prototype.isOccupationTypeChecked = function (list, occupationType) {
        return list.some(function (item) { return item === occupationType.number; });
    };
    StatisticsSettingsComponent.prototype.toggleOccupationTypeChecked = function (list, occupationType) {
        this.isOccupationTypeChecked(list, occupationType) ? _.pull(list, occupationType.number) : list.push(occupationType.number);
    };
    StatisticsSettingsComponent.prototype.filterOccupationTypes = function (type) {
        if (type !== 'EASEMENT_GEOMETRIC_PLAN' && type !== 'EASEMENT_LAND_TAKE') {
            return this.authService.getActualProject().occupationTypes.filter(function (occ) {
                var cat = _.snakeCase(occ.category).toUpperCase();
                return cat === type;
            }).map(function (occ) {
                return {
                    number: occ.number,
                    name: occ.name,
                };
            });
        }
    };
    // Intervals list update functions
    StatisticsSettingsComponent.prototype.upInterval = function (interval, statistic) {
        StatisticsSettingsComponent.move(statistic.intervals, interval, -1);
        StatisticsSettingsComponent.move(statistic.statistics.intervals, interval, -1);
    };
    StatisticsSettingsComponent.prototype.downInterval = function (interval, statistic) {
        StatisticsSettingsComponent.move(statistic.intervals, interval, 1);
        StatisticsSettingsComponent.move(statistic.statistics.intervals, interval, 1);
    };
    StatisticsSettingsComponent.prototype.newIntervalChanged = function (interval, statistic) {
        if (interval.name !== null && interval === statistic.intervals[statistic.intervals.length - 1]) {
            var int = {
                name: null,
            };
            statistic.intervals.push(int);
            statistic.statistics.intervals.push(interval);
        }
    };
    StatisticsSettingsComponent.prototype.removeInterval = function (interval, statistic) {
        _.pull(statistic.intervals, interval);
        _.pull(statistic.statistics.intervals, interval);
    };
    // Common
    StatisticsSettingsComponent.move = function (list, item, diff, lastIncluded) {
        if (lastIncluded === void 0) { lastIncluded = true; }
        var index = _.indexOf(list, item);
        if (index + diff < list.length - (lastIncluded ? 0 : 1) && index + diff >= 0) {
            _.pull(list, item);
            list.splice(index + diff, 0, item);
        }
    };
    return StatisticsSettingsComponent;
}());
exports.StatisticsSettingsComponent = StatisticsSettingsComponent;
