"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var common_1 = require("@angular/common");
var cs_1 = require("@angular/common/locales/cs");
var promap_restangular_service_1 = require("./services/promap-restangular.service");
var vfze_restangular_service_1 = require("./services/vfze-restangular.service");
var sv_restangular_service_1 = require("./services/sv-restangular.service");
var promap_proxy_restangular_service_1 = require("@app/common/services/promap-proxy-restangular.service");
common_1.registerLocaleData(cs_1.default, 'cs');
var ɵ0 = function ($injector) { return $injector.get('UICONSTANTS'); }, ɵ1 = function ($injector) { return $injector.get('APP_WIKI_URL'); }, ɵ2 = function ($injector) { return $injector.get('APP_VERSION'); }, ɵ3 = function ($injector) { return $injector.get('AuthService'); }, ɵ4 = function ($injector) { return $injector.get('ChecklistService'); }, ɵ5 = function ($injector) { return $injector.get('ListLoadService'); }, ɵ6 = function ($injector) { return $injector.get('WindowDimensionService'); }, ɵ7 = function ($injector) { return $injector.get('GlobalRestangularSettingsProvider'); }, ɵ8 = function ($injector) { return $injector.get('ConfirmService'); }, ɵ9 = function ($injector) { return $injector.get('LoginService'); }, ɵ10 = function ($injector) { return $injector.get('ErrorHandlerService'); }, ɵ11 = function ($injector) { return $injector.get('localStorageService'); }, ɵ12 = function ($injector) { return $injector.get('Upload'); }, ɵ13 = function ($injector) { return $injector.get('HelpService'); }, ɵ14 = function ($injector) { return $injector.get('MapConfigService'); }, ɵ15 = function ($injector) { return $injector.get('ModalService'); }, ɵ16 = function ($injector) { return $injector.get('HELP_IDS'); }, ɵ17 = function ($injector) { return $injector.get('EVENTS'); }, ɵ18 = function ($injector) { return $injector.get('Restangular'); }, ɵ19 = window['SYMAP_CONFIG'], ɵ20 = function ($injector) { return $injector.get('APPLICATIONS'); }, ɵ21 = promap_restangular_service_1.RestangularPromapFactory, ɵ22 = promap_proxy_restangular_service_1.RestangularPromapProxyFactory, ɵ23 = function ($injector) { return $injector.get('GlobalRestangularSYMAPProvider'); }, ɵ24 = function ($injector) { return $injector.get('GlobalRestangularDIMAPProvider'); }, ɵ25 = vfze_restangular_service_1.RestangularVfzeFactory, ɵ26 = sv_restangular_service_1.RestangularSVFactory, ɵ27 = function ($injector) { return $injector.get('AlertService'); };
exports.ɵ0 = ɵ0;
exports.ɵ1 = ɵ1;
exports.ɵ2 = ɵ2;
exports.ɵ3 = ɵ3;
exports.ɵ4 = ɵ4;
exports.ɵ5 = ɵ5;
exports.ɵ6 = ɵ6;
exports.ɵ7 = ɵ7;
exports.ɵ8 = ɵ8;
exports.ɵ9 = ɵ9;
exports.ɵ10 = ɵ10;
exports.ɵ11 = ɵ11;
exports.ɵ12 = ɵ12;
exports.ɵ13 = ɵ13;
exports.ɵ14 = ɵ14;
exports.ɵ15 = ɵ15;
exports.ɵ16 = ɵ16;
exports.ɵ17 = ɵ17;
exports.ɵ18 = ɵ18;
exports.ɵ19 = ɵ19;
exports.ɵ20 = ɵ20;
exports.ɵ21 = ɵ21;
exports.ɵ22 = ɵ22;
exports.ɵ23 = ɵ23;
exports.ɵ24 = ɵ24;
exports.ɵ25 = ɵ25;
exports.ɵ26 = ɵ26;
exports.ɵ27 = ɵ27;
var CommonModule = /** @class */ (function () {
    function CommonModule() {
    }
    return CommonModule;
}());
exports.CommonModule = CommonModule;
