"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var dialog_config_1 = require("@app/dialog/dialog-config");
var dialog_ref_1 = require("@app/dialog/dialog-ref");
var contract_service_1 = require("@app/settings/customers/services/contract.service");
var customer_service_1 = require("@app/settings/customers/services/customer.service");
var ProjectFormComponent = /** @class */ (function () {
    function ProjectFormComponent(config, dialog, contractService, customerService) {
        this.config = config;
        this.dialog = dialog;
        this.contractService = contractService;
        this.customerService = customerService;
        this.loading = true;
        this.selected = {
            project: null,
        };
        this.postArgs = {
            filter: {},
        };
        this.promapObj = {
            application: 'PRO',
            projectKey: 'promap',
            projectName: 'PROMAP',
            contractCount: 0,
        };
        this.save = this.save.bind(this);
    }
    ProjectFormComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.loadProjects()];
                    case 1:
                        _a.projects = _b.sent();
                        this.loading = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    ProjectFormComponent.prototype.save = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.contractService
                            .addProject(this.config.data.contractId, this.selected['project']['application'] || 'SY', this.selected['project']['projectKey'])
                            .then(function () {
                            return _this.dialog.close(true);
                        })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ProjectFormComponent.prototype.isProjectSet = function () {
        return this.selected['project'] && this.selected['project']['projectKey'];
    };
    ProjectFormComponent.prototype.loadProjects = function () {
        return __awaiter(this, void 0, void 0, function () {
            var projects;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.customerService.getProjects(this.config.data.customerId, this.postArgs)];
                    case 1:
                        projects = _a.sent();
                        projects.push(this.promapObj);
                        projects.forEach(function (p) { return p.name = p.projectName; });
                        projects = projects.filter(function (p) { return p.contractCount === 0; });
                        return [2 /*return*/, projects];
                }
            });
        });
    };
    return ProjectFormComponent;
}());
exports.ProjectFormComponent = ProjectFormComponent;
