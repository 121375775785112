"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var MapCallbackEnum;
(function (MapCallbackEnum) {
    MapCallbackEnum["layerMouseEnter"] = "layerMouseEnter";
    MapCallbackEnum["layerMouseLeave"] = "layerMouseLeave";
    MapCallbackEnum["searchMouseEnter"] = "searchMouseEnter";
    MapCallbackEnum["searchMouseLeave"] = "searchMouseLeave";
    MapCallbackEnum["collapsedStateChange"] = "collapsedStateChange";
})(MapCallbackEnum = exports.MapCallbackEnum || (exports.MapCallbackEnum = {}));
;
