"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var DraggableDirective = /** @class */ (function () {
    function DraggableDirective() {
        this.mouseDone = new core_1.EventEmitter();
        this.dragging = false;
    }
    DraggableDirective.prototype.onMouseDown = function () {
        this.setDragging(false);
    };
    DraggableDirective.prototype.onMouseMove = function () {
        this.setDragging(true);
    };
    DraggableDirective.prototype.onMouseUp = function () {
        var wasDragging = this.dragging;
        this.setDragging(false);
        this.mouseDone.emit(wasDragging);
    };
    DraggableDirective.prototype.setDragging = function (value) {
        this.dragging = value;
    };
    return DraggableDirective;
}());
exports.DraggableDirective = DraggableDirective;
