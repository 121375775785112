"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var auth_service_1 = require("@app/common/services/auth.service");
var CadastreUpdateComponent = /** @class */ (function () {
    function CadastreUpdateComponent(restangularSymap, authService) {
        this.restangularSymap = restangularSymap;
        this.authService = authService;
    }
    CadastreUpdateComponent.prototype.ngOnInit = function () {
        this.onSend = this.onSend.bind(this);
    };
    CadastreUpdateComponent.prototype.isValid = function () {
        return this.authService.getUser().superuser;
    };
    CadastreUpdateComponent.prototype.onSend = function () {
        return this.restangularSymap.all('projects/cadastre/submit/monthly-update-projects').customPOST();
    };
    return CadastreUpdateComponent;
}());
exports.CadastreUpdateComponent = CadastreUpdateComponent;
