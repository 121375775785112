"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ColumnDefinition = /** @class */ (function () {
    function ColumnDefinition() {
        this.sortable = false;
    }
    ColumnDefinition.createFromString = function (id) {
        return {
            id: id,
            name: id,
            sortable: false
        };
    };
    return ColumnDefinition;
}());
exports.ColumnDefinition = ColumnDefinition;
