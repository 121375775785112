"use strict";
var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
Object.defineProperty(exports, "__esModule", { value: true });
var auth_service_1 = require("@app/common/services/auth.service");
var project_subject_name_pipe_1 = require("@app/ps/pipes/project-subject-name.pipe");
var common_1 = require("@angular/common");
var username_pipe_1 = require("@app/common/pipes/username.pipe");
var brand_translate_pipe_1 = require("@app/common/pipes/brand-translate.pipe");
var organizational_unit_name_pipe_1 = require("@app/common/pipes/organizational-unit-name.pipe");
var project_state_pipe_1 = require("@app/ps/pipes/project-state.pipe");
var readiness_pipe_1 = require("@app/ps/pipes/readiness.pipe");
var i0 = require("@angular/core");
var i1 = require("../../ps/pipes/project-subject-name.pipe");
var i2 = require("../../common/pipes/organizational-unit-name.pipe");
var i3 = require("../../common/pipes/username.pipe");
var i4 = require("../../common/services/auth.service");
var i5 = require("@angular/common");
var i6 = require("../../common/pipes/brand-translate.pipe");
var i7 = require("../../ps/pipes/project-state.pipe");
var i8 = require("../../ps/pipes/readiness.pipe");
var ProjectsService = /** @class */ (function () {
    function ProjectsService(projectSubjectPipe, organizationalUnitNamePipe, userNamePipe, authService, numberPipe, datePipe, brandTranslatePipe, projectStatePipe, readinessPipe) {
        this.projectSubjectPipe = projectSubjectPipe;
        this.organizationalUnitNamePipe = organizationalUnitNamePipe;
        this.userNamePipe = userNamePipe;
        this.authService = authService;
        this.numberPipe = numberPipe;
        this.datePipe = datePipe;
        this.brandTranslatePipe = brandTranslatePipe;
        this.projectStatePipe = projectStatePipe;
        this.readinessPipe = readinessPipe;
    }
    ProjectsService.prototype.transformForExport = function (projects, columnDefinitions, selectedColumns) {
        var e_1, _a, e_2, _b;
        var columnIds = columnDefinitions.filter(function (c) { return selectedColumns.includes(c.id); }).map(function (c) { return c.id; });
        var columnNames = columnDefinitions.filter(function (c) { return selectedColumns.includes(c.id); }).map(function (c) { return c.name; });
        var body = [];
        try {
            for (var projects_1 = __values(projects), projects_1_1 = projects_1.next(); !projects_1_1.done; projects_1_1 = projects_1.next()) {
                var project = projects_1_1.value;
                var row = [];
                try {
                    for (var columnIds_1 = __values(columnIds), columnIds_1_1 = columnIds_1.next(); !columnIds_1_1.done; columnIds_1_1 = columnIds_1.next()) {
                        var id = columnIds_1_1.value;
                        row.push(this.transformValueForExport(project, id));
                    }
                }
                catch (e_2_1) { e_2 = { error: e_2_1 }; }
                finally {
                    try {
                        if (columnIds_1_1 && !columnIds_1_1.done && (_b = columnIds_1.return)) _b.call(columnIds_1);
                    }
                    finally { if (e_2) throw e_2.error; }
                }
                body.push(row);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (projects_1_1 && !projects_1_1.done && (_a = projects_1.return)) _a.call(projects_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return [columnNames].concat(body);
    };
    ProjectsService.prototype.transformValueForExport = function (project, id) {
        if (id === 'donePercentage') {
            return (project[id] !== undefined ? this.numberPipe.transform(project[id], '1.0-0') : undefined);
        }
        if (['lastActivityDate', 'projectStartDate'].includes(id)) {
            return (project[id] !== undefined ? this.datePipe.transform(project[id], 'mediumDate') : undefined);
        }
        return project[id];
    };
    ProjectsService.prototype.createFromApi = function (projects) {
        var _this = this;
        return projects.map(function (p) {
            return {
                isprofond: p.isprofond,
                name: p.name,
                key: p.key,
                unit: p.unit,
                region: p.region,
                manager: _this.userNamePipe.transform(p.manager, false),
                investor: _this.organizationalUnitNamePipe.transform(p.investor ? p.investor.customerOrganizationalUnit : undefined),
                infrastructure: p.infrastructure,
                lastActivityDate: p.lastActivity ? p.lastActivity.timestamp : undefined,
                lastActivityAuthor: p.lastActivity ? _this.userNamePipe.transform(p.lastActivity.user, false) : undefined,
                projectStartDate: p.projectStartDate,
                state: p.state,
                stateName: _this.projectStatePipe.transform(p),
                stateEnum: _this.projectStatePipe.getEnum(p),
                phase: p.phase,
                propertyActivityProcessorCompany: p.propertyActivityProcessor ? p.propertyActivityProcessor.ssoCompanyName : undefined,
                propertyActivityProcessorName: _this.userNamePipe.transform(p.propertyActivityProcessor, false),
                landTakeDocumentationAuthorCompany: p.landTakeDocumentationAuthor ? p.landTakeDocumentationAuthor.companyName : undefined,
                landTakeDocumentationAuthorName: _this.projectSubjectPipe.transform(p.landTakeDocumentationAuthor, false, false),
                designerCompany: p.designer ? p.designer.companyName : undefined,
                designerName: _this.projectSubjectPipe.transform(p.designer, false, false),
                readinessPermanentOccupations: _this.readinessPipe.transform(p.readinessPermanentOccupations),
                readinessTemporaryUnderOccupations: _this.readinessPipe.transform(p.readinessTemporaryUnderOccupations),
                readinessTemporaryOverOccupations: _this.readinessPipe.transform(p.readinessTemporaryOverOccupations),
                readinessTemporaryEasements: _this.readinessPipe.transform(p.readinessTemporaryEasements),
            };
        });
    };
    ProjectsService.mapDashboardDataOnProject = function (project, dashboard) {
        project.activity = dashboard.activity;
        project.total = dashboard.total;
        project.done = dashboard.done;
        project.donePercentage = ProjectsService.getPercentage(dashboard.done, dashboard.total);
        project.tzTotal = dashboard.tzTotal;
        project.tzDone = dashboard.tzDone;
        project.dznrTotal = dashboard.dznrTotal;
        project.dznrDone = dashboard.dznrDone;
        project.dzdrTotal = dashboard.dzdrTotal;
        project.dzdrDone = dashboard.dzdrDone;
        project.vbTotal = dashboard.vbTotal;
        project.vbDone = dashboard.vbDone;
        project.expropriationTotal = dashboard.expropriationTotal;
        project.parcelTotal = dashboard.parcelTotal;
        project.tzDonePercentage = ProjectsService.getPercentage(dashboard.tzDone, dashboard.tzTotal);
        project.dzdrDonePercentage = ProjectsService.getPercentage(dashboard.dzdrDone, dashboard.dzdrTotal);
        project.dznrDonePercentage = ProjectsService.getPercentage(dashboard.dznrDone, dashboard.dznrTotal);
        project.vbDonePercentage = ProjectsService.getPercentage(dashboard.vbDone, dashboard.vbTotal);
        return project;
    };
    ProjectsService.getPercentage = function (done, total) {
        if (total === undefined || done === undefined) {
            return undefined;
        }
        if (total === 0 || done === 0) {
            return 0;
        }
        return (done / total * 100);
    };
    ProjectsService.ngInjectableDef = i0.defineInjectable({ factory: function ProjectsService_Factory() { return new ProjectsService(i0.inject(i1.ProjectSubjectNamePipe), i0.inject(i2.OrganizationalUnitNamePipe), i0.inject(i3.UserNamePipe), i0.inject(i4.AuthService), i0.inject(i5.DecimalPipe), i0.inject(i5.DatePipe), i0.inject(i6.BrandTranslatePipe), i0.inject(i7.ProjectStatePipe), i0.inject(i8.ReadinessPipe)); }, token: ProjectsService, providedIn: "root" });
    return ProjectsService;
}());
exports.ProjectsService = ProjectsService;
