"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var titles_states_1 = require("./titles.states");
var TITLES_STATES = [
    titles_states_1.titlesState,
    titles_states_1.createCaseState,
    titles_states_1.createPricingState,
    titles_states_1.createPricingOverviewState,
    titles_states_1.createPricingExpertOpinionCreateState,
    titles_states_1.createPricingOtherSourceCreateState,
    titles_states_1.updateRentalParcelPriceState,
    titles_states_1.createPreliminaryOpinionState,
    titles_states_1.titleDetailState,
];
var TitlesModule = /** @class */ (function () {
    function TitlesModule() {
    }
    return TitlesModule;
}());
exports.TitlesModule = TitlesModule;
