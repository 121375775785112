"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var dialog_config_1 = require("@app/dialog/dialog-config");
var dialog_ref_1 = require("@app/dialog/dialog-ref");
var document_type_model_1 = require("../../models/document-type.model");
var DocumentChooseTypeFormComponent = /** @class */ (function () {
    function DocumentChooseTypeFormComponent(dialogConfig, dialogComponent) {
        this.dialogConfig = dialogConfig;
        this.dialogComponent = dialogComponent;
        this.selectedDocumentType = document_type_model_1.DocumentTypeModel.createById('OTHER');
        this.note = '';
        this.baseName = '';
        this.onSave = this.onSave.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.isValid = this.isValid.bind(this);
    }
    DocumentChooseTypeFormComponent.prototype.ngOnInit = function () {
        var documentCategory = this.dialogConfig.data.documentCategory;
        var defaultDocumentType = this.dialogConfig.data.defaultDocumentType;
        this.allowType = this.dialogConfig.data.allowType;
        this.file = this.dialogConfig.data.file;
        this.documentTypes = document_type_model_1.DocumentTypeModel.getByCategory(documentCategory);
        this.baseName = this.file.title;
        if (defaultDocumentType) {
            this.selectedDocumentType = defaultDocumentType;
        }
    };
    DocumentChooseTypeFormComponent.prototype.onUpdateType = function (documentType) {
        this.selectedDocumentType = documentType;
    };
    DocumentChooseTypeFormComponent.prototype.isValid = function () {
        return this.baseName && this.selectedDocumentType && (!this.selectedDocumentType.extensions || this.selectedDocumentType.extensions.includes(this.file.extension));
    };
    DocumentChooseTypeFormComponent.prototype.onSave = function () {
        this.dialogComponent.close({ type: this.selectedDocumentType.id, note: this.note, baseName: this.baseName });
    };
    DocumentChooseTypeFormComponent.prototype.onCancel = function () {
        this.dialogComponent.close(false);
    };
    return DocumentChooseTypeFormComponent;
}());
exports.DocumentChooseTypeFormComponent = DocumentChooseTypeFormComponent;
