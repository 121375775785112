"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var pageable_list_service_1 = require("@app/common/services/pageable.list.service");
var bulk_operation_process_definition_model_1 = require("@app/ps/models/bulk-operation-process-definition.model");
var callbacks_enum_1 = require("@app/ps/enums/callbacks.enum");
var settings_service_1 = require("@app/ps/services/settings.service");
var table_service_1 = require("@app/ps/services/table.service");
var BulkOperationSelectEntitiesComponent = /** @class */ (function () {
    function BulkOperationSelectEntitiesComponent(pageableListService, componentFactoryResolver, tableService, settingsService, checklistService, processDefinition) {
        this.pageableListService = pageableListService;
        this.componentFactoryResolver = componentFactoryResolver;
        this.tableService = tableService;
        this.settingsService = settingsService;
        this.checklistService = checklistService;
        this.processDefinition = processDefinition;
        this.inverseSelection = false;
        this.toggleCheckItem = this.toggleCheckItem.bind(this);
        this.checkAll = this.checkAll.bind(this);
        this.sort = this.sort.bind(this);
    }
    BulkOperationSelectEntitiesComponent.prototype.ngOnInit = function () {
        this.checklist = new this.checklistService(!this.data.selectEntities ? undefined : this.data.selectEntities.checkedItems);
        this.inverseSelection = !this.data.selectEntities ? false : this.data.selectEntities.inverseSelection;
        this.initializeList();
    };
    BulkOperationSelectEntitiesComponent.prototype.checkAll = function () {
        this.inverseSelection = !this.inverseSelection;
        this.tableComponent.instance.inverseSelection = this.inverseSelection;
        this.checklist.empty();
        this.notifyParent();
    };
    BulkOperationSelectEntitiesComponent.prototype.toggleCheckItem = function (item) {
        this.checklist.toggleSelection(item);
        this.notifyParent();
    };
    BulkOperationSelectEntitiesComponent.prototype.notifyParent = function () {
        var _this = this;
        this.callbacks.get(callbacks_enum_1.Callbacks.DataValidityChanged)({
            isValid: function () { return _this.getSelectedCount() > 0; },
            data: {
                checkedItems: this.checklist.checkedItems,
                inverseSelection: this.inverseSelection,
                count: this.getSelectedCount(),
                filters: this.list.filter.filters,
            }
        });
    };
    BulkOperationSelectEntitiesComponent.prototype.getSelectedCount = function () {
        return this.inverseSelection ? this.list.itemCount - this.checklist.checkedItems.length : this.checklist.checkedItems.length;
    };
    BulkOperationSelectEntitiesComponent.prototype.sort = function (event) {
        this.pageableList.onSort(event.column, event.ascOrDesc);
    };
    BulkOperationSelectEntitiesComponent.prototype.initializeList = function () {
        this.pageableList = this.pageableListService.get(this.processDefinition.getBulkOperationId(), { filters: this.processDefinition.getDefaultFilters(this.settingsService) }, this.processDefinition.getEntityIds() === null ? this.processDefinition.getFilterLocalStorageName() : undefined);
        this.pageableList.disableLocalStorage();
        this.list = this.pageableList.list;
        this.pageableList.load();
        var factory = this.componentFactoryResolver
            .resolveComponentFactory(this.processDefinition.getTableComponent());
        this.tableComponent = factory
            .create(this.tableViewContainerRef.parentInjector);
        this.tableViewContainerRef.insert(this.tableComponent.hostView);
        this.tableComponent.instance.list = this.list;
        this.tableComponent.instance.isChecked = this.checklist.isChecked;
        this.tableComponent.instance.inverseSelection = this.inverseSelection;
        this.tableComponent.instance.checkAll.subscribe(this.checkAll);
        this.tableComponent.instance.sort.subscribe(this.sort);
        this.tableComponent.instance.toggleCheckItem.subscribe(this.toggleCheckItem);
    };
    BulkOperationSelectEntitiesComponent.prototype.pageChanged = function (pageNumber) {
        this.pageableList.fetchResults(pageNumber);
        this.pageableList.onListChanged();
    };
    return BulkOperationSelectEntitiesComponent;
}());
exports.BulkOperationSelectEntitiesComponent = BulkOperationSelectEntitiesComponent;
