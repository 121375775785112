"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var common_1 = require("@angular/common");
var array_utils_1 = require("../../utils/array.utils");
var MonthPickerOptionsInput = /** @class */ (function () {
    function MonthPickerOptionsInput() {
    }
    return MonthPickerOptionsInput;
}());
exports.MonthPickerOptionsInput = MonthPickerOptionsInput;
var MonthPickerComponent = /** @class */ (function () {
    function MonthPickerComponent(datePipe) {
        this.datePipe = datePipe;
        this.date = new core_1.EventEmitter();
        this.onChange = this.onChange.bind(this);
        this.onClickedOutside = this.onClickedOutside.bind(this);
    }
    MonthPickerComponent.prototype.ngOnInit = function () {
        if (this.options) {
            this.minDate = this.options.minDate;
            this.maxDate = this.options.maxDate;
            this.defaultDate = this.options.defaultDate;
        }
        else {
            this.minDate = new Date(new Date().getFullYear(), 0, 1);
            this.maxDate = new Date(new Date().getFullYear(), 11, 30);
            this.defaultDate = new Date();
        }
        this.year = this.defaultDate.getFullYear();
        this.years = array_utils_1.ArrayUtils.generateIntegerArray(this.minDate.getFullYear(), this.maxDate.getFullYear() + 1);
        this.month = this.defaultDate.getMonth();
        this.refreshMonths();
        this.monthPickerOpened = false;
        this.yearPickerOpened = false;
        this.date.emit(this.formatEvent());
    };
    MonthPickerComponent.prototype.onChange = function (value, type) {
        if (type === "month") {
            this.month = value;
        }
        else {
            this.year = value;
            this.refreshMonths();
        }
        this.date.emit(this.formatEvent());
    };
    MonthPickerComponent.prototype.onClickedOutside = function () {
        this.yearPickerOpened = false;
        this.monthPickerOpened = false;
    };
    MonthPickerComponent.prototype.getMonthNameFromNumber = function (month) {
        var date = new Date();
        date.setDate(1);
        date.setMonth(month);
        return this.datePipe.transform(date, "LLLL");
    };
    MonthPickerComponent.prototype.formatEvent = function () {
        var date = new Date();
        date.setDate(1);
        date.setMonth(this.month);
        date.setFullYear(this.year);
        return date;
    };
    MonthPickerComponent.prototype.refreshMonths = function () {
        var minYear = this.minDate.getFullYear();
        var maxYear = this.maxDate.getFullYear();
        var minMonth = this.minDate.getMonth();
        var maxMonth = this.maxDate.getMonth();
        if (minYear === maxYear) {
            this.months = array_utils_1.ArrayUtils.generateIntegerArray(minMonth, maxMonth + 1);
        }
        else {
            if (minYear === this.year) {
                this.months = array_utils_1.ArrayUtils.generateIntegerArray(minMonth, 12);
            }
            else if (maxYear === this.year) {
                this.months = array_utils_1.ArrayUtils.generateIntegerArray(0, maxMonth + 1);
            }
            else {
                this.months = array_utils_1.ArrayUtils.generateIntegerArray(0, 12);
            }
        }
        if (this.months.indexOf(this.month) === -1) {
            this.month = this.months[this.months.length - 1];
        }
    };
    return MonthPickerComponent;
}());
exports.MonthPickerComponent = MonthPickerComponent;
