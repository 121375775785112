"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("../project-subject/project-subject.component.ngfactory");
var i2 = require("../project-subject/project-subject.component");
var i3 = require("../../pipes/acting-person-name.pipe");
var i4 = require("../../../dialog/dialog.service");
var i5 = require("../../../common/components/button/button.component.ngfactory");
var i6 = require("../../../common/components/button/button.component");
var i7 = require("./project-subject-agent.component");
var i8 = require("../../../dialog/dialog-config");
var i9 = require("../../../dialog/dialog-ref");
var styles_ProjectSubjectAgentComponent = [];
var RenderType_ProjectSubjectAgentComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ProjectSubjectAgentComponent, data: {} });
exports.RenderType_ProjectSubjectAgentComponent = RenderType_ProjectSubjectAgentComponent;
function View_ProjectSubjectAgentComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Osoba zastupuj\u00EDc\u00ED v n\u00E1vrhu na vklad KN"])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "gmt-project-subject", [], null, null, null, i1.View_ProjectSubjectComponent_0, i1.RenderType_ProjectSubjectComponent)), i0.ɵdid(3, 114688, null, 0, i2.ProjectSubjectComponent, [i3.ActingPersonNamePipe, i4.DialogService], { subject: [0, "subject"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 6, "div", [["class", "text-center pt-20"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 2, "gmt-button", [["class", "button black"]], null, null, null, i5.View_ButtonComponent_0, i5.RenderType_ButtonComponent)), i0.ɵdid(6, 114688, null, 0, i6.ButtonComponent, ["ErrorHandlerService", i0.ChangeDetectorRef], { clickAction: [0, "clickAction"] }, null), (_l()(), i0.ɵted(-1, 0, [" Zru\u0161it zm\u011Bny "])), (_l()(), i0.ɵeld(8, 0, null, null, 2, "gmt-button", [["class", "button"]], null, null, null, i5.View_ButtonComponent_0, i5.RenderType_ButtonComponent)), i0.ɵdid(9, 114688, null, 0, i6.ButtonComponent, ["ErrorHandlerService", i0.ChangeDetectorRef], { clickAction: [0, "clickAction"] }, null), (_l()(), i0.ɵted(-1, 0, [" Pou\u017E\u00EDt zm\u011Bny "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.agent; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.onCancel; _ck(_v, 6, 0, currVal_1); var currVal_2 = _co.onSave; _ck(_v, 9, 0, currVal_2); }, null); }
exports.View_ProjectSubjectAgentComponent_0 = View_ProjectSubjectAgentComponent_0;
function View_ProjectSubjectAgentComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "project-subject-agent", [], null, null, null, View_ProjectSubjectAgentComponent_0, RenderType_ProjectSubjectAgentComponent)), i0.ɵdid(1, 114688, null, 0, i7.ProjectSubjectAgentComponent, [i8.DialogConfig, i9.DialogRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_ProjectSubjectAgentComponent_Host_0 = View_ProjectSubjectAgentComponent_Host_0;
var ProjectSubjectAgentComponentNgFactory = i0.ɵccf("project-subject-agent", i7.ProjectSubjectAgentComponent, View_ProjectSubjectAgentComponent_Host_0, {}, {}, []);
exports.ProjectSubjectAgentComponentNgFactory = ProjectSubjectAgentComponentNgFactory;
