"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var modules_service_1 = require("@app/common/services/modules.service");
var role_pipe_1 = require("@app/common/pipes/role.pipe");
var _ = require("lodash");
var UserDetailComponent = /** @class */ (function () {
    function UserDetailComponent(helpIds, APP_BRAND, APPLICATIONS, uiconstants, moduleService, rolePipe) {
        this.helpIds = helpIds;
        this.APP_BRAND = APP_BRAND;
        this.APPLICATIONS = APPLICATIONS;
        this.uiconstants = uiconstants;
        this.moduleService = moduleService;
        this.rolePipe = rolePipe;
        this.loading = true;
        this.sortOrder = { sortBy: 'roleName', direction: 'asc' };
        this.itemsPerPage = this.uiconstants.itemsPerPage10;
        this.dataPaging = { itemsPerPage: { id: 10, name: 10 } };
        this.roles = [];
    }
    UserDetailComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var e_1, _a, _b, allProjects, userProjectKeys_1, projects, _loop_1, this_1, projects_1, projects_1_1, p;
            var _this = this;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _b = this;
                        return [4 /*yield*/, this.moduleService.getUser(this.userId).then(function (d) { return d.plain(); })];
                    case 1:
                        _b.user = _c.sent();
                        if (!(this.APP_BRAND.NAME === 'RSD')) return [3 /*break*/, 2];
                        this.roles = this.user.ssoRoles
                            ? this.user.ssoRoles.map(function (r) {
                                return {
                                    roleName: _this.rolePipe.transform(r.role),
                                    isprofond: r.isprofond,
                                    projectKey: r.projectKey,
                                    projectName: r.projectName
                                };
                            })
                            : [];
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.moduleService.getProjects(this.APPLICATIONS.sy.name).then(function (d) { return d.plain(); })];
                    case 3:
                        allProjects = _c.sent();
                        userProjectKeys_1 = this.user.applicationProjects[this.APPLICATIONS.sy.name];
                        projects = allProjects.filter(function (p) { return userProjectKeys_1.includes(p.key); });
                        _loop_1 = function (p) {
                            var e_2, _a;
                            var roles = this_1.user.roles.filter(function (r) { return p.key === r.projectKey; });
                            if (roles.length) {
                                try {
                                    for (var roles_1 = __values(roles), roles_1_1 = roles_1.next(); !roles_1_1.done; roles_1_1 = roles_1.next()) {
                                        var r = roles_1_1.value;
                                        this_1.roles.push({
                                            roleName: this_1.rolePipe.transform(r.role),
                                            isprofond: p.isprofond,
                                            projectKey: p.key,
                                            projectName: p.name
                                        });
                                    }
                                }
                                catch (e_2_1) { e_2 = { error: e_2_1 }; }
                                finally {
                                    try {
                                        if (roles_1_1 && !roles_1_1.done && (_a = roles_1.return)) _a.call(roles_1);
                                    }
                                    finally { if (e_2) throw e_2.error; }
                                }
                            }
                            else {
                                this_1.roles.push({
                                    isprofond: p.isprofond,
                                    projectKey: p.key,
                                    projectName: p.name
                                });
                            }
                        };
                        this_1 = this;
                        try {
                            for (projects_1 = __values(projects), projects_1_1 = projects_1.next(); !projects_1_1.done; projects_1_1 = projects_1.next()) {
                                p = projects_1_1.value;
                                _loop_1(p);
                            }
                        }
                        catch (e_1_1) { e_1 = { error: e_1_1 }; }
                        finally {
                            try {
                                if (projects_1_1 && !projects_1_1.done && (_a = projects_1.return)) _a.call(projects_1);
                            }
                            finally { if (e_1) throw e_1.error; }
                        }
                        _c.label = 4;
                    case 4:
                        this.initList();
                        this.loading = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    UserDetailComponent.prototype.pagesCounterCallbackRegister = function (event) {
        this.onListChangedPagesCounter = event.onListChanged;
    };
    UserDetailComponent.prototype.itemCounterCallbackRegister = function (event) {
        this.onListChangedItemCounter = event.onListChanged;
    };
    UserDetailComponent.prototype.paginationCallbackRegister = function (event) {
        this.onListChangedPagination = event.onListChanged;
    };
    UserDetailComponent.prototype.onSort = function (sortBy) {
        this.sortOrder.direction = sortBy === this.sortOrder.sortBy
            ? this.sortOrder.direction === 'asc' ? 'desc' : 'asc'
            : 'asc';
        this.sortOrder.sortBy = sortBy;
        this.reloadList();
    };
    UserDetailComponent.prototype.onPageChanged = function (event) {
        this.list.filter.offset = (event - 1) * this.dataPaging.itemsPerPage.id;
        this.reloadList();
    };
    UserDetailComponent.prototype.onPageItemsChanged = function () {
        this.list.filter.offset = 0;
        this.reloadList();
    };
    UserDetailComponent.prototype.onListChanged = function () {
        if (this.onListChangedPagination) {
            this.onListChangedPagination();
        }
        if (this.onListChangedItemCounter) {
            this.onListChangedItemCounter();
        }
        if (this.onListChangedPagesCounter) {
            this.onListChangedPagesCounter();
        }
    };
    UserDetailComponent.prototype.reloadList = function () {
        this.roles = _.orderBy(this.roles, this.sortOrder.sortBy, this.sortOrder.direction);
        this.list.list = this.roles.slice(this.list.filter.offset, this.list.filter.offset + this.dataPaging.itemsPerPage.id);
        this.list.itemCount = this.roles.length;
        this.list.filter.limit = this.dataPaging.itemsPerPage.id;
        this.onListChanged();
    };
    UserDetailComponent.prototype.initList = function () {
        this.list = {
            loading: false,
            list: this.roles.slice(0, this.dataPaging.itemsPerPage.id),
            itemCount: this.roles.length,
            filter: { offset: 0, limit: this.dataPaging.itemsPerPage.id }
        };
    };
    return UserDetailComponent;
}());
exports.UserDetailComponent = UserDetailComponent;
