"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var geometric_plan_request_detail_component_1 = require("@app/ps/geometric-plans/components/geometric-plan-request-detail/geometric-plan-request-detail.component");
var geometric_plan_request_create_component_1 = require("@app/ps/geometric-plans/components/geometric-plan-request-create/geometric-plan-request-create.component");
var geometric_plan_request_list_component_1 = require("@app/ps/geometric-plans/components/geometric-plan-request-list/geometric-plan-request-list.component");
var geometric_plan_request_create_from_existing_component_1 = require("@app/ps/geometric-plans/components/geometric-plan-request-create-from-existing/geometric-plan-request-create-from-existing.component");
function resolveGeometricPlan(restangular, $stateParams) {
    return restangular
        .one('geometric-plan-requests', $stateParams.id)
        .get({ loadCollections: ['areas', 'constructionObjects', 'parcels'] });
}
exports.resolveGeometricPlan = resolveGeometricPlan;
function resolveNoteTypes(restangular) {
    return restangular
        .all('note-types')
        .customPOST({ filter: { filters: { dialogTypes: ['GeometricPlanRequest'] } } })
        .then(function (response) { return response.plain(); });
}
exports.resolveNoteTypes = resolveNoteTypes;
exports.geometricPlanRequestListState = {
    name: 'symap.project.geometricPlanRequests',
    url: '/geometric-plan-requests?page',
    data: {
        title: 'Geometrické plány',
    },
    params: {
        page: {
            dynamic: true,
            type: 'int',
            inherit: false,
        },
    },
    views: {
        'content@symap.project': {
            component: geometric_plan_request_list_component_1.GeometricPlanRequestListComponent,
        },
    },
};
exports.geometricPlanRequestCreateState = {
    name: 'symap.project.geometricPlanRequests.create',
    url: '/new',
    data: {
        title: 'Nový geometrický plán',
    },
    views: {
        'content@symap.project': {
            component: geometric_plan_request_create_component_1.GeometricPlanRequestCreateComponent,
        },
    },
    resolve: {
        noteTypes: ['Restangular', resolveNoteTypes],
    },
};
exports.geometricPlanRequestCreateFromExistingState = {
    name: 'symap.project.geometricPlanRequests.createFromExisting',
    url: '/new-from-existing',
    data: {
        title: 'Nový geometrický plán',
    },
    views: {
        'content@symap.project': {
            component: geometric_plan_request_create_from_existing_component_1.GeometricPlanRequestCreateFromExistingComponent,
        },
    },
};
exports.geometricPlanRequestDetailState = {
    name: 'symap.project.geometricPlanRequests.detail',
    url: '/:id/:tab',
    data: {
        title: 'Detail geometrického plánu'
    },
    params: {
        id: {
            type: 'int',
        },
        tab: {
            type: 'string',
            value: undefined,
            dynamic: true,
            inherit: false,
            squash: true,
        },
    },
    views: {
        'content@symap.project': {
            component: geometric_plan_request_detail_component_1.GeometricPlanRequestDetailComponent,
        },
    },
    resolve: {
        geometricPlanRequest: ['Restangular', '$stateParams', resolveGeometricPlan],
        noteTypes: ['Restangular', resolveNoteTypes],
    },
};
