"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var i0 = require("@angular/core");
var SeriesNamePipe = /** @class */ (function () {
    function SeriesNamePipe() {
    }
    SeriesNamePipe.prototype.transform = function (series) {
        return series.nextNumber;
    };
    SeriesNamePipe.ngInjectableDef = i0.defineInjectable({ factory: function SeriesNamePipe_Factory() { return new SeriesNamePipe(); }, token: SeriesNamePipe, providedIn: "root" });
    return SeriesNamePipe;
}());
exports.SeriesNamePipe = SeriesNamePipe;
