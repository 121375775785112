"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./cadastre-data-import.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../cadastre-data-import-form/cadastre-data-import-form.component.ngfactory");
var i3 = require("../cadastre-data-import-form/cadastre-data-import-form.component");
var i4 = require("../../../../common/services/promap-restangular.service");
var i5 = require("../cadastre-data-import-list/cadastre-data-import-list.component.ngfactory");
var i6 = require("../cadastre-data-import-list/cadastre-data-import-list.component");
var i7 = require("../../../../common/services/list.service");
var i8 = require("./cadastre-data-import.component");
var styles_CadastreDataImportComponent = [i0.styles];
var RenderType_CadastreDataImportComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CadastreDataImportComponent, data: {} });
exports.RenderType_CadastreDataImportComponent = RenderType_CadastreDataImportComponent;
function View_CadastreDataImportComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "cadastre-data-import-form", [], null, [[null, "importAdded"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("importAdded" === en)) {
        var pd_0 = (_co.onImportAdded($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_CadastreDataImportFormComponent_0, i2.RenderType_CadastreDataImportFormComponent)), i1.ɵdid(1, 114688, null, 0, i3.CadastreDataImportFormComponent, [i4.RESTANGULAR_PROMAP], null, { importAdded: "importAdded" }), (_l()(), i1.ɵeld(2, 0, null, null, 1, "cadastre-data-import-list", [], null, null, null, i5.View_CadastreDataImportListComponent_0, i5.RenderType_CadastreDataImportListComponent)), i1.ɵdid(3, 114688, null, 0, i6.CadastreDataImportListComponent, [i1.ChangeDetectorRef, i7.ListService, i4.RESTANGULAR_PROMAP], { newImport: [0, "newImport"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_0 = _co.newImport; _ck(_v, 3, 0, currVal_0); }, null); }
exports.View_CadastreDataImportComponent_0 = View_CadastreDataImportComponent_0;
function View_CadastreDataImportComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "cadastre-data-import", [], null, null, null, View_CadastreDataImportComponent_0, RenderType_CadastreDataImportComponent)), i1.ɵdid(1, 114688, null, 0, i8.CadastreDataImportComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_CadastreDataImportComponent_Host_0 = View_CadastreDataImportComponent_Host_0;
var CadastreDataImportComponentNgFactory = i1.ɵccf("cadastre-data-import", i8.CadastreDataImportComponent, View_CadastreDataImportComponent_Host_0, {}, {}, []);
exports.CadastreDataImportComponentNgFactory = CadastreDataImportComponentNgFactory;
