"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var L = require("leaflet");
var proj4_1 = require("proj4");
var i0 = require("@angular/core");
var MapUtilsCrsService = /** @class */ (function () {
    function MapUtilsCrsService() {
        this.leafletSrids = {
            3857: L.CRS.EPSG3857,
            4326: L.CRS.EPSG4326,
            3395: L.CRS.EPSG3395
        };
        this.setLeafletSrid = this.setLeafletSrid.bind(this);
        this.getLeafletSrid = this.getLeafletSrid.bind(this);
    }
    MapUtilsCrsService.prototype.getCrs = function (sridId) {
        return this.getLeafletSrid(sridId);
    };
    MapUtilsCrsService.prototype.setProj4Srid = function (projId, crsDef) {
        try {
            proj4_1.default('urn:ogc:def:crs:EPSG::' + projId);
        }
        catch (e) {
            proj4_1.default.defs('urn:ogc:def:crs:EPSG::' + projId, crsDef);
        }
    };
    MapUtilsCrsService.prototype.setLeafletSrid = function (sridId, sridDefs) {
        this.leafletSrids[sridId] = sridDefs;
    };
    MapUtilsCrsService.prototype.getLeafletSrid = function (sridId) {
        return this.leafletSrids[sridId];
    };
    MapUtilsCrsService.ngInjectableDef = i0.defineInjectable({ factory: function MapUtilsCrsService_Factory() { return new MapUtilsCrsService(); }, token: MapUtilsCrsService, providedIn: "root" });
    return MapUtilsCrsService;
}());
exports.MapUtilsCrsService = MapUtilsCrsService;
