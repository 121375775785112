"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var parcel_number_pipe_1 = require("@app/common/pipes/parcel-number.pipe");
var i0 = require("@angular/core");
var i1 = require("./parcel-number.pipe");
var BuildingNamePipe = /** @class */ (function () {
    function BuildingNamePipe(parcelNumberPipe) {
        this.parcelNumberPipe = parcelNumberPipe;
    }
    BuildingNamePipe.prototype.transform = function (building) {
        if (!building) {
            return '-';
        }
        var cp = building.cisloDomovni
            ? 'č.p. ' + building.cisloDomovni
            : 'bez č.p.';
        var parcels = '';
        if (building.parcels && building.parcels.length) {
            var parts = [
                'na parcel' + (building.parcels.length > 1 ? 'ách' : 'e'),
                this.parcelNumberPipe.transform(building.parcels[0]),
            ];
            if (building.parcels.length > 1) {
                parts.push('(+' + (parcels.length - 1) + ')');
            }
            parcels = parts.join(' ');
        }
        return (cp + ' ' + parcels);
    };
    BuildingNamePipe.ngInjectableDef = i0.defineInjectable({ factory: function BuildingNamePipe_Factory() { return new BuildingNamePipe(i0.inject(i1.ParcelNumberPipe)); }, token: BuildingNamePipe, providedIn: "root" });
    return BuildingNamePipe;
}());
exports.BuildingNamePipe = BuildingNamePipe;
