"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Callbacks;
(function (Callbacks) {
    Callbacks["ProblematicNoteChanged"] = "problematic-note-changed";
    Callbacks["DataValidityChanged"] = "data-validity-changed";
    Callbacks["CaseUpdated"] = "caseUpdated";
    Callbacks["CaseSubjectUpdated"] = "caseSubjectUpdated";
    Callbacks["CaseOwnershipUpdated"] = "caseOwnershipUpdated";
    Callbacks["SubjectUpdated"] = "subjectUpdated";
    Callbacks["RepresentativesUpdated"] = "representativesUpdated";
    Callbacks["ConstructionObjectUpdated"] = "constructionObjectUpdated";
})(Callbacks = exports.Callbacks || (exports.Callbacks = {}));
