"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var parcel_number_pipe_1 = require("@app/common/pipes/parcel-number.pipe");
var title_name_pipe_1 = require("@app/common/pipes/title-name.pipe");
var opinion_request_model_1 = require("@app/ps/models/opinion-request.model");
var angular_1 = require("@uirouter/angular");
var opinion_request_service_1 = require("@app/ps/services/opinion-request.service");
var building_name_pipe_1 = require("@app/common/pipes/building-name.pipe");
var opinion_request_status_enum_1 = require("@app/ps/enums/opinion-request-status.enum");
var external_parcel_form_component_1 = require("@app/ps/components/external-parcel-form/external-parcel-form.component");
var class_name_enum_1 = require("@app/dialog/class-name.enum");
var dialog_service_1 = require("@app/dialog/dialog.service");
var checklist_service_1 = require("@app/common/services/checklist.service");
var geometric_plan_request_status_enum_1 = require("@app/ps/enums/geometric-plan-request-status.enum");
var OpinionRequestCreateComponent = /** @class */ (function () {
    function OpinionRequestCreateComponent(requestService, titleNamePipe, parcelNumberPipe, buildingNamePipe, stateService, dialogService, checklistService, restangular) {
        this.requestService = requestService;
        this.titleNamePipe = titleNamePipe;
        this.parcelNumberPipe = parcelNumberPipe;
        this.buildingNamePipe = buildingNamePipe;
        this.stateService = stateService;
        this.dialogService = dialogService;
        this.checklistService = checklistService;
        this.restangular = restangular;
        this.PARCEL_COLS = [
            { id: 'parcel', sortable: true },
            { id: 'title', sortable: true },
            { id: 'area_name', sortable: true },
            { id: 'area', sortable: false },
        ];
        this.opinionRequest = new opinion_request_model_1.OpinionRequestModel();
        this.similarOpinionRequestExists = false;
        this.buildings = [];
        this.gpConcept = false;
        this.externalParcelListAdded = { loading: false, list: [], itemCount: 0 };
        this.checkedAll = false;
        this.checkedAllAdded = false;
        this.titleFilter = {
            validity: { values: ['valid'] },
            areaId: { values: [] },
        };
        this.parcelFilter = {
            validity: { values: ['valid'] },
            areaId: { values: [] },
            titleId: { values: [] },
        };
        this.buildingFilter = {
            validity: ['valid'],
            occupationOrEasementExists: [true],
            titleRelatedId: { values: [] },
            loadCollections: ['parcelsWithEasementOrOccupation'],
        };
        this.geometricPlanRequestFilter = {
            status: { values: [
                    geometric_plan_request_status_enum_1.GeometricPlanRequestStatusEnum.CONCEPT_APPROVED,
                    geometric_plan_request_status_enum_1.GeometricPlanRequestStatusEnum.SENT_TO_CONFIRMATION
                ] },
        };
        this.onSubmit = this.onSubmit.bind(this);
        this.onAddParcel = this.onAddParcel.bind(this);
        this.isCheckedAdded = this.isCheckedAdded.bind(this);
    }
    OpinionRequestCreateComponent.prototype.ngOnInit = function () {
        this.checklistExternalParcelsAdded = this.checklistService.get();
    };
    OpinionRequestCreateComponent.prototype.isValid = function () {
        return !!(this.opinionRequest.name
            && this.opinionRequest.title
            && (this.gpConcept
                ||
                    (this.opinionRequest.parcels && this.opinionRequest.parcels.length)
                ||
                    (this.opinionRequest.buildings && this.opinionRequest.buildings.length))
            && (!this.gpConcept
                ||
                    (this.opinionRequest.geometricPlanRequest && this.checklistExternalParcelsAdded.checkedItems.length > 0)));
    };
    OpinionRequestCreateComponent.prototype.onCadastreChanged = function () {
        this.parcelFilter.areaId.values = [this.area.id];
        this.titleFilter.areaId.values = [this.area.id];
        this.opinionRequest.title = undefined;
        this.opinionRequest.parcels = undefined;
        this.opinionRequest.buildings = undefined;
    };
    OpinionRequestCreateComponent.prototype.onTitleChanged = function () {
        this.parcelFilter.titleId.values = [this.opinionRequest.title.id];
        this.buildingFilter.titleRelatedId.values = [this.opinionRequest.title.id];
        this.opinionRequest.parcels = undefined;
        this.opinionRequest.buildings = undefined;
        this.reloadBuildings();
    };
    OpinionRequestCreateComponent.prototype.onParcelsChanged = function () {
        this.checkExistingSimilarOpinionRequest();
    };
    OpinionRequestCreateComponent.prototype.onBuildingsChanged = function () {
        this.checkExistingSimilarOpinionRequest();
    };
    OpinionRequestCreateComponent.prototype.onAddParcel = function () {
        var _this = this;
        var dialog = this.dialogService.open(external_parcel_form_component_1.ExternalParcelFormComponent, {
            data: { enablePricing: false, areaSelect: true },
            className: class_name_enum_1.ClassName.HIGHER_DIALOG
        });
        var subscriber = dialog.afterClosed.subscribe(function (parcel) {
            if (parcel) {
                _this.externalParcelListAdded.list = [parcel].concat(_this.externalParcelListAdded.list);
                _this.externalParcelListAdded.itemCount++;
                _this.checklistExternalParcelsAdded.toggleSelection(parcel);
            }
            subscriber.unsubscribe();
        });
    };
    OpinionRequestCreateComponent.prototype.isCheckedAdded = function (parcel) {
        return this.checklistExternalParcelsAdded.isChecked(parcel);
    };
    OpinionRequestCreateComponent.prototype.onToggleAllAdded = function () {
        var _this = this;
        this.checkedAllAdded = !this.checkedAllAdded;
        this.externalParcelListAdded.list.forEach(function (parcel) {
            if (!(_this.checkedAllAdded && _this.checklistExternalParcelsAdded.isChecked(parcel))) {
                _this.checklistExternalParcelsAdded.toggleSelection(parcel);
            }
        });
    };
    OpinionRequestCreateComponent.prototype.onToggleOneAdded = function (parcel) {
        this.checklistExternalParcelsAdded.toggleSelection(parcel);
        this.checkedAllAdded = (this.externalParcelListAdded.list.length === this.checklistExternalParcelsAdded.checkedItems);
    };
    OpinionRequestCreateComponent.prototype.onSubmit = function () {
        var _this = this;
        if (this.checklistExternalParcelsAdded.checkedItems.length) {
            this.opinionRequest.parcels = this.opinionRequest.parcels
                ? this.opinionRequest.parcels.concat(this.checklistExternalParcelsAdded.checkedItems)
                : this.checklistExternalParcelsAdded.checkedItems;
        }
        return this.requestService
            .create(this.opinionRequest)
            .then(function (opinionRequest) {
            _this.stateService.go('symap.project.opinionRequests.detail', { id: opinionRequest.id, tab: 'parcels' });
        });
    };
    OpinionRequestCreateComponent.prototype.checkExistingSimilarOpinionRequest = function () {
        var _this = this;
        if ((!this.opinionRequest.parcels || !this.opinionRequest.parcels.length)
            && (!this.opinionRequest.buildings || !this.opinionRequest.buildings.length)) {
            this.similarOpinionRequestExists = false;
            return;
        }
        var parcelIds = this.opinionRequest.parcels ? this.opinionRequest.parcels.map(function (p) { return p.id; }) : [];
        var buildingIds = this.opinionRequest.buildings ? this.opinionRequest.buildings.map(function (b) { return b.id; }) : [];
        var filter = { filter: { filters: {
                    parcelId: parcelIds,
                    buildingId: buildingIds,
                } } };
        this.restangular
            .all('opinion-requests').customPOST(filter)
            .then(function (data) {
            var requests = data.plain().filter(function (or) { return or.status !== opinion_request_status_enum_1.OpinionRequestStatusEnum.DELETED; });
            _this.similarOpinionRequestExists = requests.length > 0;
        });
    };
    OpinionRequestCreateComponent.prototype.reloadBuildings = function () {
        var _this = this;
        this.buildings = [];
        var title = this.opinionRequest.title;
        if (!title) {
            return;
        }
        this.restangular.all('buildings').customPOST({ filter: { filters: this.buildingFilter } })
            .then(function (data) {
            data.plain().forEach(function (building) {
                building.parcels.forEach(function (parcel) {
                    if (building.title.id === title.id && parcel.title.id !== title.id) {
                        _this.buildings.push(building);
                    }
                });
            });
        });
    };
    return OpinionRequestCreateComponent;
}());
exports.OpinionRequestCreateComponent = OpinionRequestCreateComponent;
