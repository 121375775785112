"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var request_status_enum_1 = require("./../../enums/request-status.enum");
var core_1 = require("@angular/core");
var pageable_list_service_1 = require("@app/common/services/pageable.list.service");
var common_1 = require("@angular/common");
var moment = require("moment");
var ProjectsComponent = /** @class */ (function () {
    function ProjectsComponent(pageableListService, datePipe, helpIds) {
        this.pageableListService = pageableListService;
        this.datePipe = datePipe;
        this.helpIds = helpIds;
        this.monthPickerOptions = {
            minDate: moment().subtract(2, 'year').toDate(),
            maxDate: moment().add(1, 'month').toDate(),
            defaultDate: moment().add(1, 'month').toDate()
        };
        this.filterValidity = false;
        this.statusOptions = request_status_enum_1.requestStatusOptions;
        this.uiOnParamsChanged = this.uiOnParamsChanged.bind(this);
    }
    ProjectsComponent.prototype.ngOnInit = function () {
        this.initializeProjectList();
    };
    ProjectsComponent.prototype.onValidDateChanged = function (date) {
        this.validityDate = date;
        this.setFilter();
    };
    ProjectsComponent.prototype.onFilterValidityChange = function () {
        this.filterValidity = !this.filterValidity;
        this.setFilter();
    };
    ProjectsComponent.prototype.initializeProjectList = function () {
        var filters = {
            filters: {
                searchText: { values: [{ id: '' }] },
                validity: null,
                status: { values: [] }
            }
        };
        this.pageableList = this.pageableListService.get('project/list', filters);
        this.list = this.pageableList.list;
        this.pageableList.load();
    };
    ProjectsComponent.prototype.uiOnParamsChanged = function (changedParams, $transition$) {
        this.pageableList.uiOnParamsChanged(changedParams, $transition$);
    };
    ProjectsComponent.prototype.setFilter = function () {
        if (this.filterValidity) {
            this.list.filter.filters.validity = this.datePipe.transform(this.validityDate, 'yyyy-MM-dd');
        }
        else {
            this.list.filter.filters.validity = undefined;
        }
    };
    return ProjectsComponent;
}());
exports.ProjectsComponent = ProjectsComponent;
