"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var MultiSceneCloudService = /** @class */ (function () {
    function MultiSceneCloudService() {
        this.yaw = 4.8; // seems to be the front direction
        this.pitch = 0;
    }
    MultiSceneCloudService.ngInjectableDef = i0.defineInjectable({ factory: function MultiSceneCloudService_Factory() { return new MultiSceneCloudService(); }, token: MultiSceneCloudService, providedIn: "root" });
    return MultiSceneCloudService;
}());
exports.MultiSceneCloudService = MultiSceneCloudService;
