"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var $ = require("jquery");
var perfect_scrollbar_1 = require("perfect-scrollbar");
var resize_observer_1 = require("@juggle/resize-observer");
var PerfectScrollbarComponent = /** @class */ (function () {
    function PerfectScrollbarComponent(elementRef, differs, ngZone) {
        this.elementRef = elementRef;
        this.differs = differs;
        this.ngZone = ngZone;
        this.horizontal = false;
        this.callbackRegister = new core_1.EventEmitter(); // function called that parent scope can scroll to top
        this.scrollTop = this.scrollTop.bind(this);
        this.updateScrollbar = this.updateScrollbar.bind(this);
        this.update = this.update.bind(this);
        this.doResizeCheck = this.doResizeCheck.bind(this);
    }
    PerfectScrollbarComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.horizontal = this.horizontal !== false;
        this.scrollableElement = $(this.elementRef.nativeElement.querySelector('div.scrollable'));
        this.scrollableInnerElement = $(this.elementRef.nativeElement.querySelector('div.scrollable-inner'));
        this.ngZone.runOutsideAngular(function () {
            _this.ps = new perfect_scrollbar_1.default(_this.scrollableElement[0], {
                wheelSpeed: _this.wheelSpeed || 2,
                wheelPropagation: _this.wheelPropagation || false,
                minScrollbarLength: _this.minScrollbarLength || null,
                suppressScrollY: _this.horizontal,
            });
            $(window).bind('resize', function () {
                _this.update({
                    widthContainer: _this.scrollableElement.width(),
                    heightContainer: _this.scrollableElement.height(),
                    widthContent: _this.scrollableInnerElement.width(),
                    heightContent: _this.scrollableInnerElement.height()
                });
            });
            new resize_observer_1.default(_this.doResizeCheck).observe(_this.scrollableElement[0]);
            new resize_observer_1.default(_this.doResizeCheck).observe(_this.scrollableInnerElement[0]);
        });
        this.differ = this.differs.find(this.getDiffObject()).create();
        this.callbackRegister.emit({
            scrollTop: this.scrollTop,
            updateScrollbar: this.updateScrollbar
        });
    };
    PerfectScrollbarComponent.prototype.doResizeCheck = function () {
        var diffObject = this.getDiffObject();
        var change = this.differ.diff(diffObject);
        if (change) {
            this.update(diffObject);
        }
    };
    PerfectScrollbarComponent.prototype.scrollTop = function () {
        this.scrollableElement.scrollTop(0);
    };
    PerfectScrollbarComponent.prototype.updateScrollbar = function () {
        this.ps.update();
    };
    PerfectScrollbarComponent.prototype.update = function (newValue) {
        var _this = this;
        if (newValue.widthContainer > 0 && newValue.heightContainer > 0 && newValue.widthContent > 0 && newValue.heightContent > 0) {
            setTimeout(function () {
                _this.ps.update();
                _this.ps.update();
            });
            // when content gets smaller but container is scolled to bottom
            if (newValue.heightContainer + $(this.scrollableElement).scrollTop() > newValue.heightContent) {
                this.scrollableElement.scrollTop(Math.max(0, newValue.heightContent - newValue.heightContainer));
            }
            if (newValue.widthContainer + $(this.scrollableElement).scrollLeft() > newValue.widthContent) {
                this.scrollableElement.scrollLeft(Math.max(0, newValue.widthContent - newValue.widthContainer));
            }
        }
    };
    PerfectScrollbarComponent.prototype.getDiffObject = function () {
        return {
            widthContainer: this.scrollableElement.width(),
            heightContainer: this.scrollableElement.height(),
            widthContent: this.scrollableInnerElement.width(),
            heightContent: this.scrollableInnerElement.height()
        };
    };
    return PerfectScrollbarComponent;
}());
exports.PerfectScrollbarComponent = PerfectScrollbarComponent;
