"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
Object.defineProperty(exports, "__esModule", { value: true });
var platform_browser_1 = require("@angular/platform-browser");
var core_1 = require("@angular/core");
var angular_1 = require("@uirouter/angular");
var rxjs_1 = require("rxjs");
var auth_service_1 = require("@app/common/services/auth.service");
var http_1 = require("@angular/common/http");
var LoginComponent = /** @class */ (function () {
    function LoginComponent(APP_BRAND, applications, authService, stateService, titleService, httpClient) {
        this.APP_BRAND = APP_BRAND;
        this.applications = applications;
        this.authService = authService;
        this.stateService = stateService;
        this.titleService = titleService;
        this.httpClient = httpClient;
        this.user = {
            username: '',
            password: '',
        };
        this.autofilled = false;
        this.sending = false;
        this.invalid = false;
        this.errMsg = '';
        this.servicedeskUrl = this.authService.getServiceDeskUrl();
        this.onSubmitCredentials = this.onSubmitCredentials.bind(this);
    }
    LoginComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var e_1, msg, isWebkit, checkInterval, subscriber_1;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.redirectUrl = this.stateService.params.redirectUrl;
                        this.loggedOut = this.stateService.params.loggedOut === 'true';
                        this.showLogin = this.stateService.params.secret !== undefined || this.APP_BRAND.LOGIN.SSO === '';
                        this.titleService.setTitle(this.loggedOut ? 'Odhlášení' : 'Přihlášení');
                        if (this.APP_BRAND.NAME === 'RSD' || this.APP_BRAND.NAME === 'SZ') {
                            this.authService.setActiveApplication(this.applications.landing.name);
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.loginBySsoToken()];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _a.sent();
                        console.log(e_1);
                        msg = 'Při ověřování přihlášení SSO došlo k chybě. Kontaktujte podporu.';
                        if (this.APP_BRAND.NAME === 'RSD') {
                            msg = 'Přihlášení uživatele do MPV se nezdařilo. Nemáte přidělen přístup do systému MPV.';
                        }
                        this.setErrorMessage(msg);
                        return [3 /*break*/, 4];
                    case 4:
                        isWebkit = 'WebkitAppearance' in document.documentElement.style;
                        if (isWebkit) {
                            checkInterval = rxjs_1.interval(100);
                            subscriber_1 = checkInterval.subscribe(function () {
                                try {
                                    __spread(document.querySelectorAll('input:-webkit-autofill')).forEach(function (elm) {
                                        _this.autofilled = true;
                                        var value = elm.value;
                                        if (value) {
                                            _this.user.password = (value);
                                            subscriber_1.unsubscribe();
                                        }
                                    });
                                }
                                catch (e) { }
                            });
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    LoginComponent.prototype.setErrorMessage = function (msg) {
        this.sending = false;
        this.invalid = true;
        this.errMsg = msg || 'Chybné přihlašovací údaje, opakujte zadání.';
    };
    LoginComponent.prototype.onSubmitCredentials = function (username, password) {
        var _this = this;
        if (typeof username !== 'string' || typeof password !== 'string') {
            return;
        }
        this.sending = true;
        this.invalid = false;
        this.errMsg = '';
        return this.authService.login(username, password, undefined)
            .then(function () {
            var e_2, _a, e_3, _b;
            if (_this.redirectUrl) {
                var states = _this.stateService.get();
                var originUrl = _this.redirectUrl.split('?')[0];
                var fakeUrl = "https://a.cz" + _this.redirectUrl;
                var search = {};
                try {
                    for (var _c = __values(new URL(fakeUrl).searchParams.entries()), _d = _c.next(); !_d.done; _d = _c.next()) {
                        var _e = __read(_d.value, 2), key = _e[0], value = _e[1];
                        search[key] = value;
                    }
                }
                catch (e_2_1) { e_2 = { error: e_2_1 }; }
                finally {
                    try {
                        if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
                    }
                    finally { if (e_2) throw e_2.error; }
                }
                try {
                    for (var states_1 = __values(states), states_1_1 = states_1.next(); !states_1_1.done; states_1_1 = states_1.next()) {
                        var state = states_1_1.value;
                        var privatePortion = state.$$state();
                        if (!privatePortion.url) {
                            continue;
                        }
                        var match = privatePortion.url.exec(originUrl, search);
                        if (match) {
                            return _this.stateService.go(state.name, match);
                        }
                    }
                }
                catch (e_3_1) { e_3 = { error: e_3_1 }; }
                finally {
                    try {
                        if (states_1_1 && !states_1_1.done && (_b = states_1.return)) _b.call(states_1);
                    }
                    finally { if (e_3) throw e_3.error; }
                }
            }
            if (!_this.authService.goToActiveAplication()) {
                var msg = 'Při přihlašování došlo k chybě. Kontaktujte podporu.';
                _this.setErrorMessage(msg);
            }
        }, function (err) {
            _this.setErrorMessage(err.msg);
        });
    };
    LoginComponent.prototype.loginBySsoToken = function () {
        return __awaiter(this, void 0, void 0, function () {
            var msg;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.APP_BRAND.LOGIN || !this.APP_BRAND.LOGIN.SSO) {
                            return [2 /*return*/];
                        }
                        this.sending = true;
                        if (!(this.APP_BRAND.LOGIN.SSO_OPTIONS && this.APP_BRAND.LOGIN.SSO_OPTIONS.TYPE === 'IDENTITY_SERVER')) return [3 /*break*/, 2];
                        // ochrana proti únosu spojení
                        if (sessionStorage.getItem('state') !== this.stateService.params.state ||
                            !this.stateService.params.code ||
                            !this.stateService.params.state) {
                            return [2 /*return*/];
                        }
                        // interně získá token z identity serveru a vrátí gmt token
                        return [4 /*yield*/, this.authService.login(undefined, undefined, undefined, this.stateService.params.code, sessionStorage.getItem('code_verifier'), this.APP_BRAND.LOGIN.SSO_OPTIONS.REDIRECT_URI)];
                    case 1:
                        // interně získá token z identity serveru a vrátí gmt token
                        _a.sent();
                        return [3 /*break*/, 6];
                    case 2:
                        if (!(this.APP_BRAND.LOGIN.SSO_OPTIONS && this.APP_BRAND.LOGIN.SSO_OPTIONS.TYPE === 'KEYCLOAK')) return [3 /*break*/, 4];
                        console.log(this.stateService.params.code);
                        if (!this.stateService.params.code) {
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, this.authService.login(undefined, undefined, undefined, this.stateService.params.code, undefined, undefined, undefined)];
                    case 3:
                        _a.sent();
                        return [3 /*break*/, 6];
                    case 4:
                        if (!this.stateService.params.SAMLart) {
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, this.authService.login(undefined, undefined, this.stateService.params.SAMLart, undefined, undefined, undefined, this.stateService.params.ssoLogoutToken)];
                    case 5:
                        _a.sent();
                        _a.label = 6;
                    case 6:
                        if (!this.authService.goToActiveAplication()) {
                            msg = 'Při přihlašování došlo k chybě. Kontaktujte podporu.';
                            this.setErrorMessage(msg);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    return LoginComponent;
}());
exports.LoginComponent = LoginComponent;
