"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".map-symbol__stroke[_ngcontent-%COMP%] {\n  align-items: center;\n  border-width: 4px;\n  border-style: solid;\n  display: flex;\n  height: 20px;\n  justify-content: center;\n  width: 20px; }\n\n.map-symbol__fill[_ngcontent-%COMP%] {\n  height: 12px;\n  width: 12px; }\n\n.description[_ngcontent-%COMP%] {\n  color: #909090; }"];
exports.styles = styles;
