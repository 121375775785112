"use strict";
var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var CellCaseSubjectsComponent = /** @class */ (function () {
    function CellCaseSubjectsComponent() {
        this.subjectsToShow = [];
        this.progress = 0;
    }
    CellCaseSubjectsComponent.prototype.ngOnInit = function () {
        var e_1, _a;
        if (!(this.caseSubjects
            && this.caseStatus
            && this.lastCaseChangeDate
            && Object.keys(CellCaseSubjectsComponent.STATUS_FIELD_MAP).includes(this.caseStatus.key))) {
            return;
        }
        try {
            for (var _b = __values(this.caseSubjects), _c = _b.next(); !_c.done; _c = _b.next()) {
                var caseSubject = _c.value;
                var subjectDate = caseSubject[CellCaseSubjectsComponent.STATUS_FIELD_MAP[this.caseStatus.key]];
                var signed = !!caseSubject[CellCaseSubjectsComponent.STATUS_FIELD_MAP.SignedOwner];
                if (this.lastCaseChangeDate === subjectDate) {
                    this.subjectsToShow.push(caseSubject.subject);
                }
                if (this.lastCaseChangeDate >= subjectDate || signed) {
                    this.progress++;
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
    };
    CellCaseSubjectsComponent.STATUS_FIELD_MAP = {
        SentOwner: 'sentDate',
        ReceivedOwner: 'receivedDate',
        DeclinedOwner: 'declinedDate',
        PersonalVisit: 'personalVisitDate',
        SignedOwner: 'signedDate',
        DunningLetter: 'dunningLetterDate',
        CallBeforeExpropriation: 'callBeforeExpropriationDate',
        CallBeforeExpropriationReceived: 'callBeforeExpropriationReceivedDate',
        ExpropriationCaseInProgress: 'expropriationCaseInProgressDate',
        ExpropriationCaseStopped: 'expropriationCaseStoppedDate',
        ExpropriationCaseFinished: 'expropriationCaseFinishedDate',
    };
    return CellCaseSubjectsComponent;
}());
exports.CellCaseSubjectsComponent = CellCaseSubjectsComponent;
