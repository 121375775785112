"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var i0 = require("@angular/core");
/**
 * Display name of occupation
 */
var OccupationNamePipe = /** @class */ (function () {
    function OccupationNamePipe() {
    }
    OccupationNamePipe.prototype.transform = function (occupation) {
        var detailString = '';
        if (occupation.parcel && ((occupation.parcel.title && occupation.parcel.title.lv) || (occupation.parcel.area && occupation.parcel.area.name))) {
            var detail = [];
            if (occupation.parcel.title && occupation.parcel.title.lv) {
                detail.push('LV' + occupation.parcel.title.lv);
            }
            if (occupation.parcel.area && occupation.parcel.area.name) {
                detail.push('KÚ ' + occupation.parcel.area.name);
            }
            detailString = ' (' + detail.join(' - ') + ')';
        }
        return occupation.zabcis + detailString;
    };
    OccupationNamePipe.ngInjectableDef = i0.defineInjectable({ factory: function OccupationNamePipe_Factory() { return new OccupationNamePipe(); }, token: OccupationNamePipe, providedIn: "root" });
    return OccupationNamePipe;
}());
exports.OccupationNamePipe = OccupationNamePipe;
