"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var _ = require("lodash");
var L = require("leaflet");
var map_layers_storage_service_1 = require("@app/map/services/map-layers-storage.service");
var map_layer_factory_service_1 = require("@app/map/services/map-layer-factory.service");
var map_storage_service_1 = require("@app/map/services/map-storage.service");
var i0 = require("@angular/core");
var i1 = require("./map-layers-storage.service");
var i2 = require("./map-layer-factory.service");
var i3 = require("./map-storage.service");
var MapLayerService = /** @class */ (function () {
    function MapLayerService(mapLayersStorageService, mapLayerFactoryService, mapStorageService) {
        this.mapLayersStorageService = mapLayersStorageService;
        this.mapLayerFactoryService = mapLayerFactoryService;
        this.mapStorageService = mapStorageService;
        var MapLayerId = {
            hasLayerById: function (layer) {
                if (!layer) {
                    return false;
                }
                return !_.isEmpty(_.find(this._layers, function (l) { return l._id === layer._id; }));
            },
            removeLayerById: function (layerToRemove) {
                var layer = _.find(this._layers, function (l) { return l._id === layerToRemove._id; });
                this.removeLayer(layer);
            }
        };
        L.Map.include(MapLayerId);
    }
    /**
     * Creates Leaflet layer from given configuration, adds it to map and
     * saves it to layers storage. Returns promise.
     * @memberof CmLayer
     * @param {type} mapId
     * @param {type} layerConf
     * @param {type} layerId
     * @returns {unresolved}
     */
    MapLayerService.prototype.addLayerToMap = function (mapId, layerConf, layerId) {
        var _this = this;
        var layerLeaflet = this.mapLayerFactoryService.createLayer(layerConf, layerId);
        return this.mapStorageService.getMap(mapId)
            .then(function (map) {
            if (map.hasLayerById(layerLeaflet)) {
                return Promise.reject();
            }
            else {
                layerLeaflet.addTo(map);
            }
        })
            .then(this.mapLayersStorageService.getLayers(mapId).then(function (layers) {
            // adds layer to immutable layer storage
            layers = _this.mapLayersStorageService.addLayerToLayers(layers, layerLeaflet);
            _this.mapLayersStorageService.setLayers(layers, mapId);
        }));
    };
    /**
     * Applies func for every layer.
     * @memberof CmLayer
     * @param layersStorage
     * @param func
     */
    MapLayerService.prototype.forEachLayer = function (layersStorage, func) {
        if (_.isArray(layersStorage)) {
            _.forEach(layersStorage, function (value) {
                func(value);
            });
        }
    };
    /**
     * Recursively adds new layers or layers groups to map from given
     * configuration. Recursion keeps the promise chain.
     * @memberof CmLayer
     * @param {type} mapId
     * @param {type} layerConfiguration
     * @returns {undefined}
     */
    MapLayerService.prototype.initLayersForMap = function (mapId, layerConfiguration) {
        var _this = this;
        if (_.isArray(layerConfiguration) && layerConfiguration.length > 0) {
            var layerConf = _.head(layerConfiguration);
            layerConfiguration = _.tail(layerConfiguration);
            if (layerConf.type && layerConf.type === 'layer' && layerConf.id && layerConf.layer) {
                this.addLayerToMap(mapId, layerConf.layer, layerConf.id).then(function () {
                    _this.initLayersForMap(mapId, layerConfiguration);
                });
            }
        }
    };
    /**
     * Removes layer from map and layer storage by given layer id.
     * Returns promise.
     * @memberof CmLayer
     * @param {type} mapId
     * @param {type} layerId
     * @returns {unresolved}
     */
    MapLayerService.prototype.removeLayerFromMap = function (mapId, layerId) {
        var _this = this;
        return this.mapLayersStorageService.getLayers(mapId).then(function (layers) {
            var layerLeaflet = _this.mapLayersStorageService.getLayerById(layers, layerId);
            _this.mapStorageService.getMap(mapId).then(function (map) {
                if (map.hasLayerById(layerLeaflet)) {
                    map.removeLayerById(layerLeaflet);
                    layers = _this.mapLayersStorageService.removeLayerFromLayers(layers, layerId);
                    _this.mapLayersStorageService.setLayers(layers, mapId);
                }
            });
        });
    };
    MapLayerService.ngInjectableDef = i0.defineInjectable({ factory: function MapLayerService_Factory() { return new MapLayerService(i0.inject(i1.MapLayersStorageService), i0.inject(i2.MapLayerFactoryService), i0.inject(i3.MapStorageService)); }, token: MapLayerService, providedIn: "root" });
    return MapLayerService;
}());
exports.MapLayerService = MapLayerService;
