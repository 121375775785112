"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var table_service_1 = require("@app/ps/services/table.service");
var settings_service_1 = require("@app/ps/services/settings.service");
var username_pipe_1 = require("@app/common/pipes/username.pipe");
var auth_service_1 = require("@app/common/services/auth.service");
var TableSubjectsComponent = /** @class */ (function () {
    function TableSubjectsComponent(tableService, settingsService, authService, userNamePipe, restangular) {
        this.tableService = tableService;
        this.settingsService = settingsService;
        this.authService = authService;
        this.userNamePipe = userNamePipe;
        this.restangular = restangular;
        this.showNavIcons = false;
        this.sort = new core_1.EventEmitter();
        this.TABLE_ID = 'owners';
        this.isArray = Array.isArray;
    }
    TableSubjectsComponent.prototype.ngOnInit = function () {
    };
    TableSubjectsComponent.prototype.onSort = function (column, ascOrDesc) {
        this.sort.emit({
            column: column,
            ascOrDesc: ascOrDesc,
        });
    };
    TableSubjectsComponent.prototype.hideColumn = function (columnId) {
        return this.settingsService.shouldHideColumn(this.TABLE_ID, columnId);
    };
    TableSubjectsComponent.prototype.getColumnName = function (columnId) {
        return this.tableService.getColumnById(this.TABLE_ID, columnId).name;
    };
    TableSubjectsComponent.prototype.getUniqueCadastreCount = function (owner) {
        var uniqueCadastres = new Set(owner['ownerships'].map(function (o) { return o.katuzeKod; }));
        return __spread(uniqueCadastres).length;
    };
    TableSubjectsComponent.prototype.getUniqueTitleCount = function (owner) {
        var uniqueTitles = new Set(owner['ownerships'].map(function (o) { return o.title.id; }));
        return __spread(uniqueTitles).length;
    };
    return TableSubjectsComponent;
}());
exports.TableSubjectsComponent = TableSubjectsComponent;
