"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var angular_1 = require("@uirouter/angular");
var occupation_list_component_1 = require("@app/ps/occupations/components/occupation-list/occupation-list.component");
var update_occupation_parcel_component_1 = require("@app/ps/occupations/components/update-occupation-parcel/update-occupation-parcel.component");
exports.occupationListState = {
    name: 'symap.project.occupations',
    url: '/occupations?page',
    data: {
        title: 'Zábory',
    },
    params: {
        page: {
            dynamic: true,
            type: 'int',
            inherit: false,
            squash: true,
        },
    },
    views: {
        'content@symap.project': {
            component: occupation_list_component_1.OccupationListComponent,
        },
    },
};
exports.updateOccupationParcelState = {
    name: 'symap.project.occupations.updateOccupationParcel',
    url: '/{occupationId}/update-parcel',
    data: {
        title: 'Aktualizace parcely záboru',
    },
    params: {
        occupationId: {
            type: 'int',
        },
    },
    resolve: {
        occupationId: ['$stateParams', resolveOccupationIdFunction],
    },
    views: {
        'content@symap.project': {
            component: update_occupation_parcel_component_1.UpdateOccupationParcelComponent,
        },
    },
};
function resolveOccupationIdFunction($stateParams) {
    return $stateParams.occupationId;
}
exports.resolveOccupationIdFunction = resolveOccupationIdFunction;
var OccupationsModule = /** @class */ (function () {
    function OccupationsModule() {
    }
    return OccupationsModule;
}());
exports.OccupationsModule = OccupationsModule;
