"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var tooltip_vfze_problems_component_1 = require("@app/common/components/tooltip-vfze-problems/tooltip-vfze-problems.component");
var vfze_validation_response_model_1 = require("@app/common/models/vfze-validation-response.model");
var VfzeProblemsComponent = /** @class */ (function () {
    function VfzeProblemsComponent() {
        this.loading = true;
        this.tooltipProblemsComponent = tooltip_vfze_problems_component_1.TooltipVfzeProblemsComponent;
        this.boundary = 10;
        // positioning
        // totalHeight has to be dividible by rowHeight without reminder. Any change of rowHeight needs change in scss...
        this.viewHeight = 600;
        this.totalHeight = this.viewHeight;
        this.rowHeight = 30;
        this.marginTop = 0;
        this.marginBottom = 0;
        this.showFromRow = 0;
    }
    VfzeProblemsComponent.prototype.ngOnInit = function () {
        this.prepareRows();
        if (this.rows.length * this.rowHeight > this.totalHeight) {
            this.totalHeight = this.rows.length * this.rowHeight;
            this.marginBottom = this.totalHeight - this.viewHeight;
            this.rowsToShow = this.rows.slice(this.showFromRow, this.viewHeight / this.rowHeight);
        }
        else {
            this.rowsToShow = this.rows;
        }
        this.loading = false;
    };
    VfzeProblemsComponent.prototype.onScroll = function (event) {
        var top = event.target.scrollTop;
        if (top > this.totalHeight - this.viewHeight) {
            return;
        }
        this.marginTop = top;
        this.marginBottom = this.totalHeight - this.viewHeight - top;
        this.showFromRow = Math.floor(top / this.rowHeight);
        this.rowsToShow = this.rows.slice(this.showFromRow, this.showFromRow + this.viewHeight / this.rowHeight);
    };
    /**
     * Select rows in boundary around problematic rows
     */
    VfzeProblemsComponent.prototype.prepareRows = function () {
        var e_1, _a, e_2, _b, e_3, _c;
        var allRows = this.vfzeSource.split('\n');
        this.rows = [];
        // Get problems
        var problems = this.validationResponse.fatal.map(function (p) { return __assign({}, p, { type: 'fatal' }); })
            .concat(this.validationResponse.errors.map(function (p) { return __assign({}, p, { type: 'errors' }); }))
            .concat(this.validationResponse.warnings.map(function (p) { return __assign({}, p, { type: 'warnings' }); }))
            .filter(function (p) { return p.xmlStructureError && p.line !== null; })
            .map(function (p) { return __assign({}, p, { line: p.line - 1 }); }) // wrong number of lines from BE
            .sort(function (a, b) { return a.line - b.line; });
        var problemLines = __spread(new Set(problems.map(function (p) { return p.line; })));
        var problemIndex = {};
        var _loop_1 = function (line) {
            problemIndex[line] = problems.filter(function (p) { return p.line === line; });
        };
        try {
            for (var problemLines_1 = __values(problemLines), problemLines_1_1 = problemLines_1.next(); !problemLines_1_1.done; problemLines_1_1 = problemLines_1.next()) {
                var line = problemLines_1_1.value;
                _loop_1(line);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (problemLines_1_1 && !problemLines_1_1.done && (_a = problemLines_1.return)) _a.call(problemLines_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        // Get rows ranges
        var ranges = [];
        try {
            for (var problemLines_2 = __values(problemLines), problemLines_2_1 = problemLines_2.next(); !problemLines_2_1.done; problemLines_2_1 = problemLines_2.next()) {
                var line = problemLines_2_1.value;
                var lowBoundary = (line - this.boundary < 0 ? 0 : line - this.boundary);
                var highBoundary = (line + this.boundary > allRows.length - 1 ? allRows.length - 1 : line + this.boundary);
                var prev = ranges[ranges.length - 1];
                if (!prev || prev.high < lowBoundary) {
                    ranges.push({ low: lowBoundary, high: highBoundary });
                }
                else if (prev.high < highBoundary) {
                    prev.high = highBoundary;
                }
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (problemLines_2_1 && !problemLines_2_1.done && (_b = problemLines_2.return)) _b.call(problemLines_2);
            }
            finally { if (e_2) throw e_2.error; }
        }
        var _loop_2 = function (range) {
            var rows = allRows
                .slice(range.low, range.high)
                .map(function (r, i) {
                return {
                    line: range.low + i,
                    content: r,
                    problems: problemIndex[range.low + i] ? problemIndex[range.low + i] : []
                };
            });
            this_1.rows = this_1.rows.concat(rows, [{ line: undefined, content: undefined, problems: undefined }]);
        };
        var this_1 = this;
        try {
            // Prepare rows by ranges
            for (var ranges_1 = __values(ranges), ranges_1_1 = ranges_1.next(); !ranges_1_1.done; ranges_1_1 = ranges_1.next()) {
                var range = ranges_1_1.value;
                _loop_2(range);
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (ranges_1_1 && !ranges_1_1.done && (_c = ranges_1.return)) _c.call(ranges_1);
            }
            finally { if (e_3) throw e_3.error; }
        }
    };
    return VfzeProblemsComponent;
}());
exports.VfzeProblemsComponent = VfzeProblemsComponent;
