import {transformListToFilter} from 'js/common/services/pure/listTransform'
var common = angular.module('common');
//FIXME abort count
/**
 * Handle list loading
 */
common.service('ListLoadService', ['Restangular', '$q', 'localStorageService', 'AuthService', 'ErrorHandlerService',
  function (Restangular, $q, localStorageService, AuthService, ErrorHandlerService) {

    this.fetchListPerPage = (list, page, onListChanged, onOverfloatPages) => {
      list.filter.offset = (page - 1) * list.filter.limit;
      //check if requested items fit with possible offset and limit
      if(list.list && list.filter.offset >= this.getItemsCount(list) && page !== 1) {
        list.filter.offset = 0;

        if (typeof onOverfloatPages === 'function') {
          onOverfloatPages();
        }

        return $q.reject();
      } else {
        return this.fetchResult(list).then(function() {
          if (typeof onListChanged === 'function') {
            onListChanged();
          }
        });
      }
    };

    this.fetchResult = function (list, additive, stripRestangular) {
      if (additive === undefined) {
        additive = false;
      }
      if (stripRestangular === undefined) {
        stripRestangular = true;
      }
      if (list.abortFetch) {
        list.abortFetch.resolve();
        list.promise.catch(() => {
          list.loading = true;
        });
      } else {
        list.loading = true;
      }
      list.abortFetch = $q.defer();

      var filter = transformListToFilter(list.filter);
      var filterRequest = angular.copy(filter);

      if (filterRequest.sortOrder && !(filterRequest.sortOrder instanceof Array)) {
        filterRequest.sortOrder = [filterRequest.sortOrder];
      }

      if (list.filterLocalStorageName) {
        filter.filters.loadCollections = undefined;
        localStorageService.set(list.filterLocalStorageName, filter);
      }

      list.promise = this.requestCall(
        list.RestangularService.all(list.path).withHttpConfig({timeout: list.abortFetch.promise}),
        filterRequest,
        list.attributes
      ).then(
        (data) => {
          list.loading = false;
          if (list.list === null || !additive) {
            list.list = [];
          }
          list.list = list.list.concat(stripRestangular ? Restangular.stripRestangular(data) : data);
          list.itemCount = data.itemCount;

          list.abortFetch = null;
          return list;
        }, (reason) => {
          list.loading = false;
          if (reason && reason.xhrStatus !== 'abort') {
            new ErrorHandlerService()(reason);
          }
          return $q.reject(reason);
        });
      return list.promise;
    };

    this.toFilterRequestMessage = (filterRequest) => {
      if (filterRequest.filters.searchText) {
        if (filterRequest.filters.searchText.values.length === 1) {
          filterRequest.filters.searchText.values = filterRequest.filters.searchText.values[0].split(' ');
        }
        filterRequest.filters.searchText.values = filterRequest.filters.searchText.values.filter((value) => !!value);
      }

      for (const key of Object.keys(filterRequest.filters)) {
        if (filterRequest.filters[key] && filterRequest.filters[key].searchText !== undefined) {
          if (!(filterRequest.filters[key].searchText instanceof Array)) {
            filterRequest.filters[key].searchText = filterRequest.filters[key].searchText.split(' ');
          }
          filterRequest.filters[key].searchText = filterRequest.filters[key].searchText.filter((text) => !!text);
        }
      }
      return filterRequest;
    };

    this.requestCall = function(restangularObject, filterRequest, attributesRequest, dataCallback) {
      this.toFilterRequestMessage(filterRequest);

      return restangularObject.customPOST({
        attributes: attributesRequest,
        filter: filterRequest
      }).then((data) => {
        if(typeof dataCallback === 'function') {
          return dataCallback(data);
        } else {
          return data;
        }
      });
    };

    this.fetchResultCount = function (list) {
      var filter = angular.copy(list.filter);
      filter.offset = 0;
      delete filter.sortOrder;
      var filterRequest = transformListToFilter(filter);

      return this.requestCall(
        list.RestangularService.all(list.path + '/count'),
        filterRequest,
        list.attributes,
        function (data) {
          list.itemCountTotal = data;
        });
    };

    this.cancelFetch = function (list) {
      if (list.abortFetch) {
        list.abortFetch.resolve();
      }
    };

    this.attachFilterStorage = function (list, filterLocalStorageName, executeAfter) {
      filterLocalStorageName = AuthService.getActualProject() ? `${AuthService.getActualProject().key}.${filterLocalStorageName}` : `${AuthService.getActiveApplication()}.${filterLocalStorageName}`;
      var localStorageFilter = localStorageService.get(filterLocalStorageName);
      if (localStorageFilter) {
        for (var attrname in localStorageFilter) {
          if (attrname !== 'filters' && list.filter[attrname] !== undefined) {
            list.filter[attrname] = localStorageFilter[attrname];
          } else {
            // expands arrays to objects
            for (var key in localStorageFilter[attrname]) {
              var value = localStorageFilter[attrname][key];
              var found = list.filter.filters[key];
              if (typeof found === 'undefined' || typeof found.values === 'undefined' || typeof found.values === typeof value.values) {
                found = list.filter.filters[key] = {};
                found.values = value.values;
                if (value.negation && value.negation === true) {
                  found.negation = value.negation;
                }
                if (value.searchText !== undefined) {
                  found.searchText = value.searchText;
                }
                if (found.values instanceof Array) {
                  $.each(found.values, function (i) {
                    found.values[i] = {id: found.values[i]};
                  });
                }
              }
            }
          }
        }
      }

      list.filterLocalStorageName = filterLocalStorageName;

      if (typeof executeAfter === 'function') {
        executeAfter();
      }
    };

    this.createList = function (path, defaultFilter, RestangularService = Restangular, attributes = {}) {
      var filter = {
        offset: 0,
        limit: 20,
        sortOrder: {sortBy: 'id', direction: 'asc'},
        filters: {}
      };

      if (defaultFilter) {
        for (var attrname in defaultFilter) {
          filter[attrname] = defaultFilter[attrname];
        }
      }

      var list = {
        abortFetch: null,
        path: path,
        list: null,
        loading: false,
        itemCount: 0,
        filter: filter,
        RestangularService: RestangularService,
        attributes: attributes,
      };

      return list;
    };


    this.getCurrentPage = function (list) {
      return list && list.filter ? (list.filter.offset / list.filter.limit) + 1 : 0;
    };

    this.getTotalPages = function (list) {
      return list && list.filter ? Math.ceil(this.getItemsCount(list) / list.filter.limit) : 0;
    };

    this.getItemsCount = function (list) {
      if (list && list.filter && list.itemCountTotal) {
        return list.itemCountTotal;
      } else if (list && list.filter && list.itemCount) {
        return list.itemCount;
      } else {
        return 0;
      }
    };

    this.sort = function(list, sortValues, sortDir) {
      if (sortValues instanceof Array) {
        list.filter.sortOrder = _.map(sortValues, (sortValue) => {
          return {sortBy: sortValue, direction: (sortDir === 'asc' ? 'desc' : 'asc')};
        });
      } else {
        // for backward compatibility if sortBy is checked
        list.filter.sortOrder = {sortBy: sortValues, direction: (sortDir === 'asc' ? 'desc' : 'asc')};
      }
      list.filter.offset = 0;
      return this.fetchResult(list);
    };
  }]);

