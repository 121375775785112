"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var create_case_component_1 = require("./components/create-case/create-case.component");
var title_list_component_1 = require("./components/title-list/title-list.component");
var pricing_overview_component_1 = require("@app/ps/titles/components/pricing-overview/pricing-overview.component");
var pricing_expert_opinion_component_1 = require("@app/ps/titles/components/pricing-expert-opinion/pricing-expert-opinion.component");
var pricing_other_source_component_1 = require("@app/ps/titles/components/pricing-other-source/pricing-other-source.component");
var update_rental_parcel_price_component_1 = require("@app/ps/titles/components/update-rental-parcel-price/update-rental-parcel-price.component");
var title_detail_component_1 = require("@app/ps/titles/components/title-detail/title-detail.component");
var state_utils_1 = require("@app/common/utils/state.utils");
var pricing_preliminary_opinion_component_1 = require("@app/ps/titles/components/pricing-preliminary-opinion/pricing-preliminary-opinion.component");
exports.titlesState = {
    name: 'symap.project.titles',
    data: {
        title: 'Listy vlastnictví',
    },
    url: '/titles?page&sef',
    params: {
        page: {
            dynamic: true,
            type: 'int',
            inherit: false,
        },
        sef: {
            type: 'string',
            inherit: false,
            array: true
        },
    },
    views: {
        'content@symap.project': {
            component: title_list_component_1.TitleListComponent,
        }
    }
};
exports.titleDetailState = {
    name: 'symap.project.titles.detail',
    data: {
        title: 'Detail listu vlastnictví',
    },
    url: '/:id/:tab',
    params: {
        id: {
            type: 'int',
            value: undefined
        },
        tab: {
            value: 'owners',
            dynamic: true,
            inherit: false,
            squash: true,
        }
    },
    resolve: {
        titleId: ['$stateParams', state_utils_1.resolveId]
    },
    views: {
        'content@symap.project': {
            component: title_detail_component_1.TitleDetailComponent,
        },
    },
};
exports.createCaseState = {
    name: 'symap.project.titles.detail.createCase2',
    data: {
        title: 'Založení případu',
    },
    url: '/create-case',
    params: {},
    resolve: {
        title: resolveTitle,
    },
    views: {
        'content@symap.project': {
            component: create_case_component_1.CreateCaseComponent,
        },
    },
};
exports.createPricingState = {
    name: 'symap.project.titles.detail.pricing',
    url: '/pricing',
    abstract: true,
};
exports.createPricingOverviewState = {
    name: 'symap.project.titles.detail.pricing.overview',
    data: {
        title: 'Znalecké posudky',
    },
    url: '/overview',
    params: {},
    views: {
        'content@symap.project': {
            component: pricing_overview_component_1.PricingOverviewComponent,
        },
    },
};
exports.createPricingExpertOpinionCreateState = {
    name: 'symap.project.titles.detail.pricing.expertOpinion',
    data: {
        title: 'Zadání cen',
    },
    url: '/expert-opinion?opinionId?occupationType?opinionRequestId',
    resolve: {
        opinionId: ['$stateParams', resolveOpinionId],
        opinionRequestId: ['$stateParams', resolveOpinionRequestId],
        occupationType: ['$stateParams', resolveOccupationType],
    },
    params: {
        opinionId: {
            type: 'int',
        },
        opinionRequestId: {
            type: 'int',
        },
        occupationType: {
            value: '',
        },
    },
    views: {
        'content@symap.project': {
            component: pricing_expert_opinion_component_1.PricingExpertOpinionComponent,
        },
    },
};
exports.createPricingOtherSourceCreateState = {
    name: 'symap.project.titles.detail.pricing.otherSource',
    data: {
        title: 'Zadání cen',
    },
    url: '/other-source?opinionId?occupationType',
    resolve: {
        opinionId: ['$stateParams', resolveOpinionId],
        occupationType: ['$stateParams', resolveOccupationType],
    },
    params: {
        opinionId: {
            type: 'int',
        },
        occupationType: {
            value: '',
        },
    },
    views: {
        'content@symap.project': {
            component: pricing_other_source_component_1.PricingOtherSourceComponent,
        },
    },
};
exports.createPreliminaryOpinionState = {
    name: 'symap.project.titles.detail.pricing.preliminary-opinion',
    data: {
        title: 'Znalecké posudky',
    },
    url: '/preliminary-opinion?opinionId',
    resolve: {
        opinionId: ['$stateParams', resolveOpinionId],
    },
    params: {
        opinionId: {
            type: 'int',
        }
    },
    views: {
        'content@symap.project': {
            component: pricing_preliminary_opinion_component_1.PricingPreliminaryOpinionComponent,
        },
    },
};
exports.updateRentalParcelPriceState = {
    name: 'symap.project.titles.detail.pricing.updateRentalParcelPrice',
    data: {
        title: 'Editace ceny parcely',
    },
    url: '/update-rental-parcel-price/:occupationId',
    params: {
        occupationId: {
            type: 'int',
        },
    },
    views: {
        'content@symap.project': {
            component: update_rental_parcel_price_component_1.UpdateRentalParcelPriceComponent,
        },
    },
};
function resolveTitle(restangular, titleId) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, restangular.one('titles', titleId).get()];
        });
    });
}
exports.resolveTitle = resolveTitle;
resolveTitle.$inject = ['Restangular', 'titleId'];
function resolveOpinionId($stateParams) {
    return $stateParams.opinionId;
}
exports.resolveOpinionId = resolveOpinionId;
function resolveOpinionRequestId($stateParams) {
    return $stateParams.opinionRequestId;
}
exports.resolveOpinionRequestId = resolveOpinionRequestId;
function resolveOccupationType($stateParams) {
    return $stateParams.occupationType;
}
exports.resolveOccupationType = resolveOccupationType;
