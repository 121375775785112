const common = angular.module('common');

common.component('cmHelp', {
        template:
        `<div ng-class="['cm-help',{'cm-help-admin': $ctrl.isAdmin(), 'cm-help-empty': !$ctrl.isSet}]">
          <span ng-click="$ctrl.onEditClick()" class="clickable" ng-if="$ctrl.isAdmin()">
            <i class="fa fa-pencil" aria-hidden="true"></i>
          </span>
          <a ng-if="$ctrl.isSet" class="button" href="{{$ctrl.help.url}}" target="_blank" title="{{$ctrl.help.title}}">
            <i class="fa fa-question fa-2x" aria-hidden="true"></i>
          </a>
          <i ng-if="!$ctrl.isSet && $ctrl.isAdmin()"class="fa fa-question fa-2x" aria-hidden="true"></i>
        </div>
        `,
        bindings: {
          helpId: '<',
        },
        controller: [ 'AuthService', 'ModalService', 'HelpService', 'HELP_IDS', function(AuthService, ModalService, HelpService, HELP_IDS) {
          this.isSet = true;
          this.help = null;

          this.$onInit = () => {
            HelpService.getHelpById(this.helpId).then((help)=>{
              this.isSet = !!help;
              this.help = help || {
                id: this.helpId,
                url:null,
                title: null,
                description: null,
                strId: Object.keys(HELP_IDS).find(key => HELP_IDS[key] === this.helpId)
              };
            });
          };

          this.isAdmin = () => AuthService.user && AuthService.user.superuser;

          this.onEditClick = () => {
            new ModalService({
                templateUrl: "js/common/components/cm-help-edit.html",
                controller: 'HelpEditController',
                scope: {
                  help: this.help,
                }
            }).then(() => {
              HelpService.getHelpById(this.helpId).then((help)=>{
                this.isSet = !!help;
              });
            });
          };
          }]
      });


require('./HelpEditController.js');
