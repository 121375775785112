"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var DocumentTypeModel = /** @class */ (function () {
    function DocumentTypeModel() {
    }
    /**
     * Factory method
     */
    DocumentTypeModel.createById = function (id) {
        var types = DocumentTypeModel.CASE_DOCUMENT_TYPES
            .concat(DocumentTypeModel.GP_DOCUMENT_TYPES)
            .concat(DocumentTypeModel.OPINION_DOCUMENT_TYPES)
            .concat(DocumentTypeModel.SUBJECT_DOCUMENT_TYPES)
            .concat(DocumentTypeModel.SAMPLE_DOCUMENT_TYPES);
        return types.find(function (type) { return type.id === id; })
            || types.find(function (type) { return type.id === 'OTHER'; });
    };
    DocumentTypeModel.getByCategory = function (category) {
        switch (category) {
            case 'gp':
                return DocumentTypeModel.GP_DOCUMENT_TYPES;
            case 'case':
                return DocumentTypeModel.CASE_DOCUMENT_TYPES;
            case 'o':
                return DocumentTypeModel.OPINION_DOCUMENT_TYPES;
            case 'subject':
                return DocumentTypeModel.SUBJECT_DOCUMENT_TYPES;
            case 'sample':
                return DocumentTypeModel.SAMPLE_DOCUMENT_TYPES;
            default:
                return [];
        }
    };
    DocumentTypeModel.CASE_DOCUMENT_TYPES = [
        {
            id: 'CONTRACT',
            icon: 'fa-file',
            title: 'Návrh smlouvy'
        },
        {
            id: 'CONTRACT_APPROVED_BY_INVESTOR',
            icon: 'fa-hand-o-right',
            title: 'Návrh smlouvy schválený investorem'
        },
        {
            id: 'COVERING_LETTER',
            icon: 'fa-file-text',
            title: 'Průvodní dopis'
        },
        {
            id: 'FINANCIAL_CONTROL',
            icon: 'fa-fonticons',
            title: 'Finanční kontrola FINKO'
        },
        {
            id: 'PRELIMINARY_PAYMENT_ORDER',
            icon: 'fa-list-ol',
            title: 'Proforma platebního příkazu'
        },
        {
            id: 'PAYMENT_ORDER',
            icon: 'fa-money',
            title: 'Platební příkaz'
        },
        {
            id: 'SIGNED_CONTRACT_SCAN',
            icon: 'fa-handshake-o',
            title: 'Sken všestranně podepsané smlouvy',
            extensions: ['pdf'],
        },
        {
            id: 'POWER_OF_ATTORNEY_TO_DIVIDE',
            icon: 'fa-file-text',
            title: 'Plná moc k dělení pozemku'
        },
        {
            id: 'OTHER',
            icon: 'fa-file-o',
            title: 'Nezařazené - ostatní'
        }
    ];
    DocumentTypeModel.SUBJECT_DOCUMENT_TYPES = [
        {
            id: 'POWER_OF_ATTORNEY_TO_DIVIDE',
            icon: 'fa-file-text',
            title: 'Plná moc k dělení pozemku'
        },
        {
            id: 'OTHER',
            icon: 'fa-file-o',
            title: 'Nezařazené - ostatní'
        }
    ];
    DocumentTypeModel.GP_DOCUMENT_TYPES = [
        {
            id: 'PARCEL_SPLIT_PRE_APPROVE_BY_BUILDING_AUTHORITY_SCAN',
            icon: 'fa-hand-o-right',
            title: 'Sken předběžného schválení dělení pozemků od SÚ'
        },
        {
            id: 'PARCEL_SPLIT_PRE_APPROVE_BY_OWNER_SCAN',
            icon: 'fa-handshake-o',
            title: 'Sken předběžného schválení dělení pozemků od vlastníka'
        },
        {
            id: 'GEOMETRIC_PLAN',
            icon: 'fa-object-ungroup',
            title: 'Geometrický plán'
        },
        {
            id: 'GEOMETRIC_PLAN_VFK',
            icon: 'fa-object-ungroup',
            title: 'VFK geometrického plánu'
        },
        {
            id: 'GEOMETRIC_PLAN_DOCUMENTATION',
            icon: 'fa-object-ungroup',
            title: 'Geodetická dokumentace GP'
        },
        {
            id: 'OTHER',
            icon: 'fa-file-o',
            title: 'Nezařazené - ostatní'
        }
    ];
    DocumentTypeModel.OPINION_DOCUMENT_TYPES = [
        {
            id: 'OPINION_SCAN',
            icon: 'fa-book',
            title: 'Sken znaleckého posudku'
        },
        {
            id: 'OTHER',
            icon: 'fa-file-o',
            title: 'Nezařazené - ostatní'
        }
    ];
    DocumentTypeModel.SAMPLE_DOCUMENT_TYPES = [
        {
            id: 'SAMPLE',
            icon: 'fa-book',
            title: 'Vzor'
        },
        {
            id: 'TEMPLATE',
            icon: 'fa-file-o',
            title: 'Šablona'
        },
        {
            id: 'INACTIVE_TEMPLATE',
            icon: 'fa-file',
            title: 'Chybná šablona'
        }
    ];
    return DocumentTypeModel;
}());
exports.DocumentTypeModel = DocumentTypeModel;
