"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var case_service_1 = require("@app/ps/services/case.service");
var angular_1 = require("@uirouter/angular");
var case_status_service_1 = require("@app/ps/services/case-status.service");
var AuctionResultComponent = /** @class */ (function () {
    function AuctionResultComponent(caseService, stateService, restangular, caseStatusService, confirmService) {
        this.caseService = caseService;
        this.stateService = stateService;
        this.restangular = restangular;
        this.caseStatusService = caseStatusService;
        this.confirmService = confirmService;
        this.loading = true;
        this.data = {
            auctionWinDate: new Date(),
            auctionWinLegalEffectsDate: new Date(),
            auctionWinAmount: null
        };
        this.sending = false;
        this.step = 1;
        this.onConfirm = this.onConfirm.bind(this);
        this.onDecline = this.onDecline.bind(this);
    }
    AuctionResultComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.caseStatusService.loadStatusName('AuctionWin')];
                    case 1:
                        _a.name = (_b.sent()).name;
                        this.loading = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    AuctionResultComponent.prototype.setStep = function (step) {
        if (!this.sending) {
            this.step = step;
        }
    };
    AuctionResultComponent.prototype.onDecline = function () {
        var _this = this;
        if (!this.sending) {
            return this.confirmService({
                title: 'Potvrzení zrušení případu',
                message: 'Opravdu chcete případ zrušit?'
            }).then(function (data) {
                if (data === true) {
                    _this.sending = true;
                    return _this.restangular.one('cases', _this.caseId).customPUT({}, 'cancel').then(function () {
                        _this.stateService.go('^');
                    });
                }
            });
        }
    };
    AuctionResultComponent.prototype.onConfirm = function () {
        var _this = this;
        if (this.data.auctionWinDate && this.data.auctionWinLegalEffectsDate && this.data.auctionWinAmount) {
            this.sending = true;
            return this.restangular.one('cases', this.caseId).customPUT(this.data, 'confirm-auction-win').then(function () {
                _this.stateService.go('^');
            });
        }
    };
    return AuctionResultComponent;
}());
exports.AuctionResultComponent = AuctionResultComponent;
