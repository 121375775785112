"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var PageableList = /** @class */ (function () {
    function PageableList(list, listService, stateService, uiconstants) {
        this.dataPaging = {
            itemsPerPage: {}
        };
        this.list = list;
        this.listService = listService;
        this.stateService = stateService;
        this.onFilterChanged = this.onFilterChanged.bind(this);
        this.onListChanged = this.onListChanged.bind(this);
        this.onSort = this.onSort.bind(this);
        this.itemsPerPageItems = uiconstants.itemsPerPage;
        this.dataPaging.itemsPerPage = {
            id: this.list.filter.limit,
            name: this.list.filter.limit
        };
    }
    PageableList.prototype.onFilterChanged = function (changingPage) {
        if (!changingPage) {
            return this.fetchResults();
        }
    };
    PageableList.prototype.onSort = function (sortValue, sortDir) {
        this.listService.sort(this.list, sortValue, sortDir)
            .then(this.onListChanged);
    };
    PageableList.prototype.uiOnParamsChanged = function (changedParams, $transition$) {
        if ($transition$.$from().name === $transition$.$to().name) {
            var page = this.getActualPage(changedParams);
            // through sorting reload is triggered and in some cases also change to first page one at once, hence reload is not needed
            if (page && page !== this.listService.getCurrentPage(this.list)) {
                return this.fetchResults(page);
            }
        }
    };
    PageableList.prototype.changePageItems = function () {
        this.list.filter.limit = this.dataPaging.itemsPerPage.id;
        return this.onFilterChanged(false);
    };
    PageableList.prototype.paginationCallbackRegister = function (event) {
        this.onListChangedPagination = event.onListChanged;
    };
    PageableList.prototype.itemcounterCallbackRegister = function (event) {
        this.onListChangedItemcounter = event.onListChanged;
    };
    PageableList.prototype.pagescounterCallbackRegister = function (event) {
        this.onListChangedPagescounter = event.onListChanged;
    };
    PageableList.prototype.onListChanged = function () {
        if (this.onListChangedPagination) {
            this.onListChangedPagination();
        }
        if (this.onListChangedItemcounter) {
            this.onListChangedItemcounter();
        }
        if (this.onListChangedPagescounter) {
            this.onListChangedPagescounter();
        }
    };
    PageableList.prototype.getActualPage = function (params, defaultPage) {
        if (defaultPage === void 0) { defaultPage = this.list.filter.offset / this.list.filter.limit + 1; }
        return parseInt(params.page, 10) || defaultPage;
    };
    PageableList.prototype.fetchResults = function (page) {
        var _this = this;
        if (page === void 0) { page = this.getActualPage(this.stateService.params); }
        var overfloatFallback = function () { return _this.stateService.go('.', { page: 1 }); };
        return this.listService.fetchListPerPage(this.list, page, this.onListChanged, overfloatFallback);
    };
    PageableList.prototype.load = function () {
        return __awaiter(this, void 0, void 0, function () {
            var actualPage;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        actualPage = this.getActualPage(this.stateService.params);
                        return [4 /*yield*/, this.stateService.go('.', Object.assign({}, this.stateService.params, { page: actualPage }))];
                    case 1:
                        _a.sent();
                        return [2 /*return*/, this.onFilterChanged(false)];
                }
            });
        });
    };
    PageableList.prototype.disableLocalStorage = function () {
        this.list.filterLocalStorageName = null;
    };
    return PageableList;
}());
exports.PageableList = PageableList;
