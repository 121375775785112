"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = [
    'approvedInvestorDate',
    'bonusDate',
    'bonusGranted',
    'bonusPossible',
    'cadastreEntryDate',
    'cadastreEntryDeclinedDate',
    'cadastreEntryDeliveryDate',
    'cadastreEntryNumber',
    'cadastreEntryStoppedDate',
    'contractRegisterPublicationContractId',
    'contractRegisterPublicationDate',
    'contractRegisterPublicationException',
    'contractRegisterPublicationVersionId',
    'createdDate',
    'declinedInvestorDate',
    'deliveryDeadlineDate',
    'entryProposalDate',
    'expropriationAmount',
    'expropriationAmountSource',
    'expropriationAppealDate',
    'expropriationAppealDeadlineDate',
    'expropriationAppealReason',
    'expropriationAppealResultArbitrator',
    'expropriationAppealResultDate',
    'expropriationAppealResultReason',
    'expropriationAppealSide',
    'expropriationAssessmentInteruptionDate',
    'expropriationAssessmentInteruptionDeadlineDate',
    'expropriationAssessmentInteruptionReason',
    'expropriationCalledHearingCalledDate',
    'expropriationCalledHearingDate',
    'expropriationEnforceableDate',
    'expropriationHearingRepetitionCalledDate',
    'expropriationHearingRepetitionDate',
    'expropriationHearingRepetitionReason',
    'expropriationProceedingInteruptionDate',
    'expropriationProceedingInteruptionDeadlineDate',
    'expropriationProceedingInteruptionExtendedDate',
    'expropriationProceedingInteruptionExtendedDeadlineDate',
    'expropriationProceedingInteruptionExtendedReason',
    'expropriationProceedingInteruptionReason',
    'expropriationProceedingStartedDate',
    'expropriationProposalDate',
    'expropriationResultDate',
    'expropriationResultDeliveryDate',
    'expropriationResultRealizationDeadlineDate',
    'expropriationStoppedBySide',
    'expropriationStoppedDate',
    'expropriationStoppedReason',
    'expropriationInterimDecisionRequestDate',
    'expropriationInterimDecisionDate',
    'expropriationAdministrativeAppealDate',
    'expropriationAdministrativeAppealRejectedDate',
    'expropriationAdministrativeAppealAcceptedDate',
    'expropriationCompensationPaymentDate',
    'expropriationStartDeadlineDate',
    'legalEffectsDate',
    'paymentInstructionDate',
    'paymentOrderDate',
    'sentAdministratorDate',
    'sentInvestorDate',
    'signedAdministratorDate',
    'signedInvestorDate',
    'signedSentOwnersDate',
    'validityDate',
];
