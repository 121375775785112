"use strict";
var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var map_infobox_service_1 = require("@app/map/services/map-infobox.service");
var map_service_1 = require("@app/map/services/map.service");
var map_highlight_service_1 = require("@app/map/services/map-highlight.service");
var map_layers_storage_service_1 = require("@app/map/services/map-layers-storage.service");
var auth_service_1 = require("@app/common/services/auth.service");
var L = require("leaflet");
// const MESSAGEDEFAULT = 'Kliknutím do mapy vyžádejte informace.';
var MESSAGEDEFAULT = null;
var MESSAGEEMPTY = 'Nic nenalezeno';
var MapModuleInfoComponent = /** @class */ (function () {
    function MapModuleInfoComponent(mapInfoboxService, mapService, elementRef, mapHighlightService, mapLayersStorage, authService) {
        this.mapInfoboxService = mapInfoboxService;
        this.mapService = mapService;
        this.elementRef = elementRef;
        this.mapHighlightService = mapHighlightService;
        this.mapLayersStorage = mapLayersStorage;
        this.authService = authService;
        this.visibilityInfoboxCallback = new core_1.EventEmitter();
        this.message = MESSAGEDEFAULT;
        this.loading = false;
        this.msgTimeout = null;
        this.FIRSTRENDER = true;
        this.onFeatureDataLoaded = this.onFeatureDataLoaded.bind(this);
        this.clickHandler = this.clickHandler.bind(this);
    }
    MapModuleInfoComponent.prototype.ngOnInit = function () {
        this.mapinfodata = null;
        this.featureItems = [];
        this.marker = null;
        this.onClose();
        this.mapInfoboxService.register(this.mapId, this.config, this.clickHandler, this.onFeatureDataLoaded);
    };
    MapModuleInfoComponent.prototype.ngOnDestroy = function () {
        this.mapInfoboxService.deregister(this.mapId);
        //remove marker
        if (this.marker) {
            this.marker.remove();
        }
        this.mapinfodata = null;
        this.featureItems = null;
        this.mapHighlightService.removeHighlight(this.mapId);
        this.FIRSTRENDER = false;
        if (this.msgTimeout) {
            this.stopTimeout();
        }
    };
    MapModuleInfoComponent.prototype.onClose = function () {
        this.visibilityInfoboxCallback.emit({ infoboxVisibility: false });
        if (this.marker) {
            this.marker.remove();
            this.mapHighlightService.removeHighlight(this.mapId);
        }
        this.message = MESSAGEDEFAULT;
        if (this.msgTimeout) {
            this.stopTimeout();
        }
    };
    MapModuleInfoComponent.prototype.clickHandler = function (data, clickEvt) {
        var _this = this;
        window.GoogleAnalytics('send', {
            hitType: 'event',
            eventCategory: 'map',
            eventAction: 'click',
        });
        this.visibilityInfoboxCallback.emit({ infoboxVisibility: true });
        this.featureItems = [];
        if (data && data.featureDataLoaded) {
            this.mapinfodata = data;
        }
        else {
            this.mapinfodata = null;
        }
        //add marker
        if (this.marker) {
            this.marker.remove();
        }
        this.mapService.getMap(this.mapId).then(function (map) {
            var icon = L.icon({
                iconUrl: require('img/map/marker-icon.png'),
                iconSize: [25, 41],
                iconAnchor: [13, 41]
            });
            _this.marker = L.marker(clickEvt.latlng, { icon: icon }).addTo(map);
        });
    };
    //////
    /**
    Zpracování dat jedné vrstrvy
    */
    MapModuleInfoComponent.prototype.onFeatureDataLoaded = function (featureData) {
        var _this = this;
        var e_1, _a;
        if (this.msgTimeout) {
            this.stopTimeout();
        }
        ;
        this.loading = this.mapinfodata && this.mapinfodata.featureDataLoaded.includes(false);
        //if nothing found, show message for 3000ms
        if (!this.loading && ((this.mapinfodata && this.mapinfodata.featureData.length === 0) || !this.mapinfodata) && !this.msgTimeout) {
            this.message = MESSAGEEMPTY;
            this.mapinfodata = null;
            this.msgTimeout = setTimeout(function () {
                _this.onClose();
            }, 3000);
        }
        var _loop_1 = function (lastItem) {
            var item = {};
            item.cadastreDataDate = this_1.authService.getActualProject().cadastreDataDate;
            item.featureData = lastItem;
            item.highlightFeature = lastItem.templateProperties.highlightFeature !== undefined ? lastItem.templateProperties.highlightFeature : function (featureID, layerID) {
                _this.mapHighlightService.removeHighlight(_this.mapId);
                _this.mapLayersStorage.getLayers(_this.mapId).then(function (layers) {
                    var layer = _this.mapLayersStorage.getLayerById(layers, layerID);
                    layer.loadFeatureDataByID(featureID, {
                        t: _this.authService.getToken(),
                        p: _this.authService.getActualProject().key,
                    }).then(function (res) {
                        var feature = res;
                        _this.mapHighlightService.highlightFeature(_this.mapId, feature, {
                            paddingTopLeft: [10, 10],
                            paddingBottomRight: [_this.infoboxWidthGetter(), 10]
                        });
                    });
                });
            };
            Object.assign(item, lastItem.templateProperties.scope || {});
            item.jsonParse = function (jsonString) {
                try {
                    return JSON.parse(jsonString);
                }
                catch (e) {
                    return '';
                }
            };
            item.ownershipsParse = function (jsonString) {
                var ownerships = item.jsonParse(jsonString);
                if (!ownerships) {
                    return [];
                }
                var notCzechRepublicFilter = function (o) {
                    return !(o.typrav_kod === 30
                        && o.subjects.length === 1
                        && o.subjects[0].nazev === 'Česká republika');
                };
                return ownerships
                    .filter(notCzechRepublicFilter)
                    .sort(function (a, b) { return b.typrav_kod - a.typrav_kod; });
            };
            item.header = (typeof lastItem.templateProperties.getHeader === 'function') ? lastItem.templateProperties.getHeader(item) : lastItem.templateProperties.header;
            item.rows = [];
            lastItem.templateProperties.rows.forEach(function (row) {
                if (typeof row.show !== 'function' || row.show(item)) {
                    item.rows.push({
                        data: row.data(item),
                        label: (typeof row.getLabel === 'function') ? row.getLabel(item) : row.label,
                        template: row.template,
                        className: row.className,
                    });
                }
            });
            this_1.featureItems.push(item);
        };
        var this_1 = this;
        try {
            for (var featureData_1 = __values(featureData), featureData_1_1 = featureData_1.next(); !featureData_1_1.done; featureData_1_1 = featureData_1.next()) {
                var lastItem = featureData_1_1.value;
                _loop_1(lastItem);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (featureData_1_1 && !featureData_1_1.done && (_a = featureData_1.return)) _a.call(featureData_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
    };
    MapModuleInfoComponent.prototype.stopTimeout = function () {
        window.clearTimeout(this.msgTimeout);
        this.msgTimeout = null;
    };
    return MapModuleInfoComponent;
}());
exports.MapModuleInfoComponent = MapModuleInfoComponent;
