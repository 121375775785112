"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var list_service_1 = require("@app/common/services/list.service");
var cadastre_import_model_1 = require("@app/settings/modules/models/cadastre-import.model");
var CadastreDataImportListComponent = /** @class */ (function () {
    function CadastreDataImportListComponent(cdr, listService, restangularPromap) {
        this.cdr = cdr;
        this.listService = listService;
        this.restangularPromap = restangularPromap;
    }
    Object.defineProperty(CadastreDataImportListComponent.prototype, "newImport", {
        set: function (newImport) {
            if (newImport) {
                this.onCadastreDataImportAdded(newImport);
            }
        },
        enumerable: true,
        configurable: true
    });
    CadastreDataImportListComponent.prototype.ngOnInit = function () {
        this.onCadastreDataImportAdded = this.onCadastreDataImportAdded.bind(this);
        this.getLastFiveImports();
    };
    CadastreDataImportListComponent.prototype.onCadastreDataImportAdded = function (dataImport) {
        this.list.list.unshift(dataImport);
        this.list.list.pop();
        this.cdr.markForCheck();
    };
    CadastreDataImportListComponent.prototype.getLastFiveImports = function () {
        var _this = this;
        var filters = {
            sortOrder: [{
                    sortBy: 'id',
                    direction: 'desc'
                }],
            limit: 5,
            offset: 0,
        };
        this.list = this.listService.createList('cuzk/data-import', filters, this.restangularPromap, undefined);
        this.listService.fetchResult(this.list)
            .finally(function () {
            _this.list.loading = false;
            _this.cdr.markForCheck();
        });
    };
    return CadastreDataImportListComponent;
}());
exports.CadastreDataImportListComponent = CadastreDataImportListComponent;
