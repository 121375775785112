"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var AutofocusDirective = /** @class */ (function () {
    function AutofocusDirective(el) {
        this.el = el;
    }
    AutofocusDirective.prototype.ngOnInit = function () {
        this.el.nativeElement.focus();
    };
    return AutofocusDirective;
}());
exports.AutofocusDirective = AutofocusDirective;
