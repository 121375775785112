"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var _ = require("lodash");
var word_service_1 = require("@app/common/services/word.service");
var i0 = require("@angular/core");
var i1 = require("../../common/services/word.service");
var ParcelService = /** @class */ (function () {
    function ParcelService(wordService) {
        this.wordService = wordService;
        this.groupOccupationsByParcel = this.groupOccupationsByParcel.bind(this);
        this.groupOccupationsByParcelSingle = this.groupOccupationsByParcelSingle.bind(this);
        this.getTemporaryOccupationName = this.getTemporaryOccupationName.bind(this);
        this._groupOccupationsByParcel = this._groupOccupationsByParcel.bind(this);
    }
    ParcelService.prototype.getGroupKey = function (zabtyp) {
        if (zabtyp === 1 || zabtyp === 2 || zabtyp === 3 || zabtyp === 4 || zabtyp === 7 || zabtyp === 8) {
            return 'permanent';
        }
        if (zabtyp === 5 || zabtyp === 9) {
            return 'underOneYear';
        }
        if (zabtyp === 6 || zabtyp === 10) {
            return 'overOneYear';
        }
        return 'other';
    };
    ParcelService.prototype.groupOccupationsByParcel = function (occupations, parcelsData) {
        if (parcelsData === void 0) { parcelsData = null; }
        return this._groupOccupationsByParcel(occupations, parcelsData, false);
    };
    ParcelService.prototype.groupOccupationsByParcelSingle = function (occupations, parcelsData) {
        if (parcelsData === void 0) { parcelsData = null; }
        return this._groupOccupationsByParcel(occupations, parcelsData, true);
    };
    ParcelService.prototype.getTemporaryOccupationName = function (group) {
        if (!group || !group[0] || !group[0].occupations || !group[0].occupations[0]) {
            return '';
        }
        return group[0].occupations[0].zabtyptx
            .replace(/dočasný zábor/i, this.wordService.getTranslation('TEMPORARY_OCCUPATION'));
    };
    /**
     * Identifies type of occupations and groups permanent type occupations on same parcel into one object.
     * @param {type} data
     * @param {type} parcelsData
     * @param {type} singleLevel
     * @returns {Array}
     */
    ParcelService.prototype._groupOccupationsByParcel = function (data, parcelsData, singleLevel) {
        var _this = this;
        singleLevel = singleLevel === true;
        var dataOccupationGroups = singleLevel ? [] : {};
        data.forEach(function (occupation) {
            var groupKey = _this.getGroupKey(occupation.zabtyp);
            var occupationGrouped;
            if (!singleLevel && !dataOccupationGroups[groupKey]) {
                dataOccupationGroups[groupKey] = [];
            }
            if (groupKey === 'permanent') {
                var itemFound = _.find(singleLevel ? dataOccupationGroups : dataOccupationGroups[groupKey], function (group) {
                    return (!singleLevel || group.key === groupKey) && occupation.parcel && group.parcel.id === occupation.parcel.id;
                });
                if (itemFound) {
                    itemFound.occupations.push(occupation);
                    return;
                }
                else if (occupation.parcel) {
                    if (parcelsData instanceof Array) {
                        parcelsData.push(occupation.parcel);
                    }
                    occupationGrouped = {
                        id: groupKey + '_' + occupation.parcel.id,
                        key: groupKey,
                        parcel: occupation.parcel,
                        occupations: [occupation],
                    };
                }
                else {
                    // without parcel
                    return;
                }
            }
            else {
                if (parcelsData instanceof Array) {
                    parcelsData.push(occupation.parcel);
                }
                occupationGrouped = {
                    id: groupKey + '_' + occupation.id,
                    key: groupKey,
                    parcel: occupation.parcel,
                    occupations: [occupation],
                };
            }
            (singleLevel ? dataOccupationGroups : dataOccupationGroups[groupKey]).push(occupationGrouped);
        });
        return dataOccupationGroups;
    };
    ParcelService.ngInjectableDef = i0.defineInjectable({ factory: function ParcelService_Factory() { return new ParcelService(i0.inject(i1.WordService)); }, token: ParcelService, providedIn: "root" });
    return ParcelService;
}());
exports.ParcelService = ParcelService;
