"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./terms-of-use.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../button/button.component.ngfactory");
var i3 = require("../button/button.component");
var i4 = require("@angular/common");
var i5 = require("@angular/forms");
var i6 = require("../../directives/loading.directive");
var i7 = require("./terms-of-use.component");
var i8 = require("../../services/auth.service");
var i9 = require("../../services/modules.service");
var styles_TermsOfUseComponent = [i0.styles];
var RenderType_TermsOfUseComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TermsOfUseComponent, data: {} });
exports.RenderType_TermsOfUseComponent = RenderType_TermsOfUseComponent;
function View_TermsOfUseComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "gmt-button", [["class", "fr"]], null, null, null, i2.View_ButtonComponent_0, i2.RenderType_ButtonComponent)), i1.ɵdid(2, 114688, null, 0, i3.ButtonComponent, ["ErrorHandlerService", i1.ChangeDetectorRef], { clickAction: [0, "clickAction"] }, null), (_l()(), i1.ɵted(-1, 0, ["Upravit"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.enableEditing; _ck(_v, 2, 0, currVal_0); }, null); }
function View_TermsOfUseComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "gmt-button", [["class", "fr"]], null, null, null, i2.View_ButtonComponent_0, i2.RenderType_ButtonComponent)), i1.ɵdid(1, 278528, null, 0, i4.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "disabled": 0 }), i1.ɵdid(3, 114688, null, 0, i3.ButtonComponent, ["ErrorHandlerService", i1.ChangeDetectorRef], { clickAction: [0, "clickAction"] }, null), (_l()(), i1.ɵted(-1, 0, ["Ulo\u017Eit"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "fr"; var currVal_1 = _ck(_v, 2, 0, !_co.conditionsChanged()); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _co.saveTermsOfUse; _ck(_v, 3, 0, currVal_2); }, null); }
function View_TermsOfUseComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "textarea", [["class", "tall"], ["name", "string"], ["rows", "120"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 2)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 2)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.termsOfUse = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 16384, null, 0, i5.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i5.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i5.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i5.DefaultValueAccessor]), i1.ɵdid(4, 671744, null, 0, i5.NgModel, [[8, null], [8, null], [8, null], [6, i5.NG_VALUE_ACCESSOR]], { name: [0, "name"], isDisabled: [1, "isDisabled"], model: [2, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i5.NgControl, null, [i5.NgModel]), i1.ɵdid(6, 16384, null, 0, i5.NgControlStatus, [[4, i5.NgControl]], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "mt-10"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TermsOfUseComponent_2)), i1.ɵdid(9, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["saveButton", 2]], null, 0, null, View_TermsOfUseComponent_3))], function (_ck, _v) { var _co = _v.component; var currVal_7 = "string"; var currVal_8 = !_co.editable; var currVal_9 = _co.termsOfUse; _ck(_v, 4, 0, currVal_7, currVal_8, currVal_9); var currVal_10 = !_co.editable; var currVal_11 = i1.ɵnov(_v, 10); _ck(_v, 9, 0, currVal_10, currVal_11); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 6).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 6).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 6).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 6).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 6).ngClassValid; var currVal_5 = i1.ɵnov(_v, 6).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 6).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
function View_TermsOfUseComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], [[8, "className", 0]], null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i6.LoadingDirective, [i1.ElementRef], { loading: [0, "loading"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TermsOfUseComponent_1)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.loading; _ck(_v, 1, 0, currVal_1); var currVal_2 = !_co.loading; _ck(_v, 3, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).elementClass; _ck(_v, 0, 0, currVal_0); }); }
exports.View_TermsOfUseComponent_0 = View_TermsOfUseComponent_0;
function View_TermsOfUseComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "terms-of-use", [], null, null, null, View_TermsOfUseComponent_0, RenderType_TermsOfUseComponent)), i1.ɵdid(1, 114688, null, 0, i7.TermsOfUseComponent, ["APPLICATIONS", i8.AuthService, i9.ModulesService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_TermsOfUseComponent_Host_0 = View_TermsOfUseComponent_Host_0;
var TermsOfUseComponentNgFactory = i1.ɵccf("terms-of-use", i7.TermsOfUseComponent, View_TermsOfUseComponent_Host_0, { appName: "appName" }, {}, []);
exports.TermsOfUseComponentNgFactory = TermsOfUseComponentNgFactory;
