"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var geometric_plan_request_type_enum_1 = require("@app/ps/enums/geometric-plan-request-type.enum");
var geometric_plan_request_status_enum_1 = require("@app/ps/enums/geometric-plan-request-status.enum");
var approve_enum_1 = require("@app/ps/enums/approve.enum");
var document_type_model_1 = require("../../common/models/document-type.model");
var auth_service_1 = require("@app/common/services/auth.service");
var i0 = require("@angular/core");
var i1 = require("../../common/services/auth.service");
var GeometricPlanRequestService = /** @class */ (function () {
    function GeometricPlanRequestService(restangular, uploadService, authService) {
        this.restangular = restangular;
        this.uploadService = uploadService;
        this.authService = authService;
    }
    GeometricPlanRequestService.prototype.create = function (geometricPlanRequest, notes) {
        var _this = this;
        if (notes === void 0) { notes = []; }
        var createdGeometricPlanRequest;
        return this.restangular
            .all('geometric-plan-requests/create')
            .customPOST(geometricPlanRequest, '', { loadCollections: ['areas', 'constructionObjects', 'parcels'] })
            .then(function (response) {
            createdGeometricPlanRequest = response.plain();
            return _this.createNotes(createdGeometricPlanRequest, notes);
        })
            .then(function () { return createdGeometricPlanRequest; });
    };
    GeometricPlanRequestService.prototype.createFromExisting = function (geometricPlanRequest, gpFiles, gpVfkFiles, gpDocFiles) {
        var _this = this;
        if (gpFiles === void 0) { gpFiles = []; }
        if (gpVfkFiles === void 0) { gpVfkFiles = []; }
        if (gpDocFiles === void 0) { gpDocFiles = []; }
        var createdGeometricPlanRequest;
        return this.restangular
            .all('geometric-plan-requests/create-from-existing')
            .customPOST(geometricPlanRequest, '', { loadCollections: ['areas'] })
            .then(function (response) {
            createdGeometricPlanRequest = response.plain();
        })
            .then(function () { return _this.createDocuments(createdGeometricPlanRequest, gpFiles, 'GEOMETRIC_PLAN'); })
            .then(function () { return _this.createDocuments(createdGeometricPlanRequest, gpVfkFiles, 'GEOMETRIC_PLAN_VFK'); })
            .then(function () { return _this.createDocuments(createdGeometricPlanRequest, gpDocFiles, 'GEOMETRIC_PLAN_DOCUMENTATION'); })
            .then(function () { return createdGeometricPlanRequest; });
    };
    GeometricPlanRequestService.prototype.get = function (id, collections) {
        if (collections === void 0) { collections = []; }
        return this.restangular
            .one('geometric-plan-requests', id)
            .get({ loadCollections: collections });
    };
    GeometricPlanRequestService.prototype.update = function (geometricPlanRequest, notes, gpFiles, gpVfkFiles, gpDocFiles, gpOtherFiles) {
        var _this = this;
        if (notes === void 0) { notes = []; }
        if (gpFiles === void 0) { gpFiles = []; }
        if (gpVfkFiles === void 0) { gpVfkFiles = []; }
        if (gpDocFiles === void 0) { gpDocFiles = []; }
        if (gpOtherFiles === void 0) { gpOtherFiles = []; }
        var updatedGeometricPlanRequest;
        return this.restangular
            .one('geometric-plan-requests', geometricPlanRequest.id)
            .customPUT(geometricPlanRequest, '', { loadCollections: ['areas', 'constructionObjects', 'parcels'] })
            .then(function (response) {
            updatedGeometricPlanRequest = response.plain();
            return _this.createNotes(updatedGeometricPlanRequest, notes);
        })
            .then(function () { return _this.createDocuments(updatedGeometricPlanRequest, gpFiles, 'GEOMETRIC_PLAN'); })
            .then(function () { return _this.createDocuments(updatedGeometricPlanRequest, gpVfkFiles, 'GEOMETRIC_PLAN_VFK'); })
            .then(function () { return _this.createDocuments(updatedGeometricPlanRequest, gpDocFiles, 'GEOMETRIC_PLAN_DOCUMENTATION'); })
            .then(function () { return _this.createDocuments(updatedGeometricPlanRequest, gpOtherFiles, 'OTHER'); })
            .then(function () { return updatedGeometricPlanRequest; });
    };
    GeometricPlanRequestService.prototype.createNotes = function (geometricPlanRequest, notes) {
        var _this = this;
        if (notes === void 0) { notes = []; }
        var promises = notes.map(function (note) {
            return _this.restangular
                .all('notes/create')
                .customPOST(__assign({}, note, { geometricPlanRequest: geometricPlanRequest }));
        });
        return Promise.all(promises);
    };
    GeometricPlanRequestService.prototype.createDocuments = function (geometricPlan, files, type) {
        var _this = this;
        var restBaseUrl = this.authService.getActiveApplicationRestUrl();
        var attachmentType = document_type_model_1.DocumentTypeModel.createById(type).id;
        var promises = files.map(function (file) {
            var sendData = {
                file: file.fileEntry,
                filename: file.fileEntry.name,
                geometricPlanRequestId: geometricPlan.id,
                attachmentType: attachmentType
            };
            return _this.uploadService.upload({
                url: restBaseUrl + '/attachments',
                data: sendData,
                headers: __assign({}, _this.restangular.defaultHeaders)
            });
        });
        return Promise.all(promises);
    };
    GeometricPlanRequestService.prototype.getEnumLabel = function (type, value) {
        var src;
        switch (type) {
            case 'type':
                src = geometric_plan_request_type_enum_1.geometricPlanRequestTypeOptions;
                break;
            case 'status':
                src = geometric_plan_request_status_enum_1.geometricPlanRequestStatusOptions;
                break;
            case 'approve':
                src = approve_enum_1.approveOptions;
                break;
        }
        return src.find(function (option) { return option.id === value; }).name;
    };
    GeometricPlanRequestService.prototype.getStatusEnumAction = function (value) {
        var option = geometric_plan_request_status_enum_1.geometricPlanRequestStatusOptions.find(function (option) { return option.id === value; });
        return (option.action ? option.action : option.name);
    };
    GeometricPlanRequestService.prototype.isStatusDialog = function (status) {
        return geometric_plan_request_status_enum_1.geometricPlanRequestStatusOptions.find(function (option) { return option.id === status; }).dialog;
    };
    GeometricPlanRequestService.ngInjectableDef = i0.defineInjectable({ factory: function GeometricPlanRequestService_Factory() { return new GeometricPlanRequestService(i0.inject("Restangular"), i0.inject("Upload"), i0.inject(i1.AuthService)); }, token: GeometricPlanRequestService, providedIn: "root" });
    return GeometricPlanRequestService;
}());
exports.GeometricPlanRequestService = GeometricPlanRequestService;
