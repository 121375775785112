"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var checklist_service_1 = require("@app/common/services/checklist.service");
var layers_config_1 = require("@app/ps/map/config/layers-config");
var map_config_service_1 = require("@app/map/services/map-config.service");
var new_map_config_service_1 = require("@app/map/services/new-map-config.service");
var project_1 = require("@app/models/project");
var vector_layer_style_model_1 = require("@app/ps/map/models/vector-layer-style.model");
var MapSettingsComponent = /** @class */ (function () {
    function MapSettingsComponent(applications, APP_BRAND, cdr, checklistService, newMapConfigService, mapConfigService, restangularSettings) {
        this.applications = applications;
        this.APP_BRAND = APP_BRAND;
        this.cdr = cdr;
        this.checklistService = checklistService;
        this.newMapConfigService = newMapConfigService;
        this.mapConfigService = mapConfigService;
        this.restangularSettings = restangularSettings;
        this.loaded = new core_1.EventEmitter();
        this.defaults = {
            activeLayerGroups: undefined,
            activeLayers: undefined,
            availableLayerGroups: undefined,
        };
        this.immutableLayerNamePattern = '$';
    }
    MapSettingsComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.onLayerNameEdit = this.onLayerNameEdit.bind(this);
                        this.onVisibilityChange = this.onVisibilityChange.bind(this);
                        this.onSubmit = this.onSubmit.bind(this);
                        this.mapLayerToForm = this.mapLayerToForm.bind(this);
                        return [4 /*yield*/, this.initializeData()];
                    case 1:
                        _a.sent();
                        this.cdr.markForCheck();
                        return [2 /*return*/];
                }
            });
        });
    };
    MapSettingsComponent.prototype.onBackgroundClick = function () {
        this.currentColorLayer = undefined;
    };
    MapSettingsComponent.prototype.onLayerNameBlur = function (layer) {
        this.setCurrentlyEditedLayer(undefined);
        if (layer.title.trim() === '') {
            layer.title = layer.defaultTitle;
        }
    };
    MapSettingsComponent.prototype.onLayerNameEdit = function (layer) {
        if (this.isEditable(layer)) {
            this.setCurrentlyEditedLayer(layer);
        }
    };
    MapSettingsComponent.prototype.onLayerStyleResetToDefault = function (layer) {
        layer.style = layer.defaultStyle;
    };
    MapSettingsComponent.prototype.onLayerSymbolClick = function (layer, $event) {
        this.currentColorLayer = layer;
        $event.stopPropagation();
    };
    MapSettingsComponent.prototype.onLayerStyleChange = function (layer, $event) {
        layer.style = $event;
    };
    MapSettingsComponent.prototype.onVisibilityChange = function (layer) {
        layer.visible = !layer.visible;
    };
    MapSettingsComponent.prototype.onSubmit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var payload;
            return __generator(this, function (_a) {
                payload = this.prepareLayersPayload();
                return [2 /*return*/, this.restangularSettings.one("application/" + this.applications.sy.name + "/project/" + this.project.key + "/map").customPUT(payload)];
            });
        });
    };
    MapSettingsComponent.prototype.getActiveLayerGroups = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.newMapConfigService.getLayersConfig(this.applications.sy.name, this.project.key, true)];
            });
        });
    };
    /**
     * Find layer groups with at least one active layer.
     */
    MapSettingsComponent.prototype.getActiveLayerGroupsToSubmit = function () {
        var _this = this;
        var mapConfig = layers_config_1.getLayersConfig(this.project);
        return mapConfig.layers
            .filter(function (layerGroup) { return layerGroup.layers.some(function (layer) { return _this.layersChecklist.checkedItems.find(function (checkedLayer) { return checkedLayer.id === layer; }); }); });
    };
    MapSettingsComponent.prototype.getActiveLayers = function () {
        var layerGroups = this.defaults.activeLayerGroups;
        var reduce = function (accumulator, curVal) {
            accumulator.push.apply(accumulator, __spread(curVal));
            accumulator.push.apply(accumulator, __spread(curVal.filter(function (l) { return l.layers; }).map(function (l) { return l.layers; }).reduce(reduce, [])));
            return accumulator;
        };
        var result = layerGroups
            .map(function (lg) { return lg.layers; })
            .reduce(reduce, []);
        return result;
    };
    MapSettingsComponent.prototype.getAvailableLayerGroups = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getLayersConfig()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    MapSettingsComponent.prototype.mapLayerToForm = function (layer) {
        var customized = this.defaults.activeLayers.find(function (l) { return l.id === layer.id; }) || {};
        var customLayer = {
            id: customized.id || layer.id,
            title: customized.title || layer.title,
            defaultTitle: layer.origTitle,
            visible: customized.visible,
            stylable: layer.type === 'wfs',
            description: layer.description,
            layers: layer.layers ? layer.layers.map(this.mapLayerToForm).reverse() : undefined,
        };
        if (customLayer.stylable) {
            var style = customized && customized.style;
            var defaultStyle = this.getDefaultLayerGeoJSONStyle(layer);
            customLayer.style = style ? new vector_layer_style_model_1.VectorLayerStyleModel(style.fill, style.stroke) : this.getDefaultLayerGeoJSONStyle(layer);
            customLayer.defaultStyle = defaultStyle;
        }
        return customLayer;
    };
    MapSettingsComponent.prototype.getLayersConfig = function () {
        var _this = this;
        var mapConfig = layers_config_1.getLayersConfig(this.project);
        var mapId = 'main-map';
        return this.mapConfigService.setLayers({
            type: 'node',
            selector: 'hidden',
            layers: mapConfig.layers,
        }, mapId).then(function (mc) {
            var layerGroups = mc.layers.map(function (layerGroup) {
                layerGroup.layers = layerGroup.layers.map(_this.mapLayerToForm);
                layerGroup.layers.reverse(); // put more important layers on top
                return layerGroup;
            });
            layerGroups.reverse(); // put more important layergroups on top
            return layerGroups;
        });
    };
    /**
     * Expects vector layers to have the default style set in app/js/common/map/services/layersConfig.js.
     *
     * @throws
     */
    MapSettingsComponent.prototype.getDefaultLayerGeoJSONStyle = function (layer) {
        var defaultStyle = layer.geojson && layer.geojson.style;
        if (!defaultStyle || !defaultStyle.fillColor || !defaultStyle.color || !defaultStyle.opacity || !defaultStyle.fillOpacity) {
            throw new Error('No default style for given layer.');
        }
        var style = vector_layer_style_model_1.VectorLayerStyleModel.fromRgbStrings(defaultStyle.fillColor, defaultStyle.color);
        style.fill.a = defaultStyle.fillOpacity;
        style.stroke.a = defaultStyle.opacity;
        return style;
    };
    MapSettingsComponent.prototype.initializeData = function () {
        return __awaiter(this, void 0, void 0, function () {
            var activeLayerGroups, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!!this.mapDefaults) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.getActiveLayerGroups()];
                    case 1:
                        activeLayerGroups = _b.sent();
                        this.defaults.activeLayerGroups = activeLayerGroups.layers;
                        this.defaults.activeLayers = this.getActiveLayers();
                        _a = this.defaults;
                        return [4 /*yield*/, this.getAvailableLayerGroups()];
                    case 2:
                        _a.availableLayerGroups = _b.sent();
                        this.loaded.emit({
                            defaults: this.defaults,
                            onSave: this.onSubmit,
                        });
                        return [3 /*break*/, 4];
                    case 3:
                        this.defaults = this.mapDefaults;
                        _b.label = 4;
                    case 4:
                        this.layersChecklist = this.checklistService.get(this.defaults.activeLayers);
                        return [2 /*return*/];
                }
            });
        });
    };
    MapSettingsComponent.prototype.isEditable = function (layer) {
        return !layer.defaultTitle.includes(this.immutableLayerNamePattern);
    };
    MapSettingsComponent.prototype.prepareLayersPayload = function () {
        var _this = this;
        var activeLayerGroups = this.getActiveLayerGroupsToSubmit();
        return activeLayerGroups.map(function (layerGroup) {
            return {
                id: layerGroup.id,
                type: layerGroup.type,
                title: layerGroup.title,
                selector: layerGroup.selector,
                layers: layerGroup.layers.map(function (layer) {
                    var checkedLayer = _this.layersChecklist.checkedItems.find(function (checkedLayer) { return checkedLayer.id === layer; });
                    if (!checkedLayer) {
                        return;
                    }
                    var availableLayers = _this.defaults.availableLayerGroups.map(function (l) { return l.layers; }).reduce(function (accum, layers) { return accum.concat(layers); }, []);
                    var currentLayer = availableLayers.find(function (availableLayer) { return availableLayer.id === checkedLayer.id; });
                    var result = {
                        id: checkedLayer.id,
                        title: currentLayer.title || currentLayer.defaultTitle,
                        visible: currentLayer.visible,
                        layers: currentLayer.layers,
                    };
                    if (currentLayer.stylable && Object.keys(currentLayer.style).length > 0) {
                        result.style = currentLayer.style;
                    }
                    return result;
                }).filter(function (layer) { return layer !== undefined; }),
            };
        });
    };
    MapSettingsComponent.prototype.setCurrentlyEditedLayer = function (layer) {
        this.currentlyEditedLayer = layer;
    };
    return MapSettingsComponent;
}());
exports.MapSettingsComponent = MapSettingsComponent;
