"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var map_service_1 = require("@app/map/services/map.service");
var L = require("leaflet");
var getVertexIcon = function () { return new L.DivIcon({
    iconSize: new L.Point(8, 8),
    className: "leaflet-div-icon leaflet-editing-icon leaflet-touch-icon"
}); };
var ɵ0 = getVertexIcon;
exports.ɵ0 = ɵ0;
// Localize strings in L.drawLocal, used for version 0.2.4-dev. Hopefully it will change in future versions,
// that's why we keep it there and don't put it in cm-angular-map component
var setDrawLocalization = function () {
    // Localize strings in L.drawLocal, used for version 0.2.4-dev. Hopefully it will change in future versions,
    // that's why we keep it there and don't put it in cm-angular-map component
    L.drawLocal.draw.toolbar.buttons.polyline = "Měření vzdálenosti";
    L.drawLocal.draw.toolbar.buttons.polygon = "Měření plochy";
    L.drawLocal.draw.toolbar.actions.title = "Zrušit měření";
    L.drawLocal.draw.toolbar.actions.text = "Zrušit";
    L.drawLocal.draw.toolbar.finish.title = "Ukončit měření";
    L.drawLocal.draw.toolbar.finish.text = "Ukončit";
    L.drawLocal.draw.toolbar.undo.title = "Smazat poslední označený bod";
    L.drawLocal.draw.toolbar.undo.text = "Smazat poslední bod";
    setDrawMeasurePolylineLocalization();
    L.drawLocal.draw.handlers.polygon.tooltip.start = "Klikněte a začněte vyznačovat plochu.";
    L.drawLocal.draw.handlers.polygon.tooltip.cont = "Klikněte pro pokračování.";
    L.drawLocal.draw.handlers.polygon.tooltip.end = "Kliknutím na první bod ukončete měření.";
};
var ɵ1 = setDrawLocalization;
exports.ɵ1 = ɵ1;
var setDrawMeasurePolylineLocalization = function () {
    L.drawLocal.draw.handlers.polyline.error = '<strong>Chyba:</strong> okraje oblasti se nemohou křížit.';
    L.drawLocal.draw.handlers.polyline.tooltip.start = "Klikněte a začněte měřit.";
    L.drawLocal.draw.handlers.polyline.tooltip.cont = "Klikněte pro pokračování.";
    L.drawLocal.draw.handlers.polyline.tooltip.end = "Kliknutím na poslední bod ukončete měření.";
};
var ɵ2 = setDrawMeasurePolylineLocalization;
exports.ɵ2 = ɵ2;
var MapModuleMeasureComponent = /** @class */ (function () {
    function MapModuleMeasureComponent(mapService) {
        this.mapService = mapService;
        this.measureOptions = [
            { id: 'polyline', value: 'Linie' },
            { id: 'polygon', value: 'Plochy' }
        ];
        this.measureOptionsSelections = 'polyline';
        this.draw = null;
        this.onDrawCreated = this.onDrawCreated.bind(this);
    }
    MapModuleMeasureComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.draw = new L.Control.Draw({
            // position: 'topleft',
            draw: {
                polyline: {
                    showLength: true,
                    metric: true,
                    feet: false,
                    icon: getVertexIcon(),
                    precision: {
                        m: 1,
                    },
                },
                polygon: {
                    allowIntersection: false,
                    showArea: true,
                    icon: getVertexIcon(),
                },
                rectangle: false,
                circle: false,
                circlemarker: false,
                marker: false
            }
        });
        this.mapService.getMap(this.mapId).then(function (map) {
            L.DomEvent.on(map._container, 'keyup', _this._cancelDrawing, _this);
            map.addControl(_this.draw);
            //remove toolbar from map
            _this.draw._container.parentNode.removeChild(_this.draw._container);
            setDrawLocalization();
        });
        this.onChangedMeasureType('polyline');
    };
    MapModuleMeasureComponent.prototype.ngOnDestroy = function () {
        var _this = this;
        this.mapService.getMap(this.mapId).then(function (map) {
            map.removeControl(_this.draw);
            map.off(L.Draw.Event.CREATED, _this.onDrawCreated);
            L.DomEvent.off(map._container, 'keyup', _this._cancelDrawing, _this);
        });
    };
    MapModuleMeasureComponent.prototype.onDrawCreated = function () {
        this.onChangedMeasureType(this.measureOptionsSelections);
    };
    MapModuleMeasureComponent.prototype.onChangedMeasureType = function (newVal) {
        var _this = this;
        //stop previous measure
        //detect if some measure tool is active
        if (this.draw._toolbars && this.draw._toolbars.draw && (this.draw._toolbars.draw._activeMode)) {
            this.draw._toolbars.draw._activeMode.handler.disable();
        }
        //start new measure
        this.mapService.getMap(this.mapId).then(function (map) {
            _this.draw._toolbars.draw._modes[newVal].handler.enable();
            map.once(L.Draw.Event.CREATED, _this.onDrawCreated);
        });
    };
    MapModuleMeasureComponent.prototype._cancelDrawing = function (e) {
        if (e.keyCode === 27) {
            this.onChangedMeasureType(this.measureOptionsSelections);
        }
    };
    return MapModuleMeasureComponent;
}());
exports.MapModuleMeasureComponent = MapModuleMeasureComponent;
