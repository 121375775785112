"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var OpinionBasicFormComponent = /** @class */ (function () {
    function OpinionBasicFormComponent() {
    }
    OpinionBasicFormComponent.prototype.ngOnInit = function () {
    };
    return OpinionBasicFormComponent;
}());
exports.OpinionBasicFormComponent = OpinionBasicFormComponent;
