"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var L = require("leaflet");
var leaflet_fullscreen_1 = require("leaflet.fullscreen");
var i0 = require("@angular/core");
var MapFullScreenService = /** @class */ (function () {
    function MapFullScreenService() {
    }
    MapFullScreenService.prototype.getFullscreenControl = function (opts) {
        var FullscreenControl = L.Control.FullScreen.extend({
            toggleFullScreen: function (map) {
                map._exitFired = false;
                if (map._isFullscreen) {
                    if (leaflet_fullscreen_1.fullScreenApi.supportsFullScreen && !this.options.forcePseudoFullscreen) {
                        leaflet_fullscreen_1.fullScreenApi.cancelFullScreen(map._container);
                    }
                    else {
                        L.DomUtil.removeClass(map._container, 'leaflet-pseudo-fullscreen');
                    }
                    map.invalidateSize();
                    map.fire('exitFullscreen');
                    map._exitFired = true;
                    map._isFullscreen = false;
                }
                else {
                    if (leaflet_fullscreen_1.fullScreenApi.supportsFullScreen && !this.options.forcePseudoFullscreen) {
                        leaflet_fullscreen_1.fullScreenApi.requestFullScreen(map._container);
                    }
                    else {
                        L.DomUtil.addClass(map._container, 'leaflet-pseudo-fullscreen');
                    }
                    map.invalidateSize();
                    map.fire('enterFullscreen');
                    map._isFullscreen = true;
                }
            }
        });
        return new FullscreenControl(opts);
    };
    MapFullScreenService.ngInjectableDef = i0.defineInjectable({ factory: function MapFullScreenService_Factory() { return new MapFullScreenService(); }, token: MapFullScreenService, providedIn: "root" });
    return MapFullScreenService;
}());
exports.MapFullScreenService = MapFullScreenService;
