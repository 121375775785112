"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var dialog_service_1 = require("@app/dialog/dialog.service");
var dialog_config_1 = require("@app/dialog/dialog-config");
var dialog_ref_1 = require("@app/dialog/dialog-ref");
var _ = require("lodash");
var auth_service_1 = require("@app/common/services/auth.service");
var project_service_1 = require("@app/ps/services/project.service");
var login_service_1 = require("@app/common/services/login.service");
var ProjectRemoveComponent = /** @class */ (function () {
    function ProjectRemoveComponent(helpIds, localStorageService, errorHandlerService, restangular, projectService, loginService, authService, dialogService, config, dialog) {
        this.helpIds = helpIds;
        this.localStorageService = localStorageService;
        this.errorHandlerService = errorHandlerService;
        this.restangular = restangular;
        this.projectService = projectService;
        this.loginService = loginService;
        this.authService = authService;
        this.dialogService = dialogService;
        this.config = config;
        this.dialog = dialog;
        this.project = config.data.project;
        this.confirm = this.confirm.bind(this);
        this.decline = this.decline.bind(this);
        this.confirmPhase = this.confirmPhase.bind(this);
    }
    ProjectRemoveComponent.prototype.ngOnInit = function () {
    };
    ProjectRemoveComponent.prototype.confirm = function () {
        var _this = this;
        return this.restangular.one('projects', this.project.id).remove({ password: this.password })
            .then(function () {
            _.remove(_this.authService.getActualProjects(), { id: _this.project.id });
            _this.localStorageService.set('auth-projects', _this.authService.getActualProjects());
            _this.dialog.close();
            if (_this.authService.getActualProjects().length > 0) {
                _this.projectService.changeProject(_this.authService.getActualProjects()[0]);
            }
            else {
                return _this.loginService.redirectToLogin(false);
            }
        });
    };
    ProjectRemoveComponent.prototype.decline = function () {
        this.dialog.close();
    };
    ProjectRemoveComponent.prototype.confirmPhase = function () {
        this.phase = 'confirm';
    };
    return ProjectRemoveComponent;
}());
exports.ProjectRemoveComponent = ProjectRemoveComponent;
