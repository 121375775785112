"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var list_service_1 = require("@app/common/services/list.service");
var _ = require("lodash");
var i0 = require("@angular/core");
var i1 = require("../../common/services/list.service");
var StatisticsService = /** @class */ (function () {
    function StatisticsService(EVENTS, authService, localStorageService, listService) {
        this.EVENTS = EVENTS;
        this.authService = authService;
        this.localStorageService = localStorageService;
        this.listService = listService;
        this.removeLoadedStatistics = this.removeLoadedStatistics.bind(this);
        this.authService.registerCallback(EVENTS.authProjectUpdated, this.removeLoadedStatistics);
    }
    StatisticsService.prototype.getStatistics = function () {
        if (!this.statisticsSettings) {
            this.statisticsSettings = this.listService.createList('statistics', { sortOrder: [{ sortBy: 'statisticsOrder' }, { sortBy: 'name' }] });
            this.listService.fetchResult(this.statisticsSettings);
        }
        return this.statisticsSettings;
    };
    StatisticsService.prototype.getStatisticsFilters = function () {
        if (!this.statisticsFilterSettings) {
            this.statisticsFilterSettings = this.listService.createList('statistics/filters', {
                filters: { available: true },
                sortOrder: [{ sortBy: 'statistics.statisticsOrder' }, { sortBy: 'name' }]
            });
            this.listService.fetchResult(this.statisticsFilterSettings);
        }
        return this.statisticsFilterSettings;
    };
    StatisticsService.prototype.removeLoadedStatistics = function () {
        if (this.statisticsSettings) {
            this.listService.cancelFetch(this.statisticsSettings);
            this.statisticsSettings = undefined;
        }
        this.removeLoadedStatisticsFilters();
    };
    StatisticsService.prototype.updateStorageValue = function (statistics) {
        var key = this.geStorageKey();
        if (key) {
            this.localStorageService.set(key, _.assign(this.getStorageValue(), statistics));
        }
    };
    StatisticsService.prototype.getStorageKeyValue = function (valueKey) {
        var value = this.getStorageValue();
        if (value && value[valueKey] !== undefined) {
            return value[valueKey];
        }
        value = this.getDefaultValue();
        if (value[valueKey] !== undefined) {
            return value[valueKey];
        }
    };
    StatisticsService.prototype.getDefaultKeyValue = function (valueKey) {
        var value = this.getDefaultValue();
        if (value) {
            return value[valueKey];
        }
    };
    StatisticsService.prototype.removeLoadedStatisticsFilters = function () {
        if (this.statisticsFilterSettings) {
            this.listService.cancelFetch(this.statisticsFilterSettings);
            this.statisticsFilterSettings = undefined;
        }
    };
    StatisticsService.prototype.geStorageKey = function () {
        return this.authService.getActualProject()
            ? this.authService.getActualProject().key + '.' + 'homeSelectedStatistics'
            : null;
    };
    StatisticsService.prototype.getDefaultValue = function () {
        return {
            entityTab: 'cases',
            categoryTab: 1,
            intervalTab: 0,
        };
    };
    StatisticsService.prototype.getStorageValue = function () {
        var key = this.geStorageKey();
        if (!key) {
            return null;
        }
        var value = this.localStorageService.get(key);
        if (!value || typeof value !== 'object') {
            value = {};
        }
        return value;
    };
    StatisticsService.ngInjectableDef = i0.defineInjectable({ factory: function StatisticsService_Factory() { return new StatisticsService(i0.inject("EVENTS"), i0.inject("AuthService"), i0.inject("localStorageService"), i0.inject(i1.ListService)); }, token: StatisticsService, providedIn: "root" });
    return StatisticsService;
}());
exports.StatisticsService = StatisticsService;
