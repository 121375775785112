"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./cases-change-status-confirm.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../../../common/directives/loading.directive");
var i3 = require("@angular/common");
var i4 = require("./cases-change-status-confirm.component");
var i5 = require("../../../common/services/auth.service");
var i6 = require("../../services/settings.service");
var styles_CasesChangeStatusConfirmComponent = [i0.styles];
var RenderType_CasesChangeStatusConfirmComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CasesChangeStatusConfirmComponent, data: {} });
exports.RenderType_CasesChangeStatusConfirmComponent = RenderType_CasesChangeStatusConfirmComponent;
function View_CasesChangeStatusConfirmComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "centered-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Na "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵted(-1, null, [" p\u0159\u00EDpadech bude provedena zm\u011Bna stavu na "])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵted(-1, null, ["."]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.details.group.cases.length; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.data.details.group.caseStatus.name; _ck(_v, 6, 0, currVal_1); }); }
function View_CasesChangeStatusConfirmComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "centered-text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", "/", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [], [[8, "className", 0]], null, null, null, null)), i1.ɵdid(4, 16384, null, 0, i2.LoadingDirective, [i1.ElementRef], { loading: [0, "loading"] }, null)], function (_ck, _v) { var currVal_3 = true; _ck(_v, 4, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.processed; var currVal_1 = _co.data.details.group.cases.length; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵnov(_v, 4).elementClass; _ck(_v, 3, 0, currVal_2); }); }
function View_CasesChangeStatusConfirmComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "jumbotron"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CasesChangeStatusConfirmComponent_1)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CasesChangeStatusConfirmComponent_2)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.saving; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.saving; _ck(_v, 4, 0, currVal_1); }, null); }
exports.View_CasesChangeStatusConfirmComponent_0 = View_CasesChangeStatusConfirmComponent_0;
function View_CasesChangeStatusConfirmComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_CasesChangeStatusConfirmComponent_0, RenderType_CasesChangeStatusConfirmComponent)), i1.ɵdid(1, 180224, null, 0, i4.CasesChangeStatusConfirmComponent, [i5.AuthService, i6.SettingsService, "Restangular", "processDefinition"], null, null)], null, null); }
exports.View_CasesChangeStatusConfirmComponent_Host_0 = View_CasesChangeStatusConfirmComponent_Host_0;
var CasesChangeStatusConfirmComponentNgFactory = i1.ɵccf("ng-component", i4.CasesChangeStatusConfirmComponent, View_CasesChangeStatusConfirmComponent_Host_0, { data: "data" }, {}, []);
exports.CasesChangeStatusConfirmComponentNgFactory = CasesChangeStatusConfirmComponentNgFactory;
