"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./tooltip-system-metadata.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/common");
var i3 = require("../../directives/brand-visibility.directive");
var i4 = require("../../services/brand.service");
var i5 = require("./tooltip-system-metadata.component");
var i6 = require("../../services/auth.service");
var styles_TooltipSystemMetadataComponent = [i0.styles];
var RenderType_TooltipSystemMetadataComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TooltipSystemMetadataComponent, data: {} });
exports.RenderType_TooltipSystemMetadataComponent = RenderType_TooltipSystemMetadataComponent;
function View_TooltipSystemMetadataComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["server: ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.backendVersion; _ck(_v, 2, 0, currVal_0); }); }
function View_TooltipSystemMetadataComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Data KN:"])), (_l()(), i1.ɵeld(2, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" platnost: ", ""])), i1.ɵppd(4, 1), (_l()(), i1.ɵeld(5, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, [" aktualizov\u00E1no: ", " "])), i1.ɵppd(7, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent, 0), (_co.data.cadastre && _co.data.cadastre.platnostKnDat))); _ck(_v, 3, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 6, 0, _ck(_v, 7, 0, i1.ɵnov(_v.parent, 0), (_co.data.cadastre && _co.data.cadastre.knDataLastUpdateTime), "medium")); _ck(_v, 6, 0, currVal_1); }); }
function View_TooltipSystemMetadataComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "p", [["visibleByBrand", "maja"]], null, null, null, null, null)), i1.ɵdid(3, 81920, null, 0, i3.BrandVisibilityDirective, [i1.ElementRef, i4.BrandService], { visibleByBrand: [0, "visibleByBrand"] }, null), (_l()(), i1.ɵted(4, null, [" Pr\u00E1v\u011B pracujete ve verzi ", ":"])), (_l()(), i1.ɵeld(5, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, [" client: ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TooltipSystemMetadataComponent_1)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TooltipSystemMetadataComponent_2)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "maja"; _ck(_v, 3, 0, currVal_0); var currVal_3 = _co.data.backendVersion; _ck(_v, 8, 0, currVal_3); var currVal_4 = _co.data.cadastre; _ck(_v, 10, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.applicationName; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.data.frontendVersion; _ck(_v, 6, 0, currVal_2); }); }
exports.View_TooltipSystemMetadataComponent_0 = View_TooltipSystemMetadataComponent_0;
function View_TooltipSystemMetadataComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "tooltip-system-metadata", [], null, null, null, View_TooltipSystemMetadataComponent_0, RenderType_TooltipSystemMetadataComponent)), i1.ɵdid(1, 114688, null, 0, i5.TooltipSystemMetadataComponent, ["APP_BRAND", "APPLICATIONS", i6.AuthService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_TooltipSystemMetadataComponent_Host_0 = View_TooltipSystemMetadataComponent_Host_0;
var TooltipSystemMetadataComponentNgFactory = i1.ɵccf("tooltip-system-metadata", i5.TooltipSystemMetadataComponent, View_TooltipSystemMetadataComponent_Host_0, { data: "data" }, {}, []);
exports.TooltipSystemMetadataComponentNgFactory = TooltipSystemMetadataComponentNgFactory;
