"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var DialogInjector = /** @class */ (function () {
    function DialogInjector(parentInjector, additionalTokens) {
        this.parentInjector = parentInjector;
        this.additionalTokens = additionalTokens;
    }
    DialogInjector.prototype.get = function (token, notFoundValue, flags) {
        var value = this.additionalTokens.get(token);
        if (value) {
            return value;
        }
        return this.parentInjector.get(token, notFoundValue);
    };
    return DialogInjector;
}());
exports.DialogInjector = DialogInjector;
