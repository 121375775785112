"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["\n\n\n\n\n.changelog-container {\n  position: relative;\n  z-index: 100; }\n  .changelog-container .changelog-item {\n    background-color: #ffe059;\n    color: #000;\n    min-width: 300px;\n    margin: 5px 0;\n    padding-left: 5px;\n    padding-right: 5px;\n    display: flex;\n    line-height: 30px; }\n    .changelog-container .changelog-item .changelog-name {\n      flex-grow: 2;\n      padding-right: 5px;\n      padding-left: 5px;\n      max-width: 500px;\n      white-space: nowrap;\n      overflow: hidden;\n      text-overflow: ellipsis; }\n    .changelog-container .changelog-item a, .changelog-container .changelog-item a:hover, .changelog-container .changelog-item a:focus {\n      color: #000; }\n  .changelog-container .changelog-description {\n    position: absolute;\n    top: 30px;\n    background-color: #ffe059;\n    color: #000;\n    padding: 10px;\n    min-width: 300px;\n    line-height: 20px; }\n    .changelog-container .changelog-description a, .changelog-container .changelog-description a:hover, .changelog-container .changelog-description a:focus {\n      color: #000;\n      text-decoration: underline; }\n"];
exports.styles = styles;
