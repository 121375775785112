"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("@angular/common");
var i2 = require("./tooltip-vfze-problems.component");
var styles_TooltipVfzeProblemsComponent = [];
var RenderType_TooltipVfzeProblemsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TooltipVfzeProblemsComponent, data: {} });
exports.RenderType_TooltipVfzeProblemsComponent = RenderType_TooltipVfzeProblemsComponent;
function View_TooltipVfzeProblemsComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.message; _ck(_v, 1, 0, currVal_0); }); }
function View_TooltipVfzeProblemsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "ul", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TooltipVfzeProblemsComponent_2)), i0.ɵdid(2, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.problems; _ck(_v, 2, 0, currVal_0); }, null); }
function View_TooltipVfzeProblemsComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Bez chyb"]))], null, null); }
function View_TooltipVfzeProblemsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_TooltipVfzeProblemsComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TooltipVfzeProblemsComponent_3)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.data.problems == null) ? null : _co.data.problems.length); _ck(_v, 1, 0, currVal_0); var currVal_1 = (!_co.data.problems || (_co.data.problems.length === 0)); _ck(_v, 3, 0, currVal_1); }, null); }
exports.View_TooltipVfzeProblemsComponent_0 = View_TooltipVfzeProblemsComponent_0;
function View_TooltipVfzeProblemsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tooltip-vfze-problems", [], null, null, null, View_TooltipVfzeProblemsComponent_0, RenderType_TooltipVfzeProblemsComponent)), i0.ɵdid(1, 49152, null, 0, i2.TooltipVfzeProblemsComponent, [], null, null)], null, null); }
exports.View_TooltipVfzeProblemsComponent_Host_0 = View_TooltipVfzeProblemsComponent_Host_0;
var TooltipVfzeProblemsComponentNgFactory = i0.ɵccf("tooltip-vfze-problems", i2.TooltipVfzeProblemsComponent, View_TooltipVfzeProblemsComponent_Host_0, { data: "data" }, {}, []);
exports.TooltipVfzeProblemsComponentNgFactory = TooltipVfzeProblemsComponentNgFactory;
