"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var auth_service_1 = require("@app/common/services/auth.service");
var angular_1 = require("@uirouter/angular");
var ProjectUpdateComponent = /** @class */ (function () {
    function ProjectUpdateComponent(restangularDimap, authService, stateService) {
        var _this = this;
        this.restangularDimap = restangularDimap;
        this.authService = authService;
        this.stateService = stateService;
        this.projectId = this.authService.getActualProject().key;
        this.data = {};
        this.checked = false;
        this.loading = true;
        this.tab = 'G';
        this.tabs = [
            { id: 'G', name: 'Základní' },
            { id: 'C', name: 'Podmínky užití' }
        ];
        this.toggleCheckbox = function () {
            _this.checked = !_this.checked;
            return _this.checked;
        };
        this.update = this.update.bind(this);
        this.isActiveTab = this.isActiveTab.bind(this);
    }
    ProjectUpdateComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.authService.loadProject(this.projectId).then(function (data) {
            _this.data = data;
            _this.loading = false;
        });
    };
    ProjectUpdateComponent.prototype.isActiveTab = function (id) {
        return id === this.tab;
    };
    ProjectUpdateComponent.prototype.update = function () {
        var _this = this;
        var data = {
            name: this.data.name,
            termsAndConditions: this.data.termsAndConditions,
            forceApproval: (this.checked === true)
        };
        return this.restangularDimap.one('projects', this.projectId).customPUT(data).then(function () {
            _this.stateService.go('dimap.project.settings');
        });
    };
    return ProjectUpdateComponent;
}());
exports.ProjectUpdateComponent = ProjectUpdateComponent;
