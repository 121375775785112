"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var common_1 = require("@angular/common");
var core_1 = require("@angular/core");
var moment = require("moment");
var file_saver_1 = require("file-saver");
var alert_component_1 = require("@app/dialog/alert/alert.component");
var class_name_enum_1 = require("@app/dialog/class-name.enum");
var dialog_service_1 = require("@app/dialog/dialog.service");
var CadastreDataRequestComponent = /** @class */ (function () {
    function CadastreDataRequestComponent(dialogService, restangularPromap) {
        this.dialogService = dialogService;
        this.restangularPromap = restangularPromap;
        this.unprocessedRequests = [];
        this.monthPickerOptions = {
            minDate: moment().subtract(1, 'month').toDate(),
            maxDate: moment().add(1, 'month').toDate(),
            defaultDate: moment().add(1, 'month').toDate(),
        };
    }
    CadastreDataRequestComponent.prototype.ngOnInit = function () {
        this.onExistingRequestsDownload = this.onExistingRequestsDownload.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    };
    CadastreDataRequestComponent.prototype.onExistingRequestsDownload = function () {
        return __awaiter(this, void 0, void 0, function () {
            var requests;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getBaseRequest().get()];
                    case 1:
                        requests = _a.sent();
                        requests = requests.items;
                        this.processFoundRequests(requests);
                        return [2 /*return*/, requests];
                }
            });
        });
    };
    CadastreDataRequestComponent.prototype.onRequestDateChange = function (requestDate) {
        this.requestDate = common_1.formatDate(requestDate, 'yyyy-MM-dd', 'cs');
        this.checkUnprocessedRequests();
    };
    CadastreDataRequestComponent.prototype.onSubmit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var requests;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getBaseRequest().customPOST({}, '', { validityDate: this.requestDate })];
                    case 1:
                        requests = _a.sent();
                        this.processFoundRequests(requests);
                        return [2 /*return*/, requests];
                }
            });
        });
    };
    CadastreDataRequestComponent.prototype.checkUnprocessedRequests = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.restangularPromap
                                .one("cuzk/xml-request/unprocessed?validityDate=" + this.requestDate)
                                .get()];
                    case 1:
                        _a.unprocessedRequests = _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    CadastreDataRequestComponent.prototype.downloadXmlRequest = function (request) {
        return __awaiter(this, void 0, void 0, function () {
            var baseRequest, xml;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        baseRequest = this.getBaseRequest();
                        baseRequest.id = request.id;
                        return [4 /*yield*/, baseRequest
                                .withHttpConfig({
                                responseType: 'blob',
                                transformResponse: function (response, headers) {
                                    var contentDisposition = headers()['content-disposition'];
                                    var filename = contentDisposition // attachment; filename="gmtech_09_2019_bezuplatne_4.zip"
                                        .split('=') // ["attachment; filename", ""gmtech_09_2019_bezuplatne_4.zip""]
                                        .reverse()[0] // "gmtech_09_2019_bezuplatne_4.zip"
                                        .replace(/"/g, ''); // gmtech_09_2019_bezuplatne_4.zip
                                    return { response: response, filename: filename };
                                }
                            })
                                .get()];
                    case 1:
                        xml = _a.sent();
                        file_saver_1.saveAs(xml.response, xml.filename);
                        return [2 /*return*/];
                }
            });
        });
    };
    CadastreDataRequestComponent.prototype.getBaseRequest = function () {
        return this.restangularPromap
            .all('cuzk')
            .one('xml-request');
    };
    CadastreDataRequestComponent.prototype.showMessageOnXmlRequestProcessingDone = function (msg) {
        return this.dialogService.open(alert_component_1.AlertComponent, {
            data: {
                msg: msg
            },
            className: class_name_enum_1.ClassName.ADJUSTED_DIALOG,
        });
    };
    CadastreDataRequestComponent.prototype.processFoundRequests = function (requests) {
        if (requests.length === 0) {
            this.showMessageOnXmlRequestProcessingDone('Nebyly nalezeny žádné dosud neodeslané požadavky.');
            return;
        }
        else {
            this.showDownloadMessage(requests);
        }
    };
    CadastreDataRequestComponent.prototype.showDownloadMessage = function (requests) {
        var _this = this;
        var msg = this.getDownloadMessage(requests);
        var dialog = this.showMessageOnXmlRequestProcessingDone(msg);
        var sub = dialog.afterClosed.subscribe(function (downloadWanted) {
            if (downloadWanted) {
                requests.forEach(_this.downloadXmlRequest, _this);
            }
            sub.unsubscribe();
        });
    };
    CadastreDataRequestComponent.prototype.getDownloadMessage = function (requests) {
        var msg = [];
        var invalidMsg = this.getInvalidFilesMessage(requests);
        if (requests.length === 1) {
            msg.push('Žádost je připravena, bude stažena po zavření dialogu.');
        }
        else {
            msg.push('Žádosti jsou připraveny, budou staženy po zavření dialogu.');
        }
        msg.push(invalidMsg);
        return msg.join(' ');
    };
    CadastreDataRequestComponent.prototype.getRequestReadableName = function (request) {
        var name = request.cuzkZadostId + "-" + request.poradoveCislo;
        name += request.uplatnyRezim
            ? ' (úplatná)'
            : ' (bezúplatná)';
        return name;
    };
    CadastreDataRequestComponent.prototype.getInvalidFilesMessage = function (requests) {
        var filesWithInvalidXml = requests.filter(function (r) { return !r.validXml; }).map(this.getRequestReadableName, this);
        var msg;
        if (!filesWithInvalidXml || filesWithInvalidXml.length === 0) {
            return undefined;
        }
        if (filesWithInvalidXml.length > 1) {
            msg = "\u017D\u00E1dosti " + filesWithInvalidXml.join(', ') + " nejsou validn\u00ED a mohou b\u00FDt odm\u00EDtnuty \u010C\u00DAZK.";
        }
        else {
            msg = "\u017D\u00E1dost " + filesWithInvalidXml[0] + " nen\u00ED validn\u00ED a m\u016F\u017Ee b\u00FDt odm\u00EDtnuta \u010C\u00DAZK.";
        }
        return msg;
    };
    return CadastreDataRequestComponent;
}());
exports.CadastreDataRequestComponent = CadastreDataRequestComponent;
