"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var d3 = require("d3");
var PieChartComponent = /** @class */ (function () {
    function PieChartComponent() {
        this.tooltip = true;
        this.draw = this.draw.bind(this);
    }
    PieChartComponent.prototype.ngAfterViewInit = function () {
        this.draw();
    };
    PieChartComponent.prototype.ngOnChanges = function (changes) {
        this.draw();
    };
    PieChartComponent.prototype.onResize = function () {
        this.draw();
    };
    PieChartComponent.prototype.draw = function () {
        var _this = this;
        // Setup elements
        var el = this.element.nativeElement;
        var sizeCoefficient = 0.7;
        var size = el.clientWidth * sizeCoefficient;
        el.innerHTML = '';
        this.svg = d3.select(el).append('svg');
        this.g = this.svg.append('g');
        this.svg.attr('width', size)
            .attr('height', size)
            .attr('viewBox', "0 0 " + size + " " + size);
        this.g.attr('transform', 'translate(' + size / 2 + ',' + size / 2 + ')');
        // Generate the pie
        var pie = d3.pie();
        pie.sort(null);
        // Generate the arcs
        var arc = d3.arc()
            .innerRadius((size / 2.5 > 55) ? (size / 2.5) : 55)
            .outerRadius(size / 2);
        // Generate groups
        var arcs = this.g.selectAll('arc')
            .data(pie(this.extractData('value')))
            .enter()
            .append('g')
            .attr('class', 'arc');
        // Draw arc paths
        var colors = d3.scaleOrdinal(this.extractData('color'));
        arcs.append('path')
            .attr('fill', function (d, i) {
            return colors(i);
        })
            .attr('d', arc);
        // Tooltip
        if (this.tooltip) {
            var tooltip_1 = d3.select(el)
                .append('div')
                .style('position', 'fixed')
                .style('z-index', '10')
                .style('visibility', 'hidden')
                .style('padding', '7px')
                .style('background', '#fff')
                .style('border', '1px solid #dddddd')
                .style('box-shadow', '0 6px 5px 0 rgba(0, 0, 0, 0.2)')
                .style('-webkit-box-shadow', '0 6px 5px 0 rgba(0, 0, 0, 0.2)');
            arcs
                .on('mouseover', function (d) {
                tooltip_1.text(_this.data[d.index].tooltip);
                return tooltip_1.style('visibility', 'visible');
            })
                .on('mousemove', function () {
                return tooltip_1
                    .style('top', (d3.event.pageY - 30) + 'px')
                    .style('left', (d3.event.pageX + 10) + 'px');
            })
                .on('mouseout', function () {
                return tooltip_1.style('visibility', 'hidden');
            });
        }
        // Text
        if (this.title !== undefined) {
            this.svg.append('svg:text')
                .attr('x', '50%')
                .attr('y', '50%')
                .attr('dy', '-10')
                .attr('text-anchor', 'middle')
                .attr('dominant-baseline', 'middle')
                .attr('style', 'font-size:30px')
                .text(this.title);
        }
        if (this.subTitle !== undefined) {
            this.svg.append('svg:text')
                .attr('x', '50%')
                .attr('y', '50%')
                .attr('dy', '15')
                .attr('text-anchor', 'middle')
                .attr('dominant-baseline', 'middle')
                .attr('style', 'font-size:16px')
                .text(this.subTitle);
        }
        if (this.subSubTitle !== undefined) {
            this.svg.append('svg:text')
                .attr('x', '50%')
                .attr('y', '50%')
                .attr('dy', '35')
                .attr('text-anchor', 'middle')
                .attr('dominant-baseline', 'middle')
                .attr('style', 'font-size:16px')
                .text(this.subSubTitle);
        }
    };
    PieChartComponent.prototype.extractData = function (key) {
        return this.data.map(function (d) { return d[key]; });
    };
    return PieChartComponent;
}());
exports.PieChartComponent = PieChartComponent;
