"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".gmt-switch__options__item[_ngcontent-%COMP%] {\n  background: #fff;\n  border-color: #0096dc;\n  border-style: solid;\n  border-bottom-width: 1px;\n  border-right-width: 1px;\n  border-top-width: 1px;\n  color: #0096dc;\n  cursor: pointer;\n  display: inline-block; }\n\n.gmt-switch__options__item[_ngcontent-%COMP%]:first-child {\n  border-left-width: 1px; }\n\n.gmt-switch__options__item[_ngcontent-%COMP%]:last-child {\n  border-left-width: 0px;\n  border-right-width: 1px; }\n\n.gmt-switch__options__item[_ngcontent-%COMP%]:hover, .gmt-switch__options__item--active[_ngcontent-%COMP%] {\n  background: #0096dc;\n  color: #fff; }"];
exports.styles = styles;
