"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["\n\n\n\n\n.file-upload-button {\n  position: absolute;\n  top: 24px;\n  left: 525px;\n  z-index: 1;\n  display: inline-block;\n  height: 30px;\n  width: 30px;\n  border-radius: 3px;\n  padding: 6px;\n  font-size: 16px;\n  text-align: center;\n  color: #3d3d3d; }\n  .file-upload-button:hover {\n    background-color: #e7e7e7; }\n\n.ck.ck-balloon-panel {\n  z-index: 10000; }\n\n.ck-editor__editable {\n  min-height: 150px; }\n  .ck-editor__editable h2 {\n    color: #1a427b;\n    font-weight: normal;\n    font-size: 16px;\n    padding: 10px 0 0 0; }\n  .ck-editor__editable h3 {\n    color: #1a427b;\n    font-weight: normal;\n    font-size: 14px;\n    padding: 10px 0 0 0; }\n  .ck-editor__editable h4 {\n    font-weight: bold;\n    font-size: 13px;\n    padding: 10px 0 0 0; }\n  .ck-editor__editable ul {\n    list-style-type: disc;\n    margin-left: 20px;\n    margin-bottom: 1rem; }\n  .ck-editor__editable ol {\n    list-style-type: decimal;\n    margin-left: 20px;\n    margin-bottom: 1rem; }\n  .ck-editor__editable u {\n    text-decoration: underline; }\n  .ck-editor__editable s {\n    text-decoration: line-through; }\n"];
exports.styles = styles;
