"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var dialog_service_1 = require("@app/dialog/dialog.service");
var auth_service_1 = require("@app/common/services/auth.service");
var modules_service_1 = require("@app/common/services/modules.service");
var user_edit_component_1 = require("@app/common/components/user-edit/user-edit.component");
var callback_model_1 = require("@app/ps/models/callback.model");
var list_service_1 = require("@app/common/services/list.service");
var UserDetailComponent = /** @class */ (function () {
    function UserDetailComponent(helpIds, APP_BRAND, moduleService, authService, dialogService, listService) {
        this.helpIds = helpIds;
        this.APP_BRAND = APP_BRAND;
        this.moduleService = moduleService;
        this.authService = authService;
        this.dialogService = dialogService;
        this.listService = listService;
        this.loading = true;
        this.module = this.authService.getActiveApplication();
        this.project = this.authService.getActualProject();
        this.modulePermissions = [];
        this.projects = [];
        this.editor = this.authService.getUser();
        this.onEdit = this.onEdit.bind(this);
        this.getProject = this.getProject.bind(this);
        this.onPermissionsRefreshed = this.onPermissionsRefreshed.bind(this);
    }
    UserDetailComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b, _c, _d;
            var _this = this;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.moduleService.getUser(this.userId).then(function (d) { return d.plain(); })];
                    case 1:
                        _a.user = _e.sent();
                        this.userRole = this.user.roles.find(function (r) { return r.projectKey === _this.project.key; });
                        _b = this;
                        return [4 /*yield*/, this.moduleService.getUserPermissions(this.module, this.userId).then(function (d) { return d.plain(); })];
                    case 2:
                        _b.userPermissions = _e.sent();
                        _c = this;
                        return [4 /*yield*/, this.moduleService.getPermissions(this.module).then(function (d) { return d.plain(); })];
                    case 3:
                        _c.modulePermissions = _e.sent();
                        _d = this;
                        return [4 /*yield*/, this.moduleService.getProjects(this.module).then(function (d) { return d.plain(); })];
                    case 4:
                        _d.projects = _e.sent();
                        this.user.applicationProjects[this.module] = this.user.applicationProjects[this.module].filter(this.getProject);
                        this.loading = false;
                        this.callbacks.add('userPermissionsChangedCallback', this.onPermissionsRefreshed);
                        return [2 /*return*/];
                }
            });
        });
    };
    UserDetailComponent.prototype.ngOnDestroy = function () {
        this.callbacks.remove('userPermissionsChangedCallback', this.onPermissionsRefreshed);
    };
    UserDetailComponent.prototype.onEdit = function () {
        var _this = this;
        var dialog = this.dialogService.open(user_edit_component_1.UserEditComponent, {
            data: { userId: this.user.id }
        });
        var sub = dialog.afterClosed.subscribe(function (res) {
            if (res) {
                Object.assign(_this.user, res);
            }
            sub.unsubscribe();
        });
    };
    UserDetailComponent.prototype.getPermissionName = function (id) {
        var permission = this.modulePermissions && this.modulePermissions.find(function (p) { return p.name === id; });
        return permission && permission.description;
    };
    UserDetailComponent.prototype.getProjectName = function (key) {
        var project = this.getProject(key);
        return project && project.name;
    };
    UserDetailComponent.prototype.onPermissionsRefreshed = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b;
            var _this = this;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.moduleService.loadUser(this.userId).then(function () { return _this.moduleService.getUser(_this.userId); }).then(function (d) { return d.plain(); })];
                    case 1:
                        _a.user = _c.sent();
                        _b = this;
                        return [4 /*yield*/, this.moduleService.getUserPermissions(this.module, this.userId).then(function (d) { return d.plain(); })];
                    case 2:
                        _b.userPermissions = _c.sent();
                        this.userRole = this.user.roles.find(function (r) { return r.projectKey === _this.project.key; });
                        return [2 /*return*/];
                }
            });
        });
    };
    UserDetailComponent.prototype.getProject = function (key) {
        return this.projects.find(function (p) { return p.key === key; });
    };
    return UserDetailComponent;
}());
exports.UserDetailComponent = UserDetailComponent;
