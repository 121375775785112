"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var _ = require("lodash");
var dialog_service_1 = require("@app/dialog/dialog.service");
var ares_form_component_1 = require("@app/ps/components/ares-form/ares-form.component");
var project_subject_agent_component_1 = require("@app/ps/components/project-subject-agent/project-subject-agent.component");
var acting_person_name_pipe_1 = require("@app/ps/pipes/acting-person-name.pipe");
var ProjectSubjectComponent = /** @class */ (function () {
    function ProjectSubjectComponent(actingPersonNamePipe, dialogService) {
        this.actingPersonNamePipe = actingPersonNamePipe;
        this.dialogService = dialogService;
        this.projectSubjectUpdateRegister = new core_1.EventEmitter();
        this.subjectTypes = [
            { id: 'OPO', value: 'Právnická osoba' },
            { id: 'OFO', value: 'Fyzická osoba' }
        ];
        this.updateActingSelect = this.updateActingSelect.bind(this);
        this.reloadOptionsCadastreActingPersonRegister = this.reloadOptionsCadastreActingPersonRegister.bind(this);
        this.onAddActing = this.onAddActing.bind(this);
        this.onCancelActing = this.onCancelActing.bind(this);
        this.onAddAgent = this.onAddAgent.bind(this);
    }
    ProjectSubjectComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.isInvestor = this.subject.discriminator === 'I';
        if (this.subject.discriminator !== 'G') {
            this.subjectTypes.push({ id: 'OPO_OSS', value: 'Organizační složka státu' });
            this.subjectTypes.push({ id: 'OPO_PO', value: 'Příspěvková organizace' });
        }
        if (this.subject.subjectType) {
            this.subjectType = this.subjectTypes.find(function (t) { return t.id === _this.subject.subjectType; });
        }
        else {
            this.subjectType = this.subjectTypes[0];
            this.subject.subjectType = this.subjectType.id;
        }
        this.updateActingSelect();
        this.projectSubjectUpdateRegister.emit(function (actingPersons) { _this.updateActingSelect(actingPersons); });
    };
    ProjectSubjectComponent.prototype.onSubjectTypeSelected = function (item) {
        this.subject.subjectType = item.id;
    };
    ProjectSubjectComponent.prototype.reloadOptionsCadastreActingPersonRegister = function (reload) {
        this.reloadOptionsCadastreActingPerson = reload;
    };
    ProjectSubjectComponent.prototype.onAddActing = function () {
        this.subject.actingPersons.push({ cancelled: false });
        this.updateActingSelect();
    };
    ProjectSubjectComponent.prototype.onCancelActing = function (acting) {
        if (acting && this.subject.cadastreActingPerson &&
            (acting === this.subject.cadastreActingPerson || acting.id === this.subject.cadastreActingPerson.id)) {
            this.onUpdateCadastreActingPerson({ oldValue: this.subject.cadastreActingPerson });
            this.subject.cadastreActingPerson = undefined;
        }
        if (acting.id) {
            acting.cancelled = true;
        }
        else {
            _.remove(this.subject.actingPersons, function (a) { return _.isEqual(a, acting); });
        }
        this.updateActingSelect();
    };
    ProjectSubjectComponent.prototype.onRestoreActing = function (acting) {
        acting.cancelled = false;
        this.updateActingSelect();
    };
    ProjectSubjectComponent.prototype.onAddCadastreNote = function () {
        this.subject.cadastreNotes = this.subject.cadastreNotes || [];
        this.subject.cadastreNotes.push({});
    };
    ProjectSubjectComponent.prototype.onRemoveCadastreNote = function (cadastreNote) {
        _.pull(this.subject.cadastreNotes, cadastreNote);
    };
    ProjectSubjectComponent.prototype.onUpdateCadastreActingPerson = function (event) {
        if (event.oldValue) {
            event.oldValue.cadastreActingPerson = false;
        }
        if (event.newValue) {
            event.newValue.cadastreActingPerson = true;
        }
    };
    ProjectSubjectComponent.prototype.onSearchCompany = function (name, id) {
        var _this = this;
        var dialog = this.dialogService.open(ares_form_component_1.AresFormComponent, {
            data: {
                icoToSearch: id,
                nameToSearch: name,
            },
        });
        var sub = dialog.afterClosed.subscribe(function (aresData) {
            if (aresData) {
                _this.subject.actingPersons = [];
                _.assignInWith(_this.subject, aresData, function (value, other) {
                    return other || value;
                });
            }
            sub.unsubscribe();
        });
    };
    ProjectSubjectComponent.prototype.onAddAgent = function () {
        var _this = this;
        var dialog = this.dialogService.open(project_subject_agent_component_1.ProjectSubjectAgentComponent, {
            data: { agent: this.subject.cadastreAgent }
        });
        var sub = dialog.afterClosed.subscribe(function (agent) {
            if (agent) {
                _this.subject.cadastreAgent = agent;
            }
            sub.unsubscribe();
        });
    };
    ProjectSubjectComponent.prototype.updateActingSelect = function (actingPersons) {
        if (actingPersons === void 0) { actingPersons = null; }
        var _a;
        this.actingPersonsNotCancelled = this.actingPersonsNotCancelled || [];
        var items = [];
        if (this.subject) {
            items = _.filter(actingPersons || this.subject.actingPersons, { cancelled: false, roleCadastre: true });
        }
        (_a = this.actingPersonsNotCancelled).splice.apply(_a, __spread([0, this.actingPersonsNotCancelled.length], items));
        if (this.reloadOptionsCadastreActingPerson) {
            this.reloadOptionsCadastreActingPerson();
        }
    };
    return ProjectSubjectComponent;
}());
exports.ProjectSubjectComponent = ProjectSubjectComponent;
