"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("../mpv-statistics/mpv-statistics.component.ngfactory");
var i2 = require("../mpv-statistics/mpv-statistics.component");
var i3 = require("../../../../common/services/symap-restangular.service");
var i4 = require("../../../../dialog/dialog.service");
var i5 = require("../../../../common/services/auth.service");
var i6 = require("../../../services/statistics.service");
var i7 = require("@angular/common");
var i8 = require("../../../../common/services/list.service");
var i9 = require("@uirouter/angular");
var i10 = require("../../../../common/pipes/construction-object-name.pipe");
var i11 = require("../../../components/notifications/notifications.component.ngfactory");
var i12 = require("../../../components/notifications/notifications.component");
var i13 = require("../../../../common/components/help/help.component.ngfactory");
var i14 = require("../../../../common/components/help/help.component");
var i15 = require("./home.component");
var styles_HomeComponent = [];
var RenderType_HomeComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_HomeComponent, data: {} });
exports.RenderType_HomeComponent = RenderType_HomeComponent;
function View_HomeComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "div", [["class", "home"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "h1", [["class", "mb-20"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["P\u0159ehled"])), (_l()(), i0.ɵeld(3, 0, null, null, 1, "mpv-statistics", [], null, null, null, i1.View_MpvStatisticsComponent_0, i1.RenderType_MpvStatisticsComponent)), i0.ɵdid(4, 245760, null, 0, i2.MpvStatisticsComponent, ["Restangular", i3.RESTANGULAR_SYMAP, "APP_BRAND", "localStorageService", "ConfigRestangularService", i4.DialogService, i5.AuthService, i6.StatisticsService, i7.DatePipe, i8.ListService, i9.StateService, i10.ConstructionObjectNamePipe], null, null), (_l()(), i0.ɵeld(5, 0, null, null, 5, "div", [["class", "mt-80"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 4, "fieldset", [["class", "bordered"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 1, "legend", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Upozorn\u011Bn\u00ED"])), (_l()(), i0.ɵeld(9, 0, null, null, 1, "gmt-notifications", [], null, null, null, i11.View_NotificationsComponent_0, i11.RenderType_NotificationsComponent)), i0.ɵdid(10, 245760, null, 0, i12.NotificationsComponent, ["Restangular", "localStorageService", "EVENTS", "RootCallbackService", "APP_BRAND", i5.AuthService, i8.ListService, i4.DialogService], null, null), (_l()(), i0.ɵeld(11, 0, null, null, 1, "help", [["class", "cm-help-page"]], null, null, null, i13.View_HelpComponent_0, i13.RenderType_HelpComponent)), i0.ɵdid(12, 114688, null, 0, i14.HelpComponent, [i5.AuthService, "HELP_IDS", "HelpService", "ModalService"], { helpId: [0, "helpId"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 4, 0); _ck(_v, 10, 0); var currVal_0 = _co.helpIds.PANEL_HOME; _ck(_v, 12, 0, currVal_0); }, null); }
exports.View_HomeComponent_0 = View_HomeComponent_0;
function View_HomeComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "home", [], null, null, null, View_HomeComponent_0, RenderType_HomeComponent)), i0.ɵdid(1, 114688, null, 0, i15.HomeComponent, ["HELP_IDS", "APP_BRAND", i5.AuthService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_HomeComponent_Host_0 = View_HomeComponent_Host_0;
var HomeComponentNgFactory = i0.ɵccf("home", i15.HomeComponent, View_HomeComponent_Host_0, {}, {}, []);
exports.HomeComponentNgFactory = HomeComponentNgFactory;
