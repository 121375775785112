"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var auth_service_1 = require("@app/common/services/auth.service");
var angular_1 = require("@uirouter/angular");
var ProjectCreateComponent = /** @class */ (function () {
    function ProjectCreateComponent(restangularDimap, authService, stateService) {
        this.restangularDimap = restangularDimap;
        this.authService = authService;
        this.stateService = stateService;
        this.data = { project: {} };
        this.update = this.update.bind(this);
    }
    ProjectCreateComponent.prototype.ngOnInit = function () {
    };
    ProjectCreateComponent.prototype.disabled = function () {
        return !(this.data.project.name && this.data.project.key);
    };
    ProjectCreateComponent.prototype.removePrefix = function () {
        if (this.data.key) {
            var idx = this.data.key.indexOf(ProjectCreateComponent.PREFIX);
            if (idx === -1 || idx !== 0) {
                return;
            }
            this.data.key = this.data.key.substring(idx + ProjectCreateComponent.PREFIX.length);
        }
    };
    ProjectCreateComponent.prototype.update = function () {
        var _this = this;
        if (this.disabled()) {
            return;
        }
        // save and return data
        return this.restangularDimap.all('projects').post(this.data).then(function (data) {
            if (data) {
                return _this.authService.loadDimapProjects(_this.authService.getUser().id).then(function () {
                    _this.stateService.go('dimap.project.settings');
                });
            }
        });
    };
    ProjectCreateComponent.PREFIX = 'di_';
    return ProjectCreateComponent;
}());
exports.ProjectCreateComponent = ProjectCreateComponent;
