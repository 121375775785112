"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
Object.defineProperty(exports, "__esModule", { value: true });
var map_layer_type_enum_1 = require("@app/map/enums/map-layer-type.enum");
var new_map_config_service_1 = require("@app/map/services/new-map-config.service");
var word_service_1 = require("@app/common/services/word.service");
var auth_service_1 = require("@app/common/services/auth.service");
var layers_config_service_1 = require("@app/map/services/layers-config.service");
var vector_layer_style_model_1 = require("@app/ps/map/models/vector-layer-style.model");
var i0 = require("@angular/core");
var i1 = require("../../common/services/word.service");
var i2 = require("../../common/services/auth.service");
var i3 = require("./layers-config.service");
var i4 = require("./new-map-config.service");
var i5 = require("../../common/services/config.service");
var MapConfigService = /** @class */ (function () {
    function MapConfigService(wordService, authService, layersConfigService, newMapConfigService, configService, APPLICATIONS) {
        this.wordService = wordService;
        this.authService = authService;
        this.layersConfigService = layersConfigService;
        this.newMapConfigService = newMapConfigService;
        this.configService = configService;
        this.APPLICATIONS = APPLICATIONS;
        this.zIndex = 0;
        this.setBaseConfigVariables = this.setBaseConfigVariables.bind(this);
        this.setLayers = this.setLayers.bind(this);
    }
    /**
     * Get a recursive copy of config, replage layers IDs by Layer config object.
     * Využívá se jak v modulu nastavení, kde jsou vrstvy pouze jako identifikátory, ale také před zobrazením mapového okna,
     * kde se načítá prve nastavení z BE, které limituje vrstvy na výstupu
     *
     * @param {Object} config
     * @param {string} mapId
     */
    MapConfigService.prototype.setLayers = function (config, mapId) {
        return __awaiter(this, void 0, void 0, function () {
            var e_1, _a, returnConfig, _b, layers, _c, _d, l, _e, _f, e_1_1;
            return __generator(this, function (_g) {
                switch (_g.label) {
                    case 0:
                        if (!(!config.type || config.type !== map_layer_type_enum_1.MapLayerTypeEnum.node)) return [3 /*break*/, 2];
                        returnConfig = this.layersConfigService.getLayerById(config.id || config, mapId);
                        if (!returnConfig) {
                            return [2 /*return*/, undefined];
                        }
                        return [4 /*yield*/, this.setBaseConfigVariables(returnConfig, config.id ? config : returnConfig)];
                    case 1:
                        _g.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        returnConfig = Object.assign({}, config);
                        _g.label = 3;
                    case 3:
                        _b = returnConfig.type;
                        switch (_b) {
                            case map_layer_type_enum_1.MapLayerTypeEnum.node: return [3 /*break*/, 4];
                        }
                        return [3 /*break*/, 13];
                    case 4:
                        layers = [];
                        _g.label = 5;
                    case 5:
                        _g.trys.push([5, 10, 11, 12]);
                        _c = __values(returnConfig.layers), _d = _c.next();
                        _g.label = 6;
                    case 6:
                        if (!!_d.done) return [3 /*break*/, 9];
                        l = _d.value;
                        _f = (_e = layers).push;
                        return [4 /*yield*/, this.setLayers(l, mapId)];
                    case 7:
                        _f.apply(_e, [_g.sent()]);
                        _g.label = 8;
                    case 8:
                        _d = _c.next();
                        return [3 /*break*/, 6];
                    case 9: return [3 /*break*/, 12];
                    case 10:
                        e_1_1 = _g.sent();
                        e_1 = { error: e_1_1 };
                        return [3 /*break*/, 12];
                    case 11:
                        try {
                            if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
                        }
                        finally { if (e_1) throw e_1.error; }
                        return [7 /*endfinally*/];
                    case 12:
                        returnConfig.layers = layers.filter(function (l) { return l; });
                        return [3 /*break*/, 14];
                    case 13:
                        ++this.zIndex;
                        _g.label = 14;
                    case 14: return [2 /*return*/, returnConfig];
                }
            });
        });
    };
    MapConfigService.prototype.filterMapConfig = function (config, layerGroups) {
        var e_2, _a;
        if (config.auth && !this.authService.hasPermission(config.auth)) {
            return null;
        }
        switch (config.type) {
            case map_layer_type_enum_1.MapLayerTypeEnum.node:
                if (config.id) {
                    var layerConfig_1 = this.getLayerConfigByLayerID(config.id, layerGroups);
                    config.title = layerConfig_1 && layerConfig_1.title ? layerConfig_1.title : config.title;
                }
                var returnLayers = [];
                try {
                    for (var _b = __values(config.layers), _c = _b.next(); !_c.done; _c = _b.next()) {
                        var layerConfig_2 = _c.value;
                        var cfg = this.filterMapConfig(layerConfig_2, layerGroups);
                        if (cfg) {
                            cfg.parent = config;
                            returnLayers.push(cfg);
                        }
                    }
                }
                catch (e_2_1) { e_2 = { error: e_2_1 }; }
                finally {
                    try {
                        if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                    }
                    finally { if (e_2) throw e_2.error; }
                }
                if (returnLayers.length > 0) {
                    config.layers = returnLayers;
                    return config;
                }
                break;
            case map_layer_type_enum_1.MapLayerTypeEnum.wfs:
            case map_layer_type_enum_1.MapLayerTypeEnum.wms:
            case map_layer_type_enum_1.MapLayerTypeEnum.wmts:
                //get layer config by layerID
                var layerConfig = this.getLayerConfigByLayerID(config.id, layerGroups);
                if (layerConfig) {
                    config.title = config.origTitle === config.title && layerConfig.title ? layerConfig.title : config.title;
                    return config;
                }
                break;
        }
        return null;
    };
    /**
     * Applies func recursively for every layer in config.
     * @param layerConfig
     * @param func
     */
    MapConfigService.prototype.forEachLayer = function (layerConfig, func) {
        func(layerConfig);
        if (layerConfig.type === map_layer_type_enum_1.MapLayerTypeEnum.node) {
            for (var i = 0; i < layerConfig.layers.length; i++) {
                this.forEachLayer(layerConfig.layers[i], func);
            }
        }
    };
    MapConfigService.prototype.getLayerConfigByLayerID = function (layerID, userLayers) {
        if (this.authService.getActiveApplication() === this.APPLICATIONS.sy.name) {
            // symap
            return this.newMapConfigService.getLayerById(layerID, userLayers);
            // dimap
        }
        else {
            return userLayers.find(function (layerConfig) { return layerConfig.mapPK ? layerConfig.mapPK.layerId === layerID : layerConfig.id === layerID; });
        }
    };
    MapConfigService.prototype.setBaseConfigVariables = function (layerConfig, userConfig) {
        var _this = this;
        var title = layerConfig.title;
        return this.wordService.replaceVariablesInText(title).then(function (tr) {
            layerConfig.visible = !!userConfig.visible;
            if (layerConfig[layerConfig.type]) {
                layerConfig[layerConfig.type].zIndex = _this.zIndex;
                if (layerConfig.url.startsWith(_this.configService.BACKEND_OPTIONS.geoserverUrl) || layerConfig.url.startsWith(_this.configService.BACKEND_OPTIONS.geoserverDIUrl)) {
                    layerConfig[layerConfig.type].t = _this.authService.getToken();
                    layerConfig[layerConfig.type].p = _this.authService.getActualProject().key;
                }
                layerConfig.zIndex = _this.zIndex;
                layerConfig.style = userConfig.style;
            }
            layerConfig.title = tr || layerConfig.title;
            layerConfig.origTitle = title;
            if (layerConfig.type === 'wfs' && layerConfig.geojson && layerConfig.geojson.style) {
                if (layerConfig.style) {
                    layerConfig.geojson.style.color = "rgb(" + userConfig.style.stroke.r + ", " + userConfig.style.stroke.g + ", " + userConfig.style.stroke.b + ")";
                    layerConfig.geojson.style.opacity = userConfig.style.stroke.a;
                    layerConfig.geojson.style.fillColor = "rgb(" + userConfig.style.fill.r + ", " + userConfig.style.fill.g + ", " + userConfig.style.fill.b + ")";
                    layerConfig.geojson.style.fillOpacity = userConfig.style.fill.a;
                }
                else {
                    layerConfig.style = vector_layer_style_model_1.VectorLayerStyleModel.fromRgbStrings(layerConfig.geojson.style.fillColor, layerConfig.geojson.style.color);
                    layerConfig.style.fill.a = layerConfig.geojson.style.fillOpacity;
                    layerConfig.style.stroke.a = layerConfig.geojson.style.opacity;
                }
            }
            return layerConfig;
        });
    };
    MapConfigService.ngInjectableDef = i0.defineInjectable({ factory: function MapConfigService_Factory() { return new MapConfigService(i0.inject(i1.WordService), i0.inject(i2.AuthService), i0.inject(i3.LayersConfigService), i0.inject(i4.NewMapConfigService), i0.inject(i5.ConfigService), i0.inject("APPLICATIONS")); }, token: MapConfigService, providedIn: "root" });
    return MapConfigService;
}());
exports.MapConfigService = MapConfigService;
