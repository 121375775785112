"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var $ = require("jquery");
var L = require("leaflet");
var auth_service_1 = require("@app/common/services/auth.service");
var word_service_1 = require("@app/common/services/word.service");
var new_map_config_service_1 = require("@app/map/services/new-map-config.service");
var map_service_1 = require("@app/map/services/map.service");
var map_highlight_service_1 = require("@app/map/services/map-highlight.service");
var map_layer_selector_service_1 = require("@app/map/services/map-layer-selector.service");
var map_config_service_1 = require("@app/map/services/map-config.service");
var core_2 = require("@uirouter/core");
var parcel_name_pipe_1 = require("@app/common/pipes/parcel-name.pipe");
var title_name_pipe_1 = require("@app/common/pipes/title-name.pipe");
var subject_name_pipe_1 = require("@app/common/pipes/subject-name.pipe");
var construction_object_name_pipe_1 = require("@app/common/pipes/construction-object-name.pipe");
var occupation_name_pipe_1 = require("@app/ps/pipes/occupation-name.pipe");
var case_name_pipe_1 = require("@app/ps/pipes/case-name.pipe");
var MapComponent = /** @class */ (function () {
    function MapComponent(authService, wordService, newMapConfigService, transition, mapService, mapHighlightService, HELP_IDS, APPLICATIONS, mapConfigService, localStorageService, restangular, mapLayerSelectorService, parcelNamePipe, titleNamePipe, subjectNamePipe, constructionObjectNamePipe, occupationNamePipe, caseNamePipe) {
        this.authService = authService;
        this.wordService = wordService;
        this.newMapConfigService = newMapConfigService;
        this.transition = transition;
        this.mapService = mapService;
        this.mapHighlightService = mapHighlightService;
        this.HELP_IDS = HELP_IDS;
        this.APPLICATIONS = APPLICATIONS;
        this.mapConfigService = mapConfigService;
        this.localStorageService = localStorageService;
        this.restangular = restangular;
        this.mapLayerSelectorService = mapLayerSelectorService;
        this.parcelNamePipe = parcelNamePipe;
        this.titleNamePipe = titleNamePipe;
        this.subjectNamePipe = subjectNamePipe;
        this.constructionObjectNamePipe = constructionObjectNamePipe;
        this.occupationNamePipe = occupationNamePipe;
        this.caseNamePipe = caseNamePipe;
        this.searchResources = null;
        this.mapConfigLoading = true;
        this.defaultEnableCuzkInfo = false;
        this.selectorConfig = null;
        this.selectorOptions = null;
        this.mapOptions = null;
        this.toggleLayerSelector = this.toggleLayerSelector.bind(this);
        this.getCollapsed = this.getCollapsed.bind(this);
        this.fitHeight = this.fitHeight.bind(this);
    }
    MapComponent.prototype.ngOnInit = function () {
        var _this = this;
        var mapID = 'main-map';
        var project = this.authService.getActualProject();
        var coTranslatePromise;
        if (this.authService.getActualProject().isConstructionObjectGeometry) {
            coTranslatePromise = this.wordService.getWord('CONSTRUCTION_OBJECT');
        }
        else {
            coTranslatePromise = Promise.resolve();
        }
        coTranslatePromise.then(function (co) {
            _this.searchResources = [];
            _this.searchResources.push({ id: 'parcels', icon: 'fa-toggle-up', value: 'Parcela', pipe: _this.parcelNamePipe, filter: { filters: { validity: 'valid' } } });
            _this.searchResources.push({ id: 'titles', icon: 'fa-users', value: 'List vlastnictví', pipe: _this.titleNamePipe, filter: { filters: { validity: 'valid' } } });
            _this.searchResources.push({ id: 'cases', icon: 'fa-file', value: 'Případ', pipe: _this.caseNamePipe });
            _this.searchResources.push({ id: 'subjects', icon: 'fa-user', value: 'Vlastník', pipe: _this.subjectNamePipe, filter: { filters: { opsubType: { values: ['BSM'], negation: true }, validity: 'valid' } } });
            if (co) {
                _this.searchResources.push({ id: 'construction-objects', icon: 'fa-th', value: co, pipe: _this.constructionObjectNamePipe, filter: { filters: { validity: 'valid' } } });
            }
            _this.searchResources.push({ id: 'occupations', icon: 'fa-map-pin', value: 'Zábor', pipe: _this.occupationNamePipe, filter: { filters: { validity: 'valid' } } });
        });
        // Načtení nastavení z BE
        this.newMapConfigService.getLayersConfig(this.APPLICATIONS.sy.name, project.key).then(function (origLayersSettings) {
            var layersConfig = __assign({}, origLayersSettings);
            // Rozšíří výchozí parametry vrstev o uživatelské nastavení
            _this.mapConfigService.setLayers(layersConfig, mapID).then(function (layersConfigSet) {
                var config = Object.assign({
                    id: 'main-map',
                    tools: ['info' /*, 'cuzk'*/, 'measure'],
                    enableCuzkInfo: _this.defaultEnableCuzkInfo,
                }, _this.mapConfigService.filterMapConfig(layersConfigSet, layersConfig.layers));
                _this.selectorConfig = config;
                _this.mapConfigLoading = false;
                _this.selectorOptions = {
                    saveLayersVisibility: true,
                    saveGroupCollapseStatus: true,
                    showCollapsedVisibleLayersCount: true,
                    storagePrefix: project.key
                };
            });
        });
        if (this.transition.params().layer) {
            this.localStorageService.remove('map-' + project.key);
        }
        var savedPosition = this.localStorageService.get('map-' + project.key);
        var mapLat;
        var mapLon;
        var mapZoom;
        var defaultLat = project.mapLat ? project.mapLat : 49.75;
        var defaultLon = project.mapLon ? project.mapLon : 15.75;
        var defaultZoom = project.mapZoom ? project.mapZoom : 4;
        if (savedPosition) {
            mapLat = savedPosition.lat;
            mapLon = savedPosition.lon;
            mapZoom = savedPosition.zoom;
        }
        else {
            mapLat = defaultLat;
            mapLon = defaultLon;
            mapZoom = defaultZoom;
        }
        this.mapOptions = {
            id: mapID,
            srid: 5514,
            bounds: null,
            center: [mapLat, mapLon],
            zoom: mapZoom,
            minZoom: 4,
            maxZoom: 15
        };
        // resizing window will change height of map frame
        $('#map-layer-selector').height(0);
        this.fitHeight();
        this.mapService.getMap(this.mapOptions.id).then(function (map) {
            L.control.fullscreen({
                position: 'topleft',
                title: 'Celoobrazovkový režim',
                titleCancel: 'Ukončit celoobrazovkový režim'
            }).addTo(map);
            L.control.graphicScale({ fill: 'hollow' }).addTo(map);
            var button = L.easyButton('fa-home map-home-icon', function (btn, map) {
                map.setView(new L.LatLng(defaultLat, defaultLon), defaultZoom);
                window.GoogleAnalytics('send', {
                    hitType: 'event',
                    eventCategory: 'mapModule',
                    eventAction: '',
                    eventLabel: 'home',
                });
            });
            button.addTo(map);
            /*const buttonCuzk = L.easyButton('cuzk small grayscale', (btn, map) => {
                this.defaultEnableCuzkInfo = !this.defaultEnableCuzkInfo;
                if (this.defaultEnableCuzkInfo) {
                  $(btn.button).find('.cuzk').removeClass('grayscale');
                } else {
                  $(btn.button).find('.cuzk').addClass('grayscale');
                }
                window.GoogleAnalytics('send', {
                  hitType: 'event',
                  eventCategory: 'mapModule',
                  eventAction: '',
                  eventLabel: 'map cuzk-show-position',
                });
                if (!this.selectorConfig) {
                  return;
                }
                this.selectorConfig.enableCuzkInfo = this.defaultEnableCuzkInfo;
            });
            buttonCuzk.addTo(map);*/
            var logMapUrlHighlight = function (type) {
                window.GoogleAnalytics('send', {
                    hitType: 'event',
                    eventCategory: 'mapModule',
                    eventAction: type,
                    eventLabel: 'map url highlight',
                });
            };
            if (_this.transition.params().title > 0) {
                var type = 'titles';
                logMapUrlHighlight(type);
                _this.restangular.one(type, _this.transition.params().title).get({ loadCollections: ['geometry'] }).then(function (data) {
                    _this.mapHighlightService.highlightFeature(_this.mapOptions.id, data.geometry);
                });
            }
            if (_this.transition.params().parcel > 0) {
                var type = 'parcels';
                logMapUrlHighlight(type);
                _this.restangular.one(type, _this.transition.params().parcel).get({ loadCollections: ['geometry'] }).then(function (data) {
                    _this.mapHighlightService.highlightFeature(_this.mapOptions.id, data.geometry);
                });
            }
            if (_this.transition.params().constructionObject > 0) {
                var type = 'construction-objects';
                logMapUrlHighlight(type);
                _this.restangular.one(type, _this.transition.params().constructionObject).get({ loadCollections: ['geometry'] }).then(function (data) {
                    _this.mapHighlightService.highlightFeature(_this.mapOptions.id, data.geometry);
                });
            }
            if (_this.transition.params().occupation > 0) {
                var type = 'occupations';
                logMapUrlHighlight(type);
                _this.restangular.one(type, _this.transition.params().occupation).get().then(function (data) {
                    _this.mapHighlightService.highlightFeature(_this.mapOptions.id, data.geometry);
                });
            }
            if (_this.transition.params().subject > 0) {
                var type = 'subjects';
                logMapUrlHighlight(type);
                _this.restangular.one(type, _this.transition.params().subject).customPOST({ geometry: {} }).then(function (data) {
                    _this.mapHighlightService.highlightFeature(_this.mapOptions.id, data.geometry);
                });
            }
            if (_this.transition.params().businessCase > 0) {
                var type = 'cases';
                logMapUrlHighlight(type);
                _this.restangular.one(type, _this.transition.params().businessCase).get({ loadCollections: ['geometry'] }).then(function (data) {
                    _this.mapHighlightService.highlightFeature(_this.mapOptions.id, data.geometry);
                });
            }
            map.on('moveend', function () {
                var center = map.getCenter(), zoom = map.getZoom();
                var position = {
                    lat: center.lat,
                    lon: center.lng,
                    zoom: zoom
                };
                _this.localStorageService.set('map-' + project.key, position);
            });
        });
        this.localStorageCollapsedKey = project.key + ".layerSelector.collapsed";
        var collapsedStatus = this.localStorageService.get(this.localStorageCollapsedKey);
        this.mapLayerSelectorService.setCollapsedState((collapsedStatus === 1));
    };
    MapComponent.prototype.toggleLayerSelector = function () {
        var collapsed = !this.getCollapsed();
        this.localStorageService.set(this.localStorageCollapsedKey, collapsed ? 1 : 0);
        $('#map-layer-selector').height(0);
        this.mapLayerSelectorService.setCollapsedState(collapsed);
        // also forces recentering of map window
        setTimeout(this.fitHeight);
    };
    MapComponent.prototype.getCollapsed = function () {
        return this.mapLayerSelectorService.getCollapsedState();
    };
    MapComponent.prototype.fitHeight = function () {
        var topBarHeight = $('#top-bar').outerHeight();
        var windowHeight = $(window).height();
        $('#map-window').height(windowHeight - topBarHeight);
        $('#map-content').height(windowHeight - topBarHeight);
        var layerSelectorHeight = windowHeight - topBarHeight - Math.round($('#selector-heading').outerHeight());
        $('#map-layer-selector').height(layerSelectorHeight);
    };
    return MapComponent;
}());
exports.MapComponent = MapComponent;
