"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var auth_service_1 = require("@app/common/services/auth.service");
var acting_person_name_pipe_1 = require("@app/ps/pipes/acting-person-name.pipe");
var FormCadastreEntryProposalComponent = /** @class */ (function () {
    function FormCadastreEntryProposalComponent(authService, actingPersonNamePipe, restangular) {
        this.authService = authService;
        this.actingPersonNamePipe = actingPersonNamePipe;
        this.restangular = restangular;
        this.callbackRegister = new core_1.EventEmitter();
        this.load = new core_1.EventEmitter();
        this.sendDataProposal = {};
        this.cadastreNotes = null;
        this.accountNumber = null;
        this.cadastreNotesReload = null;
        this.actingPersonReload = null;
        this.loading = true;
        this.hasValidProposalCredentials = this.hasValidProposalCredentials.bind(this);
        this.sendProposal = this.sendProposal.bind(this);
    }
    FormCadastreEntryProposalComponent.prototype.ngOnInit = function () {
        var _this = this;
        var constructionObjectsPromise = Promise.resolve();
        if (this.data.obligation.type === 'RealBurdenLiability' || this.data.obligation.type === 'RealBurdenLiabilityAccelerated') {
            constructionObjectsPromise = this.restangular.all('construction-objects').customPOST({ filter: { filters: { caseId: [this.data.id], loadCollections: ['administrator', 'administrator.actingPersons'] } } });
        }
        Promise.all([
            constructionObjectsPromise,
            this.authService.getActualProject().investor ? this.restangular.one('project-subjects', this.authService.getActualProject().investor.id).get({ loadCollections: ['cadastreAgent', 'actingPersons', 'cadastreAgent.actingPersons'] }) : Promise.resolve()
        ]).then(function (data) {
            if (data[0] && data[0][0] && data[0][0]['administrator']) {
                _this.investor = data[0][0]['administrator'];
            }
            else {
                _this.investor = data[1];
            }
            if (_this.investor) {
                _this.investor.actingPersons = _this.investor.actingPersons ? _this.investor.actingPersons.filter(function (item) { return item.cancelled === false && item.roleCadastre === true; }) : null;
            }
            _this.cadastreAgent = data[1] ? data[1].cadastreAgent : null;
            if (_this.cadastreAgent) {
                _this.cadastreAgent.actingPersons = _this.cadastreAgent.actingPersons ? _this.cadastreAgent.actingPersons.filter(function (item) { return item.cancelled === false && item.roleCadastre === true; }) : null;
            }
            _this.sendDataProposal.useCadastreAgent = !!_this.cadastreAgent;
            _this.updateCadastreActingPerson();
            _this.load.emit();
            _this.loading = false;
        });
        this.callbackRegister.emit({
            isValid: this.hasValidProposalCredentials,
            submit: this.sendProposal,
        });
    };
    FormCadastreEntryProposalComponent.prototype.updateCadastreActingPerson = function () {
        this.actingPersons = this.sendDataProposal.useCadastreAgent ? this.cadastreAgent.actingPersons : this.investor.actingPersons;
        this.sendDataProposal.cadastreActingPerson = this.sendDataProposal.useCadastreAgent ? this.cadastreAgent.cadastreActingPerson : this.investor.cadastreActingPerson;
        if (!this.sendDataProposal.cadastreActingPerson && this.actingPersons.length === 1) {
            this.sendDataProposal.cadastreActingPerson = this.actingPersons[0];
        }
        this.changedActingPerson();
        if (this.actingPersons.length && this.actingPersonReload) {
            setTimeout(this.actingPersonReload);
        }
        else {
            this.actingPersonReload = null;
        }
        this.cadastreNotes = this.sendDataProposal.useCadastreAgent ? this.cadastreAgent.cadastreNotes : this.investor.cadastreNotes;
        if (this.cadastreNotes && this.cadastreNotes.length && this.cadastreNotesReload) {
            setTimeout(this.cadastreNotesReload);
        }
        else {
            this.cadastreNotesReload = null;
        }
        if (this.sendDataProposal.useCadastreAgent && this.cadastreAgent && this.cadastreAgent.useAccountNumberForProposal && this.cadastreAgent.bankCode && this.cadastreAgent.accountNumber) {
            this.accountNumber = this.cadastreAgent.accountNumber + '/' + this.cadastreAgent.bankCode;
        }
        else if (!this.sendDataProposal.useCadastreAgent && this.investor && this.investor.useAccountNumberForProposal && this.investor.bankCode && this.investor.accountNumber) {
            this.accountNumber = this.investor.accountNumber + '/' + this.investor.bankCode;
        }
        else {
            this.accountNumber = null;
        }
        this.sendDataProposal.note = this.getContractRegisterNote() || '';
    };
    FormCadastreEntryProposalComponent.prototype.changedActingPerson = function () {
        this.sendDataProposal.email = this.sendDataProposal.phoneNumber = null;
        if (this.sendDataProposal.cadastreActingPerson) {
            this.sendDataProposal.email = this.sendDataProposal.cadastreActingPerson.email;
            this.sendDataProposal.phoneNumber = this.sendDataProposal.cadastreActingPerson.phoneNumber;
        }
        this.sendDataProposal.email = this.sendDataProposal.email || (this.sendDataProposal.useCadastreAgent ? this.cadastreAgent.email : this.investor.email);
        this.sendDataProposal.phoneNumber = this.sendDataProposal.phoneNumber || (this.sendDataProposal.useCadastreAgent ? this.cadastreAgent.phoneNumber : this.investor.phoneNumber);
    };
    FormCadastreEntryProposalComponent.prototype.toggleUseCadastreAgent = function () {
        this.sendDataProposal.useCadastreAgent = !this.sendDataProposal.useCadastreAgent;
        this.updateCadastreActingPerson();
    };
    FormCadastreEntryProposalComponent.prototype.hasValidProposalCredentials = function () {
        return this.sendDataProposal.entryProposalUser && this.sendDataProposal.entryProposalPassword;
    };
    FormCadastreEntryProposalComponent.prototype.sendProposal = function () {
        var _this = this;
        return this.data.customPUT(this.sendDataProposal, 'send-proposal').then(function (data) {
            _this.data.entryProposalLink = data.entryProposalLink;
            _this.data.entryProposalId = data.entryProposalId;
            _this.data.entryProposalUser = _this.sendDataProposal.entryProposalUser;
        });
    };
    FormCadastreEntryProposalComponent.prototype.getContractRegisterNote = function () {
        var person = this.sendDataProposal.useCadastreAgent ? this.cadastreAgent : this.investor;
        if (this.data.contractRegisterPublicationContractId && person.showPublicationNote && person.publicationNoteTemplate) {
            return person.publicationNoteTemplate.replace('[ID]', this.data.contractRegisterPublicationContractId);
        }
        else if (!this.data.contractRegisterPublicationContractId && person.showNoPublicationNote && person.noPublicationNoteTemplate) {
            return person.noPublicationNoteTemplate.replace('[VYJIMKA]', this.data.contractRegisterPublicationException);
        }
        return null;
    };
    FormCadastreEntryProposalComponent.prototype.cadastreNoteSelected = function (newValue, data) {
        this.sendDataProposal.note = newValue.content;
        this.sendDataProposal.note += (this.sendDataProposal.note ? '\n' : '') + (this.getContractRegisterNote() || '');
        data.selected = null;
    };
    FormCadastreEntryProposalComponent.prototype.registerCadastreNotesReload = function (reload) {
        this.cadastreNotesReload = reload;
    };
    FormCadastreEntryProposalComponent.prototype.registerActingPersonReload = function (reload) {
        this.actingPersonReload = reload;
    };
    FormCadastreEntryProposalComponent.prototype.isValid = function () {
        return !!this.data.handedOverInvestorDate;
    };
    return FormCadastreEntryProposalComponent;
}());
exports.FormCadastreEntryProposalComponent = FormCadastreEntryProposalComponent;
