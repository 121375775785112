"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var case_service_1 = require("@app/ps/services/case.service");
var angular_1 = require("@uirouter/angular");
var _ = require("lodash");
var common_1 = require("@angular/common");
var case_status_service_1 = require("@app/ps/services/case-status.service");
var SignOwnerComponent = /** @class */ (function () {
    function SignOwnerComponent(restangular, caseStatusService, caseService, stateService, datePipe) {
        this.restangular = restangular;
        this.caseStatusService = caseStatusService;
        this.caseService = caseService;
        this.stateService = stateService;
        this.datePipe = datePipe;
        this.loading = true;
        this.enabledSubjects = {};
        this.alreadySigned = 0;
        this.dataSend = {
            caseSubjects: null,
            responseReceivedDate: new Date()
        };
        this.deliveredInTime = null;
        this.onSubmit = this.onSubmit.bind(this);
        this.isValid = this.isValid.bind(this);
        this.onSubjectSignDateChanged = this.onSubjectSignDateChanged.bind(this);
        this.onResponseReceivedDataChanged = this.onResponseReceivedDataChanged.bind(this);
        this.signatureVerificationPriceEnabled = this.signatureVerificationPriceEnabled.bind(this);
    }
    SignOwnerComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b;
            var _this = this;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.restangular.one('cases', this.caseId).get({ loadCollections: ['caseSubjects', 'caseOwnerships'] })];
                    case 1:
                        _a.data = _c.sent();
                        _b = this;
                        return [4 /*yield*/, this.caseStatusService.loadStatusName('SignedOwner')];
                    case 2:
                        _b.name = (_c.sent()).name;
                        this.dataSend.caseSubjects = this.data.caseSubjects.filter(function (value) { return value.subject.opsubType !== 'BSM' &&
                            _this.data.caseOwnerships.some(function (value2) {
                                var bsmSubject;
                                if (value2.ownership.isBsm) {
                                    bsmSubject = _this.data.caseSubjects.find(function (cs) { return cs.subject.opsubId === value2.ownership.opsubId; }).subject;
                                }
                                return !value2.isParent && ((!bsmSubject && value2.ownership.opsubId === value.subject.opsubId) || (bsmSubject && (bsmSubject.partner1OpsubId === value.subject.opsubId || bsmSubject.partner2OpsubId === value.subject.opsubId)));
                            }); });
                        this.dataSend.caseSubjects.forEach(function (item) {
                            _this.enabledSubjects[item.id] = !item.signedDate;
                            if (item.signatureVerificationPrice === null && _this.signatureVerificationPriceEnabled()) {
                                item.signatureVerificationPrice = 0;
                            }
                        });
                        this.alreadySigned = this.dataSend.caseSubjects.filter(function (subject) { return subject.signedDate; }).length;
                        this.onResponseReceivedDataChanged();
                        this.loading = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    SignOwnerComponent.prototype.onSubjectSignDateChanged = function (signedDate) {
        if ((this.data.bonusDate || this.dueDateCompute())) {
            this.checkBonus();
        }
    };
    SignOwnerComponent.prototype.onResponseReceivedDataChanged = function () {
        if (this.data.validityDate || this.data.bonusDate || this.dueDateCompute()) {
            this.checkBonus();
            if (this.dataSend.responseReceivedDate && this.data.validityDate) {
                this.deliveredInTime = +new Date(this.data.validityDate) >= +new Date(this.dataSend.responseReceivedDate);
            }
            else {
                this.deliveredInTime = false;
            }
        }
    };
    SignOwnerComponent.prototype.isValid = function () {
        return this.dataSend.caseSubjects.filter(function (subject) { return subject.signedDate; }).length > this.alreadySigned;
    };
    SignOwnerComponent.prototype.signatureVerificationPriceEnabled = function () {
        var whitelist = [
            'AccessToLandLiability',
            'AgreementOnAFutureNetworkConstructionContractLiability',
            'AgreementOnAFutureTransferContractLiability',
            'AgreementOnAOtherFutureContractLiability',
            'BuildingPlacementLiability',
            'BorrowingLiability',
            'OtherLiability',
            'OwnersStatementLiability',
            'RightToRealizeABuildingLiability',
            'FutureRealBurdenLiabilityAccelerated',
        ];
        return !whitelist.includes(this.data.obligation.type);
    };
    SignOwnerComponent.prototype.onSubmit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (_.some(this.dataSend.caseSubjects, function (item) {
                            return item.signedDate && (!_this.dataSend.responseReceivedDate || (item.signatureVerificationPrice && !isFinite(item.signatureVerificationPrice)));
                        })) {
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, this.restangular
                                .one('cases', this.caseId)
                                .customPUT(this.dataSend, 'sign-owner')];
                    case 1:
                        _a.sent();
                        this.stateService.go('^');
                        return [2 /*return*/];
                }
            });
        });
    };
    SignOwnerComponent.prototype.dueDateCompute = function () {
        var duePeriod = this.data.obligation.duePeriod;
        var dueDataFromType = this.data.obligation.dueDateFromType;
        return !this.data.paymentOrderDate && duePeriod && (dueDataFromType === 'ResponseReceivedSignedDate' || dueDataFromType === 'SignedAllOwnersDate');
    };
    SignOwnerComponent.prototype.checkBonus = function () {
        var allSigned = false;
        var lastSignedDate = null;
        if ((this.data.bonusDate || this.dueDateCompute()) && this.dataSend.responseReceivedDate) {
            allSigned = true;
            _.forEach(this.dataSend.caseSubjects, function (item) {
                if (!item.signedDate) {
                    allSigned = false;
                }
                else if (lastSignedDate === null || +lastSignedDate < +new Date(item.signedDate)) {
                    lastSignedDate = new Date(item.signedDate);
                }
                return allSigned;
            });
        }
        if (this.data.bonusDate && this.dataSend.responseReceivedDate) {
            this.data.bonusGranted = this.data.bonusPossible && allSigned && +new Date(this.data.bonusDate) >= +new Date(this.dataSend.responseReceivedDate);
        }
        else {
            this.data.bonusGranted = false;
        }
        if (this.dueDateCompute()) {
            var duePeriod = this.data.obligation.duePeriod;
            var dueDataFromType = this.data.obligation.dueDateFromType;
            if (this.dataSend.responseReceivedDate && allSigned) {
                var date = dueDataFromType === 'ResponseReceivedSignedDate' ? new Date(this.dataSend.responseReceivedDate) : lastSignedDate;
                var newDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() + duePeriod);
                this.data.dueDate = this.datePipe.transform(newDate, 'yyyy-MM-dd');
            }
            else {
                this.data.dueDate = null;
            }
        }
    };
    return SignOwnerComponent;
}());
exports.SignOwnerComponent = SignOwnerComponent;
