"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var ModulesService = /** @class */ (function () {
    function ModulesService(modulesService) {
        this.modulesService = modulesService;
    }
    ModulesService.prototype.loadModuleRoles = function (module) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.modulesService.loadModuleRoles(module)];
            });
        });
    };
    ModulesService.prototype.getModuleRoles = function (module) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.modulesService.getModuleRoles(module)];
            });
        });
    };
    ModulesService.prototype.loadModulePermissions = function (module) {
        return this.modulesService.loadModulePermissions(module);
    };
    ModulesService.prototype.loadSyMAPProjectById = function (projectId) {
        return this.modulesService.loadSyMAPProjectById(projectId);
    };
    ModulesService.prototype.loadProjects = function (module) {
        return this.modulesService.loadProjects(module);
    };
    ModulesService.prototype.loadUser = function (userId) {
        return this.modulesService.loadUser(userId);
    };
    ModulesService.prototype.createUser = function (user) {
        return this.modulesService.createUser(user);
    };
    ModulesService.prototype.saveUser = function (user) {
        return this.modulesService.saveUser(user);
    };
    ModulesService.prototype.removeUser = function (userId) {
        return this.modulesService.removeUser(userId);
    };
    ModulesService.prototype.loadUserPermissions = function (module, userId) {
        return this.modulesService.loadUserPermissions(module, userId);
    };
    ModulesService.prototype.saveUserPermissions = function (module, projectKey, userId, permissions) {
        return this.modulesService.saveUserPermissions(module, projectKey, userId, permissions);
    };
    ModulesService.prototype.setPermissions = function (module, properties) {
        return this.modulesService.setPermissions(module, properties);
    };
    ModulesService.prototype.getPermissions = function (module) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.modulesService.getPermissions(module)];
            });
        });
    };
    ModulesService.prototype.loadUserApprovedTerms = function (userId) {
        return this.modulesService.loadUserApprovedTerms(userId);
    };
    ModulesService.prototype.loadTermsConditions = function (module) {
        return this.modulesService.loadTermsConditions(module);
    };
    ModulesService.prototype.setUserApprovedTerms = function (userId, approvedTerms) {
        return this.modulesService.setUserApprovedTerms(userId, approvedTerms);
    };
    ModulesService.prototype.setTermsConditions = function (module, conditions) {
        return this.modulesService.setTermsConditions(module, conditions);
    };
    ModulesService.prototype.saveConditions = function (module, conditions) {
        return this.modulesService.saveConditions(module, conditions);
    };
    ModulesService.prototype.setProjects = function (module, data) {
        return this.modulesService.setProjects(module, data);
    };
    ModulesService.prototype.getProjectById = function (module, projectId) {
        return this.modulesService.getProjectById(module, projectId);
    };
    ModulesService.prototype.saveProject = function (module, project) {
        return this.modulesService.saveProject(module, project);
    };
    ModulesService.prototype.getProjects = function (module) {
        return this.modulesService.getProjects(module);
    };
    ModulesService.prototype.setUser = function (userId, data) {
        return this.modulesService.setUser(userId, data);
    };
    ModulesService.prototype.getUser = function (userId) {
        return this.modulesService.getUser(userId);
    };
    ModulesService.prototype.emptyUserPermissions = function (userId) {
        return this.modulesService.emptyUserPermissions(userId);
    };
    ModulesService.prototype.setUserPermissions = function (module, userId, permissions) {
        return this.modulesService.setUserPermissions(module, userId, permissions);
    };
    ModulesService.prototype.updateUserPermissions = function (module, userId, projectKey, permissions) {
        return this.modulesService.updateUserPermissions(module, userId, projectKey, permissions);
    };
    ModulesService.prototype.getUserPermissions = function (module, userId) {
        return this.modulesService.getUserPermissions(module, userId);
    };
    ModulesService.prototype.approveTerms = function (userId, approvedModule) {
        return this.modulesService.approveTerms(userId, approvedModule);
    };
    ModulesService.prototype.getApproveTermsConditions = function (module) {
        return this.modulesService.getApproveTermsConditions(module);
    };
    ModulesService.prototype.getUserApprovedTerms = function (userId) {
        return this.modulesService.getUserApprovedTerms(userId);
    };
    ModulesService.prototype.sendUserEmail = function (userId, email) {
        return this.modulesService.sendUserEmail(userId, email);
    };
    ModulesService.prototype.getNotificationConfigs = function (module, projectKey, userId) {
        return this.modulesService.getNotificationConfigs(module, projectKey, userId);
    };
    ModulesService.prototype.uploadNotificationConfig = function (config) {
        return this.modulesService.uploadNotificationConfig(config);
    };
    ModulesService.prototype.uploadProjectNotificationConfigs = function (projectKey, configs) {
        return this.modulesService.uploadProjectNotificationConfigs(projectKey, configs);
    };
    ModulesService.ngInjectableDef = i0.defineInjectable({ factory: function ModulesService_Factory() { return new ModulesService(i0.inject("ModulesService")); }, token: ModulesService, providedIn: "root" });
    return ModulesService;
}());
exports.ModulesService = ModulesService;
