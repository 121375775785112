"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var angular_1 = require("@uirouter/angular");
var title_detail_create_case_select_parent_service_1 = require("@app/ps/services/title-detail-create-case-select-parent.service");
var CreateCaseComponent = /** @class */ (function () {
    function CreateCaseComponent(transitionService, stateService, titleDetailCreateCaseSelectParentService, HELP_IDS, confirmService) {
        this.transitionService = transitionService;
        this.stateService = stateService;
        this.titleDetailCreateCaseSelectParentService = titleDetailCreateCaseSelectParentService;
        this.HELP_IDS = HELP_IDS;
        this.confirmService = confirmService;
        this.finished = false;
        this.loading = true;
        this.menuSteps = [
            { name: 'Typ případu', ids: ['select-obligation'], enabled: false },
            { name: 'Geometrický plán', ids: ['select-area'], enabled: false },
            { name: 'Věcné břemeno', ids: ['select-easement'], enabled: false },
            { name: 'Detaily případu', ids: ['select-parent-ownership', 'price'], enabled: false },
            { name: 'Potvrzení', ids: ['confirm'], enabled: false },
            { name: 'Výsledek', ids: ['summary'], enabled: false },
        ];
        this.hasStep = this.hasStep.bind(this);
        this.onSelectParentOwnership = this.onSelectParentOwnership.bind(this);
    }
    // Prevent exit without users will
    CreateCaseComponent.prototype.beforeExit = function (transition) {
        var _this = this;
        if (transition && transition.to() === transition.from()) {
            return;
        }
        return new this.confirmService({
            title: 'Zrušit založení případu',
            message: 'Opravdu chcete odejít? Všechna zadaná data budou ztracena.'
        }).then(function (data) {
            if (data === true) {
                _this.deregisterLeaveListener();
            }
            else {
                return Promise.resolve(false);
            }
        });
    };
    CreateCaseComponent.prototype.ngOnInit = function () {
        this.steps = [];
        this.stepChanged('select-obligation');
        this.data = {
            obligation: null,
            createGroups: [],
            cases: null,
        };
        // If any link is clicked
        this.deregisterLeaveListener = this.transitionService.onBefore({}, this.beforeExit.bind(this));
    };
    CreateCaseComponent.prototype.onSelectObligation = function ($event) {
        if ($event === undefined) {
            this.stepChanged('select-area');
        }
        else if ($event.skipped === true) {
            this.steps.pop();
            this.menuSteps.shift();
            this.loading = false;
            this.stepChanged('select-area');
        }
        else if ($event.skipped === false) {
            this.loading = false;
        }
    };
    CreateCaseComponent.prototype.onSelectArea = function () {
        this.stepChanged('select-easement');
    };
    CreateCaseComponent.prototype.onSelectEasement = function () {
        this.stepChanged('select-parent-ownership');
    };
    CreateCaseComponent.prototype.onSelectParentOwnership = function (skipped) {
        if (skipped === void 0) { skipped = false; }
        if (skipped) {
            this.steps.pop();
        }
        this.stepChanged('price');
    };
    CreateCaseComponent.prototype.onPrice = function () {
        this.stepChanged('confirm');
    };
    CreateCaseComponent.prototype.onConfirm = function () {
        this.finished = true;
        this.deregisterLeaveListener();
        this.stepChanged('summary');
    };
    CreateCaseComponent.prototype.onBack = function () {
        this.steps.pop();
        this.stepChanged(this.steps.pop());
    };
    CreateCaseComponent.prototype.onGoToConstructionObject = function () {
        this.stateService.go("^");
    };
    CreateCaseComponent.prototype.hasStep = function (stepId) {
        return this.steps.includes(stepId);
    };
    CreateCaseComponent.prototype.onClickStep = function (menuStep) {
        if (!menuStep.enabled) {
            return;
        }
        while (!menuStep.ids.includes(this.activeStepId) && this.steps.length) {
            this.onBack();
        }
        var index = menuStep.ids.indexOf(this.activeStepId);
        if (index > 0 && this.hasStep(menuStep.ids[index - 1])) {
            this.onBack();
        }
    };
    CreateCaseComponent.prototype.stepChanged = function (stepId) {
        var _this = this;
        this.activeStepId = stepId;
        var menuStep = this.menuSteps.find(function (menuStep) { return menuStep.ids.includes(stepId); });
        this.steps.push(stepId);
        var helpId = null;
        switch (stepId) {
            case 'select-obligation':
                helpId = null;
                break;
            case 'select-area':
                helpId = this.HELP_IDS.CONSTRUCTIONOBJECT_CREATECASE_SELECT_AREA;
                break;
            case 'select-easement':
                helpId = this.HELP_IDS.CONSTRUCTIONOBJECT_CREATECASE_SELECT_EASEMENT;
                break;
            case 'select-parent-ownership':
                helpId = this.HELP_IDS.CONSTRUCTIONOBJECT_CREATECASE_SELECT_PARENT_OWNERSHIP;
                break;
            case 'price':
                helpId = this.HELP_IDS.CONSTRUCTIONOBJECT_CREATECASE_PRICE;
                break;
            case 'confirm':
                helpId = null;
                break;
            case 'summary':
                helpId = this.HELP_IDS.CONSTRUCTIONOBJECT_CREATECASE_SUMMARY;
                break;
        }
        this.helpId = helpId;
        if (this.onHelpIdChanged) {
            this.onHelpIdChanged(this.helpId);
        }
        this.menuSteps.forEach(function (step) {
            step.enabled = (step.ids.includes('summary') && _this.activeStepId === 'summary') || (_this.activeStepId !== 'summary' && step.ids.some(_this.hasStep));
        });
    };
    CreateCaseComponent.prototype.onCallbackRegister = function ($event) {
        this.onHelpIdChanged = $event.onHelpIdChanged;
    };
    return CreateCaseComponent;
}());
exports.CreateCaseComponent = CreateCaseComponent;
