"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vfze_states_1 = require("@app/ps/vfze/vfze.states");
var STATES = [
    vfze_states_1.vfzeListState,
    vfze_states_1.vfzeDetailState,
    vfze_states_1.vfzeImportState,
];
var VfzeModule = /** @class */ (function () {
    function VfzeModule() {
    }
    return VfzeModule;
}());
exports.VfzeModule = VfzeModule;
