"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var _ = require("lodash");
var $ = require("jquery");
var brand_translate_pipe_1 = require("@app/common/pipes/brand-translate.pipe");
var projects_service_1 = require("@app/landing/services/projects.service");
var class_name_enum_1 = require("@app/dialog/class-name.enum");
var dialog_service_1 = require("@app/dialog/dialog.service");
var project_columns_edit_component_1 = require("@app/landing/components/project-columns-edit/project-columns-edit.component");
var xlsx_service_1 = require("@app/common/services/xlsx.service");
var auth_service_1 = require("@app/common/services/auth.service");
var ProjectListComponent = /** @class */ (function () {
    function ProjectListComponent(APPLICATIONS, APP_BRAND, helpIds, localStorageService, uiconstants, restangularSymap, dialogService, authService, xlsxService, brandTranslatePipe, projectsService) {
        this.APPLICATIONS = APPLICATIONS;
        this.APP_BRAND = APP_BRAND;
        this.helpIds = helpIds;
        this.localStorageService = localStorageService;
        this.uiconstants = uiconstants;
        this.restangularSymap = restangularSymap;
        this.dialogService = dialogService;
        this.authService = authService;
        this.xlsxService = xlsxService;
        this.brandTranslatePipe = brandTranslatePipe;
        this.projectsService = projectsService;
        this.loading = true;
        this.sortOrder = { sortBy: 'isprofond', direction: 'asc' };
        this.itemsPerPage = this.uiconstants.itemsPerPage10;
        this.dataPaging = { itemsPerPage: { id: 10, name: 10 } };
        this.statuses = ['CREATED', 'STARTED_PARTIAL', 'STARTED_COMPLETE', 'PAUSED', 'FINISHED'];
        this.filtersData = {};
        this.reloadList = this.reloadList.bind(this);
        this.ngOnInit = this.ngOnInit.bind(this);
    }
    ProjectListComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var selectedColumns, sortedColumns, dataPaging, sortOrder, cancelPromise;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.columns = this.getColumnDefinitions();
                        selectedColumns = this.localStorageService.get('syProjectListSelectedColumns_3');
                        sortedColumns = this.localStorageService.get('syProjectListSortedColumns_3');
                        dataPaging = this.localStorageService.get('syProjectListPageItems_3');
                        sortOrder = this.localStorageService.get('syProjectListSortOrder_3');
                        if (selectedColumns && sortedColumns) {
                            this.selectedColumns = selectedColumns;
                            this.sortedColumns = sortedColumns;
                        }
                        else {
                            this.selectedColumns = ProjectListComponent.DEFAULT_COLUMNS;
                            this.sortedColumns = this.columns.map(function (c) { return c.id; });
                        }
                        if (dataPaging) {
                            this.dataPaging = dataPaging;
                        }
                        if (sortOrder) {
                            this.sortOrder = sortOrder;
                        }
                        this.sortColumns();
                        // project data
                        return [4 /*yield*/, this.reloadProjectData()];
                    case 1:
                        // project data
                        _a.sent();
                        cancelPromise = new Promise(function (resolve, reject) {
                            _this.xhrDashCancelResolve = resolve;
                        });
                        this.restangularSymap.all('dashboard')
                            .withHttpConfig({ timeout: cancelPromise })
                            .post()
                            .then(function (d) {
                            _this.dashboard = d.data;
                            _this.mapDashboardToProjects();
                        });
                        $('#content-wrap').on('scroll', function () { _this.onScroll(); });
                        return [2 /*return*/];
                }
            });
        });
    };
    ProjectListComponent.prototype.ngOnDestroy = function () {
        if (this.xhrCancelResolve) {
            this.xhrCancelResolve();
        }
        if (this.xhrDashCancelResolve) {
            this.xhrDashCancelResolve();
        }
        $('#content-wrap').unbind('scroll');
    };
    ProjectListComponent.prototype.onScroll = function () {
        var _this = this;
        clearTimeout(this.scrolling);
        this.scrolling = setTimeout(function () {
            var tableTop = _this.tableEl.nativeElement.getBoundingClientRect().top;
            var ths = $(_this.tableEl.nativeElement.querySelectorAll('thead th div'));
            if (tableTop < 80) {
                ths.css('top', (80 - tableTop) + 'px');
            }
            else {
                ths.css('top', '0px');
            }
        });
    };
    ProjectListComponent.prototype.onEditColumns = function () {
        var _this = this;
        var dialog = this.dialogService.open(project_columns_edit_component_1.ProjectColumnsEditComponent, {
            data: {
                columns: __spread(this.columns),
                selectedColumns: __spread(this.selectedColumns),
            },
            className: class_name_enum_1.ClassName.HIGHER_DIALOG
        });
        var sub = dialog.afterClosed.subscribe(function (result) {
            if (result) {
                _this.localStorageService.set('syProjectListSelectedColumns_3', result.selectedColumns);
                _this.localStorageService.set('syProjectListSortedColumns_3', result.sortedColumns);
                _this.selectedColumns = result.selectedColumns;
                _this.sortedColumns = result.sortedColumns;
                _this.sortColumns();
                if (_this.sortOrder && !_this.selectedColumns.includes(_this.sortOrder.sortBy)) {
                    _this.sortOrder = { sortBy: 'isprofond', direction: 'asc' };
                    _this.localStorageService.set('syProjectListSortOrder_3', _this.sortOrder);
                }
                _this.reloadProjectData();
            }
            sub.unsubscribe();
        });
    };
    ProjectListComponent.prototype.onSort = function (sortBy) {
        this.sortOrder.direction = sortBy === this.sortOrder.sortBy
            ? this.sortOrder.direction === 'asc' ? 'desc' : 'asc'
            : 'asc';
        this.sortOrder.sortBy = sortBy;
        this.localStorageService.set('syProjectListSortOrder_3', this.sortOrder);
        this.reloadList();
    };
    ProjectListComponent.prototype.getFilterItemsByColumn = function (column) {
        var columnItems = this.projects
            .map(function (p) { return { id: p[column.id], name: p[column.id] }; })
            .filter(function (i) { return i.id !== '' && i.id !== undefined; })
            .sort(function (a, b) {
            if (a.name < b.name) {
                return -1;
            }
            if (a.name > b.name) {
                return 1;
            }
            return 0;
        });
        return _.uniqBy(columnItems, 'id');
    };
    ProjectListComponent.prototype.reloadList = function () {
        var projects = this.sortAndFilterProjects();
        this.list.list = projects.slice(this.list.filter.offset, this.list.filter.offset + this.dataPaging.itemsPerPage.id);
        this.list.itemCount = projects.length;
        this.list.filter.limit = this.dataPaging.itemsPerPage.id;
        this.onListChanged();
    };
    ProjectListComponent.prototype.onPageChanged = function (event) {
        this.list.filter.offset = (event - 1) * this.dataPaging.itemsPerPage.id;
        this.reloadList();
    };
    ProjectListComponent.prototype.getVisibleColumns = function () {
        var _this = this;
        return this.columns.filter(function (c) { return _this.selectedColumns.includes(c.id); });
    };
    ProjectListComponent.prototype.pagesCounterCallbackRegister = function (event) {
        this.onListChangedPagesCounter = event.onListChanged;
    };
    ProjectListComponent.prototype.itemCounterCallbackRegister = function (event) {
        this.onListChangedItemCounter = event.onListChanged;
    };
    ProjectListComponent.prototype.paginationCallbackRegister = function (event) {
        this.onListChangedPagination = event.onListChanged;
    };
    ProjectListComponent.prototype.onListChanged = function () {
        if (this.onListChangedPagination) {
            this.onListChangedPagination();
        }
        if (this.onListChangedItemCounter) {
            this.onListChangedItemCounter();
        }
        if (this.onListChangedPagesCounter) {
            this.onListChangedPagesCounter();
        }
    };
    ProjectListComponent.prototype.onPageItemsChanged = function () {
        this.list.filter.offset = 0;
        this.localStorageService.set('syProjectListPageItems_3', this.dataPaging);
        this.reloadList();
    };
    ProjectListComponent.prototype.onExport = function () {
        var data = this.projectsService.transformForExport(this.sortAndFilterProjects(), this.columns, this.selectedColumns);
        var cellOpinions = this.getVisibleColumns().map(function (c) { return c.exportStyle ? c.exportStyle : undefined; });
        this.xlsxService.createFile(data, this.brandTranslatePipe.transform('ps.projectList') + '.xlsx', undefined, cellOpinions);
    };
    ProjectListComponent.prototype.reloadProjectData = function () {
        var _this = this;
        var cancelPromise = new Promise(function (resolve, reject) {
            _this.xhrCancelResolve = resolve;
        });
        var lastActivity = this.selectedColumns.indexOf('lastActivityDate') !== -1
            || this.selectedColumns.indexOf('lastActivityAuthor') !== -1;
        this.loading = true;
        return this.restangularSymap
            .one('users', this.authService.getUser().id)
            .withHttpConfig({ timeout: cancelPromise })
            .customGET('projects', { loadCollections: lastActivity ? ['lastActivity'] : [] })
            .then(function (d) {
            // @TODO - temp hide construction
            var projects = d.items.filter(function (p) { return p.key !== 'sy_statistics'; });
            _this.projects = _this.projectsService.createFromApi(projects);
            _this.mapDashboardToProjects();
            _this.initList();
            _this.loading = false;
        });
    };
    ProjectListComponent.prototype.mapDashboardToProjects = function () {
        var _this = this;
        if (!this.dashboard) {
            return;
        }
        this.projects.forEach(function (project) {
            var dashboard = _this.dashboard.find(function (dashboard) { return dashboard.key === project.key; });
            if (dashboard) {
                projects_service_1.ProjectsService.mapDashboardDataOnProject(project, dashboard);
            }
        });
    };
    ProjectListComponent.prototype.initList = function () {
        var projects = this.sortAndFilterProjects();
        this.list = {
            loading: false,
            list: projects.slice(0, this.dataPaging.itemsPerPage.id),
            itemCount: projects.length,
            filter: { offset: 0, limit: this.dataPaging.itemsPerPage.id }
        };
    };
    ProjectListComponent.prototype.getColumnById = function (id) {
        return this.columns.find(function (c) { return c.id === id; });
    };
    ProjectListComponent.prototype.sortAndFilterProjects = function () {
        var _this = this;
        var e_1, _a;
        var projects = this.projects;
        var _loop_1 = function (column) {
            if (column.filterable && this_1.filtersData[column.id] && this_1.filtersData[column.id].length) {
                var filter_1 = this_1.filtersData[column.id].map(function (i) { return i.id; });
                projects = projects.filter(function (p) { return filter_1.includes(p[column.id]); });
            }
        };
        var this_1 = this;
        try {
            // filters
            for (var _b = __values(this.columns), _c = _b.next(); !_c.done; _c = _b.next()) {
                var column = _c.value;
                _loop_1(column);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        // fulltext
        if (this.searchText) {
            var searchText_1 = this.searchText.toLowerCase();
            projects = projects.filter(function (p) {
                var e_2, _a;
                try {
                    for (var _b = __values(_this.columns), _c = _b.next(); !_c.done; _c = _b.next()) {
                        var column = _c.value;
                        if (column.searchable) {
                            if (p[column.id] && p[column.id].toLowerCase().indexOf(searchText_1) !== -1) {
                                return true;
                            }
                        }
                    }
                }
                catch (e_2_1) { e_2 = { error: e_2_1 }; }
                finally {
                    try {
                        if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                    }
                    finally { if (e_2) throw e_2.error; }
                }
                return false;
            });
        }
        // order
        if (!this.sortOrder) {
            return projects;
        }
        var sortColumn = this.getColumnById(this.sortOrder.sortBy);
        return _.orderBy(projects, this.getSortFunction(sortColumn), this.sortOrder.direction);
    };
    ProjectListComponent.prototype.getSortFunction = function (column) {
        return function (p) {
            var value = p[column.id];
            if (value === undefined) {
                return value;
            }
            if (column.customSort) {
                return column.customSort(value);
            }
            return typeof value === 'string' ? value.toLowerCase() : value;
        };
    };
    ProjectListComponent.prototype.getColumnDefinitions = function () {
        var columns = [
            {
                id: 'isprofond',
                name: 'Evidenční číslo',
                sortable: true,
                selectable: false,
                searchable: true,
                style: { 'min-width': '100px', 'max-width': '100px', 'word-break': 'break-all' },
            },
            {
                id: 'name',
                name: this.brandTranslatePipe.transform('ps.projectName'),
                sortable: true,
                selectable: false,
                searchable: true,
                style: { 'min-width': '300px' },
            },
            {
                id: 'manager',
                name: this.brandTranslatePipe.transform('ps.projectManager'),
                sortable: true,
                filterable: true,
                searchable: true,
                style: { 'min-width': '160px', 'max-width': '160px' },
            },
            {
                id: 'investor',
                name: this.brandTranslatePipe.transform('ps.investor'),
                sortable: true,
                filterable: true,
                searchable: true,
                style: { 'min-width': '165px', 'max-width': '165px' },
            },
            {
                id: 'unit',
                name: this.brandTranslatePipe.transform('ps.unit'),
                sortable: true,
                filterable: true,
                searchable: true,
                style: { 'min-width': '120px', 'max-width': '120px' },
            },
            {
                id: 'region',
                name: 'Kraj',
                sortable: true,
                filterable: true,
                searchable: true,
                style: { 'min-width': '150px', 'max-width': '150px' },
            },
            {
                id: 'total',
                name: this.brandTranslatePipe.transform('ps.projectList.total'),
                sortable: true,
                style: { 'min-width': '90px', 'max-width': '90px', 'text-align': 'center' },
                exportStyle: { style: { alignment: { horizontal: 'center' } } },
            },
            {
                id: 'done',
                name: this.brandTranslatePipe.transform('ps.projectList.done'),
                sortable: true,
                style: { 'min-width': '100px', 'max-width': '100px', 'text-align': 'center' },
                exportStyle: { style: { alignment: { horizontal: 'center' } } },
            },
            {
                id: 'donePercentage',
                name: this.brandTranslatePipe.transform('ps.projectList.donePercentage'),
                sortable: true,
                style: { 'min-width': '120px', 'max-width': '120px', 'text-align': 'center' },
                exportStyle: { style: { alignment: { horizontal: 'center' } } },
            },
            {
                id: 'expropriationTotal',
                name: this.brandTranslatePipe.transform('ps.projectList.expropriationTotal'),
                sortable: true,
                style: { 'min-width': '120px', 'max-width': '120px', 'text-align': 'center' },
                exportStyle: { style: { alignment: { horizontal: 'center' } } },
            },
            {
                id: 'infrastructure',
                name: this.brandTranslatePipe.transform('ps.infrastructure'),
                sortable: true,
                filterable: true,
                searchable: true,
                style: { 'min-width': '120px', 'max-width': '120px' },
            },
            {
                id: 'tzTotal',
                name: 'TZ počet případů',
                sortable: true,
                style: { 'min-width': '90px', 'max-width': '90px', 'text-align': 'center' },
                exportStyle: { style: { alignment: { horizontal: 'center' } } },
            },
            {
                id: 'tzDone',
                name: 'TZ vypořádáno případů',
                sortable: true,
                style: { 'min-width': '120px', 'max-width': '120px', 'text-align': 'center' },
                exportStyle: { style: { alignment: { horizontal: 'center' } } },
            },
            {
                id: 'dznrTotal',
                name: 'DZ nad 1 rok počet případů',
                sortable: true,
                style: { 'min-width': '100px', 'max-width': '100px', 'text-align': 'center' },
                exportStyle: { style: { alignment: { horizontal: 'center' } } },
            },
            {
                id: 'dznrDone',
                name: 'DZ nad 1 rok vypořádáno případů',
                sortable: true,
                style: { 'min-width': '100px', 'max-width': '100px', 'text-align': 'center' },
                exportStyle: { style: { alignment: { horizontal: 'center' } } },
            },
            {
                id: 'dzdrTotal',
                name: 'DZ do 1 roku počet případů',
                sortable: true,
                style: { 'min-width': '100px', 'max-width': '100px', 'text-align': 'center' },
                exportStyle: { style: { alignment: { horizontal: 'center' } } },
            },
            {
                id: 'dzdrDone',
                name: 'DZ do 1 roku vypořádáno případů',
                sortable: true,
                style: { 'min-width': '100px', 'max-width': '100px', 'text-align': 'center' },
                exportStyle: { style: { alignment: { horizontal: 'center' } } },
            },
            {
                id: 'vbTotal',
                name: 'VB počet případů',
                sortable: true,
                style: { 'min-width': '100px', 'max-width': '100px', 'text-align': 'center' },
                exportStyle: { style: { alignment: { horizontal: 'center' } } },
            },
            {
                id: 'vbDone',
                name: 'VB vypořádáno případů',
                sortable: true,
                style: { 'min-width': '100px', 'max-width': '100px', 'text-align': 'center' },
                exportStyle: { style: { alignment: { horizontal: 'center' } } },
            },
            {
                id: 'tzDonePercentage',
                name: 'Stav vypořádání trvalého záboru v %',
                sortable: true,
                style: { 'min-width': '120px', 'max-width': '120px', 'text-align': 'center' },
                exportStyle: { style: { alignment: { horizontal: 'center' } } },
            },
            {
                id: 'dzdrDonePercentage',
                name: 'Stav vypořádání dočasného záboru do 1 roku v %',
                sortable: true,
                style: { 'min-width': '120px', 'max-width': '120px', 'text-align': 'center' },
                exportStyle: { style: { alignment: { horizontal: 'center' } } },
            },
            {
                id: 'dznrDonePercentage',
                name: 'Stav vypořádání dočasného záboru nad 1 rok v %',
                sortable: true,
                style: { 'min-width': '120px', 'max-width': '120px', 'text-align': 'center' },
                exportStyle: { style: { alignment: { horizontal: 'center' } } },
            },
            {
                id: 'vbDonePercentage',
                name: 'Stav vypořádání věcných břemen  v %',
                sortable: true,
                style: { 'min-width': '120px', 'max-width': '120px', 'text-align': 'center' },
                exportStyle: { style: { alignment: { horizontal: 'center' } } },
            },
            {
                id: 'readinessPermanentOccupations',
                name: 'Úplnost dat trvalého záboru',
                sortable: true,
                style: { 'min-width': '160px' },
            },
            {
                id: 'readinessTemporaryUnderOccupations',
                name: 'Úplnost dat dočasného záboru do 1 roku',
                sortable: true,
                style: { 'min-width': '160px' },
            },
            {
                id: 'readinessTemporaryOverOccupations',
                name: 'Úplnost dat dočasného záboru nad 1 rok',
                sortable: true,
                style: { 'min-width': '160px' },
            },
            {
                id: 'readinessTemporaryEasements',
                name: 'Úplnost dat věcných břemen',
                sortable: true,
                style: { 'min-width': '160px' },
            },
            {
                id: 'stateName',
                name: this.brandTranslatePipe.transform('ps.projectState'),
                sortable: true,
                searchable: true,
                filterable: true,
                style: { 'min-width': '100px', 'max-width': '100px' },
            },
            {
                id: 'parcelTotal',
                name: 'Cekový počet parcel',
                sortable: true,
                style: { 'min-width': '100px', 'max-width': '100px', 'text-align': 'center' },
                exportStyle: { style: { alignment: { horizontal: 'center' } } },
            },
            {
                id: 'lastActivityDate',
                name: 'Datum poslední změny',
                sortable: true,
                style: { 'min-width': '100px', 'max-width': '100px', 'text-align': 'center' },
                exportStyle: { style: { alignment: { horizontal: 'center' } } },
            },
            {
                id: 'lastActivityAuthor',
                name: 'Autor poslední změny',
                sortable: true,
                style: { 'min-width': '160px' },
            },
            {
                id: 'projectStartDate',
                name: this.brandTranslatePipe.transform('ps.projectStartDate'),
                sortable: true,
                style: { 'min-width': '120px', 'max-width': '120px' },
            },
            {
                id: 'phase',
                name: 'Stupeň',
                sortable: true,
                style: { 'min-width': '100px', 'max-width': '100px' },
            },
            {
                id: 'propertyActivityProcessorCompany',
                name: this.brandTranslatePipe.transform('ps.propertyActivityProcessor') + ' (firma)',
                sortable: true,
            },
            {
                id: 'propertyActivityProcessorName',
                name: this.brandTranslatePipe.transform('ps.propertyActivityProcessor') + ' (příjmení jméno)',
                sortable: true,
            },
            {
                id: 'landTakeDocumentationAuthorCompany',
                name: 'Zpracovatel záb. elaborátu (firma)',
                sortable: true,
            },
            {
                id: 'landTakeDocumentationAuthorName',
                name: 'Zpracovatel záb. elaborátu (příjmení jméno)',
                sortable: true,
            },
            {
                id: 'designerCompany',
                name: 'Projektant (firma)',
                sortable: true,
            },
            {
                id: 'designerName',
                name: 'Projektant (příjmení jméno)',
                sortable: true,
            },
        ];
        if (this.APP_BRAND.NAME === 'SZ') {
            var denyCols_1 = ['infrastructure', 'unit'];
            return columns.filter(function (c) { return !denyCols_1.includes(c.id); });
        }
        return columns;
    };
    ProjectListComponent.prototype.sortColumns = function () {
        var _this = this;
        this.columns = _.sortBy(this.columns, function (c) {
            return _this.sortedColumns.indexOf(c.id);
        });
    };
    ProjectListComponent.DEFAULT_COLUMNS = [
        'isprofond',
        'name',
        'manager',
        'investor',
        'unit',
        'region',
        'total',
        'done',
        'expropriationTotal',
        'projectStartDate',
    ];
    return ProjectListComponent;
}());
exports.ProjectListComponent = ProjectListComponent;
