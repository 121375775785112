"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var i0 = require("@angular/core");
/**
 * Display name of case
 */
var CaseNamePipe = /** @class */ (function () {
    function CaseNamePipe() {
    }
    CaseNamePipe.prototype.transform = function (businessCase) {
        return businessCase.mpp.number ? businessCase.mpp.number : 'bez čísla';
    };
    CaseNamePipe.ngInjectableDef = i0.defineInjectable({ factory: function CaseNamePipe_Factory() { return new CaseNamePipe(); }, token: CaseNamePipe, providedIn: "root" });
    return CaseNamePipe;
}());
exports.CaseNamePipe = CaseNamePipe;
