"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("@angular/common");
var i2 = require("./multi-scene-cloud.component");
var i3 = require("../../../../common/services/auth.service");
var i4 = require("../../services/multi-scene-cloud.service");
var styles_MultiSceneCloudComponent = [];
var RenderType_MultiSceneCloudComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MultiSceneCloudComponent, data: {} });
exports.RenderType_MultiSceneCloudComponent = RenderType_MultiSceneCloudComponent;
function View_MultiSceneCloudComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.message; _ck(_v, 1, 0, currVal_0); }); }
function View_MultiSceneCloudComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "div", [["id", "multi-scene-cloud"], ["style", "width: 100%; height: 540px;"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MultiSceneCloudComponent_1)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.message; _ck(_v, 3, 0, currVal_0); }, null); }
exports.View_MultiSceneCloudComponent_0 = View_MultiSceneCloudComponent_0;
function View_MultiSceneCloudComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "multi-scene-cloud", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onResize() !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_MultiSceneCloudComponent_0, RenderType_MultiSceneCloudComponent)), i0.ɵdid(1, 114688, null, 0, i2.MultiSceneCloudComponent, [i3.AuthService, i4.MultiSceneCloudService, i0.NgZone, "MULTI_SCENE_CLOUD"], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_MultiSceneCloudComponent_Host_0 = View_MultiSceneCloudComponent_Host_0;
var MultiSceneCloudComponentNgFactory = i0.ɵccf("multi-scene-cloud", i2.MultiSceneCloudComponent, View_MultiSceneCloudComponent_Host_0, { trjName: "trjName", photoName: "photoName", x: "x", y: "y", z: "z", yaw: "yaw", pitch: "pitch", layers: "layers" }, {}, []);
exports.MultiSceneCloudComponentNgFactory = MultiSceneCloudComponentNgFactory;
