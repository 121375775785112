"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var RadioComponent = /** @class */ (function () {
    function RadioComponent() {
        this.changed = new core_1.EventEmitter();
    }
    RadioComponent.prototype.ngOnInit = function () { };
    RadioComponent.prototype.onChanged = function () {
        this.changed.emit(this.gmtChecked);
    };
    return RadioComponent;
}());
exports.RadioComponent = RadioComponent;
