import ModulesService from './services/ModulesService';
import LoginService from './services/LoginService';
import GlobalRestangularProvider from './services/GlobalRestangularProvider';
import GlobalRestangularDIMAPProvider from './services/GlobalRestangularDIMAPProvider';
import GlobalRestangularSYMAPProvider from './services/GlobalRestangularSYMAPProvider';
import GlobalRestangularSettingsProvider from './services/GlobalRestangularSettingsProvider';
import GlobalRestangularConfigProvider from './services/GlobalRestangularConfigProvider';
import GlobalRestangularVFZEProvider from './services/GlobalRestangularVFZEProvider';
import GlobalRestangularSVProvider from './services/GlobalRestangularSVProvider';
import ApproveTermsService from './services/ApproveTermsService';
import ChangePasswordService from './services/ChangePasswordService.js';
import approveTerms from './components/approveTerms';
import capitalize from './filters/Capitalize';
import cmBackButton from './components/cmBackButton';
import cmButton from './components/cmButton';

var common = angular.module('common', [
    // Angular & vendor modules
    'restangular',
    //'cm.map', // bez tohohle nefunguji mapy
    'ngCookies',
    'config',
]);

// declare application wide events
common.constant('EVENTS', {
    authUserUpdated: 'auth-user-updated',
    authProjectUpdated: 'auth-project-updated',
    authTimeout: 'auth-timeout',
    notificationWithWarningHidden: 'notification-with-warning-hidden'
})
.constant('UICONSTANTS', {
  itemsPerPage: [
    {id: 20, name: '20'},
    {id: 50, name: '50'},
    {id: 100, name: '100'},
    {id: 200, name: '200'},
  ],
  itemsPerPage10: [
    {id: 10, name: '10'},
    {id: 20, name: '20'},
    {id: 50, name: '50'},
    {id: 100, name: '100'},
    {id: 200, name: '200'},
  ],
});

common.service('GlobalRestangularProvider', GlobalRestangularProvider);
common.service('GlobalRestangularDIMAPProvider', GlobalRestangularDIMAPProvider);
common.service('GlobalRestangularSYMAPProvider', GlobalRestangularSYMAPProvider);
common.service('GlobalRestangularSettingsProvider', GlobalRestangularSettingsProvider);
common.service('GlobalRestangularConfigProvider', GlobalRestangularConfigProvider);
common.service('GlobalRestangularVFZEProvider', GlobalRestangularVFZEProvider);
common.service('GlobalRestangularSVProvider', GlobalRestangularSVProvider);
common.service('ApproveTermsService', ApproveTermsService);
common.service('ChangePasswordService', ChangePasswordService);
common.service('RootCallbackService', ['CallbackService', (CallbackService) => new CallbackService()]);

common.component('approveTerms', approveTerms);
common.component('cmButton', cmButton);

common.filter('capitalize',capitalize);

common.service('ModulesService', ModulesService);

common.service('LoginService',LoginService);

common.component('cmBackButton',cmBackButton);

require("./services/AuthService.js");
require("./services/ListLoadService.js");
require("./services/ModalService.js");
require("./services/AlertService.js");
require("./services/ConfirmService.js");
require("./services/ChecklistService.js");
require("./services/WindowDimensionService.js");
require("./services/ErrorHandlerService.js");
require("./services/CallbackService.js");
require("./services/HelpService.js");
require("./services/GoogleAnalyticsService.js");
require("./directives/Loading.js");


require('./components/cmHelp.js');


