"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var TooltipComponent = /** @class */ (function () {
    function TooltipComponent(tooltipConfig, componentFactoryResolver, injector, renderer) {
        this.tooltipConfig = tooltipConfig;
        this.componentFactoryResolver = componentFactoryResolver;
        this.injector = injector;
        this.renderer = renderer;
    }
    TooltipComponent.prototype.ngOnInit = function () {
        this.generateContent();
    };
    TooltipComponent.prototype.generateContent = function () {
        if (typeof this.tooltipConfig.content === 'string') {
            var element = this.renderer.createText(this.tooltipConfig.content);
            this.renderer.appendChild(this.tooltipContentContainer.nativeElement, element);
        }
        else if (this.tooltipConfig.content instanceof core_1.TemplateRef) {
            var context = {};
            var viewRef = this.tooltipHostDirective.viewContainerRef.createEmbeddedView(this.tooltipConfig.content, context);
        }
        else if (this.tooltipConfig.content instanceof core_1.Type) {
            // Else it's a component
            var factory = this.componentFactoryResolver.resolveComponentFactory(this.tooltipConfig.content);
            var componentRef = this.tooltipHostDirective.viewContainerRef.createComponent(factory);
            if (this.tooltipConfig.data) {
                componentRef.instance.data = this.tooltipConfig.data;
            }
        }
    };
    return TooltipComponent;
}());
exports.TooltipComponent = TooltipComponent;
