"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./bulk-operation-details.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("./bulk-operation-details.component");
var styles_BulkOperationDetailsComponent = [i0.styles];
var RenderType_BulkOperationDetailsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BulkOperationDetailsComponent, data: {} });
exports.RenderType_BulkOperationDetailsComponent = RenderType_BulkOperationDetailsComponent;
function View_BulkOperationDetailsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_BulkOperationDetailsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { detailsViewContainerRef: 0 }), (_l()(), i1.ɵand(16777216, [[1, 3], ["operationDetails", 2]], null, 0, null, View_BulkOperationDetailsComponent_1))], null, null); }
exports.View_BulkOperationDetailsComponent_0 = View_BulkOperationDetailsComponent_0;
function View_BulkOperationDetailsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_BulkOperationDetailsComponent_0, RenderType_BulkOperationDetailsComponent)), i1.ɵdid(1, 114688, null, 0, i2.BulkOperationDetailsComponent, [i1.ComponentFactoryResolver], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_BulkOperationDetailsComponent_Host_0 = View_BulkOperationDetailsComponent_Host_0;
var BulkOperationDetailsComponentNgFactory = i1.ɵccf("ng-component", i2.BulkOperationDetailsComponent, View_BulkOperationDetailsComponent_Host_0, {}, {}, []);
exports.BulkOperationDetailsComponentNgFactory = BulkOperationDetailsComponentNgFactory;
