import helpIds from './common/constants/helpIds';

const applications = {
  sy: {
    name: 'SY',
    title: SYMAP_CONFIG.APP_BRAND.PS.TITLE,
  },
  di: {
    name: 'DI',
    title: 'DIMAP',
  },
  pk: {
    name: 'PRO',
    title: 'PROMAP',
  },
  settings: {
    name: 'SETTINGS',
    title: 'Nastavení',
  },
  dashboard: {
    name: 'DASH',
    title: 'Dashboard',
  },
  vfze: {
    name: 'VFZE',
    title: 'VFZE',
  },
  landing: {
    name: 'LANDING',
    title: 'Úvodní stránka',
  },
  sv: {
    name: 'VZORY',
    title: 'Správa vzorů',
  }
};

// SYMAP_CONFIG is global variable defined  in index.ejs
angular.module('config', [])
  .constant('APP_VERSION', SYMAP_CONFIG.VERSION)
  .constant('intercomSettings', { INTERCOM_APPID: SYMAP_CONFIG.INTERCOM_APPID })
  .constant('GOOGLEANALYTICSKEY', SYMAP_CONFIG.GOOGLEANALYTICSKEY)
  .constant('BACKEND_OPTIONS', SYMAP_CONFIG.BACKEND_OPTIONS)
  .constant('LOCALSTORAGEVERSION', SYMAP_CONFIG.LOCALSTORAGEVERSION)
  .constant('APP_WIKI_URL', 'https://clevermaps.atlassian.net/wiki/display/KBSY')
  .constant('HELP_IDS', helpIds)
  .constant('APPLICATIONS', applications)
  .constant('MULTI_SCENE_CLOUD', SYMAP_CONFIG.MULTI_SCENE_CLOUD)
  .constant('SERVICE_DESK', SYMAP_CONFIG.SERVICE_DESK)
  .constant('APP_BRAND', SYMAP_CONFIG.APP_BRAND)
  .constant('ENV', SYMAP_CONFIG.ENV);

