"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
exports.RESTANGULAR_PROMAP_PROXY = new core_1.InjectionToken('RestangularPromapProxy');
function RestangularPromapProxyFactory(restangular, config) {
    return restangular.withConfig(function (restangularConfigurer) {
        restangularConfigurer.setBaseUrl(config.BACKEND_OPTIONS.restUrlPkProxy);
    });
}
exports.RestangularPromapProxyFactory = RestangularPromapProxyFactory;
