import { HttpParams } from '@angular/common/http';

class LoginService {
  constructor($window, $state, APP_BRAND) {
    this.$window = $window;
    this.$state = $state;
    this.APP_BRAND = APP_BRAND;
  }

  redirectToLogin(includeParams = false, loggedOut = false) {
    if (includeParams) {
      const redirectUrl = this.$window.location.hash.split("#!")[1];
      const code = new URLSearchParams(window.location.search).get('code');
      // Přesměrování po SSO přihlášení
      if (this.APP_BRAND.LOGIN.SSO && redirectUrl === undefined && code) {
        const queryParams = new HttpParams()
          .set('code', code)
          .toString();
        this.$window.location.href = this.$window.location.protocol + '//' + this.$window.location.host + this.$window.location.pathname + '#!/login?' + queryParams;
        return;
      }
      const params = {
        redirectUrl: redirectUrl,
      }
      return this.$state.go('login', params);
    } else if (loggedOut && this.APP_BRAND.LOGIN && this.APP_BRAND.LOGIN.LOGOUT_URL) {
      this.$window.location.href = this.APP_BRAND.LOGIN.LOGOUT_URL;
      return;
    } else {
      return this.$state.go('login', { loggedOut: loggedOut });
    }
  }
}

LoginService.$inject = ['$window', '$state', 'APP_BRAND'];

export default LoginService;
