"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var VfzeDetailComponent = /** @class */ (function () {
    function VfzeDetailComponent() {
    }
    VfzeDetailComponent.prototype.ngOnInit = function () {
    };
    return VfzeDetailComponent;
}());
exports.VfzeDetailComponent = VfzeDetailComponent;
