"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var callbacks_enum_1 = require("@app/ps/enums/callbacks.enum");
var BulkOperationConfirmComponent = /** @class */ (function () {
    function BulkOperationConfirmComponent(componentFactoryResolver) {
        this.componentFactoryResolver = componentFactoryResolver;
        this.notifyParent = this.notifyParent.bind(this);
    }
    BulkOperationConfirmComponent.prototype.ngOnInit = function () {
        if (this.data.selectAction && this.data.selectAction.confirmComponent) {
            var factory = this.componentFactoryResolver
                .resolveComponentFactory(this.data.selectAction.confirmComponent);
            this.confirmComponent = factory
                .create(this.confirmViewContainerRef.parentInjector);
            this.confirmViewContainerRef.insert(this.confirmComponent.hostView);
            this.confirmComponent.instance.data = this.data;
            this.confirmComponent.instance.callbacks = this.callbacks;
            this.proceed = this.confirmComponent.instance.proceed;
        }
    };
    BulkOperationConfirmComponent.prototype.notifyParent = function (isValid, inputData) {
        this.callbacks.get(callbacks_enum_1.Callbacks.DataValidityChanged)({
            isValid: isValid,
            data: inputData,
        });
    };
    return BulkOperationConfirmComponent;
}());
exports.BulkOperationConfirmComponent = BulkOperationConfirmComponent;
