"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var OpinionRequestStatusEnum;
(function (OpinionRequestStatusEnum) {
    OpinionRequestStatusEnum["CREATED"] = "CREATED";
    OpinionRequestStatusEnum["DELETED"] = "DELETED";
    OpinionRequestStatusEnum["REALIZATION_STARTED"] = "REALIZATION_STARTED";
    OpinionRequestStatusEnum["REALIZED"] = "REALIZED";
    OpinionRequestStatusEnum["PRICES_FILLED"] = "PRICES_FILLED";
})(OpinionRequestStatusEnum = exports.OpinionRequestStatusEnum || (exports.OpinionRequestStatusEnum = {}));
exports.opinionRequestStatusOptions = [
    {
        id: OpinionRequestStatusEnum.CREATED,
        name: 'Založení záznamu o ZP',
        dialog: false,
    },
    {
        id: OpinionRequestStatusEnum.DELETED,
        name: 'Zrušený ZP',
        action: 'Zrušit ZP',
        dialog: true,
    },
    {
        id: OpinionRequestStatusEnum.REALIZATION_STARTED,
        name: 'Zahájeno zpracování ZP',
        dialog: true,
    },
    {
        id: OpinionRequestStatusEnum.REALIZED,
        name: 'Vyhotovený ZP',
        dialog: true,
    },
    {
        id: OpinionRequestStatusEnum.PRICES_FILLED,
        name: 'Vložení cen dle ZP do aplikace',
        action: 'Vložit ceny dle ZP do aplikace',
        dialog: false,
    },
];
