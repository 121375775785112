"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var list_service_1 = require("@app/common/services/list.service");
var _ = require("lodash");
var i0 = require("@angular/core");
var i1 = require("./list.service");
var OtherLegalRelationsService = /** @class */ (function () {
    function OtherLegalRelationsService(listService) {
        this.listService = listService;
    }
    OtherLegalRelationsService.prototype.loadOtherLegalRelations = function (titleIds) {
        var dataOtherLegalRelations = this.listService.createList('other-legal-relations', {
            limit: null,
            filters: {
                titleId: titleIds, validity: ['valid'],
                sections: ['C', 'D'],
                loadCollections: ['buildingTo.parcels', 'subjectsFor', 'subjectsTo']
            }
        });
        this.listService.fetchResult(dataOtherLegalRelations);
        return dataOtherLegalRelations;
    };
    // if only ownership is set then restrictions and otherEntries with parcel or building are not filtered.
    // These are supposed to be filtered when both ownership and parcel variables are set
    OtherLegalRelationsService.prototype.filter = function (otherLegalRelations, section, primaryTitle, ownership, parcel) {
        var filtered = [];
        // filter restrictions by subject and parcel
        // filter other entries by subject and parcel
        otherLegalRelations.forEach(function (otherLegalRelation) {
            if (section !== otherLegalRelation.sekce) {
                return;
            }
            if (!otherLegalRelation.parcelTo && !otherLegalRelation.buildingTo && ownership && ownership.title.id !== primaryTitle.id) {
                return;
            }
            if (ownership !== undefined && otherLegalRelation.subjectsTo.length) {
                otherLegalRelation.subjectsTo.forEach(function (restrictionSubject) {
                    ownership.subjects.forEach(function (ownershipSubject) {
                        if (ownershipSubject.id === restrictionSubject.id &&
                            ((parcel === undefined && otherLegalRelation.parcelTo === null && otherLegalRelation.buildingTo === null) ||
                                (parcel !== undefined && otherLegalRelation.parcelTo && parcel.id === otherLegalRelation.parcelTo.id) ||
                                (parcel !== undefined && otherLegalRelation.buildingTo && parcel.building && parcel.building.id === otherLegalRelation.buildingTo.id) ||
                                (parcel !== undefined && otherLegalRelation.parcelTo && parcel.future === true && parcel.knBudouc && parcel.knBudouc.parcels && parcel.knBudouc.parcels.find(function (p) { return p.id === otherLegalRelation.parcelTo.id; })))) {
                            filtered.push(otherLegalRelation);
                        }
                    });
                });
            }
            else if (ownership === undefined && parcel !== undefined) {
                if (!otherLegalRelation.subjectsTo.length &&
                    ((otherLegalRelation.parcelTo && parcel.id === otherLegalRelation.parcelTo.id) ||
                        (otherLegalRelation.buildingTo && parcel.building && parcel.building.id === otherLegalRelation.buildingTo.id) ||
                        (otherLegalRelation.parcelTo && parcel.future === true && parcel.knBudouc && parcel.knBudouc.parcels && parcel.knBudouc.parcels.find(function (p) { return p.id === otherLegalRelation.parcelTo.id; })))) {
                    filtered.push(otherLegalRelation);
                }
            }
        });
        return filtered;
    };
    OtherLegalRelationsService.prototype.filterByEntityWithParcel = function (otherLegalRelations, dataEntities, primaryTitle, caseOwnerships) {
        var _this = this;
        var restrictions = [];
        var otherEntries = [];
        // filter specifically for parcel without owner
        _.forEach(dataEntities, function (entity) {
            restrictions.push.apply(restrictions, __spread(_this.filter(otherLegalRelations, 'C', primaryTitle, undefined, entity.parcel)));
            otherEntries.push.apply(otherEntries, __spread(_this.filter(otherLegalRelations, 'D', primaryTitle, undefined, entity.parcel)));
            caseOwnerships.forEach(function (caseOwnership) {
                if (!caseOwnership.ownership.title || entity.parcel.title.id !== caseOwnership.ownership.title.id) {
                    return;
                }
                // filter for combination of ownership and parcel
                restrictions.push.apply(restrictions, __spread(_this.filter(otherLegalRelations, 'C', primaryTitle, caseOwnership.ownership, entity.parcel)));
                otherEntries.push.apply(otherEntries, __spread(_this.filter(otherLegalRelations, 'D', primaryTitle, caseOwnership.ownership, entity.parcel)));
            });
        });
        return {
            restrictions: restrictions,
            otherEntries: otherEntries,
        };
    };
    OtherLegalRelationsService.prototype.filterByEntityWithEasement = function (otherLegalRelations, dataEasements, primaryTitle, caseOwnerships) {
        var _this = this;
        var restrictions = [];
        var otherEntries = [];
        // filter specifically for parcel without owner
        _.forEach(dataEasements, function (dataEasement) {
            restrictions.push.apply(restrictions, __spread(_this.filter(otherLegalRelations, 'C', primaryTitle, undefined, dataEasement.easement.parcel)));
            otherEntries.push.apply(otherEntries, __spread(_this.filter(otherLegalRelations, 'D', primaryTitle, undefined, dataEasement.easement.parcel)));
            caseOwnerships.forEach(function (caseOwnership) {
                if (!caseOwnership.ownership.title || dataEasement.easement.parcel.title.id !== caseOwnership.ownership.title.id) {
                    return;
                }
                // filter for combination of ownership and parcel
                restrictions.push.apply(restrictions, __spread(_this.filter(otherLegalRelations, 'C', primaryTitle, caseOwnership.ownership, dataEasement.easement.parcel)));
                otherEntries.push.apply(otherEntries, __spread(_this.filter(otherLegalRelations, 'D', primaryTitle, caseOwnership.ownership, dataEasement.easement.parcel)));
            });
        });
        return {
            restrictions: restrictions,
            otherEntries: otherEntries,
        };
    };
    OtherLegalRelationsService.prototype.filterByEntityWithParcels = function (otherLegalRelations, dataBuildings, primaryTitle, caseOwnerships) {
        var _this = this;
        var restrictions = [];
        var otherEntries = [];
        dataBuildings.forEach(function (building) {
            building.parcels.forEach(function (parcel) {
                caseOwnerships.forEach(function (caseOwnership) {
                    if (!caseOwnership.ownership.title || parcel.title.id !== caseOwnership.ownership.title.id) {
                        return;
                    }
                    // filter for combination of ownership and parcel
                    restrictions.push.apply(restrictions, __spread(_this.filter(otherLegalRelations, 'C', primaryTitle, caseOwnership.ownership, parcel)));
                    otherEntries.push.apply(otherEntries, __spread(_this.filter(otherLegalRelations, 'D', primaryTitle, caseOwnership.ownership, parcel)));
                });
            });
        });
        return {
            restrictions: restrictions,
            otherEntries: otherEntries,
        };
    };
    OtherLegalRelationsService.prototype.filterByCaseOwnerships = function (otherLegalRelations, caseOwnerships, primaryTitle) {
        var _this = this;
        var restrictions = [];
        var otherEntries = [];
        // filter for ownership without parcel
        caseOwnerships.forEach(function (caseOwnership) {
            restrictions.push.apply(restrictions, __spread(_this.filter(otherLegalRelations, 'C', primaryTitle, caseOwnership.ownership)));
            otherEntries.push.apply(otherEntries, __spread(_this.filter(otherLegalRelations, 'D', primaryTitle, caseOwnership.ownership)));
        });
        return {
            restrictions: restrictions,
            otherEntries: otherEntries,
        };
    };
    OtherLegalRelationsService.ngInjectableDef = i0.defineInjectable({ factory: function OtherLegalRelationsService_Factory() { return new OtherLegalRelationsService(i0.inject(i1.ListService)); }, token: OtherLegalRelationsService, providedIn: "root" });
    return OtherLegalRelationsService;
}());
exports.OtherLegalRelationsService = OtherLegalRelationsService;
