"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var case_service_1 = require("@app/ps/services/case.service");
var case_status_service_1 = require("@app/ps/services/case-status.service");
var case_model_1 = require("@app/ps/models/case.model");
var angular_1 = require("@uirouter/angular");
var ChangeCaseStatusComponent = /** @class */ (function () {
    function ChangeCaseStatusComponent(restangular, caseService, caseStatusService, stateService, eRef) {
        this.restangular = restangular;
        this.caseService = caseService;
        this.caseStatusService = caseStatusService;
        this.stateService = stateService;
        this.eRef = eRef;
        this.actionButtons = [];
        this.back = new core_1.EventEmitter();
        this.opened = false;
    }
    ChangeCaseStatusComponent.prototype.ngOnInit = function () {
    };
    ChangeCaseStatusComponent.prototype.toggle = function () {
        this.opened = !this.opened;
    };
    ChangeCaseStatusComponent.prototype.onBack = function () {
        this.back.emit();
    };
    ChangeCaseStatusComponent.prototype.clickout = function (event) {
        if (!this.eRef.nativeElement.contains(event.target)) {
            this.opened = false;
        }
    };
    return ChangeCaseStatusComponent;
}());
exports.ChangeCaseStatusComponent = ChangeCaseStatusComponent;
