"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var angular_1 = require("@uirouter/angular");
var file_saver_1 = require("file-saver");
var request_status_enum_1 = require("@app/pk/enums/request-status.enum");
var price_1 = require("@app/models/price");
var cadastralArea_1 = require("@app/models/cadastralArea");
var failed_reason_enum_1 = require("@app/pk/enums/failed-reason.enum");
var CreateProjectThirdStepComponent = /** @class */ (function () {
    function CreateProjectThirdStepComponent(alertService, restangular, helpIds, stateService) {
        this.alertService = alertService;
        this.restangular = restangular;
        this.helpIds = helpIds;
        this.stateService = stateService;
        this.priceConfirmation = false;
        this.STATUSES = request_status_enum_1.RequestStatusEnum;
        // props
        this.keepWaitForRequiredStatus = false;
        this.delete = this.delete.bind(this);
        this.cancel = this.cancel.bind(this);
        this.confirmPrice = this.confirmPrice.bind(this);
        this.downloadTest = this.downloadTest.bind(this);
        this.downloadVfk = this.downloadVfk.bind(this);
        this.downloadExcelSheets = this.downloadExcelSheets.bind(this);
        this.downloadZip = this.downloadZip.bind(this);
        this.downloadShp = this.downloadShp.bind(this);
        this.downloadOriginal = this.downloadOriginal.bind(this);
        this.downloadExcelReport = this.downloadExcelReport.bind(this);
        this.onAdditionalOwnershipTitilesLoad = this.onAdditionalOwnershipTitilesLoad.bind(this);
    }
    CreateProjectThirdStepComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.requestId = this.stateService.params.requestId;
        this.title = this.stateService.params.title;
        this.subtitle = this.stateService.params.subtitle;
        this.loading = true;
        this.keepWaitForRequiredStatus = true;
        if (!this.requestId) {
            this.showErrorModal();
        }
        else {
            this.waitForRequiredStatus(this.requestId)
                .then(function () { return _this.loadProject(); })
                .catch(this.showErrorModal);
        }
    };
    CreateProjectThirdStepComponent.prototype.ngOnDestroy = function () {
        this.keepWaitForRequiredStatus = false;
    };
    CreateProjectThirdStepComponent.prototype.confirmPrice = function () {
        var _this = this;
        this.loading = true;
        return this.restangular.one("request/" + this.requestId + "/price-confirm").post()
            .then(function () {
            _this.loading = false;
            _this.stateService.go('pk.projects');
        });
    };
    CreateProjectThirdStepComponent.prototype.delete = function () {
        var _this = this;
        return this.restangular.one("request/" + this.requestId).remove()
            .then(function () { return _this.stateService.go('pk.projects'); });
    };
    CreateProjectThirdStepComponent.prototype.cancel = function () {
        this.stateService.go('pk.projects');
    };
    CreateProjectThirdStepComponent.prototype.downloadTest = function () {
        return this.download('test');
    };
    CreateProjectThirdStepComponent.prototype.downloadVfk = function () {
        return this.download('vfk');
    };
    CreateProjectThirdStepComponent.prototype.downloadExcelSheets = function () {
        return this.download('excel');
    };
    CreateProjectThirdStepComponent.prototype.downloadZip = function () {
        return this.download('zip');
    };
    CreateProjectThirdStepComponent.prototype.downloadShp = function () {
        return this.download('shp');
    };
    CreateProjectThirdStepComponent.prototype.downloadExcelReport = function () {
        return this.download('excel-report');
    };
    CreateProjectThirdStepComponent.prototype.downloadOriginal = function () {
        return this.restangular.one("request/" + this.requestId + "/dump/geometries").withHttpConfig({
            responseType: 'blob',
            transformResponse: function (response, header) {
                return [response, header];
            }
        }).get().then(function (response) {
            file_saver_1.saveAs(response[0], 'geometrie.zip', true);
        });
    };
    CreateProjectThirdStepComponent.prototype.onAdditionalOwnershipTitilesLoad = function () {
        var _this = this;
        return this.restangular.one("request/" + this.requestId + "/spoluvlastnictvi").get().then(function (data) {
            _this.additionalOwnershipTitles = data.plain();
        });
    };
    CreateProjectThirdStepComponent.prototype.waitForRequiredStatus = function (requestId) {
        var _this = this;
        var getStatusIteration = function (resolve, reject) {
            _this.restangular.one("projects/" + requestId + "/status").customGET('')
                .then(function (data) {
                var status = data && data['status'] ? data['status'] : null;
                if (!_this.keepWaitForRequiredStatus) {
                    reject();
                }
                else if (CreateProjectThirdStepComponent.REQUIRED_STATUS_LIST.indexOf(status) === -1) {
                    _this.message = CreateProjectThirdStepComponent.MESSAGE_LIST.WAITING_FOR_INTERSECT;
                    setTimeout(function () { return getStatusIteration(resolve, reject); }, 1000);
                }
                else {
                    resolve(data);
                    _this.keepWaitForRequiredStatus = false;
                }
            }, function (data) {
                reject(data);
            });
        };
        return new Promise(getStatusIteration);
    };
    CreateProjectThirdStepComponent.prototype.loadProject = function () {
        var _this = this;
        this.loading = true;
        this.restangular.one("projects/" + this.requestId).get()
            .then(function (data) {
            _this.project = data.plain();
            _this.status = data.status;
            _this.cadastralAreas = cadastralArea_1.CadastralArea.createListFromApi(data.ruianKatuze);
            _this.price = price_1.Price.createFromApi(data.priceCalculation);
            _this.message = CreateProjectThirdStepComponent.getMessageFromStatus(_this.status, data.failedReason);
            _this.isGeometryFail = CreateProjectThirdStepComponent.isGeometryFail(data.failedReason);
            _this.isAdditionalOwnershipFail = (data.failedReason === failed_reason_enum_1.FailedReasonEnum.PRIDATNE_SPOLUVLASTNICTVI);
            _this.loading = false;
        });
    };
    CreateProjectThirdStepComponent.prototype.download = function (type) {
        var _this = this;
        return this.getDownloadRequest(type).then(function (response) {
            var extension = type === 'excel' ? 'xlsx' : 'zip';
            var filename = _this.project.requestId + "-" + type + "." + extension;
            file_saver_1.saveAs(response[0], filename, true);
        });
    };
    CreateProjectThirdStepComponent.prototype.getDownloadRequest = function (type) {
        switch (type) {
            case 'test':
                return this.restangular.one("request/" + this.requestId + "/export", 'vfk-static').withHttpConfig({
                    responseType: 'blob',
                    transformResponse: function (response, header) {
                        return [response, header];
                    }
                }).get();
            case 'vfk':
                return this.restangular.one("request/" + this.requestId + "/export", 'vfk').withHttpConfig({
                    responseType: 'blob',
                    transformResponse: function (response, header) {
                        return [response, header];
                    }
                }).get();
            case 'excel':
                return this.restangular.one("request/" + this.requestId + "/result", 'excel-sheets').withHttpConfig({
                    responseType: 'blob',
                    transformResponse: function (response, header) {
                        return [response, header];
                    }
                }).get();
            case 'zip':
                return this.restangular.one("request/" + this.requestId + "/zip", 'create').withHttpConfig({
                    responseType: 'blob',
                    transformResponse: function (response, header) {
                        return [response, header];
                    }
                }).get();
            case 'shp':
                return this.restangular.one("request/" + this.requestId + "/shp", 'create').withHttpConfig({
                    responseType: 'blob',
                    transformResponse: function (response, header) {
                        return [response, header];
                    }
                }).get();
            case 'excel-report':
                return this.restangular.one("request/" + this.requestId + "/report", 'excel').withHttpConfig({
                    responseType: 'blob',
                    transformResponse: function (response, header) {
                        return [response, header];
                    }
                }).get();
        }
    };
    CreateProjectThirdStepComponent.prototype.showErrorModal = function () {
        return new this.alertService({
            callback: this.cancel,
            message: CreateProjectThirdStepComponent.FAIL_REASON_LIST.INTERNAL_ERROR + " </br> Budete p\u0159esm\u011Brov\u00E1ni na seznam projekt\u016F."
        });
    };
    CreateProjectThirdStepComponent.getMessageFromStatus = function (status, failReason) {
        switch (status) {
            case request_status_enum_1.RequestStatusEnum.WAITING_FOR_PRICE_CONFIRMATION:
                return CreateProjectThirdStepComponent.MESSAGE_LIST.WAITING_FOR_PRICE_CONFIRM;
            case request_status_enum_1.RequestStatusEnum.PRICE_CONFIRMED:
            case request_status_enum_1.RequestStatusEnum.CUZK_DATA_WAITING:
                return CreateProjectThirdStepComponent.MESSAGE_LIST.WAITING_FOR_CUZK_DATA;
            case request_status_enum_1.RequestStatusEnum.FINISHED:
                return CreateProjectThirdStepComponent.MESSAGE_LIST.FINISHED;
            case request_status_enum_1.RequestStatusEnum.FAILED:
                return CreateProjectThirdStepComponent.FAIL_REASON_LIST[failReason]
                    || CreateProjectThirdStepComponent.FAIL_REASON_LIST.INTERNAL_ERROR;
            default:
                return '';
        }
    };
    CreateProjectThirdStepComponent.isGeometryFail = function (failedReason) {
        return [
            failed_reason_enum_1.FailedReasonEnum.INVALID_GEOMETRY,
            failed_reason_enum_1.FailedReasonEnum.INVALID_GEOMETRY_MORE_THAN_ROW,
            failed_reason_enum_1.FailedReasonEnum.INVALID_GEOMETRY_MORE_THAN_ONE_GEOMETRY,
            failed_reason_enum_1.FailedReasonEnum.INVALID_GEOMETRY_HOLE_IN_GEOMETRY,
            failed_reason_enum_1.FailedReasonEnum.INVALID_GEOMETRY_OR_NOT_POLYGON
        ].indexOf(failedReason) !== -1;
    };
    CreateProjectThirdStepComponent.REQUIRED_STATUS_LIST = [
        request_status_enum_1.RequestStatusEnum.FAILED,
        request_status_enum_1.RequestStatusEnum.FINISHED,
        request_status_enum_1.RequestStatusEnum.WAITING_FOR_PRICE_CONFIRMATION,
        request_status_enum_1.RequestStatusEnum.PRICE_CONFIRMED,
        request_status_enum_1.RequestStatusEnum.CUZK_DATA_WAITING
    ];
    CreateProjectThirdStepComponent.FAIL_REASON_LIST = {
        INTERNAL_ERROR: "Při zpracování došlo k interní chybě aplikace. Kontaktujte náš helpdesk.",
        AREA_TOO_LARGE: "Zadané území je větší než 15 km 2. Pokud jste si jisti správností území, kontaktujte náš helpdesk a Váš požadavek vyřešíme individuálně.",
        INCOMPLETE_DATA: "Chybí soubor se vstupní geometrii.",
        NOT_DIGITALIZED_MAP: "Katastrální mapa ve vašem území není plně digitální, požadavek nelze zpracovat.",
        INVALID_GEOMETRY: "Vstupní soubor obsahuje neplatnou geometrii.",
        INVALID_GEOMETRY_MORE_THAN_ROW: "Chybná geometrie (území tvoří více ploch).",
        INVALID_GEOMETRY_MORE_THAN_ONE_GEOMETRY: "Chybná geometrie (území tvoří více ploch).",
        INVALID_GEOMETRY_HOLE_IN_GEOMETRY: "Chybná geometrie (území tvoří plocha s dírou).",
        INVALID_GEOMETRY_OR_NOT_POLYGON: "Chybná geometrie (území není plocha).",
        CTIOS_WRONG_CREDENTIALS: "Chybné přihlašovací údaje k WSDP účtu.",
        CTIOS_EXPIRED_CREDENTIALS: "Expirovalo heslo WSDP přístupu.",
        MISSING_SUBJECT: "Chybějící vlastník - přídatné spoluvlastnictví.",
        CTIOS_UNAVAILABLE: "Nedostupná služba ČUZK pro přístup k osobním údajům vlastníků.",
        PRIDATNE_SPOLUVLASTNICTVI: "Přídatné spoluvlastnictví.",
        INVALID_GEOMETRY_NARROW: "Geometrie je v některém místě užší než 2 cm.",
    };
    CreateProjectThirdStepComponent.MESSAGE_LIST = {
        WAITING_FOR_INTERSECT: "Pracujeme na protnutí zadané geometrie s daty SGI katastru nemovitostí.",
        WAITING_FOR_PRICE_CONFIRM: "Zadanou geometrii jsme protnuli s dostupnými daty SGI katastru nemovitostí s tímto výsledkem.",
        WAITING_FOR_CUZK_DATA: "Zadanou geometrii jsme protnuli s dostupnými daty SGI katastru nemovitostí s tímto výsledkem. Pracujeme na získání dat z katastru nemovitostí.",
        FINISHED: "Zadanou geometrii jsme protnuli s dostupnými daty SGI katastru nemovitostí s tímto výsledkem."
    };
    return CreateProjectThirdStepComponent;
}());
exports.CreateProjectThirdStepComponent = CreateProjectThirdStepComponent;
