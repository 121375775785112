"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createAdministrator = function () {
    return {
        useCaseNumberAsReferenceNumber: false,
        useAccountNumberForProposal: false,
        deliveryUse: false,
        discriminator: 'A',
        actingPersons: [],
        showPublicationNote: false,
        showNoPublicationNote: false,
    };
};
exports.createAgent = function () {
    return {
        useCaseNumberAsReferenceNumber: false,
        useAccountNumberForProposal: false,
        deliveryUse: false,
        discriminator: 'G',
        subjectType: 'OPO',
        actingPersons: [],
    };
};
exports.createSubject = function (discriminator) {
    return {
        useCaseNumberAsReferenceNumber: false,
        useAccountNumberForProposal: false,
        deliveryUse: false,
        discriminator: discriminator,
        subjectType: 'OFO',
        actingPersons: [],
        showPublicationNote: false,
        showNoPublicationNote: false,
    };
};
