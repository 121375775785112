"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var dialog_config_1 = require("@app/dialog/dialog-config");
var dialog_ref_1 = require("@app/dialog/dialog-ref");
var project_subject_model_1 = require("@app/ps/models/project-subject.model");
var ProjectSubjectAgentComponent = /** @class */ (function () {
    function ProjectSubjectAgentComponent(dialogConfig, dialogRef) {
        this.dialogConfig = dialogConfig;
        this.dialogRef = dialogRef;
        this.onCancel = this.onCancel.bind(this);
        this.onSave = this.onSave.bind(this);
    }
    ProjectSubjectAgentComponent.prototype.ngOnInit = function () {
        var inputAgent = this.dialogConfig.data.agent;
        this.agent = inputAgent ? inputAgent : project_subject_model_1.createAgent();
        this.agent.showPublicationNote = this.agent.showPublicationNote || false;
        this.agent.showNoPublicationNote = this.agent.showNoPublicationNote || false;
        this.agent.publicationNoteTemplate = this.agent.publicationNoteTemplate === undefined ? 'Tato smlouva byla uveřejněna v registru smluv pod id [ID]' : this.agent.publicationNoteTemplate;
        this.agent.noPublicationNoteTemplate = this.agent.noPublicationNoteTemplate === undefined ? 'Tato smlouva nebyla uveřejněna v registru smluv. Výjimka: [VYJIMKA]' : this.agent.noPublicationNoteTemplate;
    };
    ProjectSubjectAgentComponent.prototype.onCancel = function () {
        this.dialogRef.close();
    };
    ProjectSubjectAgentComponent.prototype.onSave = function () {
        this.dialogRef.close(this.agent);
    };
    return ProjectSubjectAgentComponent;
}());
exports.ProjectSubjectAgentComponent = ProjectSubjectAgentComponent;
