"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".info-description[_ngcontent-%COMP%] {\n  float: left; }\n  .info-description[_ngcontent-%COMP%]   span[_ngcontent-%COMP%] {\n    display: inline-block;\n    width: 200px;\n    color: #909090; }\n\n.ml--40[_ngcontent-%COMP%] {\n  margin-left: -40px; }"];
exports.styles = styles;
