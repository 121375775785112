"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var auth_service_1 = require("@app/common/services/auth.service");
var table_service_1 = require("@app/ps/services/table.service");
var pageable_list_service_1 = require("@app/common/services/pageable.list.service");
var settings_service_1 = require("@app/ps/services/settings.service");
var angular_1 = require("@uirouter/angular");
var username_pipe_1 = require("@app/common/pipes/username.pipe");
var list_service_1 = require("@app/common/services/list.service");
var export_list_service_1 = require("@app/ps/services/export-list.service");
var ConstructionObjectListComponent = /** @class */ (function () {
    function ConstructionObjectListComponent(helpIds, restangularSettings, restangular, userNamePipe, pageableListService, settingsService, tableService, stateService, authService, listService, exportListService) {
        this.helpIds = helpIds;
        this.restangularSettings = restangularSettings;
        this.restangular = restangular;
        this.userNamePipe = userNamePipe;
        this.pageableListService = pageableListService;
        this.settingsService = settingsService;
        this.tableService = tableService;
        this.stateService = stateService;
        this.authService = authService;
        this.listService = listService;
        this.exportListService = exportListService;
        this.settingsLoaded = false;
        this.userSending = {};
        this.users = [];
        this.importedObjectsExists = false;
        this.exportEnable = true;
        this.mandataryFilter = {
            filters: {
                searchText: {
                    values: [],
                },
                applications: {
                    values: [this.authService.getActiveApplication()],
                },
                permission: {
                    values: ['assignable'],
                },
                projects: {
                    values: [this.authService.getActualProject().key],
                },
            },
            sortOrder: [
                { sortBy: 'surname' },
                { sortBy: 'name' },
            ],
        };
        this.defaultFilter = {
            filters: {
                validity: 'valid',
                easementExists: { values: [] },
                searchText: { values: [{ id: '' }] },
                constructionObjectStageId: {},
                constructionObjectStatusId: {},
                userId: {},
                loadCollections: ['occupationResolution'],
            }
        };
        this.onExport = this.onExport.bind(this);
    }
    ConstructionObjectListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.user = this.authService.getUser();
        this.settingsLoaded = this.settingsService.getSettingsLoader() === null;
        if (!this.settingsLoaded) {
            this.settingsService.getSettingsLoader().then(function () { return _this.settingsLoaded = true; });
        }
        if (this.authService.hasPermission('assignment_edit')) {
            this.restangularSettings.all('users/list').customPOST({ filter: this.mandataryFilter }).then(function (data) {
                _this.users = data.plain();
            });
        }
        this.initializeList();
        this.loadImported();
    };
    ConstructionObjectListComponent.prototype.onExport = function () {
        var _this = this;
        this.exportEnable = false;
        var filter = __assign({}, this.list.filter);
        filter.filters.loadCollections.push('administrator');
        return this.exportListService
            .exportList('construction-objects', 'constructionObjects', filter)
            .then(function () {
            new Promise(function (resolve) { return setTimeout(function () { return resolve(); }, 5000); }).then(function () {
                _this.exportEnable = true;
            });
        });
    };
    ConstructionObjectListComponent.prototype.initializeList = function () {
        this.pageableList = this.pageableListService.get('construction-objects', this.defaultFilter, 'constructionObjectsFilter', this.restangular, { administrator: { load: true } });
        this.list = this.pageableList.list;
        this.pageableList.load();
    };
    ConstructionObjectListComponent.prototype.loadImported = function () {
        var _this = this;
        var list = this.listService.createList('construction-objects/imports', { limit: 1 });
        return this.listService.fetchResult(list).then(function (data) {
            _this.importedObjectsExists = data && data.itemCount > 0;
        });
    };
    ConstructionObjectListComponent.prototype.uiOnParamsChanged = function (changedParams, $transition$) {
        this.pageableList.uiOnParamsChanged(changedParams, $transition$);
    };
    ConstructionObjectListComponent.prototype.hideColumn = function (columnId) {
        return this.settingsService.shouldHideColumn('constructionObjects', columnId);
    };
    ConstructionObjectListComponent.prototype.getColumnName = function (columnId) {
        return this.tableService.getColumnById('constructionObjects', columnId).name;
    };
    ConstructionObjectListComponent.prototype.isGeometricPlansChecked = function () {
        var exists = this.list.filter.filters.easementExists;
        return exists && exists.values.length === 1;
    };
    ConstructionObjectListComponent.prototype.onHideWithoutGP = function () {
        this.list.filter.filters.easementExists.values = (this.isGeometricPlansChecked() ? [] : [true]);
    };
    ConstructionObjectListComponent.prototype.onUpdateUser = function (constructionObjectId, userId) {
        var _this = this;
        this.userSending[constructionObjectId] = true;
        this.restangular.one('construction-objects', constructionObjectId).customPUT({ id: userId }, 'user').then(function () {
            _this.userSending[constructionObjectId] = false;
        });
    };
    return ConstructionObjectListComponent;
}());
exports.ConstructionObjectListComponent = ConstructionObjectListComponent;
