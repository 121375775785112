"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var project_subject_name_pipe_1 = require("@app/ps/pipes/project-subject-name.pipe");
var i0 = require("@angular/core");
var i1 = require("./project-subject-name.pipe");
/**
 * Display name of acting person
 */
var ActingPersonNamePipe = /** @class */ (function () {
    function ActingPersonNamePipe(projectSubjectNamePipe) {
        this.projectSubjectNamePipe = projectSubjectNamePipe;
    }
    ActingPersonNamePipe.prototype.transform = function (subject) {
        if (!subject) {
            return null;
        }
        var contacts = [];
        if (subject.email) {
            contacts.push('e-mail: ' + subject.email);
        }
        if (subject.phoneNumber) {
            contacts.push('tel.: ' + subject.phoneNumber);
        }
        var contactsText = contacts.join(', ');
        return this.projectSubjectNamePipe.transform(subject) + (contactsText ? ' (' + contactsText + ')' : '');
    };
    ActingPersonNamePipe.ngInjectableDef = i0.defineInjectable({ factory: function ActingPersonNamePipe_Factory() { return new ActingPersonNamePipe(i0.inject(i1.ProjectSubjectNamePipe)); }, token: ActingPersonNamePipe, providedIn: "root" });
    return ActingPersonNamePipe;
}());
exports.ActingPersonNamePipe = ActingPersonNamePipe;
