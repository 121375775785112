"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var _ = require("lodash");
var map_storage_helpers_service_1 = require("@app/map/services/map-storage-helpers.service");
var i0 = require("@angular/core");
var i1 = require("./map-storage-helpers.service");
var MapOptionsStorageService = /** @class */ (function () {
    function MapOptionsStorageService(mapStorageHelpersService) {
        this.mapStorageHelpersService = mapStorageHelpersService;
        this.opts = {};
        this.setOptions = this.setOptions.bind(this);
        this.getOptions = this.getOptions.bind(this);
        this.resetOptions = this.resetOptions.bind(this);
    }
    MapOptionsStorageService.prototype.setOptions = function (o, id) {
        var defer = this.mapStorageHelpersService.getUnresolvedDefer(this.opts, id);
        defer.resolve(o);
        this.mapStorageHelpersService.setResolvedDefer(this.opts, id);
    };
    MapOptionsStorageService.prototype.getOptions = function (mapId) {
        return this._getOptions(mapId).then(function (opts) {
            return _.cloneDeep(opts);
        });
    };
    MapOptionsStorageService.prototype.resetOptions = function (id) {
        this.opts[id] = undefined;
    };
    ///////////////////////// PRIVATE ////////////////////////////
    MapOptionsStorageService.prototype._getOptions = function (id) {
        var defer = this.mapStorageHelpersService.getDefer(this.opts, id);
        return defer.promise;
    };
    MapOptionsStorageService.ngInjectableDef = i0.defineInjectable({ factory: function MapOptionsStorageService_Factory() { return new MapOptionsStorageService(i0.inject(i1.MapStorageHelpersService)); }, token: MapOptionsStorageService, providedIn: "root" });
    return MapOptionsStorageService;
}());
exports.MapOptionsStorageService = MapOptionsStorageService;
