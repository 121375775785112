"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var auth_service_1 = require("@app/common/services/auth.service");
var case_service_1 = require("@app/ps/services/case.service");
var dialog_config_1 = require("@app/dialog/dialog-config");
var dialog_ref_1 = require("@app/dialog/dialog-ref");
var list_service_1 = require("@app/common/services/list.service");
var FormCaseUpdateParcelPriceComponent = /** @class */ (function () {
    function FormCaseUpdateParcelPriceComponent(helpIds, restangular, authService, caseService, cdr, config, dialog, listService) {
        this.helpIds = helpIds;
        this.restangular = restangular;
        this.authService = authService;
        this.caseService = caseService;
        this.cdr = cdr;
        this.config = config;
        this.dialog = dialog;
        this.listService = listService;
        this.updatePrice = this.updatePrice.bind(this);
    }
    FormCaseUpdateParcelPriceComponent.prototype.ngOnInit = function () {
        this.caseOwnershipParcelList = this.listService.createList('case-ownership-parcels', { filters: {
                caseId: this.config.data.caseId,
                parcelId: this.config.data.parcelId,
                easementId: this.config.data.easementId
            }, limit: null });
        this.listService.fetchResult(this.caseOwnershipParcelList);
    };
    FormCaseUpdateParcelPriceComponent.prototype.updatePrice = function () {
        var _this = this;
        return this.restangular.all('case-ownership-parcels').customPUT(this.caseOwnershipParcelList.list).
            then(function () {
            _this.dialog.close(true);
        });
    };
    return FormCaseUpdateParcelPriceComponent;
}());
exports.FormCaseUpdateParcelPriceComponent = FormCaseUpdateParcelPriceComponent;
