"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var common_1 = require("@angular/common");
var core_1 = require("@angular/core");
var moment = require("moment");
var file_saver_1 = require("file-saver");
var alert_component_1 = require("@app/dialog/alert/alert.component");
var class_name_enum_1 = require("@app/dialog/class-name.enum");
var dialog_service_1 = require("@app/dialog/dialog.service");
var RESULT_CODE_MESSAGES = {
    10: 'V hlášení některé žádosti chybí, hlášení nelze vytvořit. Zkontrolujte data.',
    20: 'V hlášení jsou k. ú., v nichž nebyly odebrány žádné měrné jednotky, hlášení nelze vytvořit. Zkontrolujte data.',
    30: 'Hlášení pro zvolené období už pravděpodobně existuje.',
    40: 'Do hlášení nebyla zahrnuta všechna data, hlášení nelze vytvořit. Zkontrolujte data.',
};
var CadastreDataReportComponent = /** @class */ (function () {
    function CadastreDataReportComponent(dialogService, restangularPromap) {
        this.dialogService = dialogService;
        this.restangularPromap = restangularPromap;
        this.monthPickerOptions = {
            minDate: moment().subtract(1, 'month').toDate(),
            maxDate: moment().subtract(1, 'month').toDate(),
            defaultDate: moment().subtract(1, 'month').toDate(),
        };
    }
    CadastreDataReportComponent.prototype.ngOnInit = function () {
        this.onExistingReportDownload = this.onExistingReportDownload.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    };
    CadastreDataReportComponent.prototype.onExistingReportDownload = function () {
        return __awaiter(this, void 0, void 0, function () {
            var reports;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getBaseReport().get()];
                    case 1:
                        reports = _a.sent();
                        reports = reports.items;
                        this.processFoundReport(reports);
                        return [2 /*return*/, reports];
                }
            });
        });
    };
    CadastreDataReportComponent.prototype.onReportDateChange = function (reportDate) {
        this.reportDate = common_1.formatDate(reportDate, 'yyyy-MM-dd', 'cs');
        this.checkUnfinishedRequests();
    };
    CadastreDataReportComponent.prototype.onSubmit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var reports;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getBaseReport().customPOST({}, '', { validityDate: this.reportDate })];
                    case 1:
                        reports = _a.sent();
                        this.processFoundReport(reports);
                        return [2 /*return*/, reports];
                }
            });
        });
    };
    CadastreDataReportComponent.prototype.checkUnfinishedRequests = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.restangularPromap
                                .one("cuzk/xml-report/unfinished?validityDate=" + this.reportDate)
                                .get()];
                    case 1:
                        _a.unfinishedRequests = _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    CadastreDataReportComponent.prototype.downloadXmlReport = function (report) {
        return __awaiter(this, void 0, void 0, function () {
            var baseReport, xml;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        baseReport = this.getBaseReport();
                        baseReport.id = report.id;
                        return [4 /*yield*/, baseReport
                                .withHttpConfig({
                                responseType: 'blob',
                                transformResponse: function (response, headers) {
                                    var contentDisposition = headers()['content-disposition'];
                                    var filename = contentDisposition // attachment; filename="gmtech_09_2019_hlaseni.zip"
                                        .split('=') // ["attachment; filename", ""gmtech_09_2019_hlaseni.zip""]
                                        .reverse()[0] // "gmtech_09_2019_hlaseni.zip"
                                        .replace(/"/g, ''); // gmtech_09_2019_hlaseni.zip
                                    return { response: response, filename: filename };
                                }
                            })
                                .get()];
                    case 1:
                        xml = _a.sent();
                        file_saver_1.saveAs(xml.response, xml.filename);
                        return [2 /*return*/];
                }
            });
        });
    };
    CadastreDataReportComponent.prototype.getBaseReport = function () {
        return this.restangularPromap
            .all('cuzk')
            .one('xml-report');
    };
    CadastreDataReportComponent.prototype.showMessageOnXmlReportProcessingDone = function (msg) {
        return this.dialogService.open(alert_component_1.AlertComponent, {
            data: {
                msg: msg
            },
            className: class_name_enum_1.ClassName.ADJUSTED_DIALOG,
        });
    };
    CadastreDataReportComponent.prototype.processFoundReport = function (reports) {
        var invalidReports = reports.filter(function (r) { return !r.validXml; });
        if (invalidReports.length > 0) {
            this.showMessageOnXmlReportProcessingDone(RESULT_CODE_MESSAGES[invalidReports[0].resultCode]);
            return;
        }
        else {
            this.showDownloadMessage(reports);
        }
    };
    CadastreDataReportComponent.prototype.showDownloadMessage = function (reports) {
        var _this = this;
        var msg = this.getDownloadMessage();
        var dialog = this.showMessageOnXmlReportProcessingDone(msg);
        var sub = dialog.afterClosed.subscribe(function (downloadWanted) {
            if (downloadWanted) {
                reports.forEach(_this.downloadXmlReport, _this);
            }
            sub.unsubscribe();
        });
    };
    CadastreDataReportComponent.prototype.getDownloadMessage = function () {
        return 'Hlášení je připraveno, bude staženo po zavření dialogu.';
    };
    return CadastreDataReportComponent;
}());
exports.CadastreDataReportComponent = CadastreDataReportComponent;
