"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var i0 = require("@angular/core");
var TitleNamesPipe = /** @class */ (function () {
    function TitleNamesPipe() {
    }
    TitleNamesPipe.prototype.transform = function (titles) {
        if (!titles || !titles.length) {
            return '-';
        }
        return titles.length > 1 ? 'Více LV' : titles[0].lv;
    };
    TitleNamesPipe.ngInjectableDef = i0.defineInjectable({ factory: function TitleNamesPipe_Factory() { return new TitleNamesPipe(); }, token: TitleNamesPipe, providedIn: "root" });
    return TitleNamesPipe;
}());
exports.TitleNamesPipe = TitleNamesPipe;
