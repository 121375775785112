"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["[type=\"radio\"][_ngcontent-%COMP%] {\n  display: none; }\n\n[type=\"radio\"][_ngcontent-%COMP%]    + span[_ngcontent-%COMP%] {\n  position: relative;\n  padding-left: 20px;\n  cursor: pointer;\n  height: 15px;\n  display: inline-block; }\n\n[type=\"radio\"][_ngcontent-%COMP%]:disabled    + span[_ngcontent-%COMP%] {\n  cursor: not-allowed; }\n\n[type=\"radio\"][_ngcontent-%COMP%]    + span[_ngcontent-%COMP%]:before {\n  content: '';\n  position: absolute;\n  left: 0;\n  top: 0;\n  width: 17px;\n  height: 17px;\n  border: 1px solid #ddd;\n  border-radius: 100%;\n  background-color: #fff; }\n\n[type=\"radio\"][_ngcontent-%COMP%]:not(:disabled)    + span[_ngcontent-%COMP%]:hover:before, [type=\"radio\"][_ngcontent-%COMP%]:checked    + span[_ngcontent-%COMP%]:before {\n  border-color: #0096dc; }\n\n[type=\"radio\"][_ngcontent-%COMP%]    + span[_ngcontent-%COMP%]:after {\n  content: '';\n  width: 9px;\n  height: 9px;\n  background: #0096dc;\n  position: absolute;\n  top: 4px;\n  left: 4px;\n  border-radius: 100%;\n  transition: all 0.2s ease; }\n\n[type=\"radio\"][_ngcontent-%COMP%]:not(:checked)    + span[_ngcontent-%COMP%]:after {\n  opacity: 0;\n  transform: scale(0); }\n\n[type=\"radio\"][_ngcontent-%COMP%]:checked    + span[_ngcontent-%COMP%]:after {\n  opacity: 1;\n  transform: scale(1); }"];
exports.styles = styles;
