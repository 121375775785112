"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var ChecklistService = /** @class */ (function () {
    function ChecklistService(checklistService) {
        this.checklistService = checklistService;
    }
    ChecklistService.prototype.get = function (checkedItems, toggleCallback) {
        if (checkedItems === void 0) { checkedItems = undefined; }
        if (toggleCallback === void 0) { toggleCallback = undefined; }
        return new this.checklistService(checkedItems, toggleCallback);
    };
    ChecklistService.ngInjectableDef = i0.defineInjectable({ factory: function ChecklistService_Factory() { return new ChecklistService(i0.inject("ChecklistService")); }, token: ChecklistService, providedIn: "root" });
    return ChecklistService;
}());
exports.ChecklistService = ChecklistService;
