"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var i0 = require("@angular/core");
/**
 * Display name of project subject
 */
var ProjectSubjectNamePipe = /** @class */ (function () {
    function ProjectSubjectNamePipe() {
    }
    ProjectSubjectNamePipe.prototype.transform = function (subject, withContact, withCompany) {
        if (withContact === void 0) { withContact = false; }
        if (withCompany === void 0) { withCompany = true; }
        if (!subject) {
            return '';
        }
        var words = [];
        if (subject.surname) {
            words.push(subject.surname);
        }
        if (subject.firstName) {
            words.push(subject.firstName);
        }
        var tmp = [];
        if (words.length !== 0) {
            tmp.push(words.join(' '));
        }
        words = [];
        if (subject.titleBefore) {
            words.push(subject.titleBefore);
        }
        if (subject.degreeAfter) {
            words.push(subject.degreeAfter);
        }
        if (words.length !== 0) {
            tmp.push(words.join(' '));
        }
        if (subject.companyName && withCompany) {
            tmp.push(subject.companyName);
        }
        if (withContact) {
            if (subject.phoneNumber) {
                tmp.push(subject.phoneNumber);
            }
            if (subject.email) {
                tmp.push(subject.email);
            }
        }
        return tmp.join(', ');
    };
    ProjectSubjectNamePipe.ngInjectableDef = i0.defineInjectable({ factory: function ProjectSubjectNamePipe_Factory() { return new ProjectSubjectNamePipe(); }, token: ProjectSubjectNamePipe, providedIn: "root" });
    return ProjectSubjectNamePipe;
}());
exports.ProjectSubjectNamePipe = ProjectSubjectNamePipe;
