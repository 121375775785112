"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var parcel_model_1 = require("@app/common/models/parcel.model");
var ParcelTenantEditFormComponent = /** @class */ (function () {
    function ParcelTenantEditFormComponent(restangular) {
        this.restangular = restangular;
        this.rentalTypes = [
            {
                id: '',
                value: 'Bez nájmu',
            },
            {
                id: 'L',
                value: 'Pronájem',
            },
            {
                id: 'T',
                value: 'Pacht',
            },
        ];
        this.opsubTypes = [
            {
                id: 'OPO',
                value: 'Právnická osoba',
            },
            {
                id: 'OFO',
                value: 'Fyzická osoba',
            },
        ];
        this.isValid = this.isValid.bind(this);
        this.update = this.update.bind(this);
    }
    ParcelTenantEditFormComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.rentalTypeOption = this.rentalTypes
            .find(function (option) { return option.id === _this.parcel.parcelDetail.rentalType; });
        this.opsubTypeOption = this.opsubTypes
            .find(function (option) { return option.id === _this.parcel.parcelDetail.tenantOpsubType; });
    };
    ParcelTenantEditFormComponent.prototype.isValid = function () {
        var isOPOValid = this.parcel.parcelDetail.rentalType !== '' && this.parcel.parcelDetail.tenantOpsubType === 'OPO' && this.parcel.parcelDetail.tenantNazev;
        var isOFOValid = this.parcel.parcelDetail.rentalType !== '' && this.parcel.parcelDetail.tenantOpsubType === 'OFO' && this.parcel.parcelDetail.tenantPrijmeni;
        var isNotRented = this.parcel.parcelDetail.rentalType === '';
        return isOPOValid || isOFOValid || isNotRented;
    };
    ParcelTenantEditFormComponent.prototype.update = function () {
        return this.restangular
            .one('parcels', this.parcel.id)
            .customPUT(this.parcel);
    };
    ParcelTenantEditFormComponent.prototype.onSelectRentalTypeOption = function (option) {
        this.rentalTypeOption = option;
        this.parcel.parcelDetail.rentalType = option.id;
    };
    ParcelTenantEditFormComponent.prototype.onSelectOpsubTypeOption = function (option) {
        this.opsubTypeOption = option;
        this.parcel.parcelDetail.tenantOpsubType = option.id;
    };
    return ParcelTenantEditFormComponent;
}());
exports.ParcelTenantEditFormComponent = ParcelTenantEditFormComponent;
