"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var parcel_list_component_1 = require("@app/dimap/parcels/parcel-list/parcel-list.component");
var parcel_detail_component_1 = require("@app/dimap/parcels/parcel-detail/parcel-detail.component");
var state_utils_1 = require("@app/common/utils/state.utils");
exports.parcelsState = {
    name: 'dimap.project.parcels',
    data: {
        title: 'Parcely',
    },
    url: '/parcels?page',
    params: {
        page: {
            dynamic: true,
            type: 'int',
            inherit: false,
        }
    },
    views: {
        'content@dimap.project': {
            component: parcel_list_component_1.ParcelListComponent,
        }
    }
};
exports.parcelDetailState = {
    name: 'dimap.project.parcels.detail',
    url: '/:id',
    data: {
        title: 'Detail parcely',
    },
    views: {
        'content@dimap.project': {
            component: parcel_detail_component_1.ParcelDetailComponent,
        }
    },
    resolve: {
        parcelId: ['$stateParams', state_utils_1.resolveId]
    },
    params: {
        id: {
            type: 'int',
            value: undefined
        },
    },
};
var ParcelsModule = /** @class */ (function () {
    function ParcelsModule() {
    }
    return ParcelsModule;
}());
exports.ParcelsModule = ParcelsModule;
