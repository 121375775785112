"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var dialog_ref_1 = require("@app/dialog/dialog-ref");
var dialog_config_1 = require("@app/dialog/dialog-config");
var ArticleAttachmentComponent = /** @class */ (function () {
    function ArticleAttachmentComponent(configurationRestangular, uploadService, dialogRef, dialogConfig) {
        this.configurationRestangular = configurationRestangular;
        this.uploadService = uploadService;
        this.dialogRef = dialogRef;
        this.dialogConfig = dialogConfig;
        this.files = [];
        this.filesMsg = 'Vložte soubor přetažením nebo výběrem.';
        this.article = dialogConfig.data.article;
        this.onSave = this.onSave.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }
    ArticleAttachmentComponent.prototype.ngOnInit = function () {
    };
    ArticleAttachmentComponent.prototype.onFilesChange = function (files) {
        this.files = files;
        if (this.files.length === 0) {
            this.filesMsg = 'Vložte soubor přetažením nebo výběrem.';
        }
        else if (this.files.length > 1) {
            this.filesMsg = 'Je možné vložit pouze jeden soubor.';
        }
        else {
            this.filesMsg = '';
        }
    };
    ArticleAttachmentComponent.prototype.onSave = function () {
        var _this = this;
        var data = new FormData();
        data.append('file', this.files[0].fileEntry);
        data.append('filename', this.files[0].fileEntry.name);
        if (this.article.projectKey) {
            data.append('projectKey', this.article.projectKey);
        }
        if (this.article.application) {
            data.append('application', this.article.application);
        }
        return this.configurationRestangular
            .all('attachments')
            .customPOST(data, '', null, { 'Content-Type': undefined }).then(function (attachment) {
            _this.dialogRef.close({
                text: (_this.text ? _this.text : ''),
                attachment: attachment.plain()
            });
        });
    };
    ArticleAttachmentComponent.prototype.onCancel = function () {
        this.dialogRef.close(false);
    };
    ArticleAttachmentComponent.prototype.isValid = function () {
        return this.files && this.files.length === 1;
    };
    return ArticleAttachmentComponent;
}());
exports.ArticleAttachmentComponent = ArticleAttachmentComponent;
