"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var list_service_1 = require("@app/common/services/list.service");
var auth_service_1 = require("@app/common/services/auth.service");
var email_utils_1 = require("@app/common/utils/email.utils");
var ChangelogComponent = /** @class */ (function () {
    function ChangelogComponent(restangularSettings, listService, authService) {
        this.restangularSettings = restangularSettings;
        this.listService = listService;
        this.authService = authService;
        this.showCount = 1;
        this.collapsed = true;
        this.onAccept = this.onAccept.bind(this);
    }
    ChangelogComponent.prototype.ngOnInit = function () {
        var filter = {
            filters: {
                unacceptedByUserId: this.authService.getUser().id,
                application: this.authService.getActiveApplication().name
            },
            limit: this.showCount,
            sortOrder: { sortBy: 'publishDate', direction: 'desc' },
        };
        this.changelogList = this.listService.createList('changelogs', filter, this.restangularSettings);
        this.listService.fetchResult(this.changelogList);
    };
    ChangelogComponent.prototype.onAccept = function (changelog) {
        var _this = this;
        this.restangularSettings
            .one("changelogs/" + changelog.id + "/accept/" + this.authService.getUser().id)
            .post()
            .then(function () {
            _this.listService.fetchResult(_this.changelogList);
        });
    };
    ChangelogComponent.prototype.getPreview = function (description) {
        return email_utils_1.EmailUtils.textToHtml(description);
    };
    return ChangelogComponent;
}());
exports.ChangelogComponent = ChangelogComponent;
