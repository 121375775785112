"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var auth_service_1 = require("@app/common/services/auth.service");
var opinion_service_1 = require("@app/ps/services/opinion.service");
var angular_1 = require("@uirouter/angular");
var checklist_service_1 = require("@app/common/services/checklist.service");
var ownership_name_pipe_1 = require("@app/common/pipes/ownership-name.pipe");
var PricingPreliminaryOpinionComponent = /** @class */ (function () {
    function PricingPreliminaryOpinionComponent(restangular, helpIds, confirmService, ownershipNamePipe, checklistService, authService, opinionService, transitionService, stateService) {
        this.restangular = restangular;
        this.helpIds = helpIds;
        this.confirmService = confirmService;
        this.ownershipNamePipe = ownershipNamePipe;
        this.checklistService = checklistService;
        this.authService = authService;
        this.opinionService = opinionService;
        this.transitionService = transitionService;
        this.stateService = stateService;
        this.loading = true;
        this.sum = { price: 0 };
        this.readonly = !this.authService.hasPermission('assignable');
        this.onDelete = this.onDelete.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onClose = this.onClose.bind(this);
    }
    PricingPreliminaryOpinionComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.loadTitle()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.loadOpinion()];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.loadOpinionPricing()];
                    case 3:
                        _a.sent();
                        this.computeSum();
                        this.deregisterLeaveListener = this.transitionService.onBefore({}, this.beforeExit.bind(this));
                        this.loading = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    PricingPreliminaryOpinionComponent.prototype.onDelete = function () {
        var _this = this;
        return new this.confirmService({
            title: 'Opravdu chcete znalecký posudek smazat?',
            message: 'Všechna zadaná data budou ztracena.'
        }).then(function (data) {
            if (data === true) {
                _this.deregisterLeaveListener();
                return _this.opinionService.deleteOne(_this.opinion.id).then(function () { return true; });
            }
            else {
                return false;
            }
        }).then(function (deleted) {
            if (deleted) {
                _this.onClose();
            }
        });
    };
    PricingPreliminaryOpinionComponent.prototype.onClose = function () {
        this.stateService.go('symap.project.titles.detail.pricing.overview', { titleId: this.titleId });
    };
    PricingPreliminaryOpinionComponent.prototype.onSave = function () {
        var _this = this;
        this.deregisterLeaveListener();
        return this.opinionService
            .updateOne(this.opinion.id, this.opinion)
            .then(function () { return _this.onClose(); });
    };
    /**
     * Computes the total price of the preliminary opinion.
     *
     * @returns {void}
     */
    PricingPreliminaryOpinionComponent.prototype.computeSum = function () {
        var _this = this;
        this.sum.price = 0;
        this.opinion.parcelPrices.forEach(function (parcelPrice) {
            var coefficient = parcelPrice.preliminaryOpinionCoefficient ? parcelPrice.preliminaryOpinionCoefficient : 1;
            var landPrice = parcelPrice.preliminaryOpinionLandPrice ? parcelPrice.preliminaryOpinionLandPrice : 0;
            _this.sum.price += Math.round(coefficient * landPrice * parcelPrice.parcel.vymera);
        });
    };
    PricingPreliminaryOpinionComponent.prototype.isValid = function () {
        return this.opinion.parcelPrices && this.opinion.parcelPrices.length && this.opinion.parcelPrices.every(this.isValidPrice, this);
    };
    /**
     * Checks if a parcel price is valid.
     * Parcel price is considered valid when both price and coefficient are filled.
     *
     * @returns {boolean}
     */
    PricingPreliminaryOpinionComponent.prototype.isValidPrice = function (row) {
        var preliminaryOpinionLandPrice = isFinite(row.preliminaryOpinionLandPrice);
        var preliminaryOpinionCoefficient = isFinite(row.preliminaryOpinionCoefficient);
        return preliminaryOpinionLandPrice && preliminaryOpinionCoefficient;
    };
    PricingPreliminaryOpinionComponent.prototype.loadTitle = function () {
        var _this = this;
        return this.restangular
            .one('titles', this.titleId)
            .get({ loadCollections: ['ownerships', 'ownerships.subjects'] })
            .then(function (res) {
            _this.title = res.plain();
        });
    };
    PricingPreliminaryOpinionComponent.prototype.loadOpinion = function () {
        var _this = this;
        return this.opinionService
            .getOne(this.opinionId)
            .then(function (res) { return _this.opinion = res; });
    };
    PricingPreliminaryOpinionComponent.prototype.loadOpinionPricing = function () {
        var _this = this;
        return this.restangular
            .all('parcel-prices')
            .customPOST({
            filter: {
                filters: {
                    opinionId: [this.opinionId],
                },
            },
        }).then(function (data) {
            data = _this.restangular.stripRestangular(data);
            data.forEach(function (parcelPrice) {
                _this.opinion.parcelPrices.push(parcelPrice);
            });
        });
    };
    PricingPreliminaryOpinionComponent.prototype.beforeExit = function () {
        var _this = this;
        if (this.readonly) {
            this.deregisterLeaveListener();
            return Promise.resolve(true);
        }
        return new this.confirmService({
            title: 'Opravdu chcete odejít?',
            message: 'Všechny úpravy budou ztraceny.'
        }).then(function (data) {
            if (data === true) {
                _this.deregisterLeaveListener();
                return Promise.resolve(true);
            }
            return Promise.resolve(false);
        });
    };
    return PricingPreliminaryOpinionComponent;
}());
exports.PricingPreliminaryOpinionComponent = PricingPreliminaryOpinionComponent;
