"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".no-country-code[_nghost-%COMP%]   .country-code-block[_ngcontent-%COMP%] {\n  display: none; }"];
exports.styles = styles;
