"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var parent_case_ownership_name_pipe_1 = require("@app/ps/pipes/parent-case-ownership-name.pipe");
var title_detail_create_case_select_parent_service_1 = require("@app/ps/services/title-detail-create-case-select-parent.service");
var CreateCaseSelectParentOwnershipComponent = /** @class */ (function () {
    function CreateCaseSelectParentOwnershipComponent(parentCaseOwnershipNamePipe, titleDetailCreateCaseSelectParentService) {
        this.parentCaseOwnershipNamePipe = parentCaseOwnershipNamePipe;
        this.titleDetailCreateCaseSelectParentService = titleDetailCreateCaseSelectParentService;
        this.forwardStep = true;
        this.submitCallback = new core_1.EventEmitter();
        this.loading = false;
        this.submit = this.submit.bind(this);
    }
    CreateCaseSelectParentOwnershipComponent.prototype.ngOnInit = function () {
        this.checkNeedsParent();
    };
    CreateCaseSelectParentOwnershipComponent.prototype.submit = function () {
        if (this.needsParentSelection()) {
            return;
        }
        return this.submitCallback.emit({
            skipped: !this.ownershipsWithParent,
        });
    };
    CreateCaseSelectParentOwnershipComponent.prototype.checkNeedsParent = function () {
        var _this = this;
        if (this.data.obligation.type === 'InnominateSalesContractLiability') {
            return this.submit();
        }
        return this.titleDetailCreateCaseSelectParentService.checkOwnershipsWithParent(this.data.caseOwnerships).then(function (ownershipsWithParent) {
            if (!_this.forwardStep || _this.titleDetailCreateCaseSelectParentService.needsParentSelection(ownershipsWithParent)) {
                _this.ownershipsWithParent = ownershipsWithParent;
            }
            else {
                _this.submit();
            }
        });
    };
    CreateCaseSelectParentOwnershipComponent.prototype.changedParent = function (newValue, oldValue, ownershipWithParentExt, caseOwnerships, ownershipsWithParent) {
        var _this = this;
        var promise = this.titleDetailCreateCaseSelectParentService.changedParent(newValue, oldValue, ownershipWithParentExt, caseOwnerships, ownershipsWithParent);
        if (promise) {
            this.loading = true;
            promise.then(function () {
                _this.loading = false;
            });
        }
    };
    CreateCaseSelectParentOwnershipComponent.prototype.needsParentSelection = function () {
        return this.loading || this.titleDetailCreateCaseSelectParentService.needsParentSelection(this.ownershipsWithParent);
    };
    return CreateCaseSelectParentOwnershipComponent;
}());
exports.CreateCaseSelectParentOwnershipComponent = CreateCaseSelectParentOwnershipComponent;
