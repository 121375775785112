"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".row[_nghost-%COMP%]:hover   .chart__number[_ngcontent-%COMP%], .row:hover   [_nghost-%COMP%]   .chart__number[_ngcontent-%COMP%], .clickable[_nghost-%COMP%]:hover   .chart__number[_ngcontent-%COMP%], .clickable:hover   [_nghost-%COMP%]   .chart__number[_ngcontent-%COMP%] {\n  display: block;\n  position: absolute;\n  left: 0;\n  top: -20px;\n  text-align: center;\n  width: 100%; }\n\n[_nghost-%COMP%] {\n  display: block;\n  height: 100%; }\n\n.chart[_ngcontent-%COMP%] {\n  border-top-style: solid;\n  border-top-width: 5px;\n  display: inline-block;\n  margin-top: 0;\n  position: relative;\n  text-align: center; }\n\n.chart--no-data[_ngcontent-%COMP%] {\n  display: inline-block; }\n\n.chart--onhold[_ngcontent-%COMP%] {\n  border-top-color: #ffc90e; }\n\n.chart--progress[_ngcontent-%COMP%] {\n  border-top-color: #30a2e8; }\n\n.chart--done[_ngcontent-%COMP%] {\n  border-top-color: #22b14c; }\n\n.chart__number[_ngcontent-%COMP%] {\n  display: none; }"];
exports.styles = styles;
