"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./cell-construction-objects.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../construction-object-list/construction-object-list.component.ngfactory");
var i3 = require("../construction-object-list/construction-object-list.component");
var i4 = require("../../../ps/pipes/project-subject-name.pipe");
var i5 = require("../../directives/tooltip/tooltip.directive");
var i6 = require("@angular/common");
var i7 = require("./cell-construction-objects.component");
var styles_CellConstructionObjectsComponent = [i0.styles];
var RenderType_CellConstructionObjectsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CellConstructionObjectsComponent, data: {} });
exports.RenderType_CellConstructionObjectsComponent = RenderType_CellConstructionObjectsComponent;
function View_CellConstructionObjectsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "gmt-construction-object-list", [], null, null, null, i2.View_ConstructionObjectListComponent_0, i2.RenderType_ConstructionObjectListComponent)), i1.ɵdid(1, 114688, null, 0, i3.ConstructionObjectListComponent, [i4.ProjectSubjectNamePipe], { constructionObjects: [0, "constructionObjects"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.constructionObjects; _ck(_v, 1, 0, currVal_0); }, null); }
function View_CellConstructionObjectsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 6, "div", [["class", "tooltip-hover-area fill-content"]], null, [[null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("mouseenter" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).mouseEnter() !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).closeTooltip() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 81920, null, 0, i5.TooltipDirective, [i1.ElementRef, "WindowDimensionService", i1.Renderer2, i1.ComponentFactoryResolver, i1.ViewContainerRef, i1.Injector], { content: [0, "content"], data: [1, "data"], activeContent: [2, "activeContent"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "fill-content flexbox-container flex-centered p-10"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "icon-count flexbox-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [["class", "fa fa-th"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "number ml-4"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tooltipComponent; var currVal_1 = _co.tooltipData; var currVal_2 = true; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.constructionObjects.length; _ck(_v, 6, 0, currVal_3); }); }
function View_CellConstructionObjectsComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_CellConstructionObjectsComponent_1)), i1.ɵdid(1, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CellConstructionObjectsComponent_2)), i1.ɵdid(3, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !(_co.tooltipComponent && _co.tooltipData); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.tooltipComponent && _co.tooltipData); _ck(_v, 3, 0, currVal_1); }, null); }
exports.View_CellConstructionObjectsComponent_0 = View_CellConstructionObjectsComponent_0;
function View_CellConstructionObjectsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "cell-construction-objects", [], null, null, null, View_CellConstructionObjectsComponent_0, RenderType_CellConstructionObjectsComponent)), i1.ɵdid(1, 49152, null, 0, i7.CellConstructionObjectsComponent, [], null, null)], null, null); }
exports.View_CellConstructionObjectsComponent_Host_0 = View_CellConstructionObjectsComponent_Host_0;
var CellConstructionObjectsComponentNgFactory = i1.ɵccf("cell-construction-objects", i7.CellConstructionObjectsComponent, View_CellConstructionObjectsComponent_Host_0, { tooltipData: "tooltipData", tooltipType: "tooltipType", list: "list", constructionObjects: "constructionObjects" }, {}, []);
exports.CellConstructionObjectsComponentNgFactory = CellConstructionObjectsComponentNgFactory;
