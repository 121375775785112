"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
Object.defineProperty(exports, "__esModule", { value: true });
var auth_service_1 = require("@app/common/services/auth.service");
var common_1 = require("@angular/common");
var settings_service_1 = require("@app/ps/services/settings.service");
var i0 = require("@angular/core");
var i1 = require("./symap-restangular.service");
var i2 = require("./auth.service");
var i3 = require("@angular/common");
var i4 = require("../../ps/services/settings.service");
exports.vocabulary = [{
        id: 1,
        name: 'stavební objekt',
        key: 'CONSTRUCTION_OBJECT',
        commonName: 'stavební objekt'
    }, {
        id: 2,
        name: 'SO',
        key: 'CONSTRUCTION_OBJECT_SHORTCUT',
        commonName: 'SO'
    }, {
        id: 3,
        name: 'stavebního objektu',
        key: 'CONSTRUCTION_OBJECT_GENITIV',
        commonName: 'stavebního objektu'
        // example: Upozornit 5 dní před koncem realizace stavebního objektu
    }, {
        id: 4,
        name: 'stavebním objektu',
        key: 'CONSTRUCTION_OBJECT_LOCAL',
        commonName: 'stavebním objektu'
    }, {
        id: 5,
        name: 'stavebních objektů',
        key: 'CONSTRUCTION_OBJECT_PLURAL_GENITIV',
        commonName: 'stavebních objektů'
    }, {
        id: 6,
        name: 'stavební objekty',
        key: 'CONSTRUCTION_OBJECT_PLURAL_NOMINATIV',
        commonName: 'stavební objekty'
    }, {
        id: 7,
        name: 'dotčený stavební objekt',
        key: 'CONCERN_CONSTRUCTION_OBJECT',
        commonName: 'dotčený stavební objekt'
    }, {
        id: 8,
        name: 'SO',
        key: 'CONSTRUCTION_OBJECT_PLURAL_GENITIV_SHORTCUT',
        commonName: 'stavebních objektů (zkratka)'
    }, {
        id: 9,
        name: 'SO',
        key: 'CONSTRUCTION_OBJECT_GENITIV_SHORTCUT',
        commonName: 'stavebního objektu (zkratka)'
    }, {
        id: 10,
        name: 'dotčený SO',
        key: 'CONCERN_CONSTRUCTION_OBJECT_SHORTCUT',
        commonName: 'dotčený stavební objekt (zkratka)'
    }, {
        id: 11,
        name: 'dotčené SO',
        key: 'CONCERN_CONSTRUCTION_OBJECTS_SHORTCUT',
        commonName: 'dotčené stavební objekty (zkratka)'
    }, {
        id: 13,
        name: 'nový SO',
        key: 'NEW_CONSTRUCTION_OBJECT_SHORTCUT',
        commonName: 'nový stavební objekt (zkratka)'
    }, {
        id: 14,
        name: 'nový stavební objekt',
        key: 'NEW_CONSTRUCTION_OBJECT',
        commonName: 'nový stavební objekt'
    }, {
        id: 15,
        name: 'nového stavebního objektu',
        key: 'NEW_CONSTRUCTION_OBJECT_GENITIV',
        commonName: 'nového stavebního objektu'
    }, {
        id: 16,
        name: 'Etapa / úsek',
        key: 'ETAPA_USEK',
        commonName: 'Etapa / úsek'
    }, {
        id: 17,
        name: 'stavební objekt',
        key: 'CONSTRUCTION_OBJECT_AKUZATIV',
        commonName: 'stavební objekt/zakázku(4. pád)'
    }, {
        id: 18,
        name: 'jihého stavebního objektu',
        key: 'CONSTRUCTION_OBJECT_OTHER',
        commonName: 'jihého stavebního objektu'
    }, {
        id: 19,
        name: 'stav trvalých záborů',
        key: 'PERMANENT_OCCUPATIONS_STATE',
        commonName: 'stav trvalých záborů (popis semafor)'
    }, {
        id: 20,
        name: 'stav dočasných záborů nad 1 rok',
        key: 'OVER_YEAR_TEMPORARY_OCCUPATIONS_STATE',
        commonName: 'stav dočasných záborů nad 1 rok (popis semafor)'
    }, {
        id: 21,
        name: 'stav dočasných záborů do 1 roku',
        key: 'UNDER_YEAR_TEMPORARY_OCCUPATIONS_STATE',
        commonName: 'stav dočasných záborů do 1 roku (popis semafor)'
    }, {
        id: 22,
        name: 'stav služebností',
        key: 'EASEMENT_STATE',
        commonName: 'stav služebností (popis ouško, záhlaví tabulek)'
    }, {
        id: 23,
        name: 'trvalý zábor',
        key: 'PERMANENT_OCCUPATION',
        commonName: 'trvalý zábor (popis ouško, záhlaví tabulek)'
    }, {
        id: 24,
        name: 'dočasný zábor',
        key: 'TEMPORARY_OCCUPATION',
        commonName: 'dočasný zábor (popis ouško, záhlaví tabulek)'
    }, {
        id: 25,
        name: 'služebnost',
        key: 'EASEMENT',
        commonName: 'služebnost (popis ouško, záhlaví tabulek)'
    },
];
var WordService = /** @class */ (function () {
    function WordService(restangularSymap, authService, datePipe, settingsService) {
        this.restangularSymap = restangularSymap;
        this.authService = authService;
        this.datePipe = datePipe;
        this.settingsService = settingsService;
        this.vocabulary = null;
    }
    WordService.prototype.setVocabulary = function (projectId) {
        var _this = this;
        var activeProjectId = projectId || this.authService.getActualProject().id;
        if (!Number.isInteger(activeProjectId)) {
            throw new Error("activeProjectId is not valid integer value " + activeProjectId + ".");
        }
        if (this.vocabulary) {
            return Promise.resolve(this.vocabulary);
        }
        return this.settingsService.loadSettings(activeProjectId).then(function (settings) {
            if (!_this.hasVocabulary()) {
                return _this.parseVocabularyFromSettings(settings);
            }
            else {
                return _this.vocabulary;
            }
        }, function (reason) {
            if (!reason || reason.xhrStatus !== 'abort') {
                return Promise.reject(reason);
            }
        });
    };
    WordService.prototype.parseVocabularyFromSettings = function (settings) {
        var _this = this;
        try {
            var nomenclature = settings && settings.nomenclature;
            var nomenclatureExist = !!(nomenclature && nomenclature.length > 0);
            this.vocabulary = nomenclatureExist ? new Set(settings.nomenclature) : new Set(exports.vocabulary);
            var missingWords = this.getDifferenceWords(__spread(this.vocabulary), exports.vocabulary);
            missingWords.forEach(function (w) { return _this.vocabulary.add(w); });
            this.addUneditableVocabulary();
        }
        catch (e) {
            this.vocabulary = new Set(exports.vocabulary);
            this.addUneditableVocabulary();
        }
    };
    WordService.prototype.getVocabulary = function () {
        return this.vocabulary;
    };
    WordService.prototype.hasVocabulary = function () {
        return !!this.vocabulary;
    };
    WordService.prototype.getDifferenceWords = function (mainVocabolary, childVocabolary) {
        var e_1, _a;
        var missingWords = [];
        var _loop_1 = function (word) {
            var contain = mainVocabolary.find(function (w) { return w.key === word.key; });
            if (!contain) {
                missingWords.push(word);
            }
        };
        try {
            for (var childVocabolary_1 = __values(childVocabolary), childVocabolary_1_1 = childVocabolary_1.next(); !childVocabolary_1_1.done; childVocabolary_1_1 = childVocabolary_1.next()) {
                var word = childVocabolary_1_1.value;
                _loop_1(word);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (childVocabolary_1_1 && !childVocabolary_1_1.done && (_a = childVocabolary_1.return)) _a.call(childVocabolary_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return missingWords;
    };
    WordService.prototype.getWord = function (id, firstLeterUpper, toUpper) {
        var _this = this;
        if (firstLeterUpper === void 0) { firstLeterUpper = false; }
        if (toUpper === void 0) { toUpper = false; }
        if (!this.hasVocabulary()) {
            return this.setVocabulary().then(function () { return _this.getWord(id, firstLeterUpper, toUpper); });
        }
        if (id) {
            return Promise.resolve(this.getTranslation(id, firstLeterUpper, toUpper));
        }
        else {
            return Promise.reject(undefined);
        }
    };
    WordService.prototype.getTranslation = function (id, firstLeterUpper, toUpper) {
        if (firstLeterUpper === void 0) { firstLeterUpper = false; }
        if (toUpper === void 0) { toUpper = false; }
        var word;
        var nomenclatureItem = __spread(this.vocabulary).find(function (nom) { return nom.key === id; });
        if (!nomenclatureItem) {
            return id;
        }
        word = nomenclatureItem.name;
        if (firstLeterUpper) {
            return word.slice(0, 1).toUpperCase() + word.slice(1);
        }
        else if (toUpper) {
            return word.toUpperCase();
        }
        else {
            return word;
        }
    };
    WordService.prototype.replaceVariablesInText = function (text) {
        var _this = this;
        if (text === void 0) { text = ''; }
        if (text.includes('$')) {
            var replace = function (str) {
                var e_2, _a;
                var re = /\$\w+/g;
                var matchs = str.match(re);
                var finalPromise = Promise.resolve(str);
                if (matchs) {
                    var _loop_2 = function (m) {
                        finalPromise = finalPromise.then(function (strChained) {
                            return _this.getWord(m.split('$')[1]).then(function (tr) { return strChained.replace(m, tr); });
                        });
                    };
                    try {
                        for (var matchs_1 = __values(matchs), matchs_1_1 = matchs_1.next(); !matchs_1_1.done; matchs_1_1 = matchs_1.next()) {
                            var m = matchs_1_1.value;
                            _loop_2(m);
                        }
                    }
                    catch (e_2_1) { e_2 = { error: e_2_1 }; }
                    finally {
                        try {
                            if (matchs_1_1 && !matchs_1_1.done && (_a = matchs_1.return)) _a.call(matchs_1);
                        }
                        finally { if (e_2) throw e_2.error; }
                    }
                }
                return finalPromise;
            };
            return replace(text);
        }
        else {
            return Promise.resolve(text);
        }
    };
    WordService.prototype.clearVocabulary = function () {
        this.vocabulary = null;
    };
    WordService.prototype.addUneditableVocabulary = function () {
        var _this = this;
        var uneditableVocabulary = [
            {
                id: 12,
                name: this.authService.getActualProject() ? this.datePipe.transform(this.authService.getActualProject().cadastreDataDate, 'd.M.yyyy') : '',
                key: 'CADASTRE_DATA_DATE',
                commonName: 'datum aktualizace katastru (není možné editovat)',
                editable: false,
            },
        ];
        uneditableVocabulary.forEach(function (w) { return _this.vocabulary.add(w); });
    };
    WordService.ngInjectableDef = i0.defineInjectable({ factory: function WordService_Factory() { return new WordService(i0.inject(i1.RESTANGULAR_SYMAP), i0.inject(i2.AuthService), i0.inject(i3.DatePipe), i0.inject(i4.SettingsService)); }, token: WordService, providedIn: "root" });
    return WordService;
}());
exports.WordService = WordService;
