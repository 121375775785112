"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var pageable_list_service_1 = require("@app/common/services/pageable.list.service");
var dialog_service_1 = require("@app/dialog/dialog.service");
var auth_service_1 = require("@app/common/services/auth.service");
var user_edit_component_1 = require("@app/common/components/user-edit/user-edit.component");
var brand_service_1 = require("@app/common/services/brand.service");
var role_pipe_1 = require("@app/common/pipes/role.pipe");
var UserListComponent = /** @class */ (function () {
    function UserListComponent(helpIds, globalRestangular, APP_BRAND, authService, pageableListService, dialogService, brandService, rolePipe) {
        this.helpIds = helpIds;
        this.globalRestangular = globalRestangular;
        this.APP_BRAND = APP_BRAND;
        this.authService = authService;
        this.pageableListService = pageableListService;
        this.dialogService = dialogService;
        this.brandService = brandService;
        this.rolePipe = rolePipe;
        this.module = this.authService.getActiveApplication();
        this.editor = this.authService.getUser();
        this.defaultFilter = {
            filters: {
                searchText: {
                    values: [{ id: '' }]
                },
                applications: {
                    values: [this.module]
                },
                projects: {
                    values: []
                },
                roles: {
                    values: []
                },
                withoutRole: {
                    values: []
                },
            },
        };
        this.onCreate = this.onCreate.bind(this);
    }
    UserListComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (this.authService.getActualProject()) {
                            this.projectKey = this.authService.getActualProject().key;
                            this.defaultFilter.filters.projects.values = [this.projectKey];
                        }
                        _a = this;
                        return [4 /*yield*/, this.globalRestangular.all(this.module + "/roles").getList()];
                    case 1:
                        _a.roles = _b.sent();
                        this.initializeList();
                        return [2 /*return*/];
                }
            });
        });
    };
    UserListComponent.prototype.uiOnParamsChanged = function (changedParams, $transition$) {
        this.pageableList.uiOnParamsChanged(changedParams, $transition$);
    };
    UserListComponent.prototype.initializeList = function () {
        this.pageableList = this.pageableListService.get('users/list', this.defaultFilter, 'usersFilter2', this.globalRestangular);
        this.list = this.pageableList.list;
        this.pageableList.load();
    };
    UserListComponent.prototype.onCreate = function () {
        var _this = this;
        var dialog = this.dialogService.open(user_edit_component_1.UserEditComponent, {
            data: { modules: [this.authService.getActiveApplication()] }
        });
        var sub = dialog.afterClosed.subscribe(function (res) {
            if (res) {
                _this.pageableList.fetchResults();
            }
            sub.unsubscribe();
        });
    };
    return UserListComponent;
}());
exports.UserListComponent = UserListComponent;
