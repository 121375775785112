class ModulesService {
  constructor(Restangular, $q, APPLICATIONS, GlobalRestangularSYMAPProvider, GlobalRestangularDIMAPProvider, GlobalRestangularSettingsProvider, GlobalRestangularVFZEProvider) {
    this.Restangular = Restangular;
    this.$q = $q;
    this.APPLICATIONS = APPLICATIONS;
    this.GlobalRestangularSYMAPProvider = GlobalRestangularSYMAPProvider;
    this.GlobalRestangularDIMAPProvider = GlobalRestangularDIMAPProvider;
    this.GlobalRestangularVFZEPProvider = GlobalRestangularVFZEProvider;
    this.GlobalRestangularSettingsProvider = GlobalRestangularSettingsProvider;
    this.symapPermissions = null;
    this.roles = {};
    this.dimapPermissions = null;
    this.vfzePermissions = null;
    this.promapPermissions = null;
    this.symapProjects = null;
    this.dimapProjects = null;
    this.vfzeProjects = null;
    this.promapProjects = null;
    this.users = {};
    this.userPermissions = {};
    this.userApprovedTerms = {};
    this.termsConditions = {};
  }

  async loadModuleRoles(module) {
    return this.GlobalRestangularSettingsProvider.all(`${module}/roles/permissions`).getList();
  }

  async getModuleRoles(module) {
    if (this.roles[module]) {
      return this.roles[module];
    } else {
      if (module === this.APPLICATIONS.sy.name) {
        this.roles[module] = await this.loadModuleRoles(module);
      } else if (module === this.APPLICATIONS.di.name) {
        const permissions = (await this.getPermissions(module)).map(p => p.name);
        this.roles[module] = [{
          role: this.APPLICATIONS.di.name,
          permissions,
        }];
      } else if (module === this.APPLICATIONS.vfze.name) {
        const permissions = (await this.getPermissions(module)).map(p => p.name);
        this.roles[module] = [{
          role: this.APPLICATIONS.vfze.name,
          permissions,
        }];
      } else if (module === this.APPLICATIONS.pk.name) {
        const permissions = (await this.getPermissions(module)).map(p => p.name);
        this.roles[module] = [{
          role: this.APPLICATIONS.pk.name,
          permissions,
        }];
      }

      return this.roles[module];
    }
  }

  loadModulePermissions(module) {
    return this.GlobalRestangularSettingsProvider.one(`permissions/application/${module}/list`).get().then((data) => this.setPermissions(module, data));
  }

  loadSyMAPProjectById(projectId) {
    const projectGetParams = {loadCollections: ['contract', 'customerWsdpAccount', 'customer', 'series', 'obligationTypes', 'occupationTypes', 'statisticsTypes', 'exportTypes', 'templateTypes', 'investor', 'investor.actingPersons', 'investor.cadastreAgent', 'investor.cadastreAgent.actingPersons', 'manager', 'designer', 'propertyActivityProcessor', 'landTakeDocumentationAuthor' ]};
    return this.GlobalRestangularSYMAPProvider.one('projects', projectId).get(projectGetParams);
  }

  loadProjects(module) {
    const SyMAPProjectGetParams = {loadCollections: ['series', 'obligationTypes', 'statisticsTypes', 'exportTypes', 'templateTypes', 'investor', 'investor.actingPersons', 'investor.cadastreAgent', 'investor.cadastreAgent.actingPersons', 'manager', 'designer', 'propertyActivityProcessor', 'landTakeDocumentationAuthor' ]};
    switch (module) {
      case this.APPLICATIONS.sy.name:
        return this.GlobalRestangularSYMAPProvider.one('projects').get(SyMAPProjectGetParams).then((data) => this.setProjects(module, data));
      case this.APPLICATIONS.di.name:
        return this.GlobalRestangularDIMAPProvider.one('projects').get().then((data) => this.setProjects(module, data));
      case this.APPLICATIONS.vfze.name:
        return this.GlobalRestangularVFZEPProvider.one('projects').get().then((data) => this.setProjects(module, data));
      case this.APPLICATIONS.pk.name:
        return new Promise((resolve, reject) => {
          resolve(this.setProjects(module, { items: [ { key: 'promap_default' }] }));
        });
      default:
    }
  }

  loadUser(userId) {
    return this.GlobalRestangularSettingsProvider.one('users/user', userId).get().then((data) => this.setUser(userId, data));
  }

  createUser(user) {
    return this.GlobalRestangularSettingsProvider.all('users/user').post(user).then((data) => {
      this.setUser(data.id, data);
      return data;
    });
  }

  saveUser(user) {
    const userPlain = this.GlobalRestangularSettingsProvider.stripRestangular(user);
    return this.GlobalRestangularSettingsProvider.one('users/user', userPlain.id).customPUT(userPlain).then((data) => {
      this.setUser(data.id, data);
      return data;
    });
  }

  removeUser(userId) {
    return this.GlobalRestangularSettingsProvider.one('users/user', userId).remove().then(() => this.setUser(userId, undefined));
  }

  loadUserPermissions(module, userId) {
    return this.GlobalRestangularSettingsProvider.one(`permissions/application/${module}/user/${userId}`).get().then((data) => this.setUserPermissions(module, userId, data));
  }

  saveUserPermissions(module, projectKey, userId, permissions) {
    return this.GlobalRestangularSettingsProvider.one(`permissions/application/${module}/project/${projectKey}`, 'user').post(userId, permissions).then(() => {
      return this.updateUserPermissions(module, userId, projectKey, permissions);
    });
  }

  setPermissions(module, properties) {
    switch (module) {
      case this.APPLICATIONS.sy.name:
        this.symapPermissions = properties;
        return this.symapPermissions;
      case this.APPLICATIONS.di.name:
        this.dimapPermissions = properties;
        return this.dimapPermissions;
      case this.APPLICATIONS.vfze.name:
        this.vfzePermissions = properties;
        return this.vfzePermissions;
      case this.APPLICATIONS.pk.name:
        this.promapPermissions = properties;
        return this.promapPermissions;
      default:
    }
  }

  getPermissions(module) {
    const defered = this.$q.defer();

    const loadPermissions = (module) => {
      return this.loadModulePermissions(module).then(() => {
        this.getPermissions(module).then(data => defered.resolve(data));
      });
    };

    switch (module) {
      case this.APPLICATIONS.sy.name:
        if (!this.symapPermissions) {
          loadPermissions(module);
        } else {
          defered.resolve(this.symapPermissions);
        }
        break;
      case this.APPLICATIONS.di.name:
        if (!this.dimapPermissions) {
          loadPermissions(module);
        } else {
          defered.resolve(this.dimapPermissions);
        }
        break;
      case this.APPLICATIONS.vfze.name:
        if (!this.vfzePermissions) {
          loadPermissions(module);
        } else {
          defered.resolve(this.vfzePermissions);
        }
        break;
      case this.APPLICATIONS.pk.name:
        if (!this.promapPermissions) {
          loadPermissions(module);
        } else {
          defered.resolve(this.promapPermissions);
        }
        break;
      default:
    }

    return defered.promise;
  }

  loadUserApprovedTerms(userId) {
    return this.GlobalRestangularSettingsProvider.one(`users/user/${userId}/terms-conditions`).get().then((data) => this.setUserApprovedTerms(userId, data));
  }

  loadTermsConditions(module) {
    return this.GlobalRestangularSettingsProvider.one(`applications/${module}`).get().then((data) => this.setTermsConditions(module, data));
  }

  setUserApprovedTerms(userId, approvedTerms) {
    this.userApprovedTerms[userId] = approvedTerms;
  }

  setTermsConditions(module, conditions) {
    this.termsConditions[module] = conditions;
  }

  saveConditions(module, conditions) {
    return this.getApproveTermsConditions(module).then((oldConditions) => {
      oldConditions.termsAndConditions = conditions;
      return this.GlobalRestangularSettingsProvider.one(`applications/${module}`).customPUT(this.Restangular.stripRestangular(oldConditions));
    });
  }

  setProjects(module, data) {
    switch (module) {
      case this.APPLICATIONS.sy.name:
        this.symapProjects = data;
        return this.symapProjects;
      case this.APPLICATIONS.di.name:
        this.dimapProjects = data;
        return this.dimapProjects;
      case this.APPLICATIONS.vfze.name:
        this.vfzeProjects = data;
        return this.vfzeProjects;
      case this.APPLICATIONS.pk.name:
        this.promapProjects = data;
        return this.promapProjects;
      default:
    }
  }

  getProjectById(module, projectId) {
    return this.getProjects(module).then((projects) => {
      return projects.find(p => p.id === projectId);
    });
  }

  saveProject(module, project) {
    switch (module) {
      case this.APPLICATIONS.sy.name:
        return this.GlobalRestangularSYMAPProvider.one('projects', project.id).customPUT(project).then(() => {
          return this.loadProjects(module);
        });
      case this.APPLICATIONS.di.name:
        return this.GlobalRestangularDIMAPProvider.one('projects', project.id).customPUT(project).then(() => {
          return this.loadProjects(module);
        });
      default:
    }
  }

  getProjects(module) {
    const defered = this.$q.defer();
    let projects = null;
    switch (module) {
      case this.APPLICATIONS.sy.name:
        projects = this.symapProjects;
        break;
      case this.APPLICATIONS.di.name:
        projects = this.dimapProjects;
        break;
      case this.APPLICATIONS.vfze.name:
        projects = this.vfzeProjects;
        break;
      case this.APPLICATIONS.pk.name:
        projects = this.promapProjects;
        break;
      default:
    }

    if (!projects) {
      this.loadProjects(module).then(() => {
        this.getProjects(module).then(data => defered.resolve(data));
      });
    } else {
      defered.resolve(projects);
    }

    return defered.promise;
  }

  setUser(userId, data) {
    if (!data) {
      delete this.users[userId];
    } else {
      this.users[userId] = data;
    }
  }

  getUser(userId) {
    const defered = this.$q.defer();
    let user = this.users[userId];

    if (!user) {
      this.loadUser(userId).then(() => {
        this.getUser(userId).then(data => defered.resolve(data));
      });
    } else {
      defered.resolve(user);
    }

    return defered.promise;
  }

  emptyUserPermissions(userId) {
    this.userPermissions[userId] = {};
  }

  setUserPermissions(module, userId, permissions) {
    if(!this.userPermissions[userId]) {
      this.emptyUserPermissions(userId);
    }

    this.userPermissions[userId][module] = permissions;
  }

  async updateUserPermissions(module, userId, projectKey, permissions) {
    const roles = await this.getModuleRoles(module);
    const role = permissions && permissions.projectRole && roles.find(role => role.role === permissions.projectRole);
    const rolePermissions = role && role.permissions || [];
    const modulePermissions = await this.getPermissions(module);

    const newPermissions = rolePermissions
      .concat(permissions.permissions)
      .map((perm) => {
        const permissionDetail = modulePermissions.find(p => p.name === perm);

        return {
          application: module,
          projectKey,
          permission: permissionDetail.name,
          permissionId: permissionDetail.id,
        };
      });

    if(!this.userPermissions[userId]) {
      this.userPermissions[userId] = {};
    }
    if(!this.userPermissions[userId][module]) {
      this.userPermissions[userId][module] = {};
    }

    this.userPermissions[userId][module][projectKey] = newPermissions;

    return this.userPermissions[userId][module][projectKey];
  }

  getUserPermissions(module, userId) {
    const defered = this.$q.defer();
    const permissions = this.userPermissions[userId];

    if (!permissions || !permissions[module]) {
      this.loadUserPermissions(module, userId).then(() => {
        this.getUserPermissions(module, userId).then(data => defered.resolve(data));
      });
    } else {
      defered.resolve(permissions[module]);
    }

    return defered.promise;
  }

  approveTerms(userId, approvedModule) {
    return this.getUserApprovedTerms(userId).then((terms) => {
      terms.push(approvedModule);
      return this.GlobalRestangularSettingsProvider.all(`users/user/${userId}/terms-conditions`).customPUT({approvedTermsApplication: approvedModule});
    });
  }

  getApproveTermsConditions(module) {
    const defered = this.$q.defer();
    const termsConditions = this.termsConditions[module];

    if (!termsConditions) {
      this.loadTermsConditions(module).then(() => {
        this.getApproveTermsConditions(module).then(data => defered.resolve(data));
      });
    } else {
      defered.resolve(termsConditions);
    }

    return defered.promise;
  }

  getUserApprovedTerms(userId) {
    const defered = this.$q.defer();
    const approvedTerms = this.userApprovedTerms[userId];

    if (!approvedTerms) {
      this.loadUserApprovedTerms(userId).then(() => {
        this.getUserApprovedTerms(userId).then(data => defered.resolve(data));
      });
    } else {
      defered.resolve(approvedTerms);
    }

    return defered.promise;
  }

  sendUserEmail(userId, email) {
    return this.GlobalRestangularSettingsProvider
      .all(`users/user/${userId}/send-email`)
      .customPOST(email);
  }

  getNotificationConfigs(module, projectKey, userId) {
    const filter = { filter: { filters: {
      application: { values: [module] },
      projectKey: { values: [projectKey] },
    }}};

    if (userId) {
      filter.filter.filters.userId = { values: [ userId ]};
    }

    return this.GlobalRestangularSettingsProvider
      .all('notification-configurations')
      .customPOST(filter)
      .then(r => r.plain());
  }

  uploadNotificationConfig(config) {
    return this.GlobalRestangularSettingsProvider
      .one('notification-configurations')
      .customPUT(config)
      .then(r => r.plain());
  }

  uploadProjectNotificationConfigs(projectKey, configs) {
    return this.GlobalRestangularSettingsProvider
      .one(`notification-configurations/${projectKey}`)
      .customPUT(configs);
  }
}

ModulesService.$inject = ['Restangular', '$q', 'APPLICATIONS', 'GlobalRestangularSYMAPProvider', 'GlobalRestangularDIMAPProvider', 'GlobalRestangularSettingsProvider', 'GlobalRestangularVFZEProvider'];

export default ModulesService;
