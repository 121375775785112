"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var dialog_config_1 = require("@app/dialog/dialog-config");
var dialog_ref_1 = require("@app/dialog/dialog-ref");
var StatisticsCustomFormComponent = /** @class */ (function () {
    function StatisticsCustomFormComponent(restangular, dialogConfig, dialogComponent) {
        this.restangular = restangular;
        this.dialogConfig = dialogConfig;
        this.dialogComponent = dialogComponent;
        this.data = {};
        this.onSave = this.onSave.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }
    StatisticsCustomFormComponent.prototype.ngOnInit = function () {
        var statistics = this.dialogConfig.data.statistics;
        var filter = this.dialogConfig.data.filter;
        this.data.constructionObjectId = filter.constructionObjectId;
        this.data.constructionObjectStageId = filter.constructionObjectStageId;
        this.data.areaId = filter.areaId;
        this.data.name = statistics.name;
        this.data.statisticsId = statistics.id;
    };
    StatisticsCustomFormComponent.prototype.onSave = function () {
        var _this = this;
        return this.restangular.all('statistics').one('create').customPOST(this.data).then(function (data) {
            _this.dialogComponent.close(data.plain());
        });
    };
    StatisticsCustomFormComponent.prototype.onCancel = function () {
        this.dialogComponent.close(false);
    };
    return StatisticsCustomFormComponent;
}());
exports.StatisticsCustomFormComponent = StatisticsCustomFormComponent;
