"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var username_pipe_1 = require("@app/common/pipes/username.pipe");
var pageable_list_service_1 = require("@app/common/services/pageable.list.service");
var auth_service_1 = require("@app/common/services/auth.service");
var file_saver_1 = require("file-saver");
var VfzeListComponent = /** @class */ (function () {
    function VfzeListComponent(helpIds, restangularSettings, restangular, userNamePipe, pageableListService, authService) {
        this.helpIds = helpIds;
        this.restangularSettings = restangularSettings;
        this.restangular = restangular;
        this.userNamePipe = userNamePipe;
        this.pageableListService = pageableListService;
        this.authService = authService;
        this.defaultFilter = {
            filters: {
                searchText: { values: [{ id: '' }] },
            }
        };
        this.onGenerateFromProject = this.onGenerateFromProject.bind(this);
    }
    VfzeListComponent.prototype.ngOnInit = function () {
        this.initializeList();
    };
    VfzeListComponent.prototype.uiOnParamsChanged = function (changedParams, $transition$) {
        this.pageableList.uiOnParamsChanged(changedParams, $transition$);
    };
    VfzeListComponent.prototype.onGenerateFromProject = function () {
        var _this = this;
        return this.restangular.one("vfze/generate").withHttpConfig({
            responseType: 'blob',
            transformResponse: function (response, header) {
                return [response, header];
            }
        }).get().then(function (response) {
            file_saver_1.saveAs(response[0], _this.authService.getActualProject().key + '.vfze', true);
        });
    };
    VfzeListComponent.prototype.initializeList = function () {
        this.pageableList = this.pageableListService.get('vfze', this.defaultFilter);
        this.list = this.pageableList.list;
        this.pageableList.load();
    };
    return VfzeListComponent;
}());
exports.VfzeListComponent = VfzeListComponent;
