"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var list_service_1 = require("@app/common/services/list.service");
var subject_name_pipe_1 = require("@app/common/pipes/subject-name.pipe");
var dialog_config_1 = require("@app/dialog/dialog-config");
var dialog_ref_1 = require("@app/dialog/dialog-ref");
var NoteFormComponent = /** @class */ (function () {
    function NoteFormComponent(restangular, dialogConfig, dialogComponent, listService, subjectNamePipe) {
        this.restangular = restangular;
        this.dialogConfig = dialogConfig;
        this.dialogComponent = dialogComponent;
        this.listService = listService;
        this.subjectNamePipe = subjectNamePipe;
        this.onCreate = this.onCreate.bind(this);
        this.onUpdate = this.onUpdate.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }
    NoteFormComponent.prototype.ngOnInit = function () {
        this.note = this.dialogConfig.data.note;
        this.caseId = this.dialogConfig.data.caseId || (this.note.businessCase ? this.note.businessCase.id : undefined);
        this.allowType = this.dialogConfig.data.allowType && this.caseId !== undefined;
        this.title = (this.note.id ? "Editace poznámky" : "Nová poznámka");
        this.onTypeChange({
            newValue: this.note.noteType,
        });
    };
    NoteFormComponent.prototype.onTypeChange = function (data) {
        var _this = this;
        if (data.newValue && data.newValue.key === 'PriceRequest') {
            if (this.caseId) {
                if (!this.ownershipsList) {
                    this.ownershipsList = this.listService.createList('ownerships', {
                        limit: null,
                        filters: {
                            caseId: this.caseId,
                        },
                    }, undefined, {
                        subject: {
                            load: true,
                        },
                    });
                    this.listService.fetchResult(this.ownershipsList).then(function (data) {
                        _this.subjects = data.list.map(function (ownership) { return ownership.subject; });
                    });
                }
                this.ownershipsList.promise.then(function () {
                    if (_this.subjects.length === 1) {
                        _this.note.subject = _this.subjects[0];
                    }
                });
            }
        }
        else if (this.caseId) {
            delete this.note.subject;
        }
    };
    NoteFormComponent.prototype.onCreate = function () {
        var _this = this;
        return this.restangular.all('notes/create').post(this.note).then(function (data) {
            _this.dialogComponent.close(data.plain());
        });
    };
    NoteFormComponent.prototype.onUpdate = function () {
        var _this = this;
        return this.restangular.one("notes", this.note.id).customPUT(this.note).then(function (data) {
            _this.dialogComponent.close(data.plain());
        });
    };
    NoteFormComponent.prototype.onCancel = function () {
        this.dialogComponent.close(false);
    };
    return NoteFormComponent;
}());
exports.NoteFormComponent = NoteFormComponent;
