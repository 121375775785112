"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var role_enum_1 = require("@app/common/enums/role.enum");
var i0 = require("@angular/core");
var RolePipe = /** @class */ (function () {
    function RolePipe() {
    }
    RolePipe.prototype.transform = function (value) {
        if (!value) {
            return '';
        }
        var option = typeof value === 'string'
            ? role_enum_1.roleOptions.find(function (option) { return option.id === value; })
            : role_enum_1.roleOptions.find(function (option) { return option.id === value.id; });
        return option.name;
    };
    RolePipe.ngInjectableDef = i0.defineInjectable({ factory: function RolePipe_Factory() { return new RolePipe(); }, token: RolePipe, providedIn: "root" });
    return RolePipe;
}());
exports.RolePipe = RolePipe;
