"use strict";
var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
Object.defineProperty(exports, "__esModule", { value: true });
var VfzeUtils = /** @class */ (function () {
    function VfzeUtils() {
    }
    VfzeUtils.isValidationResponseSuccess = function (validationResponse) {
        if (!validationResponse) {
            return false;
        }
        var xsdValidationPassed = validationResponse.xsdValidationPassed, modelDeserializationPassed = validationResponse.modelDeserializationPassed, errors = validationResponse.errors, fatal = validationResponse.fatal;
        return !!(xsdValidationPassed
            && modelDeserializationPassed
            && errors.length === 0
            && fatal.length === 0);
    };
    VfzeUtils.validationResponseToText = function (validationResponse) {
        var e_1, _a;
        if (!validationResponse) {
            return '';
        }
        // header
        var valid = VfzeUtils.isValidationResponseSuccess(validationResponse);
        var mainMsg = (valid ? 'Validace úspěšně dokončena, bez chyb.' : 'Validace skončila neúspěšně.') + '\n\n';
        var validationMsg = validationResponse.validationMessage ? validationResponse.validationMessage + '\n\n' : '';
        // warnings, errors
        var content = validationResponse.fatal.map(function (problem) { return problem.message; })
            .concat(validationResponse.errors.map(function (problem) { return problem.message; }))
            .concat(validationResponse.warnings.map(function (problem) { return problem.message; })).join('\n');
        // summary
        var objectCounts = '';
        if (validationResponse.occupationSummary) {
            try {
                for (var _b = __values(Object.keys(VfzeUtils.OCCUPATION_SUMMARY_TRANSLATE)), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var key = _c.value;
                    if (validationResponse.occupationSummary[key] !== undefined) {
                        objectCounts += VfzeUtils.OCCUPATION_SUMMARY_TRANSLATE[key] + ': ' + validationResponse.occupationSummary[key] + ' m2\n';
                    }
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_1) throw e_1.error; }
            }
        }
        if (validationResponse.easementArea !== undefined && validationResponse.easementArea !== null) {
            objectCounts += 'věcné břemeno: ' + validationResponse.easementArea + ' m2\n';
        }
        objectCounts = objectCounts ? ('\n\nKontrolní součty:\n' + objectCounts) : '';
        return mainMsg + validationMsg + content + objectCounts;
    };
    VfzeUtils.OCCUPATION_SUMMARY_TRANSLATE = {
        TRVALY: 'trvalý zábor',
        TRVALY_BEZ_VYKUPU: 'trvalý zábor bez výkupu',
        DOCASNY: 'dočasný zábor nad 1 rok',
        DOCASNY_DO_ROKU: 'dočasný zábor do 1 roku',
        JINE_DOTCENI: 'jiné dotčení',
        VYVEDENI: 'vyvedení',
        ZBYTKOVA_CAST_PARCELY: 'zbytková část parcely'
    };
    return VfzeUtils;
}());
exports.VfzeUtils = VfzeUtils;
