"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./itemscounter.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("./itemscounter.component");
var i3 = require("../../services/list.service");
var styles_ItemsCounterComponent = [i0.styles];
var RenderType_ItemsCounterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ItemsCounterComponent, data: {} });
exports.RenderType_ItemsCounterComponent = RenderType_ItemsCounterComponent;
function View_ItemsCounterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "span", [["class", "items-counter"]], [[8, "title", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵted(3, null, [" ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "Po\u010Det ", _co.items, " v tabulce"); _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.itemsCount; _ck(_v, 2, 0, currVal_1); var currVal_2 = _co.items; _ck(_v, 3, 0, currVal_2); }); }
exports.View_ItemsCounterComponent_0 = View_ItemsCounterComponent_0;
function View_ItemsCounterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "itemscounter", [], null, null, null, View_ItemsCounterComponent_0, RenderType_ItemsCounterComponent)), i1.ɵdid(1, 114688, null, 0, i2.ItemsCounterComponent, [i3.ListService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_ItemsCounterComponent_Host_0 = View_ItemsCounterComponent_Host_0;
var ItemsCounterComponentNgFactory = i1.ɵccf("itemscounter", i2.ItemsCounterComponent, View_ItemsCounterComponent_Host_0, { list: "list" }, { callbackRegister: "callbackRegister" }, []);
exports.ItemsCounterComponentNgFactory = ItemsCounterComponentNgFactory;
