"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var acting_person_model_1 = require("@app/ps/models/acting-person.model");
var auth_service_1 = require("@app/common/services/auth.service");
var common_address_model_1 = require("@app/ps/models/common-address.model");
var dialog_config_1 = require("@app/dialog/dialog-config");
var dialog_ref_1 = require("@app/dialog/dialog-ref");
var enum_utils_1 = require("@app/common/utils/enum.utils");
var genders_enum_1 = require("@app/ps/enums/genders.enum");
var marital_statuses_enum_1 = require("@app/ps/enums/marital-statuses.enum");
var subject_service_1 = require("@app/ps/services/subject.service");
var FormCaseUpdateSubjectComponent = /** @class */ (function () {
    function FormCaseUpdateSubjectComponent(authService, config, dialog, restangular, confirmService, subjectService) {
        this.authService = authService;
        this.dialog = dialog;
        this.restangular = restangular;
        this.confirmService = confirmService;
        this.subjectService = subjectService;
        this.loading = true;
        this.subjectFields = {};
        this.ownershipFields = {};
        this.hasSubjectFieldsToDisplay = false;
        this.hasOwnershipFieldsToDisplay = false;
        this.availableSubjectFields = [
            'bonusPrice',
            'declinedDate',
            'personalVisitDate',
            'receivedDate',
            'responseReceivedDate',
            'sentDate',
            'signatureVerificationPrice',
            'signedDate',
            'undeliveredDate',
            'meetingRequestDate',
            'acceptedGeometricalPlanPossibleDate',
            'callBeforeExpropriationDate',
            'dunningLetterDate',
            'callBeforeExpropriationReceivedDate',
            'expropriationCaseInProgressDate',
            'expropriationCaseStoppedDate',
            'expropriationCaseFinishedDate',
        ];
        this.caseSubjectId = config.data.caseSubjectId;
        this.caseOwnershipId = config.data.caseOwnershipId;
        this.onSubmit = this.onSubmit.bind(this);
        this.onSubmitAll = this.onSubmitAll.bind(this);
        this.onSubmitCase = this.onSubmitCase.bind(this);
        this.onCreateAndAddRepresentative = this.onCreateAndAddRepresentative.bind(this);
        this.onRemoveRepresentative = this.onRemoveRepresentative.bind(this);
        this.removeRoleCadastreForAllExcept = this.removeRoleCadastreForAllExcept.bind(this);
    }
    FormCaseUpdateSubjectComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.restangular
                                .one('case-subjects', this.caseSubjectId)
                                .get({ loadCollections: ['businessCase', 'actingPersons'] })
                                .then(function (data) {
                                return __assign({}, data.plain(), { actingPersons: data.actingPersons.map(acting_person_model_1.ActingPersonModel.fromFlatObject) });
                            })];
                    case 1:
                        _a.caseSubject = _c.sent();
                        this.subjectDetail = this.caseSubject.subject.subjectDetail ? this.caseSubject.subject.subjectDetail : {};
                        _b = this;
                        return [4 /*yield*/, this.restangular.one('case-ownerships', this.caseOwnershipId).get()];
                    case 2:
                        _b.caseOwnership = (_c.sent()).plain();
                        this.MARITAL_STATUSES = enum_utils_1.EnumUtils.toObject(marital_statuses_enum_1.MaritalStatusesEnum, 'id', 'name');
                        this.GENDERS = enum_utils_1.EnumUtils.toObject(genders_enum_1.GendersEnum, 'id', 'name');
                        this.subjectFields = this.getDisplayedSubjectFields();
                        this.ownershipFields = this.getDisplayedOwnershipFields();
                        this.hasSubjectFieldsToDisplay = Object.keys(this.subjectFields).length > 0;
                        this.hasOwnershipFieldsToDisplay = Object.keys(this.ownershipFields).length > 0;
                        this.address = this.normalizeAddressFromSubject();
                        this.basicInfo = this.normalizeBasicInfoFromSubject();
                        this.mailingAddress = this.normalizeMailingAddressFromSubject();
                        this.contactPerson = this.normalizeContactPerson();
                        this.initializeActingPersonWhenEmpty();
                        this.shouldShowPaymentForm = !this.caseOwnership.ownership.isBsm || this.caseOwnership.singlePayment;
                        this.loading = false;
                        this.basicInfoBefore = __assign({}, this.basicInfo);
                        this.addressBefore = __assign({}, this.address);
                        this.mailingAddressBefore = __assign({}, this.mailingAddress);
                        this.contactBefore = __assign({
                            phoneNumber: this.caseSubject.phoneNumber,
                            email: this.caseSubject.email,
                            databoxId: this.caseSubject.databoxId
                        });
                        this.contactPersonBefore = __assign({}, this.contactPerson);
                        return [2 /*return*/];
                }
            });
        });
    };
    FormCaseUpdateSubjectComponent.prototype.onSubmit = function () {
        var _this = this;
        if (this.isValidForOwnerUpdate()) {
            return new this.confirmService({
                title: 'Uložení změn',
                message: 'Uložení změn. Chcete uložit změny údajů nejen u tohoto případu, ale i v detailu vlastníka?'
            }).then(function (data) {
                if (data === true) {
                    return _this.onSubmitAll();
                }
                else {
                    return _this.onSubmitCase();
                }
            });
        }
        return this.onSubmitCase();
    };
    FormCaseUpdateSubjectComponent.prototype.onSubmitAll = function () {
        this.assembleSubjectDetail();
        return this.restangular
            .one("subjects/" + this.caseSubject.subject.id + "/detail")
            .customPUT(this.subjectDetail)
            .then(this.onSubmitCase);
    };
    FormCaseUpdateSubjectComponent.prototype.onSubmitCase = function () {
        var _this = this;
        this.assembleOwner();
        var caseSubject = __assign({}, this.caseSubject, { actingPersons: this.caseSubject.actingPersons.map(acting_person_model_1.ActingPersonModel.toFlatObject) });
        return this.restangular
            .one('cases', this.caseSubject.businessCase.id)
            .all('ownerships-subjects')
            .customPUT({
            caseOwnerships: [this.caseOwnership],
            caseSubjects: [caseSubject],
        })
            .then(function (data) {
            data = data.plain();
            var caseSubject = __assign({}, data.caseSubjects[0], { actingPersons: data.caseSubjects[0].actingPersons.map(acting_person_model_1.ActingPersonModel.fromFlatObject) });
            _this.dialog.close({
                caseSubject: caseSubject,
                caseOwnership: data.caseOwnerships[0],
            });
        });
    };
    /*
     * If there is at least one representative with any of its properties set,
     * his/her firstname, surname and ico (when legal entity) have to be set as well.
     */
    FormCaseUpdateSubjectComponent.prototype.hasValidForm = function () {
        return this.caseSubject.actingPersons
            .filter(acting_person_model_1.ActingPersonModel.hasAtLeastOneDefinedProperty)
            .every(function (r) {
            return (r.firstName
                && r.surname
                && (r.companyName ? r.identificationNumber : true));
        });
    };
    FormCaseUpdateSubjectComponent.prototype.isValidForOwnerUpdate = function () {
        var contact = {
            phoneNumber: this.caseSubject.phoneNumber,
            email: this.caseSubject.email,
            databoxId: this.caseSubject.databoxId
        };
        var basicInfoDiff = JSON.stringify(this.basicInfoBefore) !== JSON.stringify(this.basicInfo);
        var mailAddressDiff = (JSON.stringify(this.mailingAddressBefore) !== JSON.stringify(this.mailingAddress)) || (this.subjectDetail.dorucovaciUse !== this.caseSubject.dorucovaciUse);
        var addressDiff = (JSON.stringify(this.addressBefore) !== JSON.stringify(this.address));
        var contactPersonDiff = JSON.stringify(this.contactPersonBefore) !== JSON.stringify(this.contactPerson);
        var contactDiff = JSON.stringify(this.contactBefore) !== JSON.stringify(contact);
        return basicInfoDiff || mailAddressDiff || contactPersonDiff || contactDiff || addressDiff;
    };
    FormCaseUpdateSubjectComponent.prototype.removeRoleCadastreForAllExcept = function (representative) {
        this.caseSubject.actingPersons = this.caseSubject.actingPersons
            .map(function (r) {
            if (representative !== r && representative.roleCadastre) {
                r.roleCadastre = false;
            }
            return __assign({}, r);
        });
    };
    FormCaseUpdateSubjectComponent.prototype.onRemoveRepresentative = function (representative) {
        var _this = this;
        return function () {
            var idx = _this.caseSubject.actingPersons.indexOf(representative);
            if (idx !== -1) {
                _this.caseSubject.actingPersons.splice(idx, 1);
            }
            if (_this.caseSubject.actingPersons.length === 0) { // don't let the form disappear
                _this.onCreateAndAddRepresentative();
            }
        };
    };
    FormCaseUpdateSubjectComponent.prototype.shouldShowDeleteButton = function () {
        return (this.caseSubject.actingPersons.length > 1 || (this.caseSubject.actingPersons.length === 1 && this.caseSubject.actingPersons[0].id));
    };
    FormCaseUpdateSubjectComponent.prototype.onToggleAddress = function (addressUsed) {
        this.subjectDetail.customAddressUse = addressUsed;
    };
    FormCaseUpdateSubjectComponent.prototype.onToggleMailingAddress = function (addressUsed) {
        this.caseSubject.dorucovaciUse = addressUsed;
    };
    FormCaseUpdateSubjectComponent.prototype.onCreateAndAddRepresentative = function () {
        this.caseSubject.actingPersons.push(new acting_person_model_1.ActingPersonModel());
    };
    FormCaseUpdateSubjectComponent.prototype.assembleOwner = function () {
        Object.assign(this.caseSubject, this.denormalizeMailingAddress());
        Object.assign(this.caseSubject, this.denormalizeSubjectAddress());
        Object.assign(this.caseSubject, this.denormalizeContactPerson());
        Object.assign(this.caseSubject, this.denormalizeBasicInfo());
        this.caseSubject.actingPersons = this.caseSubject.actingPersons
            .filter(acting_person_model_1.ActingPersonModel.hasAtLeastOneDefinedProperty);
        if (this.caseSubject.identificationNumber === '') {
            this.caseSubject.identificationNumber = undefined;
        }
    };
    FormCaseUpdateSubjectComponent.prototype.assembleSubjectDetail = function () {
        Object.assign(this.subjectDetail, this.denormalizeSubjectAddress());
        Object.assign(this.subjectDetail, this.denormalizeBasicInfo());
        Object.assign(this.subjectDetail, this.denormalizeMailingAddress());
        Object.assign(this.subjectDetail, this.denormalizeContactPerson());
        this.subjectDetail.phoneNumber = this.caseSubject.phoneNumber;
        this.subjectDetail.email = this.caseSubject.email;
        this.subjectDetail.databoxId = this.caseSubject.databoxId;
        this.subjectDetail.dorucovaciUse = this.caseSubject.dorucovaciUse;
        if (!this.subjectDetail.customAddressUse) {
            this.subjectDetail.customAddressUse = false;
        }
        if (this.subjectDetail.identificationNumber === '') {
            this.subjectDetail.identificationNumber = undefined;
        }
    };
    FormCaseUpdateSubjectComponent.prototype.initializeActingPersonWhenEmpty = function () {
        if (this.caseSubject.actingPersons.length === 0) {
            this.caseSubject.actingPersons.push(new acting_person_model_1.ActingPersonModel());
        }
    };
    // basic info
    FormCaseUpdateSubjectComponent.prototype.normalizeBasicInfoFromSubject = function () {
        return this.subjectService.getAdaptedBasicInfo(this.caseSubject);
    };
    FormCaseUpdateSubjectComponent.prototype.denormalizeBasicInfo = function () {
        return this.subjectService.getSubjectCompatibleBasicInfo(this.basicInfo);
    };
    // address
    FormCaseUpdateSubjectComponent.prototype.normalizeAddressFromSubject = function () {
        return this.subjectService.getAdaptedAddress(this.caseSubject);
    };
    FormCaseUpdateSubjectComponent.prototype.denormalizeSubjectAddress = function () {
        return this.subjectService.getSubjectCompatibleAddress(this.address);
    };
    // mailing address
    FormCaseUpdateSubjectComponent.prototype.normalizeMailingAddressFromSubject = function () {
        return this.subjectService.getAdaptedMailingAddress(this.caseSubject);
    };
    FormCaseUpdateSubjectComponent.prototype.denormalizeMailingAddress = function () {
        return common_address_model_1.CommonAddressModel.toMailingAddress(this.mailingAddress);
    };
    // contact person
    FormCaseUpdateSubjectComponent.prototype.normalizeContactPerson = function () {
        return this.subjectService.getAdaptedContactPerson(this.caseSubject);
    };
    FormCaseUpdateSubjectComponent.prototype.denormalizeContactPerson = function () {
        return this.subjectService.getSubjectCompatibleContactPerson(this.contactPerson);
    };
    FormCaseUpdateSubjectComponent.prototype.userCanUpdateCase = function () {
        return this.authService.canUpdateCaseData();
    };
    FormCaseUpdateSubjectComponent.prototype.userCanUpdateCasePrice = function () {
        return this.authService.canUpdateCasePrice();
    };
    FormCaseUpdateSubjectComponent.prototype.subjectHasBonusDate = function () {
        return this.caseSubject.businessCase.bonusDate;
    };
    FormCaseUpdateSubjectComponent.prototype.isCommunityProperty = function () {
        return this.caseOwnership.ownership.isBsm;
    };
    FormCaseUpdateSubjectComponent.prototype.getDisplayedOwnershipFields = function () {
        var fields = {};
        if (this.easementPriceByShareEnabled() && !this.isCommunityProperty()) {
            if (this.isRealBurdenLiability()) {
                fields['easementFutureContractNumber'] = true;
                fields['easementFutureContractStatus'] = true;
            }
            if (this.userCanUpdateCasePrice()) {
                fields['easementPriceByShare'] = true;
            }
        }
        return fields;
    };
    FormCaseUpdateSubjectComponent.prototype.getDisplayedSubjectFields = function () {
        var _this = this;
        var fields = {};
        var isDefinedAndZero = function (field) { return _this.caseSubject[field] || _this.caseSubject[field] === 0; };
        var bonusPriceEnabled = function (field) { return !!(field !== 'bonusPrice' || (_this.subjectHasBonusDate() && field === 'bonusPrice')); };
        var showField = function (field) { return fields[field] = true; };
        if (!this.userCanUpdateCase()) {
            return fields;
        }
        this.availableSubjectFields
            .filter(isDefinedAndZero)
            .filter(bonusPriceEnabled)
            .forEach(showField);
        return fields;
    };
    FormCaseUpdateSubjectComponent.prototype.isRealBurdenLiability = function () {
        return this.caseSubject.businessCase.obligation.type === 'RealBurdenLiability';
    };
    FormCaseUpdateSubjectComponent.prototype.easementPriceByShareEnabled = function () {
        return this.authService.getActualProject().easementPriceByShare;
    };
    return FormCaseUpdateSubjectComponent;
}());
exports.FormCaseUpdateSubjectComponent = FormCaseUpdateSubjectComponent;
