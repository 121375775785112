"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var pageable_list_service_1 = require("@app/common/services/pageable.list.service");
var user_edit_component_1 = require("@app/common/components/user-edit/user-edit.component");
var dialog_service_1 = require("@app/dialog/dialog.service");
var UserListComponent = /** @class */ (function () {
    function UserListComponent(helpIds, APP_BRAND, applications, settingsRestangular, pageableListService, dialogService) {
        this.helpIds = helpIds;
        this.APP_BRAND = APP_BRAND;
        this.applications = applications;
        this.settingsRestangular = settingsRestangular;
        this.pageableListService = pageableListService;
        this.dialogService = dialogService;
        this.selectModules = [
            { name: this.applications.sy.title, id: this.applications.sy.name },
            { name: this.applications.di.title, id: this.applications.di.name },
            { name: this.applications.pk.title, id: this.applications.pk.name },
            { name: this.applications.dashboard.title, id: this.applications.dashboard.name },
            { name: this.applications.vfze.title, id: this.applications.vfze.name },
            { name: 'Nic', id: '' },
        ];
        this.uiOnParamsChanged = this.uiOnParamsChanged.bind(this);
        this.onUsersSync = this.onUsersSync.bind(this);
        this.onCreate = this.onCreate.bind(this);
    }
    UserListComponent.prototype.ngOnInit = function () {
        this.initializeList();
    };
    UserListComponent.prototype.uiOnParamsChanged = function (changedParams, $transition$) {
        this.pageableList.uiOnParamsChanged(changedParams, $transition$);
    };
    UserListComponent.prototype.onUsersSync = function () {
        return this.settingsRestangular.one("users/broadcast-users-update").post();
    };
    UserListComponent.prototype.onCreate = function () {
        var _this = this;
        var dialog = this.dialogService.open(user_edit_component_1.UserEditComponent, { data: {} });
        var sub = dialog.afterClosed.subscribe(function (res) {
            if (res) {
                _this.pageableList.fetchResults();
            }
            sub.unsubscribe();
        });
    };
    UserListComponent.prototype.initializeList = function () {
        this.pageableList = this.pageableListService.get('users/list', this.getDefaultFilter(), 'settingsUsersFilter');
        this.list = this.pageableList.list;
        this.pageableList.load();
    };
    UserListComponent.prototype.getDefaultFilter = function () {
        return {
            filters: {
                searchText: {
                    values: [{ id: '' }]
                },
                applications: {},
            }
        };
    };
    return UserListComponent;
}());
exports.UserListComponent = UserListComponent;
