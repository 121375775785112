"use strict";
var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var NewMapConfigService = /** @class */ (function () {
    function NewMapConfigService(APPLICATIONS, globalRestangularSettingsProvider) {
        this.APPLICATIONS = APPLICATIONS;
        this.globalRestangularSettingsProvider = globalRestangularSettingsProvider;
        this.layersConfigs = {};
    }
    NewMapConfigService.prototype.setModuleLayers = function (module, projectKey, layers) {
        this.layersConfigs[module] = this.layersConfigs[module] || {};
        this.layersConfigs[module][projectKey] = layers;
        return this.layersConfigs[module][projectKey];
    };
    NewMapConfigService.prototype.getLayerById = function (layerId, layerGroups) {
        var e_1, _a;
        var lookIn = function (layers) {
            var e_2, _a;
            var layer = layers.find(function (l) { return l.id === layerId; });
            if (layer) {
                return layer;
            }
            try {
                for (var layers_1 = __values(layers), layers_1_1 = layers_1.next(); !layers_1_1.done; layers_1_1 = layers_1.next()) {
                    var layer2 = layers_1_1.value;
                    if (layer2.layers) {
                        layer = lookIn(layer2.layers);
                        if (layer) {
                            return layer;
                        }
                    }
                }
            }
            catch (e_2_1) { e_2 = { error: e_2_1 }; }
            finally {
                try {
                    if (layers_1_1 && !layers_1_1.done && (_a = layers_1.return)) _a.call(layers_1);
                }
                finally { if (e_2) throw e_2.error; }
            }
            return null;
        };
        try {
            for (var layerGroups_1 = __values(layerGroups), layerGroups_1_1 = layerGroups_1.next(); !layerGroups_1_1.done; layerGroups_1_1 = layerGroups_1.next()) {
                var group = layerGroups_1_1.value;
                var layer = lookIn(group.layers);
                if (layer) {
                    return layer;
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (layerGroups_1_1 && !layerGroups_1_1.done && (_a = layerGroups_1.return)) _a.call(layerGroups_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return null;
    };
    NewMapConfigService.prototype.loadModuleLayers = function (module, projectKey) {
        var _this = this;
        switch (module) {
            case this.APPLICATIONS.sy.name:
                return this.globalRestangularSettingsProvider.one("application/" + this.APPLICATIONS.sy.name + "/project/" + projectKey + "/map").get().then(function (data) {
                    var result = {
                        type: 'node',
                        selector: 'hidden',
                        layers: data
                    };
                    _this.setModuleLayers(module, projectKey, result);
                    return Promise.resolve(result);
                });
            case this.APPLICATIONS.di.name:
                // TODO unimplemented
                return Promise.resolve(null);
            default:
        }
    };
    NewMapConfigService.prototype.getLayersConfig = function (module, projectKey, fresh) {
        if (fresh === void 0) { fresh = false; }
        var loadedAlready = this.layersConfigs[module] && this.layersConfigs[module][projectKey];
        if (fresh || !loadedAlready) {
            return this.loadModuleLayers(module, projectKey).then(function (response) {
                return response;
            });
        }
        else {
            return Promise.resolve(this.layersConfigs[module][projectKey]);
        }
    };
    NewMapConfigService.ngInjectableDef = i0.defineInjectable({ factory: function NewMapConfigService_Factory() { return new NewMapConfigService(i0.inject("APPLICATIONS"), i0.inject("GlobalRestangularSettingsProvider")); }, token: NewMapConfigService, providedIn: "root" });
    return NewMapConfigService;
}());
exports.NewMapConfigService = NewMapConfigService;
