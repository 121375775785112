"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var L = require("leaflet");
var _ = require("lodash");
var map_service_1 = require("@app/map/services/map.service");
var i0 = require("@angular/core");
var i1 = require("./map.service");
var MapHighlightService = /** @class */ (function () {
    function MapHighlightService(mapService) {
        this.mapService = mapService;
        // Keep track of active highlights
        this.layers = {};
        // Keep track of active listeners
        this.listeners = {};
        this.layeraddListenerFactory = this.layeraddListenerFactory.bind(this);
        this.highlightExists = this.highlightExists.bind(this);
        this.highlightFeature = this.highlightFeature.bind(this);
        this.removeHighlight = this.removeHighlight.bind(this);
    }
    MapHighlightService.prototype.layeraddListenerFactory = function (mapId) {
        var _this = this;
        return function () {
            if (_this.layers[mapId]) {
                _this.layers[mapId].bringToFront();
            }
        };
    };
    MapHighlightService.prototype.highlightExists = function (mapId) {
        return !!this.layers[mapId];
    };
    MapHighlightService.prototype.highlightFeature = function (mapId, feature, fitBoundsOptions) {
        if (fitBoundsOptions === void 0) { fitBoundsOptions = {}; }
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (typeof feature === 'string') {
                            feature = JSON.parse(feature);
                        }
                        return [4 /*yield*/, this.removeHighlight(mapId)];
                    case 1:
                        _a.sent();
                        this.mapService.getMap(mapId).then(function (map) {
                            feature.crs = {
                                type: 'name',
                                properties: {
                                    'name': 'urn:ogc:def:crs:EPSG::5514'
                                }
                            };
                            // Point and polygon style shares basic style properties, while point is shown as circle
                            var style = {
                                clickable: false,
                                fillColor: 'yellow',
                                fillOpacity: 0.29,
                                color: 'yellow',
                                weight: 2,
                                opacity: 1,
                            };
                            var geojsonMarkerOptions = _.extend({
                                radius: 8,
                            }, style);
                            var layerLeaflet = L.Proj.geoJson(feature, {
                                style: _.extend(style),
                                pointToLayer: function (feature, latlng) {
                                    var marker = L.circleMarker(latlng, geojsonMarkerOptions);
                                    return marker;
                                }
                            });
                            map.addLayer(layerLeaflet);
                            _this.layers[mapId] = layerLeaflet;
                            _this.listeners[mapId] = _this.layeraddListenerFactory(mapId);
                            if (fitBoundsOptions !== false) {
                                map.fitBounds(layerLeaflet.getBounds(), fitBoundsOptions);
                            }
                            map.on('layeradd', _this.listeners[mapId]);
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    MapHighlightService.prototype.removeHighlight = function (mapId) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                if (this.highlightExists(mapId)) {
                    return [2 /*return*/, this.mapService.getMap(mapId).then(function (map) {
                            if (_this.highlightExists(mapId)) {
                                map.off('layeradd', _this.listeners[mapId]);
                                map.removeLayer(_this.layers[mapId]);
                                _this.layers[mapId] = null;
                                _this.listeners[mapId] = null;
                            }
                        })];
                }
                return [2 /*return*/];
            });
        });
    };
    MapHighlightService.ngInjectableDef = i0.defineInjectable({ factory: function MapHighlightService_Factory() { return new MapHighlightService(i0.inject(i1.MapService)); }, token: MapHighlightService, providedIn: "root" });
    return MapHighlightService;
}());
exports.MapHighlightService = MapHighlightService;
