"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var SeriesModel = /** @class */ (function () {
    function SeriesModel() {
    }
    SeriesModel.createDefault = function () {
        return {
            series: {
                cancelled: false,
                available: true,
            },
            sections: [
                {
                    type: 'text',
                },
                {
                    type: 'order',
                    restart: true,
                    show: false,
                },
                {
                    type: 'year',
                    short: true,
                    show: false,
                },
                {
                    type: 'title',
                    show: false
                },
            ],
        };
    };
    return SeriesModel;
}());
exports.SeriesModel = SeriesModel;
