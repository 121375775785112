"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var construction_objects_states_1 = require("./construction-objects.states");
var STATES = [
    construction_objects_states_1.createCaseState,
    construction_objects_states_1.constructionObjectListState,
    construction_objects_states_1.constructionObjectDetailState,
    construction_objects_states_1.constructionObjectDetailUpdateState,
    construction_objects_states_1.updateGlobalEasementPrice,
    construction_objects_states_1.constructionObjectImportedListState,
    construction_objects_states_1.constructionObjectCreateState,
    construction_objects_states_1.easementCreateState,
];
var ConstructionObjectsModule = /** @class */ (function () {
    function ConstructionObjectsModule() {
    }
    return ConstructionObjectsModule;
}());
exports.ConstructionObjectsModule = ConstructionObjectsModule;
