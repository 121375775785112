"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var i0 = require("@angular/core");
var ProjectNamePipe = /** @class */ (function () {
    function ProjectNamePipe() {
    }
    ProjectNamePipe.prototype.transform = function (project) {
        var words = [];
        if (project.isprofond) {
            words.push('(' + project.isprofond + ')');
        }
        words.push(project.name);
        return words.join(' ');
    };
    ProjectNamePipe.ngInjectableDef = i0.defineInjectable({ factory: function ProjectNamePipe_Factory() { return new ProjectNamePipe(); }, token: ProjectNamePipe, providedIn: "root" });
    return ProjectNamePipe;
}());
exports.ProjectNamePipe = ProjectNamePipe;
