"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var table_service_1 = require("@app/ps/services/table.service");
var settings_service_1 = require("@app/ps/services/settings.service");
var username_pipe_1 = require("@app/common/pipes/username.pipe");
var auth_service_1 = require("@app/common/services/auth.service");
var TableTitlesComponent = /** @class */ (function () {
    function TableTitlesComponent(tableService, settingsService, authService, userNamePipe, restangularSettings, restangular) {
        this.tableService = tableService;
        this.settingsService = settingsService;
        this.authService = authService;
        this.userNamePipe = userNamePipe;
        this.restangularSettings = restangularSettings;
        this.restangular = restangular;
        this.showNavIcons = false;
        this.checkAll = new core_1.EventEmitter();
        this.sort = new core_1.EventEmitter();
        this.toggleCheckItem = new core_1.EventEmitter();
        this.TABLE_ID = 'titles';
        this.users = [];
        this.userSending = {};
    }
    TableTitlesComponent.prototype.ngOnInit = function () {
        var activeApplication = this.authService.getActiveApplication();
        var activeProjectKey = this.authService.getActualProject().key;
        this.mandataryFilter = {
            filters: {
                searchText: {
                    values: [],
                },
                applications: {
                    values: [activeApplication],
                },
                permission: {
                    values: ['assignable'],
                },
                projects: {
                    values: [activeProjectKey],
                },
            },
            sortOrder: [
                { sortBy: 'surname' },
                { sortBy: 'name' },
            ],
        };
        if (this.isChecked === undefined) {
            this.loadPossibleMandataryUsers();
        }
    };
    TableTitlesComponent.prototype.trackByFn = function (index, item) {
        return item['id'];
    };
    TableTitlesComponent.prototype.onCheckAll = function () {
        this.checkAll.emit();
    };
    TableTitlesComponent.prototype.onToggleCheckItem = function (title) {
        this.toggleCheckItem.emit(title);
    };
    TableTitlesComponent.prototype.onSort = function (column, ascOrDesc) {
        this.sort.emit({
            column: column,
            ascOrDesc: ascOrDesc,
        });
    };
    TableTitlesComponent.prototype.hideColumn = function (columnId) {
        if (this.customColumns) {
            return !this.customColumns.includes(columnId);
        }
        return this.settingsService.shouldHideColumn(this.TABLE_ID, columnId);
    };
    TableTitlesComponent.prototype.getColumnName = function (columnId) {
        return this.tableService.getColumnById(this.TABLE_ID, columnId).name;
    };
    TableTitlesComponent.prototype.loadPossibleMandataryUsers = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!this.authService.hasPermission('assignment_edit')) {
                            return [2 /*return*/];
                        }
                        _a = this;
                        return [4 /*yield*/, this.restangularSettings.all('users/list').customPOST({ filter: this.mandataryFilter })];
                    case 1:
                        _a.users = _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    TableTitlesComponent.prototype.updateUser = function (titleId, userId) {
        var _this = this;
        this.userSending[titleId] = true;
        this.restangular.one('titles', titleId)
            .customPUT({ id: userId }, 'user')
            .then(function () {
            _this.userSending[titleId] = false;
        });
    };
    return TableTitlesComponent;
}());
exports.TableTitlesComponent = TableTitlesComponent;
