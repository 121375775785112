"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./layer-symbol.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../../../../../../node_modules/ngx-color/chrome/ngx-color-chrome.ngfactory");
var i3 = require("ngx-color/chrome");
var i4 = require("@angular/common");
var i5 = require("./layer-symbol.component");
var styles_LayerSymbolComponent = [i0.styles];
var RenderType_LayerSymbolComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LayerSymbolComponent, data: {} });
exports.RenderType_LayerSymbolComponent = RenderType_LayerSymbolComponent;
function View_LayerSymbolComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "color-chrome", [["class", "ml-10"]], null, [[null, "onChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onChange" === en)) {
        var pd_0 = (_co.onColorChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ChromeComponent_0, i2.RenderType_ChromeComponent)), i1.ɵdid(1, 770048, null, 0, i3.ChromeComponent, [], { color: [0, "color"] }, { onChange: "onChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.style[_co.fillOrStroke]; _ck(_v, 1, 0, currVal_0); }, null); }
function View_LayerSymbolComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LayerSymbolComponent_2)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.fillOrStroke; _ck(_v, 2, 0, currVal_0); }, null); }
function View_LayerSymbolComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 5, ":svg:svg", [[":xmlns:xlink", "http://www.w3.org/1999/xlink"], ["xmlns", "http://www.w3.org/2000/svg"]], [[1, "width", 0], [1, "height", 0], [1, "viewBox", 0]], null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i4.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, ":svg:rect", [], [[1, "width", 0], [1, "height", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.interactive && _co.onColorpickerToggle("stroke")) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 278528, null, 0, i4.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, ":svg:rect", [], [[1, "width", 0], [1, "height", 0], [1, "x", 0], [1, "y", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.interactive && _co.onColorpickerToggle("fill")) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 278528, null, 0, i4.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LayerSymbolComponent_1)), i1.ɵdid(7, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.svgRootStyle; _ck(_v, 1, 0, currVal_3); var currVal_6 = _co.getStrokeStyle(); _ck(_v, 3, 0, currVal_6); var currVal_11 = _co.getFillStyle(); _ck(_v, 5, 0, currVal_11); var currVal_12 = _co.isColorpickerVisible; _ck(_v, 7, 0, currVal_12); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.svgRootStyle["width.px"], ""); var currVal_1 = i1.ɵinlineInterpolate(1, "", _co.svgRootStyle["height.px"], ""); var currVal_2 = i1.ɵinlineInterpolate(1, "", _co.svgViewBox, ""); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_4 = _co.getStrokeStyle()["width.px"]; var currVal_5 = _co.getStrokeStyle()["height.px"]; _ck(_v, 2, 0, currVal_4, currVal_5); var currVal_7 = _co.getFillStyle()["width.px"]; var currVal_8 = _co.getFillStyle()["height.px"]; var currVal_9 = i1.ɵinlineInterpolate(1, "", (_co.interactive ? 2 : 1), ""); var currVal_10 = i1.ɵinlineInterpolate(1, "", (_co.interactive ? 2 : 1), ""); _ck(_v, 4, 0, currVal_7, currVal_8, currVal_9, currVal_10); }); }
exports.View_LayerSymbolComponent_0 = View_LayerSymbolComponent_0;
function View_LayerSymbolComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "layer-symbol", [], null, null, null, View_LayerSymbolComponent_0, RenderType_LayerSymbolComponent)), i1.ɵdid(1, 114688, null, 0, i5.LayerSymbolComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_LayerSymbolComponent_Host_0 = View_LayerSymbolComponent_Host_0;
var LayerSymbolComponentNgFactory = i1.ɵccf("layer-symbol", i5.LayerSymbolComponent, View_LayerSymbolComponent_Host_0, { interactive: "interactive", style: "style", showColorpicker: "showColorpicker" }, { stylechange: "stylechange" }, []);
exports.LayerSymbolComponentNgFactory = LayerSymbolComponentNgFactory;
