"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ReadinessStateEnum;
(function (ReadinessStateEnum) {
    ReadinessStateEnum["COMPLETE_DATA"] = "COMPLETE_DATA";
    ReadinessStateEnum["PARTIAL_DATA"] = "PARTIAL_DATA";
    ReadinessStateEnum["NO_DATA"] = "NO_DATA";
    ReadinessStateEnum["NOT_DELIVERED_DATA"] = "NOT_DELIVERED_DATA";
})(ReadinessStateEnum = exports.ReadinessStateEnum || (exports.ReadinessStateEnum = {}));
exports.readinessStateOptions = [
    { id: ReadinessStateEnum.COMPLETE_DATA, value: 'úplná data' },
    { id: ReadinessStateEnum.PARTIAL_DATA, value: 'částečná data' },
    { id: ReadinessStateEnum.NO_DATA, value: 'neobsahuje data' },
    { id: ReadinessStateEnum.NOT_DELIVERED_DATA, value: 'nedodaná data' },
];
