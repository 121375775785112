"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var L = require("leaflet");
var i0 = require("@angular/core");
var LeafletWmsCommonService = /** @class */ (function () {
    function LeafletWmsCommonService() {
    }
    /**
     * loadFeatureData
     *
     * @param options {object}
     * - latlng
     * - url
     * - uppercase
     * - httpService
     * - map
     * - crs
     * - layers
     * - filter
     * - cqlFilter
     * - featureCount
     * - version
     * @returns {Promise}
     */
    LeafletWmsCommonService.prototype.loadFeatureData = function (options) {
        // get location of features in pixels, map size and displayed map bounds
        // note: we can't use map.getBounds() - it doesn't returns bounds
        // of visible area
        if (!options.url) {
            throw new Error('No url provided.');
        }
        if (!options.httpService) {
            throw new Error('No httpService provided.');
        }
        if (!options.map) {
            throw new Error('No map provided.');
        }
        if (!options.layers) {
            throw new Error('No layers provided.');
        }
        if (!options.crs) {
            throw new Error('No crs provided.');
        }
        var featurePoint = options.map.latLngToContainerPoint(options.latlng).round();
        var mapSize = options && options.map.getSize();
        var nwPoint = [0, 0];
        var sePoint = [mapSize.x, mapSize.y];
        var nwLatlng = options && options.map.containerPointToLatLng(nwPoint);
        var seLatlng = options.map.containerPointToLatLng(sePoint);
        var nw = options.crs.project(nwLatlng);
        var se = options.crs.project(seLatlng);
        var bbox = (options.version && this._getNumericVersion(options.version) >= 1.3 && options.crs === L.CRS.EPSG4326 ?
            [se.y, nw.x, nw.y, se.x] :
            [nw.x, se.y, se.x, nw.y]).join(',');
        var reqParams = Object.assign({}, options.queryParams);
        reqParams['service'] = 'WMS';
        reqParams['version'] = options.version || '1.3.0';
        reqParams['request'] = 'GetFeatureInfo';
        reqParams['info_format'] = 'application/json';
        reqParams['x'] = featurePoint.x;
        reqParams['y'] = featurePoint.y;
        reqParams['bbox'] = bbox;
        reqParams['width'] = mapSize.x;
        reqParams['height'] = mapSize.y;
        reqParams['layers'] = options.layers;
        reqParams['query_layers'] = options.layers;
        reqParams['feature_count'] = (options.featureCount ? options.featureCount : 1);
        // cql filter has a priority
        if (options && options.cqlFilter) {
            reqParams['cql_filter'] = options.cqlFilter;
        }
        else if (options && options.filter) {
            reqParams['filter'] = options.filter;
        }
        if (options && this._getNumericVersion(reqParams['version']) >= 1.3) {
            reqParams['crs'] = options.crs.code;
        }
        else {
            reqParams['srs'] = options.crs.code;
        }
        var reqUrl = options.url + L.Util.getParamString(reqParams, options.url, options.uppercase);
        return options.httpService.get(reqUrl).toPromise();
    };
    /**
     * Gets numeric representation of OGC service version.
     *
     * @param version {text}
     * @throws {Error}
     * @returns {float}
     */
    LeafletWmsCommonService.prototype._getNumericVersion = function (version) {
        try {
            return parseFloat(version.split('.').slice(0, 2).join('.'));
        }
        catch (e) {
            throw new Error('Version ' + version + ' cannot be converted to number.');
        }
    };
    LeafletWmsCommonService.ngInjectableDef = i0.defineInjectable({ factory: function LeafletWmsCommonService_Factory() { return new LeafletWmsCommonService(); }, token: LeafletWmsCommonService, providedIn: "root" });
    return LeafletWmsCommonService;
}());
exports.LeafletWmsCommonService = LeafletWmsCommonService;
