"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var construction_object_name_pipe_1 = require("@app/common/pipes/construction-object-name.pipe");
var geometric_plan_request_model_1 = require("@app/ps/models/geometric-plan-request.model");
var geometric_plan_request_type_enum_1 = require("@app/ps/enums/geometric-plan-request-type.enum");
var angular_1 = require("@uirouter/angular");
var geometric_plan_request_service_1 = require("@app/ps/services/geometric-plan-request.service");
var kn_gp_name_pipe_1 = require("@app/ps/pipes/kn-gp-name.pipe");
var _ = require("lodash");
var opinion_request_status_enum_1 = require("@app/ps/enums/opinion-request-status.enum");
var GeometricPlanRequestCreateFromExistingComponent = /** @class */ (function () {
    function GeometricPlanRequestCreateFromExistingComponent(requestService, constructionObjectNamePipe, stateService, knGpNamePipe, restangular) {
        this.requestService = requestService;
        this.constructionObjectNamePipe = constructionObjectNamePipe;
        this.stateService = stateService;
        this.knGpNamePipe = knGpNamePipe;
        this.restangular = restangular;
        this.geometricPlanRequest = geometric_plan_request_model_1.GeometricPlanRequestModel.create();
        this.typeOptions = geometric_plan_request_type_enum_1.geometricPlanRequestTypeOptions;
        this.similarGeometricPlanRequestExists = false;
        this.emptyKnGp = false;
        this.type = this.typeOptions[0];
        this.typeEnum = geometric_plan_request_type_enum_1.GeometricPlanRequestTypeEnum;
        this.gpFiles = [];
        this.gpVfkFiles = [];
        this.gpDocFiles = [];
        this.gpFilesValid = false;
        this.gpVfkFilesValid = false;
        this.gpFilesMsg = GeometricPlanRequestCreateFromExistingComponent.MSG_GP_FILES_DEFAULT;
        this.gpVfkFilesMsg = GeometricPlanRequestCreateFromExistingComponent.MSG_GP_VFK_FILES_DEFAULT;
        this.gpDocFilesMsg = GeometricPlanRequestCreateFromExistingComponent.MSG_GP_DOC_FILES_DEFAULT;
        this.constructionObjectFilter = {
            validity: { values: ['valid'] },
            areaId: { values: [] },
        };
        this.onSubmit = this.onSubmit.bind(this);
    }
    GeometricPlanRequestCreateFromExistingComponent.prototype.ngOnInit = function () {
    };
    GeometricPlanRequestCreateFromExistingComponent.prototype.isValid = function () {
        return !!(this.type
            && !this.emptyKnGp
            && this.geometricPlanRequest.knGp
            && this.gpFiles.length
            && this.gpVfkFiles.length);
    };
    GeometricPlanRequestCreateFromExistingComponent.prototype.onKnGpChange = function (knGp) {
        var _this = this;
        this.similarGeometricPlanRequestExists = false;
        this.emptyKnGp = false;
        if (!this.geometricPlanRequest.knGp) {
            return;
        }
        var parcels = [];
        this.geometricPlanRequest.knGp.knBudoucs.forEach(function (knBudouc) {
            parcels = parcels.concat(knBudouc.parcels);
        });
        this.geometricPlanRequest.knGp.easements.forEach(function (easement) {
            parcels.push(easement.parcel);
        });
        parcels = _.uniqBy(parcels, 'id');
        if (!parcels.length) {
            this.emptyKnGp = true;
            return;
        }
        var parcelIds = parcels.map(function (p) { return p.id; });
        var filter = { filter: { filters: { parcelId: parcelIds } } };
        this.restangular
            .all('geometric-plan-requests').customPOST(filter)
            .then(function (data) {
            var requests = data.plain().filter(function (or) { return or.status !== opinion_request_status_enum_1.OpinionRequestStatusEnum.DELETED; });
            _this.similarGeometricPlanRequestExists = requests.length > 0;
        });
    };
    GeometricPlanRequestCreateFromExistingComponent.prototype.onGpFilesChange = function (files) {
        this.gpFiles = files;
        var extensionValid = files.every(function (f) { return f.extension && f.extension.toLowerCase() === 'pdf'; });
        if (this.gpFiles.length === 0) {
            this.gpFilesMsg = GeometricPlanRequestCreateFromExistingComponent.MSG_GP_FILES_DEFAULT;
        }
        else if (!extensionValid) {
            this.gpFilesMsg = GeometricPlanRequestCreateFromExistingComponent.MSG_GP_FILES_EXTENSION;
        }
        else if (this.gpFiles.length > 1) {
            this.gpFilesMsg = GeometricPlanRequestCreateFromExistingComponent.MSG_FILES_QUANTITY;
        }
        else {
            this.gpFilesMsg = '';
        }
        this.gpFilesValid = (extensionValid && this.gpFiles.length === 1);
    };
    GeometricPlanRequestCreateFromExistingComponent.prototype.onGpVfkFilesChange = function (files) {
        this.gpVfkFiles = files;
        var extensionValid = files.every(function (f) { return f.extension && f.extension.toLowerCase() === 'vfk'; });
        if (this.gpVfkFiles.length === 0) {
            this.gpVfkFilesMsg = GeometricPlanRequestCreateFromExistingComponent.MSG_GP_VFK_FILES_DEFAULT;
        }
        else if (!extensionValid) {
            this.gpVfkFilesMsg = GeometricPlanRequestCreateFromExistingComponent.MSG_GP_VFK_FILES_EXTENSION;
        }
        else if (this.gpVfkFiles.length > 1) {
            this.gpVfkFilesMsg = GeometricPlanRequestCreateFromExistingComponent.MSG_FILES_QUANTITY;
        }
        else {
            this.gpVfkFilesMsg = '';
        }
        this.gpVfkFilesValid = (extensionValid && this.gpVfkFiles.length === 1);
    };
    GeometricPlanRequestCreateFromExistingComponent.prototype.onGpDocFilesChange = function (files) {
        this.gpDocFiles = files;
    };
    GeometricPlanRequestCreateFromExistingComponent.prototype.onSubmit = function () {
        var _this = this;
        this.geometricPlanRequest.type = this.type.id;
        return this.requestService
            .createFromExisting(this.geometricPlanRequest, this.gpFiles, this.gpVfkFiles, this.gpDocFiles)
            .then(function (geometricPlanRequest) {
            _this.stateService.go('symap.project.geometricPlanRequests.detail', { id: geometricPlanRequest.id, tab: 'parcels-budouc' });
        });
    };
    GeometricPlanRequestCreateFromExistingComponent.MSG_GP_FILES_DEFAULT = 'Vložte soubor přetažením nebo výběrem. Je možné vložit jeden soubor PDF.';
    GeometricPlanRequestCreateFromExistingComponent.MSG_GP_FILES_EXTENSION = 'Soubor musí být ve formátu PDF.';
    GeometricPlanRequestCreateFromExistingComponent.MSG_GP_VFK_FILES_DEFAULT = 'Vložte soubor přetažením nebo výběrem. Je možné vložit jeden soubor VFK.';
    GeometricPlanRequestCreateFromExistingComponent.MSG_GP_VFK_FILES_EXTENSION = 'Soubor musí být ve formátu VFK.';
    GeometricPlanRequestCreateFromExistingComponent.MSG_GP_DOC_FILES_DEFAULT = 'Vložte soubor přetažením nebo výběrem. Je možné vložit více souborů.';
    GeometricPlanRequestCreateFromExistingComponent.MSG_FILES_QUANTITY = 'Je možné vložit pouze jeden soubor.';
    return GeometricPlanRequestCreateFromExistingComponent;
}());
exports.GeometricPlanRequestCreateFromExistingComponent = GeometricPlanRequestCreateFromExistingComponent;
