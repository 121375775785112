"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var dialog_service_1 = require("@app/dialog/dialog.service");
var administrator_edit_component_1 = require("@app/ps/components/administrator-edit/administrator-edit.component");
var AdministratorDetailComponent = /** @class */ (function () {
    function AdministratorDetailComponent(restangular, dialogService) {
        this.restangular = restangular;
        this.dialogService = dialogService;
        this.loading = true;
        this.onEdit = this.onEdit.bind(this);
    }
    AdministratorDetailComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.restangular
            .one('administrators', this.administratorId)
            .get({ loadCollections: ['actingPersons'] })
            .then(function (data) {
            _this.administrator = data.plain();
            _this.loading = false;
        });
    };
    AdministratorDetailComponent.prototype.onEdit = function () {
        var _this = this;
        var dialog = this.dialogService.open(administrator_edit_component_1.AdministratorEditComponent, {
            data: { administratorId: this.administrator.id }
        });
        var sub = dialog.afterClosed.subscribe(function (res) {
            if (res) {
                Object.assign(_this.administrator, res);
            }
            sub.unsubscribe();
        });
    };
    return AdministratorDetailComponent;
}());
exports.AdministratorDetailComponent = AdministratorDetailComponent;
