"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var parcel_list_component_1 = require("./components/parcel-list/parcel-list.component");
var parcel_detail_component_1 = require("@app/ps/parcels/components/parcel-detail/parcel-detail.component");
var update_occupation_parcel_component_1 = require("@app/ps/occupations/components/update-occupation-parcel/update-occupation-parcel.component");
var state_utils_1 = require("@app/common/utils/state.utils");
exports.parcelsState = {
    name: 'symap.project.parcels',
    data: {
        title: 'Parcely',
    },
    url: '/parcels?page&sef',
    params: {
        page: {
            dynamic: true,
            type: 'int',
            inherit: false,
        },
        sef: {
            type: 'string',
            inherit: false,
            array: true
        },
    },
    views: {
        'content@symap.project': {
            component: parcel_list_component_1.ParcelListComponent,
        }
    }
};
exports.parcelDetailState = {
    name: 'symap.project.parcels.detail',
    url: '/:id/:tab',
    data: {
        title: 'Detail parcely',
    },
    views: {
        'content@symap.project': {
            component: parcel_detail_component_1.ParcelDetailComponent,
        }
    },
    resolve: {
        parcelId: ['$stateParams', state_utils_1.resolveId]
    },
    params: {
        id: {
            type: 'int',
            value: undefined
        },
        tab: {
            value: 'occupations',
            dynamic: true,
            inherit: false,
            squash: true,
        }
    },
};
exports.updateOccupationParcelState = {
    name: 'symap.project.parcels.detail.updateOccupationParcel',
    url: '/occupation/{occupationId}/update-parcel',
    data: {
        title: 'Aktualizace parcely záboru',
    },
    params: {
        occupationId: {
            type: 'int',
        },
    },
    resolve: {
        occupationId: ['$stateParams', resolveOccupationIdFunction],
    },
    views: {
        'content@symap.project': {
            component: update_occupation_parcel_component_1.UpdateOccupationParcelComponent,
        },
    },
};
function resolveOccupationIdFunction($stateParams) {
    return $stateParams.occupationId;
}
exports.resolveOccupationIdFunction = resolveOccupationIdFunction;
