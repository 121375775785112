"use strict";
var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var rxjs_1 = require("rxjs");
var insertion_directive_1 = require("./insertion.directive");
var dialog_ref_1 = require("./dialog-ref");
var dialog_config_1 = require("./dialog-config");
var $ = require("jquery");
var DialogComponent = /** @class */ (function () {
    function DialogComponent(componentFactoryResolver, changeDetector, dialogRef, elementRef, dialogConfig) {
        this.componentFactoryResolver = componentFactoryResolver;
        this.changeDetector = changeDetector;
        this.dialogRef = dialogRef;
        this.elementRef = elementRef;
        this.dialogConfig = dialogConfig;
        this._onClose = new rxjs_1.Subject();
        this.onClose = this._onClose.asObservable();
        this.onScrollbarRegister = this.onScrollbarRegister.bind(this);
        this.onWindowResize = this.onWindowResize.bind(this);
    }
    DialogComponent.prototype.ngAfterViewInit = function () {
        var e_1, _a;
        this.loadChildComponent(this.childComponentType);
        this.className = this.dialogConfig.className;
        if (typeof this.dialogConfig.data === 'object') {
            try {
                for (var _b = __values(Object.entries(this.dialogConfig.data)), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var _d = __read(_c.value, 2), key = _d[0], value = _d[1];
                    this.componentRef.instance[key] = value;
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_1) throw e_1.error; }
            }
        }
        this.changeDetector.detectChanges();
        this.onWindowResize();
    };
    DialogComponent.prototype.ngOnDestroy = function () {
        if (this.componentRef) {
            this.componentRef.destroy();
        }
    };
    DialogComponent.prototype.onOverlayClicked = function (evt) {
        if (this.elementRef.nativeElement.children[0] === evt.target) {
            this.close(false);
        }
    };
    DialogComponent.prototype.onEscape = function (evt) {
        var ESC_KEY = 27;
        if (evt.keyCode === ESC_KEY) {
            this.close(false);
        }
    };
    DialogComponent.prototype.onClosed = function (evt) {
        this.close(false);
    };
    DialogComponent.prototype.close = function (val) {
        this.dialogRef.close(val);
    };
    DialogComponent.prototype.loadChildComponent = function (componentType) {
        var componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentType);
        var viewContainerRef = this.insertionPoint.viewContainerRef;
        viewContainerRef.clear();
        this.componentRef = viewContainerRef.createComponent(componentFactory);
    };
    DialogComponent.prototype.onWindowResize = function () {
        var scroll = $(this.elementRef.nativeElement.querySelector('.modal-content > * > .scrollable'));
        scroll.css('max-height', ($(window).height() - 80) + 'px');
        this.updateScrollbar();
    };
    DialogComponent.prototype.onScrollbarRegister = function (updateScrollbar) {
        this.updateScrollbar = updateScrollbar;
    };
    return DialogComponent;
}());
exports.DialogComponent = DialogComponent;
