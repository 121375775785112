"use strict";
var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var _ = require("lodash");
var project_model_1 = require("@app/dashboard/models/project.model");
var data_service_1 = require("@app/dashboard/services/data.service");
var common_1 = require("@angular/common");
var tooltip_trend_component_1 = require("@app/dashboard/components/tooltip-trend/tooltip-trend.component");
var auth_service_1 = require("@app/common/services/auth.service");
var ProjectsComponent = /** @class */ (function () {
    function ProjectsComponent(helpIds, dataService, authService, datePipe) {
        this.helpIds = helpIds;
        this.dataService = dataService;
        this.authService = authService;
        this.datePipe = datePipe;
        this.loading = true;
        this.sortOrder = { sortBy: 'name', direction: 'asc' };
        this.filter = { name: '', management: '', isprofond: '' };
        this.captureDate = '';
        this.tooltipComponent = tooltip_trend_component_1.TooltipTrendComponent;
        this.onSort = this.onSort.bind(this);
        this.onFilter = this.onFilter.bind(this);
    }
    ProjectsComponent.prototype.ngOnInit = function () {
        this.loadUserProjects();
        this.loadProjects();
    };
    ProjectsComponent.prototype.onSort = function (sortBy) {
        this.sortOrder.direction = sortBy === this.sortOrder.sortBy
            ? this.sortOrder.direction === 'asc' ? 'desc' : 'asc'
            : 'asc';
        this.sortOrder.sortBy = sortBy;
        this.projects = _.orderBy(this.projects, this.sortOrder.sortBy, this.sortOrder.direction);
    };
    ProjectsComponent.prototype.onFilter = function () {
        var _this = this;
        this.projects = this.projectsSource.filter(function (project) {
            if (_this.filter.name && project.name.toLowerCase().indexOf(_this.filter.name.toLowerCase()) === -1) {
                return false;
            }
            if (_this.filter.management && project.management.toLowerCase().indexOf(_this.filter.management.toLowerCase()) === -1) {
                return false;
            }
            if (_this.filter.isprofond && project.isprofond.toLowerCase().indexOf(_this.filter.isprofond.toLowerCase()) === -1) {
                return false;
            }
            return true;
        });
        this.projects = _.sortBy(this.projects, this.sortOrder.sortBy, this.sortOrder.direction);
    };
    ProjectsComponent.prototype.getUserProject = function (project) {
        if (!project.key || !this.userProjects) {
            return false;
        }
        return _.find(this.userProjects, { key: project.key });
    };
    ProjectsComponent.prototype.getDoneRate = function (done, total) {
        if (total === 0 || done === 0) {
            return 0;
        }
        return (done / total * 100);
    };
    ProjectsComponent.prototype.loadProjects = function () {
        var _this = this;
        this.dataService.getStatisticsData().subscribe(function (data) {
            var e_1, _a;
            _this.projectsSource = [];
            try {
                for (var data_1 = __values(data), data_1_1 = data_1.next(); !data_1_1.done; data_1_1 = data_1.next()) {
                    var part = data_1_1.value;
                    _this.captureDate = _this.datePipe.transform(part.date, 'shortDate');
                    _this.projectsSource = _this.projectsSource.concat(part.data.map(project_model_1.ProjectModel.createFromAPI));
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (data_1_1 && !data_1_1.done && (_a = data_1.return)) _a.call(data_1);
                }
                finally { if (e_1) throw e_1.error; }
            }
            _this.projectsSource = _.sortBy(_this.projectsSource, _this.sortOrder.sortBy, _this.sortOrder.direction);
            _this.projects = _this.projectsSource;
            _this.loading = false;
        });
    };
    ProjectsComponent.prototype.loadUserProjects = function () {
        var _this = this;
        this.authService.loadSymapProjects(this.authService.getUser().id)
            .then(function (data) { return _this.userProjects = data.projects; });
    };
    return ProjectsComponent;
}());
exports.ProjectsComponent = ProjectsComponent;
