"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var users_states_1 = require("./users.states");
var STATES = [
    users_states_1.userListState,
    users_states_1.editUserPermissionsState,
    users_states_1.userDetailState
];
var UsersModule = /** @class */ (function () {
    function UsersModule() {
    }
    return UsersModule;
}());
exports.UsersModule = UsersModule;
