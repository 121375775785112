"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var sample_status_enum_1 = require("@app/sv/enums/sample-status.enum");
var i0 = require("@angular/core");
var SampleStatusNamePipe = /** @class */ (function () {
    function SampleStatusNamePipe() {
    }
    SampleStatusNamePipe.prototype.transform = function (value) {
        var option = sample_status_enum_1.sampleStatusOptions.find(function (option) { return option.id === value; });
        return option.name;
    };
    SampleStatusNamePipe.ngInjectableDef = i0.defineInjectable({ factory: function SampleStatusNamePipe_Factory() { return new SampleStatusNamePipe(); }, token: SampleStatusNamePipe, providedIn: "root" });
    return SampleStatusNamePipe;
}());
exports.SampleStatusNamePipe = SampleStatusNamePipe;
