"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var dialog_ref_1 = require("@app/dialog/dialog-ref");
var dialog_config_1 = require("@app/dialog/dialog-config");
var dialog_service_1 = require("@app/dialog/dialog.service");
var article_attachment_component_1 = require("@app/common/components/article-attachment/article-attachment.component");
var $ = require("jquery");
var auth_service_1 = require("@app/common/services/auth.service");
var GMTCK = require("gmt-ckeditor/dist");
var ArticleEditComponent = /** @class */ (function () {
    function ArticleEditComponent(helpIds, configurationRestangular, elementRef, dialogRef, dialogConfig, dialogService, authService) {
        this.helpIds = helpIds;
        this.configurationRestangular = configurationRestangular;
        this.elementRef = elementRef;
        this.dialogRef = dialogRef;
        this.dialogConfig = dialogConfig;
        this.dialogService = dialogService;
        this.authService = authService;
        this.token = this.authService.getToken();
        this.article = dialogConfig.data.article;
        this.onSave = this.onSave.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }
    ArticleEditComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.prepareEditor()];
                    case 1:
                        _a.sent();
                        this.registerLinkListener();
                        return [2 /*return*/];
                }
            });
        });
    };
    ArticleEditComponent.prototype.isValid = function () {
        return this.article.title;
    };
    ArticleEditComponent.prototype.onSave = function () {
        var _this = this;
        this.article.content = this.editor.getData();
        return this.configurationRestangular.all('articles').customPUT(this.article).then(function (article) {
            _this.dialogRef.close(article.plain());
        });
    };
    ArticleEditComponent.prototype.onCancel = function () {
        this.dialogRef.close(false);
    };
    ArticleEditComponent.prototype.prepareEditor = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, GMTCK.ClassicEditor.create(document.querySelector('#editor'))];
                    case 1:
                        _a.editor = _b.sent();
                        this.editor.setData(this.article.content);
                        return [2 /*return*/];
                }
            });
        });
    };
    ArticleEditComponent.prototype.onFileUpload = function () {
        var _this = this;
        var dialog = this.dialogService.open(article_attachment_component_1.ArticleAttachmentComponent, {
            data: { article: this.article }
        });
        var sub = dialog.afterClosed.subscribe(function (res) {
            if (res) {
                _this.editor.model.change(function (writer) {
                    var link = writer.createText(res.text ? res.text : res.attachment.name, {
                        linkHref: './inner-' + res.attachment.id,
                    });
                    _this.editor.model.insertContent(link, _this.editor.model.document.selection);
                });
            }
            sub.unsubscribe();
        });
    };
    ArticleEditComponent.prototype.registerLinkListener = function () {
        var _this = this;
        $('body').on('click', '.ck-link-actions__preview', function (event) {
            event.preventDefault();
            event.stopPropagation();
            var href = $(event.currentTarget).attr('href');
            if (href.indexOf('./inner-') === 0) {
                window.open(_this.configurationRestangular.configuration.baseUrl + "/attachments/" + href.replace('./inner-', '') + "/data?t=" + _this.token, '_blank');
            }
            else {
                window.open(href, '_blank');
            }
        });
    };
    return ArticleEditComponent;
}());
exports.ArticleEditComponent = ArticleEditComponent;
