"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var map_layer_type_enum_1 = require("@app/map/enums/map-layer-type.enum");
var map_callback_enum_1 = require("@app/map/enums/map-callback.enum");
var map_layer_selector_service_1 = require("@app/map/services/map-layer-selector.service");
var map_layer_service_1 = require("@app/map/services/map-layer.service");
var core_2 = require("@uirouter/core");
var _isString = require("lodash/isString");
var _isNumber = require("lodash/isNumber");
var MapLayerSelectorComponent = /** @class */ (function () {
    function MapLayerSelectorComponent(mapLayerSelectorService, localStorageService, mapLayerService, transition) {
        this.mapLayerSelectorService = mapLayerSelectorService;
        this.localStorageService = localStorageService;
        this.mapLayerService = mapLayerService;
        this.transition = transition;
    }
    MapLayerSelectorComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var options;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.getCollapsedState = this.mapLayerSelectorService.getCollapsedState;
                        options = {
                            saveLayersVisibility: false,
                            saveGroupCollapseStatus: false,
                            showCollapsedVisibleLayersCount: false,
                            storagePrefix: ""
                        };
                        // used to chain asynchronous layer initialization
                        //let layerInit = $q.resolve();
                        // merge defaults with user provided values
                        Object.assign(options, this.options);
                        if (!Array.isArray(this.transition.params().layer)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.layersShow(this.config, this.transition.params().layer)];
                    case 1:
                        _a.sent();
                        if (this.isOptionsSaveLayersVisibility() === true) {
                            this.saveConfig();
                        }
                        return [2 /*return*/];
                    case 2:
                        if (this.isOptionsSaveGroupCollapseStatus() === true) {
                            this.updateCollapsed(this.config);
                        }
                        if (this.isOptionsSaveLayersVisibility() === true) {
                            this.loadConfig();
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    // updates layer and sub-layer collapsed flag configuration from local storage
    MapLayerSelectorComponent.prototype.updateCollapsed = function (layer) {
        var _this = this;
        var isCollapsed = this.localStorageService.get(this.getLayerGroupVisibilityId(layer.id));
        if (isCollapsed === true) {
            layer.collapsed = true;
        }
        else {
            layer.collapsed = false;
        }
        if (Array.isArray(layer.layers)) {
            layer.layers.forEach(function (subLayer) {
                _this.updateCollapsed(subLayer);
            });
            layer.hasVisibleItems = layer.layers.some(function (l) { return !l.collapsed; });
        }
    };
    MapLayerSelectorComponent.prototype.hideLayerObjects = function (layerIds) {
        var _this = this;
        if (!Array.isArray(layerIds)) {
            return;
        }
        layerIds.forEach(function (id) {
            var config = _this.mapLayerSelectorService.getObjectConfig(_this.config, id);
            if (config.selector === 'group') {
                _this.hideGroup(config);
            }
            else {
                _this.hideLayer(config);
            }
        });
    };
    MapLayerSelectorComponent.prototype.hideLayer = function (layerConfig) {
        if (_isString(layerConfig)) {
            layerConfig = this.mapLayerSelectorService.getObjectConfig(this.config, layerConfig);
        }
        if (layerConfig.visible) {
            this.switchLayer(layerConfig);
        }
    };
    MapLayerSelectorComponent.prototype.hideGroup = function (groupConfig) {
        if (_isString(groupConfig)) {
            groupConfig = this.mapLayerSelectorService.getObjectConfig(this.config, groupConfig);
        }
        if (groupConfig.selector !== 'group') {
            return;
        }
        groupConfig.layers.forEach(this.hideLayer);
    };
    MapLayerSelectorComponent.prototype.getLayersToSave = function (config) {
        var _this = this;
        var result = [];
        if (config.id !== undefined && config.visible !== undefined) {
            var item;
            // saving always true from layer with minZoomVisibility
            if (_isNumber(config.minZoomVisibility)) {
                item = { id: config.id, visible: true };
            }
            else {
                item = { id: config.id, visible: config.visible };
            }
            result.push(item);
        }
        if (Array.isArray(config.layers)) {
            config.layers.forEach(function (value) {
                _this.getLayersToSave(value).forEach(function (value) {
                    result.push(value);
                });
            });
        }
        return result;
    };
    MapLayerSelectorComponent.prototype.getVisibleForLayerId = function (loadedConfig, id) {
        var found = loadedConfig.find(function (value) { return value.id === id; });
        if (found) {
            return found.visible;
        }
        return undefined;
    };
    MapLayerSelectorComponent.prototype.getMapSelectorConfigId = function () {
        return (this.options.storagePrefix ? this.options.storagePrefix + '.' : '') + 'layerSelector.' + this.mapId;
    };
    MapLayerSelectorComponent.prototype.getLayerGroupVisibilityId = function (id) {
        return this.getMapSelectorConfigId() + '_groupSelector_' + id;
    };
    MapLayerSelectorComponent.prototype.isOptionsSaveLayersVisibility = function () {
        return this.options.saveLayersVisibility === true;
    };
    MapLayerSelectorComponent.prototype.isOptionsSaveGroupCollapseStatus = function () {
        return this.options.saveGroupCollapseStatus === true;
    };
    MapLayerSelectorComponent.prototype.saveConfig = function () {
        this.localStorageService.set(this.getMapSelectorConfigId(), this.getLayersToSave(this.config));
    };
    MapLayerSelectorComponent.prototype.loadConfig = function () {
        return __awaiter(this, void 0, void 0, function () {
            var config;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        config = this.localStorageService.get(this.getMapSelectorConfigId());
                        return [4 /*yield*/, this.loadLayers(config, this.config)];
                    case 1:
                        if ((_a.sent()) === true) {
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    MapLayerSelectorComponent.prototype.loadLayers = function (loadedConfig, scopeConfig) {
        return __awaiter(this, void 0, void 0, function () {
            var e_1, _a, change, newVisible, _b, _c, value, e_1_1;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        change = false;
                        if (!(scopeConfig.id !== undefined)) return [3 /*break*/, 2];
                        newVisible = loadedConfig ? this.getVisibleForLayerId(loadedConfig, scopeConfig.id) : (scopeConfig.visible || (scopeConfig.parent ? scopeConfig.parent.visible : false));
                        if ((scopeConfig.visible !== newVisible) && (newVisible !== undefined)) {
                            scopeConfig.visible = newVisible;
                            change = true;
                        }
                        if (!(scopeConfig.visible && scopeConfig.type !== 'node')) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.mapLayerService.addLayerToMap(this.mapId, scopeConfig, scopeConfig.id)];
                    case 1:
                        _d.sent();
                        _d.label = 2;
                    case 2:
                        if (!Array.isArray(scopeConfig.layers)) return [3 /*break*/, 11];
                        _d.label = 3;
                    case 3:
                        _d.trys.push([3, 8, 9, 10]);
                        _b = __values(scopeConfig.layers), _c = _b.next();
                        _d.label = 4;
                    case 4:
                        if (!!_c.done) return [3 /*break*/, 7];
                        value = _c.value;
                        return [4 /*yield*/, this.loadLayers(loadedConfig, value)];
                    case 5:
                        if ((_d.sent()) === true) {
                            change = true;
                        }
                        _d.label = 6;
                    case 6:
                        _c = _b.next();
                        return [3 /*break*/, 4];
                    case 7: return [3 /*break*/, 10];
                    case 8:
                        e_1_1 = _d.sent();
                        e_1 = { error: e_1_1 };
                        return [3 /*break*/, 10];
                    case 9:
                        try {
                            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                        }
                        finally { if (e_1) throw e_1.error; }
                        return [7 /*endfinally*/];
                    case 10:
                        ;
                        _d.label = 11;
                    case 11: return [2 /*return*/, change];
                }
            });
        });
    };
    MapLayerSelectorComponent.prototype.layersShow = function (scopeConfig, layersToShow) {
        return __awaiter(this, void 0, void 0, function () {
            var e_2, _a, childVisible, _b, _c, value, vVisible, e_2_1;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        childVisible = undefined;
                        if (!(layersToShow.includes(scopeConfig.id) || (scopeConfig.parent && scopeConfig.parent.visible))) return [3 /*break*/, 4];
                        scopeConfig.visible = true;
                        if (!(scopeConfig.type !== 'node')) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.mapLayerService.addLayerToMap(this.mapId, scopeConfig, scopeConfig.id)
                            /*layerInit = layerInit.then(
                              () => {
                                return this.mapLayerService.addLayerToMap(this.mapId, scopeConfig, scopeConfig.id)
                              }
                            );*/
                        ];
                    case 1:
                        _d.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        scopeConfig.collapsed = false;
                        if (this.isOptionsSaveGroupCollapseStatus() === true) {
                            this.localStorageService.set(this.getLayerGroupVisibilityId(scopeConfig.id), scopeConfig.collapsed);
                        }
                        _d.label = 3;
                    case 3:
                        childVisible = true;
                        return [3 /*break*/, 5];
                    case 4:
                        scopeConfig.visible = false;
                        _d.label = 5;
                    case 5:
                        if (!Array.isArray(scopeConfig.layers)) return [3 /*break*/, 14];
                        _d.label = 6;
                    case 6:
                        _d.trys.push([6, 11, 12, 13]);
                        _b = __values(scopeConfig.layers), _c = _b.next();
                        _d.label = 7;
                    case 7:
                        if (!!_c.done) return [3 /*break*/, 10];
                        value = _c.value;
                        return [4 /*yield*/, this.layersShow(value, layersToShow)];
                    case 8:
                        vVisible = _d.sent();
                        if (childVisible === undefined) {
                            childVisible = vVisible;
                        }
                        else if (childVisible !== vVisible) {
                            childVisible = null;
                        }
                        _d.label = 9;
                    case 9:
                        _c = _b.next();
                        return [3 /*break*/, 7];
                    case 10: return [3 /*break*/, 13];
                    case 11:
                        e_2_1 = _d.sent();
                        e_2 = { error: e_2_1 };
                        return [3 /*break*/, 13];
                    case 12:
                        try {
                            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                        }
                        finally { if (e_2) throw e_2.error; }
                        return [7 /*endfinally*/];
                    case 13:
                        ;
                        if (scopeConfig.type === 'node') {
                            scopeConfig.visible = childVisible;
                        }
                        _d.label = 14;
                    case 14: return [2 /*return*/, scopeConfig.visible];
                }
            });
        });
    };
    MapLayerSelectorComponent.prototype.showCollapsedVisibleLayersCount = function () {
        return this.options.showCollapsedVisibleLayersCount === true;
    };
    MapLayerSelectorComponent.prototype.getSelectorLayers = function (layerConfig) {
        return (layerConfig.comparator ? layerConfig.layers.slice().sort(layerConfig.comparator) : layerConfig.layers.slice().reverse());
    };
    // recursive search for map layers that are currently visible in a map
    MapLayerSelectorComponent.prototype.getDisplayedLayersCount = function (layer) {
        var _this = this;
        var current = 0;
        if (layer.visible && layer.type !== 'node') {
            current += 1;
        }
        if (Array.isArray(layer.layers)) {
            layer.layers.forEach(function (value) {
                current += _this.getDisplayedLayersCount(value);
            });
        }
        return current;
    };
    // recursive search for all map layer (no nodes)
    MapLayerSelectorComponent.prototype.getTotalLayersCount = function (layer) {
        var _this = this;
        var current = 0;
        if (layer.type !== 'node') {
            current += 1;
        }
        if (Array.isArray(layer.layers)) {
            layer.layers.forEach(function (value) {
                current += _this.getTotalLayersCount(value);
            });
        }
        return current;
    };
    ;
    MapLayerSelectorComponent.prototype.logMapLayerSwitch = function (action) {
        window.GoogleAnalytics('send', {
            hitType: 'event',
            eventCategory: 'mapModule',
            eventAction: action,
            eventLabel: 'legend',
        });
    };
    MapLayerSelectorComponent.prototype.switchLayer = function (layerConfig, collapse) {
        var _this = this;
        if (collapse === void 0) { collapse = false; }
        if (collapse) {
            this.switchGroup(layerConfig);
            return;
        }
        layerConfig.visible = !layerConfig.visible;
        this.logMapLayerSwitch("layer visibility change " + layerConfig.title + " " + layerConfig.visible);
        if (layerConfig.visible && layerConfig.hideIfVisible) {
            this.hideLayerObjects(layerConfig.hideIfVisible);
        }
        if (layerConfig.parent && layerConfig.parent.selector === 'switch' && !layerConfig.visible) {
            layerConfig.parent.visible = layerConfig.parent.layers.some(function (l) { return l.visible; }) ? null : false;
        }
        else if (layerConfig.parent && layerConfig.parent.selector === 'switch' && layerConfig.visible) {
            layerConfig.parent.visible = layerConfig.parent.layers.every(function (l) { return l.visible; }) ? true : null;
        }
        if (this.isOptionsSaveLayersVisibility() === true) {
            this.saveConfig();
        }
        if (layerConfig.type === map_layer_type_enum_1.MapLayerTypeEnum.node && layerConfig.layers) {
            layerConfig.layers.forEach(function (l) {
                if (layerConfig.visible !== !!l.visible) {
                    _this.switchLayer(l);
                }
            });
            return;
        }
        if (layerConfig.visible) {
            this.mapLayerService
                .addLayerToMap(this.mapId, layerConfig, layerConfig.id);
        }
        else {
            this.mapLayerService
                .removeLayerFromMap(this.mapId, layerConfig.id);
        }
    };
    MapLayerSelectorComponent.prototype.switchGroup = function (layerConfig) {
        layerConfig.collapsed = !layerConfig.collapsed;
        this.logMapLayerSwitch("group visibility change " + layerConfig.title + " " + layerConfig.collapsed);
        if (this.isOptionsSaveGroupCollapseStatus() === true) {
            this.localStorageService.set(this.getLayerGroupVisibilityId(layerConfig.id), layerConfig.collapsed);
        }
        layerConfig.parent.hasVisibleItems = layerConfig.parent.layers.some(function (l) { return !l.collapsed; });
    };
    MapLayerSelectorComponent.prototype.layerMouseEnter = function (event, layer) {
        event.layer = layer;
        this.mapLayerSelectorService.fireCallback(map_callback_enum_1.MapCallbackEnum.layerMouseEnter, event);
    };
    MapLayerSelectorComponent.prototype.layerMouseLeave = function (event) {
        this.mapLayerSelectorService.fireCallback(map_callback_enum_1.MapCallbackEnum.layerMouseLeave, event);
    };
    return MapLayerSelectorComponent;
}());
exports.MapLayerSelectorComponent = MapLayerSelectorComponent;
