"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var FormCaseDeclineOwnerComponent = /** @class */ (function () {
    function FormCaseDeclineOwnerComponent() {
        this.callbackRegister = new core_1.EventEmitter();
        this.isValid = this.isValid.bind(this);
    }
    FormCaseDeclineOwnerComponent.prototype.ngOnInit = function () {
        this.data.declinedOwnerDate = new Date();
        this.data.note = {};
        this.callbackRegister.emit({
            isValid: this.isValid,
        });
    };
    FormCaseDeclineOwnerComponent.prototype.isValid = function () {
        return !!this.data.declinedOwnerDate && !!this.data.note.text && !!this.data.note.noteType;
    };
    return FormCaseDeclineOwnerComponent;
}());
exports.FormCaseDeclineOwnerComponent = FormCaseDeclineOwnerComponent;
