"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var angular_1 = require("@uirouter/angular");
var bulk_operation_process_definition_model_1 = require("../../models/bulk-operation-process-definition.model");
var callback_model_1 = require("@app/ps/models/callback.model");
var callbacks_enum_1 = require("@app/ps/enums/callbacks.enum");
var BulkOperationComponent = /** @class */ (function () {
    function BulkOperationComponent(stateService, componentFactoryResolver, restangular) {
        this.stateService = stateService;
        this.componentFactoryResolver = componentFactoryResolver;
        this.restangular = restangular;
        this.isValid = function () { return false; };
        this.sent = false;
        this.data = {};
        this.done = false;
        this.nextStatus = this.nextStatus.bind(this);
        this.proceed = this.proceed.bind(this);
        this.goToList = this.goToList.bind(this);
        this.newOperation = this.newOperation.bind(this);
    }
    BulkOperationComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.callbacks.add(callbacks_enum_1.Callbacks.DataValidityChanged, function (event) {
            _this.isValid = event.isValid;
            _this.data[_this.activeStep.id] = event.data;
        });
        this.steps = this.processDefinition.getSteps();
        this.selectStep(this.steps[0]);
    };
    BulkOperationComponent.prototype.isNextEnabled = function () {
        return this.isValid() || this.finalStep;
    };
    BulkOperationComponent.prototype.nextStatus = function () {
        return __awaiter(this, void 0, void 0, function () {
            var e_1, _a, useNext, _b, _c, step, e_1_1;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        useNext = false;
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 8, 9, 10]);
                        _b = __values(this.steps), _c = _b.next();
                        _d.label = 2;
                    case 2:
                        if (!!_c.done) return [3 /*break*/, 7];
                        step = _c.value;
                        if (!useNext) return [3 /*break*/, 5];
                        if (!this.finalStep) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.proceed()];
                    case 3:
                        _d.sent();
                        _d.label = 4;
                    case 4:
                        this.selectStep(step);
                        this.isValid = function () { return false; };
                        useNext = false;
                        return [3 /*break*/, 7];
                    case 5:
                        if (step === this.activeStep) {
                            useNext = true;
                        }
                        _d.label = 6;
                    case 6:
                        _c = _b.next();
                        return [3 /*break*/, 2];
                    case 7: return [3 /*break*/, 10];
                    case 8:
                        e_1_1 = _d.sent();
                        e_1 = { error: e_1_1 };
                        return [3 /*break*/, 10];
                    case 9:
                        try {
                            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                        }
                        finally { if (e_1) throw e_1.error; }
                        return [7 /*endfinally*/];
                    case 10: return [2 /*return*/];
                }
            });
        });
    };
    BulkOperationComponent.prototype.proceed = function () {
        return __awaiter(this, void 0, void 0, function () {
            var result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.sent = true;
                        return [4 /*yield*/, this.componentRef.instance.proceed()];
                    case 1:
                        result = _a.sent();
                        this.done = true;
                        this.sent = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    BulkOperationComponent.prototype.goToList = function () {
        this.stateService.go('^');
    };
    BulkOperationComponent.prototype.newOperation = function () {
        this.done = false;
        this.selectStep(this.steps.find(function (step) { return step.id === 'selectAction'; }));
    };
    BulkOperationComponent.prototype.selectStep = function (step) {
        var _this = this;
        if (step === void 0) { step = null; }
        this.activeStep = step;
        if (this.activeStep && this.activeStep.enabled) {
            this.isValid = function () { return true; };
        }
        this.stepHostDirective.viewContainerRef.clear();
        if (this.activeStep && this.activeStep.component) {
            var factory = this.componentFactoryResolver.resolveComponentFactory(this.activeStep.component);
            this.componentRef = this.stepHostDirective.viewContainerRef.createComponent(factory);
            this.componentRef.instance.data = this.data;
            this.componentRef.instance.callbacks = this.callbacks;
            this.componentRef.instance.newOperation = this.newOperation;
            this.componentRef.instance.goToList = this.goToList;
        }
        var enable = !!this.activeStep && this.activeStep !== this.steps[this.steps.length - 1];
        this.finalStep = this.activeStep === this.steps[this.steps.length - 2];
        this.steps.forEach(function (step) {
            step.enabled = enable;
            if (!enable) {
                if (_this.activeStep && !_this.done) {
                    delete _this.data[step.id];
                }
            }
            if (step === _this.activeStep) {
                enable = false;
            }
        });
    };
    BulkOperationComponent.prototype.clickStep = function (step) {
        if (!this.sent && step.enabled && step !== this.activeStep) {
            this.selectStep(step);
        }
    };
    return BulkOperationComponent;
}());
exports.BulkOperationComponent = BulkOperationComponent;
