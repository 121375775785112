"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var file_saver_1 = require("file-saver");
var i0 = require("@angular/core");
var ContractService = /** @class */ (function () {
    function ContractService(configRestangularService, modulesService) {
        this.configRestangularService = configRestangularService;
        this.modulesService = modulesService;
    }
    /**
     * @param {string} customerId
     * @param {FormData} data
     *
     * @returns {Promise}
     */
    ContractService.prototype.create = function (customerId, data) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.configRestangularService
                        .all("v2/contracts/customer/" + customerId + "/new")
                        .customPOST(data, '', null, { 'Content-Type': undefined })];
            });
        });
    };
    /**
     * @param {string} contractId
     * @param {FormData} data
     *
     * @returns {Promise}
     */
    ContractService.prototype.update = function (contractId, data) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.configRestangularService
                            .all("v2/contracts/" + contractId)
                            .customPUT(data, '', null, { 'Content-Type': undefined })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ContractService.prototype.getOne = function (contractId) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.configRestangularService
                            .one("v2/contracts/" + contractId)
                            .get(function (res) {
                            return _this.configRestangularService.stripRestangular(res);
                        })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ContractService.prototype.getOneFile = function (contractId, filename) {
        return this.configRestangularService
            .one("v2/contracts/" + contractId + "/document").withHttpConfig({
            responseType: 'blob',
            transformResponse: function (response, header) {
                return [response, header];
            }
        }).get().then(function (response) {
            file_saver_1.saveAs(response[0], filename, true);
        });
    };
    /**
     * @param {string} customerId
     *
     * @returns {Promise}
     */
    ContractService.prototype.getForCustomer = function (customerId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.configRestangularService
                            .all("v2/customers/" + customerId + "/contracts")
                            .getList()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ContractService.prototype.deleteOne = function (contractId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.configRestangularService
                            .one("v2/contracts/" + contractId)
                            .remove()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ContractService.prototype.addProject = function (contractId, application, projectKey) {
        return __awaiter(this, void 0, void 0, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        data = new FormData();
                        data.append('application', application);
                        data.append('projectKey', projectKey);
                        return [4 /*yield*/, this.configRestangularService
                                .all("v2/contracts/" + contractId + "/project")
                                .customPOST(data, '', null, { 'Content-Type': undefined })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ContractService.prototype.deleteProject = function (contractId, projectId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.configRestangularService
                            .one("v2/contracts/" + contractId + "/project/" + projectId)
                            .remove()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ContractService.ngInjectableDef = i0.defineInjectable({ factory: function ContractService_Factory() { return new ContractService(i0.inject("ConfigRestangularService"), i0.inject("ModulesService")); }, token: ContractService, providedIn: "root" });
    return ContractService;
}());
exports.ContractService = ContractService;
