"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./tooltip-ownerships.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@uirouter/angular/lib/directives/uiSref");
var i3 = require("@uirouter/core");
var i4 = require("@angular/common");
var i5 = require("../../pipes/subject-name.pipe");
var i6 = require("../../directives/loading.directive");
var i7 = require("./tooltip-ownerships.component");
var i8 = require("../../services/list.service");
var styles_TooltipOwnershipsComponent = [i0.styles];
var RenderType_TooltipOwnershipsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TooltipOwnershipsComponent, data: {} });
exports.RenderType_TooltipOwnershipsComponent = RenderType_TooltipOwnershipsComponent;
function View_TooltipOwnershipsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "a", [["uiSref", "symap.project.owners.detail"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).go($event.button, $event.ctrlKey, $event.metaKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i2.AnchorUISref, [i1.ElementRef, i1.Renderer2], null, null), i1.ɵdid(2, 737280, null, 0, i2.UISref, [i3.UIRouter, [2, i2.AnchorUISref], "UIView.PARENT_INJECT"], { state: [0, "state"], params: [1, "params"] }, null), i1.ɵpod(3, { ownerId: 0, tab: 1 }), (_l()(), i1.ɵted(4, null, [" ", " "])), i1.ɵppd(5, 1)], function (_ck, _v) { var currVal_0 = "symap.project.owners.detail"; var currVal_1 = _ck(_v, 3, 0, _v.context.$implicit.id, "about"); _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = i1.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i1.ɵnov(_v.parent.parent, 0), _v.context.$implicit)); _ck(_v, 4, 0, currVal_2); }); }
function View_TooltipOwnershipsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "tag tag--danger tag--small is-uppercase"], ["title", "Problematick\u00FD vlastn\u00EDk"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["PV"]))], null, null); }
function View_TooltipOwnershipsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "li", [["class", "ownership cell--flex__container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "cell--flex__container__main"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " / ", "", " "])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "comma-list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TooltipOwnershipsComponent_2)), i1.ɵdid(5, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "cell--flex__container_side"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TooltipOwnershipsComponent_3)), i1.ɵdid(8, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_3 = (_v.context.$implicit.subjects || _v.context.$implicit.subject); _ck(_v, 5, 0, currVal_3); var currVal_4 = (_v.context.$implicit.subjects && (((_v.context.$implicit.subjects[0].subjectDetail == null) ? null : _v.context.$implicit.subjects[0].subjectDetail.problematic) || ((_v.context.$implicit.subjects[1] == null) ? null : ((_v.context.$implicit.subjects[1].subjectDetail == null) ? null : _v.context.$implicit.subjects[1].subjectDetail.problematic)))); _ck(_v, 8, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.podilCitatel; var currVal_1 = _v.context.$implicit.podilJmenovatel; var currVal_2 = (_v.context.$implicit.typravNazev ? ((" (" + _v.context.$implicit.typravNazev) + ")") : ""); _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }); }
function View_TooltipOwnershipsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u017D\u00E1dn\u00ED vlastn\u00EDci"]))], null, null); }
function View_TooltipOwnershipsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i5.SubjectNamePipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [], [[8, "className", 0]], null, null, null, null)), i1.ɵdid(2, 16384, null, 0, i6.LoadingDirective, [i1.ElementRef], { loading: [0, "loading"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TooltipOwnershipsComponent_1)), i1.ɵdid(5, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TooltipOwnershipsComponent_4)), i1.ɵdid(7, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.loading; _ck(_v, 2, 0, currVal_1); var currVal_2 = ((_co.data.list == null) ? null : _co.data.list.list); _ck(_v, 5, 0, currVal_2); var currVal_3 = (((_co.data.list.list == null) ? null : _co.data.list.list.length) === 0); _ck(_v, 7, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).elementClass; _ck(_v, 1, 0, currVal_0); }); }
exports.View_TooltipOwnershipsComponent_0 = View_TooltipOwnershipsComponent_0;
function View_TooltipOwnershipsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "tooltip-ownership", [], null, null, null, View_TooltipOwnershipsComponent_0, RenderType_TooltipOwnershipsComponent)), i1.ɵdid(1, 114688, null, 0, i7.TooltipOwnershipsComponent, [i1.ChangeDetectorRef, i8.ListService, "tooltipConfig"], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_TooltipOwnershipsComponent_Host_0 = View_TooltipOwnershipsComponent_Host_0;
var TooltipOwnershipsComponentNgFactory = i1.ɵccf("tooltip-ownership", i7.TooltipOwnershipsComponent, View_TooltipOwnershipsComponent_Host_0, { data: "data" }, {}, []);
exports.TooltipOwnershipsComponentNgFactory = TooltipOwnershipsComponentNgFactory;
