"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./bulk-operation-select-entities.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../../../common/components/pagescounter/pagescounter.component.ngfactory");
var i3 = require("../../../common/components/pagescounter/pagescounter.component");
var i4 = require("../../../common/services/list.service");
var i5 = require("../../../common/components/itemscounter/itemscounter.component.ngfactory");
var i6 = require("../../../common/components/itemscounter/itemscounter.component");
var i7 = require("../../../common/components/pagination/pagination.component.ngfactory");
var i8 = require("../../../common/components/pagination/pagination.component");
var i9 = require("@uirouter/angular");
var i10 = require("../../../common/directives/loading.directive");
var i11 = require("@angular/common");
var i12 = require("./bulk-operation-select-entities.component");
var i13 = require("../../../common/services/pageable.list.service");
var i14 = require("../../services/table.service");
var i15 = require("../../services/settings.service");
var styles_BulkOperationSelectEntitiesComponent = [i0.styles];
var RenderType_BulkOperationSelectEntitiesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BulkOperationSelectEntitiesComponent, data: {} });
exports.RenderType_BulkOperationSelectEntitiesComponent = RenderType_BulkOperationSelectEntitiesComponent;
function View_BulkOperationSelectEntitiesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "table-top-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "flex1"], ["style", "line-height:25px;flex-basis:0;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Zvoleno "])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵted(-1, null, [" z\u00E1znam\u016F"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "pagescounter", [], null, [[null, "callbackRegister"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("callbackRegister" === en)) {
        var pd_0 = (_co.pageableList.pagescounterCallbackRegister($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_PagesCounterComponent_0, i2.RenderType_PagesCounterComponent)), i1.ɵdid(7, 114688, null, 0, i3.PagesCounterComponent, [i4.ListService], { list: [0, "list"] }, { callbackRegister: "callbackRegister" }), (_l()(), i1.ɵeld(8, 0, null, null, 0, "div", [["class", "flex1"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.list; _ck(_v, 7, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getSelectedCount(); _ck(_v, 4, 0, currVal_0); }); }
function View_BulkOperationSelectEntitiesComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_BulkOperationSelectEntitiesComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "itemscounter", [["style", "max-width: 100px;"]], null, [[null, "callbackRegister"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("callbackRegister" === en)) {
        var pd_0 = (_co.pageableList.itemcounterCallbackRegister($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_ItemsCounterComponent_0, i5.RenderType_ItemsCounterComponent)), i1.ɵdid(1, 114688, null, 0, i6.ItemsCounterComponent, [i4.ListService], { list: [0, "list"] }, { callbackRegister: "callbackRegister" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.list; _ck(_v, 1, 0, currVal_0); }, null); }
function View_BulkOperationSelectEntitiesComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "pagination", [], null, [[null, "pageChanged"], [null, "callbackRegister"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("pageChanged" === en)) {
        var pd_0 = (_co.pageChanged($event) !== false);
        ad = (pd_0 && ad);
    } if (("callbackRegister" === en)) {
        var pd_1 = (_co.pageableList.paginationCallbackRegister($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i7.View_PaginationComponent_0, i7.RenderType_PaginationComponent)), i1.ɵdid(1, 114688, null, 0, i8.PaginationComponent, [i4.ListService, i9.StateService], { list: [0, "list"], shouldUpdateUrl: [1, "shouldUpdateUrl"] }, { callbackRegister: "callbackRegister", pageChanged: "pageChanged" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.list; var currVal_1 = false; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_BulkOperationSelectEntitiesComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { tableViewContainerRef: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [], [[8, "className", 0]], null, null, null, null)), i1.ɵdid(2, 16384, null, 0, i10.LoadingDirective, [i1.ElementRef], { loading: [0, "loading"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BulkOperationSelectEntitiesComponent_1)), i1.ɵdid(4, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, [[1, 3], ["entityTable", 2]], null, 0, null, View_BulkOperationSelectEntitiesComponent_2)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BulkOperationSelectEntitiesComponent_3)), i1.ɵdid(7, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BulkOperationSelectEntitiesComponent_4)), i1.ɵdid(9, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.list.loading; _ck(_v, 2, 0, currVal_1); var currVal_2 = (_co.list.itemCount && (_co.list.list !== null)); _ck(_v, 4, 0, currVal_2); var currVal_3 = (_co.list.itemCount && (_co.list.list !== null)); _ck(_v, 7, 0, currVal_3); var currVal_4 = (_co.list.itemCount && (_co.list.list !== null)); _ck(_v, 9, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).elementClass; _ck(_v, 1, 0, currVal_0); }); }
exports.View_BulkOperationSelectEntitiesComponent_0 = View_BulkOperationSelectEntitiesComponent_0;
function View_BulkOperationSelectEntitiesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_BulkOperationSelectEntitiesComponent_0, RenderType_BulkOperationSelectEntitiesComponent)), i1.ɵdid(1, 114688, null, 0, i12.BulkOperationSelectEntitiesComponent, [i13.PageableListService, i1.ComponentFactoryResolver, i14.TableService, i15.SettingsService, "ChecklistService", "processDefinition"], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_BulkOperationSelectEntitiesComponent_Host_0 = View_BulkOperationSelectEntitiesComponent_Host_0;
var BulkOperationSelectEntitiesComponentNgFactory = i1.ɵccf("ng-component", i12.BulkOperationSelectEntitiesComponent, View_BulkOperationSelectEntitiesComponent_Host_0, {}, {}, []);
exports.BulkOperationSelectEntitiesComponentNgFactory = BulkOperationSelectEntitiesComponentNgFactory;
