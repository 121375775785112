"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var i0 = require("@angular/core");
var CustomerNamePipe = /** @class */ (function () {
    function CustomerNamePipe() {
    }
    CustomerNamePipe.prototype.transform = function (customer) {
        if (!customer) {
            return '';
        }
        return customer.companyName;
    };
    CustomerNamePipe.ngInjectableDef = i0.defineInjectable({ factory: function CustomerNamePipe_Factory() { return new CustomerNamePipe(); }, token: CustomerNamePipe, providedIn: "root" });
    return CustomerNamePipe;
}());
exports.CustomerNamePipe = CustomerNamePipe;
