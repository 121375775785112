"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./cases-change-status.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/common");
var i3 = require("../../../common/directives/loading.directive");
var i4 = require("@uirouter/angular/lib/directives/uiSref");
var i5 = require("@uirouter/core");
var i6 = require("../../../common/components/radio/radio.component.ngfactory");
var i7 = require("../../../common/components/radio/radio.component");
var i8 = require("./cases-change-status.component");
var i9 = require("../../../common/services/list.service");
var i10 = require("../../../common/services/auth.service");
var i11 = require("../../services/case-status.service");
var i12 = require("../../services/case.service");
var styles_CasesChangeStatusComponent = [i0.styles];
var RenderType_CasesChangeStatusComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CasesChangeStatusComponent, data: {} });
exports.RenderType_CasesChangeStatusComponent = RenderType_CasesChangeStatusComponent;
function View_CasesChangeStatusComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "centered-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Na\u010D\u00EDt\u00E1 se seznam p\u0159\u00EDpad\u016F."]))], null, null); }
function View_CasesChangeStatusComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "centered-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Prob\u00EDh\u00E1 vyhodnocen\u00ED dostupn\u00FDch stav\u016F p\u0159\u00EDpad\u016F a opr\u00E1vn\u011Bn\u00ED."]))], null, null); }
function View_CasesChangeStatusComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "centered-text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", "/", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.countResolved; var currVal_1 = _co.data.selectEntities.count; _ck(_v, 2, 0, currVal_0, currVal_1); }); }
function View_CasesChangeStatusComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "jumbotron"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CasesChangeStatusComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CasesChangeStatusComponent_3)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CasesChangeStatusComponent_4)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [], [[8, "className", 0]], null, null, null, null)), i1.ɵdid(8, 16384, null, 0, i3.LoadingDirective, [i1.ElementRef], { loading: [0, "loading"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.list.loading; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.list.loading; _ck(_v, 4, 0, currVal_1); var currVal_2 = !_co.list.loading; _ck(_v, 6, 0, currVal_2); var currVal_4 = true; _ck(_v, 8, 0, currVal_4); }, function (_ck, _v) { var currVal_3 = i1.ɵnov(_v, 8).elementClass; _ck(_v, 7, 0, currVal_3); }); }
function View_CasesChangeStatusComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "a", [["uiSref", "symap.project.cases.detail"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).go($event.button, $event.ctrlKey, $event.metaKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 16384, null, 0, i4.AnchorUISref, [i1.ElementRef, i1.Renderer2], null, null), i1.ɵdid(3, 737280, null, 0, i4.UISref, [i5.UIRouter, [2, i4.AnchorUISref], "UIView.PARENT_INJECT"], { state: [0, "state"], params: [1, "params"] }, null), i1.ɵpod(4, { id: 0 }), (_l()(), i1.ɵted(5, null, ["", ""]))], function (_ck, _v) { var currVal_0 = "symap.project.cases.detail"; var currVal_1 = _ck(_v, 4, 0, _v.context.$implicit.id); _ck(_v, 3, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = (_v.context.$implicit.mpp.number || "bez \u010D\u00EDsla"); _ck(_v, 5, 0, currVal_2); }); }
function View_CasesChangeStatusComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["dal\u0161\u00EDch ", " p\u0159\u00EDpad\u016F"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.inputData.noStatusGroup.length - 10); _ck(_v, 1, 0, currVal_0); }); }
function View_CasesChangeStatusComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "fieldset", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "legend", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["P\u0159\u00EDpady bez mo\u017Enosti zm\u011Bny stavu"])), (_l()(), i1.ɵeld(3, 0, null, null, 8, "div", [["class", "column-100"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["P\u0159\u00EDpady"])), (_l()(), i1.ɵeld(6, 0, null, null, 5, "div", [["class", "info comma-list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_CasesChangeStatusComponent_7)), i1.ɵdid(8, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(0, i2.SlicePipe, []), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CasesChangeStatusComponent_8)), i1.ɵdid(11, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform(_co.inputData.noStatusGroup, 0, 10)); _ck(_v, 8, 0, currVal_0); var currVal_1 = (_co.inputData.noStatusGroup.length > 10); _ck(_v, 11, 0, currVal_1); }, null); }
function View_CasesChangeStatusComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "checklist-item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setActiveNextStatus(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [], null, null, null, null, null)), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(3, { cancelled: 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 1, "gmt-radio", [], null, null, null, i6.View_RadioComponent_0, i6.RenderType_RadioComponent)), i1.ɵdid(5, 114688, null, 0, i7.RadioComponent, [], { gmtChecked: [0, "gmtChecked"] }, null), (_l()(), i1.ɵted(6, null, [" ", " (", ") "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, !_v.context.$implicit.saveEndpoint); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.inputData.group && (_co.inputData.group.caseStatus.id === _v.context.$implicit.caseStatus.id)); _ck(_v, 5, 0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.caseStatus.name; var currVal_3 = _v.context.$implicit.cases.length; _ck(_v, 6, 0, currVal_2, currVal_3); }); }
function View_CasesChangeStatusComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "fieldset", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "legend", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Zm\u011Bna stavu p\u0159\u00EDpad\u016F"])), (_l()(), i1.ɵeld(3, 0, null, null, 6, "div", [["class", "column-100"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "label", [["class", "required"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Nov\u00FD stav p\u0159\u00EDpadu"])), (_l()(), i1.ɵeld(6, 0, null, null, 3, "div", [["class", "input"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "checklist"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CasesChangeStatusComponent_10)), i1.ɵdid(9, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.inputData.groups; _ck(_v, 9, 0, currVal_0); }, null); }
function View_CasesChangeStatusComponent_13(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "a", [["uiSref", "symap.project.cases.detail"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).go($event.button, $event.ctrlKey, $event.metaKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 16384, null, 0, i4.AnchorUISref, [i1.ElementRef, i1.Renderer2], null, null), i1.ɵdid(3, 737280, null, 0, i4.UISref, [i5.UIRouter, [2, i4.AnchorUISref], "UIView.PARENT_INJECT"], { state: [0, "state"], params: [1, "params"] }, null), i1.ɵpod(4, { id: 0 }), (_l()(), i1.ɵted(5, null, ["", ""]))], function (_ck, _v) { var currVal_0 = "symap.project.cases.detail"; var currVal_1 = _ck(_v, 4, 0, _v.context.$implicit.id); _ck(_v, 3, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = (_v.context.$implicit.mpp.number || "bez \u010D\u00EDsla"); _ck(_v, 5, 0, currVal_2); }); }
function View_CasesChangeStatusComponent_14(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["dal\u0161\u00EDch ", " p\u0159\u00EDpad\u016F"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.inputData.group.cases.length - 10); _ck(_v, 1, 0, currVal_0); }); }
function View_CasesChangeStatusComponent_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "info comma-list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_CasesChangeStatusComponent_13)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(0, i2.SlicePipe, []), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CasesChangeStatusComponent_14)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.inputData.group.cases, 0, 10)); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.inputData.group.cases.length > 10); _ck(_v, 5, 0, currVal_1); }, null); }
function View_CasesChangeStatusComponent_15(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "info"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u017E\u00E1dn\u00E9 p\u0159\u00EDpady"]))], null, null); }
function View_CasesChangeStatusComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "fieldset", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "legend", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Detaily stavu"])), (_l()(), i1.ɵeld(3, 0, null, null, 6, "div", [["class", "column-100"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["P\u0159\u00EDpady"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CasesChangeStatusComponent_12)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CasesChangeStatusComponent_15)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.inputData.group.cases.length; _ck(_v, 7, 0, currVal_0); var currVal_1 = !_co.inputData.group.cases.length; _ck(_v, 9, 0, currVal_1); }, null); }
function View_CasesChangeStatusComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CasesChangeStatusComponent_6)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CasesChangeStatusComponent_9)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CasesChangeStatusComponent_11)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.inputData.noStatusGroup.length; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.inputData.groups.length; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.inputData.group; _ck(_v, 6, 0, currVal_2); }, null); }
function View_CasesChangeStatusComponent_16(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_CasesChangeStatusComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { statusViewContainerRef: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CasesChangeStatusComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CasesChangeStatusComponent_5)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [], [[4, "display", null]], null, null, null, null)), (_l()(), i1.ɵand(16777216, [[1, 3], ["statusDetails", 2]], null, 0, null, View_CasesChangeStatusComponent_16))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.countResolved !== _co.data.selectEntities.count); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.countResolved === _co.data.selectEntities.count); _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = ((_co.inputData.group && _co.inputData.group.cases.length) ? "block" : "none"); _ck(_v, 5, 0, currVal_2); }); }
exports.View_CasesChangeStatusComponent_0 = View_CasesChangeStatusComponent_0;
function View_CasesChangeStatusComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_CasesChangeStatusComponent_0, RenderType_CasesChangeStatusComponent)), i1.ɵdid(1, 4440064, null, 0, i8.CasesChangeStatusComponent, [i9.ListService, i10.AuthService, i1.ComponentFactoryResolver, "processDefinition", i11.CaseStatusService, "Restangular", i12.CaseService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_CasesChangeStatusComponent_Host_0 = View_CasesChangeStatusComponent_Host_0;
var CasesChangeStatusComponentNgFactory = i1.ɵccf("ng-component", i8.CasesChangeStatusComponent, View_CasesChangeStatusComponent_Host_0, { data: "data", notifyParent: "notifyParent" }, {}, []);
exports.CasesChangeStatusComponentNgFactory = CasesChangeStatusComponentNgFactory;
