"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var checklist_service_1 = require("@app/common/services/checklist.service");
var FormCaseSignOwnerComponent = /** @class */ (function () {
    function FormCaseSignOwnerComponent(restangular, checklistService) {
        this.restangular = restangular;
        this.checklistService = checklistService;
        this.callbackRegister = new core_1.EventEmitter();
        this.caseIndex = -1;
        this.displaySignatureVerificationPrice = false;
        this.isValid = this.isValid.bind(this);
        this.previous = this.previous.bind(this);
        this.next = this.next.bind(this);
        this.checkSubject = this.checkSubject.bind(this);
        this.onUpdate = this.onUpdate.bind(this);
    }
    FormCaseSignOwnerComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.data.responseReceivedDate = new Date();
        this.callbackRegister.emit({
            isValid: this.isValid,
        });
        this.next();
        this.displaySignatureVerificationPrice = this.cases.some(function (bCase) { return _this.signatureVerificationPriceEnabled(bCase.obligation.type); });
    };
    FormCaseSignOwnerComponent.prototype.signatureVerificationPriceEnabled = function (obligationType) {
        var whitelist = [
            'AccessToLandLiability',
            'AgreementOnAFutureNetworkConstructionContractLiability',
            'AgreementOnAFutureTransferContractLiability',
            'AgreementOnAOtherFutureContractLiability',
            'BuildingPlacementLiability',
            'BorrowingLiability',
            'OtherLiability',
            'OwnersStatementLiability',
            'RightToRealizeABuildingLiability',
            'FutureRealBurdenLiabilityAccelerated',
        ];
        return !whitelist.includes(obligationType);
    };
    FormCaseSignOwnerComponent.prototype.load = function (caseIndex) {
        return __awaiter(this, void 0, void 0, function () {
            var bcase;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        bcase = this.cases[caseIndex];
                        if (bcase.caseSubjects.length) {
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, this.restangular.one('cases', bcase.id).get({ loadCollections: ['caseSubjects', 'caseOwnerships'] })
                                .then(function (dataCase) {
                                bcase.caseSubjects = dataCase.caseSubjects.filter(function (value) { return !value.signedDate && value.subject.opsubType !== "BSM" &&
                                    dataCase.caseOwnerships.some(function (value2) {
                                        var bsmSubject;
                                        if (value2.ownership.isBsm) {
                                            bsmSubject = dataCase.caseSubjects.find(function (cs) { return cs.subject.opsubId === value2.ownership.opsubId; }).subject;
                                        }
                                        return !value2.isParent && ((!bsmSubject && value2.ownership.opsubId === value.subject.opsubId) || (bsmSubject && (bsmSubject.partner1OpsubId === value.subject.opsubId || bsmSubject.partner2OpsubId === value.subject.opsubId)));
                                    }); });
                            })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    FormCaseSignOwnerComponent.prototype.previous = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(this.caseIndex > this.firstCaseId)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.load(this.caseIndex - 1)];
                    case 1:
                        _a.sent();
                        this.caseIndex--;
                        if (this.cases[this.caseIndex].caseSubjects.length === 1) {
                            this.previous();
                        }
                        else {
                            this.subjectChecklist = this.checklistService.get(this.dataCases[this.caseIndex].caseSubjects);
                        }
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    FormCaseSignOwnerComponent.prototype.next = function () {
        return __awaiter(this, void 0, void 0, function () {
            var sentSubjects;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(this.caseIndex + 1 < this.cases.length)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.load(this.caseIndex + 1)];
                    case 1:
                        _a.sent();
                        this.caseIndex++;
                        this.dataCases[this.caseIndex] = this.dataCases[this.caseIndex] || { caseSubjects: [] };
                        this.subjectChecklist = this.checklistService.get(this.dataCases[this.caseIndex].caseSubjects);
                        sentSubjects = this.cases[this.caseIndex].caseSubjects
                            .filter(function (caseSubject) { return caseSubject.sentDate || caseSubject.personalVisitDate; });
                        if (this.dataCases[this.caseIndex].caseSubjects.length === 0) {
                            sentSubjects.forEach(this.checkSubject);
                        }
                        if (this.cases[this.caseIndex].caseSubjects.length === sentSubjects.length) {
                            this.next();
                        }
                        else {
                            if (this.firstCaseId === undefined) {
                                this.firstCaseId = this.caseIndex;
                            }
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        this.caseIndex++;
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    FormCaseSignOwnerComponent.prototype.checkSubject = function (caseSubject) {
        this.setData(this.cases[this.caseIndex].obligation.type, caseSubject);
        this.subjectChecklist.toggleSelection(caseSubject);
    };
    FormCaseSignOwnerComponent.prototype.setData = function (obligationType, caseSubject) {
        if (this.signatureVerificationPriceEnabled(obligationType)) {
            caseSubject.signatureVerificationPrice = this.signatureVerificationPrice;
        }
        caseSubject.signedDate = this.signedDate;
    };
    FormCaseSignOwnerComponent.prototype.onUpdate = function () {
        var _this = this;
        this.dataCases.forEach(function (bCase, i) {
            bCase.caseSubjects.forEach(function (caseSubject) {
                _this.setData(_this.cases[i].obligation.type, caseSubject);
            });
        });
    };
    FormCaseSignOwnerComponent.prototype.checkedSubject = function (caseSubject) {
        return this.subjectChecklist.isChecked(caseSubject);
    };
    FormCaseSignOwnerComponent.prototype.isValidCurrentCase = function () {
        return this.dataCases[this.caseIndex].caseSubjects.length;
    };
    FormCaseSignOwnerComponent.prototype.isValid = function () {
        return !!this.signedDate && !!this.data.responseReceivedDate && this.caseIndex === this.cases.length;
    };
    return FormCaseSignOwnerComponent;
}());
exports.FormCaseSignOwnerComponent = FormCaseSignOwnerComponent;
