"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var case_model_1 = require("@app/ps/models/case.model");
var FormExpropriationInterimDecisionRequestComponent = /** @class */ (function () {
    function FormExpropriationInterimDecisionRequestComponent() {
        this.callbackRegister = new core_1.EventEmitter();
        this.isValid = this.isValid.bind(this);
    }
    FormExpropriationInterimDecisionRequestComponent.prototype.ngOnInit = function () {
        this.data.expropriationInterimDecisionRequestDate = new Date();
        this.callbackRegister.emit({
            isValid: this.isValid,
        });
    };
    FormExpropriationInterimDecisionRequestComponent.prototype.isValid = function () {
        return !!this.data.expropriationInterimDecisionRequestDate;
    };
    return FormExpropriationInterimDecisionRequestComponent;
}());
exports.FormExpropriationInterimDecisionRequestComponent = FormExpropriationInterimDecisionRequestComponent;
