"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var EnumUtils = /** @class */ (function () {
    function EnumUtils() {
    }
    /**
     * Transforms each enum member into an object with:
     * enum member name represented with `withKeyName`
     * enum member value represented with `withValueName`.
     */
    EnumUtils.toObject = function (_enum, withKeyName, withValueName) {
        if (withKeyName === void 0) { withKeyName = 'id'; }
        if (withValueName === void 0) { withValueName = 'value'; }
        return Object.keys(_enum).map(function (key) {
            var tmp = {};
            tmp[withKeyName] = key;
            tmp[withValueName] = _enum[key];
            return tmp;
        });
    };
    return EnumUtils;
}());
exports.EnumUtils = EnumUtils;
