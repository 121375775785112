"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["[_nghost-%COMP%] {\n  display: flex; }\n\ndiv.scrollable[_ngcontent-%COMP%] {\n  position: relative;\n  width: 100%; }\n  div.scrollable.horizontal[_ngcontent-%COMP%]   .scrollable-inner[_ngcontent-%COMP%] {\n    display: inline-block; }"];
exports.styles = styles;
