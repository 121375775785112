"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var table_parcels_abstract_component_1 = require("../table-parcels-abstract/table-parcels-abstract.component");
var auth_service_1 = require("@app/common/services/auth.service");
var update_easement_price_component_1 = require("@app/ps/components/update-easement-price/update-easement-price.component");
var dialog_service_1 = require("@app/dialog/dialog.service");
var TableEasementsComponent = /** @class */ (function (_super) {
    __extends(TableEasementsComponent, _super);
    function TableEasementsComponent(authService, dialogService) {
        var _this = _super.call(this) || this;
        _this.authService = authService;
        _this.dialogService = dialogService;
        // props
        _this.COMPONENT_COLUMNS = [
            'area_name', 'title_name', 'parcel', 'parcel_title', 'geometric_plan', 'construction_object',
            'title', 'area', 'length', 'subject_price', 'title_price', 'case_price', 'parcel_price', 'case'
        ];
        return _this;
    }
    TableEasementsComponent.prototype.ngOnInit = function () {
        // If find some easement with property oznacVb, then set $scope.easementType to true, else false
        this.easementType = this.data && this.data.some(function (easement) { return easement.oznacVb; });
    };
    TableEasementsComponent.prototype.canEdit = function (permissions) {
        return this.authService.isAuthorized()
            || this.authService.hasPermission("assignable")
            || this.authService.hasPermission(permissions);
    };
    TableEasementsComponent.prototype.isEditablePrice = function (easement) {
        var easementGlobalPriceType = new Set(easement.constructionObjects.map(function (co) { return co.easementGlobalPriceType; })).size == 1 ?
            easement.constructionObjects[0].easementGlobalPriceType : null;
        var easementGlobalOtherUnitType = new Set(easement.constructionObjects.map(function (co) { return co.easementGlobalOtherUnitType; })).size == 1 ?
            easement.constructionObjects[0].easementGlobalOtherUnitType : null;
        // @TODO - refactor
        return easement.parcel.title
            && (this.authService.isAuthorized() || this.authService.hasPermission("assignable"))
            && (easementGlobalPriceType === 'I'
                || (easementGlobalPriceType === 'B'
                    && easementGlobalOtherUnitType === 'S'
                    && easement.acceleratedCaseStatus === 'DeclinedOwner'))
            && !easement.existsCaseBlockingPrice;
    };
    TableEasementsComponent.prototype.onPriceEdit = function (easement) {
        if (!this.isEditablePrice(easement)) {
            return;
        }
        var dialog = this.dialogService.open(update_easement_price_component_1.UpdateEasementPriceComponent, {
            data: { easementId: easement.id },
        });
        var sub = dialog.afterClosed.subscribe(function (updatedEasement) {
            if (updatedEasement) {
                easement.easementPrice = updatedEasement.easementPrice;
            }
            sub.unsubscribe();
        });
    };
    return TableEasementsComponent;
}(table_parcels_abstract_component_1.TableParcelsAbstractComponent));
exports.TableEasementsComponent = TableEasementsComponent;
