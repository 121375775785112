"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./labeled-block.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/common");
var i3 = require("./labeled-block.component");
var styles_LabeledBlockComponent = [i0.styles];
var RenderType_LabeledBlockComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LabeledBlockComponent, data: {} });
exports.RenderType_LabeledBlockComponent = RenderType_LabeledBlockComponent;
function View_LabeledBlockComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "bordered block-ellipsis"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onToggleCollapsed() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fa fa-ellipsis-h"]], null, null, null, null, null))], null, null); }
function View_LabeledBlockComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["style", "position:relative;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [], null, null, null, null, null)), i1.ɵncd(null, 0)], null, null); }
function View_LabeledBlockComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "mb-20"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "a", [["class", "block-label"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onToggleCollapsed() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(2, null, ["", " "])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "span", [["class", "arrow"]], null, null, null, null, null)), i1.ɵdid(4, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(5, { "active": 0 }), (_l()(), i1.ɵeld(6, 0, null, null, 0, "div", [["class", "bottom-corner"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LabeledBlockComponent_1)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LabeledBlockComponent_2)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "arrow"; var currVal_2 = _ck(_v, 5, 0, !_co.collapsed); _ck(_v, 4, 0, currVal_1, currVal_2); var currVal_3 = _co.collapsed; _ck(_v, 8, 0, currVal_3); var currVal_4 = !_co.collapsed; _ck(_v, 10, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.label; _ck(_v, 2, 0, currVal_0); }); }
exports.View_LabeledBlockComponent_0 = View_LabeledBlockComponent_0;
function View_LabeledBlockComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "gmt-labeled-block", [], null, null, null, View_LabeledBlockComponent_0, RenderType_LabeledBlockComponent)), i1.ɵdid(1, 49152, null, 0, i3.LabeledBlockComponent, [], null, null)], null, null); }
exports.View_LabeledBlockComponent_Host_0 = View_LabeledBlockComponent_Host_0;
var LabeledBlockComponentNgFactory = i1.ɵccf("gmt-labeled-block", i3.LabeledBlockComponent, View_LabeledBlockComponent_Host_0, { label: "label", collapsed: "collapsed" }, { changed: "changed" }, ["*"]);
exports.LabeledBlockComponentNgFactory = LabeledBlockComponentNgFactory;
