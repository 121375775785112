"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./project-columns-edit.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../../../common/components/checkbox/checkbox.component.ngfactory");
var i3 = require("../../../common/components/checkbox/checkbox.component");
var i4 = require("@angular/common");
var i5 = require("../../../common/components/button/button.component.ngfactory");
var i6 = require("../../../common/components/button/button.component");
var i7 = require("./project-columns-edit.component");
var i8 = require("../../../dialog/dialog-config");
var i9 = require("../../../dialog/dialog-ref");
var styles_ProjectColumnsEditComponent = [i0.styles];
var RenderType_ProjectColumnsEditComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProjectColumnsEditComponent, data: {} });
exports.RenderType_ProjectColumnsEditComponent = RenderType_ProjectColumnsEditComponent;
function View_ProjectColumnsEditComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "checklist-item cb"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "a", [["class", "fl mr-10"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onMoveColumn(_v.parent.context.$implicit, (0 - 1)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "fa fa-arrow-up"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "a", [["class", "fl mr-10"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onMoveColumn(_v.parent.context.$implicit, (1 - 0)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [["class", "fa fa-arrow-down"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "mr-10"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onToggleColumn(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "gmt-checkbox", [["class", "item-checkbox"]], null, null, null, i2.View_CheckboxComponent_0, i2.RenderType_CheckboxComponent)), i1.ɵdid(7, 114688, null, 0, i3.CheckboxComponent, [], { gmtChecked: [0, "gmtChecked"] }, null), (_l()(), i1.ɵted(8, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isColumnEnabled(_v.parent.context.$implicit); _ck(_v, 7, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = _v.parent.context.$implicit.name; _ck(_v, 8, 0, currVal_1); }); }
function View_ProjectColumnsEditComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProjectColumnsEditComponent_2)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = ((_v.context.$implicit.selectable === undefined) || (_v.context.$implicit.selectable === true)); _ck(_v, 2, 0, currVal_0); }, null); }
function View_ProjectColumnsEditComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["V\u00FDb\u011Br zobrazen\u00FDch sloupc\u016F"])), (_l()(), i1.ɵeld(2, 0, null, null, 3, "fieldset", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "bordered column-100 checklist"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProjectColumnsEditComponent_1)), i1.ɵdid(5, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 6, "div", [["class", "text-center pt-20"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "gmt-button", [], null, null, null, i5.View_ButtonComponent_0, i5.RenderType_ButtonComponent)), i1.ɵdid(8, 114688, null, 0, i6.ButtonComponent, ["ErrorHandlerService", i1.ChangeDetectorRef], { clickAction: [0, "clickAction"], gmtDisabled: [1, "gmtDisabled"] }, null), (_l()(), i1.ɵted(-1, 0, ["Ulo\u017Eit"])), (_l()(), i1.ɵeld(10, 0, null, null, 2, "gmt-button", [["class", "red"]], null, null, null, i5.View_ButtonComponent_0, i5.RenderType_ButtonComponent)), i1.ɵdid(11, 114688, null, 0, i6.ButtonComponent, ["ErrorHandlerService", i1.ChangeDetectorRef], { clickAction: [0, "clickAction"] }, null), (_l()(), i1.ɵted(-1, 0, ["Zru\u0161it"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.columns; _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.onUpdate; var currVal_2 = !_co.isValid(); _ck(_v, 8, 0, currVal_1, currVal_2); var currVal_3 = _co.onCancel; _ck(_v, 11, 0, currVal_3); }, null); }
exports.View_ProjectColumnsEditComponent_0 = View_ProjectColumnsEditComponent_0;
function View_ProjectColumnsEditComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "project-columns-edit", [], null, null, null, View_ProjectColumnsEditComponent_0, RenderType_ProjectColumnsEditComponent)), i1.ɵdid(1, 114688, null, 0, i7.ProjectColumnsEditComponent, [i8.DialogConfig, i9.DialogRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_ProjectColumnsEditComponent_Host_0 = View_ProjectColumnsEditComponent_Host_0;
var ProjectColumnsEditComponentNgFactory = i1.ɵccf("project-columns-edit", i7.ProjectColumnsEditComponent, View_ProjectColumnsEditComponent_Host_0, {}, {}, []);
exports.ProjectColumnsEditComponentNgFactory = ProjectColumnsEditComponentNgFactory;
