"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var i0 = require("@angular/core");
var RentalTypePipe = /** @class */ (function () {
    function RentalTypePipe() {
    }
    RentalTypePipe.prototype.transform = function (rentalType) {
        switch (rentalType) {
            case 'L':
                return 'Pronájem';
            case 'T':
                return 'Pacht';
            default:
                return '';
        }
    };
    RentalTypePipe.ngInjectableDef = i0.defineInjectable({ factory: function RentalTypePipe_Factory() { return new RentalTypePipe(); }, token: RentalTypePipe, providedIn: "root" });
    return RentalTypePipe;
}());
exports.RentalTypePipe = RentalTypePipe;
