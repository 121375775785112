"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var menuConfig_1 = require("@app/models/menuConfig");
var auth_service_1 = require("@app/common/services/auth.service");
var status_service_1 = require("@app/pk/services/status.service");
var MainComponent = /** @class */ (function () {
    function MainComponent(authService, statusService, applications, localStorageService, modulesService, approveTermsService, changePasswordService, googleAnalyticsService, ENV) {
        this.authService = authService;
        this.statusService = statusService;
        this.applications = applications;
        this.localStorageService = localStorageService;
        this.modulesService = modulesService;
        this.approveTermsService = approveTermsService;
        this.changePasswordService = changePasswordService;
        this.googleAnalyticsService = googleAnalyticsService;
        this.ENV = ENV;
        this.collapsed = false;
        this.menuConfig = [];
        this.servicedeskUrl = undefined;
    }
    MainComponent.prototype.ngOnInit = function () {
        this.authService.setActiveApplication(this.applications.settings.name);
        this.authService.updateBaseUrl();
        this.authService.updateAuthorizationHeader();
        this.googleAnalyticsService.onUserCome();
        this.collapsed = this.localStorageService.get('menuOpened');
        this.servicedeskUrl = this.authService.getServiceDeskUrl();
        this.user = this.authService.getUser();
        this.menuConfig = this.getMenuConfig();
    };
    MainComponent.prototype.onCollapse = function () {
        this.collapsed = !this.collapsed;
        this.localStorageService.set('menuOpened', this.collapsed);
    };
    MainComponent.prototype.logout = function () {
        this.authService.logout();
    };
    MainComponent.prototype.getMenuConfig = function () {
        return [
            new menuConfig_1.MenuConfig('Moduly', 'settings.modules', 'fa-cubes'),
            new menuConfig_1.MenuConfig('Uživatelé', 'settings.users', 'fa-users'),
            new menuConfig_1.MenuConfig('Objednatelé', 'settings.customers', 'fa-handshake-o'),
        ];
    };
    return MainComponent;
}());
exports.MainComponent = MainComponent;
