"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var parcels_states_1 = require("./parcels.states");
var PARCELS_STATES = [
    parcels_states_1.parcelsState,
    parcels_states_1.parcelDetailState,
    parcels_states_1.updateOccupationParcelState,
];
var ParcelsModule = /** @class */ (function () {
    function ParcelsModule() {
    }
    return ParcelsModule;
}());
exports.ParcelsModule = ParcelsModule;
