"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var numeric_pipe_1 = require("@app/common/pipes/numeric.pipe");
var forms_1 = require("@angular/forms");
var InputNumberDirective = /** @class */ (function () {
    function InputNumberDirective(ngControl, numericPipe) {
        this.ngControl = ngControl;
        this.numericPipe = numericPipe;
    }
    InputNumberDirective.prototype.onBlur = function () {
        this.ngControl.control.setValue(this.numericPipe.transform(this.ngControl.control.value));
    };
    return InputNumberDirective;
}());
exports.InputNumberDirective = InputNumberDirective;
