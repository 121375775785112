"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var _ = require("lodash");
var parcel_service_1 = require("@app/ps/services/parcel.service");
var auth_service_1 = require("@app/common/services/auth.service");
var table_utils_1 = require("@app/common/utils/table.utils");
var case_service_1 = require("@app/ps/services/case.service");
var list_service_1 = require("@app/common/services/list.service");
var username_pipe_1 = require("@app/common/pipes/username.pipe");
var case_status_service_1 = require("@app/ps/services/case-status.service");
var angular_1 = require("@uirouter/angular");
var tooltip_parcel_component_1 = require("@app/common/components/tooltip-parcel/tooltip-parcel.component");
var callback_model_1 = require("@app/ps/models/callback.model");
var callbacks_enum_1 = require("@app/ps/enums/callbacks.enum");
var dialog_service_1 = require("@app/dialog/dialog.service");
var settings_service_1 = require("@app/ps/services/settings.service");
var form_case_update_parcel_price_component_1 = require("@app/ps/cases/components/form-case-update-parcel-price/form-case-update-parcel-price.component");
var form_case_update_subject_component_1 = require("@app/ps/cases/components/form-case-update-subject/form-case-update-subject.component");
var CaseDetailComponent = /** @class */ (function () {
    function CaseDetailComponent(restangular, helpIds, confirmService, errorHandlerService, userNamePipe, caseStatusService, authService, caseService, parcelService, listService, dialogService, stateService, settingsService) {
        this.restangular = restangular;
        this.helpIds = helpIds;
        this.confirmService = confirmService;
        this.errorHandlerService = errorHandlerService;
        this.userNamePipe = userNamePipe;
        this.caseStatusService = caseStatusService;
        this.authService = authService;
        this.caseService = caseService;
        this.parcelService = parcelService;
        this.listService = listService;
        this.dialogService = dialogService;
        this.stateService = stateService;
        this.settingsService = settingsService;
        this.loading = true;
        this.sending = false;
        this.userSending = false;
        this.mandatarySending = false;
        this.actionButtons = [];
        this.statusActions = [];
        this.restBaseUrl = this.authService.getActiveApplicationRestUrl();
        this.authToken = this.authService.getToken();
        this.loggedUser = this.authService.getUser();
        this.tooltipParcelComponent = tooltip_parcel_component_1.TooltipParcelComponent;
        this.notificationCounts = { archived: undefined, unarchived: undefined };
        this.occupationLoading = true;
        this.easementsLoading = true;
        this.mandataryFilter = {
            filters: {
                searchText: {
                    values: [],
                },
                applications: {
                    values: [this.authService.getActiveApplication()],
                },
                permission: {
                    values: ['assignable'],
                },
                projects: {
                    values: [this.authService.getActualProject().key],
                },
            },
            sortOrder: [
                { sortBy: 'surname' },
                { sortBy: 'name' },
            ],
        };
        this.tabs = [
            {
                name: 'Vlastníci',
                id: 'owners',
                href: 'symap.project.cases.detail',
                urlParams: { tab: 'owners' },
            },
            {
                name: 'Podrobné informace',
                id: 'detailInfo',
                href: 'symap.project.cases.detail',
                urlParams: { tab: 'detailInfo' },
            },
            {
                name: 'Parcely',
                id: 'parcels',
                href: 'symap.project.cases.detail',
                urlParams: { tab: 'parcels' },
            },
            {
                name: 'Poznámky',
                id: 'notes',
                href: 'symap.project.cases.detail',
                urlParams: { tab: 'notes' },
            },
            {
                name: 'Historie',
                id: 'history',
                href: 'symap.project.cases.detail',
                urlParams: { tab: 'history' },
            },
            {
                name: 'Upozornění',
                id: 'notifications',
                href: 'symap.project.cases.detail',
                urlParams: { tab: 'notifications' },
            },
            {
                name: 'Dokumenty',
                id: 'documents',
                href: 'symap.project.cases.detail',
                urlParams: { tab: 'documents' },
            },
        ];
        this.EXT_PARCELS_COLUMNS = [
            { id: 'parcel', sortable: false },
            { id: 'title', sortable: false },
            { id: 'area_name', sortable: false },
            { id: 'area', sortable: false },
            { id: 'parcel_price', sortable: false },
        ];
        this.allowExpropriation = true;
        this.cancel = this.cancel.bind(this);
        this.backStatus = this.backStatus.bind(this);
        this.isThreeParty = this.isThreeParty.bind(this);
        this.canEdit = this.canEdit.bind(this);
        this.onUpdateNoteList = this.onUpdateNoteList.bind(this);
        this.onUpdateHistoryList = this.onUpdateHistoryList.bind(this);
        this.onUpdateAttachmentsList = this.onUpdateAttachmentsList.bind(this);
        this.onUpdateOwnersList = this.onUpdateOwnersList.bind(this);
        this.onUpdateParcelsList = this.onUpdateParcelsList.bind(this);
        this.onUpdateNotificationList = this.onUpdateNotificationList.bind(this);
        this.caseUpdated = this.caseUpdated.bind(this);
        this.caseSubjectUpdated = this.caseSubjectUpdated.bind(this);
        this.caseParcelPriceUpdated = this.caseParcelPriceUpdated.bind(this);
        this.updateCaseSubject = this.updateCaseSubject.bind(this);
        this.updateCaseOwnership = this.updateCaseOwnership.bind(this);
        this.loadSetting = this.loadSetting.bind(this);
        this.filterActionButtons = this.filterActionButtons.bind(this);
        this.updateParcelPriceDialogFunction = this.updateParcelPriceDialogFunction.bind(this);
        this.updateCaseSubjectDialogFunction = this.updateCaseSubjectDialogFunction.bind(this);
        this.getBtnActionFunction = this.getBtnActionFunction.bind(this);
        this.tab = (this.stateService.params.tab ? this.stateService.params.tab : 'owners');
        this.isActiveTab = this.isActiveTab.bind(this);
    }
    CaseDetailComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.loadCase()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.loadSetting()];
                    case 2:
                        _a.sent();
                        this.prepareParcelColumns();
                        return [4 /*yield*/, this.loadOccupations()];
                    case 3:
                        _a.sent();
                        return [4 /*yield*/, this.loadEasements()];
                    case 4:
                        _a.sent();
                        this.onUpdateOwnersList();
                        this.onUpdateParcelsList();
                        this.loadAttachments();
                        this.loadNotes();
                        this.loadHistory();
                        this.loadNotifications();
                        this.filterActionButtons();
                        this.registerCallbacks();
                        this.loading = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    CaseDetailComponent.prototype.ngOnDestroy = function () {
        this.removeCallbacks();
    };
    CaseDetailComponent.prototype.isActiveTab = function (id) {
        return id === this.tab;
    };
    CaseDetailComponent.prototype.uiOnParamsChanged = function (changedParams) {
        this.tab = changedParams.tab;
    };
    CaseDetailComponent.prototype.void = function () { };
    CaseDetailComponent.prototype.cancel = function () {
        var _this = this;
        return new this.confirmService({
            title: 'Potvrzení zrušení případu',
            message: 'Opravdu chcete případ zrušit?'
        }).then(function (data) {
            if (data === true) {
                _this.sending = true;
                _this.restangular.one('cases', _this.caseId).customPUT({}, 'cancel').then(function (data) {
                    delete data.constructionObjects;
                    delete data.caseBuildings;
                    delete data.titles;
                    Object.assign(_this.data, data.plain());
                    _this.loadHistory();
                    _this.loadSetting().then(_this.filterActionButtons);
                    _this.sending = false;
                });
            }
        });
    };
    CaseDetailComponent.prototype.backStatus = function () {
        var _this = this;
        if (!this.sending) {
            return new this.confirmService({
                title: 'Potvrzení vrácení stavu',
                message: 'Opravdu vrátit případ do předcházejícího stavu?'
            }).then(function (data) {
                if (data === true) {
                    _this.sending = true;
                    _this.restangular.one('cases', _this.caseId)
                        .customPUT({}, 'back', { loadCollections: ['caseSubjects'] })
                        .then(function (data) {
                        delete data.constructionObjects;
                        delete data.titles;
                        Object.assign(_this.data, data.plain());
                        _this.sending = false;
                        _this.dataCaseOwnerships.forEach(function (item) {
                            var bsmSubject;
                            if (item.ownership.isBsm) {
                                bsmSubject = _this.data.caseSubjects.find(function (value) { return value.subject.opsubId === item.ownership.opsubId; }).subject;
                            }
                            item.caseSubjects = _this.data.caseSubjects.filter(function (value) { return (!bsmSubject && value.subject.opsubId === item.ownership.opsubId) || (bsmSubject && (bsmSubject.partner1OpsubId === value.subject.opsubId || bsmSubject.partner2OpsubId === value.subject.opsubId)); });
                        });
                        _this.dataRecipients = _this.caseService.getRecipients(_this.dataCaseOwnerships);
                        _this.loadHistory();
                        _this.loadSetting().then(_this.filterActionButtons);
                    }, new _this.errorHandlerService());
                }
                _this.sending = false;
            });
        }
    };
    CaseDetailComponent.prototype.isThreeParty = function () {
        return this.hasAdministrator() && this.hasNotInvestorOwner();
    };
    CaseDetailComponent.prototype.canEdit = function () {
        return this.authService.isAuthorized() || this.authService.hasPermission("assignable");
    };
    CaseDetailComponent.prototype.onUpdateNoteList = function () {
        var noteTab = this.tabs.find(function (tab) { return tab.id === 'notes'; });
        noteTab.name = "Pozn\u00E1mky (" + this.noteList.list.filter(function (a) { return !a.cancelled; }).length + ")";
    };
    CaseDetailComponent.prototype.onUpdateHistoryList = function () {
        var historyTab = this.tabs.find(function (tab) { return tab.id === 'history'; });
        historyTab.name = "Historie (" + this.historyList.itemCount + ")";
    };
    CaseDetailComponent.prototype.onUpdateAttachmentsList = function () {
        var attachmentsTab = this.tabs.find(function (tab) { return tab.id === 'documents'; });
        attachmentsTab.name = "Dokumenty (" + this.attachmentList.list.filter(function (a) { return !a.cancelled; }).length + ")";
    };
    CaseDetailComponent.prototype.onUpdateOwnersList = function () {
        var ownersTab = this.tabs.find(function (tab) { return tab.id === 'owners'; });
        ownersTab.name = "Vlastn\u00EDci (" + this.data.countOwnerships + ")";
    };
    CaseDetailComponent.prototype.onUpdateParcelsList = function () {
        var parcelTab = this.tabs.find(function (tab) { return tab.id === 'parcels'; });
        parcelTab.name = "Parcely (" + (this.data.caseParcels.length + this.data.caseBuildings.length) + ")";
    };
    CaseDetailComponent.prototype.onUpdateNotificationList = function () {
        var _this = this;
        var filter = {
            limit: 1,
            filters: {
                caseId: this.caseId,
                archive: false
            }
        };
        var archivedFilter = __assign({}, filter, { filters: __assign({}, filter.filters, { archive: true }) });
        var notificationList = this.listService.createList('notifications', filter);
        var notificationListArchived = this.listService.createList('notifications', archivedFilter);
        this.listService.fetchResult(notificationList).then(function (data) {
            _this.notificationCounts.unarchived = data.itemCount;
            _this.updateNotificationTab();
        });
        this.listService.fetchResult(notificationListArchived).then(function (data) {
            _this.notificationCounts.archived = data.itemCount;
            _this.updateNotificationTab();
        });
    };
    CaseDetailComponent.prototype.updateParcelPriceDialogFunction = function (params) {
        var _this = this;
        var data = { caseId: this.caseId };
        Object.assign(data, params);
        var dialog = this.dialogService.open(form_case_update_parcel_price_component_1.FormCaseUpdateParcelPriceComponent, {
            data: data
        });
        var sub = dialog.afterClosed.subscribe(function (res) {
            if (res) {
                _this.caseParcelPriceUpdated(res);
            }
            sub.unsubscribe();
        });
    };
    CaseDetailComponent.prototype.updateCaseSubjectDialogFunction = function (params) {
        var _this = this;
        var dialog = this.dialogService.open(form_case_update_subject_component_1.FormCaseUpdateSubjectComponent, {
            data: params
        });
        var sub = dialog.afterClosed.subscribe(function (res) {
            if (res.caseSubject && res.caseOwnership) {
                _this.caseSubjectUpdated(res);
            }
            sub.unsubscribe();
        });
    };
    CaseDetailComponent.prototype.loadCase = function () {
        var _this = this;
        return this.restangular.one('cases', this.caseId).get({ loadCollections: [
                'caseBuildings',
                'caseBuildings.parcelsWithEasementOrOccupation',
                'caseBuildings.parcels.parcelProtections',
                'caseBuildings.buildingProtections',
                'caseParcels',
                'caseOccupations',
                'caseEasements',
                'constructionObjects',
                'parcelsExternal',
                'caseSubjects',
                'caseSubjects.actingPersons',
                'caseOwnerships',
                'caseTransferErrors',
                'titles',
                'fromCase',
                'nextCase'
            ] }).then(function (data) {
            _this.data = data.plain();
            _this.showEasementDistributionSystemParts = _this.caseService.showEasementDistributionSystemParts(_this.data.obligation);
            _this.caseTransferErrorsData = _this.data.caseTransferErrors;
            _this.dataConstructionObjects = _this.data.constructionObjects;
            _this.dataCaseOwnerships = _this.caseService.getDataCaseOwnerships(_this.data.caseOwnerships, _this.data.caseSubjects);
            _this.dataCaseSubjects = _this.data.caseSubjects.filter(function (caseSubject) {
                return _this.dataCaseOwnerships.some(function (caseOwnership) {
                    return caseOwnership.ownership.opsubId === caseSubject.subject.opsubId;
                });
            });
            _this.dataRecipients = _this.caseService.getRecipients(_this.dataCaseOwnerships);
            _this.notificationsReadonly = false;
            if (_this.data.parcelsExternal && _this.data.parcelsExternal.length) {
                CaseDetailComponent.PARCELS_EXTERNAL_TAB.name = "Parcely investora (" + _this.data.parcelsExternal.length + ")";
                _this.tabs.splice(3, 0, CaseDetailComponent.PARCELS_EXTERNAL_TAB);
            }
        });
    };
    CaseDetailComponent.prototype.loadSetting = function () {
        var _this = this;
        return Promise.all([
            this.caseStatusService.loadStatuses(),
            this.restangular.one('cases', this.caseId).one('setting').get()
        ]).then(function (data) {
            _this.caseStatuses = data[0];
            _this.nextStates = data[1].nextStates.items;
            _this.documents = data[1].documents.items;
            _this.allowCadastreEntryProposal = data[1].allowCadastreEntryProposal;
            _this.caseStatusService.loadStatusName(data[1].previousState).then(function (caseStatus) {
                _this.previousState = caseStatus ? caseStatus.name : null;
            });
        });
    };
    CaseDetailComponent.prototype.loadOccupations = function () {
        var _this = this;
        return this.restangular.all('occupations').customPOST({
            filter: { filters: { caseId: [this.caseId], loadCollections: ['parcel.knBudouc.currentParcels', 'constructionObjects', 'rentalExpert'] } }
        }).then(function (data) {
            _this.dataOccupations = data;
            _this.dataOccupations.forEach(function (item) {
                item.constructionObjects = item.constructionObjects.filter(function (co) { return !item.constructionObjects.some(function (co2) { return co2.id > co.id && co2.socis === co.socis && co2.sousek === co.sousek; }); });
            });
            _this.attachParcels();
        });
    };
    CaseDetailComponent.prototype.loadEasements = function () {
        var _this = this;
        return this.restangular.all('easements').customPOST({
            filter: { filters: { caseId: [this.caseId] } },
            attributes: { constructionObjects: {
                    load: true,
                    attributes: {
                        administrator: { load: true },
                    }
                } }
        }).then(function (data) {
            _this.dataEasements = data;
            _this.easementsLoading = false;
            _this.attachParcels();
        });
    };
    CaseDetailComponent.prototype.loadAttachments = function () {
        var _this = this;
        this.attachmentList = this.listService.createList('attachments', { limit: null, filters: { caseId: this.caseId, cancelled: false }, sortOrder: { sortBy: 'timestamp', direction: 'desc' } });
        this.listService.fetchResult(this.attachmentList).then(function () {
            _this.onUpdateAttachmentsList();
        });
    };
    CaseDetailComponent.prototype.loadNotes = function () {
        var _this = this;
        this.noteList = this.listService.createList('notes', {
            filters: {
                caseIdRelated: this.caseId,
                systemType: false,
                cancelled: false,
            },
            sortOrder: {
                sortBy: 'timestamp',
                direction: 'desc'
            },
        }, undefined, {
            title: {
                load: true
            },
            subject: {
                load: true
            },
            parcel: {
                load: true
            }
        });
        this.listService.fetchResult(this.noteList).then(function () {
            _this.onUpdateNoteList();
        });
    };
    CaseDetailComponent.prototype.loadHistory = function () {
        var _this = this;
        if (!this.historyList) {
            this.historyList = this.listService.createList('notes', { filters: { caseId: this.caseId, systemType: true }, sortOrder: { sortBy: 'timestamp', direction: 'desc' } });
        }
        this.listService.fetchResult(this.historyList).then(function () {
            _this.onUpdateHistoryList();
        });
    };
    CaseDetailComponent.prototype.loadNotifications = function () {
        var _this = this;
        this.notificationList = this.listService.createList('notifications', {
            filters: {
                caseId: this.caseId,
                loadCollections: ['knBudouc.futureParcel'],
                archive: false
            },
            sortOrder: { sortBy: 'createdTimestamp', direction: 'desc' }
        });
        this.listService.fetchResult(this.notificationList).then(function () {
            _this.onUpdateNotificationList();
        });
    };
    CaseDetailComponent.prototype.updateNotificationTab = function () {
        var notificationTab = this.tabs.find(function (tab) { return tab.id === 'notifications'; });
        if (this.notificationCounts.unarchived !== undefined && this.notificationCounts.archived !== undefined) {
            notificationTab.name = "Upozorn\u011Bn\u00ED (" + this.notificationCounts.unarchived + "/" + this.notificationCounts.archived + ")";
        }
        else {
            notificationTab.name = 'Upozornění';
        }
    };
    CaseDetailComponent.prototype.prepareParcelColumns = function () {
        var occupationPermanentColumns = __spread(CaseDetailComponent.OCCUPATION_PERMANENT_COLUMNS);
        var occupationUnderOneYearColumns = __spread(CaseDetailComponent.OCCUPATION_UNDER_YEAR_COLUMNS);
        var occupationOverOneYearColumns = __spread(CaseDetailComponent.OCCUPATION_OVER_YEAR_COLUMNS);
        var easementColumns = __spread(CaseDetailComponent.EASEMENT_COLUMNS);
        if (this.data.titles.length > 1) {
            var titleColumn = { id: 'occupation_title', sortable: true };
            occupationPermanentColumns.push(titleColumn);
            occupationUnderOneYearColumns.push(titleColumn);
            occupationOverOneYearColumns.push(titleColumn);
            easementColumns.push({ id: 'title_name', sortable: true });
            var areaColumn = { id: 'occupation_area', sortable: true };
            occupationPermanentColumns.push(areaColumn);
            occupationUnderOneYearColumns.push(areaColumn);
            occupationOverOneYearColumns.push(areaColumn);
            easementColumns.push({ id: 'area_name', sortable: true });
        }
        if (!CaseDetailComponent.FORBIDER_PERMANENT.includes(this.data.obligation.type)) {
            occupationPermanentColumns = occupationPermanentColumns.concat(CaseDetailComponent.OCCUPATION_PERMANENT_PRICE_COLUMNS);
        }
        if (!CaseDetailComponent.FORBIDER_TEMPORARY.includes(this.data.obligation.type)) {
            occupationUnderOneYearColumns = occupationUnderOneYearColumns.concat(CaseDetailComponent.OCCUPATION_UNDER_YEAR_PRICE_COLUMNS);
            occupationOverOneYearColumns = occupationOverOneYearColumns.concat(CaseDetailComponent.OCCUPATION_OVER_YEAR_PRICE_COLUMNS);
        }
        if (!this.authService.getActualProject().easementPriceByShare && !CaseDetailComponent.FORBIDER_EASEMENT.includes(this.data.obligation.type)) {
            easementColumns = easementColumns.concat(CaseDetailComponent.EASEMENT_PRICE_COLUMNS);
        }
        this.occupationPermanentColumns = table_utils_1.TableUtils.repairColumnsStructure(occupationPermanentColumns);
        this.occupationUnderOneYearColumns = table_utils_1.TableUtils.repairColumnsStructure(occupationUnderOneYearColumns);
        this.occupationOverOneYearColumns = table_utils_1.TableUtils.repairColumnsStructure(occupationOverOneYearColumns);
        this.easementColumns = table_utils_1.TableUtils.repairColumnsStructure(easementColumns);
    };
    CaseDetailComponent.prototype.attachParcels = function () {
        var _this = this;
        if (this.easementsLoading || !this.dataOccupations || !this.data) {
            return;
        }
        this.dataOccupationGroups = {};
        this.dataOccupations.forEach(function (occupation) {
            var title = occupation.parcel ? occupation.parcel.title : null;
            occupation.caseParcel = _.find(_this.data.caseParcels, function (caseParcel) {
                return caseParcel.parcel.idpar === occupation.zabidpar;
            });
            if (occupation.caseParcel) {
                occupation.caseParcel.parcel.titles = occupation.caseParcel.parcel.title || title;
            }
            occupation.caseOccupation = _.find(_this.data.caseOccupations, function (caseOccupation) {
                return (caseOccupation.occupation.zabtyp === occupation.zabtyp
                    && caseOccupation.occupation.zabst === occupation.zabst
                    && caseOccupation.occupation.zabcis === occupation.zabcis);
            });
        });
        this.occupationLoading = false;
        this.dataOccupationGroups = this.parcelService.groupOccupationsByParcel(_.filter(this.dataOccupations, function (occupation) { return !!occupation.caseParcel; }), null);
        _.forEach(this.dataOccupationGroups, function (group) {
            group.caseId = _this.caseId;
        });
        this.occupationUnderYearTableName = this.parcelService.getTemporaryOccupationName(this.dataOccupationGroups.underOneYear);
        this.occupationOverYearTableName = this.parcelService.getTemporaryOccupationName(this.dataOccupationGroups.overOneYear);
        this.dataEasements.forEach(function (easement) {
            var title = easement.parcel ? easement.parcel.title : null;
            easement.caseParcel = _.find(_this.data.caseParcels, function (caseParcel) {
                return caseParcel.parcel.idpar === easement.idpar;
            });
            if (!easement.caseParcel) {
                return;
            }
            easement.caseEasement = _this.data.caseEasements.find(function (caseEasement) { return caseEasement.easement.id === easement.id; });
            easement.caseParcel.parcel.titles = easement.caseParcel.parcel.title || title;
        });
    };
    CaseDetailComponent.prototype.filterActionButtons = function () {
        var _this = this;
        this.statusActions = _.map(_.filter(this.caseService.getActionButtons(), function (action) {
            return _.includes(_this.nextStates, action.caseStatus);
        }), function (action) {
            return {
                key: action.key,
                click: action.click ? _this.getBtnActionFunction(action.click) : undefined,
                icon: action.icon || 'fa-plus-square',
                name: action.name ? action.name : _.find(_this.caseStatuses, { key: action.caseStatus }).name
            };
        });
        this.actionButtons = _.map(_.filter(this.caseService.getGenerateButtons(this.data.obligation.type), function (action) {
            return _.some(action.templates, function (template) { return _.includes(_this.documents, template); });
        }), function (action) {
            return {
                key: action.key,
                click: action.click ? _this.getBtnActionFunction(action.click) : undefined,
                icon: action.icon || 'fa-files-o',
                name: action.name ? action.name : _.find(_this.caseStatuses, { key: action.caseStatus }).name
            };
        }).concat(this.actionButtons);
    };
    CaseDetailComponent.prototype.getBtnActionFunction = function (action) {
        var _this = this;
        switch (action) {
            case 'cancel':
                return function () { _this.cancel(); };
        }
    };
    CaseDetailComponent.prototype.hasNotInvestorOwner = function () {
        var _this = this;
        var result = false;
        _.forEach(this.data.caseOwnerships, function (caseOwnership) {
            if (!caseOwnership.isParent &&
                (!_this.authService.getActualProject().investor || caseOwnership.ownership.opsubId !== _this.authService.getActualProject().investor.opsubId)) {
                result = true;
            }
        });
        return result;
    };
    CaseDetailComponent.prototype.hasAdministrator = function () {
        return (this.data &&
            (this.data.obligation.type === 'RealBurdenLiability' ||
                this.data.obligation.type === 'AgreementOnAFutureNetworkConstructionContractLiability' ||
                this.data.obligation.type === 'RealBurdenLiabilityAccelerated' ||
                this.data.obligation.type === 'FutureRealBurdenLiabilityAccelerated')) &&
            // @ts-ignore
            this.data.constructionObjects[0].administratorType === 'A';
    };
    // Callbacks
    CaseDetailComponent.prototype.registerCallbacks = function () {
        this.callbacks.add(callbacks_enum_1.Callbacks.CaseUpdated, this.caseUpdated);
    };
    CaseDetailComponent.prototype.removeCallbacks = function () {
        this.callbacks.remove(callbacks_enum_1.Callbacks.CaseUpdated, this.caseUpdated);
    };
    CaseDetailComponent.prototype.caseUpdated = function (data) {
        if (data !== undefined) {
            delete data.constructionObjects;
            delete data.caseBuildings;
            delete data.titles;
            // delete previous contract register related values not to duplicate them on frontend
            delete this.data.contractRegisterPublicationContractId;
            delete this.data.contractRegisterPublicationDate;
            delete this.data.contractRegisterPublicationException;
            delete this.data.contractRegisterPublicationNecessary;
            delete this.data.contractRegisterPublicationVersionId;
            Object.assign(this.data, data);
            if (data.caseOwnerships.length && data.caseSubjects.length) {
                this.dataCaseOwnerships = this.caseService.getDataCaseOwnerships(data.caseOwnerships, this.data.caseSubjects);
                this.dataRecipients = this.caseService.getRecipients(this.dataCaseOwnerships);
            }
            this.loadHistory();
            this.loadSetting().then(this.filterActionButtons);
        }
    };
    CaseDetailComponent.prototype.caseSubjectUpdated = function (data) {
        if (data !== undefined) {
            this.updateCaseSubject(data.caseSubject);
            this.updateCaseOwnership(data.caseOwnership);
            this.dataRecipients = this.caseService.getRecipients(this.dataCaseOwnerships);
            this.loadHistory();
        }
    };
    CaseDetailComponent.prototype.caseParcelPriceUpdated = function (data) {
        var _this = this;
        if (data !== undefined) {
            this.occupationLoading = true;
            this.easementsLoading = true;
            this.restangular.one('cases', this.caseId).get({ loadCollections: ['caseParcels', 'caseOccupations', 'caseEasements', 'caseOwnerships'] }).then(function (data) {
                _this.data.caseParcels = data.caseParcels;
                _this.data.caseOccupations = data.caseOccupations;
                _this.data.caseEasements = data.caseEasements;
                _this.data.caseOwnerships = data.caseOwnerships;
                _this.dataCaseOwnerships = _this.caseService.getDataCaseOwnerships(data.caseOwnerships, _this.data.caseSubjects);
                _this.occupationLoading = false;
                _this.easementsLoading = false;
                _this.attachParcels();
            });
            this.loadHistory();
        }
    };
    CaseDetailComponent.prototype.updateCaseSubject = function (caseSubject) {
        var found = _.find(this.data.caseSubjects, { id: caseSubject.id });
        if (found) {
            var responseReceivedDate_1 = found.responseReceivedDate;
            var _loop_1 = function (attrname) {
                // when sent to SJM subjects, then both date is changed at once
                if (((attrname === 'sentDate' || attrname === 'receivedDate') && found.signedDate === null) || attrname === 'responseReceivedDate') {
                    this_1.data.caseSubjects.forEach(function (cs) {
                        if (cs[attrname] !== null && (cs['signedDate'] === null ||
                            (attrname === 'responseReceivedDate' &&
                                cs['responseReceivedDate'] === responseReceivedDate_1 &&
                                found['sentDate'] === cs['sentDate'] &&
                                found['receivedDate'] === cs['receivedDate']))) {
                            cs[attrname] = caseSubject[attrname];
                        }
                    });
                }
                else {
                    found[attrname] = caseSubject[attrname];
                }
            };
            var this_1 = this;
            for (var attrname in caseSubject) {
                _loop_1(attrname);
            }
        }
    };
    CaseDetailComponent.prototype.updateCaseOwnership = function (caseOwnership) {
        if (caseOwnership !== undefined) {
            var found = _.find(this.dataCaseOwnerships, { id: caseOwnership.id });
            if (found) {
                for (var attrname in caseOwnership) {
                    found[attrname] = caseOwnership[attrname];
                }
            }
        }
    };
    CaseDetailComponent.prototype.hideColumn = function (columnId) {
        return this.settingsService.shouldHideColumn('cases', columnId);
    };
    CaseDetailComponent.TABS_ORDER = ['owners', 'detailInfo', 'parcels', 'notes', 'history', 'documents'];
    CaseDetailComponent.OCCUPATION_PERMANENT_COLUMNS = [
        { id: 'label_gp', sortable: false },
        { id: 'occupation_parcel', sortable: true },
        { id: 'occupation_concern_object', sortable: false },
        { id: 'occupation_occupation', sortable: true },
        { id: 'occupation_update', sortable: false },
        { id: 'current_parcel_title', sortable: false },
        { id: 'current_concern_object', sortable: false },
    ];
    CaseDetailComponent.OCCUPATION_UNDER_YEAR_COLUMNS = [
        { id: 'label_gp', sortable: false },
        { id: 'occupation_parcel', sortable: true },
        { id: 'occupation_occupation', sortable: true },
        { id: 'occupation_concern_object', sortable: false },
        { id: 'occupation_update', sortable: false },
        { id: 'current_parcel_title', sortable: false },
        { id: 'current_concern_object', sortable: false },
    ];
    CaseDetailComponent.OCCUPATION_OVER_YEAR_COLUMNS = CaseDetailComponent.OCCUPATION_UNDER_YEAR_COLUMNS;
    CaseDetailComponent.EASEMENT_COLUMNS = [
        { id: 'parcel', sortable: true },
        { id: 'geometric_plan', sortable: false },
        { id: 'construction_object', sortable: false },
        { id: 'title', sortable: false },
        { id: 'area', sortable: false },
        { id: 'length', sortable: false },
    ];
    CaseDetailComponent.OCCUPATION_PERMANENT_PRICE_COLUMNS = [
        { id: 'occupation_price_land_price', sortable: false },
        { id: 'occupation_price_rent_price', sortable: false },
        { id: 'occupation_price_summary_cases', sortable: false },
        { id: 'occupation_assessment_price', sortable: false },
    ];
    CaseDetailComponent.OCCUPATION_UNDER_YEAR_PRICE_COLUMNS = [
        { id: 'occupation_price_land_price', sortable: false },
        { id: 'occupation_price_rent_length', sortable: false },
        { id: 'occupation_price_summary_cases', sortable: false },
        { id: 'occupation_assessment_price', sortable: false },
    ];
    CaseDetailComponent.OCCUPATION_OVER_YEAR_PRICE_COLUMNS = CaseDetailComponent.OCCUPATION_UNDER_YEAR_PRICE_COLUMNS;
    CaseDetailComponent.EASEMENT_PRICE_COLUMNS = [
        { id: 'case_price', sortable: false }
    ];
    CaseDetailComponent.FORBIDER_PERMANENT = [
        'AgreementOnAOtherFutureContractLiability',
        'FulfillmentOfDecisionExpropriationLiability',
        'FulfillmentOfDecisionAuctionSaleExecutionLiability',
        'AgreementOnAFutureTransferContractLiability',
        'RightToRealizeABuildingLiability',
        'InnominateSalesContractLiability',
        'BuildingPlacementLiability',
        'BorrowingLiability',
        'OtherLiability',
        'OwnersStatementLiability'
    ];
    CaseDetailComponent.FORBIDER_TEMPORARY = [
        'TemporaryExpropriationLiability',
        'AgreementOnAOtherFutureContractLiability',
        'RightToRealizeABuildingLiability',
        'InnominateSalesContractLiability',
        'AccessToLandLiability',
        'BuildingPlacementLiability',
        'BorrowingLiability',
        'OtherLiability',
        'OwnersStatementLiability'
    ];
    CaseDetailComponent.FORBIDER_EASEMENT = [
        'FulfillmentOfDecisionExpropriationLiability',
        'AgreementOnAFutureNetworkConstructionContractLiability',
        'RightToRealizeABuildingLiability'
    ];
    CaseDetailComponent.PARCELS_EXTERNAL_TAB = {
        name: 'Parcely investora',
        id: 'parcelsExternal',
        href: 'symap.project.cases.detail',
        urlParams: { tab: 'parcelsExternal' },
    };
    return CaseDetailComponent;
}());
exports.CaseDetailComponent = CaseDetailComponent;
