"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var project_name_pipe_1 = require("@app/vfze/pipes/project-name.pipe");
var file_saver_1 = require("file-saver");
var GenerateComponent = /** @class */ (function () {
    function GenerateComponent(restangular, projectNamePipe) {
        this.restangular = restangular;
        this.projectNamePipe = projectNamePipe;
        this.onGenerate = this.onGenerate.bind(this);
    }
    GenerateComponent.prototype.ngOnInit = function () {
    };
    GenerateComponent.prototype.onGenerate = function () {
        var _this = this;
        return this.restangular.one("generate/" + this.project.projectKey)
            .withHttpConfig({
            responseType: 'blob',
            transformResponse: function (response, header) {
                return [response, header];
            }
        })
            .get()
            .then(function (response) {
            file_saver_1.saveAs(response[0], (_this.project ? _this.project.projectKey : 'generated') + ".vfze", true);
        });
    };
    return GenerateComponent;
}());
exports.GenerateComponent = GenerateComponent;
