"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var i0 = require("@angular/core");
var BrandTranslatePipe = /** @class */ (function () {
    function BrandTranslatePipe(APP_BRAND) {
        this.APP_BRAND = APP_BRAND;
    }
    BrandTranslatePipe.prototype.transform = function (key) {
        if (!this.APP_BRAND.TRANSLATE || !this.APP_BRAND.TRANSLATE[key]) {
            return key;
        }
        return this.APP_BRAND.TRANSLATE[key];
    };
    BrandTranslatePipe.ngInjectableDef = i0.defineInjectable({ factory: function BrandTranslatePipe_Factory() { return new BrandTranslatePipe(i0.inject("APP_BRAND")); }, token: BrandTranslatePipe, providedIn: "root" });
    return BrandTranslatePipe;
}());
exports.BrandTranslatePipe = BrandTranslatePipe;
