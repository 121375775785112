"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var CallbackService = /** @class */ (function () {
    function CallbackService(callbackService) {
        this.callbackService = callbackService;
    }
    CallbackService.prototype.createCallback = function () {
        var model = this.callbackService();
        return {
            add: model.register,
            remove: model.unregister,
            get: model.get,
        };
    };
    CallbackService.ngInjectableDef = i0.defineInjectable({ factory: function CallbackService_Factory() { return new CallbackService(i0.inject("CallbackService")); }, token: CallbackService, providedIn: "root" });
    return CallbackService;
}());
exports.CallbackService = CallbackService;
