"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var terms_of_use_component_1 = require("@app/common/components/terms-of-use/terms-of-use.component");
var landing_pk_component_1 = require("@app/settings/modules/components/landing-pk/landing-pk.component");
var cadastre_data_import_component_1 = require("@app/settings/modules/components/cadastre-data-import/cadastre-data-import.component");
var cadastre_data_request_component_1 = require("@app/settings/modules/components/cadastre-data-request/cadastre-data-request.component");
var cadastre_data_report_component_1 = require("@app/settings/modules/components/cadastre-data-report/cadastre-data-report.component");
var landing_sy_component_1 = require("@app/settings/modules/components/landing-sy/landing-sy.component");
var cadastre_update_component_1 = require("@app/settings/modules/components/cadastre-update/cadastre-update.component");
var changelog_list_component_1 = require("@app/settings/modules/components/changelog-list/changelog-list.component");
var landing_vfze_component_1 = require("@app/settings/modules/components/landing-vfze/landing-vfze.component");
var main_component_1 = require("@app/settings/modules/components/main/main.component");
var landing_di_component_1 = require("@app/settings/modules/components/landing-di/landing-di.component");
function resolveActiveTabFunction($stateParams) {
    return $stateParams.tab;
}
exports.resolveActiveTabFunction = resolveActiveTabFunction;
exports.modulesState = {
    name: 'settings.modules',
    url: '/modules',
    data: {
        title: 'Správa modulů',
    },
    views: {
        'content@settings': {
            component: main_component_1.MainComponent,
        }
    },
};
function resolveApplicationDiNameFunction(APPLICATIONS) {
    return APPLICATIONS.di.name;
}
exports.resolveApplicationDiNameFunction = resolveApplicationDiNameFunction;
exports.landingDiState = {
    name: 'settings.modules.dimap',
    data: {
        title: 'Modul DiMAP',
    },
    url: '/dimap/:tab',
    params: {
        tab: 'terms-of-use'
    },
    views: {
        'content@settings': {
            component: landing_di_component_1.LandingDiComponent,
        },
        'terms-of-use@settings.modules.dimap': {
            component: terms_of_use_component_1.TermsOfUseComponent,
        },
    },
    resolve: {
        activeTab: ['$stateParams', resolveActiveTabFunction],
        appName: ['APPLICATIONS', resolveApplicationDiNameFunction]
    },
};
function resolveApplicationPkNameFunction(APPLICATIONS) {
    return APPLICATIONS.pk.name;
}
exports.resolveApplicationPkNameFunction = resolveApplicationPkNameFunction;
exports.promapLandingState = {
    name: 'settings.modules.pk',
    data: {
        title: 'PROMAP',
    },
    url: '/pk/:tab',
    params: {
        tab: 'cadastre-data-import'
    },
    views: {
        'content@settings': {
            component: landing_pk_component_1.LandingPkComponent,
        },
        'terms-of-use@settings.modules.pk': {
            component: terms_of_use_component_1.TermsOfUseComponent,
        },
        'cadastre-data-import@settings.modules.pk': {
            component: cadastre_data_import_component_1.CadastreDataImportComponent,
        },
        'cadastre-data-request@settings.modules.pk': {
            component: cadastre_data_request_component_1.CadastreDataRequestComponent,
        },
        'cadastre-data-report@settings.modules.pk': {
            component: cadastre_data_report_component_1.CadastreDataReportComponent,
        },
    },
    resolve: {
        activeTab: ['$stateParams', resolveActiveTabFunction],
        appName: ['APPLICATIONS', resolveApplicationPkNameFunction]
    },
};
function resolveApplicationSyNameFunction(APPLICATIONS) {
    return APPLICATIONS.sy.name;
}
exports.resolveApplicationSyNameFunction = resolveApplicationSyNameFunction;
function resolveSettings(settingsService, wordService, $transition$) {
    var projectId = $transition$.params().id;
    settingsService.clearSettings();
    return settingsService.loadSettings(projectId).then(function (settings) {
        return wordService.parseVocabularyFromSettings(settings);
    });
}
exports.resolveSettings = resolveSettings;
exports.landingSyState = {
    name: 'settings.modules.ps',
    data: {
        title: 'MAJA/MPV',
    },
    url: '/PS_URL_BASE_PATH/:tab',
    params: {
        tab: 'cadastre-update'
    },
    views: {
        'content@settings': {
            component: landing_sy_component_1.LandingSyComponent,
        },
        'terms-of-use@settings.modules.ps': {
            component: terms_of_use_component_1.TermsOfUseComponent,
        },
        'cadastre-update@settings.modules.ps': {
            component: cadastre_update_component_1.CadastreUpdateComponent,
        },
        'changelog@settings.modules.ps': {
            component: changelog_list_component_1.ChangelogListComponent,
        },
    },
    resolve: {
        activeTab: ['$stateParams', resolveActiveTabFunction],
        applicationName: ['APPLICATIONS', resolveApplicationSyNameFunction],
        appName: ['APPLICATIONS', resolveApplicationSyNameFunction]
    },
};
function resolveApplicationVfzeNameFunction(APPLICATIONS) {
    return APPLICATIONS.vfze.name;
}
exports.resolveApplicationVfzeNameFunction = resolveApplicationVfzeNameFunction;
exports.vfzeLandingState = {
    name: 'settings.modules.vfze',
    data: {
        title: 'VFZE',
    },
    url: '/vfze/:tab',
    params: {
        tab: 'terms-of-use'
    },
    views: {
        'content@settings': {
            component: landing_vfze_component_1.LandingVfzeComponent,
        },
        'terms-of-use@settings.modules.vfze': {
            component: terms_of_use_component_1.TermsOfUseComponent,
        },
    },
    resolve: {
        activeTab: ['$stateParams', resolveActiveTabFunction],
        appName: ['APPLICATIONS', resolveApplicationVfzeNameFunction]
    },
};
