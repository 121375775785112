"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var dialog_config_1 = require("@app/dialog/dialog-config");
var dialog_ref_1 = require("@app/dialog/dialog-ref");
var KnWsdpStatusModalComponent = /** @class */ (function () {
    function KnWsdpStatusModalComponent(dialogConfig, dialogComponent) {
        this.dialogConfig = dialogConfig;
        this.dialogComponent = dialogComponent;
        this.onClose = this.onClose.bind(this);
    }
    KnWsdpStatusModalComponent.prototype.ngOnInit = function () {
        this.request = this.dialogConfig.data.request;
    };
    KnWsdpStatusModalComponent.prototype.onClose = function () {
        this.dialogComponent.close(false);
    };
    return KnWsdpStatusModalComponent;
}());
exports.KnWsdpStatusModalComponent = KnWsdpStatusModalComponent;
