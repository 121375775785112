"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var bulk_price_step_component_1 = require("@app/ps/project-settings/components/bulk-price/bulk-price-step/bulk-price-step.component");
var BulkPriceInitComponent = /** @class */ (function (_super) {
    __extends(BulkPriceInitComponent, _super);
    function BulkPriceInitComponent(restangular) {
        var _this = _super.call(this) || this;
        _this.restangular = restangular;
        _this.loading = false;
        _this.onSubmit = _this.onSubmit.bind(_this);
        _this.loadOccupations = _this.loadOccupations.bind(_this);
        return _this;
    }
    BulkPriceInitComponent.prototype.ngOnInit = function () {
        this.rentalPrice = this.data.rentalPrice || {
            rentalPriceType: 'F',
            rentalPriceUnit: 'A',
            rentalLength: 12,
        };
        this.occupations = this.data.occupations;
        this.occupationType = this.data.occupationType;
    };
    BulkPriceInitComponent.prototype.isValid = function () {
        var rentValid = this.rentalPrice.rentalPriceType
            && this.rentalPrice.rentalLength
            && ((this.occupationType === 'u' && this.rentalPrice.rentalLength <= 12) || (this.occupationType === 'o' && this.rentalPrice.rentalLength > 12))
            && ((this.rentalPrice.rentalPriceType === 'F' && this.rentalPrice.priceAssessmentYear && this.rentalPrice.priceAssessmentLandPrice)
                || (this.rentalPrice.rentalPriceType === 'E' && this.rentalPrice.rentalExpertOpinionNumber && this.rentalPrice.rentalExpertOpinionDate && this.rentalPrice.rentalExpert && this.rentalPrice.priceAssessmentLandPrice)
                || (this.rentalPrice.rentalPriceType === 'O' && this.rentalPrice.rentalOtherPriceSource && ((this.rentalPrice.rentalPriceUnit === 'T' && this.rentalPrice.totalRentPrice) || (this.rentalPrice.rentalPriceUnit === 'A' && this.rentalPrice.priceAssessmentLandPrice))));
        return !!(this.occupations && this.occupations.length && rentValid);
    };
    BulkPriceInitComponent.prototype.onSubmit = function () {
        this.data.rentalPrice = this.rentalPrice;
        this.data.occupations = this.occupations;
        this.data.occupationType = this.occupationType;
        this.submitCallback.emit();
    };
    BulkPriceInitComponent.prototype.onChangeExpert = function (expert) {
        this.rentalPrice.rentalExpert = expert;
    };
    BulkPriceInitComponent.prototype.onTypeChange = function (type) {
        if (this.loading) {
            return;
        }
        this.occupationType = type;
        this.loadOccupations();
    };
    BulkPriceInitComponent.prototype.loadOccupations = function () {
        var _this = this;
        this.loading = true;
        var occupationFilter = { filter: { filters: {
                    validity: ['valid'],
                    loadCollections: ['rentalExpert'],
                    withCaseOrFutureCase: [false],
                    occupationTypeId: [],
                } } };
        var occupationTypeFilter = { filter: { filters: {
                    validity: ['valid'],
                    number: this.occupationType === 'u' ? [5, 9] : [6, 10],
                } } };
        return this.restangular.all('occupation-types')
            .customPOST(occupationTypeFilter)
            .then(function (ot) {
            ot = ot.plain();
            if (ot.length === 0) {
                _this.loading = false;
                _this.occupations = [];
                return Promise.resolve();
            }
            occupationFilter.filter.filters.occupationTypeId = ot.map(function (i) { return i.id; });
            return _this.restangular
                .all('occupations')
                .customPOST(occupationFilter)
                .then(function (data) {
                _this.loading = false;
                _this.occupations = data.plain();
            });
        });
    };
    return BulkPriceInitComponent;
}(bulk_price_step_component_1.BulkPriceStepComponent));
exports.BulkPriceInitComponent = BulkPriceInitComponent;
