"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./tooltip-cases.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/common");
var i3 = require("@uirouter/angular/lib/directives/uiSref");
var i4 = require("@uirouter/core");
var i5 = require("../../pipes/ownership-name.pipe");
var i6 = require("../../pipes/subject-name.pipe");
var i7 = require("../../directives/loading.directive");
var i8 = require("./tooltip-cases.component");
var i9 = require("../../services/list.service");
var styles_TooltipCasesComponent = [i0.styles];
var RenderType_TooltipCasesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TooltipCasesComponent, data: {} });
exports.RenderType_TooltipCasesComponent = RenderType_TooltipCasesComponent;
function View_TooltipCasesComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵppd(3, 1)], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent.parent.parent, 0), _v.parent.context.$implicit.ownership)); _ck(_v, 2, 0, currVal_0); }); }
function View_TooltipCasesComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TooltipCasesComponent_3)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = !_v.context.$implicit.isParent; _ck(_v, 2, 0, currVal_0); }, null); }
function View_TooltipCasesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "li", [["class", "case"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 10, "a", [["uiSref", "symap.project.cases.detail"]], [[8, "target", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).go($event.button, $event.ctrlKey, $event.metaKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 16384, null, 0, i3.AnchorUISref, [i1.ElementRef, i1.Renderer2], null, null), i1.ɵdid(3, 737280, null, 0, i3.UISref, [i4.UIRouter, [2, i3.AnchorUISref], "UIView.PARENT_INJECT"], { state: [0, "state"], params: [1, "params"] }, null), i1.ɵpod(4, { id: 0 }), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, [" ", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TooltipCasesComponent_2)), i1.ɵdid(11, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_1 = "symap.project.cases.detail"; var currVal_2 = _ck(_v, 4, 0, _v.context.$implicit.id); _ck(_v, 3, 0, currVal_1, currVal_2); var currVal_6 = _v.context.$implicit.caseOwnerships; _ck(_v, 11, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", (((_co.data.customData == null) ? null : _co.data.customData.openNewTab) ? "_blank" : "_self"), ""); _ck(_v, 1, 0, currVal_0); var currVal_3 = (_v.context.$implicit.mpp.number || "bez \u010D\u00EDsla"); _ck(_v, 5, 0, currVal_3); var currVal_4 = _v.context.$implicit.obligation.name; _ck(_v, 7, 0, currVal_4); var currVal_5 = _v.context.$implicit.caseStatus.name; _ck(_v, 9, 0, currVal_5); }); }
function View_TooltipCasesComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u017D\u00E1dn\u00E9 p\u0159\u00EDpady"]))], null, null); }
function View_TooltipCasesComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i5.OwnershipNamePipe, [i6.SubjectNamePipe]), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [], [[8, "className", 0]], null, null, null, null)), i1.ɵdid(2, 16384, null, 0, i7.LoadingDirective, [i1.ElementRef], { loading: [0, "loading"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TooltipCasesComponent_1)), i1.ɵdid(5, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TooltipCasesComponent_4)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.loading; _ck(_v, 2, 0, currVal_1); var currVal_2 = ((_co.data == null) ? null : ((_co.data.list == null) ? null : _co.data.list.list)); _ck(_v, 5, 0, currVal_2); var currVal_3 = (((_co.data == null) ? null : ((_co.data.list == null) ? null : _co.data.list.list)) && (_co.data.list.list.length === 0)); _ck(_v, 7, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).elementClass; _ck(_v, 1, 0, currVal_0); }); }
exports.View_TooltipCasesComponent_0 = View_TooltipCasesComponent_0;
function View_TooltipCasesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "tooltip-cases", [], null, null, null, View_TooltipCasesComponent_0, RenderType_TooltipCasesComponent)), i1.ɵdid(1, 114688, null, 0, i8.TooltipCasesComponent, [i1.ChangeDetectorRef, i9.ListService, "tooltipConfig"], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_TooltipCasesComponent_Host_0 = View_TooltipCasesComponent_Host_0;
var TooltipCasesComponentNgFactory = i1.ɵccf("tooltip-cases", i8.TooltipCasesComponent, View_TooltipCasesComponent_Host_0, { data: "data" }, {}, []);
exports.TooltipCasesComponentNgFactory = TooltipCasesComponentNgFactory;
