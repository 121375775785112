"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var login_component_1 = require("./components/login/login.component");
var auth_service_1 = require("@app/common/services/auth.service");
exports.loginState = {
    name: 'login',
    url: '/login?redirectUrl&secret&SAMLart&loggedOut&code&state&ssoLogoutToken&session_state',
    component: login_component_1.LoginComponent,
    onEnter: checkAuth,
};
function checkAuth($transition$) {
    var authService = $transition$.injector().get(auth_service_1.AuthService);
    var APPLICATIONS = $transition$.injector().get('APPLICATIONS');
    var APP_BRAND = $transition$.injector().get('APP_BRAND');
    if (authService.isAuthenticated()) {
        if (APP_BRAND.NAME === 'RSD' || APP_BRAND.NAME === 'SZ') {
            authService.setActiveApplication(APPLICATIONS.landing.name);
        }
        return authService.goToActiveAplication();
    }
    var params = $transition$.params();
    if (params.loggedOut !== 'true' && !params.SAMLart && !params.code && authService.isSsoLoginEnabled(params.secret)) {
        return authService.ssoLogin();
    }
    return true;
}
exports.checkAuth = checkAuth;
checkAuth.$inject = ['$transition$'];
var LoginAppModule = /** @class */ (function () {
    function LoginAppModule() {
    }
    return LoginAppModule;
}());
exports.LoginAppModule = LoginAppModule;
