"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("../../../common/components/button/button.component.ngfactory");
var i2 = require("../../../common/components/button/button.component");
var i3 = require("@angular/common");
var i4 = require("./bulk-operation-finish.component");
var styles_BulkOperationFinishComponent = [];
var RenderType_BulkOperationFinishComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_BulkOperationFinishComponent, data: {} });
exports.RenderType_BulkOperationFinishComponent = RenderType_BulkOperationFinishComponent;
function View_BulkOperationFinishComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(0, null, null, 0))], null, null); }
function View_BulkOperationFinishComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "div", [["class", "jumbotron"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [["class", "centered-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Operace byla \u00FAsp\u011B\u0161n\u011B provedena. P\u0159ejete si prov\u00E9st dal\u0161\u00ED hromadnou operaci nad zvolen\u00FDm seznamem?"])), (_l()(), i0.ɵeld(3, 0, null, null, 6, "div", [["class", "centered-text mt-10"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 2, "gmt-button", [], null, null, null, i1.View_ButtonComponent_0, i1.RenderType_ButtonComponent)), i0.ɵdid(5, 114688, null, 0, i2.ButtonComponent, ["ErrorHandlerService", i0.ChangeDetectorRef], { clickAction: [0, "clickAction"] }, null), (_l()(), i0.ɵted(-1, 0, ["Ano"])), (_l()(), i0.ɵeld(7, 0, null, null, 2, "gmt-button", [], null, null, null, i1.View_ButtonComponent_0, i1.RenderType_ButtonComponent)), i0.ɵdid(8, 114688, null, 0, i2.ButtonComponent, ["ErrorHandlerService", i0.ChangeDetectorRef], { clickAction: [0, "clickAction"] }, null), (_l()(), i0.ɵted(-1, 0, ["Ne"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.newOperation; _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.goToList; _ck(_v, 8, 0, currVal_1); }, null); }
function View_BulkOperationFinishComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { viewContainerRef: 0 }), (_l()(), i0.ɵand(16777216, [[1, 3], ["operationFinish", 2]], null, 0, null, View_BulkOperationFinishComponent_1)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_BulkOperationFinishComponent_2)), i0.ɵdid(3, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.finishComponent; _ck(_v, 3, 0, currVal_0); }, null); }
exports.View_BulkOperationFinishComponent_0 = View_BulkOperationFinishComponent_0;
function View_BulkOperationFinishComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_BulkOperationFinishComponent_0, RenderType_BulkOperationFinishComponent)), i0.ɵdid(1, 114688, null, 0, i4.BulkOperationFinishComponent, [i0.ComponentFactoryResolver], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_BulkOperationFinishComponent_Host_0 = View_BulkOperationFinishComponent_Host_0;
var BulkOperationFinishComponentNgFactory = i0.ɵccf("ng-component", i4.BulkOperationFinishComponent, View_BulkOperationFinishComponent_Host_0, { data: "data", newOperation: "newOperation", goToList: "goToList" }, {}, []);
exports.BulkOperationFinishComponentNgFactory = BulkOperationFinishComponentNgFactory;
