"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var ApplicationsHostDirective = /** @class */ (function () {
    function ApplicationsHostDirective(viewContainerRef) {
        this.viewContainerRef = viewContainerRef;
    }
    return ApplicationsHostDirective;
}());
exports.ApplicationsHostDirective = ApplicationsHostDirective;
