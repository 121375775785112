"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var dialog_service_1 = require("@app/dialog/dialog.service");
var dialog_config_1 = require("@app/dialog/dialog-config");
var dialog_ref_1 = require("@app/dialog/dialog-ref");
var _ = require("lodash");
var ObligationSettingsComponent = /** @class */ (function () {
    function ObligationSettingsComponent(helpIds, restangular, dialogService, config, dialog) {
        this.helpIds = helpIds;
        this.restangular = restangular;
        this.dialogService = dialogService;
        this.config = config;
        this.dialog = dialog;
        this.loading = true;
        this.dueDateFromTypes = [
            { id: 'EntryProposalDate', name: 'Podání návrhu na vklad' },
            { id: 'CadastreEntryDate', name: 'Rozhodnutí KN' },
            { id: 'CadastreEntryDeliveryDate', name: 'Doručení rozhodnutí' },
            { id: 'ResponseReceivedSignedDate', name: 'Doručení podepsané smlouvy' },
            { id: 'SignedAllOwnersDate', name: 'Podpis všech vlastníků' },
        ];
        this.obligationId = config.data.obligationId;
        this.update = this.update.bind(this);
    }
    ObligationSettingsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.restangular.one('obligations', this.obligationId).get().then(function (data) {
            _this.obligation = data.plain();
            _this.loading = false;
            _this.obligation.dueDateFromType = _.find(_this.dueDateFromTypes, { id: data.dueDateFromType });
        });
    };
    ObligationSettingsComponent.prototype.update = function () {
        var _this = this;
        return this.restangular.one('obligations', this.obligationId)
            .customPUT(__assign({}, this.obligation, { dueDateFromType: this.obligation.dueDateFromType.id }))
            .then(function (data) {
            _this.dialog.close(data.plain());
        });
    };
    return ObligationSettingsComponent;
}());
exports.ObligationSettingsComponent = ObligationSettingsComponent;
