"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var dialog_service_1 = require("@app/dialog/dialog.service");
var dialog_config_1 = require("@app/dialog/dialog-config");
var dialog_ref_1 = require("@app/dialog/dialog-ref");
var _ = require("lodash");
var $ = require("jquery");
var document_settings_component_1 = require("@app/ps/project-settings/components/document-settings/document-settings.component");
var ObligationFlowSettingsComponent = /** @class */ (function () {
    function ObligationFlowSettingsComponent(helpIds, restangular, confirmService, elementRef, dialogService, config, dialog, cdr) {
        this.helpIds = helpIds;
        this.restangular = restangular;
        this.confirmService = confirmService;
        this.elementRef = elementRef;
        this.dialogService = dialogService;
        this.config = config;
        this.dialog = dialog;
        this.cdr = cdr;
        this.editable = false;
        this.workflowChanged = false;
        this.loading = true;
        this.obligationId = config.data.obligationId;
        this.selectedEnterStatus = this.selectedEnterStatus.bind(this);
        this.selectedLeaveStatus = this.selectedLeaveStatus.bind(this);
        this.selectedDocument = this.selectedDocument.bind(this);
        this.removeEnterStatus = this.removeEnterStatus.bind(this);
        this.removeLeaveStatus = this.removeLeaveStatus.bind(this);
        this.removeDocument = this.removeDocument.bind(this);
        this.removeFlowStatus = this.removeFlowStatus.bind(this);
        this.up = this.up.bind(this);
        this.down = this.down.bind(this);
        this.reloadOptionsRegisterEnter = this.reloadOptionsRegisterEnter.bind(this);
        this.reloadOptionsRegisterLeave = this.reloadOptionsRegisterLeave.bind(this);
        this.reloadOptionsRegisterDocument = this.reloadOptionsRegisterDocument.bind(this);
        this.editDocument = this.editDocument.bind(this);
        this.update = this.update.bind(this);
        this.reset = this.reset.bind(this);
        this.goToStatusAnchor = this.goToStatusAnchor.bind(this);
        this.toggleAllowCadastreEntryProposal = this.toggleAllowCadastreEntryProposal.bind(this);
    }
    ObligationFlowSettingsComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.restangular.all('templates').one('obligations', this.obligationId).all('types-allowed').getList()
                            .then(function (data) {
                            _this.documentTemplatesOptions = _.sortBy(data, ['name']);
                            _this.documentTemplateNames = {};
                            data.forEach(function (item) {
                                _this.documentTemplateNames[item.key] = item.name;
                            });
                        })];
                    case 1:
                        _a.sent();
                        this.restangular.one('flow', this.obligationId).get().then(function (data) {
                            _this.processData(data.plain());
                            _this.loading = false;
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    ObligationFlowSettingsComponent.prototype.ngAfterContentChecked = function () {
        this.cdr.detectChanges();
    };
    ObligationFlowSettingsComponent.prototype.selectedEnterStatus = function (caseStatus, enterStatus, data) {
        this.workflowChanged = true;
        var flowStatusExt = this.flowData[caseStatus];
        flowStatusExt.flowStatus.enterStatuses.push(enterStatus.key);
        var foundFlowStatus = this.flowData[enterStatus.key];
        if (!foundFlowStatus) {
            foundFlowStatus = enterStatus;
            this.flowDataSort.push(enterStatus.key);
            this.flowData[enterStatus.key] = foundFlowStatus;
        }
        foundFlowStatus.leaveStatuses.push(caseStatus);
        data.selected = null;
        flowStatusExt.reloadOptionsEnter();
        if (foundFlowStatus.reloadOptionsLeave) {
            foundFlowStatus.reloadOptionsLeave();
        }
    };
    ObligationFlowSettingsComponent.prototype.selectedLeaveStatus = function (caseStatus, leaveStatus, data) {
        this.workflowChanged = true;
        var flowStatusExt = this.flowData[caseStatus];
        flowStatusExt.leaveStatuses.push(leaveStatus.key);
        var foundFlowStatus = this.flowData[leaveStatus.key];
        if (!foundFlowStatus) {
            foundFlowStatus = leaveStatus;
            this.flowDataSort.push(leaveStatus.key);
            this.flowData[leaveStatus.key] = foundFlowStatus;
        }
        foundFlowStatus.flowStatus.enterStatuses.push(caseStatus);
        data.selected = null;
        flowStatusExt.reloadOptionsLeave();
        if (foundFlowStatus.reloadOptionsEnter) {
            foundFlowStatus.reloadOptionsEnter();
        }
    };
    ObligationFlowSettingsComponent.prototype.selectedDocument = function (caseStatus, document, data) {
        var flowStatusExt = this.flowData[caseStatus];
        flowStatusExt.flowStatus.documents.push({
            key: document.key
        });
        data.selected = null;
        flowStatusExt.reloadOptionsDocument();
    };
    ObligationFlowSettingsComponent.prototype.removeEnterStatus = function (enterStatus, caseStatus) {
        this.workflowChanged = true;
        var flowStatusExt = this.flowData[caseStatus];
        _.pull(flowStatusExt.flowStatus.enterStatuses, enterStatus);
        var foundFlowStatus = this.flowData[enterStatus];
        if (foundFlowStatus) {
            _.pull(foundFlowStatus.leaveStatuses, caseStatus);
            if (!foundFlowStatus.leaveStatuses.length && !foundFlowStatus.flowStatus.enterStatuses.length && enterStatus !== 'Created') {
                _.pull(this.flowDataSort, enterStatus);
                delete this.flowData[enterStatus];
                foundFlowStatus = null;
            }
        }
        if (!flowStatusExt.leaveStatuses.length && !flowStatusExt.flowStatus.enterStatuses.length && caseStatus !== 'Created') {
            _.pull(this.flowDataSort, caseStatus);
            delete this.flowData[caseStatus];
            flowStatusExt = null;
        }
        if (flowStatusExt) {
            flowStatusExt.reloadOptionsEnter();
        }
        if (foundFlowStatus) {
            foundFlowStatus.reloadOptionsLeave();
        }
    };
    ObligationFlowSettingsComponent.prototype.removeLeaveStatus = function (leaveStatus, caseStatus) {
        this.workflowChanged = true;
        var flowStatusExt = this.flowData[caseStatus];
        _.pull(flowStatusExt.leaveStatuses, leaveStatus);
        var foundFlowStatus = this.flowData[leaveStatus];
        if (foundFlowStatus) {
            _.pull(foundFlowStatus.flowStatus.enterStatuses, caseStatus);
            if (!foundFlowStatus.leaveStatuses.length && !foundFlowStatus.flowStatus.enterStatuses.length && leaveStatus !== 'Created') {
                _.pull(this.flowDataSort, leaveStatus);
                delete this.flowData[leaveStatus];
                foundFlowStatus = null;
            }
        }
        if (!flowStatusExt.leaveStatuses.length && !flowStatusExt.flowStatus.enterStatuses.length && caseStatus !== 'Created') {
            _.pull(this.flowDataSort, caseStatus);
            delete this.flowData[caseStatus];
            flowStatusExt = null;
        }
        if (flowStatusExt) {
            flowStatusExt.reloadOptionsLeave();
        }
        if (foundFlowStatus) {
            foundFlowStatus.reloadOptionsEnter();
        }
    };
    ObligationFlowSettingsComponent.prototype.removeDocument = function (document, caseStatus) {
        var flowStatusExt = this.flowData[caseStatus];
        _.pull(flowStatusExt.flowStatus.documents, document);
        flowStatusExt.reloadOptionsDocument();
    };
    ObligationFlowSettingsComponent.prototype.removeFlowStatus = function (caseStatus) {
        var _this = this;
        this.workflowChanged = true;
        _.pull(this.flowDataSort, caseStatus);
        this.flowData[caseStatus].flowStatus.enterStatuses = [];
        this.flowData[caseStatus].leaveStatuses = [];
        delete this.flowData[caseStatus];
        _.forEach(this.flowData, function (flowStatus2, caseStatus2) {
            _.pull(flowStatus2.leaveStatuses, caseStatus);
            _.pull(flowStatus2.flowStatus.enterStatuses, caseStatus);
            if (!flowStatus2.leaveStatuses.length && !flowStatus2.flowStatus.enterStatuses.length && caseStatus2 !== 'Created') {
                _.pull(_this.flowDataSort, caseStatus2);
                delete _this.flowData[caseStatus2];
            }
        });
    };
    ObligationFlowSettingsComponent.prototype.up = function (caseStatus) {
        var index = _.indexOf(this.flowDataSort, caseStatus);
        if (index > 0) {
            _.pull(this.flowDataSort, caseStatus);
            this.flowDataSort.splice(index - 1, 0, caseStatus);
        }
    };
    ObligationFlowSettingsComponent.prototype.down = function (caseStatus) {
        var index = _.indexOf(this.flowDataSort, caseStatus);
        if (index < this.flowDataSort.length - 1) {
            _.pull(this.flowDataSort, caseStatus);
            this.flowDataSort.splice(index + 1, 0, caseStatus);
        }
    };
    ObligationFlowSettingsComponent.prototype.reloadOptionsRegisterEnter = function (flowStatusExt) {
        if (!flowStatusExt.reloadOptionsRegisterEnter) {
            flowStatusExt.reloadOptionsRegisterEnter = function (reloadOptions) {
                flowStatusExt.reloadOptionsEnter = reloadOptions;
            };
            flowStatusExt.enterFilter = function (option) {
                return !_.includes(flowStatusExt.flowStatus.enterStatuses, option.key) && flowStatusExt.key !== option.key;
            };
        }
        return flowStatusExt.reloadOptionsRegisterEnter;
    };
    ObligationFlowSettingsComponent.prototype.reloadOptionsRegisterLeave = function (flowStatus) {
        if (!flowStatus.reloadOptionsRegisterLeave) {
            flowStatus.reloadOptionsRegisterLeave = function (reloadOptions) {
                flowStatus.reloadOptionsLeave = reloadOptions;
            };
            flowStatus.leaveFilter = function (option) {
                return !_.includes(flowStatus.leaveStatuses, option.key) && flowStatus.key !== option.key;
            };
        }
        return flowStatus.reloadOptionsRegisterLeave;
    };
    ObligationFlowSettingsComponent.prototype.reloadOptionsRegisterDocument = function (flowStatusExt) {
        if (!flowStatusExt.reloadOptionsRegisterDocument) {
            flowStatusExt.reloadOptionsRegisterDocument = function (reloadOptions) {
                flowStatusExt.reloadOptionsDocument = reloadOptions;
            };
            flowStatusExt.documentFilter = function (option) {
                return !_.some(flowStatusExt.flowStatus.documents, { key: option.key });
            };
        }
        return flowStatusExt.reloadOptionsRegisterDocument;
    };
    ObligationFlowSettingsComponent.prototype.editDocument = function (event, document) {
        event.stopPropagation();
        event.preventDefault();
        var dialog = this.dialogService.open(document_settings_component_1.DocumentSettingsComponent, { data: { document: __assign({}, document) } });
        var sub = dialog.afterClosed.subscribe(function (updatedDocument) {
            if (updatedDocument) {
                Object.assign(document, updatedDocument);
            }
            sub.unsubscribe();
        });
    };
    ObligationFlowSettingsComponent.prototype.update = function () {
        var _this = this;
        if (this.data.readOnly && this.editable && this.workflowChanged) {
            return new this.confirmService({
                title: 'Potvrzení změny workflow',
                message: 'Určitě uložit změny? Změny mohou způsobit konflikt ve stavech existujících případů.'
            }).then(function (data) {
                if (data === true) {
                    return _this.saveData();
                }
            });
        }
        else {
            return this.saveData();
        }
    };
    ObligationFlowSettingsComponent.prototype.reset = function () {
        var _this = this;
        return new this.confirmService({
            title: 'Potvrzení změny workflow',
            message: 'Určitě chcete obnovit workflow? Změny mohou způsobit konflikt ve stavech existujících případů.'
        }).then(function (data) {
            if (data === true) {
                _this.loading = true;
                return _this.restangular.one('flow', _this.obligationId).remove().then(_this.processData).then(function () {
                    _this.loading = false;
                }, function (response) {
                    _this.loading = false;
                    return Promise.reject(response);
                });
            }
        });
    };
    ObligationFlowSettingsComponent.prototype.goToStatusAnchor = function (flowStatusKey) {
        var anchor = this.elementRef.nativeElement.querySelector('#statusAnchor' + flowStatusKey);
        $(anchor).get(0).scrollIntoView();
        var parent = $(anchor).parent();
        parent.addClass('colored');
        setTimeout(function () {
            parent.removeClass('colored');
        }, 1000);
    };
    ObligationFlowSettingsComponent.prototype.toggleAllowCadastreEntryProposal = function (flowStatusExt) {
        flowStatusExt.flowStatus.allowCadastreEntryProposal = !flowStatusExt.flowStatus.allowCadastreEntryProposal;
    };
    ObligationFlowSettingsComponent.prototype.processData = function (data) {
        var _this = this;
        this.editable = !data.readOnly;
        this.data = data;
        this.flowDataSort = this.data.flow ? Object.keys(this.data.flow) : null;
        // Original data that keeps only attributes for REST message
        // Extended objects based on original data that are refferenced and structure never modified
        this.flowData = {};
        this.caseStatusOptions = [];
        _.forEach(this.data.flow, function (flowStatus, caseStatus) {
            // remove disabled documents
            _.remove(flowStatus.documents, function (document) { return _this.documentTemplateNames[document.key] === undefined; });
            var flowStatusExtended = {
                flowStatus: flowStatus,
                key: caseStatus,
                name: flowStatus.name,
                leaveStatuses: []
            };
            _this.flowData[caseStatus] = flowStatusExtended;
            _this.caseStatusOptions.push(flowStatusExtended);
        });
        this.caseStatusOptions = _.sortBy(this.caseStatusOptions, ['name']);
        _.forEach(this.data.flow, function (flowStatus, caseStatus) {
            for (var i = 0; i < flowStatus.enterStatuses.length; i++) {
                var enterStatus = flowStatus.enterStatuses[i];
                var foundFlowStatus = _this.flowData[enterStatus];
                if (!foundFlowStatus) {
                    // remove from view statuses not in workflow
                    _.pull(flowStatus.enterStatuses, enterStatus);
                    i--;
                    continue;
                }
                foundFlowStatus.leaveStatuses.push(caseStatus);
            }
        });
        // remove from view statuses not selected
        _.forEach(this.flowData, function (flowStatusExtended, caseStatus) {
            if (!flowStatusExtended.flowStatus.enterStatuses.length && !flowStatusExtended.leaveStatuses.length && caseStatus !== 'Created') {
                _.pull(_this.flowDataSort, caseStatus);
                delete _this.flowData[caseStatus];
            }
        });
    };
    ObligationFlowSettingsComponent.prototype.saveData = function () {
        var _this = this;
        this.data.flow = {};
        this.flowDataSort.forEach(function (caseStatus) {
            _this.data.flow[caseStatus] = _this.flowData[caseStatus].flowStatus;
        });
        return this.restangular.one('flow', this.obligationId).customPOST(this.data).then(function () {
            _this.dialog.close();
        });
    };
    return ObligationFlowSettingsComponent;
}());
exports.ObligationFlowSettingsComponent = ObligationFlowSettingsComponent;
