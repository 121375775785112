"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./tooltip-trend.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("./tooltip-trend.component");
var styles_TooltipTrendComponent = [i0.styles];
var RenderType_TooltipTrendComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TooltipTrendComponent, data: {} });
exports.RenderType_TooltipTrendComponent = RenderType_TooltipTrendComponent;
function View_TooltipTrendComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["P\u016Fvodn\u011B: ", " z ", " ", ""])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["Nyn\u00ED: ", " z ", " ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.doneBefore; var currVal_1 = _co.data.totalBefore; var currVal_2 = _co.data.unit; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _co.data.done; var currVal_4 = _co.data.total; var currVal_5 = _co.data.unit; _ck(_v, 3, 0, currVal_3, currVal_4, currVal_5); }); }
exports.View_TooltipTrendComponent_0 = View_TooltipTrendComponent_0;
function View_TooltipTrendComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "tooltip-trend", [], null, null, null, View_TooltipTrendComponent_0, RenderType_TooltipTrendComponent)), i1.ɵdid(1, 49152, null, 0, i2.TooltipTrendComponent, [], null, null)], null, null); }
exports.View_TooltipTrendComponent_Host_0 = View_TooltipTrendComponent_Host_0;
var TooltipTrendComponentNgFactory = i1.ɵccf("tooltip-trend", i2.TooltipTrendComponent, View_TooltipTrendComponent_Host_0, { data: "data" }, {}, []);
exports.TooltipTrendComponentNgFactory = TooltipTrendComponentNgFactory;
