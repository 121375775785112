"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var table_parcels_abstract_component_1 = require("../table-parcels-abstract/table-parcels-abstract.component");
var TableBuildingsComponent = /** @class */ (function (_super) {
    __extends(TableBuildingsComponent, _super);
    function TableBuildingsComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.COMPONENT_COLUMNS = [
            'parcel',
            'parcel_title',
            'building_title',
            'cast_obce',
            'house_number',
            'usage',
            'protection',
            'cases',
            'price'
        ];
        return _this;
    }
    return TableBuildingsComponent;
}(table_parcels_abstract_component_1.TableParcelsAbstractComponent));
exports.TableBuildingsComponent = TableBuildingsComponent;
