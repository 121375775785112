"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var LoginService = /** @class */ (function () {
    function LoginService(loginService) {
        this.loginService = loginService;
    }
    LoginService.prototype.redirectToLogin = function (includeParams, loggedOut) {
        if (includeParams === void 0) { includeParams = false; }
        if (loggedOut === void 0) { loggedOut = false; }
        this.loginService.redirectToLogin(includeParams, loggedOut);
    };
    LoginService.ngInjectableDef = i0.defineInjectable({ factory: function LoginService_Factory() { return new LoginService(i0.inject("LoginService")); }, token: LoginService, providedIn: "root" });
    return LoginService;
}());
exports.LoginService = LoginService;
