"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var angular_1 = require("@uirouter/angular");
var ProjectsComponent = /** @class */ (function () {
    function ProjectsComponent(helpIds, modulesService, confirmService, checklistService, stateService) {
        this.helpIds = helpIds;
        this.modulesService = modulesService;
        this.confirmService = confirmService;
        this.checklistService = checklistService;
        this.stateService = stateService;
        this.loading = true;
        this.onPermissionsChange = this.onPermissionsChange.bind(this);
    }
    Object.defineProperty(ProjectsComponent.prototype, "userProjects", {
        set: function (userProjects) {
            this.checklistSelection = new this.checklistService(userProjects);
        },
        enumerable: true,
        configurable: true
    });
    ProjectsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.modulesService.getProjects(this.module).then(function (data) { return _this.projects = data.sort(function (a, b) { return a.name.localeCompare(b.name); }); }).then(function () {
            _this.loading = false;
        });
    };
    ProjectsComponent.prototype.onPermissionsChange = function (project, changeStatus) {
        var _this = this;
        if (changeStatus === void 0) { changeStatus = false; }
        if (this.checklistSelection.isChecked(project.key) && changeStatus) {
            return new this.confirmService({
                title: 'Odstranit všechny práva na projekt?',
                message: "Opravdu chcete odebrat u\u017Eivateli ve\u0161ker\u00E1 pr\u00E1va v projektu " + project.name + "?"
            }).then(function (data) {
                if (data === true) {
                    var payload = { projectKey: project.key, application: _this.module, permissions: [] };
                    _this.modulesService.saveUserPermissions(_this.module, project.key, _this.userId, payload).then(function () {
                        _this.checklistSelection.toggleSelection(project.key);
                        _this.callbacks.get('userPermissionsChangedCallback')();
                    });
                }
            });
        }
        else {
            this.stateService.go('settings.users.detail.editPermissions', { projectKey: project.key, tab: this.module });
        }
    };
    return ProjectsComponent;
}());
exports.ProjectsComponent = ProjectsComponent;
