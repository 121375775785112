
var common = angular.module('common');

/**
 * Handle all authentication-related operations
 */
common.service('GoogleAnalyticsService', ['$window', 'AuthService',
  function ($window, AuthService) {
    this.onUserCome = function() {
      $window.GoogleAnalytics('set', 'userId', AuthService.user.id);
      $window.GoogleAnalytics('set', 'dimension1', (AuthService.user.company ? AuthService.user.company.companyName : null) || 'unknown');
      $window.GoogleAnalytics('set', 'dimension2', AuthService.getActualProject() ? AuthService.getActualProject().name : '' || AuthService.getActiveApplication());
      $window.GoogleAnalytics('set', 'dimension3', AuthService.getActiveApplication());
    };
  }
]);
