"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var angular_hybrid_1 = require("@uirouter/angular-hybrid");
var sample_list_component_1 = require("@app/sv/components/sample-list/sample-list.component");
var main_component_1 = require("@app/sv/components/main/main.component");
var ticket_list_component_1 = require("@app/sv/components/ticket-list/ticket-list.component");
var sample_detail_component_1 = require("@app/sv/components/sample-detail/sample-detail.component");
var ticket_detail_component_1 = require("@app/sv/components/ticket-detail/ticket-detail.component");
var settings_component_1 = require("@app/sv/components/settings/settings.component");
var state_utils_1 = require("@app/common/utils/state.utils");
var ticket_create_component_1 = require("@app/sv/components/ticket-create/ticket-create.component");
var document_type_create_component_1 = require("@app/sv/components/document-type-create/document-type-create.component");
function authCallback(authService, applications, $transition$) {
    return authService.checkProjectStatus($transition$, applications.sv.name, null, authService.loadSvProjects, 'error').then(function () { }, function (newState) { return newState; });
}
exports.authCallback = authCallback;
exports.sampleState = {
    name: 'sv',
    url: '/sv',
    abstract: true,
    component: main_component_1.MainComponent,
    onEnter: ['AuthService', 'APPLICATIONS', '$transition$', authCallback],
};
exports.sampleListState = {
    name: 'sv.samples',
    url: '/sample-list?page',
    data: {
        title: 'Vzory',
    },
    params: {
        page: {
            dynamic: true,
            type: 'int',
            inherit: false,
        },
    },
    views: {
        'content@sv': {
            component: sample_list_component_1.SampleListComponent,
        }
    },
};
exports.sampleDetailState = {
    name: 'sv.samples.detail',
    url: '/:id/:tab',
    data: {
        title: 'Detail vzoru',
    },
    views: {
        'content@sv': {
            component: sample_detail_component_1.SampleDetailComponent,
        }
    },
    resolve: {
        sampleId: ['$stateParams', state_utils_1.resolveId]
    },
    params: {
        id: {
            type: 'int',
            value: undefined
        },
        tab: {
            value: 'notes',
            dynamic: true,
            inherit: false,
            squash: true,
        }
    },
};
exports.ticketListState = {
    name: 'sv.tickets',
    url: '/ticket-list?page',
    data: {
        title: 'Požadavky',
    },
    params: {
        page: {
            dynamic: true,
            type: 'int',
            inherit: false,
        },
    },
    views: {
        'content@sv': {
            component: ticket_list_component_1.TicketListComponent,
        }
    },
};
exports.ticketDetailState = {
    name: 'sv.tickets.detail',
    url: '/:id/:tab',
    data: {
        title: 'Detail požadavku',
    },
    views: {
        'content@sv': {
            component: ticket_detail_component_1.TicketDetailComponent,
        }
    },
    resolve: {
        ticketId: ['$stateParams', state_utils_1.resolveId]
    },
    params: {
        id: {
            type: 'int',
            value: undefined
        },
        tab: {
            value: 'notes',
            dynamic: true,
            inherit: false,
            squash: true,
        }
    },
};
exports.ticketCreateState = {
    name: 'sv.tickets.create',
    url: '/create?id',
    data: {
        title: 'Vytvoření požadavku',
    },
    resolve: {
        sampleId: ['$stateParams', state_utils_1.resolveId]
    },
    params: {
        id: {
            type: 'int',
            value: undefined
        },
    },
    views: {
        'content@sv': {
            component: ticket_create_component_1.TicketCreateComponent,
        }
    },
};
exports.settingsState = {
    name: 'sv.settings',
    url: '/settings',
    data: {
        title: 'Požadavky',
    },
    views: {
        'content@sv': {
            component: settings_component_1.SettingsComponent,
        }
    },
};
exports.documentTypeCreateState = {
    name: 'sv.settings.createDocumentType',
    url: '/create-document-type',
    data: {
        title: 'Vytvoření nového typu dokumentu',
    },
    views: {
        'content@sv': {
            component: document_type_create_component_1.DocumentTypeCreateComponent,
        }
    },
};
var SvAppModule = /** @class */ (function () {
    function SvAppModule() {
    }
    return SvAppModule;
}());
exports.SvAppModule = SvAppModule;
