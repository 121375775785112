"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var L = require("leaflet");
var map_ogc_common_service_1 = require("@app/map/services/map-ogc-common.service");
var i0 = require("@angular/core");
var i1 = require("@angular/common/http");
var i2 = require("./map-ogc-common.service");
/**
 * WMS layer extended with WFS-style filtering capabilities
 */
var LeafletTileLayerWmts = /** @class */ (function () {
    function LeafletTileLayerWmts(http, mapOgcCommonService) {
        this.WmtsTileLayer = L.TileLayer.extend({
            defaultWmtsParams: {
                service: 'WMTS',
                request: 'GetTile',
                version: '1.0.0',
                layer: '',
                style: '',
                tilematrixSet: '',
                format: 'image/jpeg'
            },
            initialize: function (url, options) {
                if (!options || !options.id) {
                    throw new Error("Missing layer id option in initialization.");
                }
                this._url = url;
                var wmtsParams = L.extend({}, this.defaultWmtsParams);
                var tileSize = options.tileSize || this.options.tileSize;
                if (options.detectRetina && L.Browser.retina) {
                    wmtsParams.width = wmtsParams.height = tileSize * 2;
                }
                else {
                    wmtsParams.width = wmtsParams.height = tileSize;
                }
                for (var optionsKey in options.wmts) {
                    if (options.wmts.hasOwnProperty(optionsKey)) {
                        // all keys that are not TileLayer options go to WMTS params
                        if (!this.options.hasOwnProperty(optionsKey) && optionsKey !== 'matrixIds') {
                            wmtsParams[optionsKey] = options.wmts[optionsKey];
                        }
                    }
                }
                this.wmtsParams = wmtsParams;
                this.matrixIds = options.matrixIds || this.getDefaultMatrix();
                L.setOptions(this, options);
                this._id = options.id;
                // this.wmsParams = L.extend(options.wms);
                if (options.filter) {
                    this.wmsParams['filter'] = mapOgcCommonService.getFilter(options.filter, true);
                }
                this._httpService = http;
                // L.TileLayer.WMTS.prototype.initialize.call(this, url, this.wmsParams);
                if (options.hasOwnProperty('removeWmsParams')) {
                    for (var i = 0; i < options['removeWmsParams'].length; i++) {
                        this.removeWmsParam(options['removeWmsParams'][i]);
                    }
                }
            },
            removeWmtsParam: function (param) {
                if (this.wmsParams.hasOwnProperty(param)) {
                    delete this.wmsParams[param];
                }
            },
            onAdd: function (map) {
                L.TileLayer.prototype.onAdd.call(this, map);
            },
            getTileUrl: function (tilePoint) {
                var ident = this.matrixIds[tilePoint.z].identifier;
                var tilecol = tilePoint.x;
                var tilerow = tilePoint.y;
                var url = L.Util.template(this._url, { s: this._getSubdomain(tilePoint) });
                return url + L.Util.getParamString(this.wmtsParams, url) + '&tilematrix=' +
                    ident + '&tilerow=' + tilerow + '&tilecol=' + tilecol;
            },
            setParams: function (params, noRedraw) {
                L.extend(this.wmtsParams, params);
                if (!noRedraw) {
                    this.redraw();
                }
                return this;
            },
            getDefaultMatrix: function () {
                /**
                 * the matrix3857 represents the projection
                 * for in the IGN WMTS for the google coordinates.
                 */
                var matrixIds3857 = new Array(22);
                for (var i = 0; i < 22; i++) {
                    matrixIds3857[i] = {
                        identifier: '' + i,
                        topLeftCorner: new L.LatLng(20037508.3428, -20037508.3428)
                    };
                }
                return matrixIds3857;
            }
        });
    }
    LeafletTileLayerWmts.prototype.getLayer = function (url, options) {
        return new this.WmtsTileLayer(url, options);
    };
    LeafletTileLayerWmts.ngInjectableDef = i0.defineInjectable({ factory: function LeafletTileLayerWmts_Factory() { return new LeafletTileLayerWmts(i0.inject(i1.HttpClient), i0.inject(i2.MapOgcCommonService)); }, token: LeafletTileLayerWmts, providedIn: "root" });
    return LeafletTileLayerWmts;
}());
exports.LeafletTileLayerWmts = LeafletTileLayerWmts;
