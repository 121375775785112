"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var table_titles_component_1 = require("../components/table-titles/table-titles.component");
var table_cases_component_1 = require("../components/table-cases/table-cases.component");
var title_mandatary_change_component_1 = require("../components/title-mandatary-change/title-mandatary-change.component");
var titles_mandatary_change_confirm_component_1 = require("../components/titles-mandatary-change-confirm/titles-mandatary-change-confirm.component");
var cases_change_status_component_1 = require("../components/cases-change-status/cases-change-status.component");
var cases_change_status_confirm_component_1 = require("../components/cases-change-status-confirm/cases-change-status-confirm.component");
var cases_generate_documents_component_1 = require("../components/cases-generate-documents/cases-generate-documents.component");
var cases_generate_documents_confirm_component_1 = require("../components/cases-generate-documents-confirm/cases-generate-documents-confirm.component");
var titles_create_cases_component_1 = require("../components/titles-create-cases/titles-create-cases.component");
var titles_create_cases_confirm_component_1 = require("../components/titles-create-cases-confirm/titles-create-cases-confirm.component");
var titles_create_cases_finish_component_1 = require("../components/titles-create-cases-finish/titles-create-cases-finish.component");
var cases_generate_documents_finish_component_1 = require("../components/cases-generate-documents-finish/cases-generate-documents-finish.component");
var isVisible = function (settingsService, table, column) { return !settingsService.shouldHideColumn(table, column); };
var ɵ0 = isVisible;
exports.ɵ0 = ɵ0;
var BulkOperationType = /** @class */ (function () {
    function BulkOperationType(config) {
        this.config = config;
    }
    BulkOperationType.prototype.toString = function () {
        return this.config.key;
    };
    BulkOperationType.CASES = new BulkOperationType({
        key: 'cases',
        filterKey: 'casesFilter',
        title: 'Případy',
        backButtonText: 'Seznam případů',
        firstStepName: 'Výběr případů',
        tableComponent: table_cases_component_1.TableCasesComponent,
        getDefaultFilters: function (settingsService) {
            var loadCollections = ['caseOwnerships'];
            var columns = ['areaName', 'lv'];
            var shouldLoadTitleAndCadastre = columns.some(function (col) { return isVisible(settingsService, BulkOperationType.CASES.toString(), col); });
            var shouldLoadStatus = isVisible(settingsService, BulkOperationType.CASES.toString(), 'caseStatus');
            if (shouldLoadTitleAndCadastre) {
                loadCollections.push('titles');
            }
            if (shouldLoadStatus) {
                loadCollections.push('caseStatusSubjects');
            }
            return {
                loadCollections: loadCollections,
            };
        },
        getActions: function () {
            return [
                {
                    name: 'Změna stavu podle workflow',
                    id: 'case-status-change',
                    component: cases_change_status_component_1.CasesChangeStatusComponent,
                    confirmComponent: cases_change_status_confirm_component_1.CasesChangeStatusConfirmComponent,
                },
                {
                    name: 'Generování dokumentů',
                    id: 'generate-documents',
                    component: cases_generate_documents_component_1.CasesGenerateDocumentsComponent,
                    confirmComponent: cases_generate_documents_confirm_component_1.CasesGenerateDocumentsConfirmComponent,
                    finishComponent: cases_generate_documents_finish_component_1.CasesGenerateDocumentsFinishComponent,
                },
            ];
        },
    });
    BulkOperationType.TITLES = new BulkOperationType({
        key: 'titles',
        filterKey: 'titlesFilter',
        title: 'Listy vlastnictví',
        backButtonText: 'Seznam LV',
        firstStepName: 'Výběr LV',
        tableComponent: table_titles_component_1.TableTitlesComponent,
        getDefaultFilters: function (settingsService) {
            var loadCollections = ['ownerships'];
            var settlementProgressColumns = [
                'pernamentOccupationState',
                'overOneYearOccupationState',
                'underOneYearOccupationState',
                'easementCaseState',
            ];
            var shouldLoadSettlementProgress = settlementProgressColumns.some(function (col) { return isVisible(settingsService, BulkOperationType.TITLES.toString(), col); });
            if (shouldLoadSettlementProgress) {
                loadCollections.push('occupationResolution');
            }
            return {
                loadCollections: loadCollections,
            };
        },
        getActions: function (authService, settingsService) {
            return __awaiter(this, void 0, void 0, function () {
                var actions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            actions = [];
                            return [4 /*yield*/, settingsService.loadSettings(authService.getActualProject().id)];
                        case 1:
                            _a.sent();
                            if (authService.hasPermission('assignment_edit') && !settingsService.shouldHideColumn('titles', 'user')) {
                                actions.push({
                                    name: 'Změna přidělení LV',
                                    id: 'title-mandatary-change',
                                    component: title_mandatary_change_component_1.TitleMandataryChangeComponent,
                                    confirmComponent: titles_mandatary_change_confirm_component_1.TitlesMandataryChangeConfirmComponent,
                                });
                            }
                            actions.push({
                                name: 'Založení případů',
                                id: 'title-create-case',
                                component: titles_create_cases_component_1.TitlesCreateCasesComponent,
                                confirmComponent: titles_create_cases_confirm_component_1.TitlesCreateCasesConfirmComponent,
                                finishComponent: titles_create_cases_finish_component_1.TitlesCreateCasesFinishComponent,
                            });
                            return [2 /*return*/, actions];
                    }
                });
            });
        },
    });
    return BulkOperationType;
}());
exports.BulkOperationType = BulkOperationType;
