"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var list_service_1 = require("@app/common/services/list.service");
var word_translate_pipe_1 = require("@app/common/pipes/word-translate.pipe");
var CreateCaseSelectObligationComponent = /** @class */ (function () {
    function CreateCaseSelectObligationComponent(listService, wordTranslatePipe, restangular, ConfirmService, checklistService) {
        this.listService = listService;
        this.wordTranslatePipe = wordTranslatePipe;
        this.restangular = restangular;
        this.ConfirmService = ConfirmService;
        this.checklistService = checklistService;
        this.submitCallback = new core_1.EventEmitter();
        this.loading = true;
        this.submit = this.submit.bind(this);
    }
    CreateCaseSelectObligationComponent.prototype.ngOnInit = function () {
        delete this.data.geometricPlansSelected;
        this.loadConstructionObject();
        if (!this.data.createGroups) {
            this.data.createGroups = [];
        }
        this.checklistCreateGroups = new this.checklistService(this.data.createGroups);
        this.onSelectObligation(this.data.obligation);
        this.acceleratedPriceTypes = [
            { id: 'SINGLE', name: 'Jednotná cena za parcelu' },
            { id: 'CONSTRUCTION_OBJECT', name: 'Podle počtu ' + this.wordTranslatePipe.transform('CONSTRUCTION_OBJECT_PLURAL_GENITIV') + ' na případu' },
            { id: 'CONSTRUCTION_OBJECT_PARCEL', name: 'Podle počtu ' + this.wordTranslatePipe.transform('CONSTRUCTION_OBJECT_PLURAL_GENITIV') + ' na jednotlivých parcelách' },
            { id: 'ADMINISTRATOR', name: 'Podle počtu správců ' + this.wordTranslatePipe.transform('CONSTRUCTION_OBJECT_PLURAL_GENITIV') },
        ];
    };
    CreateCaseSelectObligationComponent.prototype.toggleCreateGroup = function (createGroup) {
        if (this.isCreateGroupDisabled()) {
            return;
        }
        this.checklistCreateGroups.toggleSelection(createGroup);
    };
    CreateCaseSelectObligationComponent.prototype.isCheckeCreateGroup = function (createGroup) {
        return this.checklistCreateGroups.isChecked(createGroup);
    };
    CreateCaseSelectObligationComponent.prototype.isCreateGroupDisabled = function () {
        return !this.data.obligation || (this.data.obligation.type !== 'AgreementOnAFutureNetworkConstructionContractLiability' && this.data.obligation.type !== 'FutureRealBurdenLiabilityAccelerated');
    };
    CreateCaseSelectObligationComponent.prototype.loadConstructionObject = function () {
        var _this = this;
        var obligations = this.listService.createList('obligations', { filters: { obligationType: ['RealBurdenLiability', 'RealBurdenLiabilityAccelerated', 'AgreementOnAFutureNetworkConstructionContractLiability', 'FutureRealBurdenLiabilityAccelerated'] } });
        Promise.all([
            this.listService.fetchResult(obligations),
            this.restangular.one('construction-objects', this.constructionObjectId).get()
        ]).then(function (data) {
            _this.obligations = data[0].list;
            _this.dataConstructionObject = data[1];
            _this.loading = false;
            // Other than global batch price with sigle price
            /*if (this.dataConstructionObject.easementGlobalPriceType !== 'B' || this.dataConstructionObject.easementGlobalOtherUnitType !== 'S') {
              this.obligations = this.obligations.filter(obligation => obligation.type !== 'RealBurdenLiabilityAccelerated');
            }*/
            if (_this.obligations.length === 1) {
                _this.data.obligation = _this.obligations[0];
                _this.submitCallback.emit({ skipped: _this.isCreateGroupDisabled() });
            }
            else {
                _this.submitCallback.emit({ skipped: false });
            }
        });
    };
    CreateCaseSelectObligationComponent.prototype.submit = function () {
        return this.submitCallback.emit();
    };
    CreateCaseSelectObligationComponent.prototype.onSelectObligation = function (obligation) {
        if (obligation) {
            this.createGroups = [
                { id: 'title', name: 'Po listech vlastnictví' },
            ];
            if (obligation.type === 'AgreementOnAFutureNetworkConstructionContractLiability' || obligation.type === 'FutureRealBurdenLiabilityAccelerated') {
                this.createGroups.push({ id: 'owner', name: 'Po vlastnících' });
            }
        }
        if (this.data.obligation === obligation) {
            return;
        }
        this.checklistCreateGroups.empty();
        this.data.createGroups.push({ id: 'title' });
        this.data.obligation = obligation;
    };
    CreateCaseSelectObligationComponent.prototype.toggleAcceleratedPriceType = function (acceleratedPriceType) {
        this.data.acceleratedPriceType = acceleratedPriceType.id;
    };
    CreateCaseSelectObligationComponent.prototype.isCheckedAcceleratedPriceType = function (acceleratedPriceType) {
        return this.data.acceleratedPriceType === acceleratedPriceType.id;
    };
    return CreateCaseSelectObligationComponent;
}());
exports.CreateCaseSelectObligationComponent = CreateCaseSelectObligationComponent;
