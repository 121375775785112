"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var case_service_1 = require("@app/ps/services/case.service");
var angular_1 = require("@uirouter/angular");
var case_status_service_1 = require("@app/ps/services/case-status.service");
var CadastreEntryComponent = /** @class */ (function () {
    function CadastreEntryComponent(restangular, caseStatusService, caseService, stateService) {
        this.restangular = restangular;
        this.caseStatusService = caseStatusService;
        this.caseService = caseService;
        this.stateService = stateService;
        this.loading = true;
        this.step = 1;
        this.onDecline = this.onDecline.bind(this);
        this.onStop = this.onStop.bind(this);
        this.onConfirm = this.onConfirm.bind(this);
        this.setStep = this.setStep.bind(this);
        this.onDateChanged = this.onDateChanged.bind(this);
    }
    CadastreEntryComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b, _c, _d, _e, _f, _g, _h;
            return __generator(this, function (_j) {
                switch (_j.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.restangular.one('cases', this.caseId).get()];
                    case 1:
                        _a.data = _j.sent();
                        _b = this;
                        return [4 /*yield*/, this.restangular.one('cases', this.caseId).one('setting').get()];
                    case 2:
                        _b.nextStates = (_j.sent()).nextStates.items;
                        _c = this;
                        if (!this.nextStates.includes('EntryStopped')) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.caseStatusService.loadStatusName('EntryStopped')];
                    case 3:
                        _d = (_j.sent()).name;
                        return [3 /*break*/, 5];
                    case 4:
                        _d = null;
                        _j.label = 5;
                    case 5:
                        _c.entryStoppedName = _d;
                        _e = this;
                        if (!this.nextStates.includes('EntryDeclined')) return [3 /*break*/, 7];
                        return [4 /*yield*/, this.caseStatusService.loadStatusName('EntryDeclined')];
                    case 6:
                        _f = (_j.sent()).name;
                        return [3 /*break*/, 8];
                    case 7:
                        _f = null;
                        _j.label = 8;
                    case 8:
                        _e.entryDeclinedName = _f;
                        _g = this;
                        if (!this.nextStates.includes('CadastreEntry')) return [3 /*break*/, 10];
                        return [4 /*yield*/, this.caseStatusService.loadStatusName('CadastreEntry')];
                    case 9:
                        _h = (_j.sent()).name;
                        return [3 /*break*/, 11];
                    case 10:
                        _h = null;
                        _j.label = 11;
                    case 11:
                        _g.cadastreEntryName = _h;
                        this.data.legalEffectsDate = this.data.entryProposalDate;
                        this.data.cadastreEntryDeliveryDate = new Date();
                        this.data.cadastreEntryDate = new Date();
                        this.data.cadastreEntryDeclinedDate = new Date();
                        this.data.cadastreEntryStoppedDate = new Date();
                        if (this.nextStates.length === 1) {
                            this.setStep(this.nextStates[0]);
                        }
                        this.loading = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    CadastreEntryComponent.prototype.setStep = function (step) {
        this.step = step;
    };
    CadastreEntryComponent.prototype.onDateChanged = function (value, from) {
        var duePeriod = this.data.obligation.duePeriod;
        var dueDataFromType = this.data.obligation.dueDateFromType;
        if (!this.data.paymentOrderDate && duePeriod && dueDataFromType === from) {
            if (value) {
                var date = new Date(value);
                this.data.dueDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() + duePeriod);
            }
            else {
                this.data.dueDate = null;
            }
        }
    };
    CadastreEntryComponent.prototype.isValidStop = function () {
        return this.data.cadastreEntryStoppedDate;
    };
    CadastreEntryComponent.prototype.onStop = function () {
        return __awaiter(this, void 0, void 0, function () {
            var sendData;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        sendData = {
                            cadastreEntryStoppedDate: this.data.cadastreEntryStoppedDate
                        };
                        return [4 /*yield*/, this.restangular
                                .one('cases', this.caseId)
                                .customPUT(sendData, 'stop-cadastre-entry')];
                    case 1:
                        _a.sent();
                        this.stateService.go('^');
                        return [2 /*return*/];
                }
            });
        });
    };
    CadastreEntryComponent.prototype.isValidDecline = function () {
        return this.data.cadastreEntryDeclinedDate;
    };
    CadastreEntryComponent.prototype.onDecline = function () {
        return __awaiter(this, void 0, void 0, function () {
            var sendData;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        sendData = {
                            cadastreEntryDeclinedDate: this.data.cadastreEntryDeclinedDate
                        };
                        return [4 /*yield*/, this.restangular
                                .one('cases', this.caseId)
                                .customPUT(sendData, 'decline-cadastre-entry')];
                    case 1:
                        _a.sent();
                        this.stateService.go('^');
                        return [2 /*return*/];
                }
            });
        });
    };
    CadastreEntryComponent.prototype.isValidAccepted = function () {
        return this.data.cadastreEntryDeliveryDate && this.data.cadastreEntryDate && this.data.cadastreEntryNumber && this.data.legalEffectsDate;
    };
    CadastreEntryComponent.prototype.onConfirm = function () {
        return __awaiter(this, void 0, void 0, function () {
            var sendData;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        sendData = {
                            dueDate: this.data.dueDate,
                            cadastreEntryDeliveryDate: this.data.cadastreEntryDeliveryDate,
                            legalEffectsDate: this.data.legalEffectsDate,
                            cadastreEntryDate: this.data.cadastreEntryDate,
                            cadastreEntryNumber: this.data.cadastreEntryNumber,
                        };
                        return [4 /*yield*/, this.restangular
                                .one('cases', this.caseId)
                                .customPUT(sendData, 'confirm-cadastre-entry')];
                    case 1:
                        _a.sent();
                        this.stateService.go('^');
                        return [2 /*return*/];
                }
            });
        });
    };
    return CadastreEntryComponent;
}());
exports.CadastreEntryComponent = CadastreEntryComponent;
