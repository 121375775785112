"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var _ = require("lodash");
var $ = require("jquery");
var file_saver_1 = require("file-saver");
var auth_service_1 = require("@app/common/services/auth.service");
var brand_translate_pipe_1 = require("@app/common/pipes/brand-translate.pipe");
var manager_report_projects_service_1 = require("@app/landing/services/manager-report-projects.service");
var common_1 = require("@angular/common");
var project_state_pipe_1 = require("@app/ps/pipes/project-state.pipe");
var ManagerReportProjectListComponent = /** @class */ (function () {
    function ManagerReportProjectListComponent(APPLICATIONS, helpIds, localStorageService, uiconstants, restangularSymap, projectStatePipe, authService, brandTranslatePipe, projectsService, datePipe) {
        this.APPLICATIONS = APPLICATIONS;
        this.helpIds = helpIds;
        this.localStorageService = localStorageService;
        this.uiconstants = uiconstants;
        this.restangularSymap = restangularSymap;
        this.projectStatePipe = projectStatePipe;
        this.authService = authService;
        this.brandTranslatePipe = brandTranslatePipe;
        this.projectsService = projectsService;
        this.datePipe = datePipe;
        this.loading = true;
        this.sortOrder = { sortBy: 'isprofond', direction: 'asc' };
        this.itemsPerPage = this.uiconstants.itemsPerPage10;
        this.dataPaging = { itemsPerPage: { id: 10, name: 10 } };
        this.filtersData = {};
        this.statuses = ['CREATED', 'STARTED_PARTIAL', 'STARTED_COMPLETE', 'PAUSED', 'FINISHED'];
        this.columns = [
            { id: 'name', name: 'Název akce' },
            { id: 'isprofond', name: 'Evidenční číslo' },
            { id: 'investor', name: 'Organizační jednotka' },
            { id: 'infrastructure', name: 'Komunikace' },
            { id: 'region', name: 'Kraj' },
            { id: 'stateName', name: 'Stav akce' },
        ];
        this.onFilter = this.onFilter.bind(this);
        this.reloadList = this.reloadList.bind(this);
        this.ngOnInit = this.ngOnInit.bind(this);
        this.onExportXlsx = this.onExportXlsx.bind(this);
        this.onExportPdf = this.onExportPdf.bind(this);
        this.onScroll = this.onScroll.bind(this);
    }
    ManagerReportProjectListComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var dataPaging, searchText, filtersData;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        dataPaging = this.localStorageService.get('syManagerReportListPageItems');
                        searchText = this.localStorageService.get('syManagerReportListSearchText');
                        filtersData = this.localStorageService.get('syManagerReportListFiltersData');
                        if (dataPaging) {
                            this.dataPaging = dataPaging;
                        }
                        if (searchText) {
                            this.searchText = searchText;
                        }
                        if (filtersData) {
                            this.filtersData = filtersData;
                        }
                        return [4 /*yield*/, this.loadData()];
                    case 1:
                        _a.sent();
                        this.initList();
                        $('#content-wrap').on('scroll', function () { _this.onScroll(); });
                        this.loading = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    ManagerReportProjectListComponent.prototype.ngOnDestroy = function () {
        if (this.xhrCancelResolve) {
            this.xhrCancelResolve();
        }
        $('#content-wrap').unbind('scroll');
    };
    ManagerReportProjectListComponent.prototype.onScroll = function () {
        var _this = this;
        clearTimeout(this.scrolling);
        this.scrolling = setTimeout(function () {
            var tableTop = _this.tableEl.nativeElement.getBoundingClientRect().top;
            var ths = $(_this.tableEl.nativeElement.querySelectorAll('thead th div'));
            if (tableTop < 80) {
                ths.css('top', (80 - tableTop) + 'px');
            }
            else {
                ths.css('top', '0px');
            }
        });
    };
    ManagerReportProjectListComponent.prototype.onPageChanged = function (event) {
        this.list.filter.offset = (event - 1) * this.dataPaging.itemsPerPage.id;
        this.reloadList();
    };
    ManagerReportProjectListComponent.prototype.pagesCounterCallbackRegister = function (event) {
        this.onListChangedPagesCounter = event.onListChanged;
    };
    ManagerReportProjectListComponent.prototype.itemCounterCallbackRegister = function (event) {
        this.onListChangedItemCounter = event.onListChanged;
    };
    ManagerReportProjectListComponent.prototype.paginationCallbackRegister = function (event) {
        this.onListChangedPagination = event.onListChanged;
    };
    ManagerReportProjectListComponent.prototype.onListChanged = function () {
        if (this.onListChangedPagination) {
            this.onListChangedPagination();
        }
        if (this.onListChangedItemCounter) {
            this.onListChangedItemCounter();
        }
        if (this.onListChangedPagesCounter) {
            this.onListChangedPagesCounter();
        }
    };
    ManagerReportProjectListComponent.prototype.onPageItemsChanged = function () {
        this.list.filter.offset = 0;
        this.localStorageService.set('syManagerReportListPageItems', this.dataPaging);
        this.reloadList();
    };
    ManagerReportProjectListComponent.prototype.reloadList = function () {
        var projects = this.sortAndFilterProjects();
        this.list.list = projects.slice(this.list.filter.offset, this.list.filter.offset + this.dataPaging.itemsPerPage.id);
        this.list.itemCount = projects.length;
        this.list.filter.limit = this.dataPaging.itemsPerPage.id;
        this.onListChanged();
    };
    ManagerReportProjectListComponent.prototype.onFilter = function () {
        this.onPageChanged(1);
        this.localStorageService.set('syManagerReportListSearchText', this.searchText);
        this.localStorageService.set('syManagerReportListFiltersData', this.filtersData);
    };
    ManagerReportProjectListComponent.prototype.getFilterItemsByColumn = function (column) {
        var columnItems = this.projects
            .map(function (p) { return { id: p[column.id], name: p[column.id] }; })
            .filter(function (i) { return i.id !== '' && i.id !== undefined; })
            .sort(function (a, b) {
            if (a.name < b.name) {
                return -1;
            }
            if (a.name > b.name) {
                return 1;
            }
            return 0;
        });
        return _.uniqBy(columnItems, 'id');
    };
    ManagerReportProjectListComponent.prototype.onExportXlsx = function () {
        var projects = this.sortAndFilterProjects();
        return this.restangularSymap.one("manager-report?type=xlsx")
            .withHttpConfig({
            responseType: 'blob',
            transformResponse: function (response, header) {
                return [response, header];
            }
        })
            .customPOST(projects.map(function (p) { return p.key; }))
            .then(function (response) {
            file_saver_1.saveAs(response[0], 'manager-report.xlsx', true);
        });
    };
    ManagerReportProjectListComponent.prototype.onExportPdf = function () {
        var projects = this.sortAndFilterProjects();
        return this.restangularSymap.one("manager-report?type=pdf")
            .withHttpConfig({
            responseType: 'blob',
            transformResponse: function (response, header) {
                return [response, header];
            }
        })
            .customPOST(projects.map(function (p) { return p.key; }))
            .then(function (response) {
            file_saver_1.saveAs(response[0], 'manager-report.pdf', true);
        });
    };
    ManagerReportProjectListComponent.prototype.loadData = function () {
        return __awaiter(this, void 0, void 0, function () {
            var cancelPromise, projects;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        cancelPromise = new Promise(function (resolve, reject) {
                            _this.xhrCancelResolve = resolve;
                        });
                        return [4 /*yield*/, this.restangularSymap
                                .one('users', this.authService.getUser().id)
                                .customGET('projects', { permission: 'manager_report' })
                                .then(function (d) {
                                return _.sortBy(d.items.filter(function (p) { return p.key !== 'sy_statistics'; }), 'isprofond');
                            })];
                    case 1:
                        projects = _a.sent();
                        this.projects = this.projectsService.createFromApiBulk(projects);
                        return [2 /*return*/, this.restangularSymap.all('dashboard')
                                .withHttpConfig({ timeout: cancelPromise })
                                .post()
                                .then(function (data) {
                                if (!data) {
                                    return;
                                }
                                _this.projects.forEach(function (project) {
                                    var dashboard = data.data.find(function (dashboard) { return dashboard.key === project.key; });
                                    if (dashboard) {
                                        manager_report_projects_service_1.ManagerReportProjectsService.mapDashboardDataOnProject(project, dashboard);
                                    }
                                });
                            })];
                }
            });
        });
    };
    ManagerReportProjectListComponent.prototype.initList = function () {
        var projects = this.sortAndFilterProjects();
        this.list = {
            loading: false,
            list: projects.slice(0, this.dataPaging.itemsPerPage.id),
            itemCount: projects.length,
            filter: { offset: 0, limit: this.dataPaging.itemsPerPage.id }
        };
    };
    ManagerReportProjectListComponent.prototype.sortAndFilterProjects = function () {
        var _this = this;
        var e_1, _a;
        var projects = this.projects;
        var _loop_1 = function (column) {
            if (this_1.filtersData[column.id] && this_1.filtersData[column.id].length) {
                var filter_1 = this_1.filtersData[column.id].map(function (i) { return i.id; });
                projects = projects.filter(function (p) { return filter_1.includes(p[column.id]); });
            }
        };
        var this_1 = this;
        try {
            // filters
            for (var _b = __values(this.columns), _c = _b.next(); !_c.done; _c = _b.next()) {
                var column = _c.value;
                _loop_1(column);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        // date filters
        if (this.filtersData.startFrom) {
            projects = projects.filter(function (p) { return p.projectStartDate && _this.datePipe.transform(p.projectStartDate, 'yyyy-MM-dd') >= _this.filtersData.startFrom; });
        }
        if (this.filtersData.startTo) {
            projects = projects.filter(function (p) { return p.projectStartDate && _this.datePipe.transform(p.projectStartDate, 'yyyy-MM-dd') <= _this.filtersData.startTo; });
        }
        // fulltext
        if (this.searchText) {
            var searchText_1 = this.searchText.toLowerCase();
            projects = projects.filter(function (p) {
                if ((p.isprofond && p.isprofond.toLowerCase().indexOf(searchText_1) !== -1)
                    || (p.name && p.name.toLowerCase().indexOf(searchText_1) !== -1)) {
                    return true;
                }
                return false;
            });
        }
        return projects;
    };
    return ManagerReportProjectListComponent;
}());
exports.ManagerReportProjectListComponent = ManagerReportProjectListComponent;
