"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var angular_1 = require("@uirouter/angular");
var CreateCaseSummaryComponent = /** @class */ (function () {
    function CreateCaseSummaryComponent(stateService) {
        this.stateService = stateService;
        this.submitCallback = new core_1.EventEmitter();
        this.submit = this.submit.bind(this);
        this.goToCasesBulkOperation = this.goToCasesBulkOperation.bind(this);
    }
    CreateCaseSummaryComponent.prototype.submit = function () {
        this.submitCallback.emit();
    };
    CreateCaseSummaryComponent.prototype.goToCasesBulkOperation = function () {
        var ids = this.data.createdCases.map(function (businessCase) { return businessCase.id; });
        this.stateService.go('symap.project.cases.bulkOperation', {
            entityIds: ids,
        });
    };
    return CreateCaseSummaryComponent;
}());
exports.CreateCaseSummaryComponent = CreateCaseSummaryComponent;
