"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
exports.RESTANGULAR_VFZE = new core_1.InjectionToken('RestangularVfze');
function RestangularVfzeFactory(restangular, config) {
    return restangular.withConfig(function (restangularConfigurer) {
        restangularConfigurer.setBaseUrl(config.BACKEND_OPTIONS.restUrlVFZE);
    });
}
exports.RestangularVfzeFactory = RestangularVfzeFactory;
