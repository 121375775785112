"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var auth_service_1 = require("../services/auth.service");
var word_translate_pipe_1 = require("@app/common/pipes/word-translate.pipe");
var i0 = require("@angular/core");
var i1 = require("../services/auth.service");
var i2 = require("./word-translate.pipe");
var getsocis = function (co) { return co.socis ? co.socis + " - " : ''; };
var ɵ0 = getsocis;
exports.ɵ0 = ɵ0;
var getsoucsek = function (co, ETAPA_USEK) { return co.sousek ? " (" + ETAPA_USEK + " " + co.sousek + ")" : ''; };
var ɵ1 = getsoucsek;
exports.ɵ1 = ɵ1;
var getsotx = function (co) { return co.sotx || ''; };
var ɵ2 = getsotx;
exports.ɵ2 = ɵ2;
var format = function (co, translation) { return "" + getsocis(co) + getsotx(co) + getsoucsek(co, translation); };
var ɵ3 = format;
exports.ɵ3 = ɵ3;
/**
 * Display name of contruction object
 */
var ConstructionObjectNamePipe = /** @class */ (function () {
    function ConstructionObjectNamePipe(authService, wordTranslatePipe, events) {
        var _this = this;
        this.authService = authService;
        this.wordTranslatePipe = wordTranslatePipe;
        this.events = events;
        this.translation = {};
        this.transform = this.transform.bind(this);
        this.authService.registerCallback(events.authProjectUpdated, function () {
            _this.translation = {};
        });
    }
    ConstructionObjectNamePipe.prototype.transform = function (constructionObject) {
        var id = constructionObject.id;
        if (!this.translation[id] || this.translation[id] === '-') {
            var translated = this.wordTranslatePipe.transform('ETAPA_USEK');
            if (translated) {
                this.translation[id] = format(constructionObject, translated);
            }
            else {
                this.translation[id] = '-';
            }
        }
        return this.translation[id];
    };
    ConstructionObjectNamePipe.ngInjectableDef = i0.defineInjectable({ factory: function ConstructionObjectNamePipe_Factory() { return new ConstructionObjectNamePipe(i0.inject(i1.AuthService), i0.inject(i2.WordTranslatePipe), i0.inject("EVENTS")); }, token: ConstructionObjectNamePipe, providedIn: "root" });
    return ConstructionObjectNamePipe;
}());
exports.ConstructionObjectNamePipe = ConstructionObjectNamePipe;
