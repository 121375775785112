"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var angular_1 = require("@uirouter/angular");
var auth_service_1 = require("@app/common/services/auth.service");
var login_service_1 = require("@app/common/services/login.service");
var menuConfig_1 = require("@app/models/menuConfig");
var brand_translate_pipe_1 = require("@app/common/pipes/brand-translate.pipe");
var MainComponent = /** @class */ (function () {
    function MainComponent(authService, loginService, brandTranslatePipe, uiRouterGlobals, transitionService, ENV, APP_BRAND, applications, localStorageService, modulesService, approveTermsService, changePasswordService, googleAnalyticsService) {
        this.authService = authService;
        this.loginService = loginService;
        this.brandTranslatePipe = brandTranslatePipe;
        this.uiRouterGlobals = uiRouterGlobals;
        this.transitionService = transitionService;
        this.ENV = ENV;
        this.APP_BRAND = APP_BRAND;
        this.applications = applications;
        this.localStorageService = localStorageService;
        this.modulesService = modulesService;
        this.approveTermsService = approveTermsService;
        this.changePasswordService = changePasswordService;
        this.googleAnalyticsService = googleAnalyticsService;
        this.collapsed = false;
        this.menuConfig = [];
    }
    MainComponent.prototype.ngOnInit = function () {
        this.authService.setActiveApplication(this.applications.sv.name);
        this.authService.updateBaseUrl();
        this.authService.updateAuthorizationHeader();
        this.googleAnalyticsService.onUserCome();
        this.collapsed = this.localStorageService.get('menuOpened');
        this.user = this.authService.getUser();
        this.servicedeskUrl = this.authService.getServiceDeskUrl();
        this.menuConfig = this.getMenuConfig();
    };
    MainComponent.prototype.onCollapse = function () {
        this.collapsed = !this.collapsed;
        this.localStorageService.set('menuOpened', this.collapsed);
    };
    MainComponent.prototype.onLogout = function () {
        this.authService.logout();
    };
    MainComponent.prototype.isActive = function (uiSref) {
        return !!this.uiRouterGlobals.$current.includes[uiSref];
    };
    MainComponent.prototype.getMenuConfig = function () {
        var menu = [
            new menuConfig_1.MenuConfig('Vzory', 'sv.samples', 'fa-home')
        ];
        if (this.authService.hasPermissionOnProject('template_new,template_aproval,template_manage')) {
            menu.push(new menuConfig_1.MenuConfig('Požadavky', 'sv.tickets', 'fa-folder-open'));
        }
        if (this.authService.hasPermissionOnProject('template_manage')) {
            menu.push(new menuConfig_1.MenuConfig('Nastavení', 'sv.settings', 'fa-wrench'));
        }
        return menu;
    };
    return MainComponent;
}());
exports.MainComponent = MainComponent;
