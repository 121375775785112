"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var angular_1 = require("@uirouter/angular");
var list_service_1 = require("@app/common/services/list.service");
var auth_service_1 = require("@app/common/services/auth.service");
var username_pipe_1 = require("@app/common/pipes/username.pipe");
var modules_service_1 = require("@app/common/services/modules.service");
var brand_translate_pipe_1 = require("@app/common/pipes/brand-translate.pipe");
var ProjectInfoComponent = /** @class */ (function () {
    function ProjectInfoComponent(APP_BRAND, helpIds, restangular, restangularSettings, authService, userNamePipe, stateService, listService, modulesService, brandTranslatePipe) {
        this.APP_BRAND = APP_BRAND;
        this.helpIds = helpIds;
        this.restangular = restangular;
        this.restangularSettings = restangularSettings;
        this.authService = authService;
        this.userNamePipe = userNamePipe;
        this.stateService = stateService;
        this.listService = listService;
        this.modulesService = modulesService;
        this.brandTranslatePipe = brandTranslatePipe;
        this.loaded = false;
        this.usersTemp = {};
        this.customGroupsVisibility = [
            'podepisujici1JinaOsoba',
            'podepisujici2JinaOsoba',
            'podepisujici3JinaOsoba',
            'podepisujici4JinaOsoba',
            'podepisujici5JinaOsoba',
            'podepisujici6JinaOsoba',
            'podepisujici7JinaOsoba',
        ];
        this.project = authService.getActualProject();
        this.uiOnParamsChanged(this.stateService.params);
        this.isActiveTab = this.isActiveTab.bind(this);
        this.saveProperties = this.saveProperties.bind(this);
        this.onUpdateDocumentList = this.onUpdateDocumentList.bind(this);
        this.onUpdateNoteList = this.onUpdateNoteList.bind(this);
    }
    ProjectInfoComponent.prototype.ngOnInit = function () {
        this.loaded = true;
        this.tabs = this.getTabs();
        this.loadNotes();
        this.loadLandTakeDocumentationNotes();
        this.loadDocuments();
        this.loadProperties();
    };
    ProjectInfoComponent.prototype.loadProperties = function () {
        return __awaiter(this, void 0, void 0, function () {
            var e_1, _a, e_2, _b, _c, _d, _e, group, _f, _g, item, _h, _j, e_2_1, e_1_1;
            return __generator(this, function (_k) {
                switch (_k.label) {
                    case 0:
                        _c = this;
                        return [4 /*yield*/, this.restangular.all('properties').customGET('')];
                    case 1:
                        _c.projectProperties = _k.sent();
                        _k.label = 2;
                    case 2:
                        _k.trys.push([2, 13, 14, 15]);
                        _d = __values(this.projectProperties), _e = _d.next();
                        _k.label = 3;
                    case 3:
                        if (!!_e.done) return [3 /*break*/, 12];
                        group = _e.value;
                        _k.label = 4;
                    case 4:
                        _k.trys.push([4, 9, 10, 11]);
                        _f = __values(group.items), _g = _f.next();
                        _k.label = 5;
                    case 5:
                        if (!!_g.done) return [3 /*break*/, 8];
                        item = _g.value;
                        if (!(item.type === 'USER_SELECT')) return [3 /*break*/, 7];
                        _h = this.usersTemp;
                        _j = item.templateVariable;
                        return [4 /*yield*/, this.modulesService.getUser(item.valueLong).then(function (d) { return d.plain(); })];
                    case 6:
                        _h[_j] = _k.sent();
                        _k.label = 7;
                    case 7:
                        _g = _f.next();
                        return [3 /*break*/, 5];
                    case 8: return [3 /*break*/, 11];
                    case 9:
                        e_2_1 = _k.sent();
                        e_2 = { error: e_2_1 };
                        return [3 /*break*/, 11];
                    case 10:
                        try {
                            if (_g && !_g.done && (_b = _f.return)) _b.call(_f);
                        }
                        finally { if (e_2) throw e_2.error; }
                        return [7 /*endfinally*/];
                    case 11:
                        _e = _d.next();
                        return [3 /*break*/, 3];
                    case 12: return [3 /*break*/, 15];
                    case 13:
                        e_1_1 = _k.sent();
                        e_1 = { error: e_1_1 };
                        return [3 /*break*/, 15];
                    case 14:
                        try {
                            if (_e && !_e.done && (_a = _d.return)) _a.call(_d);
                        }
                        finally { if (e_1) throw e_1.error; }
                        return [7 /*endfinally*/];
                    case 15: return [2 /*return*/];
                }
            });
        });
    };
    ProjectInfoComponent.prototype.saveProperties = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.restangular.all('properties').customPOST(this.projectProperties)];
                    case 1:
                        _a.projectProperties = _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ProjectInfoComponent.prototype.isActiveTab = function (id) {
        return id === this.tab;
    };
    ProjectInfoComponent.prototype.uiOnParamsChanged = function (changedParams) {
        if (changedParams.tab !== undefined) {
            this.tab = changedParams.tab || 'notes';
        }
    };
    ProjectInfoComponent.prototype.onUpdateNoteList = function () {
        var noteTab = this.tabs.find(function (tab) { return tab.id === 'notes'; });
        noteTab.name = "Pozn\u00E1mky k " + (this.APP_BRAND.NAME === 'RSD' ? 'akci' : 'projektu') + " (" + this.noteList.list.filter(function (a) { return !a.cancelled; }).length + ")";
    };
    ProjectInfoComponent.prototype.onUpdateLandTakeDocumentationNoteList = function () {
        var noteTab = this.tabs.find(function (tab) { return tab.id === 'land-take-documentation-notes'; });
        noteTab.name = "Stav dat z\u00E1borov\u00E9ho elabor\u00E1tu (" + this.landTakeDocumentationNoteList.itemCount + ")";
    };
    ProjectInfoComponent.prototype.onUpdateDocumentList = function () {
        var documentTab = this.tabs.find(function (tab) { return tab.id === 'documents'; });
        documentTab.name = "Dokumenty (" + this.documentList.list.filter(function (a) { return !a.cancelled; }).length + ")";
    };
    ProjectInfoComponent.prototype.onUserChanged = function (user, group, prefix) {
        if (user) {
            group.items.find(function (i) { return i.templateVariable === prefix; }).valueLong = user.id;
            var item = group.items.find(function (i) { return i.templateVariable === prefix + 'NazevSpolecnosti'; });
            if (item) {
                item.valueText = user.ssoCompanyName || (user.company ? user.company.name : '');
            }
            item = group.items.find(function (i) { return i.templateVariable === prefix + 'PrijmeniJmeno'; });
            if (item) {
                item.valueText = [user.surname, user.name].join(' ');
            }
            item = group.items.find(function (i) { return i.templateVariable === prefix + 'Email'; });
            if (item) {
                item.valueText = user.email;
            }
        }
    };
    ProjectInfoComponent.prototype.isVisible = function (group, item) {
        var sw = this.customGroupsVisibility.find(function (tv) { return item.templateVariable.indexOf(tv) !== -1; });
        if (!sw || sw === item.templateVariable) {
            return true;
        }
        var switchItem = group.items.find(function (i) { return i.templateVariable === sw; });
        return switchItem && switchItem.valueBoolean;
    };
    ProjectInfoComponent.prototype.translate = function (text) {
        if (text.indexOf('ŘSD ČR')) {
            text = text.replace('ŘSD ČR', this.brandTranslatePipe.transform('ps.investorCommonName'));
        }
        return text;
    };
    ProjectInfoComponent.prototype.loadNotes = function () {
        var _this = this;
        this.noteList = this.listService.createList('notes', {
            filters: { projectInfo: true, systemType: false, cancelled: false, },
            sortOrder: { sortBy: 'timestamp', direction: 'desc' }
        });
        this.listService.fetchResult(this.noteList).then(function () {
            _this.onUpdateNoteList();
        });
    };
    ProjectInfoComponent.prototype.loadLandTakeDocumentationNotes = function () {
        var _this = this;
        this.landTakeDocumentationNoteList = this.listService.createList('notes', {
            filters: { landTakeDocumentation: true, systemType: false, cancelled: false, },
            sortOrder: { sortBy: 'timestamp', direction: 'desc' }
        });
        this.listService.fetchResult(this.landTakeDocumentationNoteList).then(function () {
            _this.onUpdateLandTakeDocumentationNoteList();
        });
    };
    ProjectInfoComponent.prototype.loadDocuments = function () {
        var _this = this;
        this.documentList = this.listService.createList('attachments', {
            limit: null,
            filters: { projectInfo: true, cancelled: false },
            sortOrder: { sortBy: 'timestamp', direction: 'desc' }
        });
        this.listService.fetchResult(this.documentList).then(function () {
            _this.onUpdateDocumentList();
        });
    };
    ProjectInfoComponent.prototype.getTabs = function () {
        return [{
                name: 'Poznámky k ' + (this.APP_BRAND.NAME === 'RSD' ? 'akci' : 'projektu'),
                id: 'notes',
                href: 'symap.project.info',
                urlParams: { tab: 'notes' },
            },
            {
                name: 'Stav dat záborového elaborátu',
                id: 'land-take-documentation-notes',
                href: 'symap.project.info',
                urlParams: { tab: 'land-take-documentation-notes' },
            },
            {
                name: 'Doplňkové údaje akce',
                id: 'additional-info',
                href: 'symap.project.info',
                urlParams: { tab: 'additional-info' },
            },
            {
                name: 'Dokumenty',
                id: 'documents',
                href: 'symap.project.info',
                urlParams: { tab: 'documents' },
            },
        ];
    };
    ProjectInfoComponent.prototype.canEdit = function () {
        return this.authService.hasPermission('admin');
    };
    return ProjectInfoComponent;
}());
exports.ProjectInfoComponent = ProjectInfoComponent;
