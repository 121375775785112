"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var $ = require("jquery");
var tooltip_component_1 = require("../../components/tooltip/tooltip.component");
var TooltipDirective = /** @class */ (function () {
    function TooltipDirective(elementRef, windowDimensionService, renderer, componentFactoryResolver, viewContainerRef, injector) {
        this.elementRef = elementRef;
        this.windowDimensionService = windowDimensionService;
        this.renderer = renderer;
        this.componentFactoryResolver = componentFactoryResolver;
        this.viewContainerRef = viewContainerRef;
        this.injector = injector;
        this.callback = new core_1.EventEmitter();
        this.visibilityChange = new core_1.EventEmitter();
        this.activeContent = false; // true/false if true, then is possible to move mouse inside tooltip. Default is false.
        this.tooltipMarginTop = 0;
        this.closeTooltip = this.closeTooltip.bind(this);
        this.mouseTooltipEnter = this.mouseTooltipEnter.bind(this);
        this.mouseEnter = this.mouseEnter.bind(this);
        this.checkPosition = this.checkPosition.bind(this);
    }
    TooltipDirective.prototype.ngOnInit = function () {
        this.position = this.position === 'center' ? 'center' : 'border';
        this.activeContent = this.activeContent !== false;
        this.visible = false;
    };
    TooltipDirective.prototype.mouseEnter = function () {
        if (!this.content) {
            return;
        }
        if (!this.componentRef) {
            this.renderer.setStyle(this.elementRef.nativeElement, 'position', 'relative');
            var factory = this.componentFactoryResolver.resolveComponentFactory(tooltip_component_1.TooltipComponent);
            var injector = core_1.Injector.create([
                {
                    provide: 'tooltipConfig',
                    useValue: {
                        host: this.elementRef.nativeElement,
                        content: this.content,
                        checkPosition: this.checkPosition,
                        data: this.data,
                    }
                }
            ]);
            this.componentRef = this.viewContainerRef.createComponent(factory, 0, injector);
            // Move element inside directive
            var children = this.elementRef.nativeElement.children;
            if (children.length > 0) {
                this.elementRef.nativeElement.insertBefore(this.componentRef.location.nativeElement, children[0]);
            }
            else {
                this.elementRef.nativeElement.appendChild(this.componentRef.location.nativeElement);
            }
            this.tooltipElement = this.componentRef.location.nativeElement.querySelector('.tooltip');
            this.renderer.listen(this.tooltipElement, "click", function (e) {
                if (e.target.tagName !== 'A') {
                    e.preventDefault();
                }
                e.stopPropagation();
            });
            this.renderer.listen(this.tooltipElement, 'mouseenter', this.mouseTooltipEnter);
            this.renderer.setStyle(this.tooltipElement, 'visibility', 'hidden');
            this.tooltipContent = this.tooltipElement.querySelector('.tooltip-content');
        }
        this.visible = true;
        this.checkPosition();
        this.visibilityChange.emit({
            visible: this.visible,
            checkPosition: this.checkPosition
        });
    };
    TooltipDirective.prototype.closeTooltip = function () {
        if (!this.content || !this.visible) {
            return;
        }
        this.visible = false;
        this.checkPosition();
        this.visibilityChange.emit({
            visible: this.visible,
            checkPosition: this.checkPosition
        });
    };
    TooltipDirective.prototype.checkPosition = function () {
        this.position === 'center' ? this.setCenterPosition() : this.setBorderPosition();
    };
    // verticaly and horizontaly fix position and height of tooltip to be inside window
    TooltipDirective.prototype.setCenterPosition = function () {
        var _this = this;
        setTimeout(function () {
            if (!_this.visible || (_this.data && _this.data.hasOwnProperty('visible') && !_this.tooltipHidden())) {
                _this.renderer.removeClass(_this.tooltipElement, 'tooltip-show');
                _this.renderer.setStyle(_this.tooltipElement, 'visibility', 'hidden');
                return;
            }
            _this.renderer.removeStyle(_this.tooltipElement, 'visibility');
            _this.renderer.addClass(_this.tooltipElement, 'tooltip-show');
            var tooltipScrollable = _this.elementRef.nativeElement.querySelector('div.scrollable');
            var dimensions = new _this.windowDimensionService(_this.elementRef.nativeElement, 5);
            // position horizontaly
            _this.renderer.removeClass(_this.tooltipElement, 'left');
            if ($(_this.tooltipElement).offset().left + $(_this.tooltipElement).outerWidth() > dimensions.windowWidth + dimensions.windowOffsetLeft) {
                _this.renderer.addClass(_this.tooltipElement, 'left');
            }
            // position verticaly
            if (tooltipScrollable) {
                $(tooltipScrollable).css({
                    maxHeight: Math.min(300, dimensions.windowHeight - ($(_this.tooltipContent).innerHeight() - $(_this.tooltipContent).height())) + 'px' // minus padding
                });
            }
            // top overflow
            var newTooltipMarginTop = Math.min($(_this.tooltipContent).height(), 2 * (dimensions.windowOffsetTop - $(_this.tooltipElement).offset().top)
                - _this.tooltipMarginTop);
            if (newTooltipMarginTop < 0) {
                newTooltipMarginTop = 0;
                // bottom overflow
                newTooltipMarginTop = Math.max(-$(_this.tooltipContent).height(), 2 * ((dimensions.windowOffsetTop + dimensions.windowHeight)
                    - ($(_this.tooltipElement).offset().top + $(_this.tooltipContent).outerHeight()))
                    - _this.tooltipMarginTop);
                if (newTooltipMarginTop > 0) {
                    newTooltipMarginTop = 0;
                }
            }
            _this.renderer.setStyle(_this.tooltipContent, 'marginTop', (_this.tooltipMarginTop = newTooltipMarginTop) + 'px');
        });
    };
    TooltipDirective.prototype.setBorderPosition = function () {
        var _this = this;
        setTimeout(function () {
            if (!_this.visible || (_this.data && _this.data.hasOwnProperty('visible') && !_this.tooltipHidden())) {
                _this.renderer.removeClass(_this.tooltipElement, 'tooltip-show');
                _this.renderer.setStyle(_this.tooltipElement, 'visibility', 'hidden');
                return;
            }
            _this.renderer.removeStyle(_this.tooltipElement, 'visibility');
            _this.renderer.addClass(_this.tooltipElement, 'tooltip-show');
            var tooltipScrollable = _this.elementRef.nativeElement.querySelector('div.scrollable');
            var dimensions = new _this.windowDimensionService(_this.elementRef.nativeElement, 5);
            // position horizontaly
            _this.renderer.removeClass(_this.tooltipElement, 'left');
            if ($(_this.tooltipElement).offset().left + $(_this.tooltipElement).outerWidth() > dimensions.windowWidth + dimensions.windowOffsetLeft) {
                _this.renderer.addClass(_this.tooltipElement, 'left');
            }
            // position verticaly
            if (tooltipScrollable) {
                $(tooltipScrollable).css({
                    maxHeight: Math.min(300, dimensions.windowHeight - ($(_this.tooltipContent).innerHeight() - $(_this.tooltipContent).height())) + 'px' // minus padding
                });
            }
            // place tooltip to bottom
            var newTooltipMarginTop = ($(_this.tooltipContent).outerHeight() - $(_this.elementRef.nativeElement).height()) / 2;
            //actualy bottom or top?
            var actualyTop = parseInt($(_this.tooltipElement).css("marginTop"), 10) < 0;
            if (actualyTop) {
                //check if tooltip in top position fit to window
                if (dimensions.windowHeight < ($(_this.tooltipElement).offset().top + $(_this.tooltipElement).height() - dimensions.windowOffsetTop)) {
                    //don't fit -> let it in top
                    newTooltipMarginTop = -newTooltipMarginTop;
                }
                else {
                    //fit
                    //try bottom position
                    if (dimensions.windowHeight >= ($(_this.tooltipElement).offset().top + $(_this.tooltipElement).height() - dimensions.windowOffsetTop + $(_this.tooltipElement).height() - $(_this.elementRef.nativeElement).height())) {
                        //tooltip coul be in bottom position
                        newTooltipMarginTop = newTooltipMarginTop;
                    }
                    else {
                        //don't fit -> let it in top
                        newTooltipMarginTop = -newTooltipMarginTop;
                    }
                }
            }
            else {
                //try if bottom tooltip fit to window
                if (dimensions.windowHeight < ($(_this.elementRef.nativeElement).offset().top + $(_this.tooltipElement).height() - dimensions.windowOffsetTop)) {
                    // bottom overflow -> place tooltip top
                    newTooltipMarginTop = -newTooltipMarginTop;
                }
            }
            _this.renderer.setStyle(_this.tooltipElement, 'marginTop', (_this.tooltipMarginTop = newTooltipMarginTop) + 'px');
        });
    };
    TooltipDirective.prototype.tooltipHidden = function () {
        if (this.data && this.data.hasOwnProperty('visible')) {
            return this.data.visible !== false;
        }
        else {
            return null;
        }
    };
    TooltipDirective.prototype.mouseTooltipEnter = function (evt) {
        if (!this.activeContent) {
            this.closeTooltip();
        }
    };
    return TooltipDirective;
}());
exports.TooltipDirective = TooltipDirective;
