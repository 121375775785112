"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var pricing_expert_opinion_step_component_1 = require("@app/ps/titles/components/pricing-expert-opinion-step/pricing-expert-opinion-step.component");
var auth_service_1 = require("@app/common/services/auth.service");
var opinion_model_1 = require("@app/ps/models/opinion.model");
var list_service_1 = require("@app/common/services/list.service");
var document_type_model_1 = require("@app/common/models/document-type.model");
var PricingExpertOpinionBaseComponent = /** @class */ (function (_super) {
    __extends(PricingExpertOpinionBaseComponent, _super);
    function PricingExpertOpinionBaseComponent(restangular, authService, listService) {
        var _this = _super.call(this) || this;
        _this.restangular = restangular;
        _this.authService = authService;
        _this.listService = listService;
        _this.multipleOpinionsPerParcelEnabled = _this.authService.getActualProject().multipleOpinionsPerParcel;
        _this.filesMsg = PricingExpertOpinionBaseComponent.MSG_FILES_DEFAULT;
        _this.filesValid = false;
        _this.attachmentType = document_type_model_1.DocumentTypeModel.createById('OPINION_SCAN');
        _this.onSubmit = _this.onSubmit.bind(_this);
        return _this;
    }
    PricingExpertOpinionBaseComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.data.opinion) {
                            this.data.opinion = this.data.occupationType === 'P'
                                ? opinion_model_1.OpinionModel.createDefaultExpertOpinionPermanent()
                                : opinion_model_1.OpinionModel.createDefaultExpertOpinionTemporary();
                            this.data.opinion.title = { id: this.data.title.id };
                            this.data.opinion.parcelPrices = [];
                            if (this.data.opinionRequest) {
                                this.data.opinion.expertOpinionNumber = this.data.opinionRequest.name;
                                this.data.opinion.expert = this.data.opinionRequest.expert;
                                this.data.opinion.expertOpinionDate = this.data.opinionRequest.realizedDate;
                            }
                        }
                        return [4 /*yield*/, this.loadDocuments()];
                    case 1:
                        _a.sent();
                        this.loading = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    PricingExpertOpinionBaseComponent.prototype.onChangeExpert = function (expert) {
        this.data.opinion.expert = expert;
    };
    PricingExpertOpinionBaseComponent.prototype.isFormValid = function () {
        var hasNumber = this.data.opinion.expertOpinionNumber;
        var hasDate = this.data.opinion.expertOpinionDate;
        var hasExpert = this.data.opinion.expert;
        var hasContent = (this.data.opinion.expertOpinionBuildings || this.data.opinion.expertOpinionLand || this.data.opinion.expertOpinionVegetation) || this.data.occupationType === 'T';
        return !!(hasNumber && hasDate && hasExpert && hasContent) || this.data.readonly;
    };
    PricingExpertOpinionBaseComponent.prototype.onSubmit = function () {
        this.submitCallback.emit();
    };
    PricingExpertOpinionBaseComponent.prototype.onFileChange = function (files) {
        this.data.files = files;
        var extensionValid = files.every(function (f) { return f.extension && f.extension.toLowerCase() === 'pdf'; });
        if (this.data.files.length === 0) {
            this.filesMsg = PricingExpertOpinionBaseComponent.MSG_FILES_DEFAULT;
        }
        else if (!extensionValid) {
            this.filesMsg = PricingExpertOpinionBaseComponent.MSG_FILES_EXTENSION;
        }
        else if (this.data.files.length > 1) {
            this.filesMsg = PricingExpertOpinionBaseComponent.MSG_FILES_QUANTITY;
        }
        else {
            this.filesMsg = '';
        }
        this.filesValid = (extensionValid && this.data.files.length === 1);
    };
    PricingExpertOpinionBaseComponent.prototype.loadDocuments = function () {
        if (!this.data.opinion.opinionRequest && !this.data.opinionRequest) {
            return Promise.resolve();
        }
        this.documentList = this.listService.createList('attachments', {
            filters: { opinionRequestId: (this.data.opinionRequest ? this.data.opinionRequest.id : this.data.opinion.opinionRequest.id), cancelled: false },
            sortOrder: { sortBy: 'timestamp', direction: 'desc' }
        });
        return this.listService.fetchResult(this.documentList);
    };
    PricingExpertOpinionBaseComponent.MSG_FILES_DEFAULT = 'Vložte soubor přetažením nebo výběrem. Je možné vložit jeden soubor PDF.';
    PricingExpertOpinionBaseComponent.MSG_FILES_EXTENSION = 'Soubor musí být ve formátu PDF.';
    PricingExpertOpinionBaseComponent.MSG_FILES_QUANTITY = 'Je možné vložit pouze jeden soubor.';
    return PricingExpertOpinionBaseComponent;
}(pricing_expert_opinion_step_component_1.PricingExpertOpinionStepComponent));
exports.PricingExpertOpinionBaseComponent = PricingExpertOpinionBaseComponent;
