"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var _ = require("lodash");
var column_definition_model_1 = require("../models/column-definition.model");
var TableUtils = /** @class */ (function () {
    function TableUtils() {
    }
    TableUtils.repairColumnsStructure = function (columns) {
        return columns.map(function (column) {
            return (typeof column === 'string' ? column_definition_model_1.ColumnDefinition.createFromString(column) : column);
        });
    };
    TableUtils.getAvailableColumns = function (columns, avaliableColumns) {
        return columns.filter(function (column) { return avaliableColumns.includes(column.id); });
    };
    TableUtils.sortDataByColumnStatic = function (data, dataPath, sortDir) {
        return _.orderBy(data, dataPath, sortDir);
    };
    TableUtils.getColumnsCount = function (columns, searchString) {
        return columns.filter(function (c) { return c.id.indexOf(searchString) === 0; }).length;
    };
    return TableUtils;
}());
exports.TableUtils = TableUtils;
