"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var brand_service_1 = require("@app/common/services/brand.service");
var LandingSyComponent = /** @class */ (function () {
    function LandingSyComponent(APP_BRAND, brandService) {
        this.APP_BRAND = APP_BRAND;
        this.brandService = brandService;
        this.isActiveTab = this.isActiveTab.bind(this);
    }
    LandingSyComponent.prototype.ngOnInit = function () {
        this.isActiveTab = this.isActiveTab.bind(this);
        this.tabs = [
            {
                name: 'KN',
                id: 'cadastre-update',
                href: 'settings.modules.ps',
                urlParams: { tab: 'cadastre-update' },
            }
        ];
        if (this.brandService.isVisible('maja')) {
            this.tabs.push({
                name: 'Podmínky použití',
                id: 'terms-of-use',
                href: 'settings.modules.ps',
                urlParams: { tab: 'terms-of-use' },
            });
            this.tabs.push({
                name: 'Změny v aplikaci',
                id: 'changelog',
                href: 'settings.modules.ps',
                urlParams: { tab: 'changelog' },
            });
        }
    };
    LandingSyComponent.prototype.isActiveTab = function (tabId) {
        return tabId === this.activeTab;
    };
    return LandingSyComponent;
}());
exports.LandingSyComponent = LandingSyComponent;
