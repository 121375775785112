"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var common_address_model_1 = require("@app/ps/models/common-address.model");
var subject_basic_info_model_1 = require("@app/ps/models/subject-basic-info.model");
var i0 = require("@angular/core");
var SubjectService = /** @class */ (function () {
    function SubjectService() {
    }
    // basic info
    SubjectService.prototype.getAdaptedBasicInfo = function (subject) {
        var _subject;
        var result = new subject_basic_info_model_1.SubjectBasicInfoModel();
        if (subject && subject['subject']) { // CaseSubjectModel
            _subject = subject;
            result.ico = _subject && _subject.subject && _subject.subject.ico;
            result.opsubType = _subject && _subject.subject && _subject.subject.opsubType;
        }
        else { // SubjectModel
            _subject = subject['subjectDetail'];
            result.ico = subject['ico'];
            result.opsubType = subject['opsubType'];
        }
        result.personalIdentificationNumber = _subject && _subject.personalIdentificationNumber;
        result.maritalStatus = _subject && _subject.maritalStatus;
        result.identificationNumber = _subject && _subject.identificationNumber;
        result.invoicePayment = _subject && _subject.invoicePayment;
        result.gender = _subject && _subject.gender;
        result.dateOfBirth = _subject && _subject.dateOfBirth;
        result.businessRegisterRegionalCourt = _subject && _subject.businessRegisterRegionalCourt;
        result.businessRegisterSection = _subject && _subject.businessRegisterSection;
        result.businessRegisterEntry = _subject && _subject.businessRegisterEntry;
        result.dic = _subject && _subject.dic;
        result.vatPayer = _subject && _subject.vatPayer;
        return result;
    };
    SubjectService.prototype.getSubjectCompatibleBasicInfo = function (basicInfo) {
        return {
            maritalStatus: basicInfo.maritalStatus,
            identificationNumber: basicInfo.identificationNumber,
            invoicePayment: basicInfo.invoicePayment,
            gender: basicInfo.gender,
            personalIdentificationNumber: basicInfo.personalIdentificationNumber,
            dateOfBirth: basicInfo.dateOfBirth,
            businessRegisterRegionalCourt: basicInfo.businessRegisterRegionalCourt,
            businessRegisterSection: basicInfo.businessRegisterSection,
            businessRegisterEntry: basicInfo.businessRegisterEntry,
            dic: basicInfo.dic,
            vatPayer: basicInfo.vatPayer,
        };
    };
    // address
    SubjectService.prototype.getAdaptedAddress = function (subject) {
        return common_address_model_1.CommonAddressModel.fromSubjectAddress(subject);
    };
    SubjectService.prototype.getSubjectCompatibleAddress = function (address) {
        return common_address_model_1.CommonAddressModel.toAddress(address);
    };
    // mailing address
    SubjectService.prototype.getAdaptedMailingAddress = function (subject) {
        return common_address_model_1.CommonAddressModel.fromMailingAddress(subject);
    };
    SubjectService.prototype.getSubjectCompatibleMailingAddress = function (address) {
        return common_address_model_1.CommonAddressModel.toMailingAddress(address);
    };
    // contact person
    SubjectService.prototype.getAdaptedContactPerson = function (subject) {
        return {
            titleBefore: subject && subject.contactPersonTitleBefore,
            firstName: subject && subject.contactPersonFirstName,
            surname: subject && subject.contactPersonSurname,
            degreeAfter: subject && subject.contactPersonDegreeAfter,
            'function': subject && subject.contactPersonFunction,
            phoneNumber: subject && subject.contactPersonPhoneNumber,
            email: subject && subject.contactPersonEmail,
        };
    };
    SubjectService.prototype.getSubjectCompatibleContactPerson = function (contactPerson) {
        return {
            contactPersonTitleBefore: contactPerson && contactPerson.titleBefore,
            contactPersonFirstName: contactPerson && contactPerson.firstName,
            contactPersonSurname: contactPerson && contactPerson.surname,
            contactPersonDegreeAfter: contactPerson && contactPerson.degreeAfter,
            contactPersonFunction: contactPerson && contactPerson['function'],
            contactPersonPhoneNumber: contactPerson && contactPerson.phoneNumber,
            contactPersonEmail: contactPerson && contactPerson.email,
        };
    };
    SubjectService.ngInjectableDef = i0.defineInjectable({ factory: function SubjectService_Factory() { return new SubjectService(); }, token: SubjectService, providedIn: "root" });
    return SubjectService;
}());
exports.SubjectService = SubjectService;
