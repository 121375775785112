"use strict";
var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var checklist_service_1 = require("@app/common/services/checklist.service");
var list_service_1 = require("@app/common/services/list.service");
var ExportListComponent = /** @class */ (function () {
    function ExportListComponent(helpIds, EVENTS, checklistService, listService) {
        this.helpIds = helpIds;
        this.EVENTS = EVENTS;
        this.checklistService = checklistService;
        this.listService = listService;
        this.loading = true;
    }
    ExportListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.exportList = this.listService.createList('exports/types-allowed', undefined);
        this.listService.fetchResult(this.exportList).then(function () {
            var e_1, _a, e_2, _b;
            _this.exports = _this.exportList.list;
            try {
                for (var _c = __values(_this.exports), _d = _c.next(); !_d.done; _d = _c.next()) {
                    var exp = _d.value;
                    if (exp.userParams) {
                        try {
                            for (var _e = __values(exp.userParams), _f = _e.next(); !_f.done; _f = _e.next()) {
                                var param = _f.value;
                                param.selectedObject = {};
                                if (!param.selected) {
                                    param.selected = [];
                                }
                                param.checklist = _this.checklistService.get(param.selected);
                            }
                        }
                        catch (e_2_1) { e_2 = { error: e_2_1 }; }
                        finally {
                            try {
                                if (_f && !_f.done && (_b = _e.return)) _b.call(_e);
                            }
                            finally { if (e_2) throw e_2.error; }
                        }
                    }
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
                }
                finally { if (e_1) throw e_1.error; }
            }
            _this.loading = false;
        });
    };
    return ExportListComponent;
}());
exports.ExportListComponent = ExportListComponent;
