"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./project-select.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../select/select.component.ngfactory");
var i3 = require("../select/select.component");
var i4 = require("@uirouter/angular");
var i5 = require("../../pipes/project-name.pipe");
var i6 = require("@angular/common");
var i7 = require("./project-select.component");
var styles_ProjectSelectComponent = [i0.styles];
var RenderType_ProjectSelectComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProjectSelectComponent, data: {} });
exports.RenderType_ProjectSelectComponent = RenderType_ProjectSelectComponent;
function View_ProjectSelectComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "project-name"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", "\n"])), i1.ɵppd(2, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent, 0), _co.projects[0])); _ck(_v, 1, 0, currVal_0); }); }
function View_ProjectSelectComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "project-select"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "gmt-select", [["field", "selectedProject"], ["required", "true"]], null, [[null, "changed"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("changed" === en)) {
        var pd_0 = (_co.onProjectSelect($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_SelectComponent_0, i2.RenderType_SelectComponent)), i1.ɵdid(2, 114688, null, 0, i3.SelectComponent, ["ListLoadService", "WindowDimensionService", i1.ChangeDetectorRef, i1.ElementRef, "Restangular", i4.StateService], { data: [0, "data"], field: [1, "field"], itemPipe: [2, "itemPipe"], optionItems: [3, "optionItems"], required: [4, "required"] }, { changed: "changed" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co; var currVal_1 = "selectedProject"; var currVal_2 = _co.projectNamePipe; var currVal_3 = _co.projects; var currVal_4 = "true"; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
function View_ProjectSelectComponent_0(_l) { return i1.ɵvid(2, [i1.ɵpid(0, i5.ProjectNamePipe, []), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProjectSelectComponent_1)), i1.ɵdid(2, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProjectSelectComponent_2)), i1.ɵdid(4, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.projects.length === 1); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.projects.length > 1); _ck(_v, 4, 0, currVal_1); }, null); }
exports.View_ProjectSelectComponent_0 = View_ProjectSelectComponent_0;
function View_ProjectSelectComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "gmt-project-select", [], null, null, null, View_ProjectSelectComponent_0, RenderType_ProjectSelectComponent)), i1.ɵdid(1, 114688, null, 0, i7.ProjectSelectComponent, [i5.ProjectNamePipe], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_ProjectSelectComponent_Host_0 = View_ProjectSelectComponent_Host_0;
var ProjectSelectComponentNgFactory = i1.ɵccf("gmt-project-select", i7.ProjectSelectComponent, View_ProjectSelectComponent_Host_0, { projects: "projects", selectedProject: "selectedProject" }, { selected: "selected" }, []);
exports.ProjectSelectComponentNgFactory = ProjectSelectComponentNgFactory;
