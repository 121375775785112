"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var list_service_1 = require("@app/common/services/list.service");
var auth_service_1 = require("@app/common/services/auth.service");
var parcel_number_pipe_1 = require("@app/common/pipes/parcel-number.pipe");
var pageable_list_service_1 = require("@app/common/services/pageable.list.service");
var checklist_service_1 = require("@app/common/services/checklist.service");
var word_translate_pipe_1 = require("@app/common/pipes/word-translate.pipe");
var _ = require("lodash");
var CreateCaseSelectEasementComponent = /** @class */ (function () {
    function CreateCaseSelectEasementComponent(authService, parcelNumberPipe, listService, pageableListService, checklistService, wordTranslatePipe, confirmService) {
        this.authService = authService;
        this.parcelNumberPipe = parcelNumberPipe;
        this.listService = listService;
        this.pageableListService = pageableListService;
        this.checklistService = checklistService;
        this.wordTranslatePipe = wordTranslatePipe;
        this.confirmService = confirmService;
        this.submitCallback = new core_1.EventEmitter();
        this.backCallback = new core_1.EventEmitter();
        this.loading = false;
        this.geometricPlanIndex = 0;
        this.totalSelected = 0;
        this.allEnabledEasements = [];
        this.submit = this.submit.bind(this);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }
    CreateCaseSelectEasementComponent.prototype.ngOnInit = function () {
        var _this = this;
        delete this.data.ownershipsWithParent;
        this.data.easementsSelected = this.data.easementsSelected || [];
        var project = this.authService.getActualProject();
        this.easementPriceByShare = project.easementPriceByShare;
        this.data.geometricPlansSelected.forEach(function (geometricPlan, index) {
            geometricPlan.checkedAll = geometricPlan.easementChecklist && geometricPlan.checkedAll === true;
            _this.totalSelected += _this.getSelectedCount(index);
            if (!geometricPlan.easementChecklist) {
                geometricPlan.easementChecklist = _this.checklistService.get();
            }
        });
        this.easementPageableList = this.pageableListService.get('easements', {
            sortOrder: [{ sortBy: 'parcel.lv' }],
            filters: {
                loadCollections: ['parcel.title.ownerships'],
                validity: ['valid'],
                gpId: [],
                usersEasements: [],
            },
        }, undefined, undefined, { constructionObjects: {} });
        this.load(0);
        this.loadAllEnabledEasements();
    };
    CreateCaseSelectEasementComponent.prototype.loadAllEnabledEasements = function () {
        var _this = this;
        this.loading = true;
        var list = this.listService.createList('easements', {
            limit: null,
            sortOrder: [{ sortBy: 'parcel.lv' }],
            filters: {
                validity: ['valid'],
                gpId: this.data.geometricPlansSelected.map(function (gp) { return gp.id; }),
                usersEasements: [],
            },
        });
        this.listService.fetchResult(list).then(function (data) {
            _this.allEnabledEasements = data.list.filter(function (easement) { return !_this.isDisabled(easement); });
            _this.data.geometricPlansSelected.forEach(function (gp) {
                gp.enabledEasementsCount = _this.allEnabledEasements.filter(function (easement) { return easement.geometricPlan.id === gp.id; }).length;
            });
            _this.loading = false;
        });
    };
    CreateCaseSelectEasementComponent.prototype.getSelectedCount = function (geometricPlanIndex) {
        if (geometricPlanIndex === void 0) { geometricPlanIndex = this.geometricPlanIndex; }
        if (!this.data.geometricPlansSelected[geometricPlanIndex].easementChecklist) {
            return 0;
        }
        return this.data.geometricPlansSelected[geometricPlanIndex].easementChecklist.checkedItems.length;
    };
    CreateCaseSelectEasementComponent.prototype.pageChanged = function (pageNumber) {
        this.easementPageableList.fetchResults(pageNumber);
        this.easementPageableList.onListChanged();
    };
    CreateCaseSelectEasementComponent.prototype.load = function (geometricPlanIndex) {
        var _this = this;
        var geometricPlan = this.data.geometricPlansSelected[geometricPlanIndex];
        this.easementPageableList.list.list = null;
        this.easementPageableList.list.filter.filters.gpId = [geometricPlan.id];
        return this.easementPageableList.fetchResults(1).then(function () {
            _this.easementPageableList.onListChanged();
        });
    };
    CreateCaseSelectEasementComponent.prototype.isChecked = function (easementCheck) {
        return !this.isDisabled(easementCheck) && this.data.geometricPlansSelected[this.geometricPlanIndex].easementChecklist.isChecked(easementCheck);
    };
    CreateCaseSelectEasementComponent.prototype.toggleSelectionEasement = function (easementCheck) {
        var _this = this;
        var check = this.data.geometricPlansSelected[this.geometricPlanIndex].easementChecklist.toggleSelection(easementCheck);
        var globalPromise = Promise.resolve();
        this.allEnabledEasements.forEach(function (easement) {
            if (!_this.isDisabled(easement) && easementCheck.parcel.id === easement.parcel.id) {
                var geometricPlan_1 = _this.data.geometricPlansSelected.find(function (gp) { return gp.id == easement.geometricPlan.id; });
                var promise = void 0;
                if (easement.id === easementCheck.id) {
                    promise = Promise.resolve(true);
                }
                else if (check === _this.isChecked(easement)) {
                    promise = Promise.resolve(false);
                }
                else {
                    promise = globalPromise = globalPromise.then(function () { return new _this.confirmService({
                        title: 'Další věcné břemeno',
                        message: 'Na parcele ' + _this.parcelNumberPipe.transform(easementCheck.parcel) + ' se nachází další věcné břemeno geometrického plánu ' + geometricPlan_1.cis + '. Přejete si jej také ' + (check ? 'zvolit' : 'odvybrat') + '?',
                    }); });
                    promise.then(function (result) {
                        if (result) {
                            geometricPlan_1.easementChecklist.toggleSelection(easement);
                        }
                    });
                }
                promise.then(function (result) {
                    if (result) {
                        _this.totalSelected += check ? 1 : -1;
                        if (check) {
                            _this.data.easementsSelected.push(easement);
                        }
                        else {
                            geometricPlan_1.checkedAll = false;
                            _.remove(_this.data.easementsSelected, function (easementSelected) { return easement.id === easementSelected.id; });
                        }
                    }
                });
            }
        });
        return globalPromise;
    };
    CreateCaseSelectEasementComponent.prototype.checkAll = function () {
        var _this = this;
        var geometricPlan = this.data.geometricPlansSelected[this.geometricPlanIndex];
        this.totalSelected -= this.getSelectedCount();
        geometricPlan.checkedAll = !geometricPlan.checkedAll;
        if (geometricPlan.checkedAll) {
            this.allEnabledEasements.filter(function (easement) { return easement.geometricPlan.id === geometricPlan.id; }).forEach(function (easement) {
                if (!geometricPlan.easementChecklist.isChecked(easement)) {
                    _this.data.easementsSelected.push(easement);
                    geometricPlan.easementChecklist.checkedItems.push(easement);
                }
            });
        }
        else {
            geometricPlan.easementChecklist.checkedItems.forEach(function (checkedEasement) {
                _.remove(_this.data.easementsSelected, function (easement) { return easement.id === checkedEasement.id; });
            });
            geometricPlan.easementChecklist.empty();
        }
        this.totalSelected += this.getSelectedCount();
    };
    CreateCaseSelectEasementComponent.prototype.getTotalSelected = function () {
        return this.totalSelected;
    };
    CreateCaseSelectEasementComponent.prototype.isDisabled = function (easement) {
        return this.getDisabledReason(easement).length > 0;
    };
    CreateCaseSelectEasementComponent.prototype.getDisabledReason = function (easement) {
        var reason = [];
        if ((this.data.obligation.type === 'RealBurdenLiability' && easement.existsCaseBlockingPrice) || (this.data.obligation.type === 'RealBurdenLiabilityAccelerated' && easement.acceleratedCaseStatus)) {
            reason.push('Případ již existuje');
        }
        if (easement.easementPrice === undefined && !this.easementPriceByShare && this.data.obligation.computePrice) {
            if (new Set(easement.constructionObjects.map(function (co) { return co.easementGlobalPriceType; })).size > 1 ||
                new Set(easement.constructionObjects.map(function (co) { return co.easementGlobalOtherUnitType; })).size > 1 ||
                new Set(easement.constructionObjects.map(function (co) { return co.easementGlobalOtherLengthPrice; })).size > 1 ||
                new Set(easement.constructionObjects.map(function (co) { return co.easementGlobalOtherAreaPrice; })).size > 1 ||
                new Set(easement.constructionObjects.map(function (co) { return co.easementGlobalOtherSinglePrice; })).size > 1) {
                reason.push('Zadejte shodnou cenu VB všech souvisejích ' + this.wordTranslatePipe.transform('CONSTRUCTION_OBJECT_PLURAL_GENITIV'));
            }
            else {
                reason.push('Zadejte cenu VB');
            }
        }
        if (easement.parcel.endDate) {
            reason.push('Neplatná parcela');
        }
        if (easement.parcel.title && easement.parcel.title.blockedByUser) {
            reason.push('Odblokujte LV');
        }
        return reason;
    };
    CreateCaseSelectEasementComponent.prototype.previous = function () {
        this.geometricPlanIndex--;
        this.load(this.geometricPlanIndex);
    };
    CreateCaseSelectEasementComponent.prototype.next = function () {
        this.geometricPlanIndex++;
        this.load(this.geometricPlanIndex);
    };
    CreateCaseSelectEasementComponent.prototype.submit = function () {
        return this.submitCallback.emit();
    };
    return CreateCaseSelectEasementComponent;
}());
exports.CreateCaseSelectEasementComponent = CreateCaseSelectEasementComponent;
