"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("../../../../common/components/button/button.component.ngfactory");
var i2 = require("../../../../common/components/button/button.component");
var i3 = require("@angular/forms");
var i4 = require("@angular/common");
var i5 = require("./project-remove.component");
var i6 = require("../../../../common/services/symap-restangular.service");
var i7 = require("../../../services/project.service");
var i8 = require("../../../../common/services/login.service");
var i9 = require("../../../../common/services/auth.service");
var i10 = require("../../../../dialog/dialog.service");
var i11 = require("../../../../dialog/dialog-config");
var i12 = require("../../../../dialog/dialog-ref");
var styles_ProjectRemoveComponent = [];
var RenderType_ProjectRemoveComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ProjectRemoveComponent, data: {} });
exports.RenderType_ProjectRemoveComponent = RenderType_ProjectRemoveComponent;
function View_ProjectRemoveComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "p", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["Opravdu projekt \"", "\" smazat?"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.project.name; _ck(_v, 1, 0, currVal_0); }); }
function View_ProjectRemoveComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "actions"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "gmt-button", [], null, null, null, i1.View_ButtonComponent_0, i1.RenderType_ButtonComponent)), i0.ɵdid(2, 114688, null, 0, i2.ButtonComponent, ["ErrorHandlerService", i0.ChangeDetectorRef], { clickAction: [0, "clickAction"] }, null), (_l()(), i0.ɵted(-1, 0, ["Ano"])), (_l()(), i0.ɵeld(4, 0, null, null, 2, "gmt-button", [], null, null, null, i1.View_ButtonComponent_0, i1.RenderType_ButtonComponent)), i0.ɵdid(5, 114688, null, 0, i2.ButtonComponent, ["ErrorHandlerService", i0.ChangeDetectorRef], { clickAction: [0, "clickAction"] }, null), (_l()(), i0.ɵted(-1, 0, ["Ne"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.confirmPhase; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.decline; _ck(_v, 5, 0, currVal_1); }, null); }
function View_ProjectRemoveComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "fieldset", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 9, "div", [["class", "column-100"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "label", [["class", "required"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Heslo"])), (_l()(), i0.ɵeld(4, 0, null, null, 6, "div", [["class", "input"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 5, "input", [["type", "password"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i0.ɵnov(_v, 6)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 6).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i0.ɵnov(_v, 6)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i0.ɵnov(_v, 6)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.password = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i0.ɵdid(6, 16384, null, 0, i3.DefaultValueAccessor, [i0.Renderer2, i0.ElementRef, [2, i3.COMPOSITION_BUFFER_MODE]], null, null), i0.ɵprd(1024, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.DefaultValueAccessor]), i0.ɵdid(8, 671744, null, 0, i3.NgModel, [[8, null], [8, null], [8, null], [6, i3.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i0.ɵprd(2048, null, i3.NgControl, null, [i3.NgModel]), i0.ɵdid(10, 16384, null, 0, i3.NgControlStatus, [[4, i3.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.password; _ck(_v, 8, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 10).ngClassUntouched; var currVal_1 = i0.ɵnov(_v, 10).ngClassTouched; var currVal_2 = i0.ɵnov(_v, 10).ngClassPristine; var currVal_3 = i0.ɵnov(_v, 10).ngClassDirty; var currVal_4 = i0.ɵnov(_v, 10).ngClassValid; var currVal_5 = i0.ɵnov(_v, 10).ngClassInvalid; var currVal_6 = i0.ɵnov(_v, 10).ngClassPending; _ck(_v, 5, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
function View_ProjectRemoveComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "actions"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "gmt-button", [], null, null, null, i1.View_ButtonComponent_0, i1.RenderType_ButtonComponent)), i0.ɵdid(2, 114688, null, 0, i2.ButtonComponent, ["ErrorHandlerService", i0.ChangeDetectorRef], { clickAction: [0, "clickAction"], gmtDisabled: [1, "gmtDisabled"] }, null), (_l()(), i0.ɵted(-1, 0, ["Potvrdit"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.confirm; var currVal_1 = !_co.password; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_ProjectRemoveComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Potvrzen\u00ED smaz\u00E1n\u00ED projektu"])), (_l()(), i0.ɵeld(2, 0, null, null, 8, "div", [["class", "mt-40"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ProjectRemoveComponent_1)), i0.ɵdid(4, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ProjectRemoveComponent_2)), i0.ɵdid(6, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ProjectRemoveComponent_3)), i0.ɵdid(8, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ProjectRemoveComponent_4)), i0.ɵdid(10, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.phase; _ck(_v, 4, 0, currVal_0); var currVal_1 = !_co.phase; _ck(_v, 6, 0, currVal_1); var currVal_2 = _co.phase; _ck(_v, 8, 0, currVal_2); var currVal_3 = _co.phase; _ck(_v, 10, 0, currVal_3); }, null); }
exports.View_ProjectRemoveComponent_0 = View_ProjectRemoveComponent_0;
function View_ProjectRemoveComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "project-remove", [], null, null, null, View_ProjectRemoveComponent_0, RenderType_ProjectRemoveComponent)), i0.ɵdid(1, 114688, null, 0, i5.ProjectRemoveComponent, ["HELP_IDS", "localStorageService", "ErrorHandlerService", i6.RESTANGULAR_SYMAP, i7.ProjectService, i8.LoginService, i9.AuthService, i10.DialogService, i11.DialogConfig, i12.DialogRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_ProjectRemoveComponent_Host_0 = View_ProjectRemoveComponent_Host_0;
var ProjectRemoveComponentNgFactory = i0.ɵccf("project-remove", i5.ProjectRemoveComponent, View_ProjectRemoveComponent_Host_0, {}, {}, []);
exports.ProjectRemoveComponentNgFactory = ProjectRemoveComponentNgFactory;
