"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var list_service_1 = require("@app/common/services/list.service");
var parcel_service_1 = require("@app/ps/services/parcel.service");
var checklist_service_1 = require("@app/common/services/checklist.service");
var subject_name_pipe_1 = require("@app/common/pipes/subject-name.pipe");
var project_subject_name_pipe_1 = require("@app/ps/pipes/project-subject-name.pipe");
var _remove = require("lodash/remove");
var _orderBy = require("lodash/orderBy");
var parcel_number_pipe_1 = require("@app/common/pipes/parcel-number.pipe");
var construction_object_name_pipe_1 = require("@app/common/pipes/construction-object-name.pipe");
var parcel_utils_1 = require("@app/common/utils/parcel.utils");
var title_detail_create_case_data_service_1 = require("@app/ps/services/title-detail-create-case-data.service");
var title_detail_create_case_selection_service_1 = require("@app/ps/services/title-detail-create-case-selection.service");
var case_service_1 = require("@app/ps/services/case.service");
var CreateCaseSelectObjectsItemsComponent = /** @class */ (function () {
    function CreateCaseSelectObjectsItemsComponent(listService, checklistService, subjectNamePipe, parcelService, parcelNumberPipe, projectSubjectNamePipe, constructionObjectNamePipe, caseService, selection, titleDetailCreateCaseDataService, restangular, confirmService) {
        this.listService = listService;
        this.checklistService = checklistService;
        this.subjectNamePipe = subjectNamePipe;
        this.parcelService = parcelService;
        this.parcelNumberPipe = parcelNumberPipe;
        this.projectSubjectNamePipe = projectSubjectNamePipe;
        this.constructionObjectNamePipe = constructionObjectNamePipe;
        this.caseService = caseService;
        this.selection = selection;
        this.titleDetailCreateCaseDataService = titleDetailCreateCaseDataService;
        this.restangular = restangular;
        this.confirmService = confirmService;
        this.callbackRegister = new core_1.EventEmitter();
        this.submitCallback = new core_1.EventEmitter();
        this.loading = false;
        this.checkedAllGroups = {};
        this.sortOrder = {};
        this.dataOccupationGroups = {};
        this.checkObjectsByData = this.checkObjectsByData.bind(this);
        this.isSelectionValidLocal = this.isSelectionValidLocal.bind(this);
        this.resetSelection = this.resetSelection.bind(this);
        this.unselect = this.unselect.bind(this);
        this.toggleSelectionEasement = this.toggleSelectionEasement.bind(this);
        this.selectByCase = this.selectByCase.bind(this);
    }
    CreateCaseSelectObjectsItemsComponent.prototype.ngOnInit = function () {
        var _this = this;
        // Variables to distinguish between globally added objects and those added in current component
        this.checklistGroupLocal = this.checklistService.get();
        this.checklistEasementLocal = this.checklistService.get();
        this.checklistBuildingLocal = this.checklistService.get();
        this.checklistConstructionObjectLocal = this.checklistService.get();
        this.checkedAllConstructionObjects = false;
        this.checkedAllEasements = false;
        this.checkedAllGroups = {};
        this.checkedAllBuildings = false;
        this.selection.scope = this;
        this.dataOccupationGroupsFiltered = {};
        this.dataConstructionObjectsFiltered = [];
        this.loading = true;
        var promise = this.titleDataPromise || this.titleDetailCreateCaseDataService.loadTitleData(this.titleId);
        promise.then(function (dataLoaded) {
            _this.loading = false;
            _this.dataOpinions = dataLoaded.dataOpinions;
            _this.dataParcelPrices = dataLoaded.dataParcelPrices;
            _this.dataOccupationGroups = dataLoaded.dataOccupationGroups;
            _this.dataOccupations = dataLoaded.dataOccupations;
            _this.dataEasements = dataLoaded.dataEasements;
            _this.dataCases = dataLoaded.dataCases;
            _this.dataBuildings = dataLoaded.dataBuildings;
            _this.selection.refreshItems(_this.data.obligation);
            _this.callbackRegister.emit({
                obligationChanged: _this.selection.obligationChanged,
                refreshItems: _this.selection.refreshItems,
                resetSelection: _this.resetSelection,
                unselect: _this.unselect,
                isSelectionValidLocal: _this.isSelectionValidLocal,
                checkObjectsByData: _this.checkObjectsByData,
                selectByCase: _this.selectByCase,
                selection: _this.selection,
            });
            _this.temporaryOccupationTableNames = {
                underOneYear: _this.parcelService.getTemporaryOccupationName(_this.dataOccupationGroups.underOneYear),
                overOneYear: _this.parcelService.getTemporaryOccupationName(_this.dataOccupationGroups.overOneYear),
            };
        });
        var generalDataPromise = this.generalDataPromise || this.titleDetailCreateCaseDataService.loadGeneralData(this.titleId, promise);
        generalDataPromise.then(function (data) {
            _this.dataOwnerships = data.dataOwnerships;
        });
    };
    CreateCaseSelectObjectsItemsComponent.prototype.selectByCase = function () {
        var _this = this;
        this.data.fromCase.caseEasements.forEach(function (caseEasement) {
            var easement = _this.dataEasements.find(function (easement) {
                return easement.geometricPlan.id === caseEasement.easement.geometricPlan.id &&
                    easement.idpar === caseEasement.easement.idpar &&
                    easement.oznacVb === caseEasement.easement.oznacVb &&
                    easement.parcel.title.id === _this.titleId;
            });
            if (easement) {
                _this.selection.toggleSelectionEasement(easement);
            }
        });
        this.data.fromCase.caseOccupations.forEach(function (caseOccupation) {
            var occupationGroup;
            Object.keys(_this.dataOccupationGroupsFiltered).forEach(function (key) {
                occupationGroup = _this.dataOccupationGroupsFiltered[key].find(function (occupationGroup) {
                    return occupationGroup.occupations.some(function (occupation) {
                        return caseOccupation.occupation.zabtyp === occupation.zabtyp &&
                            caseOccupation.occupation.zabst === occupation.zabst &&
                            caseOccupation.occupation.zabcis === occupation.zabcis &&
                            occupation.parcel.title.id === _this.titleId;
                    });
                });
            });
            if (occupationGroup && !_this.checklistGroup.isChecked(occupationGroup)) {
                _this.selection.toggleSelectionGroup(occupationGroup);
            }
        });
        this.data.fromCase.caseBuildings.forEach(function (caseBuilding) {
            var building = _this.dataBuildings.find(function (building) {
                return caseBuilding.building.zabtyp === building.budId &&
                    caseBuilding.building.title.id === _this.titleId;
            });
            if (building) {
                _this.selection.toggleSelectionBuilding(building);
            }
        });
    };
    CreateCaseSelectObjectsItemsComponent.prototype.setSortData = function (sortedBy, key) {
        var sortOrder = this.sortOrder[key] || {};
        this.sortOrder[key] = sortOrder;
        sortOrder.direction = sortOrder.direction === 'asc' ? 'desc' : 'asc';
        // every first sort will be sorted 'desc'
        if (sortOrder.sortBy !== sortedBy) {
            sortOrder.direction = 'desc';
        }
        sortOrder.sortBy = sortedBy;
    };
    CreateCaseSelectObjectsItemsComponent.prototype.onSortParcel = function (sortBy, data, key) {
        this.setSortData(sortBy, key);
        return _orderBy(data, parcel_utils_1.ParcelUtils.getSortFunctionByParcelsNumbers('parcel.parcisKmen', 'parcel.parcisPod', 'parcel.parcisDil'), this.sortOrder[key].direction);
    };
    CreateCaseSelectObjectsItemsComponent.prototype.onSortAdministrator = function (sortBy, data, key) {
        var _this = this;
        this.setSortData(sortBy, key);
        return _orderBy(data, function (item) {
            var administrator = item.constructionObjects[0].administrator
                ? _this.projectSubjectNamePipe.transform(item.constructionObjects[0].administrator).toLowerCase()
                : '-';
            var constructionObjectName = _this.constructionObjectNamePipe.transform(item.constructionObjects[0]);
            return administrator + ' ' + constructionObjectName;
        }, this.sortOrder[key].direction);
    };
    CreateCaseSelectObjectsItemsComponent.prototype.onSortOccupationParcel = function (sortBy, key) {
        this.dataOccupationGroupsFiltered[key] = this.onSortParcel(sortBy, this.dataOccupationGroupsFiltered[key], key);
    };
    CreateCaseSelectObjectsItemsComponent.prototype.onSortEasementsParcel = function (sortBy) {
        this.dataEasements = this.onSortParcel(sortBy, this.dataEasements, 'easement');
    };
    CreateCaseSelectObjectsItemsComponent.prototype.onSortEasementsAdministrator = function (sortBy) {
        this.dataEasements = this.onSortAdministrator(sortBy, this.dataEasements, 'easement');
    };
    CreateCaseSelectObjectsItemsComponent.prototype.onSort = function (sortBy, dataPath, data, key) {
        this.setSortData(sortBy, key);
        return parcel_utils_1.ParcelUtils.sortDataByColumnStatic(data, dataPath, this.sortOrder[key].direction);
    };
    CreateCaseSelectObjectsItemsComponent.prototype.onSortOccupation = function (sortBy, dataPath, key) {
        this.dataOccupationGroupsFiltered[key] = this.onSort(sortBy, dataPath, this.dataOccupationGroupsFiltered[key], key);
    };
    CreateCaseSelectObjectsItemsComponent.prototype.onSortEasements = function (sortBy, dataPath) {
        this.dataEasements = this.onSort(sortBy, dataPath, this.dataEasements, 'easement');
    };
    CreateCaseSelectObjectsItemsComponent.prototype.priceColumnShown = function () {
        return this.data.obligation && this.data.obligation.computePrice && !this.caseService.easementPriceByShare(this.data.obligation);
    };
    CreateCaseSelectObjectsItemsComponent.prototype.selectConstructionObjectRequired = function () {
        return this.data.obligation && this.data.obligation.selectConstructionObjectRequired;
    };
    /**
     * Make selectino echual to this scope
     * @return {undefined}
     */
    CreateCaseSelectObjectsItemsComponent.prototype.resetSelection = function () {
        var _this = this;
        var _a, _b, _c, _d;
        // remove selected occupations, easements, buildings, construction objects
        this.checklistGroup.empty();
        (_a = this.checklistGroup.checkedItems).push.apply(_a, __spread(this.checklistGroupLocal.checkedItems));
        this.checklistEasement.empty();
        (_b = this.checklistEasement.checkedItems).push.apply(_b, __spread(this.checklistEasementLocal.checkedItems));
        this.checklistBuilding.empty();
        (_c = this.checklistBuilding.checkedItems).push.apply(_c, __spread(this.checklistBuildingLocal.checkedItems));
        this.checklistConstructionObject.empty();
        (_d = this.checklistConstructionObject.checkedItems).push.apply(_d, __spread(this.checklistConstructionObjectLocal.checkedItems));
        this.checklistConstructionObject.checkedItems.forEach(function (co) {
            _remove(co.easements, function (easement) { return !_this.checklistEasement.checkedItems.some(function (item) { return item.id === easement.id; }); });
            _remove(co.occupations, function (occupation) { return !_this.checklistGroup.checkedItems.some(function (group) { return group.occupations.some(function (item) { return item.id === occupation.id; }); }); });
        });
    };
    /**
     * Unselect all object selected in this scope
     * @return {undefined}
     */
    CreateCaseSelectObjectsItemsComponent.prototype.unselect = function () {
        var _this = this;
        this.checklistGroupLocal.checkedItems.forEach(function (group) {
            _this.checklistGroup.toggleSelection(group);
        });
        this.checklistEasementLocal.checkedItems.forEach(function (easement) {
            _this.checklistEasement.toggleSelection(easement);
        });
        this.checklistBuildingLocal.checkedItems.forEach(function (building) {
            _this.checklistBuilding.toggleSelection(building);
        });
        this.checklistConstructionObject.checkedItems.forEach(function (co) {
            _remove(co.easements, function (easement) { return !_this.checklistEasement.checkedItems.some(function (item) { return item.id === easement.id; }); });
            _remove(co.occupations, function (occupation) { return !_this.checklistGroup.checkedItems.some(function (group) { return group.occupations.some(function (item) { return item.id === occupation.id; }); }); });
        });
        this.checklistConstructionObjectLocal.checkedItems.forEach(function (co) {
            if (co.selected) {
                _this.checklistConstructionObject.toggleSelection(co);
            }
        });
        this.checklistGroupLocal.empty();
        this.checklistEasementLocal.empty();
        this.checklistBuildingLocal.empty();
        this.checklistConstructionObjectLocal.empty();
    };
    /**
     * Is something selected?
     */
    CreateCaseSelectObjectsItemsComponent.prototype.isSelectionValidLocal = function () {
        var accumulateOccupations = function (accumulator, item) {
            return item.occupations
                .filter(function (itemOccupation) { return !accumulator.some(function (accItem) { return accItem.id === itemOccupation.id; }); })
                .reduce(function (acc, itemOccupation) { return __spread(acc, [itemOccupation]); }, accumulator);
        };
        var accumulateEasements = function (accumulator, item) {
            return item.easements
                .filter(function (itemEasement) { return !accumulator.some(function (accItem) { return accItem.id === itemEasement.id; }); })
                .reduce(function (acc, itemEasement) { return __spread(acc, [itemEasement]); }, accumulator);
        };
        return (this.checklistBuildingLocal.checkedItems.length ||
            this.checklistGroupLocal.checkedItems.length ||
            this.checklistEasementLocal.checkedItems.length) &&
            (!this.data.obligation.selectConstructionObjectRequired || (this.checklistConstructionObject.checkedItems.length
                && this.checklistGroupLocal.checkedItems.reduce(accumulateOccupations, []).length === this.checklistConstructionObject.checkedItems.reduce(accumulateOccupations, []).length
                && this.checklistEasementLocal.checkedItems.length === this.checklistConstructionObject.checkedItems.reduce(accumulateEasements, []).length));
    };
    CreateCaseSelectObjectsItemsComponent.prototype.checkObjectsByData = function (data) {
        var _this = this;
        Object.entries(this.parcelService.groupOccupationsByParcelSingle((data.occupations || []).map(function (occupation) { return Object.assign(_this.dataOccupations.find(function (item) { return item.id === occupation.id; }), occupation); }))).forEach(function (_a) {
            var _b = __read(_a, 2), key = _b[0], group = _b[1];
            return _this.selection.toggleSelectionGroup(group);
        });
        (data.easements || []).forEach(function (easement) { return _this.selection.toggleSelectionEasement(easement); });
        (data.buildings || []).forEach(function (building) { return _this.selection.toggleSelectionBuilding(building); });
        (data.constructionObjects || []).map(function (co) { return _this.selection.constructionObjectToCase(co, true); }).forEach(function (constructionObject) { return _this.selection.toggleSelectionConstructionObject(constructionObject); });
    };
    CreateCaseSelectObjectsItemsComponent.prototype.toggleSelectionEasement = function (easementCheck) {
        var _this = this;
        var check = this.selection.toggleSelectionEasement(easementCheck);
        var globalPromise = Promise.resolve();
        this.dataEasements.forEach(function (easement) {
            if (_this.selection.isEasementEnabled(easement) && easementCheck.parcel.id === easement.parcel.id) {
                var promise = void 0;
                if (easement.id === easementCheck.id) {
                    promise = Promise.resolve(true);
                }
                else if (check === _this.checklistEasement.isChecked(easement)) {
                    promise = Promise.resolve(false);
                }
                else {
                    promise = Promise.resolve(false);
                    /*promise = globalPromise = globalPromise.then(() => new this.confirmService({
                        title: 'Další věcné břemeno',
                        message: 'Na parcele ' + this.parcelNumberPipe.transform(easementCheck.parcel) + ' se nachází další věcné břemeno geometrického plánu ' + easement.geometricPlan.cis + '. Přejete si jej také ' + (check ? 'zvolit' : 'odvybrat') + '?',
                    }));
                    promise.then((result) => {
                      if (result) {
                        this.selection.toggleSelectionEasement(easement);
                      }
                    });*/
                }
            }
        });
        return globalPromise;
    };
    CreateCaseSelectObjectsItemsComponent.prototype.isPermanentOccupationOnParcel = function (parcel) {
        if (!parcel || !this.dataOccupations) {
            return false;
        }
        return !!this.dataOccupations.find(function (o) {
            return o.parcel.id === parcel.id && o.occupationType.category === 'permanent';
        });
    };
    return CreateCaseSelectObjectsItemsComponent;
}());
exports.CreateCaseSelectObjectsItemsComponent = CreateCaseSelectObjectsItemsComponent;
