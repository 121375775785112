"use strict";
var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
Object.defineProperty(exports, "__esModule", { value: true });
var auth_service_1 = require("@app/common/services/auth.service");
var list_service_1 = require("@app/common/services/list.service");
var case_service_1 = require("@app/ps/services/case.service");
var _ = require("lodash");
var i0 = require("@angular/core");
var i1 = require("../../common/services/auth.service");
var i2 = require("../../common/services/list.service");
var i3 = require("./case.service");
var TitleDetailCreateCaseSelectParentService = /** @class */ (function () {
    function TitleDetailCreateCaseSelectParentService(alertService, callbackService, listService, caseService) {
        this.alertService = alertService;
        this.callbackService = callbackService;
        this.listService = listService;
        this.caseService = caseService;
        this.getOwnerships = this.getOwnerships.bind(this);
        this.createOwnershipWithParent = this.createOwnershipWithParent.bind(this);
        this.checkOwnershipsWithParent = this.checkOwnershipsWithParent.bind(this);
        this.changedParent = this.changedParent.bind(this);
        this.ownershipsPossibleParents = this.ownershipsPossibleParents.bind(this);
        this.filterOwnershipsWithoutParent = this.filterOwnershipsWithoutParent.bind(this);
    }
    TitleDetailCreateCaseSelectParentService.prototype.canBecomeParent = function (opsubType) {
        return opsubType === 'OPO';
    };
    TitleDetailCreateCaseSelectParentService.prototype.getOwnerships = function (titleId) {
        var ownerships = this.listService
            .createList('ownerships', {
            limit: null,
            filters: {
                validity: 'valid',
                titleId: titleId,
            }
        }, undefined, { parentOwnership: { load: true } });
        return this.listService.fetchResult(ownerships).then(function (data) { return data.list; });
    };
    TitleDetailCreateCaseSelectParentService.prototype.createOwnershipWithParent = function (caseOwnership, caseOwnerships, ownershipsWithParent, caseOwnershipsDefault) {
        var _this = this;
        return this.getOwnerships(caseOwnership.ownership.title.id).then(function (ownerships) {
            var possibleParents = [];
            // Try to find parents by parent id
            if (caseOwnership.ownership.parentOwnership) {
                possibleParents = ownerships.filter(function (ownership) { return ownership.id === caseOwnership.ownership.parentOwnership.id; });
            }
            // Try to find parents by type
            if (possibleParents.length === 0) {
                possibleParents = ownerships
                    .filter(function (ownership) {
                    return ownership.id !== caseOwnership.ownership.id && _this.canBecomeParent(ownership.subjects[0].opsubType);
                });
            }
            var options = possibleParents.map(function (ownership) { return { ownership: ownership }; });
            var ownershipWithParentExt = {
                caseOwnership: caseOwnership,
                caseOwnershipsDefault: caseOwnershipsDefault,
                optionItems: options,
            };
            if (ownershipWithParentExt.optionItems.length === 1) {
                caseOwnership.parentCaseOwnership = ownershipWithParentExt.optionItems[0];
            }
            if (caseOwnership.parentCaseOwnership) {
                var promise = _this.changedParent(caseOwnership.parentCaseOwnership, null, ownershipWithParentExt, caseOwnerships, ownershipsWithParent);
                if (promise) {
                    return promise.then(function () { return ownershipWithParentExt; });
                }
            }
            return ownershipWithParentExt;
        });
    };
    TitleDetailCreateCaseSelectParentService.prototype.checkOwnershipsWithParent = function (caseOwnerships) {
        var e_1, _a;
        var ownershipsWithParent = [];
        var ownershipsWithoutParent = _.filter(caseOwnerships, function (caseOwnership) { return caseOwnership.ownership.typeNeedsParent; });
        var promises = [];
        var caseOwnershipsDefault = _.clone(caseOwnerships);
        try {
            for (var ownershipsWithoutParent_1 = __values(ownershipsWithoutParent), ownershipsWithoutParent_1_1 = ownershipsWithoutParent_1.next(); !ownershipsWithoutParent_1_1.done; ownershipsWithoutParent_1_1 = ownershipsWithoutParent_1.next()) {
                var owp = ownershipsWithoutParent_1_1.value;
                if (owp.parentCaseOwnership === undefined) {
                    owp.parentCaseOwnership = null;
                }
                promises.push(this.createOwnershipWithParent(owp, caseOwnerships, ownershipsWithParent, caseOwnershipsDefault)
                    .then(function (owp2) {
                    ownershipsWithParent.push(owp2);
                }));
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (ownershipsWithoutParent_1_1 && !ownershipsWithoutParent_1_1.done && (_a = ownershipsWithoutParent_1.return)) _a.call(ownershipsWithoutParent_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return Promise.all(promises).then(function () { return ownershipsWithParent; });
    };
    TitleDetailCreateCaseSelectParentService.prototype.changedParent = function (newValue, oldValue, ownershipWithParentExt, caseOwnerships, ownershipsWithParent) {
        if (oldValue) {
            // if is not selected anywhere, then ownership is not considered parent anymore
            if (!_.some(ownershipsWithParent, function (item) { return item.parentCaseOwnership &&
                item.parentCaseOwnership.ownership.id === oldValue.ownership.id; }) &&
                _.some(ownershipWithParentExt.caseOwnershipsDefault, { ownership: { id: oldValue.ownership.id } })) {
                _.filter(caseOwnerships, { ownership: { id: oldValue.ownership.id } }).forEach(function (caseOwnership) { return caseOwnership.isParent = false; });
            }
            // caseSubjects that no longer need to have parent selected
            if (!_.some(ownershipsWithParent, function (item) { return item.caseOwnership.parentCaseOwnership &&
                item.caseOwnership.parentCaseOwnership.ownership.id === oldValue.ownership.id; }) &&
                !_.some(ownershipWithParentExt.caseOwnershipsDefault, { ownership: { id: oldValue.ownership.id } })) {
                _.remove(ownershipsWithParent, function (item) { return item.caseOwnership === oldValue; });
                if (oldValue.parentCaseOwnership) {
                    var tmpOldValue = oldValue.parentCaseOwnership;
                    oldValue.parentCaseOwnership = null;
                    this.changedParent(null, tmpOldValue, ownershipWithParentExt, caseOwnerships, ownershipsWithParent);
                }
            }
        }
        if (newValue) {
            if (this.caseService.checkParentCaseOwnershipCycle(newValue, ownershipsWithParent)) {
                ownershipWithParentExt.caseOwnership.parentCaseOwnership = null;
                this.changedParent(null, newValue, ownershipWithParentExt, caseOwnerships, ownershipsWithParent);
                new this.alertService({
                    message: 'Zjištěn cyklický vztah v zadání nadřazených subjektů!'
                }).then();
                newValue = null;
            }
            if (newValue) {
                _.filter(caseOwnerships, { ownership: { id: newValue.ownership.id } }).forEach(function (caseOwnership) { return caseOwnership.isParent = true; });
                var ownershipWithParent = _.find(ownershipsWithParent, function (item) { return item.caseOwnership.ownership.id === newValue.ownership.id; });
                if (newValue.ownership.typeNeedsParent &&
                    !ownershipWithParent) {
                    return this.createOwnershipWithParent(newValue, caseOwnerships, ownershipsWithParent, ownershipWithParentExt.caseOwnershipsDefault).then(function (ownershipWithParentExt) {
                        ownershipsWithParent.push(ownershipWithParentExt);
                    });
                }
                else if (ownershipWithParent) {
                    ownershipWithParentExt.caseOwnership.parentCaseOwnership = ownershipWithParent.caseOwnership;
                }
            }
        }
    };
    TitleDetailCreateCaseSelectParentService.prototype.ownershipsPossibleParents = function (caseOwnerships) {
        var _this = this;
        return _.filter(caseOwnerships, function (caseOwnership) { return _this.canBecomeParent(caseOwnership.ownership.subjects[0].opsubType) && !caseOwnership.ownership.typeNeedsParent; });
    };
    TitleDetailCreateCaseSelectParentService.prototype.filterOwnershipsWithoutParent = function (caseOwnershipsSelected, obligationType) {
        return this.getOwnerships(caseOwnershipsSelected[0].ownership.title.id /*, obligationType @TODO service A7 */).then(function (ownerships) {
            var caseOwnerships = _.map(ownerships, function (ownership) {
                return { ownership: ownership };
            });
            return _.filter(caseOwnerships, 
            // needsParent means that ownership type is owned by another subject
            function (caseOwnership) { return caseOwnership.ownership.typeNeedsParent &&
                // parent will be selected
                !_.some(caseOwnershipsSelected, function (caseOwnershipSelected) { return caseOwnershipSelected.ownership.id === caseOwnership.ownership.id; }); });
        });
    };
    TitleDetailCreateCaseSelectParentService.prototype.needsParentSelection = function (ownershipsWithParent) {
        return _.some(ownershipsWithParent, function (ownershipWithParent) { return ownershipWithParent.caseOwnership.ownership.typeNeedsParent && !ownershipWithParent.caseOwnership.parentCaseOwnership; });
    };
    TitleDetailCreateCaseSelectParentService.ngInjectableDef = i0.defineInjectable({ factory: function TitleDetailCreateCaseSelectParentService_Factory() { return new TitleDetailCreateCaseSelectParentService(i0.inject("AlertService"), i0.inject(i1.AuthService), i0.inject(i2.ListService), i0.inject(i3.CaseService)); }, token: TitleDetailCreateCaseSelectParentService, providedIn: "root" });
    return TitleDetailCreateCaseSelectParentService;
}());
exports.TitleDetailCreateCaseSelectParentService = TitleDetailCreateCaseSelectParentService;
