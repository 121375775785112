"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var angular_1 = require("@uirouter/angular");
var auth_service_1 = require("@app/common/services/auth.service");
var opinion_service_1 = require("@app/ps/services/opinion.service");
var PricingExpertOpinionComponent = /** @class */ (function () {
    function PricingExpertOpinionComponent(restangular, helpIds, confirmService, authService, opinionService, transitionService, stateService) {
        this.restangular = restangular;
        this.helpIds = helpIds;
        this.confirmService = confirmService;
        this.authService = authService;
        this.opinionService = opinionService;
        this.transitionService = transitionService;
        this.stateService = stateService;
        this.occupationType = 'P';
        this.returnDestination = 'overview';
        this.loading = true;
        this.readonly = false;
        this.canDelete = false;
        this.caseExistsAndNotCancelled = false;
        this.parcelPrices = [];
        this.steps = [
            { id: 'base', name: 'Základní údaje', enabled: false, help: 'CONSTRUCTION_OBJECT_NEW_EASEMENT_ADD_FILES' },
            { id: 'parcels', name: 'Zadání cen', enabled: false, help: 'CONSTRUCTION_OBJECT_NEW_EASEMENT_INFO' },
            { id: 'summary', name: 'Souhrn', enabled: false, help: 'CONSTRUCTION_OBJECT_NEW_EASEMENT_SUMMARY' },
        ];
        this.stepsHistory = [];
        this.onDelete = this.onDelete.bind(this);
        this.onFinish = this.onFinish.bind(this);
        this.onClose = this.onClose.bind(this);
    }
    PricingExpertOpinionComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.loadTitle()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.loadOpinion()];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.loadOpinionRequest()];
                    case 3:
                        _a.sent();
                        if (this.opinion) {
                            this.readonly = !this.authService.hasPermission('assignable') || this.opinion.caseExistsAndNotCancelled;
                            this.canDelete = !!(this.authService.hasPermission('assignable') && this.opinionId && !this.opinion.caseExistsAndNotCancelled);
                            this.caseExistsAndNotCancelled = this.opinion.caseExistsAndNotCancelled;
                        }
                        this.data = {
                            readonly: this.readonly,
                            title: this.title,
                            opinion: this.opinion,
                            opinionRequest: this.opinionRequest,
                            occupationType: this.occupationType,
                            updated: false,
                        };
                        this.deregisterLeaveListener = this.transitionService.onBefore({}, this.beforeExit.bind(this));
                        this.loading = false;
                        this.onStep('base', true);
                        return [2 /*return*/];
                }
            });
        });
    };
    PricingExpertOpinionComponent.prototype.onDelete = function () {
        var _this = this;
        return new this.confirmService({
            title: 'Opravdu chcete znalecký posudek smazat?',
            message: 'Všechna zadaná data budou ztracena.'
        }).then(function (data) {
            if (data === true) {
                _this.deregisterLeaveListener();
                return _this.opinionService.deleteOne(_this.opinion.id).then(function () { return true; });
            }
            else {
                return false;
            }
        }).then(function (deleted) {
            if (deleted) {
                _this.onClose();
            }
        });
    };
    PricingExpertOpinionComponent.prototype.onStep = function (id, enable) {
        var _this = this;
        if (enable === void 0) { enable = false; }
        var step = this.steps.find(function (s) { return s.id === id; });
        if (step.enabled === false && enable === false) {
            return;
        }
        // step back
        if (this.stepsHistory.includes(id)) {
            this.activeStep = step;
            this.stepsHistory.splice(this.stepsHistory.indexOf(id) + 1);
            this.steps.forEach(function (v) { return v.enabled = _this.stepsHistory.includes(v.id); });
            // step forward
        }
        else {
            this.activeStep = step;
            this.stepsHistory.push(id);
            step.enabled = true;
        }
    };
    PricingExpertOpinionComponent.prototype.onFinish = function () {
        this.deregisterLeaveListener();
        this.onClose();
    };
    PricingExpertOpinionComponent.prototype.onClose = function () {
        if (this.returnDestination === 'opinionRequest') {
            this.stateService.go('symap.project.opinionRequests.detail', { id: this.opinionRequestId }, { reload: true });
        }
        else {
            this.stateService.go('symap.project.titles.detail.pricing.overview', { titleId: this.titleId });
        }
    };
    PricingExpertOpinionComponent.prototype.loadTitle = function () {
        var _this = this;
        return this.restangular
            .one('titles', this.titleId)
            .get({ loadCollections: ['ownerships', 'ownerships.subjects'] })
            .then(function (res) { _this.title = res.plain(); });
    };
    PricingExpertOpinionComponent.prototype.loadOpinion = function () {
        var _this = this;
        if (!this.opinionId) {
            return Promise.resolve();
        }
        return this.opinionService
            .getOne(this.opinionId)
            .then(function (res) { return _this.opinion = res; });
    };
    PricingExpertOpinionComponent.prototype.loadOpinionRequest = function () {
        var _this = this;
        if (!this.opinionRequestId) {
            return Promise.resolve();
        }
        return this.restangular
            .one('opinion-requests', this.opinionRequestId)
            .get({ loadCollections: ['parcels', 'buildings'] })
            .then(function (res) { _this.opinionRequest = res.plain(); });
    };
    PricingExpertOpinionComponent.prototype.beforeExit = function () {
        var _this = this;
        if (this.readonly) {
            this.deregisterLeaveListener();
            return Promise.resolve(true);
        }
        return new this.confirmService({
            title: 'Opravdu chcete odejít?',
            message: 'Všechny úpravy budou ztraceny.'
        }).then(function (data) {
            if (data === true) {
                _this.deregisterLeaveListener();
                return Promise.resolve(true);
            }
            return Promise.resolve(false);
        });
    };
    return PricingExpertOpinionComponent;
}());
exports.PricingExpertOpinionComponent = PricingExpertOpinionComponent;
