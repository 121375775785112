require('angular');
require('angular-cookies');
require('angular-local-storage');
require('@uirouter/angularjs');
require('angular-i18n/angular-locale_cs-cz.js');

require("vanilla-autofill-event");


require("lodash");
require("restangular");

require("ng-file-upload");

require("leaflet-draw");
require("leaflet-easybutton");
require("leaflet-graphicscale");
require("leaflet.fullscreen");

// import assets less
require("../less/app.less");

//import assets css
require("leaflet/dist/leaflet.css");
require("leaflet-easybutton/src/easy-button.css");
require("leaflet-draw/dist/leaflet.draw.css");
require("leaflet-graphicscale/dist/Leaflet.GraphicScale.min.css");
require("perfect-scrollbar/css/perfect-scrollbar.css");
//require("cm-charts/dist/css/cm-charts.css");
require("../css/pickadate.css");
require("../css/pickadate.date.css");
require("leaflet.fullscreen/Control.FullScreen.css");
require("gmt-ckeditor/dist/styles.css");

require('perfect-scrollbar');
require('picker');
require('picker-date');
require('../js/angular/common/directives/pickadate/pickadate.cs_CZ.js');


require("cm-mapangular/dist/cm-mapangular.min.js");
//require("cm-charts/dist/cm-charts.js");
require("./lib/intercom.js");

require("./config.module.js");

require("./main.ts");
// require("./app.js");

require("./common/common.js");

//
// symap
//
// end symap

export const appJS = angular.module('app', [
    // Angular & vendor modules
    'ui.router',
    'ui.router.upgrade',
    'LocalStorageModule',
    'ngFileUpload',
    'ngIntercom',
    // Application modules
    'config',
    'common',
]);

appJS.config(['$urlRouterProvider', 'RestangularProvider', function ($urlRouterProvider, RestangularProvider) {

    $urlRouterProvider.otherwise(function ($injector) {
        const LoginService = $injector.get('LoginService');
        LoginService.redirectToLogin(true);
        return true;
    });

    RestangularProvider.addResponseInterceptor(function (data, operation) {
        var newData;

        // modify structure for all lists loaded from backend to behave like Array extended by itemCount attribute
        if (operation === "getList" || (operation === "post" && data && data.itemCount !== undefined && data.items !== undefined)) {
            newData = data.items || data;
            newData.itemCount = data.itemCount;
        } else {
            newData = data;
        }
        return newData;
    });
    RestangularProvider.setRequestInterceptor(function(elem, operation) {
        if (operation === "remove") {
           return null;
        }
        return elem;
    });
}]);

appJS.config(['IntercomProvider', 'intercomSettings', '$sceDelegateProvider', 'BACKEND_OPTIONS', function (IntercomProvider, intercomSettings, $sceDelegateProvider, BACKEND_OPTIONS) {
        // setup intercom
        IntercomProvider.init(intercomSettings.INTERCOM_APPID);
        $sceDelegateProvider.resourceUrlWhitelist([
            'self',
            BACKEND_OPTIONS.restUrl+'**',
            BACKEND_OPTIONS.restUrlSY+'**',
            BACKEND_OPTIONS.restUrlDI+'**',
            BACKEND_OPTIONS.restUrlPK+'**',
            BACKEND_OPTIONS.restUrlPkProxy+'**',
            BACKEND_OPTIONS.restUrlVFZE+'**',
            BACKEND_OPTIONS.restUrlSV+'**',
            BACKEND_OPTIONS.geoserverUrl+'**',
            BACKEND_OPTIONS.geoserverDIUrl+'**',
        ]);
    }]);

appJS.config(['GOOGLEANALYTICSKEY', '$windowProvider', function (GOOGLEANALYTICSKEY, $windowProvider) {
  const window = $windowProvider.$get();
  window.GoogleAnalytics('create', GOOGLEANALYTICSKEY, {
    cookieDomain: 'auto',
  });
}]);

appJS.run(['$rootScope', '$transitions', 'AuthService', '$window', '$state', '$stateParams', '$rootElement', '$timeout', '$http', '$filter', 'APP_BRAND', function ($rootScope, $transitions, AuthService, $window, $state, $stateParams, $rootElement, $timeout, $http, $filter, APP_BRAND) {
  const homeStates = ['login', 'symap', 'pk', 'dimap', 'settings', 'error', 'dashboard', 'vfze', 'landing', 'sv'];

  //ADD CSS ON CHANGE STATE
  $transitions.onFinish({}, (transition) => {
    const to = transition.to();

    // if title equals brand translation key
    if (to.data && to.data.title && APP_BRAND.TRANSLATE && APP_BRAND.TRANSLATE[to.data.title]) {
      const text = APP_BRAND.TRANSLATE[to.data.title];
      $rootScope.title = $filter('capitalize')(text);
      document.title = $filter('capitalize')(text) || 'Načítání';
    } else if (to.data && to.data.title && !to.data.title.includes('$')) {
      $rootScope.title = $filter('capitalize')(to.data.title);
      document.title = $filter('capitalize')(to.data.title) || 'Načítání';
    }

      //wait until page title changes
    window.setTimeout(() => {
      //sometime filtering with fulltext emit transitions on same site
      const fromToSame = to.name === transition.from().name;
      let toTab = null;
      //handle transition between tabs
      if (to.params && to.params.tab) {
        toTab = $stateParams.tab;
      }
      if (!fromToSame || (fromToSame && toTab)) {
        $window.GoogleAnalytics('send', {
          'hitType': 'pageview',
          'page': `${transition.to().name}${toTab ? '.'+ toTab : ''}`,
        });
      }
    },100);


    let toState = transition.to().name.split('.')[0];
    if (homeStates.includes(toState)) {
      let fromState = transition.from().name ? transition.from().name.split('.')[0] : '';
      if (fromState !== toState) {
        const bodyEl = $window.document.getElementsByTagName('body')[0];
        if (fromState) {
          bodyEl.classList.remove('mod-' + fromState);
        }
        if (toState) {
          bodyEl.classList.add('mod-' + toState);
        }
      }
    }
  });

    /*SridProvider.registerProj4Srid();
    SridProvider.registerLeafletSrid();*/
    //handle drag over body element
    //when draged, add class 'cm-is-dragover' to body
    const bodyElm = document.querySelector('body');
    let counter = 0;
    bodyElm.ondrop = (e) => {
      counter = 0;
      e.preventDefault();
      bodyElm.classList.remove('cm-is-dragover');
    };

    bodyElm.ondragover = (e) => {
      if(e.dataTransfer.dropEffect !== 'copy'){
        //IE workaround needs setter to be called
        e.dataTransfer.setDropEffect ? e.dataTransfer.setDropEffect('none') : (e.dataTransfer.dropEffect = 'none');
        e.preventDefault();
      }
    };

    bodyElm.ondragenter = (e) => {
      counter++;
      bodyElm.classList.add('cm-is-dragover');
      e.preventDefault();
    };

    bodyElm.ondragleave = (e) => {
      counter--;
      if (counter === 0) {
        bodyElm.classList.remove('cm-is-dragover');
      }
      e.preventDefault();
    };

    $rootScope.$on('$viewContentLoaded', function () {
      $timeout(() => {
         window.checkAndTriggerAutoFillEvent($rootElement.find('input'));
      }, 500);
    });
}]);
