"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var trend_data_model_1 = require("@app/common/models/trend-data.model");
var TrendComponent = /** @class */ (function () {
    function TrendComponent() {
        this.withoutPercentage = false;
    }
    TrendComponent.prototype.ngOnInit = function () {
        var percentage = TrendComponent.getPercentage(this.trendData.done, this.trendData.total);
        var percentageBefore = TrendComponent.getPercentage(this.trendData.doneBefore, this.trendData.totalBefore);
        this.trend = percentage - percentageBefore;
        if (percentage > percentageBefore) {
            this.direction = 'up';
        }
        else if (percentage < percentageBefore) {
            this.direction = 'down';
        }
        else {
            this.direction = 'stay';
        }
    };
    TrendComponent.getPercentage = function (done, total) {
        if (total === 0 || done === 0) {
            return 0;
        }
        return (done / total * 100);
    };
    return TrendComponent;
}());
exports.TrendComponent = TrendComponent;
