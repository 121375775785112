const capitalize = (text) => {
  if (_.isNumber(text)) {
    return text;
  }

  return text.charAt(0).toUpperCase() + text.slice(1);
};

function fltr() {
  return capitalize;
}

module.exports = fltr;
