import {transformListToFilter} from 'js/common/services/pure/listTransform';
import { HttpParams } from '@angular/common/http';

var common = angular.module('common');
/**
 * Handle all authentication-related operations
 */
common.service('AuthService', ['Restangular', 'localStorageService', 'EVENTS', 'BACKEND_OPTIONS', 'LOCALSTORAGEVERSION','Intercom', '$window', 'CallbackService', 'GlobalRestangularProvider', '$state', 'APPLICATIONS', '$transitions', 'GlobalRestangularDIMAPProvider', 'GlobalRestangularSYMAPProvider', '$q', 'SERVICE_DESK', 'GlobalRestangularSettingsProvider', 'LoginService', 'GlobalRestangularConfigProvider', 'APP_BRAND', '$location', 'GlobalRestangularVFZEProvider', 'GlobalRestangularSVProvider',
  function (Restangular, localStorageService, EVENTS, BACKEND_OPTIONS, LOCALSTORAGEVERSION, Intercom, $window, CallbackService, GlobalRestangularProvider, $state, APPLICATIONS, $transitions, GlobalRestangularDIMAPProvider, GlobalRestangularSYMAPProvider, $q, SERVICE_DESK, GlobalRestangularSettingsProvider, LoginService, GlobalRestangularConfigProvider, APP_BRAND, $location, GlobalRestangularVFZEProvider, GlobalRestangularSVProvider) {
    const callbacks = new CallbackService();

    // current authentization token
    this.token = null;
    // details about the user logged in
    this.user = null;
    // default project key of the user
    this.SYdefaultProjectKey = null;
    this.DIdefaultProjectKey = null;
    // user avaliable applications
    //[sy, di, pro]
    this.applications = null;

    this.symapProjects = null;
    this.symapPermissions = null;

    this.dimapProjects = null;
    this.dimapPermissions = null;

    this.promapProjects = null;
    this.promapPermissions = null;
    this.settingsPermissions = null;

    this.dashboardPermissions = null;
    this.landingPermissions = null;

    this.vfzeProjects = null;
    this.vfzePermissions = null;

    this.svPermissions = null;
    this.svProjects = null;

    this.symapLastPojectKeys = null;

    this.registerCallback = (name, callback) => callbacks.register(name, callback);
    this.unregisterCallback = (name, callback) => callbacks.unregister(name, callback);

    this.activeApplication = null;

    this.onAuthProjectUpdated = (project) => {
      callbacks.get(EVENTS.authProjectUpdated)(project);
    };

    const notifyLogout = (includeParams = true) => {
      $window.GoogleAnalytics('set', 'userId', 'unlogged');
      return LoginService.redirectToLogin(includeParams, this.isSsoLoginEnabled());
    };

    this.onAuthTimeout = () => {
      callbacks.get(EVENTS.authTimeout)(null);
      return this.onAuthUserUpdated(null);
    };

    this.onAuthUserUpdated = (userData, includeParams = true) => {
      callbacks.get(EVENTS.authUserUpdated)(userData);
      if (!userData) {
        return notifyLogout(includeParams);
      }
    };

    // register Restangular interceptor to catch timeouts and user logout
    const restangularErrorInterceptor = (response) => {
        if (response.status === 401) {
            if (response.data.message === "Expired access token") {
                if (this.isAuthenticated()) {
                    this.resetUserValues();
                    this.updateAuthorizationHeader();
                    this.onAuthTimeout();
                } else {
                    this.resetUserValues();
                }
                return false;
            }

            if (response.data.message === "Missing credentials" || response.data.message === "Bad credentials") {
                if (this.isAuthenticated()) {
                    this.resetUserValues();
                    this.updateAuthorizationHeader();
                    this.onAuthUserUpdated(null);
                } else {
                    this.resetUserValues();
                }
                return false;
            }
        }
    };

    Restangular.setErrorInterceptor(restangularErrorInterceptor);
    GlobalRestangularProvider.setErrorInterceptor(restangularErrorInterceptor);
    GlobalRestangularSYMAPProvider.setErrorInterceptor(restangularErrorInterceptor);
    GlobalRestangularDIMAPProvider.setErrorInterceptor(restangularErrorInterceptor);
    GlobalRestangularSettingsProvider.setErrorInterceptor(restangularErrorInterceptor);
    GlobalRestangularVFZEProvider.setErrorInterceptor(restangularErrorInterceptor);
    GlobalRestangularSVProvider.setErrorInterceptor(restangularErrorInterceptor);
    GlobalRestangularConfigProvider.setErrorInterceptor(restangularErrorInterceptor);

    //prevent to request list data in bad format
    const restangularInterceptor = (element) => {
      if(element && element.filter) {
        element.filter = transformListToFilter(element.filter);
      }
      return element;
    };

    Restangular.addRequestInterceptor(restangularInterceptor);

    this.isAuthorized = (user) => {
      return this.hasPermission("admin") || (user && this.user && this.user.id === user.id);
    };

    this.isAuthenticated = () => {
      if (this.user && this.token) {
        return true;
      } else {
        return false;
      }
    };

    //Return list of user applications
    this.getApplications = () => this.applications || [];

    this.getSymapProjects = () => this.symapProjects;

    this.getDimapProjects = () => this.dimapProjects;

    this.isAuthenticatedApplication = (app) => {
      return (!!this.user) && (this.getApplications().includes(app));
    };
    //FIXME - for user ID
    this.getActiveApplication = () => {
      return this.activeApplication && this.getApplications().includes(this.activeApplication) ? this.activeApplication : null;
    };

    this.setActiveApplication = (appId) => {
      //maybe save active project and application for specific userId
      localStorageService.set('auth-active-application', appId);
      this.activeApplication = appId;
    };

    this.getApplicationTransitionParams = (application) => {
      switch(application) {
        case APPLICATIONS.sy.name:
          return {
            module: this.getApplicationBaseState(application),
            projectKey: this.SYdefaultProjectKey || "sy_"
          };
        case APPLICATIONS.di.name:
          return {
            module: this.getApplicationBaseState(application),
            projectKey: this.DIdefaultProjectKey || "di_"
          };
        case APPLICATIONS.pk.name:
          return {
            module: this.getApplicationBaseState(application),
          };
        case APPLICATIONS.settings.name:
          return {
            module: this.getApplicationBaseState(application),
          };
        case APPLICATIONS.dashboard.name:
          return {
            module: this.getApplicationBaseState(application),
          };
        case APPLICATIONS.vfze.name:
          return {
            module: this.getApplicationBaseState(application),
          };
        case APPLICATIONS.landing.name:
          return {
            module: this.getApplicationBaseState(application),
          };
        case APPLICATIONS.sv.name:
          return {
            module: this.getApplicationBaseState(application),
          };
        default:
          return undefined;
      }
    };

    /**
     * Check if given applicationID has some projects.
     * Projects length are checked only in SyMAP and DiMAP
     */
    this.aplicationHasProjects = (applicationId) => {
      const app = this.user.applicationProjects && this.user.applicationProjects[applicationId];

      switch(applicationId) {
        case APPLICATIONS.sy.name:
          return app && app.length > 0;
        case APPLICATIONS.di.name:
          return app && app.length > 0;
        case APPLICATIONS.pk.name:
          return !!app;
        case APPLICATIONS.settings.name:
          return this.user.superuser;
        case APPLICATIONS.dashboard.name:
          return !!app;
        case APPLICATIONS.vfze.name:
          return !!app;
        case APPLICATIONS.landing.name:
          return APP_BRAND.NAME === 'RSD' || APP_BRAND.NAME === 'SZ';
        case APPLICATIONS.sv.name:
          return !!app;
        default:
          return false;
      }
    };

    /**
     * Return first application, that is valid and can be set as active.
     */
    this.getAplicationWithProjects = () => {
      if (this.user && this.user.applicationProjects) {
        const appProjectArr = Object.entries(this.user.applicationProjects);

        // order user aps by default - sy, di, ...
        const appOrderArr = Object.keys(APPLICATIONS).map(e => APPLICATIONS[e].name);
        appProjectArr.sort((a, b) => {
          return appOrderArr.indexOf(a[0]) - appOrderArr.indexOf(b[0]);
        });

        // find user app
        for (const [key, value] of appProjectArr) {
          switch(key) {
            case APPLICATIONS.sy.name:
              if (value.length > 0) {
                return APPLICATIONS.sy.name;
              }
              break;
            case APPLICATIONS.di.name:
              if (value.length > 0) {
                return APPLICATIONS.di.name;
              }
              break;
            case APPLICATIONS.pk.name:
              return APPLICATIONS.pk.name;
            case APPLICATIONS.settings.name:
              return APPLICATIONS.settings.name;
            case APPLICATIONS.dashboard.name:
              return APPLICATIONS.dashboard.name;
            case APPLICATIONS.vfze.name:
              return APPLICATIONS.vfze.name;
            case APPLICATIONS.landing.name:
              return APPLICATIONS.landing.name;
            case APPLICATIONS.sv.name:
              return APPLICATIONS.sv.name;
          }
        }
        return null;
      } else {
        return null;
      }
    };

    //
    this.goToActiveAplication = () => {
      // 1) if user have active application in local storage and application is valid (user has permission on it), then go to this application
      // 2) fallback is get first application with project
      // 3) fallback is first application, but without project, so user will see exception on error page
      const activeApplication = this.getActiveApplication();
      const application = activeApplication && this.aplicationHasProjects(activeApplication) ? activeApplication : this.getAplicationWithProjects() || this.getApplications()[0];

      let params;
      let opts;

      switch(application) {
        case APPLICATIONS.sy.name:
          params = this.getApplicationTransitionParams(application);
          opts = params.projectKey ? {projectKey: params.projectKey} : null;
          return $state.go(params.module, opts);
        case APPLICATIONS.di.name:
          params = this.getApplicationTransitionParams(application);
          opts = params.projectKey ? {projectKey: params.projectKey} : null;
          return $state.go(params.module, opts);
        case APPLICATIONS.pk.name:
          params = this.getApplicationTransitionParams(application);
          return $state.go(params.module);
        case APPLICATIONS.settings.name:
          params = this.getApplicationTransitionParams(application);
          return $state.go(params.module);
        case APPLICATIONS.dashboard.name:
          params = this.getApplicationTransitionParams(application);
          return $state.go(params.module);
        case APPLICATIONS.vfze.name:
          params = this.getApplicationTransitionParams(application);
          return $state.go(params.module);
        case APPLICATIONS.landing.name:
          params = this.getApplicationTransitionParams(application);
          return $state.go(params.module);
        case APPLICATIONS.sv.name:
          params = this.getApplicationTransitionParams(application);
          return $state.go(params.module);
        default:
          return undefined;
      }
    };

    this.getApplicationBaseState = (application) => {
      switch(application) {
        case APPLICATIONS.sy.name:
          return 'symap.project.home';
        case APPLICATIONS.di.name:
          return 'dimap.project.propertyStatus';
        case APPLICATIONS.pk.name:
          return 'pk.projects';
        case APPLICATIONS.settings.name:
          return 'settings.users';
        case APPLICATIONS.dashboard.name:
          return 'dashboard.projects';
        case APPLICATIONS.vfze.name:
          return 'vfze.validation';
        case APPLICATIONS.landing.name:
          return 'landing.home';
        case APPLICATIONS.sv.name:
          return 'sv.samples';
        default:
          return undefined;
      }
    };

    this.getGlobalApplicationRestUrl = () => `${BACKEND_OPTIONS.restUrl}/app`;

		this.getActiveApplicationRestUrl = () => {
      const activeApplication = this.getActiveApplication();

      switch(activeApplication) {
        case APPLICATIONS.sy.name:
          return BACKEND_OPTIONS.restUrlSY + '/' + this.getActualProject().key;
        case APPLICATIONS.di.name:
          return BACKEND_OPTIONS.restUrlDI + '/' + this.getActualProject().key;
        case APPLICATIONS.pk.name:
          return BACKEND_OPTIONS.restUrlPK;
        case APPLICATIONS.settings.name:
          return BACKEND_OPTIONS.restUrlSETTINGS;
        case APPLICATIONS.dashboard.name:
          return BACKEND_OPTIONS.restUrlSY;
        case APPLICATIONS.vfze.name:
          return BACKEND_OPTIONS.restUrlVFZE;
        case APPLICATIONS.landing.name:
          return BACKEND_OPTIONS.restUrlSY;
        case APPLICATIONS.sv.name:
          return BACKEND_OPTIONS.restUrlSV;
      }
    };

    this.passwordChanged = function () {
        this.user.passwordChanged = true;
        localStorageService.set('auth-user', this.user);
    };

    this.isFirstLogin = function() {
        return this.user.passwordChanged === false;
    };

    const hasProjectWithPermissions = () => {
      const actualProject = this.getActualProject();
      const actualPermissions = this.getActualPermissions();
      return actualProject && actualPermissions && actualPermissions[actualProject.key];
    };

    const projectPermissionsContainsAction = (actions) => {
      const actualProject = this.getActualProject();
      const actualPermissions = this.getActualPermissions();
      return actions.split(',').some(a => actualPermissions[actualProject.key].includes(a));
    };

    //check if user has permission for one of given action
    // @Array.<string> action
    this.hasPermission = function (action) {
      const activeApplication = this.getActiveApplication();

      switch (activeApplication) {
        case APPLICATIONS.sy.name:
          return hasProjectWithPermissions() && projectPermissionsContainsAction(action);
        case APPLICATIONS.di.name:
          return hasProjectWithPermissions() && projectPermissionsContainsAction(action);
        case APPLICATIONS.pk.name:
          const actualPkPermissions = this.getActualPermissions();
          const projectPkPermissions = actualPkPermissions[Object.keys(actualPkPermissions)[0]];
          return projectPkPermissions && action.split(',').some(a => projectPkPermissions.includes(a));
        case APPLICATIONS.settings.name:
          //FIXME
          return true;
        case APPLICATIONS.dashboard.name:
          return true;
        case APPLICATIONS.vfze.name:
          const actualPermissions = this.getActualPermissions();
          const projectPermissions = actualPermissions[Object.keys(actualPermissions)[0]];
          return projectPermissions && action.split(',').some(a => projectPermissions.includes(a));
        case APPLICATIONS.landing.name:
          return true;
        case APPLICATIONS.sv.name:
          return true;
        default:
          return false;
      }
    };

    this.resetUserValues = function () {
        this.token = null;
        this.user = null;
        this.SYdefaultProjectKey = null;
        this.DIdefaultProjectKey = null;
        this.applications = null;

        this.symapProjects = null;
        this.symapPermissions = null;

        this.dimapProjects = null;
        this.dimapPermissions = null;

        this.promapPermissions = null;
        this.settingsPermissions = null;
        this.dashboardPermissions = null;
        this.landingPermissions = null;

        this.vfzePermissions = null;
        this.svPermissions = null;
        this.symapLastPojectKeys = null;

        localStorageService.remove('auth-token');
        localStorageService.remove('auth-user');
        localStorageService.remove('auth-applications');
    };

    this.localStorageActual = () => {
      //get version
      const LSVersion = parseInt(localStorageService.get('version'));
      return LSVersion && LSVersion >= LOCALSTORAGEVERSION;
    };

    this.updateAuthorizationHeader = function () {
      const defaultHeader = {
        Authorization: this.token,
        'If-Modified-Since': '0',
      };

      Restangular.setDefaultHeaders(defaultHeader);
      GlobalRestangularProvider.setDefaultHeaders(defaultHeader);
      GlobalRestangularSYMAPProvider.setDefaultHeaders(defaultHeader);
      GlobalRestangularDIMAPProvider.setDefaultHeaders(defaultHeader);
      GlobalRestangularSettingsProvider.setDefaultHeaders(defaultHeader);
      GlobalRestangularConfigProvider.setDefaultHeaders(defaultHeader);
      GlobalRestangularVFZEProvider.setDefaultHeaders(defaultHeader);
      GlobalRestangularSVProvider.setDefaultHeaders(defaultHeader);

      if (this.user) {
        // register user to intercom
        Intercom.boot({
          'user_id': this.user.id,
          'applications': (this.user.applications ? this.user.applications.join(", ") : ""),
          'superuser': this.user.superuser,
          'email': this.user.email,
          'name': this.user.fullName,
          'company': this.user.company && this.user.company.companyName ? {
            'id': this.user.company.companyName,
            'name': this.user.company.companyName
          } : null
        });
      } else {
        Intercom.shutdown();
      }
    };

    this.updateBaseUrl = function () {
      Restangular.setBaseUrl(this.getActiveApplicationRestUrl());
    };

    this.getAplicationGlobalRestangularProvider = () => {
      const application = this.getActiveApplication();
      switch (application) {
        case APPLICATIONS.sy.name:
          return GlobalRestangularSYMAPProvider;
        case APPLICATIONS.di.name:
          return GlobalRestangularDIMAPProvider;
        default:
          return null;
      }
    };

    this.loadProject = function (projectKey) {
      const provider = this.getAplicationGlobalRestangularProvider();
      if (provider) {
        return provider.one('projects', projectKey).customPOST({});
      } else {
        return false;
      }
    };

    this.loadSymapProjects = (userId, params = {}) => {
      return loadProjects(userId, GlobalRestangularSYMAPProvider, APPLICATIONS.sy, params).then((data) => {
        this.symapProjects = data.projects;
        this.symapPermissions = data.permissions;
        return data;
      });
    };

    this.loadDimapProjects = (userId) => {
      return loadProjects(userId, GlobalRestangularDIMAPProvider, APPLICATIONS.di).then((data) => {
        this.dimapProjects = data.projects;
        this.dimapPermissions = data.permissions;
        return data;
      });
    };

    this.loadVfzeProjects = (userId) => {
      return GlobalRestangularSettingsProvider
        .one(`permissions/application/VFZE/user/`, userId)
        .customGET('project-permissions')
        .then(data => {
          this.vfzePermissions = Restangular.stripRestangular(data);
          return {
            projects: false,
            permissions: data,
          };
        });
    };

    this.loadPromapProjects = (userId) => {
      return GlobalRestangularSettingsProvider
        .one(`permissions/application/PRO/user/`, userId)
        .customGET('project-permissions')
        .then(data => {
          this.promapPermissions = Restangular.stripRestangular(data);
          return {
            projects: false,
            permissions: data,
          };
        });
    };

    this.loadSvProjects = (userId) => {
      return loadProjects(userId, null, APPLICATIONS.sv).then((data) => {
        this.svPermissions = data.permissions;
        return {
          projects: false,
          permissions: data,
        };
      });
    };

    const loadProjects = (userId, Provider, app, params = {}) => {
      const requests = {};

      requests.projectsRequest = Provider
        ? Provider.one('users', userId).customGET('projects', params).then((r) => Restangular.stripRestangular(r))
        : Promise.resolve({});

      requests.permissionsRequest = GlobalRestangularSettingsProvider
        .one(`permissions/application/${app.name}/user/`, userId)
        .customGET('project-permissions')
        .then((r) => Restangular.stripRestangular(r));

      return $q.all(requests).then((data) => {
        const projects = [];
        const permissions = {};

        if (data.projectsRequest && data.permissionsRequest) {
          const reqProjects = data.projectsRequest.items || [];
          const reqPermissions = data.permissionsRequest;

          projects.push(...reqProjects);
          Object.assign(permissions, reqPermissions);
        }

        return {
          projects: projects,
          permissions: permissions,
        };
      });
    };

    this.isSsoLoginEnabled = function(secret) {
      return APP_BRAND.LOGIN.SSO && !(secret && secret === 'xs22c');
    };

    this.ssoLogin = function() {
      if (APP_BRAND.LOGIN.SSO_OPTIONS && APP_BRAND.LOGIN.SSO_OPTIONS.TYPE === 'IDENTITY_SERVER') {
        function generateRandomString(length) {
            let text = "";
            let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~";
            for (var i = 0; i < length; i++) {
                text += possible.charAt(Math.floor(Math.random() * possible.length));
            }
            return text;
        }

        const code_verifier = generateRandomString(128);
        const state = generateRandomString(10);
        sessionStorage.setItem("code_verifier", code_verifier);
        sessionStorage.setItem("state", state);

        function computeChallenge(code_verifier) {
          return GlobalRestangularSettingsProvider.all('auth').customGET('sha256/base64url', {
            value: code_verifier
          });
        }

        return computeChallenge(code_verifier)
          .then(challenge => {
            var queryParams = new HttpParams()
              .set('client_id', APP_BRAND.LOGIN.SSO_OPTIONS.CLIENT_ID)
              .set('redirect_uri', APP_BRAND.LOGIN.SSO_OPTIONS.REDIRECT_URI)
              .set('scope', APP_BRAND.LOGIN.SSO_OPTIONS.SCOPES.join(' '))
              .set('grant_type', 'authorization_code')
              .set('code_challenge', challenge)
              .set('code_challenge_method', 'S256')
              .set('response_type', 'code')
              .set('state', state)
              .toString();
            window.location.href = APP_BRAND.LOGIN.SSO + '?'+ queryParams;
            return false;
        });
      } else if (APP_BRAND.LOGIN.SSO_OPTIONS && APP_BRAND.LOGIN.SSO_OPTIONS.TYPE === 'KEYCLOAK') {
        const domainConfig = APP_BRAND.LOGIN.SSO_OPTIONS.HOSTS[$location.host()];
        if (!domainConfig) {
          return false;
        }

        const queryParams = new HttpParams()
          .set('response_type', 'code')
          .set('redirect_uri', domainConfig.redirectUri)
          .set('client_id', domainConfig.clientId)
          .toString();

        let ssoUrl = APP_BRAND.LOGIN.SSO;
        Object.entries(domainConfig).forEach(([key, value]) => {
          ssoUrl = ssoUrl.replace('{' + key + '}', value);
        });

        window.location.href = ssoUrl + '?'+ queryParams;
        return false;
      }

      const ssoLogoutToken = Math.random().toString(36).slice(2) + Math.random().toString(36).slice(2);
      const redirectUrl = encodeURIComponent($location.protocol() + '://'+ $location.host() + '?ssoLogoutToken=' + ssoLogoutToken);
      const logoutUrl = encodeURIComponent(BACKEND_OPTIONS.restUrlSETTINGS + '/auth/sso/logout/' + ssoLogoutToken);
      let redirectURL = APP_BRAND.LOGIN.SSO;
      redirectURL = redirectURL.replace('xxx', redirectUrl);
      redirectURL = redirectURL.replace('yyy', logoutUrl);
      window.location.href = redirectURL;
      return false;
    };

    this.login = function (username, password, ssoToken, ssoAuthorizationCode, ssoCodeVerifier, ssoRedirectURI, ssoLogoutToken) {
      this.resetUserValues();
      this.updateAuthorizationHeader();

      const resource = (ssoToken || ssoAuthorizationCode ? 'auth/sso' : 'auth');
      const postData = (ssoToken
        ? { ssoToken: ssoToken, ssoLogoutToken: ssoLogoutToken }
        : (ssoAuthorizationCode
          ? { ssoAuthorizationCode, ssoCodeVerifier, ssoRedirectURI }
          : { username: username, password: password })
      );

      return GlobalRestangularSettingsProvider.all(resource).customPOST(postData, 'login', {loadCompany: true}).then((data) => {
        if (data.value && data.user && data.user.applications.length > 0) {
          return data;
        } else {
          const msg = data.user.applications.length === 0 ? 'Uživatel nemá přiřazen žádný modul. Kontaktujte podporu.' : 'Při přihlašování došlo k chybě. Kontaktujte podporu.';
          return $q.reject({msg: msg});
        }
      }).then(
        (data) => {
          this.token = data.value;
          this.user = data.user;
          this.applications = data.user.applications;

          // Filter "applicationProjects" to include only modules with user permissions.
          this.user.applicationProjects = Object.keys(this.user.applicationProjects)
            .filter(key => this.applications.includes(key))
            .reduce((obj, key) => {
              obj[key] = this.user.applicationProjects[key];
              return obj;
            }, {});

          //TODO - remove, get settings module from BE
          // Every user with superuser has module SETTINGS
          if (this.user.superuser) {
            this.applications.push('SETTINGS');
            // this.applications.push('VZORY');
          }

          if (APP_BRAND.NAME === 'RSD' || APP_BRAND.NAME === 'SZ') {
            this.applications.push('LANDING');
          }

          this.SYdefaultProjectKey = localStorageService.get('auth-default-project-key-sy');
          this.DIdefaultProjectKey = localStorageService.get('auth-default-project-key-di');
          this.symapLastPojectKeys = localStorageService.get('auth-last-visited-project-keys-sy');

          localStorageService.set('auth-token', this.token);
          localStorageService.set('auth-user', this.user);
          localStorageService.set('auth-applications', this.getApplications());
          this.updateAuthorizationHeader();
          this.onAuthUserUpdated(data);
        }
      );
    };

    this.switchProject = (project, applicationKey) => {
      switch(applicationKey) {
        case APPLICATIONS.sy.name:
          this.SYdefaultProjectKey = project.key;
          localStorageService.set('auth-default-project-key-sy', project.key);

          let lastProjects = this.symapLastPojectKeys ? this.symapLastPojectKeys : [];
          lastProjects.unshift(project.key);
          lastProjects = _.uniq(lastProjects);
          this.symapLastPojectKeys = lastProjects;
          localStorageService.set('auth-last-visited-project-keys-sy', lastProjects);
          break;
        case APPLICATIONS.di.name:
          this.DIdefaultProjectKey = project.key;
          localStorageService.set('auth-default-project-key-di', project.key);
          break;
      }

      this.updateBaseUrl();
    };

    this.switchProjectByKey = (projectKey, applicationKey) => {
      if (!this.getActualProjects()) {
        return;
      }

      switch (applicationKey) {
        case APPLICATIONS.sy.name:
          this.SYdefaultProjectKey = projectKey;
          break;
        case APPLICATIONS.di.name:
          this.DIdefaultProjectKey = projectKey;
          break;
      }

      const project = this.getActualProject();

      if(!project) {
        return;
      } else {
        this.switchProject(project, applicationKey);
      }
    };

    this.getActualProjects = function () {
      const application = this.getActiveApplication();
      switch (application) {
        case APPLICATIONS.sy.name:
          return this.symapProjects;
        case APPLICATIONS.di.name:
          return this.dimapProjects;
        case APPLICATIONS.vfze.name:
          return this.vfzeProjects;
        case APPLICATIONS.sv.name:
          return this.svProjects;
        case APPLICATIONS.pk.name:
          return this.promapProjects;
        default:
          return false;
      }
    };

    this.getLastVisitedProjects = function (applicationKey) {
      switch (applicationKey) {
        case APPLICATIONS.sy.name:
          return this.symapLastPojectKeys;
        default:
          return [];
      }
    };

    this.getActualProject = function () {
      const application = this.getActiveApplication();
      switch (application) {
        case APPLICATIONS.sy.name:
          return this.symapProjects && this.symapProjects.length > 0 ? this.symapProjects.find(p => p.key === this.SYdefaultProjectKey) || this.symapProjects[0] : false;
        case APPLICATIONS.di.name:
          return this.dimapProjects && this.dimapProjects.length > 0 ? this.dimapProjects.find(p => p.key === this.DIdefaultProjectKey) || this.dimapProjects[0] : false;
        default:
          return false;
      }
    };

    this.getActualPermissions = function () {
      const application = this.getActiveApplication();
      switch (application) {
        case APPLICATIONS.sy.name:
          return this.symapPermissions;
        case APPLICATIONS.di.name:
          return this.dimapPermissions;
        case APPLICATIONS.pk.name:
          return this.promapPermissions;
        case APPLICATIONS.settings.name:
          return this.settingsPermissions;
        case APPLICATIONS.dashboard.name:
          return this.dashboardPermissions;
        case APPLICATIONS.vfze.name:
          return this.vfzePermissions;
        case APPLICATIONS.landing.name:
          return this.landingPermissions;
        case APPLICATIONS.sv.name:
          return this.svPermissions;
        default:
          return false;
      }
    };

    this.checkProjectStatus = ($transition$, applicationName, projectLoadingShortcut, projectLoader, errorPage) => {
      if (this.isAuthenticated()) {
        if (this.isAuthenticatedApplication(applicationName)) {
          this.setActiveApplication(applicationName);
          const projects = this.getActualProjects();
          if (projects === false) {
            return $q.resolve();
          }
          let loaderPromise;
          if (!projects) {
            // Get only projects from object
            loaderPromise = projectLoader(this.user.id).then(data => data.projects);
          } else {
            loaderPromise = $q.resolve(projects);
          }

          return loaderPromise.then((projects) => {
            if (projects.length === 0) {
              //user is autenticated and has permissions for requested module, but does not have any projects -> redirect to login
              return $q.reject($transition$.router.stateService.target(errorPage, {errorCode: 4}));
            }

            let projectKey = $transition$.params().projectKey;
            // First load of module without prefered project. projectKey === sy_ || di_
            if (projectKey === projectLoadingShortcut && projects.length) {
              projectKey = projects[0].key;
              var newParams = angular.extend({}, $transition$.params());
              newParams.projectKey = projectKey;
              return $q.reject($transition$.router.stateService.target($transition$.to(), newParams, $transition$.options()));
            }

            if (!projects.some(p => p.key === projectKey)) {
              //user is autenticated and has permissions for requested module, but does not have permissions for requested project -> redirect to login
              return $q.reject($transition$.router.stateService.target(errorPage, {errorCode: 1}));
            }
          }, () => {
            //some error on loading projects
            return $q.reject($transition$.router.stateService.target('error', {errorCode: 5}));
          });
        } else {
          //user is autenticated, but not for requested module -> redirect to login
          return $q.reject($transition$.router.stateService.target('error', {errorCode: 2}));
        }
      } else {
        //user is not autenticated -> redirect to login
        return $q.reject($transition$.router.stateService.target('error', {errorCode: 3, redirectUrl: $window.location.hash.split("#!")[1]}));
      }
    };

    this.logout = () => {
        // set google analytics user as unlogged
        $window.GoogleAnalytics('set', 'userId', 'unlogged');

        GlobalRestangularSettingsProvider.all('auth').one('logout').customPOST(this.token);

        this.resetUserValues();
        this.updateAuthorizationHeader();
        this.onAuthProjectUpdated(null);
        return this.onAuthUserUpdated(null, false).then(() => {
          if (APP_BRAND.LOGIN.SSO_LOGOUT) {
            window.location.href = APP_BRAND.LOGIN.SSO_LOGOUT;
          }
          return true;
        });
    };

    const getServiceDeskPortalID = () => {
      const application = this.getActiveApplication();
      switch (application) {
        case APPLICATIONS.sy.name:
          return SERVICE_DESK.PORTALSY;
        case APPLICATIONS.di.name:
          return SERVICE_DESK.PORTALDI;
        case APPLICATIONS.pk.name:
          return SERVICE_DESK.PORTALPRO;
        case APPLICATIONS.settings.name:
          return SERVICE_DESK.PORTALSETTINGS;
        default:
          return SERVICE_DESK.PORTALSY;
      }
    };

    this.getServicedeskUrl = function(loginType) {
      if (loginType !== true) {
        loginType = false;
      }
      if (!SERVICE_DESK.BASEURL) {
        return null;
      }
      let url = SERVICE_DESK.BASEURL;
      const portalId = getServiceDeskPortalID();
      if (!loginType && portalId) {
        url += '/portal/' + portalId;
      } else if (loginType && portalId) {
        url += '/portal/' + portalId;
        url += '/create/' + SERVICE_DESK.CREATELOGINERROR;
      }
      return url;
    };

    const localStorageActual = this.localStorageActual();
    if (!localStorageActual) {
      //clear storage
      localStorageService.clearAll();
      //set version
      localStorageService.set('version', LOCALSTORAGEVERSION);
    }

    // SERVICE INIT - called on application load (first time or on browser reload)

    // check if there is auth information in cookies
    var cookieToken = localStorageService.get('auth-token');
    var cookieUser = localStorageService.get('auth-user');
    var cookieApplications = localStorageService.get('auth-applications');
    var cookieSYdefaultProjectKey = localStorageService.get('auth-default-project-key-sy');
    var cookieDIdefaultProjectKey = localStorageService.get('auth-default-project-key-di');
    var cookiesymapLastPojectKeys = localStorageService.get('auth-last-visited-project-keys-sy');
    var activeApplication = localStorageService.get('auth-active-application');

    if (cookieToken && cookieUser) {
      this.token = cookieToken;
      this.user = cookieUser;
    }

    this.SYdefaultProjectKey = cookieSYdefaultProjectKey;
    this.DIdefaultProjectKey = cookieDIdefaultProjectKey;
    this.applications = cookieApplications;
    this.activeApplication = activeApplication;
    this.symapLastPojectKeys = cookiesymapLastPojectKeys;

    if (this.applications) {
      if (APP_BRAND.NAME === 'RSD' && this.applications.indexOf('LANDING') === -1) {
        this.applications.push('LANDING');
      }
    }

    this.updateAuthorizationHeader();
}]);
