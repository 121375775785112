"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var MapModuleService = /** @class */ (function () {
    function MapModuleService() {
        this.modules = [
            {
                id: 'move',
                icon: 'fa fa-arrows',
                title: 'Pohyb v mapě',
                showInfobox: false,
            },
            {
                id: 'measure',
                icon: 'fa fa-arrows-h',
                title: 'Měření v mapě',
            },
            {
                id: 'intersection',
                icon: 'fa fa-crop',
                title: 'Výběr parcel',
            },
            {
                id: 'info',
                icon: 'fa fa-hand-pointer-o',
                title: 'Informace kliknutím do mapy',
            },
            {
                id: 'cutParcels',
                icon: 'fa fa-cut',
                title: 'Dělení parcel',
            },
            {
                id: 'cuzk',
                icon: 'cuzk',
                title: 'Informace o parcele v katastru nemovitostí',
            },
        ];
        this.activeModule = null;
    }
    MapModuleService.prototype.getModules = function () {
        return this.modules;
    };
    MapModuleService.prototype.getModuleById = function (id) {
        return this.modules.find(function (m) { return m.id === id; });
    };
    ;
    MapModuleService.prototype.setActiveModuleId = function (moduleId) {
        this.activeModule = moduleId;
    };
    MapModuleService.prototype.getActiveModuleId = function () {
        return this.activeModule;
    };
    MapModuleService.ngInjectableDef = i0.defineInjectable({ factory: function MapModuleService_Factory() { return new MapModuleService(); }, token: MapModuleService, providedIn: "root" });
    return MapModuleService;
}());
exports.MapModuleService = MapModuleService;
