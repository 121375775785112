"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("../../forms/basic-info-form/basic-info-form.component.ngfactory");
var i2 = require("../../forms/basic-info-form/basic-info-form.component");
var i3 = require("../../../../../common/services/auth.service");
var i4 = require("../../../../../dialog/dialog.service");
var i5 = require("../../../../../common/services/list.service");
var i6 = require("@angular/common");
var i7 = require("../../forms/address-form/address-form.component.ngfactory");
var i8 = require("../../forms/address-form/address-form.component");
var i9 = require("../../forms/contact-form/contact-form.component.ngfactory");
var i10 = require("../../forms/contact-form/contact-form.component");
var i11 = require("../../forms/representative-form/representative-form.component.ngfactory");
var i12 = require("../../forms/representative-form/representative-form.component");
var i13 = require("../../forms/payment-form/payment-form.component.ngfactory");
var i14 = require("../../forms/payment-form/payment-form.component");
var i15 = require("../../../../../common/directives/auth.directive");
var i16 = require("../../../../../common/components/checkbox/checkbox.component.ngfactory");
var i17 = require("../../../../../common/components/checkbox/checkbox.component");
var i18 = require("../../../../../common/components/button/button.component.ngfactory");
var i19 = require("../../../../../common/components/button/button.component");
var i20 = require("./about.component");
var i21 = require("../../../../services/subject.service");
var styles_AboutComponent = [];
var RenderType_AboutComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AboutComponent, data: {} });
exports.RenderType_AboutComponent = RenderType_AboutComponent;
function View_AboutComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "basic-info-form", [], null, null, null, i1.View_BasicInfoFormComponent_0, i1.RenderType_BasicInfoFormComponent)), i0.ɵdid(1, 114688, null, 0, i2.BasicInfoFormComponent, ["APPLICATIONS", "Restangular", "ConfigRestangularService", i3.AuthService, i4.DialogService, i5.ListService], { basicInfo: [0, "basicInfo"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.basicInfo; _ck(_v, 1, 0, currVal_0); }, null); }
function View_AboutComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_AboutComponent_1)), i0.ɵdid(1, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 1, "address-form", [["toggleText", "Jin\u00E1 adresa ne\u017E v datech KN"]], null, [[null, "toggle"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("toggle" === en)) {
        var pd_0 = (_co.onToggleAddress($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_AddressFormComponent_0, i7.RenderType_AddressFormComponent)), i0.ɵdid(3, 114688, null, 0, i8.AddressFormComponent, [], { address: [0, "address"], toggleText: [1, "toggleText"], collapsed: [2, "collapsed"] }, { toggle: "toggle" }), (_l()(), i0.ɵeld(4, 0, null, null, 1, "address-form", [["heading", "Doru\u010Dovac\u00ED adresa"], ["toggleText", "Jin\u00E1 doru\u010Dovac\u00ED adresa"]], null, [[null, "toggle"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("toggle" === en)) {
        var pd_0 = (_co.onToggleMailingAddress($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_AddressFormComponent_0, i7.RenderType_AddressFormComponent)), i0.ɵdid(5, 114688, null, 0, i8.AddressFormComponent, [], { address: [0, "address"], toggleText: [1, "toggleText"], heading: [2, "heading"], collapsed: [3, "collapsed"] }, { toggle: "toggle" }), (_l()(), i0.ɵeld(6, 0, null, null, 1, "contact-form", [], null, null, null, i9.View_ContactFormComponent_0, i9.RenderType_ContactFormComponent)), i0.ɵdid(7, 114688, null, 0, i10.ContactFormComponent, [], { contactInfo: [0, "contactInfo"] }, null), (_l()(), i0.ɵeld(8, 0, null, null, 1, "representative-form", [["heading", "Kontaktn\u00ED osoba"]], null, null, null, i11.View_RepresentativeFormComponent_0, i11.RenderType_RepresentativeFormComponent)), i0.ɵdid(9, 114688, null, 0, i12.RepresentativeFormComponent, [], { allowRoleAssignment: [0, "allowRoleAssignment"], heading: [1, "heading"], representative: [2, "representative"] }, null), (_l()(), i0.ɵeld(10, 0, null, null, 1, "payment-form", [], null, null, null, i13.View_PaymentFormComponent_0, i13.RenderType_PaymentFormComponent)), i0.ɵdid(11, 114688, null, 0, i14.PaymentFormComponent, [], { paymentOptions: [0, "paymentOptions"], withPaymentDetails: [1, "withPaymentDetails"] }, null), (_l()(), i0.ɵeld(12, 0, null, null, 9, "div", [["class", "centered-text"]], null, null, null, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 5, "div", [["class", "mb-5 text-center"]], null, null, null, null, null)), i0.ɵdid(14, 81920, null, 0, i15.AuthDirective, [i0.ElementRef, i3.AuthService], { gmtAuth: [0, "gmtAuth"] }, null), (_l()(), i0.ɵeld(15, 0, null, null, 1, "gmt-checkbox", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.actualizeAll = !_co.actualizeAll) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i16.View_CheckboxComponent_0, i16.RenderType_CheckboxComponent)), i0.ɵdid(16, 114688, null, 0, i17.CheckboxComponent, [], { gmtChecked: [0, "gmtChecked"] }, null), (_l()(), i0.ɵted(-1, null, [" Aktualizovat zm\u011Bny i u v\u0161ech existuj\u00EDc\u00EDch p\u0159\u00EDpad\u016F (p\u0159\u00EDp. odli\u0161n\u00E9 \u00FAdaje budou p\u0159epsan\u00E9)."])), (_l()(), i0.ɵeld(18, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵeld(19, 0, null, null, 2, "gmt-button", [], null, null, null, i18.View_ButtonComponent_0, i18.RenderType_ButtonComponent)), i0.ɵdid(20, 114688, null, 0, i19.ButtonComponent, ["ErrorHandlerService", i0.ChangeDetectorRef], { clickAction: [0, "clickAction"] }, null), (_l()(), i0.ɵted(-1, 0, ["Ulo\u017Eit \u00FAdaje"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.owner.opsubType === "OFO") || (_co.owner.opsubType === "OPO")); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.address; var currVal_2 = "Jin\u00E1 adresa ne\u017E v datech KN"; var currVal_3 = (_co.owner.subjectDetail && _co.owner.subjectDetail.customAddressUse); _ck(_v, 3, 0, currVal_1, currVal_2, currVal_3); var currVal_4 = _co.mailingAddress; var currVal_5 = "Jin\u00E1 doru\u010Dovac\u00ED adresa"; var currVal_6 = "Doru\u010Dovac\u00ED adresa"; var currVal_7 = (_co.owner.subjectDetail && _co.owner.subjectDetail.dorucovaciUse); _ck(_v, 5, 0, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_8 = _co.owner.subjectDetail; _ck(_v, 7, 0, currVal_8); var currVal_9 = false; var currVal_10 = "Kontaktn\u00ED osoba"; var currVal_11 = _co.contactPerson; _ck(_v, 9, 0, currVal_9, currVal_10, currVal_11); var currVal_12 = _co.owner.subjectDetail; var currVal_13 = false; _ck(_v, 11, 0, currVal_12, currVal_13); var currVal_14 = "admin,update_case_data"; _ck(_v, 14, 0, currVal_14); var currVal_15 = _co.actualizeAll; _ck(_v, 16, 0, currVal_15); var currVal_16 = _co.onSubmit; _ck(_v, 20, 0, currVal_16); }, null); }
exports.View_AboutComponent_0 = View_AboutComponent_0;
function View_AboutComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tab-owner-about", [], null, null, null, View_AboutComponent_0, RenderType_AboutComponent)), i0.ɵdid(1, 114688, null, 0, i20.AboutComponent, ["Restangular", "callbacks", i21.SubjectService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_AboutComponent_Host_0 = View_AboutComponent_Host_0;
var AboutComponentNgFactory = i0.ɵccf("tab-owner-about", i20.AboutComponent, View_AboutComponent_Host_0, { owner: "owner" }, {}, []);
exports.AboutComponentNgFactory = AboutComponentNgFactory;
