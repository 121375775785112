"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("../map-feature-item/map-feature-item.component.ngfactory");
var i2 = require("../map-feature-item/map-feature-item.component");
var i3 = require("@angular/common");
var i4 = require("../../../common/directives/loading.directive");
var i5 = require("./map-module-info.component");
var i6 = require("../../services/map-infobox.service");
var i7 = require("../../services/map.service");
var i8 = require("../../services/map-highlight.service");
var i9 = require("../../services/map-layers-storage.service");
var i10 = require("../../../common/services/auth.service");
var styles_MapModuleInfoComponent = [];
var RenderType_MapModuleInfoComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MapModuleInfoComponent, data: {} });
exports.RenderType_MapModuleInfoComponent = RenderType_MapModuleInfoComponent;
function View_MapModuleInfoComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "centered-text"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.message; _ck(_v, 2, 0, currVal_0); }); }
function View_MapModuleInfoComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "map-feature-item", [["class", "layer-item"]], null, null, null, i1.View_MapFeatureItemComponent_0, i1.RenderType_MapFeatureItemComponent)), i0.ɵdid(1, 49152, null, 0, i2.MapFeatureItemComponent, [], { feature: [0, "feature"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }, null); }
function View_MapModuleInfoComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "content-layers"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MapModuleInfoComponent_3)), i0.ɵdid(2, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.featureItems; _ck(_v, 2, 0, currVal_0); }, null); }
function View_MapModuleInfoComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [], [[8, "className", 0]], null, null, null, null)), i0.ɵdid(1, 16384, null, 0, i4.LoadingDirective, [i0.ElementRef], { loading: [0, "loading"] }, null)], function (_ck, _v) { var currVal_1 = true; _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).elementClass; _ck(_v, 0, 0, currVal_0); }); }
function View_MapModuleInfoComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["class", "popup-close-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        $event.stopPropagation();
        var pd_0 = (_co.onClose() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00D7"]))], null, null); }
function View_MapModuleInfoComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 8, "div", [["class", "centered"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MapModuleInfoComponent_1)), i0.ɵdid(3, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MapModuleInfoComponent_2)), i0.ɵdid(5, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MapModuleInfoComponent_4)), i0.ɵdid(7, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MapModuleInfoComponent_5)), i0.ɵdid(9, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((!_co.loading && _co.message) && ((!_co.mapinfodata || !_co.mapinfodata.featureData) || (((_co.mapinfodata.featureData == null) ? null : _co.mapinfodata.featureData.length) === 0))); _ck(_v, 3, 0, currVal_0); var currVal_1 = (_co.featureItems && _co.featureItems.length); _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.loading; _ck(_v, 7, 0, currVal_2); var currVal_3 = (_co.mapinfodata && (((_co.mapinfodata.featureData == null) ? null : _co.mapinfodata.featureData.length) > 0)); _ck(_v, 9, 0, currVal_3); }, null); }
exports.View_MapModuleInfoComponent_0 = View_MapModuleInfoComponent_0;
function View_MapModuleInfoComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "map-module-info", [], null, null, null, View_MapModuleInfoComponent_0, RenderType_MapModuleInfoComponent)), i0.ɵdid(1, 245760, null, 0, i5.MapModuleInfoComponent, [i6.MapInfoboxService, i7.MapService, i0.ElementRef, i8.MapHighlightService, i9.MapLayersStorageService, i10.AuthService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_MapModuleInfoComponent_Host_0 = View_MapModuleInfoComponent_Host_0;
var MapModuleInfoComponentNgFactory = i0.ɵccf("map-module-info", i5.MapModuleInfoComponent, View_MapModuleInfoComponent_Host_0, { config: "config", mapId: "mapId", infoboxWidthGetter: "infoboxWidthGetter" }, { visibilityInfoboxCallback: "visibilityInfoboxCallback" }, []);
exports.MapModuleInfoComponentNgFactory = MapModuleInfoComponentNgFactory;
