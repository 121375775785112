"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var auth_service_1 = require("@app/common/services/auth.service");
var CadastreDataDateComponent = /** @class */ (function () {
    function CadastreDataDateComponent(authService) {
        this.authService = authService;
    }
    CadastreDataDateComponent.prototype.ngOnInit = function () {
        this.cadastreDataDate = this.authService.getActualProject() ? this.authService.getActualProject().cadastreDataDate : '';
    };
    return CadastreDataDateComponent;
}());
exports.CadastreDataDateComponent = CadastreDataDateComponent;
