"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var map_feature_filter_service_1 = require("@app/map/services/map-feature-filter.service");
var i0 = require("@angular/core");
var i1 = require("./map-feature-filter.service");
var DimapFeatureFilterService = /** @class */ (function () {
    function DimapFeatureFilterService(mapFeatureFilterService) {
        this.mapFeatureFilterService = mapFeatureFilterService;
    }
    // green color
    DimapFeatureFilterService.prototype.filterPropertyInfoOwnedInside = function () {
        return this.mapFeatureFilterService.statementsAnd([
            this.mapFeatureFilterService.propertyOr('owner_type', ['O']),
            this.mapFeatureFilterService.statementsOr([
                this.mapFeatureFilterService.statementsAnd([
                    this.mapFeatureFilterService.not(this.mapFeatureFilterService.propertyIsNull('key')),
                    this.mapFeatureFilterService.statementsOr([
                        this.mapFeatureFilterService.propertyEquals('wanted', true),
                        this.mapFeatureFilterService.propertyIsNull('wanted')
                    ])
                ]),
                this.mapFeatureFilterService.statementsAnd([
                    this.mapFeatureFilterService.propertyIsNull('key'),
                    this.mapFeatureFilterService.propertyEquals('wanted', true)
                ])
            ])
        ]);
    };
    // blue color
    DimapFeatureFilterService.prototype.filterPropertyInfoOwnedOutside = function () {
        return this.mapFeatureFilterService.statementsAnd([
            this.mapFeatureFilterService.propertyOr('owner_type', ['O', 'S']),
            this.mapFeatureFilterService.statementsOr([
                this.mapFeatureFilterService.statementsAnd([
                    this.mapFeatureFilterService.not(this.mapFeatureFilterService.propertyIsNull('key')),
                    this.mapFeatureFilterService.propertyEquals('wanted', false)
                ]),
                this.mapFeatureFilterService.statementsAnd([
                    this.mapFeatureFilterService.propertyIsNull('key'),
                    this.mapFeatureFilterService.statementsOr([
                        this.mapFeatureFilterService.propertyEquals('wanted', false),
                        this.mapFeatureFilterService.propertyIsNull('wanted')
                    ])
                ])
            ])
        ]);
    };
    // red color
    DimapFeatureFilterService.prototype.filterPropertyInfoOthersInside = function () {
        return this.mapFeatureFilterService.statementsAnd([
            this.mapFeatureFilterService.propertyOr('owner_type', ['S', 'F']),
            this.mapFeatureFilterService.statementsOr([
                this.mapFeatureFilterService.statementsAnd([
                    this.mapFeatureFilterService.not(this.mapFeatureFilterService.propertyIsNull('key')),
                    this.mapFeatureFilterService.statementsOr([
                        this.mapFeatureFilterService.propertyEquals('wanted', true),
                        this.mapFeatureFilterService.propertyIsNull('wanted')
                    ])
                ]),
                this.mapFeatureFilterService.statementsAnd([
                    this.mapFeatureFilterService.propertyIsNull('key'),
                    this.mapFeatureFilterService.propertyEquals('wanted', true)
                ])
            ])
        ]);
    };
    // white color
    DimapFeatureFilterService.prototype.filterPropertyInfoOthersOutside = function () {
        return this.mapFeatureFilterService.statementsAnd([
            this.mapFeatureFilterService.propertyEquals('owner_type', 'F'),
            this.mapFeatureFilterService.statementsOr([
                this.mapFeatureFilterService.propertyEquals('wanted', false),
                this.mapFeatureFilterService.statementsAnd([
                    this.mapFeatureFilterService.propertyIsNull('key'),
                    this.mapFeatureFilterService.propertyIsNull('wanted')
                ])
            ])
        ]);
    };
    DimapFeatureFilterService.prototype.areaToKeep = function (areaNum) {
        return this.mapFeatureFilterService.statementsAnd([
            this.mapFeatureFilterService.propertyOr('owner_type', ['O']),
            this.mapFeatureFilterService.statementsOr([
                this.mapFeatureFilterService.statementsAnd([
                    this.mapFeatureFilterService.not(this.mapFeatureFilterService.propertyIsNull('key' + areaNum)),
                    this.mapFeatureFilterService.statementsOr([
                        this.mapFeatureFilterService.propertyEquals('wanted' + areaNum, true),
                        this.mapFeatureFilterService.propertyIsNull('wanted' + areaNum)
                    ])
                ]),
                this.mapFeatureFilterService.statementsAnd([
                    this.mapFeatureFilterService.propertyIsNull('key' + areaNum),
                    this.mapFeatureFilterService.propertyEquals('wanted' + areaNum, true)
                ])
            ])
        ]);
    };
    DimapFeatureFilterService.prototype.areaToSell = function (areaNum) {
        return this.mapFeatureFilterService.statementsAnd([
            this.mapFeatureFilterService.propertyOr('owner_type', ['O', 'S']),
            this.mapFeatureFilterService.statementsOr([
                this.mapFeatureFilterService.statementsAnd([
                    this.mapFeatureFilterService.not(this.mapFeatureFilterService.propertyIsNull('key' + areaNum)),
                    this.mapFeatureFilterService.propertyEquals('wanted' + areaNum, false)
                ]),
                this.mapFeatureFilterService.statementsAnd([
                    this.mapFeatureFilterService.propertyIsNull('key' + areaNum),
                    this.mapFeatureFilterService.statementsOr([
                        this.mapFeatureFilterService.propertyEquals('wanted' + areaNum, false),
                        this.mapFeatureFilterService.propertyIsNull('wanted' + areaNum)
                    ])
                ])
            ])
        ]);
    };
    DimapFeatureFilterService.prototype.areaToBuy = function (areaNum) {
        return this.mapFeatureFilterService.statementsAnd([
            this.mapFeatureFilterService.propertyOr('owner_type', ['S', 'F']),
            this.mapFeatureFilterService.statementsOr([
                this.mapFeatureFilterService.statementsAnd([
                    this.mapFeatureFilterService.not(this.mapFeatureFilterService.propertyIsNull('key' + areaNum)),
                    this.mapFeatureFilterService.statementsOr([
                        this.mapFeatureFilterService.propertyEquals('wanted' + areaNum, true),
                        this.mapFeatureFilterService.propertyIsNull('wanted' + areaNum)
                    ])
                ]),
                this.mapFeatureFilterService.statementsAnd([
                    this.mapFeatureFilterService.propertyIsNull('key' + areaNum),
                    this.mapFeatureFilterService.propertyEquals('wanted' + areaNum, true)
                ])
            ])
        ]);
    };
    DimapFeatureFilterService.prototype.areaOther = function (areaNum) {
        return this.mapFeatureFilterService.statementsAnd([
            this.mapFeatureFilterService.propertyEquals('owner_type', 'F'),
            this.mapFeatureFilterService.statementsOr([
                this.mapFeatureFilterService.statementsAnd([
                    this.mapFeatureFilterService.not(this.mapFeatureFilterService.propertyIsNull('key' + areaNum)),
                    this.mapFeatureFilterService.propertyEquals('wanted' + areaNum, false)
                ]),
                this.mapFeatureFilterService.statementsAnd([
                    this.mapFeatureFilterService.propertyIsNull('key' + areaNum),
                    this.mapFeatureFilterService.statementsOr([
                        this.mapFeatureFilterService.propertyEquals('wanted' + areaNum, false),
                        this.mapFeatureFilterService.propertyIsNull('wanted' + areaNum)
                    ])
                ])
            ])
        ]);
    };
    DimapFeatureFilterService.prototype.filterGeometricPlan = function () {
        return this.mapFeatureFilterService.statementsOr([
            this.mapFeatureFilterService.statementsAnd([this.areaToKeep(1), this.areaToSell(2)]),
            this.mapFeatureFilterService.statementsAnd([this.areaToBuy(1), this.areaToSell(2)]),
            this.mapFeatureFilterService.statementsAnd([this.areaToBuy(1), this.areaOther(2)])
        ]);
    };
    DimapFeatureFilterService.ngInjectableDef = i0.defineInjectable({ factory: function DimapFeatureFilterService_Factory() { return new DimapFeatureFilterService(i0.inject(i1.MapFeatureFilterService)); }, token: DimapFeatureFilterService, providedIn: "root" });
    return DimapFeatureFilterService;
}());
exports.DimapFeatureFilterService = DimapFeatureFilterService;
