"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var EmailUtils = /** @class */ (function () {
    function EmailUtils() {
    }
    EmailUtils.textToHtml = function (text, user) {
        // user placeholders
        if (user) {
            text = text.replace(EmailUtils.USER_LOGIN_REGEXP, user.username);
        }
        // line breaks to <br>
        text = text.replace(EmailUtils.LINE_BREAK_REGEXP, '<br>');
        // replace link placeholders
        text = text.replace(EmailUtils.ANCHOR_REGEXP, function (fullMatch, text, url) {
            return '<a href="' + url + '" target="_blank">' + text + '</a>';
        });
        return text;
    };
    EmailUtils.USER_LOGIN_REGEXP = /\[login\]/g;
    EmailUtils.LINE_BREAK_REGEXP = /(?:\r\n|\r|\n)/g;
    // for replace [text|url] => <a href="url">text</a>
    EmailUtils.ANCHOR_REGEXP = /\[([^\]]+)\|([^\]]+)\]/g;
    return EmailUtils;
}());
exports.EmailUtils = EmailUtils;
