"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var dialog_component_1 = require("./dialog.component");
var dialog_injector_1 = require("./dialog-injector");
var dialog_config_1 = require("./dialog-config");
var dialog_ref_1 = require("./dialog-ref");
var i0 = require("@angular/core");
var i1 = require("./dialog.module");
var DialogService = /** @class */ (function () {
    function DialogService(componentFactoryResolver, appRef, injector) {
        this.componentFactoryResolver = componentFactoryResolver;
        this.appRef = appRef;
        this.injector = injector;
    }
    DialogService.prototype.open = function (componentType, config) {
        var dialogRef = this.appendDialogComponentToBody(config);
        this.dialogComponentRef.instance.childComponentType = componentType;
        return dialogRef;
    };
    DialogService.prototype.appendDialogComponentToBody = function (config) {
        var _this = this;
        var map = new WeakMap();
        var dialogRef = new dialog_ref_1.DialogRef();
        map.set(dialog_config_1.DialogConfig, config);
        map.set(dialog_ref_1.DialogRef, dialogRef);
        var componentFactory = this.componentFactoryResolver.resolveComponentFactory(dialog_component_1.DialogComponent);
        var componentRef = componentFactory.create(new dialog_injector_1.DialogInjector(this.injector, map));
        this.appRef.attachView(componentRef.hostView);
        var domElem = componentRef.hostView.rootNodes[0];
        document.body.appendChild(domElem);
        this.dialogComponentRef = componentRef;
        var sub = dialogRef.afterClosed.subscribe(function () {
            _this.removeDialogComponentFromBody(componentRef);
            sub.unsubscribe();
        });
        return dialogRef;
    };
    DialogService.prototype.removeDialogComponentFromBody = function (componentRef) {
        this.appRef.detachView(componentRef.hostView);
        this.dialogComponentRef.destroy();
    };
    DialogService.ngInjectableDef = i0.defineInjectable({ factory: function DialogService_Factory() { return new DialogService(i0.inject(i0.ComponentFactoryResolver), i0.inject(i0.ApplicationRef), i0.inject(i0.INJECTOR)); }, token: DialogService, providedIn: i1.DialogModule });
    return DialogService;
}());
exports.DialogService = DialogService;
