"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var _ = require("lodash");
var file_utils_1 = require("@app/common/utils/file.utils");
var BASETEXT = "Vyberte soubory nebo je p\u0159et\u00E1hn\u011Bte sem.";
var UploadFileExtended = /** @class */ (function () {
    function UploadFileExtended(fileEntry) {
        this.fileEntry = fileEntry;
    }
    return UploadFileExtended;
}());
exports.UploadFileExtended = UploadFileExtended;
var FileUploadComponent = /** @class */ (function () {
    function FileUploadComponent(elementRef) {
        this.elementRef = elementRef;
        this.onchange = new core_1.EventEmitter();
        this.message = BASETEXT;
        this.disabled = false;
        this.inputId = 'hiddenFileSelect';
        this.files = [];
        this.draggable = true;
        this.inputFilesAdded = this.inputFilesAdded.bind(this);
        this.removeFile = this.removeFile.bind(this);
        this.fileIsValid = this.fileIsValid.bind(this);
    }
    FileUploadComponent.prototype.ngOnInit = function () { };
    FileUploadComponent.prototype.dragOver = function (event) {
        event.preventDefault();
        event.stopPropagation();
        return false;
    };
    FileUploadComponent.prototype.dropFiles = function (event) {
        if (!this.draggable) {
            return undefined;
        }
        event.preventDefault();
        if (event.dataTransfer) {
            event.dataTransfer.dropEffect = 'copy';
            if (event.dataTransfer.items) {
                // Use DataTransferItemList interface to access the file(s)
                for (var i = 0; i < event.dataTransfer.items.length; i++) {
                    // If dropped items aren't files, reject them
                    if (event.dataTransfer.items[i].kind === 'file') {
                        var file = event.dataTransfer.items[i].getAsFile();
                        this.files.push(file_utils_1.FileUtils.fileToUpload(file, this.fileIsValid));
                    }
                }
            }
            else {
                // Use DataTransfer interface to access the file(s)
                for (var i = 0; i < event.dataTransfer.files.length; i++) {
                    var file = event.dataTransfer.files[i];
                    this.files.push(file_utils_1.FileUtils.fileToUpload(file, this.fileIsValid));
                }
            }
            this.dispatchChange();
        }
    };
    FileUploadComponent.prototype.openFileUpload = function () {
        var fileSelect = document.getElementById(this.inputId);
        fileSelect.click();
    };
    FileUploadComponent.prototype.inputFilesAdded = function (event) {
        var _this = this;
        var _a;
        if (event.target && event.target.files.length > 0) {
            (_a = this.files).push.apply(_a, __spread(Array.from(event.target.files).map(function (file) { return file_utils_1.FileUtils.fileToUpload(file, _this.fileIsValid); }).filter(function (item) { return item; })));
            this.dispatchChange();
        }
        var fileSelect = document.getElementById(this.inputId);
        fileSelect.value = '';
    };
    FileUploadComponent.prototype.removeFile = function (file) {
        _.pull(this.files, file);
        this.dispatchChange();
    };
    FileUploadComponent.prototype.fileIsValid = function (file) {
        return typeof this.validation === 'function' ? this.validation(file) : true;
    };
    FileUploadComponent.prototype.dispatchChange = function () {
        this.onchange.emit(this.files);
    };
    return FileUploadComponent;
}());
exports.FileUploadComponent = FileUploadComponent;
