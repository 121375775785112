const controller = ['$scope', 'AuthService', 'GlobalRestangularSettingsProvider',function ($scope, AuthService, GlobalRestangularSettingsProvider) {

    $scope.data = {};
    $scope.user = AuthService.user;
    $scope.update = function () {
      if (!AuthService.isAuthenticated()) {
        return;
      }

      const data = {
        oldPassword: $scope.data.oldPassword,
        newPassword: $scope.data.newPassword,
        newPasswordCheck: $scope.data.newPasswordCheck,
        username:$scope.data.username,
      };

      return GlobalRestangularSettingsProvider.all(`users/user/${AuthService.user.id}/password-change`).customPUT(data).then(
        (data) => {
          $scope.closeModal(data);
          AuthService.passwordChanged();
        }
      );
    };

    $scope.disabledSave = () => {
      //user must fill current password and new password or fields for changing username
      return !!$scope.data.oldPassword && !!($scope.data.username || ($scope.data.newPassword && $scope.data.newPasswordCheck));
    };
}];

module.exports = controller;
