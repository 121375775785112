"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("../../../common/components/switch/switch.component.ngfactory");
var i2 = require("../../../common/components/switch/switch.component");
var i3 = require("./map-module-measure.component");
var i4 = require("../../services/map.service");
var styles_MapModuleMeasureComponent = [];
var RenderType_MapModuleMeasureComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MapModuleMeasureComponent, data: {} });
exports.RenderType_MapModuleMeasureComponent = RenderType_MapModuleMeasureComponent;
function View_MapModuleMeasureComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "content measure-module"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["M\u011B\u0159en\u00ED vzd\u00E1lenost\u00ED a ploch v map\u011B."])), (_l()(), i0.ɵeld(3, 0, null, null, 1, "gmt-switch", [], null, [[null, "selectedIdChange"], [null, "select"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectedIdChange" === en)) {
        var pd_0 = ((_co.measureOptionsSelections = $event) !== false);
        ad = (pd_0 && ad);
    } if (("select" === en)) {
        var pd_1 = (_co.onChangedMeasureType($event.id) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_SwitchComponent_0, i1.RenderType_SwitchComponent)), i0.ɵdid(4, 114688, null, 0, i2.SwitchComponent, [], { options: [0, "options"], selectedId: [1, "selectedId"] }, { select: "select", selectedIdChange: "selectedIdChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.measureOptions; var currVal_1 = _co.measureOptionsSelections; _ck(_v, 4, 0, currVal_0, currVal_1); }, null); }
exports.View_MapModuleMeasureComponent_0 = View_MapModuleMeasureComponent_0;
function View_MapModuleMeasureComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "map-module-measure", [], null, null, null, View_MapModuleMeasureComponent_0, RenderType_MapModuleMeasureComponent)), i0.ɵdid(1, 245760, null, 0, i3.MapModuleMeasureComponent, [i4.MapService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_MapModuleMeasureComponent_Host_0 = View_MapModuleMeasureComponent_Host_0;
var MapModuleMeasureComponentNgFactory = i0.ɵccf("map-module-measure", i3.MapModuleMeasureComponent, View_MapModuleMeasureComponent_Host_0, { mapId: "mapId" }, {}, []);
exports.MapModuleMeasureComponentNgFactory = MapModuleMeasureComponentNgFactory;
