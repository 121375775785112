"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// @TODO - remove and replace with ../common/models/area.model
var CadastralArea = /** @class */ (function () {
    function CadastralArea() {
    }
    CadastralArea.createFromApi = function (data) {
        var cadastralArea = new CadastralArea();
        cadastralArea.code = data.kod;
        cadastralArea.name = data.nazev;
        return cadastralArea;
    };
    CadastralArea.createListFromApi = function (data) {
        return data.map(CadastralArea.createFromApi);
    };
    return CadastralArea;
}());
exports.CadastralArea = CadastralArea;
