"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var post_code_pipe_1 = require("@app/common/pipes/post-code.pipe");
var i0 = require("@angular/core");
var i1 = require("./post-code.pipe");
var FullMunicipalityPipe = /** @class */ (function () {
    function FullMunicipalityPipe(postCodePipe) {
        this.postCodePipe = postCodePipe;
    }
    FullMunicipalityPipe.prototype.transform = function (subject) {
        if (!subject) {
            return '';
        }
        var postCode = this.postCodePipe.transform(subject.postCode);
        if (!subject.district && 'Praha' === subject.municipality && subject.postCode && (subject.postCode + '').length === 5) {
            var digit = (subject.postCode + '').substr(1, 1);
            subject.district = 'Praha ' + (digit === '0' ? 10 : digit);
        }
        if (subject.municipality || subject.district) {
            postCode += postCode ? ' ' : '';
            postCode += subject.district || subject.municipality;
        }
        return postCode;
    };
    FullMunicipalityPipe.ngInjectableDef = i0.defineInjectable({ factory: function FullMunicipalityPipe_Factory() { return new FullMunicipalityPipe(i0.inject(i1.PostCodePipe)); }, token: FullMunicipalityPipe, providedIn: "root" });
    return FullMunicipalityPipe;
}());
exports.FullMunicipalityPipe = FullMunicipalityPipe;
