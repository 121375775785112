"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var i0 = require("@angular/core");
var TitleNamePipe = /** @class */ (function () {
    function TitleNamePipe() {
    }
    TitleNamePipe.prototype.transform = function (title) {
        if (title.lv && title.area && title.area.name) {
            return title.lv + ' (' + title.area.name + ')';
        }
    };
    TitleNamePipe.ngInjectableDef = i0.defineInjectable({ factory: function TitleNamePipe_Factory() { return new TitleNamePipe(); }, token: TitleNamePipe, providedIn: "root" });
    return TitleNamePipe;
}());
exports.TitleNamePipe = TitleNamePipe;
