"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var _ = require("lodash");
var i0 = require("@angular/core");
var MapStorageHelpersService = /** @class */ (function () {
    function MapStorageHelpersService() {
    }
    MapStorageHelpersService.prototype.getDefer = function (obj, id) {
        var defer;
        if (_.isEmpty(obj[id]) || obj[id].resolvedDefer === false) {
            defer = this.getUnresolvedDefer(obj, id);
        }
        else {
            defer = obj[id].defer;
        }
        return defer;
    };
    MapStorageHelpersService.prototype.getUnresolvedDefer = function (obj, id) {
        var defer;
        if (_.isEmpty(obj[id]) || obj[id].resolvedDefer === true) {
            var reject_1;
            var resolve_1;
            defer = {};
            defer.promise = new Promise(function (_resolve, _reject) {
                reject_1 = _reject;
                resolve_1 = _resolve;
            });
            defer.reject = reject_1;
            defer.resolve = resolve_1;
            obj[id] = {
                defer: defer,
                resolvedDefer: false
            };
        }
        else {
            defer = obj[id].defer;
        }
        return defer;
    };
    MapStorageHelpersService.prototype.setResolvedDefer = function (obj, id) {
        obj[id].resolvedDefer = true;
    };
    MapStorageHelpersService.ngInjectableDef = i0.defineInjectable({ factory: function MapStorageHelpersService_Factory() { return new MapStorageHelpersService(); }, token: MapStorageHelpersService, providedIn: "root" });
    return MapStorageHelpersService;
}());
exports.MapStorageHelpersService = MapStorageHelpersService;
