"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var vector_layer_style_model_1 = require("@app/ps/map/models/vector-layer-style.model");
var LayerSymbolComponent = /** @class */ (function () {
    function LayerSymbolComponent() {
        this.stylechange = new core_1.EventEmitter();
        this.interactiveSvgViewBox = '-2 -2 20 20';
        this.staticSvgViewBox = '0 0 16 12';
        this.staticSvgRootStyle = {
            'height.px': 12,
            'width.px': 16,
        };
        this.interactiveSvgRootStyle = {
            'height.px': 20,
            'width.px': 20,
        };
    }
    Object.defineProperty(LayerSymbolComponent.prototype, "showColorpicker", {
        set: function (val) {
            if (!val) {
                this.fillOrStroke = undefined; // when switching between multiple layers, forces colorpicker to close and reopen
            }
            this.isColorpickerVisible = val;
        },
        enumerable: true,
        configurable: true
    });
    LayerSymbolComponent.prototype.ngOnInit = function () {
        this.svgViewBox = this.interactive ? this.interactiveSvgViewBox : this.staticSvgViewBox;
        this.svgRootStyle = this.interactive ? this.interactiveSvgRootStyle : this.staticSvgRootStyle;
    };
    LayerSymbolComponent.prototype.onColorChange = function ($event) {
        this.style[this.fillOrStroke] = $event.color.rgb;
        this.stylechange.emit(this.style);
    };
    LayerSymbolComponent.prototype.onColorpickerToggle = function (fillOrStroke) {
        if (this.fillOrStroke === fillOrStroke) {
            this.fillOrStroke = undefined;
        }
        else {
            this.fillOrStroke = fillOrStroke;
        }
    };
    LayerSymbolComponent.prototype.getFillStyle = function () {
        var result = {
            fill: "rgb(" + this.style.fill.r + ", " + this.style.fill.g + ", " + this.style.fill.b + ")",
            opacity: this.style.fill.a,
        };
        if (this.interactive) {
            result['height.px'] = 12;
            result['width.px'] = 12;
        }
        else {
            result['height.px'] = 10;
            result['width.px'] = 14;
        }
        return result;
    };
    LayerSymbolComponent.prototype.getStrokeStyle = function () {
        var result = {
            'fill': 'none',
            'opacity': this.style.stroke.a,
            'stroke': "rgb(" + this.style.stroke.r + ", " + this.style.stroke.g + ", " + this.style.stroke.b + ")",
            'width.px': 16,
        };
        if (this.interactive) {
            result['height.px'] = 16;
            result['stroke-width'] = 4;
        }
        else {
            result['height.px'] = 12;
            result['stroke-width'] = 2;
        }
        return result;
    };
    return LayerSymbolComponent;
}());
exports.LayerSymbolComponent = LayerSymbolComponent;
