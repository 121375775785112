"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var map_component_1 = require("./components/map/map.component");
exports.mapState = {
    name: 'symap.project.map',
    data: {
        title: 'Mapa',
    },
    url: '/map?title&parcel&constructionObject&occupation&subject&businessCase&layer',
    params: {
        title: { type: 'int' },
        parcel: { type: 'int' },
        constructionObject: { type: 'int' },
        occupation: { type: 'int' },
        subject: { type: 'int' },
        businessCase: { type: 'int' },
        layer: { type: 'string', array: true }
    },
    views: {
        'content@symap.project': {
            component: map_component_1.MapComponent,
        }
    }
};
