"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var auth_service_1 = require("../services/auth.service");
var AuthDirective = /** @class */ (function () {
    function AuthDirective(elementRef, authService) {
        this.elementRef = elementRef;
        this.authService = authService;
    }
    AuthDirective.prototype.ngOnInit = function () {
        if (this.gmtAuth && !this.authService.hasPermission(this.gmtAuth)) {
            this.elementRef.nativeElement.style.display = 'none';
        }
    };
    return AuthDirective;
}());
exports.AuthDirective = AuthDirective;
