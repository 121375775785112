"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var _ = require("lodash");
var table_utils_1 = require("@app/common/utils/table.utils");
var parcel_utils_1 = require("@app/common/utils/parcel.utils");
var sort_model_1 = require("@app/common/models/sort.model");
var TableParcelsAbstractComponent = /** @class */ (function () {
    function TableParcelsAbstractComponent() {
        // bindings
        this.data = [];
        this.toggleAll = new core_1.EventEmitter();
        this.toggleOne = new core_1.EventEmitter();
        this.sortOrder = new sort_model_1.SortModel();
    }
    Object.defineProperty(TableParcelsAbstractComponent.prototype, "columns", {
        set: function (columns) {
            this._columns = table_utils_1.TableUtils.getAvailableColumns(columns, this.COMPONENT_COLUMNS);
            this.visibleColumns = _.keyBy(this._columns, function (c) { return c.id; });
            this.sortableColumns = _.keyBy(this._columns.filter(function (column) { return column.sortable; }), function (c) { return c.id; });
            this.occupationColumnsCount = table_utils_1.TableUtils.getColumnsCount(this._columns, 'occupation_');
            this.occupationPriceColumnsCount = table_utils_1.TableUtils.getColumnsCount(this._columns, 'occupation_price_');
            this.currentColumnsCount = table_utils_1.TableUtils.getColumnsCount(this._columns, 'current_');
        },
        enumerable: true,
        configurable: true
    });
    TableParcelsAbstractComponent.prototype.setSortData = function (sortBy) {
        this.sortOrder.direction = this.sortOrder.direction === 'asc' ? 'desc' : 'asc';
        // every first sort will be sorted 'desc'
        if (this.sortOrder.sortBy !== sortBy) {
            this.sortOrder.direction = 'desc';
        }
        this.sortOrder.sortBy = sortBy;
    };
    TableParcelsAbstractComponent.prototype.onSort = function (sortBy, dataPath) {
        this.setSortData(sortBy);
        this.data = table_utils_1.TableUtils.sortDataByColumnStatic(this.data, dataPath, this.sortOrder.direction);
    };
    TableParcelsAbstractComponent.prototype.onSortParcel = function (sortBy, nested) {
        if (nested === void 0) { nested = true; }
        this.setSortData(sortBy);
        this.data = _.orderBy(this.data, parcel_utils_1.ParcelUtils.getSortFunctionByParcelsNumbers(nested ? 'parcel.parcisKmen' : 'parcisKmen', nested ? 'parcel.parcisPod' : 'parcisPod', nested ? 'parcel.parcisDil' : 'parcisDil'), this.sortOrder.direction);
    };
    TableParcelsAbstractComponent.prototype.onToggleAll = function () {
        this.toggleAll.emit();
    };
    TableParcelsAbstractComponent.prototype.onToggleOne = function (entity) {
        this.toggleOne.emit(entity);
    };
    return TableParcelsAbstractComponent;
}());
exports.TableParcelsAbstractComponent = TableParcelsAbstractComponent;
