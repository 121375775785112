"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var CountTypeEnum;
(function (CountTypeEnum) {
    CountTypeEnum["TITLE"] = "TITLE";
    CountTypeEnum["OWNERSHIP"] = "OWNERSHIP";
    CountTypeEnum["CONSTRUCTION_OBJECT"] = "CONSTRUCTION_OBJECT";
    CountTypeEnum["ADMINISTRATOR"] = "ADMINISTRATOR";
})(CountTypeEnum = exports.CountTypeEnum || (exports.CountTypeEnum = {}));
var TypeEnum;
(function (TypeEnum) {
    TypeEnum["EASEMENT_GEOMETRIC_PLAN"] = "EASEMENT_GEOMETRIC_PLAN";
    TypeEnum["EASEMENT_LAND_TAKE"] = "EASEMENT_LAND_TAKE";
    TypeEnum["PERMANENT"] = "PERMANENT";
    TypeEnum["UNDER_ONE_YEAR"] = "UNDER_ONE_YEAR";
    TypeEnum["OVER_ONE_YEAR"] = "OVER_ONE_YEAR";
})(TypeEnum = exports.TypeEnum || (exports.TypeEnum = {}));
var StatisticsObjectTypeModel = /** @class */ (function () {
    function StatisticsObjectTypeModel() {
    }
    return StatisticsObjectTypeModel;
}());
exports.StatisticsObjectTypeModel = StatisticsObjectTypeModel;
