"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var case_service_1 = require("@app/ps/services/case.service");
var angular_1 = require("@uirouter/angular");
var case_status_service_1 = require("@app/ps/services/case-status.service");
var SimpleCaseStatusComponent = /** @class */ (function () {
    function SimpleCaseStatusComponent(stateService, caseService, restangular, caseStatusService) {
        this.stateService = stateService;
        this.caseService = caseService;
        this.restangular = restangular;
        this.caseStatusService = caseStatusService;
        this.doneRedirect = true;
        this.done = new core_1.EventEmitter();
        this.cancel = new core_1.EventEmitter();
        this.loading = true;
        this.onCancel = this.onCancel.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }
    SimpleCaseStatusComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b, dataCaseOwnerships, recipient;
            var _this = this;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.caseStatusService.loadStatusName(this.config.name)];
                    case 1:
                        _a.name = (_c.sent()).name;
                        _b = this;
                        return [4 /*yield*/, this.restangular.one('cases', this.caseId).get(this.config.recipient ? { loadCollections: ['caseSubjects', 'caseOwnerships'] } : undefined)];
                    case 2:
                        _b.data = _c.sent();
                        if (this.config.recipient) {
                            dataCaseOwnerships = this.caseService.getDataCaseOwnerships(this.data.caseOwnerships, this.data.caseSubjects);
                            recipient = this.caseService.getRecipients(dataCaseOwnerships).find(function (value) {
                                return (!_this.config.statusDateFieldName || !value[_this.config.statusDateFieldName]) && !value.signedDate && !value.acceptedGeometricalPlanPossibleDate && (value.declinedDate || value.undeliveredDate || value.sentDate || value.personalVisitDate || value.receivedDate);
                            });
                            this.config = Object.assign({}, this.config);
                            this.config.formFields = [{
                                    value: recipient.name,
                                    name: 'Adresát',
                                }].concat(this.config.formFields);
                        }
                        this.loading = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    SimpleCaseStatusComponent.prototype.formCallbackRegister = function (event) {
        this.isValid = event.isValid;
    };
    SimpleCaseStatusComponent.prototype.onCancel = function () {
        this.cancel.emit();
    };
    SimpleCaseStatusComponent.prototype.onSubmit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var e_1, _a, sendData, _b, _c, formField;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        sendData = {};
                        try {
                            for (_b = __values(this.config.formFields), _c = _b.next(); !_c.done; _c = _b.next()) {
                                formField = _c.value;
                                sendData[formField.field] = this.data[formField.field];
                            }
                        }
                        catch (e_1_1) { e_1 = { error: e_1_1 }; }
                        finally {
                            try {
                                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                            }
                            finally { if (e_1) throw e_1.error; }
                        }
                        return [4 /*yield*/, this.restangular
                                .one('cases', this.caseId)
                                .customPUT(sendData, this.config.saveEndpoint)];
                    case 1:
                        _d.sent();
                        if (this.doneRedirect) {
                            this.stateService.go('^');
                        }
                        else {
                            this.done.emit();
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    return SimpleCaseStatusComponent;
}());
exports.SimpleCaseStatusComponent = SimpleCaseStatusComponent;
