"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var MapFeatureFilterService = /** @class */ (function () {
    function MapFeatureFilterService() {
    }
    /**
     * Creates OR filter for a list of property values
     * @param attrName
     * @param values
     * @returns {*}
     */
    MapFeatureFilterService.prototype.propertyOr = function (attrName, values) {
        var filter = null;
        values.forEach(function (value) {
            var operand = {
                type: 'PropertyIsEqualTo',
                propertyName: attrName,
                literal: value
            };
            if (filter === null) {
                filter = operand;
            }
            else {
                filter = {
                    type: 'Or',
                    arg1: filter,
                    arg2: operand
                };
            }
        });
        return filter;
    };
    /**
     * Joins statements into And statement
     * @param values
     * @returns {*}
     */
    MapFeatureFilterService.prototype.statementsAnd = function (values) {
        var filter = null;
        values.forEach(function (value) {
            if (filter === null) {
                filter = value;
            }
            else {
                filter = {
                    type: 'And',
                    arg1: filter,
                    arg2: value
                };
            }
        });
        return filter;
    };
    /**
     * Joins statements into Or statement
     * @param values
     * @returns {*}
     */
    MapFeatureFilterService.prototype.statementsOr = function (values) {
        var filter = null;
        values.forEach(function (value) {
            if (filter === null) {
                filter = value;
            }
            else {
                filter = {
                    type: 'Or',
                    arg1: filter,
                    arg2: value
                };
            }
        });
        return filter;
    };
    MapFeatureFilterService.prototype.propertyEquals = function (property, value) {
        return {
            type: 'PropertyIsEqualTo',
            propertyName: property,
            literal: value
        };
    };
    MapFeatureFilterService.prototype.not = function (value) {
        return {
            type: 'Not',
            arg: value
        };
    };
    MapFeatureFilterService.prototype.propertyIsNull = function (property) {
        return {
            type: 'PropertyIsNull',
            propertyName: property
        };
    };
    MapFeatureFilterService.ngInjectableDef = i0.defineInjectable({ factory: function MapFeatureFilterService_Factory() { return new MapFeatureFilterService(); }, token: MapFeatureFilterService, providedIn: "root" });
    return MapFeatureFilterService;
}());
exports.MapFeatureFilterService = MapFeatureFilterService;
