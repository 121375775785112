"use strict";
var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
Object.defineProperty(exports, "__esModule", { value: true });
var word_service_1 = require("@app/common/services/word.service");
var auth_service_1 = require("@app/common/services/auth.service");
var i0 = require("@angular/core");
var i1 = require("../../common/services/word.service");
var i2 = require("../../common/services/auth.service");
var TableService = /** @class */ (function () {
    function TableService(EVENTS, wordService, authService) {
        this.EVENTS = EVENTS;
        this.wordService = wordService;
        this.authService = authService;
        this.tables = [
            {
                id: 'cases',
                name: 'Případy',
                columns: [
                    {
                        name: 'Číslo případu',
                        id: 'caseNumber',
                        exportDefinitions: [
                            { name: 'Číslo případu', key: 'mpp.number' }
                        ]
                    },
                    {
                        name: 'K.Ú.',
                        id: 'areaName',
                        exportDefinitions: [
                            { name: 'K.Ú.', key: 'titles.area.name' }
                        ]
                    },
                    {
                        name: 'LV',
                        id: 'lv',
                        exportDefinitions: [
                            { name: 'LV', key: 'caseOwnerships.ownership.lv' }
                        ]
                    },
                    {
                        name: 'Vlastník',
                        id: 'owner',
                        exportDefinitions: [
                            { name: 'Vlastník', key: 'caseOwnerships.ownership.ownershipName' }
                        ]
                    },
                    {
                        name: 'Typ případu',
                        id: 'caseType',
                        exportDefinitions: [
                            { name: 'Typ případu', key: 'obligation.name' }
                        ]
                    },
                    {
                        name: 'Stav',
                        id: 'caseStatus',
                        exportDefinitions: [
                            { name: 'Stav', key: 'caseStatus.name' }
                        ]
                    },
                    {
                        name: 'K datu',
                        id: 'respectiveDate',
                        exportDefinitions: [
                            { name: 'K datu', key: 'statusRespectiveDate' }
                        ]
                    },
                    {
                        name: 'Stavební objekt',
                        id: 'constructionObjects',
                        exportDefinitions: [
                            { name: 'Stavební objekt', key: 'constructionObjects', format: '{socis} - {sotx} (Etapa / úsek {sousek})' }
                        ]
                    },
                    {
                        name: 'Cena',
                        id: 'price',
                        exportDefinitions: [
                            { name: 'Cena', key: 'price' }
                        ]
                    },
                    {
                        name: 'Řešitel případu',
                        id: 'mandatary',
                        exportDefinitions: [
                            { name: 'Řešitel případu', key: 'mandatary', format: '{fullName} ({company.companyName})' }
                        ]
                    },
                    {
                        name: 'Přidělení LV',
                        id: 'titleUser',
                        exportDefinitions: [
                            { name: 'Přidělení LV', key: 'titles.user', format: '{fullName} ({company.companyName})' }
                        ]
                    }
                ]
            },
            {
                id: 'titles',
                name: 'Listy vlastnictví',
                columns: [
                    {
                        name: 'LV',
                        id: 'lv',
                        exportDefinitions: [
                            { name: 'LV', key: 'lv' }
                        ]
                    },
                    {
                        name: 'K.Ú.',
                        id: 'areaName',
                        exportDefinitions: [
                            { name: 'K.Ú.', key: 'area.name' }
                        ]
                    },
                    {
                        name: 'Vlastník',
                        id: 'owner',
                        exportDefinitions: [
                            { name: 'Vlastník', key: 'ownerships.ownershipName' }
                        ]
                    },
                    {
                        name: 'Případy',
                        id: 'case',
                        exportDefinitions: [
                            { name: 'Případy', key: 'cases.mpp.number' }
                        ]
                    },
                    {
                        name: 'Přidělení LV',
                        id: 'user',
                        exportDefinitions: [
                            { name: 'Přidělení LV', key: 'user', format: '{fullName} ({company.companyName})' }
                        ]
                    },
                    {
                        name: 'Řešitelé případů',
                        id: 'mandatary',
                        exportDefinitions: [
                            { name: 'Řešitel případů', key: 'caseMandatary', format: '{fullName} ({company.companyName})' }
                        ]
                    },
                    {
                        name: 'Stav trvalých záborů',
                        id: 'pernamentOccupationState'
                    },
                    {
                        name: 'Stav dočasných záborů nad 1 rok',
                        id: 'overOneYearOccupationState',
                    },
                    {
                        name: 'Stav dočasných záborů do 1 roku',
                        id: 'underOneYearOccupationState',
                    },
                    {
                        name: 'Stav služebností',
                        id: 'easementCaseState'
                    },
                    {
                        name: 'Blokování',
                        id: 'blocked',
                        exportDefinitions: [
                            { name: 'Blokování', key: 'blockedByUser' }
                        ]
                    }
                ]
            },
            {
                id: 'owners',
                name: 'Vlastníci',
                columns: [
                    {
                        id: 'owner',
                        name: 'Vlastník',
                        exportDefinitions: [
                            { name: 'Vlastník', key: 'subjectFullName' }
                        ]
                    },
                    {
                        id: 'address',
                        name: 'Adresa',
                        exportDefinitions: [
                            { name: 'Adresa', key: 'address' }
                        ]
                    },
                    {
                        id: 'cases',
                        name: 'Případy',
                        exportDefinitions: [
                            { name: 'Případy', key: 'caseNumbers' }
                        ]
                    },
                    {
                        id: 'titles',
                        name: 'LV',
                        exportDefinitions: [
                            { name: 'LV', key: 'ownerships.lv' }
                        ]
                    },
                    {
                        id: 'areaName',
                        name: 'K.Ú.',
                        exportDefinitions: [
                            { name: 'K.Ú.', key: 'ownerships.title.area.name' }
                        ]
                    },
                    {
                        id: 'pernamentOccupationState',
                        name: 'Stav trvalých záborů',
                    },
                    {
                        id: 'overOneYearOccupationState',
                        name: 'Stav dočasných záborů nad 1 rok',
                    },
                    {
                        id: 'underOneYearOccupationState',
                        name: 'Stav dočasných záborů do 1 roku',
                    },
                    {
                        id: 'easementCaseState',
                        name: 'Stav služebností',
                    },
                ],
            },
            {
                id: 'constructionObjects',
                name: null,
                columns: [
                    {
                        name: null,
                        id: 'co',
                        exportDefinitions: [
                            { name: 'SO', key: 'socis' }
                        ]
                    },
                    {
                        name: null,
                        id: 'phase'
                    },
                    {
                        name: 'Název',
                        id: 'name',
                        exportDefinitions: [
                            { name: 'Název', key: 'sotx' }
                        ]
                    },
                    {
                        name: 'Správce SO',
                        id: 'administrator',
                        exportDefinitions: [
                            { name: 'Správce SO', key: 'administrator.companyName' }
                        ]
                    },
                    {
                        name: 'Stav',
                        id: 'state'
                    },
                    {
                        name: 'Způsob řešení',
                        id: 'coSolution'
                    },
                    {
                        name: 'Termín realizace',
                        id: 'realizationDate'
                    },
                    {
                        name: 'Přidělení SO',
                        id: 'mandatary',
                        exportDefinitions: [
                            { name: 'Přidělění SO', key: 'user', format: '{fullName} ({company.companyName})' }
                        ]
                    },
                    {
                        id: 'permanentOccupationState',
                        name: 'Stav trvalých záborů',
                    },
                    {
                        id: 'overOneYearOccupationState',
                        name: 'Stav dočasných záborů nad 1 rok',
                    },
                    {
                        id: 'underOneYearOccupationState',
                        name: 'Stav dočasných záborů do 1 roku',
                    },
                    {
                        id: 'easementCaseState',
                        name: 'Stav služebností',
                    },
                ]
            },
            {
                id: 'occupations',
                name: 'Zábory',
                columns: [
                    {
                        id: 'lv',
                        name: 'LV',
                        exportDefinitions: [{
                                name: 'LV',
                                key: 'zablv'
                            }]
                    },
                    {
                        id: 'zabkutx',
                        name: 'K.Ú.',
                        exportDefinitions: [{
                                name: 'K.Ú.',
                                key: 'zabkutx'
                            }]
                    },
                    {
                        id: 'zabcis',
                        name: 'Číslo',
                        exportDefinitions: [{
                                name: 'Číslo záboru',
                                key: 'zabcis'
                            }]
                    },
                    {
                        exportDefinitions: [{
                                name: 'Druh záboru',
                                key: 'zabtyptx'
                            }]
                    },
                    {
                        exportDefinitions: [{
                                name: 'Výměra záboru',
                                key: 'zabvym'
                            }]
                    },
                    {
                        id: 'zabst',
                        name: 'Etapa / úsek',
                        exportDefinitions: [{
                                name: 'Etapa / úsek',
                                key: 'zabst'
                            }]
                    },
                    {
                        id: 'nabyvatel',
                        name: 'Nabyvatel',
                        exportDefinitions: [{
                                name: 'Nabyvatel',
                                key: 'nabyvatel'
                            }]
                    },
                    {
                        id: 'ownerships',
                        name: 'Vlastník',
                        exportDefinitions: [{
                                name: 'Vlastník',
                                key: 'parcel.title.ownerships.ownershipName'
                            }]
                    },
                    {
                        id: 'parcel',
                        name: 'Parcela',
                        exportDefinitions: [{
                                name: 'Číslo parcely',
                                key: '',
                                format: '{zabcisknp} {zabciskn}'
                            }, {
                                name: 'Výměra parcely',
                                key: 'parcel.vymera'
                            }, {
                                name: 'Druh pozemku',
                                key: 'parcel.drupozNazev'
                            }, {
                                name: 'Způsob využití',
                                key: 'parcel.zpusobVyuziti'
                            }]
                    },
                    {
                        id: 'cases',
                        name: 'Případy',
                        exportDefinitions: [{
                                name: 'Případy',
                                key: 'cases.mpp.number'
                            }]
                    },
                    {
                        id: 'constructionObjects',
                        name: 'Stavební objekt',
                        exportDefinitions: [{
                                name: 'Dotčený SO',
                                key: 'constructionObjects',
                                format: '{socis} - {sotx} (Etapa / úsek {sousek})'
                            }]
                    },
                    {
                        id: 'permanentOccupation',
                        name: 'Trvalý zábor',
                    },
                    {
                        id: 'temporaryOccupation',
                        name: 'Dočasný zábor',
                    },
                ]
            },
            {
                id: 'parcels',
                name: 'Parcely',
                columns: [
                    {
                        id: 'parcel',
                        name: 'Parcela',
                        exportDefinitions: [
                            { name: 'Číslo', key: 'parcis' },
                            { name: 'Výměra', key: 'vymera' },
                            { name: 'Druh Pozemku', key: 'drupozNazev' },
                            { name: 'Způsob využití', key: 'zpusobVyuziti' }
                        ]
                    },
                    {
                        id: 'areaName',
                        name: 'K.Ú.',
                        exportDefinitions: [
                            { name: 'K.Ú.', key: 'title.area.name' }
                        ]
                    },
                    {
                        id: 'lv',
                        name: 'LV',
                        exportDefinitions: [
                            { name: 'LV', key: 'title.lv' }
                        ]
                    },
                    {
                        id: 'owner',
                        name: 'Vlastník',
                        exportDefinitions: [
                            { name: 'Vlastník', key: 'title.ownerships.ownershipName' }
                        ]
                    },
                    {
                        id: 'case',
                        name: 'Případy',
                        exportDefinitions: [
                            { name: 'Případy', key: 'businessCases.mpp.number' }
                        ]
                    },
                    {
                        id: 'geometricPlan',
                        name: 'Geometrický plán',
                        exportDefinitions: [
                            { name: 'Geometrický plán', key: 'geometricalPlanExists' }
                        ]
                    },
                    {
                        id: 'opinion',
                        name: 'Znalecký posudek',
                        exportDefinitions: [
                            { name: 'Znalecký posudek', key: 'opinionRequests.name' }
                        ]
                    },
                    {
                        id: 'protection',
                        name: 'Ochrana',
                        exportDefinitions: [
                            { name: 'Ochrana', key: 'parcelProtectionsText' }
                        ]
                    },
                    {
                        id: 'constructionObjects',
                        name: 'Stavební objekt',
                        exportDefinitions: [
                            { name: 'Stavební objekt', key: 'constructionObjects', format: '{socis} - {sotx} (Etapa / úsek {sousek})' }
                        ]
                    },
                ],
            },
            {
                id: 'geometricPlanRequests',
                name: 'GP',
                columns: [
                    {
                        id: 'area',
                        name: 'Katastrální území',
                        exportDefinitions: [
                            { name: 'Katastrální území', key: 'areas.name' }
                        ]
                    },
                    {
                        id: 'lv',
                        name: 'LV',
                        exportDefinitions: [
                            { name: 'LV', key: 'parcels.lv' }
                        ]
                    },
                    {
                        id: 'name',
                        name: 'Číslo GP',
                        exportDefinitions: [
                            { name: 'Číslo GP', key: 'name' }
                        ]
                    },
                    {
                        id: 'supplier',
                        name: 'Zhotovitel GP',
                        exportDefinitions: [
                            { name: 'Zhotovitel GP', key: 'supplier' }
                        ]
                    },
                    {
                        id: 'type',
                        name: 'Typ',
                        exportDefinitions: [
                            { name: 'Typ', key: 'typeName' }
                        ]
                    },
                    {
                        id: 'status',
                        name: 'Stav',
                        exportDefinitions: [
                            { name: 'Stav', key: 'statusName' }
                        ]
                    },
                    {
                        id: 'date',
                        name: 'K datu',
                        exportDefinitions: [
                            { name: 'K datu', key: 'createdDate' }
                        ]
                    },
                    {
                        id: 'parcels',
                        name: 'Řešené parcely',
                        exportDefinitions: [
                            { name: 'Řešené parcely', key: 'parcels.parcis' }
                        ]
                    },
                    {
                        id: 'constructionObject',
                        name: 'Stavební objekt',
                        exportDefinitions: [
                            { name: 'Stavební objekt', key: 'constructionObjects', format: '{socis} - {sotx} (Etapa / úsek {sousek})' }
                        ]
                    },
                ]
            },
            {
                id: 'opinionRequests',
                name: 'ZP',
                columns: [
                    {
                        id: 'area',
                        name: 'Katastrální území',
                        exportDefinitions: [
                            { name: 'Katastrální území', key: 'title.area.name' }
                        ]
                    },
                    {
                        id: 'lv',
                        name: 'LV',
                        exportDefinitions: [
                            { name: 'LV', key: 'title.lv' }
                        ]
                    },
                    {
                        id: 'number',
                        name: 'Číslo ZP',
                        exportDefinitions: [
                            { name: 'Číslo ZP', key: 'name' }
                        ]
                    },
                    {
                        id: 'expert',
                        name: 'Znalec',
                        exportDefinitions: [
                            { name: 'Znalec', key: 'expert.fullName' }
                        ]
                    },
                    {
                        id: 'status',
                        name: 'Stav',
                        exportDefinitions: [
                            { name: 'Stav', key: 'statusName' }
                        ]
                    },
                    {
                        id: 'date',
                        name: 'K datu',
                        exportDefinitions: [
                            { name: 'K datu', key: 'realizedDate' }
                        ]
                    },
                    {
                        id: 'parcels',
                        name: 'Řešené parcely',
                        exportDefinitions: [
                            { name: 'Řešené parcely', key: 'parcels.parcis' }
                        ]
                    },
                ]
            },
            {
                id: 'constructionObjectsImported',
                name: null,
                columns: [
                    {
                        name: "Založeno",
                        id: 'createdDate'
                    },
                    {
                        name: null,
                        id: 'cislo'
                    },
                    {
                        name: null,
                        id: 'usekEtapa'
                    },
                    {
                        name: 'Název',
                        id: 'name'
                    },
                ]
            },
            {
                id: 'constructionObjectDetail',
                name: null,
                columns: [
                    {
                        name: 'Trvalý zábor - sloupce ceny',
                        id: 'permanentOccupationPriceColumns'
                    },
                    {
                        name: 'Dočasný zábor do jednoho roku - sloupce ceny',
                        id: 'temporaryUnderYearPriceColumns'
                    },
                    {
                        name: 'Dočasný zábor nad jeden rok - sloupce ceny',
                        id: 'temporaryOverYearPriceColumns'
                    },
                    {
                        name: 'Společné Věcné břemeno pro SO',
                        id: 'constructionObjectsBySharedEasement'
                    }
                ]
            },
            {
                id: 'parcelDetail',
                name: 'Detail parcely',
                columns: [
                    {
                        name: 'Trvalý zábor - sloupce ceny',
                        id: 'permanentOccupationPriceColumns'
                    },
                    {
                        name: 'Dočasný zábor do jednoho roku - sloupce ceny',
                        id: 'temporaryUnderYearPriceColumns'
                    },
                    {
                        name: 'Dočasný zábor nad jeden rok - sloupce ceny',
                        id: 'temporaryOverYearPriceColumns'
                    },
                    {
                        name: 'Služebnost - sloupce ceny',
                        id: 'easementsPriceColumns'
                    }
                ]
            },
            {
                id: 'titleDetail',
                name: 'Detail LV',
                columns: [
                    {
                        name: 'Trvalý zábor - sloupce ceny',
                        id: 'permanentOccupationPriceColumns'
                    },
                    {
                        name: 'Dočasný zábor do jednoho roku - sloupce ceny',
                        id: 'temporaryUnderYearPriceColumns'
                    },
                    {
                        name: 'Dočasný zábor nad jeden rok - sloupce ceny',
                        id: 'temporaryOverYearPriceColumns'
                    },
                    {
                        name: 'Služebnost - sloupce ceny',
                        id: 'easementsPriceColumns'
                    }
                ]
            }
        ];
        this.onAuthProjectUpdated = this.onAuthProjectUpdated.bind(this);
        this.authService.registerCallback(EVENTS.authProjectUpdated, this.onAuthProjectUpdated);
        this._translateVariables();
    }
    TableService.prototype.getTables = function () {
        return this.tables;
    };
    TableService.prototype.getTableByID = function (tableID) {
        return this.getTables().find(function (table) { return table.id === tableID; });
    };
    TableService.prototype.getColumnById = function (tableID, columnID) {
        var table = this.getTableByID(tableID);
        return table.columns.find(function (column) { return column.id === columnID; });
    };
    TableService.prototype._setDynamicFilterColumnName = function (tableID, columnID, name) {
        var column = this.getColumnById(tableID, columnID);
        column.name = name;
    };
    TableService.prototype._setDynamicFilterColumnNameMulti = function (tableIDs, columnID, name) {
        var e_1, _a;
        try {
            for (var tableIDs_1 = __values(tableIDs), tableIDs_1_1 = tableIDs_1.next(); !tableIDs_1_1.done; tableIDs_1_1 = tableIDs_1.next()) {
                var tableID = tableIDs_1_1.value;
                this._setDynamicFilterColumnName(tableID, columnID, name);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (tableIDs_1_1 && !tableIDs_1_1.done && (_a = tableIDs_1.return)) _a.call(tableIDs_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
    };
    TableService.prototype._setDynamicFilterTableName = function (tableID, name) {
        var table = this.getTableByID(tableID);
        table.name = name;
    };
    TableService.prototype._translateVariables = function () {
        var _this = this;
        this.wordService.getWord('CONSTRUCTION_OBJECT_PLURAL_NOMINATIV').then(function (word) { return _this._setDynamicFilterTableName('constructionObjects', word); });
        this.wordService.getWord('CONSTRUCTION_OBJECT_SHORTCUT').then(function (word) { return _this._setDynamicFilterColumnName('constructionObjects', 'co', word); });
        this.wordService.getWord('ETAPA_USEK').then(function (word) { return _this._setDynamicFilterColumnName('constructionObjects', 'phase', word); });
        this.wordService.getWord('CONSTRUCTION_OBJECT_PLURAL_NOMINATIV').then(function (word) { return _this._setDynamicFilterTableName('constructionObjectsImported', "P\u0159ipravovan\u00E9 " + word); });
        this.wordService.getWord('CONSTRUCTION_OBJECT_SHORTCUT').then(function (word) { return _this._setDynamicFilterColumnName('constructionObjectsImported', 'cislo', word); });
        this.wordService.getWord('ETAPA_USEK').then(function (word) { return _this._setDynamicFilterColumnName('constructionObjectsImported', 'usekEtapa', word); });
        this.wordService.getWord('CONSTRUCTION_OBJECT_GENITIV').then(function (word) { return _this._setDynamicFilterTableName('constructionObjectDetail', "Detail " + word); });
        this.wordService.getWord('PERMANENT_OCCUPATIONS_STATE').then(function (word) { return _this._setDynamicFilterColumnNameMulti(['titles', 'owners'], 'pernamentOccupationState', word); });
        this.wordService.getWord('PERMANENT_OCCUPATIONS_STATE').then(function (word) { return _this._setDynamicFilterColumnNameMulti(['constructionObjects'], 'permanentOccupationState', word); });
        this.wordService.getWord('OVER_YEAR_TEMPORARY_OCCUPATIONS_STATE').then(function (word) { return _this._setDynamicFilterColumnNameMulti(['titles', 'owners', 'constructionObjects'], 'overOneYearOccupationState', word); });
        this.wordService.getWord('UNDER_YEAR_TEMPORARY_OCCUPATIONS_STATE').then(function (word) { return _this._setDynamicFilterColumnNameMulti(['titles', 'owners', 'constructionObjects'], 'underOneYearOccupationState', word); });
        this.wordService.getWord('EASEMENT_STATE').then(function (word) { return _this._setDynamicFilterColumnNameMulti(['titles', 'owners', 'constructionObjects'], 'easementCaseState', word); });
        this.wordService.getWord('CONCERN_CONSTRUCTION_OBJECTS_SHORTCUT').then(function (word) { return _this._setDynamicFilterColumnNameMulti(['parcels'], 'constructionObjects', word); });
    };
    TableService.prototype.onAuthProjectUpdated = function () {
        if (this.authService.getActualProject()) {
            this._translateVariables();
        }
    };
    TableService.ngInjectableDef = i0.defineInjectable({ factory: function TableService_Factory() { return new TableService(i0.inject("EVENTS"), i0.inject(i1.WordService), i0.inject(i2.AuthService)); }, token: TableService, providedIn: "root" });
    return TableService;
}());
exports.TableService = TableService;
