"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var subject_name_pipe_1 = require("@app/common/pipes/subject-name.pipe");
var class_name_enum_1 = require("@app/dialog/class-name.enum");
var dialog_service_1 = require("@app/dialog/dialog.service");
var expert_edit_form_component_1 = require("@app/ps/components/expert-edit-form/expert-edit-form.component");
var ExpertSelectFormComponent = /** @class */ (function () {
    function ExpertSelectFormComponent(subjectNamePipe, dialogService) {
        this.subjectNamePipe = subjectNamePipe;
        this.dialogService = dialogService;
        this.changed = new core_1.EventEmitter();
        this.onAddExpert = this.onAddExpert.bind(this);
        this.onUpdateExpert = this.onUpdateExpert.bind(this);
        this.onChange = this.onChange.bind(this);
        this.expertsReloadRegister = this.expertsReloadRegister.bind(this);
    }
    ExpertSelectFormComponent.prototype.ngOnInit = function () {
    };
    ExpertSelectFormComponent.prototype.expertsReloadRegister = function (reloadFn) {
        this.reloadExperts = reloadFn;
    };
    ExpertSelectFormComponent.prototype.onChange = function () {
        this.changed.emit(this.expert);
    };
    ExpertSelectFormComponent.prototype.onAddExpert = function () {
        this.showDialog();
    };
    ExpertSelectFormComponent.prototype.onUpdateExpert = function () {
        this.showDialog(this.expert);
    };
    ExpertSelectFormComponent.prototype.showDialog = function (expert) {
        var _this = this;
        if (expert === void 0) { expert = null; }
        var dialog = this.dialogService.open(expert_edit_form_component_1.ExpertEditFormComponent, {
            className: class_name_enum_1.ClassName.HIGHER_DIALOG,
            data: { expert: expert }
        });
        var subscriber = dialog.afterClosed.subscribe(function (expert) {
            if (expert) {
                _this.expert = expert;
                _this.reloadExperts();
                _this.changed.emit(_this.expert);
            }
            subscriber.unsubscribe();
        });
    };
    return ExpertSelectFormComponent;
}());
exports.ExpertSelectFormComponent = ExpertSelectFormComponent;
