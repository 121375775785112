"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var common_1 = require("@angular/common");
var i0 = require("@angular/core");
var i1 = require("@angular/common");
var PercentagePipe = /** @class */ (function () {
    function PercentagePipe(decimalPipe) {
        this.decimalPipe = decimalPipe;
    }
    PercentagePipe.prototype.transform = function (value, total) {
        var val = parseFloat(value);
        var tot = parseFloat(total);
        if (isNaN(val) || isNaN(tot) || tot === 0 || val === 0) {
            return '0%';
        }
        var percentage = val / tot * 100;
        if (percentage > 0 && percentage < 1) {
            return this.decimalPipe.transform(percentage, '1.2-2') + '%';
        }
        if (percentage >= 1 || percentage === 0) {
            return this.decimalPipe.transform(percentage, '1.0-0') + '%';
        }
    };
    PercentagePipe.ngInjectableDef = i0.defineInjectable({ factory: function PercentagePipe_Factory() { return new PercentagePipe(i0.inject(i1.DecimalPipe)); }, token: PercentagePipe, providedIn: "root" });
    return PercentagePipe;
}());
exports.PercentagePipe = PercentagePipe;
