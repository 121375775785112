"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var angular_1 = require("@uirouter/angular");
var acting_person_model_1 = require("@app/ps/models/acting-person.model");
var callbacks_enum_1 = require("@app/ps/enums/callbacks.enum");
var callback_model_1 = require("@app/ps/models/callback.model");
var RepresentationComponent = /** @class */ (function () {
    function RepresentationComponent(stateService, callbacks, restangular) {
        this.callbacks = callbacks;
        this.restangular = restangular;
        this.label = 'Jednající/zastupující osoby';
        this.API_URL = "subjects/" + stateService.params.ownerId + "/acting-persons";
    }
    RepresentationComponent.prototype.ngOnInit = function () {
        if (this.representatives.length === 0) {
            this.createAndAddRepresentative();
        }
        this.addRepresentative = this.addRepresentative.bind(this);
        this.removeRepresentative = this.removeRepresentative.bind(this);
        this.createAndAddRepresentative = this.createAndAddRepresentative.bind(this);
        this.removeRoleCadastreForAllExcept = this.removeRoleCadastreForAllExcept.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    };
    RepresentationComponent.prototype.createAndAddRepresentative = function () {
        this.representatives.push(new acting_person_model_1.ActingPersonModel());
    };
    RepresentationComponent.prototype.addRepresentative = function (representative) {
        this.representatives.push(representative);
    };
    RepresentationComponent.prototype.removeRoleCadastreForAllExcept = function (representative) {
        this.representatives = this.representatives
            .map(function (r) {
            if (representative !== r && representative.roleCadastre) {
                r.roleCadastre = false;
            }
            return __assign({}, r);
        });
    };
    RepresentationComponent.prototype.removeRepresentative = function (representative) {
        var _this = this;
        return function () {
            var idx = _this.representatives.indexOf(representative);
            if (idx !== -1) {
                _this.representatives.splice(idx, 1);
            }
            if (_this.representatives.length === 0) { // don't let the form disappear
                _this.createAndAddRepresentative();
            }
        };
    };
    RepresentationComponent.prototype.shouldShowDeleteButton = function () {
        return (this.representatives.length > 1 || (this.representatives.length === 1 && this.representatives[0].id));
    };
    RepresentationComponent.prototype.onSubmit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var validRepresentatives, flattenRepresentatives, response, representatives;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        validRepresentatives = this.representatives.filter(acting_person_model_1.ActingPersonModel.hasAtLeastOneDefinedProperty);
                        validRepresentatives.forEach(function (r) { return r.cancelled = false; });
                        flattenRepresentatives = validRepresentatives.map(acting_person_model_1.ActingPersonModel.toFlatObject);
                        return [4 /*yield*/, this.restangular.one(this.API_URL).customPUT(flattenRepresentatives)];
                    case 1:
                        response = _a.sent();
                        representatives = this.restangular.stripRestangular(response).map(acting_person_model_1.ActingPersonModel.fromFlatObject);
                        this.callbacks.get(callbacks_enum_1.Callbacks.RepresentativesUpdated)(representatives);
                        return [2 /*return*/, response];
                }
            });
        });
    };
    RepresentationComponent.prototype.hasValidForm = function () {
        return this.representatives
            .filter(acting_person_model_1.ActingPersonModel.hasAtLeastOneDefinedProperty)
            .every(function (r) {
            return (r.firstName
                && r.surname
                && (r.companyName ? r.identificationNumber : true));
        });
        // const exactlyOne: boolean = this.representatives.length === 1;
        // return allFilled || exactlyOne;
    };
    return RepresentationComponent;
}());
exports.RepresentationComponent = RepresentationComponent;
